import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Logo from "../../../assets/images/TechademyLogo.svg";
import CalenderIcon from "../../../assets/images/CalenderIcon.svg";
import "./header.scss";
import NotificationPopover from "./NotificationPopover";
import ProfileView from "./ProfileMenu";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import { ReactSVG } from "react-svg";
import { Container } from "iiht-b2a-ui-components/build/components";
import { useSelector } from "react-redux";

function HeaderView() {
  const navigate = useNavigate();
  const { isAdmin } = useSelector((state) => state.user);

  return (
    <AppBar position="static" className="navbar-dashboard">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { md: "flex", xs: "none", sm: "none" },
            }}
          >
            <ReactSVG src={Logo} />
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "right",
            }}
          >
            {
              isAdmin && 
              <IconButton
                sx={{ p: 0 }}
                onClick={() => navigate("/dashboard")}
                className="headerBtns"
              >
                <GridMenuIcon />
              </IconButton>
            }
            <IconButton
              sx={{ p: 0 }}
              onClick={() => navigate("/calender")}
              className="headerBtns"
            >
              <ReactSVG src={CalenderIcon} />
            </IconButton>
            <NotificationPopover />
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <ProfileView />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default HeaderView;

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: "flex", 
    flexDirection: "column",
    gap: "28px",
    padding: "32px 18px"
  },
  breadCrumbsContainer: {
    display: "inline-flex", 
    gap: "4px", 
    alignItems: "center"
  },
  breadCrumbsContent: {
    color: "#6F6F6F",
    fontSize: "12px",
    fontWeight: "400",
    cursor: "pointer"
  },
  headerContainer: {
    display: "inline-flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: '20px',
  },
  title: {
    fontSize: "22px", 
    color: "#0D1021", 
    fontWeight: "500"
  },
  gridIcon: {
    color: "#647B8F"
  },
  buttonsGroup: {
    display: "inline-flex",
    gap: "15px",
    alignItems: "center",
    justifyContent: "center"
  },
  button: {
    cursor: "pointer",
    borderRadius:" 4px",
    background: "#FFF",
    padding: "4px 14px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 0px 2px 0px rgba(21, 40, 80, 0.12), 0px 2px 4px 0px rgba(21, 40, 80, 0.10)"
  },
  filterButton: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "7px",
  },
  buttonText: {
    color: "#405071",
    fontSize: "14px",
    fontWeight: "600"
  },
  activeButton: {
    cursor: "pointer",
    borderRadius:" 4px",
    background: "#A32930",
    padding: "4px 14px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 0px 2px 0px rgba(21, 40, 80, 0.12), 0px 2px 4px 0px rgba(21, 40, 80, 0.10)"
  },
  activeButtons:{
    cursor: "pointer",
    borderRadius:" 4px",
    background: "#A32930",
    padding: "0px 14px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 0px 2px 0px rgba(21, 40, 80, 0.12), 0px 2px 4px 0px rgba(21, 40, 80, 0.10)"
  },
  activeButtonText: {
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "400"
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  field: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    width: "25%"
  },
  section:{
    display: "flex",
    gap:"180px"
  },
  textarea: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    width: "100%"
  },
  fieldLabel: {
    color: "#012148",
    fontSize: "14px",
    fontWeight: "500",
  },
  fieldInput: {
    color: "#9DA9B0",
    fontSize: "12px",
    fontWeight: "400",
    padding: "10px 12px",
    borderRadius: "4px",
    border: "1px solid #CDD6E2",
    background: "#fff",
    outline: "none",
    marginBottom: "20px"
  },
  checkboxContainer: {
    display: "inline-flex",
    gap: "44px", 
  },
  checkboxLabel: {
    display: "inline-flex",
    gap: "8px",
    alignItems: "center",
  },
  textAreaInput: {
    resize: "none",
    color: "#9DA9B0",
    fontSize: "12px",
    fontWeight: "400",
    padding: "8px 12px",
    borderRadius: "4px",
    border: "1px solid #CDD6E2",
    background: "#fff",
    outline: "none",
  },
  checkbox: {
    width: "14px",
    height: "14px",
    borderRadius: "6px",
    border: "1px solid ##A6B0C2",
    accentColor: "#AC0521"
  },
  errorMessage: {
    fontSize: "10px",
    fontWeight: "400",
    color: "#AC0521",
  },
  line:{
    background:'#A32930',
    height:"3px"
  },
  QuestionHeader:{
    fontFamily: 'Poppins',
    fontSize: '21px',
    fontWeight: '500',
    lineHeight: '24px',
    textAlign: 'left',
    marginTop:"30px"
  },
  fields:{
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    width: "90%"
  },
  addButton:{
    cursor: "pointer",
    borderRadius:" 4px",
    background: "#A32930",
    padding: "4px 14px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 0px 2px 0px rgba(21, 40, 80, 0.12), 0px 2px 4px 0px rgba(21, 40, 80, 0.10)"
  },
  buttonsGroupra:{
    display: "inline-flex",
    gap: "15px",
    alignItems: "center",
    justifyContent: "center",
    marginBottom:"40px",
    marginTop:"30px",
  }
}));

export default useStyles;

import Admission from "../../../src/assets/images/admission-module.svg";
import Academic from "../../../src/assets/images/academic-module.svg";
import Finance from "../../../src/assets/images/finance-module.svg";
import HR from "../../../src/assets/images/hr-module.svg";
import Examination from "../../../src/assets/images/examination-module.svg";
import Survey from "../../../src/assets/images/survey_icon.png";

export const fieldsData = [
  { name: "Admission", icon: Admission },
  { name: "Academics", icon: Academic },
  { name: "Finance", icon: Finance },
  { name: "HR", icon: HR },
  { name: "IQAC", icon: Admission },
  { name: "Examination", icon: Examination },
  { name: "Survey", icon: Survey }
];

import { Badge, Button, Popover, FormControl, IconButton } from "@mui/material";
import { ReactSVG } from "react-svg";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Filters, SaveFilters } from "../../modules/lead";
import { useDispatch, useSelector } from "react-redux";
import { addFilter, saveFilter, clearAllFilters } from "../../slices/filterSlice";
import ArrowDownSVG from "../../assets/images/arrow-down.svg";
import FiltersSVG from "../../assets/images/filters-icon.svg";
import { toast } from "react-toastify";
import useStyles from "./style";
import "./filters.css";

function CommonFilters(type) {
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterFields, setFilterFields] = useState([{ data: "", condition: "", searchTerm: "" }]);
  const { doctype } = type;
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [formattedFilter, setFormattedFilter] = useState([]);
  const [filterName, setFilterName] = useState("");
  const dispatch = useDispatch();
  const selectedFilters = useSelector((state) => state.filters.selectedFilter);
  const saveFilters = useSelector((state) => state.filters.filters);

  const conditionMapping = {
    Equals: "=",
    "Not Equals": "!=",
    Like: "like",
    "Not Like": "not+like",
    In: "in",
    "Not In": "not+in",
    Is: "is",
  };

  const handleApplyFilter = () => {
    const formattedFilters = filterFields.map((filter) => {
      const condition = conditionMapping[filter.condition];
      const formattedCondition = condition ? condition : "";

      return [filter.data, formattedCondition, filter.searchTerm];
    });

    dispatch(saveFilter({ selectedFilter: filterFields }));

    setFormattedFilter(formattedFilters);
    const filtersApplied = [...appliedFilters, ...filterFields];

    setAppliedFilters([...filtersApplied]);

    dispatch(addFilter({ doctype: doctype, filters: formattedFilters }));
  };

  const [filters, setFilters] = useState([]);
  const handleFilterButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddField = () => {
    setFilterFields([...filterFields, { data: "", condition: "", searchTerm: "" }]);
  };

  const handleRemoveField = (index) => {
    const newFields = [...filterFields];
    newFields.splice(index, 1);
    setFilterFields(newFields);
  };

  const handleClearFilters = () => {
    dispatch(clearAllFilters()); // Dispatch action to clear all filters
  };

  const handleEditAppliedFilter = (index, field, value) => {
    const newFilterFields = [...filterFields];
    newFilterFields[index] = {
      ...newFilterFields[index],
      [field]: value,
    };
    setFilterFields(newFilterFields);
  };

  const handleChange = (index, key, value) => {
    const newFields = [...filterFields];
    newFields[index][key] = value;
    setFilterFields(newFields);
  };

  const getFilters = async () => {
    const payload = {
      doctype: doctype,
    };
    try {
      const res = await Filters(payload);
      if (res.status === 200) {
        setFilters(res?.data.message);
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  const handleFilterNameChange = (event) => {
    setFilterName(event.target.value);
  };

  const handleSaveFilters = async () => {
    const payload = {
      doc: {
        doctype: "List Filter",
        reference_doctype: doctype,
        filter_name: filterName,
        filters: JSON.stringify(saveFilters),
      },
    };
    try {
      const res = await SaveFilters(payload);
      if (res.status === 200) {
        toast.success("Filter added successfully!");
      }
    } catch (error) {
      console.error("Error saving the filter:", error);
    }
  };

  useEffect(() => {
    getFilters();
    setFilterFields(selectedFilters || [{ data: "", condition: "", searchTerm: "" }]);
    setAppliedFilters(selectedFilters[0]?.data ? selectedFilters : []);
  }, [selectedFilters]);

  const getConditionsForLabel = (fieldname) => {
    const selectedLabel = filters.find((option) => option.fieldname === fieldname);
    return selectedLabel ? selectedLabel.conditions : [];
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <div className={`filterBtn ${styles.button} ${styles.filterButton}`} onClick={handleFilterButtonClick}>
        <ReactSVG src={FiltersSVG} />
        <p className={styles.buttonText}>Filters</p>
        {appliedFilters.length > 0 && (
          <p style={{ margin: "0px 12px" }}>
            <Badge badgeContent={appliedFilters.length} color="error" />
          </p>
        )}
        <ReactSVG src={ArrowDownSVG} />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{ mt: 1 }}
      >
        <div className={styles.container}>
          <div className={styles.filterContainer}>
            {filterFields.map((field, index) => {
              return (
                <div key={index} className={styles.filterRow}>
                  <select
                    value={field.data}
                    className={styles.filterSelectInput}
                    onChange={(e) => handleEditAppliedFilter(index, "data", e.target.value)}
                  >
                    <option value="" disabled selected={true}>
                      Filters
                    </option>
                    {filters?.map((option) => (
                      <option key={option.fieldname} value={option.fieldname}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <select
                    value={field.condition}
                    className={styles.filterSelectInput}
                    onChange={(e) => handleEditAppliedFilter(index, "condition", e.target.value)}
                  >
                    <option value="" disabled selected={true}>
                      Condition
                    </option>
                    {getConditionsForLabel(field.data).map((condition) => (
                      <option key={condition} value={condition}>
                        {condition}
                      </option>
                    ))}
                  </select>
                  {
                    filters?.map((option, i) => {
                      return (
                        <>
                          {
                            field.data === option.fieldname ? (
                              option.fieldtype === "Select" ? (
                                  <select
                                    value={field.searchTerm}
                                    className={styles.filterSelectInput}
                                    onChange={(e) =>
                                      handleEditAppliedFilter(index, "searchTerm", e.target.value)
                                    }
                                  >
                                    <option value="" disabled>
                                      Select Value
                                    </option>
                                    {option.options.split("\n").map((opt, i) => (
                                      <option key={i} value={opt.trim()}>
                                        {opt.trim()}
                                      </option>
                                    ))}
                                  </select>
                              ) : (
                                  <input
                                    value={field.searchTerm}
                                    className={styles.filterRowInput}
                                    placeholder="Enter value"
                                    onChange={(e) =>
                                      handleEditAppliedFilter(index, "searchTerm", e.target.value)
                                    }
                                  />
                              )
                            ) : null
                          }
                        </>
                      )
                    }) 
                  }
                  <IconButton onClick={() => handleRemoveField(index)} size="small">
                    <CloseIcon className="close_filter" />
                  </IconButton>
                </div>
              )})}
          </div>
          <div className={styles.addRow}>
              <p onClick={handleAddField} className={styles.nonActiveButton}>
                + Add Filter
              </p>
            <div className={styles.buttonGroup}>
              <p className={styles.nonActiveButton} onClick={handleClearFilters}>
                Clear
              </p>
              <p className={styles.activeButton} onClick={handleApplyFilter}>
                Apply
              </p>
            </div>
          </div>
          <div className={styles.addRow}>
            <input
              label="Filter Name"
              variant="outlined"
              className={styles.filterNameInput}
              placeholder="Filter Name"
              onChange={handleFilterNameChange}
            />
            <p className={styles.activeButton} onClick={handleSaveFilters}>
              Save
            </p>
          </div>
        </div>
      </Popover>
    </div>
  );
}

export default CommonFilters;

import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GridMenuIcon } from "@mui/x-data-grid";
import { Stack, Typography } from "iiht-b2a-ui-components/build/components";
import Button from "iiht-b2a-ui-components/build/components/Button";
import { Table } from "antd";
import moment from "moment";
import { toast } from "react-toastify";
import { CommonModal, CustomBreadcrumb, MainLayout } from "components/common";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { setLoading } from "slices/commonSlice";
import {
  deleteGenericDataApi,
  exportGenericData,
  getGenericListApi,
  updateGenericdataInBulk,
} from "modules/hr";
import { ActionFilters, CommonFilters, SavedFilters } from "components/Filters";
import BulkEdit from "components/common/BulkEdit";
import { genericDataActionOptions } from "constants/commonConstants";
import "./style.scss";
import ActionExport from "components/Filters/ActionExport";
import APIs from "api";

const InterviewTypeList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [interviewTypeList, setInterviewTypeList] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const { doctype, filters } = useSelector((state) => state.filters);
  const { loading } = useSelector((state) => state.common);

  const breadcrumbs = [
    {
      label: "IIHT",
      link: "/dashboard",
    },
    {
      label: "HR",
      link: "/modules/hr",
    },
    {
      label: "Recruitment",
      link: "/modules/hr",
    },
    {
      label: "Masters",
      link: "/modules/hr/masters",
    },
    {
      label: "Interview Type",
      link: "",
    },
  ];

  const columns = [
    {
      dataIndex: "name",
      title: "Name",
      width: "30%",
    },
    {
      dataIndex: "description",
      title: "Description",
      width: "40%",
    },
    {
      dataIndex: "name",
      title: "Action",
      width: "15%",
      render: (val, row) => {
        return (
          <NavLink
            color="secondary"
            variant="body2"
            to={`/modules/hr/masters/interview-type/edit-view/${row?.id}`}
            className="mouse-pointer qa-view-all view-all"
          >
            View
          </NavLink>
        );
      },
    },
  ];

  const handleBulkEdit = async ({ customfieldname, value }) => {
    const payload = {
      doctype: "Interview Type",
      docnames: selectedIds,
      customfieldname,
      value,
    };
    dispatch(setLoading({ loading: true }));
    setEditModal(false);
    const res = await updateGenericdataInBulk(payload);
    if (res?.status === 200) {
      setSelectedIds([]);
      setSelectedRowKeys([]);
      toast.success(res.data.message);
      fetchInterviewTypeList();
    } else {
      toast.error(res.data?.exc_type);
    }
    dispatch(setLoading({ loading: false }));
  };

  const handleBulkExport = async () => {
    dispatch(setLoading({ loading: true }));
    let exportIds = [];
    for (let i = 0; i < selectedIds.length; i++) {
      exportIds.push({
        doctype: "Interview Type",
        id: selectedIds[i],
      });
    }

    const payload = { export: [...exportIds] };
    const response = await fetch(APIs.bulkActionExport, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      dispatch(setLoading({ loading: false }));
      setSelectedIds([]);
      setSelectedRowKeys([]);
      toast.success("Data exported!");
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Interview Type.xlsx");
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error("Something went wrong");
      dispatch(setLoading({ loading: false }));
    }
  };

  const handleBulkDelete = async () => {
    setDeleteModal(false);
    const bulkDeleteparams = interviewTypeList
      .filter((row) => selectedIds.includes(row.id))
      .map(({ id }) => ({
        doctype: "Interview Type",
        id,
      }));
    const res = await deleteGenericDataApi({ delete: bulkDeleteparams });
    if (res?.status === 200) {
      toast.success("Interview Type deleted successfully.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setSelectedIds([]);
      setSelectedRowKeys([]);
      fetchInterviewTypeList();
    } else {
      toast.error("Failed to delete Interview Type List.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleAction = (option) => {
    if (option.id === "edit") {
      setEditModal(true);
    } else if (option.id === "export") {
      handleBulkExport();
    } else if (option.id === "delete") {
      setDeleteModal(true);
    }
  };

  const onSelectChange = (selectedKeys, selectedRowData) => {
    setSelectedRowKeys(selectedKeys);
    let selectedDataIds = [];
    selectedRowData.map((rowData) => selectedDataIds.push(rowData.id));
    setSelectedIds([...selectedDataIds]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      id: record.id,
    }),
  };

  const fetchInterviewTypeList = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Interview Type",
      fields: JSON.stringify(["*"]),
      filters:
        doctype === "Interview Type"
          ? JSON.stringify(filters).slice(1, -1)
          : [],
      limit: "*",
    };
    const res = await getGenericListApi(params);
    if (res?.status === 200) {
      if (res.data?.message?.length) {
        const list = res.data.message.map((data, i) => {
          return { ...data, key: i };
        });
        setInterviewTypeList(list);
      } else {
        setInterviewTypeList([]);
      }
    }
    dispatch(setLoading({ loading: false }));
  };

  useEffect(() => {
    fetchInterviewTypeList();
  }, [filters]);

  return (
    <MainLayout>
      {loading && <LoadingBackdrop open={loading} />}
      <div>
        <CustomBreadcrumb breadcrumbs={breadcrumbs} />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <GridMenuIcon className="gridIcon" />
            <Typography color="#0D1021" fontSize={22}>
              Interview Type
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <CommonFilters doctype="Interview Type" />
            <SavedFilters doctype="Interview Type" />
            {selectedRowKeys.length > 0 && (
              <ActionExport
                title="Action"
                options={genericDataActionOptions}
                disabled={!selectedRowKeys.length}
                onChange={handleAction}
              />
            )}
            {/* <div className="actionButton">
              <Typography color="#405071" variant="fieldHeadings">
                ...
              </Typography>
            </div> */}
            {!selectedRowKeys.length && (
              <Button
                className="qa-add-interview-type-button"
                variant="contained"
                color="primary"
                onClick={() =>
                  navigate("/modules/hr/masters/interview-type/create")
                }
              >
                Add Interview Type
              </Button>
            )}
          </Stack>
        </Stack>

        <div className="mt4">
          <Table
            rowSelection={{ type: "checkbox", ...rowSelection }}
            columns={columns}
            dataSource={interviewTypeList}
            size="small"
            pagination={true}
          />
        </div>
      </div>
      {deleteModal && (
        <CommonModal
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          modalTitle="Confirm"
          showDividers={true}
          onSubmit={handleBulkDelete}
          cancelBtnName="Cancel"
          submitBtnName="Delete"
          children="Are you sure you want to delete?."
          width=""
        />
      )}
      {editModal && (
        <BulkEdit
          handleBulkEdit={handleBulkEdit}
          doctype="Interview Type"
          isEditOpen={editModal}
          setIsEditOpen={setEditModal}
          items={selectedIds.length}
        />
      )}
    </MainLayout>
  );
};

export default InterviewTypeList;

import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { useNavigate, useParams } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import MainHeader from "../../../common/MainHeader";
import MainFooter from "../../../common/MainFooter";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSidebar } from "../../../../slices/financeSlice";
import { setLoading } from "../../../../slices/commonSlice";
import LoadingBackdrop from "../../../common/LoadingBackdrop.js/index.js";
import { toast } from "react-toastify";
import { Select, Table } from "antd";
import {
  addNewFloor,
  getAllRooms,
  getFloorDetailsById,
  getRoomDetailsById,
} from "modules/seatManagement";

const GetUpdateFloor = () => {
  const styles = useStyles();
  const selectSize = "middle";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { name } = useParams();
  const { loading } = useSelector((state) => state.common);
  const [floorName, setFloorName] = useState("");
  const [floorRooms, setFloorRooms] = useState([{}]);
  const [roomOptions, setRoomOptions] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [updateFloorDetails, setUpdateFloorDetails] = useState(false);

  const handleGetFloorDetailsById = async () => {
    const payload = {
      doctype: "Floor",
      id: name,
    };

    const res = await getFloorDetailsById({ payload });
    if(res.status === 200) {
      const floorDetails = res.data.message;
      const { floor_name, floor_room } = floorDetails;
      setFloorName(floor_name);
      let arr = [];
      for(let i = 0 ; i < floor_room.length ; i++) {
        const { room, row, capacity, room_number } = floor_room[i];
        arr.push({
          key: i,
          roomId: room,
          capacity,
          roomNumber: room_number,
          row,
          column: "",
          action: i,
        })
      }

      setFloorRooms([...arr]);
    }
    
  };

  const handleGetRoomDetailsById = async ({ id, index }) => {
    const payload = {
      doctype: "Room",
      id,
    };

    const res = await getRoomDetailsById({ payload });
    const roomDetails = res.data.message;
    floorRooms[index] = {
      key: index,
      roomId: roomDetails.id,
      capacity: roomDetails.seating_capacity,
      roomNumber: roomDetails.room_number,
      row: roomDetails.custom_row,
      column: roomDetails.custom_column,
      action: index,
    };
    setFloorRooms([...floorRooms]);
  };

  const columns = [
    {
      dataIndex: "key",
      title: "No.",
      width: "5%",
      render: (val, row, index) => {
        return <p className={styles.tableContentFields}>{index + 1}</p>;
      },
    },
    {
      title: "ROOM",
      dataIndex: "roomId",
      width: "25%",
      render: (val, record, index) => {
        return (
          <Select
            className={styles.tableContentFields}
            size={selectSize}
            placeholder="Select"
            style={{ width: "100%", border: "none !important" }}
            value={record.roomId}
            disabled={!updateFloorDetails}
            options={roomOptions}
            onChange={(value) => {
              handleGetRoomDetailsById({ id: value, index });
            }}
          />
        );
      },
    },
    {
      title: "CAPACITY",
      dataIndex: "capacity",
      width: "15%",
      render: (val, record) => {
        return <p className={styles.tableContentFields}>{record.capacity}</p>;
      },
    },
    {
      title: "ROOM NUMBER",
      dataIndex: "roomNumber",
      width: "15%",
      render: (val, record) => {
        return <p className={styles.tableContentFields}>{record.roomNumber}</p>;
      },
    },
    {
      title: "COLUMN",
      dataIndex: "column",
      width: "15%",
      render: (val, record) => {
        return <p className={styles.tableContentFields}>{record.column}</p>;
      },
    },
    {
      title: "ROW",
      dataIndex: "row",
      width: "15%",
      render: (val, record) => {
        return <p className={styles.tableContentFields}>{record.row}</p>;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "10%",
      render: (val, record) => {
        return (
          <>
            {record.roomId && (
              <p
                className={styles.link}
                onClick={() => {
                  const url = `/modules/examination/seat-management/room/${record.roomId}`;
                  window.open(url, "_blank");
                }}
              >
                View
              </p>
            )}
          </>
        );
      },
    },
  ];

  const handleUpdate = async () => {
    if (floorRooms.length <= 0) return;
    dispatch(setLoading({ loading: true }));
    const payload = {
      doctype: "Floor",
      floor_name: floorName,
      floor_room: floorRooms.map((roomDetails) => ({
        room: roomDetails.roomId,
        capacity: parseInt(roomDetails.capacity),
        row: parseInt(roomDetails.row),
        column: parseInt(roomDetails.column),
        room_number: parseInt(roomDetails.roomNumber),
      })),
    };

    console.log("paylod: ", payload)
    dispatch(setLoading({ loading: false }));
  };

  const handleGetAllRooms = async () => {
    const res = await getAllRooms();
    if (res.status === 200) {
      const roomList = [];
      res.data.message.forEach((room) => {
        roomList.push({
          value: room.id,
          label: room.room_name,
        });
      });
      setRoomOptions(roomList);
    }
  };

  const handleAddFloorRoom = () => {
    setFloorRooms([...floorRooms, {}]);
  };

  const handleDeleteFloorRoom = () => {
    const resultArray = floorRooms.filter(
      (obj) => !selectedRowKeys.includes(obj.key)
    );
    let arr = [];
    for (let i = 0; i < resultArray.length; i++) {
      arr.push({
        ...resultArray[i],
        key: i,
      });
    }
    setSelectedRowKeys([]);
    arr.length > 0 ? setFloorRooms(arr) : setFloorRooms([{}]);
  };

  const onSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = {
    type: "checkbox",
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      key: record.key,
    }),
    selectedRowKeys,
  };

  useEffect(() => {
    handleGetFloorDetailsById();
    handleGetAllRooms();
  }, []);

  return (
    <>
      <MainHeader />
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/dashboard");
                dispatch(setActiveSidebar({ activeSidebar: 0 }));
              }}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/modules/examination");
                dispatch(setActiveSidebar({ activeSidebar: 1 }));
              }}
            >
              Examination {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/modules/examination");
                dispatch(setActiveSidebar({ activeSidebar: 1 }));
              }}
            >
              Seat Management {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/modules/examination/seat-management/floors");
              }}
            >
              Floors
            </p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>{name}</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div
                className={styles.button}
                onClick={() => {
                  navigate("/modules/examination/seat-management/floors");
                }}
              >
                <p className={styles.buttonText}>Back</p>
              </div>
              {updateFloorDetails ? (
                <div className={styles.activeButton} onClick={handleUpdate}>
                  <p className={styles.activeButtonText}>Update</p>
                </div>
              ) : (
                <div
                  className={styles.activeButton}
                  onClick={() => setUpdateFloorDetails(true)}
                >
                  <p className={styles.activeButtonText}>Edit</p>
                </div>
              )}
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Floor Name *</p>
                <input
                  type="text"
                  placeholder="ex Text"
                  value={floorName}
                  className={styles.fieldInput}
                  disabled={!updateFloorDetails}
                  onChange={(e) => {
                    setFloorName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.tableField}>
                <p className={styles.fieldLabel}>Floor Room *</p>
                <Table
                  rowSelection={{ type: "checkbox", ...rowSelection }}
                  columns={columns}
                  dataSource={floorRooms}
                  size="small"
                  pagination={false}
                />
              </div>
            </div>
            {updateFloorDetails && (
              <div className={styles.fieldButtons}>
                {selectedRowKeys.length > 0 && (
                  <div
                    className={styles.deleteRow}
                    onClick={handleDeleteFloorRoom}
                  >
                    Delete
                  </div>
                )}
                <div className={styles.addRow} onClick={handleAddFloorRoom}>
                  +
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default GetUpdateFloor;

import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid"; 
import Typography from "@mui/material/Typography"; 
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import "./index.scss";
import UploadButton from "../common/UploadButton";
import { Button } from "iiht-b2a-ui-components/build/components";
import { getDataOfApplicationFormByApplicationId } from "../../modules/student";
import { PhotoProvider, PhotoView } from 'react-photo-view';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode == "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function FormSupport({ handleNext, handleBack }) {
  const [flag, setFlag] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState({
    custom_not_applicable1: 0,
    custom_disability_visual_hearing_locomotor_speechlanguage_etc: 0,
    custom_cognitivelearning_disability: 0,
    custom_physical_health: 0,
    custom_mental_health: 0,
    custom_any_other_condition_that_may_require_special_attention: 0,
    custom_not_applicable2: 0,
    custom_compensatoryextra_time: 0,
    custom_compensatoryextra_time1: 0,
    custom_scribe_for_persons: 0,
    custom_other_please_specify: 0,
    custom_provide_the_relevant_details_in_no_more_than_100_words: "",
    custom_provide_relevant_details_in_no_more_than_100_words: "",
    custom_attach_relevant_document:'',
    custom_if_any_please_attach_relevant_document:'',
  });

  // Function to handle checkbox changes
  const handleCheckboxChange = (name) => (event) => {
    setCheckboxValues({
      ...checkboxValues,
      [name]: event.target.checked ? 1 : 0,
    });
  };

  const handleStringChange = (name) => (event) => {
    setCheckboxValues({
      ...checkboxValues,
      [name]: event.target.value,
    });
  };

  const handleSave = () => {
    const formData = {
      custom_not_applicable1: checkboxValues.custom_not_applicable1,
      custom_disability_visual_hearing_locomotor_speechlanguage_etc: checkboxValues.custom_disability_visual_hearing_locomotor_speechlanguage_etc,
      custom_cognitivelearning_disability: checkboxValues.custom_cognitivelearning_disability,
      custom_physical_health: checkboxValues.custom_physical_health,
      custom_mental_health: checkboxValues.custom_mental_health,
      custom_attach_relevant_document:checkboxValues.custom_attach_relevant_document,
      custom_if_any_please_attach_relevant_document:checkboxValues.custom_if_any_please_attach_relevant_document,
      custom_any_other_condition_that_may_require_special_attention: checkboxValues.custom_any_other_condition_that_may_require_special_attention,
      custom_not_applicable2: checkboxValues.custom_not_applicable2,
      custom_compensatoryextra_time: checkboxValues.custom_compensatoryextra_time,
      custom_compensatoryextra_time1: checkboxValues.custom_compensatoryextra_time1,
      custom_scribe_for_persons: checkboxValues.custom_scribe_for_persons,
      custom_other_please_specify: checkboxValues.custom_other_please_specify,
      custom_provide_the_relevant_details_in_no_more_than_100_words: checkboxValues.custom_provide_the_relevant_details_in_no_more_than_100_words,
      custom_provide_relevant_details_in_no_more_than_100_words: checkboxValues.custom_provide_relevant_details_in_no_more_than_100_words,
    };

    handleNext({ addData: false, payload: formData });
  };

  const handleGetDataOfApplicationFormByApplicationId = async () => {
    if (window.localStorage.getItem("application-name")) {
      const applicationId = JSON.parse(window.localStorage.getItem("application-name"));
      const res = await getDataOfApplicationFormByApplicationId({ applicationId });
      if (res.status == 200) {
        const data = res.data.data;
        setCheckboxValues({
          ...checkboxValues,
          custom_not_applicable1: data.custom_not_applicable1 ? data.custom_not_applicable1 == 0 ? false : true : "",
          custom_disability_visual_hearing_locomotor_speechlanguage_etc: data.custom_disability_visual_hearing_locomotor_speechlanguage_etc ? data.custom_disability_visual_hearing_locomotor_speechlanguage_etc == 0 ? false : true : "",
          custom_cognitivelearning_disability: data.custom_cognitivelearning_disability ? data.custom_cognitivelearning_disability == 0 ? false : true : "",
          custom_physical_health: data.custom_physical_health ? data.custom_physical_health == 0 ? false : true : "",
          custom_mental_health: data.custom_mental_health ? data.custom_mental_health == 0 ? false : true : "",
          custom_attach_relevant_document: data.custom_attach_relevant_document ? data.custom_attach_relevant_document : "",
          custom_if_any_please_attach_relevant_document: data.custom_if_any_please_attach_relevant_document ? data.custom_if_any_please_attach_relevant_document : "",
          custom_any_other_condition_that_may_require_special_attention: data.custom_any_other_condition_that_may_require_special_attention ? data.custom_any_other_condition_that_may_require_special_attention == 0 ? false : true : "",
          custom_not_applicable2: data.custom_not_applicable2 ? data.custom_not_applicable2 == 0 ? false : true : "",
          custom_compensatoryextra_time: data.custom_compensatoryextra_time ? data.custom_compensatoryextra_time == 0 ? false : true : "",
          custom_compensatoryextra_time1: data.custom_compensatoryextra_time1 ? data.custom_compensatoryextra_time1 == 0 ? false : true : "",
          custom_scribe_for_persons: data.custom_scribe_for_persons ? data.custom_scribe_for_persons == 0 ? false : true : "",
          custom_other_please_specify: data.custom_other_please_specify ? data.custom_other_please_specify == 0 ? false : true : "",
          custom_provide_the_relevant_details_in_no_more_than_100_words: data.custom_provide_the_relevant_details_in_no_more_than_100_words ? data.custom_provide_the_relevant_details_in_no_more_than_100_words : "",
          custom_provide_relevant_details_in_no_more_than_100_words: data.custom_provide_relevant_details_in_no_more_than_100_words ? data.custom_provide_relevant_details_in_no_more_than_100_words : "",
        })
      }
    }
  }

  useEffect(() => {
    handleGetDataOfApplicationFormByApplicationId();
  },[])

  return (
    <>
      <Box sx={{ boxShadow: "none" }} className="formPersonalDetails">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item className="mainFormBody">
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={12}>
                  <Item>
                    <h4>Support At IIHT</h4>
                  </Item>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="body1">
                    Are you dealing with one or more of the following concerns
                    which have affected your academic or student life and require
                    special assistance? Please fill in the details thoroughly to
                    help us support you during your study at IIHT University. *
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={checkboxValues.custom_not_applicable1 == 1} onChange={handleCheckboxChange("custom_not_applicable1")} />}
                      label="Not Applicable"
                    />
                    <FormControlLabel
                      required
                      control={<Checkbox checked={checkboxValues.custom_disability_visual_hearing_locomotor_speechlanguage_etc == 1} onChange={handleCheckboxChange("custom_disability_visual_hearing_locomotor_speechlanguage_etc")} />}
                      label="Disability (Visual, Hearing, Locomotor, Speech/Language, etc)"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={checkboxValues.custom_cognitivelearning_disability == 1} onChange={handleCheckboxChange("custom_cognitivelearning_disability")} />}
                      label="Cognitive/Learning Disability"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={checkboxValues.custom_physical_health == 1} onChange={handleCheckboxChange("custom_physical_health")} />}
                      label="Physical Health"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={checkboxValues.custom_mental_health == 1} onChange={handleCheckboxChange("custom_mental_health")} />}
                      label="Mental Health"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={checkboxValues.custom_any_other_condition_that_may_require_special_attention == 1} onChange={handleCheckboxChange("custom_any_other_condition_that_may_require_special_attention")} />}
                      label="Any other condition that may require special attention"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="body1">
                    <p>
                      If applicable, please attach relevant documents such as
                      psycho-educational assessments or certificates, prescription
                      from medical professionals, etc.
                    </p>
                    <p>
                      <a href="#">Here</a> are the details of the centres on
                      campus to assist students who are enrolled at IIHT
                      University. Please note that the information provided will
                      be kept confidential and not shared publicly. Selective
                      information shared by students who are enrolled at IIHT
                      University might be shared with specific Departments and
                      Offices within IIHT University on a case-by-case basis for
                      internal official purposes.
                    </p>
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="body1">
                    <p>
                      Please provide the relevant details in no more than 100
                      words. Please attach relevant documents (if any).
                    </p>
                  </Typography>
                  <TextareaAutosize
                    aria-label="empty textarea"
                    placeholder="Type here ..."
                    style={{ width: "100%", minHeight: "100px" }}
                    value={checkboxValues.custom_provide_relevant_details_in_no_more_than_100_words}
                    onChange={handleStringChange(
                      "custom_provide_relevant_details_in_no_more_than_100_words"
                    )}
                  />
                </Grid>

                <Grid item xs={4} sm={4} md={4}>
                  <Typography variant="body1">
                    (If Any) Please Attach Relevant Document
                  </Typography>
                  <UploadButton formData={checkboxValues} setFormData={setCheckboxValues} fieldName="custom_if_any_please_attach_relevant_document" flag={flag} setFlag={setFlag} fileTypes={["JPG", "PNG", "JPEG", "PDF"]}/>
                  { 
                    checkboxValues.custom_if_any_please_attach_relevant_document ? 
                      checkboxValues.custom_if_any_please_attach_relevant_document.split(".")[1] === "pdf" ? 
                        <p style={{ color: "black", textDecoration: "none", cursor: "pointer", fontSize: "12px" }} onClick={() => window.open(`https://erp-dev.techademycampus.com${checkboxValues.custom_if_any_please_attach_relevant_document}`, "_blank")}>{checkboxValues.custom_if_any_please_attach_relevant_document.split("/")[2]}</p> : 
                        <PhotoProvider>
                        <div className="foo">
                          <PhotoView src={`https://erp-dev.techademycampus.com${checkboxValues.custom_if_any_please_attach_relevant_document}`}>
                            <img src={`https://erp-dev.techademycampus.com${checkboxValues.custom_if_any_please_attach_relevant_document}`} alt="" style={{width: "150px", height: "100px", marginTop: "12px", cursor: "pointer"}}/>
                          </PhotoView>
                        </div>
                    </PhotoProvider> :
                    <></>
                  }
                </Grid>
                <Grid item xs={4} sm={8} md={8}>
                  &nbsp;
                </Grid>
              </Grid>

              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={12}>
                  <Item>
                  &nbsp;
                  </Item>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="body1">
                  Based on the information shared by you in the previous question, please specify if you need any of the following academic accommodations during the admission process?The Admissions Committee will review the information shared by you in this section. If approved, you will be granted the relevant academic accommodations for the admission process.*
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={checkboxValues.custom_not_applicable2 == 1} onChange={handleCheckboxChange("custom_not_applicable2")} />}
                      label="Not Applicable"
                    />
                    <FormControlLabel
                      required
                      control={<Checkbox checked={checkboxValues.custom_compensatoryextra_time == 1} onChange={handleCheckboxChange("custom_compensatoryextra_time")} />}
                      label="Compensatory/Extra time for persons with physical/learning disabilities/impairments, etc."
                    />
                    <FormControlLabel
                      control={<Checkbox checked={checkboxValues.custom_compensatoryextra_time1 == 1} onChange={handleCheckboxChange("custom_compensatoryextra_time1")} />}
                      label="Compensatory/Extra time for people who need English language support"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={checkboxValues.custom_scribe_for_persons == 1} onChange={handleCheckboxChange("custom_scribe_for_persons")} />}
                      label="Scribe for persons with physical/learning disabilities/impairments, etc."
                    />
                    <FormControlLabel
                      control={<Checkbox checked={checkboxValues.custom_other_please_specify == 1} onChange={handleCheckboxChange("custom_other_please_specify")} />}
                      label="Other (Please specify)"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="body1">
                    <p>
                    Please use the space below to share relevant information, which has not been captured anywhere else in this form. This information can include circumstances or situations that might have led to educational, social or economic disadvantage. This is not a mandatory section but any/all information shared by you will contribute to a holistic assessment of your application.
                    </p>
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="body1">
                    <p>
                    Please provide the relevant details in no more than 100 words. Please attach relevant documents (if any).
                    </p>
                  </Typography>
                  <TextareaAutosize
                    aria-label="empty textarea"
                    placeholder="Type here ..."
                    style={{ width: "100%", minHeight: "100px" }}
                    value={checkboxValues.custom_provide_the_relevant_details_in_no_more_than_100_words}
                    onChange={handleStringChange(
                      "custom_provide_the_relevant_details_in_no_more_than_100_words"
                    )}
                  />
                </Grid>

                <Grid item xs={4} sm={4} md={4}>
                  <Typography variant="body1">
                  Attach Relevant Document
                  </Typography>
                  <UploadButton formData={checkboxValues} setFormData={setCheckboxValues} fieldName="custom_attach_relevant_document" flag={flag} setFlag={setFlag} fileTypes={["JPG", "PNG", "JPEG", "PDF"]}/>
                  { 
                    checkboxValues.custom_attach_relevant_document ? 
                      checkboxValues.custom_attach_relevant_document.split(".")[1] === "pdf" ? 
                        <p style={{ color: "black", textDecoration: "none", cursor: "pointer", fontSize: "12px" }} onClick={() => window.open(`https://erp-dev.techademycampus.com${checkboxValues.custom_attach_relevant_document}`, "_blank")}>{checkboxValues.custom_attach_relevant_document.split("/")[2]}</p> : 
                        <PhotoProvider>
                        <div className="foo">
                          <PhotoView src={`https://erp-dev.techademycampus.com${checkboxValues.custom_attach_relevant_document}`}>
                            <img src={`https://erp-dev.techademycampus.com${checkboxValues.custom_attach_relevant_document}`} alt="" style={{width: "150px", height: "100px", marginTop: "12px", cursor: "pointer"}}/>
                          </PhotoView>
                        </div>
                    </PhotoProvider> :
                    <></>
                  }
                </Grid>
                <Grid item xs={6} sm={8} md={8}>
                  &nbsp;
                </Grid>
              </Grid>
            </Item>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "row", pt: 2, justifyContent: "space-between" }}
        className="navBottomBtns"
      >
        <div style={{ display: "flex", gap: "12px"}}>
          <Button
            color="inherit"
            sx={{ mr: 1 }}
            className="backBtn"
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            onClick={() => {
              handleSave();
            }}
            sx={{ mr: 1 }}
          >
            Save
          </Button>
        </div>
        <Button sx={{ mr: 1 }} onClick={() => handleSave()}> 
          Next
        </Button>
      </Box>
    </>
  );
}

import React, { useState, useEffect } from "react";
import useStyles from './style';
import LeadDetails from "./LeadDetails";
import LeadEmails from "./LeadEmails";
import LeadEvents from "./LeadEvents";
import LeadNotes from "./LeadNotes";
import { useNavigate, useParams } from "react-router-dom";
import { getLeadDetails } from "../../../../modules/lead";
import { GridMenuIcon } from '@mui/x-data-grid';
import { useDispatch } from "react-redux"
import { setActiveSidebar } from "../../../../slices/financeSlice";
import MainHeader from "../../../common/MainHeader";
import MainFooter from "../../../common/MainFooter";
 
const LeadDashboard = () => {
  const { id, type } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const styles = useStyles();
  const [value, setValue] = useState(1);

  const [formData, setFormData] = useState({
    fullName: "",
  });

  useEffect(() => {
    if (type === "events") {
      setValue(4);;
    } else if (type === "emails") {
      setValue(2);
    } else if (type === "notes") {
      setValue(3);
    } else if (type === "details") {
      setValue(1);
    }
  }, [type]);

  useEffect(() => {
    handleGetLeadDetails();
  }, []);

  const handleGetLeadDetails = async () => {
    const res = await getLeadDetails({ uniqueId: id });
    setFormData({
      fullName: res?.data.data.first_name,
    });
  };

  return (
    <>
      <MainHeader />
      <div className={styles.mainContainer}>
        <div className={styles.breadCrumbsContainer}>
          <p className={styles.breadCrumbsContent} onClick={() => navigate("/dashboard")}>IIHT {" > "}</p>
          <p className={styles.breadCrumbsContent} onClick={() => {
            dispatch(setActiveSidebar({ activeSidebar: 1}));
            navigate("/modules/admission")
          }}>Admission {" > "}</p>
          <p className={styles.breadCrumbsContent} onClick={() => {
            dispatch(setActiveSidebar({ activeSidebar: 1}));
            navigate("/modules/admission")
          }}>Lead</p>
        </div>
       {
        formData && formData.fullName && (
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon}/>
              <p className={styles.title}>{formData.fullName}</p>
            </div>
          </div>
        )}
        {
          value === 1 ? <LeadDetails uniqueId={id} showHeader={true}/>
            : value === 2 ? <LeadEmails uniqueId={id} showHeader={true}/> 
              : value === 3 ? <LeadNotes uniqueId={id} showHeader={true}/> 
                : value === 4 ? <LeadEvents uniqueId={id} showHeader={true}/>  
                  : <></>
        }
      </div>
      <MainFooter />
    </>
  );
}

export default LeadDashboard;
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Popover, Table } from "antd";
import { GridMenuIcon } from "@mui/x-data-grid";
import {
  Button,
  Stack,
  Typography,
} from "iiht-b2a-ui-components/build/components";
import { toast } from "react-toastify";
import moment from "moment";
import { exportGenericData, getGenericListApi } from "modules/hr";
import { setLoading } from "slices/commonSlice";
import { CustomBreadcrumb, MainLayout } from "components/common";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import CommonFilters from "components/Filters/CommonFilters";
import SavedFilters from "components/Filters/SavedFilters";
import { ActionFilters } from "components/Filters";
import APIs from "api";
import useStyles from "./style";
import ActionExport from "components/Filters/ActionExport";

const OfferTermList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [offerTermList, setOfferTermList] = useState([]);
  const { doctype, filters } = useSelector((state) => state.filters);
  const { loading } = useSelector((state) => state.common);
  const styles = useStyles();

  const breadcrumbs = [
    {
      label: "IIHT",
      link: "/dashboard",
    },
    {
      label: "HR",
      link: "/modules/hr",
    },
    {
      label: "Recruitment",
      link: "/modules/hr",
    },
    {
      label: "Masters",
      link: "/modules/hr/masters",
    },
    {
      label: "Offer Term",
      link: "",
    },
  ];

  const columns = [
    {
      dataIndex: "offer_term",
      title: "ID",
      width: "90%",
    },
  ];

  const actionOptions = [{ label: "Export", id: "export", key: "export" }];

  const handleBulkExport = async () => {
    dispatch(setLoading({ loading: true }));
    let exportIds = [];
    for (let i = 0; i < selectedIds.length; i++) {
      exportIds.push({
        doctype: "Offer Term",
        id: selectedIds[i],
      });
    }

    const payload = { export: [...exportIds] };
    const response = await fetch(APIs.bulkActionExport, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      dispatch(setLoading({ loading: false }));
      setSelectedIds([]);
      setSelectedRowKeys([]);
      toast.success("Data exported!");
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Offer Term.xlsx");
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error("Something went wrong");
      dispatch(setLoading({ loading: false }));
    }
  };

  const handleAction = (option) => {
    if (option.id === "export") {
      handleBulkExport();
    }
  };

  const onSelectChange = (selectedKeys, selectedRowData) => {
    setSelectedRowKeys(selectedKeys);
    let selectedDataIds = [];
    selectedRowData.map((rowData) => selectedDataIds.push(rowData.id));
    setSelectedIds([...selectedDataIds]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      id: record.id,
    }),
  };

  const fetchDesignationList = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Offer Term",
      fields: JSON.stringify(["*"]),
      filters:
        doctype === "Offer Term" ? JSON.stringify(filters).slice(1, -1) : [],
      limit: "*",
    };
    const res = await getGenericListApi(params);
    if (res?.status === 200) {
      if (res.data?.message?.length) {
        const list = res.data.message.map((data, i) => {
          return { ...data, key: i };
        });
        setOfferTermList(list);
      } else {
        setOfferTermList([]);
      }
    }
    dispatch(setLoading({ loading: false }));
  };

  useEffect(() => {
    fetchDesignationList();
  }, [filters]);

  return (
    <MainLayout>
      {loading && <LoadingBackdrop open={loading} />}
      <div>
        <CustomBreadcrumb breadcrumbs={breadcrumbs} />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <GridMenuIcon className="gridIcon" />
            <Typography color="#0D1021" fontSize={22}>
              Offer Term
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <CommonFilters doctype="Offer Term" />
            <SavedFilters doctype="Offer Term" />
            {selectedRowKeys.length > 0 && (
              <ActionExport
                title="Actions"
                options={actionOptions}
                disabled={!selectedRowKeys.length}
                onChange={handleAction}
              />
            )}

            {!selectedRowKeys.length && (
              <Button
                className="qa-sign-in-button"
                variant="contained"
                color="primary"
                onClick={() =>
                  navigate("/modules/hr/masters/offer-term/create")
                }
              >
                Add Offer Term
              </Button>
            )}
          </Stack>
        </Stack>

        <div className="mt4">
          <Table
            rowSelection={{ type: "checkbox", ...rowSelection }}
            columns={columns}
            dataSource={offerTermList}
            size="small"
            pagination={true}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default OfferTermList;

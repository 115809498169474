import APIs from "../api";
import axiosInstance from "../translator";

export const getAllQuestionsListing = async () => {
  try {
    return axiosInstance.get(
      `${APIs.getAllQuestionsList}?doctype=Question Bank`
    );
  } catch (error) {
    console.log("Error while getting questions Listing", error);
    return error.response;
  }
};

export const getAllSkillListing = async () => {
  try {
    return axiosInstance.get(`${APIs.getAllSkillList}`);
  } catch (error) {
    console.log("Error while getting skill Listing", error);
    return error.response;
  }
};

export const getAllChaptersListing = async () => {
  try {
    return axiosInstance.get(`${APIs.getAllChaptersList}`);
  } catch (error) {
    console.log("Error while getting chapters Listing", error);
    return error.response;
  }
};

export const getAllRubricsListing = async () => {
  try {
    return axiosInstance.get(`${APIs.getAllRubricsList}`);
  } catch (error) {
    console.log("Error while getting rubrics Listing", error);
    return error.response;
  }
};

export const getQuestionBankByID = async (params) => {
  try {
    return axiosInstance.get(APIs.questionBankByID, {
      params: params,
    });
  } catch (error) {
    console.log("Error while getting question bank by ID", error);
    return error.response;
  }
};

export const createQuestionBank = async ({ payload }) => {
  try {
    return axiosInstance.post(APIs.questionBank, payload);
  } catch (error) {
    console.log("Error while creating question bank ", error);
    return error.response;
  }
};

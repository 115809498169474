import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
  headingContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%"
  },
  headingField: {
    borderRadius: "4px",
    border: "1px solid #E6EBF2",
    background: "#fff",
    boxShadow: "3px 3px 10px 0px rgba(0, 0, 0, 0.10)",
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    padding: "8px 18px",
    width: "30%",
    borderLeft: "1px solid #1C385C"
  },
  headingText: {
    color: "#000",
    fontSize: "18px",
    fontWeight: "500",
    fontFamily: "Poppins"
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    width: "30%"
  },
  contentField: {
    borderRadius: "4px",
    border: "1px solid #C3CBD6",
    background: "#fff",
    boxShadow: "3px 3px 10px 0px rgba(0, 0, 0, 0.10)",
    padding: "6px 18px",
    display: "inline-flex",
    gap: "16px",
    cursor: "pointer",
    alignItems: "center",
  },
  contentText: {
    color: "#000",
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "Poppins"
  },
  selectedContentField: {
    borderRadius: "4px",
    border: "1px solid #1C385C",
    background: "#fff",
    boxShadow: "3px 3px 10px 0px rgba(0, 0, 0, 0.10)",
    padding: "6px 18px",
    display: "inline-flex",
    gap: "16px",
    cursor: "pointer",
    alignItems: "center",
  }
}));

export default useStyles;

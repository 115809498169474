import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import { getGenericListApi } from "modules/hr";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { setLoading } from "slices/commonSlice";

const AppointmentLetterList = ({ applicantName, jobApplicationData }) => {
  const dispatch = useDispatch();
  const { id: jobApplicantId, jobOpeningId, designationId } = useParams();
  const [appointmentLetterList, setAppointmentLetterList] = useState([]);
  const { loading } = useSelector((state) => state.common);

  const columns = [
    {
      dataIndex: "",
      title: "SNo.",
      width: "10%",
      align: "center",
      render: (_val, _row, rowIndex) => rowIndex + 1,
    },
    {
      dataIndex: "applicant_name",
      title: "Applicant Name",
      width: "30%",
    },
    {
      dataIndex: "id",
      title: "Application ID",
      width: "30%",
    },
    {
      dataIndex: "id",
      title: "Action",
      width: "10%",
      render: (val) => {
        return (
          <NavLink
            color="secondary"
            variant="body2"
            to={`/modules/hr/job-application/view/appointment-letter/edit-view/${jobApplicantId}/${jobOpeningId}/${designationId}/${val}`}
            state={{
              applicantName,
              emailId: jobApplicationData?.email_id,
            }}
            className="mouse-pointer qa-view-all view-all"
          >
            View
          </NavLink>
        );
      },
    },
  ];

  const fetchAppointmentLetterList = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Appointment Letter",
      fields: JSON.stringify(["*"]),
      filters: JSON.stringify([
        ["job_applicant", "=", jobApplicantId],
        // ["id", "=", jobOpeningId],
      ]),
      limit: "*",
    };
    const res = await getGenericListApi(params);
    if (res?.status === 200) {
      if (res.data?.message?.length) {
        const list = res.data.message.map((data, i) => {
          return { ...data, key: i };
        });
        setAppointmentLetterList(list);
      } else {
        setAppointmentLetterList([]);
      }
    }
    dispatch(setLoading({ loading: false }));
  };

  useEffect(() => {
    fetchAppointmentLetterList();
  }, []);

  return (
    <div>
      {loading && <LoadingBackdrop open={loading} />}
      <Table columns={columns} dataSource={appointmentLetterList} size="small" pagination={true} />
    </div>
  );
};

export default AppointmentLetterList;

import React from "react";
import useStyles from "./style";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import { ReactSVG } from "react-svg";
import { attendanceDashboardList } from "./config";

const AttendanceComponent = ({ setActiveModule }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const user = JSON.parse(window.localStorage.getItem("userDetails"));

  return (
    <div className={styles.mainContainer}>
      <div className={styles.breadCrumbsContainer}>
        <p
          className={styles.breadCrumbsContent}
          onClick={() => navigate("/dashboard")}
        >
          IIHT {" > "}
        </p>
        <p
          className={styles.breadCrumbsContent}
          onClick={() => setActiveModule(0)}
        >
          Academics {" > "}
        </p>
        <p className={styles.breadCrumbsContent}>Attendance</p>
      </div>
      <div className={styles.headerContainer}>
        <div className={styles.breadCrumbsContainer}>
          <GridMenuIcon className={styles.gridIcon} />
          <p className={styles.title}>Attendance</p>
        </div>
      </div>
      <div className={styles.contentContainer}>
        {attendanceDashboardList.map((item) => {
          if (
            (item.name === "Student Attendance" && user.isStudent) ||
            (item.name !== "Student Attendance" && !user.isStudent)
          ) {
            return (
              <div
                className={styles.itemContainer}
                onClick={() => navigate(`/modules/academics${item.url}`)}
              >
                <ReactSVG src={item.icon} />
                <p className={styles.itemTitle}>{item.name}</p>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default AttendanceComponent;

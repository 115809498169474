// Recruitment.js
import { Box, Button } from "@mui/material";
import { GridMenuIcon } from "@mui/x-data-grid";
import React from "react";
import MastersLanding from "../../../components/Hr/HrRecruit/Recruitment";
const RecruitmentComponent = () => {
  return (
    <>
      <MastersLanding/>
    </>
  );
};

export default RecruitmentComponent;

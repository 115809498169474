import React, { useEffect, useState } from "react";
import { Input, Select, Radio, Checkbox, Button } from "antd";
import useStyles from "../add-question-bank/style";
import { useNavigate, useParams } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import MainHeader from "../../../common/MainHeader";
import MainFooter from "../../../common/MainFooter";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSidebar } from "../../../../slices/financeSlice.js";
import LoadingBackdrop from "../../../common/LoadingBackdrop.js";
import { getAcademicYearListing } from "../../../../modules/finance.js";
import {
  questionTypeOptions,
  CategoryOptions,
  ProficiencyLevelOptions,
} from "../config";
import { getCourseListing } from "modules/studentGroup";
import {
  getAllChaptersListing,
  getAllRubricsListing,
  getAllSkillListing,
  getQuestionBankByID,
} from "modules/Examination";

const GetUpdateQuestionBank = () => {
  const selectSize = "middle";
  const styles = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [academicYearOptions, setAcademicYearOptions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [skillOptions, setSkillOptions] = useState([]);
  const [chapterOptions, setChapterOptions] = useState([]);
  const [rubricsOptions, setRubricsOptions] = useState([]);
  const [formData, setFormData] = useState([]);
  const [questionType, setQuestionType] = useState("");
  const [options, setOptions] = useState(Array(4).fill(""));
  const [questionText, setQuestionText] = useState("");
  const [questions, setQuestions] = useState([]);
  const [questionSaved, setQuestionSaved] = useState(false);
  const [values, setValues] = useState({
    academic_year: "",
    name: "",
    version: "",
    course_code: "",
    course_name: "",
    question_type: "",
    proficiency_level: "",
    duration: "",
    score: "",
    category: "",
    skill: "",
    sub_skill_1: "",
    sub_skill_2: "",
    subject_name: "",
    chapters: "",
    mapped_co: "",
    mapped_pi: "",
    rubrics: "",
  });

  const handleQuestionTypeChange = (value) => {
    setQuestionType(value);
  };

  const handleGetAcademicYearListing = async () => {
    const res = await getAcademicYearListing();
    if (res.status === 200) {
      const arr = [];
      const acdemicYearList = res.data.data;
      for (let i = 0; i < acdemicYearList.length; i++) {
        arr.push({
          value: acdemicYearList[i].name,
          label: acdemicYearList[i].name,
        });
      }
      setAcademicYearOptions(arr);
    }
  };

  const handleGetCourseListing = async () => {
    const res = await getCourseListing();
    if (res.status === 200) {
      const arr = [];
      const courseList = res.data.data;
      for (let i = 0; i < courseList.length; i++) {
        arr.push({
          value: courseList[i].name,
          label: courseList[i].name,
        });
      }
      setCourseOptions(arr);
    }
  };

  const handleGetSkillListing = async () => {
    const res = await getAllSkillListing();
    if (res.status === 200) {
      const arr = [];
      const skillList = res.data.data;
      for (let i = 0; i < skillList.length; i++) {
        arr.push({
          value: skillList[i].name,
          label: skillList[i].name,
        });
      }
      setSkillOptions(arr);
    }
  };

  const handleGetChaptersListing = async () => {
    const res = await getAllChaptersListing();
    if (res.status === 200) {
      const arr = [];
      const chapterList = res.data.data;
      for (let i = 0; i < chapterList.length; i++) {
        arr.push({
          value: chapterList[i].name,
          label: chapterList[i].name,
        });
      }
      setChapterOptions(arr);
    }
  };

  const handleGetRubricsListing = async () => {
    const res = await getAllRubricsListing();
    if (res.status === 200) {
      const arr = [];
      const rubricsList = res.data.data;
      for (let i = 0; i < rubricsList.length; i++) {
        arr.push({
          value: rubricsList[i].name,
          label: rubricsList[i].name,
        });
      }
      setRubricsOptions(arr);
    }
  };

  const handleGetQuestionBankByID = async () => {
    const params = {
      doctype: "Question Bank",
      id: id,
    };
    const res = await getQuestionBankByID(params);
    if (res.status === 200) {
      const questionBankDetails = res.data.message;
      const tableDetails = questionBankDetails.table_xgun_details || [];
      setFormData(tableDetails);
      console.log(res);
      Object.keys(questionBankDetails).forEach((key) => {
        setValues((prevValues) => ({
          ...prevValues,
          [key]: questionBankDetails[key],
        }));
      });
    }
  };

  useEffect(() => {
    handleGetAcademicYearListing();
    handleGetCourseListing();
    handleGetSkillListing();
    handleGetChaptersListing();
    handleGetRubricsListing();
    handleGetQuestionBankByID();
  }, []);

  return (
    <>
      <MainHeader />
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/dashboard")}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/modules/examination")}
            >
              Examination {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                dispatch(setActiveSidebar({ activeSidebar: 0 }));
                navigate("/modules/examination");
              }}
            >
              Question Bank {" > "}
            </p>
            <p className={styles.breadCrumbsContent}>{id}</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>{id}</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div
                className={styles.button}
                onClick={() => {
                  dispatch(setActiveSidebar({ activeSidebar: 0 }));
                  navigate("/modules/examination");
                }}
              >
                <p className={styles.buttonText}>Back</p>
              </div>
              {/* <div className={styles.activeButton}>
                <p className={styles.activeButtonText}>Save Question Bank</p>
              </div> */}
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Academic Year *</p>
                <Select
                  showSearch
                  size={selectSize}
                  disabled={true}
                  value={values.academic_year}
                  placeholder="Select"
                  className={styles.selectInput}
                  options={academicYearOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      academic_year: value,
                    });
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Name of Question Bank *</p>
                <Input
                  placeholder="ex Text"
                  value={values.name}
                  disabled={true}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      name: e.target.value,
                    });
                  }}
                  className={styles.fieldInput}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Version *</p>
                <Input
                  placeholder="ex Text"
                  value={values.version}
                  disabled={true}
                  className={styles.fieldInput}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      version: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Course Name *</p>
                <Select
                  showSearch
                  value={values.course_name}
                  size={selectSize}
                  placeholder="Select"
                  options={courseOptions}
                  className={styles.selectInput}
                  disabled={true}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      course_name: value,
                    });
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Course Code *</p>
                <Input
                  placeholder="ex Text"
                  value={values.course_code}
                  disabled={true}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      course_code: e.target.value,
                    });
                  }}
                  className={styles.fieldInput}
                />
              </div>
              <div className={styles.field}></div>
            </div>
            {formData.map((item, index) => (
              <>
                <div className={styles.formHeadingContainer}>
                  <p className={styles.headingTitle}>{item.id}</p>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Question Type *</p>
                    <Select
                      showSearch
                      size={selectSize}
                      disabled={true}
                      placeholder="Select"
                      value={item.question_type}
                      options={questionTypeOptions}
                      className={styles.selectInput}
                      onChange={handleQuestionTypeChange}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Proficiency Level *</p>
                    <Select
                      showSearch
                      size={selectSize}
                      disabled={true}
                      placeholder="Select"
                      value={item.proficiency_level}
                      options={ProficiencyLevelOptions}
                      className={styles.selectInput}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          proficiency_level: value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Duration (in minutes) *</p>
                    <Input
                      placeholder="ex Text"
                      disabled={true}
                      className={styles.fieldInput}
                      value={item.duration}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          duration: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Score *</p>
                    <Input
                      placeholder="ex Text"
                      disabled={true}
                      className={styles.fieldInput}
                      value={item.score}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          score: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Category *</p>
                    <Select
                      showSearch
                      size={selectSize}
                      disabled={true}
                      placeholder="Select"
                      className={styles.selectInput}
                      options={CategoryOptions}
                      value={item.category}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          category: value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Skill *</p>
                    <Select
                      showSearch
                      size={selectSize}
                      disabled={true}
                      placeholder="Select"
                      className={styles.selectInput}
                      options={skillOptions}
                      value={item.skill}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          skill: value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Sub Skill 1 *</p>
                    <Select
                      showSearch
                      size={selectSize}
                      disabled={true}
                      placeholder="Select"
                      className={styles.selectInput}
                      options={skillOptions}
                      value={item.sub_skill_1}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          sub_skill_1: value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Sub Skill 2 *</p>
                    <Select
                      showSearch
                      size={selectSize}
                      placeholder="Select"
                      disabled={true}
                      className={styles.selectInput}
                      options={skillOptions}
                      value={item.sub_skill_2}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          sub_skill_2: value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Course / Subject Name *</p>
                    <Select
                      showSearch
                      size={selectSize}
                      placeholder="Select"
                      disabled={true}
                      className={styles.selectInput}
                      options={courseOptions}
                      value={item.subject_name}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          subject_name: value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Chapter Name *</p>
                    <Select
                      showSearch
                      size={selectSize}
                      placeholder="Select"
                      disabled={true}
                      className={styles.selectInput}
                      options={chapterOptions}
                      value={item.chapters}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          chapters: value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Mapped CO *</p>
                    <Input
                      placeholder="ex BSC_CS"
                      className={styles.fieldInput}
                      value={item.mapped_co}
                      disabled={true}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          mapped_co: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Mapped PI *</p>
                    <Input
                      placeholder="ex Text"
                      className={styles.fieldInput}
                      value={item.mapped_pi}
                      disabled={true}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          mapped_pi: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Rubrics List </p>
                    <Select
                      showSearch
                      size={selectSize}
                      disabled={true}
                      placeholder="Select"
                      className={styles.selectInput}
                      options={rubricsOptions}
                      value={item.rubrics}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          rubrics: value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.formHeadingContainer}>
                  <p className={styles.headingTitle}></p>
                </div>
                <div className={styles.optionInput}>
                  <div className={styles.optionRow}>
                    <p className={styles.fieldLabel}>Question Text *</p>
                  </div>
                  <div className={styles.optionRow}>
                    <div
                      className={styles.textArea}
                      contentEditable="false"
                      dangerouslySetInnerHTML={{ __html: item.question }}
                    />
                    {/* <Input.TextArea
                      placeholder="Enter your question"
                      value={item.question}
                      disabled={true}
                    /> */}
                  </div>
                </div>
                {item.options.map((option, index) => (
                  <div className={styles.optionInput}>
                    <div className={styles.optionRow}>
                      <Checkbox checked={option.is_correct === 1} />
                      <p>{`Option ${index + 1}:`}</p>
                    </div>
                    <div className={styles.optionRow}>
                      <Input
                        placeholder={`Option ${index + 1}`}
                        value={option.option}
                        disabled={true}
                      />
                    </div>
                  </div>
                ))}
              </>
            ))}
          </div>
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default GetUpdateQuestionBank;

export const ApplicationDetailsJSON = [
  {
    name: "General",
    fields: [
      {
        label: "General Details",
        name: "generalDetails",
        type: "section",
        fields: [
          [
            {
              label: "",
              name: "custom_upload_student_image",
              type: "image",
            },
            {
              label: "Application Number",
              name: "name",
              type: "text",
            },
            {
              label: "Assign To",
              name: "assignedto",
              type: "text",
            },
          ],
          [
            {
              label: "Programme Type",
              name: "custom_program_group",
              type: "text",
            },
            {
              label: "Programme",
              name: "program",
              type: "text",
            },
            {
              label: "College",
              name: "college",
              type: "text",
            },
          ],
          [
            {
              label: "Course Preference 1",
              name: "custom_preference_1_",
              type: "text",
            },
            {
              label: "Course Preference 2",
              name: "custom_preference_2_",
              type: "text",
            },
            {
              label: "Course Preference 3",
              name: "custom_preference_3_",
              type: "text",
            },
          ],
          [
            {
              label: "Aadhaar Card Number",
              name: "custom_aadhar_card_number",
              type: "encrypted",
            },
            {
              label: "ABC ID",
              name: "custom_abc_id",
              type: "text",
            },
            {
              label: "",
              name: "",
              type: "text",
            },
          ],
        ],
      },
    ],
  },
  {
    name: "Personal",
    fields: [
      {
        label: "Personal Details",
        name: "personalDetails",
        type: "section",
        fields: [
          [
            {
              label: "First Name",
              name: "first_name",
              type: "text",
            },
            {
              label: "Middle Name",
              name: "middle_name",
              type: "text",
            },
            {
              label: "Last Name",
              name: "last_name",
              type: "text",
            },
          ],
          [
            {
              label: "Gender",
              name: "gender",
              type: "text",
            },
            {
              label: "Preferred Pronouns",
              name: "custom_preferred_gender_pronouns",
              type: "text",
            },
            {
              label: "DOB",
              name: "date_of_birth",
              type: "date",
            },
          ],
          [
            {
              label: "Nationality",
              name: "nationality",
              type: "text",
            },
            {
              label: "Category",
              name: "student_category",
              type: "text",
            },
            {
              label: "Blood Group",
              name: "blood_group",
              type: "text",
            },
          ],
          [
            {
              label: "Aadhaar Card Number",
              name: "custom_aadhar_card_number",
              type: "encrypted",
            },
            {
              label: "ABC ID",
              name: "custom_abc_id",
              type: "text",
            },
            {
              label: "",
              name: "",
              type: "text",
            },
          ],
        ],
      },
      {
        label: "Contact Information",
        name: "contactInfo",
        type: "section",
        fields: [
          [
            {
              label: "Email Address",
              name: "student_email_id",
              type: "email",
            },
            {
              label: "Alternate Email Address",
              name: "custom_alternate_email_address",
              type: "email",
            },
            {
              label: "Phone Number",
              name: "student_mobile_number",
              type: "text",
            },
          ],
          [
            {
              label: "Alternate Phone Number",
              name: "custom_alternate_mobile",
              type: "text",
            },
            {
              label: "Telephone Number",
              name: "custom_telephone_number_residence",
              type: "text",
            },
            {
              label: "",
              name: "",
              type: "text",
            },
          ],
          [
            {
              label: "Emergency Contact Name",
              name: "custom_emergency_contact_name_",
              type: "text",
            },
            {
              label: "Relation With Emergency Contact",
              name: "custom_relation_with_emergency_contact_",
              type: "text",
            },
            {
              label: "Emercency Contact Number",
              name: "custom_emergency_contact_number_",
              type: "text",
            },
          ],
        ],
      },
      {
        label: "Corresponding Address",
        name: "correspondingAddress",
        type: "section",
        fields: [
          [
            {
              label: "Country",
              name: "custom_country_correspondence",
              type: "text",
            },
            {
              label: "State",
              name: "custom_state_correspondence",
              type: "text",
            }, 
            {
              label: "City",
              name: "custom_city_correspondence",
              type: "text",
            },
          ], 
          [
            {
              label: "Address Line 1",
              name: "custom_address_line_1_correspondence",
              type: "text",
            },
            {
              label: "Address Line 2",
              name: "custom_address_line_2_correspondence",
              type: "text",
            },
            {
              label: "Pincode",
              name: "custom_pincode_correspondence",
              type: "text",
            },
          ],
        ],
      },
      {
        label: "Permanent Address",
        name: "permanentAddress",
        type: "section",
        fields: [
          [
            {
              label: "Country",
              name: "country",
              type: "text",
            },
            {
              label: "State",
              name: "state",
              type: "text",
            },
            {
              label: "City",
              name: "city",
              type: "text",
            },
            {
              label: "",
              name: "",
              type: "text",
            },
          ],
          [
            {
              label: "Address Line 1",
              name: "address_line_1",
              type: "text",
            },
            {
              label: "Address Line 2",
              name: "address_line_2",
              type: "text",
            },
            {
              label: "Pincode",
              name: "pincode",
              type: "text",
            },
          ],
        ],
      },
      {
        label: "Parent/Guardian - 1 Details",
        name: "parent1Details",
        type: "section",
        fields: [
          [
            {
              label: "Parent/Guardian First Name",
              name: "custom_first_name",
              type: "arrayText",
              arrayField: "guardians",
              index: 0
            },
            {
              label: "Parent/Guardian Last Name",
              name: "custom_last_name",
              type: "arrayText",
              arrayField: "guardians",
              index: 0
            },
            {
              label: "Parent/Guardian Date of Birth",
              name: "date_of_birth",
              type: "arrayDate",
              arrayField: "guardians",
              index: 0
            },
          ],
          [
            {
              label: "Relationship with Candidate",
              name: "relation",
              type: "arrayText",
              arrayField: "guardians",
              index: 0
            },
            {
              label: "Parent/Guardian Contact Number",
              name: "mobile_number",
              type: "arrayText",
              arrayField: "guardians",
              index: 0
            },
            {
              label: "Parent/Guardian Email Address",
              name: "email_address",
              type: "arrayText",
              arrayField: "guardians",
              index: 0
            },
          ],
          [
            {
              label: "Nationality",
              name: "custom_nationality",
              type: "arrayText",
              arrayField: "guardians",
              index: 0
            },
            {
              label: "Highest Level of Education",
              name: "custom_highest_level_of_education",
              type: "arrayText",
              arrayField: "guardians",
              index: 0
            },
            {
              label: "",
              name: "",
              type: "text",
            },
          ],
          [
            {
              label: "Employment Category",
              name: "custom_employment_category",
              type: "arrayText",
              arrayField: "guardians",
              index: 0
            },
            {
              label: "field of Employment",
              name: "custom_field_of_employment",
              type: "arrayText",
              arrayField: "guardians",
              index: 0
            },
            {
              label: "Name of Organisation",
              name: "work_address",
              type: "arrayText",
              arrayField: "guardians",
              index: 0
            },
          ],
          [
            {
              label: "Designation",
              name: "designation",
              type: "arrayText",
              arrayField: "guardians",
              index: 0
            },
            {
              label: "Gross Annural Income (In INR)",
              name: "custom_gross_annual_income_in_inr",
              type: "arrayText",
              arrayField: "guardians",
              index: 0
            },
            {
              label: "",
              name: "",
              type: "text",
            },
          ],
          [
            {
              label: "Guardian details",
              name: "custom_occupation_including_position_and_a_brief_upto_50_words",
              type: "arrayTextArea",
              arrayField: "guardians",
              index: 0
            },
          ]
        ],
      },
      {
        label: "Parent/Guardian - 2 Details",
        name: "parent2Details",
        type: "section",
        fields: [
          [
            {
              label: "Parent/Guardian First Name",
              name: "custom_first_name",
              type: "arrayText",
              arrayField: "guardians",
              index: 1
            },
            {
              label: "Parent/Guardian Last Name",
              name: "custom_last_name",
              type: "arrayText",
              arrayField: "guardians",
              index: 1
            },
            {
              label: "Parent/Guardian Date of Birth",
              name: "date_of_birth",
              type: "arrayDate",
              arrayField: "guardians",
              index: 1
            },
          ],
          [
            {
              label: "Relationship with Candidate",
              name: "relation",
              type: "arrayText",
              arrayField: "guardians",
              index: 1
            },
            {
              label: "Parent/Guardian Contact Number",
              name: "mobile_number",
              type: "arrayText",
              arrayField: "guardians",
              index: 1
            },
            {
              label: "Parent/Guardian Email Address",
              name: "email_address",
              type: "arrayText",
              arrayField: "guardians",
              index: 1
            },
          ],
          [
            {
              label: "Nationality",
              name: "custom_nationality",
              type: "arrayText",
              arrayField: "guardians",
              index: 1
            },
            {
              label: "Highest Level of Education",
              name: "custom_highest_level_of_education",
              type: "arrayText",
              arrayField: "guardians",
              index: 1
            },
            {
              label: "",
              name: "",
              type: "text",
            },
          ],
          [
            {
              label: "Employment Category",
              name: "custom_employment_category",
              type: "arrayText",
              arrayField: "guardians",
              index: 1
            },
            {
              label: "field of Employment",
              name: "custom_field_of_employment",
              type: "arrayText",
              arrayField: "guardians",
              index: 1
            },
            {
              label: "Name of Organisation",
              name: "work_address",
              type: "arrayText",
              arrayField: "guardians",
              index: 1
            },
          ],
          [
            {
              label: "Designation",
              name: "designation",
              type: "arrayText",
              arrayField: "guardians",
              index: 1
            },
            {
              label: "Gross Annural Income (In INR)",
              name: "custom_gross_annual_income_in_inr",
              type: "arrayText",
              arrayField: "guardians",
              index: 1
            },
            {
              label: "",
              name: "",
              type: "text",
            },
          ],
          [
            {
              label: "Guardian details",
              name: "custom_occupation_including_position_and_a_brief_upto_50_words",
              type: "arrayTextArea",
              arrayField: "guardians",
              index: 1
            },
          ]
        ],
      },
      {
        label: "Siblings",
        name: "Siblings",
        type: "section",
        fields: [
          [
            {
              label: "Number of Siblings studying in the same college",
              name: "siblings",
              type: "text",
              dependantField: true,
            },
            {
              label: "",
              name: "",
              type: "text",
            },
            {
              label: "",
              name: "",
              type: "text",
            },
          ],
        ],
      },
    ],
  },
  {
    name: "Academic",
    fields: [
      {
        label: "Education Xth Details",
        name: "educationXth",
        type: "section",
        fields: [
          [
            {
              label: "Class X Board",
              name: "custom_class_x_board",
              type: "text",
            },
            {
              label: "State/Union Territory",
              name: "custom_stateunion_territory",
              type: "text",
            },
            {
              label: "City of School",
              name: "custom_city_of_school",
              type: "text",
            },
          ],
          [
            {
              label: "School Attended",
              name: "custom_school_name_x",
              type: "text",
            },
            {
              label: "Year of Completion",
              name: "custom_year_of_completion",
              type: "text",
            },
            {
              label: "Class X Marks/grade",
              name: "custom_class_x_marksgrade_",
              type: "text",
            },
          ]
        ],
      },
      {
        label: "Education XIth Details",
        name: "educationXIth",
        type: "section",
        fields: [
          [
            {
              label: "Class XI Board",
              name: "custom_class_xi_board",
              type: "text",
            },
            {
              label: "State/Union Territory",
              name: "custom_stateunion_territory_xi",
              type: "text",
            },
            {
              label: "City of School",
              name: "custom_city_of_school_xi",
              type: "text",
            },
          ],
          [
            {
              label: "School Attended",
              name: "custom_school_name_xi",
              type: "text",
            },
            {
              label: "Year of Completion",
              name: "custom_year_of_completion_xi",
              type: "text",
            },
            {
              label: "Class XI Marks/grade",
              name: "custom_class_xi_marksgrade_",
              type: "text",
            },
          ]
        ],
      },
      {
        label: "Education XIIth Details",
        name: "educationXIIth",
        type: "section",
        fields: [
          [
            {
              label: "Class XII Board",
              name: "custom_class_xii_board",
              type: "text",
            },
            {
              label: "State/Union Territory",
              name: "custom_stateunion_territory_copy",
              type: "text",
            },
            {
              label: "City of School",
              name: "custom_city_of_school_copy",
              type: "text",
            },
          ],
          [
            {
              label: "School Attended",
              name: "custom_school_name_xii",
              type: "text",
            },
            {
              label: "Year of Completion",
              name: "custom_year_of_completion_xii",
              type: "text",
            },
            {
              label: "Class XII Marks/grade",
              name: "custom_class_xii_marksgrade_",
              type: "text",
            },
          ]
        ],
      },
      {
        label: "XIIth Details",
        name: "XIIthDetails",
        type: "section",
        fields: [
          [
            {
              headers: [
                {
                  key: "S.No.",
                  value: "idx",
                  width:"5%"
                },
                {
                  key: "Subject",
                  value: "subject",
                  valueText: "subject_text",
                  width:"55%"
                },
                {
                  key: "Marks Obtained",
                  value: "marks",
                  width:"20%"
                },
                {
                  key: "Maximum Marks",
                  value: "maximum_marksgrade",
                  width:"20%"
                },
              ],
              type: "table",
              minRows: 5,
              name: "custom_xii_subject",
            },
          ],
          [
            {
              label: "Have you taken a gap year after Class XII? *",
              type: "radio",
              name: "custom_have_you_taken_a_gap_year_after_class_xii",
              options: ["yes", "no"],
            },
            {
              label: "Are you currently enrolled in a college/University? *",
              type: "radio",
              options: ["yes", "no"],
              name: "custom_are_you_currently_enrolled_in_a_collegeuniversity",
            },
            {
              label: "",
              name: "",
              type: "text",
            }
          ]
        ],
      },
      {
        label: "Other Relevant Information",
        name: "relInfo",
        type: "section",
        fields: [
          [
            {
              label: "How did you hear about IIHT University?",
              name: "custom_how_did_you_hear_about_our_university",
              type: "text",
            },
            {
              label: "",
              name: "",
              type: "text",
            },
            {
              label: "",
              name: "",
              type: "text",
            },
          ],
          [
            {
              label: "Have you applied to IIHT University before? ",
              name: "custom_have_you_applied_our_university_before",
              type: "radio",
              options: ["yes", "no"],
            },
            {
              label: "",
              name: "",
              type: "text",
            },
            {
              label: "",
              name: "",
              type: "text",
            },
          ],
          [
            {
              type: "checkbox",
              label:
                "Have you ever been implicated in any one or more of the following? Please note that the information you provide will be kept confidential and not shared publicly. Selective information shared by students who are enrolled at IIHT University might be shared with specific Departments and Offices within IIHT University on a case-by-case basis for internal official purposes.",
              name: "implication",
              options: [
                {
                  key: "Not Applicable",
                  name: "custom_not_applicable",
                },
                {
                  key: "Disciplinary inquiry leading to suspension or expulsion from any academic programme",
                  value: "custom_inquiry",
                },
                {
                  key: "Disciplinary Status due to substance abuse",
                  value: "custom_disciplinary_action_due_to_substance_abuse",
                },
                {
                  key: "Arrested/Charged or Convicted by a court of law for offenses in India/abroad",
                  value: "custom_arrestedcharged",
                },
              ],
            },
            {
              label: "",
              name: "",
              type: "text",
            },
            {
              label: "",
              name: "",
              type: "text",
            }
          ],
          [
            {
              label:
              "Please provide the relevant details in no more than 100 words. Please attach relevant documents (if any)",
              name: "custom_provide_info",
              type: "textarea",
            },
            {
              label: "",
              name: "",
              type: "text",
            },
            {
              label: "",
              name: "",
              type: "text",
            }
          ]
        ],
      },
      {
        label: "Known Languages",
        name: "custom_known_language",
        type: "section",
        fields: [
          [
            {
              headers: [
                {
                  key: "Language",
                  value: "language",
                },
                {
                  key: "Read",
                  value: "read",
                },
                {
                  key: "Write",
                  value: "write",
                },
                {
                  key: "Speak",
                  value: "speak",
                },
                {
                  key: "Proficiency Level",
                  value: "language_proficiency",
                },
              ],
              type: "table",
              name: "languages",
              minRows: 2, // Minimum number of rows
              name: "custom_known_language",
            },
          ],
        ],
      },
      {
        label: "Course Preference",
        name: "coursePreference",
        type: "section",
        fields: [
          [
            {
              label: "Preference 1",
              name: "custom_preference_1_",
              type: "text",
            },
            {
              label: "Preference 2",
              name: "custom_preference_2_",
              type: "text",
            },
            {
              label: "Preference 3",
              name: "custom_preference_3_",
              type: "text",
            },
          ],
        ],
      },
      {
        label: "Admission Type",
        name: "admissionType",
        type: "section",
        fields: [
          [
            {
              label: "",
              name: "custom_admission_type",
              type: "text",
            },
            {
              label: "",
              name: "",
              type: "text",
            },
            {
              label: "",
              name: "",
              type: "text",
            },
          ],
        ],
      },
      {
        label: "Support At IIHT",
        name: "support",
        type: "section",
        fields: [
          [
            {
              type: "checkbox",
              label:
                "Are you dealing with one or more of the following concerns which have affected your academic or student life and require special assistance? Please fill in the details thoroughly to help us support you during your study at IIHT University.",
              name: "implication",
              options: [
                {
                  key: "Not Applicable",
                  value: "custom_not_applicable1",
                },
                {
                  key: "Disability (Visual, Hearing, Locomotor, Speech/Language, etc)",
                  value:
                    "custom_disability_visual_hearing_locomotor_speechlanguage_etc",
                },
                {
                  key: "Cognitive/Learning Disability",
                  value: "custom_cognitivelearning_disability",
                },
                {
                  key: "Physical Health",
                  value: "custom_physical_health",
                },
                {
                  key: "Mental Health",
                  value: "custom_mental_health",
                },
                {
                  key: "Any other condition that may require special attention",
                  value:
                    "custom_any_other_condition_that_may_require_special_attention",
                },
              ],
            },
          ],
          [
            {
              label:
                "Please provide the relevant details in no more than 100 words. Please attach relevant documents (if any).",
              name: "custom_provide_the_relevant_details_in_no_more_than_100_words",
              type: "textarea",
            }
          ],
          [
            {
              type: "checkbox",
              label:
                "Based on the information shared by you in the previous question, please specify if you need any of the following academic accommodations during the admission process?The Admissions Committee will review the information shared by you in this section. If approved, you will be granted the relevant academic accommodations for the admission process. ",
              name: "implication",
              options: [
                {
                  key: "Not Applicable",
                  value: "custom_not_applicable2",
                },
                {
                  key: "Compensatory/Extra time for persons with physical/learning disabilities/impairments, etc.",
                  value: "custom_compensatoryextra_time",
                },
                {
                  key: "Compensatory/Extra time for people who need English language support",
                  value: "custom_compensatoryextra_time1",
                },
                {
                  key: "Scribe for persons with physical/learning disabilities/impairments, etc.",
                  value: "custom_scribe_for_persons",
                },
                {
                  key: "Other (Please specify)",
                  value: "custom_other_please_specify",
                },
              ],
            },
          ],
          [
            {
              label:
                "Please provide the relevant details in no more than 100 words. Please attach relevant documents (if any). ",
              name: "custom_provide_relevant_details_in_no_more_than_100_words",
              type: "textarea",
            },
          ]
        ],
      },
      {
        label: "Declaration",
        name: "declaration",
        type: "section",
        fields: [
          [
            {
              type: "checkbox",
              label:
                "I certify that the information submitted by me in support of this application, is true to the best of knowledge and belief. I understand that in the event of any information being found false or incorrect, my admission is liable to be rejected / cancelled at any stage of the program. I undertake to abide by the disciplinary rules and regulations of the institute.",
              name: "custom_i_agree",
              options: [
                {
                  key: "I Agree",
                  value: "custom_i_agree",
                },
              ],
            },
          ],
          [
            {
              label: "Applicant Name",
              name: "custom_applicant_name",
              type: "text",
            },
            {
              label: "Date",
              name: "custom_date_",
              type: "date",
            },
            {
              label: "",
              name: "",
              type: "text",
            }
          ],
        ],
      },
    ],
  },
  {
    name: "Attachments",
    fields: [
      {
        fields: [
          [
            {
              type: "component",
              componentToRender: "attachments",
            },
          ]
        ],
      },
    ],
  },
  {
    name: "Emails",
    fields: [
      {
        fields: [
          [
            {
              type: "component",
              componentToRender: "emails",
            },
          ]
        ],
      }
    ],
  },
  {
    name: "Notes",
    fields: [
      {
        fields: [
          [
            {
              type: "component",
              componentToRender: "notes",
            },
          ]
        ],
      },
    ],
  },
  {
    name: "Events",
    fields: [
      {
        fields: [
          [
            {
              type: "component",
              componentToRender: "events",
            },
          ]
        ],
      },
    ],
  },
  {
    name: "Payments",
    fields: [
      {
        fields: [
          [
            {
              type: "component",
              componentToRender: "payment",
            },
          ]
        ],
      },
    ],
  },
];

export default ApplicationDetailsJSON;

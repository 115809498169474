import * as React from "react";
import { IconButton, Typography, Menu, Tooltip, MenuItem, Box } from "@mui/material";
import { ExpandArrow } from "../../../icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const settings = ["Profile", "Logout"];

function ProfileView() {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Open settings">
          <IconButton
            onClick={handleOpenUserMenu}
            sx={{ p: 0 }}
            className="angleDown"
          >
            <Typography textAlign="center" className="profileName">
              {user?.fullName}
            </Typography>
            <ExpandArrow />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settings.map((setting) => (
            <MenuItem
              key={setting}
              onClick={handleCloseUserMenu}
              className="profileMenu"
            >
              <Typography
                textAlign="center"
                className={setting === "Logout" ? "logoutBtn" : ""}
                onClick={() => {
                  if (setting === "Logout") {
                    window.localStorage.removeItem("userDetails");
                    navigate("/login");
                  } else if (setting === "Profile") {
                    navigate("/profile");
                  }
                }}
              >
                {setting}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </>
  );
}
export default ProfileView;

import { CancelIcon, CheckTickIcon, ErrorIcon } from "../icons";
import { theme } from "../themes";

export const accreditationOptions = [
  { key: 0, value: 0, label: "NBA" },
  { key: 1, value: 1, label: "QCI" },
  { key: 2, value: 2, label: "DEC" },
  { key: 3, value: 3, label: "NCTE" },
  { key: 4, value: 4, label: "ICAR" },
  { key: 5, value: 5, label: "BCI" },
  { key: 6, value: 6, label: "SIROs" },
  { key: 7, value: 7, label: "RCI" },
  { key: 8, value: 8, label: "MCI" },
  { key: 9, value: 9, label: "PCI" },
  { key: 10, value: 10, label: "INC" },
  { key: 11, value: 11, label: "ADU" },
  { key: 12, value: 12, label: "NCIE" },
  { key: 13, value: 13, label: "DCI" },
  { key: 14, value: 14, label: "CCH" },
  { key: 15, value: 15, label: "CCIM" },
  { key: 16, value: 16, label: "NAAC" },
  { key: 17, value: 17, label: "MHRD" },
  { key: 18, value: 18, label: "AIU" },
  { key: 19, value: 19, label: "IMU" },
  { key: 20, value: 20, label: "IGNOU" },
];

export const courseLevelOptions = [
  { key: 1, value: 1, label: "Basic" },
  { key: 2, value: 2, label: "Medium" },
  { key: 3, value: 3, label: "Advanced" },
];

export const courseFileTypeOptions = [
  { key: 1, value: 1, label: "CourseImage" },
  { key: 2, value: 2, label: "Syllabus" },
  { key: 3, value: 3, label: "Schema" },
  { key: 4, value: 4, label: "OtherDocument" },
  { key: 5, value: 5, label: "RequestNote" },
  { key: 6, value: 6, label: "DepartmentProfile" },
];

export const courseLanguageOptions = [
  { key: 0, value: 0, label: "English" },
  { key: 1, value: 1, label: "Hindi" },
];

export const totalCreditsOptions = [
  { key: 1, value: 1, label: "Credit 1" },
  { key: 2, value: 2, label: "Credit 2" },
  { key: 3, value: 3, label: "Credit 3" },
];

export const durationOptions = [
  { key: 1, value: 1, label: "1hr" },
  { key: 2, value: 2, label: "2hr" },
  { key: 3, value: 3, label: "3hr" },
  { key: 4, value: 4, label: "4hr" },
  { key: 5, value: 5, label: "5hr" },
  { key: 6, value: 6, label: "6hr" },
  { key: 7, value: 7, label: "7hr" },
  { key: 8, value: 8, label: "8hr" },
  { key: 9, value: 9, label: "9hr" },
  { key: 10, value: 10, label: "10hr" },
];

export const semesterOptions = [
  { key: 1, value: 1, label: "Semester1" },
  { key: 2, value: 2, label: "Semester2" },
  { key: 3, value: 3, label: "Semester3" },
  { key: 4, value: 4, label: "Semester4" },
  { key: 5, value: 5, label: "Semester5" },
  { key: 6, value: 6, label: "Semester6" },
  { key: 7, value: 7, label: "Semester7" },
  { key: 8, value: 8, label: "Semester8" },
];

export const moduleFileTypesEnum = {
  CourseImage: 1,
  Syllabus: 2,
  Schema: 3,
  OtherDocument: 4,
  RequestNote: 5,
  DepartmentProfile: 6,
};
export const courseStatus = {
  Approved: 1,
  Rejected: 2,
  SendForAprroval: 3,
};

export const courseLevelEnum = ["", "Basic", "Medium", "Advanced"];

export const evaluationStatus = {
  0: "Pending",
  1: "Completed",
  2: "Published",
  3: "Pending",
};

export const CourseVisible = {
  All: 1,
  Faculties: 2,
  Students: 3,
};
export const CreationStatus = {
  1: "Incomplete",
  2: "Completed",
  3: "Published",
};

export const submittedStatusEnum = {
  0: "Pending",
  1: "In Progress",
  2: "Pending",
  3: "Completed",
};

export const fileViewerSupportedType = {
  "image/png": [".png"],
  "image/jpeg": [".jpeg", ".jpg"],
  "image/gif": [".gif"],
  "application/pdf": [".pdf"],
  "text/csv": [".csv"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
  "video/mp4": [".mp4"],
  "video/webm": [".webm"],
  "audio/mpeg": [".mp3"],
  "text/plain": [".txt"],
};

export const fileSupportMessage = `Support .png, .jpg, .jpeg, .gif, .txt, .pdf, .csv, .xlsx, .docx, .mp4, .webm and .mp3 formats`;

export const academicYears = [
  { id: 1, key: 1, label: "2021", value: 2021 },
  { id: 1, key: 2, label: "2022", value: 2022 },
  { id: 1, key: 3, label: "2023", value: 2023 },
];

export const evalResultStatus = {
  HODEval: 1,
  HODPending: 2,
  facultyEval: 3,
  facultyPending: 4,
  registrarEval: 5,
  studentEval: 6,
};

export const activitySubmissionStatus = {
  1: "Pending",
  2: "InProgress",
  3: "Submitted",
};

export const feeStatus = {
  10: "Paid",
  20: "Unpaid",
};

export const gendersOptions = [
  {
    id: "1",
    label: "Male",
    key: "1",
    value: "male",
  },
  { id: "2", label: "Female", key: "2", value: "female" },
  { id: "3", label: "Other", key: "3", value: "other" },
];

export const gendersEnumLead = [
  {
    id: "0",
    label: "Male",
    key: "0",
    value: "0",
  },
  { id: "1", label: "Female", key: "1", value: "1" },
];

export const maritalStatusEnumLead = [
  {
    id: "0",
    label: "Unmarried",
    key: "0",
    value: "0",
  },
  { id: "1", label: "Married", key: "1", value: "1" },
  { id: "2", label: "Divorced", key: "2", value: "2" },
];

export const maritialOptions = [
  {
    id: "1",
    label: "Married",
    key: "1",
    value: "married",
  },
  {
    id: "2",
    label: "Un Married",
    key: "2",
    value: "unmarried",
  },
];

export const attendanceTypeActions = [
  { absentAction: false, absentValue: 0 },
  { presentAction: true, presentValue: 1 },
];

export const idTypeOptions = [
  {
    id: "1",
    label: "Aadhaar Card",
    key: "1",
    value: "Aadhaar Card",
  },
  {
    id: "2",
    label: "Pan Card",
    key: "2",
    value: "Pan Card",
  },
];

export const filterOptions = [
  { key: 1, label: "Closed", name: "closed", value: 0 },
  { key: 2, label: "Hot", name: "hot", value: 1 },
  { key: 2, label: "Warm", name: "warm", value: 2 },
  { key: 3, label: "Cold", name: "cold", value: 3 },
  { key: 4, label: "Missed", name: "missed", value: 4 },
];

export const educationOptions = [
  {
    id: "1",
    label: "Schooling",
    key: "1",
    value: "Schooling",
  },
  {
    id: "2",
    label: "Graduation",
    key: "2",
    value: "Graduation",
  },
  {
    id: "3",
    label: "Post Graduation",
    key: "3",
    value: "Post Graduation",
  },
  {
    id: "4",
    label: "PHD",
    key: "4",
    value: "PHD",
  },
];

export const dataGridStyling = {
  "& .MuiDataGrid-columnHeaderTitle": {
    whiteSpace: "normal",
    lineHeight: "normal",
  },
  "& .MuiDataGrid-columnHeader": {
    height: "unset !important",
  },
  "& .MuiDataGrid-columnHeaders": {
    maxHeight: "168px !important",
  },
};

export const leadStatusEnum = {
  0: {
    label: "Closed",
    key: "0",
    value: "0",
    color: theme.palette.grey.P1000,
  },
  1: {
    label: "Hot",
    key: 1,
    value: 1,
    color: theme.palette.common.lead_hot,
  },
  2: {
    label: "Warm",
    key: 2,
    value: 2,
    color: theme.palette.success.light,
  },
  3: {
    label: "Cold",
    key: 3,
    value: 3,
    color: theme.palette.warning.light,
  },
  4: {
    label: "Missed",
    key: 4,
    value: 4,
    color: theme.palette.common.missed,
  },
};

export const leadStatusOptions = [
  {
    id: "1",
    label: "Closed",
    key: "1",
    value: "0",
    color: theme.palette.grey.P1000,
  },
  {
    id: "2",
    label: "Hot",
    key: "2",
    value: "1",
    color: theme.palette.common.lead_hot,
  },
  {
    id: "3",
    label: "Warm",
    key: "3",
    value: "2",
    color: theme.palette.success.light,
  },
  {
    id: "4",
    label: "Cold",
    key: "4",
    value: "3",
    color: theme.palette.warning.light,
  },
  {
    id: "5",
    label: "Missed",
    key: "5",
    value: "4",
    color: theme.palette.common.missed,
  },
];

export const docTypeOptions = [
  {
    id: 0,
    label: "Identity Proof",
    key: 0,
    value: 0,
  },
  {
    id: 1,
    label: "Address Proof",
    key: 1,
    value: 1,
  },
  {
    id: 2,
    label: "DOB Proof",
    key: 2,
    value: 2,
  },
];

export const docIdOptions = [
  {
    id: 0,
    label: "Aadhaar Card",
    key: 0,
    value: 0,
  },
  {
    id: 1,
    label: "PAN Card",
    key: 1,
    value: 1,
  },
  {
    id: 2,
    label: "Driving Licence",
    key: 2,
    value: 2,
  },
  {
    id: 3,
    label: "VoterID",
    key: 3,
    value: 3,
  },
];

export const currencySymbols = {
  INR: "₹",
};

export const counsellorLeadStatus = {
  5: {
    label: "Payment Received",
    key: "0",
    value: "0",
    color: theme.palette.success.light,
  },
  6: {
    label: "Awaiting For Payment",
    key: 6,
    value: 6,
    color: theme.palette.warning.main,
  },
  7: {
    label: "Form Submitted",
    key: 7,
    value: 7,
    color: theme.palette.success.dark,
  },
  8: {
    label: "Form Submission Pending",
    key: 8,
    value: 8,
    color: theme.palette.info.dark,
  },
  9: {
    label: "Sent To Verifier",
    key: 9,
    value: 9,
    color: theme.palette.secondary.text,
  },
  10: {
    label: "Awaiting Form Submission",
    key: 10,
    value: 10,
    color: theme.palette.grey.P1000,
  },
  11: {
    label: "Sent To Registrar",
    key: 11,
    value: 11,
    color: theme.palette.grey.P1000,
  },
  12: {
    label: "Payment Pending",
    key: 12,
    value: 12,
    color: theme.palette.grey.P1000,
  },
};

export const verifierLeadStatusEnum = {
  5: {
    label: "Payment Received",
    key: 5,
    value: 5,
    color: theme.palette.success.light,
  },
  10: {
    label: "Awaiting Form Submission",
    key: 10,
    value: 10,
    color: theme.palette.info.dark,
  },
  11: {
    label: "Sent To Registrar",
    key: 11,
    value: 11,
    color: theme.palette.grey.P1000,
  },
  12: {
    label: "Payment Pending",
    key: 12,
    value: 12,
    color: theme.palette.warning.main,
  },
};

export const prospectusStatus = {
  0: {
    label: "Pending",
    key: 0,
    value: 0,
    color: theme.palette.grey.P1000,
    icon: <></>,
    type: "warning",
  },
  1: {
    label: "Re-submission required",
    key: 1,
    value: 1,
    color: theme.palette.warning.main,
    icon: <ErrorIcon fill={theme.palette.warning.light} width={22} height={22} />,
    type: "warning",
  },
  2: {
    label: "Approved",
    key: 2,
    value: 2,
    color: theme.palette.success.light,
    icon: <CheckTickIcon fill={theme.palette.success.light} width={22} height={22} />,
    type: "success",
  },
  3: {
    label: "Rejected",
    key: 3,
    value: 3,
    color: theme.palette.error.main,
    icon: <CancelIcon fill={theme.palette.error.light} width={22} height={22} />,
    type: "error",
  },
};

export const feesPaymentStatus = {
  0: {
    label: "Payment Pending",
    key: 0,
    value: 0,
    color: theme.palette.warning.light,
    icon: <ErrorIcon fill={theme.palette.warning.light} width={22} height={22} />,
    type: "warning",
  },
  1: {
    label: "Payment Received",
    key: 1,
    value: 1,
    color: theme.palette.success.light,
    icon: <CheckTickIcon fill={theme.palette.success.light} width={22} height={22} />,
    type: "success",
  },
  2: {
    label: "Awaiting for Payment",
    key: 2,
    value: 2,
    color: theme.palette.error.main,
    icon: <CancelIcon fill={theme.palette.error.light} width={22} height={22} />,
    type: "error",
  },
};

export const formFiedTypes = {
  Text: "text",
  ShortText: "short_text",
  LongText: "long_text",
  DatePicker: "datepicker",
  Checkbox: "checkbox",
  Radio: "radio",
  Select: "select",
  FileUpload: "file_upload",
  Matrix: "matrix",
};

export const admissionFormQuestionOptions = [
  {
    key: "Short",
    value: "short_text",
    label: "Short Text",
  },
  {
    key: "Long",
    value: "long_text",
    label: "Long Text",
  },
  // {
  //   key: 'Dropdown',
  //   value: 'select',
  //   label: 'Dropdown',
  // },
  {
    key: "Checkboxes",
    value: "checkbox",
    label: "Checkboxes",
  },
  {
    key: "radioButtons",
    value: "radio",
    label: "Multiple Choice",
  },
  {
    key: "Date",
    value: "datepicker",
    label: "Date",
  },
  {
    key: "Email",
    value: "text",
    label: "Email",
  },
  {
    key: "fileUpload",
    value: "file_upload",
    label: "Upload File",
  },
  {
    key: "matrix",
    value: "matrix",
    label: "Matrix",
  },
];

export const academicYearRangeOptios = [
  {
    key: 0,
    label: "AY 2022-23",
    value: "2022-2023",
  },
];

export const drawerEnum = {
  lead_generated: "Lead Generated",
  counselling: "Counselling",
  document_verification: "Document Verification",
  student_onboarded: "Student Onboarded",
  form_submitted: "Form Submitted",
  onboarding_started: "Onboarding Started",
  inProgress: "In-progress",
  done: "Done",
};

export const programTypeOptions = [
  { key: 0, value: 0, label: "Full time" },
  { key: 1, value: 1, label: "Part time" },
  { key: 2, value: 2, label: "Online" },
];

export const programLevelOptions = [
  { key: 0, value: 0, label: "Diploma" },
  { key: 1, value: 1, label: "Bachelors Degree" },
  { key: 2, value: 2, label: "Masters Degree" },
  { key: 3, value: 3, label: "MOOCs" },
];

export const programYearOptions = [
  { key: 1, value: 1, label: "First Year" },
  { key: 2, value: 2, label: "Second Year" },
  { key: 3, value: 3, label: "Third Year" },

  { key: 4, value: 4, label: "Fourth Year" },
  { key: 5, value: 5, label: "Fifth Year" },
  { key: 6, value: 6, label: "Sixth Year" },

  { key: 7, value: 7, label: "Seventh Year" },
  { key: 8, value: 8, label: "Eight Year" },
  { key: 9, value: 9, label: "Ninth Year" },
];

export const facultyModals = {
  liveSession: "LiveSession",
  reading: "Reading",
};

export const role = {
  Student: 0,
  Faculty: 1,
  HOD: 2,
};

export const PointsCalBasedOn = {
  0: "Highest Attempt",
  1: "Average of all Attempts",
  2: "Last Attempts",
};

export const ResultFormat = {
  0: "All Questions with correct answers",
  1: "All Questions with correct incorrect answers",
};

export const AssignmentType = {
  0: "Upload",
  1: "Online",
};

export const FilesAllowPerSubmission = {
  0: "One File",
  1: "Multiple Files",
};

export const SubmissionType = {
  0: "Online file submission",
  1: "On Paper Submission",
  2: "Observed in Person",
};

export const SubmissionAssignmentType = {
  0: "Individual Assignment",
  1: "Group Assignment",
};

export const AllowableFileExtension = {
  0: "No Restriction",
  1: "PDF Only",
  2: "Images and Videos",
};

export const Submissions = {
  0: "All Submissions",
  1: "Only One",
  2: "Recent Submissions",
};

export const exam_typ_enum = [
  { key: 0, value: 0, label: "Mid Sem Test" },
  { key: 1, value: 1, label: "End Semester Examination" },
];

export const evaluation_metadata_enum = [
  { key: 0, value: 0, label: "Unit Test" },
  { key: 1, value: 1, label: "Attendance" },
  { key: 2, value: 2, label: "CBC" },
  { key: 3, value: 3, label: "Other" },
];

export const evaluationTypeEnum = [
  { key: 0, value: 0, label: "Theory" },
  { key: 1, value: 1, label: "Practical" },
];

export const internalExamTypeEnum = [
  {
    value: "online",
    label: "Online",
  },
  { value: "upload", label: "Upload" },
  { value: "quiz", label: "Quizes" },
];

export const examType = {
  0: "Online",
  1: "Upload",
  2: "Quizes",
  3: "Practical",
};

export const internalExamTypes = {
  online: 0,
  upload: 1,
  quiz: 2,
  practical: 3,
};

export const examStatus = {
  0: "Published",
  1: "Draft",
  2: "Not Created",
};

export const theoryExamType = {
  0: "online",
  1: "upload",
  2: "quiz",
};

//Temporarily added
export const rubricConfig = [
  {
    id: "7548d4e8-404a-4232-a5bf-6dda86fe8fcb",
    title: "Rubric",
    tenant: "4286717b-f6a6-4150-be8a-89d1537451a1",
    program: "ac9f952d-e6e8-42e4-8047-291db4abae17",
    rubric_type: 1,
    description: "Descrption",
  },
];

export const examTypeEnum = [
  { id: 0, key: 0, value: 0, label: "Theory" },
  { id: 1, key: 1, value: 1, label: "Practical" },
  { id: 2, key: 2, value: 2, label: "Not Created" },
];

export const examStatusEnum = [
  { id: 0, key: 0, value: 0, label: "Published" },
  { id: 1, key: 1, value: 1, label: "Draft" },
  { id: 2, key: 2, value: 2, label: "Not Created" },
];

export const examMethodConstant = [
  { id: 0, key: 0, value: 0, label: "Online" },
  { id: 1, key: 1, value: 1, label: "Upload" },
  { id: 2, key: 2, value: 2, label: "Quiz" },
  { id: 3, key: 3, value: 3, label: "Practical" },
];

export const evaluation_status_enum = [
  { id: 0, key: 0, value: 0, label: "Absent" },
  { id: 1, key: 1, value: 1, label: "Pending" },
  { id: 2, key: 2, value: 2, label: "Complete" },
  { id: 3, key: 3, value: 3, label: "Recheck Pending" },
];

export const submission_type_enum = [
  { id: 0, key: 0, value: 0, label: "Absent" },
  { id: 1, key: 1, value: 1, label: "Submitted" },
  { id: 2, key: 2, value: 2, label: "Pending" },
];

export const EVALUATION_STATUS_ENUM = {
  0: "Absent",
  1: "Pending",
  2: "Completed",
  3: "Re-check Pending",
  4: "Re-checking Complete",
  5: "Re-appear",
  6: "Exempt",
};

export const SUBMISSION_TYPE_ENUM = {
  0: "Absent",
  1: "Submitted",
};

export const MOOC_ADMIN_COURSE_TYPE = {
  affiliate: "affiliate",
  publish: "publish",
  enroll: "enroll",
  lpType: "learning_path_course",
};

export const MOOC_ADMIN_COURSES_ADVANCED_FILTERS = [
  {
    id: 1,
    key: 1,
    identity: "Trending",
    label: "Trending",
    value: "trending_courses",
  },
  {
    id: 2,
    key: 2,
    identity: "Recently Published",
    label: "Recently Published",
    value: "recently_published",
  },
  {
    id: 3,
    key: 3,
    identity: "Best Selling",
    label: "Best Selling",
    value: "best_selling",
  },
  {
    id: 4,
    key: 4,
    identity: "Free",
    label: "Free",
    value: "free",
  },
];

export const myLabsOpsConstants = {
  toEnableLabStart: [26, 30, 32, 27],
  toEnableSuspend: [28, 31],
  toEnableStop: [28, 32, 29],
  toEnableConnect: [28, 29, 30, 31, 32],
  toEnableReset: [28],
  toEnableShutdownGuestOs: [28, 35],
  toEnableRestartGuestOs: [28, 34],
};
export const selectSessionOptions = [
  { key: 0, value: 0, label: "Winter" },
  { key: 1, value: 1, label: "Fall" },
  { key: 2, value: 2, label: "Summer" },
  { key: 3, value: 3, label: "Odd" },
  { key: 4, value: 4, label: "Even" },
  { key: 5, value: 5, label: "Autumn" },
];

export const category = [
  { id: "0", label: "General", key: "0", value: "General" },
  { id: "1", label: "OBC", key: "1", value: "obc" },
  { id: "2", label: "SC", key: "2", value: "sc" },
  { id: "3", label: "ST", key: "3", value: "st" },
];

export const REPORT_RADIO_OPTIONS = [
  {
    id: 0,
    label: "Spam",
    value: 0,
  },
  {
    id: 1,
    label: "Hate Speech",
    value: 1,
  },
  {
    id: 2,
    label: "Harassment and bullying",
    value: 2,
  },
  {
    id: 3,
    label: "Harmful activities",
    value: 3,
  },
  {
    id: 4,
    label: "Sexual exploitation and abuse (child, adults and animals)",
    value: 4,
  },
];

export const PROGRAM_OUTCOMES_OPTIONS = [
  {
    id: "peo",
    key: 5,
    title: "Program Educational Objectives (PEO)",
    label: "PEO",
  },
  {
    id: "po",
    key: 6,
    title: "Program Outcomes (PO)",
    label: "PO",
  },
  {
    id: "pso",
    key: 7,
    title: "Program Specific Outcomes (PSO)",
    label: "PSO",
  },
  {
    id: "cl",
    key: 8,
    title: "Competency Levels (CL)",
    label: "CL",
  },
  {
    id: "pi",
    key: 9,
    title: "Performance Indicators (PI)",
    label: "PI",
  },
  // {
  //   id: 'co',
  //   key: 10,
  //   title: 'Course Outcomes (CO)',
  //   label: 'CO',
  // },
];
export const VISION_AND_MISSION_OPTIONS = [
  {
    id: "UV",
    key: 1,
    title: "University Vision",
    label: "UV",
  },
  {
    id: "UM",
    key: 2,
    title: "University Mission",
    label: "UM",
  },
  {
    id: "DV",
    key: 3,
    title: "Department Vision",
    label: "DV",
  },
  {
    id: "DM",
    key: 4,
    title: "Department Mission",
    label: "DM",
  },
];

export const genericDataActionOptions = [
  { label: "Edit", id: "edit", key: "edit" },
  { label: "Export", id: "export", key: "export" },
  { label: "Delete", id: "delete", key: "delete" },
];

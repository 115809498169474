import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import { Table } from "antd";
import { toast } from "react-toastify";
import moment from "moment";
import {
  Button,
  Stack,
  Typography,
} from "iiht-b2a-ui-components/build/components";
import CommonFilters from "components/Filters/CommonFilters";
import SavedFilters from "components/Filters/SavedFilters";
import { CustomBreadcrumb, MainLayout } from "components/common";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import {
  exportGenericData,
  getGenericListApi,
  updateGenericdataInBulk,
} from "modules/hr";
import { setLoading } from "slices/commonSlice";
import { ActionFilters } from "components/Filters";
import BulkEdit from "components/common/BulkEdit";
import APIs from "api";
import ActionExport from "components/Filters/ActionExport";

const SkillsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [editModal, setEditModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [skillsList, setSkillsList] = useState([]);
  const { doctype, filters } = useSelector((state) => state.filters);
  const { loading } = useSelector((state) => state.common);
  const breadcrumbs = [
    {
      label: "IIHT",
      link: "/dashboard",
    },
    {
      label: "HR",
      link: "/modules/hr",
    },
    {
      label: "Recruitment",
      link: "/modules/hr",
    },
    {
      label: "Masters",
      link: "/modules/hr/masters",
    },
    {
      label: "Skills",
      link: "",
    },
  ];

  const columns = [
    {
      dataIndex: "skill_name",
      title: "Skill",
      width: "30%",
    },
    {
      dataIndex: "description",
      title: "Description",
      width: "40%",
    },
    {
      dataIndex: "skill_name",
      title: "Action",
      width: "15%",
      render: (val, row) => {
        return (
          <NavLink
            color="secondary"
            variant="body2"
            to={`/modules/hr/masters/skills/edit-view/${row?.id}`}
            className="mouse-pointer qa-view-all view-all"
          >
            View
          </NavLink>
        );
      },
    },
  ];

  const actionOptions = [
    { label: "Edit", id: "edit", key: "edit" },
    { label: "Export", id: "export", key: "export" },
  ];

  const handleBulkEdit = async ({ customfieldname, value }) => {
    const payload = {
      doctype: "Skill",
      docnames: selectedIds,
      customfieldname,
      value,
    };
    dispatch(setLoading({ loading: true }));
    setEditModal(false);
    const res = await updateGenericdataInBulk(payload);
    if (res?.status === 200) {
      setSelectedIds([]);
      setSelectedRowKeys([]);
      toast.success(res.data.message);
      fetchSkillsList();
    } else {
      toast.error(res.data?.exc_type);
    }
    dispatch(setLoading({ loading: false }));
  };

  const handleBulkExport = async () => {
    dispatch(setLoading({ loading: true }));
    let exportIds = [];
    for (let i = 0; i < selectedIds.length; i++) {
      exportIds.push({
        doctype: "Skill",
        id: selectedIds[i],
      });
    }

    const payload = { export: [...exportIds] };
    const response = await fetch(APIs.bulkActionExport, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      dispatch(setLoading({ loading: false }));
      setSelectedIds([]);
      setSelectedRowKeys([]);
      toast.success("Data exported!");
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Skill.xlsx");
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error("Something went wrong");
      dispatch(setLoading({ loading: false }));
    }
  };
  const handleAction = (option) => {
    if (option.id === "edit") {
      setEditModal(true);
    } else if (option.id === "export") {
      handleBulkExport();
    }
  };

  const onSelectChange = (selectedKeys, selectedRowData) => {
    setSelectedRowKeys(selectedKeys);
    let selectedDataIds = [];
    selectedRowData.map((rowData) => selectedDataIds.push(rowData.id));
    setSelectedIds([...selectedDataIds]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      id: record.id,
    }),
  };

  const fetchSkillsList = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Skill",
      fields: JSON.stringify(["*"]),
      filters: doctype === "Skill" ? JSON.stringify(filters).slice(1, -1) : [],
      limit: "*",
    };
    const res = await getGenericListApi(params);
    if (res?.status === 200) {
      if (res.data?.message?.length) {
        const data = res.data.message.map((skill, i) => {
          return {
            ...skill,
            key: i,
          };
        });
        setSkillsList(data);
      } else {
        setSkillsList([]);
      }
    }
    dispatch(setLoading({ loading: false }));
  };

  useEffect(() => {
    fetchSkillsList();
  }, [filters]);

  return (
    <MainLayout>
      {loading && <LoadingBackdrop open={loading} />}
      <div>
        <CustomBreadcrumb breadcrumbs={breadcrumbs} />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <GridMenuIcon className="gridIcon" />
            <Typography color="#0D1021" fontSize={22}>
              Skills
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <CommonFilters doctype="Skill"></CommonFilters>
            <SavedFilters doctype="Skill"></SavedFilters>
            {selectedRowKeys.length > 0 && (
              <ActionExport
                options={actionOptions}
                disabled={!selectedRowKeys.length}
                onChange={handleAction}
              />
            )}{" "}
            {!selectedRowKeys.length && (
              <Button
                className="qa-sign-in-button"
                variant="contained"
                color="primary"
                onClick={() => navigate("/modules/hr/masters/skills/create")}
              >
                Add Skill
              </Button>
            )}
          </Stack>
        </Stack>

        <div className="mt4">
          <Table
            rowSelection={{ type: "checkbox", ...rowSelection }}
            columns={columns}
            dataSource={skillsList}
            size="small"
            pagination={true}
          />
        </div>

        {editModal && (
          <BulkEdit
            handleBulkEdit={handleBulkEdit}
            doctype="Skill"
            isEditOpen={editModal}
            setIsEditOpen={setEditModal}
            items={selectedIds.length}
          />
        )}
      </div>
    </MainLayout>
  );
};

export default SkillsList;

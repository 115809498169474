import React from 'react';
import { useTranslation } from 'react-i18next';
import Divider from 'iiht-b2a-ui-components/build/components/Divider';
import { useTheme } from 'iiht-b2a-ui-components/build/components/Styles';
import Typography from 'iiht-b2a-ui-components/build/components/Typography';
import Container from 'iiht-b2a-ui-components/build/components/Container';
import './footer.scss';

const FooterView = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <footer>
      <Container className="footer" maxWidth="xl">
        <Divider sx={{ m: 0 }} variant="middle" />
        <Typography
          sx={{ mt: 0.5 }}
          color={theme.palette.grey.P600}
          display="flex"
          variant="caption"
        >
          {t('b2aFooterText')}
        </Typography>
      </Container>
    </footer>
  );
};

export default FooterView;

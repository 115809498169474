import React, { useEffect, useState } from 'react';
import useStyles from './style';
import { useNavigate, useParams } from 'react-router-dom';
import { GridMenuIcon } from '@mui/x-data-grid';
import { setLoading } from 'slices/commonSlice';
import { useDispatch, useSelector } from "react-redux";
import LoadingBackdrop from "../../../common/LoadingBackdrop.js";
import { getStudentDetailsById, getApplicationDetailsById } from 'modules/admission.js';
import { setActiveSidebar } from 'slices/financeSlice';
import MainHeader from 'components/common/MainHeader';
import MainFooter from 'components/common/MainFooter';
import StudentDetailsJSON from './details';
import { formatDateTODDMMMYYYY } from 'utils/date';
import "./style.css";
 
const TextBox = ({ field, data }) => {
  const styles = useStyles();
  const { label, name } = field;
  const details = data.applicationDetails;
  return (
    <div className={styles.textField}>
      <p className={styles.labelText}>{label}</p>
      <p className={styles.labelValue}>{details[name] ? details[name] : label && "-"}</p>
    </div>
  );
};

const ArrayTextBox = ({ field, data }) => {
  const styles = useStyles();
  const { label, name, arrayField, index } = field;
  const details = data.applicationDetails[arrayField];
  const value = details[index];
  return (
    <div className={styles.textField}>
      <p className={styles.labelText}>{label}</p>
      <p className={styles.labelValue}>{value[name] ? value[name] : label && "-"}</p>
    </div>
  );
};

const ArrayTextArea = ({ field, data }) => {
  const styles = useStyles();
  const { label, name, arrayField, index } = field;
  const details = data.applicationDetails[arrayField];
  const value = details[index];
  return (
    <div className={styles.textAreaField}>
      <p className={styles.labelText}>{label}</p>
      <p className={styles.textAreaLabelValue}>{value[name] ? value[name] : label && "-"}</p>
    </div>
  );
};

const ArrayDate = ({ field, data }) => {
  const styles = useStyles();
  const { label, name, arrayField, index  } = field;
  const details = data.applicationDetails[arrayField];
  const value = details[index];
  return (
    <div className={styles.textField}>
      <p className={styles.labelText}>{label}</p>
      <p className={styles.labelValue}>{value[name] ? formatDateTODDMMMYYYY(value[name]) : label && "-"}</p>
    </div>
  );
};

const ProgramTextBox = ({ field, data }) => {
  const styles = useStyles();
  const { label, name } = field;
  const details = data.studentDetails;
  return (
    <div className={styles.textField}>
      <p className={styles.labelText}>{label}</p>
      <p className={styles.labelValue}>{details[name] ? details[name] : label && "-"}</p>
    </div>
  );
};

const Image = ({ field, data }) => {
  const styles = useStyles();
  const { label, name } = field;
  const details = data.applicationDetails;
  return (
    <div className={styles.textField}>
      <p className={styles.labelText}>{label}</p>
      <img
        src={`https://erp-dev.techademycampus.com${details[name]}`}
        alt={label}
        style={{ width: "150px", height: "150px" }}
      />
    </div>
  );
};

const Date = ({ field, data }) => {
  const styles = useStyles();
  const { label, name } = field;
  const details = data.applicationDetails;
  return (
    <div className={styles.textField}>
      <p className={styles.labelText}>{label}</p>
      <p className={styles.labelValue}>{details[name] ? formatDateTODDMMMYYYY(details[name]) : label && "-"}</p>
    </div>
  );
};

const EncryptedTextBox = ({ field, data }) => {
  const styles = useStyles();
  const { label, name } = field;
  const details = data.applicationDetails;
  return (
    <div className={styles.textField}>
      <p className={styles.labelText}>{label}</p>
      <p className={styles.labelValue}>{details[name] ? `********${details[name].substr(-4)}` : ""}</p>
    </div>
  );
};

const Table = ({ field, data }) => {
  const styles = useStyles();
  const { name, headers } = field;
  const rows = data.applicationDetails[`${name}`];

  return (
    <div className="formTableWrap">
      <table className="formTable docs_table">
        <thead>
          <tr className={styles.tableRow}>
            {rows.length > 0 &&
              headers.map((header) => (
                <td key={header.key} className={styles.tableHeaderTitle} style={{ width: header.width }}>
                  {header.key}
                </td>
              ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((rowValue, idx) => (
            <tr key={idx} className={styles.tableRow}>
              {headers.map((header) => (
                <td key={header.key} style={{ width: header.width }}>
                    <p className={styles.tableContent}>
                      {
                        rowValue[header.value] == "Others"
                          ? rowValue[header.valueText]
                          : rowValue[header.value] || ""
                      }
                    </p>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Attachments = ({  data }) => {
  const styles = useStyles();
  const baseUrl = "https://erp-dev.techademycampus.com";
  const details = data.applicationDetails;
  const attachments = [
    { key: "custom_attach_aadhar_card", label: "Aadhaar card", documentStatusKey: "custom_aadhar_card_status" },
    { key: "custom_attach_relevant_document", label: "Relevant Document", documentStatusKey: "custom_relevant_document_status" },
    {
      key: "custom_if_any_please_attach_relevant_document",
      label: "Relevant Document2",
      documentStatusKey: "custom_health_status"
    },
    { key: "custom_attach_xiith_marksheet", label: "XIIth Marksheet", documentStatusKey: "custom_xiith_marksheet_status"},
    { key: "custom_attach_xith_marksheet", label: "XIth Marksheet", documentStatusKey: "custom_xith_marksheet_status" },
    { key: "custom_attach_xth_marksheet", label: "Xth Marksheet", documentStatusKey: "custom_xth_marksheet_status" },
    { key: "custom_upload_student_image", label: "Student Photo", documentStatusKey: "custom_student_image_status" },
    { key: "custom_attachment_info", label: "Custom Attachment", documentStatusKey: "custom_disciplinary_inquiry_status" },
  ];

  const extractFileName = (path) => {
    if (path) {
      const parts = path.split("/");
      return parts[parts.length - 1];
    } else {
      return "N/A";
    }
  };

  return (
    <div className="formTableWrap">
        <table className="formTable docs_table">
          <thead>
            <tr>
              <td className={styles.tableHeaderTitle}>Attachment</td>
              <td className={styles.tableHeaderTitle}>Attached File</td>
            </tr>
          </thead>
          <tbody>
            {attachments.map((attachment, index) => {
             return  (
              <>
                {
                  details[attachment.key] &&  
                  <tr key={index}>
                    <td className={styles.attachmentTableContent}>
                      <p className={styles.tableContent}>{attachment.label}</p>
                    </td>
                    <td className={styles.link}>
                      {details[attachment.key] && (
                        <a
                          href={`${baseUrl}${details[attachment.key]}`}
                          download={extractFileName(details[attachment.key])}
                          target={`_blank`}
                        >
                          <p className={styles.tableContent}>{extractFileName(details[attachment.key])}</p>
                        </a>
                      )}
                    </td>
                  </tr>
                }
              </>
             )
            })}
          </tbody>
        </table>
      </div>
  )
}

const ProgramTable = ({ field, data }) => {
  const styles = useStyles();
  const { name, headers } = field;
  const rows = data.studentDetails[`${name}`];

  return (
    <div className="formTableWrap">
      <table className="formTable docs_table">
        <thead>
          <tr className={styles.tableRow}>
            {rows.length > 0 &&
              headers.map((header) => (
                <td key={header.key} className={styles.tableHeaderTitle} style={{ width: header.width }}>
                  {header.key}
                </td>
              ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((rowValue, idx) => (
            <tr key={idx} className={styles.tableRow}>
              {headers.map((header) => (
                <td key={header.key} style={{ width: header.width }}>
                    <p className={styles.tableContent}>
                      {
                        rowValue[header.value] == "Others"
                          ? rowValue[header.valueText]
                          : rowValue[header.value] || ""
                      }
                    </p>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const StudentDetails = () => {
  const styles = useStyles();
  const navigate= useNavigate();
  const dispatch = useDispatch();
  const { name } = useParams();
  const [currentTab, setCurrentTab] = useState(StudentDetailsJSON[0].name);
  const [content, setContent] = useState(StudentDetailsJSON[0].fields);
  const [applicationDetails, setApplicationDetails] = useState("");
  const [studentDetails, setStudentDetails] = useState("");
  const { loading } = useSelector((state) => state.common);

  const handleNavigation = () => {
    dispatch(setActiveSidebar({ activeSidebar: 4 }));
    navigate("/modules/admission");
  };

  const handleSetFields = (data) => {
    setCurrentTab(data.name);
    setContent(data.fields);
  }

  const handleGetStudentDetails = async () => {
    dispatch(setLoading({ loading: true }));
    const res = await getStudentDetailsById({ name });
    if (res.status === 200) {
      let data = res?.data?.data;
      setStudentDetails(data);
      handleGetApplicationDetails({ name: data.student_applicant })
    } else {
      dispatch(setLoading({ loading: false }));
    }
  };

  const handleGetApplicationDetails = async ({ name }) => {
    dispatch(setLoading({ loading: true }));
    const res = await getApplicationDetailsById({ name });
    if (res.status === 200) {
      let data = res?.data?.message;
      setApplicationDetails(data);
      dispatch(setLoading({ loading: false }));
    } else {
      dispatch(setLoading({ loading: false }));
    }
  };

  useEffect(() => {
    handleGetStudentDetails();
  }, []);

  return (
   <>
    <MainHeader />
    {
      loading ?
       <LoadingBackdrop open={loading} /> :
       <div className={styles.mainContainer}>
        <div className={styles.breadCrumbsContainer}>
          <p className={styles.breadCrumbsContent} onClick={() => navigate("/dashboard")}>IIHT {" > "}</p>
          <p className={styles.breadCrumbsContent} onClick={() => navigate("/modules/admission")}>Admission {" > "}</p>
          <p className={styles.breadCrumbsContent} onClick={handleNavigation}>Student Management {" > "}</p>
          <p className={styles.breadCrumbsContent}>{name}</p>
        </div>
        <div className={styles.headerContainer}>
          <div className={styles.breadCrumbsContainer}>
            <GridMenuIcon className={styles.gridIcon}/>
            <p className={styles.title}>{studentDetails.student_name}</p>
          </div>
          <div className={styles.buttonsGroup}>
            <div className={styles.button} onClick={handleNavigation}>
              <p>Back</p>
            </div>
          </div>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.tabContainer}>
            {StudentDetailsJSON.map((data) => {
              return (
                <div
                  type="button"
                  key={data.name}
                  className={currentTab === data.name ? styles.tabTitleActive : styles.tabTitleInActive}
                  onClick={() => handleSetFields(data)}
                >
                  {data.name}
                </div>
              )})}
          </div>
          <div className={styles.detailsContainer}>
            {
              content.map((details) => {
                const { label, rows } = details;
                return (
                  <div className={styles.sectionContainer}>
                    <div className={styles.sectionHeader}>
                      {label}
                    </div>
                    <div className={styles.container}>
                      {
                        rows.map((row, i) => {
                          return (
                            <div className={styles.rowContainer} style={{ marginTop: currentTab === "Personal" && i === 1 ? "-100px" : "" }}>
                            {
                              row.map((field) => {
                                return (
                                  <>
                                    {renderFieldByType({ field, data: { "applicationDetails": applicationDetails, "studentDetails":  studentDetails }})}
                                  </>
                                )
                              })
                            }
                          </div>
                          )
                        })
                      }
                    </div>
                  </div>
              )})
            }
          </div>
        </div>
      </div>
    }
    <MainFooter />
   </>
  )
}

const renderFieldByType = ({field, data}) => {
  switch (field.type) {
    case "text":
      return <TextBox field={field} data={data} />;
    case "image":
      return <Image field={field} data={data} />;
    case "date":
      return <Date field={field} data={data} />;
    case "encrypted":
      return <EncryptedTextBox field={field} data={data} />;
    case "table":
      return <Table field={field} data={data} />;
    case "attachments": 
      return <Attachments data={data} />;
    case "programTable":
      return <ProgramTable field={field} data={data} />;
    case "programText":
      return <ProgramTextBox field={field} data={data} />;
    case "arrayText": 
      return <ArrayTextBox field={field} data={data} />;
    case "arrayDate":
      return <ArrayDate field={field} data={data} />;
    case "arrayTextArea": 
      return <ArrayTextArea field={field} data={data} />;
  }
}

export default StudentDetails;
// Enrollment.js
import { Box, Button } from "@mui/material";
import { GridMenuIcon } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { MainLayout } from "components/common";
import { useNavigate } from "react-router-dom";
import useStyles from "./style";
import { Select, Table, Input } from "antd";
import {
  getAcademicTermListing,
  getBatchListing,
  getProgramListing,
} from "../../../../modules/finance";
import {
  enrollStudents,
  getEntityList,
  getStudentsInEnrollment,
} from "modules/masters";
import ConfirmationDialog from "components/common/confirmation";
import eventBus from "../../../../core/eventBus";
import { toast } from "react-toastify";

const BulkStudentEnrollment = () => {
  const [breadcrumbs, setBreadcrumbs] = useState([
    { label: "IIHT", url: "/dashboard" },
    { label: "Academics", url: "/modules/academics" },
    {
      label: "Bulk-Student-Enrollment",
      url: "/modules/academics/enrollment/bulk-student-enrollment",
    },
  ]);
  const [getStudentFromOptions, setGetStudentFromOptions] = useState([
    { label: "Programme Enrolled", url: "Programme Enrolled" },
    { label: "Admitted Students", value: "Admitted Students" },
  ]);

  const [programOptions, setProgramOptions] = useState([]);
  const [selectedProgramOptions, setSelectedProgramOptions] = useState([]);
  const [selectedStudentFromOptions, setSelectedStudentFromOptions] = useState(
    []
  );
  const [academicTermOptions, setAcademicTermOptions] = useState([]);
  const [selectedAcademicTermOptions, setSelectedAcademicTermOptions] =
    useState([]);
  const [batchOptions, setBatchOptions] = useState([]);
  const [selectedBatchOptions, setSelectedBatchOptions] = useState([]);
  const [studentData, setStudentData] = useState();
  const [studentSelectedRowKeys, setStudentSelectedRowKeys] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  const selectSize = "middle";
  const headers = [
    {
      title: "Student ID",
      dataIndex: "studentId",
      width: "20%",
    },
    {
      title: "Student Name",
      dataIndex: "studentName",
      width: "20%",
    },
    {
      title: "Programme Type",
      dataIndex: "programmeType",
      width: "20%",
    },
    {
      title: "Programme",
      dataIndex: "programme",
      width: "20%",
    },
    {
      title: "Status",
      dataIndex: "customStudentType",
      width: "20%",
    },
  ];
  const navigate = useNavigate();
  const styles = useStyles();

  const Breadcrumbs = () => {
    return (
      <div className="breadcrumbs">
        {breadcrumbs.map((breadcrumb, index) => (
          <React.Fragment key={index}>
            {index > 0 && <span className="separator"> &gt; </span>}
            <div
              onClick={() => {
                navigate(breadcrumb.url);
              }}
              style={{ display: "inline-flex", cursor: "pointer" }}
            >
              {breadcrumb.label}
            </div>
          </React.Fragment>
        ))}
      </div>
    );
  };

  const onStudentSelectChange = (selectedKeys) => {
    setStudentSelectedRowKeys(selectedKeys);
  };

  const studentRowSelection = {
    type: "checkbox",
    onChange: onStudentSelectChange,
    getCheckboxProps: (record) => ({
      student: record.student,
    }),
    studentSelectedRowKeys,
  };

  const handleBackButtonClick = () => {
    navigate(-1); // Use navigate to go back to the previous page
  };

  const handleGetProgramListing = async () => {
    const res = await getProgramListing();
    if (res.status === 200) {
      const arr = [];
      const programList = res.data.data;
      for (let i = 0; i < programList?.length; i++) {
        arr.push({
          value: programList[i].name,
          label: programList[i].name,
        });
      }
      setProgramOptions(arr);
    }
  };

  const handleGetAcademicTermListing = async () => {
    const res = await getAcademicTermListing();
    if (res.status === 200) {
      const arr = [];
      const acdemicTermList = res.data.data;
      for (let i = 0; i < acdemicTermList?.length; i++) {
        arr.push({
          value: acdemicTermList[i].name,
          label: acdemicTermList[i].name,
        });
      }
      setAcademicTermOptions(arr);
    }
  };

  const handleGetBatchListing = async () => {
    try {
      const res = await getBatchListing();
      if (res.status === 200) {
        const arr = [];
        const acdemicTermList = res.data.data;
        for (let i = 0; i < acdemicTermList?.length; i++) {
          arr.push({
            value: acdemicTermList[i].name,
            label: acdemicTermList[i].name,
          });
        }
        setBatchOptions(arr);
      }
    } catch (error) {
      console.log(error, "Something went wrong");
    }
  };

  const handleGetStudents = async () => {
    try {
      const res = await getStudentsInEnrollment(
        selectedStudentFromOptions === "Admitted Students"
          ? "Admitted"
          : "Enrolled",
        selectedProgramOptions
      );
      console.log(res);
      if (res.status === 200) {
        const arr = [];
        console.log("aa", res);
        const acdemicTermList = res.data.message;
        console.log("aa", res.data.message);
        for (let i = 0; i < acdemicTermList?.length; i++) {
          arr.push({
            key: acdemicTermList[i].name,
            studentId: acdemicTermList[i].name,
            studentName: acdemicTermList[i].student_name,
            programme: acdemicTermList[i].program,
            programmeType: acdemicTermList[i].program_type,
            customStudentType: acdemicTermList[i].custom_student_type,
          });
        }
        console.log("aa", arr, arr.length);
        setStudentData(arr);
        if (arr.length === 0) {
          toast.error("No Students found for Selected filters");
          setStudentData();
        }
      }
    } catch (error) {
      console.log(error, "Something went wrong");
      toast.error("Error:", error);
    }
  };

  const handleEnrollClick = (transition) => {
    setAnchorEl(null);
    eventBus.emit(
      "open-dialog",
      <ConfirmationDialog
        handleUpdateDataToApplicationForm={handleEnrollStudents}
        nextState={transition.next_state}
      />
    );
  };
  const handleEnrollStudents = async () => {
    try {
      let payload = {
        data: {
          students: studentData
            .filter((student) => studentSelectedRowKeys.includes(student.key))
            .map((student) => {
              return {
                name: student.key,
                student_name: student.studentName,
              };
            }),
          program: selectedProgramOptions,
          academic_term: selectedAcademicTermOptions,
          student_batch_name: selectedBatchOptions,
        },
      };
      console.log(payload);
      const res = await enrollStudents(payload);
      console.log(res);
      if (res.status === 200) {
        // const arr = [];
        // console.log("aa", res);
        // const acdemicTermList = res.data.message;
        // console.log("aa", res.data.message);
        // for (let i = 0; i < acdemicTermList?.length; i++) {
        //   arr.push({
        //     key: acdemicTermList[i].name,
        //     studentId: acdemicTermList[i].name,
        //     studentName: acdemicTermList[i].student_name,
        //     programme: acdemicTermList[i].program,
        //     programmeType: acdemicTermList[i].program_type,
        //     customStudentType: acdemicTermList[i].custom_student_type,
        //   });
        // }
        // console.log("aa", arr);
        // setStudentData(arr);
        toast.success("Students Enrolled!");
      }
    } catch (error) {
      console.log(error, "Something went wrong");
    }
  };

  useEffect(() => {
    handleGetProgramListing();
    handleGetAcademicTermListing();
    handleGetBatchListing();
  }, []);
  useEffect(() => {
    console.log("student data", studentData);
    console.log("student Selected Row Keys", studentSelectedRowKeys);
  }, [studentData, studentSelectedRowKeys]);

  return (
    <MainLayout>
      <Breadcrumbs />
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3 }}
        className="mainPanel noPadding"
      >
        <div className="master-heading">
          <h2>
            <GridMenuIcon />
            {`Programme Enrollment Tool`}
          </h2>
          <div className="btnsGrp">
            <Button variant="outlined" onClick={handleBackButtonClick}>
              Back
            </Button>

            {!studentData && (
              <Button
                variant="outlined"
                onClick={handleGetStudents}
                className="redBtn"
              >
                Get Students
              </Button>
            )}
            {studentSelectedRowKeys && (
              <Button
                variant="outlined"
                onClick={handleEnrollClick}
                className="redBtn"
              >
                Enroll Students
              </Button>
            )}
          </div>
        </div>
      </Box>
      <div className={styles.mainContainer}>
        <div className={styles.formContainer}>
          <div className={styles.formRow}>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Get Student From *</p>
              <Select
                placeholder="Select"
                value={selectedStudentFromOptions}
                className={styles.selectInput}
                options={[
                  { label: "Programme Enrolled", value: "Programme Enrolled" },
                  { label: "Admitted Students", value: "Admitted Students" },
                ]}
                onChange={(value) => {
                  setSelectedStudentFromOptions(value);
                }}
              />
            </div>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Application Form *</p>
              <Select
                placeholder="Select"
                value={selectedProgramOptions}
                className={styles.selectInput}
                options={programOptions}
                onChange={(value) => {
                  setSelectedProgramOptions(value);
                }}
              />
            </div>
            {studentData && (
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Acaedemic Term*</p>
                <Select
                  placeholder="Select"
                  value={selectedAcademicTermOptions}
                  className={styles.selectInput}
                  options={academicTermOptions}
                  onChange={(value) => {
                    setSelectedAcademicTermOptions(value);
                  }}
                />
              </div>
            )}
          </div>

          <div className={styles.formRow}>
            {studentData && (
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Assign Batch*</p>
                <Select
                  placeholder="Select"
                  value={selectedBatchOptions}
                  className={styles.selectInput}
                  options={batchOptions}
                  onChange={(value) => {
                    setSelectedBatchOptions(value);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.tabel}>
        <div className="mb-5">
          <label>{}</label>
        </div>
        <Table
          rowSelection={{ type: "checkbox", ...studentRowSelection }}
          columns={headers}
          dataSource={studentData}
          size="small"
          pagination={false}
        />
      </div>
    </MainLayout>
  );
};

export default BulkStudentEnrollment;

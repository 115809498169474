import axiosInstance from "translator";
import APIs from "api";

export const getAllRooms = async (filters) => {
  try {
    const response = await axiosInstance.get(
      `${APIs.genericGetListing}?doctype=Room&filters=${filters}`
    );
    return response;
  } catch (error) {
    console.error("Error getting entity detail:", error);
    throw error;
  }
};

export const getRoomDetailsById = async ({ payload }) => {
  try {
    const response = await axiosInstance.post(
      APIs.genericGetDetailsById,
      payload
    );
    return response;
  } catch (error) {
    console.error("Error getting room detail by id:", error);
    throw error;
  }
};

export const addNewRoom = async ({ payload }) => {
  try {
    const response = await axiosInstance.post(
      APIs.genericCreateOrUpdate,
      payload
    );
    return response;
  } catch (error) {
    console.error("Error while creating room: ", error);
    return error.response;
  }
};

export const getAllFloors = async (filters) => {
  try {
    const response = await axiosInstance.get(
      `${APIs.genericGetListing}?doctype=Floor&filters=${filters}`
    );
    return response;
  } catch (error) {
    console.error("Error getting all floors listing:", error);
    throw error;
  }
};

export const getFloorDetailsById = async ({ payload }) => {
  try {
    const response = await axiosInstance.post(
      APIs.genericGetDetailsById,
      payload
    );
    return response;
  } catch (error) {
    console.error("Error getting floor detail by id:", error);
    throw error;
  }
};

export const addNewFloor = async ({ payload }) => {
  try {
    const response = await axiosInstance.post(
      APIs.genericCreateOrUpdate,
      payload
    );
    return response;
  } catch (error) {
    console.error("Error while creating floor: ", error);
    return error.response;
  }
};

export const getAllBlocks = async (filters) => {
  try {
    const response = await axiosInstance.get(
      `${APIs.genericGetListing}?doctype=Block&filters=${filters}`
    );
    return response;
  } catch (error) {
    console.error("Error getting  all blocks listing:", error);
    throw error;
  }
};

export const getBlockDetailsById = async ({ payload }) => {
  try {
    const response = await axiosInstance.post(
      APIs.genericGetDetailsById,
      payload
    );
    return response;
  } catch (error) {
    console.error("Error getting block detail by id:", error);
    throw error;
  }
};

export const addNewBlock = async ({ payload }) => {
  try {
    const response = await axiosInstance.post(
      APIs.genericCreateOrUpdate,
      payload
    );
    return response;
  } catch (error) {
    console.error("Error while adding new block: ", error);
    return error.response;
  }
};
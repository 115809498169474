import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Select, Input } from "antd"; 
import useStyles from "./style";
import {
  createLead,
  getCities,
  getColleges,
  getCountries,
  getProgramGroupsList,
  getPrograms,
  getStates,
} from "../../../modules/enquiry";

const AddLead = ({ values, setValues, errorMessages }) => {
  const selectSize = "middle";
  const styles = useStyles();
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [campusOptions, setCampusOptions] = useState([]);
  const [collegeOptions, setCollegeOptions] = useState([]);
  const [programOptions, setProgramOptions] = useState([]);
  const [programGroupOptions, setProgramGroupOptions] = useState([]);

  const handleGetCountries = async () => {
    setStateOptions([]);
    setCityOptions([]);
    setValues({
      ...values,
      country: null,
      state: null,
      city: null,
    });
    const res = await getCountries();
    if (res.status === 200) {
      const arr = [];
      const countryList = res.data.data;
      for(let i = 0 ; i < countryList.length ; i++) {
        arr.push({
          value: countryList[i].name,
          label: countryList[i].name
        });
      };
      setCountryOptions(arr);
    }
  };

  const handleGetStatesByCountry = async (country) => {
    setCityOptions([]);
    setValues({
      ...values,
      country,
      state: null,
      city: null,
    });
    const res = await getStates({ country });
    if (res.status === 200) {
      const arr = [];
      const stateList = res.data.data;
      for(let i = 0 ; i < stateList.length ; i++) {
        arr.push({
          value: stateList[i].name,
          label: stateList[i].name
        });
      };
      setStateOptions(arr);
    }
  };

  const handleGetCitiesByState = async (state) => {
    const res = await getCities({ state });
    if (res.status === 200) {
      const arr = [];
      const cityList = res.data.data;
      for(let i = 0 ; i < cityList.length ; i++) {
        arr.push({
          value: cityList[i].name,
          label: cityList[i].name
        });
      };
      setCityOptions(arr);
    }
  };

  const handleGetColleges = async () => {
    const res = await getColleges();
    if (res.status === 200) {
      const arr = [];
      const collegeList = res.data.data;
      for(let i = 0 ; i < collegeList.length ; i++) {
        arr.push({
          value: collegeList[i].name,
          label: collegeList[i].name
        });
      };
      setCollegeOptions(arr);
      setCampusOptions(arr);
    }
  };

  const handleGetPrograms = async (programgroup) => {
    const res = await getPrograms({ programgroup });
    if (res.status === 200) {
      const arr = [];
      const programList = res.data.data;
      for(let i = 0 ; i < programList.length ; i++) {
        arr.push({
          value: programList[i].name,
          label: programList[i].name
        });
      };
      setProgramOptions(arr);
    }
  };

  const handleGetProgramGroupsList = async () => {
    const res = await getProgramGroupsList();
    if (res.status === 200) {
      const arr = [];
      const programGroupList = res.data.data;
      for(let i = 0 ; i < programGroupList.length ; i++) {
        arr.push({
          value: programGroupList[i].name,
          label: programGroupList[i].name
        });
      };
      setProgramGroupOptions(arr);
    }
  };

  useEffect(() => {
    handleGetCountries();
    handleGetColleges();
    handleGetProgramGroupsList();
    handleGetPrograms();
  }, []);

  return (
     <div className={styles.formContainer}>
      <div className={styles.formRow}>
        <div className={styles.field}>
          <p className={styles.fieldLabel}>Full Name *</p>
          <Input
            placeholder="ex Text"
            value={values.name}
            className={styles.fieldInput}
            onChange={(e) => {
              setValues({
                ...values,
                name: e.target.value,
              });
            }}
          />
          {
            errorMessages.name &&
            <p className={styles.errorMessage}>{errorMessages.name}</p>
          }
        </div>
        <div className={styles.field}>
          <p className={styles.fieldLabel}>Email Address *</p>
          <Input
            placeholder="ex test@gmail.com"
            value={values.email}
            className={styles.fieldInput}
            onChange={(e) => {
              setValues({
                ...values,
                email: e.target.value,
              });
            }}
          />
          {
            errorMessages.email &&
            <p className={styles.errorMessage}>{errorMessages.email}</p>
          }
        </div>
        <div className={styles.field}>
          <p className={styles.fieldLabel}>Mobile Number *</p>
          <Input
            placeholder="ex 9988776655"
            value={values.mobileNumber}
            className={styles.fieldInput}
            onChange={(e) => {
              setValues({
                ...values,
                mobileNumber: e.target.value,
              });
            }}
          />
          {
            errorMessages.mobileNumber &&
            <p className={styles.errorMessage}>{errorMessages.mobileNumber}</p>
          }
        </div>
      </div>
      <div className={styles.formRow}>
        <div className={styles.field}>
          <p className={styles.fieldLabel}>Country *</p>
          <Select
            showSearch
            size={selectSize}
            placeholder="Select"
            value={values.country}
            className={styles.selectInput}
            options={countryOptions}
            onChange={(value) => {
              setValues({ ...values, country: value });
              handleGetStatesByCountry(value);
            }}
          />
          {
            errorMessages.country &&
            <p className={styles.errorMessage}>{errorMessages.country}</p>
          }
        </div>
        <div className={styles.field}>
          <p className={styles.fieldLabel}>State *</p>
          <Select
            showSearch
            size={selectSize}
            placeholder="Select"
            value={values.state}
            className={styles.selectInput}
            options={stateOptions}
            onChange={(value) => {
              setValues({ ...values, state: value });
              handleGetCitiesByState(value);
            }}
          />
          {
            errorMessages.state &&
            <p className={styles.errorMessage}>{errorMessages.state}</p>
          }
        </div>
        <div className={styles.field}>
          <p className={styles.fieldLabel}>City *</p>
          <Select
            showSearch
            size={selectSize}
            placeholder="Select"
            value={values.city}
            className={styles.selectInput}
            options={cityOptions}
            onChange={(value) => {
              setValues({ ...values, city: value });
            }}
          />
          {
            errorMessages.city &&
            <p className={styles.errorMessage}>{errorMessages.city}</p>
          }
        </div>
      </div>
      <div className={styles.formRow}>
        <div className={styles.field}>
          <p className={styles.fieldLabel}>Campus</p>
          <Select
            showSearch
            size={selectSize}
            placeholder="Select"
            value={values.campus}
            className={styles.selectInput}
            options={collegeOptions}
            onChange={(value) => {
              setValues({ ...values, campus: value });
            }}
          />
        </div>
        <div className={styles.field}>
          <p className={styles.fieldLabel}>College *</p>
          <Select
            showSearch
            size={selectSize}
            placeholder="Select"
            value={values.college}
            className={styles.selectInput}
            options={collegeOptions}
            onChange={(value) => {
              setValues({ ...values, college: value });
            }}
          />
          {
            errorMessages.college &&
            <p className={styles.errorMessage}>{errorMessages.college}</p>
          }
        </div>
        <div className={styles.field}>
          <p className={styles.fieldLabel}>Program Group *</p>
          <Select
            showSearch
            size={selectSize}
            placeholder="Select"
            value={values.programGroups}
            className={styles.selectInput}
            options={programGroupOptions}
            onChange={(value) => {
              setValues({ ...values, programGroups: value });
              handleGetPrograms(value);
            }}
          />
          {
            errorMessages.programGroups &&
            <p className={styles.errorMessage}>{errorMessages.programGroups}</p>
          }
        </div>
      </div>
      <div className={styles.formRow}>
        <div className={styles.field}>
          <p className={styles.fieldLabel}>Program *</p>
          <Select
            showSearch
            size={selectSize}
            placeholder="Select"
            value={values.program}
            className={styles.selectInput}
            options={programOptions}
            onChange={(value) => {
              setValues({ ...values, program: value });
            }}
          />
          {
            errorMessages.program &&
            <p className={styles.errorMessage}>{errorMessages.program}</p>
          }
        </div>
      </div>
    </div>
  );
};

export default AddLead;

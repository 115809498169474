import React, { useState, useEffect } from "react";
import useStyles from "./style";
import { ReactSVG } from "react-svg";
import Menu from "@mui/material/Menu";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridMenuIcon } from "@mui/x-data-grid";
import { setLoading } from "../../../slices/commonSlice";
import ArrowDownSVG from "../../../assets/images/arrow-down.svg";
import FiltersSVG from "../../../assets/images/filters-icon.svg";
import { getAllFeeStructures } from "../../../modules/finance";
import LoadingBackdrop from "../../common/LoadingBackdrop.js";
import { Table } from "antd";
import { getStudentFeedbackTemplate } from "modules/student";

const FeeStructure = ({ setActiveModule }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.common);
  const [feedbackTemplate, setFeedbackTemplate] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const columns = [
    {
      dataIndex: "no",
      title: "NO.",
      width: "10%",
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.id}</p>;
      },
    },
    {
      dataIndex: "template_id",
      title: "ID",
      width: "20%",
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.template_id}</p>;
      },
    },
    {
      dataIndex: "template_name",
      title: "TEMPLATE NAME",
      width: "20%",
      render: (val, row) => {
        return (
          <p className={styles.tableContentFields}>{row.template_name}</p>
        );
      },
    },
    {
      dataIndex: "question_count",
      title: "Question Count",
      width: "15%",
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.question_count}</p>;
      },
    },
    {
      dataIndex: "status",
      title: "STATUS",
      width: "15%",
      render: (val, row) => {
        return (
          <p
            className={
              row.status === "Filled"
                ? styles.buttonPublish
                : styles.buttonUnPublish
            }
          >
            {row.status}
          </p>
        );
      },
    },
    {
      dataIndex: "id",
      title: "ACTION",
      width: "10%",
      render: (val, row) => {
        return (
          <p
            className={styles.tableContentView}
            onClick={() =>
              navigate(`/student/dashboard/${row.template_id}`)
            }
          >
            View
          </p>
        );
      },
    },
  ];

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const handleGetFeedbackTemplate = async () => {
    dispatch(setLoading({ loading: true }));
    const user = JSON.parse(window.localStorage.getItem("userDetails"));
    const res = await getStudentFeedbackTemplate(user.email);
    if (res.status === 200) {
      dispatch(setLoading({ loading: false }));
      const structures = [];
      for (let i = 0; i < res.data.message.length; i++) {
        structures.push({
          id: i+1,
          template_id: res.data.message[i].template_id,
          template_name: res.data.message[i].template_name,
          question_count: res.data.message[i].question_count,
          status: res.data.message[i].status === "Filled" ? "Filled" : "Not Filled",
          idx: res.data.message[i].idx,
        });
      }
      setFeedbackTemplate(structures);
    } else {
      dispatch(setLoading({ loading: false }));
    }
  };

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
      },
    },
  }));

  useEffect(() => {
    handleGetFeedbackTemplate();
  }, []);

  return (
    <div className={styles.mainContainer}>
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => setActiveModule(0)}
            >
            </p>
            <p className={styles.breadCrumbsContent}></p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>Feedback</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div className={`${styles.button} ${styles.filterButton}`}>
                <ReactSVG src={FiltersSVG} />
                <p className={styles.buttonText}>Filters</p>
                <ReactSVG src={ArrowDownSVG} />
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>Saved Filters</p>
              </div>
              <div
                className={styles.button}
                id="fee-structure-options"
                aria-controls={open ? "fee-structure-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                disableElevation
                onClick={handleClick}
              >
                <p className={styles.buttonText}>...</p>
              </div>
              <StyledMenu
                id="fee-structure-menu"
                MenuListProps={{
                  "aria-labelledby": "fee-structure-options",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem disableRipple className={styles.menuItems}>
                  Edit
                </MenuItem>
                <MenuItem disableRipple className={styles.menuItems}>
                  Delete
                </MenuItem>
                <MenuItem disableRipple className={styles.menuItems}>
                  Export
                </MenuItem>
              </StyledMenu>
            </div>
          </div>
          <Table
            rowSelection={{ type: "checkbox" }}
            columns={columns}
            dataSource={feedbackTemplate}
            size="small"
            pagination={true}
          />
        </>
      )}
    </div>
  );
};

export default FeeStructure;

import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import ArrowDownSVG from "../../../../assets/images/arrow-down.svg";
import FiltersSVG from "../../../../assets/images/filters-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { Table } from "antd";
import MainHeader from "components/common/MainHeader";
import MainFooter from "components/common/MainFooter";
import { getAllHREmployeeListing } from "modules/hr";
import { setLoading } from "slices/commonSlice";
import "./index.scss";

const Employee = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [employeeData, setEmployeeData] = useState([]);

  const handleGetAllEmployeeListing = async () => {
    dispatch(setLoading({ loading: true }));
    const res = await getAllHREmployeeListing();
    console.log(res);
    if (res.status === 200) {
      dispatch(setLoading({ loading: false }));
      const employeeList = [];
      for (let i = 0; i < res.data.message.length; i++) {
        employeeList.push({
          key: res.data.message[i].id,
          name: res.data.message[i].first_name,
          employee_id: res.data.message[i].employee,
          designation: res.data.message[i].designation,
          status: res.data.message[i].status,
        });
      }
      setEmployeeData(employeeList);
    } else {
      dispatch(setLoading({ loading: false }));
      console.log("Something went wrong while getting employee data");
    }
  };

  const columns = [
    {
      dataIndex: "name",
      title: "NAME",
      width: 0.8,
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.name}</p>;
      },
    },
    {
      dataIndex: "employee_id",
      title: "ID",
      width: 1,
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.employee_id}</p>;
      },
    },
    {
      dataIndex: "designation",
      title: "DESIGNATION",
      width: 0.7,
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.designation}</p>;
      },
    },
    {
      dataIndex: "status",
      title: "STATUS",
      width: 0.8,
      render: (val, row) => {
        const dynamicClassName = `status ${row.status}`;
        return <p className={dynamicClassName}>{row.status}</p>;
      },
    },
    {
      dataIndex: "action",
      title: "ACTION",
      width: 0.5,
      render: (val, row) => {
        return (
          <p
            className={styles.tableContentView}
            onClick={() => navigate(`/modules/hr/employee/${row.employee_id}`)}
          >
            View
          </p>
        );
      },
    },
  ];

  useEffect(() => {
    handleGetAllEmployeeListing();
  }, []);

  return (
    <>
      <MainHeader />{" "}
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/modules/hr");
              }}
            >
              Hr {" > "}
            </p>
            <p className={styles.breadCrumbsContent}>Employee</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>Employee</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div className={`${styles.button} ${styles.filterButton}`}>
                <ReactSVG src={FiltersSVG} />
                <p className={styles.buttonText}>Filters</p>
                <ReactSVG src={ArrowDownSVG} />
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>Saved Filters</p>
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>...</p>
              </div>
              <div
                className={styles.activeButton}
                onClick={() =>
                  navigate("/modules/hr/employee/add-new-employee")
                }
              >
                <p className={styles.activeButtonText}>Add Employee</p>
              </div>
            </div>
          </div>
          <Table
            rowSelection={{ type: "checkbox" }}
            columns={columns}
            dataSource={employeeData}
            size="small"
            pagination={true}
          />
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default Employee;

import MainHeader from "../../../common/MainHeader";
import MainFooter from "../../../common/MainFooter";
import React, { useEffect, useState } from "react";
import { GridMenuIcon } from "@mui/x-data-grid";
import useStyles from "./style";
import { useNavigate, useParams } from "react-router-dom";

import { useSelector } from "react-redux";
import LoadingBackdrop from "../../../common/LoadingBackdrop.js";
import {
  getCollegeListing,
  getStudentsListing,
  getCostCenterListing,
  getCurrencyListing,
  getPaymentEntry,
  getGeneralLedger,
} from "../../../../modules/finance.js";
import { DatePicker, Select, Table, Input } from "antd";

const GeneralLedger = () => {
  const styles = useStyles();
  const { paymentEntry } = useParams();
  const selectSize = "middle";
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.common);
  const [collegeListOptions, setCollegeListOptions] = useState([]);
  const [studentOptions, setStudentOptions] = useState([]);
  const [costCenterOptions, setCostCenterOptions] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableDataList, setTableDataList] = useState([]);
  const [values, setValues] = useState({
    company: "",
    from_date: "",
    to_date: "",
    account: "",
    payment_id: "",
    party_type: "",
    party: "",
    group_by: "",
    currency: "",
    cost_center: "",
    components: [{}],
  });
  //   const [errors, setErrors] = useState({
  //     company: "",
  //     payment_id: "",
  //     party_type: "",
  //     party: "",
  //     group_by: "",
  //     currency: "",
  //     cost_center: "",
  //   });

  const PartyTypeOptions = [{ value: "Student", label: "Student" }];

  const handleGetgetCollegeListing = async () => {
    const res = await getCollegeListing();
    if (res.status === 200) {
      const arr = [];
      const collegeList = res.data.data;
      for (let i = 0; i < collegeList.length; i++) {
        arr.push({
          value: collegeList[i].name,
          label: collegeList[i].name,
        });
      }
      setCollegeListOptions(arr);
    }
  };

  const handleGetStudentListing = async () => {
    const res = await getStudentsListing();
    if (res.status === 200) {
      const arr = [];
      const studentList = res.data.data;
      for (let i = 0; i < studentList.length; i++) {
        arr.push({
          value: studentList[i].name,
          label: `${studentList[i].name} - ${studentList[i].first_name}`,
        });
      }
      setStudentOptions(arr);
    }
  };

  const handleGetCurrencyListing = async () => {
    const res = await getCurrencyListing();
    if (res.status === 200) {
      const arr = [];
      const currencyList = res.data.data;
      for (let i = 0; i < currencyList.length; i++) {
        arr.push({
          value: currencyList[i].name,
          label: currencyList[i].name,
        });
      }
      setCurrencyOptions(arr);
    }
  };

  const handleGetCostCenterListing = async () => {
    const res = await getCostCenterListing();
    if (res.status === 200) {
      const arr = [];
      const costCenterList = res.data.message;
      for (let i = 0; i < costCenterList.length; i++) {
        arr.push({
          value: costCenterList[i].value,
          label: costCenterList[i].value,
        });
      }
      setCostCenterOptions(arr);
    }
  };

  const handleGetPaymentEntry = async () => {
    const res = await getPaymentEntry({ name: paymentEntry });

    if (res.status === 200) {
      const paymentEntryDetails = res.data.data;
      setValues({
        ...values,
        company: paymentEntryDetails.company,
        from_date: paymentEntryDetails.posting_date,
        to_date: paymentEntryDetails.posting_date,
        payment_id: paymentEntryDetails.name,
        party_type: paymentEntryDetails.party_type,
        party: paymentEntryDetails.party,
        party_balance: paymentEntryDetails.party_balance,
        paid_to: paymentEntryDetails.paid_to,
        paid_from: paymentEntryDetails.paid_from,
        paid_amount: parseInt(paymentEntryDetails.paid_amount),
        total_allocated_amount: parseInt(
          paymentEntryDetails.total_allocated_amount
        ),
        unallocated_amount: parseInt(paymentEntryDetails.unallocated_amount),
        difference_amount: parseInt(paymentEntryDetails.difference_amount),
        total_taxes_and_charges: paymentEntryDetails.total_taxes_and_charges,
        reference_no: paymentEntryDetails.reference_no,
        reference_date: paymentEntryDetails.reference_date,
        cost_center: paymentEntryDetails.cost_center,
        remarks: paymentEntryDetails.remarks,
        components: [],
      });
    } else {
      console.log("error: ", res);
    }
  };

  const fetchData = async () => {
    try {
      const formData = new FormData();
      formData.append("report_name", "General Ledger");
      formData.append(
        "filters",
        JSON.stringify({
          company: "Techademy",
          from_date: values.from_date,
          to_date: values.to_date,
          account: [],
          party: [],
          group_by: "Group by Voucher (Consolidated)",
          cost_center: [],
          voucher_no: paymentEntry,
          project: [],
          include_dimensions: 1,
          include_default_book_entries: 1,
        })
      );
      const response = await getGeneralLedger({ formData });
      const apires = response.data.message.result;
      const LedgerEntry = [];
      for (let i = 0; i < apires.length; i++) {
        LedgerEntry.push({
          id: i,
          posting_date: apires[i].posting_date,
          account: apires[i].account,
          debit: apires[i].debit,
          credit: apires[i].credit,
          balance: apires[i].balance,
          voucher_type: apires[i].voucher_type,
          voucher_no: apires[i].voucher_no,
          against_account: apires[i].against,
          party_type: apires[i].party_type,
          party: apires[i].party,
          cost_center: apires[i].cost_center,
          against_voucher_type: apires[i].against_voucher_type,
          against_voucher: apires[i].against_voucher,
        });
      }

      setTableDataList(LedgerEntry);
      setValues({
        ...values,
        group_by: "Group by Voucher (Consolidated)",
        cost_center: "Main-TA",
      });
    } catch (error) {
      console.log(error);
    }
  };

  fetchData();

  const columns = [
    {
      title: "No.",
      width: "5%",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Posting Date",
      dataIndex: "posting_date",
      width: "15%",
      ellipsis: true,
    },
    {
      title: "Account",
      dataIndex: "account",
      width: "25%",
      ellipsis: true,
    },
    {
      title: "Debit",
      dataIndex: "debit",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Credit",
      dataIndex: "credit",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Balance",
      dataIndex: "balance",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Voucher Type",
      dataIndex: "voucher_type",
      width: "15%",
      ellipsis: true,
    },
    {
      title: "Voucher No.",
      dataIndex: "voucher_no",
      width: "30%",
      ellipsis: true,
    },
    {
      title: "Against Account",
      dataIndex: "against_account",
      width: "20%",
      ellipsis: true,
    },
    {
      title: "Party Type",
      dataIndex: "party_type",
      width: "15%",
      ellipsis: true,
    },
    {
      title: "Party",
      dataIndex: "party",
      width: "25%",
      ellipsis: true,
    },
    {
      title: "Cost Center",
      dataIndex: "cost_center",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Against Voucher Type",
      dataIndex: "against_voucher_type",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Against Voucher",
      dataIndex: "against_voucher",
      width: "25%",
      ellipsis: true,
    },
  ];

  const onSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = {
    type: "checkbox",
    onChange: onSelectChange,
    selectedRowKeys,
  };

  const handleLedgerNavigation = () => {
    navigate(-1);
  };

  useEffect(() => {
    handleGetgetCollegeListing();
    handleGetStudentListing();
    handleGetCurrencyListing();
    handleGetCostCenterListing();
    handleGetPaymentEntry();
  }, []);

  return (
    <>
      <MainHeader />
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/modules")}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/modules/finance")}
            >
              Finance {" > "}
            </p>
            <p className={styles.breadCrumbsContent}>Ledger</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>General Ledger</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div className={styles.button} onClick={handleLedgerNavigation}>
                <p className={styles.buttonText}>Back</p>
              </div>
            </div>
          </div>
          <div className={styles.formContainer}>
            <div>
              <p className={styles.smallTitle}>Type Of Payment</p>
              <div className={styles.divider}></div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Institution *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  disabled={true}
                  value={values.company}
                  className={styles.selectInput}
                  options={collegeListOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      company: value,
                    });
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>From Date *</p>
                <input
                  type="date"
                  value={values.from_date}
                  disabled={true}
                  placeholder="YYYY-MM-DD"
                  className={styles.dateInput}
                  onChange={(e) => {
                    const dateString = e.target.value;
                    setValues({ ...values, from_date: dateString });
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>To Date *</p>
                <input
                  type="date"
                  value={values.to_date}
                  placeholder="YYYY-MM-DD"
                  disabled={true}
                  className={styles.dateInput}
                  onChange={(e) => {
                    const dateString = e.target.value;
                    setValues({ ...values, to_date: dateString });
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Account </p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.account}
                  disabled={true}
                  className={styles.selectInput}
                  //   options={collegeListOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      account: value,
                    });
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Payment ID</p>
                <Input
                  placeholder="ex Text"
                  disabled={true}
                  value={values.payment_id}
                  className={styles.fieldInput}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      payment_id: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Party Type *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.party_type}
                  disabled={true}
                  className={styles.selectInput}
                  options={PartyTypeOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      party_type: value,
                    });
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Party </p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  disabled={true}
                  value={values.party}
                  className={styles.selectInput}
                  options={studentOptions}
                  onChange={(value, options) => {
                    const selectedParty = studentOptions.find(
                      (opt) => opt.value === value
                    );
                    if (selectedParty) {
                      setValues({
                        ...values,
                        party: value,
                      });
                    } else {
                      setValues({
                        ...values,
                        party: null,
                      });
                    }
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Group By </p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  disabled={true}
                  value={values.group_by}
                  className={styles.selectInput}
                  //   options={studentOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      group_by: value,
                    });
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Currency </p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  disabled={true}
                  value={values.currency}
                  className={styles.selectInput}
                  options={currencyOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      currency: value,
                    });
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Cost Center </p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  disabled={true}
                  defaultValue={values.cost_center}
                  className={styles.selectInput}
                  options={costCenterOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      cost_center: value,
                    });
                  }}
                />
              </div>
            </div>
            <div className={styles.formRow}>
              <Table
                rowSelection={{ type: "checkbox", ...rowSelection }}
                columns={columns}
                dataSource={tableDataList}
                size="small"
                pagination={false}
                scroll={{ x: true }}
              />
            </div>
          </div>
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default GeneralLedger;

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import { getApplicationFormListing } from "../../modules/student";
import { useSelector } from "react-redux";
import { navigate } from "react-big-calendar/lib/utils/constants";
import { useNavigate } from "react-router-dom";

export default function DashboardStudent() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [applicationForms, setApplicationForms] = useState([]);

  useEffect(() => {
    handleGetApplicationFormListing();
  }, []);

  const handleGetApplicationFormListing = async () => {
    const payload = {
      user: user.email,
    };

    const res = await getApplicationFormListing({ payload });
    if (res.status === 200) {
      let arr = [];
      const val = res?.data?.message?.message;

      for (let i = 0; i < val.length; i++) {
        arr.push({
          ...val[i],
          id: i,
        });
      }

      setApplicationForms(arr);
    }
  };

  const columns = [
    { field: "application_form", headerName: "Application Form", width: "300" },
    { field: "application_number", headerName: "Application No.", width: "300" },
    { field: "submission_date", headerName: "Submission Date", width: "200" },
    { field: "percent_filled", headerName: "Percent Filled", width: "200" },
    { field: "application_fees", headerName: "Application Fee", width: "200" },
    {
      field: "id",
      headerName: "Action",
      width: "150",
      renderCell: ({ row, i }) => {
        return (
          <>
            {row?.id == 0 ? (
              <div
                onClick={() => {
                  navigate("/applicationForm", {
                    state: {
                      program: row?.application_form,
                      programGroup: row?.program_group,
                      academicYear: row?.academic_year
                    }
                  })
                }}
                variant="contained"
                className="applyLink"
              >
                Apply now
              </div>
            ) : (
              <a
                href={`/formCompleted`}
                variant="contained"
                className="applyLink"
              >
                Submitted
              </a>
            )}
          </>
        );
      },
    },
  ];
  

  const CustomToolbar = () => {
    return (
      <div className="headingBtns">
        <Typography variant="h5" className="mainHeading">
          All Application Form(s)
        </Typography>
        {/* <div className="btnsGrp">
          <Button
            variant="outlined"
            startIcon={<FilterListIcon />}
            onClick={handleFilterButtonClick}
          >
            {filtering ? "Filters" : "Filters"}
          </Button>
          <Button variant="outlined">Saved Filters</Button>
          <Button variant="outlined" className="redBtn">
            + Add Lead
          </Button>
        </div> */}
      </div>
    );
  };

  return (
    <Box sx={{ height: "100%" }}>
      {applicationForms && applicationForms.length > 0 ? (
        <DataGrid
          rowHeight={70}
          className="mainTable"
          style={{ width: "100%", height: "100%" }}
          columns={columns}
          rows={applicationForms}
          // checkboxSelection
          disableRowSelectionOnClick
          // slots={{ toolbar: GridToolbar }}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      ) : (
        <p>No Application Forms yet</p>
      )}
    </Box>
  );
}

import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { useNavigate, useParams } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { useDispatch, useSelector } from "react-redux";
import { Select, Table, Input, DatePicker, Tabs, Tab, Checkbox } from "antd";
import { toast } from "react-toastify";
import MainHeader from "components/common/MainHeader";
import MainFooter from "components/common/MainFooter";
import { getStudentsListing } from "modules/finance";
import {
  getCourseScheduleListing,
  getStudentGroupListing,
  getStudentLeaveApplicationByID,
} from "modules/hr";

const GetUpdateStudentLeaveApplication = () => {
  const styles = useStyles();
  const selectSize = "middle";
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [studentOptions, setStudentOptions] = useState([]);
  const [studentGroupOptions, setStudentGroupOptions] = useState([]);
  const [courseScheduleOptions, setCourseScheduleOptions] = useState([]);
  const [values, setValues] = useState({
    student: "",
    student_name: "",
    from_date: "",
    to_date: "",
    student_based_on: "Student Group",
    student_group: "",
    course_schedule: "",
    mark_as_present: "",
    reason: "",
    total_leave_days: "",
  });
  const [errors, setErrors] = useState({
    student: "",
    student_name: "",
    from_date: "",
    to_date: "",
    student_group: "",
    course_schedule: "",
  });

  const handleGetStudentListing = async () => {
    const res = await getStudentsListing();
    if (res.status === 200) {
      const arr = [];
      const studentList = res.data.data;
      for (let i = 0; i < studentList.length; i++) {
        arr.push({
          value: studentList[i].name,
          label: `${studentList[i].name} - ${studentList[i].first_name}`,
        });
      }
      setStudentOptions(arr);
    }
  };

  const studentBasedOnOptions = [
    {
      value: "Student Group",
      label: "Student Group",
    },
    {
      value: "Course Schedule",
      label: "Course Schedule",
    },
  ];

  const handleGetStudentGroupListing = async () => {
    const res = await getStudentGroupListing();
    if (res.status === 200) {
      const arr = [];
      const studentGroupList = res.data.data;
      for (let i = 0; i < studentGroupList.length; i++) {
        arr.push({
          value: studentGroupList[i].name,
          label: studentGroupList[i].name,
        });
      }
      setStudentGroupOptions(arr);
    }
  };

  const handleGetCourseScheduleListing = async () => {
    const res = await getCourseScheduleListing();
    if (res.status === 200) {
      const arr = [];
      const courseScheduleList = res.data.data;
      for (let i = 0; i < courseScheduleList.length; i++) {
        arr.push({
          value: courseScheduleList[i].name,
          label: `${courseScheduleList[i].name} - ${courseScheduleList[i].instructor}`,
        });
      }
      setCourseScheduleOptions(arr);
    }
  };

  const handleStudentLeaveNavigation = () => {
    navigate("/modules/academics/student-leave-application");
  };

  const handleGetStudentLeaveData = async () => {
    const params = {
      doctype: "Student Leave Application",
      id: id,
    };
    const res = await getStudentLeaveApplicationByID(params);
    if (res.status === 200) {
      const studentLeaveDetails = res.data.message;
      console.log(studentLeaveDetails);
      setValues({
        ...values,
        student: studentLeaveDetails.student,
        student_name: studentLeaveDetails.student_name,
        from_date: studentLeaveDetails.from_date,
        to_date: studentLeaveDetails.to_date,
        student_based_on: studentLeaveDetails.attendance_based_on,
        student_group: studentLeaveDetails.student_group,
        course_schedule: studentLeaveDetails.course_schedule,
        mark_as_present: studentLeaveDetails.mark_as_present,
        reason: studentLeaveDetails.reason,
        total_leave_days: studentLeaveDetails.total_leave_days,
      });
    }
  };

  useEffect(() => {
    handleGetStudentListing();
    handleGetStudentGroupListing();
    handleGetCourseScheduleListing();
    handleGetStudentLeaveData();
  }, []);

  return (
    <>
      <MainHeader />
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/dashboard")}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/modules/academics")}
            >
              Academics {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={handleStudentLeaveNavigation}
            >
              Student Leave Application {" > "}
            </p>
            <p className={styles.breadCrumbsContent}>{id}</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>{id}</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div
                className={styles.button}
                onClick={handleStudentLeaveNavigation}
              >
                <p className={styles.buttonText}>Back</p>
              </div>
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Student ID*</p>
                <Select
                  size={selectSize}
                  disabled={true}
                  placeholder="Select"
                  value={values.student}
                  className={styles.selectInput}
                  options={studentOptions}
                  onChange={(value, options) => {
                    const selectedParty = studentOptions.find(
                      (opt) => opt.value === value
                    );
                    if (selectedParty) {
                      setValues({
                        ...values,
                        student: value,
                        student_name: selectedParty.label,
                      });
                    } else {
                      setValues({
                        ...values,
                        student: null,
                        student_name: null,
                      });
                    }
                    setErrors({
                      ...errors,
                      student: "",
                    });
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
                {errors.student && (
                  <p className={styles.errorMessage}>{errors.student}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Student Name *</p>
                <Input
                  placeholder="ex Text"
                  disabled={true}
                  value={values.student_name}
                  className={styles.fieldInput}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      student_name: e.target.value,
                    });
                    setErrors({
                      ...errors,
                      student_name: "",
                    });
                  }}
                />
                {errors.student_name && (
                  <p className={styles.errorMessage}>{errors.student_name}</p>
                )}
              </div>
              <div className={styles.field}></div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>From Date *</p>
                <input
                  type="date"
                  disabled={true}
                  value={values.from_date}
                  placeholder="YYYY-MM-DD"
                  className={styles.dateInput}
                  onChange={(e) => {
                    const dateString = e.target.value;
                    setValues({ ...values, from_date: dateString });
                    setErrors({
                      ...errors,
                      from_date: "",
                    });
                  }}
                />
                {errors.from_date && (
                  <p className={styles.errorMessage}>{errors.from_date}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>To Date *</p>
                <input
                  type="date"
                  value={values.to_date}
                  disabled={true}
                  placeholder="YYYY-MM-DD"
                  className={styles.dateInput}
                  onChange={(e) => {
                    const dateString = e.target.value;
                    setValues({ ...values, to_date: dateString });
                    setErrors({
                      ...errors,
                      to_date: "",
                    });
                  }}
                />
                {errors.to_date && (
                  <p className={styles.errorMessage}>{errors.to_date}</p>
                )}
              </div>
              <div className={styles.field}></div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Student Based On</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  disabled={true}
                  value={values.student_based_on}
                  className={styles.selectInput}
                  options={studentBasedOnOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      student_based_on: value,
                    });
                  }}
                />
              </div>
              {values.student_based_on === "Student Group" ? (
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>Student Group*</p>
                  <Select
                    size={selectSize}
                    placeholder="Select"
                    disabled={true}
                    value={values.student_group}
                    className={styles.selectInput}
                    options={studentGroupOptions}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        student_group: value,
                      });
                      setErrors({
                        ...errors,
                        student_group: "",
                      });
                    }}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                  {errors.student_group && (
                    <p className={styles.errorMessage}>
                      {errors.student_group}
                    </p>
                  )}
                </div>
              ) : (
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>Course Schedule *</p>
                  <Select
                    size={selectSize}
                    placeholder="Select"
                    disabled={true}
                    value={values.course_schedule}
                    className={styles.selectInput}
                    options={courseScheduleOptions}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        course_schedule: value,
                      });
                      setErrors({
                        ...errors,
                        course_schedule: "",
                      });
                    }}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                  {errors.course_schedule && (
                    <p className={styles.errorMessage}>
                      {errors.course_schedule}
                    </p>
                  )}
                </div>
              )}
              <div className={styles.field}></div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <Checkbox
                  checked={values.mark_as_present === 1}
                  disabled={true}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    const newValue = isChecked ? 1 : 0;
                    setValues({
                      ...values,
                      mark_as_present: newValue,
                    });
                  }}
                >
                  Mark as Present
                </Checkbox>
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Total Leave Days </p>
                <Input
                  placeholder="ex Text"
                  disabled={true}
                  value={values.total_leave_days}
                  className={styles.fieldInput}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      total_leave_days: e.target.value,
                    });
                  }}
                />
              </div>
              <div className={styles.field}></div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.textarea}>
                <p className={styles.fieldLabel}>Reason </p>
                <textarea
                  type="text"
                  disabled={true}
                  placeholder="Enter Text here..."
                  className={styles.textAreaInput}
                  value={values.reason}
                  rows={5}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      reason: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default GetUpdateStudentLeaveApplication;

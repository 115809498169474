import React from "react";
import SideBar from "components/SideBar";
import Dashboard from "../../assets/images/icon-dashboard.svg";
import Recruitment from "../../assets/images/icon-master.svg";
import LeaveManagement from "../../assets/images/icon-enrolment.svg";
import Attendance from "../../assets/images/icon-group.svg";
import Payroll from "../../assets/images/icon-report.svg";
import Performance from "../../assets/images/icon-attendance.svg";
import HRDashboard from "./HRDashboard";
import RecruitmentComponent from "./Recruitment";
import LeaveManagementComponent from "./LeaveManagement";
import PayrollComponent from "./Payroll";
import PerformanceComponent from "./Performance";
import AttendanceComponent from "./Attendance/Attendance";
import GradingScale from "./GradingScale";

const sidebarContent = [
  { label: "Dashboard", icon: Dashboard },
  { label: "Recruitment", icon: Recruitment },
  { label: "Leave Management", icon: LeaveManagement },
  { label: "Attendance", icon: Attendance },
  { label: "Payroll", icon: Payroll },
  { label: "Grading Scale", icon: LeaveManagement },
  { label: "Performance", icon: Performance },
];

const modules = [
  HRDashboard,
  RecruitmentComponent,
  LeaveManagementComponent,
  AttendanceComponent,
  PayrollComponent,
  GradingScale,
  PerformanceComponent,
];

const HRContainer = () => {
  return <SideBar sidebarContent={sidebarContent} modules={modules} />;
};

export default HRContainer;

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  cardContainer: {
    display: "inline-flex",
    justifyContent: "space-between"
  },
  card: {
    display: "flex", 
    flexDirection: "column",
    gap: "24px",
    padding: "12px 0px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)"
  },
  cardTitle: {
    color: "#0D1021",
    fontSize: "16px",
    fontWeight: "700",
    textAlign: "center"
  },
  cardContent: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 54px"
  },
  cardIcon: {
    color: "#ac0521",
    height: "32px",
    width: "40px"
  },
  cardContentText: {
    color: "#0D1021",
    fontSize: "22px",
    fontWeight: "500"
  }
}));

export default useStyles;

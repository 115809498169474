import React, { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { DeleteOutlined } from "@material-ui/icons";
import { GridMenuIcon } from "@mui/x-data-grid";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircleIcon from "@mui/icons-material/Circle";
import { Table } from "antd";
import {
  CustomBreadcrumb,
  FormAutoComplete,
  FormSelectField,
  FormTextField,
  MainLayout,
} from "components/common";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { validationRules } from "constants/validationRules";
import {
  Button,
  Divider,
  IconButton,
  Rating,
  Stack,
  Typography,
} from "iiht-b2a-ui-components/build/components";
import {
  createGenericData,
  getGenericDataApi,
  getGenericListApi,
  updateGenericData,
} from "modules/hr";
import { setLoading } from "slices/commonSlice";
import { addressMapper, checkIfObjectNotEmpty } from "utils";
import { AddBoxIcon } from "icons";

const AddInterviewRound = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id: interviewRoundId = undefined } = useParams();
  const [showMore, setShowMore] = useState(false);
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [interviewRoundData, setInterviewRoundData] = useState({});
  const [rating, setRating] = useState(0);
  const [skillList, setSkillList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [interviewTypeList, setInterviewTypeList] = useState([]);
  const [interviewersList, setInterviewersList] = useState([]);
  const [skillRows, setSkillRows] = useState([
    {
      id: 0,
      key: 0,
      number: 0,
      skill_name: "",
    },
  ]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const isEditMode = !!(location.pathname.includes("edit-view") && interviewRoundId);
  const [isSaveBtnDisable, setIsSaveBtnDisable] = useState(isEditMode);
  const { loading } = useSelector((state) => state.common);

  const breadcrumbs = [
    {
      label: "IIHT",
      link: "/dashboard",
    },
    {
      label: "HR",
      link: "/modules/hr",
    },
    {
      label: "Recruitment",
      link: "/modules/hr",
    },
    {
      label: "Masters",
      link: "/modules/hr/masters",
    },
    {
      label: "Interview Round",
      link: "/modules/hr/masters/interview-round",
    },
    {
      label: "New Interview Round",
      link: "",
    },
  ];

  const columns = [
    {
      dataIndex: "number",
      title: "SNo.",
      width: "10%",
      render: (val) => val + 1,
    },
    {
      dataIndex: "",
      title: "Skill",
      width: "20%",
      render: (_val, _row, rowIndex) => {
        return (
          <div style={{ width: "200px" }}>
            <FormSelectField
              name={`skills.${rowIndex}.skill_name`}
              variant="outlined"
              label=""
              size="small"
              fullWidth
              onChange={(e) => {
                setValue(`skills.${rowIndex}.skill_name`, e.target.value);
                setIsSaveBtnDisable(false);
              }}
              options={skillList}
            />
          </div>
        );
      },
    },
    {
      dataIndex: "",
      title: "",
      width: "60%",
    },
  ];

  const defaultValues = {
    round_name: "",
    designation: "",
    interview_type: "",
    interviewers: [],
    description: "",
    skills: [
      {
        skill_name: "",
      },
    ],
  };

  const methods = useForm({
    defaultValues: defaultValues,
  });

  const { handleSubmit, reset, watch, getValues, setValue, control } = methods;

  const { append: skillsAppend, remove: skillsRemove } = useFieldArray({
    control: control,
    name: "skills",
  });

  const descriptionWatch = watch("description");

  const onSubmit = async (data) => {
    dispatch(setLoading({ loading: true }));
    const skills = [];
    data.skills?.forEach((skill) => {
      skill?.skill_name &&
        skills.push({
          skill: skill.skill_name,
        });
    });
    const interviewers = data.interviewers.map((interviewer) => {
      return { user: interviewer.value };
    });
    const payload = {
      doctype: "Interview Round",
      round_name: data.round_name,
      id: interviewRoundId || data.id,
      designation: data.designation,
      interview_type: data.interview_type,
      expected_average_rating: rating * 0.2 ?? 0,
      expected_skill_set: skills,
      interviewers,
    };
    const res = isEditMode ? await updateGenericData(payload) : await createGenericData(payload);
    isEditMode && fetchInterviewRound();
    if (res?.status === 200) {
      toast.success(`Interview Round ${isEditMode ? "Updated" : "Created"}!`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsSaveBtnDisable(true);
      setIsFormSubmited(true);
    } else {
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    dispatch(setLoading({ loading: false }));
  };

  const fetchInterviewRound = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Interview Round",
      id: interviewRoundId,
    };
    const res = await getGenericDataApi(params);
    if (res?.status === 200) {
      const data = res?.data?.message;
      setInterviewRoundData(data);
      const skills = [];
      const rows = [];
      let interviewers = [];
      if (data?.interviewers?.length) {
        interviewers = addressMapper(data?.interviewers, "user", "user", true);
      }
      if (data?.expected_skill_set?.length) {
        data.expected_skill_set.forEach((skillData, index) => {
          rows.push({
            id: index,
            key: index,
            number: index,
            skill_name: "",
          });
          skills.push({
            skill_name: skillData.skill,
          });
        });
      }
      setSkillRows(rows);
      setRating(data?.expected_average_rating / 0.2 ?? 0);
      reset({
        round_name: data.round_name,
        designation: data?.designation ?? "",
        interview_type: data?.interview_type ?? "",
        skills: skills,
        interviewers: interviewers,
      });
    }
    dispatch(setLoading({ loading: false }));
  };

  const fetchSkillsList = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Skill",
      fields: JSON.stringify(["*"]),
      filters: [],
      limit: "*",
    };
    const res = await getGenericListApi(params);
    if (res?.status === 200) {
      if (res.data?.message?.length) {
        const data = addressMapper(res.data.message, "skill_name", "id", true);
        setSkillList(data);
      }
    }
    await fetchDesignationList();
    dispatch(setLoading({ loading: false }));
  };

  const fetchDesignationList = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Designation",
      fields: JSON.stringify(["*"]),
      filters: [],
      limit: "*",
    };
    const res = await getGenericListApi(params);
    if (res?.status === 200) {
      if (res.data?.message?.length) {
        const data = addressMapper(res.data.message, "designation_name", "id", true);
        setDesignationList(data);
      }
    }
    await fetchInterviewersList();
    dispatch(setLoading({ loading: false }));
  };

  const fetchInterviewersList = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "User",
      fields: JSON.stringify(["*"]),
      filters: [],
      limit: "*",
    };
    const res = await getGenericListApi(params);
    if (res?.status === 200) {
      if (res.data?.message?.length) {
        const data = addressMapper(res.data.message, "id", "id", true);
        setInterviewersList(data);
      }
    }
    await fetchInterviewTypeList();
    dispatch(setLoading({ loading: false }));
  };

  const fetchInterviewTypeList = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Interview Type",
      fields: JSON.stringify(["*"]),
      filters: [],
      limit: "*",
    };
    const res = await getGenericListApi(params);
    if (res?.status === 200) {
      if (res.data?.message?.length) {
        const data = addressMapper(res.data.message, "name", "id", true);
        setInterviewTypeList(data);
      }
    }
    isEditMode && fetchInterviewRound();
    dispatch(setLoading({ loading: false }));
  };

  const onSelectChange = (selectedKeys, selectedRowsData) => {
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      id: record.id,
    }),
  };

  const handleAddRow = () => {
    setSkillRows((prev) => {
      return [
        ...prev,
        {
          id: skillRows.length,
          key: skillRows.length,
          number: skillRows.length,
          skill_name: "",
        },
      ];
    });
    skillsAppend({
      skill_name: "",
    });
  };

  const handleDeleteRow = () => {
    const skillData = [...skillRows];
    const data = [];
    let i = 0;
    skillData.forEach((skill, index) => {
      if (!selectedRowKeys.includes(skill.key)) {
        data.push({ ...skill, id: i, key: i, number: i });
        i++;
      }
    });
    setSkillRows(data);
    skillsRemove(selectedRowKeys);
    setSelectedRowKeys([]);
    const { skills } = getValues();
    reset({
      ...getValues(),
      skills: skills,
    });
    setIsSaveBtnDisable(false);
  };

  //   useEffect(() => {
  //     if (isEditMode) {
  //       fetchInterviewRound();
  //     }
  //   }, [isEditMode]);

  useEffect(() => {
    if (checkIfObjectNotEmpty(interviewRoundData)) {
      const { description } = getValues();
      description !== interviewRoundData?.description
        ? setIsSaveBtnDisable(false)
        : setIsSaveBtnDisable(true);
    }
  }, [descriptionWatch, interviewRoundData]);

  useEffect(() => {
    fetchSkillsList();
  }, []);

  const dropdownSx = {
    color: "#1C1B1F",
    backgroundColor: "#F3F3F3",
    borderRadius: "4px",
    height: "24px",
    width: "24px",
  };

  return (
    <MainLayout>
      {loading && <LoadingBackdrop open={loading} />}
      <div className="interviewTypeContainer">
        <CustomBreadcrumb breadcrumbs={breadcrumbs} />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={1} alignItems="center">
                <GridMenuIcon className="gridIcon" />
                <Typography color="#0D1021" fontSize={22}>
                  {isEditMode ? "View Interview Round" : "New Interview Round"}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={3}>
                <Button
                  variant="contained"
                  color="grey"
                  className="qa-save-btn"
                  size="large"
                  onClick={() => navigate("/modules/hr/masters/interview-round")}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="qa-save-btn"
                  disabled={isSaveBtnDisable}
                >
                  {isEditMode ? "Update" : "Save"}
                </Button>
              </Stack>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              spacing={6}
              sx={{ maxWidth: "40rem", mt: 4 }}
            >
              <Stack sx={{ width: "300px" }}>
                <Typography variant="bodyText" color="#012148" fontWeight={500} sx={{ mb: 1 }}>
                  Round Name*
                </Typography>
                <FormTextField
                  name="round_name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={isEditMode || isFormSubmited}
                  rules={{
                    required: validationRules.REQUIRED,
                  }}
                />
              </Stack>
              <Stack sx={{ width: "300px" }}>
                <Typography variant="bodyText" color="#012148" fontWeight={500} sx={{ mb: 1 }}>
                  Designation
                </Typography>
                <FormSelectField
                  name="designation"
                  variant="outlined"
                  label=""
                  size="small"
                  fullWidth
                  options={designationList}
                  onChange={(e) => {
                    setValue("designation", e.target.value);
                    setIsSaveBtnDisable(false);
                  }}
                />
              </Stack>
            </Stack>

            <Stack direction="row" alignItems="start" spacing={6} sx={{ maxWidth: "40rem", mt: 2 }}>
              <Stack sx={{ width: "300px" }}>
                <Typography variant="bodyText" color="#012148" fontWeight={500} sx={{ mb: 1 }}>
                  Interview Type
                </Typography>
                <FormSelectField
                  name="interview_type"
                  variant="outlined"
                  label=""
                  size="small"
                  fullWidth
                  options={interviewTypeList}
                  onChange={(e) => {
                    setValue("interview_type", e.target.value);
                    setIsSaveBtnDisable(false);
                  }}
                />
              </Stack>
              <Stack sx={{ width: "300px" }}>
                <Typography variant="bodyText" color="#012148" fontWeight={500} sx={{ mb: 1 }}>
                  Expected Average Rating
                </Typography>
                <Rating
                  name="expected_average_rating"
                  value={rating}
                  onChange={(event, value) => {
                    setRating(value);
                    setIsSaveBtnDisable(false);
                  }}
                />
              </Stack>
            </Stack>

            <Stack sx={{ width: "300px", mt: 2 }}>
              <Typography variant="bodyText" color="#012148" fontWeight={500} sx={{ mb: 1 }}>
                Interviewers
              </Typography>
              <FormAutoComplete
                name="interviewers"
                variant="outlined"
                size="small"
                fullWidth
                multiple
                className="qa-interviewers"
                getOptionLabel={(option) => option?.label ?? ""}
                options={interviewersList}
                onSelectionChange={() => setIsSaveBtnDisable(false)}
                freeSolo={false}
                includeInputInList={true}
                isOptionEqualToValue={(option, newValue) => option.key === newValue.key}
                totalCount={interviewersList?.length || 0}
              />
            </Stack>

            <div className="mt8">
              <Typography color="#0D1021" fontSize={16} fontWeight="500" sx={{ my: 1 }}>
                Expected Skillset
              </Typography>
              <Table
                rowSelection={{ type: "checkbox", ...rowSelection }}
                columns={columns}
                dataSource={skillRows}
                size="small"
                pagination={false}
              />
              <div className="mt1">
                <IconButton color="primary" onClick={handleAddRow}>
                  <AddBoxIcon fill="#A32930" />
                </IconButton>
                {selectedRowKeys.length > 0 && (
                  <IconButton aria-label="delete" color="primary" onClick={handleDeleteRow}>
                    <DeleteOutlined />
                  </IconButton>
                )}
              </div>
            </div>
          </form>
        </FormProvider>

        {interviewRoundId && (
          <div className="mt6 mb20">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography color="#000" variant="fieldHeadings">
                Activity
              </Typography>
              {showMore ? (
                <IconButton variant="contained" sx={dropdownSx} onClick={() => setShowMore(false)}>
                  <ExpandLessIcon />
                </IconButton>
              ) : (
                <IconButton variant="contained" sx={dropdownSx} onClick={() => setShowMore(true)}>
                  <ExpandMoreIcon />
                </IconButton>
              )}
            </Stack>
            {showMore && (
              <Stack direction="row" spacing={3} sx={{ height: "40px", mt: 2 }}>
                <Stack direction="column" justifyContent="start" alignItems="center" spacing={1}>
                  <CircleIcon sx={{ fontSize: "6px" }} />
                  <Divider orientation="vertical" fullWidth sx={{ borderColor: "#646464" }} />
                  <CircleIcon sx={{ fontSize: "6px" }} />
                </Stack>
                <Stack direction="column" justifyContent="space-between" sx={{ height: "70px" }}>
                  <Typography color="#696969" fontSize={12}>
                    Created <CircleIcon sx={{ fontSize: "8px", color: "#696969" }} />{" "}
                    {moment(interviewRoundData?.creation).format("LLL")}
                  </Typography>
                  <Typography color="#696969" fontSize={12}>
                    Edited <CircleIcon sx={{ fontSize: "8px", color: "#696969" }} />{" "}
                    {moment(interviewRoundData?.modified).format("LLL")}
                  </Typography>
                </Stack>
              </Stack>
            )}
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default AddInterviewRound;

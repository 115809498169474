import React from "react";
import NonActiveIcon from "../../../src/assets/images/non-active-sidebar-icon.svg";

import FeeCategory from "../../components/Finance/FeeCategory";
import FeeStructure from "../../components/Finance/FeeStructure";
import FeeDashboard from "../../components/Finance/FeeDashboard";
import FeeSchedule from "../../components/Finance/FeeSchedule";
import PaymentRequest from "../../components/Finance/PaymentRequest";
import PaymentEntry from "../../components/Finance/PaymentEntry";
import Fees from "../../components/Finance/Fees";
import SideBar from "components/SideBar";

const sidebarContent = [
  { label: "Dashboard", icon: NonActiveIcon },
  { label: "Category", icon: NonActiveIcon },
  { label: "Structure", icon: NonActiveIcon },
  { label: "Schedule", icon: NonActiveIcon },
  { label: "Fees", icon: NonActiveIcon },
  { label: "Payment Request", icon: NonActiveIcon },
  { label: "Payment Entry", icon: NonActiveIcon },
];

const modules = [
  FeeDashboard,
  FeeCategory,
  FeeStructure,
  FeeSchedule,
  Fees,
  PaymentRequest,
  PaymentEntry,
];

const FinanceContainer = () => {
  return <SideBar sidebarContent={sidebarContent} modules={modules} />;
};

export default FinanceContainer;

import React from "react";
import SideBar from "components/SideBar";
import Dashboard from "../../assets/images/icon-dashboard.svg";
import Recruitment from "../../assets/images/icon-master.svg";
import IQACDashboard from "components/IQAC/IQACDashboard";
import DocumentManagement from "components/IQAC/DocumentManagement";

const sidebarContent = [
  { label: "Dashboard", icon: Dashboard },
  { label: "Document Management", icon: Recruitment },
  //   { label: "Leave Management", icon: LeaveManagement },
  //   { label: "Attendance", icon: Attendance },
  //   { label: "Payroll", icon: Payroll },
  //   { label: "Performance", icon: Performance },
];

const modules = [
  IQACDashboard,
  DocumentManagement,
  //   LeaveManagementComponent,
  //   AttendanceComponent,
  //   PayrollComponent,
  //   PerformanceComponent,
];

const IQACContainer = () => {
  return <SideBar sidebarContent={sidebarContent} modules={modules} />;
};

export default IQACContainer;

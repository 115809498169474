import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "iiht-b2a-ui-components/build/components";
import CloseIcon from "@mui/icons-material/Close";
import { createNote } from "modules/lead";
import { theme } from "themes";
import { FormTextField } from "components/common";
import { validationRules } from "constants/validationRules";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "slices/commonSlice";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";

const AddNote = ({ open, onClose, uniqueId }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);

  const defaultValues = {
    note: "",
  };

  const methods = useForm({
    defaultValues: defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = async (data) => {
    dispatch(setLoading({ loading: true }));

    const payload = {
      data: {
        ...data,
        parent: uniqueId,
        parenttype: "Job Applicant",
        doctype: "CRM Note",
      },
    };
    const res = await createNote({ payload });
    if (res.status === 200) {
      onClose(!open);
      toast.success("Note created Successfully !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(res.data?.exc_type, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    dispatch(setLoading({ loading: false }));
  };

  return (
    <Dialog open={open} onBackdropClick={onClose} maxWidth={200} fullWidth={true}>
      {loading && <LoadingBackdrop open={loading} />}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle sx={{ m: 0, p: 2 }} onClose={onClose}>
            Add Notes
            <IconButton
              aria-label="onClose"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 9,
              }}
              className="qa-onClose-icon"
            >
              <CloseIcon fill={theme.palette.secondary.light} />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers={false}>
            <Typography color="#012148" fontSize={14} fontWeight="500">
              Enter Text *
            </Typography>
            <FormTextField
              name="note"
              variant="outlined"
              size="small"
              className="mt1"
              placeholder="Enter Text here..."
              fullWidth
              multiline
              rows={15}
              rules={{
                required: validationRules.REQUIRED,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="grayed" onClick={onClose} className="qa-cancel-btn">
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit" className="qa-submit-btn">
              Save Message
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default AddNote;

import React, { useState } from 'react';
import useStyles from './style';
import { useNavigate } from 'react-router-dom';
import { GridMenuIcon } from '@mui/x-data-grid';
import MainHeader from '../../../common/MainHeader';
import MainFooter from '../../../common/MainFooter';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveSidebar } from '../../../../slices/financeSlice';
import { createFeeCategory } from '../../../../modules/finance';
import { setLoading } from "../../../../slices/commonSlice";
import LoadingBackdrop from "../../../common/LoadingBackdrop.js";
import { toast } from 'react-toastify';
import { Select, Input, Button, Checkbox, Rate } from "antd";
import { createFeedbackTemplate } from 'modules/survey';


const AddNewFeeCategory = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [showNewFeedback, setShowNewFeedback] = useState(false);
  const { TextArea } = Input;
  const [values, setValues] = useState({
    template_name: "",
    assigned_based_on: "",
    questions:[]
  });
  const [errors, setErrors] = useState({
    template_name: "",
    assigned_based_on: "",
    questions:[]
  });
  const [questions, setQuestions] = useState([]);  

  const handleFeeCategoryNavigation = () => {
    navigate(-1);
  };

  const handleSave = async () => {
    
    const errors = {};
    if (!values.template_name) {
      errors.template_name = "Template Name is required";
    }
    if (!values.assigned_based_on) {
      errors.assigned_based_on = "Assigned Based On is required";
    }
    if (questions.length === 0) {
      errors.questions = "At least one question is required";
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    dispatch(setLoading({ loading: true }));
        const payload = {
            data: {
              template_name: values.template_name,
              assigned_based_on: values.assigned_based_on,
              questions: questions.map((question) => ({
                question: question.question,
                question_based_on: question.type,
                options:question.options
              }))
            }
          };
      try {
        const res =  await createFeedbackTemplate( payload );
        dispatch(setLoading({ loading: false }));
        if(res.status === 200) {
          toast.success("Created the Feedback Template");
          navigate(`/modules/survey`);
        } else {
          dispatch(setLoading({ loading: false }));
          toast.error("Error while creating Feedback Template!")
        }
      } catch (error) {
        dispatch(setLoading({ loading: false }));
        toast.error(error.response.data?.exc_type || "Error while creating Feedback Template!")
      }
      dispatch(setLoading({ loading: false }))
    }

  const addQuestion = () => {
    setQuestions([...questions, { question: "", type: "Select", options: ["", "", "", ""], correctAnswer: [false, false, false, false] }]);
  };

  const removeQuestion = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][field] = value;
    setQuestions(updatedQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options[optionIndex] = value;
    setQuestions(updatedQuestions);
  };

  const handleCorrectAnswerChange = (questionIndex, optionIndex, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].correctAnswer[optionIndex] = value;
    setQuestions(updatedQuestions);
  };
  
  const handleTextareaChange = (index, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][field] = value;
    setQuestions(updatedQuestions);
  };
  
  const handleRatingChange = (index, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][field] = value;
    setQuestions(updatedQuestions);
  };

  const handleCreateQuestionClick = () => {
    setShowNewFeedback(true); // Show the New Feedback section when Create Question button is clicked
    addQuestion(); // Also add a new question
  };

  

  return (
    <>
      <MainHeader />
      {
        loading ?
          <LoadingBackdrop open={loading} /> :
          <div className={styles.mainContainer}>
            <div className={styles.breadCrumbsContainer}>
              <p className={styles.breadCrumbsContent} onClick={() => navigate("/dashboard")}>IIHT {" > "}</p>
              <p className={styles.breadCrumbsContent} onClick={() => navigate("/modules/survey")}>Survey {" > "}</p>
              <p className={styles.breadCrumbsContent} onClick={handleFeeCategoryNavigation}>New Feedback Template</p>
            </div>
            <div className={styles.headerContainer}>
              <div className={styles.breadCrumbsContainer}>
                <GridMenuIcon className={styles.gridIcon} />
                <p className={styles.title}>New Feedback Template</p>
              </div>
              <div className={styles.buttonsGroup}>
                <div className={styles.button} onClick={handleFeeCategoryNavigation}>
                  <p className={styles.buttonText}>Back</p>
                </div>
                <div className={styles.activeButton} onClick={handleSave}>
                  <p className={styles.activeButtonText}>Save</p>
                </div>
              </div>
            </div>
            <div className={styles.formContainer}>
            <div className={styles.section}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Template Name *</p>
                <input
                  type="text"
                  placeholder="Template Name"
                  className={styles.fieldInput}
                  value={values.template_name}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      template_name: e.target.value
                    });
                    setErrors({
                      ...errors,
                      template_name: ""
                    });
                  }}
                />
                {
                  errors.template_name &&
                  <p className={styles.errorMessage}>{errors.template_name}</p>
                }
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Assigned Based On *</p>
                <Select
                  placeholder="Assign"
                  value={values.assigned_based_on}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      assigned_based_on: value
                    });
                    setErrors({
                      ...errors,
                      assigned_based_on: ""
                    });
                  }}
                >
                  <Select.Option value="Student">Student</Select.Option>
                  <Select.Option value="Faculty">Faculty</Select.Option>
                </Select>
                {
                  errors.assigned_based_on &&
                  <p className={styles.errorMessage}>{errors.assigned_based_on}</p>
                }
              </div>
              </div>
              <div>
                <div className={styles.buttonsGroup}>
                    <div  className={styles.activeButtons}  onClick={handleCreateQuestionClick}>
                    <p className={styles.activeButtonText}>Create Questions</p>
                    </div>
                </div>
              </div>
              {showNewFeedback && (
                <>
              <div className={styles.formContainer}>
                <p className={styles.QuestionHeader}>New Feedback Questions</p>
                <hr className={styles.line}></hr>
              </div>
              {questions.map((question, index) => (
                <div key={index}>
                    <div className={styles.field}>
                    <p className={styles.fieldLabel}>Question Type *</p>
                    <Select
                        value={question.type}
                        onChange={(value) => handleQuestionChange(index, 'type', value)}
                        placeholder="Question Type"
                    >
                        <Select.Option value="Select">Select</Select.Option>
                        <Select.Option value="Input">Input</Select.Option>
                        <Select.Option value="Rating">Rating</Select.Option>
                    </Select>
                    </div>
                    {question.type === "Choose" && (
                        <>   
                        </>
                    )}
                    {question.type === "Select" && (
                        <>
                        <div className={styles.fields}>
                        <p className={styles.fieldLabel}>Question *</p>
                        <Input
                            value={question.question}
                            onChange={(e) => handleQuestionChange(index, 'question', e.target.value)}
                            className={styles.fieldInput}                    
                        />
                        </div>
                    <div className={styles.fields}>
                        <p className={styles.fieldLabel}>Options *</p>
                        {question.options.map((option, optionIndex,i) => (
                        <div key={optionIndex}>
                            <p>Option {optionIndex+1}</p>
                            <Input
                            value={option}
                            onChange={(e) => handleOptionChange(index, optionIndex, e.target.value)}
                            className={styles.fieldInput}                    
                            />
                        </div>
                        ))}
                    </div>
                    </>
                    )}
                    {question.type === "Input" && (
                        <>
                        <div className={styles.fields}>
                        <p className={styles.fieldLabel}>Question *</p>
                        <Input
                            value={question.question}
                            onChange={(e) => handleQuestionChange(index, 'question', e.target.value)}
                            className={styles.fieldInput}                    
                        />
                        </div>
                    {/* <div className={styles.fields}>
                        <p className={styles.fieldLabel}>Textarea *</p>
                        <TextArea
                        value={question.textarea}
                        onChange={(e) => handleTextareaChange(index, 'textarea', e.target.value)}
                        className={styles.textAreaInput}
                        />
                    </div> */}
                    </>
                    )}
                    {question.type === "Rating" && (
                        <>
                    <div className={styles.fields}>
                    <p className={styles.fieldLabel}>Question *</p>
                    <Input
                        value={question.question}
                        onChange={(e) => handleQuestionChange(index, 'question', e.target.value)}
                        className={styles.fieldInput}                    
                    />
                    </div>
                    {/* <div className={styles.field}>
                        <p className={styles.fieldLabel}>Ratings *</p>
                        <Rate
                        value={question.stars}
                        onChange={(value) => handleRatingChange(index, 'stars', value)}
                        count={5} // Number of stars
                        />
                    </div> */}
                    </>
                    )}
                    <div className={styles.buttonsGroupra}>
                        <div onClick={() => removeQuestion(index)} className={styles.button}>
                           <div className={styles.buttonText}>Remove Question</div> 
                        </div>
                        <div onClick={addQuestion} className={styles.activeButton}>
                            <div className={styles.activeButtonText}>Add Question</div>
                        </div>
                    </div>
                </div>
                ))}
                </>
              )}
            </div>
          </div>
      }
      <MainFooter />
    </>
  );
};

export default AddNewFeeCategory;

import React, { useState } from "react";
import { Link, Route, Routes, useParams, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { GridMenuIcon } from "@mui/x-data-grid";
import { ReactSVG } from "react-svg";
import { fieldsData } from "./recruitlistingJson";
import "./recruitment.scss";
import { Breadcrumbs, MainLayout } from "components/common";
 

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function MastersLanding() {
  const location = useLocation();
  const { entityType } = useParams();
  const [currentEntityType, setCurrentEntityType] = useState(entityType);

  const handleSetFields = (fields, id) => {
    setCurrentEntityType(id);
  };

  // Call handleSetFields when the component mounts
  React.useEffect(() => {
    handleSetFields(fieldsData, currentEntityType);
  }, [location.pathname]); // Update when the pathname changes

  const CustomToolbar = () => {
    return (
      <>
      <Breadcrumbs />
        <div className="master-heading">
          <h2>
            <GridMenuIcon />
            {`Recruitment`}
          </h2>
        </div>
      </>
    );
  };

  return (
    <>
      <CustomToolbar />
      <Box
        component="div"
        sx={{ flexGrow: 1, p: 3 }}
        className="mainPanel noPadding"
      >
        <>
          <div className="MasterListButtons">
            {fieldsData.map((field, index) => (
              <Link to={`${field.link}`} key={index}>
                <ReactSVG src={field.icon} className="mastIcon" />
                {field.name}
              </Link>
            ))}
          </div>
          <Routes>
            <Route path="*" element={<handleEntityList />} />
          </Routes>
        </>
      </Box>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Table } from "antd";
import eventBus from "core/eventBus";
import { toast } from "react-toastify";
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from '@mui/x-data-grid';
import { setToggle } from "slices/userSlice";
import { formatDateTODDMMMYYYY } from "utils/date";
import { useDispatch, useSelector } from "react-redux";
import { getApplicationFormListing } from "modules/student";
import { razorpayCheckout } from "modules/razorpayCheckOut";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import LoadingBackdrop from "components/common/LoadingBackdrop.js/index";
import AcceptOfferLetterDialog from "components/common/AcceptOfferLetter";
import useStyles from "./style";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#AC0521",
    minWidth: "max-content"
  },
}));

const StudentApplicationDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const styles = useStyles();
  const user = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.common);
  const [applicationForms, setApplicationForms] = useState([]);
  const [selectedApplicationNumber, setSelectedApplicationNumber] = useState("");
  const [showResubmitButton, setShowResumbitButton] = useState(false);

  useEffect(() => {
    handleGetApplicationFormListing();
    window.localStorage.removeItem("application-name");
  }, [user.toggle]);

  const handleGetApplicationFormListing = async () => {
    const payload = {
      user: user.email,
    };

    const res = await getApplicationFormListing({ payload });
    if (res.status === 200) {
      let arr = [];
      const val = res?.data?.message?.message;

      for (let i = 0; i < val.length; i++) {
        arr.push({
          ...val[i],
          id: i,
        });
      }

      setApplicationForms(arr);
    }
  }; 

  const getWorkflow = (currentWorkflow) => {
    switch(currentWorkflow) {
      case "Submitted": 
        return { color: "#4BAF4F", text: "Submitted", metaText: "", toPay: false, toAccept: false };
      case "Under Review": 
        return { color: "#FFA63E", text: "Under Review", metaText: "", toPay: false, toAccept: false };
      case "Document Requested": 
        return { color: "#F50E1C", text: "Document Requested", metaText: "Click to Re-submit", toPay: false, toAccept: false };
      case "Document Re-submitted": 
        return { color: "#FFA63E", text: "Document Re-submitted", metaText: "Documents under review", toPay: false, toAccept: false };
      case "Document Verified": 
        return { color: "#4BAF4F", text: "Document Verified", metaText: "", toPay: false, toAccept: false };
      case "Qualified": 
        return { color: "#4BAF4F", text: "Qualified", metaText: "", toPay: false, toAccept: false };
      case "Payment Requested": 
      return { color: "#2C83EC", text: "Payment Requested", metaText: "Pay Tution Fee", toPay: true, toAccept: false };
      case "Rejected": 
        return { color: "#F50E1C", text: "Rejected", metaText: "", toPay: false, toAccept: false };
      case "Paid": 
        return { color: "#4BAF4F", text: "Paid", metaText: "", toPay: false, toAccept: false }; 
      case "Offer Letter Released": 
        return { color: "#4BAF4F", text: "Offer Letter Sent", metaText: "Accept Offer Letter", toPay: false, toAccept: true };
      case "Offer Accepted": 
        return { color: "#4BAF4F", text: "Offer Accepted", metaText: "", toPay: false, toAccept: false };
      case "Admitted": 
        return { color: "#4BAF4F", text: "Admitted", metaText: "", toPay: false, toAccept: false };   
    }
  }
  
  const columns = [
    { dataIndex: "application_form", title: "Application Form", width: "200" },
    { 
      dataIndex: "application_number", 
      title: "Application No.", 
      width: "200",
      render: (val, row) => {
        const { application_number, program_group } = row;
        return (
          <div className={styles.tableContentFields}>{application_number ? `${program_group}-${application_number?.split("-")[3]}` : '-'}</div>
        )
      }
    },
    { dataIndex: "academic_year", title: "Academic Year", width: "200" },
    { dataIndex: "submission_date", title: "Submission Date", width: "150",
      render: (val, row) => {
        const { submission_date } = row;
        return (
          <div className={styles.tableContentFields}>{submission_date ? formatDateTODDMMMYYYY(submission_date) : '-'}</div>
        )} 
    },
    { dataIndex: "final_date", title: "Last Date", width: "150", 
      render: (val, row) => {
        const { final_date } = row;
        return (
          <div className={styles.tableContentFields}>{final_date ? formatDateTODDMMMYYYY(final_date) : '-'}</div>
        )}
    },
    { 
      dataIndex: "application_fees", 
      title: "Application Fee", 
      width: "150" ,
      render: (val, row) => {
        return (
          <LightTooltip title={
            <div style={{ display: "flex", flexDirection: "column", gap: "14px", borderRadius: "8px", padding: "18px 14px",  }}>
              <p style={{ fontSize: "18px", fontWeight: "600", textAlign: "center", color: "#FFFFFF" }}>Application Fee!</p>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p style={{ fontSize: "14px", fontWeight: "500", color: "#FFFFFF", textAlign: "start" }}>For Regular Admission - {row.Regular}</p>
                <p style={{ fontSize: "14px", fontWeight: "500", color: "#FFFFFF", textAlign: "start" }}>For Self-supported Admission - {row["Self Supporting"]}</p>
              </div>
            </div>
          }>
            <div style={{ color: "#0D1021", fontSize: "14px", fontWeight: "400", cursor: "pointer" }}>View</div>
          </LightTooltip>
        )
      }
    },
    {
      dataIndex: "percent_filled",
      title: "Action",
      width: "200",
      render: (val, row) => {
        const { program, program_group, academic_year, percent_filled, application_number, application_fees, workflow_state } = row;
        const isFeePaid = application_fees == "paid" ? true: false;
        return (
          <>
            {
              percent_filled ?  
                <div
                  onClick={() => {
                    if(workflow_state == "Draft") {
                      navigate("/applicationForm", {
                        state: {
                          program,
                          programGroup: program_group,
                          academicYear: academic_year,
                          applicationNumber: application_number,
                          percentFilled: percent_filled,
                          isFeePaid: isFeePaid
                        }
                      })
                    } else {
                      if(getWorkflow(workflow_state).toPay) {
                        razorpayCheckout({ studentApplicantId: application_number, applicationFee: 0 }).then((resp) => {
                          if (resp.message === "Success") {
                            dispatch(setToggle({ toggle: !user.toggle }))
                            toast.success("Paid");
                          }
                        });
                      } else if(getWorkflow(workflow_state).toAccept) {
                        eventBus.emit("open-dialog", <AcceptOfferLetterDialog uniqueId={application_number}/>);
                      } else {
                        setSelectedApplicationNumber(application_number);
                        navigate(`/student/application/${application_number}`, {
                          state: {
                            uniqueId: application_number, 
                            showResubmitButton: workflow_state == "Document Requested" ? true : false,
                        }})
                        if(workflow_state == "Document Requested") {
                          setShowResumbitButton(true);
                        }
                      }
                    }
                  }}
                  variant="contained"
                  style={{ cursor: "pointer", display: "flex", flexDirection: "column", alignItems: "flex-start" }}
                >
                  {
                    workflow_state != "Draft" ? 
                    <span style={{ color: getWorkflow(workflow_state).color, fontSize: "14px", fontWeight: "500" }}>{getWorkflow(workflow_state).text}</span> : 
                    <span style={{ color: "#2C83EC", fontSize: "14px", fontWeight: "500" }}>Continue</span>
                  }
                  {
                    workflow_state != "Draft" ? 
                    <span style={{ color: "#6F6F6F", fontSize: "12px", fontWeight: "500" }}>{getWorkflow(workflow_state).metaText}</span> : 
                    <span style={{ color: "#6F6F6F", fontSize: "12px", fontWeight: "500" }}>{percent_filled}% complete</span>
                  }
                </div> :
                <div
                onClick={() => {
                  navigate("/applicationForm", {
                    state: {
                      program,
                      programGroup: program_group,
                      academicYear: academic_year,
                      applicationNumber: ""
                    }
                  })
                }}
                variant="contained"
                style={{ color: "#2C83EC", fontSize: "14px", fontWeight: "500", cursor: "pointer" }}
              >
                Apply now
              </div>
            }
          </>
        );
      },
    },
    { 
      dataIndex: "id",
      title: "Document", 
      width: "150",
      render: () => {
        return (
          <div className={styles.tableContentFields}>Brochure.pdf</div>
        )
      }  
    },
  ];

  return (
    <>
      {
        loading ?
        <LoadingBackdrop open={loading} /> :
        <div className={styles.mainContainer}>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon}/>
              <p className={styles.title}>Applications</p>
            </div>
          </div>
          {
            applicationForms && applicationForms.length > 0 ? (
              <div className={styles.content}>
                <Table 
                  columns={columns} 
                  dataSource={applicationForms} 
                  size='small'
                  pagination={true}
                />
                <p className={styles.tableContentFields}>
                  If you have any queries relating to the Application Form
                  please write to <a href="mailTo:help@university.com">help@university.com</a>
                </p>
              </div>
            ) : (
              <p className={styles.tableContentFields}>No Application Forms yet</p>
            )
          }
        </div>
      }
    </>
  );
}

export default StudentApplicationDashboard;
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import moment from "moment";
import { Stack, Typography } from "iiht-b2a-ui-components/build/components";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { setLoading } from "slices/commonSlice";
import { getEmailListApi } from "modules/hr";
import "./EmailList.scss";

const EmailList = ({ uniqueId, showEmailView }) => {
  const dispatch = useDispatch();
  const [selectedMailType, setSelectedMailType] = useState("all_emails");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const { loading } = useSelector((state) => state.common);

  const emialsTabs = [
    {
      key: 1,
      id: 1,
      label: "All Emails",
      value: "all_emails",
      type: undefined,
    },
    {
      key: 2,
      id: 2,
      label: "Sent",
      value: "sent",
      type: "Sent",
    },
    {
      key: 3,
      id: 3,
      label: "Received",
      value: "received",
      type: "Received",
    },
  ];

  const columns = [
    {
      dataIndex: "subject",
      title: (
        <Stack direction="row" spacing={4}>
          {emialsTabs.map((tab) => {
            return (
              <Typography
                fontSize="12px"
                color={selectedMailType === tab.value ? "#A32930" : "#647B8F"}
                fontWeight={500}
                className="mouse-pointer"
                onClick={() => handleEmailTab(tab.value, tab.type)}
              >
                {tab.label}
              </Typography>
            );
          })}
        </Stack>
      ),
      width: "80%",
      render: (val, row) => (
        <Stack direction="row">
          <Typography fontSize="15px" color="#0D1021" fontWeight={400} sx={{ width: "25%" }}>
            {row?.owner ?? ""}
          </Typography>
          <Typography fontSize="15px" color="#0D1021" fontWeight={400} sx={{ width: "75%" }}>
            {val}
          </Typography>
        </Stack>
      ),
    },
    {
      dataIndex: "communication_date",
      width: "15%",
      render: (val) =>
        val ? (
          <Typography fontSize="15px" color="#0D1021" fontWeight={500}>
            {moment(val).format("L")}
          </Typography>
        ) : (
          <></>
        ),
    },
  ];

  const onSelectChange = (selectedKeys, selectedRowData) => {
    setSelectedRowKeys(selectedKeys);
    // let selectedDataIds = [];
    // selectedRowData.map((rowData) => selectedDataIds.push(rowData.id));
  };

  const handleEmailTab = (value, type) => {
    setSelectedMailType(value);
    fetchEmailList(type);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      id: record.id,
    }),
  };

  const rowProps = (record, index) => {
    return {
      onClick: () => handleRowClick(record, index),
    };
  };

  const handleRowClick = (record, index) => {
    showEmailView(record);
  };

  const fetchEmailList = async (type = undefined) => {
    dispatch(setLoading({ loading: true }));
    const params = {
      fields: JSON.stringify(["*"]),
      filters: JSON.stringify([
        ["reference_name", "=", uniqueId],
        ["sent_or_received", "=", type],
      ]),
      order_by: "creation desc",
      limit: "*",
    };
    const res = await getEmailListApi(params);
    if (res.status === 200) {
      res?.data?.data?.length ? setEmailList(res.data.data) : setEmailList([]);
    }
    dispatch(setLoading({ loading: false }));
  };

  useEffect(() => {
    fetchEmailList();
  }, []);

  return (
    <>
      {loading && <LoadingBackdrop open={loading} />}
      <div className="email-list-table mb16">
        <Table
          rowSelection={{ type: "checkbox", ...rowSelection }}
          onRow={rowProps}
          columns={columns}
          dataSource={emailList}
          size="small"
          pagination={true}
          bordered={false}
          rowClassName="email-table-row-height"
        />
      </div>
    </>
  );
};

export default EmailList;

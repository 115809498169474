import React from 'react';
import { Breadcrumbs } from '../../common';
import { Typography, Stack } from 'iiht-b2a-ui-components/build/components';

export const PageTitle = React.memo(
  ({ title = '', crumbs = [], extraComp }) => {
    return (
      <>
        <Stack>
          {crumbs?.length > 0 && (
            <Breadcrumbs aria-label="breadcrumb" crumbs={crumbs} />
          )}

          {title && (
            <Typography variant="h5" className="font-23" gutterBottom>
              {title}
              {extraComp}
            </Typography>
          )}
        </Stack>
      </>
    );
  }
);

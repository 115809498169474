import APIs from "../api";
import axiosInstance from "../translator";

export const createStudentAppForm = async ({ payload }) => {
  try {
    const res = await axiosInstance.post(APIs.createStudentAppForm, payload);
    return res;
  } catch (error) {
    console.log("Error creating Application Form", error);
    return error.response;
  }
};
export const getApplicationsList = async (filters) => {
  try {
    const fields = JSON.stringify(["*"]);

    const response = await axiosInstance.get(
      `${APIs.getApplicationsList}?filters=${filters}&fields=${fields}&limit="*"&order_by=creation%20desc`
    );
    return response;
  } catch (error) {
    console.log("Error getting application form listing", error);
  }
};


export const getApplicationsByFilter = async (filter) => {
  try {
    const fields = JSON.stringify(["*"]);

    const response = await axiosInstance.get(
      `${APIs.getApplicationsList}?filters=${filter}&fields=${fields}&limit="*"&order_by=creation%20desc`
    );
    return response;
  } catch (error) {
    console.log("Error getting application form listing", error);
  }
};


export const createGuardianForm = async ({ payload }) => {
  try {
    const res = await axiosInstance.post(APIs.createGuardianForm, payload);
    return res;
  } catch (error) {
    console.log("Error creating Guardian Form", error);
    return error.response;
  }
};

export const getStudentDetails = async (uniqueId) => {
  try { 
    const res = await axiosInstance.get(
      `${APIs.getStudentDetails}/${uniqueId}`
    );
    return res;
  } catch (error) {
    console.log("Error getting Student Details", error);
  }
};

export const getApplicantDetails = async (uniqueId) => {
  
  try { 
    const res = await axiosInstance.get(
      `${APIs.getApplicantDetails}?name=${uniqueId}`
    );
    return res;
  } catch (error) {
    console.log("Error getting Student Details", error);
  }
};

export const getWorkflowForStudentApplicant = async () => {
  try { 
    const res = await axiosInstance.get(APIs.getWorkflowForStudentApplicant);
    return res;
  } catch (error) {
    console.log("Error getting workflow for student applicant", error);
  }
};
 
export const updateWorkflowStatus = async (uniqueId, action) => {
  try { 
    const response = await axiosInstance.post(APIs.getApplicationStatus, { uniqueId, action });
    return response.data;  
  } catch (error) {
    console.error('Error updating workflow status', error);
    throw error;
  }
};

export const getAddmissionPreferenceType = async () => {
  try {
    const res = await axiosInstance.get(APIs.getAdmissionPreference);
    return res;
  } catch (error) {
    console.log("Error getting Admission Preference", error);
  }
};

export const getProgramlists = async() => {
  try {
    const res = await axiosInstance.get(APIs.getPrograms);
    return res;
  } catch (error) {
    console.log("Error getting programs listing", error);
    return error.response;
  }
}

export const getPaymentReceipt = async ({ doc, name }) => {
  try {
    const res = await axiosInstance.get(`${APIs.getPaymentReceipt}?doc=${doc}&name=${name}`);
    return res;
  } catch (error) {
    console.log("Error getting payment receipt", error);
    return error.response;
  }
}
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GridMenuIcon } from "@mui/x-data-grid";
import { Table } from "antd";
import { Button, Divider, Grid, Stack, Typography } from "iiht-b2a-ui-components/build/components";
import {
  CustomBreadcrumb,
  FormDatepicker,
  FormSelectField,
  FormTextField,
  FormTimepicker,
  MainLayout,
} from "components/common";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { validationRules } from "constants/validationRules";
import {
  createGenericData,
  getGenericDataApi,
  getGenericListApi,
  updateGenericData,
} from "modules/hr";
import { addressMapper, checkIfObjectNotEmpty } from "utils";
import { setLoading } from "slices/commonSlice";

const CreateInterview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const applicantName = location.state?.applicantName ?? "Interview Details";
  const { id: jobApplicantId, jobOpeningId, designationId, interviewId } = useParams();
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [interviewData, setInterviewData] = useState({});
  const [interviewRoundList, setInterviewRoundList] = useState([]);
  const [interviewersList, setInterviewersList] = useState([]);
  const [interviewFeedbackList, setInterviewFeedbackList] = useState([]);
  const [interviewersRows, setInterviewersRows] = useState([
    {
      id: 0,
      key: 0,
      number: 0,
      skill_name: "",
    },
  ]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const isEditMode = !!(location.pathname.includes("edit-view") && jobApplicantId && interviewId);
  const [isSaveBtnDisable, setIsSaveBtnDisable] = useState(isEditMode);
  const { loading } = useSelector((state) => state.common);

  const breadcrumbs = [
    {
      label: "IIHT",
      link: "/dashboard",
    },
    {
      label: "HR",
      link: "/modules/hr",
    },
    {
      label: "Recruitment",
      link: "/modules/hr",
    },
    {
      label: "Job Applications",
      link: "/modules/hr/job-application",
    },
    {
      label: applicantName === "Interview Details" ? jobApplicantId : applicantName,
      link: `/modules/hr/job-application/view/${jobApplicantId}/${jobOpeningId}/${designationId}?tab=3`,
    },
    {
      label: isEditMode ? "View Interview" : "New Interview",
      link: "",
    },
  ];

  const columns = [
    {
      dataIndex: "number",
      title: "SNo.",
      width: "10%",
      render: (_val, _row, rowIndex) => rowIndex + 1,
    },
    {
      dataIndex: "label",
      title: "Interviewer",
      width: "80%",
      render: (val, _row, rowIndex) => {
        return val;
        // <div style={{ width: "200px" }}>
        //   <FormSelectField
        //     name={`interviewers.${rowIndex}.skill_name`}
        //     variant="outlined"
        //     label=""
        //     size="small"
        //     fullWidth
        //     onChange={(e) => {
        //       setValue(`interviewers.${rowIndex}.skill_name`, e.target.value);
        //       setIsSaveBtnDisable(false);
        //     }}
        //     options={interviewersList}
        //   />
        // </div>
      },
    },
    {
      dataIndex: "",
      title: "",
      width: "60%",
    },
  ];

  const feedbackColumns = [
    {
      dataIndex: "number",
      title: "SNo.",
      width: "10%",
      render: (_val, _row, rowIndex) => rowIndex + 1,
    },
    {
      dataIndex: "interviewer",
      title: "Interviewer",
      width: "20%",
    },
    {
      dataIndex: "average_rating",
      title: "Average Rating",
      width: "20%",
      render: (val) => parseFloat((val / 0.2).toFixed(1)),
    },
    {
      dataIndex: "result",
      title: "Status",
      width: "10%",
      render: (val, row) => {
        return (
          <>
            {val && (
              <Typography
                fontSize={12}
                fontWeight={500}
                color={`${
                  val === "Rejected" ? "#ac0521" : val === "Cleared" ? "#4baf4f" : "#2C83EC"
                }`}
                sx={{
                  border: `1px solid ${
                    val === "Rejected" ? "#ac052180" : val === "Cleared" ? "#4baf4f80" : "#2c83ec80"
                  }`,
                  borderRadius: "6px",
                  backgroundColor: `${
                    val === "Rejected" ? "#ac05210d" : val === "Cleared" ? "#4baf4f0d" : "#2c83ec0d"
                  }`,
                  textAlign: "center",
                  padding: "2px",
                  width: "80px",
                }}
              >
                {val}
              </Typography>
            )}
          </>
        );
      },
    },
    {
      dataIndex: "id",
      title: "Action",
      width: "10%",
      render: (val, row) => {
        return (
          <NavLink
            color="secondary"
            variant="body2"
            to={`/modules/hr/job-application/view/interview/feedback/edit-view/${jobApplicantId}/${jobOpeningId}/${designationId}/${interviewId}/${interviewData?.interview_round}/${val}`}
            state={{
              applicantName,
            }}
            className="mouse-pointer qa-view-all view-all"
          >
            View
          </NavLink>
        );
      },
    },
  ];

  const statusOptions = [
    {
      id: 1,
      key: 1,
      label: "Pending",
      value: "Pending",
    },
    {
      id: 2,
      key: 2,
      label: "Under Review",
      value: "Under Review",
    },
    {
      id: 3,
      key: 3,
      label: "Cleared",
      value: "Cleared",
    },
    {
      id: 4,
      key: 4,
      label: "Rejected",
      value: "Rejected",
    },
  ];

  const defaultValues = {
    interview_round: "",
    status: "",
    job_applicant: applicantName,
    // experience: "",
    resume_link: "",
    scheduled_on: "",
    from_time: "",
    to_time: "",
    expected_average_rating: 0,
    interview_summary: "",
    // interviewers: [],
  };

  const methods = useForm({
    defaultValues: defaultValues,
  });

  const { handleSubmit, reset, watch, getValues, setValue } = methods;

  // const { append: skillsAppend, remove: skillsRemove } = useFieldArray({
  //   control: control,
  //   name: "interviewers",
  // });

  // const watchAllFields = watch();
  // const isAnyFieldChanged =
  //   isDirty || Object.values(watchAllFields).some((value) => value !== defaultValues[value]);

  const interviewRoundWatch = watch("interview_round");
  const interviewSummaryWatch = watch("interview_summary");

  const onSubmit = async (data) => {
    dispatch(setLoading({ loading: true }));
    // const interviewers = [];
    // data.interviewers?.forEach((skill) => {
    //   skill?.skill_name &&
    //     interviewers.push({
    //       skill: skill.skill_name,
    //     });
    // });
    const interview_details = interviewersList.map((interviewer) => {
      return {
        interviewer: interviewer.id,
      };
    });
    const payload = {
      ...data,
      doctype: "Interview",
      designation: designationId,
      job_applicant: jobApplicantId,
      interview_details,
      id: interviewId || data.id,
      // interviewers,
    };
    const res = isEditMode ? await updateGenericData(payload) : await createGenericData(payload);
    if (res?.status === 200) {
      isEditMode && fetchInterview();
      toast.success(`Interview ${isEditMode ? "Updated" : "Created"}!`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsSaveBtnDisable(true);
      setIsFormSubmited(true);
    } else {
      toast.error(res?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    dispatch(setLoading({ loading: false }));
  };

  const fetchInterview = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Interview",
      id: interviewId,
    };
    const res = await getGenericDataApi(params);
    if (res?.status === 200) {
      const data = res?.data?.message;
      setInterviewData(data);
      const interviewers = [];
      const rows = [];
      // if (data.interviewers?.length) {
      //   data.interviewers.forEach((skillData, index) => {
      //     rows.push({
      //       id: index,
      //       key: index,
      //       number: index,
      //       skill_name: "",
      //     });
      //     interviewers.push({
      //       skill_name: skillData.skill,
      //     });
      //   });
      // }
      setInterviewersRows(rows);
      reset({
        average_rating: data?.average_rating,
        expected_average_rating: data?.expected_average_rating,
        interview_round: data?.interview_round ?? "",
        status: data?.status,
        resume_link: data?.resume_link,
        scheduled_on: data?.scheduled_on,
        from_time: data?.from_time ?? "",
        to_time: data?.to_time,
        interview_summary: data?.interview_summary,
      });
    }
    dispatch(setLoading({ loading: false }));
  };

  const fetchInterviewRoundList = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Interview Round",
      fields: JSON.stringify(["*"]),
      filters: JSON.stringify([["designation", "=", designationId]]),
      limit: "*",
    };
    const res = await getGenericListApi(params);
    if (res?.status === 200) {
      if (res.data?.message?.length) {
        const list = addressMapper(res.data.message, "round_name", "id", true);
        setInterviewRoundList(list);
      } else {
        setInterviewRoundList([]);
      }
    }
    await fetchInterviewFeedbackList();
  };

  const fetchInterviewFeedbackList = async () => {
    const params = {
      doctype: "Interview Feedback",
      fields: JSON.stringify(["*"]),
      filters: JSON.stringify([["interview", "=", interviewId]]),
      limit: "*",
    };
    const res = await getGenericListApi(params);
    if (res?.status === 200) {
      if (res.data?.message?.length) {
        const list = res.data.message.map((data, i) => {
          return { ...data, key: i };
        });
        setInterviewFeedbackList(list);
      } else {
        setInterviewFeedbackList([]);
      }
    }
    dispatch(setLoading({ loading: false }));
  };

  const fetchInterviewersList = async (id) => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Interview Round",
      id,
    };
    const res = await getGenericDataApi(params);
    if (res?.status === 200) {
      const data = res.data?.message;
      setValue("expected_average_rating", data?.expected_average_rating);
      if (data.interviewers?.length) {
        const list = addressMapper(data.interviewers, "user", "user", true);
        setInterviewersList(list);
      } else {
        setInterviewersList([]);
      }
    }
    dispatch(setLoading({ loading: false }));
  };

  const onSelectChange = (selectedKeys, selectedRowsData) => {
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      id: record.id,
    }),
  };

  // const handleAddRow = () => {
  //   setInterviewersRows((prev) => {
  //     return [
  //       ...prev,
  //       {
  //         id: interviewersRows.length,
  //         key: interviewersRows.length,
  //         number: interviewersRows.length,
  //         skill_name: "",
  //       },
  //     ];
  //   });
  //   skillsAppend({
  //     skill_name: "",
  //   });
  // };

  // const handleDeleteRow = () => {
  //   const skillData = [...interviewersRows];
  //   const data = [];
  //   let i = 0;
  //   skillData.forEach((skill, index) => {
  //     if (!selectedRowKeys.includes(skill.key)) {
  //       data.push({ ...skill, id: i, key: i, number: i });
  //       i++;
  //     }
  //   });
  //   setInterviewersRows(data);
  //   skillsRemove(selectedRowKeys);
  //   setSelectedRowKeys([]);
  //   const { interviewers } = getValues();
  //   reset({
  //     ...getValues(),
  //     interviewers: interviewers,
  //   });
  //   setIsSaveBtnDisable(false);
  // };

  const addFeedbackNavigate = () => {
    navigate(
      `/modules/hr/job-application/view/interview/feedback/create/${jobApplicantId}/${jobOpeningId}/${designationId}/${interviewId}/${interviewData?.interview_round}`,
      {
        state: {
          applicantName,
        },
      }
    );
  };

  useEffect(() => {
    if (checkIfObjectNotEmpty(interviewData)) {
      const { interview_summary } = getValues();
      if (interview_summary !== interviewData?.interview_summary) {
        setIsSaveBtnDisable(false);
      } else {
        setIsSaveBtnDisable(true);
      }
    }
  }, [interviewSummaryWatch]);

  useEffect(() => {
    if (isEditMode) {
      fetchInterview();
    }
  }, [isEditMode]);

  useEffect(() => {
    if (interviewRoundWatch) {
      fetchInterviewersList(interviewRoundWatch);
    }
  }, [interviewRoundWatch]);

  useEffect(() => {
    fetchInterviewRoundList();
  }, []);

  return (
    <MainLayout>
      {loading && <LoadingBackdrop open={loading} />}
      <div className="mb20">
        <CustomBreadcrumb breadcrumbs={breadcrumbs} />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={1} alignItems="center">
                <GridMenuIcon className="gridIcon" />
                <Typography color="#0D1021" fontSize={22}>
                  {isEditMode ? "View Interview" : "New Interview"}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={3}>
                <Button
                  variant="contained"
                  color="grey"
                  className="qa-back-btn"
                  size="large"
                  onClick={() =>
                    navigate(
                      `/modules/hr/job-application/view/${jobApplicantId}/${jobOpeningId}/${designationId}?tab=3`
                    )
                  }
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="qa-save-btn"
                  disabled={isSaveBtnDisable}
                >
                  {isEditMode ? "Update" : "Save"}
                </Button>
              </Stack>
            </Stack>

            <Typography variant="h4" color="#012148" fontWeight={500} sx={{ p: 0, mt: 3, mb: 0.5 }}>
              Details
            </Typography>
            <Divider sx={{ borderColor: "#A32930" }} />

            <Grid
              container
              rowSpacing={2}
              columnSpacing={5}
              display="flex"
              flexDirection="row"
              alignItems="start"
              sx={{ mt: 1 }}
            >
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Interview Round *
                </Typography>
                <FormSelectField
                  name="interview_round"
                  variant="outlined"
                  label=""
                  size="small"
                  fullWidth
                  className="mt1"
                  disabled={isEditMode}
                  options={interviewRoundList}
                  rules={{
                    required: validationRules.REQUIRED,
                  }}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Status *
                </Typography>
                <FormSelectField
                  name="status"
                  variant="outlined"
                  size="small"
                  fullWidth
                  className="mt1"
                  options={statusOptions}
                  rules={{
                    required: validationRules.REQUIRED,
                  }}
                  onSelectionChange={() => setIsSaveBtnDisable(false)}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Job Applicant *
                </Typography>
                <FormTextField
                  name="job_applicant"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                  className="mt1"
                />
              </Grid>

              {/* <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Years of Experience (years) *
                </Typography>
                <FormTextField
                  name="experience"
                  variant="outlined"
                  size="small"
                  fullWidth
                  className="mt1"
                  rules={{
                    required: validationRules.REQUIRED,
                  }}
                />
              </Grid> */}
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Resume link *
                </Typography>
                <FormTextField
                  name="resume_link"
                  variant="outlined"
                  size="small"
                  fullWidth
                  className="mt1"
                  rules={{
                    required: validationRules.REQUIRED,
                  }}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12} sx={{ mb: 1 }}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Scheduled On *
                </Typography>
                <FormDatepicker
                  name="scheduled_on"
                  fullWidth
                  className="mt1"
                  disabled={isEditMode}
                  rules={{
                    required: validationRules.REQUIRED,
                  }}
                />
              </Grid>

              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  From Time *
                </Typography>
                <FormTimepicker
                  name="from_time"
                  size="small"
                  className="mt1"
                  fullWidth
                  disabled={isEditMode}
                  rules={{
                    required: validationRules.REQUIRED,
                  }}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  To Time *
                </Typography>
                <FormTimepicker
                  name="to_time"
                  size="small"
                  className="mt1"
                  fullWidth
                  disabled={isEditMode}
                  rules={{
                    required: validationRules.REQUIRED,
                  }}
                />
              </Grid>
            </Grid>

            {interviewRoundWatch && (
              <div className="my6">
                <Typography color="#012148" fontSize={14} fontWeight="500">
                  Interviewers
                </Typography>
                <Table
                  rowSelection={{ type: "checkbox", ...rowSelection }}
                  columns={columns}
                  dataSource={interviewersList}
                  size="small"
                  pagination={false}
                />
                {/* <div className="mt1">
                  <IconButton color="primary" onClick={handleAddRow}>
                    <AddBoxIcon fill="#A32930" />
                  </IconButton>
                  {selectedRowKeys.length > 0 && (
                    <IconButton aria-label="delete" color="primary" onClick={handleDeleteRow}>
                      <DeleteOutlined />
                    </IconButton>
                  )}
                </div> */}
                <Divider />
              </div>
            )}

            <div>
              <Typography variant="bodyText" color="#012148" fontWeight={500}>
                Interview Summary by HR *
              </Typography>
              <FormTextField
                name="interview_summary"
                variant="outlined"
                size="small"
                className="mt1"
                placeholder="Enter Text here..."
                fullWidth
                multiline
                rows={4}
                rules={{
                  required: validationRules.REQUIRED,
                }}
              />
            </div>
          </form>
        </FormProvider>

        {isEditMode && (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="end"
              sx={{ mt: 3, mb: 1 }}
            >
              <Typography variant="h4" color="#012148" fontWeight={500} sx={{ p: 0 }}>
                Interview Feedbacks
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="qa-add-feedback-btn"
                onClick={addFeedbackNavigate}
              >
                + Add Interview Feedback
              </Button>
            </Stack>
            <Divider sx={{ borderColor: "#A32930" }} />

            <Table
              columns={feedbackColumns}
              dataSource={interviewFeedbackList}
              size="small"
              pagination={true}
              className="mt6"
            />
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default CreateInterview;

import React, { useEffect, useState } from "react";
import useStyles from "./style.js";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  Typography,
  Backdrop,
} from "@mui/material";
import {
  Button,
  TextField,
} from "iiht-b2a-ui-components/build/components/index.js";
import ContentManagement from "components/MasterPage/Content Management/New Topic.js";
import { createGenericEntity } from "modules/masters.js";
import { toast } from "react-toastify";

const CourseContentManagement = ({
  updateFormDataWithContentManagement,
  formData,
}) => {
  const [openTopicForm, setOpenTopicForm] = useState(false);
  const [popupOpen, setPopupOpen] = useState();
  const [selectedUnitKey, setSelectedUnitKey] = useState();
  const [selectedChapterKey, setSelectedChapterKey] = useState();
  const [unitName, setUnitName] = useState("");
  const [data, setData] = useState(formData || []);
  const styles = useStyles();

  const openAddUnitPopup = () => {
    setPopupOpen("Unit");
  };
  const openAddchapterPopup = () => {
    setPopupOpen("Chapter");
  };

  const openAddTopicPopup = () => {
    setOpenTopicForm(true);
  };

  const closeAddTopicPopup = () => {
    setOpenTopicForm(false);
  };

  const closePopup = () => {
    setPopupOpen();
    setUnitName("");
  };

  const handleAddUnit = async () => {
    if (popupOpen === "Unit") {
      let newUnit = {
        key: data.length + 1,
        unitName: unitName,
        chapters: [],
      };
      try {
        const payload = {
          doctype: "Topic",
          name: unitName,
        };

        const res = await createGenericEntity({ payload });
        if (res.status === 200) {
          toast.success("Unit saved");
          newUnit.id = res.data.message.name;
        } else {
          toast.error("There is some issue while creating Unit");
        }
      } catch (error) {
        toast.error(error);
        console.log(error);
      }

      setData([...data, newUnit]);
      updateFormDataWithContentManagement([...data, newUnit]);
      closePopup();
      setUnitName("");
    } else {
      let selectedunit = data.find((unit) => unit.key === selectedUnitKey);
      let newChapter = {
        key: `${selectedUnitKey}${selectedunit.chapters.length + 1}`,
        chapterName: unitName,
        topics: [],
      };

      try {
        const payload = {
          doctype: "Chapters",
          name: unitName,
          unit: selectedunit.id,
        };

        const res = await createGenericEntity({ payload });
        if (res.status === 200) {
          toast.success("Chapter saved");
          newChapter.id = res.data.message.name;
        } else {
          toast.error("There is some issue while creating Chapter");
        }
      } catch (error) {
        toast.error(error);
        console.log(error);
      }

      selectedunit.chapters.push(newChapter);
      const updatedData = data.map((item) => {
        if (item.key === selectedUnitKey) {
          return { ...item, chapters: selectedunit.chapters };
        }
        return item;
      });

      updateFormDataWithContentManagement(updatedData);
      setData(updatedData);
      closePopup();
      setUnitName("");
    }
  };

  const updateDataWithTopic = (updatedData) => {
    updateFormDataWithContentManagement(updatedData);
    setData(updatedData);
  };

  const handleUnitSelect = (key) => {
    setSelectedChapterKey();
    setSelectedUnitKey(key);
  };
  const handleChapterSelect = (unitkey, chapterkey) => {
    setSelectedChapterKey(chapterkey);
  };

  useEffect(() => {
  }, [data, openTopicForm, selectedChapterKey, selectedUnitKey]);

  return (
    <>
      {!openTopicForm ? (
        <div style={{ position: "relative" }}>
          <div className={styles.contentContainer}>
            <div className={styles.itemContainer}>
              <p className={styles.itemTitle}>Unit</p>
              <div className={styles.icon} onClick={openAddUnitPopup}>
                +
              </div>
            </div>
            <div className={styles.itemContainer}>
              <p className={styles.itemTitle}>Chapter</p>
              {selectedUnitKey && !selectedChapterKey && (
                <div className={styles.icon} onClick={openAddchapterPopup}>
                  +
                </div>
              )}
            </div>
            <div className={styles.itemContainer}>
              <p className={styles.itemTitle}>Topic</p>
              {selectedChapterKey && (
                <div className={styles.icon} onClick={openAddTopicPopup}>
                  +
                </div>
              )}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ flex: "1", marginRight: "44px" }}>
              {data?.map((unit) => (
                <div
                  key={unit.key}
                  id={`unit-${unit.key}`}
                  className={
                    selectedUnitKey === unit.key
                      ? styles.selectedsubelements
                      : styles.subelements
                  }
                  onClick={() => handleUnitSelect(unit.key)}
                >
                  <LibraryBooksOutlinedIcon style={{ color: "#840D21" }} />
                  <div className={styles.unitname}> {unit.unitName}</div>
                </div>
              ))}
            </div>

            <div style={{ flex: "1", marginRight: "46px" }}>
              {selectedUnitKey &&
                data?.map((unit) => (
                  <div key={unit.key}>
                    {unit.key === selectedUnitKey &&
                      unit.chapters.map((chapter) => (
                        <div
                          key={chapter.key}
                          className={
                            selectedChapterKey === chapter.key
                              ? styles.selectedsubelements
                              : styles.subelements
                          }
                          id={`chapter-${chapter.key}`}
                          onClick={() =>
                            handleChapterSelect(unit.key, chapter.key)
                          }
                        >
                          <LibraryBooksOutlinedIcon
                            style={{ color: "#840D21" }}
                          />
                          <div className={styles.unitname}>
                            {chapter.chapterName}
                          </div>
                        </div>
                      ))}
                  </div>
                ))}
            </div>
            <div style={{ flex: "1" }}>
              {selectedUnitKey &&
                selectedChapterKey &&
                data?.map((unit) => (
                  <div key={unit.key}>
                    {unit.key === selectedUnitKey &&
                      unit.chapters.map((chapter) => (
                        <div key={chapter.key}>
                          {chapter.key === selectedChapterKey &&
                            chapter.topics.map((topic) => (
                              <div
                                key={topic.key}
                                className={styles.subelements}
                              >
                                <LibraryBooksOutlinedIcon
                                  style={{ color: "#840D21" }}
                                />
                                <div className={styles.unitname}>
                                  {topic.title}
                                </div>
                              </div>
                            ))}
                        </div>
                      ))}
                  </div>
                ))}
            </div>
          </div>

          {popupOpen && (
            <>
              <Backdrop
                sx={{
                  zIndex: (theme) => 1,
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
                open={popupOpen}
                onClick={closePopup}
              />
              <div className={styles.popupContainer}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px",
                    borderBottom: "1px solid #ccc",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{ padding: "10px 15px", flexGrow: 1 }}
                  >
                    Add {popupOpen}
                  </Typography>
                  <IconButton onClick={closePopup}>
                    <CloseIcon style={{ color: "black" }} />
                  </IconButton>
                </div>
                <div style={{ width: "70%", margin: "0 auto" }}>
                  <Typography
                    variant="h4"
                    style={{ padding: "35px 0px 10px", flexGrow: 1 }}
                  >
                    {popupOpen} Name *
                  </Typography>
                  <TextField
                    placeholder="ex Text"
                    value={unitName} // Controlled value
                    onChange={(e) => setUnitName(e.target.value)}
                    style={{
                      marginBottom: "5px",
                      width: "100%",
                      height: "50px",
                      color: "#cdd6e2",
                      //border: "4px solid #cdd6e2",
                      background: "#fff",
                      borderRadius: "4px",
                    }}
                  />

                  <div className={styles.addButton}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddUnit}
                    >
                      Add {popupOpen}
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <ContentManagement
          data={data}
          selectedUnitKey={selectedUnitKey}
          selectedChapterKey={selectedChapterKey}
          closeAddTopicPopup={closeAddTopicPopup}
          updateDataWithTopic={updateDataWithTopic}
        />
      )}
    </>
  );
};

export default CourseContentManagement;

export const employeeStatusOptions = [
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Inactive",
    label: "Inactive",
  },
  {
    value: "Suspended",
    label: "Suspended",
  },
  {
    value: "Left",
    label: "Left",
  },
];

export const prefferedContactEmailOptions = [
  {
    value: "Company Email",
    label: "Company Email",
  },
  {
    value: "Personal Email",
    label: "Personal Email",
  },
  {
    value: "User ID",
    label: "User ID",
  },
];

export const addressTypeOptions = [
  {
    value: "Rented",
    label: "Rented",
  },
  {
    value: "Owned",
    label: "Owned",
  },
];

export const salaryModeOptions = [
  {
    value: "Bank",
    label: "Bank",
  },
  {
    value: "Cash",
    label: "Cash",
  },
  {
    value: "Cheque",
    label: "Cheque",
  },
];

export const maritalStatusOptions = [
  {
    value: "Single",
    label: "Single",
  },
  {
    value: "Married",
    label: "Married",
  },
  {
    value: "Divorced",
    label: "Divorced",
  },
  {
    value: "Widowed",
    label: "Widowed",
  },
];

export const bloodGroupOptions = [
  {
    value: "A+",
    label: "A+",
  },
  {
    value: "A-",
    label: "A-",
  },
  {
    value: "B+",
    label: "B+",
  },
  {
    value: "B-",
    label: "B-",
  },
  {
    value: "AB+",
    label: "AB+",
  },
  {
    value: "AB-",
    label: "AB-",
  },
  {
    value: "O+",
    label: "O+",
  },

  {
    value: "O-",
    label: "O-",
  },
];

export const leaveEncashedOptions = [
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

export const levelOptions = [
  {
    value: "Graduate",
    label: "Graduate",
  },
  {
    value: "Post Graduate",
    label: "Post Graduate",
  },
  {
    value: "Under Graduate",
    label: "Under Graduate",
  },
];

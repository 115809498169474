import React, { useEffect, useState } from "react";
import useStyles from "../style";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import ArrowDownSVG from "../../../../assets/images/arrow-down.svg";
import FiltersSVG from "../../../../assets/images/filters-icon.svg";
import { getAllFeeCategories } from "../../../../modules/finance";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../slices/commonSlice";
import LoadingBackdrop from "../../../common/LoadingBackdrop.js/index.js";
import { Table } from "antd";
import { AllJobOpening } from "../../../../modules/hr.js";
import { MainLayout } from "components/common";
import { CustomBreadcrumb } from "components/common";
import CommonFilters from "../../../Filters/CommonFilters";
import SavedFilters from "../../../Filters/SavedFilters";

function JobCreationList({ setActiveModule }) {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const { doctype, filters } = useSelector((state) => state.filters);

  const [jobCategories, setJobCategories] = useState([]);
  const breadcrumbs = [
    {
      label: "IIHT",
      link: "/dashboard",
    },
    {
      label: "HR",
      link: "/modules/hr",
    },
    {
      label: "Recruitment",
      link: "/modules/hr",
    },
    {
      label: "Job Opening",
      link: "/modules/hr/jobOpening",
    },
  ];

  const columns = [
    {
      dataIndex: "job_title",
      title: "JOB TITLE",
      width: 1,
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.job_title}</p>;
      },
    },
    {
      dataIndex: "id",
      title: "JOB ID",
      width: 0.8,
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.id}</p>;
      },
    },
    {
      dataIndex: "description",
      title: "Description",
      width: 0.7,
      render: (val, row) => {
        const descriptionLimit = 90;
        const truncatedDescription =
          row.description.length > descriptionLimit
            ? row.description.substring(0, descriptionLimit) + "..."
            : row.description;
        return (
          <p
            className={styles.tableContentFields}
            dangerouslySetInnerHTML={{ __html: truncatedDescription }}
          />
        );
      },
    },
    {
      dataIndex: "docstatus",
      title: "STATUS",
      width: 0.7,
      render: (val, row) => {
        const status = row.status === "Open" ? "Open" : "Closed";
        const statusClassName =
          row.status === "Open" ? styles.buttonPublish : styles.buttonUnPublish;
        return <p className={statusClassName}>{status}</p>;
      },
    },
    {
      dataIndex: "id",
      title: "ACTION",
      width: 0.5,
      render: (val, row) => {
        return (
          <p
            className={styles.tableContentView}
            onClick={() =>
              navigate(`/modules/hr/jobOpening/updateJobOpening/id=${row.id}`)
            }
          >
            View
          </p>
        );
      },
    },
  ];

  const handleGetAllJobOpenings = async () => {
    dispatch(setLoading({ loading: true }));
    let filtersToPass = [];
    if (doctype === "Job Opening") {
      filtersToPass = JSON.stringify(filters).slice(1, -1);
    }
    const payload = {
      doctype: "Job Opening",
    };
    const res = await AllJobOpening(payload, filtersToPass);
    if (res.status === 200) {
      dispatch(setLoading({ loading: false }));
      const categories = [];
      const length1 = res.data.message.length;
      console.log(length1);
      for (let i = 0; i < res.data.message.length; i++) {
        categories.push({
          id: res.data.message[i].id,
          key: res.data.message[i].id,
          job_title: res.data.message[i].job_title,
          custom_amount: res.data.message[i].custom_amount,
          status: res.data.message[i].status,
          description: res.data.message[i].description || "",
        });
      }
      setJobCategories(categories);
    } else {
      dispatch(setLoading({ loading: false }));
    }
  };

  useEffect(() => {
    handleGetAllJobOpenings();
  }, [filters]);

  return (
    <MainLayout>
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <>
          <CustomBreadcrumb breadcrumbs={breadcrumbs} />
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>Job Opening</p>
            </div>
            <div className={styles.buttonsGroup}>
              <CommonFilters doctype="Job Opening"></CommonFilters>
              <SavedFilters doctype="Job Opening"></SavedFilters>
              <div className={styles.button}>
                <p className={styles.buttonText}>...</p>
              </div>
              <div
                className={styles.activeButton}
                onClick={() => navigate("/modules/hr/jobOpening/addJobOpening")}
              >
                <p className={styles.activeButtonText}>+ Add Job Opening</p>
              </div>
            </div>
          </div>
          <Table
            rowSelection={{ type: "checkbox" }}
            columns={columns}
            dataSource={jobCategories}
            size="small"
            pagination={true}
          />
        </>
      )}
    </MainLayout>
  );
}

export default JobCreationList;

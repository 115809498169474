import React, { useState, useEffect } from "react";
import { MainLayout } from "components/common";
import { formData } from "./RecruitmentJson";
import "./ApplicationStyle.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  getApplicantDetails,
  getWorkflowForStudentApplicant,
} from "../../modules/studentApplication";
// import LeadEvents from "../../modules/Leads/LeadEvents";
import Email from "./Email";
import Event from "./Event/Event";
// import ApplicationDocs from "./ApplicationDocs";
// import Payment from "./PaymentListing";
import { GridMenuIcon } from "@mui/x-data-grid";
import Notes from "./Notes/Notes";
// import { Button } from "iiht-b2a-ui-components/build/components";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled, alpha } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { updateDataToApplicationForm } from "../../modules/student";
import { toast } from "react-toastify";
import { roleConstants } from "../../config/roles";
import ConfirmationDialog from "../../components/common/confirmation";
import eventBus from "../../core/eventBus";
import { Select, Button, Input } from "antd";

const TextBox2 = ({ field, data, rowIndex }) => {
  const { label, name, headers } = field;
  const rows = data[`${name}`] || []; // Ensure rows is an array

  return (
    <>
      {Array.isArray(rows) &&
        rows.map((rowValue, idx) => (
          <div className="parentsSection" key={idx}>
            <h4>
              {label}-{idx}
            </h4>
            <div className="fullWidthFields">
              {headers.map((header) =>
                header.type === "col-break" ? (
                  <div key={`col-break-${idx}`} className="col-break">
                    &nbsp;
                  </div>
                ) : header.type === "heading" ? (
                  <h4 key={`heading-${idx}`}>{header.label}</h4>
                ) : (
                  <div key={header.key} className="form-field">
                    <label>{header.key}</label>
                    {header.type === "textarea" ? (
                      <textarea
                        name={`${name}_${header.key}_${idx}`}
                        placeholder={header.key}
                        value={rowValue[header.value] || ""}
                      />
                    ) : (
                      <Input
                        type="text"
                        name={`${name}_${header.key}_${idx}`}
                        placeholder={header.key}
                        value={rowValue[header.value] || ""}
                      />
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        ))}
    </>
  );
};

const TextBox = ({ field, data }) => {
  const { label, fields, placeholder } = field;
  return (
    <>
      {field?.dependantField ? (
        <div className="form-field">
          <label dangerouslySetInnerHTML={{ __html: field.label }} />
          <Input
            type="text"
            //   name={name}
            placeholder={placeholder}
            //   value={data[name].length}
          />
        </div>
      ) : (
        <div className="form-field">
          <label dangerouslySetInnerHTML={{ __html: field.label }} />
          <Input
            type="text"
            //   name={name}
            placeholder={placeholder}
            //   value={data[name]}
          />
        </div>
      )}
    </>
  );
};

// const TextBox = ({ field, data }) => {
//     const { label, name, placeholder } = field;
//     return (
//       <div className="form-field">
//         <label dangerouslySetInnerHTML={{ __html: field.label }} />
//         <input
//           type="text"
//           name={name}
//           placeholder={placeholder}
//         />
//       </div>
//     );
// };

const EncryptedTextBox = ({ field, data }) => {
  const { label, fields, name, placeholder } = field;
  return (
    <div className="form-field">
      <label dangerouslySetInnerHTML={{ __html: field.label }} />
      <Input
        type="text"
        name={name}
        placeholder={placeholder}
        value={`********${data[name].substr(-4)}`}
      />
    </div>
  );
};

const Image = ({ field, data }) => {
  const { label, name } = field;
  return (
    <div className="form-field">
      <label dangerouslySetInnerHTML={{ __html: label }} />
      <img
        src={`https://erp-dev.techademycampus.com${data[name]}`} // Assuming data[name] contains the image URL
        alt={label}
        style={{ width: "150px", height: "150px" }} // Adjust styling as needed
      />
    </div>
  );
};

const TextArea = ({ field, data }) => {
  const { label, name, placeholder } = field;
  return (
    <div className="form-field fullWidthFields">
      <label dangerouslySetInnerHTML={{ __html: field.label }} />
      <textarea name={name} placeholder={placeholder} value={data[name]} />
    </div>
  );
};

const SelectBox = ({ field }) => {
  const { label, name, options } = field;

  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    // You can handle the selected option here
  };

  // Dummy data for options
  const dummyOptions = ["Option 1", "Option 2", "Option 3"];

  return (
    <div className="form-field ">
      <label>{label}</label>
      <Select name={name} onChange={handleSelectChange} style={{ width: "100%", height: "40px" }}>
        {dummyOptions.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </Select>
    </div>
  );
};

const Labels = ({ field }) => {
  const { label } = field;
  return <label className="underline">{label}</label>;
};
const Labels1 = ({ field }) => {
  const { label } = field;
  return <label>{label}</label>;
};

const EmailInputBox = ({ field, data }) => {
  const { label, name, placeholder } = field;
  return (
    <div className="form-field">
      <label>{label}</label>
      <Input type="email" name={name} placeholder={placeholder} value={data[name]} />
    </div>
  );
};

const RadioBox = ({ field, data }) => {
  const { label, name, options } = field;
  return (
    <div className="form-field">
      <label>{label}</label>
      <div className="radio-option">
        <span>
          <Input
            type="radio"
            id="yes"
            //   name={name}
            value="yes"
            //   checked={data[name] == 1}
            style={{ accentColor: "#A32930" }}
          />
          <label htmlFor="Yes">Yes</label>
        </span>
        <span>
          <Input
            type="radio"
            id="no"
            //   name={name}
            value="no"
            //   checked={data[name] == 0}
            style={{ accentColor: "#A32930" }}
          />
          <label htmlFor="No">No</label>
        </span>
      </div>
    </div>
  );
};

const RadioBox2 = ({ field, data, fieldsToShow }) => {
  const { label } = field;
  const [selection, setSelection] = useState("");
  const handleRadioChange = (event) => {
    const selectedValue = event.target.value;
    setSelection(selectedValue);
  };

  return (
    <>
      <div className="form-field">
        <label>{label}</label>
        <div className="radio-option">
          <span>
            <Input
              type="radio"
              id="yes"
              value="yes"
              onChange={handleRadioChange}
              style={{ accentColor: "#A32930" }}
            />
            <label htmlFor="yes">Yes</label>
          </span>
          <span>
            <Input
              type="radio"
              id="no"
              value="no"
              onChange={handleRadioChange}
              style={{ accentColor: "#A32930" }}
            />
            <label htmlFor="no">No</label>
          </span>
        </div>
      </div>
      {selection === "yes" && (
        <div className="fields-container">
          <div className="fields-block">
            {field.fields.map((sectionField, sectionIndex) => (
              <React.Fragment key={sectionIndex}>
                {sectionField.type === "col-break" ? (
                  <div className="col-break" key={sectionIndex}>
                    &nbsp;
                  </div>
                ) : (
                  renderFieldByType(sectionField)
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const Button1 = ({ field }) => {
  const { name } = field;
  return <Button className="jsonButton">{name}</Button>;
};

const Para = ({ field, data }) => {
  const { label, name } = field;
  return (
    <div className="form-field fullWidthFields">
      <label>{label}</label>
      <p>{data[name]}</p>
    </div>
  );
};

const Checkbox = ({ field, data }) => {
  const { label, name, options } = field;
  return (
    <div className="form-field fullWidthFields">
      <label>{label}</label>
      {options.map((option) => (
        <div key={option.key} className="formCheckbox">
          <Input
            type="checkbox"
            id={`${name}_${option.key}`}
            //   name={option.value}
            //   checked={data[option.value] == 0 ? false : true}
            style={{ accentColor: "#A32930" }}
          />
          <label htmlFor={`${name}_${option.key}`}>{option.key}</label>
        </div>
      ))}
    </div>
  );
};

const Table = ({ field }) => {
  const { headers, minRows } = field;
  const numRows = minRows || 5;

  return (
    <div className="formTableWraps">
      <table className="formTable">
        <thead className="formTableHeader">
          <tr>
            {headers.map((header) => (
              <th key={header.key} style={{ width: getWidthForHeader(header.key) }}>
                {header.key}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[...Array(numRows)].map((_, idx) => (
            <tr key={idx}>
              {headers.map((header) => (
                <td key={header.key} style={{ width: getWidthForHeader(header.key) }}>
                  {/* Render blank cell */}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Table1 = ({ field, data, rowIndex }) => {
  const { headers, minRows } = field;
  const [numRows, setNumRows] = useState(minRows || 5);

  const handleAddRow = () => {
    setNumRows(numRows + 1);
  };

  const handleRemoveRow = () => {
    if (numRows > 0) {
      setNumRows(numRows - 1);
    }
  };

  return (
    <div className="formTableWrap">
      <table className="formTable">
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header.key} style={{ width: getWidthForHeader(header.key) }}>
                {header.key}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[...Array(numRows)].map((_, idx) => (
            <tr key={idx}>
              {headers.map((header) => (
                <td key={header.key} style={{ width: getWidthForHeader(header.key) }}>
                  {/* Render input elements */}
                  {header.key === "Start Date" || header.key === "End Date" ? (
                    <input type="date" name={`${header.key}_${idx}`} />
                  ) : (
                    <input type="text" name={`${header.key}_${idx}`} placeholder={header.key} />
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const getWidthForHeader = (header) => {
  if (header === "No." || header === "S.no") {
    return "2%";
  } else if (header === "Read" || header === "Write" || header === "Speak") {
    return "15%";
  } else {
    return "10%";
  }
};

const JobFormData = () => {
  const { id } = useParams();
  const uniqueId = id.split("=")[1];
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [fieldsToRender, setFieldsToRender] = useState([]);
  const [currentTab, setCurrentTab] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [workflowData, setWorkflowData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentWorkflow, setCurrentWorkflow] = useState("");
  const [allowedActions, setAllowedActions] = useState([]);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //   const handleWorkflowClick = (transition) => {
  //     setAnchorEl(null);
  //     eventBus.emit("open-dialog", <ConfirmationDialog
  //       handleUpdateDataToApplicationForm={handleUpdateDataToApplicationForm}
  //       nextState={transition.next_state}
  //     />);
  //   }

  //   const handleUpdateDataToApplicationForm = async ({ nextState }) => {
  //     const res = await updateDataToApplicationForm({ payload: { workflow_state: nextState }, name: uniqueId });
  //     if(res.status == 200) {
  //       setCurrentWorkflow(res.data.data.workflow_state);
  //       handleWorkflow({ workflow: res.data.data.workflow_state, workflowDataArray: workflowData });
  //     } else {
  //       let error = res.data?.exception;
  //       error = error.split(":")[1];
  //       toast.error(error);
  //     }
  //   }

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
      },
    },
  }));

  const handleSetFields = (fields, tabName, name) => {
    const applicantName = name || data.first_name;
    setFieldsToRender(fields);
    setCurrentTab(tabName);
    setBreadcrumbs([
      { label: "Job Applications", url: "/jobApplicationListing" },
      { label: applicantName, url: `/jobapplications/id=${uniqueId}/type=general` },
      { label: tabName, url: `/jobapplications/id=${uniqueId}/type=${tabName}` },
    ]);
  };

  const Breadcrumbs = () => {
    const navigate = useNavigate();
    return (
      <div className="breadcrumbs">
        {breadcrumbs.map((breadcrumb, index) => (
          <React.Fragment key={index}>
            {index > 0 && <span className="separator"> &gt; </span>}
            <div
              onClick={() => navigate(breadcrumb.url)}
              style={{ display: "inline-flex", cursor: "pointer" }}
            >
              {breadcrumb.label}
            </div>
          </React.Fragment>
        ))}
      </div>
    );
  };

  //   const getDetails = async () => {
  //     try {
  //       const res = await getApplicantDetails(uniqueId);
  //       if (res.status === 200) {
  //         setData(res.data.message);
  //         setCurrentWorkflow(res.data.message.workflow_state);
  //         getActionDropdownValues({ state: res.data.message.workflow_state });
  //         handleSetFields(formData[0].fields, formData[0].name, res.data.message.first_name);
  //       } else {
  //         console.error(
  //           `Failed to get student details. Server responded with status ${res.status}`
  //         );
  //       }
  //     } catch (error) {
  //       console.error("Error fetching student details:", error.message);
  //     }
  //   };

  //   const getActionDropdownValues = async ({ state }) => {
  //     try {
  //       const res = await getWorkflowForStudentApplicant();
  //       if (res.status === 200) {
  //         setWorkflowData(res.data.message.data);
  //         handleWorkflow({ workflow: state , workflowDataArray: res.data.message.data});
  //       } else {
  //         console.error(
  //           `Failed to get application status. Server responded with status ${res.status}`
  //         );
  //       }
  //     } catch (error) {
  //       console.error("Error fetching application status:", error.message);
  //     }
  //   };

  //   useEffect(() => {
  //     getDetails();
  //   }, []);

  const handleWorkflow = ({ workflow, workflowDataArray }) => {
    const transitions = workflowDataArray[`${workflow}`]?.transitions;
    const allowedActionsArr = transitions.filter(
      (transition) => transition.allowed === roleConstants.admissionCommitee
    );
    setAllowedActions(allowedActionsArr);
  };

  return (
    <MainLayout className="container" style={{ backgroundColor: "green" }}>
      <div className="candidate-details">
        <Breadcrumbs />
        {/* <div className="candidate-heading">
          <h2>
            <GridMenuIcon />
            <div>{data.first_name}</div>
            <div className="application-status">
              <span className={currentWorkflow}>{currentWorkflow}</span>
            </div>
          </h2>
          <span className="rightBtn">
            <button type="button" onClick={() => navigate("/applications")} style={{ cursor: "pointer" }}>Back</button>
            {
              allowedActions.length > 0 && <div>
              <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{ backgroundColor: "#A32930" }}
              >
                Action
              </Button>
              {
                workflowData && 
                workflowData[`${currentWorkflow}`]?.transitions.length > 0 &&
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  {
                    workflowData && workflowData[`${currentWorkflow}`]?.transitions.map((transition) => {
                    const { action, allowed } = transition;
                      return (
                      <>
                        {
                          allowed === roleConstants.admissionCommitee && 
                          <MenuItem onClick={() => handleWorkflowClick(transition)} disableRipple sx={{ fontSize: "14px", color: "#405071", fontWeight: "500" }}>
                            {action}
                          </MenuItem>
                        }
                      </>
                      )
                    })
                  }
                </StyledMenu>
              }
            </div>
            }
          </span>
        </div> */}
        <div className="applicationNav">
          {formData.map((tabDetails) => (
            <button
              type="button"
              key={tabDetails.name}
              className={`appTab ${currentTab === tabDetails.name ? "active" : ""}`}
              onClick={() => handleSetFields(tabDetails.fields, tabDetails.name)}
            >
              {tabDetails.name}
            </button>
          ))}
        </div>
        <div className="formApplication">
          {fieldsToRender.map((field, index) => (
            <React.Fragment key={index}>
              <section className="form-section">
                {field.type === "section" && (
                  <>
                    <h3 dangerouslySetInnerHTML={{ __html: field.label }} />
                    <hr className="marginLine"></hr>
                  </>
                )}
                <div className="fields-block">
                  {field.fields.map((sectionField, sectionIndex) => (
                    <React.Fragment key={sectionIndex}>
                      {sectionField.type === "col-break" ? (
                        <div className="col-break" key={sectionIndex}>
                          &nbsp;
                        </div>
                      ) : (
                        renderFieldByType(sectionField)
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </section>
            </React.Fragment>
          ))}
        </div>
      </div>
    </MainLayout>
  );
};

const ComponentToRender = ({ data, field }) => {
  switch (field.componentToRender) {
    case "notes":
      return <Notes />;
    case "events":
      return <Event />;
    case "emails":
      return <Email />;
    // case "attachments":
    //   return <ApplicationDocs uniqueId={data.name} />;
    // case "payment":
    //   return <Payment uniqueId={data.name} />;
    default:
      return null;
  }
};

const renderFieldByType = (field, data) => {
  switch (field.type) {
    case "text":
      return <TextBox field={field} data={data} />;
    case "text2":
      return <TextBox2 field={field} data={data} />;
    case "encrypted":
      return <EncryptedTextBox field={field} data={data} />;
    case "radio2":
      return <RadioBox2 field={field} />;
    case "select":
      return <SelectBox field={field} data={data} />;
    case "email":
      return <EmailInputBox field={field} data={data} />;
    case "textarea":
      return <TextArea field={field} data={data} />;
    case "radio":
      return <RadioBox field={field} data={data} />;
    case "table":
      return <Table field={field} data={data} />;
    case "checkbox":
      return <Checkbox field={field} data={data} />;
    case "p":
      return <Para field={field} data={data} />;
    case "image":
      return <Image field={field} data={data} />;
    case "component":
      return <ComponentToRender data={data} field={field} />;
    default:
      return null;
  }
};

export default JobFormData;

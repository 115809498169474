import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import { createEvent, getEvents } from "../../../../../modules/application.js";
import AddEvent from "./AddEvent.js";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import LoadingBackdrop from "../../../../../components/common/LoadingBackdrop.js";
import { getStudentDetails } from "../../../../../modules/studentApplication.js";
import GetEvent from "./GetEvent/index.js";
import useStyles from "../style.js";
import { Table } from "antd";
import { updateEvent } from "modules/lead.js";

export default function DataGridDemo({ uniqueId }) {
  const styles = useStyles();
  const user = useSelector((state) => state.user);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [updatedValue, setUpdatedValue] = useState({ status: "" });
  const [leadEmail, setLeadEmail] = useState("");
  const [events, setEvents] = useState([]);
  const [showGetEvent, setShowGetEvent] = useState(false);
  const [eventDetails, setEventDetails] = useState({});
  const [showAddEvent, setShowAddEvent] = useState(false);
  const [loading, setLoading] = useState(true);
  const [addEventLoading, setAddEventLoading] = useState(false);
  const [values, setValues] = useState({
    subject: "",
    startOn: "",
    endsOn: "",
    eventCategory: "Event",
    status: "Open",
    eventType: "Private",
    addGuests: "",
    color: "",
    sendEmail: 0,
    repeat: 0,
    description: "",
  });

  const columns = [
    {
      dataIndex: "subject",
      title: "Subject",
      width: 600,
    },
    {
      dataIndex: "event_type",
      title: "Event Type",
      width: 200,
      render: (val, row) => {
        const dynamicClassName = `statusEvent ${row.event_type}`;
        return (
          <span className={dynamicClassName}>{row.event_type}</span>
        );
      },
    },
    {
      dataIndex: "status",
      title: "Status",
      width: 200,
      render: (val, row) => {
        const dynamicClassName = `status ${row.status}`;
        return (
          <span className={dynamicClassName}>{row.status}</span>
        ); 
      },
    },
    {
      dataIndex: "name",
      title: "ID",
      width: 200,
    }, 
    {
      dataIndex: "idx",
      title: "Action",
      width: 200,
      render: (val, row) => {
        return (
          <span className={styles.viewLink} onClick={() => handleShowEvent(row)}>View</span>
        ); 
      },
    }, 
  ];

  useEffect(() => {
    handleGetEvents();
    handleGetStudentDetails();
  }, [showAddEvent]);

  const handleGetEvents = async () => {
    setLoading(true);
    const res = await getEvents({ id: uniqueId });
    if (res.status === 200) {
      setLoading(false);
      let data = res?.data?.data;
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        let val = {
          ...data[i],
          id: data[i].name,
          idx: i
        };
        arr.push(val);
      }
      setEvents(arr);
    } else {
      setLoading(false);
    }
  };

  const handleShowEvent = (details) => {
    setShowGetEvent(true);
    setEventDetails(details);
  };

  const handleGetStudentDetails = async () => {
    const res = await getStudentDetails( uniqueId );
    if (res.status === 200) {
      setLeadEmail(res.data.data.student_email_id);
      setValues({ ...values, addGuests: `${res.data.data.student_email_id}, ${user.email}`});
    }
  };
  
  const handleCreateEvent = async () => {
    setAddEventLoading(true);
    let guests = [];
    guests.push(
      {
        reference_doctype: "Student Applicant",
        reference_docname: uniqueId,
        parentfield: "event_participants",
        parenttype: "Event",
        doctype: "Event Participants",
        email: leadEmail,
      },
      {
        reference_doctype: "User",
        reference_docname: user.fullName,
        parentfield: "event_participants",
        parenttype: "Event",
        doctype: "Event Participants",
        email: user.email,
      }
    );

    const payload = {
      starts_on: values.startOn,
      ends_on: values.endsOn,
      subject: values.subject,
      event_category: values.eventCategory,
      event_type: values.eventType,
      status: values.status,
      send_reminder: values.sendEmail,
      repeat_this_event: values.repeat,
      event_participants: guests,
      sync_with_google_calendar: 1,
      add_video_conferencing: 1,
      google_calendar: "Main",
      send_reminder: 1,
      description: values.description,
    };

    const res = await createEvent({ payload });
    if (res.status === 200) {
      setAddEventLoading(false);
      setShowAddEvent(false);
      toast.success("Event created Successfully !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setAddEventLoading(false);
      toast.error(res.data?.exc_type, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleUpdateEvent = async () => {
    setLoading(true);
    setShowGetEvent(false);
    setUpdateFlag(false);
    const res = await updateEvent({ payload: updatedValue, name: eventDetails.name });
    handleGetEvents();
    setEventDetails({});
    if (res.status === 200) {
      setLoading(false);
      toast.success("Event updated!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setLoading(false);
      toast.error(res.data?.exc_type, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  return (
    <Box
      sx={{ width: "100%", typography: "body1" }}
    >
      {
        showAddEvent && !addEventLoading ? <div style={{ display: "inline-flex", gap: "16px",  marginBottom: "12px" }}>
          <div
            className={`${styles.button} ${styles.buttonText}`}
            onClick={() => setShowAddEvent(false)}
          >
            Cancel
          </div>
          <div
            className={`${styles.activeButton} ${styles.activeButtonText}`}
            onClick={() => {
              if (!loading) {
                handleCreateEvent();
              }
            }}
          >
            {showAddEvent && addEventLoading ? "Loading..." : "Save"}
          </div>
        </div> : !addEventLoading && <div style={{ display: "inline-flex", gap: "16px", marginBottom: "12px" }}>
          {
            showGetEvent && (
              <div
                className={`${styles.button} ${styles.buttonText}`}
                onClick={() => setShowGetEvent(false)}
              >
                Back
              </div>
            )
          }
         {
            updateFlag ? 
            <div
              className={`${styles.activeButton} ${styles.activeButtonText}`}
              onClick={() => {
                handleUpdateEvent()
              }}
            >
              Update Event
            </div> : 
            !showGetEvent && <div
              className={`${styles.activeButton} ${styles.activeButtonText}`}
              onClick={() => {
                setShowGetEvent(false)
                setShowAddEvent(true);
              }}
            >
              Add Event
            </div>
          }
        </div>
      }
      <Box sx={{ height: 400, width: "100%" }}>
        {
          showGetEvent && <GetEvent eventDetails={eventDetails} values={values} setUpdatedValue={setUpdatedValue} setUpdateFlag={setUpdateFlag} updatedValue={updatedValue}/>
        }
        {showAddEvent ? (
          <AddEvent
            loading={addEventLoading}  
            setValues={setValues}
            values={values}
          />
        ) : (
          <>
            {loading ? (
              <LoadingBackdrop open={loading}/>
            ) : !loading && events.length > 0 && !showGetEvent ? (
              <Table
                columns={columns} 
                dataSource={events} 
                size='small'
                pagination={true}
              />
            ) : !showGetEvent && <p>No Events Created yet</p>}
          </>
        )}
      </Box>
    </Box>
  );
}

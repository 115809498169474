import React, { useEffect, useState } from 'react';
import useStyles from './style';
import { useNavigate } from 'react-router-dom';
import { GridMenuIcon } from '@mui/x-data-grid';
import { useDispatch, useSelector } from "react-redux";
import { Popover, Table } from 'antd';
import { getMeritCategoryListing } from '../../../../modules/admission';
import { setLoading } from '../../../../slices/commonSlice';
import MainFooter from '../../../common/MainFooter';
import MainHeader from '../../../common/MainHeader';
import { setActiveSidebar } from '../../../../slices/financeSlice';
import LoadingBackdrop from '../../../common/LoadingBackdrop.js';
import CommonFilters from '../../../Filters/CommonFilters.js';
import SavedFilters from '../../../Filters/SavedFilters.js';
import { toast } from 'react-toastify';
import DeleteConfirmationDialog from '../../../common/DeleteConfirmation/index.js';
import eventBus from '../../../../core/eventBus.js';
import { genericBulkDelete } from '../../../../modules/common.js';

const MeritListCategoryDashboard = () => {
  const styles = useStyles();
  const navigate= useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const { doctype, filters } = useSelector((state) => state.filters);
  const [rowData, setRowData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [openActions, setOpenActions] = useState(false);

  const columns = [
    {
      dataIndex: "name",
      title: "MERIT LIST CATEGORY",
      width: "85%",
      render: (val, row) => {
        return (
            <p className={styles.tableContentFields}>{row.name}</p>
        );
      },
    },
    {
      dataIndex: "id",
      title: "ACTION",
      width: "10%",
      render: (val, row) => {
        return (
            <p className={`${styles.tableContentView} ${styles.link}`} onClick={() => navigate(`/modules/admission/merit-list-category/${row.name}`)}>View</p>
        );
      },
    },
  ];

  const handleMeritListNavigation = () => {
    dispatch(setActiveSidebar({ activeSidebar: 3 }));
    navigate("/modules/admission");
  };

  const handleGetAllMeritListCategory = async () => {
    let filtersToPass = [];
    if(doctype === "Merit List Criteria Template") {
      filtersToPass = JSON.stringify(filters).slice(1, -1);
    }
    dispatch(setLoading({ loading: true }));
    const res = await getMeritCategoryListing(filtersToPass);
    dispatch(setLoading({ loading: false }));
    if(res.status === 200) {
      const meritListCategory = [];
      for(let i = 0 ; i < res.data.data.length ; i++) {
        meritListCategory.push({
          id: i,
          name: res.data.data[i].name,
          key: i
        })
      };
      setRowData(meritListCategory);
    } 
  };

  const onSelectChange = (selectedKeys, selectedRowData) => {
    setSelectedRowKeys(selectedKeys);
    let selectedDataIds = [];
    for(let i = 0 ; i < selectedRowData.length ; i++) {
      selectedDataIds.push(selectedRowData[i].name);
    }
    setSelectedIds([...selectedDataIds]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      id: record.id,
    }),
  };

  const handleConfirmBulkDelete = () => {
    setOpenActions(false);
    eventBus.emit("open-dialog", <DeleteConfirmationDialog items={selectedRowKeys.length} handleBulkDelete={handleBulkDelete} />)
  };

  const handleBulkDelete = async () => {
    dispatch(setLoading({ loading: true }));
    let deleteIds = [];
    for(let i = 0 ; i < selectedIds.length ; i++) {
      deleteIds.push({
        doctype: "Merit List Criteria Template",
        id: selectedIds[i]
      })
    };

    const payload = { delete: [...deleteIds] };
    const res = await genericBulkDelete({ payload });
    if(res.status === 200) {
      handleGetAllMeritListCategory();
      setSelectedIds([]);
      setSelectedRowKeys([]);
      toast.success(res.data.message);
    } else {
      toast.error(res.data.exc_type);
      dispatch(setLoading({ loading: false }));
    }
  };

  useEffect(() => {
    handleGetAllMeritListCategory();
  }, [filters]);

  return (
    <>
      <MainHeader />
      {
        loading ?
        <LoadingBackdrop open={loading} /> :
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p className={styles.breadCrumbsContent} onClick={() => {
              navigate("/dashboard");
              dispatch(setActiveSidebar({ activeSidebar: 0 }));
            }}>IIHT {" > "}</p>
            <p className={styles.breadCrumbsContent} onClick={() => {
              navigate("/modules/admission");
              dispatch(setActiveSidebar({ activeSidebar: 0 }));
            }}>Admission {" > "}</p>
            <p className={styles.breadCrumbsContent} onClick={() => handleMeritListNavigation()}>Merit List Tool {" > "}</p>
            <p className={styles.breadCrumbsContent}>Merit List Category</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon}/>
              <p className={styles.title}>Merit List Category</p>
            </div>
            <div className={styles.buttonsGroup}>
              <CommonFilters doctype={"Merit List Criteria Template"}></CommonFilters>
              <SavedFilters doctype={"Merit List Criteria Template"}></SavedFilters>
              {
                selectedRowKeys.length > 0 ? (
                <Popover
                  content={<div className={styles.popoverContainer}>
                    <p className={styles.popoverContent} onClick={handleConfirmBulkDelete}>Delete</p>
                  </div>}
                  trigger="click"
                  open={openActions}
                  placement="bottomRight"
                  arrow={false}
                  onOpenChange={() => setOpenActions(true)}
                >
                  <div className={styles.activeButton}>
                    <p className={styles.activeButtonText}>Actions</p>
                  </div>
                </Popover>
                ) : (
                <div className={styles.activeButton} onClick={() => navigate("/modules/admission/add-new-merit-list-category")}>
                  <p className={styles.activeButtonText}>+ New Merit List Category</p>
                </div>
              )}
            </div>
          </div>
          <Table 
            rowSelection={{ type: "checkbox", ...rowSelection }} 
            columns={columns} 
            dataSource={rowData} 
            size='small'
            pagination={true}
          />
        </div>
      }
      <MainFooter />
    </>
  )
}

export default MeritListCategoryDashboard;
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import "./AcademicMasters.scss";

import UniversityIcon from "../../../assets/images/master-university-icon.svg";
import CampusIcon from "../../../assets/images/master-campus-icon.svg";
import CollegeIcon from "../../../assets/images/master-college-icon.svg";
import ProgramIcon from "../../../assets/images/master-program-icon.svg";
import CourseIcon from "../../../assets/images/master-course-icon.svg";
import InstructorIcon from "../../../assets/images/master-instructor-icon.svg";
import AcademicYearIcon from "../../../assets/images/master-academicYear-icon.svg";
import AcademicTermIcon from "../../../assets/images/master-academicTerm-icon.svg";
import BatchIcon from "../../../assets/images/master-batch-icon.svg";
import CurriculumIcon from "../../../assets/images/master-curriculum-icon.svg";
import DepartmentIcon from "../../../assets/images/master-department-icon.svg";
import {
  Link,
  Route,
  Routes,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import { Button } from "@mui/material";
import { GridMenuIcon } from "@mui/x-data-grid";
import { useState } from "react";
import { ReactSVG } from "react-svg";

// Combined fieldsData directly into the component
const fieldsData = [
  { urlName: "University", displayName: "University", icon: UniversityIcon },
  { urlName: "Campus", displayName: "Campus", icon: CampusIcon },
  { urlName: "College", displayName: "College / School", icon: CollegeIcon },
  { urlName: "Program", displayName: "Program", icon: ProgramIcon },
  { urlName: "Course", displayName: "Course", icon: CourseIcon },
  { urlName: "Instructor", displayName: "Instructor", icon: InstructorIcon },
  { urlName: "Academic Year", displayName: "Academic Year", icon: AcademicYearIcon },
  { urlName: "Academic Term", displayName: "Academic Term", icon: AcademicTermIcon },
  { urlName: "Batch", displayName: "Batch", icon: BatchIcon },
  { urlName: "Curriculum", displayName: "Curriculum", icon: CurriculumIcon },
  { urlName: "Department", displayName: "Department", icon: DepartmentIcon },
  { urlName: "Outcomes", displayName: "Outcomes", icon: CourseIcon },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function MastersLanding() {
  const location = useLocation();
  const { entityType } = useParams();
  const [currentEntityType, setCurrentEntityType] = useState(entityType);
  const navigate = useNavigate();
  const handleSetFields = (fields, id) => {
    setCurrentEntityType(id);
  };
  const handleBackButtonClick = () => {
    // Use navigate to go back to the previous page
    navigate(-1);
  };

  // Call handleSetFields when the component mounts
  React.useEffect(() => {
    handleSetFields(fieldsData, currentEntityType);
  }, [location.pathname]); // Update when the pathname changes

  const CustomToolbar = () => {
    return (
      <>
        <div className="master-heading">
          <h2>
            <GridMenuIcon />
            {`Masters`}
          </h2>
          <div className="btnsGrp" onClick={handleBackButtonClick}>
            <Button variant="outlined">Back</Button>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <CustomToolbar />
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3 }}
        className="mainPanel noPadding"
      >
        <>
          <div className="MasterListButtons">
            {fieldsData.map((field, index) => (
              <Link to={`${field.urlName}`} key={index}> 
                {/* Use urlName for constructing URL */}
                <ReactSVG src={field.icon} className="mastIcon" />{" "}
                {field.displayName} {/* Use displayName for rendering */}
              </Link>
            ))}
          </div>
          <Routes>
            <Route path="*" element={<handleEntityList />} />
          </Routes>
        </>
      </Box>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Breadcrumbs, MainLayout } from "components/common";
import {
  getGenericData,
  createGenericEntity,
  updateGenericEntity,
  createLeaveType,
  updateLeaveType,
} from "modules/hr";
import { Button, Table, message,Input } from "antd";
import useStyles from './style.js';
import { CustomBreadcrumb } from "components/common";
import { toast } from "react-toastify";
import { GridMenuIcon } from '@mui/x-data-grid';
import { getFeedbackResponseDetails } from "modules/survey.js";
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../../slices/commonSlice';

const FeedbackResponseDetails = () => {
  const styles = useStyles();
  const { id } = useParams();
  const navigate= useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);

  const [leaveType, setLeaveType] = useState({
    leave_type_name: "",
    max_leaves_allowed: "",
    applicable_after: "",
    max_continuous_days_allowed: "",
    is_carry_forward: false,
    doctype: "Leave Type",
    is_lwp: false,
    is_ppl: false,
    is_optional_leave: false,
    allow_negative: false,
    allow_over_allocation: false,
    include_holiday: false,
    is_compensatory: false,
    is_earned_leave: false,
    allow_encashment: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isViewMode, setIsViewMode] = useState(false);
  const [errors, setErrors] = useState({});
  const [feedbackResponse, setFeedbackResponse] = useState([]);
  const [question, setQuestion] = useState([]);

  const handleGetAllFeedbackResponse = async () => {
    dispatch(setLoading({ loading: true }));
    const res = await getFeedbackResponseDetails(id);
    if(res.status === 200) {
      dispatch(setLoading({ loading: false }));
      setFeedbackResponse(res.data.data);
      setQuestion(res.data.data.response);
    } else {
      dispatch(setLoading({ loading: false }));
    }
  };

  useEffect(() => {
    handleGetAllFeedbackResponse();
  }, [id]);



  const handleCancel = () => {
    navigate(-1);
  };

        const breadcrumbs = [
          {
            label: "IIHT",
            link: "/dashboard",
          },
          {
            label: "Survey",
            link: "/modules/Survey",
          },
          {
            label: "Feedback Response",
            link: "/modules/Survey",
          },
        ] 
  return (
    <MainLayout>
       <CustomBreadcrumb breadcrumbs={breadcrumbs} />
      <div className="master-heading">
      <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon}/>
              <p className={styles.title}>Feedback Response</p>
            </div>
        <div className="btnsGrp">
          <Button variant="outlined" color="secondary" onClick={handleCancel}>
            back
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </div>
      <div className="formApplication leaveMgmtForm">
        <section className="form-section">
          <div className="fields-block">
            <div className="form-field">
              <label>Feedback From *</label>
              <input
                type="text"
                name="leave_type_name"
                placeholder="ex Text"
                value={feedbackResponse.feedback_for}
              />
              {errors.feedback_for && (
                <span className="error">{errors.feedback_for}</span>
              )}
            </div>
            <div className="form-field">
              <label>Feedback Template </label>
              <input
                type="text"
                name="max_leaves_allowed"
                placeholder="ex 4"
                value={feedbackResponse.feedback_template}
              />
            </div>
            <div className="form-field">
              <label>Student Name</label>
              <input
                type="text"
                name="applicable_after"
                placeholder="ex 4"
                value={feedbackResponse.student}
              />
            </div>
            <div className="form-field">
              <label>Student Group </label>
              <input
                type="text"
                name="max_continuous_days_allowed"
                placeholder="ex Text"
                value={feedbackResponse.max_continuous_days_allowed}
              />
            </div>
          </div>
        </section>
        <section className="form-section">
          {question.map((question, index) => (
            <div key={index}>
              <div>
                <h4>Question {index + 1}</h4>
                <hr className={styles.line}></hr>
              </div>
              <div>
              <span>{question.question}</span>
              <div className="form-field">
                {question.type === "select" && (
                  <select value={question.selectedOption} disabled>
                    {question.options.map((option, i) => (
                      <option key={i}>{option}</option>
                    ))}
                  </select>
                )}
                {question.type === "comment" && (
                  <div>
                    <label>Comment Answer:</label>
                    <p>{question.answer}</p>
                  </div>
                )}
                {question.type === "rating" && (
                  <div>
                    <label>Rating:</label>
                    {Array.from({ length: 5 }, (_, i) => (
                      <span key={i} style={{ color: i < question.rating ? "gold" : "gray" }}>★</span>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
        </section>
      </div>
    </MainLayout>
  );
};

export default FeedbackResponseDetails;
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "28px",
    padding: '32px'
  },
  breadCrumbsContainer: {
    display: "inline-flex",
    gap: "4px",
    alignItems: "center"
  },
  breadCrumbsContent: {
    color: "#6F6F6F",
    fontSize: "12px",
    fontWeight: "400",
    cursor: "pointer"
  },
  headerContainer: {
    display: "inline-flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "-20px"
  },
  title: {
    fontSize: "22px",
    color: "#0D1021",
    fontWeight: "500"
  },
  gridIcon: {
    color: "#647B8F"
  },
  buttonsGroup: {
    display: "inline-flex",
    gap: "15px",
    alignItems: "center",
    justifyContent: "center"
  },
  button: {
    cursor: "pointer",
    borderRadius: " 4px",
    background: "#FFF",
    padding: "4px 14px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 0px 2px 0px rgba(21, 40, 80, 0.12), 0px 2px 4px 0px rgba(21, 40, 80, 0.10)"
  },
  filterButton: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "7px",
  },
  buttonText: {
    color: "#405071",
    fontSize: "14px",
    fontWeight: "600"
  },
  activeButton: {
    cursor: "pointer",
    borderRadius: " 4px",
    background: "#A32930",
    padding: "4px 14px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 0px 2px 0px rgba(21, 40, 80, 0.12), 0px 2px 4px 0px rgba(21, 40, 80, 0.10)"
  },
  activeButtonText: {
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "400"
  },
  tableContentView: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#012148",
    cursor: "pointer",
    fontFamily: "Poppins"
  },
  fieldLabel: {
    color: "#012148",
    fontSize: "14px",
    fontWeight: "500",
  },
  tableContentFields: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#0D1021",
    fontFamily: "Poppins"
  },
  buttonPublish: {
    borderRadius: "6px",
    border: "1px solid rgba(44, 131, 236, 0.50)",
    background: "rgba(44, 131, 236, 0.05)",
    padding: "8px 10px",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#2C83EC",
    fontSize: "12px",
    fontWeight: "500",
    fontFamily: "Poppins"
  },
  buttonUnPublish: {
    borderRadius: "6px",
    border: "1px solid #BCBCBC",
    background: "rgba(131, 131, 131, 0.05)",
    padding: "8px 10px",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#6F6F6F",
    fontSize: "12px",
    fontWeight: "500",
    fontFamily: "Poppins"
  },
  link: {
    cursor: "pointer",
    color: "#0D1021",
    fontWeight: "500",
    textDecoration: "underline",
    fontSize: "14px"
  },
  popoverContainer: {
    width: "150px",
  },
  popoverContent: {
    color: "#405071",
    fontSize: "14px",
    fontWeight: "500",
    cursor: "pointer",
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    marginBottom: '48px'
  }, 
  roomNameNumberAndSeatingCapacityContainer: {
    display: 'flex',
  },
  formRow: {
    display: "inline-flex",
    justifyContent: "space-between"
  },
  field: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
  },
  fieldInput: {
    color: "#9DA9B0",
    fontSize: "12px",
    fontWeight: "400",
    padding: "0px 12px",
    borderRadius: "8px",
    background: "#fff",
    outline: "none",
    height: "40px",
    border: "1px solid #CDD6E2"
  },
  seatingArrangementMainContainer: {
    width: "100%",
    display: 'flex',
    flexDirection: 'column'
  },
  seatingArrangementContainer: {
    borderRadius: "8px",
    border: "1px solid var(--IIHT-InputBox-Stroke-Deactive, #C3CBD6)",
    padding: "25px 32px",
  },
  seatContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: "109px",
    height: "119.259px",
    flexShrink: 0,
    borderRadius: "7.471px",
    border: "0.934px dashed var(--IIHT-InputBox-Stroke-Deactive, #C3CBD6)",
    gap: '9px'
  },
  seatNumber: {
    display: " inline-flex",
    padding: "12.14px 26.149px",
    justifyContent: "center",
    alignItems: "center",
    gap: "9.339px",
    borderRadius: "3.736px",
    border: "0.934px solid var(--IIHT-InputBox-Stroke-Deactive, #C3CBD6)",
  },
  seatRow: {
    display: 'flex',
    gap: '16px',
    justifyContent: "space-between"
  },
  seatRowContainer:{
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    overflowX: "scroll"
  }
}));

export default useStyles;

import React, { useEffect, useState } from "react";
import { Input, Select, Radio, Checkbox, Button } from "antd";
import useStyles from "./style";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import MainHeader from "../../../common/MainHeader";
import MainFooter from "../../../common/MainFooter";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSidebar } from "../../../../slices/financeSlice.js";
import LoadingBackdrop from "../../../common/LoadingBackdrop.js";
import { getAcademicYearListing } from "../../../../modules/finance.js";
import { toast } from "react-toastify";
import {
  CategoryOptions,
  ProficiencyLevelOptions,
  questionTypeOptions,
} from "../config";
import { getCourseListing } from "modules/studentGroup";
import {
  createQuestionBank,
  getAllChaptersListing,
  getAllRubricsListing,
  getAllSkillListing,
} from "modules/Examination";

const AddNewQuestionBank = () => {
  const selectSize = "middle";
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [academicYearOptions, setAcademicYearOptions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [skillOptions, setSkillOptions] = useState([]);
  const [chapterOptions, setChapterOptions] = useState([]);
  const [rubricsOptions, setRubricsOptions] = useState([]);
  const [questionType, setQuestionType] = useState("");
  const [options, setOptions] = useState(Array(4).fill(""));
  const [questionText, setQuestionText] = useState("");
  const [showNewQuestion, setShowNewQuestion] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [questionSaved, setQuestionSaved] = useState(false);
  const [values, setValues] = useState({
    academic_year: "",
    name_of_question_bank: "",
    version: "",
    course_code: "",
    course_name: "",
    question_type: "",
    proficiency_level: "",
    duration: "",
    score: "",
    category: "",
    skill: "",
    sub_skill_1: "",
    sub_skill_2: "",
    subject_name: "",
    chapters: "",
    mapped_co: "",
    mapped_pi: "",
    rubrics: "",
  });

  const handleGetAcademicYearListing = async () => {
    const res = await getAcademicYearListing();
    if (res.status === 200) {
      const arr = [];
      const acdemicYearList = res.data.data;
      for (let i = 0; i < acdemicYearList.length; i++) {
        arr.push({
          value: acdemicYearList[i].name,
          label: acdemicYearList[i].name,
        });
      }
      setAcademicYearOptions(arr);
    }
  };

  const handleGetCourseListing = async () => {
    const res = await getCourseListing();
    if (res.status === 200) {
      const arr = [];
      const courseList = res.data.data;
      for (let i = 0; i < courseList.length; i++) {
        arr.push({
          value: courseList[i].name,
          label: courseList[i].name,
        });
      }
      setCourseOptions(arr);
    }
  };

  const handleGetSkillListing = async () => {
    const res = await getAllSkillListing();
    if (res.status === 200) {
      const arr = [];
      const skillList = res.data.data;
      for (let i = 0; i < skillList.length; i++) {
        arr.push({
          value: skillList[i].name,
          label: skillList[i].name,
        });
      }
      setSkillOptions(arr);
    }
  };

  const handleGetChaptersListing = async () => {
    const res = await getAllChaptersListing();
    if (res.status === 200) {
      const arr = [];
      const chapterList = res.data.data;
      for (let i = 0; i < chapterList.length; i++) {
        arr.push({
          value: chapterList[i].name,
          label: chapterList[i].name,
        });
      }
      setChapterOptions(arr);
    }
  };

  const handleGetRubricsListing = async () => {
    const res = await getAllRubricsListing();
    if (res.status === 200) {
      const arr = [];
      const rubricsList = res.data.data;
      for (let i = 0; i < rubricsList.length; i++) {
        arr.push({
          value: rubricsList[i].name,
          label: rubricsList[i].name,
        });
      }
      setRubricsOptions(arr);
    }
  };

  const handleSaveQuestion = () => {
    const newQuestion = {
      questionText: questionText,
      options: [...options],
      question_type: values.question_type,
      proficiency_level: values.proficiency_level,
      duration: values.duration,
      score: values.score,
      category: values.category,
      skill: values.skill,
      sub_skill_1: values.sub_skill_1,
      sub_skill_2: values.sub_skill_2,
      subject_name: values.subject_name,
      chapters: values.chapters,
      mapped_co: values.mapped_co,
      mapped_pi: values.mapped_pi,
      rubrics: values.rubrics,
    };
    setQuestions([...questions, newQuestion]);
    console.log(newQuestion);
    setValues({
      ...values,
      question_type: "",
      proficiency_level: "",
      duration: "",
      score: "",
      category: "",
      skill: "",
      sub_skill_1: "",
      sub_skill_2: "",
      subject_name: "",
      chapters: "",
      mapped_co: "",
      mapped_pi: "",
      rubrics: "",
    });

    setQuestionSaved(true);
    setQuestionText("");
  };

  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        question: "",
        type: "Select",
        options: ["", "", "", ""],
        is_correct: [false, false, false, false],
      },
    ]);
    console.log(questions);
  };

  const removeQuestion = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][field] = value;
    setQuestions(updatedQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options[optionIndex] = value;
    setQuestions(updatedQuestions);
  };

  const handleCorrectAnswerChange = (questionIndex, optionIndex, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].is_correct[optionIndex] = value;
    setQuestions(updatedQuestions);
  };

  const handleRadioChange = (questionIndex, selectedOptionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].is_correct = selectedOptionIndex;
    setQuestions(updatedQuestions);
  };

  const handleTrueFalseChange = (questionIndex, isTrueSelected) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].is_correct = isTrueSelected
      ? "True"
      : "False";
    setQuestions(updatedQuestions);
  };

  const handleTextareaChange = (index, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][field] = value;
    setQuestions(updatedQuestions);
  };

  const handleCreateQuestionClick = () => {
    setShowNewQuestion(true);
    addQuestion();
  };

  const handleSave = async () => {
    try {
      const payload = {
        ...values,
        doctype: "Question Bank",
      };
      const res = await createQuestionBank({ payload });
      console.log(res);
      if (res.status === 200) {
        toast.success("Question Bank drafted!");
        // navigate(`/modules/examination/question-bank/${res.data.message.name}`);
      }
    } catch (error) {
      toast.error(error.response.data.exception);
    }
  };

  useEffect(() => {
    handleGetAcademicYearListing();
    handleGetCourseListing();
    handleGetSkillListing();
    handleGetChaptersListing();
    handleGetRubricsListing();
  }, []);

  return (
    <>
      <MainHeader />
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/dashboard")}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/modules/examination")}
            >
              Examination {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                dispatch(setActiveSidebar({ activeSidebar: 0 }));
                navigate("/modules/examination");
              }}
            >
              Question Bank {" > "}
            </p>
            <p className={styles.breadCrumbsContent}>New Question Bank</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>New Question Bank</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div
                className={styles.button}
                onClick={() => {
                  dispatch(setActiveSidebar({ activeSidebar: 0 }));
                  navigate("/modules/examination");
                }}
              >
                <p className={styles.buttonText}>Back</p>
              </div>
              <div className={styles.activeButton} onClick={handleSave}>
                <p className={styles.activeButtonText}>Save Question Bank</p>
              </div>
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Academic Year *</p>
                <Select
                  showSearch
                  size={selectSize}
                  value={values.academic_year}
                  placeholder="Select"
                  className={styles.selectInput}
                  options={academicYearOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      academic_year: value,
                    });
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Name of Question Bank *</p>
                <Input
                  placeholder="ex Text"
                  value={values.name_of_question_bank}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      name_of_question_bank: e.target.value,
                    });
                  }}
                  className={styles.fieldInput}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Version *</p>
                <Input
                  placeholder="ex Text"
                  value={values.version}
                  className={styles.fieldInput}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      version: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Course Name *</p>
                <Select
                  showSearch
                  value={values.course_name}
                  size={selectSize}
                  placeholder="Select"
                  options={courseOptions}
                  className={styles.selectInput}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      course_name: value,
                    });
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Course Code *</p>
                <Input
                  placeholder="ex Text"
                  value={values.course_code}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      course_code: e.target.value,
                    });
                  }}
                  className={styles.fieldInput}
                />
              </div>
              <div className={styles.field}></div>
            </div>
            <div>
              <div className={styles.buttonsGroup}>
                <div
                  className={styles.activeButton}
                  onClick={handleCreateQuestionClick}
                >
                  <p className={styles.activeButtonText}>Create Questions</p>
                </div>
              </div>
            </div>
            {showNewQuestion && (
              <>
                {questions.map((question, index) => (
                  <div className={styles.formContainer} key={index}>
                    <div className={styles.formHeadingContainer}>
                      <p className={styles.headingTitle}>Create Question</p>
                    </div>
                    <div className={styles.formRow}>
                      <div className={styles.field}>
                        <p className={styles.fieldLabel}>Question Type *</p>
                        <Select
                          showSearch
                          size={selectSize}
                          value={question.type}
                          placeholder="Question Type"
                          options={questionTypeOptions}
                          onChange={(value) =>
                            handleQuestionChange(index, "type", value)
                          }
                          className={styles.selectInput}
                        />
                      </div>
                      <div className={styles.field}>
                        <p className={styles.fieldLabel}>Proficiency Level *</p>
                        <Select
                          showSearch
                          size={selectSize}
                          placeholder="Select"
                          value={values.proficiency_level}
                          options={ProficiencyLevelOptions}
                          className={styles.selectInput}
                          onChange={(value) => {
                            setValues({
                              ...values,
                              proficiency_level: value,
                            });
                          }}
                        />
                      </div>
                      <div className={styles.field}>
                        <p className={styles.fieldLabel}>
                          Duration (in minutes) *
                        </p>
                        <Input
                          placeholder="ex Text"
                          className={styles.fieldInput}
                          value={values.duration}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              duration: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.formRow}>
                      <div className={styles.field}>
                        <p className={styles.fieldLabel}>Score *</p>
                        <Input
                          placeholder="ex Text"
                          className={styles.fieldInput}
                          value={values.score}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              score: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className={styles.field}>
                        <p className={styles.fieldLabel}>Category *</p>
                        <Select
                          showSearch
                          size={selectSize}
                          placeholder="Select"
                          className={styles.selectInput}
                          options={CategoryOptions}
                          value={values.category}
                          onChange={(value) => {
                            setValues({
                              ...values,
                              category: value,
                            });
                          }}
                        />
                      </div>
                      <div className={styles.field}>
                        <p className={styles.fieldLabel}>Skill *</p>
                        <Select
                          showSearch
                          size={selectSize}
                          placeholder="Select"
                          className={styles.selectInput}
                          options={skillOptions}
                          value={values.skill}
                          onChange={(value) => {
                            setValues({
                              ...values,
                              skill: value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.formRow}>
                      <div className={styles.field}>
                        <p className={styles.fieldLabel}>Sub Skill 1 *</p>
                        <Select
                          showSearch
                          size={selectSize}
                          placeholder="Select"
                          className={styles.selectInput}
                          options={skillOptions}
                          value={values.sub_skill_1}
                          onChange={(value) => {
                            setValues({
                              ...values,
                              sub_skill_1: value,
                            });
                          }}
                        />
                      </div>
                      <div className={styles.field}>
                        <p className={styles.fieldLabel}>Sub Skill 2 *</p>
                        <Select
                          showSearch
                          size={selectSize}
                          placeholder="Select"
                          className={styles.selectInput}
                          options={skillOptions}
                          value={values.sub_skill_2}
                          onChange={(value) => {
                            setValues({
                              ...values,
                              sub_skill_2: value,
                            });
                          }}
                        />
                      </div>
                      <div className={styles.field}>
                        <p className={styles.fieldLabel}>
                          Course / Subject Name *
                        </p>
                        <Select
                          showSearch
                          size={selectSize}
                          placeholder="Select"
                          className={styles.selectInput}
                          options={courseOptions}
                          value={values.subject_name}
                          onChange={(value) => {
                            setValues({
                              ...values,
                              subject_name: value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.formRow}>
                      <div className={styles.field}>
                        <p className={styles.fieldLabel}>Chapter Name *</p>
                        <Select
                          showSearch
                          size={selectSize}
                          placeholder="Select"
                          className={styles.selectInput}
                          options={chapterOptions}
                          value={values.chapters}
                          onChange={(value) => {
                            setValues({
                              ...values,
                              chapters: value,
                            });
                          }}
                        />
                      </div>
                      <div className={styles.field}>
                        <p className={styles.fieldLabel}>Mapped CO *</p>
                        <Input
                          placeholder="ex BSC_CS"
                          className={styles.fieldInput}
                        />
                      </div>
                      <div className={styles.field}>
                        <p className={styles.fieldLabel}>Mapped PI *</p>
                        <Input
                          placeholder="ex Text"
                          className={styles.fieldInput}
                        />
                      </div>
                    </div>
                    <div className={styles.formRow}>
                      <div className={styles.field}>
                        <p className={styles.fieldLabel}>Rubrics List </p>
                        <Select
                          showSearch
                          size={selectSize}
                          placeholder="Select"
                          className={styles.selectInput}
                          options={rubricsOptions}
                          value={values.rubrics}
                          onChange={(value) => {
                            setValues({
                              ...values,
                              rubrics: value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.formHeadingContainer}>
                      <p className={styles.headingTitle}></p>
                    </div>
                    {question.type === "Single Correct Answer" && (
                      <>
                        <div className={styles.fields}>
                          <p className={styles.fieldLabel}>Question *</p>
                          <Input
                            value={question.question}
                            onChange={(e) =>
                              handleQuestionChange(
                                index,
                                "question",
                                e.target.value
                              )
                            }
                            className={styles.fieldInput}
                          />
                        </div>
                        <div className={styles.fields}>
                          <p className={styles.fieldLabel}>Options *</p>
                          {question.options.map((option, optionIndex) => (
                            <div key={optionIndex}>
                              <Radio
                                checked={question.is_correct === optionIndex}
                                onChange={() =>
                                  handleRadioChange(index, optionIndex)
                                }
                              >
                                Option {optionIndex + 1}
                              </Radio>
                              <Input
                                value={option}
                                onChange={(e) =>
                                  handleOptionChange(
                                    index,
                                    optionIndex,
                                    e.target.value
                                  )
                                }
                                className={styles.fieldInput}
                              />
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                    {question.type === "Multiple Correct Answer" && (
                      <>
                        <div className={styles.fields}>
                          <p className={styles.fieldLabel}>Question *</p>
                          <Input
                            value={question.question}
                            onChange={(e) =>
                              handleQuestionChange(
                                index,
                                "question",
                                e.target.value
                              )
                            }
                            className={styles.fieldInput}
                          />
                        </div>
                        <div className={styles.fields}>
                          <p className={styles.fieldLabel}>Options *</p>
                          {question.options.map((option, optionIndex, i) => (
                            <div key={optionIndex}>
                              <Checkbox
                                checked={question.is_correct[optionIndex]}
                                onChange={(e) =>
                                  handleCorrectAnswerChange(
                                    index,
                                    optionIndex,
                                    e.target.checked
                                  )
                                }
                              >
                                Option {optionIndex + 1}
                              </Checkbox>
                              <Input
                                value={option}
                                onChange={(e) =>
                                  handleOptionChange(
                                    index,
                                    optionIndex,
                                    e.target.value
                                  )
                                }
                                className={styles.fieldInput}
                              />
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                    {question.type === "True/False" && (
                      <>
                        <div className={styles.fields}>
                          <p className={styles.fieldLabel}>Question *</p>
                          <Input
                            value={question.question}
                            onChange={(e) =>
                              handleQuestionChange(
                                index,
                                "question",
                                e.target.value
                              )
                            }
                            className={styles.fieldInput}
                          />
                        </div>
                        <div className={styles.fields}>
                          <p className={styles.fieldLabel}>Options *</p>
                          <div>
                            <Radio
                              checked={question.is_correct === "True"}
                              onChange={() =>
                                handleTrueFalseChange(index, true)
                              }
                            >
                              True
                            </Radio>
                          </div>
                          <div>
                            <Radio
                              checked={question.is_correct === "False"}
                              onChange={() =>
                                handleTrueFalseChange(index, false)
                              }
                            >
                              False
                            </Radio>
                          </div>
                        </div>
                      </>
                    )}
                    <div className={styles.buttonsGroupra}>
                      <div
                        onClick={() => removeQuestion(index)}
                        className={styles.button}
                      >
                        <div className={styles.buttonText}>Remove Question</div>
                      </div>
                      <div
                        onClick={addQuestion}
                        className={styles.activeButton}
                      >
                        <div className={styles.activeButtonText}>
                          Add Question
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}

            {questionSaved && (
              <div className={styles.buttonContainer}>
                <div className={styles.buttonsGroup}>
                  <div
                    className={styles.button}
                    // onClick={() => handleCloneQuestion(questions.length - 1)}
                  >
                    <p className={styles.buttonText}>
                      {" "}
                      Clone Previous Question
                    </p>
                  </div>
                  <div
                    className={styles.activeButton}
                    // onClick={handleAddNewQuestion}
                  >
                    <p className={styles.activeButtonText}> Add New Question</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default AddNewQuestionBank;

import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { Popover, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./calender.scss";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Grid from "@mui/material/Grid";
import { MainLayout } from "components/common";

const localizer = momentLocalizer(moment);

const MyCalendar = ({ userRole }) => {
  const [date, setDate] = useState(new Date());
  const [view, setView] = useState("month");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [value, setValue] = React.useState("1");

  const isAdmin = userRole === "admin";
  const isDepartment = userRole === "department";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNext = () => {
    if (view === "month") {
      setDate(moment(date).add(1, "months").toDate());
    } else if (view === "week") {
      setDate(moment(date).add(1, "weeks").toDate());
    } else if (view === "day") {
      setDate(moment(date).add(1, "days").toDate());
    }
  };

  const handlePrev = () => {
    if (view === "month") {
      setDate(moment(date).subtract(1, "months").toDate());
    } else if (view === "week") {
      setDate(moment(date).subtract(1, "weeks").toDate());
    } else if (view === "day") {
      setDate(moment(date).subtract(1, "days").toDate());
    }
  };

  const handleViewChange = (selectedView) => {
    setView(selectedView);
  };

  const handleSelectEvent = (event, e) => {
    setSelectedEvent(event);
    setAnchorEl(e.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const views = ["month", "week", "day", "agenda"];

  const startDate = new Date();
  const endDate = moment().add(2, "days").toDate();
  const numberOfDays = moment(endDate).diff(startDate, "days") + 1;
  const dateWithMultipleEvents = new Date();

  const event1 = {
    start: dateWithMultipleEvents,
    end: dateWithMultipleEvents,
    title: "Event 1",
    description: "This is a single-day event.",
    location: "Location 1",
  };

  const event2 = {
    start: dateWithMultipleEvents,
    end: new Date(dateWithMultipleEvents.getTime() + 3 * 24 * 60 * 60 * 1000),
    title: "Event 2",
    description: "This event spans multiple days.",
    location: "Location 2",
  };

  const event3 = {
    start: dateWithMultipleEvents,
    end: new Date(dateWithMultipleEvents.getTime() + 2 * 24 * 60 * 60 * 1000),
    title: "Event 3",
    description: "This event spans multiple days.",
    location: "Location 3",
  };
  const events = [
    event1,
    ...Array.from({ length: 4 }, (_, index) => ({
      ...event2,
      start: new Date(dateWithMultipleEvents.getTime() + index * 24 * 60 * 60 * 1000),
      end: new Date(dateWithMultipleEvents.getTime() + index * 24 * 60 * 60 * 1000),
    })),
    ...Array.from({ length: 8 }, (_, index) => ({
      ...event3,
      start: new Date(dateWithMultipleEvents.getTime() + index * 24 * 60 * 60 * 1000),
      end: new Date(dateWithMultipleEvents.getTime() + index * 24 * 60 * 60 * 1000),
    })),
  ];

  moment.locale("en");
  const headerFormat =
    view === "month"
      ? "MMMM YYYY"
      : view === "week"
      ? `${moment(date).startOf("week").format("[MMMM] DD")} - ${moment(date)
          .endOf("week")
          .format("DD")}`
      : "dddd, MMMM DD, YYYY";

  // Define a function to get event styles
  const eventStyleGetter = (event, start, end, isSelected) => {
    const backgroundColor = getEventBackgroundColor(event); // Implement your logic to get the color
    const style = {
      backgroundColor,
      borderRadius: "5px",
      opacity: 0.8,
      color: "white",
      border: "1px solid #ccc",
      display: "block",
      cursor: "pointer",
    };

    return {
      style,
    };
  };

  const getEventBackgroundColor = (event) => {
    if (event.title === "Event 1") {
      return "green";
    } else if (event.title === "Event 2") {
      return "blue";
    } else {
      return "gray";
    }
  };
  // const components = {
  //   event: isAdmin ? AdminEventComponent : isDepartment ? DepartmentEventComponent : DefaultEventComponent,
  // };
  return (
    <MainLayout>
      <Box sx={{ width: "100%", typography: "body1" }} className="container">
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Grid
              container
              spacing={3}
              className="calenderTopBar"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid item xs={4}>
                <TabList onChange={handleChange} aria-label="CalenderTabs">
                  <Tab label="Events" value="1" />
                  <Tab label="Holidays" value="2" />
                </TabList>
              </Grid>
              {value === "1" && (
                <Grid
                  item
                  xs={4}
                  display={"flex"}
                  justifyContent={"center"}
                  className="monthDateNav"
                >
                  <button onClick={handlePrev}>
                    <ChevronLeftIcon />
                  </button>
                  <span> {moment(date).format(headerFormat)} </span>
                  <button onClick={handleNext}>
                    <ChevronRightIcon />
                  </button>
                </Grid>
              )}

              {value === "1" && (
                <Grid item xs={4} display={"flex"} justifyContent={"right"}>
                  <select value={view} onChange={(e) => handleViewChange(e.target.value)}>
                    {views.map((v) => (
                      <option key={v} value={v}>
                        {v.charAt(0).toUpperCase() + v.slice(1)}
                      </option>
                    ))}
                  </select>
                </Grid>
              )}
            </Grid>
          </Box>
          <TabPanel value="1">
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 600 }}
              date={date}
              view={view}
              views={views}
              onSelectEvent={handleSelectEvent}
              eventPropGetter={eventStyleGetter}
              // components={components}
            />

            {/* Event Popover */}
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <div style={{ padding: 16 }}>
                <Typography variant="h6">{selectedEvent && selectedEvent.title}</Typography>
                <Typography>{selectedEvent && selectedEvent.description}</Typography>
                <Typography>
                  <strong>Location:</strong>
                  {selectedEvent && selectedEvent.location}
                </Typography>
                <Typography>
                  <strong>Start Time:</strong>
                  {moment(selectedEvent && selectedEvent.start).format("LLL")}
                </Typography>
                <Typography>
                  <strong>End Time:</strong>
                  {moment(selectedEvent && selectedEvent.end).format("LLL")}
                </Typography>
              </div>
            </Popover>
          </TabPanel>
        </TabContext>
      </Box>
    </MainLayout>
  );
};

export default MyCalendar;

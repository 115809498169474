import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: "flex", 
    flexDirection: "column",
    gap: "28px",
    padding: "32px 28px"
  },
  breadCrumbsContainer: {
    display: "inline-flex", 
    gap: "4px", 
    alignItems: "center"
  },
  labelText: {
    color: "#707070",
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "Poppins",
    letterSpacing: "0.6px"
  },
  labelValue: {
    color: "#012148",
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "Poppins",
  },
  formRow: {
    display: "inline-flex",
    justifyContent: "space-between"
  },
  profilePic: {
    width: "88px",
    height: "88px",
    backgroundColor: "#f6f6f6",
    borderRadius: "50%"
  },
  uploadField: {
    display: "flex",
    flexDirection: "column",
  },
  uploadContainer: {
    display: "inline-flex",
    gap: "32px",
    justifyContent: "center",
    alignItems: "center"
  },
  profilePicUpload: {
    width: "88px",
    height: "88px",
    borderRadius: "50%"
  },
  field: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    marginBottom: "40px"
  },
  breadCrumbsContent: {
    color: "#6F6F6F",
    fontSize: "12px",
    fontWeight: "400",
    cursor: "pointer"
  },
  headerContainer: {
    display: "inline-flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "-20px"
  },
  title: {
    fontSize: "22px", 
    color: "#0D1021", 
    fontWeight: "500"
  },
  gridIcon: {
    color: "#647B8F"
  },
  buttonsGroup: {
    display: "inline-flex",
    gap: "15px",
    alignItems: "center",
    justifyContent: "center"
  },
  button: {
    cursor: "pointer",
    borderRadius:" 4px",
    background: "#FFF",
    padding: "4px 14px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 0px 2px 0px rgba(21, 40, 80, 0.12), 0px 2px 4px 0px rgba(21, 40, 80, 0.10)"
  },
  filterButton: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "7px",
  },
  buttonText: {
    color: "#405071",
    fontSize: "14px",
    fontWeight: "600"
  },
  activeButton: {
    cursor: "pointer",
    borderRadius:" 4px",
    background: "#A32930",
    padding: "4px 14px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 0px 2px 0px rgba(21, 40, 80, 0.12), 0px 2px 4px 0px rgba(21, 40, 80, 0.10)"
  },
  activeButtonText: {
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "400"
  },
  tableContentView: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#012148",
    cursor: "pointer",
    fontFamily: "Poppins"
  },
  tableContentFields: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#0D1021",
    fontFamily: "Poppins"
  },
  buttonPublish: {
    borderRadius: "6px",
    border: "1px solid rgba(44, 131, 236, 0.50)",
    background: "rgba(44, 131, 236, 0.05)",
    padding: "8px 10px",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#2C83EC",
    fontSize: "12px",
    fontWeight: "500",
    fontFamily: "Poppins"
  },
  buttonUnPublish: {
    borderRadius: "6px",
    border: "1px solid #BCBCBC",
    background: "rgba(131, 131, 131, 0.05)",
    padding: "8px 10px",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#6F6F6F",
    fontSize: "12px",
    fontWeight: "500",
    fontFamily: "Poppins"
  },
  contentContainer: {
    display: "inline-flex",
    gap: "32px",
    flexWrap: "wrap"
  },
  itemContainer: {
    width: "30%",
    display: "inline-flex",
    alignItems: "center",
    gap: "16px",
    padding: "12px 16px",
    borderRadius: "4px",
    border: "1px solid rgba(236, 236, 236, 0.23)",
    background: "#FFF",
    boxShadow: "1px 4px 7.3px 0px rgba(195, 195, 195, 0.25)",
    cursor: "pointer"
  },
  itemTitle: {
    color: "#0D1021",
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "Poppins"
  }
}));

export default useStyles;

import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import UniversityIcon from "../../../assets/images/master-university-icon.svg";
import {
  Link,
  Route,
  Routes,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import { Button } from "@mui/material";
import { GridMenuIcon } from "@mui/x-data-grid";
import { useState } from "react";
import { ReactSVG } from "react-svg";
import { Breadcrumbs } from "components";

const fieldsData = [
  { name: "Masters", icon: UniversityIcon },
  { name: "Allocations", icon: UniversityIcon },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function LeaveManagement() {
  const location = useLocation();
  const { entityType } = useParams();
  const [currentEntityType, setCurrentEntityType] = useState(entityType);
  const navigate = useNavigate();
  const handleSetFields = (fields, id) => {
    setCurrentEntityType(id);
  };
  const handleBackButtonClick = () => {
    // Use navigate to go back to the previous page
    navigate(-1);
  };

  // Function to convert entity type name to lowercase and replace spaces with hyphens
  const formatEntityType = (entityType) => {
    return entityType.toLowerCase().replace(/\s+/g, "-");
  };

  // Call handleSetFields when the component mounts
  React.useEffect(() => {
    handleSetFields(fieldsData, currentEntityType);
  }, [location.pathname]); // Update when the pathname changes

  const CustomToolbar = () => {
    return (
      <>
        <Breadcrumbs />
        <div className="master-heading">
          <h2>
            <GridMenuIcon />
            {`Leave Management`}
          </h2>
          <div className="btnsGrp" onClick={handleBackButtonClick}>
            <Button variant="outlined">Back</Button>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <CustomToolbar />
      <Box
        component="div"
        sx={{ flexGrow: 1, p: 3 }}
        className="mainPanel noPadding"
      >
        <>
          <div className="MasterListButtons">
            {fieldsData.map((field, index) => (
              <Link
                to={`/modules/hr/leave-management/${formatEntityType(
                  field.name
                )}`}
                key={index}
              >
                <ReactSVG src={field.icon} className="mastIcon" />
                {field.name}
              </Link>
            ))}
          </div>
          <Routes>
            {fieldsData.map((field, index) => (
              <Route
                key={index}
                path={`/modules/hr/leave-management/${formatEntityType(
                  field.name
                )}`}
                element={<handleEntityList entityType={field.name} />}
              />
            ))}
          </Routes>
        </>
      </Box>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import useStyles from './style';
import { useNavigate, useParams } from 'react-router-dom';
import { GridMenuIcon } from '@mui/x-data-grid';
import MainHeader from '../../../common/MainHeader';
import MainFooter from '../../../common/MainFooter';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveSidebar } from '../../../../slices/financeSlice';
import { getFeeCategory } from '../../../../modules/finance';
import { setLoading } from "../../../../slices/commonSlice";
import LoadingBackdrop from "../../../common/LoadingBackdrop.js";

const GetUpdateFeeCategory = () => {
  const styles= useStyles();
  const navigate= useNavigate();
  const dispatch = useDispatch();
  const { category } = useParams();
  const { loading } = useSelector((state) => state.common);
  const [showAmountInput, setShowAmountInput] = useState(false);
  const [updateFeeCategory, setUpdateFeeCategory] = useState(false);
  const [values, setValues] = useState({
    category_name: "",
    custom_add_amount: null,
    custom_amount: "",
    description: "",
    active: 1
  });
  const [errors, setErrors] = useState({
    category_name: "",
    custom_add_amount: null,
    custom_amount: "",
    description: ""
  });

  const handleFeeCategoryNavigation = () => {
    dispatch(setActiveSidebar({ activeSidebar: 1 }));
    navigate("/modules/finance");
  };

  const handleAddAmount = ({ value }) => {
    setShowAmountInput(value);
    setValues({
      ...values,
      custom_add_amount: value ?  1 : 0,
      custom_amount: value ? values.custom_amount : ""
    });
    setErrors({
      ...errors,
      custom_add_amount: "",
      custom_amount: ""
    });
  };

  const handleValidateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!values.category_name.trim()) {
      newErrors.category_name = "Category name is required";
      isValid = false;
    } else {
      newErrors.category_name = "";
    }

    if (values.custom_add_amount === null) {
      newErrors.custom_add_amount = "Required";
      isValid = false;
    } else {
      newErrors.custom_add_amount = "";
    }

    if (values.custom_add_amount === 1) {
      if (!values.custom_amount.trim()) {
        newErrors.custom_amount = "Amount is required";
        isValid = false;
      } else if (isNaN(parseFloat(values.custom_amount.trim()))) {
        newErrors.custom_amount = "Please enter a valid amount";
        isValid = false;
      } else {
        newErrors.custom_amount = "";
      }
    }

    if (!values.description.trim()) {
      newErrors.description = "Description is required";
      isValid = false;
    } else {
      newErrors.description = "";
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleUpdate = async () => {
    dispatch(setLoading({ loading: true }))
    const isValid = handleValidateForm();
    if(isValid) {
      const payload = {
        category_name: values.category_name,
        custom_add_amount: values.custom_add_amount,
        custom_amount: values.custom_amount,
        description: values.description,
        enabled: values.active
      };
      dispatch(setLoading({ loading: false }));
    } else {
      dispatch(setLoading({ loading: false }));
    }
  };

  const handleGetFeeCategory = async () => { 
    dispatch(setLoading({ loading: true }));
    const res =  await getFeeCategory({ name: category });
    if(res.status === 200) {
      const feeCategoryDetails = res.data.data;
      setValues({
        ...values,
        category_name: feeCategoryDetails.category_name,
        custom_add_amount: feeCategoryDetails.custom_add_amount,
        custom_amount: feeCategoryDetails.custom_amount,
        description: feeCategoryDetails.description,
        active: feeCategoryDetails.enabled
      });
      if(feeCategoryDetails.custom_add_amount == 1) {
        setShowAmountInput(true);
      }
      dispatch(setLoading({ loading: false }));
    } else {
      console.log("error: ", res);
    }
  };

  useEffect(() => {
    handleGetFeeCategory();
  }, []);

  return (
    <>
      <MainHeader />
      {
        loading ?
          <LoadingBackdrop open={loading} /> :
          <div className={styles.mainContainer}>
            <div className={styles.breadCrumbsContainer}>
              <p className={styles.breadCrumbsContent} onClick={() => navigate("/dashboard")}>IIHT {" > "}</p>
              <p className={styles.breadCrumbsContent} onClick={() => navigate("/modules/finance")}>Finance {" > "}</p>
              <p className={styles.breadCrumbsContent} onClick={handleFeeCategoryNavigation}>Fee Category</p>
            </div>
            <div className={styles.headerContainer}>
              <div className={styles.breadCrumbsContainer}>
                <GridMenuIcon className={styles.gridIcon}/>
                <p className={styles.title}>{category}</p>
              </div>
              <div className={styles.buttonsGroup}>
                {
                  updateFeeCategory ? 
                    <>
                      <div className={styles.button}  onClick={() => {
                        setUpdateFeeCategory(false);
                        handleGetFeeCategory();
                        setErrors({
                          category_name: "",
                          custom_add_amount: "",
                          custom_amount: "",
                          description: ""
                        })
                      }}>
                        <p className={styles.buttonText}>Back</p>
                      </div>
                      <div className={styles.activeButton} onClick={handleUpdate}>
                        <p className={styles.activeButtonText}>Save</p>
                      </div>
                    </> :
                    <>
                      <div className={styles.button} onClick={handleFeeCategoryNavigation}>
                        <p className={styles.buttonText}>Back</p>
                      </div>
                      <div className={styles.activeButton} onClick={() => setUpdateFeeCategory(true)}>
                        <p className={styles.activeButtonText}>Edit</p>
                      </div>
                    </>
                }
              </div>
            </div>
            <div className={styles.formContainer}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Fee Category Name *</p>
                <input
                  type="text"
                  placeholder="ex Library Fee"
                  className={styles.fieldInput}
                  value={values.category_name}
                  disabled={!updateFeeCategory}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      category_name: e.target.value
                    });
                    setErrors({
                      ...errors,
                      category_name: ""
                    });
                  }}
                />
                {
                  errors.category_name &&
                  <p className={styles.errorMessage}>{errors.category_name}</p>
                }
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Add Amount</p>
                <div className={styles.checkboxContainer}>
                  <div className={styles.checkboxLabel}>
                    <input type="checkbox" disabled={!updateFeeCategory} className={styles.checkbox} checked={values.custom_add_amount == 1 ? true : false}  onClick={() => handleAddAmount({ value: !showAmountInput })}/>
                    <p className={styles.fieldLabel}>Yes</p>
                  </div>
                </div>
                {
                  errors.custom_add_amount &&
                  <p className={styles.errorMessage}>{errors.custom_add_amount}</p>
                }
              </div>
              {
                showAmountInput &&
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>Amount *</p>
                  <input
                    type="text"
                    placeholder="ex 1500"
                    className={styles.fieldInput}
                    value={values.custom_amount}
                    disabled={!updateFeeCategory}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        custom_amount: e.target.value
                      });
                      setErrors({
                        ...errors,
                        custom_amount: ""
                      });
                    }}
                  />
                  {
                    errors.custom_amount &&
                    <p className={styles.errorMessage}>{errors.custom_amount}</p>
                  }
                </div>
              }
              <div className={styles.checkboxContainer}>
                  <div className={styles.checkboxLabel}>
                    <input type="radio" disabled={!updateFeeCategory} className={styles.checkbox} checked={values.active == 1} onChange={() => {
                      setValues({
                        ...values,
                        active: 1
                      })
                    }}/>
                    <p className={styles.fieldLabel}>Active</p>
                  </div>
                  <div className={styles.checkboxLabel}>
                    <input type="radio" disabled={!updateFeeCategory} className={styles.checkbox} checked={values.active == 0} onChange={() => {
                      setValues({
                        ...values,
                        active: 0
                      })
                    }}/>
                    <p className={styles.fieldLabel}>Inactive</p>
                  </div>
                </div>
              <div className={styles.textarea}>
                <p className={styles.fieldLabel}>Description *</p>
                <textarea
                  type="text"
                  placeholder="Enter Text here..."
                  className={styles.textAreaInput}
                  value={values.description}
                  disabled={!updateFeeCategory}
                  rows={5}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      description: e.target.value
                    });
                    setErrors({
                      ...errors,
                      description: ""
                    });
                  }}
                />
                {
                  errors.description &&
                  <p className={styles.errorMessage}>{errors.description}</p>
                }
              </div>
            </div>
          </div> 
      }
      <MainFooter />
    </>
  )
};

export default GetUpdateFeeCategory;
import React, { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GridMenuIcon } from "@mui/x-data-grid";
import { DeleteOutlined } from "@material-ui/icons";
import { Table } from "antd";
import { Button, IconButton, Stack, Typography } from "iiht-b2a-ui-components/build/components";
import { CustomBreadcrumb, FormTextField, MainLayout } from "components/common";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { validationRules } from "constants/validationRules";
import { createGenericData, getGenericDataApi, updateGenericData } from "modules/hr";
import { setLoading } from "slices/commonSlice";
import { checkIfObjectNotEmpty } from "utils";
import { AddBoxIcon } from "icons";

const AddAppointmentLetterTemplate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id: appointmentLetterId = undefined } = useParams();
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [appointmentLetterData, setAppointmentLetterData] = useState({});
  const [termRows, setTermRows] = useState([
    {
      id: 0,
      key: 0,
      number: 0,
      title: "",
      description: "",
    },
  ]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const isEditMode = !!(location.pathname.includes("edit-view") && appointmentLetterId);
  const [isSaveBtnDisable, setIsSaveBtnDisable] = useState(isEditMode);
  const { loading } = useSelector((state) => state.common);

  const breadcrumbs = [
    {
      label: "IIHT",
      link: "/dashboard",
    },
    {
      label: "HR",
      link: "/modules/hr",
    },
    {
      label: "Recruitment",
      link: "/modules/hr",
    },
    {
      label: "Masters",
      link: "/modules/hr/masters",
    },
    {
      label: "Appointment Letter Template",
      link: "/modules/hr/masters/appointment-letter-template",
    },
    {
      label: "New",
      link: "",
    },
  ];

  const defaultValues = {
    template_name: "",
    introduction: "",
    closing_notes: "",
    terms: [
      {
        title: "",
        description: "",
      },
    ],
  };

  const methods = useForm({
    defaultValues: defaultValues,
  });

  const { handleSubmit, reset, watch, getValues, control } = methods;

  const { append: termAppend, remove: termRemove } = useFieldArray({
    control: control,
    name: "terms",
  });

  const descriptionWatch = watch("introduction");
  const closedNotesWatch = watch("closing_notes");

  const columns = [
    {
      dataIndex: "number",
      title: "SNo.",
      width: "20%",
      render: (val) => val + 1,
    },
    {
      dataIndex: "",
      title: "Title *",
      width: "40%",
      render: (_val, _row, rowIndex) => {
        return (
          <div style={{ maxWidth: "300px" }}>
            <FormTextField
              name={`terms.${rowIndex}.title`}
              variant="outlined"
              size="small"
              fullWidth
              onInputChange={() => setIsSaveBtnDisable(false)}
              rules={{
                required: validationRules.REQUIRED,
              }}
            />
          </div>
        );
      },
    },
    {
      dataIndex: "",
      title: "Description *",
      width: "40%",
      render: (_val, _row, rowIndex) => {
        return (
          <div style={{ maxWidth: "300px" }}>
            <FormTextField
              name={`terms.${rowIndex}.description`}
              variant="outlined"
              size="small"
              fullWidth
              onInputChange={() => setIsSaveBtnDisable(false)}
              rules={{
                required: validationRules.REQUIRED,
              }}
            />
          </div>
        );
      },
    },
  ];

  const onSubmit = async (data) => {
    dispatch(setLoading({ loading: true }));
    const terms = [];
    data.terms?.forEach((term) => {
      term?.title &&
        terms.push({
          title: term.title,
          description: term.description,
        });
    });
    const payload = {
      doctype: "Appointment Letter Template",
      id: appointmentLetterId || data.id,
      template_name: data.template_name,
      introduction: data.introduction,
      closing_notes: data?.closing_notes,
      terms,
    };
    const res = isEditMode ? await updateGenericData(payload) : await createGenericData(payload);
    isEditMode && fetchAppointmentLetter();
    if (res?.status === 200) {
      toast.success(`Appointment Letter Template ${isEditMode ? "Updated" : "Created"}!`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsSaveBtnDisable(true);
      setIsFormSubmited(true);
    } else {
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    dispatch(setLoading({ loading: false }));
  };

  const fetchAppointmentLetter = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Appointment Letter Template",
      id: appointmentLetterId,
    };
    const res = await getGenericDataApi(params);
    if (res?.status === 200) {
      const data = res?.data?.message;
      setAppointmentLetterData(data);
      const terms = [];
      const rows = [];
      if (data.terms?.length) {
        data.terms.forEach((term, index) => {
          rows.push({
            id: index,
            key: index,
            number: index,
            title: "",
            description: "",
          });
          terms.push({
            title: term?.title,
            description: term?.description,
          });
        });
      }
      setTermRows(rows);
      reset({
        template_name: data.template_name,
        introduction: data.introduction,
        terms: terms,
        closing_notes: data?.closing_notes,
      });
    }
    dispatch(setLoading({ loading: false }));
  };

  const onSelectChange = (selectedKeys, selectedRowsData) => {
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      id: record.id,
    }),
  };

  const handleAddRow = () => {
    setTermRows((prev) => {
      return [
        ...prev,
        {
          id: termRows.length,
          key: termRows.length,
          number: termRows.length,
          title: "",
          description: "",
        },
      ];
    });
    termAppend({
      title: "",
      description: "",
    });
  };

  const handleDeleteRow = () => {
    const termsData = [...termRows];
    const data = [];
    let i = 0;
    termsData.forEach((term) => {
      if (!selectedRowKeys.includes(term.key)) {
        data.push({ ...term, id: i, key: i, number: i });
        i++;
      }
    });
    setTermRows(data);
    termRemove(selectedRowKeys);
    setSelectedRowKeys([]);
    const { terms } = getValues();
    reset({
      ...getValues(),
      terms: terms,
    });
    setIsSaveBtnDisable(false);
  };

  useEffect(() => {
    if (isEditMode) {
      fetchAppointmentLetter();
    }
  }, [isEditMode]);

  useEffect(() => {
    if (checkIfObjectNotEmpty(appointmentLetterData)) {
      const { introduction } = getValues();
      introduction !== appointmentLetterData?.introduction
        ? setIsSaveBtnDisable(false)
        : setIsSaveBtnDisable(true);
    }
  }, [descriptionWatch, closedNotesWatch, appointmentLetterData]);

  return (
    <MainLayout>
      {loading && <LoadingBackdrop open={loading} />}
      <div className="interviewTypeContainer">
        <CustomBreadcrumb breadcrumbs={breadcrumbs} />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={1} alignItems="center">
                <GridMenuIcon className="gridIcon" />
                <Typography color="#0D1021" fontSize={22}>
                  {isEditMode
                    ? "View Appointment Letter Template"
                    : "New Appointment Letter Template"}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={3}>
                <Button
                  variant="contained"
                  color="grey"
                  className="qa-save-btn"
                  size="large"
                  onClick={() => navigate("/modules/hr/masters/appointment-letter-template")}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="qa-save-btn"
                  disabled={isSaveBtnDisable}
                >
                  {isEditMode ? "Update" : "Save"}
                </Button>
              </Stack>
            </Stack>
            <Stack sx={{ mt: 4, maxWidth: "20rem" }}>
              <Typography variant="bodyText" color="#012148" fontWeight={500} sx={{ mb: 1 }}>
                Template Name*
              </Typography>
              <FormTextField
                name="template_name"
                variant="outlined"
                size="small"
                fullWidth
                disabled={isEditMode || isFormSubmited}
                rules={{
                  required: validationRules.REQUIRED,
                }}
              />
            </Stack>
            <Stack sx={{ mt: 2 }}>
              <Typography variant="bodyText" color="#012148" fontWeight={500} sx={{ mb: 1 }}>
                Introduction*
              </Typography>
              <FormTextField
                name="introduction"
                variant="outlined"
                size="small"
                placeholder="Enter Text here..."
                fullWidth
                multiline
                rows={5}
                rules={{
                  required: validationRules.REQUIRED,
                }}
              />
            </Stack>

            <div className="mt12">
              <Typography color="#575A6E" fontSize={14} fontWeight="500">
                Terms
              </Typography>
              <Table
                rowSelection={{ type: "checkbox", ...rowSelection }}
                columns={columns}
                dataSource={termRows}
                size="small"
                pagination={false}
              />
              <div className="mt1">
                <IconButton color="primary" onClick={handleAddRow}>
                  <AddBoxIcon fill="#A32930" />
                </IconButton>
                {selectedRowKeys.length > 0 && (
                  <IconButton aria-label="delete" color="primary" onClick={handleDeleteRow}>
                    <DeleteOutlined />
                  </IconButton>
                )}
              </div>
            </div>

            <Stack sx={{ mt: 4, mb: 10 }}>
              <Typography variant="bodyText" color="#012148" fontWeight={500} sx={{ mb: 1 }}>
                Closing Notes
              </Typography>
              <FormTextField
                name="closing_notes"
                variant="outlined"
                size="small"
                placeholder="Enter Text here..."
                fullWidth
                multiline
                rows={5}
              />
            </Stack>
          </form>
        </FormProvider>
      </div>
    </MainLayout>
  );
};

export default AddAppointmentLetterTemplate;

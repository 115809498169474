import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import ArrowDownSVG from "../../../assets/images/arrow-down.svg";
import FiltersSVG from "../../../assets/images/filters-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { Table } from "antd";
import { getAllGradingScaleListing } from "modules/hr";

const GradingScale = ({ setActiveModule }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [gradingScaleList, setGradingScaleList] = useState([]);

  const columns = [
    {
      dataIndex: "grading_scale_id",
      title: "Grading Scale ID",
      width: "30%",
      render: (grading_scale_id) => (
        <p className={styles.tableContentFields}>{grading_scale_id}</p>
      ),
    },
    {
      dataIndex: "description",
      title: "Description",
      width: "45%",
      render: (description) => (
        <p className={styles.tableContentFields}>{description}</p>
      ),
    },
    {
      dataIndex: "action",
      title: "Action",
      width: "15%",
      render: (text, record) => (
        <p
          className={styles.tableContentView}
          onClick={() =>
            navigate(`/modules/hr/grading-scale/${record.grading_scale_id}`)
          }
        >
          View
        </p>
      ),
    },
  ];

  const handleGetAllGradingScaleListing = async () => {
    const res = await getAllGradingScaleListing();
    console.log(res);
    if (res.status === 200) {
      const gradeList = [];
      for (let i = 0; i < res.data.message.length; i++) {
        gradeList.push({
          key: res.data.message[i].id,
          grading_scale_id: res.data.message[i].id,
          description: res.data.message[i].description,
        });
      }
      setGradingScaleList(gradeList);
    } else {
      console.log("Something went wrong while getting employee data");
    }
  };

  useEffect(() => {
    handleGetAllGradingScaleListing();
  }, []);

  return (
    <>
      <div className={styles.mainContainer}>
        {loading ? (
          <LoadingBackdrop open={loading} />
        ) : (
          <>
            <div className={styles.breadCrumbsContainer}>
              <p
                className={styles.breadCrumbsContent}
                onClick={() => navigate("/modules")}
              >
                IIHT {" > "}
              </p>
              <p
                className={styles.breadCrumbsContent}
                onClick={() => navigate("/dashboard")}
              >
                Modules {" > "}
              </p>
              <p className={styles.breadCrumbsContent}>Hr</p>
            </div>
            <div className={styles.headerContainer}>
              <div className={styles.breadCrumbsContainer}>
                <GridMenuIcon className={styles.gridIcon} />
                <p className={styles.title}>Grading Scale</p>
              </div>
              <div className={styles.buttonsGroup}>
                <div className={`${styles.button} ${styles.filterButton}`}>
                  <ReactSVG src={FiltersSVG} />
                  <p className={styles.buttonText}>Filters</p>
                  <ReactSVG src={ArrowDownSVG} />
                </div>
                <div className={styles.button}>
                  <p className={styles.buttonText}>Saved Filters</p>
                </div>
                <div className={styles.button}>
                  <p className={styles.buttonText}>...</p>
                </div>
                <div
                  className={styles.activeButton}
                  onClick={() => navigate("/modules/hr/add-new-grading-scale")}
                >
                  <p className={styles.activeButtonText}>Add Grading Scale</p>
                </div>
              </div>
            </div>
            <Table
              rowSelection={{ type: "checkbox" }}
              columns={columns}
              dataSource={gradingScaleList}
              size="small"
              pagination={true}
            />
          </>
        )}
      </div>
    </>
  );
};

export default GradingScale;

import React from 'react';
import './KeyValue.scss';
import { Typography } from 'iiht-b2a-ui-components/build/components';
import { uniqueId } from 'lodash';

const KeyValue = (props) => {
  const { keyValueList = [] } = props || '';
  return (
    <>
      {keyValueList?.length > 0 &&
        keyValueList?.map((keyValueObj, i) => {
          return (
            <div className="keyValueContainer" key={uniqueId('keyvalue-')}>
              <div className="key keyItem">{keyValueObj.key}</div>
              <div className="valueItemContainer">
                <span className="hypen">-</span>
                <span className="value">{keyValueObj?.value}</span>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default KeyValue;

import { createSlice } from "@reduxjs/toolkit";
import { roleConstants } from "../config/roles";

const getUserDetailsFromLocalStorage = () => {
  const user = JSON.parse(window.localStorage.getItem("userDetails"));
  return user;
};

const user = getUserDetailsFromLocalStorage();

const userSlice = createSlice({
  name: "user",
  initialState: {
    fullName: user?.name || "",
    email: user?.email || "",
    isAdmin: user?.isAdmin || false,
    isStudent: user?.isStudent || false,
    roles: user?.roles || [],
    toggle: true
  },
  reducers: {
    addUserDetails: (state, action) => {
      const { full_name: fullName,  message: { email, roles } } = action.payload;
      const isAdminRoleExists = roles.filter(
        (role) => role === roleConstants.admissionCommitee
      );
      const isStudentRoleExists = roles.filter(
        (role) => role === roleConstants.student
      );
      state.fullName = fullName;
      state.email =  email;
      state.isAdmin = isAdminRoleExists.length > 0 ? true :  false;
      state.isStudent = isStudentRoleExists.length > 0 ? true :  false;
      state.roles = roles
    },
    setToggle: (state, action) => {
      state.toggle = action.payload.toggle;
    } 
  },
});

export const { addUserDetails, setToggle } = userSlice.actions;

export default userSlice.reducer;

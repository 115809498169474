import {
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Card,
} from 'iiht-b2a-ui-components/build/components';
import withStyles from '@material-ui/core/styles/withStyles';

export const FiCard = withStyles({
  root: {
    position: 'relative',
  },
})(Card);

export const FiCardActionArea = withStyles({
  root: {
    position: 'relative',
  },
})(CardActionArea);

export const FiCardActions = withStyles({
  root: {
    position: 'relative',
  },
})(CardActions);

export const FiCardContent = withStyles({
  root: {
    position: 'relative',
  },
})(CardContent);

export const FiCardMedia = withStyles({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: '100%',
    backgroundSize: '100% 100%',
  },
})(CardMedia);

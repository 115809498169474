import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import MainHeader from "../../../common/MainHeader";
import MainFooter from "../../../common/MainFooter";
import LoadingBackdrop from "../../../common/LoadingBackdrop.js";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSidebar } from "../../../../slices/financeSlice";
import { Select, Table, Input, DatePicker, Tabs, Tab } from "antd";
import { getBatch } from "../../../../modules/studentGroup.js";
import { toast } from "react-toastify";
import {
  getAcademicTermListing,
  getAcademicYearListing,
  getAllFeeCategories,
  getAllFeeStructures,
  getCollegeListing,
  getCostCenterListing,
  createFee,
  getFeeStructure,
  getIncomeAccountListing,
  getProgramListing,
  getReceivableAccountListing,
  getStudentCategoryListing,
  getStudentsListing,
} from "../../../../modules/finance.js";

const { TabPane } = Tabs;

const AddNewFee = () => {
  const styles = useStyles();
  const selectSize = "middle";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [programOptions, setProgramOptions] = useState([]);
  const [academicYearOptions, setAcademicYearOptions] = useState([]);
  const [academicTermOptions, setAcademicTermOptions] = useState([]);
  const [feeCategoryOptions, seFeeCategoryOptions] = useState([]);
  const [feeCategories, setFeeCategories] = useState([]);
  const [feeStructureOptions, setFeeStructureOptions] = useState([]);
  const [collegeListOptions, setCollegeListOptions] = useState([]);
  const [costCenterOptions, setCostCenterOptions] = useState([]);
  const [batchOptions, setBatchOptions] = useState([]);
  const [receivableAccountOptions, setReceivableAccountOptions] = useState([]);
  const [incomeAccountOptions, setIncomeAccountOptions] = useState([]);
  const [studentCategoryOptions, setStudentCategoryOptions] = useState([]);
  const [studentListOptions, setStudentListOptions] = useState([]);
  const [studentIdList, setStudentIdList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [values, setValues] = useState({
    program: null,
    academic_year: null,
    components: [{}],
    academic_term: null,
    total_amount: 0,
    fee_structure: null,
    total_amount_in_words: null,
    fee_schedule: null,
    outstanding_amount: 0,
    program_enrollment: "",
    company: "",
    posting_date: "",
    due_date: "",
    custom_admission_type: "",
    student_name: "",
    student: "",
    contact_email: "",
    student_category: null,
    student_batch: null,
    receivable_account: "",
    income_acount: "",
    const_center: "",
  });

  const [errors, setErrors] = useState({
    student: "",
    company: "",
    posting_date: "",
    due_date: "",
    academic_year: "",
    program: "",
    custom_admission_type: "",
    fee_structure: null,
    components: [],
    receivable_account: "",
    income_acount: "",
  });
  const { loading } = useSelector((state) => state.common);

  const handleFeeNavigation = () => {
    dispatch(setActiveSidebar({ activeSidebar: 4 }));
    navigate("/modules/finance");
  };

  const handleGetAcademicYearListing = async () => {
    const res = await getAcademicYearListing();
    if (res.status === 200) {
      const arr = [];
      const acdemicYearList = res.data.data;
      for (let i = 0; i < acdemicYearList.length; i++) {
        arr.push({
          value: acdemicYearList[i].name,
          label: acdemicYearList[i].name,
        });
      }
      setAcademicYearOptions(arr);
    }
  };
  const AdmissionTypeOptions = [
    { value: "Regular", label: "Regular" },
    { value: "Self Supporting", label: "Self Supporting" },
  ];

  const handleGetAcademicTermListing = async () => {
    const res = await getAcademicTermListing();
    if (res.status === 200) {
      const arr = [];
      const acdemicTermList = res.data.data;
      for (let i = 0; i < acdemicTermList.length; i++) {
        arr.push({
          value: acdemicTermList[i].name,
          label: acdemicTermList[i].name,
        });
      }
      setAcademicTermOptions(arr);
    }
  };

  const handleGetCostCenterListing = async (companyId) => {
    const res = await getCostCenterListing(companyId);
    if (res.status === 200) {
      const arr = [];
      const costCenterList = res.data.message;
      for (let i = 0; i < costCenterList.length; i++) {
        arr.push({
          value: costCenterList[i].value,
          label: costCenterList[i].value,
        });
      }
      setCostCenterOptions(arr);
    }
  };

  const handleGetReceivableAccountListing = async (companyId) => {
    const res = await getReceivableAccountListing(companyId);
    if (res.status === 200) {
      const arr = [];
      const receivableAccountList = res.data.message;
      for (let i = 0; i < receivableAccountList.length; i++) {
        arr.push({
          value: receivableAccountList[i].value,
          label: receivableAccountList[i].value,
        });
      }
      setReceivableAccountOptions(arr);
    }
  };

  const handleGetIncomeAccountListing = async (companyId) => {
    const res = await getIncomeAccountListing(companyId);
    if (res.status === 200) {
      const arr = [];
      const incomeAccountList = res.data.message;
      for (let i = 0; i < incomeAccountList.length; i++) {
        arr.push({
          value: incomeAccountList[i].value,
          label: incomeAccountList[i].value,
        });
      }
      setIncomeAccountOptions(arr);
    }
  };

  const handleGetgetCollegeListing = async () => {
    const res = await getCollegeListing();
    if (res.status === 200) {
      const arr = [];
      const collegeList = res.data.data;
      for (let i = 0; i < collegeList.length; i++) {
        arr.push({
          value: collegeList[i].name,
          label: collegeList[i].name,
        });
      }
      setCollegeListOptions(arr);
    }
  };

  const handleGetProgramListing = async () => {
    const res = await getProgramListing();
    if (res.status === 200) {
      const arr = [];
      const programList = res.data.data;
      for (let i = 0; i < programList.length; i++) {
        arr.push({
          value: programList[i].name,
          label: programList[i].name,
        });
      }
      setProgramOptions(arr);
    }
  };

  const handleGetBatchListing = async () => {
    const res = await getBatch();
    if (res.status === 200) {
      const arr = [];
      const batchList = res.data.data;
      for (let i = 0; i < batchList.length; i++) {
        arr.push({
          value: batchList[i].name,
          label: batchList[i].name,
        });
      }
      setBatchOptions(arr);
    }
  };

  const handleGetFeeStructureListing = async () => {
    const res = await getAllFeeStructures();
    if (res.status === 200) {
      const arr = [];
      const feeStructureList = res?.data?.data;
      console.log(feeStructureList);
      for (let i = 0; i < feeStructureList.length; i++) {
        arr.push({
          value: feeStructureList[i].name,
          label: feeStructureList[i].name,
        });
      }
      setFeeStructureOptions(arr);
    }
  };

  const handleGetFeeStructure = async (structure) => {
    try {
      const res = await getFeeStructure({ name: structure });
      if (res.status === 200) {
        console.log(res, "structure response");
        const fetchedComponents = res.data.data;
        const arr = fetchedComponents.components.map((component, index) => {
          const feeCategory = feeCategories.find(
            (category) => category.name === component.fees_category
          );

          return {
            key: index,
            feeCategory: feeCategory?.category_name || "",
            description: feeCategory?.description || "",
            amount: component.amount || 0,
            action: feeCategory?.category_name || "",
            hasCustomAmount: component.amount ? true : false,
          };
        });

        setValues({
          ...values,
          fee_structure: structure,
          receivable_account: fetchedComponents.receivable_account,
          const_center: fetchedComponents.cost_center,
          income_acount: fetchedComponents.income_account,
          components: arr,
        });
      } else {
        console.error("Failed to fetch fee structure:", res);
      }
    } catch (error) {
      console.error("Error occurred while fetching fee structure:", error);
    }
  };

  const handleGetStudentCategoryListing = async () => {
    const res = await getStudentCategoryListing();
    if (res.status === 200) {
      const arr = [];
      const studentCategoryList = res.data.data;
      for (let i = 0; i < studentCategoryList.length; i++) {
        arr.push({
          value: studentCategoryList[i].name,
          label: studentCategoryList[i].name,
        });
      }
      setStudentCategoryOptions(arr);
    }
  };

  const handleGetStudentListing = async () => {
    const res = await getStudentsListing();
    if (res.status === 200) {
      const arr = [];
      const studentList = res.data.data;
      for (let i = 0; i < studentList.length; i++) {
        arr.push({
          value: studentList[i].name,
          label: studentList[i].name,
        });
      }
      setStudentIdList(studentList);
      setStudentListOptions(arr);
    }
  };

  const handleGetFeeCategoylisting = async () => {
    const res = await getAllFeeCategories();
    if (res.status === 200) {
      const arr = [];
      const feeCategoryList = res.data.data;
      for (let i = 0; i < feeCategoryList.length; i++) {
        arr.push({
          value: feeCategoryList[i].category_name,
          label: feeCategoryList[i].category_name,
          description: feeCategoryList[i].description,
          amount: feeCategoryList[i].custom_amount,
          hasCustomAmount: feeCategoryList[i].custom_amount ? true : false,
        });
      }
      setFeeCategories(feeCategoryList);
      seFeeCategoryOptions(arr);
    }
  };

  const calculateTotalAmount = () => {
    if (!values.components) {
      // If components is undefined or null, set it to an empty array
      setValues({
        ...values,
        components: [],
      });
      return; // Return early to prevent further execution
    }
    // Function to convert numbers into words (specific to Indian numbering system)
    const convertNumberToWords = (number) => {
      const units = [
        "",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
      ];
      const teens = [
        "",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ];
      const tens = [
        "",
        "Ten",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ];

      if (number === 0) return "";

      if (number < 10) return units[number];
      else if (number < 20) return teens[number - 10];
      else if (number < 100)
        return tens[Math.floor(number / 10)] + " " + units[number % 10];
      else if (number < 1000)
        return (
          units[Math.floor(number / 100)] +
          " Hundred " +
          convertNumberToWords(number % 100)
        );
      else if (number < 100000)
        return (
          convertNumberToWords(Math.floor(number / 1000)) +
          " Thousand " +
          convertNumberToWords(number % 1000)
        );
      else if (number < 10000000)
        return (
          convertNumberToWords(Math.floor(number / 100000)) +
          " Lakh " +
          convertNumberToWords(number % 100000)
        );
      else if (number < 1000000000)
        return (
          convertNumberToWords(Math.floor(number / 10000000)) +
          " Crore " +
          convertNumberToWords(number % 10000000)
        );
    };

    const totalAmount =
      Object.keys(values.components[0]).length > 0
        ? values.components.reduce(
            (acc, currentValue) => acc + parseInt(currentValue.amount),
            0
          )
        : 0;

    if (totalAmount >= 0) {
      const totalAmountInWords = convertNumberToWords(totalAmount);
      setValues({
        ...values,
        total_amount: parseInt(totalAmount),
        total_amount_in_words: totalAmountInWords
          ? "INR " + totalAmountInWords + "Only"
          : "Zero  Only", // Check if words exist
      });
    }
  };

  const handleAddFeeCategory = () => {
    setValues({
      ...values,
      components: [...values.components, {}],
    });
    setErrors({
      ...errors,
      components: [],
    });
  };

  const handleDeleteFeeCategory = () => {
    const components = values.components;
    const resultArray = components.filter(
      (obj) => !selectedRowKeys.includes(obj.key)
    );
    setSelectedRowKeys([]);
    setValues({
      ...values,
      components: resultArray.length > 0 ? resultArray : [{}],
    });
    setErrors({
      ...errors,
      components: [],
    });
  };

  const onSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = {
    type: "checkbox",
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      feeCategory: record.feeCategory,
    }),
    selectedRowKeys,
  };

  const handleValidateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!values.student) {
      newErrors.student = "Student Id is required";
      isValid = false;
    } else {
      newErrors.student = "";
    }

    if (!values.company) {
      newErrors.company = "Institution is required";
      isValid = false;
    } else {
      newErrors.company = "";
    }

    if (!values.posting_date) {
      newErrors.posting_date = "Posting date is required";
      isValid = false;
    } else {
      newErrors.posting_date = "";
    }

    if (!values.due_date) {
      newErrors.due_date = "Due date is required";
      isValid = false;
    } else {
      newErrors.due_date = "";
    }

    if (!values.academic_year) {
      newErrors.academic_year = "Academic year is required";
      isValid = false;
    } else {
      newErrors.academic_year = "";
    }

    if (!values.program) {
      newErrors.program = "Program is required";
      isValid = false;
    } else {
      newErrors.program = "";
    }

    if (!values.custom_admission_type) {
      newErrors.custom_admission_type = "Admission type is required";
      isValid = false;
    } else {
      newErrors.custom_admission_type = "";
    }

    if (values.fee_structure === null) {
      newErrors.fee_structure = "Fee structure is required";
      isValid = false;
    } else {
      newErrors.fee_structure = "";
    }

    if (Object.keys(values.components[0]).length > 0) {
      const components = values.components;
      for (let i = 0; i < components.length; i++) {
        if (components[i].amount <= 0) {
          newErrors.components = `Enter valid amount for Fee Category "${components[i].feeCategory}"`;
          isValid = false;
          break;
        }
      }
    } else {
      newErrors.components = "Required";
      isValid = false;
    }

    if (!values.receivable_account) {
      newErrors.receivable_account = "Receivable account is required";
      isValid = false;
    } else {
      newErrors.receivable_account = "";
    }

    setErrors(newErrors);
    return isValid;
  };
  const handleSave = async () => {
    const isValid = handleValidateForm();
    if (isValid) {
      try {
        const componentsArr = values.components.map((component) => ({
          ...component,
          fees_category: component.feeCategory,
        }));

        const payload = {
          ...values,
          docstatus: 0,
          student: values.student,
          components: componentsArr,
        };

        const res = await createFee({ payload });
        if (res.status === 200) {
          navigate(`/modules/finance/fee/${res.data.data.name}`);
          toast.success("Fees Drafted!");
        } else {
          toast.error("There is some issue while creating");
        }
      } catch (error) {
        toast.error(error.response.data.exception);
      }
    }
  };

  const columns = [
    {
      title: "Fee Category",
      dataIndex: "feeCategory",
      width: "25%",
      render: (val, record, index) => {
        return (
          <>
            <Select
              className={styles.tableContentFields}
              size={selectSize}
              placeholder="Select"
              style={{ width: "100%", border: "none !important" }}
              value={record.feeCategory}
              options={feeCategoryOptions}
              onChange={(value) => {
                const feeCategory = feeCategories.find(
                  (category) => category.name === value
                );
                const componentsArr = values.components;
                componentsArr[index] = {
                  key: index,
                  feeCategory: feeCategory.category_name,
                  description: feeCategory.description,
                  amount: parseInt(feeCategory.custom_amount),
                  action: feeCategory.category_name,
                  hasCustomAmount: feeCategory.custom_amount ? true : false,
                };
                setValues({
                  ...values,
                  components: [...componentsArr],
                });
                setErrors({
                  ...errors,
                  components: "",
                });
              }}
            />
          </>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "45%",
      render: (val, record) => {
        return (
          <p className={styles.tableContentFields}>{record.description}</p>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      width: "15%",
      render: (val, record, index) => {
        return (
          <>
            {record.hasCustomAmount ? (
              <p className={styles.tableContentFields}>{record.amount}</p>
            ) : (
              <Input
                placeholder="Enter Amount"
                className={styles.tableContentFields}
                style={{ width: "100%", border: "none" }}
                value={record.amount}
                onChange={(e) => {
                  const feeCategory = feeCategories.find(
                    (category) => category.name === record.feeCategory
                  );
                  const componentsArr = values.components;
                  componentsArr[index] = {
                    key: index,
                    feeCategory: feeCategory.category_name,
                    description: feeCategory.description,
                    amount: parseInt(e.target.value),
                    action: feeCategory.category_name,
                    hasCustomAmount: false,
                  };

                  setValues({
                    ...values,
                    components: [...componentsArr],
                  });
                  setErrors({
                    ...errors,
                    components: "",
                  });
                }}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "15%",
      render: (val, record, index) => {
        return (
          <>
            {record.feeCategory && (
              <p
                className={styles.tableContentView}
                onClick={() => {
                  const url = `/modules/finance/fee-category/${record.feeCategory}`;
                  window.open(url, "_blank");
                }}
              >
                View
              </p>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    handleGetAcademicYearListing();
    handleGetAcademicTermListing();
    handleGetProgramListing();
    handleGetFeeCategoylisting();
    handleGetFeeStructureListing();
    handleGetgetCollegeListing();
    // handleGetCostCenterListing();
    // handleGetReceivableAccountListing();
    // handleGetIncomeAccountListing();
    handleGetBatchListing();
    handleGetStudentListing();
    handleGetStudentCategoryListing();
  }, []);

  useEffect(() => {
    handleGetCostCenterListing(values.company);
    handleGetReceivableAccountListing(values.company);
    handleGetIncomeAccountListing(values.company);
  }, [values.company]);

  useEffect(() => {
    calculateTotalAmount();
  }, [values.components]);

  return (
    <>
      <MainHeader />
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/modules")}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/modules/finance")}
            >
              Finance {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={handleFeeNavigation}
            >
              Fee
            </p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>New Fee</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div className={styles.button} onClick={handleFeeNavigation}>
                <p className={styles.buttonText}>Back</p>
              </div>
              <div className={styles.activeButton}>
                <p className={styles.activeButtonText} onClick={handleSave}>
                  Save
                </p>
              </div>
            </div>
          </div>

          <Tabs defaultActiveKey="1" className={styles.antTabsTab}>
            <TabPane tab="Details" key="1">
              <div className={styles.formContainer}>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Student Id *</p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      value={values.student}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      className={styles.selectInput}
                      options={studentListOptions}
                      onChange={(selectedOption) => {
                        const selectedStudentId = selectedOption;
                        const selectedStudent = studentIdList.find(
                          (student) => student.name === selectedStudentId
                        );
                        setValues({
                          ...values,
                          student: selectedOption,
                          student_name: selectedStudent.student_name,
                          contact_email: selectedStudent.student_email_id,
                        });
                        setErrors({
                          ...errors,
                          student: "",
                        });
                      }}
                    />

                    {errors.student && (
                      <p className={styles.errorMessage}>{errors.student}</p>
                    )}
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Student Name *</p>
                    <Input
                      placeholder="ex Text"
                      value={values.student_name}
                      className={styles.fieldInput}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          student_name: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Institution *</p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      value={values.company}
                      className={styles.selectInput}
                      options={collegeListOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          company: value,
                        });
                        setErrors({
                          ...errors,
                          company: "",
                        });
                      }}
                    />
                    {errors.company && (
                      <p className={styles.errorMessage}>{errors.company}</p>
                    )}
                  </div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Posting Date *</p>
                    <input
                      type="date"
                      value={values.posting_date}
                      placeholder="YYYY-MM-DD"
                      className={styles.dateInput}
                      onChange={(e) => {
                        const dateString = e.target.value;
                        setValues({ ...values, posting_date: dateString });
                        setErrors({
                          ...errors,
                          posting_date: "",
                        });
                      }}
                    />
                    {errors.posting_date && (
                      <p className={styles.errorMessage}>
                        {errors.posting_date}
                      </p>
                    )}
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Due Date *</p>
                    <input
                      type="date"
                      value={values.due_date}
                      placeholder="YYYY-MM-DD"
                      className={styles.dateInput}
                      onChange={(e) => {
                        const dateString = e.target.value;
                        setValues({ ...values, due_date: dateString });
                        setErrors({
                          ...errors,
                          due_date: "",
                        });
                      }}
                    />
                    {errors.due_date && (
                      <p className={styles.errorMessage}>{errors.due_date}</p>
                    )}
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Programme Enrollment Id</p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      value={values.program_enrollment}
                      className={styles.selectInput}
                      // options={studentsOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          program_enrollment: value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Academic Year *</p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      value={values.academic_year}
                      className={styles.selectInput}
                      options={academicYearOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          academic_year: value,
                        });
                        setErrors({
                          ...errors,
                          academic_year: "",
                        });
                      }}
                    />
                    {errors.academic_year && (
                      <p className={styles.errorMessage}>
                        {errors.academic_year}
                      </p>
                    )}
                  </div>

                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Academic Term</p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      value={values.academic_term}
                      className={styles.selectInput}
                      options={academicTermOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          academic_term: value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Programme *</p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      value={values.program}
                      className={styles.selectInput}
                      options={programOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          program: value,
                        });
                        setErrors({
                          ...errors,
                          program: "",
                        });
                      }}
                    />
                    {errors.program && (
                      <p className={styles.errorMessage}>{errors.program}</p>
                    )}
                  </div>
                </div>

                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Admission Type*</p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      value={values.custom_admission_type}
                      className={styles.selectInput}
                      options={AdmissionTypeOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          custom_admission_type: value,
                        });
                        setErrors({
                          ...errors,
                          custom_admission_type: "",
                        });
                      }}
                    />
                    {errors.custom_admission_type && (
                      <p className={styles.errorMessage}>
                        {errors.custom_admission_type}
                      </p>
                    )}
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Fee Structure *</p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      value={values.fee_structure}
                      className={styles.selectInput}
                      options={feeStructureOptions}
                      onChange={(selectedOption) => {
                        const selectedFeeStructureId = selectedOption;
                        setValues({
                          ...values,
                          fee_structure: selectedFeeStructureId,
                        });
                        setErrors({
                          ...errors,
                          fee_structure: "",
                        });
                        handleGetFeeStructure(selectedFeeStructureId);
                      }}
                      showSearch // Enable search functionality
                      filterOption={(
                        input,
                        option // Define custom filtering logic
                      ) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    />

                    {errors.fee_structure && (
                      <p className={styles.errorMessage}>
                        {errors.fee_structure}
                      </p>
                    )}
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Fee Schedule </p>
                    <Input
                      placeholder="ex Text"
                      value={values.fee_schedule}
                      className={styles.fieldInput}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          fee_schedule: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.tableField}>
                    <p className={styles.fieldLabel}>Fee Components *</p>
                    <Table
                      rowSelection={{ type: "checkbox", ...rowSelection }}
                      columns={columns}
                      dataSource={values.components}
                      size="small"
                      pagination={false}
                    />
                    {errors.components && (
                      <p className={styles.errorMessage}>{errors.components}</p>
                    )}
                  </div>
                </div>
                <div>
                  <div className={styles.fieldButtons}>
                    {selectedRowKeys.length > 0 && (
                      <div
                        className={styles.deleteRow}
                        onClick={handleDeleteFeeCategory}
                      >
                        Delete
                      </div>
                    )}
                    <div
                      className={styles.addRow}
                      onClick={handleAddFeeCategory}
                    >
                      +
                    </div>
                  </div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Grand Total</p>
                    <Input
                      placeholder="ex 1500"
                      value={values.total_amount}
                      className={styles.fieldInput}
                      disabled={true}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          total_amount: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>In Words</p>
                    <Input
                      placeholder="ex 1500"
                      value={values.total_amount_in_words}
                      className={styles.fieldInput}
                      disabled={true}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          total_amount_in_words: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>OutStanding Amount</p>
                    <Input
                      placeholder="ex 1500"
                      value={values.outstanding_amount}
                      className={styles.fieldInput}
                      disabled={true}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          outstanding_amount: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Student Details" key="2">
              <div className={styles.formContainer}>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Student Email</p>
                    <Input
                      placeholder="ex Text"
                      value={values.contact_email}
                      className={styles.fieldInput}
                      disabled={true}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          contact_email: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Student Batch</p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      value={values.student_batch}
                      className={styles.selectInput}
                      options={batchOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          student_batch: value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Student Category</p>

                    <Select
                      size={selectSize}
                      placeholder="Select"
                      value={values.student_category}
                      className={styles.selectInput}
                      options={studentCategoryOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          student_category: value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Accounts" key="3">
              <p className={styles.smallTitle} style={{ marginBottom: "8px" }}>
                Accounting
              </p>
              <div className={styles.formContainer}>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Receivable Account *</p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      value={values.receivable_account}
                      className={styles.selectInput}
                      options={receivableAccountOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          receivable_account: value,
                        });
                        setErrors({
                          ...errors,
                          receivable_account: "",
                        });
                      }}
                    />
                    {errors.receivable_account && (
                      <p className={styles.errorMessage}>
                        {errors.receivable_account}
                      </p>
                    )}
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Income Account</p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      value={values.income_acount}
                      className={styles.selectInput}
                      options={incomeAccountOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          income_acount: value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}></div>
                </div>
              </div>
              <p className={styles.smallTitle} style={{ margin: "8px 0" }}>
                Accounting Dimensions
              </p>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Cost Center</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.const_center}
                  className={styles.selectInput}
                  options={costCenterOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      const_center: value,
                    });
                  }}
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default AddNewFee;

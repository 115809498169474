import APIs from "../api";
import axiosInstance from "../translator";

export const getCountries = async () => {
  try {
    const res = await axiosInstance.get(APIs.getCountryList);
    return res;
  } catch (error) {
    console.log("Error getting countries listing", error);
  }
};

export const getStates = async ({ country }) => {
  try {
    const filters = JSON.stringify([["country", "=", country]]);
    const res = await axiosInstance.get(
      `${APIs.getStateList}?filters=${filters}`
    );
    return res;
  } catch (error) {
    console.log("Error getting states listing", error);
  }
};

export const getDistrictsByState = async ({ state }) => {
  try {
    const filters = JSON.stringify([["state", "=", state]]);
    const res = await axiosInstance.get(
      `${APIs.getDistrictList}?filters=${filters}`
    );
    return res;
  } catch (error) {
    console.log("Error getting states listing", error);
  }
};


export const States = async () => {
  try {
    const res = await axiosInstance.get(APIs.getStateList);
    return res;
  } catch (error) {
    console.log("Error getting states listing", error);
  }
};

export const getCities = async ({ state }) => {
  try {
    const filters = JSON.stringify([["state", "=", state]]);
    const res = await axiosInstance.get(
      `${APIs.getCityList}?filters=${filters}`
    );
    return res;
  } catch (error) {
    console.log("Error getting cities listing", error);
  }
};

export const getCitiesByDistrict = async ({ district }) => {
  try {
    const filters = JSON.stringify([["district", "=", district]]);
    const res = await axiosInstance.get(
      `${APIs.getCityList}?filters=${filters}`
    );
    return res;
  } catch (error) {
    console.log("Error getting cities listing", error);
  }
};

export const getColleges = async () => {
  try {
    const res = await axiosInstance.get(APIs.getCollegeList);
    return res;
  } catch (error) {
    console.log("Error getting colleges listing", error);
  }
};

export const getPrograms = async ({ programgroup }) => {
  try {
    const fields = JSON.stringify(["name"]);
    const filters = JSON.stringify([["parent_group", "=", programgroup]]);
    const res = await axiosInstance.get(
      `${APIs.getProgramGroupList}?fields=${fields}&filters=${filters}`
    );
    return res;
  } catch (error) {
    console.log("Error getting programs listing", error);
  }
};

export const getProgramGroupsList = async () => {
  try {
    const fields = JSON.stringify(["name"]);
    const filters = JSON.stringify([["parent_group", "=", ""]]);
    const res = await axiosInstance.get(
      `${APIs.getProgramGroupList}?fields=${fields}&filters=${filters}`
    );
    return res;
  } catch (error) {
    console.log("Error getting programs group listing ", error);
  }
};

export const createLead = async ({ payload }) => {
  try {
    const res = await axiosInstance.post(APIs.createLead, payload);
    return res;
  } catch (error) {
    console.log("Error adding candidate ", error);
  }
};

export const getotpemail = async ({ payload }) => {
  try {
    const res = await axiosInstance.post(APIs.getotpemail, payload);
    return res;
  } catch (error) {
    console.log("Otp not sent ", error);
    return error.response;
  }
};

export const getotpphone = async ({ payload }) => {
  try {
    const res = await axiosInstance.post(APIs.getotpphone, payload);
    return res;
  } catch (error) {
    console.log("Otp not sent ", error);
    return error.response;
  }
};

export const verifyemail = async ({ payload }) => {
  try {
    const res = await axiosInstance.post(APIs.verifyemail, payload);
    return res;
  } catch (error) {
    console.log("Incorrect otp", error);
    return error.response;
  }
};

export const verifyphone = async ({ payload }) => {
  try {
    const res = await axiosInstance.post(APIs.verifyphone, payload);
    return res;
  } catch (error) {
    console.log("Incorrect otp", error);
    return error.response;
  }
};

export const getLeadListing = async (startDate = null, endDate = null) => {
  try {
    let fieldFilters = [];
    if (startDate && endDate) {
      fieldFilters.push(["creation", "between", [startDate, endDate]]);
    }
    const fields = JSON.stringify(["*"]);

    const res = await axiosInstance.get(
      `${APIs.getLeadList}?filters=${JSON.stringify(
        fieldFilters
      )}&fields=${fields}&limit="*"&order_by=creation%20desc`
    );
    return res;
  } catch (error) {
    console.log("Error getting enquiry form listing", error);
  }
};

export const getLeadListingByFilters = async (filters) => {
  try {
    const fields = JSON.stringify(["*"]);
    
    const res = await axiosInstance.get(
      `${APIs.getLeadList}?filters=${filters}&fields=${fields}&limit="*"&order_by=creation%20desc`
    );
    return res;
  } catch (error) {
    console.log("Error getting enquiry form listing", error);
  }
};
import React, { useEffect, useState } from "react";
import useStyles from "./style.js";
import { useNavigate, useParams } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import MainHeader from "../../../../common/MainHeader/index.js";
import MainFooter from "../../../../common/MainFooter/index.js";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSidebar } from "../../../../../slices/financeSlice.js";
import { setLoading } from "../../../../../slices/commonSlice.js";
import LoadingBackdrop from "../../../../common/LoadingBackdrop.js/index.js";
import { toast } from "react-toastify";

import { Select, Button, Table, Input } from "antd";
import {
  getDepartment,
  getCurrency,
  getDesignation,
  getEmploymentType,
  getInstitution,
  getLocation,
  updateJobOpening,
  getJobOpeningDetails,
} from "modules/hr.js";
import { uniqueId } from "lodash";

function UpdateJobOpening() {
  const styles = useStyles();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.common);
  const selectSize = "middle";
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [institutionOptions, setInstitutionOptions] = useState([]);
  const [employmentTypeOptions, setEmploymentTypeOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState(["Open", "Closed"]);
  const [salaryPerOptions, setSalaryPerOptions] = useState(["Month", "Year"]);
  const { id } = useParams();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const handleGetDepartement = async () => {
    const res = await getDepartment();
    if (res.status === 200) {
      const arr = [];
      const departmentList = res.data.data;
      for (let i = 0; i < departmentList.length; i++) {
        arr.push({
          value: departmentList[i].name,
          label: departmentList[i].name,
        });
      }
      setDepartmentOptions(arr);
    }
  };

  const handleGetDesignation = async () => {
    const res = await getDesignation();
    if (res.status === 200) {
      const arr = [];
      const designationList = res.data.data;
      for (let i = 0; i < designationList.length; i++) {
        arr.push({
          value: designationList[i].name,
          label: designationList[i].name,
        });
      }
      setDesignationOptions(arr);
    }
  };

  const handleGetInstitution = async () => {
    const res = await getInstitution();
    if (res.status === 200) {
      const arr = [];
      const institutionList = res.data.data;
      for (let i = 0; i < institutionList.length; i++) {
        arr.push({
          value: institutionList[i].name,
          label: institutionList[i].name,
        });
      }
      setInstitutionOptions(arr);
    }
  };

  const handleGetLocation = async () => {
    const res = await getLocation();
    if (res.status === 200) {
      const arr = [];
      const locationList = res.data.data;
      for (let i = 0; i < locationList.length; i++) {
        arr.push({
          value: locationList[i].name,
          label: locationList[i].name,
        });
      }
      setLocationOptions(arr);
    }
  };

  const handleGetCurrency = async () => {
    const res = await getCurrency();
    if (res.status === 200) {
      const arr = [];
      const currencyList = res.data.data;
      for (let i = 0; i < currencyList.length; i++) {
        arr.push({
          value: currencyList[i].name,
          label: currencyList[i].name,
        });
      }
      setCurrencyOptions(arr);
    }
  };

  const handleGetEmploymentType = async () => {
    const res = await getEmploymentType();
    if (res.status === 200) {
      const arr = [];
      const employmentTypeList = res.data.data;
      for (let i = 0; i < employmentTypeList.length; i++) {
        arr.push({
          value: employmentTypeList[i].name,
          label: employmentTypeList[i].name,
        });
      }
      setEmploymentTypeOptions(arr);
    }
  };

  const [values, setValues] = useState({
    doctype: "Job Opening",
    job_title: "",
    designation: "",
    status: "",
    posted_on: "",
    closes_on: "",
    company: "",
    department: "",
    employment_type: "",
    location: "",
    staffing_plan: "",
    planned_vacancies: "",
    job_requisition: "",
    vacancies: "",
    description: "",
    currency: "",
    lower_range: "",
    upper_range: "",
    salary_per: "",
    publish_salary_range: "",
  });

  const [errors, setErrors] = useState({
    doctype: "Job Opening",
    job_title: "",
    designation: "",
    status: "",
    posted_on: "",
    closes_on: "",
    company: "",
    department: "",
    employment_type: "",
    location: "",
    staffing_plan: "",
    planned_vacancies: "",
    job_requisition: "",
    vacancies: "",
    description: "",
    currency: "",
    lower_range: "",
    upper_range: "",
    salary_per: "",
    publish_salary_range: "",
  });

  const handleValidateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!values.job_title) {
      newErrors.job_title = "Required";
      isValid = false;
    } else {
      newErrors.job_title = "";
    }

    if (!values.designation) {
      newErrors.designation = "Required";
      isValid = false;
    } else {
      newErrors.designation = "";
    }

    if (!values.status) {
      newErrors.status = "Required";
      isValid = false;
    } else {
      newErrors.status = "";
    }

    if (!values.posted_on) {
      newErrors.posted_on = "Required";
      isValid = false;
    } else {
      newErrors.posted_on = "";
    }

    if (!values.closes_on) {
      newErrors.closes_on = "Required";
      isValid = false;
    } else {
      newErrors.closes_on = "";
    }

    if (!values.company) {
      newErrors.company = "Required";
      isValid = false;
    } else {
      newErrors.company = "";
    }

    if (!values.department) {
      newErrors.department = "Required";
      isValid = false;
    } else {
      newErrors.department = "";
    }

    if (!values.employment_type) {
      newErrors.employment_type = "Required";
      isValid = false;
    } else {
      newErrors.employment_type = "";
    }

    if (!values.location) {
      newErrors.location = "Required";
      isValid = false;
    } else {
      newErrors.location = "";
    }

    if (!values.lower_range) {
      newErrors.lower_range = "Required";
      isValid = false;
    } else {
      newErrors.lower_range = "";
    }

    if (!values.upper_range) {
      newErrors.upper_range = "Required";
      isValid = false;
    } else {
      newErrors.upper_range = "";
    }

    if (!values.salary_per) {
      newErrors.salary_per = "Required";
      isValid = false;
    } else {
      newErrors.salary_per = "";
    }

    if (!values.description) {
      newErrors.description = "Required";
      isValid = false;
    } else {
      newErrors.description = "";
    }

    setErrors(newErrors);
    return isValid;
  };
  const handleGetJobDetails = async () => {
    const payload = {
      doctype: "Job Opening",
      id: id.split("=")[1],
    };
    const res = await getJobOpeningDetails(payload);
    if (res.status === 200) {
      setValues({
        doctype: "Job Opening",
        job_title: res.data.message.job_title,
        designation: res.data.message.designation,
        status: res.data.message.status,
        posted_on: res.data.message.posted_on,
        closes_on: res.data.message.closes_on,
        employment_type: res.data.message.employment_type,
        company: res.data.message.company,
        department: res.data.message.department,
        location: res.data.message.location,
        description: res.data.message.description,
        currency: res.data.message.currency,
        lower_range: res.data.message.lower_range,
        upper_range: res.data.message.upper_range,
        salary_per: res.data.message.salary_per,
        publish_salary_range: res.data.message.publish_salary_range,
      });
    }
  };
  const handleSave = async () => {
    const isValid = handleValidateForm();
    if (isValid) {
      try {
        const payload = {
          doctype: "Job Opening",
          job_title: values.job_title,
          designation: values.designation,
          status: values.status,
          posted_on: values.posted_on,
          closes_on: values.closes_on,
          company: values.company,
          department: values.department,
          employment_type: values.employment_type,
          location: values.location,
          description: values.description,
          currency: values.currency,
          lower_range: values.lower_range,
          upper_range: values.upper_range,
          salary_per: values.salary_per,
          publish_salary_range: values.publish_salary_range,
          docstatus: 0,
          id: id.split("=")[1],
        };

        const res = await updateJobOpening(payload);
        if (res.status === 200) {
          navigate(`/modules/hr/jobOpening`);
          toast.success("Job Opening Updated!");
        } else {
          toast.error("There is some issue while creating");
        }
      } catch (error) {
        toast.error(error.response);
      }
    }
  };

  useEffect(() => {
    handleGetCurrency();
    handleGetDepartement();
    handleGetDesignation();
    handleGetEmploymentType();
    handleGetInstitution();
    handleGetLocation();
    handleGetJobDetails();
  }, []);

  const convertHtmlToPlainText = (html) => {
    const temp = document.createElement("div");
    temp.innerHTML = html;
    return temp.textContent || temp.innerText || "";
  };

  const handleBack = () => {
    navigate("/modules/hr/jobOpening");
  };

  return (
    <>
      <MainHeader />
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>{values.job_title}</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div className={styles.button}>
                <p className={styles.buttonText} onClick={handleBack}>
                  Back
                </p>
              </div>
              <div className={styles.activeButton}>
                <p className={styles.activeButtonText} onClick={handleSave}>
                  Save
                </p>
              </div>
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Job Title *</p>
                <input
                  size={selectSize}
                  placeholder="Job Title"
                  defaultValue={values.job_title}
                  className={styles.fieldInput}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      job_title: e.target.value,
                    });
                    setErrors({
                      ...errors,
                      job_title: "",
                    });
                  }}
                />
                {errors.job_title && (
                  <p className={styles.errorMessage}>{errors.job_title}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Designation *</p>
                <Select
                  size={selectSize}
                  placeholder="Select Designation"
                  value={values.designation}
                  className={styles.selectInput}
                  options={designationOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      designation: value,
                    });
                    setErrors({
                      ...errors,
                      designation: "",
                    });
                  }}
                />
                {errors.designation && (
                  <p className={styles.errorMessage}>{errors.designation}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Status *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.status}
                  className={styles.selectInput}
                  options={statusOptions.map((option) => ({
                    value: option,
                    label: option,
                  }))}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      status: value,
                    });
                  }}
                />
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Posting Date *</p>
                <input
                  size={selectSize}
                  placeholder="Select"
                  type="date"
                  defaultValue={formatDate(values.posted_on)}
                  className={styles.fieldInput}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      posted_on: e.target.value,
                    });
                    setErrors({
                      ...errors,
                      posted_on: "",
                    });
                  }}
                />
                {errors.posted_on && (
                  <p className={styles.errorMessage}>{errors.posted_on}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Closes On *</p>
                <input
                  size={selectSize}
                  placeholder="Select"
                  type="date"
                  defaultValue={values.closes_on}
                  min={values.posted_on}
                  className={styles.fieldInput}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      closes_on: e.target.value,
                    });
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Institution *</p>
                <Select
                  size={selectSize}
                  placeholder="Select Institution"
                  value={values.company}
                  options={institutionOptions}
                  className={styles.selectInput}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      company: value,
                    });
                    setErrors({
                      ...errors,
                      company: "",
                    });
                  }}
                />
                {errors.company && (
                  <p className={styles.errorMessage}>{errors.company}</p>
                )}
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Employment Type *</p>
                <Select
                  type="text"
                  placeholder="ex Annual"
                  value={values.employment_type}
                  className={styles.selectInput}
                  options={employmentTypeOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      employment_type: value,
                    });
                    setErrors({
                      ...errors,
                      employment_type: "",
                    });
                  }}
                />
                {errors.employment_type && (
                  <p className={styles.errorMessage}>
                    {errors.employment_type}
                  </p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Department *</p>
                <Select
                  type="text"
                  placeholder="Select Department"
                  value={values.department}
                  className={styles.selectInput}
                  options={departmentOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      department: value,
                    });
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Location *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.location}
                  className={styles.selectInput}
                  options={locationOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      location: value,
                    });
                    setErrors({
                      ...errors,
                      location: "",
                    });
                  }}
                />
                {errors.location && (
                  <p className={styles.errorMessage}>{errors.location}</p>
                )}
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Salary Paid Per</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.salary_per}
                  className={styles.selectInput}
                  options={salaryPerOptions.map((option) => ({
                    value: option,
                    label: option,
                  }))}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      salary_per: value,
                    });
                    setErrors({
                      ...errors,
                      salary_per: "",
                    });
                  }}
                />
                {errors.salary_per && (
                  <p className={styles.errorMessage}>{errors.salary_per}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Currency</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.currency}
                  className={styles.selectInput}
                  options={currencyOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      currency: value,
                    });
                    setErrors({
                      ...errors,
                      currency: "",
                    });
                  }}
                />
                {errors.currency && (
                  <p className={styles.errorMessage}>{errors.currency}</p>
                )}
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Lower Range *</p>
                <input
                  size={selectSize}
                  placeholder="Select"
                  defaultValue={values.lower_range}
                  className={styles.fieldInput}
                  // options={costCenterOptions}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      lower_range: e.target.value,
                    });
                    setErrors({
                      ...errors,
                      lower_range: "",
                    });
                  }}
                />
                {errors.lower_range && (
                  <p className={styles.errorMessage}>{errors.lower_range}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Upper Range *</p>
                <input
                  size={selectSize}
                  placeholder="Select"
                  defaultValue={values.upper_range}
                  className={styles.fieldInput}
                  // options={costCenterOptions}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      upper_range: e.target.value,
                    });
                    setErrors({
                      ...errors,
                      upper_range: "",
                    });
                  }}
                />
                {errors.upper_range && (
                  <p className={styles.errorMessage}>{errors.upper_range}</p>
                )}
              </div>
            </div>
            <div className={styles.field}>
              <div className={styles.checkboxContainer}>
                <input
                  type="checkbox"
                  checked={values.publish_salary_range}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      publish_salary_range: e.target.checked,
                    })
                  }
                />
                <p className={styles.fieldLabel}>Publish Salary range</p>
              </div>
            </div>
            <div className={styles.textarea}>
              <p className={styles.fieldLabel}>Description *</p>
              <textarea
                type="text"
                placeholder="Enter Text here..."
                className={styles.textAreaInput}
                value={convertHtmlToPlainText(values.description)}
                rows={5}
                onChange={(e) => {
                  setValues({
                    ...values,
                    description: e.target.value,
                  });
                  setErrors({
                    ...errors,
                    description: "",
                  });
                }}
              />
              {errors.description && (
                <p className={styles.errorMessage}>{errors.description}</p>
              )}
            </div>
            <div className={styles.field}>
              <div className={styles.checkboxContainer}>
                <input
                  type="checkbox"
                  checked={values.checkbox2}
                  onChange={(e) =>
                    setValues({ ...values, checkbox2: e.target.checked })
                  }
                />
                <p className={styles.fieldLabel}>Publish on website</p>
              </div>
            </div>
          </div>
          {/* Add Textarea */}
        </div>
      )}
      <MainFooter />
    </>
  );
}

export default UpdateJobOpening;

import APIs from "../api";
import axiosInstance from "../translator";

export const getEvents = async ({ id }) => {
  try {
    const filters = JSON.stringify([
      ["Event Participants", "reference_docname", "=", id],
    ]);
    const fields = JSON.stringify(["*"]);
    const res = await axiosInstance.get(
      `${APIs.getEventList}?fields=${fields}&filters=${filters}`
    );
    return res;
  } catch (error) {
    console.log("Error getting events listing", error);
    return error.response;
  }
};

export const getLeadDetails = async ({ uniqueId }) => {
  try {
    const res = await axiosInstance.get(`${APIs.getLeadDetails}/${uniqueId}`);
    return res;
  } catch (error) {
    console.log("Error getting Lead Details", error);
    return error.response;
  }
};

export const getLanguagesAvailable = async () => {
  try {
    const res = await axiosInstance.get(`${APIs.getLanguagesAvailable}`, {
      params: { doctype: "Language", txt: "e", page_length: "100" },
    });
    return res;
  } catch (error) {
    console.log("Error getting Language Details", error);
    return error.response;
  }
};

export const updateLeadDetails = async ({ uniqueId, payload }) => {
  try {
    const res = await axiosInstance.put(
      `${APIs.getLeadDetails}/${uniqueId}`,
      payload
    );
    return res;
  } catch (error) {
    console.log("Error getting Lead Details", error);
    return error.response;
  }
};

export const updateProfileData = async ({ payload }) => {
  try {
    const res = await axiosInstance.put(`${APIs.updateUserDetails}`, payload);
    return res;
  } catch (error) {
    console.log("Error updating Profile details", error);
    return error.response;
  }
};

export const updateUserProfilePicture = async (
  doctype,
  docname,
  fieldname,
  file_url
) => {
  try {
    const res = await axiosInstance.put(`${APIs.updateUserProfilePicture}`, {
      docname: docname,
      doctype: doctype,
      file_url: file_url,
      fieldname: fieldname,
    });
    return res;
  } catch (error) {
    console.log("Error updating Profile Picture", error);
    return error.response;
  }
};

export const getUserDetails = async () => {
  try {
    const fields = JSON.stringify(["*"]);
    const res = await axiosInstance.get(`${APIs.getUser}?fields=${fields}`);
    return res;
  } catch (error) {
    console.log("Error getting user Details", error);
    return error.response;
  }
};

export const getUserDetailsbyEmail = async (email) => {
  try {
    const filters = JSON.stringify([["email", "=", email]]);
    const res = await axiosInstance.get(`${APIs.getUserByEmail}`, {
      params: {
        email: email,
      },
    });
    return res;
  } catch (error) {
    console.log("Error getting user Details", error);
    return error.response;
  }
};

export const getAttendanceDetails = async (id) => {
  try {
    const res = await axiosInstance.get(`${APIs.getAttendanceByAttendanceId}`, {
      params: {
        doctype: "Student Attendance",
        id: id,
      },
    });
    return res;
  } catch (error) {
    console.log("Error getting user Details", error);
    return error.response;
  }
};

export const getUserDetailsAll = async () => {
  try {
    const res = await axiosInstance.get(`${APIs.getUser}`);
    return res;
  } catch (error) {
    console.log("Error getting User Details", error);
    return error.response;
  }
};

export const createEvent = async ({ payload }) => {
  try {
    const res = await axiosInstance.post(APIs.createEvent, payload);
    return res;
  } catch (error) {
    console.log("Error creating events", error);
    return error.response;
  }
};

export const updateEvent = async ({ payload, name }) => {
  try {
    const res = await axiosInstance.put(`${APIs.createEvent}/${name}`, payload);
    return res;
  } catch (error) {
    console.log("Error updating event", error);
    return error.response;
  }
};

export const getTemplate = async ({ doctype }) => {
  try {
    const filters = JSON.stringify([["custom_doctype", "=", doctype]]);
    const res = await axiosInstance.get(
      `${APIs.getTemplate}?filters=${filters}`
    );
    return res;
  } catch (error) {
    console.log("Error getting Templates Details", error);
    return error.response;
  }
};

export const selectTemplate = async (template) => {
  try {
    const res = await axiosInstance.get(`${APIs.selectTemplate}/${template}`);
    return res;
  } catch (error) {
    console.log("Error getting Templates Details", error);
    return error.response;
  }
};

export const sendEmail = async (payload) => {
  try {
    const res = await axiosInstance.post(APIs.sendEmail, payload);
    return res;
  } catch (error) {
    console.log("Error sending mail ", error);
    return error.response;
  }
};

export const getEmailList = async (uniqueId) => {
  try {
    const fields = JSON.stringify(["*"]);
    const filters = JSON.stringify([["reference_name", "=", uniqueId]]);
    const res = await axiosInstance.get(
      `${APIs.getEmailList}?fields=${fields}&filters=${filters}&order_by=creation%20desc`
    );
    return res;
  } catch (error) {
    console.log("Error getting programs group listing ", error);
    return error.response;
  }
};

export const getSentEmail = async (uniqueId) => {
  try {
    const fields = JSON.stringify(["*"]);
    const filters = JSON.stringify([
      ["sent_or_received", "in", ["Sent"]],
      ["reference_name", "=", uniqueId],
    ]);
    const res = await axiosInstance.get(
      `${APIs.getSentEmail}?fields=${fields}&filters=${filters}&order_by=creation%20desc`
    );
    return res;
  } catch (error) {
    console.log("Error getting programs group listing ", error);
  }
};

export const getReceiveEmail = async (email) => {
  try {
    const fields = JSON.stringify(["*"]);
    const filters = JSON.stringify([
      ["sent_or_received", "=", "Received"],
      ["sender", "=", email],
    ]);
    const res = await axiosInstance.get(
      `${APIs.getReceiveEmail}?fields=${fields}&filters=${filters}&order_by=creation%20desc`
    );
    return res;
  } catch (error) {
    console.log("Error getting programs group listing ", error);
  }
};

export const createNote = async ({ payload }) => {
  try {
    const res = await axiosInstance.post(APIs.createNote, payload);
    return res;
  } catch (error) {
    console.log("Error creating notes", error);
    return error.response;
  }
};

export const getNotes = async ({ id }) => {
  try {
    const res = await axiosInstance.get(
      `${APIs.createNote}?doctype=Lead&name=${id}`
    );
    return res;
  } catch (error) {
    console.log("Error creating notes", error);
    return error.response;
  }
};

export const Filters = async (payload) => {
  try {
    const res = await axiosInstance.post(APIs.filters, payload);
    return res;
  } catch (error) {
    console.log("Error getting filters", error);
    return error.response;
  }
};

export const SaveFilters = async (payload) => {
  try {
    const res = await axiosInstance.post(APIs.saveFilters, payload);
    return res;
  } catch (error) {
    console.log("Error getting filters", error);
    return error.response;
  }
};

export const getSavedFilter = async (doctype) => {
  const filters = JSON.stringify([["reference_doctype", "=", doctype]]);
  try {
    const res = await axiosInstance.get(
      `${APIs.getSavedFilters}?filters=${filters}&fields=["*"]`
    );
    return res;
  } catch (error) {
    console.log("Error getting filters", error);
    return error.response;
  }
};

export const getEmailThread = async (doctype, uniqueId) => {
  try {
    const res = await axiosInstance.get(
      `${APIs.getEmailThread}?doctype=${doctype}&name=${uniqueId}`
    );
    return res;
  } catch (error) {
    console.log("Error getting the Email Threading", error);
    return error.response;
  }
};

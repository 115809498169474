import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  mainContainer: {
    cursor: "pointer",
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyItems: "center",
    borderRadius: "8px",
    border: "1px solid rgba(236, 236, 236, 0.23)",
    background: "#FFF",
    boxShadow: "1px 4px 7.3px 0px rgba(195, 195, 195, 0.25)",
    width: "24%",
  },
  title: {
    fontSize: "18px",
    color: "#0D1021",
    fontWeight: "500",
  },
  container: {
    padding: "66px 54px",
    display: "flex",
    flexWrap: "wrap",
    // justifyContent: "space-between",
    gap: "16px",
  },
}));

export default useStyles;

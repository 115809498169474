import React, { useEffect, useState } from 'react';
import { Table, Popover } from 'antd';
import useStyles from './style';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { GridMenuIcon } from '@mui/x-data-grid';
import { setLoading } from '../../../slices/commonSlice';
import { getApplicationsList } from '../../../modules/studentApplication';
import "./index.scss";
import CommonFilters from '../../Filters/CommonFilters';
import SavedFilters from '../../Filters/SavedFilters';
import { toast } from "react-toastify";
import eventBus from '../../../core/eventBus.js';
import DeleteConfirmationDialog from '../../common/DeleteConfirmation';
import { genericBulkDelete } from '../../../modules/common';
import LoadingBackdrop from '../../common/LoadingBackdrop.js/index.js';
import APIs from '../../../api/index.js';
import { updateFieldsInBulk } from '../../../modules/admission.js';
import BulkEdit from '../../common/BulkEdit/index.js';

const AdmissionApplicationManagement = ({ setActiveModule }) => {
  const styles = useStyles();
  const navigate= useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const { doctype, filters } = useSelector((state) => state.filters);
  const [applicationList, setApplicationList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [openActions, setOpenActions] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const columns = [
    {
      dataIndex: "name",
      title: "Application Id",
      width: 0.7,
    },
    {
      dataIndex: "first_name",
      title: "Student name",
      width: 0.5,
      render: (val, row) => {
        return (
          <>
            <span className="studentName">{row.first_name}</span>
          </>
        );
      },
    },
    {
      dataIndex: "custom_program_group",
      title: "Program Type",
      width: 0.5,
    },
    {
      dataIndex: "program",
      title: "Program",
      width: 0.5,
    },
    {
      dataIndex: "college",
      title: "College",
      width: 1,
    },
    {
      dataIndex: "application_status",
      title: "Status",
      width: 0.5,
      render: (val, row) => {
        const dynamicClassName = `status ${row.application_status}`;
        return (
          <>
            <span className={dynamicClassName}>{row.application_status}</span>
          </>
        );
      },
    },
    {
      dataIndex: "action",
      title: "Action",
      width: 0.5,
      render: (val, row) => {
        return (
          <p className={styles.link} onClick={() => navigate(`/applications/${row?.id}`)}>
            View
          </p>
        );
      },
    },
  ];

  const onSelectChange = (selectedKeys, selectedRowData) => {
    setSelectedRowKeys(selectedKeys);
    let selectedDataIds = [];
    for(let i = 0 ; i < selectedRowData.length ; i++) {
      selectedDataIds.push(selectedRowData[i].id);
    }
    setSelectedIds([...selectedDataIds]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  const handleConfirmBulkDelete = () => {
    setOpenActions(false);
    eventBus.emit("open-dialog", <DeleteConfirmationDialog items={selectedIds.length} handleBulkDelete={handleBulkDelete} />)
  };

  const handleBulkDelete = async () => {
    dispatch(setLoading({ loading: true }));
    let deleteIds = [];
    for(let i = 0 ; i < selectedIds.length ; i++) {
      deleteIds.push({
        doctype: "Student Applicant",
        id: selectedIds[i]
      })
    };

    const payload = { delete: [...deleteIds] };
    const res = await genericBulkDelete({ payload });
    if(res.status === 200) {
      handleApplicationListing();
      setSelectedIds([]);
      setSelectedRowKeys([]);
      toast.success(res.data.message);
    } else {
      toast.error(res.data.exc_type);
      dispatch(setLoading({ loading: false }));
    }
  };

  const handleBulkExport = async () => {
    dispatch(setLoading({ loading: true }));
    let exportIds = [];
    for(let i = 0 ; i < selectedIds.length ; i++) {
      exportIds.push({
        doctype: "Student Applicant",
        id: selectedIds[i]
      })
    };

    const payload = { export : [...exportIds] };
    const response = await fetch(APIs.bulkActionExport, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      dispatch(setLoading({ loading: false }));
      setSelectedIds([]);
      setSelectedRowKeys([]);
      toast.success("Data exported!");
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Applications-Data.xlsx");
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error("Something went wrong");
      dispatch(setLoading({ loading: false }));
    }
  };

  const handleBulkEdit = async ({ customfieldname, value }) => {
    const payload = {
      doctype: "Student Applicant",
      docnames:	selectedIds,
      customfieldname,
      value
    };
    dispatch(setLoading({ loading: true }));
    setIsEditOpen(false)
    const res = await updateFieldsInBulk({ payload });
    if(res.status === 200) {
      handleApplicationListing();
      setSelectedIds([]);
      setSelectedRowKeys([]);
      toast.success(res.data.message);
    } else {
      toast.error(res.data.exc_type);
      dispatch(setLoading({ loading: false }));
    }
  }


  const handleApplicationListing = async () => {
    let filtersToPass = [];
    if(doctype === "Student Applicant") {
      filtersToPass = JSON.stringify(filters).slice(1, -1);
    }
    dispatch(setLoading({ loading: true }));
    const response = await getApplicationsList(filtersToPass);
    if (response.status === 200) {
      let data = response?.data?.data;
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        let val = {
          ...data[i],
          id: data[i].name,
          key: i,
        };
        arr.push(val);
      }
      setApplicationList(arr);
      dispatch(setLoading({ loading: false }))
    } else {
      dispatch(setLoading({ loading: false }))
    }
  };

  useEffect(() => {
    handleApplicationListing();
  }, [filters]);
 
  return (
    <>
      {
        loading ?
        <LoadingBackdrop open={loading} /> :
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p className={styles.breadCrumbsContent} onClick={() => navigate("/dashboard")}>IIHT {" > "}</p>
            <p className={styles.breadCrumbsContent} onClick={() => setActiveModule(0)}>Admission {" > "}</p>
            <p className={styles.breadCrumbsContent}>Application Management</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon}/>
              <p className={styles.title}>Application Management</p>
            </div>
            <div className={styles.buttonsGroup}>
              <CommonFilters doctype={"Student Applicant"}></CommonFilters>
              <SavedFilters doctype={"Student Applicant"}></SavedFilters>
              {
                selectedRowKeys.length > 0 && (
                  <Popover
                    content={<div className={styles.popoverContainer}>
                      <p className={styles.popoverContent} onClick={() => {
                        setOpenActions(false);
                        setIsEditOpen(true);
                      }}>Edit</p>
                      <p className={styles.popoverContent} onClick={handleBulkExport}>Export</p>
                      <p className={styles.popoverContent} onClick={handleConfirmBulkDelete}>Delete</p>
                    </div>}
                    trigger="click"
                    open={openActions}
                    placement="bottomRight"
                    arrow={false}
                    onOpenChange={() => setOpenActions(true)}
                  >
                    <div className={styles.activeButton}>
                      <p className={styles.activeButtonText}>Actions</p>
                    </div>
                  </Popover>
                )
              }
            </div>
          </div>
          <Table 
            rowSelection={{ type: "checkbox", ...rowSelection }} 
            columns={columns} 
            dataSource={applicationList} 
            size='small'
            pagination={true}
          />
        </div>
      }
      {
        isEditOpen && 
          <BulkEdit 
            handleBulkEdit={handleBulkEdit} 
            doctype="Student Applicant" 
            isEditOpen={isEditOpen} 
            setIsEditOpen={setIsEditOpen} 
            items={selectedIds.length}
          />
      }
    </>
  )
}

export default AdmissionApplicationManagement;
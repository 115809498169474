import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { formConfig } from "./MasterFormJson";
import FilterListIcon from "@mui/icons-material/FilterList";
import { GridMenuIcon } from "@mui/x-data-grid";
import { MainLayout } from "components/common";
import { Box, Button } from "@mui/material";
import "./MasterStyle.scss";
import { getEntityDetail, getGenericData } from "../../modules/masters";
import {
  TextInputField,
  TextAreaField,
  DateInputField,
  SelectInputField,
  TableField,
  Select1InputField,
  EmailInputField,
  PhoneInputField,
  CheckboxField,
} from "./FormFields";
import Breadcrumbs from "../common/breadcrumb/breadcrumb";
import CourseContentManagement from "./Content Management/content-management-view";
import Outcomes from "./Outcomes";

const MasterPage = () => {
  const { entityId, entityType } = useParams();
  const [currentEntityType, setCurrentEntityType] = useState(entityType);
  const [activeTab, setActiveTab] = useState("Default");
  const [formData, setFormData] = useState({});
  const [rowData, setRowData] = useState({});
  const [entityConfig, setEntityConfig] = useState(null);
  const [apiId, setApiId] = useState(null);
  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSetFields = (fields, tabName) => {
    setCurrentEntityType(tabName);
  };

  const handleBackButtonClick = () => {
    // Go back to the entity list page
    navigate(-1);
  };

  const CustomToolbar = () => {
    const handleAddAppClick = () => {
      handleSetFields();
    };
    return (
      <>
        <Breadcrumbs />
        <div className="master-heading">
          <h2>
            <GridMenuIcon />
            {`${entityType} Masters`}
          </h2>
          <span className="rightBtn">
            <Button variant="outlined" startIcon={<FilterListIcon />}>
              {"Filters"}
            </Button>
            <Button variant="outlined">Saved Filters</Button>
            <Button
              variant="outlined"
              className="redBtn"
              onClick={handleAddAppClick}
            >
              {`+ Add ${entityType}`}
            </Button>
          </span>
        </div>
      </>
    );
  };

  const getMastersDetails = async () => {
    try {
      const response = await getEntityDetail(entityType, entityId);
      // Check if the response data array is not empty
      if (response && response.length > 0) {
        const responseData = response[0];
        setFormData(responseData);
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  const getGenericDetails = async (doctype, id) => {
    try {
      const response = await getGenericData(doctype, id);

      // Check if the response is defined and has the expected structure
      if (response && response.message) {
        const responseData = response.message;

        // Update your component state or perform other actions with the data
        setFormData(responseData);
      } else {
        console.error("Unexpected API response format:", response);
      }
    } catch (error) {
      console.error("Error fetching entity details:", error);
    }
  };

  const getDetails = async (type, id) => {
    try {
      let response;

      // Check if the type is "Instructor" or "Curriculum"
      if (
        type === "Instructor" ||
        type === "Curriculum" ||
        type === "Course" ||
        type === "Outcomes"
      ) {
        response = await getGenericData(type, id);
        // Check if the response is successful and contains the expected data
        if (response && response.message) {
          const responseData = response.message;
          // Update your component state or perform other actions with the data
          setFormData(responseData);
        } else {
          console.error("Unexpected API response:", response);
          // Handle unexpected or error responses here
        }
      } else {
        // For other types, use getEntityDetail
        response = await getEntityDetail(type, id);
        // Check if the response is successful and contains data
        if (response && response.length > 0) {
          const responseData = response[0];
          // Update your component state or perform other actions with the data
          setFormData(responseData);
        } else {
          console.error("Unexpected API response:", response);
          // Handle unexpected or error responses here
        }
      }
    } catch (error) {
      console.error("Error fetching details:", error);
      // Handle errors here, such as showing an error message to the user
    }
  };

  useEffect(() => {
    const entityConfig = formConfig.find(
      (config) => config.entityType === entityType
    );
    if (entityConfig) {
      setEntityConfig(entityConfig);
    }

    // Fetch entity details based on entity type
    getDetails(entityType, entityId);
  }, []);

  const [apiCallMade, setApiCallMade] = useState(false);

  useEffect(() => {
    // Filter formConfig to get fields for the specified entity
    const entityConfig = formConfig.find(
      (config) => config.entityType === entityType
    );

    if (entityConfig) {
      setEntityConfig(entityConfig);
    }

    //  getMastersDetails();

    // Check the value of tabs
    const tabs = Array.from(
      new Set(
        entityConfig?.fields
          .filter((field) => field.tab)
          .map((field) => field.tab)
      )
    ) || ["Details"];

    // Set the first tab as active by default
    setActiveTab(tabs.includes("Details") ? "Details" : tabs[0]);

    // Update apiId when the API response is received
    const handleApiResponse = (response) => {
      if (response && response.message && response.message.length > 0) {
        const firstItem = response.message[0];
        const receivedId = firstItem.id; // Modify this based on the actual structure of your API response
        setApiId(receivedId);
      } else {
        console.error("Unexpected API response format:", response);
      }
    };

    // Call getGenericDetails with handleApiResponse as a callback
    //  getGenericDetails(entityType, entityId).then(handleApiResponse);
    // Log formData when it changes

    // getGenericDetails(entityType, apiId);
  }, []);

  if (!entityConfig) {
    return <div>Error: Entity not found</div>;
  }
  const tabs = Array.from(
    new Set(
      entityConfig?.fields
        .filter((field) => field.tab)
        .map((field) => field.tab)
    )
  ) || ["Default"];

  const TabbedForm = ({
    activeTab,
    handleTabClick,
    renderTabContent,
    tabs,
  }) => {
    // If there are no tabs, render the entire form without tabs
    if (!tabs || tabs.length === 0) {
      return renderTabContent(tabs[0]); // Pass null to indicate no specific tab
    }

    return (
      <div>
        <div className="tabs-bar">
          {/* Map through tabs and render them */}
          {tabs.map((tab) => (
            <div
              key={tab}
              onClick={() => handleTabClick(tab)}
              className={`tabsItem ${activeTab === tab ? "active-tab" : ""}`}
            >
              {tab}
            </div>
          ))}
        </div>
        {/* Render content for the active tab or the first tab */}
        {renderTabContent(activeTab || tabs[0])}
      </div>
    );
  };

  // Declare the function before using it
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  const ComponentPageToRender = ({ field }) => {
    switch (field.pagename) {
      case "ContentManagement":
        return (
          <CourseContentManagement details={formData}/>
        );
      default:
        return null;
    }
  }; 

  const renderTabContent = (tab) => {
    const fieldsForTab = entityConfig?.fields.filter(
      (field) => field.tab === tab
    );
    if (!fieldsForTab || !fieldsForTab.length) {
      return null;
    }

    return (
      <form onSubmit={handleSubmit} className="tabsContent">
        <div className="tab-content">
          <div className="fields-block">
            {fieldsForTab.map((field, index) => (
              <React.Fragment key={index}>
                {
                  field.type === "page" && (
                    <div style={{ width: "100%" }}>
                      <ComponentPageToRender field={field} />
                    </div>
                )}
                {field.type === "text" && (
                  <div className="form-field">
                    <TextInputField
                      field={field}
                      value={formData[field.name]}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>
                )} 
                {field.type === "gst_number" && (
                  <div className="form-field">
                    <TextInputField
                      field={field}
                      value={formData[field.name]}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>
                )}
                {field.type === "tel" && (
                  <div className="form-field">
                    <PhoneInputField
                      field={field}
                      value={formData[field.name]}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>
                )}
                {field.type === "email" && (
                  <div className="form-field">
                    <EmailInputField
                      field={field}
                      value={formData[field.name]}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>
                )}
                {field.type === "textarea" && (
                  <div className="form-field fullWidthFields">
                    <TextAreaField
                      field={field}
                      value={formData[field.name]}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>
                )}
                {field.type === "date" && (
                  <div className="form-field">
                    <DateInputField
                      field={field}
                      value={formData[field.name]}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>
                )}
                {field.type === "checkbox" && (
                  <div className="form-field">
                    <CheckboxField
                      field={field}
                      checked={formData[field.name] === 1} // Assuming 1 represents a checked state
                      onChange={(e) =>
                        handleInputChange(field.name, e.target.checked ? 1 : 0)
                      }
                      disabled
                    />
                  </div>
                )}
                {(field.type === "select" || field.type === "select2") && (
                  <div className="form-field">
                    <SelectInputField
                      field={field}
                      value={formData[field.name]}
                      onChange={(selectedValue) =>
                        handleInputChange(field.name, selectedValue)
                      }
                      options={field.options}
                      disabled
                    />
                  </div>
                )}
                {field.type === "select1" && (
                  <div className="form-field">
                    <Select1InputField
                      field={field}
                      value={formData[field.name]}
                      onChange={(selectedValue) =>
                        handleInputChange(field.name, selectedValue)
                      }
                      disabled
                    />
                  </div>
                )}
                {field.type === "table" && (
                  <>
                    <h4 for={field.label}>{field.label} </h4>
                    <TableField
                      headers={field.headers.map((header) => ({
                        key: header.key,
                        value: header.value,
                      }))}
                      data={
                        formData[field.name]?.length
                          ? formData[field.name]
                          : [{}]
                      }
                      disabled
                    />
                  </>
                )}
                 
                {field.type === "col-break" && (
                  <div className="col-break" key={field.name}></div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </form>
    );
  };
  return (
    <MainLayout>
      <Box sx={{ height: "100%" }}>
        <div className="formApplication">
          <section className="form-section">
            <Breadcrumbs />
            <div className="master-heading">
              <h2>
                <GridMenuIcon />
                {`${entityConfig.entityType} Masters`}
              </h2>
              <div className="btnsGrp">
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleBackButtonClick}
                >
                  Back
                </Button>
                {/* <Button variant="contained" color="primary" className="redBtn">
                  Save
                </Button> */}
              </div>
            </div>
            <TabbedForm
              activeTab={activeTab}
              handleTabClick={handleTabClick}
              renderTabContent={renderTabContent}
              tabs={tabs}
            />
          </section>
        </div>
      </Box>
    </MainLayout>
  );
};

export default MasterPage;

export const StudentDetailsJSON = [
  {
    name: "Personal",
    fields: [
      {
        label: "Personal Details",
        name: "personalDetails",
        type: "section",
        rows: [
          [
            {
              label: "",
              name: "custom_upload_student_image",
              type: "image",
            },
            {
              label: "FIRST NAME",
              name: "first_name",
              type: "text",
            },
            {
              label: "MIDDLE NAME",
              name: "middle_name",
              type: "text",
            },
          ],
          [
            {
              label: "",
              name: "",
              type: "text",
            },
            {
              label: "LAST NAME",
              name: "last_name",
              type: "text",
            },
            {
              label: "GENDER",
              name: "gender",
              type: "text",
            },
          ],
          [
            {
              label: "Preferred Gender Pronoun(s)",
              name: "custom_preferred_gender_pronouns",
              type: "text",
            },
            {
              label: "DOB",
              name: "date_of_birth",
              type: "date",
            },
            {
              label: "NATIONALITY",
              name: "nationality",
              type: "text",
            },
          ],
          [
            {
              label: "CATEGORY",
              name: "student_category",
              type: "text",
            },
            {
              label: "BLOOD GROUP",
              name: "blood_group",
              type: "text",
            },
            {
              label: "",
              name: "",
              type: "text",
            },
          ],
          [
            {
              label: "Aadhaar Card Number",
              name: "custom_aadhar_card_number",
              type: "encrypted",
            },
            {
              label: "ABC ID",
              name: "custom_abc_id",
              type: "text",
            },
            {
              label: "",
              name: "",
              type: "text",
            },
          ]
        ],
      },
    ],
  },
  {
    name: "Contact",
    fields: [
      {
        label: "Contact Details",
        name: "contactDetails",
        type: "section",
        rows: [
          [
            {
              label: "Email Address",
              name: "custom_primary_email_address",
              type: "text",
            },
            {
              label: "Alternate Email Address",
              name: "custom_alternate_email_address",
              type: "text",
            },
            {
              label: "Phone Number",
              name: "student_mobile_number",
              type: "text",
            },
          ],
          [
            {
              label: "Alternate Phone Number",
              name: "custom_alternate_mobile",
              type: "text",
            },
            {
              label: "Telephone Number",
              name: "custom_telephone_number_residence",
              type: "text",
            },
            {
              label: "",
              name: "",
              type: "text",
            },
          ],
          [
            {
              label: "Emergency Contact Name",
              name: "custom_emergency_contact_name_",
              type: "text",
            },
            {
              label: "Relation with  Emergency Contact",
              name: "custom_relation_with_emergency_contact_",
              type: "text",
            },
            {
              label: "Emergency Contact Number ",
              name: "custom_emergency_contact_number_",
              type: "text",
            },
          ],
        ],
      },
      {
        label: "Correspondence Address (for the duration of the admission cycle)",
        name: "correspondenceAddress",
        type: "section",
        rows: [
          [
            {
              label: "Country",
              name: "custom_country_correspondence",
              type: "text",
            },
            {
              label: "State",
              name: "custom_state_correspondence",
              type: "text",
            },
            {
              label: "District",
              name: "custom_district_correspondence",
              type: "text",
            },
          ],
          [
            {
              label: "City",
              name: "custom_city_correspondence",
              type: "text",
            },
            {
              label: "",
              name: "",
              type: "text",
            },
            {
              label: "",
              name: "",
              type: "text",
            },
          ],
          [
            {
              label: "Address line 1",
              name: "custom_address_line_1_correspondence",
              type: "text",
            },
            {
              label: "Address line 2",
              name: "custom_address_line_2_correspondence",
              type: "text",
            },
            {
              label: "Pincode",
              name: "custom_pincode_correspondence",
              type: "text",
            },
          ],
        ],
      },
      {
        label: "Permanent Address",
        name: "permanentAddress",
        type: "section",
        rows: [
          [
            {
              label: "Country",
              name: "country",
              type: "text",
            },
            {
              label: "",
              name: "",
              type: "text",
            },
            {
              label: "",
              name: "",
              type: "text",
            },
          ],
          [
            {
              label: "Address line 1",
              name: "address_line_1",
              type: "text",
            },
            {
              label: "Address line 2",
              name: "address_line_2",
              type: "text",
            },
            {
              label: "Pincode",
              name: "pincode",
              type: "text",
            },
          ],
        ],
      },
    ],
  },
  {
    name: "Parent/Guardian",
    fields: [
      {
        label: "Parent/Guardian - 1 Details",
        name: "parent1Details",
        type: "section",
        rows: [
          [
            {
              label: "Parent/Guardian First Name",
              name: "custom_first_name",
              type: "arrayText",
              arrayField: "guardians",
              index: 0
            },
            {
              label: "Parent/Guardian Last Name",
              name: "custom_last_name",
              type: "arrayText",
              arrayField: "guardians",
              index: 0
            },
            {
              label: "Parent/Guardian Date of Birth",
              name: "date_of_birth",
              type: "arrayDate",
              arrayField: "guardians",
              index: 0
            },
          ],
          [
            {
              label: "Relationship with Candidate",
              name: "relation",
              type: "arrayText",
              arrayField: "guardians",
              index: 0
            },
            {
              label: "Parent/Guardian Contact Number",
              name: "mobile_number",
              type: "arrayText",
              arrayField: "guardians",
              index: 0
            },
            {
              label: "Parent/Guardian Email Address",
              name: "email_address",
              type: "arrayText",
              arrayField: "guardians",
              index: 0
            },
          ],
          [
            {
              label: "Nationality",
              name: "custom_nationality",
              type: "arrayText",
              arrayField: "guardians",
              index: 0
            },
            {
              label: "Highest Level of Education",
              name: "custom_highest_level_of_education",
              type: "arrayText",
              arrayField: "guardians",
              index: 0
            },
            {
              label: "",
              name: "",
              type: "text",
            },
          ],
        ],
      },
      {
        label: "Guardian 1 Employment Category",
        name: "guardianEmploymentCatgeory",
        type: "section",
        rows: [
          [
            {
              label: "Employment Category",
              name: "custom_employment_category",
              type: "arrayText",
              arrayField: "guardians",
              index: 0
            },
            {
              label: "field of Employment",
              name: "custom_field_of_employment",
              type: "arrayText",
              arrayField: "guardians",
              index: 0
            },
            {
              label: "Name of Organisation",
              name: "work_address",
              type: "arrayText",
              arrayField: "guardians",
              index: 0
            },
          ],
          [
            {
              label: "Designation",
              name: "designation",
              type: "arrayText",
              arrayField: "guardians",
              index: 0
            },
            {
              label: "Gross Annural Income (In INR)",
              name: "custom_gross_annual_income_in_inr",
              type: "arrayText",
              arrayField: "guardians",
              index: 0
            },
            {
              label: "",
              name: "",
              type: "text",
            },
          ],
          [
            {
              label: "Guardian details",
              name: "custom_occupation_including_position_and_a_brief_upto_50_words",
              type: "arrayTextArea",
              arrayField: "guardians",
              index: 0
            },
          ]
        ],
      },
      {
        label: "Parent/Guardian - 2 Details",
        name: "parent2Details",
        type: "section",
        rows: [
          [
            {
              label: "Parent/Guardian First Name",
              name: "custom_first_name",
              type: "arrayText",
              arrayField: "guardians",
              index: 1
            },
            {
              label: "Parent/Guardian Last Name",
              name: "custom_last_name",
              type: "arrayText",
              arrayField: "guardians",
              index: 1
            },
            {
              label: "Parent/Guardian Date of Birth",
              name: "date_of_birth",
              type: "arrayDate",
              arrayField: "guardians",
              index: 1
            },
          ],
          [
            {
              label: "Relationship with Candidate",
              name: "relation",
              type: "arrayText",
              arrayField: "guardians",
              index: 1
            },
            {
              label: "Parent/Guardian Contact Number",
              name: "mobile_number",
              type: "arrayText",
              arrayField: "guardians",
              index: 1
            },
            {
              label: "Parent/Guardian Email Address",
              name: "email_address",
              type: "arrayText",
              arrayField: "guardians",
              index: 1
            },
          ],
          [
            {
              label: "Nationality",
              name: "custom_nationality",
              type: "arrayText",
              arrayField: "guardians",
              index: 1
            },
            {
              label: "Highest Level of Education",
              name: "custom_highest_level_of_education",
              type: "arrayText",
              arrayField: "guardians",
              index: 1
            },
            {
              label: "",
              name: "",
              type: "text",
            },
          ],
        ],
      },
      {
        label: "Guardian 2 Employment Category",
        name: "guardianEmploymentCatgeory",
        type: "section",
        rows: [
          [
            {
              label: "Employment Category",
              name: "custom_employment_category",
              type: "arrayText",
              arrayField: "guardians",
              index: 1
            },
            {
              label: "field of Employment",
              name: "custom_field_of_employment",
              type: "arrayText",
              arrayField: "guardians",
              index: 1
            },
            {
              label: "Name of Organisation",
              name: "work_address",
              type: "arrayText",
              arrayField: "guardians",
              index: 1
            },
          ],
          [
            {
              label: "Designation",
              name: "designation",
              type: "arrayText",
              arrayField: "guardians",
              index: 1
            },
            {
              label: "Gross Annural Income (In INR)",
              name: "custom_gross_annual_income_in_inr",
              type: "arrayText",
              arrayField: "guardians",
              index: 1
            },
            {
              label: "",
              name: "",
              type: "text",
            },
          ],
          [
            {
              label: "Guardian details",
              name: "custom_occupation_including_position_and_a_brief_upto_50_words",
              type: "arrayTextArea",
              arrayField: "guardians",
              index: 1
            },
          ]
        ],
      },
    ],
  },
  {
    name: "Academic",
    fields: [
      {
        label: "Education Xth Details",
        name: "educationXth",
        type: "section",
        rows: [
          [
            {
              label: "Class X Board",
              name: "custom_class_x_board",
              type: "text",
            },
            {
              label: "State/Union Territory",
              name: "custom_stateunion_territory",
              type: "text",
            },
            {
              label: "City of School",
              name: "custom_city_of_school",
              type: "text",
            },
          ],
          [
            {
              label: "School Attended",
              name: "custom_school_name_x",
              type: "text",
            },
            {
              label: "Year of Completion",
              name: "custom_year_of_completion",
              type: "text",
            },
            {
              label: "Class X Marks/grade",
              name: "custom_class_x_marksgrade_",
              type: "text",
            },
          ]
        ],
      },
      {
        label: "Education XIth Details",
        name: "educationXIth",
        type: "section",
        rows: [
          [
            {
              label: "Class XI Board",
              name: "custom_class_xi_board",
              type: "text",
            },
            {
              label: "State/Union Territory",
              name: "custom_stateunion_territory_xi",
              type: "text",
            },
            {
              label: "City of School",
              name: "custom_city_of_school_xi",
              type: "text",
            },
          ],
          [
            {
              label: "School Attended",
              name: "custom_school_name_xi",
              type: "text",
            },
            {
              label: "Year of Completion",
              name: "custom_year_of_completion_xi",
              type: "text",
            },
            {
              label: "Class XI Marks/grade",
              name: "custom_class_xi_marksgrade_",
              type: "text",
            },
          ]
        ],
      },
      {
        label: "Education XIIth Details",
        name: "educationXIIth",
        type: "section",
        rows: [
          [
            {
              label: "Class XII Board",
              name: "custom_class_xii_board",
              type: "text",
            },
            {
              label: "State/Union Territory",
              name: "custom_stateunion_territory_copy",
              type: "text",
            },
            {
              label: "City of School",
              name: "custom_city_of_school_copy",
              type: "text",
            },
          ],
          [
            {
              label: "School Attended",
              name: "custom_school_name_xii",
              type: "text",
            },
            {
              label: "Year of Completion",
              name: "custom_year_of_completion_xii",
              type: "text",
            },
            {
              label: "Class XII Marks/grade",
              name: "custom_class_xii_marksgrade_",
              type: "text",
            },
          ]
        ],
      },
      {
        label: "XIIth Details",
        name: "XIIthDetails",
        type: "section",
        rows: [
          [
            {
              headers: [
                {
                  key: "S.No.",
                  value: "idx",
                  width:"5%"
                },
                {
                  key: "Subject",
                  value: "subject",
                  valueText: "subject_text",
                  width:"55%"
                },
                {
                  key: "Marks Obtained",
                  value: "marks",
                  width:"20%"
                },
                {
                  key: "Maximum Marks",
                  value: "maximum_marksgrade",
                  width:"20%"
                },
              ],
              type: "table",
              minRows: 5,
              name: "custom_xii_subject",
            },
          ],
        ],
      },
    ],
  },
  {
    name: "Documents",
    fields: [
      {
        label: "Document Details",
        name: "documentDetails",
        type: "section",
        rows: [
          [
            {
              type: "attachments",
              name: "attachments",
            },
          ]
        ],
      },
    ],
  },
  {
    name: "Program",
    fields: [
      {
        label: "Program Details",
        name: "programDetails",
        type: "section",
        rows: [
          [
            {
              label: "Admission no.",
              name: "custom_student_admission_no",
              type: "programText",
            },
            {
              label: "Enrollment Id",
              name: "custom_enrollment_id",
              type: "programText",
            },
            {
              label: "Student Id",
              name: "name",
              type: "programText",
            },
          ],
          [
            {
              headers: [
                {
                  key: "S.No.",
                  value: "idx",
                  width:"5%"
                },
                {
                  key: "program type",
                  value: "program_type",
                  width:"32%"
                },
                {
                  key: "program",
                  value: "program",
                  width:"32%"
                },
                {
                  key: "university",
                  value: "university",
                  width:"32%"
                },
              ],
              type: "programTable",
              name: "custom_program_details",
            },
          ]
        ],
      },
    ],
  },
];

export default StudentDetailsJSON;

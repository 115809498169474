import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const StudentLogout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.localStorage.removeItem("userDetails");
    navigate("/login");
  }, []);

  return (
    <></>
  )
};

export default StudentLogout;
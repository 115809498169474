import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import moment from "moment";
import { Typography } from "iiht-b2a-ui-components/build/components";
import { getGenericListApi } from "modules/hr";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { setLoading } from "slices/commonSlice";

const JobOfferList = ({ applicantName, jobApplicationData }) => {
  const dispatch = useDispatch();
  const { id: jobApplicantId, jobOpeningId, designationId } = useParams();
  const [interviewList, setInterviewList] = useState([]);
  const { loading } = useSelector((state) => state.common);

  const columns = [
    {
      dataIndex: "",
      title: "SNo.",
      width: "5%",
      align: "center",
      render: (_val, _row, rowIndex) => rowIndex + 1,
    },
    {
      dataIndex: "id",
      title: "Application ID",
      width: "15%",
    },
    {
      dataIndex: "designation",
      title: "Designation",
      width: "15%",
    },
    {
      dataIndex: "company",
      title: "Institution",
      width: "10%",
      render: (val) => val.split(":").slice(0, 2).join(":"),
    },
    {
      dataIndex: "offer_date",
      title: "Offer Date",
      width: "10%",
      render: (val) => (val ? moment(val).format("MMMM DD, YYYY") : "-"),
    },
    {
      dataIndex: "status",
      title: "Status",
      width: "10%",
      render: (val, row) => {
        return (
          <>
            {val && (
              <Typography
                fontSize={12}
                fontWeight={500}
                color={`${
                  val === "Rejected" ? "#ac0521" : val === "Accepted" ? "#4baf4f" : "#2C83EC"
                }`}
                sx={{
                  border: `1px solid ${
                    val === "Rejected"
                      ? "#ac052180"
                      : val === "Accepted"
                      ? "#4baf4f80"
                      : "#2c83ec80"
                  }`,
                  borderRadius: "6px",
                  backgroundColor: `${
                    val === "Rejected"
                      ? "#ac05210d"
                      : val === "Accepted"
                      ? "#4baf4f0d"
                      : "#2c83ec0d"
                  }`,
                  textAlign: "center",
                  padding: "2px",
                  width: "80px",
                }}
              >
                {val}
              </Typography>
            )}
          </>
        );
      },
    },
    {
      dataIndex: "id",
      title: "Action",
      width: "10%",
      render: (val) => {
        return (
          <NavLink
            color="secondary"
            variant="body2"
            to={`/modules/hr/job-application/view/job-offer/edit-view/${jobApplicantId}/${jobOpeningId}/${designationId}/${val}`}
            state={{
              applicantName,
              emailId: jobApplicationData?.email_id,
            }}
            className="mouse-pointer qa-view-all view-all"
          >
            View
          </NavLink>
        );
      },
    },
  ];

  const fetchInterviewList = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Job Offer",
      fields: JSON.stringify(["*"]),
      filters: JSON.stringify([
        ["job_applicant", "=", jobApplicantId],
        // ["id", "=", jobOpeningId],
      ]),
      limit: "*",
    };
    const res = await getGenericListApi(params);
    if (res?.status === 200) {
      if (res.data?.message?.length) {
        const list = res.data.message.map((data, i) => {
          return { ...data, key: i };
        });
        setInterviewList(list);
      } else {
        setInterviewList([]);
      }
    }
    dispatch(setLoading({ loading: false }));
  };

  useEffect(() => {
    fetchInterviewList();
  }, []);

  return (
    <div>
      {loading && <LoadingBackdrop open={loading} />}
      <Table columns={columns} dataSource={interviewList} size="small" pagination={true} />
    </div>
  );
};

export default JobOfferList;

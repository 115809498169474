import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { clearAllFilters } from "slices/filterSlice";
import { useDispatch, useSelector } from "react-redux";
import MainHeader from "../../components/common/MainHeader";
import MainFooter from "../../components/common/MainFooter";
import LeftMenuToggle from "../../assets/images/sidePanelToggle.svg";
import useStyles from "./style";
import "./style.scss";

const SideBar = ({ sidebarContent, modules }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);  
  const finance = useSelector((state) => state.finance);
  const [activeModule, setActiveModule] = useState(finance.activeSidebar);
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    setActiveModule(finance.activeSidebar);
  }, [finance]);

  return (
    <>
      <MainHeader />
      <div className={styles.mainContainer}>
        <div
          className={
            collapsed ? styles.collapsedSidebar : styles.sidebarContainer
          }
        >
          <button onClick={toggleSidebar} className="drawerIconsRD">
            <ReactSVG src={LeftMenuToggle} />
          </button>
          {sidebarContent.map((content, index) => {
            return (
              <div
                key={index}
                className={`${
                  index === activeModule
                    ? styles.sidebarContentActive
                    : collapsed
                    ? styles.collapsedSidebar
                    : styles.sidebarContent
                } sidebarContent`}
                onClick={() => {
                  setActiveModule(index);
                  dispatch(clearAllFilters());
                }}
              >
                <ReactSVG
                  src={content.icon}
                  className={`${styles.sidebarContentIcons} sidebarContentIcons`}
                />
                {!collapsed && (
                  <div className={styles.sidebarContentLabel}>
                    {content.label}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className={styles.moduleContainer}>
          {React.createElement(modules[activeModule], { setActiveModule })}
        </div>
        <div className={styles.leftContainer}></div>
      </div>
      <MainFooter />
    </>
  );
};

export default SideBar;

import APIs from "../api";
import axiosInstance from "../translator";

export const getMeritListing = async (filters) => {
  try {
    return axiosInstance.get(
      `${APIs.getMeritList}?filters=${filters}&fields=["*"]`
    );
  } catch (error) {
    console.log("Error while getting Merit Listing", error);
    return error.response;
  }
};

export const getBulkEditFieldList = async ({ doctype }) => {
  try {
    const response = await axiosInstance.get(
      `${APIs.getBulkEditSelectFieldOptions}?doctype=${doctype}`
    );
    return response;
  } catch (error) {
    console.error("Error getting Bulk Edit Fields:", error);
    throw error.response;
  }
};

export const getStudentAttendance = async () => {
  try {
    const res = await axiosInstance.get(
      `${APIs.getGenericList}?doctype=Student Attendance`
    );
    return res;
  } catch (error) {
    console.log("Error getting Student Attendance", error);
    return error.response;
  }
};

export const getListingFromDoctype = async ({ doctype }) => {
  try {
    const response = await axiosInstance.get(`/api/resource/${doctype}`);
    return response;
  } catch (error) {
    console.error("Error getting Listing from doctype :", error);
    throw error.response;
  }
};

export const updateFieldsInBulk = async ({ payload }) => {
  try {
    const response = await axiosInstance.post(APIs.bulkActionEdit, payload);
    return response;
  } catch (error) {
    console.error("Error getting Bulk Edit Fields:", error);
    throw error.response;
  }
};

export const getMeritCriteriaListing = async (filters) => {
  try {
    return axiosInstance.get(
      `${APIs.meritListCriteria}?filters=${filters}&fields=["*"]`
    );
  } catch (error) {
    console.log("Error while getting Merit Criteria Listing", error);
    return error.response;
  }
};

export const getMeritCategoryListing = async (filters) => {
  try {
    return axiosInstance.get(
      `${APIs.meritListCastTemplate}?filters=${filters}&fields=["*"]`
    );
  } catch (error) {
    console.log("Error while getting Merit Category Listing", error);
    return error.response;
  }
};

export const getMeritCategoryByName = async ({ name }) => {
  try {
    return axiosInstance.get(`${APIs.meritListCastTemplate}/${name}`);
  } catch (error) {
    console.log("Error while getting Merit Category By Name", error);
    return error.response;
  }
};

export const getMeritCriteriaByName = async ({ name }) => {
  try {
    const res = await axiosInstance.get(`${APIs.meritListCriteria}/${name}`);
    return res;
  } catch (error) {
    console.log("Error while getting Merit Criteria By Name", error);
    return error.response;
  }
};

export const createMeritCriteria = async ({ payload }) => {
  try {
    return axiosInstance.post(APIs.meritListCriteria, payload);
  } catch (error) {
    console.log("Error while creating Merit Criteria", error);
    return error.response;
  }
};

export const createMeritCategory = async ({ payload }) => {
  try {
    const res = await axiosInstance.post(APIs.meritListCastTemplate, payload);
    return res;
  } catch (error) {
    console.log("Error while creating Merit Category", error);
    return error.response;
  }
};

export const getStudentCategoryListing = async () => {
  try {
    return axiosInstance.get(`${APIs.getStudentCategoryList}?fields=["*"]`);
  } catch (error) {
    console.log("Error while getting Student Category Listing", error);
    return error.response;
  }
};

export const getMeritListingByName = async ({ name }) => {
  try {
    return axiosInstance.get(`${APIs.getMeritList}/${name}?fields=["*"]`);
  } catch (error) {
    console.log("Error while getting Merit Listing ny name", error);
    return error.response;
  }
};

export const getFilteredStudentInMeritList = async ({ payload }) => {
  try {
    return axiosInstance.post(`${APIs.getFilteredStudentInMeritList}`, payload);
  } catch (error) {
    console.log("Error while getting filtered students Merit Listing", error);
    return error.response;
  }
};

export const createMeritList = async ({ payload }) => {
  try {
    return axiosInstance.post(`${APIs.createMeritList}`, payload);
  } catch (error) {
    console.log("Error while creating merit list", error);
    return error.response;
  }
};

export const getStudentListing = async (filters) => {
  try {
    return axiosInstance.get(
      `${APIs.getStudentList}?filters=${filters}&fields=["*"]`
    );
  } catch (error) {
    console.log("Error while getting Student Listing", error);
    return error.response;
  }
};

export const getStudentDetailsById = async ({ name }) => {
  try {
    return axiosInstance.get(`${APIs.getStudentList}/${name}`);
  } catch (error) {
    console.log("Error while getting Student ", error);
    return error.response;
  }
};

export const getApplicationDetailsById = async ({ name }) => {
  try {
    return axiosInstance.get(`${APIs.getApplicantDetails}?name=${name}`);
  } catch (error) {
    console.log("Error while getting application details ", error);
    return error.response;
  }
};

export const updateWithdrawStatus = async ({ payload }) => {
  try {
    const res = await axiosInstance.post(APIs.createUpdateGenericData, payload);
    return res;
  } catch (error) {
    console.log("Error Updating withdraw status", error);
    return error.response;
  }
};

// Enrollment.js
import { Box, Button } from "@mui/material";
import { GridMenuIcon } from "@mui/x-data-grid";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import MasterIcon from "../../../assets/images/master-list-icon.svg";
import "./Enrollment.scss";

const EnrollmentComponent = () => {
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    // Use navigate to go back to the previous page
    navigate(-1);
  };
  return (
    <>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3 }}
        className="mainPanel noPadding"
      >
        <div className="master-heading">
          <h2>
            <GridMenuIcon />
            {`Enrollment`}
          </h2>
          <div className="btnsGrp" onClick={handleBackButtonClick}>
            <Button variant="outlined">Back</Button>
          </div>
        </div>
      </Box>
      <div className="MasterListButtons">
        <div
          className="buttons"
          onClick={() => {
            navigate("/modules/academics/enrollment/bulk-student-enrollment");
          }}
        >
          {" "}
          <ReactSVG src={MasterIcon} className="mastIcon" />
          Bulk Student Enrollment Tool
        </div>
        <div className="buttons">
          <ReactSVG src={MasterIcon} className="mastIcon" />
          Student Enrollment
        </div>
      </div>{" "}
    </>
  );
};

export default EnrollmentComponent;

import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import { getTemplate } from "modules/application";
import { Stack, Typography } from "iiht-b2a-ui-components/build/components";
import { FormSelectField, FormTextField } from "components/common";
import { validationRules } from "constants/validationRules";
import { addressMapper } from "utils";
import { selectTemplate, sendEmail } from "modules/lead";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { setLoading } from "slices/commonSlice";

const ComposeEmail = forwardRef(({ uniqueId, jobApplicationData, close, emailView }, ref) => {
  useImperativeHandle(ref, () => {
    return {
      onSubmit: onSubmit,
    };
  });
  const dispatch = useDispatch();
  const readOnly = emailView.value;
  const [templateList, setTemplateList] = useState([]);
  const [emailContent, setEmailContent] = useState(readOnly ? emailView.data?.content : "");
  const { loading } = useSelector((state) => state.common);

  const defaultValues = {
    recipients: jobApplicationData?.email_id || "",
    subject: readOnly ? emailView.data?.subject : "",
    template: "",
    send_email: "1",
    print_html: "",
    send_me_a_copy: "0",
    print_format: "",
    attachments: [],
    read_receipt: "0",
    print_letterhead: "1",
    send_after: "",
  };

  const methods = useForm({
    defaultValues: defaultValues,
  });

  const { handleSubmit, reset, watch, getValues, setValue, trigger } = methods;

  const temaplteWatch = watch("template");

  const fetchTemplates = async () => {
    dispatch(setLoading({ loading: true }));
    const res = await getTemplate({ doctype: "Lead" });
    if (res.status === 200) {
      const list = addressMapper(res?.data?.data, "name", "name", true);
      setTemplateList(list);
    }
    dispatch(setLoading({ loading: false }));
  };

  const onSubmit = async () => {
    dispatch(setLoading({ loading: true }));
    const valid = await trigger();
    if (valid) {
      const data = getValues();
      const payload = {
        doctype: "Job Applicant",
        name: uniqueId,
        recipients: data.recipients,
        subject: data.subject,
        content: emailContent,
        send_email: "1",
        print_html: "",
        send_me_a_copy: "0",
        print_format: "",
        attachments: [],
        read_receipt: "0",
        print_letterhead: "1",
        send_after: "",
      };
      const res = await sendEmail(payload);
      if (res?.status === 200) {
        close(false);
      }
    }
    dispatch(setLoading({ loading: false }));
  };

  const fetchDescription = async (selectedTemplate) => {
    dispatch(setLoading({ loading: true }));
    const res = await selectTemplate(selectedTemplate);
    if (res.status === 200) {
      setEmailContent(res?.data?.data?.response);
    }
    dispatch(setLoading({ loading: false }));
  };

  useEffect(() => {
    if (temaplteWatch) {
      fetchDescription(temaplteWatch);
    }
  }, [temaplteWatch]);

  useEffect(() => {
    fetchTemplates();
  }, []);

  return (
    <div>
      {loading && <LoadingBackdrop open={loading} />}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={1}>
            <div style={{ maxWidth: "600px" }}>
              <Typography variant="bodyText" color="#012148" fontWeight={500}>
                To
              </Typography>
              <FormTextField
                name="recipients"
                variant="outlined"
                size="small"
                fullWidth
                disabled
                className="mt1"
              />
            </div>

            <div style={{ maxWidth: "600px" }}>
              <Typography variant="bodyText" color="#012148" fontWeight={500}>
                Subject
              </Typography>
              <FormTextField
                name="subject"
                variant="outlined"
                size="small"
                fullWidth
                className="mt1"
                disabled={readOnly}
                rules={{
                  required: validationRules.REQUIRED,
                }}
              />
            </div>
            {!emailView.value && (
              <div style={{ maxWidth: "600px" }}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Select Template
                </Typography>
                <FormSelectField
                  name="template"
                  variant="outlined"
                  size="small"
                  fullWidth
                  className="mt1"
                  options={templateList}
                />
              </div>
            )}

            <div style={{ maxWidth: "900px" }}>
              <Typography variant="bodyText" color="#012148" fontWeight={500}>
                Description
              </Typography>
              <ReactQuill value={emailContent} onChange={(value) => setEmailContent(value)} />
            </div>
          </Stack>
        </form>
      </FormProvider>
    </div>
  );
});

export default ComposeEmail;

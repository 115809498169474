import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import CardComponent from "../../../../components/common/Cards";
import { Checklist, MenuBookSvg, UserTick } from "../../../../icons";
import GraphSvg from "../../../../icons/Graph";
import { TextField } from "@mui/material";
import { getCurrentDate } from "../../../../utils/date";
import { getApplicationListing } from "../../../../modules/application";
import useStyles from "./style";

const getTodayDate = () => {
  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();

  return `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}`;
};

const ApplicationDashboard = () => {
  const styles = useStyles();
  const [startDate, setStartDate] = useState(getTodayDate());
  const [endDate, setEndDate] = useState(getTodayDate());
  const [applicants, setApplicants] = useState([]);
  const [admittedApplicants, setAdmittedApplicants] = useState(0);
  const [rejectedApplicants, setRejectedApplicants] = useState(0);
  const [submittedApplicants, setSubmittedApplicants] = useState(0);
  const [paidApplicants, setPaidApplicants] = useState(0);

  const handleGetApplications = async () => {
    const res = await getApplicationListing(startDate, endDate);
    if (res.status === 200) {

      setApplicants(res.data.data.length);
      const admitted = res?.data?.data.filter(
        (item) => item.application_status === "Admitted"
      );
      const rejected = res?.data?.data.filter(
        (item) => item.application_status === "Rejected"
      );
      const submitted = res?.data?.data.filter(
        (item) => item.workflow_state === "Submitted"
      );
      const paid = res?.data?.data.filter(
        (item) => item.workflow_state === "Paid"
      );
      setAdmittedApplicants(admitted.length);
      setRejectedApplicants(rejected.length);
      setSubmittedApplicants(submitted.length);
      setPaidApplicants(paid.length)
    }
  };

  const handleStartDateChange = (newStartDate) => {
    if (newStartDate <= getCurrentDate()) {
      setStartDate(newStartDate);
      if (newStartDate > endDate) {
        setEndDate(newStartDate);
      }
    }
  };

  const handleEndDateChange = (newEndDate) => {
    if (newEndDate <= getCurrentDate()) {
      setEndDate(newEndDate);
      if (newEndDate < startDate) {
        setStartDate(newEndDate);
      }
    }
  };

  useEffect(() => {
    handleGetApplications();
  }, [startDate, endDate]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "22px" }}>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <div style={{ display: "inline-flex", gap: "12px" }}>
          <TextField
            id="start-date"
            label="Start Date"
            type="date"
            value={startDate}
            inputProps={{
              max: getCurrentDate(),
            }}
            onChange={(e) => handleStartDateChange(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="end-date"
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => handleEndDateChange(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: getCurrentDate(),
            }}
          />
        </div>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={2.4}>
          <CardComponent
            title="Total Applicants"
            content={applicants}
            icon={
              <UserTick
                width={40}
                height={32}
                viewBox={"0 96 960 960"}
                fill="#ac0521"
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CardComponent
            title="Paid Application Fee"
            content={submittedApplicants}
            icon={
              <GraphSvg
                width={40}
                height={32}
                viewBox={"0 96 960 960"}
                fill="#ac0521"
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CardComponent
            title="Paid Admission Fee"
            content={paidApplicants}
            icon={
              <Checklist
                width={40}
                height={32}
                viewBox={"0 96 960 960"}
                fill="#ac0521"
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CardComponent
            title="Rejected"
            content={rejectedApplicants}
            icon={
              <Checklist
                width={40}
                height={32}
                viewBox={"0 96 960 960"}
                fill="#ac0521"
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CardComponent
            title="Admitted"
            content={admittedApplicants}
            icon={
              <MenuBookSvg
                width={40}
                height={32}
                viewBox={"0 96 960 960"}
                fill="#ac0521"
              />
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ApplicationDashboard;

import APIs from "../api";
import axiosInstance from "../translator";

export const getApplicationFormListing = async ({ payload }) => {
  try {
    const fields = JSON.stringify(["*"]);
    const res = await axiosInstance.post(
      `${APIs.getAppicationFormListing}?fields=${fields}`,
      payload
    );
    return res;
  } catch (error) {
    console.log("Error getting application form listing", error);
    return error.response;
  }
};

export const addDataToApplicationForm = async ({ payload }) => {
  try {
    const res = await axiosInstance.post(
      APIs.addDataToApplicationForm,
      payload
    );
    return res;
  } catch (error) {
    console.log("Error adding data to application form", error);
    return error.response;
  }
};

export const addStudentAttendance = async ({ payload }) => {
  console.log(payload);
  try {
    const res = await axiosInstance.post(APIs.addAttendance, payload);
    return res;
  } catch (error) {
    console.log("Error adding data to application form", error);
    return error.response;
  }
};

export const addBulkStudentAttendance = async (payload) => {
  console.log("abcabc", payload);
  console.log("abcabc", JSON.stringify(payload));
  try {
    const res = await axiosInstance.post(APIs.addBulkAttendance, payload);
    return res;
  } catch (error) {
    console.log("Error adding attendance", error);
    return error.response;
  }
};

export const getDataOfApplicationFormByApplicationId = async ({
  applicationId,
}) => {
  try {
    const res = await axiosInstance.get(
      `${APIs.addDataToApplicationForm}/${applicationId}`
    );
    return res;
  } catch (error) {
    console.log("Error getting data from application form", error);
    return error.response;
  }
};

export const updateDataToApplicationForm = async ({ payload, name }) => {
  try {
    const res = await axiosInstance.put(
      `${APIs.addDataToApplicationForm}/${name}`,
      payload
    );
    return res;
  } catch (error) {
    console.log("Error updating data to application form", error);
    return error.response;
  }
};

export const uploadFile = async ({ fileName, fileData }) => {
  try {
    const res = await axiosInstance.post(APIs.uploadFile, {
      cmd: "uploadfile",
      isprivate: 0,
      from_form: 1,
      filename: fileName,
      filedata: fileData,
    });
    return res;
  } catch (error) {
    console.log("Error uploading file", error);
    return error.response;
  }
};

export const getGenderList = async () => {
  try {
    const res = await axiosInstance.get(APIs.getGenderList);
    return res;
  } catch (error) {
    console.log("Error getting gender listing", error);
    return error.response;
  }
};

export const getGenderPronounList = async () => {
  try {
    const res = await axiosInstance.get(APIs.getGenderPronounList);
    return res;
  } catch (error) {
    console.log("Error getting gender pronoun listing", error);
    return error.response;
  }
};

export const getStudentCategoryListing = async () => {
  try {
    const res = await axiosInstance.get(APIs.getStudentCategoryList);
    return res;
  } catch (error) {
    console.log("Error getting student category listing", error);
    return error.response;
  }
};

export const getGuardianDetailsById = async ({ guardianId }) => {
  try {
    const res = await axiosInstance.get(
      `${APIs.createGuardianForm}/${guardianId}`
    );
    return res;
  } catch (error) {
    console.log("Error getting data from guardian api", error);
    return error.response;
  }
};

export const getSubjectsListing = async () => {
  try {
    const res = await axiosInstance.get(APIs.getSubjectsList);
    return res;
  } catch (error) {
    console.log("Error getting student category listing", error);
    return error.response;
  }
};

export const getStudentFeedbackTemplate = async ( email ) => {
  try {
    const res = await axiosInstance.get(`${APIs.getStudentFeedbackTemplate}?email=${email}`);
    return res;
  } catch (error) {
    console.log("Error getting student category listing", error);
    return error.response;
  }
};

export const getFeedbackQuestion = async ( id ) => {
  try {
    const res = await axiosInstance.get(`${APIs.getFeedbackTemplate}/${id}?fields=["*"]`);
    return res;
  } catch (error) {
    console.log("Error getting student category listing", error);
    return error.response;
  }
};

export const feedbackResponse = async ( payload ) => {
  try {
    const res = await axiosInstance.post(APIs.feedbackResponse,payload);
    return res;
  } catch (error) {
    console.log("Error posting response", error);
    return error.response;
  }
};


import React, { useState, useEffect } from "react";
import { GridMenuIcon } from "@mui/x-data-grid";
import { getApplicantDetails } from "modules/studentApplication";
import Event from "./Event/Event";
import Email from "./Email";
import Notes from './Notes/Notes';
import Payment from "./PaymentListing";
import ApplicationDocs from "./ApplicationDocs";
import ApplicationDetailsJSON from "./ApplicationDataJson";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useStyles from "./style";
import MainFooter from "components/common/MainFooter";
import MainHeader from "components/common/MainHeader";
import { formatDateTODDMMMYYYY } from "utils/date";
import "./ApplicationStyles.css";
 
const ArrayTextBox = ({ field, data }) => {
  const styles = useStyles();
  const { label, name, arrayField, index } = field;
  const details = data[arrayField];
  let value = {};
  if(index < details.length) {
    value = details[index];
  } else {
    value[name] = "-";
  }
  return (
    <div className={styles.textField}>
      <p className={styles.labelText}>{label}</p>
      <p className={styles.labelValue}>{value[name] ? value[name] : label && "-"}</p>
    </div>
  );
};

const ArrayTextArea = ({ field, data }) => {
  const styles = useStyles();
  const { label, name, arrayField, index } = field;
  const details = data[arrayField];
  let value = {};
  if(index < details.length) {
    value = details[index];
  } else {
    value[name] = "-";
  }
  return (
    <div className={styles.textAreaField}>
      <p className={styles.labelText}>{label}</p>
      <p className={styles.textAreaLabelValue}>{value[name] ? value[name] : label && "-"}</p>
    </div>
  );
};

const ArrayDate = ({ field, data }) => {
  const styles = useStyles();
  const { label, name, arrayField, index  } = field;
  const details = data[arrayField];
  let value = {};
  if(index < details.length) {
    value = details[index];
  } else {
    value[name] = "";
  }
  return (
    <div className={styles.textField}>
      <p className={styles.labelText}>{label}</p>
      <p className={styles.labelValue}>{value[name] ? formatDateTODDMMMYYYY(value[name]) : label && "-"}</p>
    </div>
  );
};

const TextBox = ({ field, data }) => {
  const styles = useStyles();
  const { label, name } = field;
  return (
    <>
      {field?.dependantField ? (
        <div className={styles.textField}>
          <p className={styles.labelText}>{label}</p>
          <p className={styles.labelValue}>{data[name].length ? data[name] : label && "-"}</p>
        </div>
      ) : (
        <div className={styles.textField}>
          <p className={styles.labelText}>{label}</p>
          <p className={styles.labelValue}>{data[name] ? data[name] : label && "-"}</p>
        </div>
      )}
    </>
  );
};

const EncryptedTextBox = ({ field, data }) => {
  const styles = useStyles();
  const { label, name } = field;
  return (
    <div className={styles.textField}>
      <p className={styles.labelText}>{label}</p>
      <p className={styles.labelValue}>{data[name] ? `********${data[name].substr(-4)}` : ""}</p>
    </div>
  );
};

const Image = ({ field, data }) => {
  const styles = useStyles();
  const { label, name } = field;
  return (
    <div className={styles.textField}>
      <p className={styles.labelText}>{label}</p>
      <img
        src={`https://erp-dev.techademycampus.com${data[name]}`}
        alt={label}
        style={{ width: "150px", height: "150px" }}
      />
    </div>
  );
};

const TextArea = ({ field, data }) => {
  const styles = useStyles();
  const { label, name } = field;
  return (
    <div className={styles.textAreaField}>
      <p className={styles.labelText}>{label}</p>
      <p className={styles.textAreaLabelValue}>{data[name] ? data[name] : label && "-"}</p>
    </div>
  );
};

const SelectBox = ({ field }) => {
  const styles = useStyles();
  const { label, name, options } = field;
  return (
    <div className={styles.textField}>
      <p className={styles.labelText}>{label}</p>
      <select name={name}>
        {options.map((option) => (
          <option key={option} value={option}>
            <p className={styles.labelValue}>{option}</p>
          </option>
        ))}
      </select>
    </div>
  );
};

const Date = ({ field, data }) => {
  const styles = useStyles();
  const { label, name } = field;
  const details = data;
  return (
    <div className={styles.textField}>
      <p className={styles.labelText}>{label}</p>
      <p className={styles.labelValue}>{details[name] ? formatDateTODDMMMYYYY(details[name]) : label && "-"}</p>
    </div>
  );
};

const RadioBox = ({ field, data }) => {
  const styles = useStyles();
  const { label, name } = field;
  return (
    <div className={styles.textField}>
      <p className={styles.labelText}>{label}</p>
      <div className={styles.radioOptions}>
        <span className={styles.radioOptionsValue}>
          <input
            type="radio"
            checked={data[name] == 1}
            style={{ accentColor: "#A32930" }}
          />
           <p className={styles.labelValue}>Yes</p>
        </span>
        <span className={styles.radioOptionsValue}>
          <input
            type="radio"
            checked={data[name] == 0}
            style={{ accentColor: "#A32930" }}
          />
           <p className={styles.labelValue}>No</p>
        </span>
      </div>
    </div>
  );
}; 

const Checkbox = ({ field, data }) => {
  const styles = useStyles();
  const { label, options } = field;
  return (
    <div className={styles.textAreaField}>
      <p className={styles.labelText}>{label}</p>
      <div className={styles.checkboxContainer}>
        {options.map((option) => (
          <div key={option.key} className={styles.radioOptionsValue}>
            <input
              type="checkbox"
              checked={data[option.value] == 0 ? false : true}
              style={{ accentColor: "#A32930" }}
            />
            <p className={styles.labelValue}>{option.key}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const Table = ({ field, data }) => {
  const styles = useStyles();
  const { name, headers } = field;
  const rows = data[`${name}`];

  return (
    <div className="formTableWrap">
      <table className="formTable docs_table">
        <thead>
          <tr className={styles.tableRow}>
            {rows.length > 0 &&
              headers.map((header) => (
                <th
                  key={header.key}
                  className={styles.tableHeaderTitle}
                  style={{ width: getWidthForHeader(header.key), paddingLeft: "22px" }}
                >
                  {header.key}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((rowValue, idx) => {
           return <tr key={idx} className={styles.tableRow}>
              {headers.map((header, index) => (
                <td
                  key={header.key}
                  style={{ width: getWidthForHeader(header.key) }}
                >
                  {index === 0 ? (
                   <p className={styles.tableContent}>
                    {
                      rowValue[header.value] == "Others"
                        ? rowValue[header.valueText]
                        : rowValue[header.value] || ""
                    }
                  </p>
                  ) : header.key === "Read" ||
                    header.key === "Write" ||
                    header.key === "Speak" ? (
                    <input
                      type="checkbox"
                      className="chkbxCustom"
                      name={`${name}_${header.key}_${idx}`}
                      checked={data[`${name}_${header.value}_${idx}`] || false}
                    />
                  ) : (
                    <p className={styles.tableContent}>
                      {
                        rowValue[header.value] == "Others"
                          ? rowValue[header.valueText]
                          : rowValue[header.value] || ""
                      }
                    </p>
                  )}
                </td>
              ))}
            </tr>
          })}
        </tbody>
      </table>
    </div>
  );
};

const getWidthForHeader = (header) => {
  if (header === "S.No.") {
    return "10%";
  } else if (header === "Read" || header === "Write" || header === "Speak") {
    return "15%";
  } else {
    return "30%";
  }
};

const ApplicationFormData = () => {
  const styles = useStyles();
  const user = useSelector((state) => state.user);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { uniqueId, showResubmitButton } = state;
  const [data, setData] = useState({});
  const [currentTab, setCurrentTab] = useState(ApplicationDetailsJSON[0].name);
  const [content, setContent] = useState(ApplicationDetailsJSON[0].fields);
  const [currentWorkflow, setCurrentWorkflow] = useState("");

  const handleSetFields = (data) => {
    setCurrentTab(data.name);
    setContent(data.fields);
  }

  const getDetails = async (uniqueId) => {
    try {
      const res = await getApplicantDetails(uniqueId);
      if (res.status === 200) {
        setData(res.data.message);
        setCurrentWorkflow(res.data.message.workflow_state);
      } else {
        console.error(
          `Failed to get student details. Server responded with status ${res.status}`
        );
      }
    } catch (error) {
      console.error("Error fetching student details:", error.message);
    }
  };

  useEffect(() => {
    getDetails(uniqueId);
  }, [user.toggle]);

  return (
    <>
      <MainHeader />
      <div className={styles.mainContainer}>
        <div className={styles.headerContainer}>
          <div className={styles.breadCrumbsContainer}>
            <GridMenuIcon className={styles.gridIcon} />
            <p className={styles.title}>{data.first_name}</p>
            <div className="application-status" style={{ marginLeft: "12px"}}>
              <span className={currentWorkflow}>{currentWorkflow}</span>
            </div>
          </div>
          <div className={styles.buttonsGroup}>
            <div
              className={styles.button}
              onClick={() =>  navigate("/student/dashboard")}
            >
              <p className={styles.buttonText}>Back</p>
            </div>
          </div>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.tabContainer}>
            {ApplicationDetailsJSON.map((data) => {
              return (
                <div
                  type="button"
                  key={data.name}
                  className={currentTab === data.name ? styles.tabTitleActive : styles.tabTitleInActive}
                  onClick={() => handleSetFields(data)}
                >
                  {data.name}
                </div>
              )})}
          </div>
          <div className={styles.detailsContainer}>
            {
              content.map((details) => {
                const { label, fields } = details;
                return (
                  <div className={styles.sectionContainer}>
                    <div className={label ? styles.sectionHeader : styles.sectionHeaderWithoutBorder}>
                      {label}
                    </div>
                    <div className={styles.container}>
                      {
                        fields.map((row, i) => {
                          return (
                            <div className={styles.rowContainer}>
                            {
                              row.map((field) => {
                                return (
                                  <>
                                    {renderFieldByType(field, data, uniqueId, showResubmitButton)}
                                  </>
                                )
                              })
                            }
                          </div>
                          )
                        })
                      }
                    </div>
                  </div>
              )})
            }
          </div>
        </div>
      </div>
      <MainFooter />
    </>
  );
};

const ComponentToRender = ({ data, field, uniqueId, showResubmitButton }) => {
  switch (field.componentToRender) {
    case "notes":
      return <Notes uniqueId={uniqueId}  />;
    case "events":
      return <Event uniqueId={uniqueId}  />;
    case "emails":
      return <Email uniqueId={uniqueId}  />;
    case "attachments":
      return <ApplicationDocs 
              uniqueId={uniqueId}
              showResubmitButton={showResubmitButton}  />;
    case "payment":
      return <Payment uniqueId={uniqueId}  />;
    default:
      return null;
  }
};

const renderFieldByType = (field, data, uniqueId, showResubmitButton ) => {
  switch (field.type) {
    case "text":
      return <TextBox field={field} data={data} />;
    case "image":
      return <Image field={field} data={data} />;
    case "date":
      return <Date field={field} data={data} />;
    case "encrypted":
      return <EncryptedTextBox field={field} data={data} />;
    case "table":
      return <Table field={field} data={data} />;
    case "arrayText": 
      return <ArrayTextBox field={field} data={data} />;
    case "arrayDate":
      return <ArrayDate field={field} data={data} />; 
    case "select":
      return <SelectBox field={field} data={data} />;
    case "textarea":
      return <TextArea field={field} data={data} />;
    case "radio":
      return <RadioBox field={field} data={data} />;
    case "checkbox":
      return <Checkbox field={field} data={data} />;
    case "arrayTextArea": 
      return <ArrayTextArea field={field} data={data} />;
    case "component":
      return <ComponentToRender 
        data={data} 
        field={field} 
        uniqueId={uniqueId} 
        showResubmitButton={showResubmitButton} 
      />;
    default:
      return null;
  }
};

export default ApplicationFormData;

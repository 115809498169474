import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import eventBus from "core/eventBus";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { createNote } from "modules/application";
import { toast } from "react-toastify";

const AddNotes = ({ setToggle, toggle, uniqueId }) => {
  const [note, setNote] = useState("");

  const handleCreateNote = async () => {
    const payload = {
      data: {
        note,
        parent: uniqueId,
        parenttype: "Student Applicant",
        doctype: "CRM Note",
      },
    };
    console.log(payload,"99911111111111")
    const res = await createNote({ payload });
    if (res.status === 200) {
      setToggle(!toggle);
      eventBus.emit("close-dialog");
      toast.success("Note created Successfully !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(res.data?.exc_type, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <div
      style={{
        padding: "32px",
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        width: "60vw",
      }}
    >
      <div
        style={{
          display: "inline-flex",
          justifyContent: "space-between",
          // width: "100%",
        }}
      >
        <p style={{ color: "#1A1B25", fontSize: "24px", fontWeight: "600" }}>
          Add Notes
        </p>
        <CloseIcon
          onClick={() => eventBus.emit("close-dialog")}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <p style={{ color: "#545969", fontSize: "14px", fontWeight: "500" }}>
          Enter text
        </p>
        <div
          style={{
            borderRadius: "10px",
            backgroundColor: "#F6F6F6",
            padding: "24px",
          }}
        >
          <textarea
            rows="15"
            style={{
              width: "100%",
              background: "none",
              border: "none",
              outline: "none",
            }}
            onChange={(e) => setNote(e.target.value)}
          ></textarea>

          {/* <div
            style={{
              borderRadius: "40px",
              backgroundColor: "#DEDEDE",
              display: "inline-flex",
              height: "32px",
              alignItems: "flex-start",
            }}
          >
            <ReactSVG
              src={TagsSvg}
              style={{ marginTop: "-4px", marginLeft: "-4px" }}
            />
            <p
              style={{
                color: "#1A1B25",
                fontSize: "14px",
                fontWeight: "500",
                paddingRight: "8px",
              }}
            >
              Tags
            </p>
          </div> */}
        </div>
      </div>
      <div
        style={{
          width: "168px",
          backgroundColor: "#A32930",
          borderRadius: "4px",
          padding: "8px 16px",
          display: "inline-flex",
          alignItems: "center",
          gap: "8px",
          cursor: "pointer",
        }}
        onClick={handleCreateNote}
      >
        <p style={{ color: "#FFF", fontSize: "14px", fontWeight: "500" }}>
          Save Message
        </p>
        <ArrowRightAltIcon style={{ color: "#fff" }} />
      </div>
    </div>
  );
};

export default AddNotes;

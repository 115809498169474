import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Breadcrumbs, MainLayout } from "components/common";
import { Button, message, Input, Select, Table, Checkbox } from "antd";
import { toast } from "react-toastify";
import {
  getGenericData,
  createGenericEntity,
  updateGenericEntity,
  getGenericList,
  getLeaveDetailList,
} from "modules/hr";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "slices/commonSlice";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;

const LeaveApplicationDetails = () => {
  const loading = useSelector((state) => state.common.loading);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState([]);
  const [leaveTypeData, setLeaveTypeData] = useState([]);
  const [leaveAllocationData, setLeaveAllocationData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [isHalfDay, setIsHalfDay] = useState(false);
  const [leaveApplication, setLeaveApplication] = useState({
    id: "",
    carry_forward: 0,
    employee_name: "",
    leave_approver: "",
    company: "",
    assignment_based_on: "",
    effective_to: "",
    employee: "",
    leaves_allocated: 0,
    effective_from: "",
    from_date: "",
    to_date: "",
    description: "",
    follow_via_email: "",
    total_leave_days: "",
    status: "",
    half_day_date: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (id && id !== "new") {
      fetchData(id);
    } else {
      resetLeaveApplication();
    }
    fetchEmployeeData();
    fetchLeaveTypeData();
  }, [id]);

  const resetLeaveApplication = () => {
    setLeaveApplication({
      id: "",
      carry_forward: 0,
      employee_name: "",
      leave_approver: "",
      company: "Techademy",
      assignment_based_on: "",
      effective_to: "",
      employee: "",
      leaves_allocated: 0,
      effective_from: "",
      from_date: "",
      to_date: "",
      description: "",
      follow_via_email: "",
      total_leave_days: "",
      status: "Open",
      half_day_date: "",
    });
  };

  useEffect(() => {
    if (leaveApplication.employee) {
      fetchLeaveDetailsData(leaveApplication.employee);
    }
  }, [leaveApplication.employee]);

  const fetchData = async (id) => {    
    dispatch(setLoading({ loading: true }));
    try {
      if (id && id !== "new") {
        const leaveApplicationData = await getGenericData(
          "Leave Application",
          id
        );
        setLeaveApplication({
          ...leaveApplicationData.message,
          company: leaveApplicationData.message.company,
        });
      }
    } catch (error) {
      toast.error("Failed to fetch Leave Application list. Please try again.");
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };
  const fetchLeaveDetailsData = async (employeeId) => {
    try {
      if (employeeId) {
        const currentDate = new Date().toISOString().split("T")[0];
        const leaveDetails = await getLeaveDetailList(employeeId, currentDate);
        const leaveAllocationList = leaveDetails.leave_allocation;
        const formattedData = Object.keys(leaveAllocationList).map((leaveType) => ({
          key: leaveType, // Adding a unique key for each row
          leave_type: leaveType,
          total_leaves: leaveAllocationList[leaveType].total_leaves,
          expired_leaves: leaveAllocationList[leaveType].expired_leaves, 
          leaves_pending_approval: leaveAllocationList[leaveType].leaves_pending_approval,
          remaining_leaves: leaveAllocationList[leaveType].remaining_leaves,
        }));
        setRowData(formattedData);
      }
    } catch (error) {
      toast.error("Failed to fetch leave allocation data. Please try again.");
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  const fetchEmployeeData = async () => {
    setLoading(true);
    try {
      const employeeDataList = await getGenericList("Employee");
      // Populate the leave_approver_name field with the email ID of the leave approver
      const updatedEmployeeDataList = employeeDataList.map((employee) => ({
        ...employee,
        leave_approver_name: employee.leave_approver, // Set leave_approver_name to leave_approver
      }));
      setEmployeeData(updatedEmployeeDataList);
      console.log("Employee Data List:", updatedEmployeeDataList);
    } catch (error) {
      console.error("Error fetching Employee Data:", error);
    }
    setLoading(false);
  };

  const fetchLeaveTypeData = async () => {
    setLoading(true);
    try {
      const leaveTypeList = await getGenericList("Leave Type");
      setLeaveTypeData(leaveTypeList);
      console.log("Leave Type Data List:", leaveTypeList);
    } catch (error) {
      console.error("Error fetching Leave Type Data:", error);
    }
    setLoading(false);
  };

  const calculateTotalLeaveDays = () => {
    const { from_date, to_date } = leaveApplication;
    const startDate = new Date(from_date);
    const endDate = new Date(to_date);
    const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
    const leaveDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 1;
    return leaveDays;
  };

  const handleSave = async () => {
    try {
      const doctype = "Leave Application";
  
      const validationErrors = validateFields();
  
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }
  
      const payload = {
        ...leaveApplication,
        doctype,
      };
  
      let res;
      if (id) {
        res = await updateGenericEntity({ payload });
      } else {
        res = await createGenericEntity({ payload });
      }
  
      if (res.status === 200) {
        toast.success(id ? "Leave Allocation List Updated!" : "Leave Allocation List Created!");
        navigate(-1);
      } else {
        if (res.data && res.data.message) {
          toast.error(res.data.message);
        } else {
          toast.error("Failed to save Leave Allocation. Please try again later."+ res.data);
        }
      }
    } catch (error) {
      console.error("Error saving Leave Allocation:", error);
      toast.error("An error occurred while saving Leave Allocation.");
    }
  };
  

  // const handleSave = async () => {
  //   try {
  //     const validationErrors = validateFields();
  //     if (Object.keys(validationErrors).length > 0) {
  //       setErrors(validationErrors);
  //       return;
  //     }
  //     const totalLeaveDays = isHalfDay ? 0.5 : calculateTotalLeaveDays();
  
  //     const payload = {
  //       doctype: "Leave Application",
  //       ...leaveApplication,
  //       total_leave_days: totalLeaveDays,
  //     };
  //     if (id && id !== "new") {
  //       payload.id = id;
  //       await updateGenericEntity({ payload });
  //       toast.success("Leave Application updated successfully");
  //     } else {
  //       await createGenericEntity({ payload });
  //       // No error occurred during creation, so display success message
  //       navigate(-1); // Navigate back only after success
  //       toast.success("Leave Application added successfully");
  //     }
  //   } catch (error) {
  //     console.error("Error occurred:", error);
  //     if (error.message) {
  //       // Handle specific error message here
  //       toast.error(error.message);
  //     } else {
  //       // Handle generic error message
  //       toast.error("Failed to save Leave Application. Please try again.");
  //     }
  //   }
  // };
  

  const validateFields = () => {
    const errors = {};

    if (!leaveApplication.employee) {
      errors.employee = "Employee Id is required";
    }
    if (!leaveApplication.employee_name) {
      errors.employee_name = "Employee Name is required";
    }
    if (!leaveApplication.company) {
      errors.company = "Institution is required";
    }
    if (!leaveApplication.leave_type) {
      errors.leave_type = "Leave Type is required";
    }
    if (!leaveApplication.from_date) {
      errors.from_date = "From date is required";
    }
    if (!leaveApplication.to_date) {
      errors.to_date = "To date is required";
    }
    if (!leaveApplication.description) {
      errors.description = "Reason is required";
    }
    if (!leaveApplication.leave_approver) {
      errors.leave_approver = "Leave Approver is required";
    }
    if (!leaveApplication.status) {
      errors.status = "Status is required";
    }

    return errors;
  };
  const handleFetchError = (error) => {
    console.error("Error fetching Leave Application data:", error);
    dispatch(setLoading(false));
  };
  const handleSaveError = (error) => {
    console.error("Error saving Leave Application:", error);
    toast.error("Failed to save Leave Application. Please try again.");
  };
  const handleCancel = () => {
    navigate(-1);
  };

  const handleEmployeeIdChange = (value) => {
    const selectedEmployee = employeeData.find(
      (employee) => employee.id === value
    );
    if (selectedEmployee) {
      setLeaveApplication((prevState) => ({
        ...prevState,
        employee: value,
        employee_name: selectedEmployee.employee_name,
        leave_approver: selectedEmployee.leave_approver, // Set the leave approver based on the selected employee
        effective_from: selectedEmployee.creation,
        effective_to: selectedEmployee.modified,
      }));
      fetchLeaveDetailsData(value); // Fetch leave allocation data for the selected employee
    }
  };
  const handleLeaveApproverChange = (value) => {
    const selectedEmployee = employeeData.find(
      (employee) => employee.leave_approver === value
    );
    if (selectedEmployee) {
      setLeaveApplication((prevState) => ({
        ...prevState,
        leave_approver: selectedEmployee.leave_approver,
      }));
    }
  };

  const handleLeaveTypeIdChange = (value) => {
    const selectedLeaveType = leaveTypeData.find(
      (leavetype) => leavetype.id === value
    );
    if (selectedLeaveType) {
      setLeaveApplication((prevState) => ({
        ...prevState,
        leave_type: value,
        effective_from: selectedLeaveType.from_date,
        effective_to: selectedLeaveType.to_date,
      }));
    }
  };

  const handleStatusChange = (value) => {
    setLeaveApplication((prevState) => ({
      ...prevState,
      status: value,
    }));
  };

  const columns = [
    {
      title: "Leave Type",
      dataIndex: "leave_type",
      key: "leave_type",
    },
    {
      title: "Total Leaves Allocated",
      dataIndex: "total_leaves",
      key: "total_leaves",
    },
    {
      title: "Expired Leaves",
      dataIndex: "expired_leaves",
      key: "expired_leaves",
    }, 
    {
      title: "Leaves Pending Approval",
      dataIndex: "leaves_pending_approval",
      key: "leaves_pending_approval",
    },
    {
      title: "Available Leaves",
      dataIndex: "remaining_leaves",
      key: "remaining_leaves",
    },
  ];
  return (
    <MainLayout>
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <>
          <Breadcrumbs />
          <div className="master-heading">
            <h2>
              {id && id !== "new"
                ? "View Leave Applications"
                : "Add Leave Applications"}
            </h2>
            <div className="btnsGrp">
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                Back
              </Button>
              <Button
                onClick={handleSave}
                variant="contained"
                color="primary"
                className="redBtn"
              >
                {id && id !== "new" ? "Update" : "Save"}
              </Button>
            </div>
          </div>
          <div className="formApplication allocationsForm">
            <h4>Leave Allocation Details</h4>
            {leaveApplication.employee && (
              <section className="table-section ">
                <Table dataSource={rowData} columns={columns} />
              </section>
            )} 
            <section className="form-section">
              <div className="fields-block">
                <div className="form-field">
                  <label>Employee Id *</label>
                  <Select
                    value={leaveApplication.employee}
                    onChange={handleEmployeeIdChange}
                    placeholder="Select Employee"
                  >
                    {employeeData.map((employee) => (
                      <Option key={employee.id} value={employee.id}>
                        {employee.id}
                      </Option>
                    ))}
                  </Select>
                  {errors.employee && (
                    <span className="error-message">{errors.employee}</span>
                  )}
                </div>

                <div className="form-field">
                  <label>Employee Name *</label>
                  <Input
                    // disabled={!id}
                    disabled
                    value={leaveApplication.employee_name}
                    onChange={(e) =>
                      setLeaveApplication((prevState) => ({
                        ...prevState,
                        employee_name: e.target.value,
                      }))
                    }
                  />
                  {errors.employee_name && (
                    <span className="error-message">
                      {errors.employee_name}
                    </span>
                  )}
                </div>
                <div className="form-field">
                  <label>Institution *</label>
                  <Input
                    disabled={true}
                    value={leaveApplication.company}
                    onChange={(e) =>
                      setLeaveApplication((prevState) => ({
                        ...prevState,
                        company: e.target.value,
                      }))
                    }
                  />
                  {errors.company && (
                    <span className="error-message">{errors.company}</span>
                  )}
                </div>
                <div className="form-field">
                  <label>Leave Type *</label>
                  <Select
                    value={leaveApplication.leave_type}
                    onChange={handleLeaveTypeIdChange}
                    placeholder="Select Leave Type"
                  >
                    {leaveTypeData.map((leavetype) => (
                      <Option key={leavetype.id} value={leavetype.id}>
                        {leavetype.id}
                      </Option>
                    ))}
                  </Select>
                  {errors.leave_type && (
                    <span className="error-message">{errors.leave_type}</span>
                  )}
                </div>
              </div>
            </section>
            <section className="form-section">
              <h4>Dates and Reason</h4>
              <div className="fields-block">
                <div className="form-field">
                  <label>From date *</label>
                  <Input
                    disabled={!id}
                    type="date"
                    value={leaveApplication.from_date}
                    onChange={(e) =>
                      setLeaveApplication((prevState) => ({
                        ...prevState,
                        from_date: e.target.value,
                      }))
                    }
                  />
                  {errors.from_date && (
                    <span className="error-message">{errors.from_date}</span>
                  )}
                </div>
                <div className="form-field">
                  <label>To Date *</label>
                  <Input
                    disabled={!id}
                    type="date"
                    value={leaveApplication.to_date}
                    onChange={(e) =>
                      setLeaveApplication((prevState) => ({
                        ...prevState,
                        to_date: e.target.value,
                      }))
                    }
                  />
                  {errors.to_date && (
                    <span className="error-message">{errors.to_date}</span>
                  )}
                </div>

                <div className="form-field">&nbsp;</div>

                <div className="form-field fullWidthFields">
                  <Checkbox
                    checked={isHalfDay}
                    onChange={(e) => setIsHalfDay(e.target.checked)}
                  >
                    Half Day
                  </Checkbox>
                </div>
                <div className="form-field fullWidthFields">
                  <label>Reason *</label>
                  <TextArea
                    disabled={!id}
                    value={leaveApplication.description}
                    onChange={(e) =>
                      setLeaveApplication((prevState) => ({
                        ...prevState,
                        description: e.target.value,
                      }))
                    }
                  />
                  {errors.description && (
                    <span className="error-message">{errors.description}</span>
                  )}
                </div>
              </div>
            </section>
            <section className="form-section">
              <h4>Approval</h4>
              <div className="fields-block">
                <div className="form-field">
                  <label>Leave Approver *</label>
                  <Select
                    value={leaveApplication.leave_approver}
                    onChange={handleLeaveApproverChange}
                    placeholder="Select Leave Approver"
                  >
                    {employeeData.map((employee) => (
                      <Option
                        key={employee.leave_approver}
                        value={employee.leave_approver}
                      >
                        {employee.leave_approver}
                      </Option>
                    ))}
                  </Select>

                  {errors.leave_approver && (
                    <span className="error-message">
                      {errors.leave_approver}
                    </span>
                  )}
                </div>

                <div className="form-field">
                  <label>Posting Date *</label>
                  <Input
                    disabled={!id}
                    type="date"
                    value={leaveApplication.posting_date}
                    onChange={(e) =>
                      setLeaveApplication((prevState) => ({
                        ...prevState,
                        posting_date: e.target.value,
                      }))
                    }
                  />
                  {errors.posting_date && (
                    <span className="error-message">{errors.posting_date}</span>
                  )}
                </div>
                <div className="form-field">
                  <label>Status *</label>
                  <Select
                    value={leaveApplication.status}
                    onChange={handleStatusChange}
                    placeholder="Select Status"
                  >
                    <Option value="Open">Open</Option>
                    <Option value="Approved">Approved</Option>
                    <Option value="Rejected">Rejected</Option>
                    <Option value="Cancelled">Cancelled</Option>
                  </Select>
                  {errors.status && (
                    <span className="error-message">{errors.status}</span>
                  )}
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </MainLayout>
  );
};

export default LeaveApplicationDetails;

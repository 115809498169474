import APIs from "../api";
import axiosInstance from "../translator";

export const getEntityList = async (entityType) => {
  try {
    const response = await axiosInstance.get(
      `${APIs.getEntityList}?entityType=${entityType}`
    );
    console.log("getEntityList Response:", response);
    if (response.status === 200) {
      return response?.data?.message;
    }
  } catch (error) {
    console.error("Error getting entity detail:", error);
    throw error;
  }
};
export const getCountryList = async () => {
  try {
    const response = await axiosInstance.get(`${APIs.getCountryList}`);
    console.log("getEntityList Response:", response);
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error("Error getting entity detail:", error);
    throw error;
  }
};
export const getCurrencyList = async (entityType) => {
  try {
    const response = await axiosInstance.get(`${APIs.getCurrencyList}`);
    console.log("getEntityList Response:", response);
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error("Error getting entity detail:", error);
    throw error;
  }
};

export const getBulkEditFieldNames = async (entityType) => {
  try {
    const response = await axiosInstance.get(
      `${APIs.getBulkEditSelectFieldOptions}?doctype=${entityType}`,
      {
        doctype: entityType,
      }
    );
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error("Error getting Bulk Edit Fields:", error);
    throw error;
  }
};

export const getEntityDetail = async (entityType, id) => {
  try {
    const response = await axiosInstance.post(APIs.getEntityData, {
      entityType: entityType,
      id: id,
    });

    console.log("GetEntityData Responseeeeee:", response);

    if (response.status === 200) {
      return response?.data?.message;
    }
  } catch (error) {
    console.error("Error getting entity detail:", error);
    throw error;
  }
};

export const createEntity = async ({ payload }) => {
  try {
    const res = await axiosInstance.post(APIs.createEntity, payload);
    return res;
  } catch (error) {
    console.log("Error Adding Entity", error);
    return error.response;
  }
};

export const getCourseList = async (setDataCourseOptions) => {
  try {
    const response = await getEntityList("Course");
    if (response.success_key === 1) {
      let options = [{ label: "", value: "" }];
      response.message.map((item) => {
        options.push({ label: item.id, value: item.id });
      });
      const finalCourseData = {
        courseList: options,
        isFetched: true,
      };
      console.log(finalCourseData, "RESPONSE Course");
      setDataCourseOptions(finalCourseData);
    }
  } catch (error) {
    console.error(error, "Something went wrong");
  }
};

export const getGenericList = async (doctype) => {
  try {
    const response = await axiosInstance.get(
      `${APIs.getGenericList}?doctype=${doctype}`
    );
    console.log("getGenericList Response:", response);
    if (response.status === 200) {
      return response?.data?.message;
    }
  } catch (error) {
    console.error("Error getting Generic list:", error);
    throw error;
  }
};

export const getGenericData = async (doctype, id) => {
  try {
    const response = await axiosInstance.get(APIs.getGenericData, {
      params: {
        doctype: doctype,
        id: id,
      },
    });

    console.log("Get GenericData Response:", response?.data);

    if (response.status === 200) {
      return response?.data;
    }
  } catch (error) {
    console.error("Error getting entity detail:", error);
    throw error;
  }
};

export const getStudentsInEnrollment = async (studentFrom, programme) => {
  try {
    const response = await axiosInstance.get(APIs.getStudentsInEnrollment, {
      params: {
        student_from: studentFrom,
        program: programme,
      },
    });

    console.log("Get GenericData Response:", response);

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error("Error getting entity detail:", error);
    throw error;
  }
};

export const enrollStudents = async (payload) => {
  console.log(payload);
  try {
    const res = await axiosInstance.post(APIs.enrollStudents, payload);
    return res;
  } catch (error) {
    console.log("Error Enrolling Students", error);
    return error.response;
  }
};

export const createGenericEntity = async ({ payload }) => {
  console.log(payload);
  try {
    const res = await axiosInstance.post(APIs.createGenericData, payload);
    return res;
  } catch (error) {
    console.log("Error Adding Generic Entity", error);
    return error.response;
  }
};

export const getChaptersList = async ({ unit }) => {
  try {
    const filters = JSON.stringify([["unit", "=", unit]]);
    const response = await axiosInstance.get(
      `${APIs.genericGetListing}?doctype=Chapters&filters=${filters}`
    );
    return response;
  } catch (error) {
    console.error("Error getting chapters Listing: ", error);
    throw error;
  }
};

export const getTopicsList = async () => {
  try {
    const response = await axiosInstance.get(
      `${APIs.genericGetListing}?doctype=Article`
    );
    return response;
  } catch (error) {
    console.error("Error getting articles Listing: ", error);
    throw error;
  }
};
export const updateFieldsInBulk = async ({ payload }) => {
  try {
    const response = await axiosInstance.post(APIs.bulkActionEdit, payload);
    return response;
  } catch (error) {
    console.error("Error getting Bulk Edit Fields:", error);
    throw error.response;
  }
};

import React from "react";
import { NavLink } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import { Box, Stack, Typography } from "iiht-b2a-ui-components/build/components";
import { ReactSVG } from "react-svg";
import { mastersFieldsData } from "../recruitlistingJson";
import { MainLayout } from "components/common";
import { CustomBreadcrumb } from "components/common";

const Masters = () => {
  const breadcrumbs = [
    {
      label: "IIHT",
      link: "/dashboard",
    },
    {
      label: "HR",
      link: "/modules/hr",
    },
    {
      label: "Recruitment",
      link: "/modules/hr",
    },
    {
      label: "Masters",
      link: "",
    },
  ];

  return (
    <MainLayout>
      <div>
        <CustomBreadcrumb breadcrumbs={breadcrumbs} />
        <Stack direction="row" spacing={1} alignItems="center">
          <GridMenuIcon className="gridIcon" />
          <Typography color="#0D1021" fontSize={22}>
            Masters
          </Typography>
        </Stack>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }} className="mainPanel noPadding">
          <div className="MasterListButtons">
            {mastersFieldsData.map((field, index) => (
              <NavLink to={field.link} key={index}>
                <ReactSVG src={field.icon} className="mastIcon" />
                {field.name}
              </NavLink>
            ))}
          </div>
        </Box>
      </div>
    </MainLayout>
  );
};

export default Masters;

import React, { useState } from 'react';
import useStyles from './style';
import { useNavigate } from 'react-router-dom';
import { GridMenuIcon } from '@mui/x-data-grid';
import MainHeader from '../../../common/MainHeader';
import MainFooter from '../../../common/MainFooter';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveSidebar } from '../../../../slices/financeSlice';
import { createFeeCategory } from '../../../../modules/finance';
import { setLoading } from "../../../../slices/commonSlice";
import LoadingBackdrop from "../../../common/LoadingBackdrop.js";
import { toast } from 'react-toastify';

const AddNewFeeCategory = () => {
  const styles= useStyles();
  const navigate= useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [showAmountInput, setShowAmountInput] = useState(false);
  const [values, setValues] = useState({
    category_name: "",
    custom_add_amount: 0,
    custom_amount: "",
    description: "",
    active: 1
  });
  const [errors, setErrors] = useState({
    category_name: "",
    custom_add_amount: "",
    custom_amount: "",
    description: ""
  });

  const handleFeeCategoryNavigation = () => {
    dispatch(setActiveSidebar({ activeSidebar: 1 }));
    navigate("/modules/finance");
  };

  const handleAddAmount = ({ value }) => {
    setShowAmountInput(value);
    setValues({
      ...values,
      custom_add_amount: value ?  1 : 0,
      custom_amount: value ? values.custom_amount : ""
    });
    setErrors({
      ...errors,
      custom_add_amount: "",
      custom_amount: ""
    });
  };

  const handleValidateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!values.category_name.trim()) {
      newErrors.category_name = "Category name is required";
      isValid = false;
    } else {
      newErrors.category_name = "";
    }

    if (values.custom_add_amount === null) {
      newErrors.custom_add_amount = "Required";
      isValid = false;
    } else {
      newErrors.custom_add_amount = "";
    }

    if (values.custom_add_amount === 1) {
      if (!values.custom_amount.trim()) {
        newErrors.custom_amount = "Amount is required";
        isValid = false;
      } else if (isNaN(parseFloat(values.custom_amount.trim()))) {
        newErrors.custom_amount = "Please enter a valid amount";
        isValid = false;
      } else {
        newErrors.custom_amount = "";
      }
    }

    if (!values.description.trim()) {
      newErrors.description = "Description is required";
      isValid = false;
    } else {
      newErrors.description = "";
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSave = async () => {
    dispatch(setLoading({ loading: true }));
    const isValid = handleValidateForm();
    if(isValid) {
      const payload = {
        category_name: values.category_name,
        custom_add_amount: values.custom_add_amount,
        custom_amount: values.custom_amount,
        description: values.description,
        enabled: values.active
      };
      try {
        const res =  await createFeeCategory({ payload });
        dispatch(setLoading({ loading: false }));
        if(res.status === 200) {
          toast.success("created");
          // navigate(`/modules/finance/fee-category/${res.data.data.category_name}`);
          navigate(-1);
          // navigate("/modules/finance");
        } else {
          dispatch(setLoading({ loading: false }));
          toast.error("Error while creating fee category!")
        }
      } catch (error) {
        dispatch(setLoading({ loading: false }));
        toast.error(error.response.data?.exc_type || "Error while creating fee category!")
      }
    } else {
      dispatch(setLoading({ loading: false }))
    }
  }

  return (
    <>
      <MainHeader />
      {
        loading ?
          <LoadingBackdrop open={loading} /> :
          <div className={styles.mainContainer}>
            <div className={styles.breadCrumbsContainer}>
              <p className={styles.breadCrumbsContent} onClick={() => navigate("/dashboard")}>IIHT {" > "}</p>
              <p className={styles.breadCrumbsContent} onClick={() => navigate("/modules/finance")}>Finance {" > "}</p>
              <p className={styles.breadCrumbsContent} onClick={handleFeeCategoryNavigation}>Fee Category</p>
            </div>
            <div className={styles.headerContainer}>
              <div className={styles.breadCrumbsContainer}>
                <GridMenuIcon className={styles.gridIcon}/>
                <p className={styles.title}>New Fee Category</p>
              </div>
              <div className={styles.buttonsGroup}>
                <div className={styles.button} onClick={handleFeeCategoryNavigation}>
                  <p className={styles.buttonText}>Back</p>
                </div>
                <div className={styles.activeButton} onClick={handleSave}>
                  <p className={styles.activeButtonText}>Save</p>
                </div>
              </div>
            </div>
            <div className={styles.formContainer}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Fee Category Name *</p>
                <input
                  type="text"
                  placeholder="ex Library Fee"
                  className={styles.fieldInput}
                  value={values.category_name}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      category_name: e.target.value
                    });
                    setErrors({
                      ...errors,
                      category_name: ""
                    });
                  }}
                />
                {
                  errors.category_name &&
                  <p className={styles.errorMessage}>{errors.category_name}</p>
                }
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Add Amount </p>
                <div className={styles.checkboxContainer}>
                  <div className={styles.checkboxLabel}>
                    <input type="checkbox" className={styles.checkbox} checked={values.custom_add_amount == 1 ? true : false}  onClick={() => handleAddAmount({ value: !showAmountInput })}/>
                    <p className={styles.fieldLabel}>Yes</p>
                  </div>
                </div>
                {
                  errors.custom_add_amount &&
                  <p className={styles.errorMessage}>{errors.custom_add_amount}</p>
                }
              </div>
              {
                showAmountInput &&
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>Amount *</p>
                  <input
                    type="text"
                    placeholder="ex 1500"
                    className={styles.fieldInput}
                    value={values.custom_amount}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        custom_amount: e.target.value
                      });
                      setErrors({
                        ...errors,
                        custom_amount: ""
                      });
                    }}
                  />
                  {
                    errors.custom_amount &&
                    <p className={styles.errorMessage}>{errors.custom_amount}</p>
                  }
                </div>
              }
              <div className={styles.checkboxContainer}>
                  <div className={styles.checkboxLabel}>
                    <input type="radio" className={styles.checkbox} checked={values.active == 1} onChange={() => {
                      setValues({
                        ...values,
                        active: 1
                      })
                    }}/>
                    <p className={styles.fieldLabel}>Active</p>
                  </div>
                  <div className={styles.checkboxLabel}>
                    <input type="radio" className={styles.checkbox} checked={values.active == 0} onChange={() => {
                      setValues({
                        ...values,
                        active: 0
                      })
                    }}/>
                    <p className={styles.fieldLabel}>Inactive</p>
                  </div>
                </div>
              <div className={styles.textarea}>
                <p className={styles.fieldLabel}>Description *</p>
                <textarea
                  type="text"
                  placeholder="Enter Text here..."
                  className={styles.textAreaInput}
                  value={values.description}
                  rows={5}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      description: e.target.value
                    });
                    setErrors({
                      ...errors,
                      description: ""
                    });
                  }}
                />
                {
                  errors.description &&
                  <p className={styles.errorMessage}>{errors.description}</p>
                }
              </div>
            </div>
          </div>
      }
      <MainFooter />
    </>
  )
};

export default AddNewFeeCategory;
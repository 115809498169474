import { makeStyles } from "@mui/styles";
import { theme } from "../../../themes";

export const useStyles = makeStyles({
  tableCollapseRow: {
    background: `${theme.palette.common.gray5} 0% 0% no-repeat padding-box`,
  },
  iconColor: {
    fill: theme.palette.secondary.main,
  },
});

import React from "react";
import SideBar from "components/SideBar";
import Dashboard from "../../assets/images/icon-dashboard.svg";
import Recruitment from "../../assets/images/icon-master.svg";
import FeedbackTemplate from "components/Survey/Feedback";
import FeedbackResponse from "components/Survey/Response";
import FeedbackDashboard from "components/Survey/Dashboard";
import FeedbackAssignment from "components/Survey/FeedbackAssignment";
import Payroll from "../../assets/images/icon-report.svg";

const sidebarContent = [
  { label: "Dashboard", icon: Dashboard },
  { label: "Feedback Template", icon: Dashboard },
  { label: "Feedback Response", icon: Recruitment },
  { label: "Feedback Assignment", icon: Payroll },
  //   { label: "Document Management", icon: Recruitment },
  //   { label: "Leave Management", icon: LeaveManagement },
  //   { label: "Attendance", icon: Attendance },
  //   { label: "Payroll", icon: Payroll },
  //   { label: "Performance", icon: Performance },
];

const modules = [
  FeedbackDashboard,
  FeedbackTemplate,
  FeedbackResponse,
  FeedbackAssignment,
];

const FeedBackContainer = () => {
  return <SideBar sidebarContent={sidebarContent} modules={modules} />;
};

export default FeedBackContainer;

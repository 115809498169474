import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import ArrowDownSVG from "../../../../assets/images/arrow-down.svg";
import FiltersSVG from "../../../../assets/images/filters-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { Table } from "antd";
import { getAllStudentLeaveApplicationListing } from "modules/hr";
import MainHeader from "components/common/MainHeader";
import MainFooter from "components/common/MainFooter";

const StudentLeaveApplication = ({ setActiveModule }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [studentLeaveList, setStudentLeaveList] = useState([]);

  //   const sortedDataSource = [...studentLeaveList].sort((a, b) => {
  //     return b.leave_id.localeCompare(a.leave_id);
  //   });

  const handleGetAllStudentLeaveApplication = async () => {
    const res = await getAllStudentLeaveApplicationListing();
    console.log(res);
    if (res.status === 200) {
      const studentList = [];
      for (let i = 0; i < res.data.message.length; i++) {
        studentList.push({
          key: res.data.message[i].id,
          student_name: res.data.message[i].student_name,
          from_date: res.data.message[i].from_date,
          to_date: res.data.message[i].to_date,
          leave_id: res.data.message[i].id,
        });
      }
      setStudentLeaveList(studentList);
    } else {
      console.log("Something went wrong while getting employee data");
    }
  };

  const columns = [
    {
      dataIndex: "student_name",
      title: "STUDENT NAME",
      width: "25%",
      render: (student_name) => (
        <p className={styles.tableContentFields}>{student_name}</p>
      ),
    },
    {
      dataIndex: "from_date",
      title: "From Date",
      width: "15%",
      render: (from_date) => (
        <p className={styles.tableContentFields}>{from_date}</p>
      ),
    },
    {
      dataIndex: "to_date",
      title: "To Date",
      width: "15%",
      render: (to_date) => (
        <p className={styles.tableContentFields}>{to_date}</p>
      ),
    },
    {
      dataIndex: "leave_id",
      title: "Leave ID",
      width: "25%",
      render: (leave_id) => (
        <p className={styles.tableContentFields}>{leave_id}</p>
      ),
    },
    {
      dataIndex: "id",
      title: "Action",
      width: "15%",
      render: (id, row) => (
        <p
          className={styles.tableContentView}
          onClick={() =>
            navigate(
              `/modules/academics/student-leave-application/${row.leave_id}`
            )
          }
        >
          View
        </p>
      ),
    },
  ];

  useEffect(() => {
    handleGetAllStudentLeaveApplication();
  }, []);

  return (
    <>
      <MainHeader />{" "}
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/modules")}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/dashboard")}
            >
              Modules {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/modules/academics")}
            >
              Academics {" > "}
            </p>
            <p className={styles.breadCrumbsContent}>
              Student Leave Application
            </p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>Student Leave Application</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div className={`${styles.button} ${styles.filterButton}`}>
                <ReactSVG src={FiltersSVG} />
                <p className={styles.buttonText}>Filters</p>
                <ReactSVG src={ArrowDownSVG} />
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>Saved Filters</p>
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>...</p>
              </div>
              <div
                className={styles.activeButton}
                onClick={() =>
                  navigate(
                    "/modules/academics/student-leave-application/add-new-student-leave-application"
                  )
                }
              >
                <p className={styles.activeButtonText}>
                  Add Student Leave Application
                </p>
              </div>
            </div>
          </div>
          <Table
            rowSelection={{ type: "checkbox" }}
            columns={columns}
            dataSource={studentLeaveList}
            size="small"
            pagination={true}
          />
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default StudentLeaveApplication;

import React, { useState, useEffect } from "react";
import { GridMenuIcon } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { getStudentList } from "../../../modules/studentGroup";
import useStyles from "./style";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import ArrowDownSVG from "../../../assets/images/arrow-down.svg";
import FiltersSVG from "../../../assets/images/filters-icon.svg";
import { ReactSVG } from "react-svg";
import { setLoading } from "slices/commonSlice";

const StudentGroupComponent = ({ setActiveModule }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState([]);
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.common);

  const handleGetStudentListing = async () => {
    dispatch(setLoading({ loading: true }));
    const res = await getStudentList();
    if (res.status === 200) {
      dispatch(setLoading({ loading: false }));
      console.log(res);
      const studentList = [];
      for (let i = 0; i < res.data.data.length; i++) {
        studentList.push({
          key: res.data.data[i].name,
          group_name: res.data.data[i].name,
          group_id: res.data.data[i].name,
          academic_year: res.data.data[i].academic_year,
          group_based_on: res.data.data[i].group_based_on,
          status: res.data.data[i].disabled === 0 ? "Active" : "Inactive",
        });
      }
      setRowData(studentList);
    } else {
      dispatch(setLoading({ loading: false }));
      console.log("Something went wrong while getting student data");
    }
  };

  const columns = [
    {
      dataIndex: "group_name",
      title: "Group Name",
      width: 1,
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.group_name}</p>;
      },
    },
    {
      dataIndex: "group_id",
      title: "Group ID",
      width: 1,
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.group_id}</p>;
      },
    },
    {
      dataIndex: "academic_year",
      title: "Academic Year",
      width: 1,
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.academic_year}</p>;
      },
    },

    {
      dataIndex: "group_based_on",
      title: "Group Based On",
      width: 1,
      render: (val, row) => {
        return (
          <p className={styles.tableContentFields}>{row.group_based_on}</p>
        );
      },
    },
    {
      dataIndex: "status",
      title: "STATUS",
      width: 0.8,
      render: (val, row) => {
        return (
          <p
            className={
              row.status === "Inactive"
                ? styles.buttonPublish
                : styles.buttonUnPublish
            }
          >
            {row.status}
          </p>
        );
      },
    },
    {
      dataIndex: "action",
      title: "ACTION",
      width: 0.5,
      render: (val, row) => {
        return (
          <p
            className={styles.tableContentView}
            onClick={() => navigate(`student-group/${row?.group_name}`)}
          >
            View
          </p>
        );
      },
    },
  ];

  useEffect(() => {
    handleGetStudentListing();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                setActiveModule(0);
              }}
            >
              Academics {" > "}
            </p>
            <p className={styles.breadCrumbsContent}>Student Group</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>Student Group</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div className={`${styles.button} ${styles.filterButton}`}>
                <ReactSVG src={FiltersSVG} />
                <p className={styles.buttonText}>Filters</p>
                <ReactSVG src={ArrowDownSVG} />
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>Saved Filters</p>
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>...</p>
              </div>
              <div
                className={styles.activeButton}
                onClick={() =>
                  navigate("/modules/academics/add-new-student-group")
                }
              >
                <p className={styles.activeButtonText}>+ Add Student Group</p>
              </div>
            </div>
          </div>
          <Table
            rowSelection={{ type: "checkbox" }}
            columns={columns}
            dataSource={rowData}
            size="small"
            pagination={true}
          />
        </div>
      )}
    </>
    // <Box sx={{ flexGrow: 1, p: 3 }}>
    //   <Grid container spacing={3} sx={{ width: "100%" }}>
    //     <div className="headingBtns">
    //       <Typography variant="h5" className="mainHeading">
    //         <GridMenuIcon />
    //         Student Group
    //       </Typography>
    //       <div className="btnsGrp">
    //         <Button
    //           variant="outlined"
    //           startIcon={<FilterListIcon />}
    //           endIcon={<ArrowDropDown />}
    //         >
    //           Filters
    //         </Button>
    //         <Button variant="outlined">Saved Filters</Button>
    //         <Button variant="outlined">...</Button>
    //         <Button
    //           variant="outlined"
    //           onClick={handleStudentGroup}
    //           className="redBtn"
    //         >
    //           + Add Student Group
    //         </Button>
    //       </div>
    //     </div>
    //     <Box sx={{ width: "100%" }}>
    //       <DataGrid
    //         rowHeight={70}
    //         className="mainTable"
    //         style={{ width: "100%", height: "100%" }}
    //         columns={columns}
    //         rows={rowData}
    //         disableSelectionOnClick
    //         checkboxSelection
    //         onSelectionModelChange={(newRowSelectionModel) => {
    //           setRowSelectionModel(newRowSelectionModel.selectionModel);
    //         }}
    //         selectionModel={rowSelectionModel}
    //       />
    //     </Box>
    //   </Grid>
    // </Box>
  );
};

export default StudentGroupComponent;

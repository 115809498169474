import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "./index.scss";
import { Button } from "iiht-b2a-ui-components/build/components";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function FormInstructions({ handleNext, handleBack }) {
  const handleSave = () => {
    handleNext({ payload: {} , addData: false });
  };

  return (
    <>
      <Box sx={{ boxShadow: "none" }} className="formDashboard">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item className="mainPanel">
              <h3>General Instructions</h3>
              <ol>
                <li>There is no application fee
                </li>
                <li>The application form should be filled only in the English language
                </li>
                <li>All fields marked by the * sign are mandatory. You will not be able to submit the application form unless all these fields have been duly filled
                </li>
                <li>Please add the email address 'admissions@IIHT.edu.in' to your inbox address book so that the mails you receive from this email address are not directed to your spam folder
                </li>
                <li>If you are unable to find a suitable response in the drop-down options for any question in the application form, please click on the option 'Other' and write your response in the text box
                </li>
                <li>You can move from one section to the next only after you have filled all the mandatory fields in the section.You will be able to revisit these filled mandatory fields to edit them later on
                </li>
                <li>Once you submit your application form, please note that we will not accept any changes to the details mentioned by you in the application form
                </li>
                <li>Please click on <a href="#">this link</a> to access an instruction manual on how to fill your application
                </li>
              </ol> 

              <div className="notes">
                Please note that the information shared by you will be kept confidential and not shared publicly. However, selective information might be shared with certain Offices and Departments within IIHT University on a case-by-case basis for internal official purposes.
              </div>
              <p>In case you have any questions related to your application form before you submit the form, please write to us at <a href="ugapply@IIHT.edu.in" target="_blank">ugapply@IIHT.edu.in</a> .</p>
            </Item>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "row", pt: 2, justifyContent: "space-between" }}
        className="navBottomBtns"
      >
        <div style={{ display: "flex", gap: "12px"}}>
          <Button
            color="inherit"
            sx={{ mr: 1 }}
            className="backBtn"
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            onClick={() => {
              handleSave();
            }}
            sx={{ mr: 1 }}
          >
            Save
          </Button>
        </div>
        <Button sx={{ mr: 1 }} onClick={() => handleSave()}> 
          Next
        </Button>
      </Box>
    </>
  );
}

import APIs from "../api";
import axiosInstance from "../translator";

export const login = async ({ payload }) => {
  try {
    const res = await axiosInstance.post(APIs.login, payload);
    return res;
  } catch (error) {
    console.log("Login API Error ", error);
    return error.response;
  }
};

export const forgotpassword = async ({ payload }) => {
  try {
    const res = await axiosInstance.post(APIs.forgotpassword, payload);
    return res;
  } catch (error) {
    console.log("ForgotPassword API Error ", error);
  }
};

export const resetpassword = async ({ payload }) => {
  try {
    const res = await axiosInstance.post(APIs.resetpassword, payload);
    return res;
  } catch (error) {
    console.log("ForgotPassword API Error ", error);
  }
};

import React, { useState, useEffect } from 'react';
import { Select, Button, Input, Modal } from 'antd';
import { Typography } from '@mui/material';
import useStyles from "./JobApplicationForm.js";
import { JobApply } from '../../../modules/hr.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UploadButton from '../../common/UploadButton/index.js';


const JobApplicationForm = ({details}) => {
  const { id, designation } = details;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [profile, setProfile] = useState('');
  const [experience, setExperience] = useState('');
  const [joining, setJoining] = useState('');
  const [ctc, setCTC] = useState('');
  const [location, setLocation] = useState('');
  const [expectedCTC, setExpectedCTC] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [flag, setFlag] = useState(false);
  const [formData, setFormData] = useState({
    fileUploadPath: ""
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    experience: "",
    joining: "",
    ctc: "",
    location: "",
    expectedCTC: "",
    fileUploadPath: "",
  });
  const styles = useStyles();



  const handleSubmit = async () => {

    const isEmailValid = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const isPhoneNumberValid = (phoneNumber) => {
      const phoneRegex = /^\d{10}$/;
      return phoneRegex.test(phoneNumber);
    };

    const validationErrors = {};
    if (!firstName) {
      validationErrors.firstName = "First Name is Required";
    }
    if (!lastName) {
      validationErrors.lastName = "Last Name is Required";
    }
    if (!email) {
      validationErrors.email = "Email is Required";
    } else if (!isEmailValid(email)) {
      validationErrors.email = "Please Enter Valid Email";
    }
    if (!phoneNumber) {
      validationErrors.phoneNumber = "Phone Number is Required";
    } else if (!isPhoneNumberValid(phoneNumber)) {
      validationErrors.phoneNumber = "Please Enter Valid Phone Number";
    }
    // if (!profile) {
    //   validationErrors.profile = "Profile is Required";
    // }
    if (!experience) {
      validationErrors.experience = "Experience is Required";
    }
    if (!joining) {
      validationErrors.joining = "Joining Date is Required";
    }
    if (!ctc) {
      validationErrors.ctc = "Current CTC is Required";
    }
    if (!location) {
      validationErrors.location = "Location is Required";
    }
    if (!expectedCTC) {
      validationErrors.expectedCTC = "Expected CTC is Required";
    }
    if (!formData.fileUploadPath) {
      validationErrors.fileUploadPath = "Please upload your resume";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setErrors({});
    const payload = {
      doctype:"Job Applicant",
      upload_resume: formData.fileUploadPath,
      first_name: firstName,
      experience: experience,
      job_opening: id,
      applicant_name:`${firstName} ${lastName}`,
      email_id: email,
      how_soon_can_you_join_us: joining,
      phone_number: phoneNumber,
      relocation: location,
      designation: designation,
      expected_ctc: expectedCTC,
      last_name: lastName,
      current_ctc: ctc,
    };

    try {
      const res = await JobApply(payload);
      if (res.status === 200) {
        toast.success("Application submitted successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhoneNumber('');
        setProfile('');
        setExperience('');
        setJoining('');
        setCTC('');
        setLocation('');
        setExpectedCTC('');
        setErrors({});
        setIsModalOpen(false);
        setFormData({ fileUploadPath: "" });
      }
    } catch (error) {
      console.error("Error submitting application:", error);
      toast.error("Failed to submit application. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleOutstandingInvoices = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
      setIsModalOpen(false);
      setFirstName('');
      setLastName('');
      setEmail('');
      setPhoneNumber('');
      setProfile('');
      setExperience('');
      setJoining('');
      setCTC('');
      setLocation('');
      setExpectedCTC('');
      setErrors({});
      setIsModalOpen(false);
      setFormData('');
  };

  return (
    <>
    <Modal
            title="APPLY NOW"
            open={isModalOpen}
            onCancel={handleCancel}
            footer={[
              <div className={styles.row} key="footerButtons">
                <div className={styles.button} onClick={handleCancel}>
                  <p className={styles.buttonText}>Cancel</p>
                </div>
                <div className={styles.activeButton}>
                  <p className={styles.activeButtonText} onClick={handleSubmit}>
                    Submit
                  </p>
                </div>
              </div>,
            ]}
          >
      <form onSubmit={handleSubmit} style={{padding : '18px'}}>
          <div className={styles.formContainer}>
            <div className={styles.formItem}>
              <Typography htmlFor="firstName">First Name*</Typography>
              <Input
                type="text"
                id="firstName"
                name="firstName"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                required
                className={styles.input}
              />
              {errors.firstName && <p className={styles.errorMessage}>{errors.firstName}</p>}
            </div>
            <div>
              <Typography htmlFor="lastName">Last Name *</Typography>
              <Input
                type="text"
                id="lastName"
                name="lastName"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                className={styles.input}
              />
              {errors.lastName && <p className={styles.errorMessage}>{errors.lastName}</p>}
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formItem}>
              <Typography htmlFor="email">Email*</Typography>
              <Input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                required
                className={styles.input}
              />
              {errors.email && <p className={styles.errorMessage}>{errors.email}</p>}
            </div>
            <div>
              <Typography htmlFor="phoneNumber">Phone Number *</Typography>
              <Input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={phoneNumber}
                onChange={(event) => setPhoneNumber(event.target.value)}
                className={styles.input}
              />
              {errors.phoneNumber && <p className={styles.errorMessage}>{errors.phoneNumber}</p>}
            </div>
          </div>
          <div className={styles.formItems}>
            <Typography htmlFor="profile">Select Profile *</Typography>
            <Input
              id="profile"
              name="profile"
              value={designation}
              disabled
              // onChange={(value) => setProfile(value)}
            >
            </Input>
            {errors.profile && <p className={styles.errorMessage}>{errors.profile}</p>}
          </div>
          <div className={styles.formItems}>
            <Typography htmlFor="experience">How many years of experience do you have? *</Typography>
            <Input
              id="experience"
              name="experience"
              value={experience}
              placeholder='0 year, 0.5 year, 1 year, 2+ years, 3+ years...'
              onChange={(event) => setExperience(event.target.value)}
              className={styles.input}
            />
            {errors.experience && <p className={styles.errorMessage}>{errors.experience}</p>}
          </div>
          <div className={styles.formItems}>
            <Typography htmlFor="joining">How soon can you join us? *</Typography>
              <Input
              id="joining"
              name="joining"
              value={joining}
              placeholder='Tomorrow, 1 week, 2 weeks, 20 days, 45 days etc'
              onChange={(event) => setJoining(event.target.value)}
              className={styles.input}
            />
            {errors.joining && <p className={styles.errorMessage}>{errors.joining}</p>}
          </div>
          <div className={styles.formItems}>
            <Typography htmlFor="location">Where are you based at present? Are you ready to relocate? *</Typography>
            <Input
              type="text"
              id="location"
              name="location"
              placeholder='Yes Or No'
              value={location}
              onChange={(event) => setLocation(event.target.value)}
              className={styles.input}
            />
            {errors.location && <p className={styles.errorMessage}>{errors.location}</p>}
          </div>
          <div className={styles.formItems}>
            <Typography htmlFor="ctc">What is your current CTC? *</Typography>
            <Input
              id="ctc"
              name="ctc"
              value={ctc}
              placeholder='0, 10 LPA, 60k per month, 20 LPA etc'
              onChange={(event) => setCTC(event.target.value)}
              className={styles.input}
            />
            {errors.ctc && <p className={styles.errorMessage}>{errors.ctc}</p>}
          </div>
          <div className={styles.formItems}>
            <Typography htmlFor="expectedCTC">What is your expected CTC? *</Typography>
            <Input
              id="expectedCTC"
              name="expectedCTC"
              value={expectedCTC}
              placeholder='NA, x LPA'
              onChange={(event) => setExpectedCTC(event.target.value)}
              className={styles.input}
            />
            {errors.expectedCTC && <p className={styles.errorMessage}>{errors.expectedCTC}</p>}
          </div>
          <div className={styles.formItem}>
            <Typography htmlFor="upload">Upload Resume *</Typography>
            <UploadButton  formData={formData}
            setFormData={setFormData}
            fieldName="fileUploadPath"
            flag={flag}
            setFlag={setFlag}
            fileTypes={["PDF"]}></UploadButton>
            {errors.fileUploadPath && <p className={styles.errorMessage}>{errors.fileUploadPath}</p>}
            {formData.fileUploadPath && formData.fileUploadPath.split(".")[1] === "pdf" && (
              <p style={{ color: "black", textDecoration: "none", cursor: "pointer", fontSize: "12px" }} onClick={() => window.open(`https://erp-dev.techademycampus.com${formData.fileUploadPath}`, "_blank")}>{formData.fileUploadPath.split("/")[2]}</p>
            )}
            <span>Max. size 400KB</span>
          </div>
          
    </form>
    
    </Modal>
    <Button className={styles.applyButton} onClick={handleOutstandingInvoices}>Apply Now</Button>
    </>
  );
};

export default JobApplicationForm;

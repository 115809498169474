import React, { useState,useEffect } from 'react';
import { formData } from './RecruitmentJson';
import "./RecruitmentStyle.css";
import GeneralInstruction from "./GeneralInstructionPage"
import UploadButton from '../common/UploadButton';
import { Select, Button, Input } from "antd";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { RemoveIconCircle } from "../../icons";
import Box from "@mui/material/Box";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ReactSVG } from "react-svg";
import BackArrowSvg from "../../assets/images/backArrow.svg";
import NextArrowSvg from "../../assets/images/nextArrow.svg";


const TextBox2 = ({ field, data, rowIndex }) => {
    const { label, name, headers } = field;
    const rows = data[`${name}`] || []; // Ensure rows is an array
  
    return (
      <>
        {Array.isArray(rows) &&
          rows.map((rowValue, idx) => (
            <div className="parentsSection" key={idx}>
              <h4>
                {label}-{idx}
              </h4>
              <div className="fullWidthFields">
                {headers.map((header) =>
                  header.type === "col-break" ? (
                    <div key={`col-break-${idx}`} className="col-break">
                      &nbsp;
                    </div>
                  ) : header.type === "heading" ? (
                    <h4 key={`heading-${idx}`}>{header.label}</h4>
                  ) : (
                    <div key={header.key} className="form-field">
                      <label>{header.key}</label>
                      {header.type === "textarea" ? (
                        <textarea
                          name={`${name}_${header.key}_${idx}`}
                          placeholder={header.key}
                          value={rowValue[header.value] || ""}
                        />
                      ) : (
                        <Input
                          type="text"
                          name={`${name}_${header.key}_${idx}`}
                          placeholder={header.key}
                          value={rowValue[header.value] || ""}
                        />
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
          ))}
      </>
    );
  };
  
  const TextBox = ({ field, data }) => {
    const { label, fields, placeholder } = field;
    return (
      <>
        {field?.dependantField ? (
          <div className="form-field">
            <label dangerouslySetInnerHTML={{ __html: field.label }} />
            <Input
              type="text"
            //   name={name}
              placeholder={placeholder}
            //   value={data[name].length}
            />
          </div>
        ) : (
          <div className="form-field">
            <label dangerouslySetInnerHTML={{ __html: field.label }} />
            <Input
              type="text"
            //   name={name}
              placeholder={placeholder}
            //   value={data[name]}
            />
          </div>
        )}
      </>
    );
  };

// const TextBox = ({ field, data }) => {
//     const { label, name, placeholder } = field;
//     return (
//       <div className="form-field">
//         <label dangerouslySetInnerHTML={{ __html: field.label }} />
//         <input
//           type="text"
//           name={name}
//           placeholder={placeholder}
//         />
//       </div>
//     );
// };

  
  const EncryptedTextBox = ({ field, data }) => {
    const { label, fields, name, placeholder } = field;
    return (
      <div className="form-field">
        <label dangerouslySetInnerHTML={{ __html: field.label }} />
        <Input
          type="text"
          name={name}
          placeholder={placeholder}
          value={`********${data[name].substr(-4)}`}
        />
      </div>
    );
  };

  const Upload = ({field,data}) => {
    const { label,name } = field;
    return(
        <div className="form-field">
            <label dangerouslySetInnerHTML={{__html: label}} />
            <UploadButton />
        </div>
    );
  }
  
  const Image = ({ field, data }) => {
    const { label, name } = field;
    return (
      <div className="form-field">
        <label dangerouslySetInnerHTML={{ __html: label }} />
        <img
          src={`https://erp-dev.techademycampus.com${data[name]}`} // Assuming data[name] contains the image URL
          alt={label}
          style={{ width: "150px", height: "150px" }} // Adjust styling as needed
        />
      </div>
    );
  };
  
  const TextArea = ({ field, data }) => {
    const { label, name, placeholder } = field;
    return (
      <div className="form-field fullWidthFields">
        <label dangerouslySetInnerHTML={{ __html: field.label }} />
        <textarea name={name} placeholder={placeholder} value={data[name]} />
      </div>
    );
  };
  
  const SelectBox = ({ field }) => {
    const { label, name, options } = field;
    
    const handleSelectChange = (event) => {
      const selectedOption = event.target.value;
      // You can handle the selected option here
    };
  
    // Dummy data for options
    const dummyOptions = ["Option 1", "Option 2", "Option 3"];
  
    return (
      <div className="form-field ">
        <label>{label}</label>
        <Select name={name} onChange={handleSelectChange} style={{width:"100%", height:'40px'}}>
          {dummyOptions.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </Select>
      </div>
    );
  };

  const Labels = ({field}) => {
    const { label } = field;
    return(
        <label className='underline'>{label}</label>
    );
  }
  const Labels1 = ({field}) => {
    const { label } = field;
    return(
        <label>{label}</label>
    );
  }
  
  const EmailInputBox = ({ field, data }) => {
    const { label, name, placeholder } = field;
    return (
      <div className="form-field">
        <label>{label}</label>
        <Input
          type="email"
          name={name}
          placeholder={placeholder}
          value={data[name]}
        />
      </div>
    );
  };
  
  const RadioBox = ({ field, data }) => {
    const { label, name, options } = field;
    return (
      <div className="form-field">
        <label>{label}</label>
        <div className="radio-option">
          <span>
            <Input
              type="radio"
              id="yes"
            //   name={name}
              value="yes"
            //   checked={data[name] == 1}
              style={{ accentColor: "#A32930" }}
            />
            <label htmlFor="Yes">Yes</label>
          </span>
          <span>
            <Input
              type="radio"
              id="no"
            //   name={name}
              value="no"
            //   checked={data[name] == 0}
              style={{ accentColor: "#A32930" }}
            />
            <label htmlFor="No">No</label>
          </span>
        </div>
      </div>
    );
  };

  const RadioBox2 = ({ field, data, fieldsToShow }) => {
    const { label } = field;
    const [selection, setSelection] = useState('');
    const handleRadioChange = (event) => {
      const selectedValue = event.target.value;
      setSelection(selectedValue);
    };
  
    return (
      <>
        <div className="form-field">
          <label>{label}</label>
          <div className="radio-option">
            <span>
              <Input
                type="radio"
                id="yes"
                value="yes"
                onChange={handleRadioChange}
                style={{ accentColor: '#A32930' }}
              />
              <label htmlFor="yes">Yes</label>
            </span>
            <span>
              <Input
                type="radio"
                id="no"
                value="no"
                onChange={handleRadioChange}
                style={{ accentColor: '#A32930' }}
              />
              <label htmlFor="no">No</label>
            </span>
          </div>
        </div>
        {selection === 'yes' && (
          <div className="fields-container">
            <div className="fields-block">
              {field.fields.map((sectionField, sectionIndex) => (
                <React.Fragment key={sectionIndex}>
                  {sectionField.type === 'col-break' ? (
                    <div className="col-break" key={sectionIndex}>&nbsp;</div>
                  ) : (
                    renderFieldByType(sectionField)
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
      </>
    );
  };

  const Button1 = ({field}) => {
    const { name } = field;
    return(
      <Button
        className="jsonButton"
      >
        {name}
      </Button>
    );
  };
  
  
  
  const Para = ({ field, data }) => {
    const { label, name } = field;
    return (
      <div className="form-field fullWidthFields">
        <label>{label}</label>
        <p>{data[name]}</p>
      </div>
    );
  };
  
  const Checkbox = ({ field, data }) => {
    const { label, name, options } = field;
    return (
      <div className="form-field fullWidthFields">
        <label>{label}</label>
        {options.map((option) => (
          <div key={option.key} className="formCheckbox">
            <Input
              type="checkbox"
              id={`${name}_${option.key}`}
            //   name={option.value}
            //   checked={data[option.value] == 0 ? false : true}
              style={{ accentColor: "#A32930" }}
            />
            <label htmlFor={`${name}_${option.key}`}>{option.key}</label>
          </div>
        ))}
      </div>
    );
  };
  
  const Table = ({ field }) => {
    const { headers, minRows } = field;
    const numRows = minRows || 5; 
  
    return (
      <div className="formTableWraps">
        <table className="formTable">
          <thead className='formTableHeader'>
            <tr>
              {headers.map((header) => (
                <th key={header.key} style={{ width: getWidthForHeader(header.key) }}>
                  {header.key}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {[...Array(numRows)].map((_, idx) => (
              <tr key={idx}>
                {headers.map((header) => (
                  <td key={header.key} style={{ width: getWidthForHeader(header.key) }}>
                    {/* Render blank cell */}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  
  const Table1 = ({ field, data, rowIndex }) => {
    const { headers, minRows } = field;
    const [numRows, setNumRows] = useState(minRows || 5);
  
    const handleAddRow = () => {
      setNumRows(numRows + 1);
    };
  
    const handleRemoveRow = () => {
      if (numRows > 0) {
        setNumRows(numRows - 1);
      }
    };
  
    return (
      <div className="formTableWrap">
        <table className="formTable">
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={header.key} style={{ width: getWidthForHeader(header.key) }}>
                  {header.key}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {[...Array(numRows)].map((_, idx) => (
              <tr key={idx}>
                {headers.map((header) => (
                  <td key={header.key} style={{ width: getWidthForHeader(header.key) }}>
                    {/* Render input elements */}
                    {header.key === "Start Date" || header.key === "End Date" ? (
                      <input
                        type="date"
                        name={`${header.key}_${idx}`}
                      />
                    ) : (
                      <input
                        type="text"
                        name={`${header.key}_${idx}`}
                        placeholder={header.key}
                      />
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="dynamicFieldbutton">
            <div className='minusButton'>
                    <IconButton
                      onClick={handleRemoveRow}
                      style={{ color: 'white', borderRadius: '4px' }}
                    >
                      <RemoveIconCircle />
                    </IconButton>
            </div>  
            <div className='addButton'>     
                    <IconButton onClick={handleAddRow} >
                      <AddIcon style={{ color: 'white', background: 'darkred', borderRadius: '4px', }} />
                    </IconButton>
            </div>
        </div> 
      </div>
    );
  };
  
  
  

  
  

  const getWidthForHeader = (header) => {
    if (header === "No." || header === "S.no" ) {
      return "2%";
    } else if (header === "Read" || header === "Write" || header === "Speak") {
      return "15%";
    }  else {
      return "10%";
    }
  };


const RecruitmentFormData = () => {
  const [fieldsToRender, setFieldsToRender] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [activeStep, setActiveStep] = React.useState(0);
  const [validSteps, setValidSteps] = React.useState([0]);

  const handleSetFields = (fields) => {
    setFieldsToRender(fields);
  }

  useEffect(() => {
    // Set initial fields to render
    handleSetFields(formData[activeStep].fields);
  }, [activeStep]);

  const totalSteps = () => {
    return formData.length;
  };

  const handleNextStep = () => {
    // if (activeStep < totalSteps()) {
      // if (activeStep === 0 || validSteps.includes(activeStep)) {
        setActiveStep(activeStep + 1);
      // }
    // }
  };

  const handlePrevStep = () => {
    // if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    // }
  };
  

  const handleStep = (step) => {
    setActiveStep(step);
  };

  const goToPreviousPage = () => {
    if (currentPage > 0) {
      const previousPage = currentPage - 1;
      setCurrentPage(previousPage);
      const previousFields = formData[previousPage].fields;
      handleSetFields(previousFields);
      setActiveStep(previousPage);
    }
  };
  

  const goToNextPage = () => {
    if (currentPage < formData.length - 1) {
      setCurrentPage(currentPage + 1);
      const nextFields = formData[currentPage + 1].fields; 
      handleSetFields(nextFields);
      setActiveStep(currentPage + 1); 
    }
  };

  return (
    <>
     <div style={{ padding: "40px 40px", display: "flex", flexDirection: "column", gap: "28px"}}>
       {activeStep === 0 && fieldsToRender.length > 0 && fieldsToRender[0].componentToRender === 'general' && (
          <GeneralInstruction />
        )}
    <div>
        <div className="arrow-container">
          <ReactSVG
            src={BackArrowSvg}
            className="arrows-icon"
            onClick={() => {
              if (activeStep > 0) {
                handlePrevStep();
              }
            }}
          />
          <div className="steps-container">
            {formData.slice(activeStep, activeStep + 4).map((data, index) => {
                const { icon, name, fields } = data;
                return (
                    <div
                        className="step-item-container"
                        onClick={() => {
                        if (validSteps.includes(activeStep + index)) {
                            handleStep(activeStep + index);
                            handleSetFields(fields);
                        }
                        }}
                    >
                        <div
                        className={`step-icon ${
                            validSteps.includes(activeStep + index) ? "success" : "current"
                        }`}
                        >
                        <img src={icon} alt=""/>
                        </div>
                        {name}
                    </div>
                );
            })}
        </div>
        <ReactSVG
            src={NextArrowSvg}
            className="arrows-icon"
            onClick={() => {
              if (activeStep !== totalSteps()) {
                handleNextStep();
              }
            }}
          />
          </div>
        </div>
        <div className="formApplication">
          {fieldsToRender.map((field, index) => (
            <React.Fragment key={index}>
              <section className="form-section">
                {field.type === "section" && (
                    <>
                  <h3 dangerouslySetInnerHTML={{ __html: field.label }} />
                  <hr className='marginLine'></hr>
                  </>
                )}
                <div className="fields-block">
                  {field.fields.map((sectionField, sectionIndex) => (
                    <React.Fragment key={sectionIndex}>
                      {sectionField.type === "col-break" ? (
                        <div className="col-break" key={sectionIndex}>
                          &nbsp;
                        </div>
                      ) : (
                        renderFieldByType(sectionField)
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </section>
            </React.Fragment>
          ))}
          <Box
        sx={{ display: "flex", flexDirection: "row", pt: 2, justifyContent: "space-between" }}
        className="navBottomBtn"
      >
        <div style={{ display: "flex", gap: "12px" }}>
          <Button
            color="inherit"
            sx={{ mr: 1 }}
            className="backBtn"
            onClick={goToPreviousPage}
          >
            Back
          </Button>
          <Button
            onClick={() => {
            //   handleSave();
            goToNextPage()
            }}
            sx={{ mr: 1 }}
          >
            Save
          </Button>
        </div>
        <Button sx={{ mr: 1 }} onClick={goToNextPage}>
          Next
        </Button>
      </Box>
       </div>
       </div>
    </>
  )
}

const ComponentToRender = ({ data, field }) => {
    switch (field.componentToRender) {
      case "general":
        return <GeneralInstruction />;
    //   case "events":
    //     return <Event uniqueId={data.name} />;
    //   case "emails":
    //     return <Email uniqueId={data.name} />;
    //   case "attachments":
    //     return <ApplicationDocs uniqueId={data.name} />;
    //   case "payment":
    //     return <Payment uniqueId={data.name} />;
      default:
        return null;
    }
  };

const renderFieldByType = (field, data) => {
    switch (field.type) {
      case "text":
        return <TextBox field={field} />;
      case "text2":
        return <TextBox2 field={field} />;
      case "encrypted": 
        return <EncryptedTextBox field={field}  />;
      case "select":
        return <SelectBox field={field}  />;
      case "email":
        return <EmailInputBox field={field} />;
      case "textarea":
        return <TextArea field={field} />;
      case "radio":
        return <RadioBox field={field} />;
      case "radio2":
        return <RadioBox2 field={field} />;
      case "table":
        return <Table field={field} />;
      case "table1":
        return <Table1 field={field} />;
      case "checkbox":
        return <Checkbox field={field} />;
      case "p":
        return <Para field={field} />;
      case "image":
        return <Image field={field} />;
        case "upload":
        return <Upload field={field} />;
      case "component":
        return <ComponentToRender data={data} field={field} />;
      case "label":
        return <Labels field={field}/>;
      case "label1":
        return <Labels1 field={field}/>;
      case "button":
        return <Button1 field={field}/>;
      default:
        return null;
    }
  };

export default RecruitmentFormData;
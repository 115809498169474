import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  logoContainer: {
    padding: "25px 40px",
    zIndex: "999",
    position: "relative",
    background: "#fff",
  },
  enquiryFormContainer: {
    position: "absolute",
    padding: "20px 32px",
    backgroundColor: "#F9D290",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    zIndex: 1,
    marginTop: "1%",
    right: "2%",
  },
  backgroundContainer: {
    position: "relative",
    height: "100vh",
    marginTop: "-13vh",
    display: "contents",
  },
  backgroundImage: {
    width: "100%",
    height: "750px",
    objectFit: "cover",
  },
  // Media Query for Small Screens (example: screens less than 600px wide)
  "@media (max-width: 600px)": {
    enquiryFormContainer: {
      right: "auto",
      top: "auto",
      width: "96%",
      margin: "0 2%",
      padding: "20px",
    },
  },
  "@media (min-width: 600px)": {
    enquiryFormContainer: {
      width: "60%",
    },
  },
  "@media (min-width: 1000px)": {
    enquiryFormContainer: {
      width: "45%",
    },
  },
  "@media (min-width: 1200px)": {
    enquiryFormContainer: {
      width: "30%",
    },
  },
}));

export default useStyles;

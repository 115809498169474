// HRDashboard.js
import { Box, Button } from "@mui/material";
import { GridMenuIcon } from "@mui/x-data-grid";
import { Breadcrumbs } from "components";
import React from "react";
const HRDashboard = () => {
  return (
    <>
    <Breadcrumbs/>
      <Box
        component="div"
        sx={{ flexGrow: 1, p: 3 }}
        className="mainPanel noPadding"
      >
        <div className="master-heading">
          <h2>
            <GridMenuIcon />
            {`HR Dashboard Details`}
          </h2>
          <div className="btnsGrp">
            <Button variant="outlined">Back</Button>
          </div>
        </div>
      </Box>
    </>
  );
};

export default HRDashboard;

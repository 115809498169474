import * as React from 'react';

const UserTickSvg = ({ fill, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={rest?.height}
    viewBox={rest?.viewBox}
    width={rest?.width}
    fill={fill}
  >
    <path d="M80 896V784q0-33 17-62t47-44q51-26 115-44t141-18q30 0 58.5 3t55.5 9l-70 70q-11-2-21.5-2H400q-71 0-127.5 17T180 750q-9 5-14.5 14t-5.5 20v32h250l80 80H80Zm542 16L484 774l56-56 82 82 202-202 56 56-258 258ZM400 576q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm10 240Zm-10-320q33 0 56.5-23.5T480 416q0-33-23.5-56.5T400 336q-33 0-56.5 23.5T320 416q0 33 23.5 56.5T400 496Zm0-80Z" />
  </svg>
);

export default UserTickSvg;

import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import ArrowDownSVG from "../../../assets/images/arrow-down.svg";
import FiltersSVG from "../../../assets/images/filters-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../slices/commonSlice";
import LoadingBackdrop from "../../common/LoadingBackdrop.js";
import { Table } from "antd";
import { getAllPaymentEntry } from "../../../modules/finance.js";

const PaymentEntry = ({ setActiveModule }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [paymentEntryList, setPaymentEntryList] = useState([]);

  const columns = [
    {
      dataIndex: "payment_id",
      title: "PAYMENT ID",
      width: "20%",
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.payment_id}</p>;
      },
    },
    {
      dataIndex: "payment_type",
      title: "PAYMENT TYPE",
      width: "10%",
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.payment_type}</p>;
      },
    },
    {
      dataIndex: "posting_date",
      title: "POSTING DATE",
      width: "15%",
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.posting_date}</p>;
      },
    },
    {
      dataIndex: "mode_of_payment",
      title: "MODE OF PAYMENT",
      width: "15%",
      render: (val, row) => {
        return (
          <p className={styles.tableContentFields}>{row.mode_of_payment}</p>
        );
      },
    },
    {
      dataIndex: "student_id",
      title: "STUDENT ID",
      width: "20%",
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.student_id}</p>;
      },
    },
    {
      dataIndex: "status",
      title: "STATUS",
      width: "10%",
      render: (val, row) => {
        return (
          <p
            className={
              row.status === "Submitted"
                ? styles.buttonPublish
                : styles.buttonUnPublish
            }
          >
            {row.status}
          </p>
        );
      },
    },
    {
      dataIndex: "id",
      title: "ACTION",
      width: "10%",
      render: (val, row) => {
        return (
          <p
            className={styles.tableContentView}
            onClick={() =>
              navigate(`/modules/finance/payment-entry/${row.payment_id}`)
            }
          >
            View
          </p>
        );
      },
    },
  ];

  const handleGetPaymentEntryListing = async () => {
    dispatch(setLoading({ loading: true }));
    const res = await getAllPaymentEntry();
    if (res.status === 200) {
      dispatch(setLoading({ loading: false }));
      const paymentEntry = [];
      for (let i = 0; i < res.data.data.length; i++) {
        paymentEntry.push({
          id: i,
          payment_id: res.data.data[i].name,
          payment_type: res.data.data[i].payment_type,
          posting_date: res.data.data[i].posting_date,
          mode_of_payment: res.data.data[i].mode_of_payment,
          student_id: res.data.data[i].party,
          status: res.data.data[i].status,
        });
      }
      setPaymentEntryList(paymentEntry);
    } else {
      dispatch(setLoading({ loading: false }));
    }
  };

  const sortedDataSource = [...paymentEntryList].sort((a, b) => {
    return b.payment_id.localeCompare(a.payment_id);
  });

  useEffect(() => {
    handleGetPaymentEntryListing();
  }, []);

  return (
    <div className={styles.mainContainer}>
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/dashboard")}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => setActiveModule(0)}
            >
              Finance {" > "}
            </p>
            <p className={styles.breadCrumbsContent}>Payment Entry</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>Payment Entry</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div className={`${styles.button} ${styles.filterButton}`}>
                <ReactSVG src={FiltersSVG} />
                <p className={styles.buttonText}>Filters</p>
                <ReactSVG src={ArrowDownSVG} />
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>Saved Filters</p>
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>...</p>
              </div>
              <div
                className={styles.activeButton}
                onClick={() =>
                  navigate("/modules/finance/add-new-payment-entry")
                }
              >
                <p className={styles.activeButtonText}>+ Add New</p>
              </div>
            </div>
          </div>
          <Table
            rowSelection={{ type: "checkbox" }}
            columns={columns}
            dataSource={sortedDataSource}
            size="small"
            pagination={true}
          />
        </>
      )}
    </div>
  );
};

export default PaymentEntry;

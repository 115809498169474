import { setActiveSidebar } from "../../../../slices/academicsSlice";
import { useDispatch, useSelector } from "react-redux";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import useStyles from "./style";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import MainHeader from "components/common/MainHeader";
import MainFooter from "components/common/MainFooter";
import { Select, Checkbox, Modal } from "antd";
import { useState, useEffect } from "react";
import { attendanceBasedOnOptions, groupBasedOnOptions } from "../config";
import {
  getAcademicTermListing,
  getAcademicValues,
  getAcademicYearListing,
  getAllStudentsInGroup,
  getStudentGroupListing,
} from "modules/finance";
import { toast } from "react-toastify";
import {
  addBulkStudentAttendance,
  addStudentAttendance,
} from "modules/student";

const StudentAttendanceTool = () => {
  const selectSize = "middle";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const styles = useStyles();
  const { loading } = useSelector((state) => state.common);
  const [attendanceBasedOn, setAttendanceBasedOn] = useState("");
  const [data, setData] = useState([]); // State to store fetched data
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [academicYearOptions, setAcademicYearOptions] = useState([]);
  const [academicTermOptions, setAcademicTermOptions] = useState([]);
  const [studentGroupOptions, setStudentGroupOptions] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [values, setValues] = useState({
    student: "",
    student_name: "",
    contact_no: "",
    group_based_on: "",
    student_group: "",
    academic_year: "",
    academic_term: "",
    date: "",
    course_schedule: "",
    status: "",
  });
  const [errors, setErrors] = useState({
    student: "",
    student_name: "",
    contact_no: "",
    student_group: "",
    group_based_on: "",
    date: "",
    status: "",
    course_schedule: "",
  });

  const handleAttendanceNavigation = () => {
    dispatch(setActiveSidebar({ activeSidebar: 3 }));
    navigate("/modules/academics");
  };

  const handleCheckboxChange = (itemId) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter((item) => item !== itemId);
      } else {
        return [...prevSelectedItems, itemId];
      }
    });
  };

  const handleSelectAll = () => {
    setSelectedItems(data.map((item) => item.id));
  };

  const handleUnselectAll = () => {
    setSelectedItems([]);
  };
  const handleGetAcademicYearListing = async () => {
    const res = await getAcademicYearListing();
    if (res.status === 200) {
      const arr = [];
      const acdemicYearList = res.data.data;
      for (let i = 0; i < acdemicYearList?.length; i++) {
        arr.push({
          value: acdemicYearList[i].name,
          label: acdemicYearList[i].name,
        });
      }
      setAcademicYearOptions(arr);
    }
  };

  const handleGetAcademicTermListing = async () => {
    const res = await getAcademicTermListing();
    if (res.status === 200) {
      const arr = [];
      const acdemicTermList = res.data.data;
      for (let i = 0; i < acdemicTermList?.length; i++) {
        arr.push({
          value: acdemicTermList[i].name,
          label: acdemicTermList[i].name,
        });
      }
      setAcademicTermOptions(arr);
    }
  };

  const handleGetStudentGroupListing = async () => {
    const res = await getStudentGroupListing(values.group_based_on);
    if (res.status === 200) {
      const arr = [];
      const studentGroupList = res.data.data;
      for (let i = 0; i < studentGroupList?.length; i++) {
        arr.push({
          value: studentGroupList[i].name,
          label: studentGroupList[i].name,
        });
      }
      setStudentGroupOptions(arr);
    }
  };

  const handleGetAcademicValues = async () => {
    const payload = {
      doctype: "Student Group",
      docname: values.student_group,
      fields: ["academic_year", "academic_term"],
    };
    let res = await getAcademicValues(payload);
    console.log(res);
    if (res.status === 200) {
      res = res.data.message;
      console.log(res);
      setValues((prevData) => ({
        ...prevData,
        academic_year: res.academic_year,
        academic_term: res.academic_term,
      }));
    }
  };

  const handleGetStudentsListing = async () => {
    const payload = {
      based_on: values.group_based_on,
      student_group: values.student_group,
      date: values.date,
      course_schedule: "",
    };
    const res = await getAllStudentsInGroup({ payload });
    console.log(res);
    if (res.status === 200) {
      const arr = [];

      const studentGroupList = res.data.message;
      for (let i = 0; i < studentGroupList?.length; i++) {
        arr.push({
          student: studentGroupList[i].student,
          student_name: studentGroupList[i].student_name,
          group_roll_number: studentGroupList[i].group_roll_number,
        });
      }
      setStudentData(arr);
      //setStudentGroupOptions(arr);
    }
  };

  useEffect(() => {
    handleGetAcademicYearListing();
    handleGetAcademicTermListing();
    handleGetStudentGroupListing();
  }, []);
  useEffect(() => {
    handleGetStudentGroupListing();
  }, [values.group_based_on]);
  useEffect(() => {
    if (values.student_group) {
      handleGetAcademicValues();
    }
  }, [values.student_group]);
  useEffect(() => {
    if (values.date) {
      handleGetStudentsListing();
    }
  }, [values]);

  useEffect(() => {
    const dummyData = [
      { id: 1, name: "Item 1" },
      { id: 2, name: "Item 2" },
      { id: 3, name: "Item 3" },
      { id: 4, name: "Item 4" },
      { id: 5, name: "Item 5" },
      { id: 6, name: "Item 6" },
      { id: 7, name: "Item 7" },
      { id: 8, name: "Item 8" },
      { id: 9, name: "Item 9" },
      { id: 10, name: "Item 10" },
      { id: 11, name: "Item 11" },
      { id: 12, name: "Item 12" },
    ];
    setData(dummyData);
  }, []);
  useEffect(() => {
    console.log(values);
    console.log(studentData);
  }, [values, studentData]);

  const showModal = () => {
    setModalVisible(true);
  };

  const handleOk = async () => {
    try {
      setModalVisible(false);
      console.log(selectedItems);
      const payload = {
        students_present: JSON.stringify(
          studentData.filter((item) => selectedItems.includes(item.student))
        ),
        students_absent: JSON.stringify(
          studentData.filter((item) => !selectedItems.includes(item.student))
        ),
        student_group: values.student_group,
        date: values.date,
        course_schedule: "",
      };
      console.log(payload);

      const res = await addBulkStudentAttendance(JSON.stringify(payload));
      console.log(res);
      if (res.status === 200) {
        //navigate(`/modules/finance/fee-schedule/${res.data.data.name}`);
        toast.success("Attendance Saved!");
      } else {
        console.log("Error", res.data.exception);
        toast.error(
          "Error - Student Attendance already marked for the date ",
          res.data.exception
        );
      }
    } catch (error) {
      toast.error(error.response.data.exception);
    }
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <>
      <MainHeader />{" "}
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/dashboard");
                dispatch(setActiveSidebar({ activeSidebar: 0 }));
              }}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/modules/academics");
                dispatch(setActiveSidebar({ activeSidebar: 0 }));
              }}
            >
              Academics {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => handleAttendanceNavigation()}
            >
              Attendance {" > "}
            </p>
            <p className={styles.breadCrumbsContent}>Student Attendance Tool</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>Student Attendance Tool</p>
            </div>
          </div>

          <div className={styles.formContainer}>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Attendance Based On *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.student}
                  className={styles.selectInput}
                  options={attendanceBasedOnOptions}
                  onChange={(selectedOption) => {
                    setValues({
                      ...values,
                      student: selectedOption,
                      course_schedule: "",
                      student_group: "",
                    });
                    setAttendanceBasedOn(selectedOption);
                    setErrors({
                      ...errors,
                      student: "",
                    });
                  }}
                />

                {errors.student && (
                  <p className={styles.errorMessage}>{errors.student}</p>
                )}
              </div>
              {attendanceBasedOn === "Course Schedule" && (
                <>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Course Schedule *</p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      value={values.course_schedule}
                      className={styles.selectInput}
                      // options={attendanceBasedOnOptions}
                      onChange={(selectedOption) => {
                        setValues({
                          ...values,
                          course_schedule: selectedOption,
                        });
                        setErrors({
                          ...errors,
                          course_schedule: "",
                        });
                      }}
                    />

                    {errors.course_schedule && (
                      <p className={styles.errorMessage}>
                        {errors.course_schedule}
                      </p>
                    )}
                  </div>
                  <div className={styles.field}></div>
                </>
              )}
              {attendanceBasedOn === "Student Group" && (
                <>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Group Based On *</p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      className={styles.selectInput}
                      options={groupBasedOnOptions}
                      value={values.group_based_on}
                      onChange={(val) => {
                        setValues({
                          ...values,
                          group_based_on: val,
                        });
                        setErrors({
                          ...errors,
                          group_based_on: "",
                        });
                      }}
                    />
                    {errors.group_based_on && (
                      <p className={styles.errorMessage}>
                        {errors.group_based_on}
                      </p>
                    )}
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Student Group *</p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      options={studentGroupOptions}
                      className={styles.selectInput}
                      //   options={academicYearOptions}
                      value={values.student_group}
                      onChange={(val) => {
                        setValues({
                          ...values,
                          student_group: val,
                        });
                        setErrors({
                          ...errors,
                          student_group: "",
                        });
                      }}
                    />
                    {errors.student_group && (
                      <p className={styles.errorMessage}>
                        {errors.student_group}
                      </p>
                    )}
                  </div>
                </>
              )}
            </div>
            {attendanceBasedOn === "Student Group" && (
              <div className={styles.formRow}>
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>Academic Year *</p>
                  <Select
                    size={selectSize}
                    placeholder="Select"
                    options={academicYearOptions}
                    value={values.academic_year}
                    className={styles.selectInput}
                    //   options={academicYearOptions}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        academic_year: value,
                      });
                      setErrors({
                        ...errors,
                        academic_year: "",
                      });
                    }}
                  />
                  {errors.academic_year && (
                    <p className={styles.errorMessage}>
                      {errors.academic_year}
                    </p>
                  )}
                </div>
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>Academic Term</p>
                  <Select
                    size={selectSize}
                    placeholder="Select"
                    options={academicTermOptions}
                    value={values.academic_term}
                    className={styles.selectInput}
                    //   options={academicTermOptions}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        academic_term: value,
                      });
                    }}
                  />
                </div>
                <div className={styles.field}>
                  <p className={styles.fieldLabel}> Date *</p>
                  <input
                    type="date"
                    value={values.date}
                    placeholder="YYYY-MM-DD"
                    className={styles.dateInput}
                    onChange={(e) => {
                      const dateString = e.target.value;
                      setValues({ ...values, date: dateString });
                      setErrors({
                        ...errors,
                        date: "",
                      });
                    }}
                  />
                  {errors.date && (
                    <p className={styles.errorMessage}>{errors.date}</p>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className={styles.container}>
            <p className={styles.smallTitle}>Students</p>
            <div className={styles.buttonsGroup}>
              <div className={styles.button} onClick={handleSelectAll}>
                <p className={styles.buttonText}>Select All</p>
              </div>
              <div className={styles.button} onClick={handleUnselectAll}>
                <p className={styles.buttonText}>Unselect All</p>
              </div>
              <div className={styles.activeButton} onClick={() => showModal()}>
                <p className={styles.activeButtonText}>Mark Attendance</p>
              </div>
            </div>
          </div>
          <div className={styles.divider}></div>

          <div className={styles.attendenceContainer}>
            {studentData.map((item) => (
              <div key={item.id} className={styles.checkboxWrapper}>
                <Checkbox
                  key={item.student}
                  checked={selectedItems.includes(item.student)}
                  onChange={() => handleCheckboxChange(item.student)}
                >
                  {item.student_name}
                </Checkbox>
              </div>
            ))}
          </div>
          <Modal
            title="Confirm"
            open={modalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
          >
            <div>
              <p>Do you want to update Attendance?</p>
              <p>Present: {selectedItems.length}</p>
              <p>Absent: {studentData.length - selectedItems.length}</p>
              <div className={styles.modalButton}>
                <div className={styles.button} onClick={handleCancel}>
                  <p className={styles.buttonText}>No</p>
                </div>
                <div className={styles.activeButton} onClick={handleOk}>
                  <p className={styles.activeButtonText}>Yes</p>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default StudentAttendanceTool;

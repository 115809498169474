import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import LoadingBackdrop from "../../../common/LoadingBackdrop.js/index.js";
import MainFooter from "components/common/MainFooter";
import MainHeader from "components/common/MainHeader";
import { Input } from "antd";
import { setActiveSidebar } from "slices/financeSlice";
import { PhotoProvider, PhotoView } from "react-photo-view";

const FileDetails = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { loading } = useSelector((state) => state.common);
  const [fileDetails, setFileDetails] = useState({
    fileName: "",
    fileSize: "",
    fileType: "",
    fileUrl: ""
  })

  const handleGetFileDetails = async () => {
    if(!state.fileDetails || Object.keys(state.fileDetails).length <= 0) {
      navigate("/modules/iqac");
    } else {
      setFileDetails({
        fileName: state.fileDetails.fileName,
        fileSize: state.fileDetails.fileSize,
        fileType: state.fileDetails.fileType,
        fileUrl: state.fileDetails.fileUrl
      })
    }
  }


  const handleDownload = () => {
    const { fileUrl, fileName } = fileDetails;

    fetch(fileUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(error => console.error('Error downloading file:', error));
  };

  const handleNavigation = () => {
    navigate("/modules/iqac");
    dispatch(setActiveSidebar({ activeSidebar: 1 }));
  }

  useEffect(() => {
    handleGetFileDetails();
  }, []);

  return (
    <>
      <MainHeader />
      <div className={styles.mainContainer}>
        {loading ? (
          <LoadingBackdrop open={loading} />
        ) : (
          <>
            <div className={styles.breadCrumbsContainer}>
              <p className={styles.breadCrumbsContent} onClick={() => navigate("/dashboard")}>
                IIHT {" > "}
              </p>
              <p className={styles.breadCrumbsContent} onClick={() => navigate("/modules/iqac")}>
                IQAC {" > "}
              </p>
              <p className={styles.breadCrumbsContent} onClick={handleNavigation}>Document Management {" > "}</p>
               <p className={styles.breadCrumbsContent}>{fileDetails.fileName}</p>
            </div>
            <div className={styles.headerContainer}>
              <div className={styles.breadCrumbsContainer}>
                <GridMenuIcon className={styles.gridIcon} />
                <p className={styles.title}>{fileDetails.fileName}</p>
              </div>
              <div className={styles.buttonsGroup}>
                <div className={styles.button} onClick={handleNavigation}>
                  <p className={styles.buttonText}>Back</p>
                </div>
                <div className={styles.activeButton} onClick={handleDownload}>
                  <p className={styles.activeButtonText}>
                    Download
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.formContainer}>
              <div className={styles.formRow}>
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>File Name *</p>
                  <Input className={styles.fieldInput} value={fileDetails.fileName} disabled={true} />
                </div>
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>File Type *</p>
                  <Input className={styles.fieldInput} value={fileDetails.fileType} disabled={true}/>
                </div>
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>File Size *</p>
                  <Input className={styles.fieldInput} value={`${fileDetails.fileSize}KB`} disabled={true}/>
                </div>
              </div>
              <div className={styles.previewContainer}>
                <p className={styles.previewText}>Preview</p>
                {
                  fileDetails.fileType === "JPG" || fileDetails.fileType === "PNG" || fileDetails.fileType === "JPEG" ? 
                  <PhotoProvider>
                    <PhotoView src={`https://erp-dev.techademycampus.com${fileDetails.fileUrl}`}>
                      <img src={`https://erp-dev.techademycampus.com/${fileDetails.fileUrl}`} className={styles.previewImage} /> 
                    </PhotoView>
                  </PhotoProvider> :
                  <p className={styles.link} onClick={() => window.open(`https://erp-dev.techademycampus.com${fileDetails.fileUrl}`, "_blank")}>{fileDetails.fileName}</p> 
                }
              </div>
            </div>
          </>
        )}
      </div>
      <MainFooter />
    </>
  );
};

export default FileDetails;

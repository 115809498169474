import React, { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

const Breadcrumbs = () => {
  const location = useLocation();
  const { entityType } = useParams();
  const [currentEntityType, setCurrentEntityType] = useState(entityType);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  // Function to create breadcrumbs based on the URL
  const createBreadcrumbs = () => {
    const pathSegments = location.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const breadcrumbsArray = pathSegments.map((segment, index) => {
      const decodedLabel = decodeURIComponent(segment.replace(/\+/g, '%20'));
      const url = `/${pathSegments.slice(0, index + 1).join("/")}`;
      return { label: decodedLabel, url };
    });

    // Add "IIHT" as the root link
    // breadcrumbsArray.unshift({ label: "Modules", url: "/dashboard" });
    breadcrumbsArray.unshift({ label: "IIHT", url: "/dashboard" });

    return breadcrumbsArray;
  };

  const handleSetFields = (fields, id) => {
    setCurrentEntityType(id);
    setBreadcrumbs(createBreadcrumbs());
  };

  // Call handleSetFields when the component mounts
  React.useEffect(() => {
    handleSetFields(currentEntityType);
  }, [location.pathname]); // Update when the pathname changes

  return (
    <div className="breadcrumbs">
      {breadcrumbs.map((breadcrumb, index) => (
        <React.Fragment key={index}>
          {index > 0 && <span className="separator"> &gt; </span>}
          <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
        </React.Fragment>
      ))}
    </div>
  );
};

export default Breadcrumbs;

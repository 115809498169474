import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import { Table } from "antd";
import { toast } from "react-toastify";
import moment from "moment";
import { Stack, Typography } from "iiht-b2a-ui-components/build/components";
import { ActionFilters } from "components/Filters";
import CommonFilters from "components/Filters/CommonFilters";
import SavedFilters from "components/Filters/SavedFilters";
import { CustomBreadcrumb, MainLayout } from "components/common";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { exportGenericData, getGenericListApi } from "modules/hr";
import { setLoading } from "slices/commonSlice";

const JobApplications = () => {
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [jobApplicationsList, setJobApplicationsList] = useState([]);
  const { doctype, filters } = useSelector((state) => state.filters);
  const { loading } = useSelector((state) => state.common);

  const breadcrumbs = [
    {
      label: "IIHT",
      link: "/dashboard",
    },
    {
      label: "HR",
      link: "/modules/hr",
    },
    {
      label: "Recruitment",
      link: "/modules/hr",
    },
    {
      label: "Job Applications",
      link: "",
    },
  ];

  const columns = [
    {
      dataIndex: "id",
      title: "Application Id",
      width: "20%",
    },
    {
      dataIndex: "applicant_name",
      title: "Candidate Name",
      width: "20%",
      render: (_val, row) => `${row?.first_name ?? ""} ${row?.last_name ?? ""}`,
    },
    {
      dataIndex: "designation",
      title: "Position",
      width: "10%",
    },
    {
      dataIndex: "programme",
      title: "Programme",
      width: "10%",
    },
    {
      dataIndex: "campus",
      title: "Campus",
      width: "10%",
    },
    {
      dataIndex: "status",
      title: "Status",
      width: "10%",
      render: (val, row) => {
        return (
          <Typography
            fontSize={12}
            fontWeight={500}
            color={`${val === "Rejected" ? "#ac0521" : val === "Accepted" ? "#4baf4f" : "#2C83EC"}`}
            sx={{
              border: `1px solid ${
                val === "Rejected" ? "#ac052180" : val === "Accepted" ? "#4baf4f80" : "#2c83ec80"
              }`,
              borderRadius: "6px",
              backgroundColor: `${
                val === "Rejected" ? "#ac05210d" : val === "Accepted" ? "#4baf4f0d" : "#2c83ec0d"
              }`,
              textAlign: "center",
              padding: "2px",
              width: "80px",
            }}
          >
            {val}
          </Typography>
        );
      },
    },
    {
      dataIndex: "id",
      title: "Action",
      width: "10%",
      render: (val, row) => {
        return (
          <NavLink
            color="secondary"
            variant="body2"
            to={`/modules/hr/job-application/view/${val}/${row?.job_opening}/${row?.designation}`}
            className="mouse-pointer qa-view-all view-all"
          >
            View
          </NavLink>
        );
      },
    },
  ];

  const actionOptions = [{ label: "Export", id: "export", key: "export" }];

  const handleBulkExport = async () => {
    try {
      const bulkExportparams = jobApplicationsList
        .filter((row) => selectedIds.includes(row.id))
        .map(({ id, applicant_name, designation, status, creation, modified }) => ({
          doctype: "Job Applicant",
          id,
          applicant_name,
          designation,
          status,
          creation: moment(creation).format("LLL"),
          modified: moment(modified).format("LLL"),
        }));

      const payloadData = {
        export: bulkExportparams,
      };
      const response = await exportGenericData(payloadData);
      if (response?.status === 200) {
        const blob = await response.data;
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", "job_applications_list.xlsx");
        document.body.appendChild(link);
        link.click();

        // Show success message
        toast.success("Job Applicantions list exported successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.success("Failed to Export Job Applicantions List.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error("Failed to Export Job Applicantions List.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    fetchJobApplicationsList();
  };

  const handleAction = (option) => {
    if (option.id === "export") {
      handleBulkExport();
    }
  };

  const onSelectChange = (selectedKeys, selectedRowData) => {
    setSelectedRowKeys(selectedKeys);
    let selectedDataIds = [];
    selectedRowData.map((rowData) => selectedDataIds.push(rowData.id));
    setSelectedIds([...selectedDataIds]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      id: record.id,
    }),
  };

  const fetchJobApplicationsList = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Job Applicant",
      fields: JSON.stringify(["*"]),
      filters: doctype === "Job Applicant" ? JSON.stringify(filters).slice(1, -1) : [],
      limit: "*",
    };
    const res = await getGenericListApi(params);
    if (res?.status === 200) {
      if (res.data?.message?.length) {
        const list = res.data.message.map((data, i) => {
          return { ...data, key: i };
        });
        setJobApplicationsList(list);
      } else {
        setJobApplicationsList([]);
      }
    }
    dispatch(setLoading({ loading: false }));
  };

  useEffect(() => {
    fetchJobApplicationsList();
  }, [filters]);

  return (
    <MainLayout>
      {loading && <LoadingBackdrop open={loading} />}
      <div>
        <CustomBreadcrumb breadcrumbs={breadcrumbs} />
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={1} alignItems="center">
            <GridMenuIcon className="gridIcon" />
            <Typography color="#0D1021" fontSize={22}>
              Job Application
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <CommonFilters doctype="Job Applicant"></CommonFilters>
            <SavedFilters doctype="Job Applicant"></SavedFilters>
            <ActionFilters
              options={actionOptions}
              disabled={!selectedRowKeys.length}
              onChange={handleAction}
            />
          </Stack>
        </Stack>

        <div className="mt4">
          <Table
            rowSelection={{ type: "checkbox", ...rowSelection }}
            columns={columns}
            dataSource={jobApplicationsList}
            size="small"
            pagination={true}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default JobApplications;

import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Divider from '@mui/material/Divider';
import { makeStyles } from "@mui/styles";
import { getLeadDetails, getUserDetails, updateLeadDetails } from "../../../../modules/lead";
import "./index.scss";
import LoadingBackdrop from "../../../common/LoadingBackdrop.js";
import { Select } from "antd";

const useStyles = makeStyles((theme) => ({
  disabledInputBox: {
    color: "#536882",
    fontSize: "12px",
    fontWeight: "400",
    padding: "12px",
    borderRadius: "5px",
    border: "1px solid #CDD6E2",
    background: "#EFEEEE",
    outline: "none",
    width: "100%",
  },
  disabledInputLabel: {
    color: "#012148",
    fontSize: "14px",
    fontWeight: "500",
  },
  dividerLine: {
    width: "100%",
    height: "1px",
    backgroundColor: "#E7EAEF",
    margin: "32px 12px"
  },
  selectInputBox: {
    color: "#536882",
    fontSize: "12px",
    fontWeight: "400",
    borderRadius: "5px",
    background: "#fff",
    outline: "none",
    width: "100%",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    marginBottom: "40px",
    marginTop: "16px",
  },
  field: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
  },
  formRow: {
    display: "inline-flex",
    justifyContent: "space-between"
  },
}));

const LeadDetails = ({ uniqueId }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [status, setStatus] = useState("");
  const [value, setValue] = useState("1");
  const [loading, setLoading] = useState(true);
  const [displayButtons, setDisplayButtons] = useState(false);
  const [userListOptions, setUserListOptions] = useState([]);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    country: "",
    state: "",
    city: "",
    campus: "",
    college: "",
    programGroup: "",
    program: "",
    select1: "",
    select2: "",
  });

  const statusOptions = [
    {
      value: "Open",
      label: "Open",
    },
    {
      value: "Hot",
      label: "Hot",
    },
    {
      value: "Cold",
      label: "Cold",
    },
    {
      value: "Warm",
      label: "Warm",
    },
    {
      value: "Qualified",
      label: "Qualified",
    },
    {
      value: "Not Qualified",
      label: "Not Qualified",
    },
  ]

  useEffect(() => {
    handleGetLeadDetails();
    handleGetUserDetails();
  }, []);

  const handleGetLeadDetails = async () => {
    const res = await getLeadDetails({ uniqueId });
    if (res.status === 200) {
      let updatedStatus;
      if (res?.data.data.status === "Eligible") {
        updatedStatus = "Qualified";
      } else if (res?.data.data.status === "Not Eligible") {
        updatedStatus = "Non Qualified";
      } else {
        updatedStatus = res?.data.data.status;
      }
      setFormData({
        fullName: res?.data.data.first_name,
        email: res?.data.data.email_id,
        phone: res?.data.data.mobile_no,
        country: res?.data.data.country,
        state: res?.data.data.state,
        city: res?.data.data.city,
        campus: res?.data.data.company,
        college: res?.data.data.company,
        programGroup: res?.data.data.custom_program_group,
        program: res?.data.data.custom_program,
        select1: res?.data.data.lead_owner,
        select2: updatedStatus,
      });
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(res.data?.exception);
    }
  };

  const handleGetUserDetails = async () => {
    const res = await getUserDetails();
    if (res.status === 200) {
      const arr = [];
      for(let i = 0; i < res.data.data.length ; i++) {
        arr.push({
          value: res.data.data[i].name,
          label: res.data.data[i].name
        })
      }

      setUserListOptions(arr);
    }
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    handleUpdateLeadDetails();
  };

  const handleUpdateLeadDetails = async () => {
    let selectedStatus = formData.select2;
    if (selectedStatus === "Qualified") {
      selectedStatus = "Eligible";
    } else if (selectedStatus === "Not Qualified") {
      selectedStatus = "Not Eligible";
    }

    const payload = {
      lead_name: formData.fullName,
      phone: formData.phone,
      country: formData.country,
      state: formData.state,
      city: formData.city,
      company: formData.campus,
      custom_program_group: formData.programGroup,
      custom_program: formData.program,
      lead_owner: formData.select1,
      email_id: formData.email,
      status: selectedStatus,
    };

    const res = await updateLeadDetails({ uniqueId, payload });
    if (res.status === 200) {
      handleGetLeadDetails();
      setLoading(false);
      setDisplayButtons(false);
      if (selectedStatus === "Eligible") {
        toast.success("Login Credential sent.");
      } else {
        toast.success("Updated Successfully!");
      }
    } else {
      setLoading(false);
      toast.error(res.data?.exception);
    }
  };

  const handleStatusChange = (selectedStatus) => {
    setDisplayButtons(true);
    let displayedStatus;
    let apiStatus;
    if (selectedStatus === "Qualified") {
      displayedStatus = "Qualified";
      apiStatus = "Eligible";
    } else if (selectedStatus === "Not Qualified") {
      displayedStatus = "Not Qualified";
      apiStatus = "Not Eligible";
    } else {
      displayedStatus = selectedStatus;
      apiStatus = selectedStatus;
    }

    setStatus(apiStatus);

    setFormData((prevData) => ({
      ...prevData,
      select2: displayedStatus,
    }));
  };

  const handleTabChange = (event, newValue) => {
    if (newValue == 1) return;
    const type = newValue == 2 ? "emails" : newValue == 3 ? "notes" : "events";
    navigate(`/lead/${uniqueId}/${type}`);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1", marginTop: "-20px" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TabContext value={value}>
          <TabList
            onChange={handleTabChange}
            aria-label="Lead Detail"
            className="tabHead"
          >
            <Tab label="Details" value="1" />
            <Tab label="Email" value="2" />
            <Tab label="Notes" value="3" />
            <Tab label="Event" value="4" />
          </TabList>
        </TabContext>

        {
          displayButtons && <div className="rightBtn">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        }
      </Box>
      <TabContext>
        {
          loading ? 
            <LoadingBackdrop open={loading}/> :
            <div className={classes.formContainer}>
              <div className={classes.formRow}>
                <div className={classes.field}>
                  <p className={classes.disabledInputLabel}>Full Name</p>
                  <input
                    className={classes.disabledInputBox}
                    value={formData.fullName}
                    disabled
                  />
                </div>
                <div className={classes.field}>
                  <p className={classes.disabledInputLabel}>Email Address</p>
                  <input
                    className={classes.disabledInputBox}
                    value={formData.email}
                    disabled
                  />
                </div>
                <div className={classes.field}>
                  <p className={classes.disabledInputLabel}>Phone Number</p>
                  <input
                    className={classes.disabledInputBox}
                    value={formData.phone}
                    disabled
                  />
                </div>
              </div>
              <div className={classes.formRow}>
                <div className={classes.field}>
                  <p className={classes.disabledInputLabel}>Country</p>
                  <input
                    className={classes.disabledInputBox}
                    value={formData.country}
                    disabled
                  />
                </div>
                <div className={classes.field}>
                  <p className={classes.disabledInputLabel}>State</p>
                  <input
                    className={classes.disabledInputBox}
                    value={formData.state}
                    disabled
                  />
                </div>
                <div className={classes.field}>
                  <p className={classes.disabledInputLabel}>City</p>
                  <input
                    className={classes.disabledInputBox}
                    value={formData.city}
                    disabled
                  />
                </div>
              </div>
              <div className={classes.formRow}>
                <div className={classes.field}>
                  <p className={classes.disabledInputLabel}>Campus</p>
                  <input
                    className={classes.disabledInputBox}
                    value={formData.campus}
                    disabled
                  />
                </div>
                <div className={classes.field}>
                  <p className={classes.disabledInputLabel}>College</p>
                  <input
                    className={classes.disabledInputBox}
                    value={formData.college}
                    disabled
                  />
                </div>
                <div className={classes.field}>
                  <p className={classes.disabledInputLabel}>Program Type</p>
                  <input
                    className={classes.disabledInputBox}
                    value={formData.programGroup}
                    disabled
                  />
                </div>
              </div>
              <div className={classes.formRow}>
                <div className={classes.field}>
                  <p className={classes.disabledInputLabel}>Program</p>
                  <input
                    className={classes.disabledInputBox}
                    value={formData.program}
                    disabled
                  />
                </div>
              </div>
              <Divider />
              <div className={classes.formRow}>
                <div className={classes.field}>
                  <p className={classes.disabledInputLabel}>
                    Assigned to
                  </p>
                  <Select 
                    showSearch
                    options={userListOptions} 
                    onChange={(value) => {
                      setDisplayButtons(true);
                      setFormData({ ...formData, select1: value })
                    }}
                    className={classes.selectInputBox}
                    value={formData.select1} />
                </div>
                <div className={classes.field}>
                  <p className={classes.disabledInputLabel}>
                    Lead Status
                  </p>
                  <Select 
                  showSearch
                  options={statusOptions} 
                  onChange={(value) => handleStatusChange(value)}  
                  className={classes.selectInputBox}
                  value={formData.select2} />
                </div>
                <div className={classes.field}></div>
              </div>
            </div>
        }
      </TabContext>
    </Box>
  );
};

export default LeadDetails;

import React from 'react';
import './General.css';
import { Button } from '@mui/material';

const RegistrationForm = () => {
  return (
    <div className="container">
      <h2>Registration Details</h2>
      <div className="form-group">
        <p htmlFor="appNo">Application no:</p>
        <p>Ayush</p> 
      </div>
      <div className="form-group">
        <p htmlFor="email">Email ID:</p>
        <p>ayush@yopmail.com</p>
      </div>
      <div className="form-group">
        <p htmlFor="mobile">Mobile Number:</p>
        <p>9988776655</p>
      </div>
      <div className="note">
        <p>NOTE:</p>
        <li>Please retain the mobile number and email ID till the completion of the recruitment process.</li>
        <li>Please quote application number in all communications regarding recruitment.</li>
        <li>You can edit your application multiple times by logging in using application number.</li>
      </div>
    </div>
  );
};

export default RegistrationForm;

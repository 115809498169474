import React from "react";
import Grid from "iiht-b2a-ui-components/build/components/Grid";
import { Typography } from "iiht-b2a-ui-components/build/components";
import { FormTextField } from "components/common";
import { FormProvider, useForm } from "react-hook-form";

const JobApplicationDetails = ({ jobApplicationData }) => {
  const methods = useForm({
    defaultValues: jobApplicationData,
  });

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <Grid container spacing={4} display="flex" flexDirection="row" alignItems="center">
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Typography variant="bodyText" color="#012148" fontWeight={500}>
                First Name
              </Typography>
              <FormTextField
                name="first_name"
                variant="outlined"
                size="small"
                fullWidth
                disabled
                className="mt1"
              />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Typography variant="bodyText" color="#012148" fontWeight={500}>
                Last Name
              </Typography>
              <FormTextField
                name="last_name"
                variant="outlined"
                size="small"
                fullWidth
                disabled
                className="mt1"
              />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Typography variant="bodyText" color="#012148" fontWeight={500}>
                Years of Experience (years)
              </Typography>
              <FormTextField
                name="experience"
                variant="outlined"
                size="small"
                fullWidth
                disabled
                className="mt1"
              />
            </Grid>

            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Typography variant="bodyText" color="#012148" fontWeight={500}>
                How soon you can join us (Days)
              </Typography>
              <FormTextField
                name="how_soon_can_you_join_us"
                variant="outlined"
                size="small"
                fullWidth
                disabled
                className="mt1"
              />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Typography variant="bodyText" color="#012148" fontWeight={500}>
                Ready to Relocate
              </Typography>
              <FormTextField
                name="relocation"
                variant="outlined"
                size="small"
                fullWidth
                disabled
                className="mt1"
              />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Typography variant="bodyText" color="#012148" fontWeight={500}>
                Current CTC (In LPA)
              </Typography>
              <FormTextField
                name="current_ctc"
                variant="outlined"
                size="small"
                fullWidth
                disabled
                className="mt1"
              />
            </Grid>

            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Typography variant="bodyText" color="#012148" fontWeight={500}>
                Expected CTC (In LPA)
              </Typography>
              <FormTextField
                name="expected_ctc"
                variant="outlined"
                size="small"
                fullWidth
                disabled
                className="mt1"
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default JobApplicationDetails;

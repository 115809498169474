import React from "react";
import { ReactSVG } from "react-svg";

import Logo from "../../assets/images/TechademyLogo.svg";
import BackgroundLogo from "../../assets/images/landingPageBG.png";
import HrForm from "../../components/HrForm";
import useStyles from "./style";

const Landing = () => {
  const styles = useStyles();
  return (
    <div style={{ overflowY: "hidden" }}>
      <div className={styles.logoContainer}>
        <ReactSVG src={Logo} />
      </div>
      <div className={styles.enquiryFormContainer}>
        <HrForm />
      </div>
      <div className={styles.backgroundContainer}>
        <img
          src={BackgroundLogo}
          className={styles.backgroundImage}
          alt="backgroundImage"
        />
      </div>
    </div>
  );
};

export default Landing;

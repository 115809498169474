import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { Button } from "iiht-b2a-ui-components/build/components";
import "./index.scss";
import { useNavigate } from "react-router";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { razorpayCheckout } from "../../modules/razorpayCheckOut";
import FinishTick from "../../assets/images/finishTick.svg";
import { getDataOfApplicationFormByApplicationId } from "../../modules/student";
import { getCurrentDate } from "../../utils/date";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function FormDeclaration({ handleNext, academicYear, handleBack, isFeePaid }) {
  const [errorMessages, setErrorMessages] = useState({});
  const [openDialog, setOpenDialog] = React.useState(false);
  const navigate = useNavigate();
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenPayment = async () => {
    setOpenDialog(false);
    razorpayCheckout().then((resp) => {
      if (resp.message === "Success") {
        navigate("/student/dashboard");
      }
    });
  };

  const [values, setValues] = useState({
    custom_i_agree: 0,
    custom_applicant_name: "",
    custom_date_: "",
  });

  const handleCheckboxChange = (name) => (event) => {
    setValues({
      ...values,
      [name]: event.target.checked ? 1 : 0,
    });
  };

  const handleInputChange = (name) => (event) => {
    setValues({
      ...values,
      [name]: event.target.value,
    });
  };

  const handleSave = () => {
    const formData = {
      custom_i_agree: values.custom_i_agree,
      custom_applicant_name: values.custom_applicant_name,
      custom_date_: values.custom_date_,
    };

    const newErrorMessages = {};

    for (const key in formData) {
      if (!formData[key]) {
        newErrorMessages[key] = "Required";
      }
    }

    setErrorMessages(newErrorMessages);

    // If any field is empty, stop further processing
    if (Object.keys(newErrorMessages).length > 0) {
      console.error("Error: Some fields are empty");
      return; // or handle the empty fields in some way
    }
    handleNext({ addData: false, payload: formData });
    // handleOpenDialog();
  };

  const handleGetDataOfApplicationFormByApplicationId = async () => {
    if (window.localStorage.getItem("application-name")) {
      const applicationId = JSON.parse(window.localStorage.getItem("application-name"));
      const res = await getDataOfApplicationFormByApplicationId({ applicationId });
      if (res.status == 200) {
        const data = res.data.data;
        setValues({
          ...values,
          custom_applicant_name: data.first_name,
          custom_i_agree: data.custom_i_agree,
          custom_date_: data.custom_date_,
        })
      }
    }
  }
  useEffect(() => {
    handleGetDataOfApplicationFormByApplicationId();
  },[])

  return (
    <>
      <Box sx={{ boxShadow: "none" }} className="formPersonalDetails">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item className="mainFormBody">
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={12}>
                  <Item>
                    <h4>Declaration</h4>
                  </Item>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="body1">
                    <p>
                      I certify that the information submitted by me in support
                      of this application is true to the best of my knowledge
                      and belief. I understand that in the event of any
                      information being found false or incorrect, my admission
                      is liable to be rejected/canceled at any stage of the
                      program. I undertake to abide by the disciplinary rules
                      and regulations of the institute.*
                    </p>
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.custom_i_agree}
                          onChange={handleCheckboxChange("custom_i_agree")}
                        />
                      }
                      label="I Agree"
                    />
                  </FormGroup>
                  {errorMessages.custom_i_agree && (
                    <span style={{ color: "red" }}>
                      {errorMessages.custom_i_agree}
                    </span>
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">Applicant Name *</Typography>
                  <TextField
                    required
                    name="applicantName"
                    placeholder="Enter name"
                    disabled
                    value={values.custom_applicant_name}            
                  />
                  {errorMessages.custom_applicant_name && (
                    <span style={{ color: "red" }}>
                      {errorMessages.custom_applicant_name}
                    </span>
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">Date *</Typography>
                  <input
                    type="date"
                    required
                    name="date"
                    placeholder="DD-MM-YYYY"
                    value={values.custom_date_}
                    onChange={handleInputChange("custom_date_")}
                    max={getCurrentDate()}
                  />
                  {errorMessages.custom_date_ && (
                    <span style={{ color: "red" }}>
                      {errorMessages.custom_date_}
                    </span>
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">Academic Year</Typography>
                  <input
                    type="text"
                    required
                    name="text"
                    // placeholder="DD-MM-YYYY"
                    disabled
                    value={academicYear}
                    // onChange={handleInputChange("custom_date_")}
                  />
                  {/* {errorMessages.custom_date_ && (
                    <span style={{ color: "red" }}>
                      {errorMessages.custom_date_}
                    </span>
                  )} */}
                </Grid>
              </Grid>
            </Item>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          pt: 2,
          justifyContent: "space-between",
        }}
        className="navBottomBtns"
      >
        <div style={{ display: "flex", gap: "12px" }}>
          <Button color="inherit" sx={{ mr: 1 }} className="backBtn" onClick={handleBack}>
            Back
          </Button>
          { 
            !isFeePaid &&  
            <Button onClick={() => handleSave()} sx={{ mr: 1 }}>
              Save
            </Button>
          }
        </div>
        {
          !isFeePaid && 
          <Button sx={{ mr: 1 }} onClick={() => handleSave()}>
            Submit Application
          </Button>
        }

        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          className="completedPopup"
        >
          <DialogTitle className="popupHeader">
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseDialog}
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="popupContent">
            <Typography>
              <img
                src={FinishTick}
                alt="Complete"
                style={{ height: 96, width: 96 }}
              />

              <p>
                Form saved succesfully. <br />
                Please make payment to confirm submission.
              </p>
            </Typography>
          </DialogContent>
          <DialogActions className="popupAction">
            <Button onClick={handleOpenPayment}>Make Payment</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GridMenuIcon } from "@mui/x-data-grid";
import { DeleteOutlined } from "@material-ui/icons";
import { Table } from "antd";
import { Button, IconButton, Stack, Typography } from "iiht-b2a-ui-components/build/components";
import { CustomBreadcrumb, FormSelectField, FormTextField, MainLayout } from "components/common";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { validationRules } from "constants/validationRules";
import {
  createGenericData,
  getGenericDataApi,
  getGenericListApi,
  updateGenericData,
} from "modules/hr";
import { setLoading } from "slices/commonSlice";
import { addressMapper, checkIfObjectNotEmpty } from "utils";
import { AddBoxIcon } from "icons";

const AddDesignation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id: designationId = undefined } = useParams();
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [designationData, setDesignationData] = useState({});
  const [skillList, setSkillList] = useState([]);
  const [skillRows, setSkillRows] = useState([
    {
      id: 0,
      key: 0,
      number: 0,
      skill_name: "",
    },
  ]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const isEditMode = !!(location.pathname.includes("edit-view") && designationId);
  const [isSaveBtnDisable, setIsSaveBtnDisable] = useState(isEditMode);
  const { loading } = useSelector((state) => state.common);

  const breadcrumbs = [
    {
      label: "IIHT",
      link: "/dashboard",
    },
    {
      label: "HR",
      link: "/modules/hr",
    },
    {
      label: "Recruitment",
      link: "/modules/hr",
    },
    {
      label: "Masters",
      link: "/modules/hr/masters",
    },
    {
      label: "Designation",
      link: "/modules/hr/masters/designation",
    },
    {
      label: "Add",
      link: "",
    },
  ];

  const columns = [
    {
      dataIndex: "number",
      title: "SNo.",
      width: "10%",
      render: (val) => val + 1,
    },
    {
      dataIndex: "",
      title: "Skill",
      width: "20%",
      render: (_val, _row, rowIndex) => {
        return (
          <div style={{ width: "200px" }}>
            <FormSelectField
              name={`skills.${rowIndex}.skill_name`}
              variant="outlined"
              label=""
              size="small"
              fullWidth
              onChange={(e) => {
                setValue(`skills.${rowIndex}.skill_name`, e.target.value);
                setIsSaveBtnDisable(false);
              }}
              options={skillList}
            />
          </div>
        );
      },
    },
    {
      dataIndex: "",
      title: "",
      width: "60%",
    },
  ];

  const defaultValues = {
    designation_name: "",
    description: "",
    skills: [
      {
        skill_name: "",
      },
    ],
  };

  const methods = useForm({
    defaultValues: defaultValues,
  });

  const { handleSubmit, reset, watch, getValues, setValue, control } = methods;

  const { append: skillsAppend, remove: skillsRemove } = useFieldArray({
    control: control,
    name: "skills",
  });

  const descriptionWatch = watch("description");

  const onSubmit = async (data) => {
    dispatch(setLoading({ loading: true }));
    const skills = [];
    data.skills?.forEach((skill) => {
      skill?.skill_name &&
        skills.push({
          skill: skill.skill_name,
        });
    });
    const payload = {
      doctype: "Designation",
      designation_name: data.designation_name,
      description: data.description,
      id: designationId || data.id,
      skills,
    };
    const res = isEditMode ? await updateGenericData(payload) : await createGenericData(payload);
    isEditMode && fetchDesignation();
    if (res?.status === 200) {
      toast.success(`Designation ${isEditMode ? "Updated" : "Created"}!`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsSaveBtnDisable(true);
      setIsFormSubmited(true);
    } else {
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    dispatch(setLoading({ loading: false }));
  };

  const fetchDesignation = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Designation",
      id: designationId,
    };
    const res = await getGenericDataApi(params);
    if (res?.status === 200) {
      const data = res?.data?.message;
      setDesignationData(data);
      const skills = [];
      const rows = [];
      if (data.skills?.length) {
        data.skills.forEach((skillData, index) => {
          rows.push({
            id: index,
            key: index,
            number: index,
            skill_name: "",
          });
          skills.push({
            skill_name: skillData.skill,
          });
        });
      }
      setSkillRows(rows);
      reset({
        designation_name: data.designation_name,
        description: data.description,
        skills: skills,
      });
    }
    dispatch(setLoading({ loading: false }));
  };

  const fetchSkillsList = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Skill",
      fields: JSON.stringify(["*"]),
      filters: [],
      limit: "*",
    };
    const res = await getGenericListApi(params);
    if (res?.status === 200) {
      if (res.data?.message?.length) {
        const data = addressMapper(res.data.message, "skill_name", "id", true);
        setSkillList(data);
      }
    }
    dispatch(setLoading({ loading: false }));
  };

  const onSelectChange = (selectedKeys, selectedRowsData) => {
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      id: record.id,
    }),
  };

  const handleAddRow = () => {
    setSkillRows((prev) => {
      return [
        ...prev,
        {
          id: skillRows.length,
          key: skillRows.length,
          number: skillRows.length,
          skill_name: "",
        },
      ];
    });
    skillsAppend({
      skill_name: "",
    });
  };

  const handleDeleteRow = () => {
    const skillData = [...skillRows];
    const data = [];
    let i = 0;
    skillData.forEach((skill, index) => {
      if (!selectedRowKeys.includes(skill.key)) {
        data.push({ ...skill, id: i, key: i, number: i });
        i++;
      }
    });
    setSkillRows(data);
    skillsRemove(selectedRowKeys);
    setSelectedRowKeys([]);
    const { skills } = getValues();
    reset({
      ...getValues(),
      skills: skills,
    });
    setIsSaveBtnDisable(false);
  };

  useEffect(() => {
    if (isEditMode) {
      fetchDesignation();
    }
  }, [isEditMode]);

  useEffect(() => {
    if (checkIfObjectNotEmpty(designationData)) {
      const { description } = getValues();
      description !== designationData?.description
        ? setIsSaveBtnDisable(false)
        : setIsSaveBtnDisable(true);
    }
  }, [descriptionWatch, designationData]);

  useEffect(() => {
    fetchSkillsList();
  }, []);

  return (
    <MainLayout>
      {loading && <LoadingBackdrop open={loading} />}
      <div className="interviewTypeContainer">
        <CustomBreadcrumb breadcrumbs={breadcrumbs} />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={1} alignItems="center">
                <GridMenuIcon className="gridIcon" />
                <Typography color="#0D1021" fontSize={22}>
                  {isEditMode ? "View Designation" : "New Designation"}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={3}>
                <Button
                  variant="contained"
                  color="grey"
                  className="qa-save-btn"
                  size="large"
                  onClick={() => navigate("/modules/hr/masters/designation")}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="qa-save-btn"
                  disabled={isSaveBtnDisable}
                >
                  {isEditMode ? "Update" : "Save"}
                </Button>
              </Stack>
            </Stack>
            <Stack sx={{ mt: 4, maxWidth: "20rem" }}>
              <Typography variant="bodyText" color="#012148" fontWeight={500} sx={{ mb: 1 }}>
                Designation*
              </Typography>
              <FormTextField
                name="designation_name"
                variant="outlined"
                size="small"
                fullWidth
                disabled={isEditMode || isFormSubmited}
                rules={{
                  required: validationRules.REQUIRED,
                }}
              />
            </Stack>
            <div>
              <Typography variant="bodyText" color="#012148" fontWeight={500} sx={{ mb: 1 }}>
                Description
              </Typography>
              <FormTextField
                name="description"
                variant="outlined"
                size="small"
                placeholder="Enter Text here..."
                fullWidth
                multiline
                rows={5}
              />
            </div>

            <div className="mt18 mb20">
              <Typography color="#0D1021" fontSize={16} fontWeight="500" sx={{ my: 2 }}>
                Required Skills
              </Typography>
              <Typography color="#575A6E" fontSize={14} fontWeight="500">
                Skills
              </Typography>
              <Table
                rowSelection={{ type: "checkbox", ...rowSelection }}
                columns={columns}
                dataSource={skillRows}
                size="small"
                pagination={false}
              />
              <div className="mt1">
                <IconButton color="primary" onClick={handleAddRow}>
                  <AddBoxIcon fill="#A32930" />
                </IconButton>
                {selectedRowKeys.length > 0 && (
                  <IconButton aria-label="delete" color="primary" onClick={handleDeleteRow}>
                    <DeleteOutlined />
                  </IconButton>
                )}
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </MainLayout>
  );
};

export default AddDesignation;

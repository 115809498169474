import React from 'react';
import useStyles from './style';
import { GridMenuIcon } from '@mui/x-data-grid';

const StudentTimeTable = () => {
  const styles = useStyles();
  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerContainer}>
        <div className={styles.breadCrumbsContainer}>
          <GridMenuIcon className={styles.gridIcon}/>
          <p className={styles.title}>Time Table</p>
        </div>
      </div>
    </div>
  )
}

export default StudentTimeTable;
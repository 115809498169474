import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTheme } from 'iiht-b2a-ui-components/build/components/Styles';
import FormHelperText from 'iiht-b2a-ui-components/build/components/FormHelperText';
import FormControl from 'iiht-b2a-ui-components/build/components/Form/FormControl';
import Button from 'iiht-b2a-ui-components/build/components/Button';
import Typography from 'iiht-b2a-ui-components/build/components/Typography';
import TechademyLogo from '../../assets/images/TechademyLogo.svg';
import { useNavigate } from 'react-router-dom';
import {forgotpassword} from '../../modules/auth'

// import { signInFormError } from '../../../utils/errorHelper';
import './index.scss';
import {
  Grid,
  Link,
  Paper,
  TextField,
} from 'iiht-b2a-ui-components/build/components';
// import { forgotPasswordRequest } from 'store';
// import { placeParams } from 'utils';
// import { ROUTES } from '../../../constants/routesConstants';
import { setResetPasswordEmail } from '../../slices/authSlice';

function ForgotPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // let history = useHistory();
  let dispatch = useDispatch();
  const theme = useTheme();

  const [tenantSponsorLogos, setTenantSponsorLogos] = useState({});
  // const [error, setError] = useState(signInFormError);
  const [username, setUsername] = useState('');
  const handleForgotPassword = async() => {

    
    const payload = {
      user:username,
    };

    const res = await forgotpassword({ payload });
    if (res.status === 200) {
      dispatch(setResetPasswordEmail(username));
      navigate("/forgotpasswordsuccess") 
    } else {
    }
  };
  const handleChangeUsername = (e) => {
    setUsername(e.target.value);
  }

  return (
    <>
      <div className="login-form-logo-wrapper">
        <div className="sign-in-section">
          {tenantSponsorLogos?.logo_image_url ? (
            <img
              className="tenantLogo"
              alt="Tenant Logo"
              src={tenantSponsorLogos?.logo_image_url}
            />
          ) : (
            <img
              className="defaultTenantLogo"KfK
              alt="Default Logo"
              src={TechademyLogo}
            />
          )}
          <form autoComplete="off" className="sign-form">
            <Paper elevation={0} className="sign-in-input-wrapper p-2 pt-4">
              <div className="forgot_password">
                <Typography variant="h4" sx={{ mb: 3, mt: 2 }} className="loginHead">
                  {t('forgotPassword?')}
                </Typography>
              </div>
              <div className="link_detaiils">
                <div>{t('forgottext')}</div>
              </div>

              <FormControl
                tabIndex={0}
                variant="outlined"
                sx={{ width: '80%' }}
                // error={error?.userNameOrEmailAddress}
              >
                <TextField
                  label={`${t('email')} *`}
                  id="userNameOrEmailAddress"
                  className="qa-sign-in-username"
                  type="email"
                  onChange={(e) => handleChangeUsername(e)}
                  autoComplete="off"
                />
                {/* <FormHelperText>
                  {error?.userNameOrEmailAddress
                    ? t('signIn.email.error')
                    : ' '}
                </FormHelperText> */}
              </FormControl>
              <br />
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className="mx10"
              >
                <Button
                  className="qa-sign-in-button"
                  variant="contained"
                  color="secondary"
                  tabIndex={5}
                  sx={{
                    width: '100%',
                    alignSelf: 'center',
                    color: theme.palette.common.white,
                  }}
                  onClick={handleForgotPassword}
                >
                  {t('sendLink')}
                </Button>
              </Grid>

              {/* <Grid
                item
                xs={12}
                display="flex"
                justifyContent="start"
                alignItems="center"
                className="mx101"
                sx={{ mb: 2 }}
              >
                {t('forgotyouemail')}
                <Typography color="#004FAF" sx={{ cursor: 'pointer', ml: 1 }}>
                  {t('phoneNo')}
                </Typography>
              </Grid> */}
              <br />
            </Paper>
          </form>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;

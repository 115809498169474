import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  cardContainer: {
    display: "inline-flex",
    justifyContent: "space-between"
  },
  card: {
    width: "23%",
    display: "flex", 
    flexDirection: "column",
    gap: "28px",
    padding: "12px 14px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)"
  },
  cardTitle: {
    color: "#0D1021",
    fontSize: "16px",
    fontWeight: "600"
  },
  title: {
    fontSize: "18px", 
    color: "#0D1021", 
    fontWeight: "500"
  },
  cardContent: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  cardIcon: {
    color: "#ac0521",
    height: "32px",
    width: "40px"
  },
  cardContentText: {
    color: "#0D1021",
    fontSize: "14px",
    fontWeight: "500"
  }
}));

export default useStyles;

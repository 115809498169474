import React, { useState, useEffect } from 'react';
import { Table, Select, Input } from "antd";
import useStyles from './style';
import { useNavigate } from 'react-router-dom';
import { GridMenuIcon } from '@mui/x-data-grid';
import MainHeader from '../../../common/MainHeader';
import MainFooter from '../../../common/MainFooter';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveSidebar } from '../../../../slices/financeSlice.js';
import LoadingBackdrop from "../../../common/LoadingBackdrop.js";
import { createMeritCriteria, getMeritCategoryByName, getMeritCategoryListing } from '../../../../modules/admission.js';
import { toast } from 'react-toastify';

const AddNewMeritListCriteria = () => {
  const selectSize = "middle";
  const styles= useStyles();
  const navigate= useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [values, setValues] = useState({
    name1: "",
    weightage_12: "",
    weightage_10: "",
    criteria_template: null,
    criteria: []
  });
  const [errors, setErrors] = useState({
    name1: "",
    weightage_12: "",
    weightage_10: "",
    criteria_template: "",
  });

  const columns = [
    {
      dataIndex: "studentCategory",
      title: "STUDENT CATEGORY",
      width: 1,
      render: (val, row) => {
        return (
            <p className={styles.tableContentFields}>{row.studentCategory}</p>
        );
      },
    },
    {
      dataIndex: "percentage",
      title: "PERCENTAGE",
      width: 1,
      render: (val, row) => {
        return (
            <p className={styles.tableContentFields}>{row.percentage}</p>
        );
      },
    },
    {
      dataIndex: "id",
      title: "ACTION",
      width: 0.5,
      render: (val, row) => {
        return (
            <p className={`${styles.tableContentView} ${styles.link}`} onClick={() => navigate(`/modules/admission/merit-list-category/${values.criteria_template}`)}>View</p>
        );
      },
    },
  ];

  const handleMeritListNavigation = () => {
    dispatch(setActiveSidebar({ activeSidebar: 3 }));
    navigate("/modules/admission");
  };

  const handleGetMeritCategoryListing = async () => {
    const filters = [];
    const res = await getMeritCategoryListing(filters);
    if(res.status === 200) {
      const arr = [];
      const categoryList = res.data.data;
      for(let i = 0 ; i < categoryList.length ; i++) {
        arr.push({
          value: categoryList[i].name,
          label: categoryList[i].name
        });
      };
      setCategoryOptions(arr);
    };
  };

  const handleGetMeritCategoryByName = async ({ name }) => {
    const res = await getMeritCategoryByName({ name });
    if(res.status === 200) {
      const meritListCategory = [];
      const arr = [];
      for(let i = 0 ; i < res.data.data.options.length ; i++) {
        meritListCategory.push({
          id: i,
          studentCategory: res.data.data.options[i].student_category,
          percentage: res.data.data.options[i].percentage,
          key: i
        });
        arr.push({
          student_category: res.data.data.options[i].student_category,
          allocation_percentage: res.data.data.options[i].percentage,
        });
      };
      setValues({
        ...values,
        criteria_template: name,
        criteria: [...arr]
      })
      setRowData(meritListCategory);
    } 
  };

  const handleCreate = async () => {
    try {
      const res = await createMeritCriteria({ payload: values });
      if(res.status === 200) {
        toast.success("Created!");
        navigate("/modules/admission/merit-list-criteria");
      } else {
        toast.error("Some issue while creating!");
      }
    } catch (error) {
      toast.error(error.response.data.exception);
    }
  }

  useEffect(() => {
    handleGetMeritCategoryListing();
  }, []);

  return (
    <>
      <MainHeader />
      {
        loading ?
          <LoadingBackdrop open={loading} /> :
          <div className={styles.mainContainer}>
            <div className={styles.breadCrumbsContainer}>
              <p className={styles.breadCrumbsContent} onClick={() => navigate("/dashboard")}>IIHT {" > "}</p>
              <p className={styles.breadCrumbsContent} onClick={() => navigate("/modules/admission")}>Admission {" > "}</p>
              <p className={styles.breadCrumbsContent} onClick={handleMeritListNavigation}>Merit List Tool {" > "}</p>
              <p className={styles.breadCrumbsContent} onClick={() => navigate("/modules/admission/merit-list-criteria")}>Merit List Criteria</p>
            </div>
            <div className={styles.headerContainer}>
              <div className={styles.breadCrumbsContainer}>
                <GridMenuIcon className={styles.gridIcon}/>
                <p className={styles.title}>New Merit List Criteria</p>
              </div>
              <div className={styles.buttonsGroup}>
                <div className={styles.button} onClick={() => navigate("/modules/admission/merit-list-criteria")}>
                  <p className={styles.buttonText}>Back</p>
                </div>
                <div className={styles.activeButton}>
                  <p className={styles.activeButtonText} onClick={handleCreate}>Save</p>
                </div> 
              </div>
            </div>
            <div className={styles.formContainer}>
              <div className={styles.formRow}>
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>Name *</p>
                  <Input
                    type="text" 
                    placeholder="ex Text"
                    className={styles.fieldInput}
                    value={values.name1}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        name1: e.target.value,
                      });
                      setErrors({
                        ...errors,
                        name1: "",
                      });
                    }}
                  />
                </div>
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>10<sup>th</sup> Weightage  *</p>
                  <Input
                    type="text" 
                    placeholder="ex 40"
                    className={styles.fieldInput}
                    value={values.weightage_10}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        weightage_10: Number(e.target.value),
                      });
                      setErrors({
                        ...errors,
                        weightage_10: "",
                      });
                    }}
                  />
                </div>
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>12<sup>th</sup> Weightage  *</p>
                  <Input
                    type="text" 
                    placeholder="ex 60"
                    className={styles.fieldInput}
                    value={values.weightage_12}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        weightage_12: Number(e.target.value),
                      });
                      setErrors({
                        ...errors,
                        weightage_12: "",
                      });
                    }}
                  />
                </div>
              </div>
              <div className={styles.formRow}>
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>Merit Category Template *</p>
                  <Select
                    showSearch
                    size={selectSize}
                    placeholder="Select"
                    className={styles.selectInput}
                    options={categoryOptions}
                    value={values.criteria_template}
                    onChange={(val) => {
                      handleGetMeritCategoryByName({ name: val });
                      setValues({
                        ...values,
                        criteria_template: val,
                      });
                      setErrors({
                        ...errors,
                        criteria_template: "",
                      });
                    }}
                  />
                </div>
              </div>
              {
                values.criteria_template && 
                <div className={styles.formRow}>
                  <div className={styles.tableField}>
                    <Table 
                      columns={columns} 
                      dataSource={rowData} 
                      size='small'
                      pagination={false}
                    />
                  </div>
              </div>
              }
            </div>
          </div>
      }
      <MainFooter />
    </>
  )
};

export default AddNewMeritListCriteria;
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Breadcrumbs, MainLayout } from "components/common";
import { ColorPicker } from "antd";
import {
  getGenericData,
  createGenericEntity,
  updateGenericEntity,
  getInstitution,
  getLeaveType,
} from "modules/hr";
import { Input, Button, Table, message, Checkbox} from "antd";
import { EditOutlined } from "@ant-design/icons";
import useStyles from './style.js';
import { Modal,Select } from 'antd';
import { Typography } from "@mui/material";
import {  getUserDetails } from "modules/lead.js";
import { toast } from "react-toastify";



const HolidayListDetails = () => {
  const { id } = useParams();
  const styles= useStyles();
  const navigate = useNavigate();
  const [leaveBlockList, setLeaveBlockList] = useState({
    leave_block_list_name: "",
    company: "",
    applies_to_all_departments: false,
    leave_type: "",
    leave_block_list_dates: [],
    leave_block_list_allowed:[],
    doctype: "Leave Block List",
    // add_day_wise_dates:'',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [ institution,setInstitution ] = useState([]);
  const [ leaveType,setLeaveType ] = useState([]);

  const [selectedColor, setSelectedColor] = useState(null);
  const [isViewMode, setIsViewMode] = useState(false);
  const [errors, setErrors] = useState({});
  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalCheckboxes, setModalCheckboxes] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });

  const [userListOptions, setUserListOptions] = useState([]);
  const [selectedWeekdays, setSelectedWeekdays] = useState([]);

  const showModal = () => {
    setIsModalVisible(true);
  };
  
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    handleGetUserDetails();
    handleGetInstitution();
    handleGetLeaveType();
  }, []);

  const handleGetUserDetails = async () => {
    const res = await getUserDetails();
    if (res.status === 200) {
      const arr = [];
      for(let i = 0; i < res.data.data.length ; i++) {
        arr.push({
          value: res.data.data[i].name,
          label: res.data.data[i].name
        })
      }

      setUserListOptions(arr);
    }
  };

  const handleAddRow = (tableType) => {
    // Append a new row to the specified table
    const newRow = {
      // Define properties for the new row based on the table type
    };
  
    if (tableType === "leave_block_list_dates") {
      setLeaveBlockList((prevState) => ({
        ...prevState,
        leave_block_list_dates: [...prevState.leave_block_list_dates, newRow],
      }));
    } else if (tableType === "leave_block_list_allowed") {
      setLeaveBlockList((prevState) => ({
        ...prevState,
        leave_block_list_allowed: [...prevState.leave_block_list_allowed, newRow],
      }));
    }
  };

  const handleSelectChange = (value, index) => {
    const updatedAllowedUsers = [...leaveBlockList.leave_block_list_allowed];
    updatedAllowedUsers[index].allow_user = value; 
    setLeaveBlockList((prevState) => ({
      ...prevState,
      leave_block_list_allowed: updatedAllowedUsers,
    }));
  };

  const handleFieldChange = (name, value) => {
    setLeaveBlockList((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  
  const handleGetInstitution = async() => {
    const res = await getInstitution();
    if (res.status === 200) {
      const arr = [];
      for(let i = 0; i < res.data.data.length ; i++) {
        arr.push({
          value: res.data.data[i].name,
          label: res.data.data[i].name
        })
      }

      setInstitution(arr);
    }
  }

  const handleGetLeaveType = async() => {
    
  const payload = {
    doctype:"Leave Type",
  }
    const res = await getLeaveType( payload );
    if (res.status === 200) {
      const arr = [];
      for(let i = 0; i < res.data.data.length ; i++) {
        arr.push({
          value: res.data.data[i].name,
          label: res.data.data[i].name
        })
      }

      setLeaveType(arr);
    }
  }



  const handleDateCheckboxChange = (index, checked) => {
    const updatedData = [...leaveBlockList.leave_block_list_dates];
    
    // Check if the item at the specified index exists
    if (updatedData[index]) {
      updatedData[index].checked = checked;
      setLeaveBlockList((prevState) => ({
        ...prevState,
        leave_block_list_dates: updatedData,
      }));
    }
  };
  

  const handleAllowCheckboxChange = (index, checked) => {
    const updatedData = [...leaveBlockList.leave_block_list_allowed];
    updatedData[index].checked = checked;
    setLeaveBlockList((prevState) => ({
      ...prevState,
      leave_block_list_allowed: updatedData,
    }));
  };
  
  const handleDateSelectAllChange = (e) => {
    const checked = e.target.checked;
    const updatedData = leaveBlockList.leave_block_list_dates.map((item) => ({
      ...item,
      checked: checked,
    }));
    setLeaveBlockList((prevState) => ({
      ...prevState,
      leave_block_list_dates: updatedData,
    }));
    setSelectAll(checked);
  };
  
  const handleAllowedSelectAllChange = (e) => {
    const checked = e.target.checked;
    const updatedData = leaveBlockList.leave_block_list_allowed.map((item) => ({
      ...item,
      checked: checked,
    }));
    setLeaveBlockList((prevState) => ({
      ...prevState,
      leave_block_list_allowed: updatedData,
    }));
    setSelectAll(checked);
  };
  const handleEditClick = (index) => {
    setEditableRowIndex(index);
  };

  const handleSaveClick = (index) => {
    setEditableRowIndex(null);
  };

  const handleCancelClick = () => {
    setEditableRowIndex(null);
  };

  const isRowEditable = (index) => {
    return editableRowIndex === index;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          const leaveBlockListData = await getGenericData("Leave Block List", id);
          setLeaveBlockList(leaveBlockListData.message);
          setIsViewMode(true);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching Leave Block list:", error);
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      // Cleanup if needed
    };
  }, [id]);

  const handleSave = async () => {
    try {
      // Define the doctype
      const doctype = "Leave Block List";

      // Validate fields
      const validationErrors = validateFields();

      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }
      const payload = {
        ...leaveBlockList,
        doctype,
      };

      let res;
      if (id) {
        res = await updateGenericEntity({ payload });
      } else {
        res = await createGenericEntity({ payload });
      }
  
      if (res.status === 200) {
        toast.success(id ? "Leave Block List Updated!" : "Leave Block List Created!");
        navigate(-1);
      } else {
        if (res.data && res.data.message) {
          toast.error(res.data.message);
        } else {
          toast.error("Failed to save Leave Block. Please try again later.");
        }
      }
    } catch (error) {
      console.error("Error creating Leave Block List:", error);
    }
  };

  const renderDeleteButton = (tableType) => {
    const anyChecked = tableType === "leave_block_list_dates"
      ? leaveBlockList.leave_block_list_dates?.some((item) => item.checked)
      : leaveBlockList.leave_block_list_allowed?.some((item) => item.checked);

    if (anyChecked) {
      return (
        <Button onClick={() => handleDeleteLeave(tableType)} className="deleteRow">
          {"Delete"}
        </Button>
      );
    }
    return null;
  };

  const handleDeleteLeave = (tableType) => {
    let updatedList;
    if (tableType === "leave_block_list_dates") {
      updatedList = leaveBlockList.leave_block_list_dates.filter(
        (item) => !item.checked
      );
      setLeaveBlockList((prevState) => ({
        ...prevState,
        leave_block_list_dates: updatedList,
      }));
    } else if (tableType === "leave_block_list_allowed") {
      updatedList = leaveBlockList.leave_block_list_allowed.filter(
        (item) => !item.checked
      );
      setLeaveBlockList((prevState) => ({
        ...prevState,
        leave_block_list_allowed: updatedList,
      }));
    }
    setSelectAll(false);
  };
  


  const validateFields = () => {
    const errors = {};

    if (!leaveBlockList.leave_block_list_name.trim()) {
      errors.holiday_list_name = "Leave Block List Name is required";
    }

    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLeaveBlockList((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddHoliday = () => {
    // Append a new holiday to the holidays array
    const newHoliday = {
      id: "",
      holiday_date: "",
      description: "",
      weekly_off: "",
    };
    setLeaveBlockList((prevState) => ({
      ...prevState,
      holidays: [...prevState.holidays, newHoliday],
    }));
  };

  const handleHolidayChange = (index, e) => {
    const { name, value } = e.target;
    const updatedHolidays = [...leaveBlockList.leave_block_list_dates];
    updatedHolidays[index][name] = value;
    setLeaveBlockList((prevState) => ({
      ...prevState,
      leave_block_list_dates: updatedHolidays,
    }));
  };

  const handleCancel = () => {
    navigate(-1);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const columns = [
    {
      title: () => (
        <Checkbox checked={selectAll} onChange={handleDateSelectAllChange} />
      ),
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => (
        <Checkbox
          checked={record.checked}
          onChange={(e) => handleDateCheckboxChange (index, e.target.checked)}
        />
      ),
    },
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "BLOCK DATE",
      dataIndex: "block_date",
      key: "block_date",
      render: (text, record, index) =>
        isRowEditable(index) ? (
          <input
            type="date"
            value={text}
            onChange={(e) => handleHolidayChange(index, e)}
            name="block_date"
          />
        ) : (
          text
        ),
    },
    {
      title: "REASON",
      dataIndex: "reason",
      key: "reason",
      render: (text, record, index) =>
        isRowEditable(index) ? (
          <Input
            type="text"
            value={text}
            onChange={(e) => handleHolidayChange(index, e)}
            name="description"
          />
        ) : (
          text
        ),
    },
    {
      title: "Action",
      dataIndex: "actions",
      key: "actions",
      render: (text, record, index) => (
        <div>
          {editableRowIndex === index ? (
            <div className="saveCancel">
              <Button onClick={() => handleSaveClick(index)} type="primary">
                Save
              </Button>
              <Button onClick={handleCancelClick}>Cancel</Button>
            </div>
          ) : (
            <Button
              onClick={() => handleEditClick(index)}
              type="link"
              // icon={<EditOutlined />}
            >
              {"Edit"}
            </Button>
          )}
        </div>
      ),
    },
  ];
  const columns1 = [
    {
      title: () => (
        <Checkbox checked={selectAll} onChange={handleAllowedSelectAllChange} />
      ),
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => (
        <Checkbox
          checked={record.checked}
          onChange={(e) => handleAllowCheckboxChange(index, e.target.checked)}
        />
      ),
    },
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "ALLOW USER",
      dataIndex: "allow_user",
      key: "allow_user",
      render: (text, record, index) => (
        <Select
          options={userListOptions}
          onChange={(value) => handleSelectChange(value, index)}
          value={text}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "actions",
      key: "actions",
      render: (text, record, index) => (
        <div>
          {editableRowIndex === index ? (
            <div className="saveCancel">
              <Button onClick={() => handleSaveClick(index)} type="primary">
                Save
              </Button>
              <Button onClick={handleCancelClick}>Cancel</Button>
            </div>
          ) : (
            <Button
              onClick={() => handleEditClick(index)}
              type="link"
              // icon={<EditOutlined />}
            >
              {"Edit"}
            </Button>
          )}
        </div>
      ),
    },
  ];

  
  
  const handleModalSelectAll = () => {
    const updatedModalCheckboxes = {};
    Object.keys(modalCheckboxes).forEach((day) => {
      updatedModalCheckboxes[day] = true;
    });
    setModalCheckboxes(updatedModalCheckboxes);

    // Update selected weekdays in state
    setLeaveBlockList((prevState) => ({
      ...prevState,
      selectedWeekdays: Object.keys(updatedModalCheckboxes),
    }));
  };

  // Function to handle unselecting all days
  const handleModalUnselectAll = () => {
    const updatedModalCheckboxes = {};
    Object.keys(modalCheckboxes).forEach((day) => {
      updatedModalCheckboxes[day] = false;
    });
    setModalCheckboxes(updatedModalCheckboxes);

    // Update selected weekdays in state to empty array
    setLeaveBlockList((prevState) => ({
      ...prevState,
      selectedWeekdays: [],
    }));
  };

  // Function to handle checkbox change
  const handleModalCheckboxChange = (day, checked) => {
    setModalCheckboxes((prevState) => ({
      ...prevState,
      [day]: checked,
    }));

    // Update selected weekdays based on checkbox change
    let updatedSelectedWeekdays = [...selectedWeekdays];
    if (checked && !updatedSelectedWeekdays.includes(day)) {
      updatedSelectedWeekdays.push(day);
    } else if (!checked && updatedSelectedWeekdays.includes(day)) {
      updatedSelectedWeekdays = updatedSelectedWeekdays.filter((weekday) => weekday !== day);
    }
    setSelectedWeekdays(updatedSelectedWeekdays);
  };

  
  const getDatesBetween = (startDate, endDate, targetDays) => {
    const dates = [];
    let currentDate = new Date(startDate);
    endDate = new Date(endDate);
  
    while (currentDate <= endDate) {
      const currentDay = currentDate.getDay();
      if (targetDays.includes(currentDay)) {
        dates.push(new Date(currentDate).toISOString().slice(0, 10));
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
  
    return dates;
  };

  const getWeekdayNumber = (weekday) => {
    switch (weekday.toLowerCase()) {
      case 'sunday':
        return 0;
      case 'monday':
        return 1;
      case 'tuesday':
        return 2;
      case 'wednesday':
        return 3;
      case 'thursday':
        return 4;
      case 'friday':
        return 5;
      case 'saturday':
        return 6;
      default:
        return -1; // Invalid input
    }
  };


    // Inside handleAddLeave function
    const handleAddLeave = () => {
      // Retrieve start date, end date, reason, and selected weekdays from inputs
      const startDate = document.getElementById("startDateInput").value;
      const endDate = document.getElementById("endDateInput").value;
      const reason = document.getElementById("reasonInput").value;
      const selectedWeekdaysNumbers = Object.keys(modalCheckboxes)
        .filter(day => modalCheckboxes[day])
        .map(getWeekdayNumber) // Convert weekday strings to numbers
        .filter(day => day >= 0); // Remove invalid entries
    
      // Filter dates based on selected weekdays within the range
      const filteredDates = getDatesBetween(startDate, endDate, selectedWeekdaysNumbers);
      console.log(filteredDates);
    
      // Update the state with the filtered dates
      setLeaveBlockList((prevState) => ({
        ...prevState,
        leave_block_list_dates: [
          ...prevState.leave_block_list_dates,
          ...filteredDates.map((date) => ({ block_date: date, reason: reason })),
        ],
      }));
    
      // Close the modal after adding dates
      setIsModalVisible(false);
    };
    
      
  

  return (
    <MainLayout>
      <Breadcrumbs />
      <div className="master-heading">
        <h2>{isViewMode ? "View Leave Block List" : "New Leave Block List"}</h2>
        <div className="btnsGrp">
          <Button variant="outlined" color="secondary" onClick={handleCancel}>
            back
          </Button> 
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            className="redBtn" 
          >
            {isViewMode ? "Save" : "Update"}
          </Button>
        </div>
      </div>
      <div className="formApplication">
      <section className="form-section">
          <div className="fields-block">
            <div className="form-field">
              <label>Leave Block List Name *</label>
              <input
                type="text"
                name="leave_block_list_name"
                placeholder="ex Text"
                value={leaveBlockList.leave_block_list_name}
                onChange={handleChange}
              />
              {errors.leave_block_list_name && (
                <span className="error">{errors.leave_block_list_name}</span>
              )}
            </div>
            <div className="form-field">
              <label>Leave Type </label>
              <Select
                name="leave_type"
                placeholder="Select Leave Type"
                options={leaveType}
                value={leaveBlockList.leave_type}
                onChange={(value) => handleFieldChange("leave_type", value)}
              />
            </div>
            <div className="form-field">
              <label>Institution *</label>
              <Select
                name="company"
                placeholder="Select Institution"
                options={institution}
                value={leaveBlockList.company}
                onChange={(value) => handleFieldChange("company", value)}
              />
            </div>
            </div>
            <div className={styles.formContainer}>
              <div className={styles.field}>
                <div className={styles.checkboxContainer}>
                  <input
                    type="checkbox"
                    name="applies_to_all_departments"
                    checked={leaveBlockList.applies_to_all_departments}
                    onChange={(e) =>
                      setLeaveBlockList({
                        ...leaveBlockList,
                        applies_to_all_departments: e.target.checked,
                      })
                    }
                  />
                  <p className={styles.fieldLabel}>Applies to Company</p>
                </div>
              </div>
            </div>
        </section>
        
        <section className="form-section">
          <h2 className="">Block Days</h2>
          <hr className={styles.line}></hr>
          <span>Stop users from making Leave Applications on following days.</span>
          <div style={{ marginTop:'10px'}}>
          <div className={styles.activeButton} onClick={showModal}>
            <p className={styles.activeButtonText}>Add Day-wise Dates</p>
         </div>
          </div>
          <h4> {"Leave Block List Dates"}</h4>
          <Table dataSource={leaveBlockList.leave_block_list_dates} columns={columns} />
          <div className="addDeleteBtns">
              {renderDeleteButton("leave_block_list_dates")}
              <Button onClick={() => handleAddRow("leave_block_list_dates")} className="addRow">
                {"+"}
              </Button>
          </div>
        </section>
        <section className="form-section">
          <h2 className="">Allow Users</h2>
          <hr className={styles.line}></hr>
          <span>Allow the following users to approve Leave Applications for block days.</span>
          <h4> {"Leave Block List Allowed"}</h4>
          <Table dataSource={leaveBlockList.leave_block_list_allowed} columns={columns1} />
          <div className="addDeleteBtns">
            {renderDeleteButton("leave_block_list_allowed")}
              <Button onClick={() => handleAddRow("leave_block_list_allowed")} className="addRow">
                {"+"}
              </Button>
          </div>  
        </section>
      </div>
      <Modal
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        <h2>Add Leave Block Dates</h2>
        <div style={{ display: "flex", flexDirection: "column", marginTop: "30px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "50%" }}>
              <Typography>Start Date *</Typography>
              <input type="date" className={styles.fieldInput} id="startDateInput"/>
            </div>
            <div style={{ width: "50%" }}>
              <Typography>End Date *</Typography>
              <input type="date" className={styles.fieldInput} id="endDateInput" />
            </div>
          </div>
          <div style={{ marginTop: "16px", marginBottom: "16px", display: "flex", gap: "10px" }}>
            <Button onClick={handleModalSelectAll}>Select All</Button>
            <Button onClick={handleModalUnselectAll}>Unselect All</Button>
          </div>
          {/* Render checkboxes for weekdays */}
          {Object.keys(modalCheckboxes).map((day) => (
            <div key={day} style={{ marginBottom: "8px" }}>
              <Checkbox
                checked={modalCheckboxes[day]}
                onChange={(e) => handleModalCheckboxChange(day, e.target.checked)}
              />
              <span style={{ marginLeft: "8px" }}>{day}</span>
            </div>
          ))}
          <Typography>Reason *</Typography>
          <textarea className={styles.textAreaInput} placeholder="Enter Text here ..." 
          rows={4} style={{ marginBottom: "16px" }} 
          id="reasonInput" />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="primary" style={{ width: "15%", background: "#A32930" }} onClick={handleAddLeave}>Add</Button>
          </div>
        </div>
      </Modal>


    </MainLayout>
  );
};

export default HolidayListDetails;

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: "100vw",
    height: "100vh",
    position: "fixed",
    backgroundColor: "rgba(0,0,0,0.7)",
    backdropFilter: "blur(2px)",
    zIndex: "99",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "25px",
  },
  dialog: {
    width: "fit-content",
    height: "fit-content",
    overflow: "auto",
    maxHeight: "100vh",
    minWidth: "200px",
    minHeight: "150px",
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
  },
}));

export default useStyles;

import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import CircularProgress from '@mui/material/CircularProgress';
import IconSvg from "assets/images/units-content-management.svg";
import { getChaptersList, getTopicsList } from "modules/masters.js";
import useStyles from "./styles.js";
import GetTopicDetails from "./topic-view.js";

const CourseContentManagement = ({ details }) => {
  const styles = useStyles();
  const [units, setUnits] = useState([]);
  const [topics, setTopics] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("");
  const [selectedChapter, setSelectedChapter] = useState("");
  const [topicDetails, setTopicDetails] = useState({});
  const [chaptersLoading, setChaptersLoading] = useState(false);
  const [topicsLoading, setTopicsLoading] = useState(false);

  const handleGetChapters = async (unit) => {
    const res = await getChaptersList({ unit });
    if(res.status === 200) {
      if(res.data.message) setChapters(res.data.message);
      else setChapters([]);
    }
    setChaptersLoading(false);
  };

  const handleGetTopics = async (chapter) => {
    const res = await getTopicsList();
    if(res.status === 200) {
      if(res.data.message){
        const topics = res.data.message;
        const filteredTopics = topics.filter((topic) => topic.chapter === chapter);
        setTopics(filteredTopics);
      }
      else setTopics([]);
    }
    setTopicsLoading(false);
  };

  const handleSelectUnit = (unit) => {
    setChaptersLoading(true);
    setSelectedUnit(unit);
    setSelectedChapter("");
    setSelectedTopic("");
    setChapters([]);
    setTopics([]);
    handleGetChapters(unit);
  };

  const handleSelectChapter = (chapter) => {
    setTopicsLoading(true);
    setSelectedChapter(chapter);
    setSelectedTopic("");
    setTopics([]);
    handleGetTopics(chapter);
  };

  const handleSelectTopic = (topic) => {
    setSelectedTopic(topic.id);
    setTopicDetails(topic);
  }

  useEffect(() => {
    setUnits(details.topics);
    if(details.topics.length > 0) {
      setSelectedUnit(details.topics[0].topic);
    }
  }, [details]);

  return (
    <>
      {
        Object.keys(topicDetails).length > 0 ?
        <GetTopicDetails details={topicDetails} setSelectedTopic={setSelectedTopic} setTopicDetails={setTopicDetails}/> :
        <div className={styles.mainContainer}>
          <div className={styles.headingContainer}>
            <div className={styles.headingField}>
              <p className={styles.headingText}>Unit</p>
            </div>
            <div className={styles.headingField}>
              <p className={styles.headingText}>Chapter</p>
            </div>
            <div className={styles.headingField}>
              <p className={styles.headingText}>Topic</p>
            </div>
          </div>
          <div className={styles.headingContainer}>
            <div className={styles.contentContainer}>
            {
              units.length > 0 ? (
                units.map((unit) => {
                  const { topic, topic_name } = unit;
                  return (
                    <div className={topic === selectedUnit ? styles.selectedContentField : styles.contentField} onClick={() => handleSelectUnit(topic)}>
                      <ReactSVG src={IconSvg} />
                      <p className={styles.contentText}>{topic_name}</p>
                    </div>
                  );
                })
              ) : <p className={styles.contentText}>No Units</p>
            }
            </div>
            <div className={styles.contentContainer}>
            {
              chaptersLoading ? 
              <CircularProgress /> :
              chapters.length > 0 ? (
                chapters.map((chapter) => {
                  const { id, name } = chapter;
                  return (
                    <div className={id === selectedChapter ? styles.selectedContentField : styles.contentField} onClick={() => handleSelectChapter(id)}>
                      <ReactSVG src={IconSvg} />
                      <p className={styles.contentText}>{name}</p>
                    </div>
                  );
                })
              ) : selectedUnit && <p className={styles.contentText}>No Chapters Under this Unit</p>
            }
            </div>
            <div className={styles.contentContainer}>
            {
              topicsLoading ? 
              <CircularProgress /> :
              topics.length > 0 ? (
                topics.map((topic) => {
                  const { id } = topic;
                  return (
                    <div className={id === selectedTopic ? styles.selectedContentField : styles.contentField} onClick={() => handleSelectTopic(topic)}>
                      <ReactSVG src={IconSvg} />
                      <p className={styles.contentText}>{id}</p>
                    </div>
                  );
                })
              ) : selectedChapter && <p className={styles.contentText}>No Topics under this Chapter</p>
            }
            </div>
          </div>
        </div>
      }
      
    </>
  );
};

export default CourseContentManagement;

import React, { useEffect, useState } from "react";
import useStyles from "../style";
import { useNavigate, useParams } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import MainHeader from "../../../common/MainHeader";
import MainFooter from "../../../common/MainFooter";
import { useDispatch, useSelector } from "react-redux";
import LoadingBackdrop from "../../../common/LoadingBackdrop.js";
import { toast } from "react-toastify";
import { Select, Input, Modal } from "antd";
import { getFeedbackAssignmentByID } from "modules/survey";

const GetUpdateFeedbackAssignment = () => {
  const styles = useStyles();
  const selectSize = "middle";
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [studentGroupOptions, setStudentGroupOptions] = useState([]);
  const [feebackTemplateOptions, setFeedbackTemplateOptions] = useState([]);
  const [values, setValues] = useState({
    template_name: "",
    assigned_based_on: "",
    student_group: [],
    publish_on: "",
    last_date_of_submission: "",
  });

  const handleBackNavigation = () => {
    navigate("/modules/survey");
  };

  const handleGetFeedbackTemplateByID = async () => {
    const res = await getFeedbackAssignmentByID(id);
    const feedbackList = res.data.data;
    console.log(res);
    if (res.status === 200) {
      const studentGroups = feedbackList.student_group.map(
        (studentgroup) => studentgroup.student_group
      );
      setValues({
        template_name: feedbackList.template_name,
        assigned_based_on: feedbackList.assigned_based_on,
        student_group: studentGroups,
        publish_on: feedbackList.publish_on,
        last_date_of_submission: feedbackList.last_submission,
      });
    }
  };

  useEffect(() => {
    handleGetFeedbackTemplateByID();
  }, [id]);

  return (
    <>
      <MainHeader />
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/dashboard")}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/modules/survey")}
            >
              Survey {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={handleBackNavigation}
            >
              {id}
            </p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>{id}</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div className={styles.button} onClick={handleBackNavigation}>
                <p className={styles.buttonText}>Back</p>
              </div>
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Template Name </p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  disabled={true}
                  value={values.template_name}
                  className={styles.selectInput}
                  options={feebackTemplateOptions}
                  onChange={(value) => {
                    const selectedTemplate = feebackTemplateOptions.find(
                      (template) => template.value === value
                    );
                    if (selectedTemplate) {
                      setValues((prevState) => ({
                        ...prevState,
                        template_name: value,
                        assigned_based_on: selectedTemplate.assigned_based_on,
                      }));
                    } else {
                      setValues((prevState) => ({
                        ...prevState,
                        template_name: value,
                        assigned_based_on: "",
                      }));
                    }
                  }}
                  showSearch
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Assigned Based On </p>
                <Select
                  placeholder="Assign"
                  disabled={true}
                  value={values.assigned_based_on}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      assigned_based_on: value,
                    });
                  }}
                >
                  <Select.Option value="Student">Student</Select.Option>
                  <Select.Option value="Faculty">Faculty</Select.Option>
                </Select>
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Student Group </p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  disabled={true}
                  value={values.student_group}
                  className={styles.selectInput}
                  options={studentGroupOptions}
                  onChange={(selectedValues) => {
                    setValues({
                      ...values,
                      student_group: selectedValues,
                    });
                  }}
                  showSearch
                  mode="multiple"
                />
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Publish On</p>
                <input
                  type="date"
                  value={values.publish_on}
                  placeholder="YYYY-MM-DD"
                  disabled={true}
                  className={styles.dateInput}
                  onChange={(e) => {
                    const dateString = e.target.value;
                    setValues({ ...values, publish_on: dateString });
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Last Date Of Submission</p>
                <input
                  type="date"
                  value={values.last_date_of_submission}
                  placeholder="YYYY-MM-DD"
                  disabled={true}
                  className={styles.dateInput}
                  onChange={(e) => {
                    const dateString = e.target.value;
                    setValues({
                      ...values,
                      last_date_of_submission: dateString,
                    });
                  }}
                />
              </div>
              <div className={styles.field}></div>
            </div>
          </div>
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default GetUpdateFeedbackAssignment;

import Landing from "../screens/Landing";
import Login from "../screens/Login";
import ForgotPassword from "../screens/ForgotPassword";
import ResetPassword from "../screens/ResetPassword";
import ForgotPasswordSuccess from "../components/ForgotPasswordSuccess/forgot_password_success";
import Calender from "../components/Calender";
import ApplicationForm from "../components/ApplicationForm";
import LeadProfile from "../components/Admission/LeadManagement/components/LeadProfile";
import DynamicForm from "../components/CommonJsonForm/dynamicFormData";
import ApplicationDetails from "../components/Admission/ApplicationManagement/components/index";
import Payment from "../screens/Applications/Payment";
import Receipt from "../screens/Receipt/receipt";
import MasterPage from "../components/MasterPage/EntityView";
import EntityList from "../components/MasterPage/EntityList";
import ModulesList from "../screens/Modules";
import Academics from "../screens/Academics";
import Finance from "../screens/Finance";
import AddNewFeeCategory from "../components/Finance/FeeCategory/add-new-fee-category";
import GetUpdateFeeCategory from "../components/Finance/FeeCategory/get-update-fee-category";
import AddNewFeeStructure from "../components/Finance/FeeStructure/add-new-fee-structure";
import AddNewFeeSchedule from "../components/Finance/FeeSchedule/add-new-fee-schedule";
import GetUpdateFeeStructure from "../components/Finance/FeeStructure/get-update-fee-structure";
import Admission from "../screens/Admissions";
import JobApplicationListing from "../screens/Recruitment/JobApplicationListing";
import JobApplicationDetails from "../screens/Recruitment/index";
import HrForm from "../screens/HrForm/index";
import StudentGroupComponent from "../screens/Academics/StudentGroup/StudentGroup";

import AddNewMeritList from "../components/Admission/MeritList/add-new-merit-list";
import GetAndUpdateMeritList from "../components/Admission/MeritList/get-merit-list";
import LeadDashboard from "../components/Admission/LeadManagement/components";
import AddNewPaymentRequest from "../components/Finance/PaymentRequest/add-new-payment-request";
import GetUpdatePaymentRequest from "../components/Finance/PaymentRequest/get-update-payment-request";
import JobCreationList from "../components/Hr/HrRecruit/JobOpening/JobCreationList.js";
import AddJobOpening from "../components/Hr/HrRecruit/JobOpening/AddjobOpening/AddJobOpening.js";

import AddNewPaymentEntry from "../components/Finance/PaymentEntry/add-new-payment-entry";
import GetUpdatePaymentEntry from "../components/Finance/PaymentEntry/get-update-payment-entry";
import Career from "../screens/Career/Career";
import JobOpening from "../screens/Career/JobOpening";
import AddNewFee from "../components/Finance/Fees/add-new-fee";
import GetUpdateFee from "../components/Finance/Fees/get-update-fee";
import GeneralLedger from "../components/Finance/PaymentEntry/general-ledger";

import ApplyJob from "../components/ApplyJob/ApplyJob/applyjob";
import HR from "../screens/HR/index";
import RecruitmentForm from "../components/Recruitment Form/index";
import MeritListDashboard from "../components/Admission/MeritList/merit-list-dashboard";
import MeritListCriteriaDashboard from "../components/Admission/MeritList/merit-list-criteria-dashboard";
import MeritListCategoryDashboard from "../components/Admission/MeritList/merit-list-category-dashboard";
import AddNewMeritListCriteria from "../components/Admission/MeritList/add-new-merit-list-criteria";
import AddNewMeritListCategory from "../components/Admission/MeritList/add-new-merit-list-category";
import GetMeritListCriteria from "../components/Admission/MeritList/get-new-merit-list-criteria";
import GetMeritListCategory from "../components/Admission/MeritList/get-new-merit-list-category";
import GetUpdateFeeSchedule from "../components/Finance/FeeSchedule/get-update-fee-schedule";

import LeaveManagement from "../screens/HR/LeaveManagement";
import LeaveEntityList from "../components/Hr/HrLeaveManagement/Masters/HolidayList";
import BulkStudentEnrollment from "../screens/Academics/Enrollment/BulkStudentEnrollment/Enrollment";
import {
  AddDesignation,
  AddInterviewType,
  AddSkills,
  DesignationList,
  InterviewTypeList,
  Masters,
  SkillsList,
  InterviewRoundList,
  AddInterviewRound,
  // AddOfferTerm,
  // OfferTermList,
  OfferTermTemplateList,
  AddOfferTermTemplate,
  AppointmentLetterTemplate,
  AddAppointmentLetterTemplate,
} from "components/Hr/HrRecruit/Masters";
import LeavePeriod from "../components/Hr/HrLeaveManagement/Masters/LeavePeriod";
import LeavePolicy from "../components/Hr/HrLeaveManagement/Masters/LeavePolicy";
import UpdateJobOpening from "../components/Hr/HrRecruit/JobOpening/UpdateJobOpening/updateJobOpening";
import LeavePolicyAssignment from "../components/Hr/HrLeaveManagement/Allocations/LeavePolicyAssignment";
import LeaveApplication from "../components/Hr/HrLeaveManagement/Allocations/LeaveApplication";

import IQACContainer from "screens/IQAC";
import StudentAttendance from "screens/Academics/Attendance/student-attendence";
import AddNewStudentAttendance from "screens/Academics/Attendance/student-attendence/add-new-student-attendance";
import StudentAttendanceTool from "screens/Academics/Attendance/student-attendance-tool";
import StudentDetails from "components/Admission/StudentManagement/get-student-details";
import {
  AddOfferTerm,
  OfferTermList,
} from "components/Hr/HrRecruit/Masters/OfferTerm";
import LeaveMasters from "components/Hr/HrLeaveManagement/Masters";
import Allocation from "components/Hr/HrLeaveManagement/Allocations";
import LeavePolicyAssignmentDetails from "components/Hr/HrLeaveManagement/Allocations/LeavePolicyAssignment/get-update-leave-policy-assignment";
import LeaveApplicationDetails from "components/Hr/HrLeaveManagement/Allocations/LeaveApplication/get-update-leave-application";
import LeaveAllocation from "components/Hr/HrLeaveManagement/LeaveAllocation";
import LeaveAllocationDetails from "components/Hr/HrLeaveManagement/LeaveAllocation/get-update-leave-allocation";
import HolidayListDetails from "components/Hr/HrLeaveManagement/Masters/HolidayList/get-update-holiday-list";
import LeavePeriodDetails from "components/Hr/HrLeaveManagement/Masters/LeavePeriod/get-update-leave-period";
import LeavePolicyDetails from "components/Hr/HrLeaveManagement/Masters/LeavePolicy/get-update-leave-policy";
import LeaveTypeList from "components/Hr/HrLeaveManagement/Masters/LeaveType/index";
import LeaveTypeDetails from "components/Hr/HrLeaveManagement/Masters/LeaveType/get-update-leave-type";
import LeaveBlockList from "components/Hr/HrLeaveManagement/Masters/LeaveBlockList/index";
import LeaveBlockDetails from "components/Hr/HrLeaveManagement/Masters/LeaveBlockList/get-update-leave-block-list";
import FileDetails from "components/IQAC/DocumentManagement/get-file-details";

import AddNewGradingScale from "screens/HR/GradingScale/add-new-grading-scale";
import GetUpdateGradingScale from "screens/HR/GradingScale/get-update-grading-scale";

import ViewStudentAttendance from "screens/Academics/Attendance/student-attendence/view-student-attendance";
import LeaveControlPanel from "../components/Hr/HrLeaveManagement/Allocations/LeaveControlPanel";

import ContentManagement from "components/MasterPage/Content Management/New Topic";

import StudentDashboard from "screens/Student";
import StudentApplicationDetails from "components/Student/ApplicationDetails";
import Examination from "screens/Examination";
import Employee from "components/Hr/HrRecruit/Employee";
import AddNewEmployee from "components/Hr/HrRecruit/Employee/add-new-employee";

import {
  CreateAppointmentLetter,
  CreateInterview,
  CreateJobOffer,
  InterviewFeedback,
  JobApplicationView,
  JobApplications,
} from "components/Hr/HrRecruit/JobApplications";

import AddNewQuestionBank from "components/Examination/QuestionBank/add-question-bank";

import AddNewStudentGroup from "screens/Academics/StudentGroup/add-new-student-group";
import GetUpdateStudentGroup from "screens/Academics/StudentGroup/get-update-student-group";
import GetUpdateEmployee from "components/Hr/HrRecruit/Employee/get-update-employee";

import GetUpdateQuestionBank from "components/Examination/QuestionBank/get-update-question-bank";

import FeedBackContainer from "screens/Survey";
import CreateFeedbackTemplate from "components/Survey/Feedback/add-new-feedback-template"
import SeatManagementRooms from "components/Examination/SeatManagement/Room";
import AddNewRoom from "components/Examination/SeatManagement/add-new-room";
import FeedbackResponse from "components/Survey/Response/get-the-feedback-response";
import FeedbackForm from "components/Student/Feedback/FillQuestion/form";
import QuestionCard from "components/Student/Feedback/FillQuestion/index";
import SeatManagementFloors from "components/Examination/SeatManagement/Floor";
import SeatManagementBlocks from "components/Examination/SeatManagement/Block";
import AddNewFloor from "components/Examination/SeatManagement/add-new-floor";
import AddNewBlock from "components/Examination/SeatManagement/add-new-block";
import GetUpdateFloor from "components/Examination/SeatManagement/get-update-floor";
import GetUpdateBlock from "components/Examination/SeatManagement/get-update-block";
import GetUpdateRoom from "components/Examination/SeatManagement/get-update-room";
import AddNewFeedbackAssignment from "components/Survey/FeedbackAssignment/add-new-feedback-assignment";
import GetUpdateFeedbackAssignment from "components/Survey/FeedbackAssignment/get-update-feedback-assignment";
import AddNewEmployeeAttendance from "screens/HR/Attendance/employee-attendance/add-new-employee-attendance";
import EmployeeAttendance from "screens/HR/Attendance/employee-attendance";
import GetUpdateEmployeeAttendance from "screens/HR/Attendance/employee-attendance/get-update-employee-attendance";
import StudentLeaveApplication from "screens/Academics/Attendance/StudentLeaveApplication";
import AddNewStudentLeaveApplication from "screens/Academics/Attendance/StudentLeaveApplication/add-new-student-leave-application";
import GetUpdateStudentLeaveApplication from "screens/Academics/Attendance/StudentLeaveApplication/get-update-student-leave-application";

export const routes = [
  { path: "/", name: "Landing", element: Landing },
  { path: "/dynamicForm", name: "Dynamic Form", element: DynamicForm },
  { path: "/payment", name: "Payment", element: Payment },
  { path: "/receipt/:name/:type", name: "Receipt", element: Receipt },
  { path: "/hrform", name: "HRForm", element: HrForm },
  { path: "/student/dashboard/:id", name:"QuestionCard", element: QuestionCard },
  {
    path: "/modules/hr/jobOpening",
    name: "JobCreationList",
    element: JobCreationList,
  },
  {
    path: "/jobApplicationListing",
    name: "JobApplicationListing",
    element: JobApplicationListing,
  },
  { path: "/apply", name: "ApplyJob", element: ApplyJob },
  {
    path: "/recruitmentForm",
    name: "RecruitmentForm",
    element: RecruitmentForm,
  },

  { path: "/jobOpening/:id/", name: "JobOpening", element: JobOpening },

  {
    path: "/login",
    name: "Login",
    element: Login,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    element: ModulesList,
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    element: ForgotPassword,
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    element: ResetPassword,
  },
  {
    path: "/forgotpasswordsuccess",
    name: "ForgotPassword",
    element: ForgotPasswordSuccess,
  },
  {
    path: "/lead/:id/:type",
    name: "LeadInfo",
    element: LeadDashboard,
  },
  {
    path: "/calender",
    name: "CalenderPage",
    element: Calender,
  },
  {
    path: "/contentManagement/NewArticle",
    name: "NewArticle",
    element: ContentManagement,
  },
  {
    path: "/student/dashboard",
    name: "DashboardStudent",
    element: StudentDashboard,
  },
  {
    path: "/applicationForm",
    name: "ApplicationForm",
    element: ApplicationForm,
  },
  {
    path: "/career",
    name: "Career",
    element: Career,
  },
  {
    path: "/profile",
    name: "LeadProfile",
    element: LeadProfile,
  },
  {
    path: "/applications/:id/",
    name: "ApplicationDetails",
    element: ApplicationDetails,
  },
  {
    path: "/jobapplications/:id/:type",
    name: "JobApplicationDetails",
    element: JobApplicationDetails,
  },
  {
    path: "/modules/admission",
    name: "Admission",
    element: Admission,
  },
  {
    path: "/modules/hr",
    name: "HR",
    element: HR,
  },
  {
    path: "/modules/hr/leave-management/masters",
    name: "LeaveMasters",
    element: LeaveMasters,
  },
  {
    path: "/modules/hr/leave-management/allocations",
    name: "Allocation",
    element: Allocation,
  },
  {
    path: "modules/hr/leave-management/allocations/leave-policy-assignment",
    name: "LeavePolicyAssignment",
    element: LeavePolicyAssignment,
  },
  {
    path: "modules/hr/leave-management/allocations/leave-application",
    name: "LeaveApplication",
    element: LeaveApplication,
  },
  {
    path: "modules/hr/leave-management/allocations/leave-policy-assignment/:id",
    name: "LeavePolicyAssignmentDetails",
    element: LeavePolicyAssignmentDetails,
  },
  {
    path: "modules/hr/leave-management/allocations/leave-application/:id",
    name: "LeaveApplicationDetails",
    element: LeaveApplicationDetails,
  },

  {
    path: "/modules/hr/leave-management/masters/:entityType",
    name: "LeaveEntityList",
    element: LeaveEntityList,
  },
  {
    path: "/modules/hr/leave-management/masters/leave-period",
    name: "LeavePeriod",
    element: LeavePeriod,
  },
  {
    path: "/modules/hr/leave-management/masters/leave-period/:id",
    name: "LeavePeriodDetails",
    element: LeavePeriodDetails,
  },
  {
    path: "/modules/hr/leave-management/masters/leave-policy",
    name: "LeavePolicy",
    element: LeavePolicy,
  },
  {
    path: "/modules/hr/leave-management/masters/holiday-list/:id",
    name: "HolidayListDetails",
    element: HolidayListDetails,
  },
  {
    path: "/modules/hr/leave-management/masters/leave-policy/:id",
    name: "LeavePolicydDetails",
    element: LeavePolicyDetails,
  },
  {
    path: "/modules/hr/leave-management/masters/leave-type",
    name: "LeaveTypeList",
    element: LeaveTypeList,
  },
  {
    path: "/modules/hr/leave-management/masters/leave-type/:id",
    name: "LeaveTypeDetails",
    element: LeaveTypeDetails,
  },
  {
    path: "/modules/academics",
    name: "Academics",
    element: Academics,
  },
  {
    path: "/modules/academics/:entityType/:entityId",
    name: "MasterPage",
    element: MasterPage,
  },
  {
    path: "/modules/academics/:entityType",
    name: "EntityList",
    element: EntityList,
  },
  {
    path: "/modules/finance",
    name: "Finance",
    element: Finance,
  },
  {
    path: "/modules/finance/fee-category/:category",
    name: "Get and Update Fee Category",
    element: GetUpdateFeeCategory,
  },
  {
    path: "/modules/finance/add-new-fee-category",
    name: "Add New Fee Category",
    element: AddNewFeeCategory,
  },
  {
    path: "/modules/finance/fee-structure/:structure",
    name: "Get and Update Fee Structure",
    element: GetUpdateFeeStructure,
  },
  {
    path: "/modules/finance/fee-schedule/:schedule",
    name: "Get and Update Fee Schedule",
    element: GetUpdateFeeSchedule,
  },
  {
    path: "/modules/finance/add-new-fee-structure",
    name: "Add New Fee Structure",
    element: AddNewFeeStructure,
  },
  {
    path: "/modules/finance/add-new-fee-schedule",
    name: "Add New Fee Schedule",
    element: AddNewFeeSchedule,
  },
  {
    path: "/modules/finance/add-new-payment-entry",
    name: "Add New  Payment Entry",
    element: AddNewPaymentEntry,
  },
  {
    path: "/modules/finance/add-new-payment-request",
    name: "Add New Payment Request",
    element: AddNewPaymentRequest,
  },
  {
    path: "/modules/finance/payment-request/:paymentRequest",
    name: "Get and Update Payment Request",
    element: GetUpdatePaymentRequest,
  },
  {
    path: "/modules/finance/payment-entry/:paymentEntry",
    name: "Get and Update Payment Entry",
    element: GetUpdatePaymentEntry,
  },

  {
    path: "/modules/finance/add-new-fee",
    name: "Add New Fee",
    element: AddNewFee,
  },
  {
    path: "/modules/finance/fee/:fee_id",
    name: "Get Update Fee",
    element: GetUpdateFee,
  },

  {
    path: "/modules/finance/general-ledger/:paymentEntry",
    name: "General Ledger",
    element: GeneralLedger,
  },
  // {
  //   path: "/basic",
  //   name: "Addmission Module",
  //   element: AdmissionModuleLanding,
  // },
  // { path: "/basic/login", name: "Admission Module Login", element: AdmissionModuleLogin },
  {
    path: "/modules/academics/add-new-student-group",
    name: "Add New Student Group",
    element: AddNewStudentGroup,
  },
  {
    path: "/modules/academics/enrollment/bulk-student-enrollment",
    name: "Bulk Student Enrollment",
    element: BulkStudentEnrollment,
  },
  {
    path: "modules/academics/student-group/:group_name",
    name: "Get Update Student Group",
    element: GetUpdateStudentGroup,
  },
  {
    path: "/modules/admission/add-new-merit-list",
    name: "Add New Merit List",
    element: AddNewMeritList,
  },
  {
    path: "/modules/admission/merit-list/:meritList",
    name: "Get and Update Merit List",
    element: GetAndUpdateMeritList,
  },
  {
    path: "/modules/admission/merit-list",
    name: "Merit Listing Dashboard",
    element: MeritListDashboard,
  },
  {
    path: "/modules/admission/merit-list-criteria",
    name: "Merit Listing Criteria Dashboard",
    element: MeritListCriteriaDashboard,
  },
  {
    path: "/modules/admission/merit-list-category",
    name: "Merit Listing Category Dashboard",
    element: MeritListCategoryDashboard,
  },
  {
    path: "/modules/admission/add-new-merit-list-criteria",
    name: "Add New Merit List Criteria",
    element: AddNewMeritListCriteria,
  },
  {
    path: "/modules/admission/add-new-merit-list-category",
    name: "Add New Merit List Category",
    element: AddNewMeritListCategory,
  },
  {
    path: "/modules/admission/merit-list-criteria/:meritList",
    name: "Get Merit List Criteria",
    element: GetMeritListCriteria,
  },
  {
    path: "/modules/admission/merit-list-category/:meritList",
    name: "Get Merit List Category",
    element: GetMeritListCategory,
  },
  {
    path: "/modules/hr/masters",
    name: "HR recruitment masters",
    element: Masters,
  },
  {
    path: "/modules/hr/masters/interview-type",
    name: "List Interview type",
    element: InterviewTypeList,
  },
  {
    path: "/modules/hr/masters/interview-type/create",
    name: "Create Interview type",
    element: AddInterviewType,
  },
  {
    path: "/modules/hr/masters/interview-type/edit-view/:id",
    name: "View and Edit Interview type",
    element: AddInterviewType,
  },
  {
    path: "/modules/admission/merit-list",
    name: "Merit Listing Dashboard",
    element: MeritListDashboard,
  },
  {
    path: "/modules/admission/merit-list-criteria",
    name: "Merit Listing Criteria Dashboard",
    element: MeritListCriteriaDashboard,
  },
  {
    path: "/modules/admission/merit-list-category",
    name: "Merit Listing Category Dashboard",
    element: MeritListCategoryDashboard,
  },
  {
    path: "/modules/admission/add-new-merit-list-criteria",
    name: "Add New Merit List Criteria",
    element: AddNewMeritListCriteria,
  },
  {
    path: "/modules/admission/add-new-merit-list-category",
    name: "Add New Merit List Category",
    element: AddNewMeritListCategory,
  },
  {
    path: "/modules/admission/merit-list-criteria/:meritList",
    name: "Get Merit List Criteria",
    element: GetMeritListCriteria,
  },
  {
    path: "/modules/admission/merit-list-category/:meritList",
    name: "Get Merit List Category",
    element: GetMeritListCategory,
  },
  {
    path: "/modules/hr/jobOpening/addJobOpening",
    name: "AddJobOpening",
    element: AddJobOpening,
  },
  {
    path: "/modules/hr/jobOpening/updateJobOpening/:id",
    name: "UpdateJobOpening",
    element: UpdateJobOpening,
  },

  {
    path: "/modules/hr/masters/designation",
    name: "List Designation",
    element: DesignationList,
  },
  {
    path: "/modules/hr/masters/designation/create",
    name: "Create Designation",
    element: AddDesignation,
  },
  {
    path: "/modules/hr/masters/designation/edit-view/:id",
    name: "View and Edit Designation",
    element: AddDesignation,
  },
  {
    path: "/modules/iqac",
    name: "IQAC",
    element: IQACContainer,
  },
  {
    path: "/modules/academics/student-attendance",
    name: "Student Attendance",
    element: StudentAttendance,
  },
  {
    path: "/modules/academics/student-attendance/:attendanceId",
    name: "View Student Attendance",
    element: ViewStudentAttendance,
  },
  {
    path: "/modules/academics/student-attendance-tool",
    name: "Student Attendance Tool",
    element: StudentAttendanceTool,
  },
  {
    path: "/modules/academics/add-new-student-attendance",
    name: "Add New Student Attendance",
    element: AddNewStudentAttendance,
  },
  {
    path: "/modules/hr/employee-attendance",
    name: "Employee Attendance",
    element: EmployeeAttendance,
  },
  {
    path: "/modules/hr/add-new-employee-attendance",
    name: "Add New Employee Attendance",
    element: AddNewEmployeeAttendance,
  },
  {
    path: "/modules/hr/employee-attendance/:id",
    name: "Get Update Employee Attendance",
    element: GetUpdateEmployeeAttendance,
  },
  {
    path: "/modules/hr/masters/skills",
    name: "Skills list",
    element: SkillsList,
  },
  {
    path: "/modules/hr/masters/skills/create",
    name: "Create Skills",
    element: AddSkills,
  },
  {
    path: "/modules/hr/masters/skills/edit-view/:id",
    name: "View and Edit Skills",
    element: AddSkills,
  },
  {
    path: "modules/admission/student/:name",
    name: "Student Details",
    element: StudentDetails,
  },
  {
    path: "/modules/hr/masters/interview-round",
    name: "Interview round list",
    element: InterviewRoundList,
  },
  {
    path: "/modules/hr/masters/interview-round/create",
    name: "Create Interview round",
    element: AddInterviewRound,
  },
  {
    path: "/modules/hr/masters/interview-round/edit-view/:id",
    name: "View and Edit Interview round",
    element: AddInterviewRound,
  },
  {
    path: "/modules/hr/masters/offer-term",
    name: "offer term list",
    element: OfferTermList,
  },
  {
    path: "/modules/hr/masters/offer-term/create",
    name: "Create offer term",
    element: AddOfferTerm,
  },
  {
    path: "/modules/hr/masters/offer-term/edit-view/:id",
    name: "View and Edit offer term",
    element: AddOfferTerm,
  },
  {
    path: "/modules/hr/leave-management/masters/leave-block-list",
    name: "LeaveBlockList",
    element: LeaveBlockList,
  },
  {
    path: "/modules/hr/leave-management/masters/get-update-leave-block-list/:id",
    name: "LeaveBlockDetails",
    element: LeaveBlockDetails,
  },
  {
    path: "/modules/iqac/get-file-details/:id",
    name: "GetFileDetails",
    element: FileDetails,
  },
  {
    path: "/modules/hr/masters/offer-term-template",
    name: "offer term template list",
    element: OfferTermTemplateList,
  },
  {
    path: "/modules/hr/masters/offer-term-template/create",
    name: "Create offer term template",
    element: AddOfferTermTemplate,
  },
  {
    path: "/modules/hr/masters/offer-term-template/edit-view/:id",
    name: "View and Edit offer term template",
    element: AddOfferTermTemplate,
  },
  {
    path: "/modules/hr/masters/appointment-letter-template",
    name: "offer term template list",
    element: AppointmentLetterTemplate,
  },
  {
    path: "/modules/hr/masters/appointment-letter-template/create",
    name: "Create offer term template",
    element: AddAppointmentLetterTemplate,
  },
  {
    path: "/modules/hr/masters/appointment-letter-template/edit-view/:id",
    name: "View and Edit offer term template",
    element: AddAppointmentLetterTemplate,
  },
  {
    path: "/modules/hr/leave-management/allocations/leave-allocation",
    name: "LeaveAllocation",
    element: LeaveAllocation,
  },
  {
    path: "/modules/hr/leave-management/allocations/leave-allocation/:id",
    name: "LeaveAllocationDetails",
    element: LeaveAllocationDetails,
  },
  {
    path: "/modules/academics/student-leave-application",
    name: "Student Leave Application",
    element: StudentLeaveApplication,
  },
  {
    path: "/modules/academics/student-leave-application/add-new-student-leave-application",
    name: "Add New Student Leave Application",
    element: AddNewStudentLeaveApplication,
  },
  {
    path: "/modules/academics/student-leave-application/:id",
    name: "Get Update Student Leave Application",
    element: GetUpdateStudentLeaveApplication,
  },
  {
    path: "/modules/hr/add-new-grading-scale",
    name: "Add New Grading Scale",
    element: AddNewGradingScale,
  },
  {
    path: "/modules/hr/grading-scale/:id",
    name: "Get Update Grading Scale",
    element: GetUpdateGradingScale,
  },
  {
    path: "/modules/hr/leave-management/allocations/:entityType",
    name: "LeaveControlPanel",
    element: LeaveControlPanel,
  },
  {
    path: "/student/application/:id",
    name: "StudentApplicationDetails",
    element: StudentApplicationDetails,
  },
  {
    path: "/modules/examination",
    name: "Examination",
    element: Examination,
  },
  {
    path: "/modules/hr/employee",
    name: "Employee",
    element: Employee,
  },
  {
    path: "/modules/hr/employee/add-new-employee",
    name: "Add New Employee",
    element: AddNewEmployee,
  },
  {
    path: "/modules/hr/job-application",
    name: "Job Applications",
    element: JobApplications,
  },
  {
    path: "/modules/hr/job-application/view/:id/:jobOpeningId/:designationId",
    name: "Job Application view",
    element: JobApplicationView,
  },
  {
    path: "/modules/hr/job-application/view/interview/create/:id/:jobOpeningId/:designationId",
    name: "Job Application view",
    element: CreateInterview,
  },
  {
    path: "/modules/hr/job-application/view/interview/edit-view/:id/:jobOpeningId/:designationId/:interviewId",
    name: "View Job Application view",
    element: CreateInterview,
  },
  {
    path: "/modules/hr/job-application/view/interview/feedback/create/:id/:jobOpeningId/:designationId/:interviewId/:interviewRoundId",
    name: "New Interview Feedback",
    element: InterviewFeedback,
  },
  {
    path: "/modules/hr/job-application/view/interview/feedback/edit-view/:id/:jobOpeningId/:designationId/:interviewId/:interviewRoundId/:feedbackId",
    name: "View Interview Feedback",
    element: InterviewFeedback,
  },
  {
    path: "/modules/hr/job-application/view/job-offer/create/:id/:jobOpeningId/:designationId",
    name: "Job Application view",
    element: CreateJobOffer,
  },
  {
    path: "/modules/hr/job-application/view/job-offer/edit-view/:id/:jobOpeningId/:designationId/:jobOfferId",
    name: "Job Application view",
    element: CreateJobOffer,
  },
  {
    path: "/modules/hr/employee/:employee_id",
    name: "Get Update Employee",
    element: GetUpdateEmployee,
  },
  {
    path: "/modules/hr/job-application/view/appointment-letter/create/:id/:jobOpeningId/:designationId",
    name: "Job Application view",
    element: CreateAppointmentLetter,
  },
  {
    path: "/modules/hr/job-application/view/appointment-letter/edit-view/:id/:jobOpeningId/:designationId/:appointmentId",
    name: "Job Application view",
    element: CreateAppointmentLetter,
  },
  {
    path: "/modules/examination/add-question-bank",
    name: "Add Question Bank",
    element: AddNewQuestionBank,
  },
  {
    path: "/modules/examination/question-bank/:id",
    name: "Get Update Question Bank",
    element: GetUpdateQuestionBank,
  },
  {
    path: "/modules/survey",
    name: "survey",
    element: FeedBackContainer,
  },
  {
    path: "/modules/survey/New-template",
    name: "CreateFeedbackTemplate",
    element: CreateFeedbackTemplate,
  },
  {
    path: "/modules/examination/seat-management/rooms",
    name: "Seat Management Rooms",
    element: SeatManagementRooms
  },
  {
    path: "modules/examination/seat-management/rooms/add-new-room",
    name: "Add new Room",
    element: AddNewRoom
  },
  {
    path: "/modules/survey/:id",
    name: "FeedbackResponse",
    element: FeedbackResponse,
  },
  {
    path: "/modules/examination/seat-management/floors",
    name: "Seat Management Floors",
    element: SeatManagementFloors
  },
  {
    path: "/modules/examination/seat-management/blocks",
    name: "Seat Management Blocks",
    element: SeatManagementBlocks
  },
  {
    path: "modules/examination/seat-management/floors/add-new-floor",
    name: "Add new Floor",
    element: AddNewFloor
  },
  {
    path: "modules/examination/seat-management/blocks/add-new-block",
    name: "Add new Block",
    element: AddNewBlock
  },
  {
    path: "modules/examination/seat-management/floor/:name",
    name: "Get and Update Floor Details",
    element: GetUpdateFloor
  },
  {
    path: "modules/examination/seat-management/block/:name",
    name: "Get and Update Block Details",
    element: GetUpdateBlock
  },
  {
    path: "modules/examination/seat-management/room/:name",
    name: "Get and Update Room Details",
    element: GetUpdateRoom
  },
  {
    path: "/modules/survey/add-new-feedback-assignment",
    name: "Add New Feedback Assignment",
    element: AddNewFeedbackAssignment,
  },
  {
    path: "/modules/survey/feedback-assignment/:id",
    name: "Get Update Feedback Assignment",
    element: GetUpdateFeedbackAssignment,
  },
  {
    path: "/student/dashboard/:id",
    name: "FeedbackForm",
    element: FeedbackForm,
  },
];

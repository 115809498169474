import React, { useEffect, useState } from 'react';
import { Table, Popover } from 'antd';
import useStyles from './style';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { GridMenuIcon } from '@mui/x-data-grid';
import { setLoading } from '../../../slices/commonSlice';
import CommonFilters from '../../Filters/CommonFilters';
import SavedFilters from '../../Filters/SavedFilters';
import { useDispatch, useSelector } from "react-redux";
import LoadingBackdrop from "../../common/LoadingBackdrop.js";
import eventBus from '../../../core/eventBus.js';
import DeleteConfirmationDialog from '../../common/DeleteConfirmation/index.js';
import { genericBulkDelete } from '../../../modules/common.js';
import APIs from '../../../api/index.js';
import BulkEdit from '../../common/BulkEdit';
import { getStudentListing, updateFieldsInBulk } from '../../../modules/admission.js';
import { formatDateTODDMMMYYYY } from 'utils/date';

const AdmissionStudentManagement = ({ setActiveModule }) => {
  const styles = useStyles();
  const navigate= useNavigate();
  const dispatch = useDispatch();
  const [studentList, setStudentList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [openActions, setOpenActions] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const { doctype, filters } = useSelector((state) => state.filters);
  const { loading } = useSelector((state) => state.common);

  const columns = [
    {
      dataIndex: "name",
      title: "STUDENT ID",
      width: "40%",
    },
    {
      dataIndex: "first_name",
      title: "FIRST NAME",
      width: "25%",
    },
    {
      dataIndex: "joining_date",
      title: "JOINING DATE",
      width: "25%",
      render: (val, row) => {
        return (
            <p className={styles.tableContentFields}>{row.joining_date ? formatDateTODDMMMYYYY(row.joining_date) : "-"}</p>
        );
      },
    },
    {
      dataIndex: "name",
      title: "Action",
      width: "10%",
      render: (val, row) => {
        return (
          <p onClick={() => navigate(`/modules/admission/student/${row.name}`)} className={styles.link}>
            View
          </p>
        );
      },
    },
  ];

  const handleStudentListing = async () => {
    let filtersToPass = [];
    if(doctype === "Student") {
      filtersToPass = JSON.stringify(filters).slice(1, -1);
    }
    dispatch(setLoading({ loading: true }));
    const res = await getStudentListing(filtersToPass);
    if (res.status === 200) {
      let data = res?.data?.data;
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        let val = {
          ...data[i],
          id: data[i].name,
          key: i
        };
        arr.push(val);
      }
      setStudentList(arr);
      dispatch(setLoading({ loading: false }));
    } else {
      dispatch(setLoading({ loading: false }));
    }
  };

  const onSelectChange = (selectedKeys, selectedRowData) => {
    setSelectedRowKeys(selectedKeys);
    let selectedDataIds = [];
    for(let i = 0 ; i < selectedRowData.length ; i++) {
      selectedDataIds.push(selectedRowData[i].id);
    }
    setSelectedIds([...selectedDataIds]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      id: record.id,
    }),
  };

  const handleConfirmBulkDelete = () => {
    setOpenActions(false);
    eventBus.emit("open-dialog", <DeleteConfirmationDialog items={selectedRowKeys.length} handleBulkDelete={handleBulkDelete} />)
  }

  const handleBulkDelete = async () => {
    dispatch(setLoading({ loading: true }));
    let deleteIds = [];
    for(let i = 0 ; i < selectedIds.length ; i++) {
      deleteIds.push({
        doctype: "Student",
        id: selectedIds[i]
      })
    };

    const payload = { delete: [...deleteIds] };
    const res = await genericBulkDelete({ payload });
    if(res.status === 200) {
      handleStudentListing();
      setSelectedIds([]);
      setSelectedRowKeys([]);
      toast.success(res.data.message);
    } else {
      toast.error(res.data.exc_type);
      dispatch(setLoading({ loading: false }));
    }
  } 

  const handleBulkExport = async () => {
    setOpenActions(false);
    dispatch(setLoading({ loading: true }));
    let exportIds = [];
    for(let i = 0 ; i < selectedIds.length ; i++) {
      exportIds.push({
        doctype: "Student",
        id: selectedIds[i]
      })
    };

    const payload = { export : [...exportIds] };
    const response = await fetch(APIs.bulkActionExport, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      dispatch(setLoading({ loading: false }));
      setSelectedIds([]);
      setSelectedRowKeys([]);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      toast.success("Data exported!");
      link.setAttribute("download", "Student-Data.xlsx");
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error("Something went wrong");
      dispatch(setLoading({ loading: false }));
    }
  };

  const handleBulkEdit = async ({ customfieldname, value }) => {
    const payload = {
      doctype: "Student",
      docnames:	selectedIds,
      customfieldname,
      value
    };
    dispatch(setLoading({ loading: true }));
    setIsEditOpen(false)
    const res = await updateFieldsInBulk({ payload });
    if(res.status === 200) {
      handleStudentListing();
      setSelectedIds([]);
      setSelectedRowKeys([]);
      toast.success(res.data.message);
    } else {
      toast.error(res.data.exc_type);
      dispatch(setLoading({ loading: false }));
    }
  }

  useEffect(() => {
    handleStudentListing();
  }, [filters]);

  return (
   <>
    {
      loading ?
       <LoadingBackdrop open={loading} /> :
       <div className={styles.mainContainer}>
        <div className={styles.breadCrumbsContainer}>
          <p className={styles.breadCrumbsContent} onClick={() => navigate("/dashboard")}>IIHT {" > "}</p>
          <p className={styles.breadCrumbsContent} onClick={() => setActiveModule(0)}>Admission {" > "}</p>
          <p className={styles.breadCrumbsContent}>Student Management</p>
        </div>
        <div className={styles.headerContainer}>
          <div className={styles.breadCrumbsContainer}>
            <GridMenuIcon className={styles.gridIcon}/>
            <p className={styles.title}>Student Management</p>
          </div>
          <div className={styles.buttonsGroup}>
            <CommonFilters doctype={"Student"}></CommonFilters>
            <SavedFilters doctype={"Student"}></SavedFilters>
            {
              selectedRowKeys.length > 0 ? (
              <Popover
                content={<div className={styles.popoverContainer}>
                  <p className={styles.popoverContent} onClick={() => {
                    setOpenActions(false);
                    setIsEditOpen(true);
                  }}>Edit</p>
                  <p className={styles.popoverContent} onClick={handleBulkExport}>Export</p>
                  <p className={styles.popoverContent} onClick={handleConfirmBulkDelete}>Delete</p>
                </div>}
                trigger="click"
                open={openActions}
                placement="bottomRight"
                arrow={false}
                onOpenChange={() => setOpenActions(true)}
              >
                <div className={styles.activeButton}>
                  <p className={styles.activeButtonText}>Actions</p>
                </div>
              </Popover>
              ) : (
                <div className={styles.activeButton}>
                  <p className={styles.activeButtonText}>+ Add Student</p>
                </div>
              )
            }
          </div>
        </div>
        <Table 
          rowSelection={{ type: "checkbox", ...rowSelection }} 
          columns={columns} 
          dataSource={studentList} 
          size='small'
          pagination={true}
        />
      </div>
    }
    {
      isEditOpen && 
        <BulkEdit 
          handleBulkEdit={handleBulkEdit} 
          doctype="Student" 
          isEditOpen={isEditOpen} 
          setIsEditOpen={setIsEditOpen} 
          items={selectedIds.length}
        />
    }
   </>
  )
}

export default AdmissionStudentManagement;
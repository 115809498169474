import React, { useState, useEffect } from "react";
import { Button, Popover, Table } from "antd";
import { MainLayout } from "components/common";
import Breadcrumbs from "../../../common/breadcrumb/breadcrumb";
import { GridMenuIcon } from "@mui/x-data-grid"; 
import { getGenericList, updateFieldsInBulk } from "modules/hr";
import { Link, useNavigate } from "react-router-dom";
import "../style.scss";
import { useSelector, useDispatch } from "react-redux"; // Import useDispatch
import { setLoading } from "slices/commonSlice";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { CommonFilters, SavedFilters } from "components/Filters";
import useStyles from"./get-update-leave-allocation/style"

import { toast } from "react-toastify";
import { genericBulkDelete } from "modules/common";
import DeleteConfirmationDialog from "components/common/DeleteConfirmation";
import eventBus from "core/eventBus";
import APIs from "api";
import BulkEdit from "components/common/BulkEdit";

const Index = () => {
  const [data, setData] = useState([]);
  const styles = useStyles();
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [filtering, setFiltering] = useState(false);
  const [rowData, setRowData] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate hook
  const loading = useSelector((state) => state.common.loading);
  const { doctype, filters } = useSelector((state) => state.filters);
  const dispatch = useDispatch(); // Initialize useDispatch hook

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openActions, setOpenActions] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);


  useEffect(() => { 
    handleLeaveAllocation();
  }, [filters]);

  const handleLeaveAllocation = async () => {
    dispatch(setLoading({ loading: true }));
    let filtersToPass = [];
    if(doctype === "Leave Allocation") {
      filtersToPass = JSON.stringify(filters).slice(1, -1);
    }
    try {
      const doctype = "Leave Allocation";
      const leavealocationList = await getGenericList(doctype,filtersToPass);

      // Add a unique identifier to each Holiday List
      const leaveAllocationWithId = leavealocationList.map((holiday, index) => ({
        ...holiday,
        entityType: "Leave Allocation",
      }));
      setData(leaveAllocationWithId);
      setRowData(leaveAllocationWithId); // Set rowData to the updated data state

      console.log("Leave Allocation with Id: ", leaveAllocationWithId);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // Set loading to false when data fetching completes
      dispatch(setLoading({ loading: false }));
    }
  };


  const onSelectChange = (selectedKeys, selectedRowData) => {
    setSelectedRowKeys(selectedKeys);
    let selectedDataIds = [];
    for (let i = 0; i < selectedRowData.length; i++) {
      selectedDataIds.push(selectedRowData[i].id);
    }
    setSelectedIds([...selectedDataIds]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      id: record.id,
    }),
  };

  const handleConfirmBulkDelete = () => {
    setOpenActions(false);
    eventBus.emit(
      "open-dialog",
      <DeleteConfirmationDialog
        items={selectedRowKeys.length}
        handleBulkDelete={handleBulkDelete}
      />
    );
  };

  const handleBulkDelete = async () => {
    dispatch(setLoading({ loading: true }));
    let deleteIds = [];
    for (let i = 0; i < selectedIds.length; i++) {
      deleteIds.push({
        doctype: "Leave Application",
        id: selectedIds[i],
      });
    }

    const payload = { delete: [...deleteIds] };
    const res = await genericBulkDelete({ payload });
    if (res.status === 200) {
      handleLeaveAllocation();
      setSelectedIds([]);
      setSelectedRowKeys([]);
      toast.success(res.data.message);
    } else {
      toast.error(res.data.exc_type);
      dispatch(setLoading({ loading: false }));
    }
  };

  const handleBulkExport = async () => {
    setOpenActions(false);
    dispatch(setLoading({ loading: true }));
    let exportIds = [];
    for (let i = 0; i < selectedIds.length; i++) {
      exportIds.push({
        doctype: "Leave Application",
        id: selectedIds[i],
      });
    }

    const payload = { export: [...exportIds] };
    const response = await fetch(APIs.bulkActionExport, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      dispatch(setLoading({ loading: false }));
      setSelectedIds([]);
      setSelectedRowKeys([]);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      toast.success("Data exported!");
      link.setAttribute("download", "HolidayList-Data.xlsx");
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error("Something went wrong");
      dispatch(setLoading({ loading: false }));
    }
  };

  const handleBulkEdit = async ({ customfieldname, value }) => {
    const payload = {
      doctype: "Leave Application",
      docnames: selectedIds,
      customfieldname,
      value,
    };
    dispatch(setLoading({ loading: true }));
    setIsEditOpen(false);
    const res = await updateFieldsInBulk({ payload });
    if (res.status === 200) {
      handleLeaveAllocation();
      setSelectedIds([]);
      setSelectedRowKeys([]);
      toast.success(res.data.message);
    } else {
      toast.error(res.data.exc_type);
      dispatch(setLoading({ loading: false }));
    }
  };


  const commonActionColumn = {
    title: "Action",
    dataIndex: "action",
    render: (text, row) => (
      <Link to={`/modules/hr/leave-management/allocations/leave-allocation/${row.id}`}>View</Link>
    ),
  };

  const columns = [
    {
      title: "No.",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1, // Render the row index + 1
    },
    {
      title: "EMPLOYEE NAME",
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: "EMPLOYEE ID",
      dataIndex: "employee",
      key: "employee",
    },
    {
      title: "LEAVE TYPE",
      dataIndex: "leave_type",
      key: "leave_type",
    },
    {
      title: "Leave Id",
      dataIndex: "id",
      key: "id",
    },
    {
        title: "STATUS",
        dataIndex: "total_holidays",
        key: "total_holidays",
    },
    commonActionColumn,
  ];

  const CustomToolbar = () => {
    const handleAddButtonClick = () => {
      navigate("/modules/hr/leave-management/allocations/leave-allocation/new"); // Navigate to the add form route with an identifier indicating add mode
    };

    return (
      <>
        <Breadcrumbs />
        <div className="master-heading">
          <h2>
            <GridMenuIcon />
            {`Leave Allocation`}
          </h2>
          <div className={styles.buttonsGroup}>
            <CommonFilters doctype={"Leave Application"}></CommonFilters>
            <SavedFilters doctype={"Leave Application"}></SavedFilters>
            {selectedRowKeys.length > 0 ? (
              <Popover
                content={
                  <div className={styles.popoverContainer}>
                    <p
                      className={styles.popoverContent}
                      onClick={() => {
                        setOpenActions(false);
                        setIsEditOpen(true);
                      }}
                    >
                      Edit
                    </p>
                    <p
                      className={styles.popoverContent}
                      onClick={handleBulkExport}
                    >
                      Export
                    </p>
                    <p
                      className={styles.popoverContent}
                      onClick={handleConfirmBulkDelete}
                    >
                      Delete
                    </p>
                  </div>
                }
                trigger="click"
                open={openActions}
                placement="bottomRight"
                arrow={false}
                onOpenChange={() => setOpenActions(true)}
              >
                <div className={styles.activeButton}>
                  <p className={styles.activeButtonText}>Actions</p>
                </div>
              </Popover>
            ) : (
              <Button
                variant="outlined"
                className="redBtn"
                onClick={handleAddButtonClick}
              >
                {`Add Leave Allocation`}
              </Button>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <MainLayout>
      <CustomToolbar />
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <Table
          className="mainTable"
          rowKey="id"
          columns={columns}
          dataSource={rowData}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
        />
      )}
      {isEditOpen && (
        <BulkEdit
          handleBulkEdit={handleBulkEdit}
          doctype="Leave Application"
          isEditOpen={isEditOpen}
          setIsEditOpen={setIsEditOpen}
          items={selectedIds.length}
        />
      )}
    </MainLayout>
  );
};

export default Index;

import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import CardComponent from "../../../../components/common/Cards";
import { Checklist, MenuBookSvg, UserTick } from "../../../../icons";
import GraphSvg from "../../../../icons/Graph";
import { TextField } from "@mui/material";
import { getLeadListing } from "../../../../modules/enquiry";
import { getCurrentDate } from "../../../../utils/date";
import useStyles from "./style";

const getTodayDate = () => {
  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();

  return `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}`;
};

const LeadDashboard = () => {
  const styles = useStyles();
  const [startDate, setStartDate] = useState(getTodayDate());
  const [endDate, setEndDate] = useState(getTodayDate());
  const [leads, setLeads] = useState([]);
  const [openLeads, setOpenLeads] = useState(0);
  const [notEligibleLeads, setNotEligibleLeads] = useState(0);
  const [eligibleLeads, setEligibleLeads] = useState(0);
  const [conversionLeads, setConversionLeads] = useState("0 %");

  const getAllLeads = async () => {
    const res = await getLeadListing(startDate, endDate);
    if (res.status === 200) {
      setLeads(res?.data?.data);

      const notEligible = res?.data?.data.filter(
        (item) => item.status === "Not Eligible"
      );
      const eligible = res?.data?.data.filter((item) =>
        ["Eligible", "Converted"].includes(item.status)
      );

      const openLeads = res?.data?.data.filter((item) =>
        [
          "Lead",
          "Open",
          "Replied",
          "Opportunity",
          "Quotation",
          "Lost Quotation",
          "Interested",
          "Do Not Contact",
          "Hot",
          "Warm",
          "Cold",
        ].includes(item.status)
      );

      setNotEligibleLeads(notEligible.length);
      setEligibleLeads(eligible.length);
      setOpenLeads(openLeads.length);

      setConversionLeads(
        `${
          eligible.length > 0
            ? parseInt((eligible.length * 100) / res?.data?.data.length)
            : 0
        } %`
      );
    }
  };

  const handleStartDateChange = (newStartDate) => {
    if (newStartDate <= getCurrentDate()) {
      setStartDate(newStartDate);
      if (newStartDate > endDate) {
        setEndDate(newStartDate);
      }
    }
  };

  const handleEndDateChange = (newEndDate) => {
    if (newEndDate <= getCurrentDate()) {
      setEndDate(newEndDate);
      if (newEndDate < startDate) {
        setStartDate(newEndDate);
      }
    }
  };

  useEffect(() => {
    getAllLeads();
  }, [startDate, endDate]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "22px" }}>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <div style={{ display: "inline-flex", gap: "12px" }}>
          <TextField
            id="start-date"
            label="Start Date"
            type="date"
            value={startDate}
            inputProps={{
              max: getCurrentDate(),
            }}
            onChange={(e) => handleStartDateChange(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="end-date"
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => handleEndDateChange(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: getCurrentDate(),
            }}
          />
        </div>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={2.4}>
          <CardComponent
            title="Total Leads"
            content={leads.length > 0 ? leads.length : 0}
            icon={
              <UserTick
                width={40}
                height={32}
                viewBox={"0 96 960 960"}
                fill="#ac0521"
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CardComponent
            title="Open Leads"
            content={openLeads ? openLeads : 0}
            icon={
              <GraphSvg
                width={40}
                height={32}
                viewBox={"0 96 960 960"}
                fill="#ac0521"
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CardComponent
            title="Not Eligible"
            content={notEligibleLeads ? notEligibleLeads : 0}
            icon={
              <Checklist
                width={40}
                height={32}
                viewBox={"0 96 960 960"}
                fill="#ac0521"
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CardComponent
            title="Eligible"
            content={eligibleLeads ? eligibleLeads : 0}
            icon={
              <Checklist
                width={40}
                height={32}
                viewBox={"0 96 960 960"}
                fill="#ac0521"
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CardComponent
            title="Conversion %"
            content={conversionLeads}
            icon={
              <MenuBookSvg
                width={40}
                height={32}
                viewBox={"0 96 960 960"}
                fill="#ac0521"
              />
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default LeadDashboard;

import React from "react";
import useStyles from "./style";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";

const IQACDashboard = ({ setActiveModule }) => {
  const styles = useStyles();
  const navigate = useNavigate();

  return (
    <div className={styles.mainContainer}>
      <div className={styles.breadCrumbsContainer}>
        <p
          className={styles.breadCrumbsContent}
          onClick={() => navigate("/dashboard")}
        >
          IIHT {" > "}
        </p>
        <p
          className={styles.breadCrumbsContent}
          onClick={() => setActiveModule(0)}
        >
          IQAC {" > "}
        </p>
        <p className={styles.breadCrumbsContent}>IQAC Dashboard</p>
      </div>
      <div className={styles.headerContainer}>
        <div className={styles.breadCrumbsContainer}>
          <GridMenuIcon className={styles.gridIcon} />
          <p className={styles.title}>Dashboard</p>
        </div>
      </div>
    </div>
  );
};

export default IQACDashboard;

import React from 'react';
import useStyles from './style';


const CardComponent = ({ title, icon, content }) => {
  const styles = useStyles();
  return (
    <div className={styles.card}>
      <p className={styles.cardTitle}>{title}</p>
      <div className={styles.cardContent}>
      {icon}
        <p className={styles.cardContentText}>{content}</p>
      </div>
    </div>
  );
};

export default CardComponent;

import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import ArrowDownSVG from "../../../assets/images/arrow-down.svg";
import FiltersSVG from "../../../assets/images/filters-icon.svg";
// import { getAllFeedbackTemplates } from '../../../modules/feedback'; // Commented out for now
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../slices/commonSlice";
import LoadingBackdrop from "../../common/LoadingBackdrop.js";
import { Table } from "antd";
import { getFeedbackTemplate } from "modules/survey";

const FeedbackAssignment = ({ setActiveModule }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [feedbackTemplates, setFeedbackTemplates] = useState([]);

  const columns = [
    {
      dataIndex: "id",
      title: "Template ID",
      width: "20%", // 1.2 out of 6
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.id}</p>;
      },
    },
    {
      dataIndex: "template_name",
      title: "Template Name",
      width: "13.33%", // 0.8 out of 6
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.template_name}</p>;
      },
    },
    {
      dataIndex: "template_based_on",
      title: "Template Type",
      width: "13.33%", // 0.8 out of 6
      render: (val, row) => {
        return (
          <p className={styles.tableContentFields}>{row.template_based_on}</p>
        );
      },
    },
    {
      dataIndex: "question_count",
      title: "Question Count",
      width: "13.33%", // 0.8 out of 6
      render: (val, row) => {
        return (
          <p className={styles.tableContentFields}>{row.question_count}</p>
        );
      },
    },
    {
      dataIndex: "published_on",
      title: "Published On",
      width: "13.33%", // 0.8 out of 6
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.published_on}</p>;
      },
    },
    {
      dataIndex: "last_date_of_submission",
      title: "Last Date of Submission",
      width: "16.67%", // 1 out of 6
      render: (val, row) => {
        return (
          <p className={styles.tableContentFields}>
            {row.last_date_of_submission}
          </p>
        );
      },
    },
    {
      dataIndex: "action",
      title: "ACTION",
      width: "8.33%", // 0.5 out of 6
      render: (val, row) => {
        return (
          <p
            className={styles.tableContentView}
            onClick={() =>
              navigate(`/modules/survey/feedback-assignment/${row.id}`)
            }
          >
            View
          </p>
        );
      },
    },
  ];

  const handleGetAllFeedbackTemplate = async () => {
    dispatch(setLoading({ loading: true }));
    const res = await getFeedbackTemplate();
    if (res.status === 200) {
      dispatch(setLoading({ loading: false }));
      const templates = [];
      console.log(res.data.data, "Response");
      for (let i = 0; i < res.data.data.length; i++) {
        templates.push({
          id: res.data.data[i].name,
          key: res.data.data[i].name,
          template_name: res.data.data[i].template_name,
          template_based_on: res.data.data[i].assigned_based_on,
          question_count: res.data.data[i].question_count,
          published_on: res.data.data[i].publish_on,
          last_date_of_submission: res.data.data[i].last_submission,
        });
      }

      setFeedbackTemplates(templates);
    } else {
      dispatch(setLoading({ loading: false }));
    }
  };

  useEffect(() => {
    handleGetAllFeedbackTemplate();
  }, []);

  return (
    <div className={styles.mainContainer}>
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/dashboard")}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => setActiveModule(0)}
            >
              Survey {" > "}
            </p>
            <p className={styles.breadCrumbsContent}>Feedback Assignment</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>Feedback Assignment</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div className={`${styles.button} ${styles.filterButton}`}>
                <ReactSVG src={FiltersSVG} />
                <p className={styles.buttonText}>Filters</p>
                <ReactSVG src={ArrowDownSVG} />
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>Saved Filters</p>
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>...</p>
              </div>
              <div
                className={styles.activeButton}
                onClick={() =>
                  navigate("/modules/survey/add-new-feedback-assignment")
                }
              >
                <p className={styles.activeButtonText}>
                  + Add Feedback Assignment
                </p>
              </div>
            </div>
          </div>
          <Table
            rowSelection={{ type: "checkbox" }}
            columns={columns}
            dataSource={feedbackTemplates}
            size="small"
            pagination={true}
          />
        </>
      )}
    </div>
  );
};

export default FeedbackAssignment;

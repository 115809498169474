import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { useNavigate, useParams } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import MainHeader from "../../../common/MainHeader";
import MainFooter from "../../../common/MainFooter";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSidebar } from "../../../../slices/financeSlice";
import { setLoading } from "../../../../slices/commonSlice";
import LoadingBackdrop from "../../../common/LoadingBackdrop.js/index.js";
import { toast } from "react-toastify";
import { Select, Table } from "antd";
import {
  getAllFloors, getFloorDetailsById, getBlockDetailsById
} from "modules/seatManagement";

const GetUpdateBlock = () => {
  const styles = useStyles();
  const selectSize = "middle";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { name } = useParams();
  const { loading } = useSelector((state) => state.common);
  const [blockName, setBlockName] = useState("");
  const [floors, setFloors] = useState([{}]);
  const [floorOptions, setFloorOptions] = useState([]);
  const [floorRooms, setFloorRooms] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [updateBlockDetails, setUpdateBlockDetails] = useState(false);

  const handleGetBlockDetailsById = async () => {
    dispatch(setLoading({ loading: true }));
    const payload = {
      doctype: "Block",
      id: name,
    };

    const res = await getBlockDetailsById({ payload });
    if(res.status === 200) {
      const blockDetails = res.data.message;
      const { block_name, block_floor, floor_room } = blockDetails;
      setBlockName(block_name);
      let arr = [];
      for(let i = 0 ; i < block_floor.length ; i++) {
        const { floor } = block_floor[i];
        const payloadForFloorDetails = {
          doctype: "Floor",
          id: floor,
        };
        const res = await getFloorDetailsById({ payload: payloadForFloorDetails });
        const floorDetails = res.data.message;
        arr.push({
          key: i,
          floorId: floorDetails.id,
          floorRooms: floorDetails.floor_room,
          action: i,
        });
      }
      setFloors([...arr]);
      setFloorRooms([...floor_room]);
    }
    
    dispatch(setLoading({ loading: false }));
  };

  const handleGetFloorDetailsById = async ({ id, index }) => {
    const payload = {
      doctype: "Floor",
      id,
    };

    const res = await getFloorDetailsById({ payload });
    const floorDetails = res.data.message;
    let val = floors;
    val[index] = {
      key: index,
      floorId: floorDetails.id,
      floorRooms: floorDetails.floor_room,
      action: index,
    };
    console.log("floors: ", floors);
    console.log("val: ", val)
    setFloors([...val]);
    let arr = [];
    val.map((floor) => {
      arr.push(...floor.floorRooms);
    });
    setFloorRooms(arr);
  };

  const columns = [
    {
      dataIndex: "key",
      title: "No.",
      width: "5%",
      render: (val, row, index) => {
        return <p className={styles.tableContentFields}>{index + 1}</p>;
      },
    },
    {
      dataIndex: "floorId",
      title: "ID",
      width: "85%",
      render: (val, row, index) => {
        return (
          <Select
            className={styles.tableContentFields}
            size={selectSize}
            placeholder="Select"
            style={{ width: "40%", border: "none !important" }}
            value={row.floorId}
            disabled={!updateBlockDetails}
            options={floorOptions}
            onChange={(value) => {
              handleGetFloorDetailsById({ id: value, index });
            }}
          />
        );
      },
    },
    {
      dataIndex: "action",
      title: "Action",
      width: "10%",
      render: (val, row) => {
        return (
          <>
            {row.floorId && (
              <p
                className={`${styles.tableContentView} ${styles.link}`}
                onClick={() => {
                  const url = `/modules/examination/seat-management/floor/${row.floorId}`;
                  window.open(url, "_blank");
                }}
              >
                View
              </p>
            )}
          </>
        );
      },
    },
  ];

  const roomColumns = [
    {
      dataIndex: "key",
      title: "No.",
      width: "5%",
      render: (val, row, index) => {
        return <p className={styles.tableContentFields}>{index + 1}</p>;
      },
    },
    {
      title: "ROOM",
      dataIndex: "room",
      width: "25%",
      render: (val, record, index) => {
        return <p className={styles.tableContentFields}>{record.room}</p>;
      },
    },
    {
      title: "CAPACITY",
      dataIndex: "capacity",
      width: "15%",
      render: (val, record) => {
        return <p className={styles.tableContentFields}>{record.capacity}</p>;
      },
    },
    {
      title: "ROOM NUMBER",
      dataIndex: "room_number",
      width: "15%",
      render: (val, record) => {
        return (
          <p className={styles.tableContentFields}>{record.room_number}</p>
        );
      },
    },
    {
      title: "COLUMN",
      dataIndex: "column",
      width: "15%",
      render: (val, record) => {
        return <p className={styles.tableContentFields}>{record.column}</p>;
      },
    },
    {
      title: "ROW",
      dataIndex: "row",
      width: "15%",
      render: (val, record) => {
        return <p className={styles.tableContentFields}>{record.row}</p>;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "10%",
      render: (val, record) => {
        return (
          <>
            {record.room && (
              <p
                className={styles.link}
                onClick={() => {
                  const url = `/modules/examination/seat-management/room/${record.room}`;
                  window.open(url, "_blank");
                }}
              >
                View
              </p>
            )}
          </>
        );
      },
    },
  ];

  const handleUpdate = async () => {
    if (floorRooms.length <= 0) return;
    dispatch(setLoading({ loading: true }));
    const payload = {
      doctype: "Block",
      block_name: blockName,
    };

    console.log("paylod: ", payload)
    dispatch(setLoading({ loading: false }));
  };

  const handleGetAllFloors = async () => {
    const res = await getAllFloors();
    if (res.status === 200) {
      const floorList = [];
      res.data.message.forEach((floor) => {
        floorList.push({
          value: floor.id,
          label: floor.floor_name,
        });
      });
      setFloorOptions(floorList);
    }
  };

  const handleAddFloorRoom = () => {
    setFloors([...floors, {}]);
  };

  const handleDeleteFloorRoom = () => {
    const resultArray = floors.filter(
      (obj) => !selectedRowKeys.includes(obj.key)
    );
    let arr = [];
    for (let i = 0; i < resultArray.length; i++) {
      arr.push({
        ...resultArray[i],
        key: i,
      });
    }
    setSelectedRowKeys([]);
    let dummyArr = [];
    arr.map((floor) => {
      dummyArr.push(...floor.floorRooms);
    });
    setFloorRooms(dummyArr);
    arr.length > 0 ? setFloors(arr) : setFloors([{}]);
  };

  const onSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = {
    type: "checkbox",
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      key: record.key,
    }),
    selectedRowKeys,
  };

  useEffect(() => {
    handleGetBlockDetailsById();
    handleGetAllFloors();
  }, []);

  return (
    <>
      <MainHeader />
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/dashboard");
                dispatch(setActiveSidebar({ activeSidebar: 0 }));
              }}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/modules/examination");
                dispatch(setActiveSidebar({ activeSidebar: 1 }));
              }}
            >
              Examination {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/modules/examination");
                dispatch(setActiveSidebar({ activeSidebar: 1 }));
              }}
            >
              Seat Management {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/modules/examination/seat-management/blocks");
              }}
            >
              Blocks
            </p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>{name}</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div
                className={styles.button}
                onClick={() => {
                  navigate("/modules/examination/seat-management/blocks");
                }}
              >
                <p className={styles.buttonText}>Back</p>
              </div>
              {updateBlockDetails ? (
                <div className={styles.activeButton} onClick={handleUpdate}>
                  <p className={styles.activeButtonText}>Update</p>
                </div>
              ) : (
                <div
                  className={styles.activeButton}
                  onClick={() => setUpdateBlockDetails(true)}
                >
                  <p className={styles.activeButtonText}>Edit</p>
                </div>
              )}
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Block Name *</p>
                <input
                  type="text"
                  placeholder="ex Text"
                  value={blockName}
                  className={styles.fieldInput}
                  disabled={!updateBlockDetails}
                  onChange={(e) => {
                    setBlockName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.tableField}>
                <p className={styles.fieldLabel}>Block Floor</p>
                <Table
                  rowSelection={{ type: "checkbox", ...rowSelection }}
                  columns={columns}
                  dataSource={floors}
                  size="small"
                  pagination={false}
                />
              </div>
            </div>
            {updateBlockDetails && (
              <div className={styles.fieldButtons}>
                {selectedRowKeys.length > 0 && (
                  <div
                    className={styles.deleteRow}
                    onClick={handleDeleteFloorRoom}
                  >
                    Delete
                  </div>
                )}
                <div className={styles.addRow} onClick={handleAddFloorRoom}>
                  +
                </div>
              </div>
            )}
             {floorRooms.length > 0 && (
              <div className={styles.formRow}>
                <div className={styles.tableField}>
                  <p className={styles.fieldLabel}>Floor Room</p>
                  <Table
                    columns={roomColumns}
                    dataSource={floorRooms}
                    size="small"
                    pagination={false}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default GetUpdateBlock;

export const questionTypeOptions = [
  {
    value: "Single Correct Answer",
    label: "Single Correct Answer",
  },
  {
    value: "Multiple Correct Answer",
    label: "Multiple Correct Answer",
  },
  {
    value: "Fill in the Blanks",
    label: "Fill in the Blanks",
  },
  {
    value: "True/False",
    label: "True/False",
  },
  {
    value: "Match the Columns",
    label: "Match the Columns",
  },
];

export const ProficiencyLevelOptions = [
  {
    value: "Beginner",
    label: "Beginner",
  },
  {
    value: "Intermediate",
    label: "Intermediate",
  },
  {
    value: "Advanced",
    label: "Advanced",
  },
];

export const CategoryOptions = [
  {
    value: "Category1",
    label: "Category1",
  },
  {
    value: "Category2",
    label: "Category2",
  },
  {
    value: "Category3",
    label: "Category3",
  },
];

import React from "react";
import Grid from "@mui/material/Grid";
import ReactQuill from "react-quill";
import LoadingBackdrop from "../../../common/LoadingBackdrop.js";
import { getCurrentDateAndTime } from "../../../../utils/date.js";
import useStyles from "./style.js";
import { Select, Input } from "antd";

const AddEvent = ({ values, setValues, loading }) => {
  const selectSize = "middle";
  const styles = useStyles();
  const eventCategoryOptions = [
    {
      value: "Event",
      label: "Event"
    },
    {
      value: "Meeting",
      label: "Meeting"
    },
    {
      value: "Call",
      label: "Call"
    },
    {
      value: "Sent/Received Email",
      label: "Sent/Received Email"
    },
    {
      value: "Other",
      label: "Other"
    }
  ];
  const statusOptions = [
    {
      value: "Open",
      label: "Open"
    },
    {
      value: "Completed",
      label: "Completed"
    },
    {
      value: "Closed",
      label: "Closed"
    },
    {
      value: "Cancelled",
      label: "Cancelled"
    },
  ];
  const eventTypeOptions = [
    {
      value: "Private",
      label: "Private"
    },
    {
      value: "Public",
      label: "Public"
    },
  ];
  return (
    <>
      {loading ? (
        <LoadingBackdrop open={loading}/>
      ) : (
        <div className={styles.formContainer}>
          <p className={styles.title}>New Event</p>
          <div className={styles.formRow}>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Subject *</p>
              <Input
                placeholder="ex Subject"
                value={values.subject}
                className={styles.fieldInput}
                onChange={(e) => {
                  setValues({
                    ...values,
                    subject: e.target.value,
                  });
                }}
              />
            </div>
           <div className={styles.fieldContainer}>
            <div className={styles.dateField}>
              <p className={styles.fieldLabel}>Starts on *</p>
              <input
                type="datetime-local"
                placeholder="Enter OTP *"
                style={{
                  color: "#536882",
                  fontSize: "12px",
                  padding: "10px",
                  fontWeight: "400",
                  borderRadius: "5px",
                  border: "1px solid #CDD6E2",
                  background: "#fff",
                  outline: "none",
                  width: "100%",
                }}
                onChange={(e) => {
                  setValues({ ...values, startOn: e.target.value });
                }}
                min={getCurrentDateAndTime()}
              />
            </div>
            <div className={styles.dateField}>
              <p className={styles.fieldLabel}>Ends on *</p>
              <input
                type="datetime-local"
                style={{
                  color: "#536882",
                  fontSize: "12px",
                  fontWeight: "400",
                  padding: "12px",
                  borderRadius: "5px",
                  border: "1px solid #CDD6E2",
                  background: "#fff",
                  outline: "none",
                  width: "100%",
                }}
                onChange={(e) => {
                  setValues({ ...values, endsOn: e.target.value });
                }}
                min={getCurrentDateAndTime()}
              />
            </div>
           </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Event Category *</p>
              <Select
                showSearch
                size={selectSize}
                placeholder="Select"
                defaultValue={values.eventCategory}
                className={styles.selectInput}
                options={eventCategoryOptions}
                onChange={(value) => {
                  setValues({
                    ...values,
                    eventCategory: value,
                  })
                }}
              />
            </div>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Status *</p>
              <Select
                showSearch
                size={selectSize}
                placeholder="Select"
                defaultValue={values.status}
                className={styles.selectInput}
                options={statusOptions}
                onChange={(value) => {
                  setValues({
                    ...values,
                    status: value,
                  })
                }}
              />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Event Type *</p>
              <Select
                showSearch
                size={selectSize}
                placeholder="Select"
                defaultValue={values.eventType}
                className={styles.selectInput}
                options={eventTypeOptions}
                onChange={(value) => {
                  setValues({
                    ...values,
                    eventType: value,
                  })
                }}
              />
            </div>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Invite sent to</p>
              <Input
                value={values.addGuests}
                className={styles.fieldInput}
                disabled={true}
              />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Color</p>
              <input
                type="color"
                style={{
                  padding: "18px",
                  borderRadius: "5px",
                  border: "1px solid #CDD6E2",
                  background: "#fff",
                  outline: "none",
                  width: "100%",
                  height:"10px",
                }}
                onChange={(e) => {
                  setValues({ ...values, color: e.target.value });
                }}
              />
            </div>
            <div className={styles.field}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  marginTop: "12px"
                }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    style={{
                      height: "18px",
                      width: "18px",
                      borderRadius: "4px",
                      border: "1px solid #A6B0C2",
                      backgroundColor: "#FFF",
                    }}
                    onClick={() => {
                      setValues({
                        ...values,
                        sendEmail: values.sendEmail === 0 ? 1 : 0,
                      });
                    }}
                  />
                  <p
                    style={{
                      color: "#012148",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    Send the email reminder this morning
                  </p>
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    style={{
                      height: "18px",
                      width: "18px",
                      borderRadius: "4px",
                      border: "1px solid #A6B0C2",
                      backgroundColor: "#FFF",
                      zIndex: "100"
                    }}
                    onClick={() => {
                      setValues({
                        ...values,
                        repeat: values.repeat === 0 ? 1 : 0,
                      });
                    }}
                  />
                  <p
                    style={{
                      color: "#012148",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    Repeat this event
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.formRow} ${styles.bottom}`}>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Description</p>
              <ReactQuill
                value={values.description}
                onChange={(value) => {
                  setValues({ ...values, description: value });
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddEvent;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Breadcrumbs, MainLayout } from "components/common";
import { Button, message, Checkbox, Select } from "antd";
import {
  getGenericData,
  createGenericEntity,
  updateGenericEntity,
  getGenericList,
  getInstitution,
} from "modules/hr";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "slices/commonSlice";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { toast } from "react-toastify";

const LeavePeriodDetails = () => {
  const loading = useSelector((state) => state.common.loading);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [leavePeriod, setLeavePeriod] = useState({
    id: "",
    from_date: "",
    to_date: "",
    company: "", // Updated institution to company
    is_active: 1,
    optional_holiday_list: "",
  });
  const [errors, setErrors] = useState({});
  const [holidayLists, setHolidayLists] = useState([]);
  const [isViewMode, setIsViewMode] = useState(false);
  const [data, setData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [institution, setInstitution] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading({ loading: true }));
      try {
        if (id && id !== "new") {
          const leavePeriodData = await getGenericData("Leave Period", id);
          setLeavePeriod(leavePeriodData.message);
          setIsViewMode(true);
        } else {
          setIsViewMode(false); // Set to false when adding a new entry
        }

        // Fetch existing leave periods
        const leavePeriodList = await getGenericList("Leave Period");
        setData(leavePeriodList);
        setRowData(leavePeriodList);

        // Fetch holiday lists
        const holidayListsData = await getGenericList("Holiday List");
        setHolidayLists(holidayListsData);
        console.log(holidayListsData, "Holiday List");
        dispatch(setLoading({ loading: false }));
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("There is some issue while creating Fee");
        dispatch(setLoading({ loading: false }));
      }
    };

    fetchData();
  }, [id]);

  const handleChange = (value, name) => {
    if (name === "company") {
      setLeavePeriod((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setLeavePeriod((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleGetInstitution = async () => {
    const res = await getInstitution();
    if (res.status === 200) {
      const arr = [];
      for (let i = 0; i < res.data.data.length; i++) {
        arr.push({
          value: res.data.data[i].name,
          label: res.data.data[i].name,
        });
      }

      setInstitution(arr);
    }
  };
  useEffect(() => {
    handleGetInstitution();
  }, []);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setLeavePeriod((prevState) => ({
      ...prevState,
      [name]: checked ? 1 : 0,
    }));
  };

  const handleSave = async () => {
    try {
      const doctype = "Leave Period";
      const validationErrors = validateFields();
  
      if (Object.keys(validationErrors).length > 0) {
        console.log("Validation errors:", validationErrors);
        setErrors(validationErrors);
        return;
      }
  
      console.log("Leave period to save:", leavePeriod);
  
      const payload = {
        ...leavePeriod,
        doctype,
      };
  
      if (id) {
        await updateGenericEntity({ payload });
        toast.success("Leave period updated successfully");
      } else {
        await createGenericEntity({ payload });
        toast.success("Leave period added successfully");
      }
      navigate(-1);
    } catch (error) {
      console.error("Error saving leave period:", error);
      toast.error(error);
    }
  };
  
  

  const checkOverlapWithExistingPeriods = (
    fromDate,
    toDate,
    existingPeriods
  ) => {
    const overlapPeriod = existingPeriods.find(
      (period) =>
        (fromDate >= period.from_date && fromDate <= period.to_date) ||
        (toDate >= period.from_date && toDate <= period.to_date) ||
        (fromDate <= period.from_date && toDate >= period.to_date)
    );
  
    return overlapPeriod;
  };
  
  
  const validateFields = () => {
    const errors = {};

    if (!leavePeriod.from_date) {
      errors.from_date = "From Date is required";
    }
    if (!leavePeriod.to_date) {
      errors.to_date = "To Date is required";
    }
    if (!leavePeriod.company) {
      errors.company = "Company is required";
    }
    if (!leavePeriod.optional_holiday_list) {
      errors.optional_holiday_list = "Optional Holiday List is required";
    }

    return errors;
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <MainLayout>
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <>
          <Breadcrumbs />
          <div className="master-heading">
            <h2>{isViewMode ? "Leave Period" : "New Leave Period"}</h2>
            <div className="btnsGrp">
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                back
              </Button>
              <Button
                onClick={handleSave}
                variant="contained"
                color="primary"
                className="redBtn"
              >
                {isViewMode ? "Update" : "Save"}
              </Button>
            </div>
          </div>

          <div className="formApplication">
            <section className="form-section">
              <div className="fields-block">
                <div className="form-field">
                  <label>From Date *</label>
                  <input
                    type="date"
                    name="from_date"
                    value={leavePeriod.from_date}
                    onChange={(e) => handleChange(e.target.value, "from_date")}
                  />
                  {errors.from_date && (
                    <span className="error-message">{errors.from_date}</span>
                  )}
                </div>
                <div className="form-field">
                  <label>To Date *</label>
                  <input
                    type="date"
                    name="to_date"
                    value={leavePeriod.to_date}
                    onChange={(e) => handleChange(e.target.value, "to_date")}
                  />
                  {errors.to_date && (
                    <span className="error-message">{errors.to_date}</span>
                  )}
                </div>
                <div className="form-field">
                  <label>Institution *</label>
                  <Select
                    name="company"
                    placeholder="Select Institution"
                    options={institution}
                    value={leavePeriod.company}
                    onChange={(value) => handleChange(value, "company")}
                  />
                  {errors.company && (
                    <span className="error-message">{errors.company}</span>
                  )}
                </div>
                <div className="form-field">
                  <label>Holiday List *</label>
                  <Select
                    name="optional_holiday_list"
                    onChange={(value) =>
                      handleChange(value, "optional_holiday_list")
                    }
                    value={leavePeriod.optional_holiday_list}
                    placeholder="Select Optional Holiday List"
                  >
                    {holidayLists.map((holidayList) => (
                      <Select.Option
                        key={holidayList.id}
                        value={holidayList.id}
                      >
                        {holidayList.holiday_list_name}
                      </Select.Option>
                    ))}
                  </Select>
                  {errors.optional_holiday_list && (
                    <span className="error-message">
                      {errors.optional_holiday_list}
                    </span>
                  )}
                </div>
                <div className="form-field fullWidthFields">
                  <div className="isActive">
                    <Checkbox
                      name="is_active"
                      checked={leavePeriod.is_active === 1}
                      onChange={handleCheckboxChange}
                    />
                    <label>Is Active</label>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </MainLayout>
  );
};

export default LeavePeriodDetails;

import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { useDispatch, useSelector } from "react-redux";
import { Select, Table, Input } from "antd";
import { toast } from "react-toastify";
import MainHeader from "components/common/MainHeader";
import MainFooter from "components/common/MainFooter";
import { createGradingScale } from "modules/hr";

const AddNewGradingScale = () => {
  const styles = useStyles();
  const selectSize = "middle";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [values, setValues] = useState({
    grading_scale_name: "",
    components: [{}],
    description: "",
  });
  const [errors, setErrors] = useState({
    grading_scale_name: "",
    components: [],
  });

  const handleGradingScaleNavigation = () => {
    navigate("/modules/hr");
  };

  const handleAddGradingScale = () => {
    setValues({
      ...values,
      components: [...values.components, {}],
    });
    setErrors({
      ...errors,
      components: [],
    });
  };

  const handleDeleteGradingScale = () => {
    const updatedComponents = values.components.filter(
      (_, index) => !selectedRowKeys.includes(index)
    );
    setValues({
      ...values,
      components: updatedComponents,
    });
    setSelectedRowKeys([]); // Clear selected row keys
    setErrors({
      ...errors,
      components: [],
    });
  };

  const onSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = {
    type: "checkbox",
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      grade_code: record.grade_code,
    }),
    selectedRowKeys,
  };

  const columns = [
    {
      title: "NO.",
      dataIndex: "index",
      width: "5%",
      render: (val, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Grade Code *",
      dataIndex: "grade_code",
      width: "25%",
      render: (val, record, index) => {
        return (
          <Input
            placeholder="Enter Grade Code"
            className={styles.tableContentFields}
            style={{ width: "100%", border: "none" }}
            value={record.grade_code}
            onChange={(e) => {
              const { value } = e.target;
              setValues((prevValues) => ({
                ...prevValues,
                components: prevValues.components.map((item, i) => {
                  if (i === index) {
                    return { ...item, grade_code: value };
                  }
                  return item;
                }),
              }));
            }}
          />
        );
      },
    },
    {
      title: "Threshold *",
      dataIndex: "threshold",
      width: "25%",
      render: (val, record, index) => {
        return (
          <Input
            placeholder="Enter Threshold"
            className={styles.tableContentFields}
            style={{ width: "100%", border: "none" }}
            value={record.threshold}
            onChange={(e) => {
              const { value } = e.target;
              setValues((prevValues) => ({
                ...prevValues,
                components: prevValues.components.map((item, i) => {
                  if (i === index) {
                    return { ...item, threshold: value };
                  }
                  return item;
                }),
              }));
            }}
            addonAfter="%"
          />
        );
      },
    },
    {
      title: "Grade Description",
      dataIndex: "grade_description",
      width: "40%",
      render: (val, record, index) => {
        return (
          <Input.TextArea
            placeholder="Enter Grade Description"
            className={styles.tableContentFields}
            style={{ width: "100%", border: "none" }}
            value={record.grade_description}
            onChange={(e) => {
              const { value } = e.target;
              setValues((prevValues) => ({
                ...prevValues,
                components: prevValues.components.map((item, i) => {
                  if (i === index) {
                    return { ...item, grade_description: value };
                  }
                  return item;
                }),
              }));
            }}
          />
        );
      },
    },
  ];

  const handleValidation = () => {
    let isValid = true;
    const newErrors = { ...errors };

    // Validate mandatory fields
    if (!values.grading_scale_name) {
      newErrors.grading_scale_name = "Grading Scale Name is required";
      isValid = false;
    } else {
      newErrors.grading_scale_name = "";
    }

    // Validate components
    const componentErrors = values.components.map((component, index) => {
      if (!component.grade_code || !component.threshold) {
        isValid = false;
        return `Row ${index + 1}: Grade Code and Threshold are mandatory`;
      } else if (parseFloat(component.threshold) > 1) {
        isValid = false;
        return `Row ${index + 1}: Threshold should be less than 1`;
      } else {
        return "";
      }
    });

    newErrors.components = componentErrors;
    setErrors(newErrors);

    return isValid;
  };

  const handleSave = async () => {
    const isValid = handleValidation();
    if (isValid) {
      try {
        const payload = {
          doctype: "Grading Scale",
          grading_scale_name: values.grading_scale_name,
          intervals: values.components.map((component) => ({
            grade_code: component.grade_code,
            threshold: component.threshold,
            grade_description: component.grade_description,
          })),
          description: values.description,
        };
        const res = await createGradingScale({ payload });
        console.log(res);
        if (res.status === 200) {
          toast.success("Grading Scale created!");
          navigate(`/modules/hr/grading-scale/${res.data.message.name}`);
        } else {
          toast.error("There was an issue while saving grading scale.");
        }
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    }
  };

  return (
    <>
      <MainHeader />
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/dashboard")}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/modules/hr")}
            >
              HR {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={handleGradingScaleNavigation}
            >
              Grading Scale {" > "}
            </p>
            <p className={styles.breadCrumbsContent}>Create</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>New Grading Scale</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div
                className={styles.button}
                onClick={handleGradingScaleNavigation}
              >
                <p className={styles.buttonText}>Back</p>
              </div>
              <div className={styles.activeButton}>
                <p className={styles.activeButtonText} onClick={handleSave}>
                  Save
                </p>
              </div>
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Grading Scale Name *</p>
                <Input
                  placeholder="ex Text"
                  value={values.grading_scale_name}
                  className={styles.fieldInput}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      grading_scale_name: e.target.value,
                    });
                    setErrors({
                      ...errors,
                      grading_scale_name: "",
                    });
                  }}
                />
                {errors.grading_scale_name && (
                  <p className={styles.errorMessage}>
                    {errors.grading_scale_name}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.tableField}>
                <p className={styles.fieldLabel}>Grading Scale Interval *</p>
                <Table
                  rowSelection={{ type: "checkbox", ...rowSelection }}
                  columns={columns}
                  dataSource={values.components.map((item, index) => ({
                    ...item,
                    key: index,
                    index,
                  }))}
                  size="small"
                  pagination={false}
                  rowKey={(record) => record.key}
                />
                {errors.components && (
                  <p className={styles.errorMessage}>{errors.components}</p>
                )}
              </div>
            </div>
            <div className={styles.fieldButtons}>
              {selectedRowKeys.length > 0 && (
                <div
                  className={styles.deleteRow}
                  onClick={handleDeleteGradingScale}
                >
                  Delete
                </div>
              )}
              <div className={styles.addRow} onClick={handleAddGradingScale}>
                +
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.textarea}>
                <p className={styles.fieldLabel}>Description </p>
                <textarea
                  type="text"
                  placeholder="Enter Text here..."
                  className={styles.textAreaInput}
                  value={values.description}
                  rows={5}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      description: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default AddNewGradingScale;

import React, { useState } from "react";
import "./index.scss";
import { FileUploader } from "react-drag-drop-files";
import { uploadFile } from "../../../modules/student";
import { toast } from "react-toastify";

function UploadButton({ formData: dataToChange, setFormData, fieldName , flag, setFlag, fileTypes }) {
  const [loading, setLoading] = useState(false);

  const handleChange = (file) => {
    if(!loading) {
      setLoading(true);
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('is_private', 0);
  
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.onreadystatechange = () => {
          if (xhr.readyState == XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
              const r = JSON.parse(xhr.responseText);
              const data = dataToChange;
              data[`${fieldName}`] = r.message.file_url;
              setFormData(data);
              setFlag(!flag);
            } else {
              toast.error("some issue while uploading!");
            }
          }
          setLoading(false);
        }
  
        xhr.open("POST", "/api/method/upload_file");
        xhr.send(formData);
      };
    }
  };

  return (
    <FileUploader multiple={false} handleChange={handleChange} name="file" types={fileTypes} label={loading?  "Uploading file" : "Upload File"} />
  );
}
export default UploadButton;






import MasterIcon from "../../../assets/images/master-list-icon.svg";
export const fieldsData = [
  { name: "Masters", link: "masters" },
  { name: "Job Applications", link: "/modules/hr/job-application" },
  { name: "Job Opening", link: "/modules/hr/jobOpening" },
  { name: "Employee", link: "/modules/hr/employee" },
].map((field) => ({ ...field, icon: MasterIcon }));

export const JobOpening = [
  { name: "Add Job Opening", link: "/modules/hr/jobOpening/addJobOpening" },
].map((field) => ({ ...field }));

export const mastersFieldsData = [
  { name: "Interview Type", link: "/modules/hr/masters/interview-type" },
  { name: "Designation", link: "/modules/hr/masters/designation" },
  { name: "Interview Round", link: "/modules/hr/masters/interview-round" },
  { name: "Skills", link: "/modules/hr/masters/skills" },
  { name: "Offer Term", link: "/modules/hr/masters/offer-term" },
  {
    name: "Offer Term Template",
    link: "/modules/hr/masters/offer-term-template",
  },
  {
    name: "Appointment Letter Template",
    link: "/modules/hr/masters/appointment-letter-template",
  },
].map((field) => ({ ...field, icon: MasterIcon }));

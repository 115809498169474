import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: "flex", 
    flexDirection: "column",
    gap: "28px"
  },
  breadCrumbsContainer: {
    display: "inline-flex", 
    gap: "4px", 
    alignItems: "center"
  },
  breadCrumbsContent: {
    color: "#6F6F6F",
    fontSize: "12px",
    fontWeight: "400",
    cursor: "pointer"
  },
  headerContainer: {
    display: "inline-flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "-20px"
  },
  title: {
    fontSize: "22px", 
    color: "#0D1021", 
    fontWeight: "500"
  },
  gridIcon: {
    color: "#647B8F"
  },
  fieldSelect: {
    fontSize: "12px",
    fontWeight: "400",
    borderRadius: "8px",
    background: "#fff",
    outline: "none",
    height: "40px",
    width:'15%'
  },
  inputContainer: {
    marginBottom: '20px',
  },
  questionContainer: {
    marginBottom: '20px',
  },
  commentsBox: {
    width: '100%',
    height: "200px",
    borderRadius: '4px',
    border: '1px solid #CCCCCC',
    padding: '16px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#E6EBF2', 
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D9D9D9',
      borderRadius: '3px', 
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
  },
  selectRatingContainer: {
    display: 'flex',
    gap: '70px',
  },
  selectContainer: {
    flexBasis: '50%',
  },
  ratingContainer: {
    flexBasis: '50%',
  },
  responseLine:{
    backgroundColor:"#E6EBF2",
  },
  answer:{
    padding:"6px",
    fontSize:"14px",
  },
  answers:{
    padding:"9px",
    fontSize:"14px",
  },
  stars:{
    padding:"3.5px",
  },  
}));

export default useStyles;

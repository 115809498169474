import React, { useEffect, useState } from 'react';
import useStyles from './style';
import { ReactSVG } from 'react-svg';
import { useNavigate } from 'react-router-dom';
import { GridMenuIcon } from '@mui/x-data-grid';
import ArrowDownSVG from "../../../assets/images/arrow-down.svg";
import FiltersSVG from "../../../assets/images/filters-icon.svg";
// import { getAllFeedbackTemplates } from '../../../modules/feedback'; // Commented out for now
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../slices/commonSlice';
import LoadingBackdrop from "../../common/LoadingBackdrop.js";
import { Table } from 'antd';
import { getFeedbackTemplate } from 'modules/survey';

const FeedbackTemplate = ({ setActiveModule }) => {
  const styles = useStyles();
  const navigate= useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [feedbackTemplates, setFeedbackTemplates] = useState([]);

  const columns = [
    {
      dataIndex: "id",
      title: "ID",
      width: 3,
      render: (val, row) => {
        return (
          <p className={styles.tableContentFields}>{row.id}</p>
        );
      },
    },
    {
      dataIndex: "template_name",
      title: "Template Name",
      width: 0.8,
      render: (val, row) => {
        return (
          <p className={styles.tableContentFields}>{row.template_name}</p>
        );
      },
    },
    {
      dataIndex: "template_type",
      title: "Template Type",
      width: 0.8,
      render: (val, row) => {
        return (
          <p className={styles.tableContentFields}>{row.template_type}</p>
        );
      },
    },
    {
      dataIndex: "question_count",
      title: "Question Count",
      width: 0.8,
      render: (val, row) => {
        return (
          <p className={styles.tableContentFields}>{row.question_count}</p>
        );
      },
    },
    {
      dataIndex: "created_date",
      title: "Created Date",
      width: 0.8,
      render: (val, row) => {
        return (
          <p className={styles.tableContentFields}>{row.created_date}</p>
        );
      },
    },
    {
      dataIndex: "created_by",
      title: "Created By",
      width: 0.8,
      render: (val, row) => {
        return (
          <p className={styles.tableContentFields}>{row.created_by}</p>
        );
      },
    },
  ];
  
  const convertCreationTime = (creationTime) => {
    const date = new Date(creationTime);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };  

  // useEffect(() => {
  //   // handleGetAllFeedbackTemplates(); // Commented out for now
  //   // Dummy data for feedbackTemplates
  //   const dummyData = [
  //     { id: 1, category_name: "Category 1", template_name: "Template 1", template_type: "Type 1", question_count: 5, created_date: "2023-01-01", created_by: "User 1" },
  //     { id: 2, category_name: "Category 2", template_name: "Template 2", template_type: "Type 2", question_count: 7, created_date: "2023-01-02", created_by: "User 2" },
  //     // Add more dummy data as needed
  //   ];
  //   setFeedbackTemplates(dummyData);
  // }, []);

  const handleGetAllFeedbackTemplate = async () => {
    dispatch(setLoading({ loading: true }));
    const res = await getFeedbackTemplate();
    if(res.status === 200) {
      dispatch(setLoading({ loading: false }));
      const templates = [];
      console.log(res.data.data,"Response")
      for(let i = 0 ; i < res.data.data.length ; i++) {
        templates.push({
          id: res.data.data[i].name,
          idx: res.data.data[i].idx,
          template_name: res.data.data[i].template_name,
          created_date: convertCreationTime(res.data.data[i].creation),
          modified: res.data.data[i].modified,
          modified_by: res.data.data[i].modified_by,
          docstatus: res.data.data[i].docstatus,
          naming_series: res.data.data[i].naming_series,
          template_type: res.data.data[i].assigned_based_on,
          created_by: res.data.data[i].owner,
          question_count:res.data.data[i].question_count
        })
      };
      setFeedbackTemplates(templates);
    } else {
      dispatch(setLoading({ loading: false }));
    }
  };

  useEffect(() => {
    handleGetAllFeedbackTemplate();
  }, []);

  return (
    <div className={styles.mainContainer}>
      {
        loading ?
        <LoadingBackdrop open={loading} />:
        <>
          <div className={styles.breadCrumbsContainer}>
            <p className={styles.breadCrumbsContent} onClick={() => navigate("/dashboard")}>IIHT {" > "}</p>
            <p className={styles.breadCrumbsContent} onClick={() => setActiveModule(0)}>Survey {" > "}</p>
            <p className={styles.breadCrumbsContent}>Feedback Template</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon}/>
              <p className={styles.title}>Feedback Template</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div className={`${styles.button} ${styles.filterButton}`}>
                <ReactSVG src={FiltersSVG} />
                <p className={styles.buttonText}>Filters</p>
                <ReactSVG src={ArrowDownSVG} />
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>Saved Filters</p>
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>...</p>
              </div>
              <div className={styles.activeButton} onClick={() => navigate("/modules/survey/New-template")}>
                <p className={styles.activeButtonText}>+ Add Feedback Template</p>
              </div>
            </div>
          </div>
          <Table 
            rowSelection={{ type: "checkbox" }} 
            columns={columns} 
            dataSource={feedbackTemplates} 
            size='small'
            pagination={true}
          />
        </>
      }
    </div>
  )
}

export default FeedbackTemplate;
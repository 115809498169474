import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridMenuIcon } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import FilterListIcon from "@mui/icons-material/FilterList";
import Button from "iiht-b2a-ui-components/build/components/Button";
import { getApplicationsList } from "../../modules/studentApplication";
import LoadingBackdrop from "../../components/common/LoadingBackdrop.js";
import { setLoading } from "../../slices/commonSlice.js";
import { useSelector, useDispatch } from "react-redux";
import CommonFilters from "../../components/Filters/CommonFilters.js";
import SavedFilters from "../../components/Filters/SavedFilters.js";

const columns = [
  {
    field: "name",
    headerName: "Application Id",
    sortable: false,
    flex: 0.7,
  },
  {
    field: "first_name",
    headerName: "Candidate Name",
    sortable: false,
    headerClassName: "studentName",
    flex: 0.5,
    renderCell: ({ row }) => {
      return (
        <>
          <span className="studentName">{row.first_name}</span>
        </>
      );
    },
  },
  {
    field: "custom_program_group",
    headerName: "Type",
    sortable: false,
    flex: 0.5,
  },
  {
    field: "program",
    headerName: "Position",
    flex: 1,
  },
  {
    field: "college",
    headerName: "Programme",
    flex: 1,
  },
  {
    field: "campus",
    headerName: "Campus",
    flex: 0.5,
  },
  {
    field: "application_status",
    headerName: "Status",
    sortable: false,
    flex: 0.5,
    renderCell: ({ row }) => {
      const dynamicClassName = `status ${row.application_status}`;
      return (
        <>
          <span className={dynamicClassName}>{row.application_status}</span>
        </>
      );
    },
  },
  {
    field: "action",
    headerName: "Action",
    flex: 0.5,
    renderCell: ({ row }) => {
      return (
        <>
          <a
            href={`/jobapplications/:id=${row?.id}/:type=general`}
            variant="contained"
            className="viewLink"
            style={{ textDecoration: "none" }}
          >
            View
          </a>
        </>
      );
    },
  },
];

export default function ApplicationsList() {
  const dispatch = useDispatch();
  const { doctype, filters } = useSelector((state) => state.filters);
  const [filtering, setFiltering] = useState(false);
  const [rowData, setRowData] = useState([]);
  const { loading } = useSelector((state) => state.common);
  const [isAddApplication, setIsAddApplication] = useState(false);

  const dummyData = [
    {
      id: 1,
      name: "APP001",
      first_name: "John",
      custom_program_group: "Group A",
      program: "Computer Science",
      college: "ABC College",
      campus:"Engineering",
      application_status: "Pending",
    },
    // Add more dummy data as needed
  ];

  useEffect(() => {
    setRowData(dummyData);
  }, []);

  const CustomToolbar = () => {
    const handleAddAppClick = () => {
      setIsAddApplication(true);
    };
    return (
      <div className="headingBtns">
        <Typography variant="h5" className="mainHeading">
          <GridMenuIcon />
          Applications
        </Typography>
        <div
          className="btnsGrp"
          style={{
            display: "flex",
          }}
        >
          <CommonFilters doctype={"Student Applicant"}></CommonFilters>
          <SavedFilters doctype={"Student Applicant"}></SavedFilters>
          <Button
            variant="outlined"
            className="redBtn"
            onClick={handleAddAppClick}
          >
            + Add Application
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Box sx={{ height: "100%" }}>
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <DataGrid
          rowHeight={70}
          className="mainTable"
          columns={columns}
          rows={rowData}
          disableRowSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      )}
    </Box>
  );
}

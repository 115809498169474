import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridMenuIcon } from "@mui/x-data-grid";
import ArrowDownSVG from "../../../assets/images/arrow-down.svg";
import FiltersSVG from "../../../assets/images/filters-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../slices/commonSlice";
import LoadingBackdrop from "../../common/LoadingBackdrop.js";
import { getAllFees } from "../../../modules/finance.js";
import { Table } from "antd";

const Fees = ({ setActiveModule }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [FeeList, setFeeList] = useState([]);

  const columns = [
    {
      dataIndex: "student_name",
      title: "STUDENT NAME",
      width: "15%",
      render: (student_name) => (
        <p className={styles.tableContentFields}>{student_name}</p>
      ),
    },
    {
      dataIndex: "student_id",
      title: "Student ID",
      width: "20%",
      render: (student_id) => (
        <p className={styles.tableContentFields}>{student_id}</p>
      ),
    },
    {
      dataIndex: "program",
      title: "Program",
      width: "15%",
      render: (program) => (
        <p className={styles.tableContentFields}>{program}</p>
      ),
    },
    {
      dataIndex: "fee_id",
      title: "Fee ID",
      width: "20%",
      render: (fee_id) => <p className={styles.tableContentFields}>{fee_id}</p>,
    },
    {
      dataIndex: "status",
      title: "Status",
      width: "10%",
      render: (status, row) => (
        <p
          className={
            row.status === "Paid" ? styles.buttonPaid : styles.buttonUnPaid
          }
        >
          {status}
        </p>
      ),
    },
    {
      dataIndex: "id",
      title: "Action",
      width: "10%",
      render: (id, row) => (
        <p
          className={styles.tableContentView}
          onClick={() => navigate(`/modules/finance/fee/${row.fee_id}`)}
        >
          View
        </p>
      ),
    },
  ];

  const handleGetFeeListing = async () => {
    dispatch(setLoading({ loading: true }));
    const res = await getAllFees();
    console.log(res.data.data);
    if (res.status === 200) {
      dispatch(setLoading({ loading: false }));
      const fees = [];
      for (let i = 0; i < res.data.data.length; i++) {
        fees.push({
          id: i,
          student_name: res.data.data[i].student_name,
          student_id: res.data.data[i].student,
          program: res.data.data[i].program,
          fee_id: res.data.data[i].name,
          status: res.data.data[i].outstanding_amount > 0 ? "Unpaid" : "Paid",
        });
      }
      setFeeList(fees);
    } else {
      dispatch(setLoading({ loading: false }));
    }
  };

  const sortedDataSource = [...FeeList].sort((a, b) => {
    return b.fee_id.localeCompare(a.fee_id);
  });

  useEffect(() => {
    handleGetFeeListing();
  }, []);
  return (
    <div className={styles.mainContainer}>
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/modules")}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => setActiveModule(0)}
            >
              Finance {" > "}
            </p>
            <p className={styles.breadCrumbsContent}>Fee</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>Fees</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div className={`${styles.button} ${styles.filterButton}`}>
                <ReactSVG src={FiltersSVG} />
                <p className={styles.buttonText}>Filters</p>
                <ReactSVG src={ArrowDownSVG} />
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>Saved Filters</p>
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>...</p>
              </div>
              <div
                className={styles.activeButton}
                onClick={() => navigate("/modules/finance/add-new-fee")}
              >
                <p className={styles.activeButtonText}>+ Add Fee</p>
              </div>
            </div>
          </div>
          <Table
            rowSelection={{ type: "checkbox" }}
            columns={columns}
            dataSource={sortedDataSource}
            size="small"
            pagination={true}
          />
        </>
      )}
    </div>
  );
};

export default Fees;

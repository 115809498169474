const APIs = {
  login: "/api/method/techademy_erpnext.custom_auth.login",
  forgotpassword:
    "/api/method/techademy_erpnext.verification.reset_password_mail",
  resetpassword: "/api/method/techademy_erpnext.verification.update_password",
  createLead: "/api/resource/Lead",
  getotpemail: "/api/method/techademy_erpnext.verification.generate_otp",
  getotpphone: "/api/method/techademy_erpnext.verification.generate_otp",
  verifyemail: "/apdi/method/techademy_erpnext.verification.verify_otp",
  verifyphone: "/api/method/techademy_erpnext.verification.verify_otp",
  getCountryList: `/api/resource/Country?limit="*"`,
  getStateList: "/api/resource/State",
  getSubdivisionList: "/api/resource/Subdivision",
  getDistrictList: "/api/resource/District",
  getCityList: "/api/resource/City",
  getCollegeList: "/api/resource/Company",
  getProgramList: "/api/resource/Program Group",
  getProgramGroupList: "/api/resource/Program Group",
  getLeadList: `/api/resource/Lead`,
  getEventList: "/api/resource/Event",
  getLeadDetails: "/api/resource/Lead",
  getUser: "/api/resource/User",
  getUserByEmail: "/api/method/techademy_erpnext.profile.get_user_profile",
  createEvent: "/api/resource/Event",
  getTemplate: "/api/resource/Email Template",
  selectTemplate: "/api/resource/Email Template",
  sendEmail: "/api/method/frappe.core.doctype.communication.email.make",
  getEmailList: "/api/resource/Communication",
  createNote: "/api/method/techademy_erpnext.notes.notes",
  createInvoice:
    "/api/method/techademy_erpnext.custom_accounting.sales_invoice.create_sales_invoice",
  paymentCallback:
    "/api/method/techademy_erpnext.custom_accounting.sales_invoice.razorpay_callback",
  getSentEmail: "/api/resource/Communication",
  getEmailThread: "/api/method/frappe.desk.form.load.getdoc",
  getReceiveEmail: "/api/resource/Communication",
  getAppicationFormListing:
    "/api/method/techademy_erpnext.techademy_education.doctype.admission_form_template.admission_form_template.get_application_form",
  addDataToApplicationForm: "/api/resource/Student Applicant",
  uploadFile: "/api/method?cmd=uploadfile&isprivate=0&from_form=0",
  getGenderList: "/api/resource/Gender",
  getGenderPronounList: "/api/resource/Preferred Gender Pronoun",
  getStudentCategoryList: "/api/resource/Student Category",
  createStudentAppForm: "/api/resource/Student Applicant",
  getApplicationsList: "/api/resource/Student Applicant",
  createGuardianForm: "/api/resource/Guardian",
  getStudentDetails: "/api/resource/Student Applicant",
  getAdmissionPreference: "/api/resource/Admission Preference",
  getApplicantDetails:
    "/api/method/techademy_erpnext.custom_education.student_applicant.create_student_applicant",
  getWorkflowForStudentApplicant:
    "/api/method/techademy_erpnext.meta.get_workflow_info?document_type=Student%20Applicant",
  getSubjectsList: `/api/resource/Subject?fields=["*"]`,
  getPaymentList:
    "/api/method/techademy_erpnext.custom_accounting.payment_entry.get_payment_list",
  createNotes: "/api/method/techademy_erpnext.notes.notes",
  getWorkflow: "/api/method/techademy_erpnext.meta.get_workflow_info",
  getPrograms: "/api/resource/Program",
  getPaymentReceipt: "/api/method/frappe.www.printview.get_html_and_style",
  filters: "/api/method/techademy_erpnext.meta.filter",
  saveFilters: "/api/method/frappe.client.insert",
  getSavedFilters: "/api/resource/List Filter",
  getEntityList: "/api/method/techademy_erpnext.masters.get_entity_list",
  getEntityData: "/api/method/techademy_erpnext.masters.get_entity",
  createEntity: "/api/method/techademy_erpnext.masters.create_entity",
  feeCategory: "/api/resource/Fee Category",
  feeStructure: "/api/resource/Fee Structure",
  bulkActionExport: "/api/method/techademy_erpnext.bulk_actions.export",
  bulkActionDelete: "/api/method/techademy_erpnext.bulk_actions.delete",
  bulkActionEdit: "/api/method/techademy_erpnext.generic.update_value",
  feeSchedule: "/api/resource/Fee Schedule",
  studentGroup: "/api/resource/Student Group",
  getAcademicYear: "/api/resource/Academic Year",
  getAcademicTerm: "/api/resource/Academic Term",
  getProgram: "/api/resource/Program",
  getBatch: "/api/resource/Batch",
  getStudentCategory: "/api/resource/Student Category",
  getInstructor: "/api/resource/Instructor",
  getStudents:
    "/api/method/education.education.doctype.student_group.student_group.get_students",
  getAcademicYearList: "/api/resource/Academic Year",
  getAcademicTermlist: "/api/resource/Academic Term",
  paymentRequest: "/api/resource/Payment Request",
  getModeOfPaymentList: "/api/resource/Mode of Payment",
  paymentEntry: "/api/resource/Payment Entry",
  getAccountPaidToList:
    '/api/method/frappe.desk.search.search_link?txt=&doctype=Account&ignore_user_permissions=0&reference_doctype=Payment Entry&page_length=10&filters={"account_type":["in",["Bank","Cash"]],"is_group":0,"company":"Techademy"}',
  getAccountPaidFromList:
    '/api/method/frappe.desk.search.search_link?txt=&doctype=Account&ignore_user_permissions=0&reference_doctype=Payment Entry&page_length=10&filters={"account_type":["in",["Receivable"]],"is_group":0,"company":"Techademy"}',
  createRecruit: "/api/resource/Recruitment Form",
  getCurrencyList: "/api/resource/Currency",
  getFeeList: "/api/resource/Fees",
  getStudentList: "/api/resource/Student",
  getGenericList: "/api/method/techademy_erpnext.generic.get_list",
  getGenericData: "/api/method/techademy_erpnext.generic.get_data",
  createGenericData: "/api/method/techademy_erpnext.generic.create_update_data",
  createUpdateGenericData:
    "/api/method/techademy_erpnext.generic.create_update_data",
  getReceivableAccountList:
    "/api/method/frappe.desk.search.search_link?txt=&doctype=Account&ignore_user_permissions=0&reference_doctype=Fees&page_length=10",
  getIncomeAccountList:
    "/api/method/frappe.desk.search.search_link?txt=&doctype=Account&ignore_user_permissions=0&reference_doctype=Fees&page_length=10",
  getCostCenterList:
    "/api/method/frappe.desk.search.search_link?txt=&doctype=Cost Center&ignore_user_permissions=0&reference_doctype=Fees&page_length=10&query=erpnext.controllers.queries.get_filtered_dimensions",
  updateUserDetails:
    "/api/method/techademy_erpnext.profile.update_user_profile",
  updateUserProfilePicture:
    "/api/method/techademy_erpnext.profile.update_user_profile_pic",
  getLanguagesAvailable: "/api/method/frappe.desk.search.search_link",
  getBulkEditSelectFieldOptions:
    "/api/method/techademy_erpnext.generic.get_fields",
  getStudentsInFeeSchedule: "/api/resource/Student Group",
  jobApply: "/api/method/techademy_erpnext.generic.guest_create_update_data",
  getJobs: "/api/resource/Staffing Plan",
  jobOpening: "/api/method/techademy_erpnext.generic.get_list",
  meritListCriteria: "/api/resource/Merit List Criteria",
  meritListCastTemplate: "/api/resource/Merit List Criteria Template",
  getMeritList: "/api/resource/Merit List",
  getFilteredStudentInMeritList:
    "/api/method/techademy_erpnext.techademy_education.doctype.merit_list.merit_list.get_filtered_student",
  createMeritList:
    "/api/method/techademy_erpnext.techademy_education.doctype.merit_list.merit_list.create_or_update_merit_list",
  generalLedger: "/api/method/frappe.desk.query_report.run",
  getFeeSchedule: "/api/resource/Fee Schedule",
  updateFeeSchedule: "/api/resource/Fee Schedule",
  getInterviewType: "/api/method/techademy_erpnext.generic.get_data",
  createInterviewType:
    "/api/method/techademy_erpnext.generic.create_update_data",
  updateInterviewType:
    "/api/method/techademy_erpnext.generic.create_update_data",
  deleteInterviewType: "/api/method/techademy_erpnext.bulk_actions.delete",
  getStudentsInEnrollment:
    "/api/method/techademy_erpnext.custom_education.student.get_students",
  enrollStudents:
    "/api/method/techademy_erpnext.custom_education.program_enrollment_tool.enroll_students",
  createFeeFormFeeSchedule:
    "/api/method/techademy_erpnext.custom_education.fee_schedule.create_fee",
  getBatchName: "/api/resource/Student Batch Name",
  createGenericEntity:
    "/api/method/techademy_erpnext.generic.create_update_data",
  updateGenericData: "/api/method/techademy_erpnext.generic.create_update_data",
  deleteGenericData: "/api/method/techademy_erpnext.bulk_actions.delete",
  getDesignation: "/api/resource/Designation",
  getInstitution: "/api/resource/Company",
  getDepartment: "/api/resource/Department",
  getLocation: "/api/resource/Branch",
  getEmploymentType: "/api/resource/Employment Type",
  createJobOpening: "/api/method/techademy_erpnext.generic.create_update_data",
  getJobDetails: "/api/method/techademy_erpnext.generic.get_data",
  getDesignationApi: "/api/method/techademy_erpnext.generic.get_data",
  createDesignation: "/api/method/techademy_erpnext.generic.create_update_data",
  updateDesignation: "/api/method/techademy_erpnext.generic.create_update_data",
  getEmployeeList: "/api/resource/Employee",
  getAllAttendanceList: "/api/method/techademy_erpnext.generic.get_list",
  getShiftTypeList: "/api/resource/Shift Type",
  employeeAttendance:
    "/api/method/techademy_erpnext.generic.create_update_data",
  employeeAttendanceByID: "/api/method/techademy_erpnext.generic.get_data",
  getLeaveType: "/api/resource/Leave Type",
  getFeeStructureDetails: "/api/resource/fee structure",
  getStudentGroupDataFeeSchedule:
    "/api/method/frappe.desk.search.search_link?txt=&doctype=Student Group&ignore_user_permissions=0&reference_doctype=Fee Schedule Student Group&page_length=999",
  createLeaveType: "/api/method/techademy_erpnext.generic.create_update_data",
  getFileList:
    "/api/method/techademy_erpnext.custom_education.file.get_file_list",
  customUploadFile: "/api/method/upload_file",
  createNewFolder: "/api/method/frappe.core.api.file.create_new_folder",
  cutPasteFile: "/api/method/techademy_erpnext.custom_education.file.file_move",
  getEmployee: "/api/resource/Employee",
  getLeaveDetails:
    "/api/method/hrms.hr.doctype.leave_application.leave_application.get_leave_details",

  getAllStudentLeaveApplication:
    "/api/method/techademy_erpnext.generic.get_list",
  getCourseSchedule: "/api/resource/Course Schedule",
  studentLeaveApplicationByID: "/api/method/techademy_erpnext.generic.get_data",
  studentLeaveApplication:
    "/api/method/techademy_erpnext.generic.create_update_data",
  getAllGradingScale: "/api/method/techademy_erpnext.generic.get_list",
  gradingScaleById: "/api/method/techademy_erpnext.generic.get_data",
  gradingScale: "/api/method/techademy_erpnext.generic.create_update_data",

  addAttendance: "/api/method/techademy_erpnext.generic.create_update_data",
  getAttendanceByAttendanceId: "/api/method/techademy_erpnext.generic.get_data",
  getAllStudentsInGroup:
    "/api/method/education.education.doctype.student_attendance_tool.student_attendance_tool.get_student_attendance_records",
  getAcademicValues: "/api/method/frappe.client.validate_link",
  addBulkAttendance: "/api/method/education.education.api.mark_attendance",
  getLeavePeriod: "/api/resource/Leave Period",
  getEmployeeGrade: "/api/resource/Employee Grade",
  getLeavePolicy: "/api/resource/Leave Policy",
  createLeaveControlPanel: "/api/method/run_doc_method",
  getSalutationList: "/api/resource/Salutation",
  getJobApplicantList: "/api/resource/Job Applicant",
  getHolidayList: "/api/resource/Holiday List",
  getCourse: "/api/resource/Course",
  getAllCostCenter: "/api/resource/Cost Center",
  getAllEmployeeList: "/api/method/techademy_erpnext.generic.get_list",
  employeeByID: "/api/method/techademy_erpnext.generic.get_data",
  hrEmployee: "/api/method/techademy_erpnext.generic.create_update_data",
  getFeedbackTemplate: "/api/resource/Feedback Template",
  getLeaveControlPanel:"/api/method/techademy_erpnext.generic.get_single_doctype",
  getAllQuestionsList: "/api/method/techademy_erpnext.generic.get_list",
  getAllSkillList: "/api/resource/Skill",
  getAllChaptersList: "/api/resource/Chapters",
  getCountryList: "/api/resource/Country?limit=['*']",
  getCurrencyList: "/api/resource/Currency?limit=['*']",
  getAllRubricsList: "/api/resource/Rubrics",
  QuestionBankByID: "/api/method/techademy_erpnext.exam.get_question_bank",
  createFeedbackTemplate: '/api/method/techademy_erpnext.techademy_education.doctype.feedback_template.feedback_template.create_feedback_template',
  getFeedbackResponse: '/api/resource/Feedback Response',
  genericCreateOrUpdate: '/api/method/techademy_erpnext.generic.create_update_data',
  genericGetDetailsById: '/api/method/techademy_erpnext.generic.get_data',
  genericGetListing: '/api/method/techademy_erpnext.generic.get_list',
  questionBank:"/api/method/techademy_erpnext.exam.create_update_question_bank",
  feedbackAssignment:"/api/method/techademy_erpnext.techademy_education.doctype.feedback_template.feedback_template.assign_template_to_user",
  getFeedbackDashboard: '/api/method/techademy_erpnext.techademy_education.doctype.feedback_response.feedback_response.get_dashboard_details',
  getStudentFeedbackTemplate:'/api/method/techademy_erpnext.techademy_education.doctype.feedback_response.feedback_response.get_feedback_view',
  feedbackResponse: '/api/method/techademy_erpnext.techademy_education.doctype.feedback_response.feedback_response.create_feedback_response',
};

export default APIs;

import APIs from "../api";
import axiosInstance from "../translator";

export const createRecruit = async ({ payload }) => {
  try {
    const res = await axiosInstance.post(APIs.createRecruit, payload);
    return res;
  } catch (error) {
    console.log("Error adding candidate ", error);
  }
};

export const JobApply = async (payload) => {
  try {
    const res = await axiosInstance.post(APIs.jobApply, payload);
    return res;
  } catch (error) {
    console.log("Error adding candidate ", error);
  }
};

export const getJobs = async () => {
  try {
    const res = await axiosInstance.get(`${APIs.getJobs}`);
    return res;
  } catch (error) {
    console.log("Error getting Jobs", error);
    return error.response;
  }
};

export const JobOpening = async (payload) => {
  try {
    const res = await axiosInstance.get(APIs.jobOpening, payload);
    return res;
  } catch (error) {
    console.log("Error adding candidate ", error);
  }
};

export const AllJobOpening = async (payload, filters) => {
  const fields = JSON.stringify(["*"]);
  console.log(filters, "pppppppp");
  try {
    const res = await axiosInstance.get(
      `${APIs.jobOpening}?doctype=${payload.doctype}&filters=${filters}&fields=${fields}&limit="*"&order_by=creation%20desc`
    );
    return res;
  } catch (error) {
    console.log("Error adding candidate ", error);
  }
};

export const JobOpeningDetail = async (payload) => {
  try {
    const res = await axiosInstance.post(APIs.getGenericData, payload);
    return res;
  } catch (error) {
    console.log("Error getting Job Details", error);
  }
};

export const getGenericListApi = async (params) => {
  try {
    const res = await axiosInstance.get(APIs.getGenericList, {
      params: params,
    });
    return res;
  } catch (error) {
    console.log("Error get generic list ", error);
    return error;
  }
};

export const getGenericDataApi = async (params) => {
  try {
    const res = await axiosInstance.get(APIs.getGenericData, {
      params: params,
    });
    return res;
  } catch (error) {
    console.log("Error get generic data ", error);
    return error;
  }
};

export const createGenericData = async (payload) => {
  try {
    const res = await axiosInstance.post(APIs.createGenericData, payload);
    return res;
  } catch (error) {
    console.log("Error adding generic data", error);
    return error;
  }
};

export const updateGenericData = async (payload) => {
  try {
    const res = await axiosInstance.put(APIs.updateGenericData, payload);
    return res;
  } catch (error) {
    console.log("Error update generic data ", error);
    return error;
  }
};

export const deleteGenericDataApi = async (payload) => {
  console.log(payload);
  try {
    const res = await axiosInstance.post(APIs.deleteGenericData, payload);
    return res;
  } catch (error) {
    console.log("Error delete generic data ", error);
    return error;
  }
};

export const getDepartment = async () => {
  try {
    const res = await axiosInstance.get(APIs.getDepartment);
    return res;
  } catch (error) {
    console.log("Error getting Department", error);
  }
};

export const getInstitution = async () => {
  try {
    const res = await axiosInstance.get(APIs.getInstitution);
    return res;
  } catch (error) {
    console.log("Error getting Institution", error);
  }
};

export const getEmploymentType = async () => {
  try {
    const res = await axiosInstance.get(APIs.getEmploymentType);
    return res;
  } catch (error) {
    console.log("Error getting Employment Type", error);
  }
};

export const getLocation = async () => {
  try {
    const res = await axiosInstance.get(APIs.getLocation);
    return res;
  } catch (error) {
    console.log("Error getting Location", error);
  }
};

export const getDesignation = async () => {
  try {
    const res = await axiosInstance.get(APIs.getDesignation);
    return res;
  } catch (error) {
    console.log("Error getting Designation", error);
  }
};

export const getCurrency = async () => {
  try {
    const res = await axiosInstance.get(`${APIs.getCurrencyList}?limit=["*"]`);
    return res;
  } catch (error) {
    console.log("Error getting Currency", error);
  }
};

export const createJobOpening = async (payload) => {
  console.log(payload, "payload");
  try {
    const res = await axiosInstance.post(APIs.createJobOpening, payload);
    return res;
  } catch (error) {
    console.log("Error create Job opening ", error);
  }
};

export const updateJobOpening = async (payload) => {
  console.log(payload, "payload");
  try {
    const res = await axiosInstance.put(APIs.createJobOpening, payload);
    return res;
  } catch (error) {
    console.log("Error updating Job Opening ", error);
  }
};

export const getJobOpeningDetails = async (payload) => {
  try {
    const res = await axiosInstance.post(APIs.getJobDetails, payload);
    return res;
  } catch (error) {
    console.log("Error geting Job details ", error);
  }
};

export const getGenericList = async (doctype, filter) => {
  try {
    const response = await axiosInstance.get(
      `${APIs.getGenericList}?doctype=${doctype}`
    );
    console.log("getGenericList Response:", response);
    if (response.status === 200) {
      return response?.data?.message;
    }
  } catch (error) {
    console.error("Error getting Generic list:", error);
    throw error;
  }
};

export const getGenericListByFilters = async (filters) => {
  try {
    const fields = JSON.stringify(["*"]);

    const res = await axiosInstance.get(
      `${APIs.getGenericList}?filters=${filters}&fields=${fields}&limit="*"&order_by=creation%20desc`
    );
    return res;
  } catch (error) {
    console.log("Error getting getGeneric listing", error);
  }
};

export const getLeaveDetailList = async (employeeId, date) => {
  try {
    const response = await axiosInstance.get(APIs.getLeaveDetails, {
      params: {
        employee: employeeId,
        date: date,
      },
    });
    console.log("Get Leave Details Response:", response);
    if (response.status === 200) {
      return response.data.message;
    }
  } catch (error) {
    console.error("Error getting Leave Details list:", error);
    throw error;
  }
};

export const getGenericData = async (doctype, id) => {
  try {
    const response = await axiosInstance.get(APIs.getGenericData, {
      params: {
        doctype: doctype,
        id: id,
      },
    });

    console.log("Get GenericData Response:", response);

    if (response.status === 200) {
      return response?.data;
    }
  } catch (error) {
    console.error("Error getting entity detail:", error);
    throw error;
  }
};

export const createGenericEntity = async ({ payload }) => {
  try {
    const res = await axiosInstance.post(APIs.createGenericData, payload);
    return res;
  } catch (error) {
    console.log("Error Adding Generic Entity", error);
    return error.response;
  }
};

export const updateGenericEntity = async ({ payload }) => {
  try {
    // Assuming APIs.updateGenericData is the endpoint for updating data
    const res = await axiosInstance.post(APIs.createUpdateGenericData, payload);
    console.log(res, "Generic Entity updated");
    return res;
  } catch (error) {
    console.log("Error Updating Generic Entity", error);
    return error.response;
  }
};

export const JobOpeningCards = async (payload) => {
  try {
    const res = await axiosInstance.get(
      `${APIs.jobOpening}?doctype=${payload.doctype}&filters=[["status","=","Open"]]`
    );
    return res;
  } catch (error) {
    console.log("Error adding candidate ", error);
  }
};

export const createLeaveType = async ({ payload }) => {
  try {
    const res = await axiosInstance.post(APIs.createLeaveType, payload);
    return res;
  } catch (error) {
    console.log("Error Adding Leave Type", error);
    return error.response;
  }
};

export const updateLeaveType = async ({ payload }) => {
  try {
    const res = await axiosInstance.put(APIs.createLeaveType, payload);
    return res;
  } catch (error) {
    console.log("Error Updating Leave Type", error);
    return error.response;
  }
};

export const getLeaveType = async (payload) => {
  try {
    const res = await axiosInstance.get(APIs.getLeaveType, payload);
    return res;
  } catch (error) {
    console.log("Error getting Leave Type", error);
    return error.response;
  }
};
export const exportGenericData = async (payload) => {
  try {
    const res = await axiosInstance.post(APIs.bulkActionExport, payload);
    return res;
  } catch (error) {
    console.log("Error export generic data", error);
    return error;
  }
};

export const updateGenericdataInBulk = async (payload) => {
  try {
    const response = await axiosInstance.post(APIs.bulkActionEdit, payload);
    return response;
  } catch (error) {
    console.error("Error getting Bulk Edit Fields:", error);
    throw error.response;
  }
};

export const getEmployeeAllocation = async () => {
  try {
    const res = await axiosInstance.get(APIs.getEmployee);
    return res;
  } catch (error) {
    console.log("Error getting Employee", error);
    return error.response;
  }
};

export const getEmployee = async (filter) => {
  try {
    const queryString = filter.queryString;
    const res = await axiosInstance.get(
      `${APIs.getEmployee}?fields=["*"]&filters=${queryString}`
    );
    return res;
  } catch (error) {
    console.log("Error getting Employee", error);
    return error.response;
  }
};

export const getEmployeeName = async (name) => {
  const employee_id = JSON.stringify(name);
  try {
    const res = await axiosInstance.get(
      `${APIs.getEmployee}?fields=["employee_name"]&filters=[["name","=",${employee_id}]]`
    );
    return res;
  } catch (error) {
    console.log("Error getting Employee Name", error);
    return error.response;
  }
};

export const getEmployeeGrade = async () => {
  try {
    const res = await axiosInstance.get(APIs.getEmployeeGrade);
    return res;
  } catch (error) {
    console.log("Error getting Employee Grade", error);
    return error.response;
  }
};

export const getLeavePeriod = async (payload) => {
  try {
    const res = await axiosInstance.get(APIs.getLeavePeriod, payload);
    return res;
  } catch (error) {
    console.log("Error getting Leave Period", error);
    return error.response;
  }
};

export const getLeavePolicy = async (payload) => {
  try {
    const res = await axiosInstance.get(APIs.getLeavePolicy, payload);
    return res;
  } catch (error) {
    console.log("Error getting Leave Policy", error);
    return error.response;
  }
};

export const createLeaveControlPanel = async ({ payload }) => {
  try {
    const res = await axiosInstance.post(APIs.createLeaveControlPanel, payload);
    return res;
  } catch (error) {
    console.log("Error Adding Generic Entity", error);
    return error.response;
  }
};

export const getAllStudentLeaveApplicationListing = async () => {
  try {
    return axiosInstance.get(
      `${APIs.getAllStudentLeaveApplication}?doctype=Student Leave Application`
    );
  } catch (error) {
    console.log("Error while getting employee Listing", error);
    return error.response;
  }
};

export const getStudentGroupListing = async () => {
  try {
    return axiosInstance.get(`${APIs.studentGroup}?fields=["*"]`);
  } catch (error) {
    console.log("Error while getting student group", error);
    return error.response;
  }
};

export const getCourseScheduleListing = async () => {
  try {
    return axiosInstance.get(`${APIs.getCourseSchedule}?fields=["*"]`);
  } catch (error) {
    console.log("Error while getting course schedule", error);
    return error.response;
  }
};

export const getStudentLeaveApplicationByID = async (params) => {
  try {
    return axiosInstance.get(APIs.studentLeaveApplicationByID, {
      params: params,
    });
  } catch (error) {
    console.log("Error while getting student leave application by name", error);
    return error.response;
  }
};

export const createStudentLeaveApplication = async ({ payload }) => {
  try {
    return axiosInstance.post(APIs.studentLeaveApplication, payload);
  } catch (error) {
    console.log("Error while creating student leave application ", error);
    return error.response;
  }
};

export const getAllGradingScaleListing = async () => {
  try {
    return axiosInstance.get(
      `${APIs.getAllGradingScale}?doctype=Grading Scale`
    );
  } catch (error) {
    console.log("Error while getting grading scale", error);
    return error.response;
  }
};

export const getGradingScaleByID = async (params) => {
  try {
    return axiosInstance.get(APIs.gradingScaleById, {
      params: params,
    });
  } catch (error) {
    console.log("Error while getting grading scale by id", error);
    return error.response;
  }
};

export const createGradingScale = async ({ payload }) => {
  try {
    return axiosInstance.post(APIs.gradingScale, payload);
  } catch (error) {
    console.log("Error while creating grading scale ", error);
    return error.response;
  }
};

export const updateFieldsInBulk = async ({ payload }) => {
  try {
    const response = await axiosInstance.post(APIs.bulkActionEdit, payload);
    return response;
  } catch (error) {
    console.error("Error getting Bulk Edit Fields:", error);
    throw error.response;
  }
};

export const getSalutationListing = async () => {
  try {
    const res = await axiosInstance.get(APIs.getSalutationList);
    return res;
  } catch (error) {
    console.log("Error getting salutation listing", error);
    return error.response;
  }
};

export const getJobApplicantListing = async () => {
  try {
    const res = await axiosInstance.get(APIs.getJobApplicantList);
    return res;
  } catch (error) {
    console.log("Error getting job applicant listing", error);
    return error.response;
  }
};

export const getHolidayListing = async () => {
  try {
    const res = await axiosInstance.get(APIs.getHolidayList);
    return res;
  } catch (error) {
    console.log("Error getting job holiday listing", error);
    return error.response;
  }
};

export const getAllCostCenterListing = async () => {
  try {
    return axiosInstance.get(`${APIs.getAllCostCenter}`);
  } catch (error) {
    console.log("Error while getting all cost center Listing", error);
    return error.response;
  }
};

export const getAllHREmployeeListing = async () => {
  try {
    return axiosInstance.get(`${APIs.getAllEmployeeList}?doctype=Employee`);
  } catch (error) {
    console.log("Error while getting employee Listing", error);
    return error.response;
  }
};

export const getEmployeeByID = async (params) => {
  try {
    return axiosInstance.get(APIs.employeeByID, {
      params: params,
    });
  } catch (error) {
    console.log("Error while getting employee by ID", error);
    return error.response;
  }
};

export const createHrEmployee = async ({ payload }) => {
  try {
    return axiosInstance.post(APIs.hrEmployee, payload);
  } catch (error) {
    console.log("Error while creating employee ", error);
    return error.response;
  }
};

export const getAllUserListing = async () => {
  try {
    return axiosInstance.get(`${APIs.getUser}`);
  } catch (error) {
    console.log("Error while getting all user Listing", error);
    return error.response;
  }
};

export const updateEmployeeByID = async ({ params, payload }) => {
  try {
    return axiosInstance.put(APIs.hrEmployee, payload, {
      params: params,
    });
  } catch (error) {
    console.log("Error while updating employee by ID", error);
    return error.response;
  }
};

export const getLeaveControlPanel = async (payload) => {
  try {
    const res = await axiosInstance.get(
      `${APIs.getLeaveControlPanel}?doctype=${payload.doctype}`
    );
    return res;
  } catch (error) {
    console.log("Error getting Leave Control Panel", error);
  }
};

export const getNoteApi = async (params) => {
  try {
    const res = await axiosInstance.get(APIs.createNote, {
      params: params,
    });
    return res;
  } catch (error) {
    console.log("Error get Note", error);
    return error;
  }
};

export const createNoteApi = async (payload) => {
  try {
    const res = await axiosInstance.post(APIs.createNote, payload);
    return res;
  } catch (error) {
    console.log("Error create Note", error);
    return error;
  }
};

export const getEmailListApi = async (params) => {
  try {
    const res = await axiosInstance.get(APIs.getEmailList, { params: params });
    return res;
  } catch (error) {
    console.log("Error getting email list ", error);
    return error.response;
  }
};

export const getCountries = async () => {
  try {
    const res = await axiosInstance.get(APIs.getCountryList);
    console.log("Country list", res);
    return res;
  } catch (error) {
    console.log("Error getting countries listing", error);
  }
};
export const getSubdivision = async ({ country }) => {
  try {
    const filters = JSON.stringify([["country", "=", country]]);
    const res = await axiosInstance.get(
      `${APIs.getSubdivisionList}?filters=${filters}`
    );
    return res;
  } catch (error) {
    console.log("Error getting states listing", error);
  }
};

export const formData = [
    {
      name: "General",
      fields: [
        {
          label: Image,
          name: "image",
          type: "image",
          placeholder: "Image",
        },
      ],
    },
    {
      type: "tab-break",
    },
    {
      name: "Personal",
      fields: [
        {
          label: "Personal Details",
          name: "personalDetails",
          type: "section",
          fields: [
            {
              label: "First Name",
              name: "firstName",
              type: "text",
              placeholder: "",
            },
            {
              label: "Middle Name",
              name: "middleName",
              type: "text",
              placeholder: "",
            },
            {
              label: "Last Name",
              name: "lastName",
              type: "text",
              placeholder: "",
            },
            {
              type: "col-break",
            },
            {
              label: "Gender",
              type: "text",
              name: "gender",
              placeholder: "Select Gender",
            },
            {
                label: "Preferred Pronouns",
                name: "preferredpronouns",
                type: "text",
                placeholder: "",
            },
            {
                label: "DOB",
                name: "dob",
                type: "text",
                placeholder: "",
            },
            {
                type: "col-break",
            },
            {
                label: "Nationality",
                type: "text",
                name: "nationality",
                placeholder: "",
            },
            {
                label: "Category",
                name: "category",
                type: "text",
                placeholder: "",
            },
            {
                label: "Blood Group",
                name: "bloodgroup",
                type: "text",
                placeholder: "",
            },
            {
                type: "col-break",
            },
            {
                label: "Aadhaar Card Number",
                name: "aadharcardnumber",
                type: "text",
                placeholder: "",
            },
            {
                label: "ABC ID",
                name: "abcid",
                type: "text",
                placeholder: "",
            },
          ],
        },
        {
          label: "Contact Information",
          name: "contactInfo",
          type: "section",
          fields: [
            {
              label: "Email Address",
              name: "email",
              type: "email",
              placeholder: "",
            },
            {
                label: "Alternate Email Address",
                name: "alternateemail",
                type: "email",
                placeholder: "",
            },
            {
                label: "Phone Number",
                name: "phoneNumber",
                type: "text",
                placeholder: "",
            },
            {
                type: "col-break",
            },
            {
                label: "Alternate Phone Number",
                name: "alternatePhoneNumber",
                type: "text",
                placeholder: "",
            },
            {
                label: "Telephone Number",
                name: "telephoneNumber",
                type: "text",
                placeholder: "",
            },
            {
                type: "col-break",
            },
            {
                label: "Emergency Contact Name",
                name: "emergencyontactname",
                type: "text",
                placeholder: "",
            },
            {
                label: "Relation With Emergency Contact",
                name: "relationEmergency",
                type: "text",
                placeholder: "",
            },
            {
                label: "Emercency Contact Number",
                name: "emergencyContactNumber",
                type: "text",
                placeholder: "",
            },
            
          ],
        },
        {
          label: "Corresponding Address",
          name: "correspondingAddress",
          type: "section",
          fields: [
            {
              label: "Country",
              name: "Country",
              type: "text",
              placeholder: "",
            },
            {
                label: "State",
                name: "state",
                type: "text",
                placeholder: "",
            },
            {
                label: "District",
                name: "district",
                type: "text",
                placeholder: "",
            },
            {
                type: "col-break",
            },
            {
                label: "City",
                name: "city",
                type: "text",
                placeholder: "",
            },
            {
                type: "col-break",
            },
            {
                label: "Address Line 1",
                name: "addressline1",
                type: "text",
                placeholder: "",
            },
            {
                label: "Address Line 2",
                name: "addressline2",
                type: "text",
                placeholder: "",
            },
            {
                label: "Pincode",
                name: "pincode",
                type: "text",
                placeholder: "",
            },
            
          ],
        },
        {
            label: "Permanent Address",
            name: "permanentAddress",
            type: "section",
            fields: [
              {
                label: "Country",
                name: "Country",
                type: "text",
                placeholder: "",
              },
              {
                  label: "State",
                  name: "state",
                  type: "text",
                  placeholder: "",
              },
              {
                  label: "District",
                  name: "district",
                  type: "text",
                  placeholder: "",
              },
              {
                  type: "col-break",
              },
              {
                  label: "City",
                  name: "city",
                  type: "text",
                  placeholder: "",
              },
              {
                  type: "col-break",
              },
              {
                  label: "Address Line 1",
                  name: "addressline1",
                  type: "text",
                  placeholder: "",
              },
              {
                  label: "Address Line 2",
                  name: "addressline2",
                  type: "text",
                  placeholder: "",
              },
              {
                  label: "Pincode",
                  name: "pincode",
                  type: "text",
                  placeholder: "",
              },
              
            ],
          },
          
          {
            label: "Sibling",
            type: "section",
            name: "Siblings",
            fields: [
            {
                label: "Number of Siblings studying in the same college",
                name: "siblings",
                type: "text",
                placeholder: "",
            },
            {
                type:"col-break",
            },
            {
            headers: ["S.no", "Siblings Name", "Programme Name", "Admisson/Roll Number"],
            type: "table",
            minRows: 3, 
            }
            ]
          },
      ],
    },
    {
        name: "Academics",
        fields: [
            {
                label: "Education Xth Details",
                name: "XthDetails",
                type: "section",
                fields: [
                  {
                    label: "Class X Board",
                    name: "classxthboard",
                    type: "text",
                    placeholder: "",
                  },
                  {
                    label: "State/Union Territory",
                    name: "state/union",
                    type: "text",
                    placeholder: "",
                  },
                  {
                    label: "City Of School",
                    name: "city of school",
                    type: "text",
                    placeholder: "",
                  },
                  {
                    type: "col-break",
                  },
                  {
                    label: "School Attended",
                    type: "text",
                    name: "schoolattended",
                    placeholder: "Select Gender",
                  },
                  {
                      label: "Year of Completion",
                      name: "completion year",
                      type: "text",
                      placeholder: "",
                  },
                  {
                      label: "Class Xth Marks/grade",
                      name: "Marks/grade",
                      type: "text",
                      placeholder: "",
                  },
                ],
              },
              {
                label: "Education XIth Details",
                name: "contactInfo",
                type: "section",
                fields: [
                    {
                        label: "Class XI Board",
                        name: "classxthboard",
                        type: "text",
                        placeholder: "",
                      },
                      {
                        label: "State/Union Territory",
                        name: "state/union",
                        type: "text",
                        placeholder: "",
                      },
                      {
                        label: "City Of School",
                        name: "city of school",
                        type: "text",
                        placeholder: "",
                      },
                      {
                        type: "col-break",
                      },
                      {
                        label: "School Attended",
                        type: "text",
                        name: "schoolattended",
                        placeholder: "Select Gender",
                      },
                      {
                          label: "Year of Completion",
                          name: "completion year",
                          type: "text",
                          placeholder: "",
                      },
                      {
                          label: "Class XIth Marks/grade",
                          name: "Marks/grade",
                          type: "text",
                          placeholder: "",
                      },
                  
                ],
              },
              {
                label: "Education XIIth Details",
                name: "correspondingAddress",
                type: "section",
                fields: [
                    {
                        label: "Class XII Board",
                        name: "classxiithboard",
                        type: "text",
                        placeholder: "",
                      },
                      {
                        label: "State/Union Territory",
                        name: "state/union",
                        type: "text",
                        placeholder: "",
                      },
                      {
                        label: "City Of School",
                        name: "city of school",
                        type: "text",
                        placeholder: "",
                      },
                      {
                        type: "col-break",
                      },
                      {
                        label: "School Attended",
                        type: "text",
                        name: "schoolattended",
                        placeholder: "Select Gender",
                      },
                      {
                          label: "Year of Completion",
                          name: "completionyear",
                          type: "text",
                          placeholder: "",
                      },
                      {
                          label: "Class XIIth Marks/grade",
                          name: "Marks/grade",
                          type: "text",
                          placeholder: "",
                      },
                ],
              },
              {
                label: "XIIth Marks Table",
                type: "section",
                name: "xiithMarksTable",
                fields: [
                {
                headers: ["Subject", "Marks Obtained", "Maximum Marks"],
                type: "table",
                minRows: 5, // Minimum number of rows
                },
                {
                    type: "col-break",
                },
                {
                    label: "Have you taken a gap year after Class XII? *",
                    type: "radio",
                    options: ["yes", "no"]
                },
                {
                    label: "Are you currently enrolled in a college/University? *",
                    type: "radio",
                    options: ["yes", "no"]
                },
                
                ]
              },

        ],
      },
  ];
  
  export default formData;
  
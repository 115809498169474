import { createSlice } from "@reduxjs/toolkit";
const filterSlice = createSlice({
  name: "filters",
  initialState: {
   doctype: "",
   filters: [],
   selectedFilter: [{ data: "", condition: "", searchTerm: "" }],
   selectedSavedFilters: []
  },
  reducers: {
    addFilter: (state, action) => {
      const { doctype, filters } = action.payload;
      state.doctype = doctype;
      state.filters = [];
      state.filters.push(filters);
    },
    saveFilter: (state, action) => {
      const { selectedFilter } = action.payload;
      state.selectedFilter = selectedFilter;
    },
    selectedSaveFilter: (state, action) => {
      const { selectedSavedFilters } = action.payload;
      state.selectedSavedFilters = selectedSavedFilters;
    },
    clearAllFilters: (state) => {
      state.filters = [];
      state.selectedFilter = [{ data: "", condition: "", searchTerm: "" }];
      state.selectedSavedFilters = [];
    },
  },
});

export const { addFilter, saveFilter, clearAllFilters, selectedSaveFilter } = filterSlice.actions;
export default filterSlice.reducer;
import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTheme } from 'iiht-b2a-ui-components/build/components/Styles';
import Button from 'iiht-b2a-ui-components/build/components/Button';
import Typography from 'iiht-b2a-ui-components/build/components/Typography';
import TechademyLogo from '../../assets/images/TechademyLogo.svg';
import { useNavigate } from 'react-router-dom';

// import { signInFormError } from '../../../utils/errorHelper';
import { Grid, Paper } from 'iiht-b2a-ui-components/build/components';
// import { forgotPasswordRequest } from 'store';
// import { placeParams } from 'utils';
// import { ROUTES } from '../../../constants/routesConstants';
import './index.scss';
// import { capitalize } from 'lodash';
// import CheckCircleSvg from 'icons/CheckCircle';
// import { CheckCircle } from 'icons';

const ForgotPasswordSuccess = () => {
//   const { t } = useTranslation();
  // let history = useHistory();
  let dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  const handleRecovery = () =>{
    navigate("/login")
  }
  

  // const [error, setError] = useState(signInFormError);
  // const [username, setUsername] = useState('');
  // const handleForgotPassword = () => {
  //   const tenantName = localStorage.getItem('tenantName');
  //   dispatch(
  //     forgotPasswordRequest({
  //       userName: username,
  //       tenantName: tenantName,
  //       resetUrl: 'abc',
  //     })
  //   );

  //   history.push(placeParams(`/${tenantName}${ROUTES.LOGIN}`, tenantName));
  // };

  return (
    <>
      <div className="login-form-logo-wrapper">
        <div className="sign-in-section">
          <img
            className="defaultTenantLogo"
            alt="Default Logo"
            src={TechademyLogo}
          />
          <form autoComplete="off" className="sign-form">
            <Paper elevation={0} className="sign-in-input-wrapper p-2 pt-4">
              <div className="link_sent">
                {/* <CheckCircle
                  className="checkIcon"
                  fill={theme.palette.success.light}
                  height={25}
                  width={25}
                /> */}
                <Typography variant="h4" sx={{ mb: 3 }}>
                    Recovery Link Sent
                </Typography>
              </div>
              <div className="link_detaiils">
                <div>We've sent a verification link to your email address. Please 
                    check your inbox and follow the instructions to complete the process.</div>
              </div>

              <br />
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className="mx10"
              >
                <Button
                  className="qa-sign-in-button"
                  variant="contained"
                  color="secondary"
                  tabIndex={5}
                  fullwidth
                  sx={{
                    width: '100%',
                    alignSelf: 'center',
                    color: theme.palette.common.white,
                  }}
                  onClick={handleRecovery}
                >
                  Sign In To Your Account
                </Button>
              </Grid>

              <br />
            </Paper>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordSuccess;

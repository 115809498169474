import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GridMenuIcon } from "@mui/x-data-grid";
import { Table } from "antd";
import { Button, Grid, Rating, Stack, Typography } from "iiht-b2a-ui-components/build/components";
import { CustomBreadcrumb, FormSelectField, FormTextField, MainLayout } from "components/common";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { validationRules } from "constants/validationRules";
import { createGenericData, getGenericDataApi, updateGenericData } from "modules/hr";
import { addressMapper } from "utils";
import { setLoading } from "slices/commonSlice";

const InterviewFeedback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const applicantName = location.state?.applicantName ?? "Interview Details";
  const {
    id: jobApplicantId,
    jobOpeningId,
    designationId,
    interviewId,
    interviewRoundId,
    feedbackId,
  } = useParams();
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [interviewFeedbackData, setInterviewFeedbackData] = useState({});
  const [interviewersList, setInterviewersList] = useState([]);
  const [skillAssessment, setSkillAssessment] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const isEditMode = !!(location.pathname.includes("edit-view") && feedbackId);
  const [isSaveBtnDisable, setIsSaveBtnDisable] = useState(isEditMode);
  const { loading } = useSelector((state) => state.common);

  const breadcrumbs = [
    {
      label: "IIHT",
      link: "/dashboard",
    },
    {
      label: "HR",
      link: "/modules/hr",
    },
    {
      label: "Recruitment",
      link: "/modules/hr",
    },
    {
      label: "Job Applications",
      link: "/modules/hr/job-application",
    },
    {
      label: applicantName === "Interview Details" ? jobApplicantId : applicantName,
      link: `/modules/hr/job-application/view/${jobApplicantId}/${jobOpeningId}/${designationId}?tab=3`,
    },
    {
      label: "View Interview",
      link: `/modules/hr/job-application/view/interview/edit-view/${jobApplicantId}/${jobOpeningId}/${designationId}/${interviewId}`,
      state: {
        applicantName,
      },
    },
    {
      label: "Feedback",
      link: "",
    },
  ];

  const columns = [
    {
      dataIndex: "number",
      title: "SNo.",
      width: "10%",
      render: (_val, _row, rowIndex) => rowIndex + 1,
    },
    {
      dataIndex: "skill",
      title: "Skill",
      width: "30%",
      render: (val) => val,
    },
    {
      dataIndex: "rating",
      title: "Rating",
      width: "60%",
      render: (val, _row, rowIndex) => (
        <Rating
          name="rating"
          value={val}
          onChange={(event, value) => {
            const data = [...skillAssessment];
            data[rowIndex].rating = value;
            setSkillAssessment(data);
            setIsSaveBtnDisable(false);
          }}
        />
      ),
    },
  ];

  const statusOptions = [
    {
      id: 1,
      key: 1,
      label: "Pending",
      value: "Pending",
    },
    {
      id: 2,
      key: 2,
      label: "Under Review",
      value: "Under Review",
    },
    {
      id: 3,
      key: 3,
      label: "Cleared",
      value: "Cleared",
    },
    {
      id: 4,
      key: 4,
      label: "Rejected",
      value: "Rejected",
    },
  ];

  const defaultValues = {
    interview_round: interviewRoundId,
    interviewer: "",
    result: "",
    job_applicant: applicantName,
    feedback: "",
    interview: interviewId,
  };

  const methods = useForm({
    defaultValues: defaultValues,
  });

  const { handleSubmit, reset, watch, getValues, setValue } = methods;

  const onSubmit = async (data) => {
    dispatch(setLoading({ loading: true }));
    const skill_assessment = skillAssessment.map((skillData) => {
      return {
        skill: skillData.skill,
        rating: parseFloat((skillData.rating * 0.2).toFixed(1)),
      };
    });
    const payload = {
      ...data,
      job_applicant: jobApplicantId,
      average_rating: parseFloat((averageRating * 0.2).toFixed(1)) ?? 0,
      skill_assessment,
      doctype: "Interview Feedback",
    };
    const res = isEditMode ? await updateGenericData(payload) : await createGenericData(payload);
    if (res?.status === 200) {
      isEditMode && fetchInterviewFeedback();
      toast.success(`Interview Feedback ${isEditMode ? "Updated" : "Created"}!`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsSaveBtnDisable(true);
      setIsFormSubmited(true);
    } else {
      toast.error(res?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    dispatch(setLoading({ loading: false }));
  };

  const fetchInterviewFeedback = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Interview Feedback",
      id: feedbackId,
    };
    const res = await getGenericDataApi(params);
    if (res?.status === 200) {
      const data = res?.data?.message;
      setInterviewFeedbackData(data);
      reset({
        ...data,
        job_applicant: applicantName,
      });
      const skills = data?.skill_assessment?.map((skillData) => {
        return {
          ...skillData,
          id: skillData.skill,
          value: skillData.skill,
          lable: skillData.skill,
          rating: skillData.rating / 0.2,
        };
      });
      setSkillAssessment(skills);
      setAverageRating(data?.average_rating / 0.2 ?? 0);
    }
    dispatch(setLoading({ loading: false }));
  };

  const fetchInterviewersList = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Interview Round",
      id: interviewRoundId,
    };
    const res = await getGenericDataApi(params);
    if (res?.status === 200) {
      const data = res.data?.message;
      setValue("interview_round", interviewRoundId);
      if (data.interviewers?.length) {
        const list = addressMapper(data.interviewers, "user", "user", true);
        setInterviewersList(list);
      } else {
        setInterviewersList([]);
      }
      if (data?.expected_skill_set?.length) {
        const skills = data.expected_skill_set.map((skillData) => {
          return {
            ...skillData,
            id: skillData.skill,
            value: skillData.skill,
            lable: skillData.skill,
            rating: 0,
          };
        });
        setSkillAssessment(skills);
      }
    }
    dispatch(setLoading({ loading: false }));
  };

  useEffect(() => {
    if (isEditMode) {
      fetchInterviewFeedback();
    }
  }, [isEditMode]);

  useEffect(() => {
    fetchInterviewersList();
  }, []);

  return (
    <MainLayout>
      {loading && <LoadingBackdrop open={loading} />}
      <div className="mb20">
        <CustomBreadcrumb breadcrumbs={breadcrumbs} />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={1} alignItems="center">
                <GridMenuIcon className="gridIcon" />
                <Typography color="#0D1021" fontSize={22}>
                  {isEditMode ? "View Interview Feedback" : "New Interview Feedback"}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={3}>
                <Button
                  variant="contained"
                  color="grey"
                  className="qa-save-btn"
                  size="large"
                  onClick={() =>
                    navigate(
                      `/modules/hr/job-application/view/interview/edit-view/${jobApplicantId}/${jobOpeningId}/${designationId}/${interviewId}`,
                      {
                        state: {
                          applicantName,
                        },
                      }
                    )
                  }
                >
                  Back
                </Button>
                {!isEditMode && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSaveBtnDisable}
                    className="qa-save-btn"
                  >
                    {isEditMode ? "Update" : "Save"}
                  </Button>
                )}
              </Stack>
            </Stack>

            <Typography variant="h4" color="#012148" fontWeight={500} sx={{ p: 0, mt: 3, mb: 0.5 }}>
              Details
            </Typography>

            <Grid
              container
              rowSpacing={2}
              columnSpacing={5}
              display="flex"
              flexDirection="row"
              alignItems="start"
              sx={{ mt: 1 }}
            >
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Interview ID *
                </Typography>
                <FormTextField
                  name="interview"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                  className="mt1"
                />
              </Grid>

              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Interviewer *
                </Typography>
                <FormSelectField
                  name="interviewer"
                  variant="outlined"
                  size="small"
                  fullWidth
                  className="mt1"
                  options={interviewersList}
                  rules={{
                    required: validationRules.REQUIRED,
                  }}
                />
              </Grid>

              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Job Applicant *
                </Typography>
                <FormTextField
                  name="job_applicant"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                  className="mt1"
                />
              </Grid>

              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Interview Round *
                </Typography>
                <FormTextField
                  name="interview_round"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                  className="mt1"
                  rules={{
                    required: validationRules.REQUIRED,
                  }}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Result Status *
                </Typography>
                <FormSelectField
                  name="result"
                  variant="outlined"
                  size="small"
                  fullWidth
                  className="mt1"
                  options={statusOptions}
                  rules={{
                    required: validationRules.REQUIRED,
                  }}
                />
              </Grid>
            </Grid>

            <div className="my6">
              <Typography color="#012148" fontSize={14} fontWeight="500">
                Skill Assessment
              </Typography>
              <Table
                columns={columns}
                dataSource={skillAssessment}
                size="small"
                pagination={false}
              />
            </div>

            <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 5 }}>
              <Typography variant="bodyText" color="#012148" fontWeight={500}>
                Average Rating *
              </Typography>
              <Rating
                name="average-rating"
                value={averageRating}
                onChange={(event, value) => {
                  setAverageRating(value);
                  setIsSaveBtnDisable(false);
                }}
              />
            </Stack>
            <div className="mt2">
              <Typography variant="bodyText" color="#012148" fontWeight={500}>
                Feedback *
              </Typography>
              <FormTextField
                name="feedback"
                variant="outlined"
                size="small"
                className="mt1"
                placeholder="Enter Text here..."
                fullWidth
                multiline
                rows={4}
                rules={{
                  required: validationRules.REQUIRED,
                }}
              />
            </div>
          </form>
        </FormProvider>
      </div>
    </MainLayout>
  );
};

export default InterviewFeedback;

import APIs from "../api";
import axiosInstance from "../translator";

export const getFeedbackTemplate = async () => {
  try {
    const res = await axiosInstance.get(
      `${APIs.getFeedbackTemplate}?fields=["*"]`
    );
    return res;
  } catch (error) {
    console.log("Error getting Feedback Template", error);
    return error.response;
  }
};

export const getFeedbackTemplateId = async () => {
    try {
      const res = await axiosInstance.get(`${APIs.getFeedbackTemplate}`);
      return res;
    } catch (error) {
      console.log("Error getting Feedback Template", error);
      return error.response;
    }
};

export const createFeedbackTemplate = async (payload) => {
  try {
    const res = await axiosInstance.post(APIs.createFeedbackTemplate, payload);
    return res;
  } catch (error) {
    console.log("Error Creating Feedback Template", error);
    return error.response;
  }
};

export const getFeedbackResponse = async () => {
  try {
    const res = await axiosInstance.get(
      `${APIs.getFeedbackResponse}?fields=["*"]`
    );
    return res;
  } catch (error) {
    console.log("Error getting Feedback Response", error);
    return error.response;
  }
};

export const getFeedbackResponseDetails = async (id) => {
  try {
    const res = await axiosInstance.get(
      `${APIs.getFeedbackResponse}/${id}?fields=["*"]`
    );
    return res;
  } catch (error) {
    console.log("Error getting Feedback Response", error);
    return error.response;
  }
};

export const createFeedbackAssignment = async (payload) => {
  try {
    const res = await axiosInstance.post(APIs.feedbackAssignment, payload);
    return res;
  } catch (error) {
    console.log("Error Creating Feedback assignment", error);
    return error.response;
  }
};

export const getFeedbackAssignmentByID = async (id) => {
  try {
    const res = await axiosInstance.get(
      `${APIs.getFeedbackTemplate}/${id}?fields=["*"]`
    );
    return res;
  } catch (error) {
    console.log("Error getting Feedback assignment by ID", error);
    return error.response;
  }
};


export const getFeedbackDashboard = async ( id ) => {
    try {
      const res = await axiosInstance.get(`${APIs.getFeedbackDashboard}?feedback_template=${id}`);
      return res;
    } catch (error) {
      console.log("Error getting Feedback Dashboard details", error);
      return error.response;
    }
};
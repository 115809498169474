import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "28px",
    padding: "32px 18px",
  },
  breadCrumbsContainer: {
    display: "inline-flex",
    gap: "4px",
    alignItems: "center",
  },
  breadCrumbsContent: {
    color: "#6F6F6F",
    fontSize: "12px",
    fontWeight: "400",
    cursor: "pointer",
  },

  headerContainer: {
    display: "inline-flex",
    width: "100%",
    alignItems: "center",
    marginTop: "-24px",
  },
  title: {
    fontSize: "22px",
    color: "#0D1021",
    fontWeight: "500",
    paddingTop: "30px",
  },
  gridIcon: {
    color: "#647B8F",
  },
  buttonsGroup: {
    display: "inline-flex",
    gap: "15px",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    cursor: "pointer",
    borderRadius: " 4px",
    background: "#FFF",
    padding: "4px 14px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow:
      "0px 0px 2px 0px rgba(21, 40, 80, 0.12), 0px 2px 4px 0px rgba(21, 40, 80, 0.10)",
  },
  filterButton: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "7px",
  },
  buttonText: {
    color: "#405071",
    fontSize: "14px",
    fontWeight: "600",
  },
  activeButton: {
    cursor: "pointer",
    borderRadius: " 4px",
    background: "#A32930",
    padding: "4px 14px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow:
      "0px 0px 2px 0px rgba(21, 40, 80, 0.12), 0px 2px 4px 0px rgba(21, 40, 80, 0.10)",
  },
  activeButtonText: {
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "400",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "14px",
    marginBottom: "40px",
  },
  field: {
    display: "flex",
    flexDirection: "column",
    width: "30%", // Adjust width of each select field
  },
  fieldButtons: {
    display: "inline-flex",
    gap: "16px",
    padding: "16px 0px",
  },
  textarea: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    width: "100%",
  },
  fieldLabel: {
    color: "#012148",
    fontSize: "14px",
    fontWeight: "500",
  },
  fieldInput: {
    color: "#9DA9B0",
    fontSize: "12px",
    fontWeight: "400",
    padding: "8px 12px",
    borderRadius: "4px",
    background: "#fff",
    outline: "none",
    height: "40px",
    border: "1px solid #CDD6E2",
  },
  selectInput: {
    width: "100%",
    color: "#9DA9B0",
  },
  fieldSelectInput: {
    color: "#9DA9B0",
    fontSize: "12px",
    fontWeight: "400",
    padding: "8px 12px",
    borderRadius: "4px",
    background: "#fff",
    outline: "none",
    height: "40px",
    marginTop: "-2px",
    width: "100%",
  },

  formRow: {
    display: "flex",
    gap: "40px",
  },
  metaHeadingText: {
    fontSize: "22px",
    color: "#0D1021",
    fontWeight: "500",
    marginTop: "8px",
  },
  dateInput: {
    color: "#9DA9B0",
  },
  tabel: {
    marginBottom: "50px",
  },
}));

export default useStyles;

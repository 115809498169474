import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "./index.scss";
import axios from "axios";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import APIs from "../../api";
import { Button } from "iiht-b2a-ui-components/build/components";
import {
  getDataOfApplicationFormByApplicationId,
  getGuardianDetailsById,
} from "../../modules/student";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const FormParentDetails = ({ handleNext, handleBack }) => {
  const [errors, setErrors] = useState({
    pfname: "",
    plname: "",
    pdob: "",
    relation: "",
    pcontact: "",
    // pemail: "",
    pnationality: "",
    pcategory: "",
    // peducation: "",
    // empcategory: "",
    // empfield: "",
    // organization: "",
    // designation: "",
    // income: "",
    // occupationDetails: "",

    // pfname2: "",
    // plname2: "",
    // pdob2: "",
    // relation2: "",
    // pcontact2: "",
    // pemail2: "",
    // pnationality2: "",
    // pcategory2: "",
    // peducation2: "",
    // empcategory2: "",
    // empfield2: "",
    // organization2: "",
    // designation2: "",
    // income2: "",
    // occupationDetails2: "",

    // Siblings
    siblingCount: "",
    siblings: [],

    guardians: [],
  });
  const [values, setValues] = useState({
    // Parent/Guardian - 1 Details
    pfname: "",
    plname: "",
    pdob: "",
    relation: "",
    pcontact: "",
    pemail: "",
    pnationality: "",
    pcategory: "",
    peducation: "",
    empcategory: "",
    empfield: "",
    organization: "",
    designation: "",
    income: "",
    occupationDetails: "",

    pfname2: "",
    plname2: "",
    pdob2: "",
    relation2: "",
    pcontact2: "",
    pemail2: "",
    pnationality2: "",
    pcategory2: "",
    peducation2: "",
    empcategory2: "",
    empfield2: "",
    organization2: "",
    designation2: "",
    income2: "",
    occupationDetails2: "",

    // Siblings
    siblingCount: "",
    siblings: [],

    guardians: [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("sfullname")) {
      const siblingIndex = parseInt(name.match(/\d+/)[0], 10);
      const updatedSiblings = [...values.siblings];

      // Update the specific sibling object
      updatedSiblings[siblingIndex - 1] = {
        ...updatedSiblings[siblingIndex - 1],
      };

      // Update the state
      setValues({
        ...values,
        siblings: updatedSiblings,
        [name]: value,
      });
    } else {
      // For other fields, update the state directly
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  let saveGuardian = async (data) => {
    const formData = {
      custom_first_name: data.pfname,
      custom_last_name: data.plname,
      email_address: data.pemail,
      mobile_number: data.pcontact,
      date_of_birth: data.pdob,
      education: data.peducation,
      designation: data.designation,
      work_address: data.organization,
      custom_occupation_including_position_and_a_brief_upto_50_words:
        data.occupationDetails,
      custom_employment_category: data.empcategory,
      custom_field_of_employment: data.empfield,
      custom_gross_annual_income_in_inr: data.income,
      custom_nationality: data.nationality,
      guardian_name: `${data.pfname} ${data.plname}`, // Add guardian_name field
    };
    const response = await axios.post(APIs.createGuardianForm, formData);
    return response;
  };

  const validateForm = () => {
    const newErrors = {};
    // Validate Parent/Guardian 1
    if (!values.pfname?.trim()) {
      newErrors.pfname = "First name is required";
    }
    if (!values.pdob?.trim()) {
      newErrors.pdob = "Date of birth is required";
    }
    if (!values.relation?.trim()) {
      newErrors.relation = "Relation is required";
    }
    if (!values.pcontact?.trim()) {
      newErrors.pcontact = "Contact number is required";
    } else if (!/^([0-9]{1,5})?([7-9][0-9]{9})$/.test(values.pcontact)) {
      newErrors.pcontact = "Invalid contact number format";
    }

    // if (!values.pemail?.trim()) {
    //   newErrors.pemail = "Email address is required";
    // } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.pemail)) {
    //   newErrors.pemail = "Invalid email address format";
    // }
    if (!values.pnationality) {
      newErrors.pnationality = "Nationality is required";
    }
    if (!values.pcategory?.trim()) {
      newErrors.pcategory = "Category is required";
    }
    // if (!values.peducation?.trim()) {
    //   newErrors.peducation = "Education is required";
    // }
    // if (!values.empcategory?.trim()) {
    //   newErrors.empcategory = "Employment category is required";
    // }
    // if (!values.empfield?.trim()) {
    //   newErrors.empfield = "Employment Field is required";
    // }
    // if (!values.organization?.trim()) {
    //   newErrors.organization = "Organization is required";
    // }
    // if (!values.designation?.trim()) {
    //   newErrors.designation = "Designation is required";
    // }
    // if (!values.income) {
    //   newErrors.income = "Income is required";
    // }
    // if (!values.occupationDetails?.trim()) {
    //   newErrors.occupationDetails = "Occupation detail is required";
    // }

    // Validate Parent/Guardian 2
    // if (!values.pfname2?.trim()) {
    //   newErrors.pfname2 = "First name is required";
    // }
    // if (!values.pdob2?.trim()) {
    //   newErrors.pdob2 = "Date of birth is required";
    // }
    // if (!values.relation2?.trim()) {
    //   newErrors.relation2 = "Relation is required";
    // }
    // if (!values.pcontact2?.trim()) {
    //   newErrors.pcontact2 = "Contact number is required";
    // } else if (!/^([0-9]{1,5})?([7-9][0-9]{9})$/.test(values.pcontact2)) {
    //   newErrors.pcontact2 = "Invalid contact number format";
    // }
    // if (!values.pemail2?.trim()) {
    //   newErrors.pemail2 = "Email address is required";
    // } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.pemail2)) {
    //   newErrors.pemail2 = "Invalid email address format";
    // }
    // if (!values.pnationality2) {
    //   newErrors.pnationality2 = "Nationality is required";
    // }
    // if (!values.pcategory2?.trim()) {
    //   newErrors.pcategory2 = "Category is required";
    // }
    // if (!values.peducation2?.trim()) {
    //   newErrors.peducation2 = "Education is required";
    // }
    // if (!values.empcategory2?.trim()) {
    //   newErrors.empcategory2 = "Employment category is required";
    // }
    // if (!values.empfield2?.trim()) {
    //   newErrors.empfield2 = "Employment Field is required";
    // }
    // if (!values.organization2?.trim()) {
    //   newErrors.organization2 = "organization is required";
    // }
    // if (!values.designation2?.trim()) {
    //   newErrors.designation2 = "Designation is required";
    // }
    // if (!values.income2) {
    //   newErrors.income2 = "Income is required";
    // }
    // if (!values.occupationDetails2?.trim()) {
    //   newErrors.occupationDetails2 = "Occupation detail is required";
    // }

    if (values.siblingCount > 0) {
      for (let i = 1; i <= values.siblingCount; i++) {
        if (!values[`sfullname${i}`]?.trim()) {
          newErrors[`sfullname${i}`] = `Sibling ${i} full name is required`;
        }
        if (!values[`programme${i}`]?.trim()) {
          newErrors[`programme${i}`] = `Programme name ${i} is required`;
        }
        if (!values[`admno${i}`]?.trim()) {
          newErrors[`admno${i}`] = `Admission/Roll ${i} number is required`;
        }
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // const handleSave = async () => {
  //   if (validateForm()) {
  //     try {
  //       const guardian1 = await saveGuardian({
  //         pfname: values.pfname,
  //         plname: values.plname,
  //         pemail: values.pemail,
  //         pcontact: `+${values.pcontact}`,
  //         pdob: values.pdob,
  //         peducation: values.peducation,
  //         empcategory: values.empcategory,
  //         designation: values.designation,
  //         organization: values.organization,
  //         empfield: values.empfield,
  //         income: values.income,
  //         occupationDetails: values.occupationDetails,
  //         nationality: values.pnationality
  //       });
  //       const guardian2 = await saveGuardian({
  //         pfname: values.pfname2,
  //         plname: values.plname2,
  //         pemail: values.pemail2,
  //         pcontact: `+${values.pcontact2}`,
  //         pdob: values.pdob2,
  //         peducation: values.peducation2,
  //         empcategory: values.empcategory2,
  //         designation: values.designation2,
  //         organization: values.organization2,
  //         empfield: values.empfield2,
  //         income: values.income2,
  //         occupationDetails: values.occupationDetails2,
  //         nationality: values.pnationality2
  //       });
  //       let guardians = values.guardians;

  //       guardians.push({
  //         guardian: guardian1.data.data.name,
  //         guardian_name: values.pfname + " " + values.plname,
  //         relation: values.relation,
  //       });
  //       guardians.push({
  //         guardian: guardian2.data.data.name,
  //         guardian_name: values.pfname2 + " " + values.plname2,
  //         relation: values.relation2,
  //       });
  //       let siblings = values.siblings;
  //       for (let i = 1; i <= values.siblingCount; i++) {
  //         siblings.push({
  //           full_name: values[`sfullname${i}`],
  //           program: values[`programme${i}`],
  //           student: values[`admno${i}`],
  //           studying_in_same_institute: "YES",
  //         });
  //       }

  //       setValues({
  //         ...values,
  //         ...{ guardians },
  //         ...{ siblings },
  //       });

  //       handleNext({
  //         payload: {
  //           ...values,
  //           ...{ guardians },
  //           ...{ siblings },
  //         },
  //         addData: false,
  //       });
  //     } catch (error) {
  //       console.error("API Error:", error);
  //     }
  //     // localStorage.setItem('formData', JSON.stringify(values));
  //   } else {
  //     toast.error("Form has errors. Please fix them.");
  //     window.scrollTo(0, 0);
  //   }
  // };

  const handleSave = async () => {
    if (validateForm()) {
      try {
        const guardian1 = await saveGuardian({
          pfname: values.pfname,
          plname: values.plname,
          pemail: values.pemail,
          pcontact: `+${values.pcontact}`,
          pdob: values.pdob,
          peducation: values.peducation,
          empcategory: values.empcategory,
          designation: values.designation,
          organization: values.organization,
          empfield: values.empfield,
          income: values.income,
          occupationDetails: values.occupationDetails,
          nationality: values.pnationality,
        });

        let guardians = [
          {
            guardian: guardian1.data.data.name,
            guardian_name: values.pfname + " " + values.plname,
            relation: values.relation,
          },
        ];

        if (
          values.pfname2 &&
          values.plname2 &&
          values.pdob2 &&
          values.relation2 &&
          values.pcontact2 &&
          values.pemail2 &&
          values.pnationality2 &&
          values.pcategory2
        ) {
          const guardian2 = await saveGuardian({
            pfname: values.pfname2,
            plname: values.plname2,
            pemail: values.pemail2,
            pcontact: `+${values.pcontact2}`,
            pdob: values.pdob2,
            peducation: values.peducation2,
            empcategory: values.empcategory2,
            designation: values.designation2,
            organization: values.organization2,
            empfield: values.empfield2,
            income: values.income2,
            occupationDetails: values.occupationDetails2,
            nationality: values.pnationality2,
          });

          guardians.push({
            guardian: guardian2.data.data.name,
            guardian_name: values.pfname2 + " " + values.plname2,
            relation: values.relation2,
          });
        }

        let siblings = values.siblings;
        for (let i = 1; i <= values.siblingCount; i++) {
          siblings.push({
            full_name: values[`sfullname${i}`],
            program: values[`programme${i}`],
            student: values[`admno${i}`],
            studying_in_same_institute: "YES",
          });
        }

        setValues({
          ...values,
          ...{ guardians },
          ...{ siblings },
        });

        handleNext({
          payload: {
            ...values,
            ...{ guardians },
            ...{ siblings },
          },
          addData: false,
        });
      } catch (error) {
        console.error("API Error:", error);
      }
    } else {
      toast.error("Form has errors. Please fix them.");
      window.scrollTo(0, 0);
    }
  };

  const handleGetDataOfApplicationFormByApplicationId = async () => {
    if (window.localStorage.getItem("application-name")) {
      const applicationId = JSON.parse(
        window.localStorage.getItem("application-name")
      );
      const res = await getDataOfApplicationFormByApplicationId({
        applicationId,
      });
      if (res.status === 200) {
        const data = res.data.data;
        const guardians = [];

        for (let i = 0; i < data.guardians.length; i++) {
          const guardianId = data.guardians[i].guardian;
          const res = await getGuardianDetailsById({ guardianId });
          if (res.status == 200) {
            const val = res.data.data;
            guardians.push({
              firstName: val.custom_first_name,
              lastName: val.custom_last_name,
              dob: val.date_of_birth,
              relation: data.guardians[i].relation,
              contact: val.mobile_number ? val.mobile_number.slice(1) : "",
              email: val.email_address,
              category: val.custom_category,
              education: val.custom_highest_level_of_education,
              empcategory: val.custom_employment_category,
              empfield: val.custom_field_of_employment,
              organization: val.work_address,
              designation: val.designation,
              income: val.custom_gross_annual_income_in_inr,
              occupationDetails:
                val.custom_occupation_including_position_and_a_brief_upto_50_words,
              nationality: val.custom_nationality,
            });
          }
        }

        setValues({
          ...values,
          pfname: guardians[0]?.firstName,
          plname: guardians[0]?.lastName,
          pdob: guardians[0]?.dob,
          relation: guardians[0]?.relation,
          pcontact: guardians[0]?.contact,
          pemail: guardians[0]?.email,
          pcategory: guardians[0]?.category,
          peducation: guardians[0]?.education,
          empcategory: guardians[0]?.empcategory,
          empfield: guardians[0]?.empfield,
          organization: guardians[0]?.organization,
          designation: guardians[0]?.designation,
          income: guardians[0]?.income,
          occupationDetails: guardians[0]?.occupationDetails,
          pnationality: guardians[0]?.nationality,
          pfname2: guardians[1]?.firstName,
          plname2: guardians[1]?.lastName,
          pdob2: guardians[1]?.dob,
          relation2: guardians[1]?.relation,
          pcontact2: guardians[1]?.contact,
          pemail2: guardians[1]?.email,
          pcategory2: guardians[1]?.category,
          peducation2: guardians[1]?.education,
          empcategory2: guardians[1]?.empcategory,
          empfield2: guardians[1]?.empfield,
          organization2: guardians[1]?.organization,
          designation2: guardians[1]?.designation,
          income2: guardians[1]?.income,
          occupationDetails2: guardians[1]?.occupationDetails,
          pnationality2: guardians[1]?.nationality,
        });
      }
    }
  };

  useEffect(() => {
    handleGetDataOfApplicationFormByApplicationId();
  }, []);

  return (
    <>
      <Box sx={{ boxShadow: "none" }} className="formPersonalDetails">
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Item className="mainFormBody">
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={12}>
                    <Item>
                      <h3>Parent/Guardian - 1 Details</h3>
                    </Item>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Parent/Guardian First Name *
                    </Typography>
                    <input
                      type="text"
                      name="pfname"
                      placeholder="Enter your first name"
                      value={values.pfname}
                      onChange={handleInputChange}
                    />
                    {errors.pfname && (
                      <span className="error">{errors.pfname}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Parent/Guardian Last Name
                    </Typography>
                    <input
                      type="text"
                      name="plname"
                      placeholder="Enter your middle name"
                      value={values.plname}
                      onChange={handleInputChange}
                    />
                    {errors.plname && (
                      <span className="error">{errors.plname}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Parent/Guardian Date of Birth *
                    </Typography>
                    <input
                      type="date"
                      name="pdob"
                      placeholder="Enter your last name"
                      value={values.pdob}
                      onChange={handleInputChange}
                    />
                    {errors.pdob && (
                      <span className="error">{errors.pdob}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Relationship with Candidate *
                    </Typography>
                    <select
                      name="relation"
                      value={values.relation}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Select Relationship
                      </option>
                      <option value="Mother">Mother</option>
                      <option value="Father">Father</option>
                      <option value="Others">Others</option>
                    </select>
                    {errors.relation && (
                      <span className="error">{errors.relation}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Parent/Guardian Contact Number *
                    </Typography>
                    <PhoneInput
                      country={"in"}
                      onChange={(phone) =>
                        setValues({ ...values, pcontact: phone })
                      }
                      value={values.pcontact}
                      inputStyle={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        width: "100%",
                      }}
                    />
                    {errors.pcontact && (
                      <span className="error">{errors.pcontact}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Parent/Guardian Email Address
                    </Typography>
                    <input
                      type="text"
                      name="pemail"
                      placeholder="Enter email address"
                      value={values.pemail}
                      onChange={handleInputChange}
                    />
                    {errors.pemail && (
                      <span className="error">{errors.pemail}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Nationality *</Typography>
                    <input
                      type="text"
                      name="pnationality"
                      placeholder="Nationality"
                      value={values.pnationality}
                      onChange={handleInputChange}
                    />
                    {errors.pnationality && (
                      <span className="error">{errors.pnationality}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Category *</Typography>
                    <select
                      name="pcategory"
                      value={values.pcategory}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Select Category
                      </option>
                      <option value="General">General</option>
                      <option value="OBC">OBC</option>
                      <option value="SC">SC</option>
                      <option value="ST">ST</option>
                      <option value="EWS">EWS</option>
                      <option value="others">other minoritie</option>
                    </select>
                    {errors.pcategory && (
                      <span className="error">{errors.pcategory}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Highest Level of Education
                    </Typography>
                    <select
                      name="peducation"
                      value={values.peducation}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Select Education
                      </option>
                      <option value="PG">PG</option>
                      <option value="Graduate">Graduate</option>
                      <option value="UG">UG</option>
                    </select>
                    {errors.peducation && (
                      <span className="error">{errors.peducation}</span>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={12}>
                    <Item>
                      <h4>Guardian Employment Category</h4>
                    </Item>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Employment Category</Typography>
                    <select
                      name="empcategory"
                      value={values.empcategory}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Select Employment Category
                      </option>
                      <option value="Apprenticeship">Apprenticeship</option>
                      <option value="Casual">Casual</option>
                      <option value="Contract employment">
                        Contract employment
                      </option>
                      <option value="Employee">Employee</option>
                      <option value="Gainful employment">
                        Gainful employment
                      </option>
                      <option value="Probation">Probation</option>
                      <option value="Self-employed">Self-employed</option>
                      <option value="Temporary employees">
                        Temporary employees
                      </option>
                      <option value="Leased employees">Leased employees</option>
                      <option value="Office and Administrative Support occupations">
                        Office and Administrative Support occupations
                      </option>
                    </select>
                    {errors.empcategory && (
                      <span className="error">{errors.empcategory}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Field of Employment</Typography>
                    <select
                      name="empfield"
                      value={values.empfield}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Select Field of Employment
                      </option>
                      <option value="Education">Education</option>
                      <option value="Healthcare">Healthcare</option>
                      <option value="Sales">Sales</option>
                      <option value="Technology">Technology</option>
                      <option value="Architect">Architect</option>
                      <option value="Hospitality">Hospitality</option>
                      <option value="Construction">Construction</option>
                      <option value="Government">Government</option>
                      <option value="Agriculture">Agriculture</option>
                      <option value="Arts">Arts</option>
                      <option value="Human Resources">Human Resources</option>
                      <option value="Maintenance and repair">
                        Maintenance and repair
                      </option>
                      <option value="Media">Media</option>
                      <option value="Law enforcement">Law enforcement</option>
                      <option value="Marketing">Marketing</option>
                      <option value="Business">Business</option>
                    </select>
                    {errors.empfield && (
                      <span className="error">{errors.empfield}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Name of organization
                    </Typography>
                    <input
                      type="text"
                      name="organization"
                      placeholder="Enter name of organization"
                      value={values.organization}
                      onChange={handleInputChange}
                    />
                    {errors.organization && (
                      <span className="error">{errors.organization}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Designation</Typography>
                    <input
                      type="text"
                      name="designation"
                      placeholder="Enter designation"
                      value={values.designation}
                      onChange={handleInputChange}
                    />
                    {errors.designation && (
                      <span className="error">{errors.designation}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Gross Annual Income (In INR)
                    </Typography>
                    <input
                      type="text"
                      name="income"
                      placeholder="Enter annual income"
                      value={values.income}
                      onChange={handleInputChange}
                    />
                    {errors.income && (
                      <span className="error">{errors.income}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}></Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="body1">
                      Please provide further details of your parent/guardian's
                      occupation including position and a brief description.
                      (Upto 50 Words)
                    </Typography>
                    <TextareaAutosize
                      aria-label="Empty textarea"
                      name="occupationDetails"
                      placeholder="Type here ..."
                      value={values.occupationDetails}
                      onChange={handleInputChange}
                      style={{ width: "100%", minHeight: "100px" }}
                    />
                    {errors.occupationDetails && (
                      <span className="error">{errors.occupationDetails}</span>
                    )}
                  </Grid>
                </Grid>
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Item className="mainFormBody">
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={12}>
                    <Item>
                      <h3>Parent/Guardian - 2 Details</h3>
                    </Item>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Parent/Guardian First Name
                    </Typography>
                    <input
                      type="text"
                      name="pfname2"
                      placeholder="Enter your first name"
                      value={values.pfname2}
                      onChange={handleInputChange}
                    />
                    {errors.pfname2 && (
                      <span className="error">{errors.pfname2}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Parent/Guardian Last Name
                    </Typography>
                    <input
                      type="text"
                      name="plname2"
                      placeholder="Enter your middle name"
                      value={values.plname2}
                      onChange={handleInputChange}
                    />
                    {errors.plname2 && (
                      <span className="error">{errors.plname2}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Parent/Guardian Date of Birth
                    </Typography>
                    <input
                      type="date"
                      name="pdob2"
                      placeholder="Enter your last name"
                      value={values.pdob2}
                      onChange={handleInputChange}
                    />
                    {errors.pdob2 && (
                      <span className="error">{errors.pdob2}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Relationship with Candidate
                    </Typography>
                    <select
                      name="relation2"
                      value={values.relation2}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Select Relationship
                      </option>
                      <option value="Mother">Mother</option>
                      <option value="Father">Father</option>
                      <option value="Others">Others</option>
                    </select>
                    {errors.relation2 && (
                      <span className="error">{errors.relation2}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Parent/Guardian Contact Number
                    </Typography>
                    <PhoneInput
                      country={"in"}
                      onChange={(phone) =>
                        setValues({ ...values, pcontact2: phone })
                      }
                      value={values.pcontact2}
                      inputStyle={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        width: "100%",
                      }}
                    />
                    {errors.pcontact2 && (
                      <span className="error">{errors.pcontact2}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Parent/Guardian Email Address
                    </Typography>
                    <input
                      type="text"
                      name="pemail2"
                      placeholder="Enter email address"
                      value={values.pemail2}
                      onChange={handleInputChange}
                    />
                    {errors.pemail2 && (
                      <span className="error">{errors.pemail2}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Nationality</Typography>
                    <input
                      type="text"
                      name="pnationality2"
                      placeholder="Nationality"
                      value={values.pnationality2}
                      onChange={handleInputChange}
                    />
                    {errors.pnationality2 && (
                      <span className="error">{errors.pnationality2}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Category</Typography>
                    <select
                      name="pcategory2"
                      value={values.pcategory2}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Select Category
                      </option>
                      <option value="General">General</option>
                      <option value="OBC">OBC</option>
                      <option value="SC">SC</option>
                      <option value="ST">ST</option>
                      <option value="EWS">EWS</option>
                      <option value="others">other minoritie</option>
                    </select>
                    {errors.pcategory2 && (
                      <span className="error">{errors.pcategory2}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Highest Level of Education
                    </Typography>
                    <select
                      name="peducation2"
                      value={values.peducation2}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Select Education
                      </option>
                      <option value="PG">PG</option>
                      <option value="Graduate">Graduate</option>
                      <option value="UG">UG</option>
                    </select>
                    {errors.peducation2 && (
                      <span className="error">{errors.peducation2}</span>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={12}>
                    <Item>
                      <h4>Guardian Employment Category</h4>
                    </Item>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Employment Category</Typography>
                    <select
                      name="empcategory2"
                      value={values.empcategory2}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Select Employment Category
                      </option>
                      <option value="Apprenticeship">Apprenticeship</option>
                      <option value="Casual">Casual</option>
                      <option value="Contract employment">
                        Contract employment
                      </option>
                      <option value="Employee">Employee</option>
                      <option value="Gainful employment">
                        Gainful employment
                      </option>
                      <option value="Probation">Probation</option>
                      <option value="Self-employed">Self-employed</option>
                      <option value="Temporary employees">
                        Temporary employees
                      </option>
                      <option value="Leased employees">Leased employees</option>
                      <option value="Office and Administrative Support occupations">
                        Office and Administrative Support occupations
                      </option>
                    </select>
                    {errors.empcategory2 && (
                      <span className="error">{errors.empcategory2}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Field of Employment</Typography>
                    <select
                      name="empfield2"
                      value={values.empfield2}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Select Field of Employment
                      </option>
                      <option value="Education">Education</option>
                      <option value="Healthcare">Healthcare</option>
                      <option value="Sales">Sales</option>
                      <option value="Technology">Technology</option>
                      <option value="Architect">Architect</option>
                      <option value="Hospitality">Hospitality</option>
                      <option value="Construction">Construction</option>
                      <option value="Government">Government</option>
                      <option value="Agriculture">Agriculture</option>
                      <option value="Arts">Arts</option>
                      <option value="Human Resources">Human Resources</option>
                      <option value="Maintenance and repair">
                        Maintenance and repair
                      </option>
                      <option value="Media">Media</option>
                      <option value="Law enforcement">Law enforcement</option>
                      <option value="Marketing">Marketing</option>
                      <option value="Business">Business</option>
                    </select>
                    {errors.empfield2 && (
                      <span className="error">{errors.empfield2}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Name of organization
                    </Typography>
                    <input
                      type="text"
                      name="organization2"
                      placeholder="Enter name of organization"
                      value={values.organization2}
                      onChange={handleInputChange}
                    />
                    {errors.organization2 && (
                      <span className="error">{errors.organization2}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Designation</Typography>
                    <input
                      type="text"
                      name="designation2"
                      placeholder="Enter designation"
                      value={values.designation2}
                      onChange={handleInputChange}
                    />
                    {errors.designation2 && (
                      <span className="error">{errors.designation2}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Gross Annual Income (In INR)
                    </Typography>
                    <input
                      type="text"
                      name="income2"
                      placeholder="Enter annual income"
                      value={values.income2}
                      onChange={handleInputChange}
                    />
                    {errors.income2 && (
                      <span className="error">{errors.income2}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}></Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="body1">
                      Please provide further details of your parent/guardian's
                      occupation including position and a brief description.
                      (Upto 50 Words)
                    </Typography>
                    <TextareaAutosize
                      aria-label="Empty textarea"
                      placeholder="Type here ..."
                      name="occupationDetails2"
                      value={values.occupationDetails2}
                      onChange={handleInputChange}
                      style={{ width: "100%", minHeight: "100px" }}
                    />
                    {errors.occupationDetails2 && (
                      <span className="error">{errors.occupationDetails2}</span>
                    )}
                  </Grid>
                </Grid>
              </Item>
            </Grid>
            <Grid item xs={12} className="siblingContainer">
              <Item className="mainFormBody">
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={12}>
                    <Item>
                      <h3>Siblings</h3>
                    </Item>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Number of Siblings studying in same college *
                    </Typography>
                    <select
                      name="siblingCount"
                      value={values.siblingCount}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                    </select>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    &nbsp;
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    &nbsp;
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  className="siblingItemWrap"
                >
                  <Grid item xs={1} sm={1} md={1}>
                    <Typography variant="body1">S. No.</Typography>
                  </Grid>
                  <Grid item xs={2} sm={3} md={3}>
                    <Typography variant="body1">Sibling Name *</Typography>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Programme Name *</Typography>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Admission/Roll Number *
                    </Typography>
                  </Grid>
                </Grid>

                {Array.from({ length: values.siblingCount }).map((_, index) => (
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                    className="siblingItemWrap"
                  >
                    <Grid item xs={1} sm={1} md={1}>
                      <input
                        type="text"
                        name={`sno${index + 1}`}
                        placeholder={`${index + 1}`}
                        value={values[`sno${index + 1}`] || ""}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={2} sm={3} md={3}>
                      <input
                        type="text"
                        name={`sfullname${index + 1}`}
                        placeholder={`Sibling ${index + 1} Full Name *`}
                        value={values[`sfullname${index + 1}`] || ""}
                        onChange={handleInputChange}
                      />
                      <span className="error">
                        {errors[`sfullname${index + 1}`]}
                      </span>
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <input
                        type="text"
                        name={`programme${index + 1}`}
                        placeholder={`Programme ${index + 1} Name *`}
                        value={values[`programme${index + 1}`] || ""}
                        onChange={handleInputChange}
                      />
                      <span className="error">
                        {errors[`programme${index + 1}`]}
                      </span>
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <input
                        type="text"
                        name={`admno${index + 1}`}
                        placeholder={`Admission/Roll ${index + 1} Number *`}
                        value={values[`admno${index + 1}`] || ""}
                        onChange={handleInputChange}
                      />
                      <span className="error">
                        {errors[`admno${index + 1}`]}
                      </span>
                    </Grid>
                  </Grid>
                ))}
              </Item>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          pt: 2,
          justifyContent: "space-between",
        }}
        className="navBottomBtns"
      >
        <div style={{ display: "flex", gap: "12px" }}>
          <Button
            color="inherit"
            sx={{ mr: 1 }}
            className="backBtn"
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            onClick={() => {
              handleSave();
            }}
            sx={{ mr: 1 }}
          >
            Save
          </Button>
        </div>
        <Button sx={{ mr: 1 }} onClick={() => handleSave()}>
          Next
        </Button>
      </Box>
    </>
  );
};

export default FormParentDetails;

// authSlice.js

import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    resetPasswordEmail: null,
  },
  reducers: {
    setResetPasswordEmail: (state, action) => {
      state.resetPasswordEmail = action.payload;
    },
  },
});

export const { setResetPasswordEmail, setToggle } = authSlice.actions;
export const selectResetPasswordEmail = (state) => state.auth.resetPasswordEmail;

export default authSlice.reducer;

import React, { useState, useEffect } from "react";
import { getStudentDetails } from "modules/studentApplication";
import { updateDataToApplicationForm } from "modules/student";
import { toast } from "react-toastify";
import UploadButton from "components/common/ReUploadButton";
import { useDispatch, useSelector } from "react-redux";
import { setToggle } from "slices/userSlice";
import { useNavigate } from "react-router-dom";
import "./ApplicationStyles.css";
import useStyles from "./style";

const ApplicationDocs = ({ uniqueId, showResubmitButton  }) => {
  const user = useSelector((state) => state.user);
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [formData, setFormData] = useState({});
  const [flag, setFlag] = useState(true);
  const [show, setShow] = useState(showResubmitButton);
  const onlyPngUploadFileTypes = ["JPG", "PNG", "JPEG"];
  const uploadFileTypes = ["JPG", "PNG", "JPEG", "PDF"];

  const baseUrl = "https://erp-dev.techademycampus.com";

  useEffect(() => {
    getDetails();
  }, [user.toggle]);

  const getDetails = async () => {
    try {
      const res = await getStudentDetails(uniqueId);
      if (res.status === 200) {
        setData(res.data.data);
        handleFormPayload({ data: res.data.data });
      }
    } catch (error) {
      console.error("Error get the student details", error);
    }
  };

  const attachments = [
    { key: "custom_attach_aadhar_card", label: "Aadhaar card", documentStatusKey: "custom_aadhar_card_status" },
    { key: "custom_attach_relevant_document", label: "Relevant Document", documentStatusKey: "custom_relevant_document_status" },
    {
      key: "custom_if_any_please_attach_relevant_document",
      label: "Relevant Document2",
      documentStatusKey: "custom_health_status"
    },
    { key: "custom_attach_xiith_marksheet", label: "XIIth Marksheet", documentStatusKey: "custom_xiith_marksheet_status"},
    { key: "custom_attach_xith_marksheet", label: "XIth Marksheet", documentStatusKey: "custom_xith_marksheet_status" },
    { key: "custom_attach_xth_marksheet", label: "Xth Marksheet", documentStatusKey: "custom_xth_marksheet_status" },
    { key: "custom_upload_student_image", label: "Student Photo", documentStatusKey: "custom_student_image_status" },
    { key: "custom_attachment_info", label: "Custom Attachment", documentStatusKey: "custom_disciplinary_inquiry_status" },
  ];

  const extractFileName = (path) => {
    if (path) {
      const parts = path.split("/");
      return parts[parts.length - 1];
    } else {
      return "N/A";
    }
  };

  const validateData = ({ payload }) => {
    const values = Object.entries(payload);
    let isValid = true;
    values.map((val) => {
      if(val[0].includes("status")){
        if(val[1] == "Re-Submit") {
          isValid = false;
        }
      }
    })

    return isValid;
  }

  const handleUpdateDataToApplicationForm = async () => {
    const isValid = validateData({ payload: formData });
    if(isValid) {
      const payload = {
        ...formData,
        workflow_state: "Document Re-submitted"
      }
      const res = await updateDataToApplicationForm({ payload, name: uniqueId });
      if(res.status == 200) {
        toast.success("Documents Resubmitted");
        setShow(false);
        dispatch(setToggle({ toggle: !user.toggle }))
      } else {
        let error = res.data?.exception;
        error = error.split(":")[1];
        toast.error(error);
      }
    } else {
      toast.error("Please Re-Submit The Requested Documents.")
    }
  }

  const handleFormPayload = ({ data }) => {
    const payload = {};
    attachments.forEach((attachment) => {
      if (data[`${attachment.documentStatusKey}`] === "Re-Submit") {
        payload[attachment.key] = "";
        payload[attachment.documentStatusKey] = "Re-Submit";
      }
    });
    setFormData(payload);
  }

  return (
    <>
      <div className="formTableWrap">
        <table className="formTable docs_table">
          <thead>
            <tr className={styles.tableRow}>
              <th className={styles.tableHeaderTitle} style={{ paddingLeft: "22px" }}>Attachment</th>
              <th className={styles.tableHeaderTitle} style={{ paddingLeft: "22px" }}>Attached File</th>
              <th className={styles.tableHeaderTitle} style={{ paddingLeft: "22px" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {attachments.map((attachment, index) => {
              return  (
                <>
                  {
                    data[attachment.key] && <tr key={index} className={styles.tableRow}>
                    <td className={styles.tableDocContent}>{attachment.label}</td>
                    <td>
                      { data[`${attachment.documentStatusKey}`] === "Re-Submit" ? 
                        formData[`${attachment.documentStatusKey}`] === "" ?
                        <a
                          href={`${baseUrl}${formData[attachment.key]}`}
                          download={extractFileName(formData[attachment.key])}
                          target={`_blank`}
                          style={{ textDecoration: "none" }}
                        >
                          <p style={{ color: "#2C83EC", fontSize: "14px", fontWeight: "400" }}>{extractFileName(formData[attachment.key])}</p>
                        </a> :
                        <p style={{ color: "#F50E1C", fontSize: "14px", fontWeight: "400" }}>Re-submit</p> :  
                        data[attachment.key] && (
                          <a
                            href={`${baseUrl}${data[attachment.key]}`}
                            download={extractFileName(data[attachment.key])}
                            target={`_blank`}
                            style={{ textDecoration: "none" }}
                          >
                            <p className={styles.tableDocContent}>{extractFileName(data[attachment.key])}</p>
                          </a>
                        )
                      }
                    </td>
                    <td>
                      {
                        data[`${attachment.documentStatusKey}`] === "Approved" ? 
                        <div style={{ padding: "12px 0px" }}>
                          <div style={{ border: "1px solid #CDD6E2", borderRadius: "4px", padding: "4px 0px", display: "flex", justifyContent: "center", color: "#0D1021", fontSize: "14px", fontWeight: "500", maxWidth: "508px" }}>
                            Approved
                          </div> 
                        </div>:  
                        data[`${attachment.documentStatusKey}`] === "Re-Submit" ? 
                        <div style={{ padding: "12px 0px", width: "100%" }}>
                          <UploadButton 
                            formData={formData} 
                            setFormData={setFormData} 
                            fieldName={attachment.key} 
                            fieldName2={attachment.documentStatusKey}
                            flag={flag} setFlag={setFlag} 
                            fileTypes={attachment.key === "custom_upload_student_image" ? onlyPngUploadFileTypes : uploadFileTypes }
                          />
                        </div> :
                        <>-</>
                      }
                    </td>
                  </tr>
                  }
                </>
              )
            })}
          </tbody>
        </table>
      </div>
      <div style={{ display: "inline-flex", justifyContent: "space-between", width: "100%", marginTop: "34px" }}>
        <div></div>
        {
          show &&
          <div
            style={{
              backgroundColor:  "#A32930",
              color: "#FFFFFF",
              fontSize: "14px",
              fontWeight: "400",
              padding: "10px 20px",
              borderRadius: "4px",
              cursor: "pointer"
            }}
            onClick={handleUpdateDataToApplicationForm}
          >
            Re-submit Application
          </div>
        }
      </div>
    </>
  );
};

export default ApplicationDocs;

import React, { useEffect, useState } from 'react';
import useStyles from './style';
import { ReactSVG } from 'react-svg';
import { useNavigate } from 'react-router-dom';
import { GridMenuIcon } from '@mui/x-data-grid';
import ArrowDownSVG from "../../../assets/images/arrow-down.svg";
import FiltersSVG from "../../../assets/images/filters-icon.svg";
import { getAllFeeCategories } from '../../../modules/finance';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../slices/commonSlice';
import LoadingBackdrop from "../../common/LoadingBackdrop.js";
import { Table } from 'antd';

const FeeCategory = ({ setActiveModule }) => {
  const styles = useStyles();
  const navigate= useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [feeCategories, setFeeCategories] = useState([]);

  const columns = [
    {
      dataIndex: "category_name",
      title: "FEE CATEGORY",
      width: 1,
      render: (val, row) => {
        return (
            <p className={styles.tableContentFields}>{row.category_name}</p>
        );
      },
    },
    {
      dataIndex: "custom_amount",
      title: "AMOUNT",
      width: 0.8,
      render: (val, row) => {
        return (
            <p className={styles.tableContentFields}>{row.custom_amount}</p>
        );
      },
    }, 
    {
      dataIndex: "docstatus",
      title: "STATUS",
      width: 0.7,
      render: (val, row) => {
        return (
            <p className={row.status === "Active" ? styles.buttonPublish : styles.buttonUnPublish}>{row.status}</p>
        );
      },
    },
    {
      dataIndex: "id",
      title: "ACTION",
      width: 0.5,
      render: (val, row) => {
        return (
            <p className={styles.tableContentView} onClick={() => navigate(`/modules/finance/fee-category/${row.category_name}`)}>View</p>
        );
      },
    },
  ];

  const handleGetAllFeeCategories = async () => {
    dispatch(setLoading({ loading: true }));
    const res = await getAllFeeCategories();
    if(res.status === 200) {
      dispatch(setLoading({ loading: false }));
      const categories = [];
      for(let i = 0 ; i < res.data.data.length ; i++) {
        categories.push({
          id: i,
          category_name: res.data.data[i].category_name,
          custom_amount: res.data.data[i].custom_amount,
          status: res.data.data[i].enabled ? "Active" : "Inactive",
          idx: res.data.data[i].idx,
        })
      };
      setFeeCategories(categories);
    } else {
      dispatch(setLoading({ loading: false }));
    }
  };

  useEffect(() => {
    handleGetAllFeeCategories();
  }, []);

  return (
    <div className={styles.mainContainer}>
      {
        loading ?
        <LoadingBackdrop open={loading} />:
        <>
          <div className={styles.breadCrumbsContainer}>
            <p className={styles.breadCrumbsContent} onClick={() => navigate("/dashboard")}>IIHT {" > "}</p>
            <p className={styles.breadCrumbsContent} onClick={() => setActiveModule(0)}>Finance {" > "}</p>
            <p className={styles.breadCrumbsContent}>Fee Category</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon}/>
              <p className={styles.title}>Fee Category</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div className={`${styles.button} ${styles.filterButton}`}>
                <ReactSVG src={FiltersSVG} />
                <p className={styles.buttonText}>Filters</p>
                <ReactSVG src={ArrowDownSVG} />
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>Saved Filters</p>
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>...</p>
              </div>
              <div className={styles.activeButton} onClick={() => navigate("/modules/finance/add-new-fee-category")}>
                <p className={styles.activeButtonText}>+ Add Fee Category</p>
              </div>
            </div>
          </div>
          <Table 
            rowSelection={{ type: "checkbox" }} 
            columns={columns} 
            dataSource={feeCategories} 
            size='small'
            pagination={true}
          />
        </>
      }
    </div>
  )
}

export default FeeCategory;
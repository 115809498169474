import { Button } from "iiht-b2a-ui-components/build/components";
import React from "react";
import eventBus from "../../../core/eventBus";

const SureSubmitModal = ({
  handleUpdate,
  confirmationMessage = "Are you sure you want to submit?",
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "18px",
        alignItems: "center",
        padding: "18px 22px",
        width: "420px",
      }}
    >
      <p style={{ color: "#405071", fontSize: "22px", fontWeight: "600" }}>
        CONFIRMATION!
      </p>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ color: "#405071", fontSize: "14px", fontWeight: "500" }}>
          {confirmationMessage}
        </span>
      </div>
      <div style={{ display: "inline-flex" }}>
        <Button
          variant="contained"
          style={{
            marginRight: "8px",
            backgroundColor: "white",
            color: "gray",
            cursor: "pointer",
          }}
          onClick={() => {
            eventBus.emit("close-dialog");
          }}
        >
          No
        </Button>
        <Button
          variant="contained"
          style={{ cursor: "pointer" }}
          onClick={() => {
            eventBus.emit("close-dialog");
            handleUpdate();
          }}
        >
          Yes
        </Button>
      </div>
    </div>
  );
};

export default SureSubmitModal;

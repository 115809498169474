import React, { useState } from 'react';
import { Card, Input, Rate, Radio } from 'antd';

const { Group } = Radio;

const App = ({ questionType, options, question, name, onChange, value }) => {
  const [selectedValue, setSelectedValue] = useState("");

  const handleRadioChange = (e) => {
    const selectedOption = e.target.value;
    setSelectedValue(selectedOption);
    onChange(name, selectedOption); 
  };

  let questionContent;
  let selectOptions = Array.isArray(options) ? options : options ? options.replace(/^\[|\]$/g, '').split(",").map(option => option.trim().replace(/^"|"$/g, '')) : [];
  
  switch (questionType) {
    case 'Input':
      questionContent = (
        <div>
          <p style={{marginBottom:"10px"}}>{question}</p>
          <Input placeholder="Enter your answer" value={value} onChange={(e) => onChange(name, e.target.value)} />
        </div>
      );
      break;
      case 'Select':
        questionContent = (
          <div>
            <p style={{marginBottom:"10px" }}>{question}?</p>
            <Group onChange={handleRadioChange} value={selectedValue} style={{display:'flex', flexDirection:'column', marginBottom:"15px" }}>
              {selectOptions.length > 0 && selectOptions.map((option, index) => (
                <Radio key={index} value={option} style={{ marginBottom:"15px"}}>
                  {option}
                </Radio>
              ))}
            </Group>
          </div>
        );
        break;
    case 'Rating':
      questionContent = (
        <div>
          <p style={{marginBottom:"10px"}}>{question}</p>
          <Rate value={parseInt(value)} onChange={(value) => onChange(name, value.toString())} />
        </div>
      );
      break;
    default:
      questionContent = null;
  }

  return (
    <Card bordered={false} style={{ marginBottom: '16px', width: '90%' }}>
        {questionContent}
    </Card>
  );
};

export default App;

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { createEvent, getEvents, getLeadDetails, updateEvent } from "../../../../modules/lead";
import AddEvent from "../../../Lead/Event/AddEvent";
import { useNavigate } from "react-router-dom";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Table } from "antd";
import LoadingBackdrop from "../../../common/LoadingBackdrop.js";
import useStyles from "./style.js";
import GetEvent from "../../../Lead/Event/GetEvent/index.js";
 
export default function DataGridDemo({ uniqueId, showHeader }) {
  const styles = useStyles();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [value, setValue] = useState("4");
  const [updatedValue, setUpdatedValue] = useState({ status: "" });
  const [updateFlag, setUpdateFlag] = useState(false);
  const [leadEmail, setLeadEmail] = useState("");
  const [events, setEvents] = useState([]);
  const [showAddEvent, setShowAddEvent] = useState(false);
  const [eventDetails, setEventDetails] = useState({});
  const [showGetEvent, setShowGetEvent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addEventLoading, setAddEventLoading] = useState(false);
  const [values, setValues] = useState({
    subject: "",
    startOn: "",
    endsOn: "",
    eventCategory: "Event",
    status: "Open",
    eventType: "Private",
    addGuests: "",
    color: "",
    sendEmail: 0,
    repeat: 0,
    description: "",
  });

  const handleShowEvent = (details) => {
    setShowGetEvent(true);
    setEventDetails(details);
  }

  const columns = [
    {
      dataIndex: "subject",
      title: "Subject",
      width: 600,
    },
    {
      dataIndex: "event_type",
      title: "Event Type",
      width: 200,
      render: (val, row) => {
        const dynamicClassName = `statusEvent ${row.event_type}`;
        return (
          <span className={dynamicClassName}>{row.event_type}</span>
        );
      },
    },
    {
      dataIndex: "status",
      title: "Status",
      width: 200,
      render: (val, row) => {
        const dynamicClassName = `status ${row.status}`;
        return (
          <span className={dynamicClassName}>{row.status}</span>
        ); 
      },
    },
    {
      dataIndex: "name",
      title: "ID",
      width: 200,
    }, 
    {
      dataIndex: "idx",
      title: "Action",
      width: 200,
      render: (val, row) => {
        return (
          <span className={styles.viewLink} onClick={() => handleShowEvent(row)}>View</span>
        ); 
      },
    }, 
  ];

  useEffect(() => {
    handleGetEvents();
    handleGetLeadDetails();
  }, [showAddEvent]);

  const handleGetEvents = async () => {
    setLoading(true);
    const res = await getEvents({ id: uniqueId });
    if (res.status === 200) {
      setLoading(false);
      let data = res?.data?.data;
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        let val = {
          ...data[i],
          id: data[i].name,
          idx: i
        };
        arr.push(val);
      }
      setEvents(arr);
    } else {
      setLoading(false);
    }
  };

  const handleGetLeadDetails = async () => {
    const res = await getLeadDetails({ uniqueId });
    if (res.status === 200) {
      setLeadEmail(res.data.data.email_id);
      setValues({ ...values, addGuests: `${res.data.data.email_id}, ${user.email}`});
    }
  };
  
  const handleCreateEvent = async () => {
    setAddEventLoading(true);
    let guests = [];
    guests.push(
      {
        reference_doctype: "Lead",
        reference_docname: uniqueId,
        parentfield: "event_participants",
        parenttype: "Event",
        doctype: "Event Participants",
        email: leadEmail,
      },
      {
        reference_doctype: "User",
        reference_docname: user.fullName,
        parentfield: "event_participants",
        parenttype: "Event",
        doctype: "Event Participants",
        email: user.email,
      }
    );

    const payload = {
      starts_on: values.startOn,
      ends_on: values.endsOn,
      subject: values.subject,
      event_category: values.eventCategory,
      event_type: values.eventType,
      status: values.status,
      send_reminder: values.sendEmail,
      repeat_this_event: values.repeat,
      event_participants: guests,
      sync_with_google_calendar: 1,
      add_video_conferencing: 1,
      google_calendar: "Main",
      send_reminder: 1,
      description: values.description,
    };

    const res = await createEvent({ payload });
    if (res.status === 200) {
      setAddEventLoading(false);
      setShowAddEvent(false);
      toast.success("Event created Successfully !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setAddEventLoading(false);
      toast.error(res.data?.exc_type, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleTabChange = (event, newValue) => {
    if(newValue == 4) return;
    const type = newValue == 1 ? "details" : newValue == 3 ? "notes" : "emails";
    navigate(`/lead/${uniqueId}/${type}`);
  };

  const handleUpdateEvent = async () => {
    setLoading(true);
    setShowGetEvent(false);
    setUpdateFlag(false);
    const res = await updateEvent({ payload: updatedValue, name: eventDetails.name });
    handleGetEvents();
    setEventDetails({});
    if (res.status === 200) {
      setLoading(false);
      toast.success("Event updated!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setLoading(false);
      toast.error(res.data?.exc_type, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  return (
    <Box sx={{ width: "100%", typography: "body1", marginTop: "-20px" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {
          showHeader &&
            <TabContext value={value}>
              <TabList
                onChange={handleTabChange}
                aria-label="Lead Detail"
                className="tabHead"
              >
                <Tab label="Details" value="1" />
                <Tab label="Email" value="2" />
                <Tab label="Notes" value="3" />
                <Tab label="Event" value="4" />
              </TabList>
            </TabContext>
        }

        {showAddEvent && !addEventLoading ? <div style={{ display: "inline-flex", gap: "16px" }}>
          <Button
            variant="contained"
            style={{
              marginRight: "8px",
              backgroundColor: "white",
              color: "gray",
            }}
            onClick={() => setShowAddEvent(false)}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (!loading) {
                handleCreateEvent();
              }
            }}
          >
            {showAddEvent && addEventLoading ? "Loading..." : "Save"}
          </Button>
        </div> : 
        !addEventLoading && <div style={{ display: "inline-flex", gap: "16px" }}>
          {
            showGetEvent && (
              <Button
                variant="contained"
                style={{
                  marginRight: "8px",
                  backgroundColor: "white",
                  color: "gray",
                }}
                onClick={() => setShowGetEvent(false)}
              >
                Back
              </Button>
            )
          }
          {
            updateFlag ? 
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => {
                handleUpdateEvent()
              }}
            >
              Update Event
            </Button> : 
            !showGetEvent && <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => {
                setShowGetEvent(false)
                setShowAddEvent(true);
              }}
            >
              Add Event
            </Button>
          }
        </div>}
      </Box>
      <TabContext>
        <Box sx={{ height: 400, width: "100%", marginTop: "14px" }}>
          {
            showGetEvent && <GetEvent eventDetails={eventDetails} values={values} setUpdatedValue={setUpdatedValue} setUpdateFlag={setUpdateFlag} updatedValue={updatedValue}/>
          }
          {showAddEvent ? (
            <AddEvent
              loading={addEventLoading}  
              setValues={setValues}
              values={values}
            />
          ) : (
            <>
              {loading ? 
                <LoadingBackdrop open={loading}/> : 
                !loading && events.length > 0 && !showGetEvent ?
                  <Table
                    columns={columns} 
                    dataSource={events} 
                    size='small'
                    pagination={true}
                  />
                  : !showGetEvent && <p>No Events Created yet</p> }
            </>
          )}
        </Box>
      </TabContext>
    </Box>
  );
}

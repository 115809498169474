import React, { useState } from 'react';
import { formData } from './dynamicJson';
import './dynamicForm.css';

const TextBox = ({ field }) => {
  const { label, name, placeholder } = field;
  return (
    <div className="form-field">
      <label>{label}</label>
      <input type="text" name={name} placeholder={placeholder} />
    </div>
  );
};

const SelectBox = ({ field }) => {
  const { label, name, options } = field;
  return (
    <div className="form-field">
      <label>{label}</label>
      <select name={name}>
        <option selected disabled>
          {label}
        </option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

const EmailInputBox = ({ field }) => {
  const { label, name, placeholder } = field;
  return (
    <div className="form-field">
      <label>{label}</label>
      <input type="email" name={name} placeholder={placeholder} />
    </div>
  );
};

const RadioBox = ({ field }) => {
  const { label, name, options } = field;
  return (
    <div className="form-field">
      <label>{label}</label>
      <div className="radio-options-container">
      {options.map((option) => (
        <div key={option} className="radio-option">
          <input type="radio" id={option} name={name} value={option} />
          <label htmlFor={option}>{option}</label>
        </div>
      ))}
      </div>
    </div>
  );
};

const Table = ({ field }) => {
  const { label, name, headers, minRows } = field;
  const rows = Array.from({ length: minRows }, (_, index) => index + 1);

  return (
    <div>
      <h3>{label}</h3>
      <table>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((rowIndex) => (
            <tr key={rowIndex}>
              {headers.map((header) => (
                <td key={header}>
                  <input type="text" name={`${name}_${header}_${rowIndex}`} placeholder={header} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};



const ApplicationFormData = () => {
    const [fieldsToRender, setFieldsToRender] = useState([]);
  
    const handleSetFields = (fields) => {
      setFieldsToRender(fields);
    };
  
    return (
        <div style={{ padding: '40px 40px', display: 'flex', flexDirection: 'column', gap: '28px' }}>
          <div style={{ display: 'inline-flex', gap: '16px' }}>
            {formData.map((data) => (
              <div
                key={data.name}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => handleSetFields(data.fields)}
              >
                {data.name}
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', gap: '16px' }}>
            {fieldsToRender.map((field, index) => (
              <React.Fragment key={index}>
                {field.type === 'section' && (
                  <div style={{ display: 'flex', gap: '16px' }}>
                    <h2>{field.label}</h2>
                  </div>
                )}
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', flexWrap: 'wrap' }}>
                  {field.fields.map((sectionField, sectionIndex) => (
                    <React.Fragment key={sectionIndex}>
                      {sectionField.type === 'col-break' ? (
                        <div style={{ display: 'block', flexBasis: '100%', width: '100%' }} key={sectionIndex}></div>
                      ) : (
                        renderFieldByType(sectionField)
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      );
    };

const renderFieldByType = (field) => {
  switch (field.type) {
    case 'text':
      return <TextBox field={field} />;
    case 'select':
      return <SelectBox field={field} />;
    case 'email':
      return <EmailInputBox field={field} />;
    case 'radio':
      return <RadioBox field={field} />;
    case 'table':
      return <Table field={field} />;
    default:
      return null;
  }
};

export default ApplicationFormData;

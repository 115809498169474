import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Breadcrumbs, MainLayout } from "components/common";
import { ColorPicker, Select } from "antd";
import {
  getGenericData,
  createGenericEntity,
  updateGenericEntity,
  getCountries,
} from "modules/hr";
import { Input, Button, Table, message, Checkbox } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "slices/commonSlice";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { toast } from "react-toastify";

const HolidayListDetails = () => {
  const loading = useSelector((state) => state.common.loading);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [holidayList, setHolidayList] = useState({
    holiday_list_name: "",
    from_date: "",
    to_date: "",
    weekly_off: "",
    total_holidays: "",
    holidays: [],
    doctype: "Holiday List",
    country: "",
    subdivision: "",
  });
  const [selectedColor, setSelectedColor] = useState(null);
  const [isViewMode, setIsViewMode] = useState(false);
  const [errors, setErrors] = useState({});
  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [countries, setCountries] = useState([]);
    const [values, setValues] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  useEffect(() => {
    
    handleGetCountries();
  }, []);

  const handleGetCountries = async () => {
    setStates([]);
    setCities([]);
    setValues({
      ...values,
      country: "",
      state: "",
      city: "",
    });
    const res = await getCountries();
    if (res.status === 200) {
      setCountries(res?.data?.data);
    }
  };

  // Disable "Holiday List Name" and "Total Holidays" input fields
  const isEditMode = id && isViewMode;
  const disableHolidayListName = isEditMode;
  const disableTotalHolidays = isEditMode;

  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;
    const updatedData = holidayList.holidays.map((item) => ({
      ...item,
      checked: checked,
    }));
    setHolidayList((prevState) => ({
      ...prevState,
      holidays: updatedData,
    }));
    setSelectAll(checked);
  };

  const handleCheckboxChange = (index, checked) => {
    const updatedLeaveAllocations = [...holidayList.holidays];
    updatedLeaveAllocations[index].checked = checked;
    setHolidayList((prevState) => ({
      ...prevState,
    }));
    // Check if any checkbox is checked
    const anyChecked = updatedLeaveAllocations?.some((item) => item.checked);
    setSelectAll(anyChecked);
  };

  const handleEditClick = (index) => {
    setEditableRowIndex(index);
  };

  const handleSaveClick = (index) => {
    setEditableRowIndex(null);
    // Save the changes for the specific row
  };

  const handleCancelClick = () => {
    setEditableRowIndex(null);
    // Revert changes if needed
  };

  const isRowEditable = (index) => {
    return editableRowIndex === index;
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading({ loading: true }));
      try {
        if (id && id !== "new") {
          const holidayListData = await getGenericData("Holiday List", id);
          setHolidayList(holidayListData.message);
          setIsViewMode(true);
          console.log(holidayListData.message, "Holiday List");
          // Initialize selectedColor with the color value from the API response
          const color = holidayListData.message.color;
          setSelectedColor(color || null);
        }
      } catch (error) {
        toast.error("Failed to fetch holiday list. Please try again.");
      } finally {
        dispatch(setLoading({ loading: false }));
      }
    };

    fetchData();
  }, [id, dispatch]);

  const handleDeleteLeave = () => {
    const updatedLeaveAllocations = holidayList.holidays.filter(
      (item) => !item.checked
    );
    setHolidayList((prevState) => ({
      ...prevState,
      leave_allocations: updatedLeaveAllocations,
    }));
    setSelectAll(false);
  };

  // Conditional rendering of the delete button
  const renderDeleteButton = () => {
    const anyChecked = holidayList.holidays?.some((item) => item.checked);
    if (anyChecked) {
      return (
        <Button onClick={handleDeleteLeave} className="deleteRow">
          {"Delete"}
        </Button>
      );
    }
    return null;
  };

  const handleSave = async () => {
    try {
      const doctype = "Holiday List";
      const validationErrors = validateFields();

      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }

      let colorHex = null;
      if (
        selectedColor &&
        typeof selectedColor === "object" &&
        selectedColor.toHexString
      ) {
        colorHex = selectedColor.toHexString();
      }

      console.log("Selected Color:", colorHex);
      const payload = {
        ...holidayList,
        color: colorHex,
        doctype,
      };

      let response;
      if (id && id !== "new") {
        response = await updateGenericEntity({ payload });
        toast.success("Holiday list updated successfully");
      } else {
        response = await createGenericEntity({ payload });
        if (response && response.id) {
          // Check if the response contains the id property
          toast.success("Holiday list added successfully");
          navigate("/modules/hr");
          return; // Exit the function after displaying the success message
        }
      }

      if (!response || !response.id) {
        // Check if the response contains the id property
        throw new Error("Failed to create holiday list");
      }
    } catch (error) {
      console.error("Error creating/updating holiday list:", error);
    }
    toast.success("Holiday list added successfully");
  };

  // Function to validate required fields
  const validateFields = () => {
    const errors = {};

    if (!holidayList.holiday_list_name.trim()) {
      errors.holiday_list_name = "Holiday List Name is required";
    }

    if (!holidayList.from_date.trim()) {
      errors.from_date = "From Date is required";
    }

    if (!holidayList.to_date.trim()) {
      errors.to_date = "To Date is required";
    }

    return errors;
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setHolidayList((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };
  const handleChange = (fieldName, value) => {
    setHolidayList((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };
  const handleAddHoliday = () => {
    // Get the selected weekly off day from the state
    const selectedWeeklyOff = holidayList.weekly_off;

    // Define an array of day names corresponding to their index (0 for Sunday, 1 for Monday, etc.)
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Check if weekly off day is selected
    if (selectedWeeklyOff !== null && selectedWeeklyOff !== undefined) {
      // Calculate the number of days between from_date and to_date
      const fromDate = new Date(holidayList.from_date);
      const toDate = new Date(holidayList.to_date);
      const daysDifference = Math.floor(
        (toDate - fromDate) / (1000 * 60 * 60 * 24)
      );

      // Initialize an array to hold the holiday entries
      const newHolidays = [];

      // Iterate through each day between from_date and to_date
      for (let i = 0; i <= daysDifference; i++) {
        const currentDate = new Date(
          fromDate.getTime() + i * 24 * 60 * 60 * 1000
        );

        // Check if the current day is the selected weekly off day
        if (currentDate.getDay() === selectedWeeklyOff) {
          // Get the day name corresponding to the current day index
          const dayName = dayNames[currentDate.getDay()];

          // Add the current day as a holiday entry with the day name included in the description
          const newHoliday = {
            id: "", // You may generate a unique id here
            holiday_date: currentDate.toISOString().slice(0, 10), // Format the date as YYYY-MM-DD
            description: `Weekly Off (${dayName})`, // Include the day name in the description
            weekly_off: selectedWeeklyOff, // Save the selected weekly off day
          };
          newHolidays.push(newHoliday);
        }
      }

      // Update the holidayList state to include the new holidays
      setHolidayList((prevState) => ({
        ...prevState,
        holidays: [...prevState.holidays, ...newHolidays],
      }));
    }
  };

  const handleHolidayChange = (index, e) => {
    const { name, value } = e.target;
    const updatedHolidays = [...holidayList.holidays];
    updatedHolidays[index][name] = value;
    setHolidayList((prevState) => ({
      ...prevState,
      holidays: updatedHolidays,
    }));
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const columns = [
    {
      title: () => (
        <Checkbox checked={selectAll} onChange={handleSelectAllChange} />
      ),
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => (
        <Checkbox
          checked={record.checked}
          onChange={(e) => handleCheckboxChange(index, e.target.checked)}
        />
      ),
    },
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Holiday Date",
      dataIndex: "holiday_date",
      key: "holiday_date",
      render: (text, record, index) =>
        isRowEditable(index) ? (
          <Input
            type="date"
            value={text}
            onChange={(e) => handleHolidayChange(index, e)}
            name="holiday_date"
          />
        ) : (
          text
        ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text, record, index) =>
        isRowEditable(index) ? (
          <Input
            type="text"
            value={text}
            onChange={(e) => handleHolidayChange(index, e)}
            name="description"
          />
        ) : (
          text
        ),
    },
    {
      title: "Action",
      dataIndex: "actions",
      key: "actions",
      render: (text, record, index) => (
        <div>
          {isRowEditable(index) ? (
            <div className="saveCancel">
              <Button onClick={() => handleSaveClick(index)} type="primary">
                Save
              </Button>
              <Button onClick={handleCancelClick}>Cancel</Button>
            </div>
          ) : (
            <Button onClick={() => handleEditClick(index)} type="link">
              Edit
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <MainLayout>
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <>
          <Breadcrumbs />
          <div className="master-heading">
            <h2>{isViewMode ? "View Holiday List" : "Add Holiday List"}</h2>
            <div className="btnsGrp">
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                back
              </Button>
              <Button
                onClick={handleSave}
                variant="contained"
                color="primary"
                className="redBtn"
              >
                {isViewMode ? "Update" : "Save"}
              </Button>
            </div>
          </div>
          <div className="formApplication leaveMgmtForm">
            <section className="form-section">
              <div className="fields-block">
                <div className="form-field">
                  <label>Holiday List Name *</label>
                  <input
                    type="text"
                    name="holiday_list_name"
                    placeholder="Holiday List Name"
                    value={holidayList.holiday_list_name}
                    onChange={(e) =>
                      handleChange("holiday_list_name", e.target.value)
                    }
                    disabled={disableHolidayListName}
                  />
                  {errors.holiday_list_name && (
                    <span className="error-message">
                      {errors.holiday_list_name}
                    </span>
                  )}
                </div>
                <div className="form-field">
                  <label>Total Holidays *</label>
                  <input
                    type="text"
                    name="total_holidays"
                    placeholder="Total Holidays"
                    value={holidayList.total_holidays}
                    onChange={(e) =>
                      handleChange("total_holidays", e.target.value)
                    }
                    disabled={disableTotalHolidays}
                  />
                  {errors.total_holidays && (
                    <span className="error-message">
                      {errors.total_holidays}
                    </span>
                  )}
                </div>
                <div className="form-field col-break"></div>
                <div className="form-field">
                  <label>From Date *</label>
                  <input
                    type="date"
                    name="from_date"
                    placeholder="YYYY-MM-DD"
                    value={holidayList.from_date}
                    onChange={(e) => handleChange("from_date", e.target.value)}
                  />
                  {errors.from_date && (
                    <span className="error-message">{errors.from_date}</span>
                  )}
                </div>
                <div className="form-field">
                  <label>To Date *</label>
                  <input
                    type="date"
                    name="to_date"
                    value={holidayList.to_date}
                    onChange={(e) => handleChange("to_date", e.target.value)}
                  />
                  {errors.to_date && (
                    <span className="error-message">{errors.to_date}</span>
                  )}
                </div>
              </div>
            </section>

            <section className="form-section">
              <h4> {"Add Weekly Holidays"}</h4>
              <div className="form-field">
                <label>Weekly Off</label>
                <Select
                  name="weekly_off"
                  onChange={(value) => handleChange("weekly_off", value)} // Pass the field name and the selected value
                  value={holidayList.weekly_off} // Set the value of the Select component
                  placeholder="Select weekly off"
                >
                  {/* Render options for each day of the week */}
                  <Select.Option value={0}>Sunday</Select.Option>
                  <Select.Option value={1}>Monday</Select.Option>
                  <Select.Option value={2}>Tuesday</Select.Option>
                  <Select.Option value={3}>Wednesday</Select.Option>
                  <Select.Option value={4}>Thursday</Select.Option>
                  <Select.Option value={5}>Friday</Select.Option>
                  <Select.Option value={6}>Saturday</Select.Option>
                </Select>

                {errors.weekly_off && (
                  <span className="error-message">{errors.weekly_off}</span>
                )}
              </div>
              <Button onClick={handleAddHoliday} className="addRow">
                Add to holiday
              </Button>
            </section>
            <section className="form-section">
              <h4> {"Add Local Holidays"}</h4>
              <div className="form-field">
                <label>Select Country</label>
                <Select
                  name="country"
                  onChange={(value) => handleChange("country", value)}
                  value={holidayList.country}
                  placeholder="Select Country"
                >
                  {countries?.map((country) => (
                    <Select.Option key={country.name} value={country.name}>
                      {country.name}
                    </Select.Option>
                  ))}
                </Select>

                {errors.country && (
                  <span className="error-message">{errors.country}</span>
                )}
              </div>
              <div className="form-field">
                <label>Select Subdivision</label>
                <Select
                  name="subdivision"
                  onChange={(value) => handleChange("subdivision", value)} // Pass the field name and the selected value
                  value={holidayList.subdivision} // Set the value of the Select component
                  placeholder="Select Subdivision"
                >
                  {/* Render options for each subdivision */}
                  <Select.Option value={0}>AN</Select.Option>
                </Select>

                {errors.subdivision && (
                  <span className="error-message">{errors.subdivision}</span>
                )}
              </div>
              <Button onClick={handleAddHoliday} className="addRow">
                Add to holiday
              </Button>
            </section>
            <section className="form-section">
              <h4> {"Holidays"}</h4>
              <Table dataSource={holidayList.holidays} columns={columns} />

              <div className="addDeleteBtns">
                {renderDeleteButton()}
                <Button onClick={handleAddHoliday} className="addRow">
                  {"+"}
                </Button>
              </div>
            </section>
            <section className="form-section">
              <div className="colorPicker">
                <label>Choose a Color</label>
                <ColorPicker
                  color={selectedColor}
                  onChange={(color) => {
                    console.log("Color changed:", color);
                    setSelectedColor(color); // Ensure setSelectedColor sets a color object
                  }}
                  placeholder="Select color"
                />
              </div>
            </section>
          </div>
        </>
      )}
    </MainLayout>
  );
};

export default HolidayListDetails;

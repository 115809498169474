import React from 'react';

function DeleteSmall({ fill, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="20"
      viewBox="0 96 960 960"
      width="20"
      fill={fill}
      {...rest}
    >
      <path d="M312 912q-29.7 0-50.85-21.15Q240 869.7 240 840V360h-48v-72h192v-48h192v48h192v72h-48v479.566Q720 870 698.85 891 677.7 912 648 912H312Zm336-552H312v480h336V360ZM384 768h72V432h-72v336Zm120 0h72V432h-72v336ZM312 360v480-480Z" />
    </svg>
  );
}

export default DeleteSmall;

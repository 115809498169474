import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import authReducer from "./slices/authSlice";
import commonReducer from "./slices/commonSlice";
import filterReducer from "./slices/filterSlice";
import financeReducer from "./slices/financeSlice";
import admissionReducer from "./slices/admissionSlice";
import hrReducer from "./slices/hrSlice";
import academicsReducer from "./slices/academicsSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    auth: authReducer,
    common: commonReducer,
    filters: filterReducer,
    finance: financeReducer,
    admission: admissionReducer,
    hr: hrReducer,
    academics: academicsReducer,
  },
  devTools: process.env.REACT_APP_ENVIRONMENT !== "production",
});

export default store;

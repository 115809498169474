import React from "react";
import NonActiveIcon from "../../../src/assets/images/non-active-sidebar-icon.svg";
import SideBar from "components/SideBar";
import StudentApplicationDashboard from "components/Student/Applications";
import { useSelector } from "react-redux";
import { roleConstants } from "config/roles";
import StudentExamsAndResults from "components/Student/ExamsAndResults";
import StudentTimeTable from "components/Student/TimeTable";
import StudentEvents from "components/Student/Events";
import StudentDocumentManagement from "components/Student/DocumentManagement";
import StudentLogout from "components/Student/Logout";
import StudentProfile from "components/Student/Profile";
import StudentAttendance from "screens/Academics/Attendance/student-attendence";
import StudentFeedback from "components/Student/Feedback"

const sidebarContent = [
  { label: "Applications", icon: NonActiveIcon },
  { label: "Profile", icon: NonActiveIcon },
  { label: "Logout", icon: NonActiveIcon },
];

const admittedStudentSidebarContent = [
  { label: "Profile", icon: NonActiveIcon },
  { label: "Attendance", icon: NonActiveIcon },
  { label: "Exams & Result", icon: NonActiveIcon },
  { label: "Time Table", icon: NonActiveIcon },
  { label: "Events", icon: NonActiveIcon },
  { label: "Document Management System", icon: NonActiveIcon },
  { label: "Feedback", icon: NonActiveIcon},
  { label: "Logout", icon: NonActiveIcon },
];

const modules = [StudentApplicationDashboard, StudentProfile, StudentLogout];

const admittedModules = [
  StudentProfile,
  StudentAttendance,
  StudentExamsAndResults,
  StudentTimeTable,
  StudentEvents,
  StudentDocumentManagement,
  StudentFeedback,
  StudentLogout,
];

const StudentDashboard = () => {
  const { roles } = useSelector((state) => state.user);
  const isAdmittedStudent = roles.includes(roleConstants.admittedStudent);
  return (
    <SideBar
      sidebarContent={
        isAdmittedStudent ? admittedStudentSidebarContent : sidebarContent
      }
      modules={isAdmittedStudent ? admittedModules : modules}
    />
  );
};

export default StudentDashboard;

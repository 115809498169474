import React from "react";
import NonActiveIcon from "../../../src/assets/images/non-active-sidebar-icon.svg";
import AdmissionLeadManagement from "components/Admission/LeadManagement";
import AdmissionApplicationManagement from "components/Admission/ApplicationManagement";
import AdmissionMeritList from "components/Admission/MeritList";
import AdmissionDashboard from "components/Admission/Dashboard";
import AdmissionStudentManagement from "components/Admission/StudentManagement";
import SideBar from "components/SideBar";


const sidebarContent = [
  { label: "Dashboard", icon: NonActiveIcon },
  { label: "Lead Management", icon: NonActiveIcon },
  { label: "Application Management", icon: NonActiveIcon },
  { label: "Merit List", icon: NonActiveIcon },
  { label: "Student Management", icon: NonActiveIcon },
];

const modules = [
  AdmissionDashboard,
  AdmissionLeadManagement,
  AdmissionApplicationManagement,
  AdmissionMeritList,
  AdmissionStudentManagement
];

const Admission = () => {
  return <SideBar sidebarContent={sidebarContent} modules={modules} />;
};

export default Admission;
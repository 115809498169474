export const structureBasedOnOptions = [
  {
    value: "Academic",
    label: "Academic"
  },
  {
    value: "Non-Academic",
    label: "Non-Academic"
  }
];

export const AdmissionTypeOptions = [
  {
    value: "Regular",
    label: "Regular"
  },
  {
    value: "Self Supporting",
    label: "Self Supporting"
  }
]
import React, { useEffect, useState } from "react";
import useStyles from "../style";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import MainHeader from "components/common/MainHeader";
import MainFooter from "components/common/MainFooter";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { useDispatch, useSelector } from "react-redux";
import { Select, Table, Input, Tabs, Tab, Collapse, Checkbox } from "antd";
import ReactQuill from "react-quill";
import {
  leaveEncashedOptions,
  bloodGroupOptions,
  maritalStatusOptions,
  salaryModeOptions,
  addressTypeOptions,
  prefferedContactEmailOptions,
  employeeStatusOptions,
  levelOptions,
} from "../config";
import { getGenderList } from "modules/student";
import {
  createHrEmployee,
  getAllCostCenterListing,
  getDepartment,
  getDesignation,
  getEmployeeGrade,
  getEmploymentType,
  getHolidayListing,
  getJobApplicantListing,
  getSalutationListing,
  getAllUserListing,
} from "modules/hr";

import { toast } from "react-toastify";
import { getCollegeListing, getCurrencyListing } from "modules/finance";
import {
  getAllEmployeeListing,
  getAllShiftTypeListing,
} from "modules/academics";

const { TabPane } = Tabs;
const { Panel } = Collapse;

const AddNewEmployee = () => {
  const styles = useStyles();
  const selectSize = "middle";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);

  const [values, setValues] = useState({
    salutation: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    gender: "",
    date_of_birth: "",
    date_of_joining: "",
    status: "Active",
    user_id: "",
    create_user_permission: "",
    company: "",
    designation: "",
    department: "",
    employment_type: "",
    reports_to: "",
    grade: "",
    job_applicant: "",
    final_confirmation_date: "",
    notice_number_of_days: "",
    scheduled_confirmation_date: "",
    contract_end_date: "",
    date_of_retirement: "",
    cell_number: "",
    personal_email: "",
    prefered_contact_email: "",
    company_email: "",
    unsubscribed: "",
    current_accommodation_type: "",
    permanent_accommodation_type: "",
    current_address: "",
    permanent_address: "",
    person_to_be_contacted: "",
    emergency_phone_number: "",
    relation: "",
    attendance_device_id: "",
    holiday_list: "",
    default_shift: "",
    leave_approver: "",
    ctc: "",
    payroll_cost_center: "",
    salary_currency: "INR",
    pan_number: "",
    salary_mode: "",
    provident_fund_account: "",
    bank_name: "",
    bank_ac_no: "",
    ifsc_code: "",
    micr_code: "",
    iban: "",
    marital_status: "",
    blood_group: "",
    family_background: "",
    health_details: "",
    passport_number: "",
    date_of_issue: "",
    valid_upto: "",
    place_of_issue: "",
    bio: "",
    education: [{}],
    external_work_history: [{}],
    internal_work_history: [{}],
    resignation_letter_date: "",
    held_on: "",
    leave_encashed: "",
    relieving_date: "",
    new_workplace: "",
    encashment_date: "",
    reason_for_leaving: "",
    feedback: "",
  });
  const [errors, setErrors] = useState({
    first_name: "",
    gender: "",
    date_of_birth: "",
    date_of_joining: "",
    status: "",
    company: "",
    relieving_date: "",
  });
  const [selectedRowKeys1, setSelectedRowKeys1] = useState([]);
  const [selectedRowKeys2, setSelectedRowKeys2] = useState([]);
  const [selectedRowKeys3, setSelectedRowKeys3] = useState([]);
  const [salutationOptions, setSalutationOptions] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);
  const [collegeListOptions, setCollegeListOptions] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);
  const [employmentTypeOptions, setEmploymentTypeOptions] = useState([]);
  const [employeeListOptions, setEmployeeListOptions] = useState([]);
  const [jobApplicantOptions, setJobApplicantOptions] = useState([]);
  const [shiftTypeOptions, setShiftTypeOptions] = useState([]);
  const [holidayListOptions, setHolidayListOptions] = useState([]);
  const [currencyListOptions, setCurrencyListOptions] = useState([]);
  const [costCenterOptions, setCostCenterOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [gradeOptions, setGradeOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);

  const handleAddEducation = () => {
    setValues({
      ...values,
      education: [...values.education, {}],
    });
  };

  const handleDeleteEducation = () => {
    const education = values.education;
    const resultArray = education.filter(
      (obj) => !selectedRowKeys1.includes(obj.key)
    );
    setSelectedRowKeys1([]);
    setValues({
      ...values,
      education: resultArray.length > 0 ? resultArray : [{}],
    });
  };

  const handleAddPreviousWork = () => {
    setValues({
      ...values,
      external_work_history: [...values.external_work_history, {}],
    });
  };

  const handleDeletePreviousWork = () => {
    const external_work_history = values.external_work_history;
    const resultArray = external_work_history.filter(
      (obj) => !selectedRowKeys2.includes(obj.key)
    );
    setSelectedRowKeys2([]);
    setValues({
      ...values,
      external_work_history: resultArray.length > 0 ? resultArray : [{}],
    });
  };

  const handleAddHistoryWork = () => {
    setValues({
      ...values,
      internal_work_history: [...values.internal_work_history, {}],
    });
  };

  const handleDeleteHistoryWork = () => {
    const internal_work_history = values.internal_work_history;
    const resultArray = internal_work_history.filter(
      (obj) => !selectedRowKeys3.includes(obj.key)
    );
    setSelectedRowKeys3([]);
    setValues({
      ...values,
      internal_work_history: resultArray.length > 0 ? resultArray : [{}],
    });
  };

  const onSelectChange1 = (selectedKeys) => {
    setSelectedRowKeys1(selectedKeys);
  };

  const onSelectChange2 = (selectedKeys) => {
    setSelectedRowKeys2(selectedKeys);
  };

  const onSelectChange3 = (selectedKeys) => {
    setSelectedRowKeys3(selectedKeys);
  };

  const rowSelection1 = {
    type: "checkbox",
    onChange: onSelectChange1,
    selectedRowKeys: selectedRowKeys1,
  };

  const rowSelection2 = {
    type: "checkbox",
    onChange: onSelectChange2,
    selectedRowKeys: selectedRowKeys2,
  };

  const rowSelection3 = {
    type: "checkbox",
    onChange: onSelectChange3,
    selectedRowKeys: selectedRowKeys3,
  };

  const educationColumns = [
    {
      title: "No.",
      dataIndex: "index",
      width: "5%",
      render: (val, record, index) => {
        return <p className={styles.tableContentFields}>{index + 1}</p>;
      },
    },
    {
      title: "School/University",
      dataIndex: "school_university",
      width: "25%",
      render: (val, record) => {
        return (
          <Input
            placeholder="Enter School/University"
            className={styles.tableContentFields}
            style={{ width: "100%", border: "none" }}
            value={record.school_university}
            onChange={(e) => {
              record.school_university = e.target.value;
            }}
          />
        );
      },
    },
    {
      title: "Qualification",
      dataIndex: "qualification",
      width: "20%",
      render: (val, record) => {
        return (
          <Input
            placeholder="Enter Qualification"
            className={styles.tableContentFields}
            style={{ width: "100%", border: "none" }}
            value={record.qualification}
            onChange={(e) => {
              record.qualification = e.target.value;
            }}
          />
        );
      },
    },
    {
      title: "Level",
      dataIndex: "level",
      width: "20%",
      render: (val, record) => {
        return (
          <Select
            className={styles.tableContentFields}
            size={selectSize}
            placeholder="Select Level"
            style={{ width: "100%", border: "none !important" }}
            value={record.level}
            options={levelOptions}
            onChange={(value) => {
              record.level = value;
            }}
          />
        );
      },
    },
    {
      title: "Year of Passing",
      dataIndex: "year_of_passing",
      width: "20%",
      render: (val, record) => {
        return (
          <Input
            placeholder="Enter Year of Passing"
            className={styles.tableContentFields}
            style={{ width: "100%", border: "none" }}
            value={record.year_of_passing}
            onChange={(e) => {
              record.year_of_passing = e.target.value;
            }}
          />
        );
      },
    },
  ];

  const previousWorkColumns = [
    {
      title: "No.",
      dataIndex: "index",
      width: "5%",
      render: (val, record, index) => {
        return <p className={styles.tableContentFields}>{index + 1}</p>;
      },
    },
    {
      title: "Company",
      dataIndex: "company",
      width: "20%",
      render: (val, record) => {
        return (
          <Input
            placeholder="Enter Company"
            className={styles.tableContentFields}
            style={{ width: "100%", border: "none" }}
            value={record.company}
            onChange={(e) => {
              record.company = e.target.value;
            }}
          />
        );
      },
    },
    {
      title: "Designation",
      dataIndex: "designation",
      width: "20%",
      render: (val, record) => {
        return (
          <Input
            placeholder="Enter Designation"
            className={styles.tableContentFields}
            style={{ width: "100%", border: "none" }}
            value={record.designation}
            onChange={(e) => {
              record.designation = e.target.value;
            }}
          />
        );
      },
    },
    {
      title: "Salary",
      dataIndex: "salary",
      width: "20%",
      render: (val, record) => {
        return (
          <Input
            placeholder="0.0"
            className={styles.tableContentFields}
            style={{ width: "100%", border: "none" }}
            value={record.salary}
            onChange={(e) => {
              record.salary = e.target.value;
            }}
          />
        );
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      width: "35%",
      render: (val, record) => {
        return (
          <Input.TextArea
            placeholder="Enter Address"
            className={styles.tableContentFields}
            style={{ width: "100%", border: "none" }}
            value={record.address}
            onChange={(e) => {
              record.address = e.target.value;
            }}
          />
        );
      },
    },
  ];

  const historyWorkColumns = [
    {
      title: "No.",
      dataIndex: "index",
      width: "5%",
      render: (val, record, index) => {
        return <p className={styles.tableContentFields}>{index + 1}</p>;
      },
    },
    {
      title: "Department",
      dataIndex: "department",
      width: "15%",
      render: (val, record) => {
        return (
          <Select
            className={styles.tableContentFields}
            size={selectSize}
            showSearch
            placeholder="Select Department"
            style={{ width: "100%", border: "none !important" }}
            value={record.department}
            options={departmentOptions}
            onChange={(value) => {
              record.department = value;
            }}
          />
        );
      },
    },
    {
      title: "Designation",
      dataIndex: "designation",
      width: "15%",
      render: (val, record) => {
        return (
          <Select
            className={styles.tableContentFields}
            size={selectSize}
            showSearch
            placeholder="Select Designation"
            style={{ width: "100%", border: "none !important" }}
            value={record.designation}
            options={designationOptions}
            onChange={(value) => {
              record.designation = value;
            }}
          />
        );
      },
    },
    {
      title: "From Date",
      dataIndex: "from_date",
      width: "20%",
      render: (val, record) => {
        return (
          <input
            type="date"
            className={styles.tableContentFields}
            placeholder="YYYY-MM-DD"
            style={{ width: "100%", border: "none" }}
            value={record.from_date}
            onChange={(e) => {
              const dateString = e.target.value;
              record.from_date = dateString;
            }}
          />
        );
      },
    },
    {
      title: "To Date",
      dataIndex: "to_date",
      width: "20%",
      render: (val, record) => {
        return (
          <input
            type="date"
            className={styles.tableContentFields}
            placeholder="YYYY-MM-DD"
            style={{ width: "100%", border: "none" }}
            value={record.to_date}
            onChange={(e) => {
              const dateString = e.target.value;
              record.to_date = dateString;
            }}
          />
        );
      },
    },
  ];

  const handleGetSalutationListing = async () => {
    const res = await getSalutationListing();
    if (res.status === 200) {
      const arr = [];
      const salutationList = res.data.data;

      for (let i = 0; i < salutationList.length; i++) {
        arr.push({
          value: salutationList[i].name,
          label: salutationList[i].name,
        });
      }
      setSalutationOptions(arr);
    }
  };

  const handleGetGenderListing = async () => {
    const res = await getGenderList();
    if (res.status === 200) {
      const arr = [];
      const genderList = res.data.data;

      for (let i = 0; i < genderList.length; i++) {
        arr.push({
          value: genderList[i].name,
          label: genderList[i].name,
        });
      }
      setGenderOptions(arr);
    }
  };

  const handleGetUserListing = async () => {
    const res = await getAllUserListing();
    if (res.status === 200) {
      const arr = [];
      const userList = res.data.data;
      for (let i = 0; i < userList.length; i++) {
        arr.push({
          value: userList[i].name,
          label: userList[i].name,
        });
      }
      setUserOptions(arr);
    }
  };

  const handleGetgetCollegeListing = async () => {
    const res = await getCollegeListing();
    if (res.status === 200) {
      const arr = [];
      const collegeList = res.data.data;

      for (let i = 0; i < collegeList.length; i++) {
        arr.push({
          value: collegeList[i].name,
          label: collegeList[i].name,
        });
      }
      setCollegeListOptions(arr);
    }
  };

  const handleGetDesignationListing = async () => {
    const res = await getDesignation();
    if (res.status === 200) {
      const arr = [];
      const designationList = res.data.data;
      for (let i = 0; i < designationList.length; i++) {
        arr.push({
          value: designationList[i].name,
          label: designationList[i].name,
        });
      }
      setDesignationOptions(arr);
    }
  };

  const handleGetEmployeeListing = async () => {
    const res = await getAllEmployeeListing();
    if (res.status === 200) {
      const arr = [];
      const employeeList = res.data.data;
      for (let i = 0; i < employeeList.length; i++) {
        arr.push({
          value: employeeList[i].name,
          label: `${employeeList[i].name}-${employeeList[i].first_name}`,
        });
      }
      setEmployeeListOptions(arr);
    }
  };

  const handleGetGradeListing = async () => {
    const res = await getEmployeeGrade();
    if (res.status === 200) {
      const arr = [];
      const gradeList = res.data.data;
      for (let i = 0; i < gradeList.length; i++) {
        arr.push({
          value: gradeList[i].name,
          label: gradeList[i].name,
        });
      }
      setGradeOptions(arr);
    }
  };

  const handleGetEmploymentTypeListing = async () => {
    const res = await getEmploymentType();
    if (res.status === 200) {
      const arr = [];
      const employmentTypeList = res.data.data;
      for (let i = 0; i < employmentTypeList.length; i++) {
        arr.push({
          value: employmentTypeList[i].name,
          label: employmentTypeList[i].name,
        });
      }
      setEmploymentTypeOptions(arr);
    }
  };

  const handleGetJobApplicantListing = async () => {
    const res = await getJobApplicantListing();
    if (res.status === 200) {
      const arr = [];
      const jobApplicantList = res.data.data;
      for (let i = 0; i < jobApplicantList.length; i++) {
        arr.push({
          value: jobApplicantList[i].name,
          label: jobApplicantList[i].name,
        });
      }
      setJobApplicantOptions(arr);
    }
  };

  const handleGetHolidayListing = async () => {
    const res = await getHolidayListing();
    if (res.status === 200) {
      const arr = [];
      const holidayList = res.data.data;
      for (let i = 0; i < holidayList.length; i++) {
        arr.push({
          value: holidayList[i].name,
          label: holidayList[i].name,
        });
      }
      setHolidayListOptions(arr);
    }
  };

  const handleGetShiftTypeListing = async () => {
    const res = await getAllShiftTypeListing();
    if (res.status === 200) {
      const arr = [];
      const shiftTypeList = res.data.data;
      for (let i = 0; i < shiftTypeList.length; i++) {
        arr.push({
          value: shiftTypeList[i].name,
          label: shiftTypeList[i].name,
        });
      }
      setShiftTypeOptions(arr);
    }
  };

  const handleGetCurrencyListing = async () => {
    const res = await getCurrencyListing();
    if (res.status === 200) {
      const arr = [];
      const currencyList = res.data.data;
      for (let i = 0; i < currencyList.length; i++) {
        arr.push({
          value: currencyList[i].name,
          label: currencyList[i].name,
        });
      }
      setCurrencyListOptions(arr);
    }
  };

  const handleGetCostCenterListing = async () => {
    const res = await getAllCostCenterListing();
    if (res.status === 200) {
      const arr = [];
      const costCenterList = res.data.data;
      for (let i = 0; i < costCenterList.length; i++) {
        arr.push({
          value: costCenterList[i].name,
          label: costCenterList[i].name,
        });
      }
      setCostCenterOptions(arr);
    }
  };

  const handleGetDepartmentListing = async () => {
    const res = await getDepartment();
    if (res.status === 200) {
      const arr = [];
      const departmentList = res.data.data;
      for (let i = 0; i < departmentList.length; i++) {
        arr.push({
          value: departmentList[i].name,
          label: departmentList[i].name,
        });
      }
      setDepartmentOptions(arr);
    }
  };

  const handleValidateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!values.first_name) {
      newErrors.first_name = "First Name is required";
      isValid = false;
    } else {
      newErrors.first_name = "";
    }

    if (!values.date_of_birth) {
      newErrors.date_of_birth = "Date of Birth is required";
      isValid = false;
    } else {
      newErrors.date_of_birth = "";
    }

    if (!values.gender) {
      newErrors.gender = "Gender is required";
      isValid = false;
    } else {
      newErrors.gender = "";
    }

    if (!values.date_of_joining) {
      newErrors.date_of_joining = "Date of Joining is required";
      isValid = false;
    } else {
      newErrors.date_of_joining = "";
    }

    if (!values.status) {
      newErrors.status = "Status is required";
      isValid = false;
    } else {
      newErrors.status = "";

      if (values.status === "Left" && !values.relieving_date) {
        newErrors.relieving_date = "Relieving Date is required";
        isValid = false;
      } else {
        newErrors.relieving_date = "";
      }
    }

    if (!values.company) {
      newErrors.company = "Company is required";
      isValid = false;
    } else {
      newErrors.company = "";
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSave = async () => {
    const isValid = handleValidateForm();
    if (isValid) {
      try {
        const payload = {
          ...values,
          doctype: "Employee",
          naming_series: "HR-EMP-",
        };
        const res = await createHrEmployee({ payload });
        console.log(res);
        if (res.status === 200) {
          toast.success("Employee created!");
          navigate(`/modules/hr/employee/${res.data.message.name}`);
        } else {
          toast.error("There was an issue while saving employee.");
        }
      } catch (error) {
        toast.error(error.response.data.exception);
      }
    }
  };

  useEffect(() => {
    handleGetSalutationListing();
    handleGetGenderListing();
    handleGetUserListing();
    handleGetgetCollegeListing();
    handleGetDesignationListing();
    handleGetEmployeeListing();
    handleGetGradeListing();
    handleGetEmploymentTypeListing();
    handleGetJobApplicantListing();
    handleGetHolidayListing();
    handleGetShiftTypeListing();
    handleGetCurrencyListing();
    handleGetCostCenterListing();
    handleGetDepartmentListing();
  }, []);

  const handleEmployeeNavigation = () => {
    navigate("/modules/hr/employee");
  };
  return (
    <>
      <MainHeader />
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/modules")}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/modules/hr")}
            >
              Hr {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={handleEmployeeNavigation}
            >
              Employee {" > "}
            </p>
            <p className={styles.breadCrumbsContent}>Create</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>New Employee</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div className={styles.button} onClick={handleEmployeeNavigation}>
                <p className={styles.buttonText}>Back</p>
              </div>
              <div className={styles.activeButton}>
                <p className={styles.activeButtonText} onClick={handleSave}>
                  Save
                </p>
              </div>
            </div>
          </div>
          <Tabs defaultActiveKey="1" className={styles.antTabsTab}>
            <TabPane tab="Overview" key="1">
              <div className={styles.formContainer}>
                <div>
                  <p className={styles.smallTitle}>Personal Details</p>
                  <div className={styles.divider}></div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Salutation </p>
                    <Select
                      size={selectSize}
                      showSearch
                      placeholder="Select"
                      value={values.salutation}
                      className={styles.selectInput}
                      options={salutationOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          salutation: value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>First Name *</p>
                    <Input
                      placeholder="Enter First Name"
                      value={values.first_name}
                      className={styles.fieldInput}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          first_name: e.target.value,
                        });
                        setErrors({
                          ...errors,
                          first_name: "",
                        });
                      }}
                    />
                    {errors.first_name && (
                      <p className={styles.errorMessage}>{errors.first_name}</p>
                    )}
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Middle Name </p>
                    <Input
                      placeholder="Enter Middle Name"
                      value={values.middle_name}
                      className={styles.fieldInput}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          middle_name: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Last Name </p>
                    <Input
                      placeholder="Enter Last Name"
                      value={values.last_name}
                      className={styles.fieldInput}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          last_name: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Gender *</p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      value={values.gender}
                      className={styles.selectInput}
                      options={genderOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          gender: value,
                        });
                        setErrors({
                          ...errors,
                          gender: "",
                        });
                      }}
                    />
                    {errors.gender && (
                      <p className={styles.errorMessage}>{errors.gender}</p>
                    )}
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Date Of Birth *</p>
                    <input
                      type="date"
                      value={values.date_of_birth}
                      placeholder="YYYY-MM-DD"
                      className={styles.dateInput}
                      onChange={(e) => {
                        const dateString = e.target.value;
                        const dob = new Date(dateString);
                        const retirementDate = new Date(dob);
                        retirementDate.setFullYear(
                          retirementDate.getFullYear() + 60
                        );
                        const formattedRetirementDate = retirementDate
                          .toISOString()
                          .split("T")[0];

                        setValues({
                          ...values,
                          date_of_birth: dateString,
                          date_of_retirement: formattedRetirementDate,
                        });

                        setErrors({
                          ...errors,
                          date_of_birth: "",
                        });
                      }}
                    />
                    {errors.date_of_birth && (
                      <p className={styles.errorMessage}>
                        {errors.date_of_birth}
                      </p>
                    )}
                  </div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Date Of Joining *</p>
                    <input
                      type="date"
                      value={values.date_of_joining}
                      placeholder="YYYY-MM-DD"
                      className={styles.dateInput}
                      onChange={(e) => {
                        const dateString = e.target.value;
                        setValues({ ...values, date_of_joining: dateString });
                        setErrors({
                          ...errors,
                          date_of_joining: "",
                        });
                      }}
                    />
                    {errors.date_of_joining && (
                      <p className={styles.errorMessage}>
                        {errors.date_of_joining}
                      </p>
                    )}
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Status *</p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      value={values.status}
                      className={styles.selectInput}
                      options={employeeStatusOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          status: value,
                        });
                        setErrors({
                          ...errors,
                          status: "",
                        });
                      }}
                    />
                    {errors.status && (
                      <p className={styles.errorMessage}>{errors.status}</p>
                    )}
                  </div>
                  <div className={styles.field}></div>
                </div>
                <Collapse accordion>
                  <Panel header="User Details" key="1">
                    <div className={styles.formContainer}>
                      <div className={styles.formRow}>
                        <div className={styles.field}>
                          <p className={styles.fieldLabel}>User ID </p>
                          <Select
                            size={selectSize}
                            showSearch
                            placeholder="Select"
                            value={values.user_id}
                            className={styles.selectInput}
                            options={userOptions}
                            onChange={(value) => {
                              setValues({
                                ...values,
                                user_id: value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className={styles.formRow}>
                        <Checkbox
                          checked={values.create_user_permission === 1}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            const newValue = isChecked ? 1 : 0;
                            setValues({
                              ...values,
                              create_user_permission: newValue,
                            });
                          }}
                        >
                          Create User Permission
                        </Checkbox>
                      </div>
                    </div>
                  </Panel>
                </Collapse>
                <div>
                  <p className={styles.smallTitle}>Company Details</p>
                  <div className={styles.divider}></div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Company *</p>
                    <Select
                      size={selectSize}
                      showSearch
                      placeholder="Select"
                      value={values.company}
                      className={styles.selectInput}
                      options={collegeListOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          company: value,
                        });
                        setErrors({
                          ...errors,
                          company: "",
                        });
                      }}
                    />
                    {errors.company && (
                      <p className={styles.errorMessage}>{errors.company}</p>
                    )}
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Designation </p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      showSearch
                      value={values.designation}
                      className={styles.selectInput}
                      options={designationOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          designation: value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Department </p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      showSearch
                      value={values.department}
                      className={styles.selectInput}
                      options={departmentOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          department: value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Employment Type </p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      value={values.employment_type}
                      className={styles.selectInput}
                      options={employmentTypeOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          employment_type: value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Reports To </p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      showSearch
                      value={values.reports_to}
                      className={styles.selectInput}
                      options={employeeListOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          reports_to: value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Grade </p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      value={values.grade}
                      className={styles.selectInput}
                      options={gradeOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          grade: value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Joining" key="2">
              <div className={styles.formContainer}>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Job Applicant </p>
                    <Select
                      size={selectSize}
                      showSearch
                      placeholder="Select"
                      value={values.job_applicant}
                      className={styles.selectInput}
                      options={jobApplicantOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          job_applicant: value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Confirmation Date </p>
                    <input
                      type="date"
                      value={values.final_confirmation_date}
                      placeholder="YYYY-MM-DD"
                      className={styles.dateInput}
                      onChange={(e) => {
                        const dateString = e.target.value;
                        setValues({
                          ...values,
                          final_confirmation_date: dateString,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Notice (days) </p>
                    <Input
                      placeholder="Enter Notice in days"
                      value={values.notice_number_of_days}
                      className={styles.fieldInput}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          notice_number_of_days: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Offer Date </p>
                    <input
                      type="date"
                      value={values.scheduled_confirmation_date}
                      placeholder="YYYY-MM-DD"
                      className={styles.dateInput}
                      onChange={(e) => {
                        const dateString = e.target.value;
                        setValues({
                          ...values,
                          scheduled_confirmation_date: dateString,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Contract End Date </p>
                    <input
                      type="date"
                      value={values.contract_end_date}
                      placeholder="YYYY-MM-DD"
                      className={styles.dateInput}
                      onChange={(e) => {
                        const dateString = e.target.value;
                        setValues({ ...values, contract_end_date: dateString });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Date Of Retirement </p>
                    <input
                      type="date"
                      value={values.date_of_retirement}
                      placeholder="YYYY-MM-DD"
                      className={styles.dateInput}
                      onChange={(e) => {
                        const dateString = e.target.value;
                        setValues({
                          ...values,
                          date_of_retirement: dateString,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Address & Contacts" key="3">
              <div className={styles.formContainer}>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Phone Number </p>
                    <Input
                      placeholder="+91-XXXXXXXXXX"
                      value={values.cell_number}
                      className={styles.fieldInput}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          cell_number: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Personal Email </p>
                    <Input
                      placeholder="Enter Your Personal Email"
                      value={values.personal_email}
                      className={styles.fieldInput}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          personal_email: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Prefered Contact Email </p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      value={values.prefered_contact_email}
                      className={styles.selectInput}
                      options={prefferedContactEmailOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          prefered_contact_email: value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Company Email </p>
                    <Input
                      placeholder="Enter Company Registered Email"
                      value={values.company_email}
                      className={styles.fieldInput}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          company: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.formRow}>
                  <Checkbox
                    checked={values.unsubscribed === 1}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      const newValue = isChecked ? 1 : 0;
                      setValues({
                        ...values,
                        unsubscribed: newValue,
                      });
                    }}
                  >
                    Unsubscribed
                  </Checkbox>
                </div>
                <Collapse accordion>
                  <Panel header="Address" key="1">
                    <div className={styles.formContainer}>
                      <div className={styles.formRow}>
                        <div className={styles.field}>
                          <p className={styles.fieldLabel}>
                            Current Address Is{" "}
                          </p>
                          <Select
                            size={selectSize}
                            placeholder="Select"
                            value={values.current_accommodation_type}
                            options={addressTypeOptions}
                            className={styles.selectInput}
                            onChange={(value) => {
                              setValues({
                                ...values,
                                current_accommodation_type: value,
                              });
                            }}
                          />
                        </div>
                        <div className={styles.field}>
                          <p className={styles.fieldLabel}>
                            Permanent Address Is{" "}
                          </p>
                          <Select
                            size={selectSize}
                            placeholder="Select"
                            value={values.permanent_accommodation_type}
                            options={addressTypeOptions}
                            className={styles.selectInput}
                            onChange={(value) => {
                              setValues({
                                ...values,
                                permanent_accommodation_type: value,
                              });
                            }}
                          />
                        </div>
                        <div className={styles.field}></div>
                      </div>
                      <div className={styles.formRow}>
                        <div className={styles.field}>
                          <p className={styles.fieldLabel}>Current Address </p>
                          <Input.TextArea
                            type="text"
                            placeholder="Enter Your Current Address..."
                            className={styles.textAreaInput}
                            value={values.current_address}
                            rows={5}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                current_address: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className={styles.field}>
                          <p className={styles.fieldLabel}>
                            Permanent Address{" "}
                          </p>
                          <Input.TextArea
                            type="text"
                            placeholder="Enter Your  Permanent Address..."
                            className={styles.textAreaInput}
                            value={values.permanent_address}
                            rows={5}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                permanent_address: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className={styles.field}></div>
                      </div>
                    </div>
                  </Panel>
                </Collapse>
                <div>
                  <p className={styles.smallTitle}>Emergency Contact</p>
                  <div className={styles.divider}></div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Emergency Contact Name</p>
                    <Input
                      placeholder="Enter Emergency Contact Name"
                      value={values.person_to_be_contacted}
                      className={styles.fieldInput}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          person_to_be_contacted: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Emergency Phone</p>
                    <Input
                      placeholder="+91-XXXXXXXXXX"
                      value={values.emergency_phone_number}
                      className={styles.fieldInput}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          emergency_phone_number: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Relation</p>
                    <Input
                      placeholder="Enter Relation"
                      value={values.relation}
                      className={styles.fieldInput}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          relation: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Attendance & Leaves" key="4">
              <div className={styles.formContainer}>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>
                      Attendance Device ID (Biometric/RG Tag ID)
                    </p>
                    <Input
                      placeholder="Enter ID"
                      value={values.attendance_device_id}
                      className={styles.fieldInput}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          attendance_device_id: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Holiday List </p>
                    <Select
                      size={selectSize}
                      showSearch
                      placeholder="Select"
                      value={values.holiday_list}
                      className={styles.selectInput}
                      options={holidayListOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          holiday_list: value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Default Shift </p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      value={values.default_shift}
                      className={styles.selectInput}
                      options={shiftTypeOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          default_shift: value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div>
                  <p className={styles.smallTitle}>Approvers</p>
                  <div className={styles.divider}></div>
                </div>

                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Leave Approver </p>
                    <Select
                      size={selectSize}
                      showSearch
                      placeholder="Select"
                      value={values.leave_approver}
                      className={styles.selectInput}
                      options={userOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          leave_approver: value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Salary" key="5">
              <div className={styles.formContainer}>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Cost To Company (CTC)</p>
                    <Input
                      placeholder="Enter CTC"
                      value={values.ctc}
                      className={styles.fieldInput}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          ctc: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Payroll Cost Center </p>
                    <Select
                      size={selectSize}
                      showSearch
                      placeholder="Select"
                      value={values.payroll_cost_center}
                      className={styles.selectInput}
                      options={costCenterOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          payroll_cost_center: value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Salary Currency </p>
                    <Select
                      size={selectSize}
                      showSearch
                      placeholder="Select"
                      value={values.salary_currency}
                      className={styles.selectInput}
                      options={currencyListOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          salary_currency: value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>PAN Number</p>
                    <Input
                      placeholder="Enter PAN ID"
                      value={values.pan_number}
                      className={styles.fieldInput}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          pan_number: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Salary Mode </p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      value={values.salary_mode}
                      className={styles.selectInput}
                      options={salaryModeOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          salary_mode: value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Provident Fund Account</p>
                    <Input
                      placeholder="Enter PF Account"
                      value={values.provident_fund_account}
                      className={styles.fieldInput}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          provident_fund_account: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                {values.salary_mode === "Bank" && (
                  <>
                    <div>
                      <p className={styles.smallTitle}>Bank Details</p>
                      <div className={styles.divider}></div>
                    </div>
                    <div className={styles.formRow}>
                      <div className={styles.field}>
                        <p className={styles.fieldLabel}>Bank Name </p>
                        <Input
                          placeholder="Enter Bank Name"
                          value={values.bank_name}
                          className={styles.fieldInput}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              bank_name: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className={styles.field}>
                        <p className={styles.fieldLabel}>Bank A/C No. </p>
                        <Input
                          placeholder="Enter Bank A/C Number"
                          value={values.bank_ac_no}
                          className={styles.fieldInput}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              bank_ac_no: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className={styles.field}>
                        <p className={styles.fieldLabel}>IFSC Code </p>
                        <Input
                          placeholder="Enter IFSC Code"
                          value={values.ifsc_code}
                          className={styles.fieldInput}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              ifsc_code: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.formRow}>
                      <div className={styles.field}>
                        <p className={styles.fieldLabel}>MICR Code </p>
                        <Input
                          placeholder="Enter MICR Code"
                          value={values.micr_code}
                          className={styles.fieldInput}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              micr_code: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className={styles.field}>
                        <p className={styles.fieldLabel}>IBAN </p>
                        <Input
                          placeholder="Enter IBAN"
                          value={values.iban}
                          className={styles.fieldInput}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              iban: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className={styles.field}></div>
                    </div>
                  </>
                )}
              </div>
            </TabPane>
            <TabPane tab="Personal" key="6">
              <div className={styles.formContainer}>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Marital Status </p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      value={values.marital_status}
                      className={styles.selectInput}
                      options={maritalStatusOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          marital_status: value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Blood Group </p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      value={values.blood_group}
                      className={styles.selectInput}
                      options={bloodGroupOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          blood_group: value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}></div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Family Background</p>
                    <Input.TextArea
                      type="text"
                      placeholder="Enter Your Family Background..."
                      className={styles.textAreaInput}
                      value={values.family_background}
                      rows={5}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          family_background: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Health Details </p>
                    <Input.TextArea
                      type="text"
                      placeholder="Enter Your Health Details..."
                      className={styles.textAreaInput}
                      value={values.health_details}
                      rows={5}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          health_details: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}></div>
                </div>
                <div>
                  <p className={styles.smallTitle}>Passport Details</p>
                  <div className={styles.divider}></div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Passport Number </p>
                    <Input
                      placeholder="Enter Your Passport Number"
                      value={values.passport_number}
                      className={styles.fieldInput}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          passport_number: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Date Of Issue </p>
                    <input
                      type="date"
                      value={values.date_of_issue}
                      placeholder="YYYY-MM-DD"
                      className={styles.dateInput}
                      onChange={(e) => {
                        const dateString = e.target.value;
                        setValues({ ...values, date_of_issue: dateString });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Valid Upto</p>
                    <input
                      type="date"
                      value={values.valid_upto}
                      placeholder="YYYY-MM-DD"
                      className={styles.dateInput}
                      onChange={(e) => {
                        const dateString = e.target.value;
                        setValues({ ...values, valid_upto: dateString });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Place Of Issue </p>
                    <Input
                      placeholder="Enter Middle Name"
                      value={values.place_of_issue}
                      className={styles.fieldInput}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          place_of_issue: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Profile" key="7">
              <div className={styles.formContainer}>
                <div className={styles.formRow}>
                  <div className={styles.textareaQuill}>
                    <p className={styles.fieldLabel}>Bio/Cover Letter </p>
                    <ReactQuill
                      style={{ height: "300px" }}
                      value={values.bio}
                      onChange={(value) => {
                        setValues({ ...values, bio: value });
                      }}
                    />
                  </div>
                </div>
                <Collapse accordion>
                  <Panel header=" Educational Qualification" key="1">
                    <div className={styles.formContainer}>
                      <div className={styles.formRow}>
                        <div className={styles.tableField}>
                          <p className={styles.fieldLabel}>Education</p>
                          <Table
                            rowSelection={{
                              type: "checkbox",
                              ...rowSelection1,
                            }}
                            columns={educationColumns}
                            dataSource={values.education}
                            size="small"
                            pagination={false}
                          />
                        </div>
                      </div>
                      <div className={styles.fieldButtons}>
                        {selectedRowKeys1.length > 0 && (
                          <div
                            className={styles.deleteRow}
                            onClick={handleDeleteEducation}
                          >
                            Delete
                          </div>
                        )}
                        <div
                          className={styles.addRow}
                          onClick={handleAddEducation}
                        >
                          +
                        </div>
                      </div>
                    </div>
                  </Panel>
                </Collapse>
                <Collapse accordion>
                  <Panel header=" Previous Work Experience" key="2">
                    <div className={styles.formContainer}>
                      <div className={styles.formRow}>
                        <div className={styles.tableField}>
                          <p className={styles.fieldLabel}>
                            External Work History
                          </p>
                          <Table
                            rowSelection={{
                              type: "checkbox",
                              ...rowSelection2,
                            }}
                            columns={previousWorkColumns}
                            dataSource={values.external_work_history}
                            size="small"
                            pagination={false}
                          />
                        </div>
                      </div>
                      <div className={styles.fieldButtons}>
                        {selectedRowKeys2.length > 0 && (
                          <div
                            className={styles.deleteRow}
                            onClick={handleDeletePreviousWork}
                          >
                            Delete
                          </div>
                        )}
                        <div
                          className={styles.addRow}
                          onClick={handleAddPreviousWork}
                        >
                          +
                        </div>
                      </div>
                    </div>
                  </Panel>
                </Collapse>
                <Collapse accordion>
                  <Panel header="History In Company" key="3">
                    <div className={styles.formContainer}>
                      <div className={styles.formRow}>
                        <div className={styles.tableField}>
                          <p className={styles.fieldLabel}>
                            Internal Work History
                          </p>
                          <Table
                            rowSelection={{
                              type: "checkbox",
                              ...rowSelection3,
                            }}
                            columns={historyWorkColumns}
                            dataSource={values.internal_work_history}
                            size="small"
                            pagination={false}
                          />
                        </div>
                      </div>
                      <div className={styles.fieldButtons}>
                        {selectedRowKeys3.length > 0 && (
                          <div
                            className={styles.deleteRow}
                            onClick={handleDeleteHistoryWork}
                          >
                            Delete
                          </div>
                        )}
                        <div
                          className={styles.addRow}
                          onClick={handleAddHistoryWork}
                        >
                          +
                        </div>
                      </div>
                    </div>
                  </Panel>
                </Collapse>
              </div>
            </TabPane>
            <TabPane tab="Exit" key="8">
              <div className={styles.formContainer}>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>
                      Resignation Letter Date{" "}
                    </p>
                    <input
                      type="date"
                      value={values.resignation_letter_date}
                      placeholder="YYYY-MM-DD"
                      className={styles.dateInput}
                      onChange={(e) => {
                        const dateString = e.target.value;
                        setValues({
                          ...values,
                          resignation_letter_date: dateString,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Exit Interview Held On </p>
                    <input
                      type="date"
                      value={values.held_on}
                      placeholder="YYYY-MM-DD"
                      className={styles.dateInput}
                      onChange={(e) => {
                        const dateString = e.target.value;
                        setValues({ ...values, held_on: dateString });
                      }}
                    />
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Leave Encashed? </p>
                    <Select
                      size={selectSize}
                      placeholder="Select"
                      value={values.leave_encashed}
                      className={styles.selectInput}
                      options={leaveEncashedOptions}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          leave_encashed: value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>Relieving Date </p>
                    <input
                      type="date"
                      value={values.relieving_date}
                      placeholder="YYYY-MM-DD"
                      className={styles.dateInput}
                      onChange={(e) => {
                        const dateString = e.target.value;
                        setValues({ ...values, relieving_date: dateString });
                        setErrors({
                          ...errors,
                          relieving_date: "",
                        });
                      }}
                    />
                    {errors.relieving_date && (
                      <p className={styles.errorMessage}>
                        {errors.relieving_date}
                      </p>
                    )}
                  </div>
                  <div className={styles.field}>
                    <p className={styles.fieldLabel}>New Workplace </p>
                    <Input
                      placeholder="New Workplace"
                      value={values.new_workplace}
                      className={styles.fieldInput}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          new_workplace: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {values.leave_encashed === "Yes" ? (
                    <div className={styles.field}>
                      <p className={styles.fieldLabel}>Encashment Date </p>
                      <input
                        type="date"
                        value={values.encashment_date}
                        placeholder="YYYY-MM-DD"
                        className={styles.dateInput}
                        onChange={(e) => {
                          const dateString = e.target.value;
                          setValues({
                            ...values,
                            encashment_date: dateString,
                          });
                        }}
                      />
                    </div>
                  ) : (
                    <div className={styles.field}></div>
                  )}
                </div>
                <div>
                  <p className={styles.smallTitle}>FeedBack</p>
                  <div className={styles.divider}></div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.textarea}>
                    <p className={styles.fieldLabel}>Reason For Leaving </p>
                    <Input.TextArea
                      type="text"
                      placeholder="Enter Your Reason Here..."
                      className={styles.textAreaInput}
                      value={values.reason_for_leaving}
                      rows={5}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          reason_for_leaving: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.textarea}>
                    <p className={styles.fieldLabel}>FeedBack </p>
                    <Input.TextArea
                      type="text"
                      placeholder="Enter Your FeedBack here..."
                      className={styles.textAreaInput}
                      value={values.feedback}
                      rows={5}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          feedback: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </TabPane>
          </Tabs>
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default AddNewEmployee;

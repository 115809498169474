import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import {
  Grid,
  Typography,
  TextField,
} from "iiht-b2a-ui-components/build/components";
import TabContext from "@mui/lab/TabContext";
import ReactQuill from "react-quill";
import { getTemplate, sendEmail, selectTemplate, getEmailList, getSentEmail, getReceiveEmail, } from "../../modules/application";
import { getStudentDetails } from "../../modules/studentApplication";

export default function LeadEmails({ uniqueId }) {
  const [selectedTab, setSelectedTab] = useState("all");
  const [open, setOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [description, setDescription] = useState("");
  const [template, setTemplate] = useState([]);
  const [email, setEmail] = useState({});
  const [sentEmail, setSentEmail] = useState({});
  const [receiveEmail, setReceiveEmail] = useState({})
  const [data, setData] = useState({
    email: "",
  });
  const [formData, setFormData] = useState({
    recipients: "",
    subject: "",
    content: "",
    send_email: "1",
    print_html: "",
    send_me_a_copy: "0",
    print_format: "",
    attachments: [],
    read_receipt: "0",
    print_letterhead: "1",
    send_after: "",
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFormData({
      recipients: "",
      subject: "",
      content: "",
      send_email: "1",
      print_html: "",
      send_me_a_copy: "0",
      print_format: "",
      attachments: [],
      read_receipt: "0",
      print_letterhead: "1",
      send_after: "",
    });
    setSelectedTemplate("");
    setDescription("");
    setOpen(false);
  };

  const handleCancel = () => {
    setFormData({
      recipients: "",
      subject: "",
      content: "",
      send_email: "1",
      print_html: "",
      send_me_a_copy: "0",
      print_format: "",
      attachments: [],
      read_receipt: "0",
      print_letterhead: "1",
      send_after: "",
    });
    setSelectedTemplate("");
    setDescription("");
    handleClose();
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const res = await getTemplate({ doctype: "Lead" });
      if (res.status === 200) {
        setTemplate(res?.data.data);
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  const fetchDescription = async (selectedTemplate) => {
    try {
      const res = await selectTemplate(selectedTemplate);
      if (res.status === 200) {
        setFormData({ ...formData, content: res?.data.data.response });
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  useEffect(() => {
    handleGetLeadDetails();
  }, []);

  const handleGetLeadDetails = async () => {
    const res = await getStudentDetails(uniqueId);
    setData({
      email: res?.data.data.student_email_id,
    });
    ReceiveEmail(res?.data.data.student_email_id);
  }

  const emailList = async (uniqueId) => {
    try {
      const res = await getEmailList(uniqueId);
      if (res.status === 200) {
        setEmail(res?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  useEffect(() => {
    emailList(uniqueId);
    SentEmail(uniqueId);
  }, [uniqueId]);

  useEffect(() => {
    ReceiveEmail(data.email);
  }, [data.email])

  const SentEmail = async (uniqueId) => {
    try {
      const res = await getSentEmail(uniqueId);
      if (res.status === 200) {
        setSentEmail(res?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  }

  const ReceiveEmail = async (email_id) => {
    try {
      const res = await getReceiveEmail(email_id);
      if (res.status === 200) {
        setReceiveEmail(res?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      doctype: "Student Applicant",
      name: uniqueId,
      recipients: data.email,
      subject: formData.subject,
      content: formData.content,
      send_email: "1",
      print_html: "",
      send_me_a_copy: "0",
      print_format: "",
      attachments: [],
      read_receipt: "0",
      print_letterhead: "1",
      send_after: "",
    };

    try {
      const res = await sendEmail(payload);
      if (res.status === 200) {
        handleClose();
        emailList(uniqueId);
        SentEmail(uniqueId);
      } else {
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

    return {
      time: formattedTime,
      date: formattedDate,
    };
  };

  const emailRows = Array.isArray(email)
    ? email.map((emailItem, index) => ({
      id: index + 1,
      email: stripHtmlTags(emailItem.content),
      ...formatDate(emailItem.communication_date),
    }))
    : [];

  const sentRows = Array.isArray(sentEmail)
    ? sentEmail.map((emailItem, index) => ({
      id: index + 1,
      email: stripHtmlTags(emailItem.content),
      ...formatDate(emailItem.communication_date),
    }))
    : [];

  const receiveRows = Array.isArray(receiveEmail)
    ? receiveEmail.map((emailItem, index) => ({
      id: index + 1,
      email: stripHtmlTags(emailItem.content),
      ...formatDate(emailItem.communication_date),
    }))
    : [];

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <Box
      sx={{ width: "100%", typography: "body1" }}
    >
      <div className="rightBtn">
        {open ? (
          <>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCancel}
              style={{
                marginRight: "8px",
                backgroundColor: "white",
                color: "gray",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleFormSubmit}
            >
              Send
            </Button>
          </>
        ) : (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleOpen}
          >
            Compose Email
          </Button>
        )}
      </div>
      <Box sx={{ height: 500, width: "100%", marginTop: "38px" }}>
        {!open && (
          <Tabs
            value={selectedTab}
            onChange={(event, newValue) => setSelectedTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="All Emails" value="all" />
            <Tab label="Sent" value="sent" />
            <Tab label="Received" value="received" />
          </Tabs>
        )}
        {open ? (
          <form onSubmit={handleFormSubmit}>
            <>
              <Grid item xs={2} sm={4} md={4}>
                <Typography variant="body1">To</Typography>
                <TextField
                  required
                  id="outlined-required"
                  name="fullName"
                  placeholder="Enter Email of Recipient"
                  value={data.email}
                  disabled
                  onChange={(e) =>
                    setFormData({ ...formData, recipients: data.email })
                  }
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography variant="body1">Subject</Typography>
                <TextField
                  required
                  id="outlined-required"
                  name="fullName"
                  placeholder="Enter Subject"
                  value={formData.subject}
                  onChange={(e) =>
                    setFormData({ ...formData, subject: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={2} sm={4} md={4}>
                <Typography variant="body1">Select Template</Typography>
                <select
                  label=""
                  id="select2"
                  value={selectedTemplate}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setSelectedTemplate(selectedValue);
                    fetchDescription(selectedValue);
                  }}
                >
                  <option value="" disabled selected>
                    Select Template
                  </option>
                  {template.map((template) => (
                    <option key={template.id} value={template.name}>
                      {template.name}
                    </option>
                  ))}
                </select>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography>Description:</Typography>
                <ReactQuill
                  value={formData.content}
                  onChange={(value) =>
                    setFormData({ ...formData, content: value })
                  }
                />
              </Grid>
            </>
          </form>
        ) : (
          <>
            {selectedTab === "all" && (
              <div className="email-list">
                {emailRows.map((row) => (
                  <div key={row.id}>
                    <div className="d-flex justify-content-between">
                      <p className="flex-grow-1">
                        {truncateText(row.email, 130)}
                      </p>
                      <div className="d-flex justify-content-between">
                        <p className="mr-3">{row.date}</p>
                        <p>{row.time}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {selectedTab === "sent" && (
              <div className="email-list">
                {sentRows.map((row) => (
                  <div key={row.id}>
                    <div className="d-flex justify-content-between">
                      <p className="flex-grow-1">
                        {truncateText(row.email, 130)}
                      </p>
                      <div className="d-flex">
                        <p className="mr-3">{row.date}</p>
                        <p>{row.time}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {selectedTab === "received" && (
              <div className="email-list">
                {receiveRows.map((row) => (
                  <div key={row.id}>
                    <div className="d-flex justify-content-between">
                      <p className="flex-grow-1">
                        {truncateText(row.email, 130)}
                      </p>
                      <div className="d-flex justify-content-between">
                        <p className="mr-3">{row.date}</p>
                        <p>{row.time}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}
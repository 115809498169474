import APIs from "../api";
import axiosInstance from "../translator";

export const getEvents = async ({ id }) => {
  try {
    const filters = JSON.stringify([
      ["Event Participants", "reference_docname", "=", id],
    ]);
    const fields = JSON.stringify(["*"]);
    const res = await axiosInstance.get(
      `${APIs.getEventList}?fields=${fields}&filters=${filters}`
    );
    return res;
  } catch (error) {
    console.log("Error getting events listing", error);
    return error.response;
  }
};


export const createEvent = async ({ payload }) => {
  try {
    console.log("gh: ", APIs.createEvent);
    const res = await axiosInstance.post(APIs.createEvent, payload);
    return res;
  } catch (error) {
    console.log("Error creating events", error);
    console.log("Error creating events", error);
    return error.response;
  }
};

export const getTemplate = async ({ doctype }) => {
  try {
    const filters = JSON.stringify([["custom_doctype", "=", doctype]])
    const res = await axiosInstance.get(`${APIs.getTemplate}?filters=${filters}`);
    return res;
  } catch (error) {
    console.log("Error getting Templates Details", error);
    return error.response;
  }
};

export const selectTemplate = async (template) => {
  try {
    const res = await axiosInstance.get(`${APIs.selectTemplate}/${template}`);
    return res;
  } catch (error) {
    console.log("Error getting Templates Details", error);
    return error.response;
  }
};

export const sendEmail = async (payload) => {
  try {
    const res = await axiosInstance.post(APIs.sendEmail, payload);
    return res;
  } catch (error) {
    console.log("Error sending mail ", error);
    return error.response;
  }
};

export const getEmailList = async (uniqueId) => {
  try {
    const fields = JSON.stringify(["*"]);
    const filters = JSON.stringify([["reference_name", "=", uniqueId]]);
    const res = await axiosInstance.get(
      `${APIs.getEmailList}?fields=${fields}&filters=${filters}&order_by=creation%20desc`
    );
    return res;
  } catch (error) {
    console.log("Error getting programs group listing ", error);
    return error.response;
  }
};

export const getSentEmail = async (uniqueId) => {
  try {
    const fields = JSON.stringify(["*"]);
    const filters = JSON.stringify([
      ["sent_or_received", "in", ["Sent"]],
      ["reference_name", "=", uniqueId],
    ]);
    const res = await axiosInstance.get(
      `${APIs.getSentEmail}?fields=${fields}&filters=${filters}&order_by=creation%20desc`
    );
    return res;
  } catch (error) {
    console.log("Error getting programs group listing ", error);
  }
};

export const getReceiveEmail = async (email) => {
  try {
    const fields = JSON.stringify(["*"]);
    const filters = JSON.stringify([
      ["sent_or_received", "=", "Received"],
      ["sender", "=", email],
    ]);
    const res = await axiosInstance.get(
      `${APIs.getReceiveEmail}?fields=${fields}&filters=${filters}&order_by=creation%20desc`
    );
    return res;
  } catch (error) {
    console.log("Error getting programs group listing ", error);
  }
};

export const createNote = async ({ payload }) => {
  try {
    const res = await axiosInstance.post(APIs.createNotes, payload);
    return res;
  } catch (error) {
    console.log("Error creating notes", error);
    return error.response;
  }
};

export const getNote = async ({ id }) => {
  try {
    const filters = "Student Applicant";
    const res = await axiosInstance.get(
      `${APIs.createNote}?doctype=${filters}&name=${id}`
    );
    return res;
  } catch (error) {
    console.log("Error creating notes", error);
    return error.response;
  }
};

export const getPaymentList = async (uniqueId) => {
    try {
      const filters = uniqueId;
      const res = await axiosInstance.get(
        `${APIs.getPaymentList}?student_applicant=${filters}`
      );
      return res;
    } catch (error) {
      console.log("Error getting programs group listing ", error);
    }
  };

  export const getWorkFlow = async () => {
    try {
      const doctype = "Student Applicant"
      const res = await axiosInstance.get(
        `${APIs.getWorkflow}?document_type=${doctype}`
      );
      return res;
    } catch (error) {
      console.log("Error getting programs group listing ", error);
    }
  };

  export const getApplicationListing = async (startDate = null, endDate = null) => {
    try {
      let fieldFilters = [];
      if (startDate && endDate) {
        fieldFilters.push(["creation", "between", [startDate, endDate]]);
      }
      const fields = JSON.stringify(["*"]);
  
      const res = await axiosInstance.get(
        `${APIs.getApplicationsList}?filters=${JSON.stringify(
          fieldFilters
        )}&fields=${fields}&limit="*"&order_by=creation%20desc`
      );
      return res;
    } catch (error) {
      console.log("Error getting application listing", error);
    }
  };
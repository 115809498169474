import React, { useEffect, useState } from "react";
import { Select, Input } from "antd";
import useStyles from "./style";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import MainHeader from "components/common/MainHeader";
import MainFooter from "components/common/MainFooter";
import { useDispatch, useSelector } from "react-redux";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { setActiveSidebar } from "slices/academicsSlice";
import { getCollegeListing } from "modules/finance";
import { employeeStautsTypeOptions } from "../../config";
import { Checkbox } from "antd";
import { toast } from "react-toastify";
import {
  createEmployeeAttendance,
  getAllEmployeeListing,
  getAllLeaveTypeListing,
  getAllShiftTypeListing,
} from "modules/academics";

const AddNewEmployeeAttendance = () => {
  const [values, setValues] = useState({
    employee: "",
    employee_name: "",
    company: "",
    date: new Date().toISOString().split("T")[0],
    status: "",
    shift: "",
    leave_type: "",
    late_entry: 0,
    early_exit: 0,
  });
  const [errors, setErrors] = useState({
    employee: "",
    employee_name: "",
    company: "",
    date: "",
    status: "",
    leave_type: "",
    shift: "",
  });
  const selectSize = "middle";
  const styles = useStyles();
  const [employeeIdOptions, setEmployeeIdOptions] = useState([]);
  const [employeeIdList, setEmployeeIdList] = useState([]);
  const [collegeListOptions, setCollegeListOptions] = useState([]);
  const [shiftTypeOptions, setShiftTypeOptions] = useState([]);
  const [leaveTypeOptions, setLeaveTypeOptions] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);

  const handleStudentAttendanceNavigation = () => {
    dispatch(setActiveSidebar({ activeSidebar: 3 }));
    navigate("/modules/hr");
  };

  const handleGetEmployeeListing = async () => {
    const res = await getAllEmployeeListing();
    if (res.status === 200) {
      const arr = [];
      const employeeList = res.data.data;
      for (let i = 0; i < employeeList.length; i++) {
        arr.push({
          value: employeeList[i].name,
          label: `${employeeList[i].name} - ${employeeList[i].employee_name}`,
        });
      }
      setEmployeeIdList(employeeList);
      setEmployeeIdOptions(arr);
    }
  };

  const handleGetgetCollegeListing = async () => {
    const res = await getCollegeListing();
    if (res.status === 200) {
      const arr = [];
      const collegeList = res.data.data;
      for (let i = 0; i < collegeList.length; i++) {
        arr.push({
          value: collegeList[i].name,
          label: collegeList[i].name,
        });
      }
      setCollegeListOptions(arr);
    }
  };

  const handleGetLeaveTypeListing = async () => {
    const res = await getAllLeaveTypeListing();
    if (res.status === 200) {
      const arr = [];
      const leaveList = res.data.data;
      for (let i = 0; i < leaveList.length; i++) {
        arr.push({
          value: leaveList[i].name,
          label: leaveList[i].name,
        });
      }
      setLeaveTypeOptions(arr);
    }
  };

  const handleGetAllShiftTypeListing = async () => {
    const res = await getAllShiftTypeListing();
    if (res.status === 200) {
      const arr = [];
      const shiftList = res.data.data;
      for (let i = 0; i < shiftList.length; i++) {
        arr.push({
          value: shiftList[i].name,
          label: shiftList[i].name,
        });
      }
      setShiftTypeOptions(arr);
    }
  };

  const handleValidateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!values.employee) {
      newErrors.employee = "Employee ID is required";
      isValid = false;
    } else {
      newErrors.employee = "";
    }

    if (!values.company) {
      newErrors.company = "Institution is required";
      isValid = false;
    } else {
      newErrors.company = "";
    }

    if (!values.date) {
      newErrors.date = "Date is required";
      isValid = false;
    } else {
      newErrors.date = "";
    }

    if (!values.status) {
      newErrors.status = "Status is required";
      isValid = false;
    } else {
      newErrors.status = "";
    }

    if (
      (values.status === "On Leave" || values.status === "Half Day") &&
      !values.leave_type
    ) {
      newErrors.leave_type = "Leave type is required";
      isValid = false;
    } else {
      newErrors.leave_type = "";
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSave = async () => {
    const isValid = handleValidateForm();

    if (isValid) {
      try {
        const payload = {
          ...values,
          doctype: "Attendance",
          docstatus: 0,
          employee: values.employee,
          employee_name: values.employee_name,
          status: values.status,
          attendance_date: values.date,
          shift: values.shift,
          company: values.company,
          late_entry: values.late_entry,
          early_exit: values.early_exit,
          leave_type: values.leave_type,
        };
        const res = await createEmployeeAttendance({ payload });
        console.log(res);
        if (res.status === 200) {
          toast.success("Employee Attendance created!");
          navigate(`/modules/hr/employee-attendance/${res.data.message.name}`);
        } else {
          toast.error("There was an issue while saving employee attendance.");
        }
      } catch (error) {
        toast.error(error.response.data.exception);
      }
    }
  };

  useEffect(() => {
    handleGetEmployeeListing();
    handleGetgetCollegeListing();
    handleGetAllShiftTypeListing();
    handleGetLeaveTypeListing();
  }, []);
  return (
    <>
      <MainHeader />
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/dashboard")}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/modules/hr")}
            >
              Hr{" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={handleStudentAttendanceNavigation}
            >
              Attendance {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/modules/hr/employee-attendance")}
            >
              Employee Attendance {" > "}
            </p>
            <p className={styles.breadCrumbsContent}>Create</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>New Employee Attendance</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div
                className={styles.button}
                onClick={() => navigate("/modules/hr/employee-attendance")}
              >
                <p className={styles.buttonText}>Back</p>
              </div>
              <div className={styles.activeButton}>
                <p className={styles.activeButtonText} onClick={handleSave}>
                  Save
                </p>
              </div>
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Employee Id *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.employee}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  className={styles.selectInput}
                  options={employeeIdOptions}
                  onChange={(selectedOption) => {
                    const selectedStudentId = selectedOption;
                    const selectedStudent = employeeIdList.find(
                      (student) => student.name === selectedStudentId
                    );
                    setValues({
                      ...values,
                      employee: selectedOption,
                      employee_name: selectedStudent.employee_name,
                      company: selectedStudent.company,
                    });
                    setErrors({
                      ...errors,
                      employee: "",
                    });
                  }}
                />

                {errors.employee && (
                  <p className={styles.errorMessage}>{errors.employee}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Employee Name *</p>
                <Input
                  placeholder="ex Text"
                  disabled={true}
                  value={values.employee_name}
                  className={styles.fieldInput}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      employee_name: e.target.value,
                    });
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Institution *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  className={styles.selectInput}
                  options={collegeListOptions}
                  value={values.company}
                  onChange={(val) => {
                    setValues({
                      ...values,
                      company: val,
                    });
                    setErrors({
                      ...errors,
                      company: "",
                    });
                  }}
                />
                {errors.company && (
                  <p className={styles.errorMessage}>{errors.company}</p>
                )}
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}> Date *</p>
                <input
                  type="date"
                  value={values.date}
                  placeholder="YYYY-MM-DD"
                  className={styles.dateInput}
                  onChange={(e) => {
                    const dateString = e.target.value;
                    setValues({ ...values, date: dateString });
                    setErrors({
                      ...errors,
                      date: "",
                    });
                  }}
                />
                {errors.date && (
                  <p className={styles.errorMessage}>{errors.date}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Status *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  className={styles.selectInput}
                  options={employeeStautsTypeOptions}
                  value={values.status}
                  onChange={(val) => {
                    setValues({
                      ...values,
                      status: val,
                    });
                    setErrors({
                      ...errors,
                      status: "",
                    });
                  }}
                />
                {errors.status && (
                  <p className={styles.errorMessage}>{errors.status}</p>
                )}
              </div>
              {values.status === "On Leave" || values.status === "Half Day" ? (
                <div className={styles.field}>
                  <p className={styles.fieldLabel}> Leave Type *</p>
                  <Select
                    size={selectSize}
                    placeholder="Select"
                    className={styles.selectInput}
                    options={leaveTypeOptions}
                    value={values.leave_type}
                    onChange={(val) => {
                      setValues({
                        ...values,
                        leave_type: val,
                      });
                      setErrors({
                        ...errors,
                        leave_type: "",
                      });
                    }}
                  />
                  {errors.leave_type && (
                    <p className={styles.errorMessage}>{errors.leave_type}</p>
                  )}
                </div>
              ) : (
                <div className={styles.field}></div>
              )}
            </div>
            <div>
              <p className={styles.smallTitle}>Details</p>
              <div className={styles.divider}></div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Shift </p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  className={styles.selectInput}
                  options={shiftTypeOptions}
                  value={values.shift}
                  onChange={(val) => {
                    setValues({
                      ...values,
                      shift: val,
                    });
                  }}
                />
              </div>
            </div>
            <Checkbox
              checked={values.late_entry === 1}
              onChange={(e) => {
                const isChecked = e.target.checked;
                const newValue = isChecked ? 1 : 0;
                setValues({
                  ...values,
                  late_entry: newValue,
                });
              }}
            >
              Late Entry
            </Checkbox>
            <Checkbox
              checked={values.early_exit === 1}
              onChange={(e) => {
                const isChecked = e.target.checked;
                const newValue = isChecked ? 1 : 0;
                setValues({
                  ...values,
                  early_exit: newValue,
                });
              }}
            >
              Early Exit
            </Checkbox>
          </div>
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default AddNewEmployeeAttendance;

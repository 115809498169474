import React from "react";
import { Route, Routes } from "react-router-dom";
import { ErrorBoundary } from "./ErrorBoundary";
import FallBackScreen from "./FallBackScreen";
import { routes } from "./config/router.config";
import { theme } from "./themes";
import { ToastContainer } from "react-toastify";
import { DialogConnector } from "../src/ui/DialogConnector";
import { BottomSheetConnector } from "../src/ui/BottomSheetConnector";
import {
  ThemeProvider,
  StyledEngineProvider,
} from "iiht-b2a-ui-components/build/components/Styles";
import CssBaseline from "iiht-b2a-ui-components/build/components/CssBaseline";
import "./App.scss";

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DialogConnector />
        <BottomSheetConnector />
        <ToastContainer />
        <ErrorBoundary fallback={<FallBackScreen />}>
          <Routes>
            {routes.map((route) => {
              const { name, path, element: Element } = route;
              return <Route key={name} path={path} element={<Element />} />;
            })}
          </Routes>
        </ErrorBoundary>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;

import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormControl, TextField } from "iiht-b2a-ui-components/build/components";

const FormDatepicker = React.memo(
  ({
    name,
    rules,
    fullWidth,
    className,
    inputClassName,
    required = false,
    multiline,
    rows,
    size = "small",
    color = "secondary",
    isAsterisk,
    label = "",
    readOnly = false,
    margin = "normal",
    onInputChange = () => {},
    ...rest
  }) => {
    const {
      control,
      formState: { errors },
      trigger,
    } = useFormContext();
    const { getFieldState } = control;

    return (
      <FormControl margin={margin} fullWidth={fullWidth} className={className} size={size}>
        <Controller
          name={name}
          control={control}
          rules={rules}
          noRef={true}
          render={({ field }) => {
            const { name, value = "", onChange, onBlur } = field;
            const { invalid, error } = getFieldState(name);
            return (
              <TextField
                type="date"
                name={name}
                required={required}
                value={value || ""}
                onChange={(e) => {
                  onChange(e);
                  onInputChange(e);
                  trigger(name);
                }}
                onBlur={onBlur}
                error={error ? true : false}
                aria-invalid={invalid ? "true" : "false"}
                helperText={error?.message}
                size={size}
                color={color}
                multiline={multiline}
                rows={rows}
                inputProps={{ maxLength: 10000, readOnly: readOnly }}
                className={inputClassName}
                InputLabelProps={{ shrink: true }}
                label={`${label ?? ""}${isAsterisk ? "*" : ""}`}
                {...rest}
              />
            );
          }}
        ></Controller>
      </FormControl>
    );
  }
);
export default FormDatepicker;

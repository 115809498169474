import React, { useEffect, useState } from "react";
import { Select, Input } from "antd";
import useStyles from "./style";
import { useNavigate, useParams } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import MainHeader from "components/common/MainHeader";
import MainFooter from "components/common/MainFooter";
import { useDispatch, useSelector } from "react-redux";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { setActiveSidebar } from "slices/academicsSlice";
import { getStudentData, getStudentsListing } from "modules/finance";
import { stautsTypeOptions } from "../../config";
import { toast } from "react-toastify";
import { getAttendanceDetails, getUserDetailsbyEmail } from "modules/lead";
import {
  getStudentGroupAttendance,
  getStudentGroupDataFeeSchedule,
  getStudentGroupList,
} from "modules/studentGroup";
import { addStudentAttendance } from "modules/student";

const ViewStudentAttendance = () => {
  const [values, setValues] = useState({
    student: "",
    student_name: "",
    contact_no: "",
    student_group: "",
    date: "",
  });
  const [errors, setErrors] = useState({
    student: "",
    student_name: "",
    contact_no: "",
    student_group: "",
    date: "",
    status: "",
  });
  const selectSize = "middle";
  const styles = useStyles();
  const [studentIdOptions, setStudentIdOptions] = useState([]);
  const [studentIdList, setStudentIdList] = useState([]);
  const [studentGroupOptions, setStudentGroupOptions] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const { attendanceId } = useParams();

  const handleStudentAttendanceNavigation = () => {
    dispatch(setActiveSidebar({ activeSidebar: 3 }));
    navigate("/modules/academics");
  };

  const handleGetStudentListing = async (uniqueUser) => {
    const res = await getAttendanceDetails(attendanceId);
    console.log(res);
    if (res.status === 200) {
      const arr = [];
      const studentList = res.data.message;

      setValues((prevData) => ({
        ...prevData,
        studentId: studentList.id,
        studentGroup: studentList.student_group,
        student_name: studentList.student_name,
        contact_no: studentList?.phone,
        date: studentList.date,
        status: studentList.status,
      }));

      setStudentIdList(studentList);
      setStudentIdOptions(arr);
    }

    const res2 = await getStudentGroupList(uniqueUser);
    console.log(res2);
    if (res2.status === 200) {
      const arr = [];
      const studentList = res2.data.data;
      for (let i = 0; i < studentList.length; i++) {
        arr.push({
          value: studentList[i].name,
          label: studentList[i].name,
        });
      }

      console.log(studentList, arr);
      setStudentGroupOptions(arr);
    }
  };

  const handleSave = async () => {
    try {
      console.log(values);
      const payload = {
        // custom_fee_schedule_name: formData?.schedule_name,
        // fee_creation_status: "",
        // fee_structure: formData.feeStructure,
        // send_email: formData["Send Payment Request Mail"],
        // student_category: formData.studentCategory,
        // program: formData.programme,
        // academic_year: formData.academicYear,
        // academic_term: formData.academicTerm,
        // company: formData.company,
        // due_date: formData.due_date,
        // docstatus: 0,
        // doctype: "Fee Schedule",
        doctype: "Student Attendance",
        student: values.studentId,
        student_name: values.student_name,
        date: values.date,
        status: values.status,
        student_group: values.studentGroup,
      };

      console.log(payload, values);
      const res = await addStudentAttendance({ payload });
      if (res.status === 200) {
        //navigate(`/modules/finance/fee-schedule/${res.data.data.name}`);
        toast.success("Attendance Saved!");
        console.log(res);
      } else {
        toast.error("There is some issue while Saving Attendance");
      }
    } catch (error) {
      toast.error(error.response.data.exception);
    }
  };

  const getUserDetailsFromLocalStorage = () => {
    const user = JSON.parse(window.localStorage.getItem("userDetails"));
    return user;
  };

  useEffect(() => {
    const user = getUserDetailsFromLocalStorage();
    console.log(user, user.email);
    const uniqueUser = user.isAdmin ? user.name : user.email;
    handleGetStudentListing(uniqueUser);
  }, []);

  useEffect(() => {
    console.log(values);
  }, [values]);

  return (
    <>
      <MainHeader />
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/dashboard")}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/modules/academics")}
            >
              Academics {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={handleStudentAttendanceNavigation}
            >
              Attendance {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/modules/academics/student-attendance")}
            >
              Student Attendance {" > "}
            </p>
            <p className={styles.breadCrumbsContent}>Create</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>New Student Attendance</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div
                className={styles.button}
                onClick={() => navigate("/student/dashboard")}
              >
                <p className={styles.buttonText}>Back</p>
              </div>
              {/* <div className={styles.activeButton}>
                <p className={styles.activeButtonText} onClick={handleSave}>
                  Save
                </p>
              </div> */}
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Student Id *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.studentId}
                  showSearch
                  disabled={true}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  className={styles.selectInput}
                  options={studentIdOptions}
                  onChange={(selectedOption) => {
                    const selectedStudentId = selectedOption;
                    const selectedStudent = studentIdList.find(
                      (student) => student.name === selectedStudentId
                    );
                    setValues({
                      ...values,
                      student: selectedOption,
                      student_name: selectedStudent.student_name,
                      contact_no: selectedStudent.student_mobile_number,
                    });
                    setErrors({
                      ...errors,
                      student: "",
                    });
                  }}
                />

                {errors.student && (
                  <p className={styles.errorMessage}>{errors.student}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Student Name *</p>
                <Input
                  placeholder="ex Text"
                  disabled={true}
                  value={values.student_name}
                  className={styles.fieldInput}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      student_name: e.target.value,
                    });
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Student Mobile Number *</p>
                <Input
                  placeholder="ex Text"
                  disabled={true}
                  value={values.contact_no}
                  className={styles.fieldInput}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      contact_no: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Student Group *</p>
                <Select
                  size="middle"
                  placeholder="Select"
                  disabled={true}
                  defaultValue={"Select"}
                  className={styles.selectInput}
                  options={studentGroupOptions}
                  value={values.studentGroup}
                  onChange={(value) => {
                    setValues((prevData) => ({
                      ...prevData,
                      studentGroup: value,
                    }));
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Posting Date *</p>
                <input
                  type="date"
                  value={values.date}
                  disabled={true}
                  placeholder="YYYY-MM-DD"
                  className={styles.dateInput}
                  onChange={(e) => {
                    const dateString = e.target.value;
                    setValues({ ...values, date: dateString });
                    setErrors({
                      ...errors,
                      date: "",
                    });
                  }}
                />
                {errors.posting_date && (
                  <p className={styles.errorMessage}>{errors.date}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Status *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  disabled={true}
                  className={styles.selectInput}
                  options={stautsTypeOptions}
                  value={values.status}
                  onChange={(val) => {
                    setValues({
                      ...values,
                      status: val,
                    });
                    setErrors({
                      ...errors,
                      status: "",
                    });
                  }}
                />
                {errors.status && (
                  <p className={styles.errorMessage}>{errors.status}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default ViewStudentAttendance;

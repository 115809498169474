import moment from "moment";

export const formatDateToDDMMM = (date) => {
  const dateObj = new Date(date);
  const parsedDate = moment(dateObj, "DD/MM/YYYY");
  const formattedDate = parsedDate.format("DD MMM");
  return formattedDate;
};

export const getCurrentDateAndTime = () => {
  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  var day = currentDate.getDate().toString().padStart(2, '0');
  var hours = currentDate.getHours().toString().padStart(2, '0');
  var minutes = currentDate.getMinutes().toString().padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};


export const getCurrentDate = () => {
  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  var day = currentDate.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const formatDateFile = (dateString) => {
  const date = new Date(dateString);
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return date.toLocaleDateString('en-GB', options);
};



export const formatDateTODDMMMYYYY = (inputDate) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const [year, month, day] = inputDate.split('-');
  const monthIndex = parseInt(month, 10) - 1;
  return `${parseInt(day, 10)} ${months[monthIndex]}, ${year}`;
}

import React, { useEffect, useState } from 'react';
import useStyles from './style';
import { ReactSVG } from 'react-svg';
import { useNavigate } from 'react-router-dom';
import { GridMenuIcon } from '@mui/x-data-grid';
import ArrowDownSVG from "../../../assets/images/arrow-down.svg";
import FiltersSVG from "../../../assets/images/filters-icon.svg";
// import { getAllFeedbackTemplates } from '../../../modules/feedback'; // Commented out for now
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../slices/commonSlice';
import LoadingBackdrop from "../../common/LoadingBackdrop.js";
import { Table } from 'antd';
import { getFeedbackResponse, getFeedbackTemplate } from 'modules/survey';

const FeedbackTemplate = ({ setActiveModule }) => {
  const styles = useStyles();
  const navigate= useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [feedbackTemplates, setFeedbackTemplates] = useState([]);

  const columns = [
    {
      dataIndex: "number",
      title: "No.",
      width: 3,
      render: (val, row) => {
        return (
          <p className={styles.tableContentFields}>{row.number}</p>
        );
      },
    },
    {
      dataIndex: "id",
      title: "ID",
      width: 3,
      render: (val, row) => {
        return (
          <p className={styles.tableContentFields}>{row.id}</p>
        );
      },
    },
    {
      dataIndex: "feedback_for",
      title: "FEEDBACK FROM",
      width: 0.8,
      render: (val, row) => {
        return (
          <p className={styles.tableContentFields}>{row.feedback_for}</p>
        );
      },
    },
    {
      dataIndex: "name",
      title: "STUDENT NAME",
      width: 0.8,
      render: (val, row) => {
        return (
          <p className={styles.tableContentFields}>{row.template_type}</p>
        );
      },
    },
    {
      dataIndex: "student_group",
      title: "STUDENT GROUP",
      width: 0.8,
      render: (val, row) => {
        return (
          <p className={styles.tableContentFields}>{row.question_count}</p>
        );
      },
    },
    {
      dataIndex: "id",
      title: "ACTION",
      width: 0.5,
      render: (val, row) => {
        return (
            <p className={styles.tableContentView} onClick={() => navigate(`/modules/survey/${row.id}`)}>View</p>
        );
      },
    },
  ];
  
  const convertCreationTime = (creationTime) => {
    const date = new Date(creationTime);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };  


  const handleGetAllFeedbackResponse = async () => {
    dispatch(setLoading({ loading: true }));
    const res = await getFeedbackResponse();
    if(res.status === 200) {
      dispatch(setLoading({ loading: false }));
      const response = [];
      console.log(res.data.data,"Response")
      for(let i = 0 ; i < res.data.data.length ; i++) {
        response.push({
          number: i+1,
          id: res.data.data[i].name,
          idx: res.data.data[i].idx,
          feedback_for: res.data.data[i].feedback_for,
          // created_date: convertCreationTime(res.data.data[i].creation),
          // modified: res.data.data[i].modified,
          // modified_by: res.data.data[i].modified_by,
          // docstatus: res.data.data[i].docstatus,
          // naming_series: res.data.data[i].naming_series,
          // template_type: res.data.data[i].assigned_based_on,
          // created_by: res.data.data[i].owner,
          // question_count:res.data.data[i].question_count
        })
      };
      setFeedbackTemplates(response);
    } else {
      dispatch(setLoading({ loading: false }));
    }
  };

  useEffect(() => {
    handleGetAllFeedbackResponse();
  }, []);

  return (
    <div className={styles.mainContainer}>
      {
        loading ?
        <LoadingBackdrop open={loading} />:
        <>
          <div className={styles.breadCrumbsContainer}>
            <p className={styles.breadCrumbsContent} onClick={() => navigate("/dashboard")}>IIHT {" > "}</p>
            <p className={styles.breadCrumbsContent} onClick={() => setActiveModule(0)}>Survey {" > "}</p>
            <p className={styles.breadCrumbsContent}>Feedback Response</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon}/>
              <p className={styles.title}>Feedback Response</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div className={`${styles.button} ${styles.filterButton}`}>
                <ReactSVG src={FiltersSVG} />
                <p className={styles.buttonText}>Filters</p>
                <ReactSVG src={ArrowDownSVG} />
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>Saved Filters</p>
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>...</p>
              </div>
            </div>
          </div>
          <Table 
            rowSelection={{ type: "checkbox" }} 
            columns={columns} 
            dataSource={feedbackTemplates} 
            size='small'
            pagination={true}
          />
        </>
      }
    </div>
  )
}

export default FeedbackTemplate;
import React from "react";
import useStyles from "./style";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import { ReactSVG } from "react-svg";

const SeatManagement = ({ setActiveModule }) => {
  const styles = useStyles();
  const navigate = useNavigate();

  const systemList = ["Rooms", "Floors", "Blocks"];

  return (
    <div className={styles.mainContainer}>
      <div className={styles.breadCrumbsContainer}>
        <p
          className={styles.breadCrumbsContent}
          onClick={() => navigate("/dashboard")}
        >
          IIHT {" > "}
        </p>
        <p
          className={styles.breadCrumbsContent}
          onClick={() => setActiveModule(0)}
        >
          Examination {" > "}
        </p>
        <p className={styles.breadCrumbsContent}>Seat Management System</p>
      </div>
      <div className={styles.headerContainer}>
        <div className={styles.breadCrumbsContainer}>
          <GridMenuIcon className={styles.gridIcon} />
          <p className={styles.title}>Seat Management System</p>
        </div>
      </div>
      <div className={styles.contentContainer}>
        {systemList.map((item) => {
          return (
            <div
              className={styles.itemContainer}
              onClick={() =>
                navigate(`/modules/examination/seat-management/${item.toLowerCase()}`)
              }
            >
              <ReactSVG src={item.icon} />
              <p className={styles.itemTitle}>{item}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SeatManagement;

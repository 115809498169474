import * as React from "react";
import Box from "@mui/material/Box";
import Navigation from "./Navigation";

export default function FormContainer() {
  return ( 
    <Box sx={{  boxShadow:'none', padding: "22px 36px" }}>
      <h2>Undergraduate Programmes (B-Tech) - 2024 Intake</h2>
      <Navigation/>
    </Box>
  );
}

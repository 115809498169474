import React, { useEffect, useState } from "react";
import { createGenericEntity, getGenericList } from "modules/masters";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "slices/commonSlice";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { toast } from "react-toastify";
import TextArea from "antd/es/input/TextArea";

const Outcomes = ({ initialCourse }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.common.loading);

  // Ensure initialCourse is set properly
  const [outcomeData, setOutcomeData] = useState({
    program: "",
    outcome_type: "Course Outcome(CO)",
    course: initialCourse ? initialCourse.id : "",
    title: "",
    description: "",
  });

  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [outcomesList, setOutcomesList] = useState([]);
  const [currentOutcomeId, setCurrentOutcomeId] = useState(null);
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading({ loading: true }));
      try {
        const response = await getGenericList("Outcomes");
        if (response.success_key === 1) {
          // Map the outcomes data to the outcomesList state variable
          const updatedOutcomesList = response.message.map((outcome) => ({
            id: outcome.id,
            title: outcome.title,
          }));
          setOutcomesList(updatedOutcomesList);
          console.log(updatedOutcomesList, " setOutcomesList setOutcomesList");

          // Filter outcomes based on the currentOutcomeId
          const filteredOutcomes = updatedOutcomesList.filter(
            (outcome) => outcome.id === currentOutcomeId
          );
          console.log(filteredOutcomes, " filteredOutcomes filteredOutcomes");
        } else {
          setError("Failed to fetch outcomes data.");
        }
      } catch (error) {
        console.error(error, "Something went wrong");
        setError("Failed to fetch outcomes data. Please try again later.");
      } finally {
        dispatch(setLoading({ loading: false }));
      }
    };
    fetchData();
  }, [currentOutcomeId]); // Update outcomes list when a new outcome is created

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOutcomeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !outcomeData.outcome_type.trim() ||
      !outcomeData.title.trim() ||
      !outcomeData.description.trim()
    ) {
      toast.error("Please fill in all fields.");
      return;
    }

    try {
      dispatch(setLoading({ loading: true }));
      const response = await createGenericEntity({
        payload: {
          doctype: "Outcomes",
          ...outcomeData,
        },
      });
      const newOutcome = response.data.message;
      setCurrentOutcomeId(newOutcome.id); // Update currentOutcomeId with the ID of the newly created outcome
      toast.success("Outcome created successfully!");
    } catch (error) {
      console.error("Error creating Outcome:", error);
      toast.error("Failed to create outcome. Please try again later.");
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  return (
    <>
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <>
      
              <div className="form-field fullWidthFields">
                <button type="submit">Create Outcome</button>
              </div>
              <div className="form-field">
                <label>Title:</label>
                <input
                  type="text"
                  name="name"
                  value={outcomeData.title}
                  onChange={handleChange}
                />
              </div>
              <div className="form-field">
                <label>Outcome Type:</label>
                <select
                  name="outcome_type"
                  value={outcomeData.outcome_type}
                  onChange={handleChange}
                >
                  <option value="Course Outcome(CO)">Course Outcome(CO)</option>
                  <option value="Program Educational Objectives(PEO)">
                    Program Educational Objectives(PEO)
                  </option>
                  <option value="Program Outcomes(PO)">
                    Program Outcomes(PO)
                  </option>
                  <option value="Program Specific Outcomes(PSO)">
                    Program Specific Outcomes(PSO)
                  </option>
                  <option value="Competency Levels(CL)">
                    Competency Levels(CL)
                  </option>
                  <option value="Performance Indicators(PI)">
                    Performance Indicators(PI)
                  </option>
                </select>
              </div>
              <div className="form-field fullWidthFields">
                <label>Description:</label>
                <TextArea
                  type="text"
                  name="description"
                  value={outcomeData.description}
                  onChange={handleChange}
                />
              </div>
              {error && <p className="error-message">{error}</p>}
            
          
        </>
      )}
    </>
  );
};

export default Outcomes;

import React from "react";
import NonActiveIcon from "../../../src/assets/images/non-active-sidebar-icon.svg";
import SideBar from "components/SideBar";
import QuestionBankDashboard from "components/Examination/QuestionBank";
import SeatManagement from "components/Examination/SeatManagement";

const sidebarContent = [
  { label: "Question Bank", icon: NonActiveIcon },
  { label: "Seat Management System", icon: NonActiveIcon }
];

const modules = [
  QuestionBankDashboard,
  SeatManagement
];

const Examination = () => {
  return <SideBar sidebarContent={sidebarContent} modules={modules} />;
};

export default Examination;
import React from 'react';
import useStyles from './style';
import { useNavigate } from 'react-router-dom';
import { GridMenuIcon } from '@mui/x-data-grid';
import { ReactSVG } from 'react-svg';
import { meritListDashboardList } from './config';

const AdmissionMeritList = ({ setActiveModule }) => {
  const styles = useStyles();
  const navigate= useNavigate();


  return (
    <div className={styles.mainContainer}>
      <div className={styles.breadCrumbsContainer}>
        <p className={styles.breadCrumbsContent} onClick={() => navigate("/dashboard")}>IIHT {" > "}</p>
        <p className={styles.breadCrumbsContent} onClick={() => setActiveModule(0)}>Admission {" > "}</p>
        <p className={styles.breadCrumbsContent}>Merit List Tool</p>
      </div>
      <div className={styles.headerContainer}>
        <div className={styles.breadCrumbsContainer}>
          <GridMenuIcon className={styles.gridIcon}/>
          <p className={styles.title}>Merit List Tool</p>
        </div>
      </div>
      <div className={styles.contentContainer}>
        {
          meritListDashboardList.map((item) => {
            return (
              <div className={styles.itemContainer} onClick={() => navigate(`/modules/admission${item.url}`)}>
                <ReactSVG src={item.icon} />
                <p className={styles.itemTitle}>{item.name}</p>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default AdmissionMeritList;
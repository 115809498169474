import React, { useState, useEffect } from 'react';
import useStyles from './style';
import { Input, Table } from "antd";
import { useNavigate, useParams } from 'react-router-dom';
import { GridMenuIcon } from '@mui/x-data-grid';
import MainHeader from '../../../common/MainHeader';
import MainFooter from '../../../common/MainFooter';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveSidebar } from '../../../../slices/financeSlice.js';
import LoadingBackdrop from "../../../common/LoadingBackdrop.js";
import { setLoading } from '../../../../slices/commonSlice.js';
import { getMeritCriteriaByName } from '../../../../modules/admission.js';

const GetMeritListCriteria = () => {
  const styles= useStyles();
  const navigate= useNavigate();
  const dispatch = useDispatch();
  const { meritList } = useParams();
  const { loading } = useSelector((state) => state.common);
  const [rowData, setRowData] = useState([]);
  const [values, setValues] = useState({
    name1: "",
    weightage_12: "",
    weightage_10: "",
    criteria_template: null,
  });

  const columns = [
    {
      dataIndex: "student_category",
      title: "STUDENT CATEGORY",
      width: 1,
      render: (val, row) => {
        return (
            <p className={styles.tableContentFields}>{row.student_category}</p>
        );
      },
    },
    {
      dataIndex: "allocation_percentage",
      title: "PERCENTAGE",
      width: 1,
      render: (val, row) => {
        return (
            <p className={styles.tableContentFields}>{row.allocation_percentage}</p>
        );
      },
    },
    {
      dataIndex: "id",
      title: "ACTION",
      width: 0.5,
      render: (val, row) => {
        return (
            <p className={`${styles.tableContentView} ${styles.link}`} onClick={() => navigate(`/modules/admission/merit-list-category/${values.criteria_template}`)}>View</p>
        );
      },
    },
  ];
  
  const handleMeritListNavigation = () => {
    dispatch(setActiveSidebar({ activeSidebar: 3 }));
    navigate("/modules/admission");
  };

  const handleGetMeritCriteria = async () => {
    dispatch(setLoading({ loading: true }));
    const res = await getMeritCriteriaByName({ name: meritList });
    dispatch(setLoading({ loading: false }));
    if(res.status === 200) {
      const data = res.data.data;
      setValues({
        name1: data.name,
        weightage_12: data.weightage_12,
        weightage_10: data.weightage_10,
        criteria_template: data.criteria_template,
      });
      setRowData(data.criteria);
    }
  };

  useEffect(() => {
    handleGetMeritCriteria();
  }, []);

  return (
    <>
      <MainHeader />
      {
        loading ?
          <LoadingBackdrop open={loading} /> :
          <div className={styles.mainContainer}>
            <div className={styles.breadCrumbsContainer}>
              <p className={styles.breadCrumbsContent} onClick={() => navigate("/dashboard")}>IIHT {" > "}</p>
              <p className={styles.breadCrumbsContent} onClick={() => navigate("/modules/admission")}>Admission {" > "}</p>
              <p className={styles.breadCrumbsContent} onClick={handleMeritListNavigation}>Merit List Tool {" > "}</p>
              <p className={styles.breadCrumbsContent} onClick={() => navigate("/modules/admission/merit-list-criteria")}>Merit List Criteria{" > "}</p>
              <p className={styles.breadCrumbsContent}>{meritList}</p>
            </div>
            <div className={styles.headerContainer}>
              <div className={styles.breadCrumbsContainer}>
                <GridMenuIcon className={styles.gridIcon}/>
                <p className={styles.title}>{meritList}</p>
              </div>
              <div className={styles.buttonsGroup}>
                <div className={styles.button} onClick={() => navigate("/modules/admission/merit-list-criteria")}>
                  <p className={styles.buttonText}>Back</p>
                </div>
              </div>
            </div>
            <div className={styles.formContainer}>
              <div className={styles.formRow}>
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>Name *</p>
                  <Input
                    type="text" 
                    className={styles.fieldInput}
                    value={values.name1}
                    disabled={true}
                  />
                </div>
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>10<sup>th</sup> Weightage  *</p>
                  <Input
                    type="text" 
                    className={styles.fieldInput}
                    value={values.weightage_10}
                    disabled={true}
                  />
                </div>
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>12<sup>th</sup> Weightage  *</p>
                  <Input
                    type="text" 
                    className={styles.fieldInput}
                    value={values.weightage_12}
                    disabled={true}
                  />
                </div>
              </div>
              <div className={styles.formRow}>
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>Merit Category Template *</p>
                  <Input
                    type="text" 
                    className={styles.fieldInput}
                    value={values.criteria_template}
                    disabled={true}
                  />
                </div>
              </div>
              <div className={styles.formRow}>
                <div className={styles.tableField}>
                  <Table 
                    columns={columns} 
                    dataSource={rowData} 
                    size='small'
                    pagination={false}
                  />
                </div>
              </div>
            </div>
          </div>
      }
      <MainFooter />
    </>
  )
};

export default GetMeritListCriteria;
import React, { useEffect, useState } from "react";
import useStyles from "../style";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import MainHeader from "../../../common/MainHeader";
import MainFooter from "../../../common/MainFooter";
import { useDispatch, useSelector } from "react-redux";
import LoadingBackdrop from "../../../common/LoadingBackdrop.js";
import { toast } from "react-toastify";
import { Select, Input, Modal } from "antd";
import { getStudentGroupListing } from "modules/hr";
import { createFeedbackAssignment, getFeedbackTemplate } from "modules/survey";

const AddNewFeedbackAssignment = () => {
  const styles = useStyles();
  const selectSize = "middle";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [studentGroupOptions, setStudentGroupOptions] = useState([]);
  //   const [isModalVisible, setIsModalVisible] = useState(false);
  const [feebackTemplateOptions, setFeedbackTemplateOptions] = useState([]);
  const [values, setValues] = useState({
    template_name: "",
    assigned_based_on: "",
    student_group: [],
    publish_on: "",
    last_date_of_submission: "",
  });

  const handleGetStudentGroupListing = async () => {
    const res = await getStudentGroupListing();
    if (res.status === 200) {
      const arr = [];
      const studentGroupList = res.data.data;
      for (let i = 0; i < studentGroupList.length; i++) {
        arr.push({
          value: studentGroupList[i].name,
          label: studentGroupList[i].name,
        });
      }
      setStudentGroupOptions(arr);
    }
  };

  const handleGetAllFeedbackTemplate = async () => {
    const res = await getFeedbackTemplate();
    if (res.status === 200) {
      const arr = [];
      const templateList = res.data.data;
      for (let i = 0; i < res.data.data.length; i++) {
        arr.push({
          value: templateList[i].name,
          label: `${templateList[i].name} - ${templateList[i].template_name}`,
          assigned_based_on: templateList[i].assigned_based_on,
        });
      }
      setFeedbackTemplateOptions(arr);
    }
  };

  const handleBackNavigation = () => {
    navigate("/modules/survey");
  };

  const handleSave = async () => {
    const payload = {
      //   ...values,
      doc_name: values.template_name,
      assigned_based_on: values.assigned_based_on,
      student_group: values.student_group,
      publish_on: values.publish_on,
      last_submission: values.last_date_of_submission,
    };
    try {
      const res = await createFeedbackAssignment(payload);
      console.log(res);
      if (res.status === 200) {
        toast.success("Created the Feedback assignment");
        navigate(`/modules/survey`);
      } else {
        toast.error("Error while creating Feedback assignment!");
      }
    } catch (error) {
      toast.error(
        error.response.data?.exc_type ||
          "Error while creating Feedback assignment!"
      );
    }
  };

  //   const showModal = () => {
  //     setIsModalVisible(true);
  //   };

  //   const handleCancel = () => {
  //     setIsModalVisible(false);
  //   };

  //   const handleSubmit = () => {
  //     setIsModalVisible(false);
  //   };

  useEffect(() => {
    handleGetStudentGroupListing();
    handleGetAllFeedbackTemplate();
  }, []);
  return (
    <>
      <MainHeader />
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/dashboard")}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/modules/survey")}
            >
              Survey {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={handleBackNavigation}
            >
              New Feedback Assignment
            </p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>New Feedback Assignment</p>
            </div>
            <div className={styles.buttonsGroup}>
              {/* <div className={styles.button} onClick={showModal}>
                <p className={styles.buttonText}>Assign To</p>
              </div> */}
              <div className={styles.button} onClick={handleBackNavigation}>
                <p className={styles.buttonText}>Back</p>
              </div>
              <div className={styles.activeButton} onClick={handleSave}>
                <p className={styles.activeButtonText}>Save</p>
              </div>
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Template Name </p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.template_name}
                  className={styles.selectInput}
                  options={feebackTemplateOptions}
                  onChange={(value) => {
                    const selectedTemplate = feebackTemplateOptions.find(
                      (template) => template.value === value
                    );
                    if (selectedTemplate) {
                      setValues((prevState) => ({
                        ...prevState,
                        template_name: value,
                        assigned_based_on: selectedTemplate.assigned_based_on,
                      }));
                    } else {
                      setValues((prevState) => ({
                        ...prevState,
                        template_name: value,
                        assigned_based_on: "",
                      }));
                    }
                  }}
                  showSearch
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Assigned Based On </p>
                <Select
                  placeholder="Assign"
                  value={values.assigned_based_on}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      assigned_based_on: value,
                    });
                  }}
                >
                  <Select.Option value="Student">Student</Select.Option>
                  <Select.Option value="Faculty">Faculty</Select.Option>
                </Select>
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Student Group </p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.student_group}
                  className={styles.selectInput}
                  options={studentGroupOptions}
                  onChange={(selectedValues) => {
                    setValues({
                      ...values,
                      student_group: selectedValues,
                    });
                  }}
                  showSearch
                  mode="multiple"
                />
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Publish On</p>
                <input
                  type="date"
                  value={values.publish_on}
                  placeholder="YYYY-MM-DD"
                  className={styles.dateInput}
                  onChange={(e) => {
                    const dateString = e.target.value;
                    setValues({ ...values, publish_on: dateString });
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Last Date Of Submission</p>
                <input
                  type="date"
                  value={values.last_date_of_submission}
                  placeholder="YYYY-MM-DD"
                  className={styles.dateInput}
                  onChange={(e) => {
                    const dateString = e.target.value;
                    setValues({
                      ...values,
                      last_date_of_submission: dateString,
                    });
                  }}
                />
              </div>
              <div className={styles.field}></div>
            </div>
            {/* <Modal
              title="Assign To"
              open={isModalVisible}
              onCancel={handleCancel}
              footer={null}
            >
              <div className={styles.modalContent}>
                <div className={styles.fields}>
                  <p className={styles.fieldLabel}>Student Group </p>
                  <Select
                    size={selectSize}
                    placeholder="Select"
                    value={values.student_group}
                    className={styles.selectInput}
                    options={studentGroupOptions}
                    onChange={(selectedValues) => {
                      setValues({
                        ...values,
                        student_group: selectedValues,
                      });
                    }}
                    showSearch
                    mode="multiple"
                  />
                </div>
                <div className={styles.modalFooter}>
                  <div className={styles.buttonsGroup}>
                    <div className={styles.button} onClick={handleCancel}>
                      <p className={styles.buttonText}>Cancel</p>
                    </div>
                    <div className={styles.activeButton} onClick={handleSubmit}>
                      <p className={styles.activeButtonText}>Submit</p>
                    </div>
                  </div>
                </div>
              </div>
            </Modal> */}
          </div>
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default AddNewFeedbackAssignment;

import React, { useEffect, useState } from 'react';
import App from './form';
import { useNavigate,useParams } from "react-router-dom";
import axios from 'axios'; 
import { feedbackResponse, getFeedbackQuestion } from 'modules/student';
import { Card, Button } from 'antd';
import useStyles from "../style";
import { MainLayout } from "components/common";
import { toast } from "react-toastify";


const Questionnaire = () => {
  const [questions, setQuestions] = useState([]);
  const [ feedbackFor,setFeedbackFor ] = useState("")
  const [formData, setFormData] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();
  const styles = useStyles();
  const user = JSON.parse(window.localStorage.getItem("userDetails"));

  const getFeedbackQuestions = async () => {
    try {
      const res = await getFeedbackQuestion(id);
      if (res.status === 200) {
        setFeedbackFor(res.data.data.assigned_based_on)
        setQuestions(res.data.data.feedback_questions);
        const initialFormData = {};
        res.data.data.feedback_questions.forEach(question => {
          initialFormData[question.name] = "";
        });
        setFormData(initialFormData);
      }
    } catch (error) {
      console.error("Error fetching feedback questions:", error);
    }
  };

  useEffect(() => {
    getFeedbackQuestions();
  }, []);

  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      const payload = {
        feedback: {
          feedback_for: feedbackFor,
          student: user.name,
          feedback_template: id,
          email: user.email,
          ques: questions.map(question => ({
            question: question.question,
            type: question.type,
            value: formData[question.name] || ""
          }))
        }
      };
      console.log(payload,"Payload")
      const res = await feedbackResponse(payload);
      if(res.status === 200){
        console.log("Feedback Response Recorded")
        toast.success("Feedback Response Recorded!");
        navigate(-1);
      }
    } catch (error) {
      // Handle error
      console.error("Error submitting feedback:", error);
    }
  };

  return (
    <MainLayout>
      <Card
        title="IIHT College of Technology"
        bordered={false}
        style={{
          width: '90%',
          marginBottom: '16px',
        }}
      >
        <p>{user.name}</p>
        <span className={styles.emails}>{user.email}</span>
      </Card>
      {questions.map((question, index) => (
        <App
          key={question.name}
          questionType={question.type}
          options={question.options}
          question={question.question}
          name={question.name}
          onChange={handleInputChange}
          value={formData[question.name]}
          bordered={false} 
        />
      ))}
      <div className={styles.buttonsGroup}>
                <div className={styles.button} onClick={() => {
                //   setIsAddLeadOpen(false);
                //   handleClearErrormessages()
                }}>
                  <p className={styles.buttonText}>Back</p>
                </div>
                <div className={styles.activeButton} onClick={() => handleSubmit()}>
                  <p className={styles.activeButtonText}>Save</p>
                </div>
              </div>
    </MainLayout>
  );
};

export default Questionnaire;

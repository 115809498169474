import React from "react";
import { useNavigate } from "react-router-dom";
import { fieldsData } from "./ModulesListJson";
import useStyles from "./style"; 
import MainHeader from "components/common/MainHeader";
import MainFooter from "components/common/MainFooter";

export default function ModulesLanding() {
  const styles = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <MainHeader />
      <div className={styles.container}>
        {fieldsData.map((field, index) => (
          <div 
            onClick={() => {
              navigate(`/modules/${field.name.toLowerCase()}`)
            }}
            key={index} 
            className={styles.mainContainer}>
              <img
                src={field.icon}
                alt={field.name}
              />
              <p className={styles.title}>{field.name}</p>
          </div>
        ))}
      </div>
      <MainFooter />
    </>
  );
}
import React from "react";
import LoginCoverImage from "../../assets/images/new_loginimg.png";
import Loginform from "../../components/LoginForm";
import "./index.scss";

const Login = () => {
  const withAccessUi = (component) => {
    return (
      <>
        <section className="access-section-container">
          <div className="left-half-cover-image">
            <img
              src={LoginCoverImage}
              className="loginFormCoverImage"
              alt="loginCoverImage"
            />
          </div>
          <div className="right-half-component">{component}</div>
        </section>
      </>
    );
  };

  const getComponent = () => {
    return withAccessUi(<Loginform />);
  };

  return <div className="d-flex">{getComponent()}</div>;
};

export default Login;

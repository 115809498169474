import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Breadcrumbs, MainLayout } from "components/common";
import { Select } from "antd";
import {
  getGenericData,
  createGenericEntity,
  updateGenericEntity,
  getLeaveType,
  getEmployeeAllocation,
  getInstitution,
  getEmployeeName,
} from "modules/hr";
import { Input, Button, Table, message, Checkbox } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "slices/commonSlice";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import useStyles from "./style.js";
import { toast } from "react-toastify";
import { stringify } from "json5";

const HolidayListDetails = () => {
  const styles = useStyles();
  const loading = useSelector((state) => state.common.loading);
  const dispatch = useDispatch(); // Initialize useDispatch hook
  const { id } = useParams();
  const navigate = useNavigate();
  const [holidayList, setHolidayList] = useState({
    employee: "",
    from_date: "",
    to_date: "",
    leave_type: "",
    doctype: "Leave Allocation",
    employee_name: "",
    company: "",
    new_leaves_allocated: "",
    description: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [selectedColor, setSelectedColor] = useState(null);
  const [isViewMode, setIsViewMode] = useState(false);
  const [errors, setErrors] = useState({}); // State to hold validation errors
  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [leaveType, setLeaveType] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [institution, setInstitution] = useState([]);
  const [employeeName, setEmployeeName] = useState("");

  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;
    const updatedData = holidayList.holidays.map((item) => ({
      ...item,
      checked: checked,
    }));
    setHolidayList((prevState) => ({
      ...prevState,
      holidays: updatedData,
    }));
    setSelectAll(checked);
  };

  const handleGetLeaveType = async () => {
    const payload = {
      doctype: "Leave Type",
    };
    const res = await getLeaveType(payload);
    if (res.status === 200) {
      const arr = [];
      for (let i = 0; i < res.data.data.length; i++) {
        arr.push({
          value: res.data.data[i].name,
          label: res.data.data[i].name,
        });
      }

      setLeaveType(arr);
    }
  };

  const handleGetEmployee = async () => {
    const res = await getEmployeeAllocation();
    if (res.status === 200) {
      const arr = [];
      for (let i = 0; i < res.data.data.length; i++) {
        arr.push({
          value: res.data.data[i].name,
          label: res.data.data[i].name,
        });
      }

      setEmployee(arr);
    }
  };

  const handleGetEmployeeName = async () => {
    try {
      const res = await getEmployeeName(holidayList.employee);
      if (
        res.status === 200 &&
        res.data &&
        res.data.data &&
        res.data.data.length > 0
      ) {
        const employeeName = res.data.data[0].employee_name;
        if (employeeName) {
          setEmployeeName(employeeName);
        } else {
          console.error("Employee name is not available in the response.");
        }
      } else {
        console.error("Failed to fetch employee name. Response:", res);
      }
    } catch (error) {
      console.error("An error occurred while fetching employee name:", error);
    }
  };

  const handleGetInstitution = async () => {
    const res = await getInstitution();
    if (res.status === 200) {
      const arr = [];
      for (let i = 0; i < res.data.data.length; i++) {
        arr.push({
          value: res.data.data[i].name,
          label: res.data.data[i].name,
        });
      }

      setInstitution(arr);
    }
  };

  useEffect(() => {
    handleGetLeaveType();
    handleGetEmployee();
    handleGetInstitution();
  }, []);

  useEffect(() => {
    handleGetEmployeeName();
  }, [holidayList.employee]);

  const handleCheckboxChange = (index, checked) => {
    // Handle individual checkbox change
  };
  const handleEditClick = (index) => {
    setEditableRowIndex(index);
  };

  const handleSaveClick = (index) => {
    setEditableRowIndex(null);
    // Save the changes for the specific row
  };

  const handleCancelClick = () => {
    setEditableRowIndex(null);
    // Revert changes if needed
  };

  const isRowEditable = (index) => {
    return editableRowIndex === index;
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading({ loading: true }));
      try {
        if (id) {
          const holidayListData = await getGenericData("Leave Allocation", id);
          setHolidayList(holidayListData.message);
          setIsViewMode(true);
        }
        dispatch(setLoading({ loading: false }));
      } catch (error) {
        console.error("Error fetching leave allocation list:", error);
        dispatch(setLoading({ loading: false }));
      }
    };

    fetchData();

    return () => {
      // Cleanup if needed
    };
  }, [id]);

  const handleFieldChange = (name, value) => {
    setHolidayList((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      const doctype = "Leave Allocation";
  
      const validationErrors = validateFields();
  
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }
  
      const payload = {
        ...holidayList,
        doctype,
      };
  
      let res;
      if (id) {
        res = await updateGenericEntity({ payload });
      } else {
        res = await createGenericEntity({ payload });
      }
  
      if (res.status === 200) {
        
        toast.success(id ? "Leave Allocation List Updated!" : "Leave Allocation List Created!");
        navigate(-1);
      } else {
        if (res.data && res.data.message) {
          toast.error(res.data.message);
        } else {
          toast.error("Failed to save Leave Allocation. Please try again later.");
        }
      }
    } catch (error) {
      console.error("Error saving holiday list:", error);
      toast.error("An error occurred while saving Leave Allocation.");
    }
  };
  
  

  // Function to validate required fields
  const validateFields = () => {
    const errors = {};

    if (!holidayList.employee.trim()) {
      errors.employee = "Employee Id is required";
    }

    if (!holidayList.from_date.trim()) {
      errors.from_date = "From Date is required";
    }

    if (!holidayList.to_date.trim()) {
      errors.to_date = "To Date is required";
    }

    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHolidayList((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddHoliday = () => {
    // Append a new holiday to the holidays array
    const newHoliday = {
      id: "",
      holiday_date: "",
      description: "",
      weekly_off: "",
    };
    setHolidayList((prevState) => ({
      ...prevState,
      holidays: [...prevState.holidays, newHoliday],
    }));
  };

  const handleHolidayChange = (index, e) => {
    const { name, value } = e.target;
    const updatedHolidays = [...holidayList.holidays];
    updatedHolidays[index][name] = value;
    setHolidayList((prevState) => ({
      ...prevState,
      holidays: updatedHolidays,
    }));
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <MainLayout>
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <>
          <Breadcrumbs />
          <div className="master-heading">
            <h2>
              {isViewMode ? "View Leave Allocation" : " New Leave Allocation"}
            </h2>
            <div className="btnsGrp">
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                back
              </Button>
              <Button
                onClick={handleSave}
                variant="contained"
                color="primary"
                className="redBtn"
              >
                {isViewMode ? "Update" : "Save"}
              </Button>
            </div>
          </div>
          <div className="formApplication">
            <section className="form-section">
              <div className="fields-block">
                <div className="form-field">
                  <label>Employee ID *</label>
                  <Select
                    type="text"
                    name="holiday_list_name"
                    placeholder="Employee"
                    options={employee}
                    value={holidayList.employee}
                    onChange={(value) => handleFieldChange("employee", value)}
                  />
                  {errors.holiday_list_name && (
                    <span className="error-message">
                      {errors.holiday_list_name}
                    </span>
                  )}
                </div>
                <div className="form-field">
                  <label>Employee Name *</label>
                  <input
                    type="text"
                    name="employee_name"
                    placeholder="Employee Name"
                    value={employeeName}
                    disabled={true}
                    onChange={(value) =>
                      handleFieldChange("employee_name", value)
                    }
                  />
                  {errors.employee_name && (
                    <span className="error-message">
                      {errors.employee_name}
                    </span>
                  )}
                </div>
                <div className="form-field">
                  <label>Institution *</label>
                  <Select
                    options={institution}
                    name="company"
                    placeholder="YYYY-MM-DD"
                    value={holidayList.company}
                    onChange={(value) => handleFieldChange("company", value)}
                  />
                  {errors.company && (
                    <span className="error-message">{errors.company}</span>
                  )}
                </div>
                <div className="form-field">
                  <label>Leave Type *</label>
                  <Select
                    options={leaveType}
                    name="leave_type"
                    value={holidayList.leave_type}
                    onChange={(value) => handleFieldChange("leave_type", value)}
                  />
                  {errors.leave_type && (
                    <span className="error-message">{errors.leave_type}</span>
                  )}
                </div>
                <div className="form-field">
                  <label>From Date *</label>
                  <input
                    type="date"
                    name="from_date"
                    value={holidayList.from_date}
                    onChange={handleChange}
                  />
                  {errors.to_date && (
                    <span className="error-message">{errors.from_date}</span>
                  )}
                </div>
                <div className="form-field">
                  <label>To Date *</label>
                  <input
                    type="date"
                    name="to_date"
                    value={holidayList.to_date}
                    onChange={handleChange}
                  />
                  {errors.to_date && (
                    <span className="error-message">{errors.to_date}</span>
                  )}
                </div>
              </div>
            </section>
            <section className="form-section">
              <h2 className="">Allocation</h2>
              <hr className={styles.line}></hr>
              <div className="fields-block">
                <div className="form-field">
                  <label>New Leaves Allocated *</label>
                  <input
                    name="new_leaves_allocated"
                    value={holidayList.new_leaves_allocated}
                    onChange={handleChange}
                  />
                  {errors.to_date && (
                    <span className="error-message">
                      {errors.new_leaves_allocated}
                    </span>
                  )}
                </div>
              </div>
            </section>
            <section className="form-section">
              <h2 className="">Notes</h2>
              <hr className={styles.line}></hr>
              <div className="fields-block">
                <div className="form-field">
                  <label>Description *</label>
                </div>
                <textarea
                  rows={4}
                  name="description"
                  value={holidayList.description}
                  onChange={handleChange}
                  className={styles.fieldInput}
                />
                {errors.description && (
                  <span className="error-message">{errors.description}</span>
                )}
              </div>
            </section>
          </div>
        </>
      )}
      ;
    </MainLayout>
  );
};

export default HolidayListDetails;

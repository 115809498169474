import React from 'react';
import useStyles from './style';
import { useNavigate } from 'react-router-dom';
import { GridMenuIcon } from '@mui/x-data-grid';
import LeadDashboard from './LeadDashboard';
import ApplicationDashboard from './ApplicationDashboard';
import { Collapse } from 'antd';

const AdmissionDashboard = ({ setActiveModule }) => {
  const styles = useStyles();
  const navigate= useNavigate();

  const items = [
    {
      key: '1',
      label: 'Lead Analytics',
      children: <LeadDashboard />,
    },
    {
      key: '2',
      label: 'Application Analytics',
      children: <ApplicationDashboard />,
    },
  ]

  return (
    <div className={styles.mainContainer}>
      <div className={styles.breadCrumbsContainer}>
        <p className={styles.breadCrumbsContent} onClick={() => navigate("/dashboard")}>IIHT {" > "}</p>
        <p className={styles.breadCrumbsContent} onClick={() => setActiveModule(0)}>Admission {" > "}</p>
        <p className={styles.breadCrumbsContent}>Dashboard</p>
      </div>
      <div className={styles.headerContainer}>
        <div className={styles.breadCrumbsContainer}>
          <GridMenuIcon className={styles.gridIcon}/>
          <p className={styles.title}>Dashboard</p>
        </div>
      </div>
      <Collapse items={items} defaultActiveKey={['1']} />
    </div>
  )
}

export default AdmissionDashboard;
import React from "react";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import useStyles from "./style";
import CardActions from "@mui/material/CardActions";
import Divider from "@mui/material/Divider";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const formatDate = (date) => {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, "0");
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const year = d.getFullYear();
  return `${day}-${month}-${year}`;
};

const JobCard = ({ jobTitle, experience, location, applicationCount, closesOn, postedon }) => {
  const classes = useStyles();
  
  const formattedPostedOn = formatDate(postedon);
  const formattedClosesOn = formatDate(closesOn);

  return (
    <Card className={classes.card}>
      <CardContent className={classes.topContainer}>
        {/* Job details */}
        <div className={classes.titleContainer}>
          <Typography variant="h5" component="div">
            {jobTitle}
          </Typography>
          <div className={classes.greenBubble}>
            <Typography variant="body2" className={classes.fullTimeText}>
              Full-time
            </Typography>
          </div>
        </div>
        <div className={classes.detailsContainer}>
          <Typography variant="body2" className={classes.exp}>
            {experience}
          </Typography>
          <Divider
            className={classes.divider}
            orientation="vertical"
            flexItem
          />
          <Typography
            variant="body2"
            className={classes.experienceText}
          >{`Posted: ${formattedPostedOn}`}</Typography>
        </div>
        <div className={classes.locationContainer}>
          <LocationOnIcon /> {/* Render the location icon */}
          <Typography variant="body2">{location}</Typography>
        </div>
      </CardContent>
      <CardActions className={classes.cardFooter}>
        {/* Footer section */}
        <div className={classes.footerLeft}>
          <Typography variant="body2" className={classes.footerLeft}>
            Applications Received: {applicationCount}
          </Typography>
        </div>
        <Divider orientation="vertical" flexItem />

        <div className={classes.footerRight}>
          <Typography variant="body2" className={classes.footerRight}>
            Closing Date: {formattedClosesOn}
          </Typography>
        </div>
      </CardActions>
    </Card>
  );
};

export default JobCard;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Breadcrumbs, MainLayout } from "components/common";
import { Button, message, Input, Table, Select, Checkbox } from "antd";
import { toast } from "react-toastify"; // Import toast from react-toastify
import {
  getGenericData,
  createGenericEntity,
  updateGenericEntity,
  getGenericList,
} from "modules/hr";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "slices/commonSlice";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { EditOutlined } from "@mui/icons-material";

const { Option } = Select;

const LeavePolicyDetails = () => {
  const loading = useSelector((state) => state.common.loading);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [leavePolicy, setLeavePolicy] = useState({
    id: "",
    title: "",
    status: "Open", // Default status is "Open"
    leave_allocations: [],
  });
  const [errors, setErrors] = useState({});
  const [isViewMode, setIsViewMode] = useState(false);
  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [data, setData] = useState([]);

  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;
    const updatedLeaveAllocations = leavePolicy.leave_allocations.map(
      (item) => ({
        ...item,
        checked: checked,
      })
    );
    setLeavePolicy((prevState) => ({
      ...prevState,
      leave_allocations: updatedLeaveAllocations,
    }));
    setSelectAll(checked);
  };

  const handleCheckboxChange = (index, checked) => {
    const updatedLeaveAllocations = [...leavePolicy.leave_allocations];
    updatedLeaveAllocations[index].checked = checked;
    setLeavePolicy((prevState) => ({
      ...prevState,
      leave_allocations: updatedLeaveAllocations,
    }));
    // Check if any checkbox is checked
    const anyChecked = updatedLeaveAllocations?.some((item) => item.checked);
    setSelectAll(anyChecked);
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading({ loading: true }));
      try {
        if (id && id !== "new") {
          const leavePolicyData = await getGenericData("Leave Policy", id);
          const { title, leave_policy_details } = leavePolicyData.message;
          // Populate leave_policy_details array with existing data
          const formattedLeaveAllocations = leave_policy_details.map(
            (allocation) => ({
              leave_policy_details: allocation.leave_type,
              annual_allocation: allocation.annual_allocation,
            })
          );
          setLeavePolicy({
            id,
            title,
            leave_allocations: formattedLeaveAllocations,
            status: leavePolicyData.message.custom_status,
          });
          setIsViewMode(true); // Set view mode if an existing leave policy is fetched
        } else {
          setIsViewMode(false); // Set add mode if no existing leave policy is fetched
        }
        dispatch(setLoading({ loading: false }));
      } catch (error) {
        console.error("Error fetching leave Policy:", error);
        dispatch(setLoading({ loading: false }));
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchLeaveTypes = async () => {
      try {
        const doctype = "Leave Type";
        const LeaveType = await getGenericList(doctype);

        const leaveTypeWithId = LeaveType.map((leave, index) => ({
          ...leave,
          entityType: "Leave Type",
        }));
        console.log("Leave Types:", leaveTypeWithId);
        setData(leaveTypeWithId);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchLeaveTypes();
  }, []);

  const handleChange = (value, index, columnName) => {
    const updatedLeaveAllocations = [...leavePolicy.leave_allocations];
    updatedLeaveAllocations[index][columnName] = value;

    // Find the corresponding Leave Type data for the selected value
    const selectedLeaveType = data.find(
      (leaveType) => leaveType.leave_type_name === value
    );

    // Update the annual_allocation field with the max_continuous_days_allowed value
    updatedLeaveAllocations[index].annual_allocation = selectedLeaveType
      ? selectedLeaveType.max_continuous_days_allowed
      : "";

    setLeavePolicy((prevState) => ({
      ...prevState,
      leave_allocations: updatedLeaveAllocations,
    }));
  };

  const handleAddLeave = (data) => {
    const newLeaveAllocation = {
      leave_type: data.leave_policy_details,
      annual_allocation: "",
      // checked: false, // Initialize checked property to false for new row
    };

    setLeavePolicy((prevState) => ({
      ...prevState,
      leave_allocations: [
        ...(prevState.leave_allocations || []),
        newLeaveAllocation,
      ],
    }));
  };

  const handleDeleteLeave = () => {
    const updatedLeaveAllocations = leavePolicy.leave_allocations.filter(
      (item) => !item.checked
    );
    setLeavePolicy((prevState) => ({
      ...prevState,
      leave_allocations: updatedLeaveAllocations,
    }));
    setSelectAll(false);
  };

  // Conditional rendering of the delete button
  const renderDeleteButton = () => {
    const anyChecked = leavePolicy.leave_allocations?.some(
      (item) => item.checked
    );
    if (anyChecked) {
      return (
        <Button onClick={handleDeleteLeave} className="deleteRow">
          {"Delete"}
        </Button>
      );
    }
    return null;
  };

  const handleSave = async () => {
    try {
      const doctype = "Leave Policy";
      const validationErrors = validateFields();

      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }

      // Construct leave_policy_details array from leave_allocations data
      const leavePolicyDetails = leavePolicy.leave_allocations.map(
        (allocation) => ({
          leave_type: allocation.leave_policy_details,
          annual_allocation: allocation.annual_allocation || "", // Ensure annual_allocation is present
        })
      );

      const payload = {
        doctype,
        title: leavePolicy.title,
        leave_policy_details: leavePolicyDetails,
        custom_status: leavePolicy.status, // Include status in the payload
      };

      if (id && id !== "new") {
        payload.id = id; // Include id for updating existing leave policy
        await updateGenericEntity({ payload });
        message.success("Leave policy updated successfully");
      } else {
        await createGenericEntity({ payload });
        message.success("Leave policy added successfully");
      }

      // Redirect only after successful form submission
      if (!(Object.keys(validationErrors).length > 0)) {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error saving leave policy:", error);
      // Show an error message to the user using toastify
      toast.error("Failed to save leave policy. Please try again.");
    }
  };

  const validateFields = () => {
    const errors = {};

    if (!leavePolicy.title) {
      errors.title = "Title is required";
    }

    const leavePolicyDetailsErrors = [];
    leavePolicy.leave_allocations.forEach((allocation, index) => {
      if (!allocation.leave_policy_details) {
        leavePolicyDetailsErrors[index] = "Leave Policy Details is required";
      }
    });
    if (leavePolicyDetailsErrors.length > 0) {
      errors.leavePolicyDetails = leavePolicyDetailsErrors;
    }

    return errors;
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const columns = [
    {
      title: () => (
        <Checkbox checked={selectAll} onChange={handleSelectAllChange} />
      ),
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => (
        <Checkbox
          checked={record.checked}
          onChange={(e) => handleCheckboxChange(index, e.target.checked)}
        />
      ),
    },
    {
      title: "Leave Policy Details",
      dataIndex: "leave_policy_details",
      key: "leave_policy_details",
      render: (text, record, index) => (
        <Select
          value={record.leave_policy_details} // Use record.leave_policy_details directly
          onChange={(value) =>
            handleChange(value, index, "leave_policy_details")
          }
          style={{ width: "100%" }}
        >
          {data.map((leaveType) => (
            <Option key={leaveType.id} value={leaveType.leave_type_name}>
              {leaveType.leave_type_name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Annual Allocation",
      dataIndex: "annual_allocation",
      key: "annual_allocation",
      render: (text, record, index) => (
        <Input
          value={text}
          onChange={(e) =>
            handleChange(e.target.value, index, "annual_allocation")
          }
          name="annual_allocation"
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "actions",
      key: "actions",
      render: (text, record, index) => (
        <div>
          {editableRowIndex === index ? (
            <div className="saveCancel">
              <Button onClick={() => handleSaveClick(index)} type="primary">
                Save
              </Button>
              <Button onClick={handleCancelClick}>Cancel</Button>
            </div>
          ) : (
            <Button
              onClick={() => handleEditClick(index)}
              type="link" 
            >
              {"Edit"}
            </Button>
          )}
        </div>
      ),
    },
  ];

  const handleEditClick = (index) => {
    setEditableRowIndex(index);
  };

  const handleSaveClick = (index) => {
    setEditableRowIndex(null);
    // Save the changes for the specific row
  };

  const handleCancelClick = () => {
    setEditableRowIndex(null);
    // Revert changes if needed
  };

  return (
    <MainLayout>
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <>
          <Breadcrumbs />
          <div className="master-heading">
            <h2>{isViewMode ? "View Leave Policy" : "Add Leave Policy"}</h2>
            <div className="btnsGrp">
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                back
              </Button>
              <Button
                onClick={handleSave}
                variant="contained"
                color="primary"
                className="redBtn"
              >
                {isViewMode ? "Update" : "Save"}
              </Button>
            </div>
          </div>
          <div className="formApplication leaveMgmtForm">
            <section className="form-section">
              <div className="fields-block">
                <div className="form-field">
                  <label>Title *</label>
                  <input
                    type="text"
                    name="title"
                    value={leavePolicy.title}
                    onChange={(e) =>
                      setLeavePolicy((prevState) => ({
                        ...prevState,
                        title: e.target.value,
                      }))
                    }
                  />
                  {errors.title && (
                    <span className="error-message">{errors.title}</span>
                  )}
                </div>
              </div>
              <h4>Leave Allocations</h4>
              <h5>Leave Policy Details</h5>
              <Table
                dataSource={leavePolicy.leave_allocations}
                columns={columns} // Define columns as needed
                rowKey={(record, index) => index}
                pagination={false}
              />
              <div className="addDeleteBtns">
                {renderDeleteButton()}
                <Button onClick={handleAddLeave} className="addRow">
                  {"+"}
                </Button>
              </div>
            </section>
          </div>
        </>
      )}
    </MainLayout>
  );
};

export default LeavePolicyDetails;

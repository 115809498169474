import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import MainHeader from "../../../common/MainHeader";
import MainFooter from "../../../common/MainFooter";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSidebar } from "../../../../slices/financeSlice";
import { Select, Input, DatePicker, Divider } from "antd";
import { paymentRequestTypeOptions, partyTypeOptions } from "../config.js";
import LoadingBackdrop from "../../../common/LoadingBackdrop.js";
import TextArea from "antd/es/input/TextArea.js";
import {
  createPaymentRequest,
  getCurrencyListing,
  getFee,
  getFeesListing,
  getModeOfPaymentListing,
  getStudentsListing,
} from "../../../../modules/finance.js";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const AddNewPaymentRequest = () => {
  const styles = useStyles();
  const selectSize = "middle";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [modeOfPaymentOptions, setModeOfPaymentOptions] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [feesOptions, setFeesOptions] = useState([]);
  const [studentIdOptions, setStudentIdOptions] = useState([]);
  const [values, setValues] = useState({
    payment_request_type: "",
    mode_of_payment: "",
    transaction_date: "",
    party_type: "",
    reference_doctype: "Fees",
    party: "",
    reference_name: "",
    grand_total: "",
    currency: "",
    email_to: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    payment_request_type: "",
    mode_of_payment: "",
    transaction_date: "",
    party_type: "",
    reference_doctype: "Fees",
    party: "",
    reference_name: "",
    grand_total: "",
    currency: "",
    email_to: "",
    subject: "",
    message: "",
  });

  const handlePaymentRequestNavigation = () => {
    dispatch(setActiveSidebar({ activeSidebar: 5 }));
    navigate("/modules/finance");
  };

  const handleGetModeOfPaymentListing = async () => {
    const res = await getModeOfPaymentListing();
    if (res.status === 200) {
      const arr = [];
      const modeOfPaymentList = res.data.data;
      for (let i = 0; i < modeOfPaymentList.length; i++) {
        arr.push({
          value: modeOfPaymentList[i].name,
          label: modeOfPaymentList[i].name,
        });
      }
      setModeOfPaymentOptions(arr);
    }
  };

  const handleGetCurrencyListing = async () => {
    const res = await getCurrencyListing();
    if (res.status === 200) {
      const arr = [];
      const currencyList = res.data.data;
      for (let i = 0; i < currencyList.length; i++) {
        arr.push({
          value: currencyList[i].name,
          label: currencyList[i].name,
        });
      }
      setCurrencyOptions(arr);
    }
  };

  const handleGetFeeListing = async () => {
    const res = await getFeesListing();
    if (res.status === 200) {
      const arr = [];
      const feeList = res.data.data;
      for (let i = 0; i < feeList.length; i++) {
        arr.push({
          value: feeList[i].name,
          label: feeList[i].name,
        });
      }
      setFeesOptions(arr);
    }
  };

  const handleGetStudentListing = async () => {
    const res = await getStudentsListing();
    if (res.status === 200) {
      const arr = [];
      const studentList = res.data.data;
      for (let i = 0; i < studentList.length; i++) {
        arr.push({
          value: studentList[i].name,
          label: `${studentList[i].name} - ${studentList[i].first_name}`,
        });
      }
      setStudentIdOptions(arr);
    }
  };

  const handleFeeById = async (fees) => {
    try {
      const res = await getFee(fees);
      if (res.status === 200) {
        console.log(res, "fee list");
        const feeList = res.data.data;
        setValues({
          ...values,
          reference_name: fees,
          grand_total: feeList.grand_total,
        });
      } else {
        console.error("Failed to fetch fees:", res);
      }
    } catch (error) {
      console.error("Error occurred while fetching fees:", error);
    }
  };

  const handleValidateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!values.payment_request_type) {
      newErrors.payment_request_type = "Payment Request Type is required";
      isValid = false;
    } else {
      newErrors.mode_of_payment = "";
    }

    if (!values.mode_of_payment) {
      newErrors.mode_of_payment = "Mode of Payment is required";
      isValid = false;
    } else {
      newErrors.mode_of_payment = "";
    }

    if (!values.party_type) {
      newErrors.party_type = "Party Type is required";
      isValid = false;
    } else {
      newErrors.party_type = "";
    }

    if (!values.party) {
      newErrors.party = "Party is required";
      isValid = false;
    } else {
      newErrors.party = "";
    }

    if (!values.reference_name) {
      newErrors.reference_name = "Reference Name is required";
      isValid = false;
    } else {
      newErrors.reference_name = "";
    }

    if (!values.grand_total) {
      newErrors.grand_total = "Amount is required";
      isValid = false;
    } else if (isNaN(parseFloat(values.grand_total))) {
      newErrors.grand_total = "Please enter a valid number for Amount";
      isValid = false;
    } else {
      newErrors.grand_total = "";
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSave = async () => {
    const isValid = handleValidateForm();
    if (isValid) {
      try {
        const payload = {
          ...values,
          docstatus: 0,
        };
        const res = await createPaymentRequest({ payload });
        if (res.status === 200) {
          navigate(`/modules/finance/payment-request/${res.data.data.name}`);
          toast.success("Payment Request Drafted!");
        } else {
          toast.error("There is some issue while creating");
        }
      } catch (error) {
        toast.error(error.response.data.exception);
      }
    }
  };

  const { state } = useLocation();
  useEffect(() => {
    if (state) {
      const { fee_id, posting_date, student, total_amount, contact_email } =
        state;
      console.log(state);
      setValues({
        ...values,
        transaction_date: posting_date,
        reference_name: fee_id,
        party: student,
        grand_total: total_amount,
        payment_request_type: "Inward",
        party_type: "Student",
        email_to: contact_email,
      });
    } else {
      setValues({
        ...values,
        transaction_date: "",
        reference_name: "",
        party: "",
        grand_total: "",
        email_to: "",
      });
    }
  }, [state]);

  useEffect(() => {
    handleGetModeOfPaymentListing();
    handleGetCurrencyListing();
    handleGetFeeListing();
    handleGetStudentListing();
  }, []);

  return (
    <>
      <MainHeader />
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/dashboard")}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/modules/finance")}
            >
              Finance {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={handlePaymentRequestNavigation}
            >
              Payment Request
            </p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>New Payment Request</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div
                className={styles.button}
                onClick={handlePaymentRequestNavigation}
              >
                <p className={styles.buttonText}>Back</p>
              </div>
              <div className={styles.activeButton} onClick={handleSave}>
                <p className={styles.activeButtonText}>Save</p>
              </div>
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Payment Request Type *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  options={paymentRequestTypeOptions}
                  value={values.payment_request_type}
                  className={styles.selectInput}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      payment_request_type: value,
                    });
                    setErrors({
                      ...errors,
                      payment_request_type: "",
                    });
                  }}
                />
                {errors.payment_request_type && (
                  <p className={styles.errorMessage}>
                    {errors.payment_request_type}
                  </p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Mode of Payment *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  options={modeOfPaymentOptions}
                  value={values.mode_of_payment}
                  className={styles.selectInput}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      mode_of_payment: value,
                    });
                    setErrors({
                      ...errors,
                      mode_of_payment: "",
                    });
                  }}
                />
                {errors.mode_of_payment && (
                  <p className={styles.errorMessage}>
                    {errors.mode_of_payment}
                  </p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Transaction Date</p>
                <input
                  type="date"
                  value={values.transaction_date}
                  className={styles.dateInput}
                  onChange={(e) => {
                    const dateString = e.target.value;
                    setValues({
                      ...values,
                      transaction_date: dateString,
                    });

                    setErrors({
                      ...errors,
                      transaction_date: "",
                    });
                  }}
                />
                {errors.transaction_date && (
                  <p className={styles.errorMessage}>
                    {errors.transaction_date}
                  </p>
                )}
              </div>
            </div>
            <p className={styles.heading}>Party Details</p>
            <Divider className={styles.divider} />
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Party Type *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  options={partyTypeOptions}
                  value={values.party_type}
                  className={styles.selectInput}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      party_type: value,
                    });
                    setErrors({
                      ...errors,
                      party_type: "",
                    });
                  }}
                />
                {errors.party_type && (
                  <p className={styles.errorMessage}>{errors.party_type}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Reference Doctype </p>
                <Input disabled={false} value="Fees" />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Party *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  options={studentIdOptions}
                  value={values.party}
                  className={styles.selectInput}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      party: value,
                    });
                    setErrors({
                      ...errors,
                      party: "",
                    });
                  }}
                  showSearch // Enable search functionality
                  filterOption={(
                    input,
                    option // Define custom filtering logic
                  ) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />

                {errors.party && (
                  <p className={styles.errorMessage}>{errors.party}</p>
                )}
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Reference Name *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  options={feesOptions}
                  value={values.reference_name}
                  className={styles.selectInput}
                  onChange={(selectedOption) => {
                    const selectedFeeValue = selectedOption;

                    setValues({
                      ...values,
                      reference_name: selectedFeeValue,
                    });
                    setErrors({
                      ...errors,
                      reference_name: "",
                    });
                    handleFeeById(selectedFeeValue);
                  }}
                  showSearch // Enable search functionality
                  filterOption={(
                    input,
                    option // Define custom filtering logic
                  ) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />

                {errors.reference_name && (
                  <p className={styles.errorMessage}>{errors.reference_name}</p>
                )}
              </div>
            </div>
            <p className={styles.heading}>Transaction Details</p>
            <Divider className={styles.divider} />
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Amount *</p>
                <Input
                  placeholder="ex 10000"
                  value={values.grand_total}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      grand_total: e.target.value,
                    });
                    setErrors({
                      ...errors,
                      grand_total: "",
                    });
                  }}
                />
                {errors.grand_total && (
                  <p className={styles.errorMessage}>{errors.grand_total}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Transaction Currency </p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  options={currencyOptions}
                  value={values.currency}
                  className={styles.selectInput}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      currency: value,
                    });
                    setErrors({
                      ...errors,
                      currency: "",
                    });
                  }}
                />
                {errors.currency && (
                  <p className={styles.errorMessage}>{errors.currency}</p>
                )}
              </div>
              <div className={styles.field}></div>
            </div>
            <p className={styles.heading}>Accounting Dimensions</p>
            <Divider className={styles.divider} />
            <p className={styles.metaHeading}>
              Recipient Message And Payment Details
            </p>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>To </p>
                <Input
                  placeholder="ex Text"
                  value={values.email_to}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      email_to: e.target.value,
                    });
                    setErrors({
                      ...errors,
                      email_to: "",
                    });
                  }}
                />
                {errors.email_to && (
                  <p className={styles.errorMessage}>{errors.email_to}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Subject </p>
                <Input
                  placeholder="ex Text"
                  value={values.subject}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      subject: e.target.value,
                    });
                    setErrors({
                      ...errors,
                      subject: "",
                    });
                  }}
                />
                {errors.subject && (
                  <p className={styles.errorMessage}>{errors.subject}</p>
                )}
              </div>
              <div className={styles.field}></div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.textAreaField}>
                <p className={styles.fieldLabel}>Message </p>
                <TextArea
                  placeholder="Enter Text here..."
                  rows={3}
                  value={values.message}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      message: e.target.value,
                    });
                    setErrors({
                      ...errors,
                      message: "",
                    });
                  }}
                />
                {errors.message && (
                  <p className={styles.errorMessage}>{errors.message}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default AddNewPaymentRequest;

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  logoContainer: {
    padding: "25px 40px",
    zIndex: "999",
    position: "relative",
    background: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  labelsContainer: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    cursor: "pointer",
    marginRight: "20px", // Adjust spacing between labels
  },
  mainImage: {
    width: "100%", // Adjust image width as needed
    maxHeight: "55vh", // Limit the image height to half of the screen height
    objectFit: "fill", // Preserve aspect ratio and cover entire container
    marginBottom: "20px", // Add margin to separate from other content
  },
  boldHeading: {
    fontWeight: "bold",
  },
  cardContainer: {
    padding: "50px", // Adjust padding value as needed
    display: "flex",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    border: "none", // Remove border
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Darker shadow effect
  },
  cardFooter: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#f5f5f5",
    padding: "0px",
    marginTop: "auto", // Push the footer to the bottom
  },
  footerLeft: {
    flex: 1,
    textAlign: "center",
  },
  footerRight: {
    flex: 1,
    textAlign: "center",
  },
  locationContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "8px",
  },
  experienceText: {
    fontSize: "0.75rem",
    fontWeight: "bold",
    color: "gray",
    marginBottom: "5px",
  },
  divider: {
    margin: `0 5px`,
    marginTop: "5px",
    height: "1rem",
  },
  exp: {
    paddingTop: "0px",
  },
  detailsContainer: {
    display: "flex",
    alignItems: "center",
    paddingTop: "0px",
  },
  topContainer: {
    padding: "20px",
  },
  titleContainer: {
    display: "flex", // Display as flex to align items horizontally
    alignItems: "center", // Center align items vertically
    marginBottom: "8px", // Add margin as needed
  },
  greenBubble: {
    backgroundColor: "#c8e6c9", // Light green background color
    color: "#388e3c", // Dark green text color
    borderRadius: "16px", // Adjust border radius as needed
    padding: "0px 6px", // Reduce padding to adjust the height
    marginLeft: "16px", // Adjust margin as needed
    display: "inline-block", // Display as inline-block to fit content
    marginLeft: "auto", // Move the bubble to the right
  },
  fullTimeText: {
    fontSize: "0.75rem", // Adjust font size as needed
    color: "#388e3c", // Dark green text color
  },
  jobOpeningTitle:{
    display:'flex',
    justifyContent:'space-between',
  },
  Title:{
    fontSize:'28px',
  },
  cardBlock:{   
    
  },
  jobOpeningCards:{
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft:'4%',
    padding:'30px'
  },
  openingCards:{
    width: '33%', 
    marginBottom: '40px',
    display:'flex',
    alignItems:'center',
  },
  jobDescription:{
    fontSize:'22px',
    marginBottom:'15px'
  },
  cardHead:{
    fontSize:'14px',
    fontFamily:'Poppins',
    color: '#707070',
  },
  labels:{
    display:'column',
    marginTop: '8px',
    marginLeft:'25px'
  }
}));

export default useStyles;

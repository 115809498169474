import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { getPaymentList } from "modules/application";
import { Table } from "antd";
import { useNavigate } from "react-router-dom";

const PaymentListing = ({ uniqueId }) => {
  const navigate = useNavigate();
  const [paymentDetails, setPaymentDetails] = useState([]);

  const handleGetPaymentList = async () => {
    const res = await getPaymentList(uniqueId);
    if (res.status === 200) {
      let arr = [];
      const val = res?.data?.message;
      for (let i = 0; i < val.length; i++) {
        arr.push({
          ...val[i],
          id: i + 1,
          idx: i,
        });
      }

      setPaymentDetails(arr);
    }
  };

  useEffect(() => {
    handleGetPaymentList();
  }, []);

  const columns = [
    { dataIndex: "id", title: "ID", width: 0.3 },
    { dataIndex: "name", title: "Payment ID", width: 1 },
    { dataIndex: "payment_for", title: "Payment For", width: 1 },
    { dataIndex: "grand_total", title: "Amount", width: 1 },
    { dataIndex: "mode", title: "Mode", width: 1 },
    {
      dataIndex: "status",
      title: "Payment Status",
      width: 1,
      render: (val, row) => {
        const dynamicClassName = `status ${row.status}`;
        return (
          <>
            <span className={dynamicClassName}>{row.status}</span>
          </>
        );
      },
    },
    { dataIndex: "date", title: "Date & Time", width: 1 },
    {
      dataIndex: "idx",
      title: "Action",
      width: 0.5,
      render: (val, row) => {
        const type = row.payment_for === "Application Fee" ? 1 : 0;
        return (
          <p
            onClick={() => navigate(`/receipt/${row.name}/${type}`)}
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
            View
          </p>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={paymentDetails}
      size="small"
      style={{ width: "100%" }}
      pagination={true}
    />
  );
};

export default PaymentListing;

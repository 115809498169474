import { createSlice } from "@reduxjs/toolkit";

const academicsSlice = createSlice({
  name: "academics",
  initialState: {
    activeSidebar: 0,
  },
  reducers: {
    setActiveSidebar: (state, action) => {
      state.activeSidebar = action.payload.activeSidebar;
    },
  },
});

export const { setActiveSidebar } = academicsSlice.actions;

export default academicsSlice.reducer;

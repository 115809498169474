import APIs from "../api";
import axiosInstance from "../translator";

export const createFeeCategory = async ({ payload }) => {
  try {
    const res = axiosInstance.post(APIs.feeCategory, payload);
    return res;
  } catch (error) {
    console.log("Error while creating Fee Category", error);
    return error.response;
  }
};

export const getAllFeeCategories = async () => {
  try {
    return axiosInstance.get(`${APIs.feeCategory}?fields=["*"]`);
  } catch (error) {
    console.log("Error while getting Fee Category Listing", error);
    return error.response;
  }
};

export const getFeeCategory = async ({ name }) => {
  try {
    return axiosInstance.get(`${APIs.feeCategory}/${name}?fields=["*"]`);
  } catch (error) {
    console.log("Error while getting Fee Category by name", error);
    return error.response;
  }
};

export const createFeeStructure = async ({ payload }) => {
  try {
    return axiosInstance.post(APIs.feeStructure, payload);
  } catch (error) {
    console.log("Error while creating Fee Structure", error);
    return error.response;
  }
};

export const updateFeeStructure = async ({ payload, name }) => {
  try {
    return axiosInstance.put(`${APIs.feeStructure}/${name}`, payload);
  } catch (error) {
    console.log("Error while updating Fee Structure", error);
    return error.response;
  }
};

export const createFeeSchedule = async ({ payload }) => {
  try {
    return axiosInstance.post(APIs.feeSchedule, payload);
  } catch (error) {
    console.log("Error while creating Fee Structure", error);
    return error.response;
  }
};

export const createFeeFromFeeSchedule = async ({ payload }) => {
  try {
    return axiosInstance.post(APIs.createFeeFormFeeSchedule, payload);
  } catch (error) {
    console.log("Error while creating Fee from fee Structure", error);
    return error.response;
  }
};

export const updateFeeSchedule = async ({ payload, scheduleId }) => {
  try {
    return axiosInstance.put(`${APIs.feeSchedule}/${scheduleId}`, payload);
  } catch (error) {
    console.log("Error while updating Fee Structure", error);
    return error.response;
  }
};

export const getAllFeeStructures = async () => {
  try {
    return axiosInstance.get(`${APIs.feeStructure}?fields=["*"]&limit="*"`);
  } catch (error) {
    console.log("Error while getting Fee Structure Listing", error);
    return error.response;
  }
};

export const getFeeStructure = async ({ name }) => {
  try {
    return axiosInstance.get(`${APIs.feeStructure}/${name}?fields=["*"]&limit="*"`);
  } catch (error) {
    console.log("Error while getting Fee Structure by name", error);
    return error.response;
  }
};

export const getAcademicYearListing = async () => {
  try {
    return axiosInstance.get(`${APIs.getAcademicYearList}?fields=["name"]`);
  } catch (error) {
    console.log("Error while getting academic year Listing", error);
    return error.response;
  }
};

export const getAcademicTermListing = async () => {
  try {
    return axiosInstance.get(`${APIs.getAcademicTermlist}?fields=["name"]`);
  } catch (error) {
    console.log("Error while getting academic term Listing", error);
    return error.response;
  }
};

export const getStudentGroupListing = async (groupBasedOn) => {
  try {
    return axiosInstance.get(
      `${APIs.studentGroup}?filters=[["group_based_on","=","${groupBasedOn}"]]`
    );
  } catch (error) {
    console.log("Error while getting student groups", error);
    return error.response;
  }
};

export const getAcademicValues = async (payload) => {
  console.log("values auto fetch", payload);
  try {
    return axiosInstance.get(`${APIs.getAcademicValues}`, {
      params: {
        doctype: payload.doctype,
        docname: payload.docname,
        fields: `["academic_year", "academic_term"]`,
      },
    });
  } catch (error) {
    console.log("Error while auto fetching academic values", error);
    return error.response;
  }
};

export const getAllStudentsInGroup = async ({ payload }) => {
  console.log(payload);
  try {
    return axiosInstance.post(`${APIs.getAllStudentsInGroup}`, payload);
  } catch (error) {
    console.log("Error while getting student groups", error);
    return error.response;
  }
};

export const getBatchListing = async () => {
  try {
    return axiosInstance.get(`${APIs.getBatchName}?fields=["*"]&limit="*"`);
  } catch (error) {
    console.log("Error while getting academic term Listing", error);
    return error.response;
  }
};

export const getFeeSchedule = async () => {
  try {
    return axiosInstance.get(`${APIs.getFeeSchedule}?fields=["*"]&limit="*"`);
  } catch (error) {
    console.log("Error while getting fee schedule", error);
    return error.response;
  }
};

export const getFeeScheduleByName = async ({ name }) => {
  try {
    return axiosInstance.get(`${APIs.getFeeSchedule}/${name}?fields=["*"]`);
  } catch (error) {
    console.log("Error while getting fee schedule", error);
    return error.response;
  }
};

export const getStudentCategoryListing = async () => {
  try {
    return axiosInstance.get(`${APIs.getStudentCategoryList}?fields=["name"]`);
  } catch (error) {
    console.log("Error while getting student category Listing", error);
    return error.response;
  }
};

export const getProgramListing = async () => {
  try {
    return axiosInstance.get(`${APIs.getPrograms}?fields=["name"]`);
  } catch (error) {
    console.log("Error while getting programs Listing", error);
    return error.response;
  }
};

export const getAllPaymentRequests = async () => {
  try {
    return axiosInstance.get(`${APIs.paymentRequest}?fields=["*"]`);
  } catch (error) {
    console.log("Error while getting Payment Requests Listing", error);
    return error.response;
  }
};

export const getPaymentRequest = async ({ name }) => {
  try {
    return axiosInstance.get(`${APIs.paymentRequest}/${name}?fields=["*"]`);
  } catch (error) {
    console.log("Error while getting Payment request by name", error);
    return error.response;
  }
};

export const getModeOfPaymentListing = async () => {
  try {
    return axiosInstance.get(`${APIs.getModeOfPaymentList}?fields=["name"]`);
  } catch (error) {
    console.log("Error while getting mode of payment Listing", error);
    return error.response;
  }
};

export const getCurrencyListing = async () => {
  try {
    return axiosInstance.get(
      `${APIs.getCurrencyList}?fields=["name"]&limit="*"`
    );
  } catch (error) {
    console.log("Error while getting currency Listing", error);
    return error.response;
  }
};

export const getFeesListing = async () => {
  try {
    return axiosInstance.get(`${APIs.getFeeList}?fields=["name"]&limit="*"`);
  } catch (error) {
    console.log("Error while getting fee Listing", error);
    return error.response;
  }
};

export const getStudentsListing = async () => {
  try {
    const filters = JSON.stringify([
      ["student_email_id", "=", "amansidhu@yopmail.com"],
    ]);

    return axiosInstance.get(`${APIs.getStudentList}?fields=["*"]&limit="*"`);
  } catch (error) {
    console.log("Error while getting student Listing", error);
    return error.response;
  }
};

export const getStudentData = async (uniqueUser) => {
  try {
    const filters = JSON.stringify([["student_email_id", "=", uniqueUser]]);

    return axiosInstance.get(
      `${APIs.getStudentList}?fields=["*"]&limit="*"&filters=${filters}`
    );
  } catch (error) {
    console.log("Error while getting student Listing", error);
    return error.response;
  }
};

export const getCollegeListing = async () => {
  try {
    return axiosInstance.get(`${APIs.getCollegeList}?fields=["*"]&limit="*"`);
  } catch (error) {
    console.log("Error while getting college Listing", error);
    return error.response;
  }
};

export const getReceivableAccountListing = async (companyId) => {
  try {
    return axiosInstance.get(
      `${APIs.getReceivableAccountList}&filters={"account_type":"Receivable","is_group":0,"company":"${companyId}"}`
    );
  } catch (error) {
    console.log("Error while getting receivable account Listing", error);
    return error.response;
  }
};

export const getIncomeAccountListing = async (companyId) => {
  try {
    return axiosInstance.get(
      `${APIs.getIncomeAccountList}&filters={"account_type":"Income Account","is_group":0,"company":"${companyId}"}`
    );
  } catch (error) {
    console.log("Error while getting income account Listing", error);
    return error.response;
  }
};

export const getCostCenterListing = async (companyId) => {
  try {
    return axiosInstance.get(
      `${APIs.getCostCenterList}&filters={"account_type":"Income Account","is_group":0,"company":"${companyId}"}`
    );
  } catch (error) {
    console.log("Error while getting cost center Listing", error);
    return error.response;
  }
};

export const createPaymentRequest = async ({ payload }) => {
  try {
    return axiosInstance.post(APIs.paymentRequest, payload);
  } catch (error) {
    console.log("Error while creating Payment Request", error);
    return error.response;
  }
};

export const updatePaymentRequest = async ({ payload, name }) => {
  try {
    return axiosInstance.put(`${APIs.paymentRequest}/${name}`, payload);
  } catch (error) {
    console.log("Error while updating Fee Structure", error);
    return error.response;
  }
};

export const getAllPaymentEntry = async () => {
  try {
    return axiosInstance.get(`${APIs.paymentEntry}?fields=["*"]&limit="*"`);
  } catch (error) {
    console.log("Error while getting all payment entry", error);
    return error.response;
  }
};

export const createPaymentEntry = async ({ payload }) => {
  try {
    return axiosInstance.post(APIs.paymentEntry, payload);
  } catch (error) {
    console.log("Error creating the payment entry", error);
    return error.response;
  }
};

export const getAllFees = async () => {
  try {
    return axiosInstance.get(`${APIs.getFeeList}?fields=["*"]&limit="*"`);
  } catch (error) {
    console.log("Error while getting all fee Listing", error);
    return error.response;
  }
};

export const getFee = async (fee_id) => {
  try {
    return axiosInstance.get(`${APIs.getFeeList}/${fee_id}?fields=["*"]`);
  } catch (error) {
    console.log("Error while getting Fee by Fee id", error);
    return error.response;
  }
};

export const createFee = async ({ payload }) => {
  try {
    return axiosInstance.post(APIs.getFeeList, payload);
  } catch (error) {
    console.log("Error creating the fee", error);
    return error.response;
  }
};

export const updateFee = async ({ payload, name }) => {
  try {
    return axiosInstance.put(`${APIs.getFeeList}/${name}`, payload);
  } catch (error) {
    console.log("Error while updating Fee", error);
    return error.response;
  }
};

export const getPaymentEntry = async ({ name }) => {
  try {
    return axiosInstance.get(`${APIs.paymentEntry}/${name}?fields=["*"]`);
  } catch (error) {
    console.log("Error while getting Payment entry by name", error);
    return error.response;
  }
};

export const updatePaymentEntry = async ({ payload, name }) => {
  try {
    return axiosInstance.put(`${APIs.paymentEntry}/${name}`, payload);
  } catch (error) {
    console.log("Error while updating payment entry", error);
    return error.response;
  }
};

export const getAccountPaidToListing = async () => {
  try {
    return axiosInstance.get(APIs.getAccountPaidToList);
  } catch (error) {
    console.log("Error while getting account paid to Listing", error);
    return error.response;
  }
};

export const getAccountPaidFromListing = async () => {
  try {
    return axiosInstance.get(APIs.getAccountPaidFromList);
  } catch (error) {
    console.log("Error while getting account paid from Listing", error);
    return error.response;
  }
};

export const getGeneralLedger = async ({ formData }) => {
  try {
    return axiosInstance.post(APIs.generalLedger, formData);
  } catch (error) {
    return error.response;
  }
};

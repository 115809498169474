import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: "flex", 
    flexDirection: "column",
    gap: "28px",
    padding: "42px 28px"
  }, 
  breadCrumbsContainer: {
    display: "inline-flex", 
    gap: "4px", 
    alignItems: "center"
  },
  breadCrumbsContent: {
    color: "#6F6F6F",
    fontSize: "12px",
    fontWeight: "400",
    cursor: "pointer"
  },
  headerContainer: {
    display: "inline-flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "-20px"
  },
  title: {
    fontSize: "22px", 
    color: "#0D1021", 
    fontWeight: "500"
  },
  gridIcon: {
    color: "#647B8F"
  },
  buttonsGroup: {
    display: "inline-flex",
    gap: "15px",
    alignItems: "center",
    justifyContent: "center"
  },
  button: {
    cursor: "pointer",
    borderRadius:" 4px",
    background: "#FFF",
    padding: "4px 14px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 0px 2px 0px rgba(21, 40, 80, 0.12), 0px 2px 4px 0px rgba(21, 40, 80, 0.10)"
  },
  filterButton: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "7px",
  },
  buttonText: {
    color: "#405071",
    fontSize: "14px",
    fontWeight: "600"
  },
  activeButton: {
    cursor: "pointer",
    borderRadius:" 4px",
    background: "#A32930",
    padding: "4px 14px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 0px 2px 0px rgba(21, 40, 80, 0.12), 0px 2px 4px 0px rgba(21, 40, 80, 0.10)"
  },
  activeButtonText: {
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "400"
  },
  tableContentView: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#012148",
    cursor: "pointer",
    fontFamily: "Poppins"
  },
  tableContentFields: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#0D1021",
    fontFamily: "Poppins"
  },
  buttonPublish: {
    borderRadius: "6px",
    border: "1px solid rgba(44, 131, 236, 0.50)",
    background: "rgba(44, 131, 236, 0.05)",
    padding: "8px 10px",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#2C83EC",
    fontSize: "12px",
    fontWeight: "500",
    fontFamily: "Poppins"
  },
  buttonUnPublish: {
    borderRadius: "6px",
    border: "1px solid #BCBCBC",
    background: "rgba(131, 131, 131, 0.05)",
    padding: "8px 10px",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#6F6F6F",
    fontSize: "12px",
    fontWeight: "500",
    fontFamily: "Poppins"
  },
  viewLink: {
    cursor: "pointer",
    color: "#0D1021",
    fontWeight: "500",
    textDecoration: "underline",
    fontSize: "14px"
  },
  applicationContent: {
    marginTop: "-20px"
  },
  textField: {
    display: "flex",
    flexDirection: "column",
    width: "33%",
  },
  textAreaField: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  radioOptions: {
    display: "inline-flex",
    gap: "32px"
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "6px"
  },
  radioOptionsValue: {
    display: "inline-flex",
    gap: "12px"
  },
  labelText: {
    color: "#707070",
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "Poppins",
    letterSpacing: "0.6px"
  },
  labelValue: {
    color: "#012148",
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "Poppins",
  },
  textAreaLabelValue: {
    color: "#9DA9B0",
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "Poppins",
  },
  table: {
    width: "100%",
    padding: "12px 18px"
  },
  tableHeaderTitle: {
    color: "#647B8F",
    fontSize: "12px",
    fontWeight: "500",
    textTransform: "uppercase",
    paddingLeft: "22px"
  },
  tableRow: {
    borderBottom: "1px solid #CDD6E2"
  },
  tableContent: {
    color: "#0D1021",
    fontSize: "12px",
    fontWeight: "500",
    paddingTop: "12px"
  },
  tableDocContent: {
    color: "#0D1021",
    fontSize: "14px",
    fontWeight: "500",
    paddingTop: "12px"
  },
  tabContainer: {
    display: "inline-flex",
    aligItems: "flex-start",
    gap: "40px",
    padding: "0px 0px 8px 0px",
    borderBottom: "3px solid #E7EAEF",
    width: "100%",
  },
  tabTitleActive: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#0D1021",
    cursor: "pointer"
  },
  tabTitleInActive: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#6F6F6F",
    cursor: "pointer"
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    width: "100%",
  },
  detailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "44px",
    marginBottom: "50px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "26px",
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
  rowContainer: {
    display: "inline-flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  sectionHeader: {
    color: "#012148",
    fontSize: "18px",
    fontWeight: "500",
    paddingBottom: "8px",
    borderBottom: "2px solid #A32930"
  },
  sectionHeaderWithoutBorder: {
    color: "#012148",
    fontSize: "18px",
    fontWeight: "500",
  },
}));
 
export default useStyles;

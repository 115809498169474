import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { Popover, Table } from "antd";
import MainFooter from "components/common/MainFooter";
import MainHeader from "components/common/MainHeader";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { CommonFilters, SavedFilters } from "components/Filters";
import { setActiveSidebar } from "slices/financeSlice";
import { getAllRooms } from "modules/seatManagement";
import { setLoading } from "slices/commonSlice";
import { genericBulkDelete } from "modules/common";
import DeleteConfirmationDialog from "components/common/DeleteConfirmation";
import eventBus from "core/eventBus";
import { toast } from "react-toastify";

const SeatManagementRooms = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const { doctype, filters } = useSelector((state) => state.filters);
  const [rowData, setRowData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [openActions, setOpenActions] = useState(false);

  const columns = [
    {
      dataIndex: "id",
      title: "ID",
      width: "25%",
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.id}</p>;
      },
    },
    {
      dataIndex: "name",
      title: "Room Name",
      width: "25%",
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.room_name}</p>;
      },
    },
    {
      dataIndex: "Room Number",
      title: "Room Number",
      width: "20%",
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.room_number}</p>;
      },
    },
    {
      dataIndex: "seating_capacity",
      title: "Seating Capacity",
      width: "20%",
      render: (val, row) => {
        return (
          <p className={styles.tableContentFields}>{row.seating_capacity}</p>
        );
      },
    },
    {
      dataIndex: "criteria",
      title: "Action",
      width: "10%",
      render: (val, row) => {
        return (
          <p
            className={`${styles.tableContentView} ${styles.link}`}
            onClick={() =>
              navigate(`/modules/examination/seat-management/room/${row.id}`)
            }
          >
            View
          </p>
        );
      },
    },
  ];

  const handleGetAllRooms = async () => {
    dispatch(setLoading({ loading: true }));
    let filtersToPass = [];
    if (doctype === "Room") {
      filtersToPass = JSON.stringify(filters).slice(1, -1);
    }
    const response = await getAllRooms(filtersToPass);
    if (response.status === 200) {
      let arr = [];
      for (let i = 0; i < response.data.message.length; i++) {
        const roomDetails = response.data.message[i];
        arr.push({
          key: i,
          ...roomDetails,
        });
      }
      setRowData(arr);
    }
    dispatch(setLoading({ loading: false }));
  };

  const onSelectChange = (selectedKeys, selectedRowData) => {
    setSelectedRowKeys(selectedKeys);
    let selectedDataIds = [];
    for (let i = 0; i < selectedRowData.length; i++) {
      selectedDataIds.push(selectedRowData[i].id);
    }
    setSelectedIds([...selectedDataIds]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      id: record.id,
    }),
  };

  const handleConfirmBulkDelete = () => {
    setOpenActions(false);
    eventBus.emit(
      "open-dialog",
      <DeleteConfirmationDialog
        items={selectedRowKeys.length}
        handleBulkDelete={handleBulkDelete}
      />
    );
  };

  const handleBulkDelete = async () => {
    dispatch(setLoading({ loading: true }));
    let deleteIds = [];
    for (let i = 0; i < selectedIds.length; i++) {
      deleteIds.push({
        doctype: "Room",
        id: selectedIds[i],
      });
    }

    const payload = { delete: [...deleteIds] };
    const res = await genericBulkDelete({ payload });
    if (res.status === 200) {
      handleGetAllRooms();
      setSelectedIds([]);
      setSelectedRowKeys([]);
      toast.success(res.data.message);
    } else {
      toast.error(res.data.exc_type);
      dispatch(setLoading({ loading: false }));
    }
  };

  useEffect(() => {
    handleGetAllRooms();
  }, [filters]);

  return (
    <>
      <MainHeader />
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/dashboard");
                dispatch(setActiveSidebar({ activeSidebar: 0 }));
              }}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/modules/examination");
                dispatch(setActiveSidebar({ activeSidebar: 1 }));
              }}
            >
              Examination {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/modules/examination");
                dispatch(setActiveSidebar({ activeSidebar: 1 }));
              }}
            >
              Seat Management {" > "}
            </p>
            <p className={styles.breadCrumbsContent}>Rooms</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>Rooms</p>
            </div>
            <div className={styles.buttonsGroup}>
              <CommonFilters doctype={"Room"}></CommonFilters>
              <SavedFilters doctype={"Room"}></SavedFilters>
              {selectedRowKeys.length > 0 ? (
                <Popover
                  content={
                    <div className={styles.popoverContainer}>
                      <p
                        className={styles.popoverContent}
                        onClick={handleConfirmBulkDelete}
                      >
                        Delete
                      </p>
                    </div>
                  }
                  trigger="click"
                  open={openActions}
                  placement="bottomRight"
                  arrow={false}
                  onOpenChange={() => setOpenActions(true)}
                >
                  <div className={styles.activeButton}>
                    <p className={styles.activeButtonText}>Actions</p>
                  </div>
                </Popover>
              ) : (
                <div
                  className={styles.activeButton}
                  onClick={() =>
                    navigate(
                      "/modules/examination/seat-management/rooms/add-new-room"
                    )
                  }
                >
                  <p className={styles.activeButtonText}>+ Add New Room</p>
                </div>
              )}
            </div>
          </div>
          <Table
            rowSelection={{ type: "checkbox", ...rowSelection }}
            columns={columns}
            dataSource={rowData}
            size="small"
            pagination={true}
          />
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default SeatManagementRooms;

import APIs from "../api";
import axiosInstance from "../translator";

export const getAcademicYear = async () => {
  try {
    const res = await axiosInstance.get(APIs.getAcademicYear);
    return res;
  } catch (error) {
    console.log("Error fetching academic years", error);
  }
};

export const getAcademicTerm = async () => {
  try {
    const res = await axiosInstance.get(APIs.getAcademicTerm);
    return res;
  } catch (error) {
    console.log("Error fetching academic term", error);
  }
};
export const getProgram = async () => {
  try {
    const res = await axiosInstance.get(APIs.getProgram);
    return res;
  } catch (error) {
    console.log("Error fetching program", error);
  }
};

export const getBatch = async () => {
  try {
    const res = await axiosInstance.get(APIs.getBatch);
    return res;
  } catch (error) {
    console.log("Error fetching batch", error);
  }
};

export const getStudentCategory = async () => {
  try {
    const res = await axiosInstance.get(APIs.getStudentCategory);
    return res;
  } catch (error) {
    console.log("Error fetching student category", error);
  }
};

export const getInstructor = async () => {
  try {
    const res = await axiosInstance.get(APIs.getInstructor);
    return res;
  } catch (error) {
    console.log("Error fetching student category", error);
  }
};

export const getCourseListing = async () => {
  try {
    const res = await axiosInstance.get(APIs.getCourse);
    return res;
  } catch (error) {
    console.log("Error fetching Course", error);
  }
};

export const getStudentList = async () => {
  try {
    const fields = JSON.stringify(["*"]);
    const res = await axiosInstance.get(
      `${APIs.studentGroup}?fields=${fields}`
    );
    return res;
  } catch (error) {
    console.log("error getting list", error);
  }
};

export const getStudents = async (academic_year, group_based_on, program) => {
  try {
    console.log(academic_year, group_based_on, program);
    const res = await axiosInstance.get(APIs.getStudents, {
      params: {
        academic_year: academic_year,
        group_based_on: group_based_on,
        program: program,
      },
    });
    return res;
  } catch (error) {
    console.log("Error creating the student group", error);
    return error.response;
  }
};

export const getStudentsInFeeSchedule = async (academicYear, programme) => {
  try {
    console.log(academicYear, programme);
    const filters = JSON.stringify([
      ["Academic Year", "=", academicYear],
      ["Program", "=", programme],
    ]);
    const res = await axiosInstance.get(`${APIs.getStudentsInFeeSchedule}`);
    return res;
  } catch (error) {
    console.log("Error getting the student group", error);
    return error.response;
  }
};

export const getStudentGroupDataFeeSchedule = async (
  academicYear,
  programme
) => {
  try {
    console.log(academicYear, programme);
    const filters = JSON.stringify([
      ["Academic Year", "=", academicYear],
      ["Program", "=", programme],
    ]);
    const res = await axiosInstance.get(
      `${APIs.getStudentGroupDataFeeSchedule}&filters={"program":"${programme}","academic_year":"${academicYear}","disabled":0}`
    );
    return res;
  } catch (error) {
    console.log("Error getting the student group", error);
    return error.response;
  }
};

export const getStudentGroupList = async (email) => {
  try {
    console.log(email);
    const res = await axiosInstance.get(`${APIs.studentGroup}`);
    return res;
  } catch (error) {
    console.log("Error getting the student group", error);
    return error.response;
  }
};

export const getStudentGroupByName = async (group_name) => {
  try {
    const res = await axiosInstance.get(
      `${APIs.studentGroup}/${group_name}?fields=["*"]`
    );
    console.log(res);
    return res;
  } catch (error) {
    console.log("Error fetching student group by name:", error);
    throw error;
  }
};

export const createStudent = async ({ payload }) => {
  try {
    const res = await axiosInstance.post(APIs.studentGroup, payload);
    return res;
  } catch (error) {
    console.log("Error creating the student group", error);
    return error.response;
  }
};

export const updateStudent = async ({ id, payload }) => {
  try {
    const res = await axiosInstance.put(`${APIs.studentGroup}/${id}`, payload);
    return res;
  } catch (error) {
    console.log("Error updating the student group", error);
    return error.response;
  }
};

import React, { useEffect, useState } from "react";
import { Card, Grid, Button, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddNotes from "./AddNotes.js";
import eventBus from "../../../core/eventBus.js";
import AddIcon from "../../../assets/images/addnotes-icon.svg";
import { ReactSVG } from "react-svg";
import { getNote } from "../../../modules/application.js";
import { formatDateToDDMMM } from "../../../utils/date.js";
import TabContext from "@mui/lab/TabContext";
import LoadingBackdrop from "../../../components/common/LoadingBackdrop.js/index.js";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  leftCard: {
    margin: theme.spacing(2),
    outline: "none",
    border: "none",
    outline: "none",
    border: "none",
  },
  rightDetails: {
    margin: theme.spacing(2),
    flexGrow: 1,
    outline: "none",
    border: "none",
    outline: "none",
    border: "none",
  },
}));

const LeadNotes = ({ uniqueId }) => {
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const [selectedNote, setSelectedNote] = useState(0);
  const [notes, setNotes] = useState([]);
  const [toggle, setToggle] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handleGetNotes();
  }, [toggle]);

  const handleGetNotes = async () => {
    const res = await getNote({ id: uniqueId });
    if (res.status === 200) {
      let notesArray = res.data.data || [];
      notesArray = notesArray.filter((notes) => notes.owner == user.fullName || notes.owner == user.email);
      setNotes(notesArray);
      setLoading(false);
    }
  };

  const handleSelectNote = (index) => {
    setSelectedNote(index);
  };

  return (
      <Box
        sx={{ width: "100%", typography: "body1" }}
      >
        <div className="rightBtn">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => {
              eventBus.emit(
                "open-dialog",
                <AddNotes setToggle={setToggle} toggle={toggle} uniqueId={uniqueId} />
              );
            }}
          >
            Add Note
          </Button>
        </div>
        {
          loading ?
          <LoadingBackdrop open={loading}/> :
          <Box sx={{ height: 400, width: "100%", marginTop: "38px" }}>
            {notes && notes.length > 0 ? (
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <Card className={classes.leftCard}>
                    <div
                      style={{
                        height: "400px",
                        overflowY: "scroll",
                        borderRadius: "10px",
                        backgroundColor: "#fff",
                        border: "1px solid #EBEEF1",
                        padding: "16px 24px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <p>All Notes</p>
                        <ReactSVG
                          src={AddIcon}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            eventBus.emit("open-dialog", <AddNotes setToggle={setToggle} toggle={toggle} uniqueId={uniqueId} />);
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {notes &&
                          notes.map((noteObj, ind) => {
                            const { owner, note, creation } = noteObj;
                            return (
                              <div
                                style={{
                                  padding: "8px 16px",
                                  display: "inline-flex",
                                  justifyContent: "space-between",
                                  cursor: "pointer",
                                  backgroundColor:
                                    selectedNote === ind ? "#F6F6F6" : "#fff",
                                  borderRadius:
                                    selectedNote === ind ? "8px" : "0px",
                                }}
                                onClick={() => handleSelectNote(ind)}
                                key={ind}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "#687385",
                                      fontSize: "12px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {owner}
                                  </p>
                                  <p
                                    style={{
                                      color: "#10111A",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {note && note.substring(0, 35)}
                                    {note.length > 35 && "..."}
                                  </p>
                                </div>
                                <p
                                  style={{
                                    color: "#687385",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {formatDateToDDMMM(creation)}
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={8}>
                  <Card className={classes.rightDetails}>
                    {selectedNote >= 0 && notes && notes?.[selectedNote] && (
                      <div
                        style={{
                          height: "400px",
                          overflowY: "scroll",
                          borderRadius: "10px",
                          backgroundColor: "#F6F6F6",
                        }}
                      >
                        <p
                          style={{
                            color: "#1A1B25",
                            fontSize: "14px",
                            fontWeight: "600",
                            padding: "16px",
                            backgroundColor: "#fff",
                          }}
                        >
                          {notes[selectedNote]?.owner}
                        </p>
                        <div
                          style={{
                            borderRadius: "10px",
                            paddingTop: "22px",
                          }}
                        >
                          <p
                            style={{
                              color: "#3F495A",
                              fontSize: "14px",
                              fontWeight: "500",
                              padding: "0px 16px",
                            }}
                          >
                            {notes[selectedNote]?.note}
                          </p>
                        </div>
                      </div>
                    )}
                  </Card>
                </Grid>
              </Grid>
            ) : (
              <Grid>No Notes yet</Grid>
            )}
          </Box>
        }
      </Box>
  );
};

export default LeadNotes;

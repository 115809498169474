import React, { useState } from "react";
import "./index.scss";
import { FileUploader } from "react-drag-drop-files";
import { uploadFile } from "../../../modules/student";

function UploadButton({ formData, setFormData, fieldName, flag, setFlag, fileTypes, fieldName2 }) {
  const [loading, setLoading] = useState(false);

  const handleChange = (file) => {
    if(!loading) {
      setLoading(true);
      if (file) {
        const reader = new FileReader();
        reader.onload = (readerEvt) => {
          handleReaderLoaded(readerEvt, file.name);
        };
        reader.readAsBinaryString(file)
      }
    }
  };

  const handleReaderLoaded = (readerEvt, fileName) => {
    let binaryString = readerEvt.target.result;
    const fileData = btoa(binaryString);
    handleUploadFile({ fileName, fileData });
  }

  const handleUploadFile = async({fileName, fileData }) => {
    const res = await uploadFile({ fileName, fileData });
    if(res.status == 200) {
      const data = formData;
      data[`${fieldName}`] = res.data.message.file_url;
      data[`${fieldName2}`] = "";
      setFormData(data);
      setFlag(!flag);
    }
    setLoading(false);
  }

  return (
    <FileUploader multiple={false} handleChange={handleChange} name="file" types={fileTypes} label={loading?  "Uploading file" : "Upload File"} />
  );
}
export default UploadButton;






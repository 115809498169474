import APIs from "../api";
import axiosInstance from "../translator";

export const genericBulkDelete = async ({ payload }) => {
  try {
    const res = await axiosInstance.post(APIs.bulkActionDelete, payload);
    return res;
  } catch (error) {
    console.log("Error while bulk deleting", error);
    return error.response;
  }
};

export const genericBulkExport = async ({ payload }) => {
  try {
    const res = await axiosInstance.post(APIs.bulkActionExport, payload);
    return res;
  } catch (error) {
    console.log("Error while bulk exporting", error);
    return error.response;
  }
};

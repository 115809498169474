import  React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "../ApplicationForm/index.scss";
import { Button } from "iiht-b2a-ui-components/build/components";
import { getDataOfApplicationFormByApplicationId } from "../../modules/student";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function FormDashboard({ handleNext, handleBack }) {
  const [isAgree, setIsAgree] = useState(false);

  const handleSave = () => {
    if(!isAgree) {
      window.alert("Please agree to terms and conditions");
    } else {
      handleNext({ payload: {} , addData: false });
    }
  };

  const handleGetDataOfApplicationFormByApplicationId = async () => {
    if(window.localStorage.getItem("application-name")) {
      const applicationId = JSON.parse(window.localStorage.getItem("application-name"));
      const res = await getDataOfApplicationFormByApplicationId({ applicationId });
      if(res.status === 200) {
        setIsAgree(true);
      }
    }
  }

  useEffect(() => {
    handleGetDataOfApplicationFormByApplicationId();
  }, []);

  return (
    <>
      <Box sx={{ boxShadow: "none" }} className="formDashboard">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item className="mainPanel">
              <h3>Admission Policies</h3>
              <ol>
                <li>
                  You cannot submit more than one application form within an
                  Admission Cycle. Once you submit your application form, you are
                  required to participate in the mandatory assessments in the
                  round in which you submit your application form. Your
                  application form will be annulled if you do not participate in
                  the assessments
                </li>
                <li>
                  The choices of preferred Major(s) specified in the Course
                  Preference section of the application form are only indicative
                  in nature and not binding. Students at Techademy University will
                  have to declare their Major only by the end of their third
                  semester. Please read the discipline-specific policies carefully
                  before you indicate your major preferences
                </li>
                <li>
                  If your application form does not meet Techademy University's
                  eligibility criteria, you will not be considered for the
                  shortlist for the interview round. An application form is not
                  eligible if it reflects any of the following:
                  <ul>
                    <li>
                      Submission of more than one application form/duplicate
                      application form in one admission cycle
                    </li>
                    <li>
                      Misrepresentation of information in the application form or
                      the interview round
                    </li>
                    <li>
                      Copied or plagiarised content from elsewhere, and used in
                      the application form
                    </li>
                  </ul>
                </li>
                <li>
                  If your application form is found to be incomplete, your
                  application might not be processed within the round in which you
                  have applied. An application form will be considered as
                  incomplete if it reflects any of the following:
                  <ul>
                    <li>
                      Invalid or illegible government ID or attachment of a
                      non-government ID as proof of identity
                    </li>
                    <li>
                      Discrepancy or mismatch between scores mentioned in the
                      attached marksheet(s) and the manually entered scores in the
                      application form
                    </li>
                    <li>Password protected mandatory attachments</li>
                    <li>Illegible Class X marksheet</li>
                    <li>Illegible Class XII Final Board/Predicted marksheet</li>
                    <li>
                      While digital marksheets issued via DigiLocker will be
                      accepted, screenshots of unverified electronically generated
                      results will render the application incomplete
                    </li>
                    <li>
                      For applicants from IB and Cambridge A Levels Educational
                      Boards - If the applicant does not have their Final Class
                      XII grades/scores at the time of applying, and they do not
                      provide their Self Predicted grades/scores and the correct
                      details of their school counsellor
                    </li>
                    <li>
                      For applicants from all other Educational Boards - If the
                      applicant does not have their Final Class XII grades/scores
                      at the time of applying, and they do not provide either
                      their Self Predicted grades/scores or submit their School
                      Predicted grades/scores
                    </li>
                  </ul>
                </li>
                <li>
                  The evaluation process leading to an Offer of Admission to
                  Techademy University or a Denial of Admission is holistic and
                  merit-based as it takes into consideration academic
                  achievements, non-academic engagements and performance,
                  disadvantages, and unique context of each applicant
                </li>
                <li>
                  All decisions taken by the Admissions Committee are final. No
                  request for re-evaluation of your application will be
                  considered. Formal or informal recommendation(s) from
                  individual(s) will not be entertained or considered
                </li>
                <li>
                  Misrepresentation of facts or any information in this
                  application form might lead to the cancellation of your
                  candidature or withdrawal of your Offer of Admission at the
                  discretion of the Admissions Committee
                </li>
                <li>
                  Techademy University does not have a policy of deferral of
                  admission. An offer of admission is only valid for the academic
                  session commencing at the end of the Admission Cycle in which
                  you have applied. If you wish to delay your joining by a year or
                  more, you will have to re-apply and participate in the entire
                  admission process within the Admission Cycle for the academic
                  session you wish to join
                </li>
                <li>
                  Techademy University does not accept 'transfer of credits' from
                  other universities at the time of admission toward either
                  specific courses or the undergraduate programme. If you are
                  offered admission to Techademy University, you will have to
                  begin your academic degree as a first-year undergraduate student
                  at Techademy University, regardless of any prior time spent at
                  another educational institution. Please note that this point
                  does not apply to students who seek lateral admission as
                  transfer applicants
                </li>
                <li>
                  If you wish to apply as a transfer applicant please refer to the
                  eligibility criteria and the application process applicable to
                  transfer applicants on the Techademy University website.
                  Applicants can only apply to the undergraduate programme once in
                  one admission cycle and through one channel of application i.e.
                  either as a fresh undergraduate applicant or as a transfer
                  applicant if they meet the eligibility criteria. If you apply
                  through multiple channels, only the application which was
                  received first will be considered. Duplicate applications within
                  the same channel will not be considered and might lead to
                  termination of your candidature
                </li>
              </ol>
              <h3>Honour Pledge</h3>

              <p class="agreeText"> 
                <input type="checkbox" id="agree" onClick={() => setIsAgree(!isAgree)} checked={isAgree}/>
                <label for="agree">
                  I agree that I have read and understood the mentioned policies
                  and the instructions.
                </label>
              </p>
              <p>
                I pledge that the information provided in this form is true and to
                the best of my knowledge. If, at any time, during the course of
                the admission process or later, the information provided by me is
                found to be false, untrue, incorrect or misleading, I accept that
                the Office of Admissions of Techademy University reserves the
                right to cancel my candidature or withdraw my Offer of Admission.
              </p>
            </Item>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "row", pt: 2, justifyContent: "space-between" }}
        className="navBottomBtns"
      >
        <div style={{ display: "flex", gap: "12px"}}>
          <Button
            color="inherit"
            sx={{ mr: 1 }}
            className="backBtn"
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            onClick={() => {
              handleSave();
            }}
            sx={{ mr: 1 }}
          >
            Save
          </Button>
        </div>
        <Button sx={{ mr: 1 }} onClick={() => handleSave()}> 
          Next
        </Button>
      </Box>
    </>
  );
}

import APIs from "../api";
import axiosInstance from "../translator";

export const getFilesListing = async ({ folder, filtersToPass }) => {
  try {
    return axiosInstance.get(`${APIs.getFileList}?filters=${filtersToPass}&folder=${folder}`);
  } catch (error) {
    console.log("Error while getting Files Listing", error);
    return error.response;
  }
};

export const uploadFile = async ({ formData, config }) => {
  try {
    const res = await axiosInstance.post(APIs.customUploadFile, formData, config);
    return res;
  } catch (error) {
    console.log("Error while uploading File", error);
    return error.response;
  }
};

export const createNewFolder = async ({ folderName, fileName }) => {
  try {
    return axiosInstance.post(`${APIs.createNewFolder}?folder=${folderName}&file_name=${fileName}`);
  } catch (error) {
    console.log("Error while creating new folder", error);
    return error.response;
  }
}

export const cutPasteFile = async ({ payload }) => {
  try {
    return axiosInstance.post(APIs.cutPasteFile, payload);
  } catch (error) {
    console.log("Error while cut and pasting a file", error);
    return error.response;
  }
}
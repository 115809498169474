import APIs from "../api";
import axiosInstance from "../translator";

export const getAllEmployeeListing = async () => {
  try {
    return axiosInstance.get(`${APIs.getEmployeeList}?fields=["*"]`);
  } catch (error) {
    console.log("Error while getting employee Listing", error);
    return error.response;
  }
};

export const getAllEmployeeAttendanceListing = async () => {
  try {
    return axiosInstance.get(`${APIs.getAllAttendanceList}?doctype=Attendance`);
  } catch (error) {
    console.log("Error while getting employee Listing", error);
    return error.response;
  }
};

export const getAllShiftTypeListing = async () => {
  try {
    return axiosInstance.get(`${APIs.getShiftTypeList}?fileds=["*"]`);
  } catch (error) {
    console.log("Error while getting shift type Listing", error);
    return error.response;
  }
};

export const createEmployeeAttendance = async ({ payload }) => {
  try {
    return axiosInstance.post(APIs.employeeAttendance, payload);
  } catch (error) {
    console.log("Error while creating Employee  Attendance ", error);
    return error.response;
  }
};

export const getEmployeeAttendanceByID = async (params) => {
  try {
    return axiosInstance.get(APIs.employeeAttendanceByID, {
      params: params,
    });
  } catch (error) {
    console.log("Error while getting Payment entry by name", error);
    return error.response;
  }
};

export const updateEmployeeAttendance = async ({ payload, name }) => {
  try {
    return axiosInstance.put(`${APIs.paymentEntry}/${name}`, payload);
  } catch (error) {
    console.log("Error while creating Employee  Attendance ", error);
    return error.response;
  }
};

export const getAllLeaveTypeListing = async () => {
  try {
    return axiosInstance.get(`${APIs.getLeaveType}?fileds=["*"]`);
  } catch (error) {
    console.log("Error while getting leave type Listing", error);
    return error.response;
  }
};

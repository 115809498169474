import React, { useEffect, useState } from 'react';
import useStyles from './style';
import { GridMenuIcon } from '@mui/x-data-grid';
import { getLanguagesAvailable, getUserDetailsbyEmail, updateProfileData, updateUserProfilePicture } from 'modules/lead';
import { toast } from 'react-toastify';
import { Input, Select } from 'antd';
import UploadButton from 'components/common/UploadButton';

const StudentProfile = () => {
  const styles = useStyles();
  const [isEditMode, setEditMode] = useState(false);
  const [flag, setFlag] = useState(false);
  const [flag2, setFlag2] = useState(false);
  const [languageListing, setlanguageListing] = useState([]);
  const [isUserAdmin, setisUserAdmin] = useState(false);
  const [userData, setUserData] = useState({
    id:"",
    firstName: "",
    phone: "",
    email: "",
    dateOfJoining: "",
    gender: "",
    dob: "",
    preferredLanguage: "",
    studentId: "",
    password: "",
  });

  useEffect(() => {
    const user = getUserDetailsFromLocalStorage();
    setUserData({ ...userData, email: user.email });
    const uniqueUser = user.isAdmin ? user.name : user.email;
    setisUserAdmin(user.isAdmin ? true : false);

    const fetchUserDetails = async () => {
      try {
        const user = getUserDetailsFromLocalStorage();
        const uniqueUser = user.isAdmin ? user.name : user.email;
        let res = await getUserDetailsbyEmail(uniqueUser);
    
        if (!res.data.message || res.data.message.length === 0) {
          console.error("User not found");
          // Handle the case where user data is not found
          return;
        }
    
        res = res.data.message[0];
    
        const removeDomain = (a) => {
          if (a && a.includes("https://erp-dev.techademycampus.com")) {
              return a.replace("https://erp-dev.techademycampus.com", "");
          } else {
              return "";
          }
        }
    
        const mappedData = {
          picture: removeDomain(res.image) || "",
          id: res.student_id || "", // Using student_id for id
          firstName: res.first_name || "",
          phone: res.phone || "",
          email: res.email || "",
          dateOfJoining: res.joining_date || "",
          gender: res.gender || "",
          dob: res.birth_date || "",
          preferredLanguage: res.language_name || "",
          studentId: res.student_id || "", // Also setting studentId for consistency
          password: res.password || "",
        };
        setUserData(mappedData);
      } catch (error) {
        console.error("Error fetching user details:", error);
        // Handle the error - you can show a toast message or set a state to indicate the error
      }
    };
    

    const fetchLanguageDetails = async () => {
      try {
        let res = await getLanguagesAvailable();
        if (res.status === 200) {
          const arr = [];
          const languageList = res.data.message;

          for (let i = 0; i < languageList.length; i++) {
            arr.push({
              value: languageList[i].value,
              label: languageList[i].label,
            });
          }
          setlanguageListing(arr);
        }
      } catch (error) {
        console.error("Error fetching Language details:", error);
      }
    };

    fetchUserDetails();
    fetchLanguageDetails();
  }, [flag2]);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  const getUserDetailsFromLocalStorage = () => {
    const user = JSON.parse(window.localStorage.getItem("userDetails"));
    return user;
  };

  const handleSave = async () => {
    const user = getUserDetailsFromLocalStorage();
    const uniqueUser = user.isAdmin ? user.name : user.email;

    try {
      const payload = {
        id: userData.email, // Include the user's ID
        email: userData.email, // Use email as the identifier for updateProfileData
        first_name: userData.firstName,
        gender: userData.gender,
        birth_date: userData.dob,
        language_name: languageListing.find(
          (lang) => lang.value === userData.preferredLanguage
        )?.value,
        phone: userData.phone,
        joining_date: userData.dateOfJoining,
      };

      const imagePayload = {
        doctype: "User",
        docname: uniqueUser,
        fieldname: "user_image",
        image: userData.picture,
      };

      const res = await updateProfileData({ payload });

      if (imagePayload.image) {
        const res2 = await updateUserProfilePicture(
          imagePayload.doctype,
          imagePayload.docname,
          imagePayload.fieldname,
          imagePayload.image
        );
        if (res2.status === 200) {
        } else {
          toast.error("There is some issue while saving");
        }
      }
      if (res.status === 200) {
        toast.success("Profile Data Saved!");
      } else {
        toast.error("There is some issue while saving");
      }
    } catch (error) {
      toast.error(error);
    }

    setEditMode(false);
    setFlag2(!flag2);
  };

  const handleImgError = () => {
    setUserData((prevData) => ({
      ...prevData,
      picture: prevData.picture.replace(
        "https://iihtdev.nestorhawk.com",
        "https://erp-dev.techademycampus.com"
      ),
    }));
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerContainer}>
        <div className={styles.breadCrumbsContainer}>
          <GridMenuIcon className={styles.gridIcon}/>
          <p className={styles.title}>Profile</p>
        </div>
        <div className={styles.buttonsGroup}>
          {
            isEditMode ? 
            <div className={styles.buttonsGroup}>
              <div className={styles.button} onClick={handleCancel}>
                <p className={styles.buttonText}>Cancel</p>
              </div>
              <div className={styles.activeButton}>
                <p className={styles.activeButtonText} onClick={handleSave}>Save</p>
              </div> 
            </div> : 
            <div className={styles.buttonsGroup}>
              <div className={styles.activeButton} onClick={handleEdit}>
                <p className={styles.activeButtonText}>Edit Profile</p>
              </div>
            </div>
          }
        </div>
      </div>
      <div className={styles.formContainer}>
      <div className={styles.formRow}>
          <div className={styles.uploadField}>
            <p className={styles.labelText}>Profile Picture</p>
            <div className={styles.uploadContainer}>
              {userData.picture ? 
                <img
                  src={`https://erp-dev.techademycampus.com/${userData.picture}`}
                  alt=" Profile"
                  className={styles.profilePicUpload}
                  onError={handleImgError}
                /> : 
                <div className={styles.profilePic}></div>
              }
              {isEditMode && (
                <UploadButton
                  formData={userData}
                  setFormData={setUserData}
                  fieldName="picture"
                  flag={flag}
                  setFlag={setFlag}
                  fileTypes={["JPG", "PNG", "JPEG"]}
                />
              )}
            </div>
          </div>
          <div className={styles.field}></div>
          <div className={styles.field}></div>
        </div>
        <div className={styles.formRow}>
          <div className={styles.field}>
            <p className={styles.labelText}>First Name</p>
            {
              isEditMode ?
              <Input
                type="text"
                className={styles.fieldInput}
                value={userData.firstName}
                onChange={(e) =>
                  setUserData({ ...userData, firstName: e.target.value })
                }
              /> :
              <p className={styles.labelValue}>{userData.firstName || "-"}</p>
            }
          </div>
          <div className={styles.field}>
            <p className={styles.labelText}>Phone</p>
            {
              isEditMode ?
              <Input
                type="text"
                className={styles.fieldInput}
                value={userData.phone}
                onChange={(e) =>
                  setUserData({ ...userData, phone: e.target.value })
                }
              /> :
              <p className={styles.labelValue}>{userData.phone || "-"}</p>
            }
          </div>
          <div className={styles.field}>
            <p className={styles.labelText}>E-Mail</p>
            {
              isEditMode ?
              <Input
                type="email"
                className={styles.fieldInput}
                value={userData.email}
                onChange={(e) =>
                  setUserData({ ...userData, email: e.target.value })
                }
              /> :
              <p className={styles.labelValue}>{userData.email || "-"}</p>
            }
          </div>
        </div>
        <div className={styles.formRow}>
          {
            !isUserAdmin &&
            <div className={styles.field}>
              <p className={styles.labelText}>Date of joining</p>
              {
                isEditMode ?
                <Input
                  type="date"
                  className={styles.fieldInput}
                  value={userData.dateOfJoining}
                  onChange={(e) =>
                    setUserData({ ...userData, dateOfJoining: e.target.value })
                  }
                /> :
                <p className={styles.labelValue}>{userData.dateOfJoining || "-"}</p>
              }
            </div>
          }
          <div className={styles.field}>
            <p className={styles.labelText}>Gender</p>
            {!isEditMode ? (
              <p className={styles.labelValue}>{userData.gender || "-"}</p>
            ) : (
              <Select
                size="medium"
                placeholder="Select"
                className={styles.fieldInput}
                defaultValue={userData.gender}
                value={userData.gender}
                readOnly={!isEditMode}
                options={[
                  { label: "Male", value: "Male" },
                  { label: "Female", value: "Female" },
                ]}
                onChange={(value) => {
                  setUserData({ ...userData, gender: value });
                }}
              />
            )}
          </div>
          <div className={styles.field}>
            <p className={styles.labelText}>Student Id</p>
            {
              isEditMode ?
              <Input
                type="text"
                className={styles.fieldInput}
                value={userData.studentId}
                onChange={(e) =>
                  setUserData({ ...userData, studentId: e.target.value })
                }
              /> :
              <p className={styles.labelValue}>{userData.studentId || "-"}</p>
            }
          </div>
          {
            isUserAdmin && <div className={styles.field}></div>
          }
        </div>
        <div className={styles.formRow}>
          <div className={styles.field}>
            <p className={styles.labelText}>DOB</p>
            {
              isEditMode ?
              <Input
                type="date"
                className={styles.fieldInput}
                value={userData.dob}
                onChange={(e) =>
                  setUserData({ ...userData, dob: e.target.value })
                }
              /> :
              <p className={styles.labelValue}>{userData.dob || "-"}</p>
            }
          </div>
          <div className={styles.field}>
            <p className={styles.labelText}>Preferred language communication</p>
            {!isEditMode ? (
              <p className={styles.labelValue}>{userData.preferredLanguage || "-"}</p>
            ) : (
              <Select
                size="medium"
                placeholder="Select"
                className={styles.fieldInput}
                defaultValue={userData.preferredLanguage}
                value={userData.preferredLanguage}
                readOnly={!isEditMode}
                options={languageListing}
                onChange={(value) => {
                  setUserData({
                    ...userData,
                    preferredLanguage: value,
                  });
                }}
              />
            )}
          </div>
          <div className={styles.field}>
          </div>
        </div>
      </div>
    </div> 
  )
}

export default StudentProfile;
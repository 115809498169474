import React, { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GridMenuIcon } from "@mui/x-data-grid";
import { DeleteOutlined } from "@material-ui/icons";
import CircleIcon from "@mui/icons-material/Circle";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "iiht-b2a-ui-components/build/components";
import { Table } from "antd";
import { CustomBreadcrumb, FormSelectField, FormTextField, MainLayout } from "components/common";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { validationRules } from "constants/validationRules";
import {
  createGenericData,
  getGenericDataApi,
  getGenericListApi,
  updateGenericData,
} from "modules/hr";
import { setLoading } from "slices/commonSlice";
import { addressMapper, checkIfObjectNotEmpty } from "utils";
import { AddBoxIcon } from "icons";
import moment from "moment";

const AddOfferTermTemplate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id: offerTemplateId = undefined } = useParams();
  const [showMore, setShowMore] = useState(false);
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [offerTemplateData, setOfferTemplateData] = useState({});
  const [offerTermList, setOfferTermList] = useState([]);
  const [offerTermRows, setOfferTermRows] = useState([
    {
      id: 0,
      key: 0,
      number: 0,
      offer_term: "",
    },
  ]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const isEditMode = !!(location.pathname.includes("edit-view") && offerTemplateId);
  const [isSaveBtnDisable, setIsSaveBtnDisable] = useState(isEditMode);
  const { loading } = useSelector((state) => state.common);

  const breadcrumbs = [
    {
      label: "IIHT",
      link: "/dashboard",
    },
    {
      label: "HR",
      link: "/modules/hr",
    },
    {
      label: "Recruitment",
      link: "/modules/hr",
    },
    {
      label: "Masters",
      link: "/modules/hr/masters",
    },
    {
      label: "Job Offer Term Template",
      link: "/modules/hr/masters/offer-term-template",
    },
    {
      label: "New",
      link: "",
    },
  ];

  const columns = [
    {
      dataIndex: "number",
      title: "SNo.",
      width: "10%",
      render: (val) => val + 1,
    },
    {
      dataIndex: "",
      title: "Offer Term *",
      width: "50%",
      render: (_val, _row, rowIndex) => {
        return (
          <div style={{ maxWidth: "300px" }}>
            <FormSelectField
              name={`offer_terms.${rowIndex}.offer_term`}
              variant="outlined"
              label=""
              size="small"
              fullWidth
              onChange={(e) => {
                setValue(`offer_terms.${rowIndex}.offer_term`, e.target.value);
                setIsSaveBtnDisable(false);
              }}
              options={offerTermList}
            />
          </div>
        );
      },
    },
    {
      dataIndex: "",
      title: "Value/Description *",
      width: "40%",
      render: (_val, _row, rowIndex) => {
        return (
          <div style={{ maxWidth: "300px" }}>
            <FormTextField
              name={`offer_terms.${rowIndex}.value`}
              variant="outlined"
              size="small"
              fullWidth
              onInputChange={() => setIsSaveBtnDisable(false)}
              rules={{
                required: validationRules.REQUIRED,
              }}
            />
          </div>
        );
      },
    },
    {
      dataIndex: "",
      title: "",
      width: "60%",
    },
  ];

  const defaultValues = {
    title: "",
    offer_terms: [
      {
        offer_term: "",
        value: "",
      },
    ],
  };

  const methods = useForm({
    defaultValues: defaultValues,
  });

  const { handleSubmit, reset, watch, getValues, setValue, control } = methods;

  const { append: offerTermAppend, remove: offerTermRemove } = useFieldArray({
    control: control,
    name: "offer_terms",
  });

  const descriptionWatch = watch("description");

  const onSubmit = async (data) => {
    dispatch(setLoading({ loading: true }));
    const offer_terms = [];
    data.offer_terms?.forEach((offerTerm) => {
      offerTerm?.offer_term &&
        offer_terms.push({
          offer_term: offerTerm.offer_term,
          value: offerTerm.value,
        });
    });
    const payload = {
      doctype: "Job Offer Term Template",
      title: data.title,
      id: offerTemplateId || data.id,
      offer_terms,
    };
    const res = isEditMode ? await updateGenericData(payload) : await createGenericData(payload);
    isEditMode && fetchOfferTemplate();
    if (res?.status === 200) {
      toast.success(`Job Offer Term Template ${isEditMode ? "Updated" : "Created"}!`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsSaveBtnDisable(true);
      setIsFormSubmited(true);
    } else {
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    dispatch(setLoading({ loading: false }));
  };

  const fetchOfferTemplate = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Job Offer Term Template",
      id: offerTemplateId,
    };
    const res = await getGenericDataApi(params);
    if (res?.status === 200) {
      const data = res?.data?.message;
      setOfferTemplateData(data);
      const offer_terms = [];
      const rows = [];
      if (data.offer_terms?.length) {
        data.offer_terms.forEach((offerTermData, index) => {
          rows.push({
            id: index,
            key: index,
            number: index,
            offer_term: "",
          });
          offer_terms.push({
            offer_term: offerTermData?.offer_term,
            value: offerTermData?.value,
          });
        });
      }
      setOfferTermRows(rows);
      reset({
        title: data.title,
        offer_terms: offer_terms,
      });
    }
    dispatch(setLoading({ loading: false }));
  };

  const fetchOfferTermList = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Offer Term",
      fields: JSON.stringify(["*"]),
      filters: [],
      limit: "*",
    };
    const res = await getGenericListApi(params);
    if (res?.status === 200) {
      if (res.data?.message?.length) {
        const data = addressMapper(res.data.message, "offer_term", "id", true);
        setOfferTermList(data);
      }
    }
    dispatch(setLoading({ loading: false }));
  };

  const onSelectChange = (selectedKeys, selectedRowsData) => {
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      id: record.id,
    }),
  };

  const handleAddRow = () => {
    setOfferTermRows((prev) => {
      return [
        ...prev,
        {
          id: offerTermRows.length,
          key: offerTermRows.length,
          number: offerTermRows.length,
          offer_term: "",
        },
      ];
    });
    offerTermAppend({
      offer_term: "",
    });
  };

  const handleDeleteRow = () => {
    const offerTermData = [...offerTermRows];
    const data = [];
    let i = 0;
    offerTermData.forEach((offer_term, index) => {
      if (!selectedRowKeys.includes(offer_term.key)) {
        data.push({ ...offer_term, id: i, key: i, number: i });
        i++;
      }
    });
    setOfferTermRows(data);
    offerTermRemove(selectedRowKeys);
    setSelectedRowKeys([]);
    const { offer_terms } = getValues();
    reset({
      ...getValues(),
      offer_terms: offer_terms,
    });
    setIsSaveBtnDisable(false);
  };

  useEffect(() => {
    if (isEditMode) {
      fetchOfferTemplate();
    }
  }, [isEditMode]);

  useEffect(() => {
    if (checkIfObjectNotEmpty(offerTemplateData)) {
      const { description } = getValues();
      description !== offerTemplateData?.description
        ? setIsSaveBtnDisable(false)
        : setIsSaveBtnDisable(true);
    }
  }, [descriptionWatch, offerTemplateData]);

  useEffect(() => {
    fetchOfferTermList();
  }, []);

  const dropdownSx = {
    color: "#1C1B1F",
    backgroundColor: "#F3F3F3",
    borderRadius: "4px",
    height: "24px",
    width: "24px",
  };

  return (
    <MainLayout>
      {loading && <LoadingBackdrop open={loading} />}
      <div className="interviewTypeContainer">
        <CustomBreadcrumb breadcrumbs={breadcrumbs} />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={1} alignItems="center">
                <GridMenuIcon className="gridIcon" />
                <Typography color="#0D1021" fontSize={22}>
                  {isEditMode ? "Update Job Offer Term Template" : "New Job Offer Term Template"}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={3}>
                <Button
                  variant="contained"
                  color="grey"
                  className="qa-save-btn"
                  size="large"
                  onClick={() => navigate("/modules/hr/masters/offer-term-template")}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="qa-save-btn"
                  disabled={isSaveBtnDisable}
                >
                  {isEditMode ? "Update" : "Save"}
                </Button>
              </Stack>
            </Stack>
            <Stack sx={{ mt: 4, maxWidth: "20rem" }}>
              <Typography variant="bodyText" color="#012148" fontWeight={500} sx={{ mb: 1 }}>
                Title*
              </Typography>
              <FormTextField
                name="title"
                variant="outlined"
                size="small"
                fullWidth
                disabled={isEditMode || isFormSubmited}
                rules={{
                  required: validationRules.REQUIRED,
                }}
              />
            </Stack>

            <div className="mt18 mb20">
              <Typography color="#575A6E" fontSize={14} fontWeight="500">
                Offer Terms
              </Typography>
              <Table
                rowSelection={{ type: "checkbox", ...rowSelection }}
                columns={columns}
                dataSource={offerTermRows}
                size="small"
                pagination={false}
              />
              <div className="mt1">
                <IconButton color="primary" onClick={handleAddRow}>
                  <AddBoxIcon fill="#A32930" />
                </IconButton>
                {selectedRowKeys.length > 0 && (
                  <IconButton aria-label="delete" color="primary" onClick={handleDeleteRow}>
                    <DeleteOutlined />
                  </IconButton>
                )}
              </div>
            </div>
          </form>
        </FormProvider>

        {offerTemplateId && (
          <div className="my20">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography color="#000" variant="fieldHeadings">
                Activity
              </Typography>
              {showMore ? (
                <IconButton variant="contained" sx={dropdownSx} onClick={() => setShowMore(false)}>
                  <ExpandLessIcon />
                </IconButton>
              ) : (
                <IconButton variant="contained" sx={dropdownSx} onClick={() => setShowMore(true)}>
                  <ExpandMoreIcon />
                </IconButton>
              )}
            </Stack>
            {showMore && (
              <Stack direction="row" spacing={3} sx={{ height: "40px", mt: 2 }}>
                <Stack direction="column" justifyContent="start" alignItems="center" spacing={1}>
                  <CircleIcon sx={{ fontSize: "6px" }} />
                  <Divider orientation="vertical" fullWidth sx={{ borderColor: "#646464" }} />
                  <CircleIcon sx={{ fontSize: "6px" }} />
                </Stack>
                <Stack direction="column" justifyContent="space-between" sx={{ height: "70px" }}>
                  <Typography color="#696969" fontSize={12}>
                    Created <CircleIcon sx={{ fontSize: "8px", color: "#696969" }} />{" "}
                    {moment(offerTemplateData?.creation).format("LLL")}
                  </Typography>
                  <Typography color="#696969" fontSize={12}>
                    Edited <CircleIcon sx={{ fontSize: "8px", color: "#696969" }} />{" "}
                    {moment(offerTemplateData?.modified).format("LLL")}
                  </Typography>
                </Stack>
              </Stack>
            )}
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default AddOfferTermTemplate;

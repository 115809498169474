import React from "react";
import LoginCoverImage from "../../assets/images/new_loginimg.png";
import ResetPasswordForm from "../../components/ResetPassword/index";
import "./index.scss";

const Login = () => {
  const withAccessUi = (component) => {
    return (
      <>
        <section className="access-section-container">
          <div className="left-half-cover-image">
            <img src={LoginCoverImage} className="loginFormCoverImage" />
          </div>
          <div className="right-half-component">{component}</div>
        </section>
      </>
    );
  };

  const getComponent = () => {
    return withAccessUi(<ResetPasswordForm />);
  };

  return <div className="d-flex">{getComponent()}</div>;
};

export default Login;


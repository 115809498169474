import { createSlice } from "@reduxjs/toolkit";

const hrSlice = createSlice({
  name: "hr",
  initialState: {
    activeSidebar: 0
  },
  reducers: {
    setActiveSidebar: (state, action) => {
      state.activeSidebar =  action.payload.activeSidebar;
    }
  },
});

export const { setActiveSidebar } = hrSlice.actions;

export default hrSlice.reducer;
import React, { useState } from "react";
import useStyles from "../../../screens/Academics/Attendance/student-attendence/add-new-student-attendance/style";
import MainFooter from "components/common/MainFooter";
import { useSelector } from "react-redux";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { createGenericEntity } from "modules/masters";

const ContentManagement = ({
  data,
  selectedUnitKey,
  selectedChapterKey,
  closeAddTopicPopup,
  updateDataWithTopic,
}) => {

  const styles = useStyles();
  const { loading } = useSelector((state) => state.common);
  const [editorHtml, setEditorHtml] = useState("");
  const [values, setValues] = useState({});

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "code"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "code",
  ];

  const handleSave = async () => {
    try {
      const selectedchapter = data
        .find((unit) => unit.key === selectedUnitKey)
        .chapters.find((chapter) => chapter.key === selectedChapterKey);
      const topic = {
        author: values.author,
        title: values.title,
        publish_date: values.publishDate,
        content: editorHtml,
        key: `${selectedUnitKey}${selectedChapterKey}${
          selectedchapter.topics.length + 1
        }`,
      };

      try {
        const payload = {
          doctype: "Article",
          title: values.title,
          author: values.author,
          content: editorHtml,
          publish_date: values.placeholder,
          chapter: selectedchapter.id,
        };
        const res = await createGenericEntity({ payload });
        if (res.status === 200) {
          toast.success("Chapter saved");
        } else {
          toast.error("There is some issue while creating Topic");
        }
      } catch (error) {
        toast.error(error);
        console.log(error);
      }

      const selectedUnitIndex = data.findIndex(
        (unit) => unit.key === selectedUnitKey
      );

      if (selectedUnitIndex !== -1) {
        const updatedData = [...data];
        const selectedChapterIndex = updatedData[
          selectedUnitIndex
        ].chapters.findIndex((chapter) => chapter.key === selectedChapterKey);

        if (selectedChapterIndex !== -1) {
          const updatedChapters = [...updatedData[selectedUnitIndex].chapters];
          updatedChapters[selectedChapterIndex].topics.push(topic);
          updatedData[selectedUnitIndex].chapters = updatedChapters;
        }

        updateDataWithTopic(updatedData);
      }
      closeAddTopicPopup();
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  const handleback = () => {
    closeAddTopicPopup();
  };

  return (
    <>
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <p className={styles.title}>New Topic</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div className={styles.button} onClick={handleback}>
                <p className={styles.buttonText}>Back</p>
              </div>
              <div className={styles.activeButton}>
                <p className={styles.activeButtonText} onClick={handleSave}>
                  Save
                </p>
              </div>
            </div>
          </div>
          <div className={styles.article}>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Title *</p>
              <input
                className={styles.fieldInput}
                type="text"
                name="Title"
                value={values.title}
                onChange={(e) => {
                  setValues({ ...values, title: e.target.value });
                }}
              />
            </div>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Author Name *</p>
              <input
                className={styles.fieldInput}
                type="text"
                name="Author"
                value={values.author}
                onChange={(e) => {
                  setValues({ ...values, author: e.target.value });
                }}
              />
            </div>
            <div className={styles.bigfield}>
              <p className={styles.fieldLabel}>Content *</p>
              <ReactQuill
                theme="snow"
                className={styles.quill}
                value={editorHtml}
                onChange={setEditorHtml}
                modules={modules}
                formats={formats}
                placeholder="Write something..."
              />
            </div>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Publish Date *</p>
              <input
                type="date"
                className={styles.dateInput}
                value={values.publishDate}
                name="Publish Date"
                onChange={(e) =>
                  setValues({ ...values, publishDate: e.target.value })
                }
              />
            </div>
          </div>
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default ContentManagement;

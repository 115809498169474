import React from "react";

const FallBackScreen = () => {
  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      Something went wrong
    </div>
  );
};

export default FallBackScreen;

import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CustomBreadcrumb, FormTextField, MainLayout } from "components/common";
import { validationRules } from "constants/validationRules";
import { createGenericData, getGenericDataApi, updateGenericData } from "modules/hr";
import { setLoading } from "slices/commonSlice";
import { checkIfObjectNotEmpty } from "utils";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { Button, Stack, Typography } from "iiht-b2a-ui-components/build/components";
import { GridMenuIcon } from "@mui/x-data-grid";

const AddSkills = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id: skillId = undefined } = useParams();
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [skillData, setSkillData] = useState({});
  const { loading } = useSelector((state) => state.common);
  const isEditMode = !!(location.pathname.includes("edit-view") && skillId);
  const [isSaveBtnDisable, setIsSaveBtnDisable] = useState(isEditMode);
  const breadcrumbs = [
    {
      label: "IIHT",
      link: "/dashboard",
    },
    {
      label: "HR",
      link: "/modules/hr",
    },
    {
      label: "Recruitment",
      link: "/modules/hr",
    },
    {
      label: "Masters",
      link: "/modules/hr/masters",
    },
    {
      label: "Skills",
      link: "/modules/hr/masters/skills",
    },
    {
      label: "Add",
      link: "",
    },
  ];

  const defaultValues = {
    skill_name: "",
    confirmCheckBox: false,
    description: "",
  };

  const methods = useForm({
    defaultValues: defaultValues,
  });

  const { handleSubmit, reset, watch, getValues } = methods;
  const descriptionWatch = watch("description");

  const onSubmit = async (data) => {
    dispatch(setLoading({ loading: true }));
    const payload = {
      doctype: "Skill",
      skill_name: data.skill_name,
      description: data.description,
      id: skillId || data.id,
    };
    const res = isEditMode ? await updateGenericData(payload) : await createGenericData(payload);
    isEditMode && getInterviewType();
    if (res?.status === 200) {
      toast.success(`Skill ${isEditMode ? "Updated" : "Created"}!`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsSaveBtnDisable(true);
      setIsFormSubmited(true);
    } else {
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    dispatch(setLoading({ loading: false }));
  };

  const getInterviewType = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Skill",
      id: skillId,
    };
    const res = await getGenericDataApi(params);
    if (res?.status === 200) {
      const data = res?.data?.message;
      setSkillData(data);
      reset({
        ...data,
      });
    }
    dispatch(setLoading({ loading: false }));
  };

  useEffect(() => {
    if (isEditMode) {
      getInterviewType();
    }
  }, [isEditMode]);

  useEffect(() => {
    if (checkIfObjectNotEmpty(skillData)) {
      const { description } = getValues();
      description !== skillData?.description
        ? setIsSaveBtnDisable(false)
        : setIsSaveBtnDisable(true);
    }
  }, [descriptionWatch, skillData]);

  return (
    <MainLayout>
      {loading && <LoadingBackdrop open={loading} />}
      <div className="interviewTypeContainer">
        <CustomBreadcrumb breadcrumbs={breadcrumbs} />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={1} alignItems="center">
                <GridMenuIcon className="gridIcon" />
                <Typography color="#0D1021" fontSize={22}>
                  {isEditMode ? "View Skill" : "New Skill"}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={3}>
                <Button
                  variant="contained"
                  color="grey"
                  className="qa-save-btn"
                  size="large"
                  onClick={() => navigate("/modules/hr/masters/skills")}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="qa-save-btn"
                  disabled={isSaveBtnDisable}
                >
                  {isEditMode ? "Update" : "Save"}
                </Button>
              </Stack>
            </Stack>
            <Stack sx={{ mt: 4, maxWidth: "20rem" }}>
              <Typography variant="bodyText" color="#012148" fontWeight={500} sx={{ mb: 1 }}>
                Skill Name*
              </Typography>
              <FormTextField
                name="skill_name"
                variant="outlined"
                size="small"
                fullWidth
                disabled={isEditMode || isFormSubmited}
                rules={{
                  required: validationRules.REQUIRED,
                }}
              />
            </Stack>
            <div>
              <Typography variant="bodyText" color="#012148" fontWeight={500} sx={{ mb: 1 }}>
                Description
              </Typography>
              <FormTextField
                name="description"
                variant="outlined"
                size="small"
                placeholder="Enter Text here..."
                fullWidth
                multiline
                rows={5}
              />
            </div>
          </form>
        </FormProvider>
      </div>
    </MainLayout>
  );
};

export default AddSkills;

import APIs from "../api";
import axiosInstance from "../translator";

const paymentDetails = {
  invoice_details: {
    mode_of_payment: "Wire Transfer",
  },
};

export const razorpayCheckout = ({studentApplicantId, applicationFee }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const razorpayScript = await loadRazorpaySource(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!razorpayScript) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      const headers = {
        "Content-Type": "application/json",
      };

      paymentDetails.invoice_details = {
        ...paymentDetails.invoice_details,
        student_applicant_id: studentApplicantId,
        application_fee: applicationFee,
      }

      const order = await axiosInstance.post(
        `${APIs.createInvoice}`,
        paymentDetails,
        { headers }
      );

      const {
        amount,
        api_key,
        currency,
        description,
        order_id,
        payer_email,
        payer_name,
        reference_docname,
        reference_doctype,
        subscription_id,
        title,
        token,
      } = order.data.message.razorpay_details;

      var options = {
        key: api_key, // Enter the Key ID generated from the Dashboard
        amount: amount , // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: currency,
        name: title, //your business name
        description: description,
        order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: async function (response) {
          const callback = await axiosInstance.post(
            `${APIs.paymentCallback}`,
            {
              options: options,
              ...{
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                // razorpay_signature: response.razorpay_signature,
                reference_docname,
                reference_doctype,
                token,
              },
            },
            { headers }
          );
          if (callback.data.message === "Success") {
            resolve({ message: "Success" });
          }
          console.log();
        },
        prefill: {
          //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
          name: payer_name, //your customer's name
          email: payer_email,
        },
        notes: {
          token: token,
        },
        theme: {
          color: "#3399cc",
        },
      };

      var rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        alert(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
      });
      rzp1.open();
    } catch (error) {
      reject({ message: "Failed" });
    }
  });
};

function loadRazorpaySource(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

import React, { useState, useEffect } from "react";
import { Button, Popover, Table } from "antd";
import { Breadcrumbs, MainLayout } from "components/common";
import { GridMenuIcon } from "@mui/x-data-grid";
import { getGenericList, updateFieldsInBulk } from "modules/hr";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../../style.scss";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "slices/commonSlice";
import { CommonFilters, SavedFilters } from "components/Filters";
import useStyles from "../LeaveBlockList/get-update-leave-block-list/style";

import { toast } from "react-toastify";
import { genericBulkDelete } from "modules/common";
import DeleteConfirmationDialog from "components/common/DeleteConfirmation";
import eventBus from "core/eventBus";
import APIs from "api";
import BulkEdit from "components/common/BulkEdit";

const Index = () => {
  const [data, setData] = useState([]);
  const styles = useStyles();
  const [rowData, setRowData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation(); // Initialize useLocation hook
  const loading = useSelector((state) => state.common.loading);
  const dispatch = useDispatch();
  const { doctype, filters } = useSelector((state) => state.filters);

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openActions, setOpenActions] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  useEffect(() => {
    // Check if the location pathname contains "new"
    if (!location.pathname.includes("new")) {
      handleLeavePolicyList();
    }
  }, [location.pathname, filters]); // Trigger effect when location.pathname changes

  const handleLeavePolicyList = async () => {
    let filtersToPass = [];
    if (doctype === "Leave Policy") {
      filtersToPass = JSON.stringify(filters).slice(1, -1);
    }
    dispatch(setLoading({ loading: true }));
    try {
      const doctype = "Leave Policy";
      const leavePolicyList = await getGenericList(doctype, filtersToPass);

      const leavePolicyWithId = leavePolicyList.map((leavePolicy, index) => ({
        ...leavePolicy,
        entityType: "Leave Policy",
      }));
      setData(leavePolicyWithId);
      setRowData(leavePolicyWithId);

      console.log(
        leavePolicyWithId,
        "Leave policy with Id: " + leavePolicyWithId
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    dispatch(setLoading({ loading: false }));
  };

  const onSelectChange = (selectedKeys, selectedRowData) => {
    setSelectedRowKeys(selectedKeys);
    let selectedDataIds = [];
    for (let i = 0; i < selectedRowData.length; i++) {
      selectedDataIds.push(selectedRowData[i].id);
    }
    setSelectedIds([...selectedDataIds]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      id: record.id,
    }),
  };

  const handleConfirmBulkDelete = () => {
    setOpenActions(false);
    eventBus.emit(
      "open-dialog",
      <DeleteConfirmationDialog
        items={selectedRowKeys.length}
        handleBulkDelete={handleBulkDelete}
      />
    );
  };

  const handleBulkDelete = async () => {
    dispatch(setLoading({ loading: true }));
    let deleteIds = [];
    for (let i = 0; i < selectedIds.length; i++) {
      deleteIds.push({
        doctype: "Leave Policy",
        id: selectedIds[i],
      });
    }

    const payload = { delete: [...deleteIds] };
    const res = await genericBulkDelete({ payload });
    if (res.status === 200) {
      handleLeavePolicyList();
      setSelectedIds([]);
      setSelectedRowKeys([]);
      toast.success(res.data.message);
    } else {
      toast.error(res.data.exc_type);
      dispatch(setLoading({ loading: false }));
    }
  };

  const handleBulkExport = async () => {
    setOpenActions(false);
    dispatch(setLoading({ loading: true }));
    let exportIds = [];
    for (let i = 0; i < selectedIds.length; i++) {
      exportIds.push({
        doctype: "Leave Policy",
        id: selectedIds[i],
      });
    }

    const payload = { export: [...exportIds] };
    const response = await fetch(APIs.bulkActionExport, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      dispatch(setLoading({ loading: false }));
      setSelectedIds([]);
      setSelectedRowKeys([]);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      toast.success("Data exported!");
      link.setAttribute("download", "LeavePolicyList-Data.xlsx");
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error("Something went wrong");
      dispatch(setLoading({ loading: false }));
    }
  };

  const handleBulkEdit = async ({ customfieldname, value }) => {
    const payload = {
      doctype: "Leave Policy",
      docnames: selectedIds,
      customfieldname,
      value,
    };
    dispatch(setLoading({ loading: true }));
    setIsEditOpen(false);
    const res = await updateFieldsInBulk({ payload });
    if (res.status === 200) {
      handleLeavePolicyList();
      setSelectedIds([]);
      setSelectedRowKeys([]);
      toast.success(res.data.message);
    } else {
      toast.error(res.data.exc_type);
      dispatch(setLoading({ loading: false }));
    }
  };

  const commonActionColumn = {
    title: "Action",
    dataIndex: "action",
    render: (text, row) => (
      <Link to={`/modules/hr/leave-management/masters/leave-policy/${row.id}`}>
        View
      </Link>
    ),
  };

  const columns = [
    {
      title: "No.",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Policy Name",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Policy Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Status",
      dataIndex: "custom_status",
      key: "custom_status",
    },
    commonActionColumn,
  ];

  const CustomToolbar = () => {
    const handleAddButtonClick = () => {
      navigate("/modules/hr/leave-management/masters/leave-policy/new");
    };

    return (
      <>
        <Breadcrumbs />
        <div className="master-heading">
          <h2>
            <GridMenuIcon />
            {`Leave policy`}
          </h2>
          <div className={styles.buttonsGroup}>
            <CommonFilters doctype={"Leave Policy"}></CommonFilters>
            <SavedFilters doctype={"Leave Policy"}></SavedFilters>
            {selectedRowKeys.length > 0 ? (
              <Popover
                content={
                  <div className={styles.popoverContainer}>
                    <p
                      className={styles.popoverContent}
                      onClick={() => {
                        setOpenActions(false);
                        setIsEditOpen(true);
                      }}
                    >
                      Edit
                    </p>
                    <p
                      className={styles.popoverContent}
                      onClick={handleBulkExport}
                    >
                      Export
                    </p>
                    <p
                      className={styles.popoverContent}
                      onClick={handleConfirmBulkDelete}
                    >
                      Delete
                    </p>
                  </div>
                }
                trigger="click"
                open={openActions}
                placement="bottomRight"
                arrow={false}
                onOpenChange={() => setOpenActions(true)}
              >
                <div className={styles.activeButton}>
                  <p className={styles.activeButtonText}>Actions</p>
                </div>
              </Popover>
            ) : (
              <Button
                variant="outlined"
                className="redBtn"
                onClick={handleAddButtonClick}
              >
                {`Add Leave Policy List `}
              </Button>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <MainLayout>
      <CustomToolbar />
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <Table
          className="mainTable"
          rowKey="id"
          columns={columns}
          dataSource={rowData}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
        />
      )}
      {isEditOpen && (
        <BulkEdit
          handleBulkEdit={handleBulkEdit}
          doctype="Leave Policy"
          isEditOpen={isEditOpen}
          setIsEditOpen={setIsEditOpen}
          items={selectedIds.length}
        />
      )}
    </MainLayout>
  );
};

export default Index;

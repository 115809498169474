import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import store from "./store";
import "./i18n";
import "./index.css";
import "./index.scss";
import "react-quill/dist/quill.snow.css";
import "react-toastify/dist/ReactToastify.css";
import 'react-photo-view/dist/react-photo-view.css';
import 'react-phone-input-2/lib/style.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);

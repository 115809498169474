import { createSlice } from "@reduxjs/toolkit";

const admissionSlice = createSlice({
  name: "admission",
  initialState: {
    activeSidebar: 0
  },
  reducers: {
    setActiveSidebar: (state, action) => {
      state.activeSidebar =  action.payload.activeSidebar;
    }
  },
});

export const { setActiveSidebar } = admissionSlice.actions;

export default admissionSlice.reducer;

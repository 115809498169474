import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Button } from "iiht-b2a-ui-components/build/components";
import "./index.scss";
import { getDataOfApplicationFormByApplicationId } from "../../modules/student";
import {getAddmissionPreferenceType, getProgramlists} from '../../modules/studentApplication'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function FormCoursePref({ handleNext, handleBack }) {
  const [admissionPreference,setAdmissionPreference] = useState([]);
  const [coursePreferenceList, setCoursePreferenceList] = useState([]);
  const [selectedAdmissionPreference, setSelectedAdmissionPreference] = useState();
  const [rows, setRows] = useState([
    { id: 1, fieldName: "", preferenceType: 1 },
    { id: 2, fieldName: "", preferenceType: 2 },
    { id: 3, fieldName: "", preferenceType: 3 },
  ]);

  const [errors, setErrors] = useState({
    1: false,
    2: false,
    3: false,
  });

  const handleChange = (index, fieldName) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index].fieldName = fieldName;
      return updatedRows;
    });
    setErrors((prevErrors) => ({ ...prevErrors, [index + 1]: false }));
  };

  const handleAdmissionPreference = async () => {
    const res =  await getAddmissionPreferenceType();
    if(res.status === 200) {
      setAdmissionPreference(res.data?.data)
    }
  }

  const mapData = () => {
    const dataMap = {};
    rows.forEach((row, index) => {
      dataMap[`custom_preference_${index + 1}_`] = row.fieldName;
    });
    return dataMap;
  };

  const handleSave = () => {
    let mappedData = mapData();
    mappedData = {
      ...mappedData,
      custom_admission_type: selectedAdmissionPreference
    }
    const newErrors = {};
    rows.forEach((row, index) => {
      if (row.fieldName.trim() === "") {
        newErrors[index + 1] = true;
      }
    });

    // If there are errors, update the state and do not proceed
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    // Use the mapped data as needed, for example, log it to the console
    handleNext({ payload: mappedData, addData: false });
  };

  const handleGetDataOfApplicationFormByApplicationId = async () => {
    if (window.localStorage.getItem("application-name")) {
      const applicationId = JSON.parse(window.localStorage.getItem("application-name"));
      const res = await getDataOfApplicationFormByApplicationId({ applicationId });
      if (res.status == 200) {
        const data = res.data.data;
        setRows([
          { id: 1, fieldName: data.custom_preference_1_, preferenceType: 1 },
          { id: 2, fieldName: data.custom_preference_2_, preferenceType: 2 },
          { id: 3, fieldName: data.custom_preference_3_, preferenceType: 3 },
        ]);
        setSelectedAdmissionPreference(data.custom_admission_type);
      }
    }
  }

  const handleGetProgramsList = async() => {
    const res = await getProgramlists();
    if(res.status === 200) {
      setCoursePreferenceList(res.data?.data);
    }
  }

  useEffect(() => {
    handleAdmissionPreference();
    handleGetProgramsList();
    handleGetDataOfApplicationFormByApplicationId();
  },[])

  return (
    <>
      <Box sx={{ boxShadow: "none" }} className="formPersonalDetails">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item className="mainFormBody">
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={12}>
                  <Item>
                    <h4>Course Preference</h4>
                  </Item>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="body1">
                    <p>
                      Note: The choices of preferred Majors specified in this
                      section are only indicative in nature and not binding.
                      Students who are enrolled at IIHT University need to declare
                      their final choice of Major only at the end of their third
                      semester.
                    </p>
                    <p>
                      Please click here to read IIHT University's
                      discipline-specific policies before you select your
                      preferred major(s).
                    </p>
                  </Typography>
                </Grid>

                {/* Render fixed number of Preferences */}
                {[1, 2, 3].map((preference) => (
                  <React.Fragment key={preference}>
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography variant="body1">{`Preference ${preference}`}</Typography>
                      <select
                       label={`Preference${preference}`}
                       id={`preference${preference}`}
                       placeholder="Enter preference"
                       value={rows[preference - 1].fieldName}
                       onChange={(e) => handleChange(preference - 1, e.target.value)}
                      >
                        <option value="" disabled selected={rows[preference - 1].fieldName}>Select Preference</option>
                        {
                          coursePreferenceList.map((preferenceData) => {
                            return <option key={preferenceData.name} value={preferenceData.name} selected={preferenceData.name == rows[preference - 1].fieldName}>
                              {preferenceData.name}
                            </option>
                          })
                        }
                      </select>
                      {/* <div key={preference}>
                        <input
                          style={{
                            height: "40px",
                            width: "100%",
                            padding:'12px',
                            borderRadius: "4px",
                            border: "1px solid #CDD6E2",
                            backgroundColor: "#FFF",
                          }}
                          label={`Preference${preference}`}
                          id={`preference${preference}`}
                          placeholder="Enter preference"
                          value={rows[preference - 1].fieldName}
                          onChange={(e) => handleChange(preference - 1, e.target.value)}
                        /> */}
                        {errors[preference] && (
                          <p variant="caption" style={{ color: "red", marginTop: "3px", fontSize:'14px' }}>
                            Required
                          </p>
                        )}
                      {/* </div> */}
                    </Grid>
                    <Grid item xs={2} sm={7} md={7}>
                      &nbsp;
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={12}>
                  <Item>
                    <h4>Admissions Preference</h4>
                  </Item>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="body1">
                    <p>
                      Note: The choices of preferred Majors specified in this
                      section are only indicative in nature and not binding.
                      Students who are enrolled at IIHT University need to declare
                      their final choice of Major only at the end of their third
                      semester.
                    </p>
                    <li>
                      Please click here to read IIHT University's
                      discipline-specific policies before you select your
                      preferred major(s).
                    </li>
                  </Typography>
                  <Typography>Admission Type *</Typography>
                  <Grid item xs={2} sm={4} md={4}>
                  <select
                        name="relation"
                        value={selectedAdmissionPreference}
                        onChange={(e) => setSelectedAdmissionPreference(e.target.value)}
                      >
                        <option value="" disabled selected>Admission Preference</option>
                      {
                        admissionPreference.map((item, index) => {
                          return (
                            <option key={index} value={item.name}>{item.name}</option>
                          );
                        })
                      }
                  </select> 
                  </Grid> 
                </Grid>
              </Grid>
            </Item>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "row", pt: 2, justifyContent: "space-between" }}
        className="navBottomBtns"
      >
        <div style={{ display: "flex", gap: "12px"}}>
          <Button
            color="inherit"
            sx={{ mr: 1 }}
            className="backBtn"
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            onClick={() => {
              handleSave();
            }}
            sx={{ mr: 1 }}
          >
            Save
          </Button>
        </div>
        <Button sx={{ mr: 1 }} onClick={() => handleSave()}> 
          Next
        </Button>
      </Box>
    </>
  );
}

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import { createEvent, getEvents } from "../../../modules/application.js";
import AddEvent from "./AddEvent.js";
import TabContext from "@mui/lab/TabContext";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import LoadingBackdrop from "../../../components/common/LoadingBackdrop.js";
import { getStudentDetails } from "../../../modules/studentApplication.js";

const columns = [
  {
    field: "subject",
    headerName: "Subject",
    width: 600,
  },
  {
    field: "event_type",
    headerName: "Event Type",
    width: 200,
    renderCell: ({ row }) => {
      const dynamicClassName = `statusEvent ${row.event_type}`;
      return (
        <>
          <span className={dynamicClassName}>{row.event_type}</span>
        </>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 200,
    renderCell: ({ row }) => {
      const dynamicClassName = `statusEventOpen ${row.status}`;
      return (
        <>
          <span className={dynamicClassName}>{row.status}</span>
        </>
      ); 
    },
  },
  {
    field: "name",
    headerName: "ID",
    sortable: false,
    width: 200,
  },
];

export default function DataGridDemo({ uniqueId }) {
  const user = useSelector((state) => state.user);
  const [leadEmail, setLeadEmail] = useState("");
  const [events, setEvents] = useState([]);
  const [showAddEvent, setShowAddEvent] = useState(false);
  const [loading, setLoading] = useState(true);
  const [addEventLoading, setAddEventLoading] = useState(false);
  const [values, setValues] = useState({
    subject: "",
    startOn: "",
    endsOn: "",
    eventCategory: "Event",
    status: "Open",
    eventType: "Private",
    addGuests: "",
    color: "",
    sendEmail: 0,
    repeat: 0,
    description: "",
  });

  useEffect(() => {
    handleGetEvents();
    handleGetStudentDetails();
  }, [showAddEvent]);

  const handleGetEvents = async () => {
    setLoading(true);
    const res = await getEvents({ id: uniqueId });
    if (res.status === 200) {
      setLoading(false);
      let data = res?.data?.data;
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        let val = {
          ...data[i],
          id: data[i].name,
        };
        arr.push(val);
      }
      setEvents(arr);
    } else {
      setLoading(false);
    }
  };

  const handleGetStudentDetails = async () => {
    const res = await getStudentDetails( uniqueId );
    if (res.status === 200) {
      setLeadEmail(res.data.data.student_email_id);
      setValues({ ...values, addGuests: `${res.data.data.student_email_id}, ${user.email}`});
    }
  };
  
  const handleCreateEvent = async () => {
    setAddEventLoading(true);
    let guests = [];
    guests.push(
      {
        reference_doctype: "Student Applicant",
        reference_docname: uniqueId,
        parentfield: "event_participants",
        parenttype: "Event",
        doctype: "Event Participants",
        email: leadEmail,
      },
      {
        reference_doctype: "User",
        reference_docname: user.fullName,
        parentfield: "event_participants",
        parenttype: "Event",
        doctype: "Event Participants",
        email: user.email,
      }
    );

    const payload = {
      starts_on: values.startOn,
      ends_on: values.endsOn,
      subject: values.subject,
      event_category: values.eventCategory,
      event_type: values.eventType,
      status: values.status,
      send_reminder: values.sendEmail,
      repeat_this_event: values.repeat,
      event_participants: guests,
      sync_with_google_calendar: 1,
      add_video_conferencing: 1,
      google_calendar: "Main",
      send_reminder: 1,
      description: values.description,
    };

    const res = await createEvent({ payload });
    if (res.status === 200) {
      setAddEventLoading(false);
      setShowAddEvent(false);
      toast.success("Event created Successfully !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setAddEventLoading(false);
      toast.error(res.data?.exc_type, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <Box
      sx={{ width: "100%", typography: "body1" }}
    >
      {
        showAddEvent && !addEventLoading ? <div className="rightBtn">
          <Button
            variant="contained"
            style={{
              marginRight: "8px",
              backgroundColor: "white",
              color: "gray",
            }}
            onClick={() => setShowAddEvent(false)}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (!loading) {
                handleCreateEvent();
              }
            }}
          >
            {showAddEvent && addEventLoading ? "Loading..." : "Save"}
          </Button>
        </div> : !addEventLoading && <div className="rightBtn">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => {
              setShowAddEvent(true);
            }}
          >
            Add Event
          </Button>
        </div>
      }
      <Box sx={{ height: 400, width: "100%" }}>
        {showAddEvent ? (
          <AddEvent
            loading={addEventLoading}  
            setValues={setValues}
            values={values}
          />
        ) : (
          <>
            {loading ? (
              <LoadingBackdrop open={loading}/>
            ) : !loading && events.length > 0 ? (
              <DataGrid
                rows={events}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
              />
            ) : (
              <>
              <p>No Events Created yet</p>
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

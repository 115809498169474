import React, { useEffect, useState } from 'react';
import useStyles from './style';
import { Input, Popover, Table } from "antd";
import { useNavigate, useParams } from 'react-router-dom';
import { GridMenuIcon } from '@mui/x-data-grid';
import MainHeader from '../../../common/MainHeader';
import MainFooter from '../../../common/MainFooter';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveSidebar } from '../../../../slices/financeSlice.js';
import LoadingBackdrop from "../../../common/LoadingBackdrop.js";
import { setLoading } from '../../../../slices/commonSlice.js';
import { getMeritListingByName, updateWithdrawStatus } from '../../../../modules/admission.js';
import "./index.scss";
import WithdrawApplications from 'components/common/WithdrawApplications';
import eventBus from 'core/eventBus';
import APIs from 'api';
import { toast } from 'react-toastify';

const GetAndUpdateMeritList = () => {
  const styles= useStyles();
  const navigate= useNavigate();
  const dispatch = useDispatch();
  const { meritList } = useParams();
  const { loading } = useSelector((state) => state.common);
  const [rowData, setRowData] = useState([]);
  const [meritListDetails, setMeritListDetails] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [openActions, setOpenActions] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [analytics, setAnalytics] = useState({
    withdrawn: 0,
    admitted: 0,
    rejected: 0
  });
  const [filterValues, setFilterValues] = useState({
    applicationId: "",
    name: "",
    programGroup: "",
    program: "",
    college: "",
    status: ""
  });

  const handleMeritListNavigation = () => {
    dispatch(setActiveSidebar({ activeSidebar: 3 }));
    navigate("/modules/admission");
  };

  const columns = [
    {
      dataIndex: "applicationId",
      title: "Application Id",
      width: "15%",
      render: (val, row, index) => {
        return (
          <>
            {
              index === 0 ? 
              <Input type="text" value={filterValues.applicationId} onChange={(e) => {
                setFilterValues({
                ...filterValues,
                  applicationId: e.target.value
                });
              }}/> :
              <p className={styles.tableContentFields}>{row.applicationId}</p>
            }
          </>
        );
      },
    }, 
    {
      dataIndex: "name",
      title: "STUDENT NAME",
      width: "15%",
      render: (val, row, index) => {
        return (
          <>
            {
              index === 0 ? 
              <Input type="text" value={filterValues.name} onChange={(e) => {
                setFilterValues({
                  ...filterValues,
                  name: e.target.value
                });
              }}/> :
              <p className={styles.tableContentFields}>{row.name}</p>
            }
          </>
        );
      },
    },
    {
      dataIndex: "programGroup",
      title: "PROGRAM GROUP",
      width: "10%",
      render: (val, row, index) => {
        return (
          <>
            {
              index === 0 ? 
              <Input type="text" value={filterValues.programGroup} onChange={(e) => {
                setFilterValues({
                  ...filterValues,
                  programGroup: e.target.value
                });
              }}/> :
              <p className={styles.tableContentFields}>{row.programGroup}</p>
            }
          </>
        );
      },
    },
    {
      dataIndex: "program",
      title: "PROGRAM",
      width: "10%",
      render: (val, row, index) => {
        return (
          <>
            {
              index === 0 ? 
              <Input type="text" value={filterValues.program} onChange={(e) => {
                setFilterValues({
                  ...filterValues,
                  program: e.target.value
                });
              }}/> :
              <p className={styles.tableContentFields}>{row.program}</p>
            }
          </>
        );
      },
    },
    {
      dataIndex: "college",
      title: "COLLEGE",
      width: "15%",
      render: (val, row, index) => {
        return (
          <>
            {
              index === 0 ? 
              <Input type="text" value={filterValues.college} onChange={(e) => {
                setFilterValues({
                  ...filterValues,
                  college: e.target.value
                });
              }}/> :
              <p className={styles.tableContentFields}>{row.college}</p>
            }
          </>
        );
      },
    }, 
    {
      dataIndex: "status",
      title: "STATUS",
      width: "15%",
      render: (val, row, index) => {
        const dynamicClassName = `status ${row.status}`;
        return (
          <>
            {
              index === 0 ? 
              <Input type="text" value={filterValues.status} onChange={(e) => {
                setFilterValues({
                  ...filterValues,
                  status: e.target.value
                });
              }}/> :
              <p className={dynamicClassName}>{row.status}</p>
            }
          </>
        );
      },
    }, 
    {
      dataIndex: "isWithdraw",
      title: "IS WITHDRAWN",
      width: "10%",
      render: (val, row, index) => {
        return (
          <>
            { 
              index !== 0 &&
              <input type="checkbox" checked={row.isWithdraw} className={styles.checkbox} />
            }
          </>
        );
      },
    },
    {
      dataIndex: "action",
      title: "ACTION",
      width: "10%",
      render: (val, row, index) => {
        return (
          <>
            {
              index !== 0 &&
              <p className={`${styles.tableContentView} ${styles.link}`}
                onClick={() => {
                  const url = `/applications/${row.applicationId}`;
                  window.open(url, '_blank');
                }}>View</p>
            }
          </>
        );
      },
    },
  ];

  const onSelectChange = (selectedKeys, selectedRowData) => {
    setSelectedRowKeys(selectedKeys);
    let selectedDataIds = [];
    for(let i = 0 ; i < selectedRowData.length ; i++) {
      selectedDataIds.push(selectedRowData[i].applicationId);
    }
    setSelectedIds([...selectedDataIds]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      id: record.id,
    }),
  };

  const handleConfirmWithdrawApplications = () => {
    setOpenActions(false);
    eventBus.emit("open-dialog", <WithdrawApplications items={selectedRowKeys.length} handleWithdrawApplication={handleWithdrawApplication}/>)
  };

  const handleWithdrawApplication = async () => {
    let newMeritListApplications = [];
    meritListDetails.merit_list.map((data) => {
      if(selectedIds.includes(data.student_applicant)) {
        newMeritListApplications.push({
          ...data,
          is_withdraw: 1
        });
      } else {
        newMeritListApplications.push(data);
      }
    });

    const payload = { ...meritListDetails, merit_list: newMeritListApplications, id: meritList };
    const res = await updateWithdrawStatus({ payload });
    if(res.status === 200) {
      toast.success("Updated!");
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleBulkExport = async () => {
    setOpenExport(false);
    dispatch(setLoading({ loading: true }));
    let exportIds = [{
      doctype: "Merit List",
      id: meritList
    }];

    const payload = { export : [...exportIds] };
    const response = await fetch(APIs.bulkActionExport, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      dispatch(setLoading({ loading: false }));
      setSelectedIds([]);
      setSelectedRowKeys([]);
      toast.success("Data exported!");
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "MeritListData.xlsx");
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error("Something went wrong");
      dispatch(setLoading({ loading: false }));
    }
  };

  const handleGetMeritList = async () => {
    dispatch(setLoading({ loading: true }));
    const res = await getMeritListingByName({ name: meritList });
    dispatch(setLoading({ loading: false }));
    if(res.status === 200) {
      const data = res.data.data;
      const meritList = res.data.data.merit_list;
      setMeritListDetails(data);
      let arr = [];
      arr.push({});
      let withdrawn = 0;
      let admitted = 0;
      let rejected = 0;
      for(let i = 0 ; i < meritList.length ; i++) {
        if(meritList[i].is_withdraw) withdrawn++;
        if(meritList[i].status === "Admitted") admitted++;
        if(meritList[i].status === "Rejected") rejected++;
        arr.push({
          applicationId: meritList[i].student_applicant,
          name: meritList[i].student_name,
          programGroup: meritList[i].program_group,
          program: meritList[i].program,
          college: meritList[i].college,
          status: meritList[i].status,
          isWithdraw: meritList[i].is_withdraw,
          action: i,
          key: i,
          id: i
        });
      };

      setRowData(arr);
      setAnalytics({
        withdrawn,
        admitted,
        rejected
      });
    } 
  };

  const applyFilter = (filterValue, arr, key) => {
    let filteredList = [];
    for (let i = 0; i < arr.length; i++) {
      if (filterValue !== null && filterValue !== "") {
        if (arr[i] && arr[i]?.[key] &&
          arr[i][key]
            .trim()
            .toLocaleLowerCase()
            .includes(filterValue.trim().toLocaleLowerCase())
        ) {
          filteredList.push(arr[i]);
        }
      } else {
        filteredList.push(arr[i]);
      }
    }
    return filteredList;
  }
  
  const handleFilters = () => {
    const meritListCopy = [...rowData];
    if(filterValues.applicationId === "" && filterValues.name ===  "" && filterValues.programGroup === "" && filterValues.program === "" && filterValues.college === "" && filterValues.status === "") {
      setFilteredData([]);
      return;
    }
    let arr = [];
    for(let i = 0 ; i < meritListCopy.length ; i++) {
      if(i != 0) {
        arr.push(meritListCopy[i]);
      }
    }

    if (filterValues.name !== "") {
      arr = applyFilter(filterValues.name, arr, "name");
    }
    if (filterValues.applicationId !== "") {
      arr = applyFilter(filterValues.applicationId, arr, "applicationId");
    }
    if (filterValues.programGroup !== "") {
      arr = applyFilter(filterValues.programGroup, arr, "programGroup");
    }
    if (filterValues.program !== "") {
      arr = applyFilter(filterValues.program, arr, "program");
    }
    if (filterValues.college !== "") {
      arr = applyFilter(filterValues.college, arr, "college");
    }
    if (filterValues.status !== "") {
      arr = applyFilter(filterValues.status, arr, "status");
    }

    const result = [
      {},
      ...arr
    ];
    setFilteredData(result);
  }

  useEffect(() => {
    handleGetMeritList();
  }, []);

  useEffect(() => {
    handleFilters();
  }, [filterValues]);

  return (
    <>
      <MainHeader />
      {
        loading ?
          <LoadingBackdrop open={loading} /> :
          <div className={styles.mainContainer}>
            <div className={styles.breadCrumbsContainer}>
              <p className={styles.breadCrumbsContent} onClick={() => navigate("/dashboard")}>IIHT {" > "}</p>
              <p className={styles.breadCrumbsContent} onClick={() => navigate("/modules/admission")}>Admission {" > "}</p>
              <p className={styles.breadCrumbsContent} onClick={handleMeritListNavigation}>Merit List Tool {" > "}</p>
              <p className={styles.breadCrumbsContent} onClick={() => navigate("/modules/admission/merit-list")}>Merit List {" > "}</p>
              <p className={styles.breadCrumbsContent}>{meritList}</p>
            </div>
            <div className={styles.headerContainer}>
              <div className={styles.breadCrumbsContainer}>
                <GridMenuIcon className={styles.gridIcon}/>
                <p className={styles.title}>{meritList}</p>
              </div>
              <div className={styles.buttonsGroup}>
                <div className={styles.analyticCardWithdrawn}>
                  <p className={styles.analyticWithdrawnText}>Withdrawn</p>
                  <p className={styles.analyticWithdrawnText}>{analytics.withdrawn}</p>
                </div>
                <div className={styles.analyticCardAdmitted}>
                  <p className={styles.analyticAdmittedText}>Admitted</p>
                  <p className={styles.analyticAdmittedText}>{analytics.admitted}</p>
                </div>
                <div className={styles.analyticCardRejected}>
                  <p className={styles.analyticRejectedText}>Rejected</p>
                  <p className={styles.analyticRejectedText}>{analytics.rejected}</p>
                </div>
                  <Popover
                    content={<div className={styles.popoverContainer}>
                      <p className={styles.popoverContent} onClick={handleBulkExport}>Export</p>
                    </div>}
                    trigger="click"
                    open={openExport}
                    placement="bottomRight"
                    arrow={false}
                    onOpenChange={() => setOpenExport(true)}
                  >
                    <div className={styles.button}>
                      <p className={styles.buttonText}>...</p>
                    </div>
                  </Popover>
                <div className={styles.button} onClick={() => navigate("/modules/admission/merit-list")}>
                  <p className={styles.buttonText}>Back</p>
                </div>
                {
                  selectedRowKeys.length > 0 && (
                  <Popover
                    content={<div className={styles.popoverContainer}>
                      <p className={styles.popoverContent} onClick={handleConfirmWithdrawApplications}>Withdraw Applications</p>
                    </div>}
                    trigger="click"
                    open={openActions}
                    placement="bottomRight"
                    arrow={false}
                    onOpenChange={() => setOpenActions(true)}
                  >
                    <div className={styles.activeButton}>
                      <p className={styles.activeButtonText}>Actions</p>
                    </div>
                  </Popover>
                  ) 
                }
              </div>
            </div>
            <Table 
              rowSelection={{ type: (record, index) => (index === 0 ? null : "checkbox"), ...rowSelection }} 
              columns={columns} 
              dataSource={filteredData.length > 0 ? filteredData : rowData} 
              size='small'
              pagination={true}
            />
          </div>
      }
      <MainFooter />
    </>
  )
};

export default GetAndUpdateMeritList;
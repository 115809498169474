import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Breadcrumbs, MainLayout } from "components/common";
import { Button, Input, Select } from "antd";
import { toast } from "react-toastify";
import {
  getGenericData,
  createGenericEntity,
  updateGenericEntity,
  getGenericList,
} from "modules/hr";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "slices/commonSlice";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";

const { Option } = Select;

const LeavePolicyAssignmentDetails = () => {
  const [data, setData] = useState([]);
  const loading = useSelector((state) => state.common.loading);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState([]);
  const [leavePeriodData, setLeavePeriodData] = useState([]);
  const [leavePolicyData, setLeavePolicyData] = useState([]);
  const [leavePolicyAssignment, setLeavePolicyAssignment] = useState({
    id: "",
    creation: "",
    modified: "",
    carry_forward: 0,
    employee_name: "",
    leave_policy: "",
    company: "",
    assignment_based_on: "",
    effective_to: "",
    employee: "",
    leave_period: "",
    leaves_allocated: 0,
    effective_from: "",
    custom_status: "Open", // Add custom_status field
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (id && id !== "new") {
      fetchData(id);
    } else {
      setLeavePolicyAssignment({
        id: "",
        creation: "",
        modified: "",
        carry_forward: 0,
        employee_name: "",
        leave_policy: "",
        company: "Techademy", // initialize company field
        assignment_based_on: "",
        effective_to: "",
        employee: "",
        leave_period: "",
        leaves_allocated: 0,
        effective_from: "",
        custom_status: "Open", // Add custom_status field
      });
    }
    fetchEmployeeData();
    fetchLeavePeriodData();
    fetchLeavePolicyData();
  }, [id]);

  const fetchData = async (id) => {
    try {
      if (id && id !== "new") {
        const leavePolicyAssignmentData = await getGenericData(
          "Leave Policy Assignment",
          id
        );
        setLeavePolicyAssignment({
          ...leavePolicyAssignmentData.message,
          company: leavePolicyAssignmentData.message.company,
        });
        console.log("Leave Policy Assignment", leavePolicyAssignmentData);
      } else {
        // Fetching company data using the same API call
        const companyId = "Techademy";
        const companyData = await getGenericData("Techademy", companyId); // Adjust companyId as per your data structure
        setLeavePolicyAssignment((prevState) => ({
          ...prevState,
          company: companyData.message.name, // Assuming the structure of companyData
        }));
        console.log("Company Data", companyData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchEmployeeData = async () => {
    setLoading(true);
    try {
      const doctype = "Employee";
      const employeeDataList = await getGenericList(doctype);
      setEmployeeData(employeeDataList);
      console.log(employeeDataList, "Employee Data List");
    } catch (error) {
      console.error("Error fetching Employee Data:", error);
    }
    setLoading(false);
  };

  const fetchLeavePeriodData = async () => {
    setLoading(true);
    try {
      const doctype = "Leave Period";
      const leavePeriodList = await getGenericList(doctype);
      setLeavePeriodData(leavePeriodList);
      console.log(leavePeriodList, "Leave Period Data List");
    } catch (error) {
      console.error("Error fetching Leave Period Data:", error);
    }
    setLoading(false);
  };

  const fetchLeavePolicyData = async () => {
    setLoading(true);
    try {
      const doctype = "Leave Policy";
      const leavePolicyList = await getGenericList(doctype);
      setLeavePolicyData(leavePolicyList);
      console.log(leavePolicyList, "Leave Policy Data List");
    } catch (error) {
      console.error("Error fetching Leave Policy Data:", error);
    }
    setLoading(false);
  };

  const handleSave = async () => {
    try {
      const doctype = "Leave Policy Assignment";
      const validationErrors = validateFields();

      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }

      const payload = {
        doctype,
        ...leavePolicyAssignment,
      };

      if (id && id !== "new") {
        payload.id = id; // Include id for updating existing leave policy
        await updateGenericEntity({ payload });
        toast.success("Leave policy updated successfully");
      } else {
        await createGenericEntity({ payload });
        toast.success("Leave policy added successfully");
      }

      if (!(Object.keys(validationErrors).length > 0)) {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error saving Leave Policy Assignment:", error);
      toast.error("Failed to save Leave Policy Assignment. Please try again.");
    }
  };

  const validateFields = () => {
    const errors = {};

    if (!leavePolicyAssignment.employee) {
      errors.employee = "Employee ID is required";
    }

    if (!leavePolicyAssignment.employee_name) {
      errors.employee_name = "Employee name is required";
    }

    if (!leavePolicyAssignment.company) {
      errors.company = "Institution is required";
    }

    if (!leavePolicyAssignment.leave_policy) {
      errors.leave_policy = "Leave Policy is required";
    }

    if (!leavePolicyAssignment.assignment_based_on) {
      errors.assignment_based_on = "Leave Assignment Based On is required";
    }

    if (!leavePolicyAssignment.leave_period) {
      errors.leave_period = "Leave Period is required";
    }

    if (!leavePolicyAssignment.effective_from) {
      errors.effective_from = "Effective From is required";
    }

    if (!leavePolicyAssignment.effective_to) {
      errors.effective_to = "Effective To is required";
    }

    return errors;
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleLeaveAssignmentBasedOnChange = (value) => {
    setLeavePolicyAssignment((prevState) => ({
      ...prevState,
      assignment_based_on: value,
    }));

    if (value === "Joining Date") {
      const selectedEmployee = employeeData.find(
        (employee) => employee.id === leavePolicyAssignment.employee
      );
      if (selectedEmployee) {
        setLeavePolicyAssignment((prevState) => ({
          ...prevState,
          effective_from: selectedEmployee.creation,
          effective_to: selectedEmployee.modified,
        }));
      }
    } else if (value === "Leave Period") {
      const selectedLeavePeriod = leavePeriodData.find(
        (period) => period.id === leavePolicyAssignment.leave_period
      );
      if (selectedLeavePeriod) {
        setLeavePolicyAssignment((prevState) => ({
          ...prevState,
          effective_from: selectedLeavePeriod.from_date,
          effective_to: selectedLeavePeriod.to_date,
        }));
      }
    }
  };

  const handleEmployeeIdChange = (value) => {
    const selectedEmployee = employeeData.find(
      (employee) => employee.id === value
    );
    if (selectedEmployee) {
      setLeavePolicyAssignment((prevState) => ({
        ...prevState,
        employee: value,
        employee_name: selectedEmployee.first_name,
        effective_from: selectedEmployee.creation,
        effective_to: selectedEmployee.modified,
      }));
    }
  };

  const handleLeavePeriodIdChange = (value) => {
    const selectedLeavePeriod = leavePeriodData.find(
      (period) => period.id === value
    );
    if (selectedLeavePeriod) {
      setLeavePolicyAssignment((prevState) => ({
        ...prevState,
        leave_period: value,
        effective_from: selectedLeavePeriod.from_date,
        effective_to: selectedLeavePeriod.to_date,
      }));
    }
  };
  const handleLeavePolicyIdChange = (value) => {
    const selectedLeavePolicy = leavePolicyData.find(
      (policy) => policy.id === value
    );
    if (selectedLeavePolicy) {
      setLeavePolicyAssignment((prevState) => ({
        ...prevState,
        leave_policy: value,
      }));
    }
  };
  const handleStatusChange = (value) => {
    setLeavePolicyAssignment((prevState) => ({
      ...prevState,
      custom_status: value,
    }));
  };

  return (
    <MainLayout>
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <>
          <Breadcrumbs />
          <div className="master-heading">
            <h2>
              {id && id !== "new"
                ? "View Leave Policy Assignment"
                : "Add Leave Policy Assignment"}
            </h2>
            <div className="btnsGrp">
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                Back
              </Button>
              <Button
                onClick={handleSave}
                variant="contained"
                color="primary"
                className="redBtn"
              >
                {id && id !== "new" ? "Update" : "Save"}
              </Button>
            </div>
          </div>
          <div className="formApplication allocationsForm">
            <section className="form-section">
              <div className="fields-block">
                <div className="form-field">
                  <label>Employee Id *</label>
                  <Select
                    value={leavePolicyAssignment.employee}
                    onChange={handleEmployeeIdChange}
                  >
                    {employeeData.map((employee) => (
                      <Option key={employee.id} value={employee.id}>
                        {employee.id}
                      </Option>
                    ))}
                  </Select>
                  {errors.employee && (
                    <span className="error-message">{errors.employee}</span>
                  )}
                </div>
                <div className="form-field">
                  <label>Employee Name *</label>
                  <Input
                    // disabled={!id}
                    disabled
                    value={leavePolicyAssignment.employee_name}
                    onChange={(e) =>
                      setLeavePolicyAssignment((prevState) => ({
                        ...prevState,
                        employee_name: e.target.value,
                      }))
                    }
                  />
                  {errors.employee_name && (
                    <span className="error-message">
                      {errors.employee_name}
                    </span>
                  )}
                </div>
                <div className="form-field">
                  <label>Institution *</label>
                  <Input
                    disabled={true}
                    value={leavePolicyAssignment.company}
                    onChange={(e) =>
                      setLeavePolicyAssignment((prevState) => ({
                        ...prevState,
                        company: e.target.value,
                      }))
                    }
                  />
                  {errors.company && (
                    <span className="error-message">{errors.company}</span>
                  )}
                </div>

                <div className="form-field">
                  <label>Leave Policy *</label>
                  <Select
                    value={leavePolicyAssignment.leave_policy}
                    onChange={handleLeavePolicyIdChange}
                  >
                    {leavePolicyData.map((policy) => (
                      <Option key={policy.id} value={policy.id}>
                        {policy.id}
                      </Option>
                    ))}
                  </Select>
                  {errors.leave_policy && (
                    <span className="error-message">{errors.leave_policy}</span>
                  )}
                </div>
                <div className="form-field">
                  <label>Leave Assignment Based On *</label>
                  <Select
                    value={leavePolicyAssignment.assignment_based_on}
                    onChange={handleLeaveAssignmentBasedOnChange}
                  >
                    <Option value="Joining Date">Joining Date</Option>
                    <Option value="Leave Period">Leave Period</Option>
                  </Select>
                  {errors.assignment_based_on && (
                    <span className="error-message">
                      {errors.assignment_based_on}
                    </span>
                  )}
                </div>
                <div className="form-field">
                  <label>Leave Period *</label>
                  <Select
                    value={leavePolicyAssignment.leave_period}
                    onChange={handleLeavePeriodIdChange}
                  >
                    {leavePeriodData.map((period) => (
                      <Option key={period.id} value={period.id}>
                        {period.id}
                      </Option>
                    ))}
                  </Select>
                  {errors.leave_period && (
                    <span className="error-message">{errors.leave_period}</span>
                  )}
                </div>
                <div className="form-field">
                  <label>Effective From *</label>

                  <Input
                    type="text"
                    value={leavePolicyAssignment.effective_from}
                    onChange={(e) =>
                      setLeavePolicyAssignment((prevState) => ({
                        ...prevState,
                        effective_from: e.target.value,
                      }))
                    }
                  />
                  {errors.effective_from && (
                    <span className="error-message">
                      {errors.effective_from}
                    </span>
                  )}
                </div>
                <div className="form-field">
                  <label>Effective To *</label>
                  <Input
                    type="text"
                    value={leavePolicyAssignment.effective_to}
                    onChange={(e) =>
                      setLeavePolicyAssignment((prevState) => ({
                        ...prevState,
                        effective_to: e.target.value,
                      }))
                    }
                  />
                  {errors.effective_to && (
                    <span className="error-message">{errors.effective_to}</span>
                  )}
                </div>
                <div className="form-field">
                  <label>Status</label>
                  <Select
                    value={leavePolicyAssignment.custom_status}
                    onChange={handleStatusChange}
                  >
                    <Option value="Open">Open</Option>
                    <Option value="Closed">Closed</Option>
                    <Option value="Draft">Draft</Option>
                  </Select>
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </MainLayout>
  );
};

export default LeavePolicyAssignmentDetails;

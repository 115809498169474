import ArrowDownSVG from "../../../../assets/images/arrow-down.svg";
import { setActiveSidebar } from "../../../../slices/academicsSlice";
import FiltersSVG from "../../../../assets/images/filters-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { Table } from "antd";
import useStyles from "./style";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { GridMenuIcon } from "@mui/x-data-grid";
import MainHeader from "components/common/MainHeader";
import MainFooter from "components/common/MainFooter";
import { useEffect, useState } from "react";
import { getStudentAttendance } from "modules/admission";

const StudentAttendance = () => {
  const [studentsAttendance, setStudentsAttendance] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const styles = useStyles();
  const { loading } = useSelector((state) => state.common);

  const columns = [
    {
      dataIndex: "student_name",
      title: "STUDENT NAME",
      width: 1,
    },
    {
      dataIndex: "course_schedule",
      title: "COURSE SCHEDULE",
      width: 1,
      // render: (val, row) => {
      //   return (
      //     <p className={styles.tableContentFields}>{row.course_schedule}</p>
      //   );
      // },
    },
    {
      dataIndex: "id",
      title: "ATTENDANCE ID",
      width: 0.8,
      //   render: (val, row) => {
      //     return <p className={styles.tableContentFields}>{row.attendance_id}</p>;
      //   },
    },
    {
      dataIndex: "modified",
      title: "DATE",
      width: 0.7,
      // render: (val, row) => {
      //   return <p className={styles.tableContentFields}>{row.date}</p>;
      // },
    },
    {
      dataIndex: "status",
      title: "STATUS ACTION",
      width: 0.8,
      render: (val, row) => {
        return (
          <p
            className={
              row.status === "Present"
                ? styles.buttonPublish
                : styles.buttonUnPublish
            }
          >
            {row.status}
          </p>
        );
      },
    },
    {
      dataIndex: "action",
      title: "ACTION",
      width: 0.5,
      render: (val, row) => {
        return (
          <p
            className={styles.tableContentView}
            onClick={() =>
              navigate(`/modules/academics/student-attendance/${row.id}`)
            }
          >
            View
          </p>
        );
      },
    },
  ];

  const handleAttendanceNavigation = () => {
    dispatch(setActiveSidebar({ activeSidebar: 3 }));
    navigate("/modules/academics");
  };

  const handleGetStudentAttendence = async () => {
    try {
      let res = await getStudentAttendance();
      if (res.status === 200) {
        const arr = [];
        console.log(res);
        const languageList = res.data.message;
        console.log(languageList);

        for (let i = 0; i < languageList.length; i++) {
          arr.push({
            id: languageList[i].id,
            modified: languageList[i].modified?.split(" ")[0],
            student_name: languageList[i].student_name,
            course_schedule: languageList[i].course_schedule,
            status: languageList[i].status,
          });
        }
        console.log(arr);
        setStudentsAttendance(arr);
      }
    } catch (error) {
      console.error("Error fetching Language details:", error);
    }
  };

  useEffect(() => {
    handleGetStudentAttendence();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>Student Attendance</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div className={`${styles.button} ${styles.filterButton}`}>
                <ReactSVG src={FiltersSVG} />
                <p className={styles.buttonText}>Filters</p>
                <ReactSVG src={ArrowDownSVG} />
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>Saved Filters</p>
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>...</p>
              </div>
              <div
                className={styles.activeButton}
                onClick={() =>
                  navigate("/modules/academics/add-new-student-attendance")
                }
              >
                <p className={styles.activeButtonText}>
                  Add Student Attendance
                </p>
              </div>
            </div>
          </div>
          <Table
            rowSelection={{ type: "checkbox" }}
            columns={columns}
            dataSource={studentsAttendance}
            size="small"
            pagination={true}
          />
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default StudentAttendance;

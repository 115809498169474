export const formConfig = [
  {
    entityType: "University",
    fields: [
      {
        label: "University Name *",
        name: "university_name",
        type: "text",
        placeholder: "University Name",
        required: "required",
      },
      {
        label: "University Code *",
        name: "university_code",
        type: "text",
        placeholder: "University Code",
        required: "required",
      },
      {
        label: "Founded Year *",
        name: "founded_year",
        type: "date",
        placeholder: "Founded Year",
        required: "required",
      },
      {
        label: "Contact Email *",
        name: "contact_email",
        type: "email",
        placeholder: "Contact Email",
        required: "required",
      },
      {
        label: "Contact Phone",
        name: "contact_phone",
        type: "tel",
        placeholder: "Contact Phone",
      },
      {
        label: "University GSTN *",
        name: "gstn", 
        type: "gst_number",
        placeholder: "22AAAAA0000A1Z5",
        required: "required",
      },
      {
        label: "Currency *",
        name: "default_currency",
        type: "select2",
        placeholder: "Currency",
        options: [{ label: "INR", value: "INR" }],
        required: "required",
      },
      {
        label: "Country *",
        name: "country",
        type: "select2",
        placeholder: "Country",
        options: [{ label: "India", value: "India" }],
        required: "required",
      },
      {
        label: "University Mission *",
        name: "university_mission",
        type: "textarea",
        placeholder: "University Mission",
        required: "required",
      },
      {
        label: "University Vision *",
        name: "university_vision",
        type: "textarea",
        placeholder: "University Vision",
        required: "required",
      },
      {
        type: "col-break",
        name: "col-break",
      },
      {
        label: "Description",
        name: "description",
        type: "textarea",
        placeholder: "Description",
      },
    ],
  },
  {
    entityType: "Campus",
    fields: [
      {
        label: "Campus Name *",
        name: "campus_name",
        type: "text",
        placeholder: "Campus Name",
        required: "required",
      },
      {
        label: "Campus Code *",
        name: "campus_code",
        type: "text",
        placeholder: "Campus Code",
        required: "required",
      },
      {
        label: "University *",
        name: "university",
        type: "select",
        placeholder: "Select University",
        required: "required",
      },
      {
        label: "Campus GSTN *",
        name: "gstn",        
        type: "gst_number",
        placeholder: "Campus GSTN",
        required: "required",
      },
      {
        label: "Country *",
        name: "country",
        type: "select2",
        placeholder: "Country",
        options: [{ label: "India", value: "India" }],
        required: "required",
      },
      {
        label: "Location *",
        name: "campus_location",
        type: "text",
        placeholder: "Location",
        required: "required",
      },
    ],
  },
  {
    entityType: "College",
    fields: [
      {
        label: "College Name *",
        name: "college_name",
        type: "text",
        placeholder: "College Name",
        required: "required",
      },
      {
        label: "College Code *",
        name: "college_code",
        type: "text",
        placeholder: "College Code",
        required: "required",
      },
      {
        label: "Founded Year *",
        name: "founded_year",
        type: "date",
        placeholder: "Founded Year",
        required: "required",
      },
      {
        type: "col-break",
        name: "col-break",
      },
      {
        label: "University Name",
        name: "university",
        type: "select",
        placeholder: "Select University",
      },
      {
        label: "Contact Email *",
        name: "contact_email",
        type: "email",
        placeholder: "Contact Email",
        required: "required",
      },
      {
        label: "Country *",
        name: "country",
        type: "select2",
        placeholder: "Country",
        options: [{ label: "India", value: "India" }],
        required: "required",
      },
      {
        type: "col-break",
        name: "col-break",
      },
      {
        label: "Contact Phone",
        name: "contact_phone",
        type: "tel",
        placeholder: "Contact Phone",
      },
      {
        label: "College GSTN *",
        name: "gstn",
        type: "gst_number",
        placeholder: "College GSTN",
        required: "required",
      },
      {
        label: "Default Currency *",
        name: "default_currency",
        type: "select2",
        placeholder: "Currency",
        options: [{ label: "INR", value: "INR" }],
        required: "required",
      },
      {
        type: "col-break",
        name: "col-break",
      },
      {
        label: "Description",
        name: "description",
        type: "textarea",
        placeholder: "Type Here...",
      },
    ],
  },
  {
    entityType: "Course",
    fields: [
      {
        label: "Course Title *",
        name: "name",
        type: "text",
        placeholder: "Course Title",
        required: "required",
        tab: "Details",
      },
      {
        label: "Course Code *",
        name: "code",
        type: "text",
        placeholder: "Course Code",
        required: "required",
        tab: "Details",
      },
      {
        label: "Duration (in years) *",
        name: "duration",
        type: "text",
        placeholder: "Duration (in years)",
        required: "required",
        tab: "Details",
      },
      {
        type: "col-break",
        name: "col-break1",
        tab: "Details",
      },
      {
        label: "Student Enrollment Minimum *",
        name: "student_enrollment_minimum",
        type: "text",
        placeholder: "Student Enrollment Minimum",
        required: "required",
        tab: "Details",
      },
      {
        label: "Student Enrollment Maximum*",
        name: "student_enrollment_maximum",
        type: "text",
        placeholder: "Student Enrollment Maximum",
        required: "required",
        tab: "Details",
      },
      {
        type: "col-break",
        name: "col-break2",
        tab: "Details",
      },
      {
        label: "Course Type *",
        name: "course_type",
        type: "select1",
        placeholder: "Course Type",
        options: [
          { label: "Elective", value: "Elective" },
          { label: "Core Course", value: "Core Course" },
        ],
        required: "required",
        tab: "Details",
      },
      {
        label: "Course Level *",
        name: "course_level",
        type: "select1",
        placeholder: "Course Level",
        options: [
          { label: "Undergraduate", value: "Undergraduate" },
          { label: "Postgraduate", value: "Postgraduate" },
          { label: "Diploma", value: "Diploma" },
        ],
        required: "required",
        tab: "Details",
      },
      {
        label: "Syllabus Link *",
        name: "syllabus_link",
        type: "text",
        placeholder: "Syllabus Link",
        required: "required",
        tab: "Details",
      },
      {
        type: "col-break",
        name: "col-break3",
        tab: "Details",
      },
      {
        label: "Primary Department *",
        name: "primary_department",
        type: "select",
        placeholder: "Primary Department",
        required: "required",
        tab: "Details",
      },
      {
        label: "Secondary Department",
        name: "secondary_department",
        type: "select",
        placeholder: "Secondary Department",
        tab: "Details",
      },
      {
        label: "Founded Year *",
        name: "founded_year",
        type: "date",
        placeholder: "Founded Year",
        required: "required",
        tab: "Details",
      },
      {
        type: "col-break",
        name: "col-break4",
        tab: "Details",
      },
      {
        label: "Academic Term",
        name: "academic_term",
        type: "select",
        placeholder: "Academic Term",
        tab: "Details",
      },
      {
        label: "Status",
        name: "status",
        type: "select1",
        placeholder: "Status",
        options: [
          { label: "Active", value: "Active" },
          { label: "Inactive", value: "Inactive" },
        ],
        tab: "Details",
      },
      {
        type: "col-break",
        name: "col-break5",
        tab: "Details",
      },
      {
        label: "Course Description",
        name: "description",
        type: "textarea",
        placeholder: "Course Description",
        tab: "Details",
      },
      {
        label: "Prerequisites",
        name: "custom_prequisites",
        type: "table",
        tab: "Requisites",
        minRows: 3,
        headers: [
          {
            key: "Course",
            value: "course",
            options: "courseList", // Specify the options property
            type: "select",
          },
          {
            key: "Course Code",
            value: "code",
          },
        ],
      },
      {
        label: "Corequisites",
        name: "custom_corequisites",
        type: "table",
        tab: "Requisites",
        minRows: 3,
        headers: [
          {
            key: "Course",
            value: "course",
            options: "courseList", // Specify the options property
            type: "select",
          },
          {
            key: "Course Code",
            value: "code",
          },
        ],
      },
      {
        label: "Antirequisites",
        name: "custom_antirequisite",
        type: "table",
        tab: "Requisites",
        minRows: 3,
        headers: [
          {
            key: "Course",
            value: "course",
            options: "courseList", // Specify the options property
            type: "select",
          },
          {
            key: "Course Code",
            value: "code",
          },
        ],
      },
      {
        label: "LTPC (Lecture, Tutorial, Practical, Contact Hours) ",
        name: "custom_ltpcs",
        type: "table",
        tab: "LTPC",
        minRows: 3,
        headers: [
          {
            key: "Lecture",
            value: "lecture",
          },
          {
            key: "Credits",
            value: "credits",
          },
          {
            key: "Contact Hours",
            value: "contact_hours",
          },
          {
            key: "Self Study Hours",
            value: "self_study_hours",
          },
          {
            key: "Tutorial",
            value: "tutorial",
          },
          {
            key: "Practical",
            value: "practical",
          },
        ],
      },
      {
        label: "New Outcomes",
        name: "custom_course_outcome",
        type: "table",
        tab: "Outcomes",
        minRows: 1,
        headers: [
          {
            key: "Outcome Id",
            value: "outcomes",
            options: "outcomesList",
            type: "select",
          },
          {
            key: "Outcome",
            value: "outcome",
            type: "text",
          },
          {
            key: "Outcome Name",
            value: "name",
            type: "text",
          },
          {
            key: "Outcome Type",
            value: "outcome_type",
            type: "text",
          },
        ],
      },

      {
        label: "Assessment Criteria",
        name: "assessment_criteria",
        type: "table",
        tab: "Assessment",
        minRows: 3,
        headers: [
          {
            key: "Assessment Criteria",
            value: "assessment_criteria",
            options: "assessmentCriteria",
            type: "select",
          },
          {
            key: "Weightage",
            value: "weightage",
            type: "integer",
          },
        ],
      },
      {
        label: "Content Management",
        name: "content_management",
        type: "page",
        minRows: 3,
        tab: "Content Management",
        pagename: "ContentManagement",
      },
    ],
  },

  {
    entityType: "Academic Year",
    fields: [
      {
        label: "Academic Year Name *",
        name: "academic_year_name",
        type: "text",
        placeholder: "Academic Year Name",
        required: "required",
      },
      {
        label: "Start Date *",
        name: "year_start_date",
        type: "date",
        placeholder: "Start Date",
        required: "required",
      },
      {
        label: "End Date *",
        name: "year_end_date",
        type: "date",
        placeholder: "End Date",
        required: "required",
      },
      {
        type: "col-break",
        name: "col-break6",
      },
      {
        label: "Status *",
        name: "status",
        type: "select1",
        placeholder: "Status",
        options: [
          { label: "Active", value: "Active" },
          { label: "Inactive", value: "Inactive" },
        ],
        required: "required",
      },
    ],
  },

  {
    entityType: "Academic Term",
    fields: [
      {
        label: "Semester / Term Code *",
        name: "term_code",
        type: "text",
        placeholder: "Semester / Term Code",
        required: "required",
      },
      {
        label: "Semester / Term Name *",
        name: "term_name",
        type: "text",
        placeholder: "Semester / Term Name",
        required: "required",
      },
      {
        type: "col-break",
        name: "col-break7",
      },
      {
        label: "Start Date *",
        name: "term_start_date",
        type: "date",
        placeholder: "Start Date",
        required: "required",
      },
      {
        label: "End Date *",
        name: "term_end_date",
        type: "date",
        placeholder: "End Date",
        required: "required",
      },
      {
        type: "col-break",
        name: "col-break8",
      },
      {
        label: "Registration Start Date *",
        name: "registration_start_date",
        type: "date",
        placeholder: "Registration Start Date",
        required: "required",
      },
      {
        label: "Registration End Date *",
        name: "registration_end_date",
        type: "date",
        placeholder: "Registration End Date",
        required: "required",
      },
      {
        type: "col-break",
        name: "col-break9",
      },
      {
        label: "Academic Year *",
        name: "academic_year",
        type: "select",
        placeholder: "Select Academic Year",
        required: "required",
      },
      {
        label: "Academic Year start date *",
        name: "yearStartDate",
        type: "date",
        placeholder: "Academic Year start date",
        value: "yearStartDate",
      },
      {
        label: "Status *",
        name: "status",
        type: "select1",
        placeholder: "Status",
        options: [
          { label: "Active", value: "Active" },
          { label: "Inactive", value: "Inactive" },
        ],
        required: "required",
      },
    ],
  },

  {
    entityType: "Batch",
    fields: [
      {
        label: "Batch Code *",
        name: "batch_code",
        type: "text",
        placeholder: "Batch Code",
        required: "required",
      },
      {
        label: "Batch Name *",
        name: "batch_name",
        type: "text",
        placeholder: "Batch Name ",
        required: "required",
      },
      {
        label: "Academic Year *",
        name: "academic_year",
        type: "select",
        placeholder: "Academic Year",
        required: "required",
      },
      {
        label: "Status *",
        name: "status",
        type: "select1",
        placeholder: "Status",
        options: [
          { label: "Active", value: "Active" },
          { label: "Inactive", value: "Inactive" },
        ],
        required: "required",
      },
    ],
  },

  {
    entityType: "Department",
    fields: [
      {
        label: "Department Name *",
        name: "department_name",
        type: "text",
        placeholder: "Department Name",
        required: "required",
      },
      {
        label: "Department Code *",
        name: "department_code",
        type: "text",
        placeholder: "Department Code",
        required: "required",
      },
      {
        label: "Founded Year *",
        name: "founded_year",
        type: "date",
        placeholder: "Founded Year",
        required: "required",
      },
      {
        type: "col-break",
        name: "col-break10",
      },
      {
        label: "Campus",
        name: "campus",
        type: "select",
        placeholder: "Select Campus",
      },
      {
        label: "University",
        name: "university",
        type: "select",
        placeholder: "Select University",
      },
      {
        label: "College",
        name: "college",
        type: "select",
        placeholder: "Select College",
      },
      {
        type: "col-break",
        name: "col-break11",
      },
      {
        label: "Head of Department *",
        name: "head_of_department",
        type: "text",
        placeholder: "Head of Department",
        required: "required",
      },
      {
        label: "Contact Email *",
        name: "contact_email",
        type: "email",
        placeholder: "Contact Email",
        required: "required",
      },
      {
        label: "Contact Phone",
        name: "contact_phone",
        type: "tel",
        placeholder: "Contact Phone",
      },
      {
        type: "col-break",
        name: "col-break12",
      },
      {
        label: "Department Coordinator *",
        name: "department_coordinator",
        type: "text",
        placeholder: "Department Coordinator",
        required: "required",
      },
      {
        label: "Contact Email *",
        name: "coordinator_contact_email",
        type: "email",
        placeholder: "Contact Email",
        required: "required",
      },
      {
        label: "Contact Phone",
        name: "coordinator_contact_phone", 
        type: "tel",
        placeholder: "Contact Phone",
      },
      {
        type: "col-break",
        name: "col-break13",
      },
      {
        label: "Office Location",
        name: "office_location",
        type: "text",
        placeholder: "Office Location",
      },
      {
        type: "col-break",
        name: "col-break14",
      },

      {
        label: "Department Mission *",
        name: "department_mission",
        type: "textarea",
        placeholder: "Department Mission",
        required: "required",
      },
      {
        label: "Department Vision *",
        name: "department_vision",
        type: "textarea",
        placeholder: "Department Vision",
        required: "required",
      },
      {
        label: "Description",
        name: "description",
        type: "textarea",
        placeholder: "Description",
      },
    ],
  },
  {
    entityType: "Program",
    fields: [
      {
        label: "Programme Category *",
        name: "custom_program_category",
        placeholder: "Program Category",
        required: "required",
        type: "select1",
        options: [
          { label: "Program", value: "Program" },
          { label: "Major Programme", value: "Major Programme" },
          { label: "Minor Programme", value: "Minor Programme" },
        ],
        tab: "Details",
      },
      {
        label: "Program Code *",
        name: "program_code",
        type: "text",
        placeholder: "Program Code",
        required: "required",
        tab: "Details",
      },
      {
        type: "col-break",
        name: "col-break15",
      },
      {
        label: "Program Type *",
        name: "program_type",
        placeholder: "Program Type",
        required: "required",
        type: "select1",
        options: [
          { label: "B.A", value: "B.A" },
          { label: "Bsc", value: "Bsc" },
          { label: "M.Tech", value: "M.Tech" },
          { label: "Online Certifications", value: "Online Certifications" },
          { label: "Cerifications", value: "Cerifications" },
          { label: "B.Tech", value: "B.Tech" },
          { label: "Diploma", value: "Diploma" },
          { label: "PG", value: "PG" },
          { label: "UG", value: "UG" },
        ],
        tab: "Details",
      },
      {
        label: "Curriculum *",
        name: "curriculum",
        placeholder: "Select Curriculum",
        required: "required",

        type: "select",

        tab: "Details",
      },
      {
        label: "Academic Year *",
        name: "academic_year",
        type: "select",
        placeholder: "Select Academic Year",
        required: "required",
        tab: "Details",
      },
      {
        type: "col-break",
        name: "col-break16",
        tab: "Details",
      },
      {
        label: "Dual Degree *",
        name: "dual_degree",
        type: "checkbox",
        placeholder: "Dual Degree",
        required: "required",
        tab: "Details",
      },
      {
        label: "Internship/Co-op *",
        name: "internshipcoop",
        type: "checkbox",
        placeholder: "Internship/Co-op",
        required: "required",
        tab: "Details",
      },
      {
        type: "col-break",
        name: "col-break17",
        tab: "Details",
      },
      {
        label: "Program Name *",
        name: "program_name",
        type: "text",
        placeholder: "Program Name",
        required: "required",
        tab: "Details",
      },
      {
        type: "col-break",
        name: "col-break18",
        tab: "Details",
      },
      {
        label: "Department *",
        name: "department",
        type: "select",
        placeholder: "Primary Department",
        required: "required",
        tab: "Details",
      },
      {
        label: "Primary Department *",
        name: "primary_department",
        type: "select",
        placeholder: "Primary Department",
        required: "required",
        tab: "Details",
      },
      {
        label: "Secondary Department",
        name: "secondary_department",
        type: "select",
        placeholder: "Secondary Department",
        tab: "Details",
      },
      {
        type: "col-break",
        name: "col-break19",
        tab: "Details",
      },
      {
        label: "Duration (in Years) *",
        name: "duration_in_years",
        type: "text",
        placeholder: "Duration (in Years)",
        required: "required",
        tab: "Details",
      },
      {
        label: "Credit Requirements *",
        name: "credit_requirements",
        type: "text",
        placeholder: "Credit Requirements",
        required: "required",
        tab: "Details",
      },
      {
        label: "Accreditation Status *",
        name: "accreditation_status",
        type: "select1",
        placeholder: "Accreditation Status",
        options: [
          { label: "Acc1", value: "Acc1" },
          { label: "Acc2", value: "Acc2" },
          { label: "Acc3", value: "Acc3" },
        ],
        required: "required",
        tab: "Details",
      },
      {
        type: "col-break",
        name: "col-break20",
        tab: "Details",
      },
      {
        label: "Program Coordinator *",
        name: "program_coordinator",
        type: "text",
        placeholder: "Program Coordinator",
        required: "required",
        tab: "Details",
      },
      {
        label: "Contact Email *",
        name: "contact_email",
        type: "email",
        placeholder: "Contact Email",
        required: "required",
        tab: "Details",
      },
      {
        label: "Contact Phone",
        name: "contact_phone",
        type: "tel",
        placeholder: "Contact Phone",
        tab: "Details",
      },
      {
        type: "col-break",
        name: "col-break21",
        tab: "Details",
      },
      {
        label: "Language of Instruction *",
        name: "language_of_instruction",
        type: "text",
        placeholder: "Language of Instruction",
        required: "required",
        tab: "Details",
      },
      {
        label: "Program Website *",
        name: "program_website",
        type: "text",
        placeholder: "Program Website",
        required: "required",
        tab: "Details",
      },
      {
        label: "Program Handbook *",
        name: "program_handbook",
        type: "text",
        placeholder: "Program Handbook",
        required: "required",
        tab: "Details",
      },
      {
        type: "col-break",
        name: "col-break22",
        tab: "Details",
      },

      // {
      //   label: "Elective Courses *",
      //   name: "courses",
      //   type: "select",
      //   placeholder: "Select a course",
      //   required: "required",
      //   tab: "Details",
      // },
      {
        type: "col-break",
        name: "col-break23",
        tab: "Details",
      },
      {
        label: "Student Enrollment (Minimum) *",
        name: "student_enrollment_minimum",
        type: "text",
        placeholder: "Student Enrollment (Minimum)",
        required: "required",
        tab: "Details",
      },
      {
        label: "Student Enrollment (Maximum) *",
        name: "student_enrollment_maximum",
        type: "text",
        placeholder: "Student Enrollment (Maximum)",
        required: "required",
        tab: "Details",
      },
      {
        label: "Program Regulation Code *",
        name: "program_regulation_code",
        type: "text",
        placeholder: "Program Regulation Code",
        required: "required",
        tab: "Details",
      },
      {
        type: "col-break",
        name: "col-break24",
        tab: "Details",
      },
      {
        label: "Founded Year",
        name: "founded_year",
        type: "date",
        placeholder: "Founded Year",
        tab: "Details",
      },
      {
        label: "Status",
        name: "status",
        type: "select1",
        placeholder: "Status",
        options: [
          { label: "Active", value: "Active" },
          { label: "Inactive", value: "Inactive" },
        ],
        tab: "Details",
      },
      {
        type: "col-break",
        name: "col-break25",
        tab: "Details",
      },
      {
        label: "Program Description",
        name: "program_description",
        type: "textarea",
        placeholder: "Program Description",
        tab: "Details",
      },
      {
        type: "col-break",
        name: "col-break26",
        tab: "Details",
      },
      {
        label: "Admission Requirements *",
        name: "admission_requirements",
        type: "textarea",
        placeholder: "Admission Requirements",
        required: "required",
        tab: "Details",
      },
      {
        type: "col-break",
        name: "col-break27",
        tab: "Details",
      },
      {
        label: "Graduation Requirements *",
        name: "graduation_requirements",
        type: "textarea",
        placeholder: "Graduation Requirements",
        required: "required",
        tab: "Details",
      },
      {
        type: "col-break",
        name: "col-break28",
        tab: "Details",
      },
      {
        label: "Research Opportunities",
        name: "research_opportunities",
        type: "textarea",
        placeholder: "Research Opportunities",
        tab: "Details",
      },
      {
        type: "col-break",
        name: "col-break29",
        tab: "Details",
      },
      {
        label: "Study Abroad Options",
        name: "study_abroad_options",
        type: "textarea",
        placeholder: "Study Abroad Options",
        tab: "Details",
      },

      {
        label: "Courses",
        name: "courses",
        type: "table",
        tab: "Course",
        minRows: 3,
        headers: [
          {
            key: "Course id",
            value: "course",
            options: "courseList", // Specify the options property
            type: "select",
          },
          {
            key: "Course Name",
            value: "course",
            type: "text",
          },
          {
            key: "Mandatory",
            value: "required",
            type: "checkbox",
          },
          {
            key: "Elective",
            value: "elective",
            type: "checkbox",
          },
          {
            key: "Academic Term",
            value: "academic_term",
            options: "academicTermList", // Specify the options property
            type: "select",
          },
        ],
      },
    ],
  },
  {
    entityType: "Outcomes",
    fields: [
      {
        label: "Outcome Type *",
        name: "outcome_type",
        type: "select1",
        options: [
          { label: "Course Outcome(CO)", value: "Course Outcome(CO)" },
          {
            label: "Program Educational Objectives(PEO)",
            value: "Program Educational Objectives(PEO)",
          },
          { label: "Program Outcomes(PO)", value: "Program Outcomes(PO)" },
          {
            label: "Program Specific Outcomes(PSO)",
            value: "Program Specific Outcomes(PSO)",
          },
          { label: "Competency Levels(CL)", value: "Competency Levels(CL)" },
          {
            label: "Performance Indicators(PI)",
            value: "Performance Indicators(PI)",
          },
        ],
        placeholder: "Outcome Type",
        required: "required",
      },
      {
        label: "Courses *",
        name: "course",
        type: "select",
        placeholder: "Courses",
      },
      {
        label: "Program *",
        name: "program",
        type: "select",
        placeholder: "Program",
      },
      {
        type: "col-break",
        name: "col-break",
      },
      {
        label: "Outcome Title *",
        name: "name",
        type: "text",
        placeholder: "Outcome Title",
        required: "required",
      },
      {
        label: "Description *",
        name: "description",
        type: "textarea",
        placeholder: "Outcome Description",
        required: "required",
      },
      // {
      //   label: "New Outcomes",
      //   name: "custom_course_outcome",
      //   type: "createOutcome",
      // },
    ],
  },
  // {
  //   label: "New Outcomes",
  //   name: "custom_course_outcome",
  //   type: "createOutcome",
  // },
  // {
  //   label: "Outcomes",
  //   name: "outcomes",
  //   type: "courseOutcome",
  // },
  {
    entityType: "Instructor",
    fields: [
      {
        label: "Instructor Name *",
        name: "instructor_name",
        type: "text",
        placeholder: "Instructor Name",
        required: "required",
        tab: "Details",
      },
      {
        label: "Employee ID *",
        name: "employee",
        type: "select",
        placeholder: "Employee ID",
        required: "required",
        tab: "Details",
      },
      {
        label: "Department *",
        name: "department",
        type: "select",
        placeholder: "Department",
        required: "required",
        tab: "Details",
      },
      {
        type: "col-break",
        name: "col-break1",
        tab: "Details",
      },
      {
        label: "Gender *",
        name: "gender",
        placeholder: "Gender",
        type: "select1",
        options: [
          { label: "Female", value: "Female" },
          { label: "Genderqueer", value: "Genderqueer" },
          { label: "Male", value: "Male" },
          { label: "Non-Conforming", value: "Non-Conforming" },
          { label: "Other", value: "Other" },
          { label: "Prefer not to say", value: "Prefer not to say" },
          { label: "Transgender", value: "Transgender" },
        ],
        required: "required",
        tab: "Details",
      },
      {
        label: "Status",
        name: "status",
        type: "select1",
        placeholder: "Status",
        options: [
          { label: "Active", value: "Active" },
          { label: "Inactive", value: "Inactive" },
        ],
        tab: "Details",
      },
      {
        label: "Instructor Log",
        name: "instructor_log",
        type: "table",
        tab: "Requisites",
        minRows: 3,
        headers: [
          {
            key: "Academic year",
            value: "academic_year",
            options: "academicYrList",
            type: "select",
          },
          {
            key: "Academic term",
            value: "academic_term",
            options: "academicTermList",
            type: "select",
          },
          {
            key: "Proramme",
            value: "program",
            options: "programList",
            type: "select",
          },
          {
            key: "Course",
            value: "course",
            options: "courseList",
            type: "select",
          },
        ],
      },
    ],
  },
  {
    entityType: "Curriculum",
    fields: [
      {
        label: "Curriculum Name *",
        name: "curriculum_name",
        type: "text",
        placeholder: "Curriculum Name",
        required: "required",
      },
    ],
  },
];

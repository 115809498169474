import React, { useEffect, useState } from 'react';
import { Select, Table } from 'antd';
import useStyles from './style';
import { useNavigate } from 'react-router-dom';
import { GridMenuIcon } from '@mui/x-data-grid';
import MainHeader from '../../../common/MainHeader';
import MainFooter from '../../../common/MainFooter';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveSidebar } from '../../../../slices/financeSlice.js';
import LoadingBackdrop from "../../../common/LoadingBackdrop.js";
import { getAcademicYearListing, getProgramListing } from '../../../../modules/finance.js';
import { createMeritList, getFilteredStudentInMeritList, getMeritCriteriaListing } from '../../../../modules/admission.js';
import { toast } from 'react-toastify';

const AddNewMeritList = () => {
  const selectSize = "middle";
  const styles= useStyles();
  const navigate= useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [programOptions, setProgramOptions] = useState([]);
  const [academicYearOptions, setAcademicYearOptions] = useState([]);
  const [meritCriteriaOptions, setMeritCriteriaOptions] = useState([]);
  const [flag, setFlag] = useState(false);
  const [response, setResponse] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [values, setValues] = useState({
    academicYear: null,
    program: null,
    meritCriteria: null,
    studentCount: "",
    meritListName: "",
  });
  const [errors, setErrors] = useState({
    academicYear: "",
    program: "",
    meritCriteria: "",
    studentCount: "",
    meritListName: "",
  });

  const columns = [
    {
      dataIndex: "applicationId",
      title: "Application Id",
      width: "20%",
      render: (val, row) => {
        return (
            <p className={styles.tableContentFields}>{row.applicationId}</p>
        );
      },
    }, 
    {
      dataIndex: "name",
      title: "STUDENT NAME",
      width: "15%",
      render: (val, row) => {
        return (
            <p className={styles.tableContentFields}>{row.name}</p>
        );
      },
    },
    {
      dataIndex: "programGroup",
      title: "PROGRAM GROUP",
      width: "15%",
      render: (val, row) => {
        return (
            <p className={styles.tableContentFields}>{row.programGroup}</p>
        );
      },
    },
    {
      dataIndex: "program",
      title: "PROGRAM",
      width: "10%",
      render: (val, row) => {
        return (
            <p className={styles.tableContentFields}>{row.program}</p>
        );
      },
    },
    {
      dataIndex: "college",
      title: "COLLEGE",
      width: "20%",
      render: (val, row) => {
        return (
            <p className={styles.tableContentFields}>{row.college}</p>
        );
      },
    }, 
    {
      dataIndex: "status",
      title: "STATUS",
      width: "10%",
      render: (val, row) => {
        return (
            <p className={styles.tableContentFields}>{row.status}</p>
        );
      },
    }, 
    {
      dataIndex: "action",
      title: "ACTION",
      width: "10%",
      render: (val, row) => {
        return (
            <p className={`${styles.tableContentView} ${styles.link}`}
              onClick={() => {
                const url = `/applications/${row.applicationId}`;
                window.open(url, '_blank');
              }}>View</p>
        );
      },
    },
  ];
  
  const handleMeritListNavigation = () => {
    dispatch(setActiveSidebar({ activeSidebar: 3 }));
    navigate("/modules/admission");
  };

  const handleGetProgramListing = async () => {
    const res = await getProgramListing();
    if(res.status === 200) {
      const arr = [];
      const programList = res.data.data;
      for(let i = 0 ; i < programList.length ; i++) {
        arr.push({
          value: programList[i].name,
          label: programList[i].name
        });
      };
      setProgramOptions(arr);
    };
  };

  const handleGetAcademicYearListing = async () => {
    const res = await getAcademicYearListing();
    if(res.status === 200) {
      const arr = [];
      const acdemicYearList = res.data.data;
      for(let i = 0 ; i < acdemicYearList.length ; i++) {
        arr.push({
          value: acdemicYearList[i].name,
          label: acdemicYearList[i].name
        });
      };
      setAcademicYearOptions(arr);
    };
  };

  const handleGetMeritCriteriaListing = async () => {
    let filters = [];
    const res = await getMeritCriteriaListing(filters);
    if(res.status === 200) {
      const arr = [];
      const meritCriteriaList = res.data.data;
      for(let i = 0 ; i < meritCriteriaList.length ; i++) {
        arr.push({
          value: meritCriteriaList[i].name,
          label: meritCriteriaList[i].name
        });
      };
      setMeritCriteriaOptions(arr);
    };
  };

  const handleValidateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!values.meritListName) {
      newErrors.meritListName = "Required";
      isValid = false;
    } else {
      newErrors.meritListName = "";
    }

    if (!values.studentCount) {
      newErrors.studentCount = "Required";
      isValid = false;
    } else {
      newErrors.studentCount = "";
    }

    if (values.academicYear === null) {
      newErrors.academicYear = "Required";
      isValid = false;
    } else {
      newErrors.academicYear = "";
    }

    if (values.program === null) {
      newErrors.program = "Required";
      isValid = false;
    } else {
      newErrors.program = "";
    }

    if (values.meritCriteria === null) {
      newErrors.meritCriteria = "Required";
      isValid = false;
    } else {
      newErrors.meritCriteria = "";
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleGetFilteredStudentInMeritList = async () => {
    const isValid = handleValidateForm();
    if(isValid) {
      const payload = {
        "Program": values.program,
        "Academic Year": values.academicYear,
        "Merit List Criteria": values.meritCriteria,
        "selected_numbers": Number(values.studentCount)
      };
  
      setFlag(true);
      const res = await getFilteredStudentInMeritList({ payload });
      if(res.status === 200) {
        const data = res.data.message;
        setResponse(data);
        let arr = [];
        for(let i = 0 ; i < data.length ; i++) {
          arr.push({
            applicationId: data[i].name,
            name: data[i].student_email_id,
            programGroup: data[i].program_group,
            program: data[i].program,
            college: data[i].college,
            status: data[i].status,
            action: i
          });
        }
  
        setRowData(arr);
      }
    }
  };

  const handleCreateMeritList = async () => {
    const payload = {
      filter: [
        {
          name: values.meritListName,
          "Program": values.program,
          "Academic Year": values.academicYear,
          "Merit List Criteria": values.meritCriteria,
          "selected_numbers": Number(values.studentCount)
        }
      ],
      message: [...response]
    };

    const res = await createMeritList({ payload });
    if(res.status === 200) {
      if(res.data.message[1] === 200)
        toast.success(res.data.message[0]);
      else if(res.data.message[1] === 400)
        toast.error(res.data.message[0]);
    } else {
      toast.error("some issue while generating");
    }
    
  }

  useEffect(() => {
    handleGetAcademicYearListing();
    handleGetProgramListing();
    handleGetMeritCriteriaListing();
  }, [])

  return (
    <>
      <MainHeader />
      {
        loading ?
          <LoadingBackdrop open={loading} /> :
          <div className={styles.mainContainer}>
            <div className={styles.breadCrumbsContainer}>
              <p className={styles.breadCrumbsContent} onClick={() => navigate("/dashboard")}>IIHT {" > "}</p>
              <p className={styles.breadCrumbsContent} onClick={() => navigate("/modules/admission")}>Admission {" > "}</p>
              <p className={styles.breadCrumbsContent} onClick={handleMeritListNavigation}>Merit List Tool {" > "}</p>
              <p className={styles.breadCrumbsContent} onClick={() => navigate("/modules/admission/merit-list")}>Merit List {" > "}</p>
              <p className={styles.breadCrumbsContent}>Create</p>
            </div>
            <div className={styles.headerContainer}>
              <div className={styles.breadCrumbsContainer}>
                <GridMenuIcon className={styles.gridIcon}/>
                <p className={styles.title}>New Merit List</p>
              </div>
              <div className={styles.buttonsGroup}>
                <div className={styles.button} onClick={() => navigate("/modules/admission/merit-list")}>
                  <p className={styles.buttonText}>Back</p>
                </div>
                {
                  flag ?  
                  <div className={styles.activeButton}>
                    <p className={styles.activeButtonText} onClick={handleCreateMeritList}>Generate Merit List</p>
                  </div> :
                  <div className={styles.activeButton}>
                    <p className={styles.activeButtonText} onClick={handleGetFilteredStudentInMeritList}>Get Merit List</p>
                  </div>
                }
              </div>
            </div>
            <div className={styles.formContainer}>
              <div className={styles.formRow}>
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>Academic Year *</p>
                  <Select
                    showSearch
                    size={selectSize}
                    placeholder="Select"
                    className={styles.selectInput}
                    options={academicYearOptions}
                    value={values.academicYear}
                    onChange={(val) => {
                      setValues({
                        ...values,
                        academicYear: val,
                      });
                      setErrors({
                        ...errors,
                        academicYear: "",
                      });
                    }}
                  />
                  {
                    errors.academicYear &&
                    <p className={styles.errorMessage}>{errors.academicYear}</p>
                  }
                </div>
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>Program *</p>
                  <Select
                    showSearch
                    size={selectSize}
                    placeholder="Select"
                    className={styles.selectInput}
                    options={programOptions}
                    value={values.program}
                    onChange={(val) => {
                      setValues({
                        ...values,
                        program: val,
                      });
                      setErrors({
                        ...errors,
                        program: "",
                      });
                    }}
                  />
                  {
                    errors.program &&
                    <p className={styles.errorMessage}>{errors.program}</p>
                  }
                </div>
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>Criteria *</p>
                  <Select
                    showSearch
                    size={selectSize}
                    placeholder="Select"
                    className={styles.selectInput}
                    options={meritCriteriaOptions}
                    value={values.meritCriteria}
                    onChange={(val) => {
                      setValues({
                        ...values,
                        meritCriteria: val,
                      });
                      setErrors({
                        ...errors,
                        meritCriteria: "",
                      });
                    }}
                  />
                  {
                    errors.meritCriteria &&
                    <p className={styles.errorMessage}>{errors.meritCriteria}</p>
                  }
                </div>
              </div>
              <div className={styles.formRow}>
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>Student Count *</p>
                  <input 
                    type="text" 
                    placeholder="ex 1000"
                    className={styles.fieldInput}
                    value={values.studentCount}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        studentCount: e.target.value,
                      });
                      setErrors({
                        ...errors,
                        studentCount: "",
                      });
                    }}
                  />
                  {
                    errors.studentCount &&
                    <p className={styles.errorMessage}>{errors.studentCount}</p>
                  }
                </div>
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>Merit List Name *</p>
                  <input 
                    type="text" 
                    placeholder="ex Text"
                    className={styles.fieldInput}
                    value={values.meritListName}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        meritListName: e.target.value,
                      });
                      setErrors({
                        ...errors,
                        meritListName: "",
                      });
                    }}
                  />
                  {
                    errors.meritListName &&
                    <p className={styles.errorMessage}>{errors.meritListName}</p>
                  }
                </div>
                <div className={styles.field}>
                </div>
              </div>
            </div>
            {
              flag &&  
              <Table 
                columns={columns} 
                dataSource={rowData} 
                size='small'
                pagination={true}
              />
            }
          </div>
      }
      <MainFooter />
    </>
  )
};

export default AddNewMeritList;
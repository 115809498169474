import * as React from "react";
import { styled } from "@mui/material/styles";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import "./index.scss";
import UploadButton from "../common/UploadButton";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove"
import { Button } from "iiht-b2a-ui-components/build/components";
import {
  getCities,
  States,
} from "../../modules/enquiry";
import { RemoveIconCircle } from "../../icons";
import { getDataOfApplicationFormByApplicationId, getSubjectsListing } from "../../modules/student";
import ImageViewer from 'react-simple-image-viewer';
import { PhotoProvider, PhotoView } from 'react-photo-view';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function FormAcademics({ handleNext, handleBack }) {
  const [flag, setFlag] = useState(false);
  const [values, setValues] = useState({
    custom_class_x_board: "",
    custom_stateunion_territory: "",
    custom_city_of_school: "",
    custom_school_name_x: "",
    custom_year_of_completion: "",
    custom_class_x_marksgrade_: "",
    custom_attach_xth_marksheet: "",
    custom_class_xi_board: "",
    custom_stateunion_territory_xi: "",
    custom_city_of_school_xi: "",
    custom_school_name_xi: '',
    custom_year_of_completion_xi: "",
    custom_class_xi_marksgrade_: "",
    custom_attach_xith_marksheet: "",
    custom_class_xii_board: "",
    custom_stateunion_territory_copy: "",
    custom_city_of_school_copy: "",
    custom_school_name_xii: '',
    custom_year_of_completion_xii: "",
    custom_class_xii_marksgrade_: "",
    custom_attach_xiith_marksheet: "",
    custom_xii_subject: [],
    custom_have_you_taken_a_gap_year_after_class_xii: null,
    custom_are_you_currently_enrolled_in_a_collegeuniversity: null,
  });

  const [customXiiSubject, setCustomXiiSubject] = useState([
    {
      docstatus: 0,
      doctype: "Subjects And Marks",
      name: "0f006f0d39",
      __unsaved: 1,
      owner: "Administrator",
      parent: "EDU-APP-2024-00001",
      parentfield: "custom_xii_subject",
      parenttype: "Student Applicant",
      idx: 1,
      subject: "",
      marks: "",
      grade: "",
      maximum_marksgrade: "",
      creation: "",
      modified: "",
      modified_by: "",
    },
  ]);

  const [subjectList, setSubjectList] = useState([]);
  const [subjectFields, setSubjectFields] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});
  const [updatedValues, setUpdatedValues] = useState(false);
  const [blockValues, setBlockValues] = useState({
    stateX: "",
    stateXI: "",
    stateXII: ""
  })

  const handleGetSubjectsListing = async () => {
    const res = await getSubjectsListing();
    if(res.status == 200) {
      setSubjectList(res.data.data);
    }
  }

  const handleXiiSubjectChange = (index, field, value) => {
    // Update the state with the new value
    const updatedSubjects = [...subjectFields];
    updatedSubjects[index][field] = value;
    setSubjectFields(updatedSubjects);
  };
  
  const handleRemoveSubject = () => {
    // Check if there are subjects to remove
    if (subjectFields.length > 0) {
      // Remove the last added subject
      const updatedSubjects = [...subjectFields];
      updatedSubjects.pop(); // Remove the last element
      setSubjectFields(updatedSubjects);
    }
  };

  const handleAddSubject = () => {
    // Add a new subject with default values
    setSubjectFields([...subjectFields, { id: subjectFields.length + 1 }]);
  };

  const handleInputChange = (index, fieldName, e) => {
    const value = e.target.value;
    handleXiiSubjectChange(index, fieldName, value);
  };

  const handleRadioChange = (field, value) => {
    setValues((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const [statesX, setStatesX] = useState([]);
  const [citiesX, setCitiesX] = useState([]);

  const [statesXI, setStatesXI] = useState([]);
  const [citiesXI, setCitiesXI] = useState([]);

  const [statesXII, setStatesXII] = useState([]);
  const [citiesXII, setCitiesXII] = useState([]);


  // const [subjectFields, setSubjectFields] = React.useState([
  //   { id: 1, subject: "", marks: "", maxMarks: "" },
  //   { id: 2, subject: "", marks: "", maxMarks: "" },
  //   { id: 3, subject: "", marks: "", maxMarks: "" },
  //   { id: 4, subject: "", marks: "", maxMarks: "" },
  //   { id: 5, subject: "", marks: "", maxMarks: "" },
  // ]);

  // const handleAddSubject = () => {
  //   setSubjectFields((prevFields) => [
  //     ...prevFields,
  //     {
  //       id: prevFields.length + 1,
  //       subject: "",
  //       marks: "",
  //       grade: "",
  //       maximum_marksgrade: "",
  //     },
  //   ]);
  // };

  // const handleRemoveSubject = () => {
  //   if (subjectFields.length > 1) {
  //     setSubjectFields((prevFields) => prevFields.slice(0, -1));
  //   }
  // };

  const saveData = () => {
    const customXiiSubjectArray = subjectFields.map((subject, index) => ({
      doctype: "Subjects And Marks",
      // idx: index + 1, // Assuming idx starts from 1
      subject: subject.subject,
      marks: subject.marks,
      grade: subject.grade,
      maximum_marksgrade: subject.maximum_marksgrade,
    }));

  };

  const isXiiSubjectDataFilled = () => {
    return customXiiSubject.every(
      (subject) =>
        subject.subject !== "" &&
        subject.marks !== "" &&
        subject.grade !== "" &&
        subject.maximum_marksgrade !== ""
    );
  };

  const handleGetDataOfApplicationFormByApplicationId = async () => {
    if (window.localStorage.getItem("application-name")) {
      const applicationId = JSON.parse(window.localStorage.getItem("application-name"));
      const res = await getDataOfApplicationFormByApplicationId({ applicationId });
      if (res.status == 200) {
        const data = res.data.data;
        setValues({
          ...values,
          custom_class_x_board: data.custom_class_x_board ? data.custom_class_x_board : "",
          custom_stateunion_territory: data.custom_stateunion_territory ? data.custom_stateunion_territory : "",
          custom_city_of_school: data.custom_city_of_school ? data.custom_city_of_school : "",
          custom_school_name_x: data.custom_school_name_x ? data.custom_school_name_x : "",
          custom_year_of_completion: data.custom_year_of_completion ? data.custom_year_of_completion : "",
          custom_class_x_marksgrade_: data.custom_class_x_marksgrade_ ? data.custom_class_x_marksgrade_ : "",
          custom_attach_xth_marksheet: data.custom_attach_xth_marksheet ? data.custom_attach_xth_marksheet : "",
          custom_class_xi_board: data.custom_class_xi_board ? data.custom_class_xi_board : "",
          custom_stateunion_territory_xi: data.custom_stateunion_territory_xi ? data.custom_stateunion_territory_xi : "",
          custom_city_of_school_xi: data.custom_city_of_school_xi ? data.custom_city_of_school_xi : "",
          custom_school_name_xi: data.custom_school_name_xi ? data.custom_school_name_xi : "",
          custom_year_of_completion_xi: data.custom_year_of_completion_xi ? data.custom_year_of_completion_xi : "",
          custom_class_xi_marksgrade_: data.custom_class_xi_marksgrade_ ? data.custom_class_xi_marksgrade_ : "",
          custom_attach_xith_marksheet: data.custom_attach_xith_marksheet ? data.custom_attach_xith_marksheet : "",
          custom_class_xii_board: data.custom_class_xii_board ? data.custom_class_xii_board : "",
          custom_stateunion_territory_copy: data.custom_stateunion_territory_copy ? data.custom_stateunion_territory_copy : "",
          custom_city_of_school_copy: data.custom_city_of_school_copy ? data.custom_city_of_school_copy : "",
          custom_school_name_xii: data.custom_school_name_xii ? data.custom_school_name_xii : "",
          custom_year_of_completion_xii: data.custom_year_of_completion_xii ? data.custom_year_of_completion_xii : "",
          custom_class_xii_marksgrade_: data.custom_class_xii_marksgrade_ ? data.custom_class_xii_marksgrade_ : "",
          custom_attach_xiith_marksheet: data.custom_attach_xiith_marksheet ? data.custom_attach_xiith_marksheet : "",
          custom_xii_subject: data.custom_xii_subject ? data.custom_xii_subject : "",
          custom_have_you_taken_a_gap_year_after_class_xii: data.custom_have_you_taken_a_gap_year_after_class_xii !== 0 || data.custom_have_you_taken_a_gap_year_after_class_xii !== 1 ? data.custom_have_you_taken_a_gap_year_after_class_xii == 1 ? 1 : 0 :  null,
          custom_are_you_currently_enrolled_in_a_collegeuniversity: data.custom_are_you_currently_enrolled_in_a_collegeuniversity !== 0 || data.custom_are_you_currently_enrolled_in_a_collegeuniversity !== 1 ? data.custom_are_you_currently_enrolled_in_a_collegeuniversity == 1 ? 1 : 0 : null,
        });
        if (data.custom_xii_subject) {
          const subjects = data.custom_xii_subject;
          const arr = [];
          for (let i = 0; i < subjects.length; i++) {
            const { subject, marks, maximum_marksgrade, subject_text } = subjects[i];
            arr.push({
              id: subjectFields.length + 1,
              subject: subject,
              marks: marks,
              grade: "",
              maximum_marksgrade: maximum_marksgrade,
              subject_text: subject_text
            });
          }
          setSubjectFields(arr);
        }
        if (data.custom_stateunion_territory) {
          setBlockValues({
            stateX: data.custom_stateunion_territory,
            stateXI: data.custom_stateunion_territory_xi,
            stateXII: data.custom_stateunion_territory_copy,
          });
          setUpdatedValues(true);
        }
      }
    }
  }

  useEffect(() => {
    handleGetState('X');
    handleGetState('XI');
    handleGetState('XII');
    handleGetDataOfApplicationFormByApplicationId();
  }, []);

  useEffect(() => {
    handleGetSubjectsListing();
  }, [])

  useEffect(() => {
    if (blockValues.stateX && blockValues.stateXII && blockValues.stateXI) {
      handleGetCitiesByState('X', blockValues.stateX);
      handleGetCitiesByState('XI', blockValues.stateXI);
      handleGetCitiesByState('XII', blockValues.stateXII);
    }
  }, [updatedValues, blockValues]);

  const handleGetState = async (block) => {
    const setStatesFunc = getSetStatesFunction(block);
    const setCitiesFunc = getSetCitiesFunction(block);

    setStatesFunc([]);
    setCitiesFunc([]);
    setValues({
      ...values,
      state: "",
      city: "",
    });

    const res = await States();
    if (res.status === 200) {
      setStatesFunc(res?.data?.data);
    }
  };

  const handleGetCitiesByState = async (block, state) => {
    const setCitiesFunc = getSetCitiesFunction(block);

    const res = await getCities({ state });
    if (res.status === 200) {
      setCitiesFunc(res?.data?.data);
    }
  };

  const getSetStatesFunction = (block) => {
    switch (block) {
      case 'X':
        return setStatesX;
      case 'XI':
        return setStatesXI;
      case 'XII':
        return setStatesXII;
      default:
        return setStatesX;
    }
  };

  const getSetCitiesFunction = (block) => {
    switch (block) {
      case 'X':
        return setCitiesX;
      case 'XI':
        return setCitiesXI;
      case 'XII':
        return setCitiesXII;
      default:
        return setCitiesX;
    }
  };

  const handleSave = () => {
    const formData = {
      custom_class_x_board: values.custom_class_x_board,
      custom_stateunion_territory: values.custom_stateunion_territory,
      custom_city_of_school: values.custom_city_of_school,
      custom_school_name_x: values.custom_school_name_x,
      custom_year_of_completion: values.custom_year_of_completion,
      custom_class_x_marksgrade_: values.custom_class_x_marksgrade_,
      custom_attach_xth_marksheet: values.custom_attach_xth_marksheet,
      custom_attach_xith_marksheet: values.custom_attach_xith_marksheet,
      custom_class_xi_board: values.custom_class_xi_board,
      custom_stateunion_territory_xi: values.custom_stateunion_territory_xi,
      custom_city_of_school_xi: values.custom_city_of_school_xi,
      custom_school_name_xi: values.custom_school_name_xi,
      custom_year_of_completion_xi: values.custom_year_of_completion_xi,
      custom_class_xi_marksgrade_: values.custom_class_xi_marksgrade_,
      custom_class_xii_board: values.custom_class_xii_board,
      custom_stateunion_territory_copy: values.custom_stateunion_territory_copy,
      custom_city_of_school_copy: values.custom_city_of_school_copy,
      custom_school_name_xii: values.custom_school_name_xii,
      custom_year_of_completion_xii: values.custom_year_of_completion_xii,
      custom_class_xii_marksgrade_: values.custom_class_xii_marksgrade_,
      custom_attach_xiith_marksheet: values.custom_attach_xiith_marksheet,
      custom_have_you_taken_a_gap_year_after_class_xii:
        values.custom_have_you_taken_a_gap_year_after_class_xii,
      custom_are_you_currently_enrolled_in_a_collegeuniversity:
        values.custom_are_you_currently_enrolled_in_a_collegeuniversity,
      custom_xii_subject: subjectFields.map((subject) => ({
        subject: subject.subject,
        marks: subject.marks,
        grade: subject.grade,
        maximum_marksgrade: subject.maximum_marksgrade,
        subject_text: subject.subject_text || ""
      })),
    };

    const newErrorMessages = {};

    // Check if any field is empty
    for (const key in formData) {

      if (!formData[key]) {
        newErrorMessages[key] = "Required";
      }

      if(key == "custom_have_you_taken_a_gap_year_after_class_xii" || 
         key == "custom_are_you_currently_enrolled_in_a_collegeuniversity") {
        delete newErrorMessages[key];
      }
    }


    // Check for at least 5 subjects
    if (subjectFields.length < 5) {
      newErrorMessages["commonSubjectMessage"] = "Please add at least 5 subjects.";
    } else {
      delete newErrorMessages["commonSubjectMessage"];
    }

    if(formData.custom_class_x_marksgrade_ !== ""){
      delete newErrorMessages["custom_class_x_marksgrade_"];
    }

    if(formData.custom_class_xi_marksgrade_ !== ""){
      delete newErrorMessages["custom_class_xi_marksgrade_"];
    }

    if(formData.custom_class_xii_marksgrade_ !== ""){
      delete newErrorMessages["custom_class_xii_marksgrade_"];
    }

    setErrorMessages(newErrorMessages);
  
    if(Object.keys(newErrorMessages).length <= 0)
      handleNext({ payload: formData, addData: false });

  };

  return (
    <>
      <Box sx={{ boxShadow: "none" }} className="formPersonalDetails">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item className="mainFormBody">
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={12}>
                  <Item>
                    <h3>
                      Education X<sup>th</sup> Details
                    </h3>
                    <p>
                      Any attachment or text field which does not follow the
                      guidelines will render your application incomplete or
                      ineligible. Please click here to refer to the criteria for
                      more information
                    </p>
                  </Item>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">
                    Class X<sup>th</sup> Board*
                  </Typography>
                  <select
                    style={{
                      color: "#536882",
                      fontSize: "12px",
                      fontWeight: "400",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #CDD6E2",
                      background: "#fff",
                      outline: "none",
                      width: "100%",
                    }}
                    onChange={(e) => {
                      setValues({ ...values, custom_class_x_board: e.target.value });
                      setErrorMessages({ ...errorMessages, custom_class_x_board: "" });
                    }}

                  >
                    <option disabled={true} selected={values.custom_class_x_board === ""}>
                      Board
                    </option>
                    <option value="CBSE">CBSE</option>
                    <option>ICSE</option>
                    <option>SSC</option>
                    <option>UP Board</option>
                    <option>Bihar Board</option>
                    <option>MP Board</option>
                  </select>
                  {errorMessages.custom_class_x_board && (
                    <span style={{ color: 'red' }}>{errorMessages.custom_class_x_board}</span>
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">State/Union Territory *</Typography>
                  <select
                    style={{
                      color: "#536882",
                      fontSize: "12px",
                      fontWeight: "400",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #CDD6E2",
                      background: "#fff",
                      outline: "none",
                      width: "100%",
                    }}
                    onChange={(e) => {
                      setValues({ ...values, custom_stateunion_territory: e.target.value });
                      setErrorMessages({ ...errorMessages, custom_stateunion_territory: "" });
                      handleGetCitiesByState('X', e.target.value);
                    }}
                  // className={styles.dropDownInputText}
                  >
                    <option value="" disabled selected={values.custom_stateunion_territory === ""}>
                      State
                    </option>
                    {statesX.map((state) => (
                      <option
                        key={state.name}
                        value={state.name}
                        selected={values.custom_stateunion_territory === state.name}
                      >
                        {state.name}
                      </option>
                    ))}
                  </select>
                  {errorMessages.custom_stateunion_territory && (
                    <span style={{ color: 'red' }}>{errorMessages.custom_stateunion_territory}</span>
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">City of School *</Typography>
                  <select
                    style={{
                      color: "#536882",
                      fontSize: "12px",
                      fontWeight: "400",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #CDD6E2",
                      background: "#fff",
                      outline: "none",
                      width: "100%",
                    }}
                    onChange={(e) => {
                      setValues({ ...values, custom_city_of_school: e.target.value });
                      setErrorMessages({ ...errorMessages, custom_city_of_school: "" });

                      if (e.target.value) {
                        // setErrorMessages({ ...errorMessages, city: "" });
                      }
                    }}
                    value={values.custom_city_of_school}
                  // className={styles.dropDownInputText}
                  >
                    <option value="" disabled selected={values.custom_city_of_school === ""}>
                      City
                    </option>
                    {citiesX.map((city) => (
                      <option
                        key={city.name}
                        value={city.name}
                        selected={values.custom_city_of_school === city.name}
                      >
                        {city.name}
                      </option>
                    ))}
                  </select>
                  {errorMessages.custom_city_of_school && (
                    <span style={{ color: 'red' }}>{errorMessages.custom_city_of_school}</span>
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">School Attended *</Typography>
                  <input
                    type="text"
                    style={{
                      color: "#536882",
                      fontSize: "12px",
                      fontWeight: "400",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #CDD6E2",
                      background: "#fff",
                      outline: "none",
                      width: "100%",
                    }}
                    placeholder="School"
                    value={values.custom_school_name_x}
                    onChange={(e) => {
                      setValues({ ...values, custom_school_name_x: e.target.value });
                      setErrorMessages({ ...errorMessages, custom_school_name_x: "" });
                    }}
                  />
                  {errorMessages.custom_school_name_x && (
                    <span style={{ color: 'red' }}>{errorMessages.custom_school_name_x}</span>
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">Year of Completion *</Typography>
                  <select
                    style={{
                      color: "#536882",
                      fontSize: "12px",
                      fontWeight: "400",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #CDD6E2",
                      background: "#fff",
                      outline: "none",
                      width: "100%",
                    }}
                    onChange={(e) => {
                      setValues({ ...values, custom_year_of_completion: e.target.value });
                      setErrorMessages({ ...errorMessages, custom_year_of_completion: "" });

                    }}
                  >
                    <option disabled={true} selected={values.custom_year_of_completion === ""}>
                      Year Of Completion
                    </option>
                    <option value="2015" selected={values.custom_year_of_completion == "2015"}>2015</option>
                    <option value="2016" selected={values.custom_year_of_completion == "2016"}>2016</option>
                    <option value="2017" selected={values.custom_year_of_completion == "2017"}>2017</option>
                    <option value="2018" selected={values.custom_year_of_completion == "2018"}>2018</option>
                    <option value="2019" selected={values.custom_year_of_completion == "2019"}>2019</option>
                    <option value="2020" selected={values.custom_year_of_completion == "2020"}>2020</option>
                    <option value="2021" selected={values.custom_year_of_completion == "2021"}>2021</option>
                    <option value="2022" selected={values.custom_year_of_completion == "2022"}>2022</option>
                    <option value="2023" selected={values.custom_year_of_completion == "2023"}>2023</option>

                  </select>
                  {errorMessages.custom_year_of_completion && (
                    <span style={{ color: 'red' }}>{errorMessages.custom_year_of_completion}</span>
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">Class X Marks/Grade *</Typography>
                  <input
                    required
                    id="outlined-required"
                    type="Grade"
                    name="grade"
                    style={{
                      height: "40px",
                      width: "100%",
                      padding: '12px',
                      borderRadius: "4px",
                      border: "1px solid #CDD6E2",
                      backgroundColor: "#FFF",
                    }}
                    value={values.custom_class_x_marksgrade_}
                    placeholder="Class X Marks/Grade"
                    onChange={(e) => {
                      setValues({ ...values, custom_class_x_marksgrade_: e.target.value });
                      setErrorMessages({ ...errorMessages, custom_class_x_marksgrade_: "" });

                    }}
                  />
                  {errorMessages.custom_class_x_marksgrade_ && (
                    <span style={{ color: 'red' }}>{errorMessages.custom_class_x_marksgrade_}</span>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="body1">
                    Attach Marksheet (If it is unavailable, attach an official
                    letter issued by your school listing your marks/grades.)
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">
                    Upload X<sup>th</sup> Marksheet*
                  </Typography>
                  <UploadButton formData={values} setFormData={setValues} fieldName="custom_attach_xth_marksheet" flag={flag} setFlag={setFlag} fileTypes={["JPG", "PNG", "JPEG", "PDF"]}/>
                  {errorMessages.custom_attach_xth_marksheet && (
                    <span style={{ color: 'red' }}>{errorMessages.custom_attach_xth_marksheet}</span>
                  )}
                  { 
                    values.custom_attach_xth_marksheet ? 
                      values.custom_attach_xth_marksheet.split(".")[1] === "pdf" ? 
                        <p style={{ color: "black", textDecoration: "none", cursor: "pointer", fontSize: "12px" }} onClick={() => window.open(`https://erp-dev.techademycampus.com${values.custom_attach_xth_marksheet}`, "_blank")}>{values.custom_attach_xth_marksheet.split("/")[2]}</p> : 
                        <PhotoProvider>
                        <div className="foo">
                          <PhotoView src={`https://erp-dev.techademycampus.com${values.custom_attach_xth_marksheet}`}>
                            <img src={`https://erp-dev.techademycampus.com${values.custom_attach_xth_marksheet}`} alt="" style={{width: "150px", height: "100px", marginTop: "12px", cursor: "pointer"}}/>
                          </PhotoView>
                        </div>
                    </PhotoProvider> :
                    <></>
                  }
                </Grid>
              </Grid>
            </Item>
          </Grid>

          <Grid item xs={12}>
            <Item className="mainFormBody">
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={12}>
                  <Item>
                    <h3>
                      Education XI<sup>th</sup> Details
                    </h3>
                    <p>
                      Any attachment or text field which does not follow the
                      guidelines will render your application incomplete or
                      ineligible. Please click here to refer to the criteria for
                      more information
                    </p>
                  </Item>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">
                    Class XI<sup>th</sup> Board *
                  </Typography>
                  <select
                    style={{
                      color: "#536882",
                      fontSize: "12px",
                      fontWeight: "400",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #CDD6E2",
                      background: "#fff",
                      outline: "none",
                      width: "100%",
                    }}
                    onChange={(e) => {
                      setValues({ ...values, custom_class_xi_board: e.target.value });
                      setErrorMessages({ ...errorMessages, custom_class_xi_board: "" });
                    }}
                  >
                    <option disabled={true} selected={values.custom_class_xi_board === ""}>
                      Board
                    </option>
                    <option value="CBSE">CBSE</option>
                    <option>ICSE</option>
                    <option>SSC</option>
                    <option>UP Board</option>
                    <option>Bihar Board</option>
                    <option>MP Board</option>
                  </select>
                  {errorMessages.custom_class_xi_board && (
                    <span style={{ color: 'red' }}>{errorMessages.custom_class_xi_board}</span>
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">State/Union Territory *</Typography>
                  <select
                    style={{
                      color: "#536882",
                      fontSize: "12px",
                      fontWeight: "400",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #CDD6E2",
                      background: "#fff",
                      outline: "none",
                      width: "100%",
                    }}
                    onChange={(e) => {
                      setValues({ ...values, custom_stateunion_territory_xi: e.target.value });
                      setErrorMessages({ ...errorMessages, custom_stateunion_territory_xi: "" });
                      handleGetCitiesByState('XI', e.target.value);

                      if (e.target.value) {
                        // setErrorMessages({ ...errorMessages, state: "" });
                      }
                    }}
                  // className={styles.dropDownInputText}
                  >
                    <option value="" disabled selected={values.custom_stateunion_territory_xi === ""}>
                      State
                    </option>
                    {statesXI.map((state) => (
                      <option
                        key={state.name}
                        value={state.name}
                        selected={values.custom_stateunion_territory_xi === state.name}
                      >
                        {state.name}
                      </option>
                    ))}
                  </select>
                  {errorMessages.custom_stateunion_territory_xi && (
                    <span style={{ color: 'red' }}>{errorMessages.custom_stateunion_territory_xi}</span>
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">City of School *</Typography>
                  <select
                    style={{
                      color: "#536882",
                      fontSize: "12px",
                      fontWeight: "400",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #CDD6E2",
                      background: "#fff",
                      outline: "none",
                      width: "100%",
                    }}
                    onChange={(e) => {
                      setValues({ ...values, custom_city_of_school_xi: e.target.value });
                      setErrorMessages({ ...errorMessages, custom_city_of_school_xi: "" });
                      if (e.target.value) {
                        // setErrorMessages({ ...errorMessages, city: "" });
                      }
                    }}
                    value={values.custom_city_of_school_xi}
                  // className={styles.dropDownInputText}
                  >
                    <option value="" disabled selected={values.custom_city_of_school_xi === ""}>
                      City
                    </option>
                    {citiesXI.map((city) => (
                      <option
                        key={city.name}
                        value={city.name}
                        selected={values.custom_city_of_school_xi === city.name}
                      >
                        {city.name}
                      </option>
                    ))}
                  </select>
                  {errorMessages.custom_city_of_school_xi && (
                    <span style={{ color: 'red' }}>{errorMessages.custom_city_of_school_xi}</span>
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">School Attended *</Typography>
                  <input
                    type="text"
                    style={{
                      color: "#536882",
                      fontSize: "12px",
                      fontWeight: "400",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #CDD6E2",
                      background: "#fff",
                      outline: "none",
                      width: "100%",
                    }}
                    placeholder="School"
                    value={values.custom_school_name_xi}
                    onChange={(e) => {
                      setValues({ ...values, custom_school_name_xi: e.target.value });
                      setErrorMessages({ ...errorMessages, custom_school_name_xi: "" });
                    }}
                  />
                  {errorMessages.custom_school_name_xi && (
                    <span style={{ color: 'red' }}>{errorMessages.custom_school_name_xi}</span>
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">Year of Completion *</Typography>
                  <select
                    style={{
                      color: "#536882",
                      fontSize: "12px",
                      fontWeight: "400",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #CDD6E2",
                      background: "#fff",
                      outline: "none",
                      width: "100%",
                    }}
                    onChange={(e) => {
                      setValues({ ...values, custom_year_of_completion_xi: e.target.value });
                      setErrorMessages({ ...errorMessages, custom_year_of_completion_xi: "" });

                    }}
                  >
                    <option disabled={true} selected={values.custom_year_of_completion_xi === ""}>
                      Year Of Completion
                    </option>
                    <option value="2015" selected={values.custom_year_of_completion_xi == "2015"}>2015</option>
                    <option value="2016" selected={values.custom_year_of_completion_xi == "2016"}>2016</option>
                    <option value="2017" selected={values.custom_year_of_completion_xi == "2017"}>2017</option>
                    <option value="2018" selected={values.custom_year_of_completion_xi == "2018"}>2018</option>
                    <option value="2019" selected={values.custom_year_of_completion_xi == "2019"}>2019</option>
                    <option value="2020" selected={values.custom_year_of_completion_xi == "2020"}>2020</option>
                    <option value="2021" selected={values.custom_year_of_completion_xi == "2021"}>2021</option>
                    <option value="2022" selected={values.custom_year_of_completion_xi == "2022"}>2022</option>
                    <option value="2023" selected={values.custom_year_of_completion_xi == "2023"}>2023</option>
                  </select>
                  {errorMessages.custom_year_of_completion_xi && (
                    <span style={{ color: 'red' }}>{errorMessages.custom_year_of_completion_xi}</span>
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">Class XI Marks/Grade *</Typography>
                  <input
                    required
                    id="outlined-required"
                    type="Grade"
                    name="grade"
                    value={values.custom_class_xi_marksgrade_}
                    style={{
                      height: "40px",
                      width: "100%",
                      padding: '12px',
                      borderRadius: "4px",
                      border: "1px solid #CDD6E2",
                      backgroundColor: "#FFF",
                    }}
                    placeholder="Class XI Marks/Grade"
                    onChange={(e) => {
                      setValues({ ...values, custom_class_xi_marksgrade_: e.target.value });
                      setErrorMessages({ ...errorMessages, custom_class_xi_marksgrade_: "" });

                    }}
                  />
                  {errorMessages.custom_class_xi_marksgrade_ && (
                    <span style={{ color: 'red' }}>{errorMessages.custom_class_xi_marksgrade_}</span>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="body1">
                    Attach Marksheet (If it is unavailable, attach an official
                    letter issued by your school listing your marks/grades.)
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">
                    Upload XI<sup>th</sup> Marksheet*
                  </Typography>
                  <UploadButton formData={values} setFormData={setValues} fieldName="custom_attach_xith_marksheet" flag={flag} setFlag={setFlag} fileTypes={["JPG", "PNG", "JPEG", "PDF"]}/>
                  {errorMessages.custom_attach_xith_marksheet && (
                    <span style={{ color: 'red' }}>{errorMessages.custom_attach_xith_marksheet}</span>
                  )}
                  { 
                    values.custom_attach_xith_marksheet ? 
                      values.custom_attach_xith_marksheet.split(".")[1] === "pdf" ? 
                        <p style={{ color: "black", textDecoration: "none", cursor: "pointer", fontSize: "12px" }} onClick={() => window.open(`https://erp-dev.techademycampus.com${values.custom_attach_xith_marksheet}`, "_blank")}>{values.custom_attach_xith_marksheet.split("/")[2]}</p> : 
                        <PhotoProvider>
                        <div className="foo">
                          <PhotoView src={`https://erp-dev.techademycampus.com${values.custom_attach_xith_marksheet}`}>
                            <img src={`https://erp-dev.techademycampus.com${values.custom_attach_xith_marksheet}`} alt="" style={{width: "150px", height: "100px", marginTop: "12px", cursor: "pointer"}}/>
                          </PhotoView>
                        </div>
                    </PhotoProvider> :
                    <></>
                  }
                </Grid>
              </Grid>
            </Item>
          </Grid>

          <Grid item xs={12}>
            <Item className="mainFormBody">
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={12}>
                  <Item>
                    <h3>
                      Education XII<sup>th</sup> Details
                    </h3>
                    <p>
                      Any attachment or text field which does not follow the
                      guidelines will render your application incomplete or
                      ineligible. Please click here to refer to the criteria for
                      more information
                    </p>
                  </Item>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">
                    Class XII<sup>th</sup> Board *
                  </Typography>
                  <select
                    style={{
                      color: "#536882",
                      fontSize: "12px",
                      fontWeight: "400",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #CDD6E2",
                      background: "#fff",
                      outline: "none",
                      width: "100%",
                    }}
                    onChange={(e) => {
                      setValues({ ...values, custom_class_xii_board: e.target.value });
                      setErrorMessages({ ...errorMessages, custom_class_xii_board: "" });

                    }}
                  >
                    <option disabled={true} selected={values.custom_class_xii_board === ""}>
                      Board
                    </option>
                    <option value="CBSE">CBSE</option>
                    <option>ICSE</option>
                    <option>SSC</option>
                    <option>UP Board</option>
                    <option>Bihar Board</option>
                    <option>MP Board</option>
                  </select>
                  {errorMessages.custom_class_xii_board && (
                    <span style={{ color: 'red' }}>{errorMessages.custom_class_xii_board}</span>
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">State/Union Territory *</Typography>
                  <select
                    style={{
                      color: "#536882",
                      fontSize: "12px",
                      fontWeight: "400",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #CDD6E2",
                      background: "#fff",
                      outline: "none",
                      width: "100%",
                    }}
                    onChange={(e) => {
                      setValues({ ...values, custom_stateunion_territory_copy: e.target.value });
                      setErrorMessages({ ...errorMessages, custom_stateunion_territory_copy: "" });
                      handleGetCitiesByState('XII', e.target.value);

                      if (e.target.value) {
                        // setErrorMessages({ ...errorMessages, state: "" });
                      }
                    }}
                  // className={styles.dropDownInputText}
                  >
                    <option value="" disabled selected={values.custom_stateunion_territory_copy === ""}>
                      State
                    </option>
                    {statesXII.map((state) => (
                      <option
                        key={state.name}
                        value={state.name}
                        selected={values.custom_stateunion_territory_copy === state.name}
                      >
                        {state.name}
                      </option>
                    ))}
                  </select>
                  {errorMessages.custom_stateunion_territory_copy && (
                    <span style={{ color: 'red' }}>{errorMessages.custom_stateunion_territory_copy}</span>
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">City of School *</Typography>
                  <select
                    style={{
                      color: "#536882",
                      fontSize: "12px",
                      fontWeight: "400",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #CDD6E2",
                      background: "#fff",
                      outline: "none",
                      width: "100%",
                    }}
                    onChange={(e) => {
                      setValues({ ...values, custom_city_of_school_copy: e.target.value });
                      setErrorMessages({ ...errorMessages, custom_city_of_school_copy: "" });
                      if (e.target.value) {
                        // setErrorMessages({ ...errorMessages, city: "" });
                      }
                    }}
                    value={values.custom_city_of_school_copy}
                  // className={styles.dropDownInputText}
                  >
                    <option value="" disabled selected={values.custom_city_of_school_copy === ""}>
                      City
                    </option>
                    {citiesXII.map((city) => (
                      <option
                        key={city.name}
                        value={city.name}
                        selected={values.custom_city_of_school_copy === city.name}
                      >
                        {city.name}
                      </option>
                    ))}
                  </select>
                  {errorMessages.custom_city_of_school_copy && (
                    <span style={{ color: 'red' }}>{errorMessages.custom_city_of_school_copy}</span>
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">School Attended *</Typography>
                  <input
                    type="text"
                    style={{
                      color: "#536882",
                      fontSize: "12px",
                      fontWeight: "400",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #CDD6E2",
                      background: "#fff",
                      outline: "none",
                      width: "100%",
                    }}
                    placeholder="School"
                    value={values.custom_school_name_xii}
                    onChange={(e) => {
                      setValues({ ...values, custom_school_name_xii: e.target.value });
                      setErrorMessages({ ...errorMessages, custom_school_name_xii: "" });
                    }}
                  />
                  {errorMessages.custom_school_name_xii && (
                    <span style={{ color: 'red' }}>{errorMessages.custom_school_name_xii}</span>
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">Year of Completion *</Typography>
                  <select
                    style={{
                      color: "#536882",
                      fontSize: "12px",
                      fontWeight: "400",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #CDD6E2",
                      background: "#fff",
                      outline: "none",
                      width: "100%",
                    }}
                    onChange={(e) => {
                      setValues({ ...values, custom_year_of_completion_xii: e.target.value });
                      setErrorMessages({ ...errorMessages, custom_year_of_completion_xii: "" });

                    }}
                  >
                    <option disabled={true} selected={values.custom_year_of_completion_xii === ""}>
                      Year Of Completion
                    </option>
                    <option value="2015" selected={values.custom_year_of_completion_xii == "2015"}>2015</option>
                    <option value="2016" selected={values.custom_year_of_completion_xii == "2016"}>2016</option>
                    <option value="2017" selected={values.custom_year_of_completion_xii == "2017"}>2017</option>
                    <option value="2018" selected={values.custom_year_of_completion_xii == "2018"}>2018</option>
                    <option value="2019" selected={values.custom_year_of_completion_xii == "2019"}>2019</option>
                    <option value="2020" selected={values.custom_year_of_completion_xii == "2020"}>2020</option>
                    <option value="2021" selected={values.custom_year_of_completion_xii == "2021"}>2021</option>
                    <option value="2022" selected={values.custom_year_of_completion_xii == "2022"}>2022</option>
                    <option value="2023" selected={values.custom_year_of_completion_xii == "2023"}>2023</option>
                  </select>
                  {errorMessages.custom_year_of_completion_xii && (
                    <span style={{ color: 'red' }}>{errorMessages.custom_year_of_completion_xii}</span>
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">Class XII Marks/Grade *</Typography>
                  <input
                    required
                    id="outlined-required"
                    type="Grade"
                    name="grade"
                    value={values.custom_class_xii_marksgrade_}
                    style={{
                      height: "40px",
                      width: "100%",
                      padding: '12px',
                      borderRadius: "4px",
                      border: "1px solid #CDD6E2",
                      backgroundColor: "#FFF",
                    }}
                    placeholder="Class XII Marks/Grade"
                    onChange={(e) => {
                      setValues({ ...values, custom_class_xii_marksgrade_: e.target.value });
                      setErrorMessages({ ...errorMessages, custom_class_xii_marksgrade_: "" });
                    }}
                  />
                  {errorMessages.custom_class_xii_marksgrade_ && (
                    <span style={{ color: 'red' }}>{errorMessages.custom_class_xii_marksgrade_}</span>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <h4>
                    XII<sup>th</sup> Details
                  </h4>
                  <Typography variant="body1">
                    Mention your subjects carefully as you will not be able to
                    edit or add to your subject list later. Please do not fill NA
                    in any mandatory field.
                  </Typography>
                </Grid>

                <Grid item xs={12} className="siblingContainer">
                  <Item className="mainFormBody">
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                      className="siblingItemWrap"
                    >
                      <Grid item xs={1} sm={1} md={1}>
                        <Typography variant="body1">S. No.</Typography>
                      </Grid>
                      <Grid item xs={2} sm={3} md={3}>
                        <Typography variant="body1">
                          XII<sup>th</sup> Subjects
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        <Typography variant="body1">
                          XII<sup>th</sup> Marks/Grade
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        <Typography variant="body1">
                          XII<sup>th</sup> Maximum Marks/Grade possible
                        </Typography>
                      </Grid>
                    </Grid>
                    {errorMessages["commonSubjectMessage"] && (
                      <span style={{ color: 'red', fontSize: '12px' }}>
                        {errorMessages["commonSubjectMessage"]}
                      </span>
                    )}
                    {subjectFields.map((field, index) => (
                      <React.Fragment key={field.id}>
                        <Grid
                          container
                          spacing={{ xs: 2, md: 3 }}
                          columns={{ xs: 4, sm: 8, md: 12 }}
                          className="siblingItemWrap"
                        >
                          <Grid item xs={1} sm={1} md={1}>
                            <TextField
                              required
                              value={index+1}
                              name={`idx${field.id}`}
                              onChange={(e) => handleXiiSubjectChange(index, 'idx', e)}
                            />
                          </Grid>
                          <Grid item xs={2} sm={3} md={3}>
                            {
                              field.subject !== "Others" ? 
                                <select
                                  style={{
                                    color: "#536882",
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    border: "1px solid #CDD6E2",
                                    background: "#fff",
                                    outline: "none",
                                    width: "100%",
                                  }}
                                  onChange={(e) => {
                                    if(e.target.value == "Others") {
                                      handleXiiSubjectChange(index, 'subject', "Others")
                                    } else {
                                      handleXiiSubjectChange(index, 'subject', e.target.value)
                                    }
                                  }}
                                >
                                  <option disabled={true} selected={field.subject == "" || !field.subject}>
                                    Select Subject
                                  </option>
                                  {
                                    subjectList && subjectList.map((obj) => {
                                      return (
                                        <option key={obj.idx} value={obj.name} selected={field.subject == obj.name}>
                                          {obj.name}
                                        </option>
                                      )
                                    })
                                  }
                                </select> :
                                <TextField
                                  required
                                  value={field.subject_text}
                                  name={`subject${field.id}`}
                                  placeholder="Subject Name *"
                                  onChange={(e) => {
                                    handleXiiSubjectChange(index, 'subject', "Others");
                                    handleXiiSubjectChange(index,'subject_text', e.target.value);
                                  }}
                                />
                            }
                            {errorMessages[`subject${index}`] && (
                              <span style={{ color: 'red', fontSize: '12px' }}>
                                {errorMessages[`subject${index}`]}
                              </span>
                            )}
                          </Grid>
                          <Grid item xs={2} sm={4} md={4}>
                            <TextField
                              required
                              name={`marks${field.id}`}
                              placeholder="Enter Marks/Grade *"
                              value={field.marks}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (!isNaN(value)) {
                                  handleXiiSubjectChange(index, 'marks', value);
                                } else {
                                  handleXiiSubjectChange(index, 'grade', value);
                                }
                              }}
                            />
                            {errorMessages[`marks${index}`] && (
                              <span style={{ color: 'red', fontSize: '12px' }}>
                                {errorMessages[`marks${index}`]}
                              </span>
                            )}
                          </Grid>
                          <Grid item xs={2} sm={4} md={4}>
                            <TextField
                              required
                              value={field.maximum_marksgrade}
                              name={`maximum_marksgrade${field.id}`}
                              placeholder="A*"
                              onChange={(e) => handleXiiSubjectChange(index, 'maximum_marksgrade', e.target.value)}
                            />
                            {errorMessages[`grade${index}`] && (
                              <span style={{ color: 'red', fontSize: '12px' }}>
                                {errorMessages[`grade${index}`]}
                              </span>
                            )}
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Item>
                  <div className="dynamicFieldbutton">
                    <IconButton
                      onClick={handleRemoveSubject}
                      style={{ color: 'white', borderRadius: '4px' }}
                    >
                      <RemoveIconCircle />
                    </IconButton>
                    <IconButton onClick={handleAddSubject} >
                      <AddIcon style={{ color: 'white', background: 'darkred', borderRadius: '4px', }} />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">
                    Upload XII<sup>th</sup> Marksheet*
                  </Typography>
                  <UploadButton formData={values} setFormData={setValues} fieldName="custom_attach_xiith_marksheet" flag={flag} setFlag={setFlag} fileTypes={["JPG", "PNG", "JPEG", "PDF"]}/>
                  {errorMessages.custom_attach_xiith_marksheet && (
                    <span style={{ color: 'red' }}>{errorMessages.custom_attach_xiith_marksheet}</span>
                  )}
                  { 
                    values.custom_attach_xiith_marksheet ? 
                      values.custom_attach_xiith_marksheet.split(".")[1] === "pdf" ? 
                        <p style={{ color: "black", textDecoration: "none", cursor: "pointer", fontSize: "12px" }} onClick={() => window.open(`https://erp-dev.techademycampus.com${values.custom_attach_xiith_marksheet}`, "_blank")}>{values.custom_attach_xiith_marksheet.split("/")[2]}</p> : 
                        <PhotoProvider>
                        <div className="foo">
                          <PhotoView src={`https://erp-dev.techademycampus.com${values.custom_attach_xiith_marksheet}`}>
                            <img src={`https://erp-dev.techademycampus.com${values.custom_attach_xiith_marksheet}`} alt="" style={{width: "150px", height: "100px", marginTop: "12px", cursor: "pointer"}}/>
                          </PhotoView>
                        </div>
                    </PhotoProvider> :
                    <></>
                  }
                </Grid>

                <Grid item xs={2} sm={8} md={8}>
                  &nbsp;
                </Grid>


                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">
                    Have you taken a gap year after Class XII? *
                  </Typography>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                      checked={(values.custom_have_you_taken_a_gap_year_after_class_xii == "yes" || values.custom_have_you_taken_a_gap_year_after_class_xii == 1)}
                      onClick={() => {
                        handleRadioChange('custom_have_you_taken_a_gap_year_after_class_xii', 1);
                        setErrorMessages({ ...errorMessages, custom_have_you_taken_a_gap_year_after_class_xii: null });
                      }}
                    />
                    <FormControlLabel value="no" control={<Radio />} label="No"
                      checked={(values.custom_have_you_taken_a_gap_year_after_class_xii == "no" || values.custom_have_you_taken_a_gap_year_after_class_xii == 0)}
                      onClick={() => {
                        handleRadioChange('custom_have_you_taken_a_gap_year_after_class_xii', 0);
                        setErrorMessages({ ...errorMessages, custom_have_you_taken_a_gap_year_after_class_xii: null });
                      }} />
                  </RadioGroup>
                  {errorMessages.custom_have_you_taken_a_gap_year_after_class_xii && (
                    <span style={{ color: 'red' }}>{errorMessages.custom_have_you_taken_a_gap_year_after_class_xii}</span>
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">
                    Are you currently enrolled in a college/University? *
                  </Typography>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                      checked={(values.custom_are_you_currently_enrolled_in_a_collegeuniversity == "yes" 
                    || values.custom_are_you_currently_enrolled_in_a_collegeuniversity == 1)}
                      onClick={() => {
                        handleRadioChange('custom_are_you_currently_enrolled_in_a_collegeuniversity', 1);
                        setErrorMessages({ ...errorMessages, custom_are_you_currently_enrolled_in_a_collegeuniversity: null });
                      }}
                    />
                    <FormControlLabel 
                      value="no" 
                      control={<Radio />} 
                      label="No" 
                      checked={(values.custom_are_you_currently_enrolled_in_a_collegeuniversity == "no" || values.custom_are_you_currently_enrolled_in_a_collegeuniversity == 0)}
                      onClick={() => {
                        handleRadioChange('custom_are_you_currently_enrolled_in_a_collegeuniversity', 0);
                        setErrorMessages({ ...errorMessages, custom_are_you_currently_enrolled_in_a_collegeuniversity: null });
                      }}
                    />
                  </RadioGroup>
                  {errorMessages.custom_are_you_currently_enrolled_in_a_collegeuniversity && (
                    <span style={{ color: 'red' }}>{errorMessages.custom_are_you_currently_enrolled_in_a_collegeuniversity}</span>
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  &nbsp;
                </Grid>
              </Grid>
            </Item>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "row", pt: 2, justifyContent: "space-between" }}
        className="navBottomBtns"
      >
        <div style={{ display: "flex", gap: "12px" }}>
          <Button
            color="inherit"
            sx={{ mr: 1 }}
            className="backBtn"
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            onClick={() => {
              handleSave();
            }}
            sx={{ mr: 1 }}
          >
            Save
          </Button>
        </div>
        <Button sx={{ mr: 1 }} onClick={() => handleSave()}>
          Next
        </Button>
      </Box>
    </>
  );
}

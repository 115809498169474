import React, { useState, useEffect } from "react";
import useStyles from "./style";
import { ReactSVG } from "react-svg";
import Menu from "@mui/material/Menu";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridMenuIcon } from "@mui/x-data-grid";
import { setLoading } from "../../../slices/commonSlice";
import ArrowDownSVG from "../../../assets/images/arrow-down.svg";
import FiltersSVG from "../../../assets/images/filters-icon.svg";
import { getAllFeeStructures } from "../../../modules/finance";
import LoadingBackdrop from "../../common/LoadingBackdrop.js";
import { Table } from "antd";
import APIs from "api";
import ActionExport from "components/Filters/ActionExport";
import BulkEdit from "components/common/BulkEdit";
import { toast } from "react-toastify";

const FeeStructure = ({ setActiveModule }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.common);
  const [feeStructures, setFeeStructures] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  const open = Boolean(anchorEl);

  const columns = [
    {
      dataIndex: "program",
      title: "PROGRAMME",
      width: "10%",
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.program}</p>;
      },
    },
    {
      dataIndex: "structure_name",
      title: "STRUCTURE NAME",
      width: "20%",
      render: (val, row) => {
        return (
          <p className={styles.tableContentFields}>{row.structure_name}</p>
        );
      },
    },
    {
      dataIndex: "structure_id",
      title: "STRUCTURE ID",
      width: "20%",
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.structure_id}</p>;
      },
    },
    {
      dataIndex: "academic_term",
      title: "ACADEMIC TERM",
      width: "15%",
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.academic_term}</p>;
      },
    },
    {
      dataIndex: "total_amount",
      title: "AMOUNT",
      width: "10%",
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.total_amount}</p>;
      },
    },
    {
      dataIndex: "status",
      title: "STATUS",
      width: "15%",
      render: (val, row) => {
        return (
          <p
            className={
              row.status === "Submitted"
                ? styles.buttonPublish
                : styles.buttonUnPublish
            }
          >
            {row.status}
          </p>
        );
      },
    },
    {
      dataIndex: "id",
      title: "ACTION",
      width: "10%",
      render: (val, row) => {
        return (
          <p
            className={styles.tableContentView}
            onClick={() =>
              navigate(`/modules/finance/fee-structure/${row.structure_id}`)
            }
          >
            View
          </p>
        );
      },
    },
  ];

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleGetFeeStructureListing = async () => {
    dispatch(setLoading({ loading: true }));
    const res = await getAllFeeStructures();
    if (res.status === 200) {
      dispatch(setLoading({ loading: false }));
      const structures = [];
      for (let i = 0; i < res.data.data.length; i++) {
        structures.push({
          id: i,
          key: res.data.data[i].name,
          structure_id: res.data.data[i].name,
          structure_name: res.data.data[i].custom_fee_structure_name,
          program: res.data.data[i].program,
          academic_term: res.data.data[i].academic_term,
          total_amount: res.data.data[i].total_amount,
          status: res.data.data[i].docstatus ? "Submitted" : "Draft",
          idx: res.data.data[i].idx,
        });
      }
      setFeeStructures(structures);
    } else {
      dispatch(setLoading({ loading: false }));
    }
  };

  const actionOptions = [
    { label: "Export", id: "export", key: "export" },
    { label: "Delete", id: "delete", key: "delete" },
  ];

  const handleBulkExport = async () => {
    let exportIds = [];
    for (let i = 0; i < selectedIds.length; i++) {
      exportIds.push({
        doctype: "Fee Structure",
        id: selectedIds[i],
      });
    }

    const payload = { export: [...exportIds] };
    const response = await fetch(APIs.bulkActionExport, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      dispatch(setLoading({ loading: false }));
      setSelectedIds([]);
      setSelectedRowKeys([]);
      toast.success("Data exported!");
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Fee Structure.xlsx");
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error("Something went wrong");
      dispatch(setLoading({ loading: false }));
    }
  };

  const handleBulkDelete = async () => {
    let exportIds = [];
    let hasSubmittedDoc = false;
    for (let i = 0; i < selectedIds.length; i++) {
      const item = feeStructures.find(
        (item) => item.structure_id === selectedIds[i]
      );
      console.log(item);
      if (item.docstatus === 1) {
        hasSubmittedDoc = true;
      } else {
        exportIds.push({
          doctype: "Fee Structure",
          id: selectedIds[i],
        });
      }
    }
    console.log(exportIds);

    const payload = { delete: [...exportIds] };

    if (exportIds.length > 0) {
      const response = await fetch(APIs.bulkActionDelete, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setSelectedIds([]);
        setSelectedRowKeys([]);
        toast.success("Data Deleted!");
        handleGetFeeStructureListing();
      } else {
        toast.error("Submitted Documents cannot be deleted");
        dispatch(setLoading({ loading: false }));
      }
    } else {
      toast.error("Submitted Documents cannot be deleted");
    }
  };

  const handleAction = (option) => {
    if (option.id === "export") {
      handleBulkExport();
    }
    if (option.id === "delete") {
      handleBulkDelete();
    }
  };

  const onSelectChange = (selectedKeys, selectedRowData) => {
    setSelectedRowKeys(selectedKeys);
    let selectedDataIds = [];
    selectedRowData.map((rowData) => selectedDataIds.push(rowData.key));
    setSelectedIds([...selectedDataIds]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      id: record.key,
    }),
  };

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
      },
    },
  }));

  useEffect(() => {
    handleGetFeeStructureListing();
  }, []);

  return (
    <div className={styles.mainContainer}>
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/dashboard")}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => setActiveModule(0)}
            >
              Finance {" > "}
            </p>
            <p className={styles.breadCrumbsContent}>Fee Structure</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>Fee Structure</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div className={`${styles.button} ${styles.filterButton}`}>
                <ReactSVG src={FiltersSVG} />
                <p className={styles.buttonText}>Filters</p>
                <ReactSVG src={ArrowDownSVG} />
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>Saved Filters</p>
              </div>
              {selectedRowKeys.length > 0 && (
                <ActionExport
                  title="Actions"
                  options={actionOptions}
                  disabled={!selectedRowKeys.length}
                  onChange={handleAction}
                />
              )}
              {!selectedRowKeys.length && (
                <div
                  className={styles.activeButton}
                  onClick={() =>
                    navigate("/modules/finance/add-new-fee-structure")
                  }
                >
                  <p className={styles.activeButtonText}>+ Add Fee Structure</p>
                </div>
              )}{" "}
            </div>
          </div>
          <Table
            rowSelection={{ type: "checkbox", ...rowSelection }}
            columns={columns}
            dataSource={feeStructures}
            size="small"
            pagination={true}
          />
        </>
      )}
    </div>
  );
};

export default FeeStructure;

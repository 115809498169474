export const paymentRequestTypeOptions = [
  {
    value: "Outward",
    label: "Outward"
  },
  {
    value: "Inward",
    label: "Inward"
  }
];

export const partyTypeOptions = [
  {
    value: "Student",
    label: "Student"
  },
];
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import { Table } from "antd";
import { toast } from "react-toastify";
import moment from "moment";
import {
  Button,
  Popover,
  Stack,
  Typography,
} from "iiht-b2a-ui-components/build/components";
import { ActionFilters } from "components/Filters";
import CommonFilters from "components/Filters/CommonFilters";
import SavedFilters from "components/Filters/SavedFilters";
import { CommonModal, CustomBreadcrumb, MainLayout } from "components/common";
import BulkEdit from "components/common/BulkEdit";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { genericDataActionOptions } from "constants/commonConstants";
import {
  deleteGenericDataApi,
  exportGenericData,
  getGenericListApi,
  updateGenericdataInBulk,
} from "modules/hr";
import { setLoading } from "slices/commonSlice";
import APIs from "api";
import ActionExport from "components/Filters/ActionExport";

const InterviewRoundList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [openActions, setOpenActions] = useState(false);
  const [interviewRoundList, setInterviewRoundList] = useState([]);
  const { doctype, filters } = useSelector((state) => state.filters);
  const { loading } = useSelector((state) => state.common);

  const breadcrumbs = [
    {
      label: "IIHT",
      link: "/dashboard",
    },
    {
      label: "HR",
      link: "/modules/hr",
    },
    {
      label: "Recruitment",
      link: "/modules/hr",
    },
    {
      label: "Masters",
      link: "/modules/hr/masters",
    },
    {
      label: "Interview round",
      link: "",
    },
  ];

  const columns = [
    {
      dataIndex: "id",
      title: "ID",
      width: "30%",
    },
    {
      dataIndex: "round_name",
      title: "Round Name",
      width: "30%",
    },
    { dataIndex: "interview_type", title: "Interview Type", width: "30%" },
    {
      dataIndex: "name",
      title: "Action",
      width: "10%",
      render: (val, row) => {
        return (
          <NavLink
            color="secondary"
            variant="body2"
            to={`/modules/hr/masters/interview-round/edit-view/${row?.id}`}
            className="mouse-pointer qa-view-all view-all"
          >
            View
          </NavLink>
        );
      },
    },
  ];

  const handleBulkEdit = async ({ customfieldname, value }) => {
    const payload = {
      doctype: "Interview Round",
      docnames: selectedIds,
      customfieldname,
      value,
    };
    dispatch(setLoading({ loading: true }));
    setEditModal(false);
    const res = await updateGenericdataInBulk(payload);
    if (res?.status === 200) {
      setSelectedIds([]);
      setSelectedRowKeys([]);
      toast.success(res.data.message);
      fetchInterviewRoundList();
    } else {
      toast.error(res.data?.exc_type);
    }
    dispatch(setLoading({ loading: false }));
  };

  const handleBulkExport = async () => {
    dispatch(setLoading({ loading: true }));
    let exportIds = [];
    for (let i = 0; i < selectedIds.length; i++) {
      exportIds.push({
        doctype: "Interview Round",
        id: selectedIds[i],
      });
    }

    const payload = { export: [...exportIds] };
    const response = await fetch(APIs.bulkActionExport, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      dispatch(setLoading({ loading: false }));
      setSelectedIds([]);
      setSelectedRowKeys([]);
      toast.success("Data exported!");
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Interview Round.xlsx");
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error("Something went wrong");
      dispatch(setLoading({ loading: false }));
    }
  };

  const handleBulkDelete = async () => {
    setDeleteModal(false);
    const bulkDeleteparams = interviewRoundList
      .filter((row) => selectedIds.includes(row.id))
      .map(({ id }) => ({
        doctype: "Interview Round",
        id,
      }));
    const res = await deleteGenericDataApi({ delete: bulkDeleteparams });
    if (res?.status === 200) {
      toast.success("Interview Round deleted successfully.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setSelectedIds([]);
      setSelectedRowKeys([]);
      fetchInterviewRoundList();
    } else {
      toast.error("Failed to delete Interview Round List.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleAction = (option) => {
    if (option.id === "edit") {
      setEditModal(true);
    } else if (option.id === "export") {
      handleBulkExport();
    } else if (option.id === "delete") {
      setDeleteModal(true);
    }
  };

  const onSelectChange = (selectedKeys, selectedRowData) => {
    setSelectedRowKeys(selectedKeys);
    let selectedDataIds = [];
    selectedRowData.map((rowData) => selectedDataIds.push(rowData.id));
    setSelectedIds([...selectedDataIds]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      id: record.id,
    }),
  };

  const fetchInterviewRoundList = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Interview Round",
      fields: JSON.stringify(["*"]),
      filters:
        doctype === "Interview Round"
          ? JSON.stringify(filters).slice(1, -1)
          : [],
      limit: "*",
    };
    const res = await getGenericListApi(params);
    if (res?.status === 200) {
      if (res.data?.message?.length) {
        const list = res.data.message.map((data, i) => {
          return { ...data, key: i };
        });
        setInterviewRoundList(list);
      } else {
        setInterviewRoundList([]);
      }
    }
    dispatch(setLoading({ loading: false }));
  };

  useEffect(() => {
    fetchInterviewRoundList();
  }, [filters]);

  return (
    <MainLayout>
      {loading && <LoadingBackdrop open={loading} />}
      <div>
        <CustomBreadcrumb breadcrumbs={breadcrumbs} />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <GridMenuIcon className="gridIcon" />
            <Typography color="#0D1021" fontSize={22}>
              Interview Round
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <CommonFilters doctype="Interview Round" />
            <SavedFilters doctype="Interview Round" />
            {selectedRowKeys.length > 0 && (
              <ActionExport
                title="Action"
                options={genericDataActionOptions}
                disabled={!selectedRowKeys.length}
                onChange={handleAction}
              />
            )}
            {!selectedRowKeys.length && (
              <Button
                className="qa-sign-in-button"
                variant="contained"
                color="primary"
                onClick={() =>
                  navigate("/modules/hr/masters/interview-round/create")
                }
              >
                Add Interview Round
              </Button>
            )}
          </Stack>
        </Stack>

        <div className="mt4">
          <Table
            rowSelection={{ type: "checkbox", ...rowSelection }}
            columns={columns}
            dataSource={interviewRoundList}
            size="small"
            pagination={true}
          />
        </div>

        {deleteModal && (
          <CommonModal
            open={deleteModal}
            onClose={() => setDeleteModal(false)}
            modalTitle="Confirm"
            showDividers={true}
            onSubmit={handleBulkDelete}
            cancelBtnName="Cancel"
            submitBtnName="Delete"
            children="Are you sure you want to delete?."
            width=""
          />
        )}
        {editModal && (
          <BulkEdit
            handleBulkEdit={handleBulkEdit}
            doctype="Interview Round"
            isEditOpen={editModal}
            setIsEditOpen={setEditModal}
            items={selectedIds.length}
          />
        )}
      </div>
    </MainLayout>
  );
};

export default InterviewRoundList;

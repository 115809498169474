import React, { useState } from "react";
import {
  Button,
  Popover,
  Stack,
  Typography,
} from "iiht-b2a-ui-components/build/components";

const ActionFilters = ({
  title = "...",
  options,
  disabled,
  onChange = () => {},
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        className="qa-action--button"
        variant="outlined"
        color="secondary"
        disabled={disabled}
        sx={{
          boxShadow:
            "0px 0px 2px 0px rgba(21, 40, 80, 0.12), 0px 2px 4px 0px rgba(21, 40, 80, 0.10)",
          borderColor: "#fff",
          "&:hover": {
            borderColor: "#fff",
            backgroundColor: "#fff",
          },
          ".MuiTouchRipple-root": {
            width: "100%",
            color: "#fff",
          },
        }}
        onClick={handleClick}
      >
        <Typography variant="bodyText" fontWeight="600">
          {title}
        </Typography>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{ mt: 1 }}
      >
        <div className="p4 pr10">
          <Stack spacing={1.5}>
            {options?.length &&
              options.map((option) => {
                return (
                  <Typography
                    variant="bodyText"
                    color="#405071"
                    fontWeight="500"
                    className="mouse-pointer"
                    key={option.key}
                    onClick={() => {
                      onChange(option);
                      handleClose();
                    }}
                  >
                    {option.label}
                  </Typography>
                );
              })}
          </Stack>
        </div>
      </Popover>
    </>
  );
};

export default ActionFilters;

import React from "react";
import ReactQuill from "react-quill";
import useStyles from "./style.js";
import { Select, Input } from "antd";

const GetEvent = ({ eventDetails, values }) => {
  const { subject, starts_on, ends_on, color, event_category, event_type, status, send_reminder, repeat_this_event, description, google_meet_link } = eventDetails;
  const selectSize = "middle";
  const styles = useStyles();

  return (
    <div className={styles.formContainer}>
      <p className={styles.title}>{subject}</p>
      <div className={styles.formRow}>
        <div className={styles.field}>
          <p className={styles.fieldLabel}>Subject</p>
          <Input
            disabled={true}
            value={subject}
            className={styles.fieldInput}
          />
        </div>
        <div className={styles.fieldContainer}>
        <div className={styles.dateField}>
          <p className={styles.fieldLabel}>Starts on</p>
          <Input
            disabled={true}
            value={starts_on}
            className={styles.fieldInput}
          />
        </div>
        <div className={styles.dateField}>
          <p className={styles.fieldLabel}>Ends on</p>
          <Input
            disabled={true}
            value={ends_on}
            className={styles.fieldInput}
          />
        </div>
        </div>
      </div>
      <div className={styles.formRow}>
        <div className={styles.field}>
          <p className={styles.fieldLabel}>Event Category</p>
          <Input
            disabled={true}
            value={event_category}
            className={styles.fieldInput}
          />
        </div>
        <div className={styles.field}>
          <p className={styles.fieldLabel}>Status</p>
          <Select
            disabled={true}
            size={selectSize}
            value={ status}
            className={styles.selectInput}
          />
        </div>
      </div>
      <div className={styles.formRow}>
        <div className={styles.field}>
          <p className={styles.fieldLabel}>Event Type</p>
          <Input
            disabled={true}
            value={event_type}
            className={styles.fieldInput}
          />
        </div>
        <div className={styles.field}>
          <p className={styles.fieldLabel}>Invite sent to</p>
          <Input
            value={values}
            className={styles.fieldInput}
            disabled={true}
          />
        </div>
      </div>
      <div className={styles.formRow}>
        <div className={styles.field}>
          <p className={styles.fieldLabel}>Color</p>
          <Input
            value={color}
            className={styles.fieldInput}
            disabled={true}
          />
        </div>
        <div className={styles.field}>
          <p className={styles.fieldLabel}>Meet Link</p>
          
          <Input
            value={google_meet_link}
            className={styles.fieldInput}
            disabled={true}
          />
        </div>
      </div>
      <div className={`${styles.formRow} ${styles.bottom}`}>
        <div className={styles.field}>
          <p className={styles.fieldLabel}>Description</p>
          <ReactQuill value={description} />
        </div>
        <div className={styles.field}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginTop: "12px"
            }}
          >
            <div
              style={{
                display: "inline-flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                checked={send_reminder === 1 ? true : false}
                style={{
                  height: "18px",
                  width: "18px",
                  borderRadius: "4px",
                  border: "1px solid #A6B0C2",
                  backgroundColor: "#FFF",
                }}
                disabled={true}
              />
              <p className={styles.fieldLabel}>Send the email reminder this morning</p>
            </div>
            <div
              style={{
                display: "inline-flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                checked={repeat_this_event === 1 ? true : false}
                style={{
                  height: "18px",
                  width: "18px",
                  borderRadius: "4px",
                  border: "1px solid #A6B0C2",
                  backgroundColor: "#FFF",
                  zIndex: "100"
                }}
                disabled={true}
              />
              <p className={styles.fieldLabel}>Repeat this even </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetEvent;

import { Button } from 'iiht-b2a-ui-components/build/components';
import React from 'react';
import eventBus from '../../../core/eventBus';

const DeleteConfirmationDialog = ({ items, handleBulkDelete  }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "18px", alignItems: "center", padding: "18px 22px", width: "420px" }}>
        <p style={{ color: "#405071", fontSize: "22px", fontWeight: "600" }}>Confirm!</p>
        <div style={{ display: "flex", flexDirection: "column"}}>
            <span style={{ color: "#405071", fontSize: "14px", fontWeight: "500" }}>
              Delete {items} {items > 1 ? "items" : "item"} permanently?
            </span>
        </div>
        <div style={{ display: "inline-flex"}}>
        <Button
            variant="contained"
            style={{
              marginRight: "8px",
              backgroundColor: "white",
              color: "gray",
              cursor: "pointer"
            }}
            onClick={() => {
              eventBus.emit("close-dialog");
            }}
          >
            No
          </Button>
          <Button variant="contained" style={{ cursor: "pointer" }} 
            onClick={() => {
              handleBulkDelete();
              eventBus.emit("close-dialog");
            }}>
            Yes
          </Button>
        </div>
    </div>
  )
}

export default DeleteConfirmationDialog;
import NonActiveIcon from "../../../assets/images/non-active-sidebar-icon.svg";

export const AdmissionTypeOptions = [
  {
    value: "Regular",
    label: "Regular"
  },
  {
    value: "Self Supporting",
    label: "Self Supporting"
  }
]

export const meritListDashboardList = [
  {
    name: "Merit List",
    icon: NonActiveIcon,
    url: "/merit-list"
  },
  {
    name: "Merit List Criteria",
    icon: NonActiveIcon,
    url: "/merit-list-criteria" 
  },
  {
    name: "Merit List Category",
    icon: NonActiveIcon,
    url: "/merit-list-category" 
  }
]
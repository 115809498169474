import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  button: {
    cursor: "pointer",
    borderRadius:" 4px",
    background: "#FFF",
    padding: "0 14px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 0px 2px 0px rgba(21, 40, 80, 0.12), 0px 2px 4px 0px rgba(21, 40, 80, 0.10)"
  },
  filterButton: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "7px",
  },
  buttonText: {
    color: "#405071",
    fontSize: "14px",
    fontWeight: "600"
  },
  nonActiveButton: {
    color: "#6C7890",
    fontSize: "14px",
    fontWeight: "500",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  activeButton: {
    backgroundColor: "#1C385C",
    borderRadius: "4px",
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "500",
    cursor: "pointer",
    padding: "0 8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  buttonGroup: {
    display: "inline-flex",
    gap: "12px"
  },
  addRow: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%"
  },
  filterNameInput: {
    width: "60%",
    height: "32px",
    borderRadius: "4px",
    border: "1px solid #CDD6E2",
    padding: "0px 4px",
    color: "#9DA9B0",
    fontSize: "14px",
    fontWeight: "500",
  },
  container: {
    height: "auto",
    width: "350px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    gap: "22px"
  },
  filterContainer: {
    borderBottom: "1px solid #9DA9B0",
    paddingBottom: "12px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    alignItems: "center"
  },
  filterRow: {
    display: "inline-flex",
    gap: "12px"
  },
  filterRowInput: {
    width: "32%",
    height: "32px",
    borderRadius: "4px",
    border: "1px solid #CDD6E2",
    padding: "0px 4px",
    color: "#9DA9B0",
    fontSize: "14px",
    fontWeight: "500",
  },
  filterSelectInput: {
    width: "32%",
    height: "32px",
    borderRadius: "4px",
    border: "1px solid #CDD6E2",
    padding: "0px 4px",
    color: "#9DA9B0",
    fontSize: "14px",
    fontWeight: "500",
  },
  savedFilters: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  applyButton: {
    marginLeft: "8px",
    backgroundColor: "#1C385C",
    borderRadius: "6px",
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "500",
    cursor: "pointer",
    padding: "4px 8px",
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    paddingBottom: "14px",
    borderBottom: "1px solid #E8EAF0"
  },
  checkboxText: {
    color: "#6C7890",
    fontSize: "14px",
    fontWeight: "500",
  },
  savedFiltersContainer: {
    borderRadius: "8px",
    border: "1px solid #FFF",
    background: "#FFF",
    boxShadow: "0px 6px 14px 0px rgba(131, 137, 151, 0.18), 0px 6px 15px 0px rgba(21, 40, 80, 0.09)",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    padding: "12px",
  }
}));

export default useStyles;
// FormFields.js
import { DeleteOutlined } from "@material-ui/icons";
import { Button, Checkbox, Input, Select, Table } from "antd";
import React, { useState } from "react";
import { getCourseList } from "../../modules/masters";

export const TextInputField = ({ field, value, onChange, disabled }) => (
  <>
    <label htmlFor={field.name}>{field.label}</label>
    <Input
      type="text"
      id={field.name}
      name={field.name}
      value={value || ""}
      onChange={onChange}
      placeholder={field.placeholder}
      disabled={disabled}
    />
  </>
);
export const PhoneInputField = ({ field, value, onChange, disabled }) => (
  <>
    <label htmlFor={field.name}>{field.label}</label>
    <Input
      type="tel"
      id={field.name}
      name={field.name}
      value={value || ""}
      onChange={onChange}
      placeholder={field.placeholder}
      disabled={disabled}
    />
  </>
);
export const EmailInputField = ({ field, value, onChange, disabled }) => (
  <>
    <label htmlFor={field.name}>{field.label}</label>
    <Input
      type="email" // Set the input type to "email"
      id={field.name}
      name={field.name}
      value={value || ""}
      onChange={onChange}
      placeholder={field.placeholder || "Enter email"}
      disabled={disabled}
    />
  </>
);
export const TextAreaField = ({ field, value, onChange, disabled }) => (
  <>
    <label htmlFor={field.name}>{field.label}</label>
    <Input.TextArea
      id={field.name}
      name={field.name}
      value={value || ""}
      onChange={onChange}
      placeholder={field.placeholder}
      disabled={disabled}
    />
  </>
);
export const CheckboxField = ({ field, checked, onChange, disabled }) => (
  <div className="checkbox-field">
    <Checkbox
      id={field.name}
      name={field.name}
      checked={checked || false}
      onChange={onChange}
      disabled={disabled}
    >
      {field.label}
    </Checkbox>
  </div>
);

export const DateInputField = ({
  field,
  value,
  onChange,
  onBlur,
  disabled,
}) => {
  return (
    <>
      <label htmlFor={field.name}>{field.label}</label>
      <Input
        type="date"
        name={field.name}
        value={value}
        onChange={(e) => onChange(field.name, e.target.value)}
        onBlur={onBlur}
        disabled={disabled}
      />
    </>
  );
};

export const Select1InputField = ({ field, value, onChange, disabled }) => {
  return (
    <>
      <label htmlFor={field.name}>{field.label}</label>
      <Select
        name={field.name}
        value={value || undefined} // Ensure it's either a valid value or undefined
        onChange={onChange}
        placeholder={field.placeholder || "Select"}
        defaultValue="Select"
        onBlur={() => {
          // Add your onBlur logic here if needed
        }}
        disabled={disabled}
      >
        {Array.isArray(field.options) &&
          field.options.map((option) => (
            <Select.Option key={option.label} value={option.value}>
              {option.value}
            </Select.Option>
          ))}
      </Select>
    </>
  );
};

export const Select2InputField = ({
  field,
  value,
  onChange,
  fieldError,
  disabled,
  academicYrList,
}) => (
  <>
    <label style={{ paddingBottom: "11px" }} htmlFor={field.name}>
      {field.label}
    </label>
    <Select
      className="searchable-select-dropdown"
      id={field.name}
      name={field.name}
      showSearch
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      value={value || undefined} // Use value to get the selected value
      onChange={onChange}
      placeholder={field.placeholder || "Select"}
      defaultValue="Select"
      onBlur={() => {
        // Add your onBlur logic here if needed
      }}
      disabled={disabled}
    >
      {console.log(field)}
      {Array.isArray(field.options) &&
        field.options.map((option) => (
          <Select.Option key={option.label} value={option.value}>
            {option.value}
          </Select.Option>
        ))}
    </Select>

    {fieldError && <div className="error-message">{fieldError}</div>}
  </>
);
export const SelectInputField = ({
  field,
  value,
  onChange,
  fieldError,
  disabled,
}) => (
  <>
    <label htmlFor={field.name}>{field.label}</label>
    <Select
      id={field.name}
      name={field.name}
      value={value || undefined}
      onChange={onChange}
      placeholder={field.placeholder || "Select"}
      defaultValue="Select"
      onBlur={() => {
        // Add your onBlur logic here if needed
      }}
      disabled={disabled}
    >
      {Array.isArray(field.options) &&
        field.options.map((option) => (
          <Select.Option key={option.label} value={option.value}>
            {option.value}
          </Select.Option>
        ))}
    </Select>

    {fieldError && <div className="error-message">{fieldError}</div>}
  </>
);

export const TableField = ({
  headers,
  data = [],
  onTableDataChange,
  dropdownOptions,
  disabled,
}) => {
  const [tableData, setTableData] = useState(data.length > 0 ? data : [{}]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedAcademicTerm, setSelectedAcademicTerm] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  console.log(
    dropdownOptions,
    "DropdownOptions:DropdownOptions:DropdownOptions"
  );

  const handleAddRow = () => {
    const newRow = {};
    headers.forEach((header) => {
      newRow[header.value] = "";
    });
    const updatedData = [...tableData, newRow];
    setTableData(updatedData);
    onTableDataChange(updatedData); // Pass updated data to the parent
  };

  const handleCellValueChange = (index, columnName, value, fields) => {
    console.log("Received value:", value);

    // Check if the value is a string before calling trim
    if (typeof value === "string" || value instanceof String) {
      value = value.trim();

      console.log("Trimmed value:", value);
    } else {
      console.log("Value is not a string, no trim applied.");
    }

    const updatedData = tableData.map((row, i) =>
      i === index ? { ...row, [columnName]: value } : row
    );
    setTableData(updatedData);
    onTableDataChange(updatedData);

    if (columnName === "outcomes") {
      // Check if dropdownOptions.outcomesList is an array

      // Find the selected outcome from the dropdownOptions
      const selectedOutcome = dropdownOptions.outcomesList.outcomesList.find(
        (outcome) => outcome.value === value
      );
      console.log(
        selectedOutcome,
        "Selected Outcome Data __________________________"
      );
      if (selectedOutcome) {
        // Update the name and type fields in the same row
        const updatedData = tableData.map((row, i) =>
          i === index
            ? {
                ...row,
                name: selectedOutcome.name,
                outcome: selectedOutcome.outcome,
                outcome_type: selectedOutcome.outcome_type, // Assuming outcome_type is a property of the selected outcome
              }
            : row
        );
        setTableData(updatedData);
        onTableDataChange(updatedData);
      }
    } else if (columnName === "course") {
      setSelectedCourse(value);
      console.log("Selected Course:", value);
      // Find the selected course from the API response

      const selectedCourseData = dropdownOptions.courseList.courseList.find(
        (course) => course.label === value && course.code !== null
      );

      console.log(
        selectedCourseData,
        "Selected Course Data __________________________"
      );
      if (selectedCourseData) {
        // Update the course code in the table data
        const updatedData = tableData.map((row, i) =>
          i === index
            ? {
                ...row,
                [columnName]: value,
                code: selectedCourseData.code || "", // Update with the course code
              }
            : row
        );

        setTableData(updatedData);
        onTableDataChange(updatedData);
      }
    } else if (columnName === "academic_term") {
      setSelectedAcademicTerm(value);
    }
  };

  const handleCheckboxChange = (index) => {
    const updatedData = [...tableData];
    updatedData[index]._isChecked = !updatedData[index]._isChecked;
    setTableData(updatedData);

    // Update selected rows
    const selectedRowIds = updatedData
      .filter((row) => row._isChecked)
      .map((row) => row._index);
    setSelectedRows(selectedRowIds);
  };

  const handleCheckAllChange = () => {
    const updatedData = tableData.map((row) => {
      row._isChecked = !selectAll;
      return row;
    });
    setTableData(updatedData);
    setSelectAll(!selectAll);

    // Update selected rows
    const selectedRowIds = updatedData
      .filter((row) => row._isChecked)
      .map((row) => row._index);
    setSelectedRows(selectedRowIds);
  };

  const handleDeleteSelectedRows = () => {
    const updatedData = tableData.filter((row) => !row._isChecked);
    setTableData(updatedData);
    onTableDataChange(updatedData);
    setSelectAll(false);
    setSelectedRows([]);
  };

  const columns = [
    {
      title: <Checkbox onChange={handleCheckAllChange} checked={selectAll} />,
      key: "selectAll",
      render: (_, record, index) => (
        <Checkbox
          checked={record._isChecked || false}
          onChange={() => handleCheckboxChange(index)}
        />
      ),
      width: 50,
    },
    {
      title: "SNo.",
      dataIndex: "_index",
      key: "_index",
      render: (_, record, index) => `${index + 1}`, // Ensure it's a string
      width: 50,
    },
    ...headers.map((header) => {
      console.log(header, "header");

      const renderColumn = (record, index, header) => {
        if (
          header.value === "course" ||
          header.value === "academic_term" ||
          header.value === "academic_year" ||
          header.value === "program" ||
          header.value === "assessment_criteria" ||
          header.value === "outcomes" ||
          header.value === "employee"
        ) {
          const options =
            dropdownOptions &&
            dropdownOptions[header.options] &&
            Array.isArray(
              header.value === "course"
                ? dropdownOptions[header.options]?.courseList
                : header.value === "academic_term"
                ? dropdownOptions[header.options]?.academicTermList
                : header.value === "academic_year"
                ? dropdownOptions[header.options]?.academicYrList
                : header.value === "program"
                ? dropdownOptions[header.options]?.programList
                : header.value === "assessment_criteria"
                ? dropdownOptions[header.options]?.assessmentCriteria
                : header.value === "curriculum"
                ? dropdownOptions[header.options].curriculumList
                : header.value === "employee"
                ? dropdownOptions[header.options].employeeList
                : header.value === "outcomes"
                ? dropdownOptions[header.options].outcomesList
                : []
            )
              ? header.value === "course"
                ? dropdownOptions[header.options].courseList
                : header.value === "academic_term"
                ? dropdownOptions[header.options].academicTermList
                : header.value === "academic_year"
                ? dropdownOptions[header.options]?.academicYrList
                : header.value === "program"
                ? dropdownOptions[header.options]?.programList
                : header.value === "assessment_criteria"
                ? dropdownOptions[header.options].assessmentCriteria
                : header.value === "curriculum"
                ? dropdownOptions[header.options].curriculumList
                : header.value === "employee"
                ? dropdownOptions[header.options].employeeList
                : header.value === "outcomes"
                ? dropdownOptions[header.options].outcomesList
                : []
              : [];

          // Debugging logs
          console.log("optionsFunction:", header.options);
          console.log("options:", options);

          // Ensure options is an array before using map
          if (Array.isArray(options)) {
            return (
              <Select
                name={String(header.value).replace(/ /g, "_").toLowerCase()}
                placeholder={header.key}
                value={record[header.value] || undefined}
                onChange={(value) =>
                  handleCellValueChange(index, header.value, value)
                }
                disabled={disabled}
              >
                {options.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            );
          } else {
            // Handle the case where options is not an array
            console.error("Options is not an array:", options);
            return null;
          }
        } else if (header.value === "required" || header.value === "elective") {
          return (
            <Checkbox
              checked={record[header.value] === 1} // Use 1 for checked and 0 for unchecked
              onChange={(e) =>
                handleCellValueChange(
                  index,
                  header.value,
                  e.target.checked ? 1 : 0
                )
              }
              disabled={disabled}
            />
          );
        }

        return (
          <Input
            type="text"
            name={String(header.value).replace(/ /g, "_").toLowerCase()}
            placeholder={header.key}
            value={record[header.value]}
            onChange={(e) =>
              handleCellValueChange(
                index,
                String(header.value).replace(/ /g, "_").toLowerCase(),
                e.target.value
              )
            }
            disabled={disabled}
          />
        );
      };
      const calculateColumnWidth = (headerValue, totalColumns) => {
        // Check if the header value is "MANDATORY" or "elective"
        const isMandatoryOrElective =
          headerValue === "required" || headerValue === "elective";

        // Set the width to 100% if it's "MANDATORY" or "elective", otherwise, distribute equally
        return isMandatoryOrElective ? "90px" : `${100 / totalColumns}%`;
      };
      return {
        title: header.key,
        dataIndex: header.value,
        key: header.value,
        render: (text, record, index) => renderColumn(record, index, header),
        width: calculateColumnWidth(header.value, headers.length),
      };
    }),
  ];

  const updatedData = Array.isArray(tableData)
    ? tableData.map((row, index) => ({
        ...row,
        _index: `${index + 1}`,
      }))
    : [];

  return (
    <div className="formTableWrap dynamicTable">
      <Table dataSource={updatedData} columns={columns} pagination={false} />
      <div className="add_delete_btn">
        {selectedRows.length > 0 && (
          <Button
            onClick={handleDeleteSelectedRows}
            className="deleteBtn"
            disabled={disabled}
          >
            Delete
          </Button>
        )}
        <Button onClick={handleAddRow} className="addBtn" disabled={disabled}>
          {"+"}
        </Button>
      </div>
    </div>
  );
};

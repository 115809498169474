import React from "react";
import SideBar from "../../components/SideBar";
import MastersLanding from "./Masters";
import AcademicsDashboard from "./AcademicsDashboard/AcademicsDashboard";
import EnrollmentComponent from "./Enrollment/Enrollment";
import StudentGroupComponent from "./StudentGroup/StudentGroup";
import AttendanceComponent from "./Attendance/Attendance";
import ReportsComponent from "./Reports/Reports";
import Dashboard from "../../assets/images/icon-dashboard.svg";
import Master from "../../assets/images/icon-master.svg";
import Enrollment from "../../assets/images/icon-enrolment.svg";
import StudentGroup from "../../assets/images/icon-group.svg";
import Attendance from "../../assets/images/icon-attendance.svg";
import Reports from "../../assets/images/icon-report.svg";
import "./Academics.scss";

const sidebarContent = [
  { label: "Dashboard", icon: Dashboard },
  { label: "Masters", icon: Master },
  { label: "Enrollment", icon: Enrollment },
  { label: "Student Group", icon: StudentGroup },
  { label: "Attendance", icon: Attendance },
  { label: "Reports", icon: Reports },
];

const modules = [
  AcademicsDashboard,
  MastersLanding,
  EnrollmentComponent,
  StudentGroupComponent,
  AttendanceComponent,
  ReportsComponent,
];

const Academics = () => {
  return <SideBar sidebarContent={sidebarContent} modules={modules} />;
};

export default Academics;

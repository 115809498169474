import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { getEmailThread } from '../../../../modules/lead';
import { useParams } from 'react-router-dom';

const EmailComponent = () => {
  const [emailThread, setEmailThread] = useState({ docinfo: { communications: [{ content: '' }] } }); // Initial state properly defined
  const { id } = useParams();
  const doctype = "Lead";

  const fetchEmailThread = async () => {
    try {
      const res = await getEmailThread(doctype, id);
      if (res.status === 200) {
        setEmailThread(res?.data); 
      }
    } catch (error) {
      console.error("Error fetching email thread:", error);
      
    }
  };

  useEffect(() => {
    fetchEmailThread();
  }, [id, doctype]);

  const communicationContent = emailThread?.docinfo?.communications[0]?.content || "";
  const htmlContent = communicationContent.replace(/<br>/g, '<br/>'); 

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        {ReactHtmlParser(htmlContent)}
      </div>
    </div>
  );
}

const styles = {
  container: {
    width: '97%',
    maxWidth: '97%',
    margin: '20px ',
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },
  content: {
    color: '#1f272e',
  },
};

export default EmailComponent;

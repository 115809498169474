import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useStyles from "./style";
import PhoneInput from 'react-phone-input-2';

import {
  getCities,
  getCountries,
  getStates,
  getotpemail,
  getotpphone,
  verifyemail,
  verifyphone,
} from "../../modules/enquiry";
import { toast } from "react-toastify";
import { createRecruit } from "../../modules/hr";
const EnquiryForm = () => {
  const styles = useStyles();
  const navigate = useNavigate();

  const [values, setValues] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    country: "",
    state: "",
    city: "",
    college: "",
    programGroups: "",
    program: "",
    checked: 0,
  });
  const [successMessages, setSuccessMessages] = useState({
    email: "",
    emailOtp: "",
    mobileNumber: "",
    mobileNumberOtp: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    email: "",
    emailOtp: "",
    mobileNumber: "",
    mobileNumberOtp: "",
    name: "",
    country: "",
    state: "",
    city: "",
    college: "",
    programGroups: "",
    program: "",
    checked:"",
  });
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [emailOtp, setEmailOtp] = useState("");
  const [emailMinutes, setEmailMinutes] = useState(1);
  const [emailSeconds, setEmailSeconds] = useState(59);
  const [disableEmailField, setDisableEmailField] = useState(false);
  const [isEmailOtpVerified, setIsEmailOtpVerified] = useState(false);
  const [emailButtonText, setEmailButtonText] = useState("Get OTP");
  const [phoneOtp, setPhoneOtp] = useState("");
  const [phoneMinutes, setPhoneMinutes] = useState(1);
  const [phoneSeconds, setPhoneSeconds] = useState(59);
  const [disablePhoneField, setDisablePhoneField] = useState(false);
  const [isMobileOtpVerified, setIsMobileOtpVerfied] = useState(false);
  const [mobileButtonText, setMobileButtonText] = useState("Get OTP");

  const handleFormSubmit = async () => {
    const {
      name,
      email,
      mobileNumber,
      country,
      state,
      city,
    } = values;

    const errors = {
      name: "",
      email: "",
      mobileNumber: "",
      country: "",
      state: "",
      city: "",
      emailOtp: "",
      mobileNumberOtp: "",
    };
    if (!name) {
      errors.name = "Name is Required";
    }
    if (!email) {
      errors.email = "Email is Required";
    }
    if (email && !isEmailValid(email)) {
      errors.email = "Please Enter Valid Email";
    }
    if (!mobileNumber) {
      errors.mobileNumber = "Mobile Number is Required";
    }
    if (mobileNumber && !isPhoneNumberValid(mobileNumber)) {
      errors.email = "Please Enter Valid Number";
    }
    if (!country) {
      errors.country = "Country is Required";
    }
    if (!state) {
      errors.state = "State is Required";
    }
    if (!city) {
      errors.city = "City is Required";
    }
    if (email && isEmailValid(email) && !isEmailOtpVerified) {
      errors.emailOtp = "Please Verify Your Email";
    }
    if (
      mobileNumber &&
      isPhoneNumberValid(mobileNumber) &&
      !isMobileOtpVerified
    ) {
      errors.emailOtp = "Please Verify Your Mobile Number";
    }
    setErrorMessages(errors);

    if (
      !isEmailOtpVerified ||
      !isMobileOtpVerified ||
      !name ||
      !country ||
      !state ||
      !city 
    )
      return;

    const payload = {
      applicant_name: name,
      email_address: email,
      mobile_number: `+91${mobileNumber}`,
      country,
      state,
      city,
    };
    console.log(payload,"payload")
    const res = await createRecruit({ payload });
    
    if (res.status == 200) {
      toast.success("Form submitted Successfully !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setEmailOtp("");
      setPhoneOtp("");
      setValues({
        name: "",
        email: "",
        mobileNumber: "",
        country: "",
        state: "",
        city: "",
      });
      setSuccessMessages({
        email: "",
        emailOtp: "",
        mobileNumber: "",
        mobileNumberOtp: "",
      });
      setErrorMessages({
        email: "",
        emailOtp: "",
        mobileNumber: "",
        mobileNumberOtp: "",
        name: "",
        country: "",
        state: "",
        city: "",
      });
    }
  };

  useEffect(() => {
    handleGetCountries();
  }, []);

  useEffect(() => {
    if (isEmailValid(values.email) && emailOtp.length === 6) {
      handleVerifyOTPEmail();
    }
  }, [emailOtp]);

  useEffect(() => {
    if (isPhoneNumberValid(values.mobileNumber) && phoneOtp.length === 6) {
      handleVerifyOTPPhone();
    }
  }, [phoneOtp]);

  const handleGetCountries = async () => {
    setStates([]);
    setCities([]);
    setValues({
      ...values,
      country: "",
      state: "",
      city: "",
    });
    const res = await getCountries();
    if (res.status === 200) {
      setCountries(res?.data?.data);
    }
  };

  const handleGetStatesByCountry = async (country) => {
    setCities([]);
    setValues({
      ...values,
      country,
      state: "",
      city: "",
    });
    const res = await getStates({ country });
    if (res.status === 200) {
      setStates(res?.data?.data);
    }
  };

  const handleGetCitiesByState = async (state) => {
    const res = await getCities({ state });
    if (res.status === 200) {
      setCities(res?.data?.data);
    }
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isPhoneNumberValid = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };

  const handleGetOTPEmail = async () => {
    if (values.email === "") {
      setEmailMinutes(0);
      setEmailSeconds(0);
    }

    const isValid = isEmailValid(values.email);

    if (isValid) {
      setEmailMinutes(1);
      setEmailSeconds(59);
      setDisableEmailField(true);
      setEmailButtonText("Loading...");

      const payload = {
        email: values.email,
        type: "verify",
      };

      const res = await getotpemail({ payload });
      if (res.status === 200) {
        setEmailButtonText("");
        handleResendEmailOtp();
        setSuccessMessages({
          ...successMessages,
          email: res.data?.message.message,
        });
        setErrorMessages({
          ...errorMessages,
          email: "",
        });
      } else {
        setEmailButtonText("Get OTP");
        setErrorMessages({
          ...errorMessages,
          email: res.data?.message.message,
        });
        setSuccessMessages({
          ...successMessages,
          email: "",
        });
      }
    }
  };

  const handleResendEmailOtp = () => {
    const interval = setInterval(() => {
      setEmailSeconds((prev) => {
        if (prev > 0) {
          return prev - 1;
        }
        if (prev === 0) {
          setEmailMinutes((min) => {
            if (min === 0) {
              setDisableEmailField(false);
              setEmailButtonText("Get OTP");
              clearInterval(interval);
            } else {
              setEmailSeconds(59);
              return min - 1;
            }
          });
        }
      });
    }, 1000);
  };

  const handleVerifyOTPEmail = async () => {
    setDisableEmailField(false);
    const payload = {
      email: values.email,
      type: "verify",
      otp: emailOtp,
    };

    const res = await verifyemail({ payload });
    if (res.status === 200) {
      setEmailButtonText("Verified");
      setIsEmailOtpVerified(true);
      setSuccessMessages({
        ...successMessages,
        emailOtp: res.data?.message.message,
        email: "",
      });
      setErrorMessages({
        ...errorMessages,
        emailOtp: "",
      });
    } else {
      setDisableEmailField(true);
      setErrorMessages({
        ...errorMessages,
        emailOtp: res.data?.message.message,
      });
      setSuccessMessages({
        ...successMessages,
        emailOtp: "",
      });
    }
  };

  const handleGetOTPPhoneNumber = async () => {
    if (values.mobileNumber === "") {
      setPhoneMinutes(0);
      setPhoneSeconds(0);
    }

    const isValid = isPhoneNumberValid(values.mobileNumber);

    if (isValid) {
      setPhoneMinutes(1);
      setPhoneSeconds(59);
      setDisablePhoneField(true);
      setMobileButtonText("Loading...");

      const payload = {
        phone: `+91${values.mobileNumber}`,
        type: "verify",
      };

      const res = await getotpphone({ payload });
      if (res.status === 200) {
        setMobileButtonText("");
        handleResendPhoneOtp();
        setSuccessMessages({
          ...successMessages,
          mobileNumber: res.data?.message.message,
        });
        setErrorMessages({
          ...errorMessages,
          mobileNumber: "",
        });
      } else {
        setMobileButtonText("Get OTP");
        setErrorMessages({
          ...errorMessages,
          mobileNumber: res.data?.message.message,
        });
        setSuccessMessages({
          ...successMessages,
          mobileNumber: "",
        });
      }
    }
  };

  const handleVerifyOTPPhone = async () => {
    setDisablePhoneField(false);
    const payload = {
      phone: `+91${values.mobileNumber}`,
      type: "verify",
      otp: phoneOtp,
    };

    const res = await verifyphone({ payload });
    if (res.status === 200) {
      setMobileButtonText("Verified");
      setIsMobileOtpVerfied(true);
      setSuccessMessages({
        ...successMessages,
        mobileNumberOtp: res.data?.message.message,
        mobileNumber: "",
      });
      setErrorMessages({
        ...errorMessages,
        mobileNumberOtp: "",
      });
    } else {
      setDisableEmailField(true);
      setErrorMessages({
        ...errorMessages,
        mobileNumberOtp: res.data?.message.message,
      });
      setSuccessMessages({
        ...successMessages,
        mobileNumberOtp: "",
      });
    }
  };

  const handleResendPhoneOtp = () => {
    const interval = setInterval(() => {
      setPhoneSeconds((prev) => {
        if (prev > 0) {
          return prev - 1;
        }
        if (prev === 0) {
          setPhoneMinutes((min) => {
            if (min === 0) {
              setDisablePhoneField(false);
              setMobileButtonText("Get OTP");
              clearInterval(interval);
            } else {
              setPhoneSeconds(59);
              return min - 1;
            }
          });
        }
      });
    }, 1000);
  };

  const handleOpenLoginScreen = () => {
    navigate("/login");
  };

  return (
    <>
      <p className={styles.heading}>Recruitment Form</p>
      <div className={styles.formContainer}>
        <input
          type="text"
          placeholder="Applicant Name *"
          className={styles.inputText}
          value={values.name}
          onChange={(e) => {
            setValues({ ...values, name: e.target.value });
            if (e.target.value) {
              setErrorMessages({ ...errorMessages, name: "" });
            }
          }}
        />
        {errorMessages.name ? (
          <p className={styles.errorMessage}> {errorMessages.name}</p>
        ) : (
          ""
        )}
        <div className={styles.buttonfieldsContainer}>
          <input
            type="text"
            placeholder="Enter Email Address *"
            className={styles.buttonFieldInputFirst}
            value={values.email}
            onChange={(e) => {
              setValues({ ...values, email: e.target.value });
              if (isEmailValid(e.target.value)) {
                setErrorMessages({ ...errorMessages, email: "" });
              } else if (!isEmailValid(e.target.value)) {
                setErrorMessages({
                  ...errorMessages,
                  email: "Please Enter Valid Email",
                });
              } else {
                setErrorMessages({
                  ...errorMessages,
                  email: "Email is Required",
                });
              }
            }}
            disabled={isEmailOtpVerified}
          />
          <button
            className={`${styles.buttonFieldInputSecond} ${
              isEmailValid(values.email) ? styles.activeButton : ""
            }`}
            onClick={handleGetOTPEmail}
            disabled={
              disableEmailField ||
              !isEmailValid(values.email) ||
              emailButtonText === "Loading..." ||
              emailButtonText === "Verified" ||
              isEmailOtpVerified
            }
          >
            {
              isEmailOtpVerified ? "Verfied" : 
                disableEmailField && (emailSeconds > 0 || emailMinutes > 0) && emailButtonText !== "Loading..."
                  ? `Resend OTP (${emailMinutes}m ${emailSeconds}s)`
                  : emailButtonText
            }
          </button>
        </div>
        {successMessages.email ? (
          <p className={styles.successMessage}>{successMessages.email}</p>
        ) : errorMessages.email ? (
          <p className={styles.errorMessage}> {errorMessages.email}</p>
        ) : (
          ""
        )}

        <input
          type="text"
          placeholder="Enter OTP *"
          className={styles.inputText}
          value={emailOtp}
          onChange={(e) => {
            setEmailOtp(e.target.value);
            setErrorMessages({ ...errorMessages, emailOtp: "" });
          }}
          disabled={isEmailOtpVerified}
        />
        {successMessages.emailOtp ? (
          <p className={styles.successMessage}>{successMessages.emailOtp}</p>
        ) : errorMessages.emailOtp ? (
          <p className={styles.errorMessage}> {errorMessages.emailOtp}</p>
        ) : (
          ""
        )}
        <div className={styles.buttonfieldsContainer}>
          <input
            type="text"
            placeholder="Enter Mobile Number *"
            className={styles.buttonFieldInputFirst}
            value={values.mobileNumber}
            onChange={(e) => {
              setValues({ ...values, mobileNumber: e.target.value });
              if (isPhoneNumberValid(e.target.value)) {
                setErrorMessages({ ...errorMessages, mobileNumber: "" });
              } else if (!isPhoneNumberValid(e.target.value)) {
                setErrorMessages({
                  ...errorMessages,
                  mobileNumber: "Please Enter Valid Mobile Number",
                });
              } else {
                setErrorMessages({
                  ...errorMessages,
                  mobileNumber: "Mobile Number is Required",
                });
              }
            }}
            disabled={isMobileOtpVerified}
          />
          <button
            className={`${styles.buttonFieldInputSecond} ${
              isPhoneNumberValid(values.mobileNumber) ? styles.activeButton : ""
            }`}
            onClick={handleGetOTPPhoneNumber}
            disabled={
              disablePhoneField ||
              !isPhoneNumberValid(values.mobileNumber) ||
              mobileButtonText === "Loading..." ||
              mobileButtonText === "Verified" ||
              isMobileOtpVerified
            }
          > 
          {
            isMobileOtpVerified ? "Verfied" : 
              disablePhoneField && (phoneSeconds > 0 || phoneMinutes > 0) && mobileButtonText !== "Loading..."
                ? `Resend OTP (${phoneMinutes}m ${phoneSeconds}s)`
                : mobileButtonText
          }
          </button>
        </div>
        {successMessages.mobileNumber ? (
          <p className={styles.successMessage}>
            {successMessages.mobileNumber}
          </p>
        ) : errorMessages.mobileNumber ? (
          <p className={styles.errorMessage}> {errorMessages.mobileNumber}</p>
        ) : (
          ""
        )}
        <input
          type="text"
          placeholder="Enter OTP *"
          className={styles.inputText}
          value={phoneOtp}
          onChange={(e) => {
            setPhoneOtp(e.target.value);
            setErrorMessages({ ...errorMessages, mobileNumberOtp: "" });
          }}
          disabled={isMobileOtpVerified}
        />
        {successMessages.mobileNumberOtp ? (
          <p className={styles.successMessage}>
            {successMessages.mobileNumberOtp}
          </p>
        ) : errorMessages.mobileNumberOtp ? (
          <p className={styles.errorMessage}>
            {" "}
            {errorMessages.mobileNumberOtp}
          </p>
        ) : (
          ""
        )}
        <div className={styles.dropDownContainer}>
          <div
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <select
              onChange={(e) => {
                setValues({ ...values, country: e.target.value });
                handleGetStatesByCountry(e.target.value);
                if (e.target.value) {
                  setErrorMessages({ ...errorMessages, country: "" });
                }
              }}
              className={styles.dropDownInputText}
            >
              <option value="" disabled selected={values.country === ""}>
                Country
              </option>
              {countries.map((country) => (
                <option
                  key={country.name}
                  value={country.name}
                  selected={values.country === country.name}
                >
                  {country.name}
                </option>
              ))}
            </select>
            {errorMessages.country ? (
              <p className={styles.errorMessage}>{errorMessages.country}</p>
            ) : (
              ""
            )}
          </div>
          <div
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <select
              onChange={(e) => {
                setValues({ ...values, state: e.target.value });
                handleGetCitiesByState(e.target.value);
                if (e.target.value) {
                  setErrorMessages({ ...errorMessages, state: "" });
                }
              }}
              className={styles.dropDownInputText}
            >
              <option value="" disabled selected={values.state === ""}>
                State
              </option>
              {states.map((state) => (
                <option
                  key={state.name}
                  value={state.name}
                  selected={values.state === state.name}
                >
                  {state.name}
                </option>
              ))}
            </select>
            {errorMessages.state ? (
              <p className={styles.errorMessage}>{errorMessages.state}</p>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className={styles.dropDownContainer}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <select
              onChange={(e) => {
                setValues({ ...values, city: e.target.value });

                if (e.target.value) {
                  setErrorMessages({ ...errorMessages, city: "" });
                }
              }}
              className={styles.inputText}
            >
              <option value="" disabled selected={values.city === ""}>
                City
              </option>
              {cities.map((city) => (
                <option
                  key={city.name}
                  value={city.name}
                  selected={values.city === city.name}
                >
                  {city.name}
                </option>
              ))}
            </select>
            {errorMessages.city ? (
              <p className={styles.errorMessage}>{errorMessages.city}</p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className={styles.submitButton} onClick={handleFormSubmit}>
        Register
      </div>
      <div className={styles.loginText}>
        Already have an account?{" "}
        <span onClick={handleOpenLoginScreen}>Login</span>
      </div>
    </>
  );
};

export default EnquiryForm;

import React, { useState, useEffect } from "react";
import ApplicationDetailsJSON from "./ApplicationDetails";
import "./ApplicationStyle.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  getApplicantDetails,
  getWorkflowForStudentApplicant,
} from "../../../../modules/studentApplication";
import Email from "./Email";
import Event from "./Event/Event";
import ApplicationDocs from "./ApplicationDocs";
import Payment from "./PaymentListing";
import { GridMenuIcon } from "@mui/x-data-grid";
import Notes from "./Notes/Notes";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { updateDataToApplicationForm } from "../../../../modules/student";
import { toast } from "react-toastify";
import { roleConstants } from "../../../../config/roles";
import ConfirmationDialog from "../../../common/confirmation";
import eventBus from "../../../../core/eventBus";
import { setActiveSidebar } from "../../../../slices/financeSlice";
import { useDispatch } from "react-redux";
import { MainLayout } from "components/common";
import useStyles from "./style";
import { formatDateTODDMMMYYYY } from "utils/date";

const ArrayTextBox = ({ field, data }) => {
  const styles = useStyles();
  const { label, name, arrayField, index } = field;
  const details = data[arrayField];
  let value = {};
  if (index < details.length) {
    value = details[index];
  } else {
    value[name] = "-";
  }
  return (
    <div className={styles.textField}>
      <p className={styles.labelText}>{label}</p>
      <p className={styles.labelValue}>
        {value[name] ? value[name] : label && "-"}
      </p>
    </div>
  );
};

const ArrayTextArea = ({ field, data }) => {
  const styles = useStyles();
  const { label, name, arrayField, index } = field;
  const details = data[arrayField];
  let value = {};
  if (index < details.length) {
    value = details[index];
  } else {
    value[name] = "-";
  }
  return (
    <div className={styles.textAreaField}>
      <p className={styles.labelText}>{label}</p>
      <p className={styles.textAreaLabelValue}>
        {value[name] ? value[name] : label && "-"}
      </p>
    </div>
  );
};

const ArrayDate = ({ field, data }) => {
  const styles = useStyles();
  const { label, name, arrayField, index } = field;
  const details = data[arrayField];
  let value = {};
  if (index < details.length) {
    value = details[index];
  } else {
    value[name] = "";
  }
  return (
    <div className={styles.textField}>
      <p className={styles.labelText}>{label}</p>
      <p className={styles.labelValue}>
        {value[name] ? formatDateTODDMMMYYYY(value[name]) : label && "-"}
      </p>
    </div>
  );
};

const TextBox = ({ field, data }) => {
  const styles = useStyles();
  const { label, name } = field;
  return (
    <>
      {field?.dependantField ? (
        <div className={styles.textField}>
          <p className={styles.labelText}>{label}</p>
          <p className={styles.labelValue}>
            {data[name].length ? data[name] : label && "-"}
          </p>
        </div>
      ) : (
        <div className={styles.textField}>
          <p className={styles.labelText}>{label}</p>
          <p className={styles.labelValue}>
            {data[name] ? data[name] : label && "-"}
          </p>
        </div>
      )}
    </>
  );
};

const EncryptedTextBox = ({ field, data }) => {
  const styles = useStyles();
  const { label, name } = field;
  return (
    <div className={styles.textField}>
      <p className={styles.labelText}>{label}</p>
      <p className={styles.labelValue}>
        {data[name] ? `********${data[name].substr(-4)}` : ""}
      </p>
    </div>
  );
};

const Image = ({ field, data }) => {
  const styles = useStyles();
  const { label, name } = field;
  return (
    <div className={styles.textField}>
      <p className={styles.labelText}>{label}</p>
      <img
        src={`https://erp-dev.techademycampus.com${data[name]}`}
        alt={label}
        style={{ width: "150px", height: "150px" }}
      />
    </div>
  );
};

const TextArea = ({ field, data }) => {
  const styles = useStyles();
  const { label, name } = field;
  return (
    <div className={styles.textAreaField}>
      <p className={styles.labelText}>{label}</p>
      <p className={styles.textAreaLabelValue}>
        {data[name] ? data[name] : label && "-"}
      </p>
    </div>
  );
};

const SelectBox = ({ field }) => {
  const styles = useStyles();
  const { label, name, options } = field;
  return (
    <div className={styles.textField}>
      <p className={styles.labelText}>{label}</p>
      <select name={name}>
        {options.map((option) => (
          <option key={option} value={option}>
            <p className={styles.labelValue}>{option}</p>
          </option>
        ))}
      </select>
    </div>
  );
};

const Date = ({ field, data }) => {
  const styles = useStyles();
  const { label, name } = field;
  const details = data;
  return (
    <div className={styles.textField}>
      <p className={styles.labelText}>{label}</p>
      <p className={styles.labelValue}>
        {details[name] ? formatDateTODDMMMYYYY(details[name]) : label && "-"}
      </p>
    </div>
  );
};

const RadioBox = ({ field, data }) => {
  const styles = useStyles();
  const { label, name } = field;
  return (
    <div className={styles.textField}>
      <p className={styles.labelText}>{label}</p>
      <div className={styles.radioOptions}>
        <span className={styles.radioOptionsValue}>
          <input
            type="radio"
            checked={data[name] == 1}
            style={{ accentColor: "#A32930" }}
          />
          <p className={styles.labelValue}>Yes</p>
        </span>
        <span className={styles.radioOptionsValue}>
          <input
            type="radio"
            checked={data[name] == 0}
            style={{ accentColor: "#A32930" }}
          />
          <p className={styles.labelValue}>No</p>
        </span>
      </div>
    </div>
  );
};

const Checkbox = ({ field, data }) => {
  const styles = useStyles();
  const { label, options } = field;
  return (
    <div className={styles.textAreaField}>
      <p className={styles.labelText}>{label}</p>
      <div className={styles.checkboxContainer}>
        {options.map((option) => (
          <div key={option.key} className={styles.radioOptionsValue}>
            <input
              type="checkbox"
              checked={data[option.value] == 0 ? false : true}
              style={{ accentColor: "#A32930" }}
            />
            <p className={styles.labelValue}>{option.key}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const Table = ({ field, data }) => {
  const styles = useStyles();
  const { name, headers } = field;
  const rows = data[`${name}`];

  return (
    <div className="formTableWrap">
      <table className="formTable docs_table">
        <thead>
          <tr className={styles.tableRow}>
            {rows.length > 0 &&
              headers.map((header) => (
                <th
                  key={header.key}
                  className={styles.tableHeaderTitle}
                  style={{
                    width: getWidthForHeader(header.key),
                    paddingLeft: "22px",
                  }}
                >
                  {header.key}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((rowValue, idx) => {
            return (
              <tr key={idx} className={styles.tableRow}>
                {headers.map((header, index) => (
                  <td
                    key={header.key}
                    style={{ width: getWidthForHeader(header.key) }}
                  >
                    {index === 0 ? (
                      <p className={styles.tableContent}>
                        {rowValue[header.value] == "Others"
                          ? rowValue[header.valueText]
                          : rowValue[header.value] || ""}
                      </p>
                    ) : header.key === "Read" ||
                      header.key === "Write" ||
                      header.key === "Speak" ? (
                      <input
                        type="checkbox"
                        className="chkbxCustom"
                        name={`${name}_${header.key}_${idx}`}
                        checked={
                          data[`${name}_${header.value}_${idx}`] || false
                        }
                      />
                    ) : (
                      <p className={styles.tableContent}>
                        {rowValue[header.value] == "Others"
                          ? rowValue[header.valueText]
                          : rowValue[header.value] || ""}
                      </p>
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const getWidthForHeader = (header) => {
  if (header === "S.No.") {
    return "10%";
  } else if (header === "Read" || header === "Write" || header === "Speak") {
    return "15%";
  } else {
    return "30%";
  }
};

const ApplicationFormData = () => {
  const { id } = useParams();
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [currentTab, setCurrentTab] = useState(ApplicationDetailsJSON[0].name);
  const [content, setContent] = useState(ApplicationDetailsJSON[0].fields);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [workflowData, setWorkflowData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentWorkflow, setCurrentWorkflow] = useState("");
  const [allowedActions, setAllowedActions] = useState([]);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleWorkflowClick = (transition) => {
    setAnchorEl(null);
    eventBus.emit(
      "open-dialog",
      <ConfirmationDialog
        handleUpdateDataToApplicationForm={handleUpdateDataToApplicationForm}
        nextState={transition.next_state}
      />
    );
  };

  const handleUpdateDataToApplicationForm = async ({ nextState }) => {
    const res = await updateDataToApplicationForm({
      payload: { workflow_state: nextState },
      name: id,
    });
    if (res.status == 200) {
      setCurrentWorkflow(res.data.data.workflow_state);
      handleWorkflow({
        workflow: res.data.data.workflow_state,
        workflowDataArray: workflowData,
      });
    } else {
      let error = res.data?.exception;
      error = error.split(":")[1];
      toast.error(error);
    }
  };

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
      },
    },
  }));

  const handleSetFields = (tabDetails) => {
    const applicantName = data.first_name;
    setContent(tabDetails.fields);
    setCurrentTab(tabDetails.name);
    setBreadcrumbs([
      { label: "IIHT", url: "/dashboard" },
      { label: "Admission", url: "/modules/admission" },
      { label: "Applications", url: "/modules/admission" },
      { label: applicantName, url: `/applications/${id}` },
    ]);
  };

  const Breadcrumbs = () => {
    const navigate = useNavigate();
    return (
      <div className="breadcrumbs">
        {breadcrumbs.map((breadcrumb, index) => (
          <React.Fragment key={index}>
            {index > 0 && <span className="separator"> &gt; </span>}
            <div
              onClick={() => {
                if (index == 2) {
                  dispatch(setActiveSidebar({ activeSidebar: 2 }));
                }
                navigate(breadcrumb.url);
              }}
              style={{ display: "inline-flex", cursor: "pointer" }}
            >
              {breadcrumb.label}
            </div>
          </React.Fragment>
        ))}
      </div>
    );
  };

  const getDetails = async () => {
    try {
      const res = await getApplicantDetails(id);
      if (res.status === 200) {
        setData(res.data.message);
        setCurrentWorkflow(res.data.message.workflow_state);
        getActionDropdownValues({ state: res.data.message.workflow_state });
      } else {
        console.error(
          `Failed to get student details. Server responded with status ${res.status}`
        );
      }
    } catch (error) {
      console.error("Error fetching student details:", error.message);
    }
  };

  const getActionDropdownValues = async ({ state }) => {
    try {
      const res = await getWorkflowForStudentApplicant();
      if (res.status === 200) {
        setWorkflowData(res.data.message.data);
        handleWorkflow({
          workflow: state,
          workflowDataArray: res.data.message.data,
        });
      } else {
        console.error(
          `Failed to get application status. Server responded with status ${res.status}`
        );
      }
    } catch (error) {
      console.error("Error fetching application status:", error.message);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  const handleWorkflow = ({ workflow, workflowDataArray }) => {
    const transitions = workflowDataArray[`${workflow}`]?.transitions;
    const allowedActionsArr = transitions.filter(
      (transition) => transition.allowed === roleConstants.admissionCommitee
    );
    setAllowedActions(allowedActionsArr);
  };

  return (
    <MainLayout>
      <div className={styles.mainContainer}>
        <Breadcrumbs />
        <div className={styles.headerContainer}>
          <div className={styles.breadCrumbsContainer}>
            <GridMenuIcon className={styles.gridIcon} />
            <p className={styles.title}>{data.first_name}</p>
            <div className="application-status" style={{ marginLeft: "12px" }}>
              <span className={currentWorkflow}>{currentWorkflow}</span>
            </div>
          </div>
          <div className={styles.buttonsGroup}>
            <div
              className={styles.button}
              onClick={() => {
                navigate("/modules/admission");
                dispatch(setActiveSidebar({ activeSidebar: 2 }));
              }}
            >
              <p className={styles.buttonText}>Back</p>
            </div>
            {allowedActions.length > 0 && (
              <div className={styles.activeButton}>
                <p
                  id="demo-customized-button"
                  aria-controls={open ? "demo-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  className={styles.activeButtonText}
                  onClick={handleClick}
                >
                  Action
                </p>
                <KeyboardArrowDownIcon />
                {workflowData &&
                  workflowData[`${currentWorkflow}`]?.transitions.length >
                    0 && (
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      {workflowData &&
                        workflowData[`${currentWorkflow}`]?.transitions.map(
                          (transition) => {
                            const { action, allowed } = transition;
                            return (
                              <>
                                {allowed ===
                                  roleConstants.admissionCommitee && (
                                  <MenuItem
                                    onClick={() =>
                                      handleWorkflowClick(transition)
                                    }
                                    disableRipple
                                    sx={{
                                      fontSize: "14px",
                                      color: "#405071",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {action}
                                  </MenuItem>
                                )}
                              </>
                            );
                          }
                        )}
                    </StyledMenu>
                  )}
              </div>
            )}
          </div>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.tabContainer}>
            {ApplicationDetailsJSON.map((tabDetails) => {
              return (
                <div
                  type="button"
                  key={tabDetails.name}
                  className={
                    currentTab === tabDetails.name
                      ? styles.tabTitleActive
                      : styles.tabTitleInActive
                  }
                  onClick={() => handleSetFields(tabDetails)}
                >
                  {tabDetails.name}
                </div>
              );
            })}
          </div>
          <div className={styles.detailsContainer}>
            {content.map((details) => {
              const { label, fields } = details;
              return (
                <div className={styles.sectionContainer}>
                  <div
                    className={
                      label
                        ? styles.sectionHeader
                        : styles.sectionHeaderWithoutBorder
                    }
                  >
                    {label}
                  </div>
                  <div className={styles.container}>
                    {fields.map((row, i) => {
                      return (
                        <div className={styles.rowContainer}>
                          {row.map((field) => {
                            return <>{renderFieldByType(field, data)}</>;
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <div className="applicationNav">
          {formData.map((tabDetails) => (
            <button
              type="button"
              key={tabDetails.name}
              className={`appTab ${currentTab === tabDetails.name ? "active" : ""}`}
              onClick={() => handleSetFields(tabDetails.fields, tabDetails.name)}
            >
              {tabDetails.name}
            </button>
          ))}
        </div>
        <div className={`formApplication ${styles.applicationContent}`}>
          {fieldsToRender.map((field, index) => (
            <React.Fragment key={index}>
              <section className="form-section">
                {field.type === "section" && (
                  <h3 dangerouslySetInnerHTML={{ __html: field.label }} />
                )}
                <div className="fields-block">
                  {field.fields.map((sectionField, sectionIndex) => (
                    <React.Fragment key={sectionIndex}>
                      {sectionField.type === "col-break" ? (
                        <div className="col-break" key={sectionIndex}>
                          &nbsp;
                        </div>
                      ) : (
                        renderFieldByType(sectionField, data)
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </section>
            </React.Fragment>
          ))}
        </div> */}
      </div>
    </MainLayout>
  );
};

const ComponentToRender = ({ data, field }) => {
  switch (field.componentToRender) {
    case "notes":
      return <Notes uniqueId={data.name} />;
    case "events":
      return <Event uniqueId={data.name} />;
    case "emails":
      return <Email uniqueId={data.name} />;
    case "attachments":
      return <ApplicationDocs uniqueId={data.name} />;
    case "payment":
      return <Payment uniqueId={data.name} />;
    default:
      return null;
  }
};

const renderFieldByType = (field, data) => {
  switch (field.type) {
    case "text":
      return <TextBox field={field} data={data} />;
    case "image":
      return <Image field={field} data={data} />;
    case "date":
      return <Date field={field} data={data} />;
    case "encrypted":
      return <EncryptedTextBox field={field} data={data} />;
    case "table":
      return <Table field={field} data={data} />;
    case "arrayText":
      return <ArrayTextBox field={field} data={data} />;
    case "arrayDate":
      return <ArrayDate field={field} data={data} />;
    case "select":
      return <SelectBox field={field} data={data} />;
    case "textarea":
      return <TextArea field={field} data={data} />;
    case "radio":
      return <RadioBox field={field} data={data} />;
    case "checkbox":
      return <Checkbox field={field} data={data} />;
    case "arrayTextArea":
      return <ArrayTextArea field={field} data={data} />;
    case "component":
      return <ComponentToRender data={data} field={field} />;
    default:
      return null;
  }
};

export default ApplicationFormData;

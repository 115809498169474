// src/components/NotificationPopoverMaterialUI.js
import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import { ReactSVG } from "react-svg";
import NotificationIcon from "../../../assets/images/NotificationIcon.svg";
// import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";

const NotificationPopover = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Button onClick={handlePopoverOpen} variant="contained" className="headerBtns"> 
          <ReactSVG src={NotificationIcon} /> 
      </Button>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {/* Add your notification content here */}
        <div style={{ padding: "16px" }}>
          <p>No new notifications.</p>
        </div>
      </Popover>
    </div>
  );
};

export default NotificationPopover;

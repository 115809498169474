import useStyles from "./style";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import MainHeader from "components/common/MainHeader";
import MainFooter from "components/common/MainFooter";
import { useState } from "react";
import { addNewRoom } from "modules/seatManagement";
import { toast } from "react-toastify";
import { setActiveSidebar } from "slices/financeSlice";
import { useDispatch } from "react-redux";
import { RowingSharp } from "@mui/icons-material";

export default function AddNewRoom() {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    roomName: "",
    roomNumber: "",
    seatingCapacity: "",
    row: "",
    column: "",
    NoOfStudentPerSeat: 1,
  });

  const renderSeats = () => {
    const seatsRow = []
    const rows = Number(formData.row)
    const columns = Number(formData.column)

    for (let i = 1; i <= rows; i++) {
      const cols = []
      for (let j = i; j <= columns * rows; j += rows) {
        const column = <div className={styles.seatContainer}>
          <div className={styles.seatNumber}>{j}</div>
          <div className={styles.circle}>
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
              <circle cx="17.388" cy="17.6205" r="16.8098" fill="#D9D9D9" />
            </svg>
          </div>
        </div>
        cols.push(column)
      }
      const row = <div className={styles.seatRow}>{cols}</div>
      seatsRow.push(row)
    }
    return seatsRow;
  };

  const isValidPayloadForAddNewroom = () => {
    if (!formData.roomName) {
      toast.error("Enter room name");
      return false;
    } else if (!formData.roomNumber) {
      toast.error("Enter room number");
      return false;
    } else if (!formData.seatingCapacity) {
      toast.error("Enter seating capacity");
      return false;
    } else if (!formData.row) {
      toast.error("Enter number of rows");
      return false;
    } else if (!formData.column) {
      toast.error("Enter number of columns");
      return false;
    } else if (!formData.NoOfStudentPerSeat) {
      toast.error("Enter number of students per seat ");
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    if (isValidPayloadForAddNewroom()) {
      const payload = {
        room_name: formData.roomName,
        room_number: formData.roomNumber,
        seating_capacity: formData.seatingCapacity,
        custom_row: Number(formData.row),
        custom_column: Number(formData.column),
        custom_number_of_student_per_seat: formData.NoOfStudentPerSeat,
        doctype: "Room",
      };
      const response = await addNewRoom({ payload });
      if (response.status == 200) {
        const data = response.data.message;
        console.log("data: ", data);
        toast.success("Room created successfully!");
      } else {
        toast.error(response.data.message);
      }
    }
  };

  return (
    <div>
      <MainHeader />
      <div className={styles.mainContainer} style={{ padding: "32px" }}>
        <div className={styles.breadCrumbsContainer}>
          <p
            className={styles.breadCrumbsContent}
            onClick={() => navigate("/dashboard")}
          >
            IIHT {" > "}
          </p>
          <p
            className={styles.breadCrumbsContent}
            onClick={() => {
              navigate("/modules/examination");
              dispatch(setActiveSidebar({ activeSidebar: 1 }));
            }}
          >
            Examination {" > "}
          </p>
          <p
            className={styles.breadCrumbsContent}
            onClick={() => {
              navigate("/modules/examination");
              dispatch(setActiveSidebar({ activeSidebar: 1 }));
            }}
          >
            Seat Management {" > "}
          </p>
          <p
            className={styles.breadCrumbsContent}
            onClick={() => {
              navigate("/modules/examination/seat-management/rooms");
            }}
          >
            Rooms
          </p>
        </div>
        <div className={styles.headerContainer}>
          <div className={styles.breadCrumbsContainer}>
            <GridMenuIcon className={styles.gridIcon} />
            <p className={styles.title}>New Room</p>
          </div>
          <div className={styles.buttonsGroup}>
            <div className={styles.button} onClick={() => {}}>
              <p
                className={styles.buttonText}
                onClick={() => {
                  navigate("/modules/examination/seat-management/rooms");
                }}
              >
                Back
              </p>
            </div>
            <div
              className={styles.activeButton}
              onClick={() => handleSave()}
            >
              <p className={styles.activeButtonText}>
                Save
              </p>
            </div>
          </div>
        </div>
        <div className={styles.formContainer}>
          <div className={styles.formRow}>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Room Name *</p>
              <input
                type="text"
                placeholder="ex text"
                className={styles.fieldInput}
                value={formData.roomName}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    roomName: e.target.value,
                  });
                }}
              />
            </div>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Room Number *</p>
              <input
                placeholder="ex 302"
                className={styles.fieldInput}
                type="text"
                value={formData.roomNumber}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    roomNumber: e.target.value,
                  });
                }}
              />
            </div>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Seating Capacity *</p>
              <input
                className={styles.fieldInput}
                value={formData.seatingCapacity}
                disabled={true}
              />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Row *</p>
              <input
                placeholder="ex 10"
                className={styles.fieldInput}
                type="text"
                value={formData.row}
                onChange={(e) => {
                  if (formData.column && e.target.value) {
                    setFormData({
                      ...formData,
                      seatingCapacity:
                        Number(formData.column) * Number(e.target.value),
                      row: Number(e.target.value),
                    });
                  } else {
                    setFormData({
                      ...formData,
                      row: Number(e.target.value),
                    });
                  }
                }}
              ></input>
            </div>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Column *</p>
              <input
                placeholder="ex 3"
                className={styles.fieldInput}
                type="text"
                value={formData.column}
                onChange={(e) => {
                  if (formData.row && e.target.value) {
                    setFormData({
                      ...formData,
                      seatingCapacity:
                        Number(e.target.value) * Number(formData.row),
                      column: Number(e.target.value),
                    });
                  } else {
                    setFormData({
                      ...formData,
                      column: Number(e.target.value),
                    });
                  }
                }}
              />
            </div>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>No. of Student Per Seat *</p>
              <input
                className={styles.fieldInput}
                value={formData.NoOfStudentPerSeat}
                disabled={true}
              />
            </div>
          </div>
          <div className={styles.seatingArrangementMainContainer}>
            <p className={styles.fieldLabel}>Seating Arrangement</p>
            <div className={styles.seatingArrangementContainer}>
              <div className={styles.seatRowContainer}>{renderSeats()}</div>
            </div>
          </div>
        </div>
      </div>
      <MainFooter />
    </div>
  );
}

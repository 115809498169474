import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "28px",
    padding: "32px 18px",
  },
  breadCrumbsContainer: {
    display: "inline-flex",
    gap: "4px",
    alignItems: "center",
  },
  breadCrumbsContent: {
    color: "#6F6F6F",
    fontSize: "12px",
    fontWeight: "400",
    cursor: "pointer",
  },
  headerContainer: {
    display: "inline-flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "-24px",
  },
  title: {
    fontSize: "22px",
    color: "#0D1021",
    fontWeight: "500",
  },
  gridIcon: {
    color: "#647B8F",
  },
  buttonsGroup: {
    display: "inline-flex",
    gap: "15px",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    cursor: "pointer",
    borderRadius: " 4px",
    background: "#FFF",
    padding: "4px 14px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow:
      "0px 0px 2px 0px rgba(21, 40, 80, 0.12), 0px 2px 4px 0px rgba(21, 40, 80, 0.10)",
  },
  filterButton: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "7px",
  },
  buttonText: {
    color: "#405071",
    fontSize: "14px",
    fontWeight: "600",
  },
  activeButton: {
    cursor: "pointer",
    borderRadius: " 4px",
    background: "#A32930",
    padding: "4px 14px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow:
      "0px 0px 2px 0px rgba(21, 40, 80, 0.12), 0px 2px 4px 0px rgba(21, 40, 80, 0.10)",
  },
  activeButtonText: {
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "400",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    marginBottom: "40px",
  },
  field: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
  },
  fieldButtons: {
    display: "inline-flex",
    gap: "16px",
  },
  tableField: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  textarea: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    width: "100%",
  },
  fieldLabel: {
    color: "#012148",
    fontSize: "14px",
    fontWeight: "500",
  },
  fieldInput: {
    color: "#9DA9B0",
    fontSize: "12px",
    fontWeight: "400",
    padding: "0px 12px",
    borderRadius: "8px",
    background: "#fff",
    outline: "none",
    height: "40px",
    border: "1px solid #CDD6E2",
  },
  checkboxContainer: {
    display: "inline-flex",
    gap: "44px",
  },
  checkboxLabel: {
    display: "inline-flex",
    gap: "8px",
    alignItems: "center",
  },
  textAreaInput: {
    resize: "none",
    color: "#9DA9B0",
    fontSize: "12px",
    fontWeight: "400",
    padding: "8px 12px",
    borderRadius: "4px",
    border: "1px solid #CDD6E2",
    background: "#fff",
    outline: "none",
  },
  checkbox: {
    width: "14px",
    height: "14px",
    borderRadius: "6px",
    border: "1px solid ##A6B0C2",
    accentColor: "#AC0521",
  },
  errorMessage: {
    fontSize: "10px",
    fontWeight: "400",
    color: "#AC0521",
  },
  formRowMeta: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  formRow: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  metaHeadingText: {
    fontSize: "22px",
    color: "#0D1021",
    fontWeight: "500",
  },
  selectInput: {
    width: "100%",
  },
  selectInputForTable: {
    width: "100%",
    border: "none",
  },
  addRow: {
    borderRadius: "2px",
    border: "1px solid #A32930",
    padding: "6px 12px",
    color: "#A32930",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  tableContentFields: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#0D1021",
    fontFamily: "Poppins",
  },
  deleteRow: {
    borderRadius: "2px",
    border: "1px solid #A32930",
    padding: "6px 12px",
    color: "#A32930",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "Poppins",
  },
  tableContentView: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#012148",
    cursor: "pointer",
    fontFamily: "Poppins",
  },
  heading: {
    color: "#0D1021",
    fontSize: "22px",
    fontWeight: "500",
    fontFamily: "Poppins",
    marginBottom: "-16px",
  },
  link: {
    cursor: "pointer",
    color: "#0D1021",
    fontWeight: "500",
    textDecoration: "underline",
    fontSize: "14px",
  },
  headingTitle: {
    color: "#012148",
    fontSize: "21px",
    fontWeight: "500",
    fontFamily: "Poppins",
    paddingBottom: "12px",
    borderBottom: "1px solid #A32930",
  },
  formHeadingContainer: {
    marginTop: "8px",
  },
  formInput: {
    display: "flex",
  },
  optionInput: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "12px",
  },
  optionRow: {
    display: "flex",
    justifyContent: "start",
    gap: "4px",
  },
  buttonContainer: {
    display: "inline-flex",
    width: "100%",
    justifyContent: "end",
    alignItems: "center",
    marginTop: "-24px",
  },
  buttonsGroupra: {
    display: "inline-flex",
    gap: "15px",
    alignItems: "center",
    justifyContent: "start",
    marginBottom: "40px",
    marginTop: "30px",
  },
  textArea: {
    width: "100%",
  },
}));

export default useStyles;

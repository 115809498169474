import React from "react";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";

import Logo from "../../assets/images/TechademyLogo.svg";
import BackgroundLogo from "../../assets/images/landingPageBG.png";
import EnquiryForm from "../../components/EnquiryForm";
import useStyles from "./style";

const Landing = () => {
  const styles = useStyles();
  const navigate = useNavigate();

  const handleCareerClick = () => {
    navigate("/career");
  };

  return (
    <div style={{ overflowY: "hidden" }}>
      <div className={styles.logoContainer}>
        <ReactSVG src={Logo} />
        <div className={styles.labelsContainer}>
          {/* Clickable labels */}
          <span className={styles.label} onClick={handleCareerClick}>
            Career
          </span>
          <span className={styles.label}>Contact Us</span>
        </div>
      </div>
      <div className={styles.enquiryFormContainer}>
        <EnquiryForm />
      </div>
      <div className={styles.backgroundContainer}>
        <img
          src={BackgroundLogo}
          className={styles.backgroundImage}
          alt="backgroundImage"
        />
      </div>
    </div>
  );
};

export default Landing;

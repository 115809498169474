import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import MainHeader from "../../../common/MainHeader";
import MainFooter from "../../../common/MainFooter";
import { useDispatch, useSelector } from "react-redux";
import LoadingBackdrop from "../../../common/LoadingBackdrop.js";
import { setActiveSidebar } from "../../../../slices/financeSlice";
import { setLoading } from "../../../../slices/commonSlice";
import { DatePicker, Select, Table, Modal, Input } from "antd";
import { toast } from "react-toastify";
import {
  createPaymentEntry,
  getAccountPaidFromListing,
  getCollegeListing,
  getAccountPaidToListing,
  getAllFees,
  getCostCenterListing,
  getModeOfPaymentListing,
  getStudentsListing,
} from "../../../../modules/finance.js";
import { useLocation } from "react-router-dom";

const AddNewPaymentEntry = () => {
  const styles = useStyles();
  const selectSize = "middle";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [modeOfPaymentOptions, setModeOfPaymentOptions] = useState([]);
  const [studentOptions, setStudentOptions] = useState([]);
  const [fees, setFees] = useState([]);
  const [feesOptions, setFeesOptions] = useState([]);
  const [costCenterOptions, setCostCenterOptions] = useState([]);
  const [collegeListOptions, setCollegeListOptions] = useState([]);
  const [accountPaidToOptions, setAccountPaidToOptions] = useState([]);
  const [accountPaidFromOptions, setAccountPaidFromOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [values, setValues] = useState({
    payment_type: "",
    posting_date: "",
    mode_of_payment: "",
    company: "",
    party_type: "",
    party: "",
    party_name: "",
    party_balance: "",
    paid_to: "",
    paid_from: "",
    paid_amount: 0,
    total_allocated_amount: 0,
    unallocated_amount: 0,
    difference_amount: 0,
    total_taxes_and_charges: 0,
    reference_no: "",
    reference_date: "",
    cost_center: "",
    remarks: "",
    references: [{}],
  });

  const [errors, setErrors] = useState({
    payment_type: "",
    posting_date: "",
    mode_of_payment: "",
    company: "",
    party_type: "",
    party: "",
    party_name: "",
    party_balance: "",
    paid_to: "",
    paid_from: "",
    paid_amount: "",
    total_allocated_amount: "",
    unallocated_amount: "",
    difference_amount: "",
    total_taxes_and_charges: "",
    reference_no: "",
    reference_date: "",
    remarks: "",
    references: [{}],
  });

  const handleValidateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!values.payment_type) {
      newErrors.payment_type = "Payment Type is required";
      isValid = false;
    } else {
      newErrors.payment_type = "";
    }

    if (!values.posting_date) {
      newErrors.posting_date = "Posting Date is required";
      isValid = false;
    } else {
      newErrors.posting_date = "";
    }

    if (!values.mode_of_payment) {
      newErrors.mode_of_payment = "Mode of Payment is required";
      isValid = false;
    } else {
      newErrors.mode_of_payment = "";
    }

    if (!values.company) {
      newErrors.company = "Institution is required";
      isValid = false;
    } else {
      newErrors.company = "";
    }

    if (!values.party_type) {
      newErrors.party_type = "Party Type is required";
      isValid = false;
    } else {
      newErrors.party_type = "";
    }

    if (!values.party) {
      newErrors.party = "Party is required";
      isValid = false;
    } else {
      newErrors.party = "";
    }

    if (!values.party_name) {
      newErrors.party_name = "Party Name is required";
      isValid = false;
    } else {
      newErrors.party_name = "";
    }

    if (!values.paid_to) {
      newErrors.paid_to = "Account Paid To is required";
      isValid = false;
    } else {
      newErrors.paid_to = "";
    }

    if (!values.paid_from) {
      newErrors.paid_from = "Account Paid From is required";
      isValid = false;
    } else {
      newErrors.paid_from = "";
    }

    if (!values.paid_amount) {
      newErrors.paid_amount = "Paid Amount is required";
      isValid = false;
    } else if (isNaN(parseFloat(values.paid_amount))) {
      newErrors.paid_amount = "Please enter a valid amount";
      isValid = false;
    } else {
      newErrors.paid_amount = "";
    }
    if (!values.reference_date) {
      newErrors.reference_date = "Reference Date From is required";
      isValid = false;
    } else {
      newErrors.reference_date = "";
    }

    setErrors(newErrors);
    return isValid;
  };

  const PaymentTypeOptions = [
    { value: "Receive", label: "Receive" },
    { value: "Pay", label: "Pay" },
    { value: "Internal Transfer", label: "Internal Transfer" },
  ];
  const PartyTypeOptions = [{ value: "Student", label: "Student" }];

  const handleGetModeOfPaymentListing = async () => {
    const res = await getModeOfPaymentListing();
    if (res.status === 200) {
      const arr = [];
      const modeOfPaymentList = res.data.data;
      for (let i = 0; i < modeOfPaymentList.length; i++) {
        arr.push({
          value: modeOfPaymentList[i].name,
          label: modeOfPaymentList[i].name,
        });
      }
      setModeOfPaymentOptions(arr);
    }
  };

  const handleGetCostCenterListing = async () => {
    const res = await getCostCenterListing();

    if (res.status === 200) {
      const arr = [];
      const costCenterList = res.data.message;
      for (let i = 0; i < costCenterList.length; i++) {
        arr.push({
          value: costCenterList[i].value,
          label: costCenterList[i].value,
        });
      }
      setCostCenterOptions(arr);
    }
  };

  const handleGetAllFees = async () => {
    const res = await getAllFees();
    if (res.status === 200) {
      const arr = [];
      const feeList = res.data.data;
      for (let i = 0; i < feeList.length; i++) {
        arr.push({
          value: feeList[i].name,
          label: feeList[i].name,
          grand_total: feeList[i].grand_total,
          outstanding_amount: feeList[i].outstanding_amount,
          total_allocated_amount: feeList[i].grand_total,
        });
      }
      setFees(feeList);
      setFeesOptions(arr);
    }
  };

  const handleGetStudentListing = async () => {
    const res = await getStudentsListing();
    if (res.status === 200) {
      const arr = [];
      const studentList = res.data.data;
      for (let i = 0; i < studentList.length; i++) {
        arr.push({
          value: studentList[i].name,
          label: `${studentList[i].name} - ${studentList[i].first_name}`,
        });
      }
      setStudentOptions(arr);
    }
  };

  const handleGetAccountPaidToListing = async () => {
    const res = await getAccountPaidToListing();
    if (res.status === 200) {
      const arr = [];
      const accountPaidToList = res.data.message;
      for (let i = 0; i < accountPaidToList.length; i++) {
        arr.push({
          value: accountPaidToList[i].value,
          label: accountPaidToList[i].value,
        });
      }
      setAccountPaidToOptions(arr);
    }
  };

  const handleGetAccountPaidFromListing = async () => {
    const res = await getAccountPaidFromListing();
    if (res.status === 200) {
      const arr = [];
      const accountPaidFromList = res.data.message;
      for (let i = 0; i < accountPaidFromList.length; i++) {
        arr.push({
          value: accountPaidFromList[i].value,
          label: accountPaidFromList[i].value,
        });
      }
      setAccountPaidFromOptions(arr);
    }
  };

  const handleGetgetCollegeListing = async () => {
    const res = await getCollegeListing();
    if (res.status === 200) {
      const arr = [];
      const collegeList = res.data.data;
      console.log(collegeList);
      for (let i = 0; i < collegeList.length; i++) {
        arr.push({
          value: collegeList[i].name,
          label: collegeList[i].name,
        });
      }
      setCollegeListOptions(arr);
    }
  };

  const onSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  };

  const handleAddPaymentEntryReference = () => {
    setValues({
      ...values,
      references: [...values.references, {}],
    });
  };

  const handleDeletePaymentEntryReference = () => {
    const references = values.references;
    const resultArray = references.filter(
      (obj) => !selectedRowKeys.includes(obj.key)
    );
    setSelectedRowKeys([]);
    setValues({
      ...values,
      references: resultArray.length > 0 ? resultArray : [{}],
    });
  };

  const rowSelection = {
    type: "checkbox",
    onChange: onSelectChange,
    selectedRowKeys,
  };

  const handleOutstandingInvoices = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handlePaymentNavigation = () => {
    dispatch(setActiveSidebar({ activeSidebar: 6 }));
    navigate("/modules/finance");
  };

  const columns = [
    {
      title: "No.",
      dataIndex: "index",
      width: "5%",
      render: (val, record, index) => {
        return <p className={styles.tableContentFields}>{index + 1}</p>;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      width: "15%",
      render: (val, record) => {
        return (
          <p className={styles.tableContentFields}>
            {record.reference_doctype || "Fees"}
          </p>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "20%",
      render: (val, record, index) => {
        return (
          <Select
            className={styles.tableContentFields}
            size={selectSize}
            placeholder="Select"
            style={{ width: "100%", border: "none !important" }}
            value={record.reference_name}
            options={feesOptions}
            onChange={(value) => {
              const fee = fees.find((category) => category.name === value);
              const componentsArr = values.references;
              componentsArr[index] = {
                key: index,
                reference_name: fee.name,
                grand_total: fee.grand_total,
                outstanding_amount: fee.outstanding_amount,
                allocated_amount: fee.grand_total,
              };
              setValues({
                ...values,
                references: [...componentsArr],
                reference_no: fee.name,
              });
            }}
          />
        );
      },
    },
    {
      title: "Grand Total(INR)",
      dataIndex: "grand_total",
      width: "15%",
      render: (val, record) => {
        return (
          <p className={styles.tableContentFields}>{record.grand_total}</p>
        );
      },
    },
    {
      title: "Outstanding(INR)",
      dataIndex: "outstanding_amount",
      width: "15%",
      render: (val, record) => {
        return (
          <p className={styles.tableContentFields}>
            {record.outstanding_amount}
          </p>
        );
      },
    },
    {
      title: "Allocated(INR)",
      dataIndex: "total_allocated_amount",
      width: "15%",
      render: (val, record) => {
        return (
          <p className={styles.tableContentFields}>{record.allocated_amount}</p>
        );
      },
    },
  ];

  const handleSave = async () => {
    const isValid = handleValidateForm();
    if (isValid) {
      try {
        const referencesPayload = values.references.map((reference) => ({
          reference_doctype: "Fees",
          reference_name: reference.reference_name,
          grand_total: reference.grand_total,
          outstanding_amount: reference.outstanding_amount,
          allocated_amount: reference.allocated_amount,
        }));
        const payload = {
          ...values,
          docstatus: 0,
          received_amount: values.paid_amount,
          source_exchange_rate: 1.0,
          paid_from_account_type: "Receivable",
          paid_from_account_currency: "INR",
          paid_to_account_type: "Bank",
          paid_to_account_currency: "INR",
          references: referencesPayload,
        };

        const res = await createPaymentEntry({ payload });
        console.log(res);
        if (res.status === 200) {
          toast.success("created");
          navigate(`/modules/finance/payment-entry/${res.data.data.name}`);
        } else {
          toast.error("Error while creating Payment Entry!");
        }
      } catch (error) {
        toast.error(error.response.data.exception);
      }
    }
  };
  const { state } = useLocation();

  useEffect(() => {
    if (state) {
      const {
        company,
        posting_date,
        student,
        student_name,
        cost_center,
        total_amount,
        fee_id,
      } = state;
      const updatedReferences = [
        {
          reference_name: fee_id,
          grand_total: total_amount,
          outstanding_amount: total_amount,
          allocated_amount: total_amount,
        },
      ];
      setValues({
        ...values,
        company: company,
        posting_date: posting_date,
        reference_date: posting_date,
        payment_type: "Receive",
        party_type: "Student",
        party: student,
        party_name: student_name,
        cost_center: cost_center,
        paid_amount: total_amount,
        references: updatedReferences,
        reference_no: fee_id,
      });
      console.log(values, "values");
    } else {
      setValues({
        ...values,
        company: "",
        posting_date: "",
        payment_type: "",
        party_type: "",
        party: "",
        party_name: "",
        cost_center: "",
        paid_amount: "",
        total_allocated_amount: "",
        reference_name: "",
        grand_total: "",
        outstanding_amount: "",
        allocated_amount: "",
        reference_no: "",
      });
    }
  }, [state]);

  useEffect(() => {
    handleGetModeOfPaymentListing();
    handleGetStudentListing();
    handleGetAllFees();
    handleGetgetCollegeListing();
    handleGetCostCenterListing();
    handleGetAccountPaidToListing();
    handleGetAccountPaidFromListing();
  }, []);

  return (
    <>
      <MainHeader />
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/dashboard")}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/modules/finance")}
            >
              Finance {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={handlePaymentNavigation}
            >
              Payment Entry
            </p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>New Payment Entry</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div className={styles.button} onClick={handlePaymentNavigation}>
                <p className={styles.buttonText}>Back</p>
              </div>
              <div className={styles.activeButton}>
                <p className={styles.activeButtonText} onClick={handleSave}>
                  Save
                </p>
              </div>
            </div>
          </div>
          <Modal
            title="Filters"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <div className={styles.row} key="footerButtons">
                <div className={styles.button} onClick={handleCancel}>
                  <p className={styles.buttonText}>Cancel</p>
                </div>
                <div className={styles.activeButton}>
                  <p className={styles.activeButtonText} onClick={handleOk}>
                    Get Outstanding Invoice
                  </p>
                </div>
              </div>,
            ]}
          >
            <div className={styles.modalDivider}></div>
            <div>
              <p className={styles.smallTitle}>Accounts</p>
              <div className={styles.row}>
                <div className={styles.modalField}>
                  <p className={styles.fieldLabel}>From Date *</p>
                  <DatePicker
                    placeholder="YYYY-MM-DD"
                    className={styles.selectInput}
                    onChange={(date, dateString) => {
                      setValues({ ...values, posting_date: dateString });
                    }}
                  />
                </div>
                <div className={styles.modalField}>
                  <p className={styles.fieldLabel}>To Date *</p>
                  <DatePicker
                    placeholder="YYYY-MM-DD"
                    className={styles.selectInput}
                    onChange={(date, dateString) => {
                      setValues({ ...values, posting_date: dateString });
                    }}
                  />
                </div>
              </div>
              <div className={styles.modalDivider}></div>
            </div>
            <div>
              <p className={styles.smallTitle}>Due Date</p>
              <div className={styles.row}>
                <div className={styles.modalField}>
                  <p className={styles.fieldLabel}>From Date *</p>
                  <DatePicker
                    placeholder="YYYY-MM-DD"
                    className={styles.selectInput}
                    onChange={(date, dateString) => {
                      setValues({ ...values, posting_date: dateString });
                    }}
                  />
                </div>
                <div className={styles.modalField}>
                  <p className={styles.fieldLabel}>To Date *</p>
                  <DatePicker
                    placeholder="YYYY-MM-DD"
                    className={styles.selectInput}
                    onChange={(date, dateString) => {
                      setValues({ ...values, posting_date: dateString });
                    }}
                  />
                </div>
              </div>
              <div className={styles.modalDivider}></div>
            </div>
            <div>
              <p className={styles.smallTitle}>Outstanding Amount</p>
              <div className={styles.row}>
                <div className={styles.modalField}>
                  <p className={styles.fieldLabel}>Greater Than Amount *</p>
                  <Input
                    placeholder="ex Text"
                    value={values.paid_amount}
                    className={styles.fieldInput}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        paid_amount: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className={styles.modalField}>
                  <p className={styles.fieldLabel}>Less Than Amount *</p>
                  <Input
                    placeholder="ex Text"
                    value={values.paid_amount}
                    className={styles.fieldInput}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        paid_amount: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className={styles.modalDivider}></div>
            </div>
            <div>
              <div className={styles.modalContainer}>
                <div>
                  <p className={styles.fieldLabel}>Cost Center *</p>
                  <Select
                    size={selectSize}
                    placeholder="Select"
                    value={values.cost_center}
                    className={styles.selectInput}
                    options={costCenterOptions}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        cost_center: value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal>
          <div className={styles.formContainer}>
            <div>
              <p className={styles.smallTitle}>Type Of Payment</p>
              <div className={styles.divider}></div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Payment Type *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.payment_type}
                  className={styles.selectInput}
                  options={PaymentTypeOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      payment_type: value,
                    });
                    setErrors({
                      ...errors,
                      payment_type: "",
                    });
                  }}
                />
                {errors.payment_type && (
                  <p className={styles.errorMessage}>{errors.payment_type}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Posting Date *</p>
                <input
                  type="date"
                  value={values.posting_date}
                  placeholder="YYYY-MM-DD"
                  className={styles.dateInput}
                  onChange={(e) => {
                    const dateString = e.target.value;
                    setValues({ ...values, posting_date: dateString });
                    setErrors({
                      ...errors,
                      posting_date: "",
                    });
                  }}
                />
                {errors.posting_date && (
                  <p className={styles.errorMessage}>{errors.posting_date}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Mode Of Payment *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.mode_of_payment}
                  className={styles.selectInput}
                  options={modeOfPaymentOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      mode_of_payment: value,
                    });
                    setErrors({
                      ...errors,
                      mode_of_payment: "",
                    });
                  }}
                />
                {errors.mode_of_payment && (
                  <p className={styles.errorMessage}>
                    {errors.mode_of_payment}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Institution *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.company}
                  className={styles.selectInput}
                  options={collegeListOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      company: value,
                    });
                    setErrors({
                      ...errors,
                      company: "",
                    });
                  }}
                />
                {errors.company && (
                  <p className={styles.errorMessage}>{errors.company}</p>
                )}
              </div>
            </div>
            <div>
              <p className={styles.smallTitle}>Payment From / To</p>
              <div className={styles.divider}></div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Party Type *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.party_type}
                  className={styles.selectInput}
                  options={PartyTypeOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      party_type: value,
                    });
                    setErrors({
                      ...errors,
                      party_type: "",
                    });
                  }}
                />
                {errors.party_type && (
                  <p className={styles.errorMessage}>{errors.party_type}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Party *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.party}
                  className={styles.selectInput}
                  options={studentOptions}
                  onChange={(value, options) => {
                    const selectedParty = studentOptions.find(
                      (opt) => opt.value === value
                    );
                    if (selectedParty) {
                      setValues({
                        ...values,
                        party: value,
                        party_name: selectedParty.label,
                      });
                    } else {
                      setValues({
                        ...values,
                        party: null,
                        party_name: null,
                      });
                    }
                    setErrors({
                      ...errors,
                      party: "",
                    });
                  }}
                  showSearch // Enable search functionality
                  filterOption={(
                    input,
                    option // Define custom filtering logic
                  ) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
                {errors.party && (
                  <p className={styles.errorMessage}>{errors.party}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Party Name *</p>
                <Input
                  placeholder="ex Text"
                  disabled={true}
                  value={values.party_name}
                  className={styles.fieldInput}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      party_name: e.target.value,
                    });
                    setErrors({
                      ...errors,
                      party_name: "",
                    });
                  }}
                />
                {errors.party_name && (
                  <p className={styles.errorMessage}>{errors.party_name}</p>
                )}
              </div>
            </div>
            <div>
              <p className={styles.smallTitle}>Accounts</p>
              <div className={styles.divider}></div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Party Balance </p>
                <Input
                  disabled={true}
                  placeholder="₹0.0"
                  value={values.party_balance}
                  className={styles.fieldInput}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      party_balance: e.target.value,
                    });
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Account Paid To *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.paid_to}
                  className={styles.selectInput}
                  options={accountPaidToOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      paid_to: value,
                    });
                    setErrors({
                      ...errors,
                      paid_to: "",
                    });
                  }}
                />
                {errors.paid_to && (
                  <p className={styles.errorMessage}>{errors.paid_to}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Account Paid From *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.paid_from}
                  className={styles.selectInput}
                  options={accountPaidFromOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      paid_from: value,
                    });
                    setErrors({
                      ...errors,
                      paid_from: "",
                    });
                  }}
                />
                {errors.paid_from && (
                  <p className={styles.errorMessage}>{errors.paid_from}</p>
                )}
              </div>
            </div>
            <div>
              <p className={styles.smallTitle}>Amount</p>
              <div className={styles.divider}></div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Paid Amount (INR) *</p>
                <Input
                  placeholder="0.0"
                  value={values.paid_amount}
                  className={styles.fieldInput}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      paid_amount: e.target.value,
                    });
                    setErrors({
                      ...errors,
                      paid_amount: "",
                    });
                  }}
                />
                {errors.paid_amount && (
                  <p className={styles.errorMessage}>{errors.paid_amount}</p>
                )}
              </div>
            </div>
            <div>
              <p className={styles.smallTitle}>Reference</p>
              <div className={styles.divider}></div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.activeButton}>
                <p
                  className={styles.activeButtonText}
                  onClick={handleOutstandingInvoices}
                >
                  Get Outstanding Invoices
                </p>
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.tableField}>
                <p className={styles.fieldLabel}>Payment References </p>
                <Table
                  rowSelection={{ type: "checkbox", ...rowSelection }}
                  columns={columns}
                  dataSource={values.references}
                  size="small"
                  pagination={false}
                />
              </div>
            </div>
            <div>
              <div className={styles.fieldButtons}>
                {selectedRowKeys.length > 0 && (
                  <div
                    className={styles.deleteRow}
                    onClick={handleDeletePaymentEntryReference}
                  >
                    Delete
                  </div>
                )}
                <div
                  className={styles.addRow}
                  onClick={handleAddPaymentEntryReference}
                >
                  +
                </div>
              </div>
            </div>
            <p className={styles.smallTitle}>Write-Off</p>
            <div className={styles.divider}></div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Total Allocated Amount *</p>
                <Input
                  placeholder="0.0"
                  disabled={true}
                  value={values.total_allocated_amount}
                  className={styles.fieldInput}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      total_allocated_amount: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Unallocated Amount </p>
                <Input
                  placeholder="0.0"
                  value={values.unallocated_amount}
                  className={styles.fieldInput}
                  onChange={(e) => {
                    const paidAmount = parseFloat(values.paid_amount);
                    const totalAllocated = parseFloat(
                      values.total_allocated_amount
                    );
                    const unAllocated = paidAmount - totalAllocated;
                    setValues({
                      ...values,
                      unallocated_amount: unAllocated,
                    });
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Differnce Amount *</p>
                <Input
                  placeholder="0.0"
                  value={values.difference_amount}
                  disabled={true}
                  className={styles.fieldInput}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      difference_amount: e.target.value,
                    });
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Total Taxes and Charges </p>
                <Input
                  placeholder="0.0"
                  value={values.total_taxes_and_charges}
                  className={styles.fieldInput}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      total_taxes_and_charges: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div>
              <p className={styles.smallTitle}>Transaction ID</p>
              <div className={styles.divider}></div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Cheque/Reference Number *</p>
                <Input
                  placeholder="0.0"
                  disabled={true}
                  value={values.reference_no}
                  className={styles.fieldInput}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      reference_no: e.target.value,
                    });
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Cheque/Reference Date *</p>
                <input
                  type="date"
                  value={values.reference_date}
                  placeholder="YYYY-MM-DD"
                  className={styles.dateInput}
                  onChange={(e) => {
                    const dateString = e.target.value;
                    setValues({ ...values, reference_date: dateString });
                    setErrors({
                      ...errors,
                      reference_date: "",
                    });
                  }}
                />
                {errors.reference_date && (
                  <p className={styles.errorMessage}>{errors.reference_date}</p>
                )}
              </div>
              <div className={styles.field}></div>
            </div>
            <div>
              <p className={styles.smallTitle}>Account Dimensions</p>
              <div className={styles.divider}></div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Cost Center </p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.cost_center}
                  className={styles.selectInput}
                  options={costCenterOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      cost_center: value,
                    });
                  }}
                />
              </div>
            </div>
            <div>
              <p className={styles.smallTitle}>More Information</p>
              <div className={styles.divider}></div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.textarea}>
                <p className={styles.fieldLabel}>Remarks </p>
                <textarea
                  type="text"
                  placeholder="Enter Text here..."
                  className={styles.textAreaInput}
                  value={values.remarks}
                  rows={5}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      remarks: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default AddNewPaymentEntry;

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "28px",
  },
  headerContainer: {
    display: "inline-flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: "22px",
    color: "#0D1021",
    fontWeight: "500",
  },
  activeButton: {
    cursor: "pointer",
    borderRadius: " 4px",
    background: "#A32930",
    padding: "0px 14px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow:
      "0px 0px 2px 0px rgba(21, 40, 80, 0.12), 0px 2px 4px 0px rgba(21, 40, 80, 0.10)",
  },
  activeButtonText: {
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "400",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    marginBottom: "40px"
  },
  field: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  fieldLabel: {
    color: "#707070",
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "Poppins",
    letterSpacing: "0.6px"
  },
  labelValue: {
    color: "#012148",
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "Poppins",
  },
}));

export default useStyles;

import React, { useState, useEffect } from "react";
import { getStudentDetails } from "../../../../modules/studentApplication";
import { updateDataToApplicationForm } from "../../../../modules/student";
import { toast } from "react-toastify";
import useStyles from "./style";
import { Select } from "antd";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";

const ApplicationDocs = ({ uniqueId }) => {
  const styles = useStyles();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const statusOptions = [
    {
      value: '-',
      label: '-'
    },
    {
      value: 'Approved',
      label: 'Approved'
    },
    {
      value: 'Re-Submit',
      label: 'Re-Submit'
    }
  ];

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    try {
      const res = await getStudentDetails(uniqueId);
      if (res.status === 200) {
        setData(res.data.data);
      }
    } catch (error) {
      console.error("Error get the student details", error);
    }
    setLoading(false);
  };

  const attachments = [
    { key: "custom_attach_aadhar_card", label: "Aadhaar card", documentStatusKey: "custom_aadhar_card_status" },
    { key: "custom_attach_relevant_document", label: "Relevant Document", documentStatusKey: "custom_relevant_document_status" },
    {
      key: "custom_if_any_please_attach_relevant_document",
      label: "Relevant Document2",
      documentStatusKey: "custom_health_status"
    },
    { key: "custom_attach_xiith_marksheet", label: "XIIth Marksheet", documentStatusKey: "custom_xiith_marksheet_status"},
    { key: "custom_attach_xith_marksheet", label: "XIth Marksheet", documentStatusKey: "custom_xith_marksheet_status" },
    { key: "custom_attach_xth_marksheet", label: "Xth Marksheet", documentStatusKey: "custom_xth_marksheet_status" },
    { key: "custom_upload_student_image", label: "Student Photo", documentStatusKey: "custom_student_image_status" },
    { key: "custom_attachment_info", label: "Custom Attachment", documentStatusKey: "custom_disciplinary_inquiry_status" },
  ];

  const baseUrl = "https://erp-dev.techademycampus.com";

  const extractFileName = (path) => {
    if (path) {
      const parts = path.split("/");
      return parts[parts.length - 1];
    } else {
      return "N/A";
    }
  };


  const handleOptionChange = (index, option, statusKey) => {
    handleUpdateDataToApplicationForm({ statusKey, option })
  };

  const handleUpdateDataToApplicationForm = async ({ statusKey, option }) => {
    setLoading(true);
    const payload = {};
    payload[`${statusKey}`] = option;
    const res = await updateDataToApplicationForm({ payload, name: uniqueId });
    if(res.status == 200) {
      getDetails();
    } else {
      let error = res.data?.exception;
      error = error.split(":")[1];
      toast.error(error);
    }
    setLoading(false);
  }

  return (
    <>
     {
      loading ?
      <LoadingBackdrop open={loading}/> :
      <div className="formTableWrap">
        <table className="formTable docs_table">
          <thead>
            <tr className={styles.tableRow}>
              <th className={styles.tableHeaderTitle} style={{ paddingLeft: "22px" }}>Attachment</th>
              <th className={styles.tableHeaderTitle} style={{ paddingLeft: "22px" }}>Attached File</th>
              <th className={styles.tableHeaderTitle} style={{ paddingLeft: "22px" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {attachments.map((attachment, index) => {
            return  (
              <>
                {
                  data[attachment.key] &&  <tr key={index} className={styles.tableRow}>
                  <td className={styles.tableDocContent}>{attachment.label}</td>
                  <td>
                    {data[attachment.key] && (
                      <a
                        href={`${baseUrl}${data[attachment.key]}`}
                        download={extractFileName(data[attachment.key])}
                        target={`_blank`}
                        style={{ textDecoration: "none" }}
                      >
                      <p className={styles.tableDocContent}>{extractFileName(data[attachment.key])}</p>
                      </a>
                    )}
                  </td>
                  <td>
                  <Select 
                    size="small"
                    options={statusOptions} 
                    onChange={(value) => handleOptionChange(index, value, attachment.documentStatusKey)}
                    className={styles.selectInputBox}
                    value={data[`${attachment.documentStatusKey}`]} />
                    {/* <select onChange={(e) => handleOptionChange(index, e.target.value, attachment.documentStatusKey)}>
                      <option value="" selected={data[`${attachment.documentStatusKey}`] == "" || data[`${attachment.documentStatusKey}`] == undefined}></option>
                      <option value="Approved" selected={data[`${attachment.documentStatusKey}`] == "Approved"} >Approved</option>
                      <option value="Re-Submit" selected={data[`${attachment.documentStatusKey}`] == "Re-Submit"}>Doc Resubmit</option>
                    </select> */}
                  </td>
                </tr>
                }
              </>
            )
            })}
          </tbody>
        </table>
      </div>
     }
    </>
  );
};

export default ApplicationDocs;

import React from "react";
import Grid from "@mui/material/Grid";
import ReactQuill from "react-quill";
import LoadingBackdrop from "../../../../common/LoadingBackdrop.js";
import { getCurrentDateAndTime } from "../../../../../utils/date.js";

const AddEvent = ({ values, setValues, loading }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "28px",
      }}
    >
      {loading ? (
        <LoadingBackdrop open={loading}/>
      ) : (
        <>
          <p style={{ color: "#1A1B25", fontSize: "18px", fontWeight: "700" }}>
            New Event
          </p>
          <p style={{ color: "#012148", fontSize: "18px", fontWeight: "500" }}>
            Add Details
          </p>
          <Grid
            container
            spacing={{ xs: 2, sm: 3, md: 6 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <p
                style={{
                  color: "#012148",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Subject
              </p>
              <input
                type="text"
                placeholder="Enter Subject"
                style={{
                  color: "#536882",
                  fontSize: "12px",
                  fontWeight: "400",
                  padding: "12px",
                  borderRadius: "5px",
                  border: "1px solid #CDD6E2",
                  background: "#fff",
                  outline: "none",
                  width: "100%",
                }}
                onChange={(e) => {
                  setValues({ ...values, subject: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <p
                style={{
                  color: "#012148",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Starts on
              </p>
              <input
                type="datetime-local"
                placeholder="Enter OTP *"
                style={{
                  color: "#536882",
                  fontSize: "12px", 
                  padding: "10px",
                  fontWeight: "400",
                  borderRadius: "5px",
                  border: "1px solid #CDD6E2",
                  background: "#fff",
                  outline: "none",
                  width: "100%",
                }}
                min={getCurrentDateAndTime()}
                onChange={(e) => {
                  setValues({ ...values, startOn: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <p
                style={{
                  color: "#012148",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Ends on
              </p>
              <input
                type="datetime-local"
                style={{
                  color: "#536882",
                  fontSize: "12px",
                  fontWeight: "400",
                  padding: "12px",
                  borderRadius: "5px",
                  border: "1px solid #CDD6E2",
                  background: "#fff",
                  outline: "none",
                  width: "100%",
                }}
                onChange={(e) => {
                  setValues({ ...values, endsOn: e.target.value });
                }}
                min={getCurrentDateAndTime()}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={{ xs: 2, sm: 3, md: 6 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <p
                style={{
                  color: "#012148",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Event Category
              </p>
              <select
                style={{
                  color: "#536882",
                  fontSize: "12px",
                  fontWeight: "400",
                  padding: "12px",
                  borderRadius: "5px",
                  border: "1px solid #CDD6E2",
                  background: "#fff",
                  outline: "none",
                  width: "100%",
                }}
                onChange={(e) => {
                  setValues({ ...values, eventCategory: e.target.value });
                }}
              >
                <option value="Event">Event</option>
                <option value="Meeting">Meeting</option>
                <option value="Call">Call</option>
                <option value="Sent/Received Email">Sent/Received Email</option>
                <option value="Other">Other</option>
              </select>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <p
                style={{
                  color: "#012148",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Status
              </p>
              <select
                style={{
                  color: "#536882",
                  fontSize: "12px",
                  fontWeight: "400",
                  padding: "12px",
                  borderRadius: "5px",
                  border: "1px solid #CDD6E2",
                  background: "#fff",
                  outline: "none",
                  width: "100%",
                }}
                onChange={(e) => {
                  setValues({ ...values, status: e.target.value });
                }}
              >
                <option value="Open">Open</option>
                <option value="Completed">Completed</option>
                <option value="Closed">Closed</option>
                <option value="Cancelled">Cancelled</option>
              </select>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={{ xs: 2, sm: 3, md: 6 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <p
                style={{
                  color: "#012148",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Event Type
              </p>
              <select
                style={{
                  color: "#536882",
                  fontSize: "12px",
                  fontWeight: "400",
                  padding: "12px",
                  borderRadius: "5px",
                  border: "1px solid #CDD6E2",
                  background: "#fff",
                  outline: "none",
                  width: "100%",
                }}
                onChange={(e) => {
                  setValues({ ...values, eventType: e.target.value });
                }}
              >
                <option value="Private">Private</option>
                <option value="Public">Public</option>
              </select>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <p
                style={{
                  color: "#012148",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Invite sent to
              </p>
              <input
                disabled={true}
                type='text'
                value={values.addGuests}
                style={{
                  color: "#536882",
                  fontSize: "12px",
                  fontWeight: "400",
                  padding: "12px",
                  borderRadius: "5px",
                  border: "1px solid #CDD6E2",
                  background: "#fff",
                  outline: "none",
                  width: "100%",
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={{ xs: 2, sm: 3, md: 6 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <p
                style={{
                  color: "#012148",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Color
              </p>
              <input
                type="color"
                style={{
                  padding: "18px",
                  borderRadius: "5px",
                  border: "1px solid #CDD6E2",
                  background: "#fff",
                  outline: "none",
                  width: "100%",
                  height:"10px",
                }}
                onChange={(e) => {
                  setValues({ ...values, color: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  marginTop: "12px"
                }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    style={{
                      height: "18px",
                      width: "18px",
                      borderRadius: "4px",
                      border: "1px solid #A6B0C2",
                      backgroundColor: "#FFF",
                    }}
                    onClick={() => {
                      setValues({
                        ...values,
                        sendEmail: values.sendEmail === 0 ? 1 : 0,
                      });
                    }}
                  />
                  <p
                    style={{
                      color: "#012148",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    Send the email reminder this morning
                  </p>
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    style={{
                      height: "18px",
                      width: "18px",
                      borderRadius: "4px",
                      border: "1px solid #A6B0C2",
                      backgroundColor: "#FFF",
                      zIndex: "100"
                    }}
                    checked={values.repeat == 1}
                    onClick={() => {
                      setValues({
                        ...values,
                        repeat: values.repeat === 0 ? 1 : 0,
                      });
                    }}
                  />
                  <p
                    style={{
                      color: "#012148",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    Repeat this event
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={{ xs: 2, sm: 3, md: 6 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            style={{ marginBottom: "80px" }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <p
                style={{
                  color: "#012148",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Description
              </p>
              <ReactQuill
                value={values.description}
                onChange={(value) => {
                  setValues({ ...values, description: value });
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default AddEvent;

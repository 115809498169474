import React, { useState, useEffect } from 'react';
import useStyles from './style';
import { Select, Table, Input } from "antd";
import { useNavigate } from 'react-router-dom';
import { GridMenuIcon } from '@mui/x-data-grid';
import MainHeader from '../../../common/MainHeader';
import MainFooter from '../../../common/MainFooter';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveSidebar } from '../../../../slices/financeSlice.js';
import LoadingBackdrop from "../../../common/LoadingBackdrop.js";
import { createMeritCategory, getStudentCategoryListing } from '../../../../modules/admission.js';
import { toast } from 'react-toastify';

const AddNewMeritListCategory = () => {
  const selectSize = "middle";
  const styles= useStyles();
  const navigate= useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [values, setValues] = useState({
    template_name: "",
    options: [{}]
  });

  const columns = [
    {
      title: 'Student Category',
      dataIndex: 'studentCategory',
      width: "70%",
      render: (val, record, index) => {
        return  (
          <Select
            className={styles.tableContentFields}
            size={selectSize}
            placeholder="Select"
            style={{ width: "30%", border: 'none !important' }}
            value={record.studentCategory}
            options={categoryOptions}
            onChange={(value) => {
              const studentCategory = categoryOptions.find((category) => category.value === value);
              const optionsArr = values.options;
              optionsArr[index] = {
                key: index,
                studentCategory: studentCategory.value
              }
              setValues({
                ...values,
                options: [
                  ...optionsArr
                ]
              });
            }}
          /> 
       )
      },
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      width: "30%",
      render: (val, record, index) => {
        return  (
          <>
            <Input
              placeholder="Enter Percentage"
              className={styles.tableContentFields}
              style={{ width: '100%', border: 'none' }}
              value={record.percentage}
              onChange={(e) => {
                const optionsArr = values.options;
                optionsArr[index] = {
                  ...optionsArr[index],
                  key: index,
                  percentage: e.target.value,
                };
                setValues({
                  ...values,
                  options: [
                    ...optionsArr
                  ]
                });
              }}
            />
          </>
       )
      },
    },
  ];

  const handleMeritListNavigation = () => {
    dispatch(setActiveSidebar({ activeSidebar: 3 }));
    navigate("/modules/admission");
  };

  const handleAddFeeCategory = () => {
    setValues({
      ...values,
      options: [
        ...values.options,
        {}
      ]
    });
  };

  const handleDeleteFeeCategory = () => {
    const components = values.options;
    const resultArray = components.filter((obj) => !selectedRowKeys.includes(obj.key))
    setSelectedRowKeys([]);
    setValues({
      ...values,
      options: resultArray.length > 0 ? resultArray : [{}]
    });
  }

  const onSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  }; 

  const rowSelection = {
    type: 'checkbox',
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      studentCategory: record.studentCategory,
    }),
    selectedRowKeys,
  };

  const handleGetStudentCategoryListing = async () => {
    const res = await getStudentCategoryListing();
    if(res.status === 200) {
      const arr = [];
      const categoryList = res.data.data;
      for(let i = 0 ; i < categoryList.length ; i++) {
        arr.push({
          value: categoryList[i].name,
          label: categoryList[i].name
        });
      };
      setCategoryOptions(arr);
    };
  };


  const handleCreate = async () => {
    try {
      let arr = [];
      for(let i = 0 ; i < values.options.length ; i++) {
        arr.push({
          student_category: values.options[i].studentCategory,
          percentage: values.options[i].percentage,
        })
      };
      const payload = {
        template_name: values.template_name,
        options: arr
      }
      const res = await createMeritCategory({ payload });
      if(res.status === 200) {
        toast.success("Created!");
        navigate("/modules/admission/merit-list-category");
      } else {
        toast.error("Some issue while creating!");
      }
    } catch (error) {
      toast.error(error.response.data.exception);
    }
  }

  useEffect(() => {
    handleGetStudentCategoryListing();
  }, []);

  return (
    <>
      <MainHeader />
      {
        loading ?
          <LoadingBackdrop open={loading} /> :
          <div className={styles.mainContainer}>
            <div className={styles.breadCrumbsContainer}>
              <p className={styles.breadCrumbsContent} onClick={() => navigate("/dashboard")}>IIHT {" > "}</p>
              <p className={styles.breadCrumbsContent} onClick={() => navigate("/modules/admission")}>Admission {" > "}</p>
              <p className={styles.breadCrumbsContent} onClick={handleMeritListNavigation}>Merit List Tool {" > "}</p>
              <p className={styles.breadCrumbsContent} onClick={() => navigate("/modules/admission/merit-list-category")}>Merit List Category</p>
            </div>
            <div className={styles.headerContainer}>
              <div className={styles.breadCrumbsContainer}>
                <GridMenuIcon className={styles.gridIcon}/>
                <p className={styles.title}>New Merit List Category</p>
              </div>
              <div className={styles.buttonsGroup}>
                <div className={styles.button} onClick={() => navigate("/modules/admission/merit-list-category")}>
                  <p className={styles.buttonText}>Back</p>
                </div>
                <div className={styles.activeButton}>
                  <p className={styles.activeButtonText} onClick={handleCreate}>Save</p>
                </div> 
              </div>
            </div>
            <div className={styles.formContainer}>
              <div className={styles.formRow}>
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>Merit Category Name *</p>
                  <Input
                    type="text" 
                    placeholder="ex Text"
                    className={styles.fieldInput}
                    value={values.name1}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        template_name: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className={styles.formRow}>
                <div className={styles.tableField}>
                  <p className={styles.fieldLabel}>Options *</p>
                  <Table 
                    rowSelection={{ type: "checkbox", ...rowSelection }} 
                    columns={columns} 
                    dataSource={values.options} 
                    size='small'
                    pagination={false}
                  />
                </div>
              </div>
              <div className={styles.fieldButtons}>
                {
                  selectedRowKeys.length > 0 && 
                      <div className={styles.deleteRow} onClick={handleDeleteFeeCategory}>Delete</div>
                }
                <div className={styles.addRow} onClick={handleAddFeeCategory}>+</div>
              </div>
            </div>
          </div>
      }
      <MainFooter />
    </>
  )
};

export default AddNewMeritListCategory;
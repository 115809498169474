import React, { useState, useEffect } from "react";
import useStyles from "./style.js";
import { GridMenuIcon } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import MainHeader from "../../../common/MainHeader/index.js";
import MainFooter from "../../../common/MainFooter/index.js";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSidebar } from "../../../../slices/financeSlice.js";
import { setLoading } from "../../../../slices/commonSlice.js";
import LoadingBackdrop from "../../../common/LoadingBackdrop.js/index.js";
import { toast } from "react-toastify";
import { MenuItem } from "@mui/material";
import { formConfig } from "../add-new-fee-schedule/FeeScheduleFormJson.js";
import { Table, Select } from "antd";
import {
  getFeeSchedule,
  getAllFeeStructures,
  getStudentCategoryListing,
  getProgramListing,
  getAcademicYearListing,
  getAcademicTermListing,
  getAllFeeCategories,
  createFeeSchedule,
  getReceivableAccountListing,
  getIncomeAccountListing,
  getCostCenterListing,
  getCollegeListing,
  createFeeFromFeeSchedule,
  getFeeStructure,
  getFeeScheduleByName,
  updateFeeSchedule,
} from "../../../../modules/finance.js";
import {
  getStudentGroupByName,
  getStudentGroupDataFeeSchedule,
  getStudentsInFeeSchedule,
} from "../../../../modules/studentGroup.js";
import { ToWords } from "to-words";

const GetUpdateFeeSchedule = () => {
  const [formData, setFormData] = useState({
    "Send Payment Request Mail": 0,
    feeCategoryComponents: [{ feeCategory: "Select" }],
    studentGroupComponents: [{}],
    "Active/Inactive": "active",
  });
  const [fieldErrors, setFieldErrors] = useState({});
  const [formConfigi, setFormConfigi] = useState(...formConfig);
  const [feeCategorySelectedRowKeys, setFeeCategorySelectedRowKeys] = useState(
    []
  );
  const [studentGroupSelectedRowKeys, setStudentGroupSelectedRowKeys] =
    useState([]);
  const [feeSchedule, setFeeSchedule] = useState([]);
  const [studentCategoryOptions, setStudentCategoryOptions] = useState([]);
  const [feeStructureOptions, setFeeStructureOptions] = useState([]);
  const [studentGroupOptions, setStudentGroupOptions] = useState([]);
  const [programOptions, setProgramOptions] = useState([]);
  const [academicYearOptions, setAcademicYearOptions] = useState([]);
  const [academicTermOptions, setAcademicTermOptions] = useState([]);
  const [receivableAccountOptions, setReceivableAccountOptions] = useState([]);
  const [incomeAccountOptions, setIncomeAccountOptions] = useState([]);
  const [costCenterOptions, setCostCenterOptions] = useState([]);
  const [collegeOptions, setCollegeOptions] = useState([]);

  const [updateFeeScheduleFlag, setUpdateFeeScheduleFlag] = useState(false);
  const [scheduleId, setScheduleId] = useState();
  const [feeCategoryOptions, seFeeCategoryOptions] = useState([]);
  const [feeCategories, setFeeCategories] = useState([]);
  const [studentGroups, setStudentGroups] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const [scheduleName, setScheduleName] = useState();
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const selectSize = "middle";
  const { schedule } = useParams();

  const handleFeeScheduleNavigation = () => {
    dispatch(setActiveSidebar({ activeSidebar: 3 }));
    navigate("/modules/finance");
  };
  const validateField = (value, field) => {
    switch (field.type) {
      case "text":
      case "textarea":
        return value && value.trim() !== ""; // Check if non-empty
      case "select":
        return value !== ""; // Check if a value is selected
      case "date":
        return value !== ""; // Check if date is not empty
      case "email":
        // Email validation using a simple regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
      case "phone":
        // Phone number validation using a simple regex
        const phoneRegex = /^[0-9]{10}$/;
        return value.trim() !== "" && phoneRegex.test(value);

      default:
        return true; // Default to true for other types
    }
  };

  const handleDeleteFeeCategory = () => {
    const feeCategoryComponents = formData.feeCategoryComponents;
    const resultArray = [
      ...feeCategoryComponents.filter(
        (obj) => !feeCategorySelectedRowKeys.includes(obj.key)
      ),
    ];

    setFeeCategorySelectedRowKeys([]);
    resultArray.forEach((item, index) => {
      item.no = index + 1;
    });
    setFormData((prevData) => ({
      ...prevData,
      feeCategoryComponents: [...resultArray],
    }));
  };
  const handleDeleteStudentGroup = () => {
    const studentGroupComponents = formData.studentGroupComponents;
    const resultArray = studentGroupComponents.filter(
      (obj) => !studentGroupSelectedRowKeys.includes(obj.key)
    );
    setStudentGroupSelectedRowKeys([]);
    setFormData((prevData) => ({
      ...prevData,
      studentGroupComponents: resultArray,
    }));
  };

  const onFeeCategorySelectChange = (selectedKeys) => {
    setFeeCategorySelectedRowKeys(selectedKeys);
  };
  const onStudentGroupSelectChange = (selectedKeys) => {
    setStudentGroupSelectedRowKeys(selectedKeys);
  };

  const feeCategoryRowSelection = {
    type: "checkbox",
    onChange: onFeeCategorySelectChange,
    getCheckboxProps: (record) => ({
      feeCategory: record.feeCategory,
    }),
    feeCategorySelectedRowKeys,
  };
  const studentGroupRowSelection = {
    type: "checkbox",
    onChange: onStudentGroupSelectChange,
    getCheckboxProps: (record) => ({
      studentGroup: record.studentGroup,
    }),
    studentGroupSelectedRowKeys,
  };
  const handleAddFeeCategory = () => {
    setFormData((prevData) => ({
      ...prevData,
      feeCategoryComponents: [...formData.feeCategoryComponents, {}],
    }));
  };
  const handleAddStudentGroup = () => {
    setFormData((prevData) => ({
      ...prevData,
      studentGroupComponents: [...formData.studentGroupComponents, {}],
    }));
  };

  const handleRadioButtonChange = (event) => {
    console.log(event.target.value);
    let temp = { ...formData };
    temp["Active/Inactive"] = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      "Active/Inactive": event.target.value,
    }));
  };

  // const handleGetFeeSchedule = async () => {
  //   const res = await getFeeSchedule();
  //   if (res.status === 200) {
  //     const arr = [];
  //     const feeSchedule = res.data.data;

  //     setFeeSchedule(arr);
  //   }
  // };
  const handleGetFeeStructure = async () => {
    const res = await getAllFeeStructures();
    if (res.status === 200) {
      const arr = [];
      const feeStructurelist = res.data.data;
      for (let i = 0; i < feeStructurelist?.length; i++) {
        arr.push({
          value: feeStructurelist[i].name,
          label: feeStructurelist[i].name,
          programme: feeStructurelist[i].program,
          studentCategory: feeStructurelist[i].student_category,
          academicYear: feeStructurelist[i].academic_year,
          academicTerm: feeStructurelist[i].academic_term,
          receivableAccount: feeStructurelist[i].receivable_account,
          incomeAccount: feeStructurelist[i].income_account,
          costCenter: feeStructurelist[i].cost_center,
          company: feeStructurelist[i].company,
        });
      }
      setFeeStructureOptions(arr);
    }
  };

  const handleGetFeeSchedule = async () => {
    const res = await getFeeScheduleByName({ name: schedule });
    if (res.status === 200) {
      const feeScheduleDetails = res.data.data;
      if (feeScheduleDetails.docstatus !== 1) {
        setUpdateFeeScheduleFlag(true);
      } else if (feeScheduleDetails.docstatus === 1) {
        setUpdateFeeScheduleFlag(false);
      } else {
        setUpdateFeeScheduleFlag(false);
      }
      console.log("fs", feeScheduleDetails);
      setScheduleId(feeScheduleDetails.name);
      const arr = [];
      const componentsArr = feeScheduleDetails.components;
      console.log("fs", componentsArr, feeCategories);
      for (let i = 0; i < componentsArr.length; i++) {
        const feeCategory = feeCategories.find(
          (category) => category.name === componentsArr[i].fees_category
        );
        console.log("fs", feeCategory);
        arr[i] = {
          key: i,
          no: i + 1,
          feeCategory: feeCategory?.category_name,
          description: feeCategory?.description,
          amount: componentsArr[i].amount,
          action: feeCategory?.category_name,
          hasCustomAmount: componentsArr[i].amount ? true : false,
        };
      }

      const arr2 = [];
      const studentGroupComponentsArr = feeScheduleDetails.student_groups;
      console.log("fs", studentGroupComponentsArr, studentCategoryOptions);
      for (let i = 0; i < studentGroupComponentsArr.length; i++) {
        const studentgroup = studentGroupOptions.find(
          (category) =>
            category.label === studentGroupComponentsArr[i].student_group
        );
        console.log("fs", studentgroup, studentGroupOptions);
        arr2[i] = {
          key: i,
          no: i + 1,
          studentGroup: studentgroup?.label,
          totalStudents: studentgroup?.totalStudents,
          action: "Edit",
          hasCustomAmount: studentGroupComponentsArr[i].amount ? true : false,
        };
      }

      console.log("fs", arr);
      setFormData((prevData) => ({
        ...prevData,
        scheduleName: feeScheduleDetails.custom_fee_schedule_name,
        feeStructure: feeScheduleDetails.fee_structure,
        custom_structure_based_on: feeScheduleDetails.custom_structure_based_on,
        programme: feeScheduleDetails.program,
        studentCategory: feeScheduleDetails.student_category,
        custom_admission_type: feeScheduleDetails.custom_admission_type,
        docstatus: feeScheduleDetails.docstatus,
        fee_creation_status: feeScheduleDetails.fee_creation_status,
        academicYear: feeScheduleDetails.academic_year,
        academicTerm: feeScheduleDetails.academic_term,
        totalamount: feeScheduleDetails.total_amount,
        receivableAccount: feeScheduleDetails.receivable_account,
        incomeAccount: feeScheduleDetails.income_account,
        company: feeScheduleDetails.company,
        costCenter: feeScheduleDetails.cost_center,
        due_date: feeScheduleDetails.due_date,
        posting_date: feeScheduleDetails.posting_date,
        feeCategoryComponents: arr,
        studentGroupComponents: arr2,
      }));
      dispatch(setLoading({ loading: false }));
    } else {
      console.log("error: ", res);
    }
  };

  const handlePopulateFeeStructureData = async (value) => {
    setFormData((prevData) => ({
      ...prevData,
      feeCategoryComponents: [],
    }));
    const res = await getFeeStructure({ name: value });
    if (res.status === 200) {
      const arr = [];
      console.log(res);
      const feeStructurelist = res.data.data;

      console.log("aa", feeStructurelist.components);
      const fieldsToInclude = ["amount", "fees_category"];
      const feeCategoryComponentsArr = feeStructurelist.components.map(
        (item) => {
          const newItem = {};
          fieldsToInclude.forEach((field) => {
            if (field === "fees_category") {
              newItem["feeCategory"] = item[field];
            } else {
              newItem[field] = item[field];
            }
          });
          const descriptionMap = {};
          feeCategoryOptions.forEach((item) => {
            descriptionMap[item.value] = item.description;
          });
          const descriptionObject = feeCategoryOptions.find(
            (obj) => obj.value === item.fees_category
          );
          if (descriptionObject) {
            newItem.description = descriptionObject.description;
          }
          return newItem;
        }
      );

      console.log("aa", feeCategoryComponentsArr);
      setFormData((prevData) => ({
        ...prevData,
        feeCategoryComponents: [...feeCategoryComponentsArr],
      }));
    }
  };

  const handleGetStudentCategoryListing = async () => {
    const res = await getStudentCategoryListing();
    if (res.status === 200) {
      const arr = [];
      const studentCategoryList = res.data.data;
      for (let i = 0; i < studentCategoryList?.length; i++) {
        arr.push({
          value: studentCategoryList[i].name,
          label: studentCategoryList[i].name,
        });
      }

      setStudentCategoryOptions(arr);
    }
  };
  const handleGetProgramListing = async () => {
    const res = await getProgramListing();
    if (res.status === 200) {
      const arr = [];
      const programList = res.data.data;
      for (let i = 0; i < programList?.length; i++) {
        arr.push({
          value: programList[i].name,
          label: programList[i].name,
        });
      }
      setProgramOptions(arr);
    }
  };
  const handleGetAcademicYearListing = async () => {
    const res = await getAcademicYearListing();
    if (res.status === 200) {
      const arr = [];
      const acdemicYearList = res.data.data;
      for (let i = 0; i < acdemicYearList?.length; i++) {
        arr.push({
          value: acdemicYearList[i].name,
          label: acdemicYearList[i].name,
        });
      }
      setAcademicYearOptions(arr);
    }
  };

  const handleGetAcademicTermListing = async () => {
    const res = await getAcademicTermListing();
    if (res.status === 200) {
      const arr = [];
      const acdemicTermList = res.data.data;
      for (let i = 0; i < acdemicTermList?.length; i++) {
        arr.push({
          value: acdemicTermList[i].name,
          label: acdemicTermList[i].name,
        });
      }
      setAcademicTermOptions(arr);
    }
  };

  const handleGetFeeCategoylisting = async () => {
    const res = await getAllFeeCategories();
    if (res.status === 200) {
      const arr = [];
      const feeCategoryList = res.data.data;
      for (let i = 0; i < feeCategoryList?.length; i++) {
        arr.push({
          value: feeCategoryList[i].category_name,
          label: feeCategoryList[i].category_name,
          description: feeCategoryList[i].description,
          amount: feeCategoryList[i].custom_amount,
          hasCustomAmount: feeCategoryList[i].custom_amount ? true : false,
        });
      }
      setFeeCategories(feeCategoryList);
      seFeeCategoryOptions(arr);
    }
  };

  const handleGetReceivableAccountListing = async (companyId) => {
    const res = await getReceivableAccountListing(companyId);
    if (res.status === 200) {
      const arr = [];
      console.log("check this", res);
      const receivableAccountList = res.data.message;
      console.log("check this", receivableAccountList);
      for (let i = 0; i < receivableAccountList?.length; i++) {
        arr.push({
          value: receivableAccountList[i].value,
          label: receivableAccountList[i].value,
        });
      }
      setReceivableAccountOptions(arr);
    }
  };

  const handleGetIncomeAccountListing = async (companyId) => {
    const res = await getIncomeAccountListing(companyId);
    if (res.status === 200) {
      const arr = [];
      const incomeAccountList = res.data.message;
      for (let i = 0; i < incomeAccountList?.length; i++) {
        arr.push({
          value: incomeAccountList[i].value,
          label: incomeAccountList[i].value,
        });
      }
      setIncomeAccountOptions(arr);
    }
  };

  const handleGetCostCenterListing = async (companyId) => {
    const res = await getCostCenterListing(companyId);
    if (res.status === 200) {
      const arr = [];
      const costCenterList = res.data.message;
      for (let i = 0; i < costCenterList?.length; i++) {
        arr.push({
          value: costCenterList[i].value,
          label: costCenterList[i].value,
        });
      }
      setCostCenterOptions(arr);
    }
  };

  const handleGetCollegeListing = async () => {
    const res = await getCollegeListing();
    if (res.status === 200) {
      const arr = [];
      const collegeList = res.data.data;
      for (let i = 0; i < collegeList.length; i++) {
        arr.push({
          value: collegeList[i].name,
          label: collegeList[i].name,
        });
      }
      setCollegeOptions(arr);
    }
  };

  const handleGetStudents = async () => {
    // const res = await getStudentsInFeeSchedule(
    //   formData.academicYear,
    //   formData.programme
    // );
    const res = await getStudentGroupDataFeeSchedule(
      formData.academicYear,
      formData.programme
    );
    console.log("sg,all ok 1 ", res);
    if (res.status === 200) {
      const arr = [];
      console.log("sg", res);
      const studentGroupList = res.data.message;

      for (let i = 0; i < studentGroupList?.length; i++) {
        const val = await getStudentGroupByName(studentGroupList[i]?.value);
        console.log("sg", val, val.data.data.students.length);

        arr.push({
          value: studentGroupList[i]?.value,
          label: studentGroupList[i]?.value,
          totalStudents: val.data.data.students.length,
        });
      }
      console.log("sg", arr);
      setStudentGroups(arr);
      setStudentGroupOptions(arr);
      setFormData((prevData) => ({
        ...prevData,
        forceUpdate: Math.random(),
      }));
    }
  };

  const handleUpdateOrSubmit = async () => {
    try {
      const payload = {
        custom_fee_schedule_name: formData?.schedule_name,
        fee_creation_status: "In Process",
        fee_structure: formData.feeStructure,
        send_email: formData["Send Payment Request Mail"],
        student_category: formData.studentCategory,
        program: formData.programme,
        academic_year: formData.academicYear,
        academic_term: formData.academicTerm,
        company: formData.company,
        due_date: formData.due_date,
        docstatus: 1,
        doctype: "Fee Schedule",
        components: formData.feeCategoryComponents.map((component) => ({
          fees_category: component.feeCategory,
          description: component.discription,
          amount: parseInt(component.amount),
        })),
        student_groups: formData.studentGroupComponents.map((component) => ({
          student_group: component.studentGroup,
          total_students: component.totalStudents,
        })),
      };

      console.log(payload, formData);
      const res = await updateFeeSchedule({ payload, scheduleId });
      if (res.status === 200) {
        //navigate(`/modules/finance/fee-schedule/${res.data.data.name}`);
        toast.success("Fee Schedule Saved!");
        setIsSaved(true);
        console.log(res);
        setScheduleName(res.data.data.name);
        setFormData((prevData) => ({
          ...prevData,
          docstatus: 1,
        }));
      } else {
        toast.error("There is some issue while Updating Fee Schedule");
      }
    } catch (error) {
      toast.error(error.response.data.exception);
    }
  };

  const handleCreateFee = async () => {
    try {
      const payload = { doctype: "Fee Schedule", docname: schedule };

      console.log(payload, formData);
      const res = await createFeeFromFeeSchedule({ payload });
      if (res.status === 200) {
        //navigate(`/modules/finance/fee-schedule/${res.data.data.name}`);
        toast.success("Fee Created");
        setFormData((prevData) => ({
          ...prevData,
          fee_creation_status: "Successful",
        }));
      } else {
        toast.error("There is some issue while creating Fee");
      }
    } catch (error) {
      toast.error(error.response.data.exception);
    }
  };

  useEffect(() => {
    dispatch(setLoading({ loading: true }));

    handleGetFeeStructure();
    handleGetStudentCategoryListing();
    handleGetProgramListing();
    handleGetAcademicYearListing();
    handleGetAcademicTermListing();
    handleGetFeeCategoylisting();
    // handleGetReceivableAccountlisting();
    // handleGetIncomeAccountlisting();
    // handleGetCostCenterlisting();
    handleGetCollegeListing();
  }, []);

  useEffect(() => {
    if (formData.company !== null) {
      console.log("yo");
      handleGetReceivableAccountListing(formData.company);
      handleGetIncomeAccountListing(formData.company);
      handleGetCostCenterListing(formData.company);
    }
  }, [formData.company]);

  useEffect(() => {
    console.log(
      "all ok 1",
      academicYearOptions.length,
      academicTermOptions.length,
      programOptions.length,
      studentCategoryOptions.length,
      feeCategories.length
    );
    if (
      academicYearOptions.length > 0 &&
      academicTermOptions.length > 0 &&
      programOptions.length > 0 &&
      studentCategoryOptions.length > 0 &&
      feeCategories.length > 0
    ) {
      console.log("all ok 1");
      handleGetFeeSchedule();
    }
  }, [
    academicYearOptions,
    academicTermOptions,
    studentGroupOptions,
    programOptions,
    studentCategoryOptions,
    feeCategories,
  ]);

  useEffect(() => {
    handleGetStudents();
  }, [formData.programme, formData.feeStructure]);

  useEffect(() => {
    let tempdata = { ...formConfigi };

    //console.log(tempdata);
    // console.log(
    //   tempdata[0].fields
    //     .find((field) => field.label === "Fee Backup For Each Student")
    //     .fields[0].headers.find((header) => header.title === "Fee Category")
    // );
    tempdata.fields
      .find((field) => field.label === "Fee Backup For Each Student")
      .fields[0].headers.find(
        (header) => header.title === "Fee Category"
      ).render = (val, record, index) => {
      return (
        <>
          <Select
            size={selectSize}
            // placeholder="Select"
            style={{ width: "100%", border: "none !important" }}
            // defaultValue={"Select"}
            options={feeCategoryOptions}
            disabled={!updateFeeScheduleFlag}
            value={
              formData?.feeCategoryComponents[index]?.feeCategory
                ? formData?.feeCategoryComponents[index]?.feeCategory
                : "Select"
            }
            onChange={(value) => {
              const feeCategory = feeCategories.find(
                (category) => category.name === value
              );
              const feeCategoryComponentsArr = formData.feeCategoryComponents;
              feeCategoryComponentsArr[index] = {
                key: Math.floor(Math.random() * 9000) + 1000,
                no: index + 1,
                feeCategory: feeCategory.category_name,
                description: feeCategory.description,
                amount: feeCategory.custom_amount,
                action: "Edit",
                hasCustomAmount: feeCategory.custom_amount ? true : false,
              };
              setFormData((prevData) => ({
                ...prevData,
                feeCategoryComponents: [...feeCategoryComponentsArr],
              }));
            }}
          />
        </>
      );
    };

    tempdata.fields
      .find((field) => field.label === "Fee Backup For Each Student")
      .fields[0].headers.find((header) => header.title === "Amount").render = (
      val,
      record,
      index
    ) => {
      return (
        <>
          {record.hasCustomAmount ? (
            <p>
              {formData?.feeCategoryComponents[index]?.amount
                ? formData?.feeCategoryComponents[index]?.amount
                : ""}
            </p>
          ) : (
            <div className="form-field" style={{ padding: "0px" }}>
              <input
                type="text"
                placeholder="Enter Amount"
                style={{ width: "100%", border: "none" }}
                disabled={!updateFeeScheduleFlag}
                value={formData?.feeCategoryComponents[index]?.amount}
                onChange={(e) => {
                  const feeCategoryComponentsArr = [
                    ...formData.feeCategoryComponents,
                  ];
                  feeCategoryComponentsArr[index] = {
                    ...feeCategoryComponentsArr[index],
                    amount: e.target.value,
                  };

                  setFormData((prevData) => ({
                    ...prevData,
                    feeCategoryComponents: [...feeCategoryComponentsArr],
                  }));
                }}
              />
            </div>
          )}
        </>
      );
    };

    tempdata.fields
      .find((field) => field.label === "Academic Year")
      .fields[0].headers.find(
        (header) => header.title === "Student Group"
      ).render = (val, record, index) => {
      return (
        <>
          <Select
            size={selectSize}
            // placeholder="Select"
            style={{ width: "100%", border: "none !important" }}
            // defaultValue={"Select"}
            options={studentGroupOptions}
            disabled={!updateFeeScheduleFlag}
            value={
              formData?.studentGroupComponents[index]?.studentGroup
                ? formData?.studentGroupComponents[index]?.studentGroup
                : "Select"
            }
            onChange={(value) => {
              const studentGroup = studentGroups.find(
                (group) => group.value === value
              );
              console.log("sg", studentGroup, studentGroups);
              const studentGroupComponentsArr = formData.studentGroupComponents;
              studentGroupComponentsArr[index] = {
                key: Math.floor(Math.random() * 9000) + 1000,
                no: index + 1,
                studentGroup: studentGroup.value,
                totalStudents: studentGroup.totalStudents,
                action: "Edit",
              };
              setFormData((prevData) => ({
                ...prevData,
                studentGroupComponents: [...studentGroupComponentsArr],
              }));
            }}
          />
        </>
      );
    };
  }, [feeCategoryOptions, studentGroupOptions, studentGroups, formData]);

  useEffect(() => {
    let amountPerStudent = formData?.totalAmountPerStudent;
    amountPerStudent = formData.feeCategoryComponents.reduce(
      (accumulator, currentValue) => {
        const amount =
          isNaN(currentValue.amount) || currentValue.amount === undefined
            ? 0
            : parseInt(currentValue.amount, 10);
        return accumulator + amount;
      },
      0
    );

    let totalStudents = formData?.totalStudents;
    totalStudents = formData?.studentGroupComponents.reduce(
      (accumulator, currentValue) => {
        const amount =
          isNaN(currentValue?.totalStudents) ||
          currentValue?.totalStudents === undefined
            ? 0
            : parseInt(currentValue.totalStudents, 10);
        return accumulator + amount;
      },
      0
    );

    totalStudents = totalStudents > 1 ? totalStudents : 1;
    amountPerStudent = amountPerStudent > 0 ? amountPerStudent : 0;
    const grandTotal = amountPerStudent * totalStudents;
    let numInWords = "";
    if (!isNaN(grandTotal)) {
      const toWords = new ToWords();

      numInWords = toWords.convert(grandTotal);
    }
    setFormData((prevData) => ({
      ...prevData,
      totalAmountPerStudent: amountPerStudent,
      grandTotal: grandTotal,
      inWords: numInWords,
    }));
  }, [formData.feeCategoryComponents, formData.studentGroupComponents]);

  useEffect(() => {
    console.log(feeCategorySelectedRowKeys, formData.feeCategoryComponents);
    console.log(" ", formData);
  }, [formData]);
  const RadioBox = ({ field }) => {
    const { label, name, options } = field;
    return (
      <div className="form-field">
        <div className="radio-option">
          <span>
            <input
              type="radio"
              id="active"
              name={name}
              disabled={!updateFeeScheduleFlag}
              value="active"
              checked={formData[name] === "active"}
              style={{ accentColor: "#A32930" }}
              onChange={handleRadioButtonChange}
            />
            <label htmlFor="Active">Active</label>
          </span>
          <span>
            <input
              type="radio"
              id="inactive"
              name={name}
              value="inactive"
              checked={formData[name] === "inactive"}
              style={{ accentColor: "#A32930" }}
              onChange={handleRadioButtonChange}
            />
            <label htmlFor="Inactive">Inactive</label>
          </span>
        </div>
      </div>
    );
  };

  const Checkbox = ({ field }) => {
    const { label, name, options } = field;
    return (
      <div className="form-field fullWidthFields">
        {options.map((option) => (
          <div key={option.key} className="formCheckbox">
            <input
              type="checkbox"
              id={`${name}_${option.key}`}
              name={option.value}
              disabled={!updateFeeScheduleFlag}
              checked={formData[option.value] == 0 ? false : true}
              onChange={(e) => {
                setFormData((prevData) => ({
                  ...prevData,
                  [name]: e.target.checked,
                }));
              }}
              style={{ accentColor: "#A32930" }}
            />
            <label htmlFor={`${name}_${option.key}`}>{option.key}</label>
          </div>
        ))}
      </div>
    );
  };

  const renderFormField = (field) => {
    const { name, label, type, options, required, placeholder } = field;
    const value = formData[name] || "";
    const fieldError = fieldErrors[name];
    const fieldname = field.fieldname;
    const selectedRow =
      label === "Academic Year"
        ? studentGroupRowSelection
        : feeCategoryRowSelection;
    const datasource =
      label === "Academic Year"
        ? formData.studentGroupComponents
        : formData.feeCategoryComponents;
    const handleSelectChange = (event) => {
      const selectedOption = event.target.value;
      setFormData((prevData) => ({
        ...prevData,
        [name]: selectedOption,
      }));
    };

    // Check if the field is applicable to the current entityType

    const isRequired = field.required ? "required" : "";

    switch (type) {
      case "text":
        return (
          <div className="form-field" key={name}>
            <label dangerouslySetInnerHTML={{ __html: field.label }} />
            <input
              type="text"
              name={name}
              placeholder={placeholder}
              value={formData[fieldname] ? formData[fieldname] : value}
              required={required}
              disabled={!updateFeeScheduleFlag}
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  [name]: e.target.value,
                }))
              }
              onBlur={() => {
                const isValid = validateField(
                  formData[fieldname] ? formData[fieldname] : value,
                  field
                );
                setFieldErrors((prevErrors) => ({
                  ...prevErrors,
                  [name]: isValid
                    ? undefined
                    : `Mandatory field '${label}' is required`,
                }));
              }}
              {...isRequired}
            />
            {fieldError && <div className="error-message">{fieldError}</div>}
          </div>
        );
      case "phone":
        return (
          <div className="form-field" key={name}>
            <label dangerouslySetInnerHTML={{ __html: field.label }} />
            <input
              type="text"
              name={name}
              placeholder={label}
              value={value}
              disabled={!updateFeeScheduleFlag}
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  [name]: e.target.value,
                }))
              }
              onBlur={() => {
                const isValid = validateField(value, field);
                setFieldErrors((prevErrors) => ({
                  ...prevErrors,
                  [name]: isValid
                    ? undefined
                    : `Mandatory field '${label}' is required or invalid phone number`,
                }));
              }}
              {...isRequired}
            />
            {fieldError && <div className="error-message">{fieldError}</div>}
          </div>
        );
      case "col-break":
        return <div className="col-break" key={name}></div>;
      case "heading":
        return (
          <>
            <div className="col-break" key={name}></div>

            <p className={styles.title}>{label}</p>
          </>
        );
      case "table":
        console.log("sg", datasource);

        return (
          <div className="w-100">
            <div className="mb-5">
              <label>{label}</label>
            </div>
            <Table
              rowSelection={{ type: "checkbox", ...selectedRow }}
              columns={field.fields[0].headers}
              dataSource={datasource}
              size="small"
              pagination={false}
            />
            <div className={styles.fieldButtons}>
              {field.selectedRow.length > 0 && (
                <div
                  className={styles.deleteRow}
                  onClick={
                    field.label === "Academic Year"
                      ? handleDeleteStudentGroup
                      : handleDeleteFeeCategory
                  }
                >
                  Delete
                </div>
              )}
              <div
                className={styles.addRow}
                onClick={
                  field.label === "Academic Year"
                    ? handleAddStudentGroup
                    : handleAddFeeCategory
                }
              >
                +
              </div>
            </div>
          </div>
        );
      case "checkbox":
        return <Checkbox field={field} />;
      case "radio":
        return <RadioBox field={field} />;
      case "select":
        // const handleSelectChange = (event) => {
        //   const selectedOption = event.target.value;
        //   setFormData({ ...formData, [name]: selectedOption });
        // };

        return (
          <div className="form-field">
            <label>{label}</label>
            <Select
              size={selectSize}
              placeholder="Select"
              defaultValue={"Select"}
              className={styles.selectInput}
              disabled={!updateFeeScheduleFlag}
              options={options}
              value={formData[fieldname]}
              onChange={(value) => {
                if (name === "fee_structure") {
                  handlePopulateFeeStructureData(value);
                  let temp = formData;
                  let data = options.find((obj) => obj.value === value);
                  temp.studentCategory = data.studentCategory
                    ? data.studentCategory
                    : formData.studentCategory
                    ? formData.studentCategory
                    : "Select";
                  temp.academicTerm = data.academicTerm
                    ? data.academicTerm
                    : formData.academicTerm
                    ? formData.academicTerm
                    : "Select";
                  temp.academicYear = data.academicYear
                    ? data.academicYear
                    : "Select";
                  temp.company = data.company ? data.company : null;
                  temp.costCenter = data.costCenter ? data.costCenter : null;
                  temp.incomeAccount = data.incomeAccount
                    ? data.incomeAccount
                    : null;
                  temp.programme = data.programme ? data.programme : null;
                  temp.receivableAccount = data.receivableAccount
                    ? data.receivableAccount
                    : null;

                  setFormData((prevData) => ({
                    ...prevData,
                    ...temp,
                  }));
                }
                setFormData((prevData) => ({
                  ...prevData,
                  [fieldname]: value,
                }));
                setFormConfigi({ ...formConfigi, type: "ignore" });
              }}
            />

            {fieldError && <div className="error-message">{fieldError}</div>}
          </div>
        );

      case "date":
        return (
          <div className="form-field" key={name}>
            <label dangerouslySetInnerHTML={{ __html: field.label }} />
            <input
              type="date"
              name={name}
              className={styles.dateInput}
              disabled={!updateFeeScheduleFlag}
              value={value}
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  [name]: e.target.value,
                }))
              }
              onBlur={() => {
                const isValid = validateField(value, field);
                setFieldErrors((prevErrors) => ({
                  ...prevErrors,
                  [name]: isValid ? undefined : `${label} is empty`,
                }));
              }}
            />
            {fieldError && <div className="error-message">{fieldError}</div>}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <MainHeader />
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/dashboard")}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/finance")}
            >
              Finance {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={handleFeeScheduleNavigation}
            >
              Fee Schedule
            </p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.maintitle}>View/Edit Fee Schedule</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div
                className={styles.button}
                onClick={handleFeeScheduleNavigation}
              >
                <p className={styles.buttonText}>Back</p>
              </div>
              {formData.docstatus === 0 && (
                <div
                  className={styles.activeButton}
                  onClick={handleUpdateOrSubmit}
                >
                  <p className={styles.activeButtonText}>Submit</p>
                </div>
              )}
              {formData.docstatus === 1 &&
                formData.fee_creation_status !== "Successful" && (
                  <div className={styles.activeButton}>
                    <p
                      className={styles.activeButtonText}
                      onClick={handleCreateFee}
                    >
                      Create Fee
                    </p>
                  </div>
                )}{" "}
            </div>
          </div>
          <div className="formApplication">
            <section className="form-section">
              <div className="fields-block">
                {formConfigi?.fields.map((field) => {
                  if (field.type === "select") {
                    if (field.name === "student_category") {
                      field.options = studentCategoryOptions
                        ? studentCategoryOptions
                        : [""];
                    } else if (field.name === "fee_structure") {
                      field.options = feeStructureOptions
                        ? feeStructureOptions
                        : [""];
                    } else if (field.name === "programme") {
                      field.options = programOptions ? programOptions : [""];
                    } else if (field.name === "academic_year") {
                      field.options = academicYearOptions
                        ? academicYearOptions
                        : [""];
                    } else if (field.name === "academic_term") {
                      field.options = academicTermOptions
                        ? academicTermOptions
                        : [""];
                    } else if (field.name === "receivable_account") {
                      field.options = receivableAccountOptions
                        ? receivableAccountOptions
                        : [""];
                    } else if (field.name === "income_account") {
                      field.options = incomeAccountOptions
                        ? incomeAccountOptions
                        : [""];
                    } else if (field.name === "cost_center") {
                      field.options = costCenterOptions
                        ? costCenterOptions
                        : [""];
                    } else if (field.name === "college/university") {
                      field.options = collegeOptions ? collegeOptions : [""];
                    } else {
                      field.options = [
                        { value: "option 11", name: "option 1" },
                        { value: "option 22", name: "option 2" },
                      ];
                    }
                  } else if (field.name === "Fee Backup For Each Student") {
                    field.rowSelection = feeCategoryRowSelection
                      ? feeCategoryRowSelection
                      : [""];
                    field.dataSource = formData.feeCategoryComponents
                      ? formData.feeCategoryComponents
                      : [""];
                    field.selectedRow = feeCategorySelectedRowKeys
                      ? feeCategorySelectedRowKeys
                      : [""];
                  } else {
                    field.rowSelection = studentGroupRowSelection
                      ? studentGroupRowSelection
                      : [""];
                    field.dataSource = formData.studentGroupComponents
                      ? formData.studentGroupComponents
                      : [""];
                    field.selectedRow = studentGroupSelectedRowKeys
                      ? studentGroupSelectedRowKeys
                      : [""];
                  }

                  if (field.type === "ignore") {
                    return;
                  }

                  return renderFormField(field);
                })}
                {/* <div className={styles.field}>
                  <p className={styles.fieldLabel}>Structure Based On *</p>
                  <Select className={styles.fieldSelectInput} value="">
                    <MenuItem disabled value="" selected>
                      Select
                    </MenuItem>
                  </Select>
                </div>
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>Academic Year *</p>
                  <Select className={styles.fieldSelectInput} value="">
                    <MenuItem disabled value="" selected>
                      Select
                    </MenuItem>
                  </Select>
                </div>
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>Academic Term *</p>
                  <Select className={styles.fieldSelectInput} value="">
                    <MenuItem disabled value="" selected>
                      Select
                    </MenuItem>
                  </Select>
                </div> */}
              </div>
            </section>
          </div>
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default GetUpdateFeeSchedule;

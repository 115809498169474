import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "iiht-b2a-ui-components/build/components";
import "./CustomBreadcrumb.scss";

const CustomBreadcrumb = ({ breadcrumbs = [] }) => {
  const navigate = useNavigate();
  const breadcrumbsLength = breadcrumbs.length;

  return (
    <div className="custom-breadcrumbs">
      {breadcrumbs.map((breadcrumb, index) => (
        <React.Fragment key={index}>
          {index > 0 && <span className="separator"> &gt; </span>}
          <Typography
            onClick={() => navigate(breadcrumb.link, { state: breadcrumb?.state })}
            sx={{
              display: "inline-flex",
              cursor: index !== breadcrumbsLength - 1 && "pointer",
              "&:hover": {
                color: index !== breadcrumbsLength - 1 && "#AC0521",
              },
            }}
            fontSize={12}
            color="#6F6F6F"
          >
            {breadcrumb.label}
          </Typography>
        </React.Fragment>
      ))}
    </div>
  );
};

export default CustomBreadcrumb;

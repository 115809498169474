import React, { useEffect, useState } from "react";
import eventBus from "../../core/eventBus";
import { BottomSheetComp } from "../BottomSheet/";

const BottomSheetConnector = () => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState(null);
    
  useEffect(()=>{
    eventBus.on("open-bottomSheet", (jsxElement) => {
      setContent(jsxElement);
      setOpen(true);
    });

    eventBus.on("close-bottomSheet", () => {
      setOpen(false);
    });
  
    // Clean up the event listener
    return () => {
      eventBus.off("open-bottomSheet");
    };
  }, []);

  const toggleVisibility = (value)=>{
    setOpen(value);
  };
  return (
    <>
      {open && <BottomSheetComp open={open} toggleVisibility={toggleVisibility}>
        {content}
      </BottomSheetComp>}
    </>
  );
};

export default BottomSheetConnector;

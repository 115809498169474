import React from 'react';

const GraphSvg = ({ fill, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" fill={fill}>
      <path d="M5 35v-3.208L7.792 29v6Zm6.792 0v-9.875l2.791-2.792V35Zm6.833 0V22.333l2.75 2.834V35Zm6.792 0v-9.833l2.791-2.792V35Zm6.791 0V18.458L35 15.667V35ZM5 25.583v-3.958l11.667-11.583 6.666 6.666L35 5v3.917L23.333 20.625l-6.666-6.667Z" />
    </svg>
  );
};

export default GraphSvg;

export const formConfig = [
  {
    entityType: "FeeSchedule",
    fields: [
      {
        label: "Fee Structure *",
        name: "fee_structure",
        fieldname: "feeStructure",
        type: "select",
        placeholder: "Select",
        required: "required",
      },
      {
        label: "Student Category *",
        name: "student_category",
        type: "select",
        fieldname: "studentCategory",
        placeholder: "Select",
        required: "required",
      },
      {
        label: "Programme *",
        name: "programme",
        type: "select",
        fieldname: "programme",
        placeholder: "Programme",
        required: "required",
      },
      {
        label: "Posting Date *",
        name: "posting_date",
        fieldname: "postingDate",
        type: "date",
        placeholder: "Posting Date",
        required: "required",
      },
      {
        label: "Due Date *",
        name: "due_date",
        fieldname: "dueDate",
        type: "date",
        placeholder: "Due Date",
        required: "required",
      },
      {
        label: "Academic Year *",
        name: "academic_year",
        fieldname: "academicYear",
        type: "select",
        placeholder: "Academic Year",
        required: "required",
      },
      {
        label: "Academic Term *",
        name: "academic_term",
        fieldname: "academicTerm",
        type: "select",
        placeholder: "Academic Term",
        required: "required",
      },

      {
        label: "Schedule Name *",
        name: "schedule_name",
        fieldname: "scheduleName",
        type: "text",
        placeholder: "ex B.Tech - CS01Sem",
        required: "required",
      },
      {
        type: "col-break",
      },
      {
        type: "checkbox",
        name: "Send Payment Request Mail",
        options: [
          {
            key: "Send Payment Request Mail",
            value: "Send Payment Request Mail",
          },
        ],
      },

      {
        label: "Actice",
        name: "Active/Inactive",
        type: "radio",
        options: ["Active", "Inactive"],
      },
      {
        type: "col-break",
      },
      {
        label: "Academic Year",
        type: "table",
        name: "Academic Year",
        fields: [
          {
            headers: [
              {
                title: "No.",
                dataIndex: "no",
                width: "15%",
              },
              {
                title: "Student Group",
                dataIndex: "student_group",
                width: "35%",
              },
              {
                title: "Total Students",
                dataIndex: "totalStudents",
                width: "30%",
              },
              {
                title: "Action",
                dataIndex: "action",
                width: "20%",
              },
            ],
            type: "table",
            name: "Academic Year",
            minRows: 5, // Minimum number of rows
            name: "Academic Year",
          },
        ],
      },
      {
        type: "col-break",
      },
      {
        label: "Fee Backup For Each Student",
        type: "table",
        name: "Fee Backup For Each Student",
        fields: [
          {
            headers: [
              {
                title: "No.",
                dataIndex: "no",
                width: "10%",
              },
              {
                title: "Fee Category",
                dataIndex: "fee_category",
                width: "25%",
              },
              {
                title: "Description",
                dataIndex: "description",
                width: "25%",
              },
              {
                title: "Amount",
                dataIndex: "amount",
                width: "25%",
              },

              {
                title: "Action",
                dataIndex: "action",
                width: "15%",
              },
            ],
            type: "table",
            name: "Academic Year",
            minRows: 5, // Minimum number of rows
            name: "Academic Year",
          },
        ],
      },

      {
        label: "Total Amount Per Student *",
        name: "total_amount_per_student",
        fieldname: "totalAmountPerStudent",
        type: "text",
        placeholder: "Total Amount Per Student",
        required: "required",
      },
      {
        label: "Grand Total *",
        name: "grand_total",
        fieldname: "grandTotal",
        type: "text",
        placeholder: "Grand Total",
        required: "required",
      },
      {
        label: "In Words *",
        name: "in_words",
        fieldname: "inWords",
        type: "text",
        placeholder: "In Words",
        required: "required",
      },
      {
        label: "Account's Mapping",
        name: "Account's Mapping",
        type: "heading",
      },
      {
        type: "col-break",
      },
      {
        label: "Receivable Account *",
        name: "receivable_account",
        fieldname: "receivableAccount",
        type: "select",
        placeholder: "Receivable Account",
        required: "required",
      },
      {
        label: "College/University *",
        name: "college/university",
        fieldname: "company",
        type: "select",
        placeholder: "College/University",
        required: "required",
      },
      {
        label: "Income Account *",
        name: "income_account",
        fieldname: "incomeAccount",
        type: "select",
        placeholder: "Income Account",
        required: "required",
      },
      {
        label: "Account's Dimensions",
        name: "Account's Dimensions",
        type: "heading",
      },
      {
        type: "col-break",
      },
      {
        label: "Cost Center *",
        name: "cost_center",
        fieldname: "costCenter",
        type: "select",
        placeholder: "Cost Center",
        required: "required",
      },
    ],
  },
];

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";  
import { Breadcrumbs, MainLayout } from "components/common";
import {
  createLeaveControlPanel,
  getLeaveType,
  getEmployee,
  getInstitution,
  getDepartment,
  getDesignation,
  getEmploymentType,
  getLocation,
  getEmployeeGrade,
  getLeavePeriod,
  getLeavePolicy,
  getLeaveControlPanel
} from "modules/hr";
import { Input, Button, Table, message, Checkbox, Select } from "antd"; 
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "slices/commonSlice";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import useStyles from '../style.js';


const HolidayListDetails = () => {
  const styles= useStyles();
  const loading = useSelector((state) => state.common.loading);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [holidayList, setHolidayList] = useState({
    dates_based_on: "",
    leave_period: "",
    from_date: "",
    to_date:"",
    carry_forward: "",
    allocate_based_on_leave_policy:"",
    doctype: "Leave Control Panel",
    leave_policy: "",
    leave_type: "",
    no_of_days:"",
    company: "",
    department: "", 
    employment_type: "", 
    branch: "", 
    designation: "", 
    employee_grade: "",
    selectedRows:[]
  });

  const [isViewMode, setIsViewMode] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [ leaveType, setLeaveType ] = useState([]);
  const [ employee, setEmployee ] = useState([]); 
  const [ institution,setInstitution ] = useState([]);
  const [ modifiedDate,setModifiedDate ] = useState([]);
  const [ departmentOptions,setDepartmentOptions ] = useState([]);
  const [ institutionOptions,setInstitutionOptions ] = useState([]);
  const [ employmentTypeOptions,setEmploymentTypeOptions ] = useState([]);
  const [ locationOptions,setLocationOptions ] = useState([]);
  const [ designationOptions,setDesignationOptions ] = useState([]);
  const [ leavePeriodOptions,setLeavePeriodOptions ] = useState([]);
  const [ employeeGradeOptions,setEmployeeGradeOptions ] = useState([]);
  const [showEarnedLeave, setShowEarnedLeave] = useState(false);
  const [ leavePolicyOptions,setLeavePolicyOptions ] = useState([]);
  const [ rows,setRows ] = useState([])


  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;
    const updatedRows = rows.map(row => ({
      ...row,
      checked: checked
    }));
    setRows(updatedRows);
    setHolidayList(prevState => ({
      ...prevState,
      selectedRows: checked ? rows : [],
    }));
    setSelectAll(checked);
  };
  

  const handleCheckboxChange = (index, checked) => {
    const updatedRows = [...rows];
    updatedRows[index].checked = checked;
    setRows(updatedRows);
  };

  
  const convertFiltersToQueryString = (filters) => {
    const filterString = Object.entries(filters)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `["${key}", "=", "${value}"]`)
      .join(",");
      return filterString ? `[${filterString}]` : "";
  };

  const getEmployeeData = async () => {
    dispatch(setLoading({ loading: true }));
    try {
      const filters = {
        company: holidayList.company,
        department: holidayList.department,
        employment_type: holidayList.employment_type,
        branch: holidayList.branch,
        designation: holidayList.designation,
        employee_grade: holidayList.employee_grade
      };
      const queryString = convertFiltersToQueryString(filters);
      const filteredEmployeeData = await getEmployee({queryString});
      setHolidayList(prevState => ({
        ...prevState,
        holidays: Array.isArray(filteredEmployeeData) ? filteredEmployeeData.data.data : []
      }));
      setRows(filteredEmployeeData.data.data);
    } catch (error) {
      console.error("Error fetching filtered employee data:", error);
      message.error("Failed to fetch filtered employee data. Please try again.");
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  useEffect(() => {
    getEmployeeData();
  }, [holidayList.company, 
    holidayList.department, 
    holidayList.employment_type, 
    holidayList.branch, 
    holidayList.designation, 
    holidayList.employee_grade]);

  const handleGetLeaveType = async() => {
    
    const payload = {
      doctype:"Leave Type",
    }
      const res = await getLeaveType( payload );
      if (res.status === 200) {
        const arr = [];
        for(let i = 0; i < res.data.data.length ; i++) {
          arr.push({
            value: res.data.data[i].name,
            label: res.data.data[i].name
          })
        }
  
        setLeaveType(arr);
      }
    };

    const handleGetLeaveControl = async() => {
    
      const payload = {
        doctype:"Leave Control Panel",
      }
        const res = await getLeaveControlPanel( payload );
        if (res.status === 200) {
          const modifiedField = res.data.message.find(item => item.field === 'modified');
            if (modifiedField) {
                setModifiedDate(modifiedField.value);
            } else {
                console.log("Modified field not found in the response.");
            }
        }
      };
      

    const handleGetEmployee = async() => {
    
        const payload = {
          doctype:"Employee",
        }
          const res = await getEmployee( payload );
          if (res.status === 200) {
            const arr = [];
            for(let i = 0; i < res.data.data.length ; i++) {
              arr.push({
                value: res.data.data[i].name,
                label: res.data.data[i].name
              })
            }
      
            setEmployee(arr);
          }
        };

          const handleGetDepartement = async () => {
            const res = await getDepartment();
            if(res.status === 200) {
              const arr = [];
              const departmentList = res.data.data;
              for(let i = 0 ; i < departmentList.length ; i++) {
                arr.push({
                  value: departmentList[i].name,
                  label: departmentList[i].name,
                });
              };
              setDepartmentOptions(arr);
            };
          };
        
          const handleGetDesignation = async () => {
            const res = await getDesignation();
            if(res.status === 200) {
              const arr = [];
              const designationList = res.data.data;
              for(let i = 0 ; i < designationList.length ; i++) {
                arr.push({
                  value: designationList[i].name,
                  label: designationList[i].name,
                });
              };
              setDesignationOptions(arr);
            };
          };
        
          const handleGetInstitution = async () => {
            const res = await getInstitution();
            if(res.status === 200) {
              const arr = [];
              const institutionList = res.data.data;
              for(let i = 0 ; i < institutionList.length ; i++) {
                arr.push({
                  value: institutionList[i].name,
                  label: institutionList[i].name,
                });
              };
              setInstitutionOptions(arr);
            };
          };
        
          const handleGetLocation = async () => {
            const res = await getLocation();
            if(res.status === 200) {
              const arr = [];
              const locationList = res.data.data;
              for(let i = 0 ; i < locationList.length ; i++) {
                arr.push({
                  value: locationList[i].name,
                  label: locationList[i].name,
                });
              };
              setLocationOptions(arr);
            };
          };
        
          const handleGetEmploymentType = async () => {
            const res = await getEmploymentType();
            if(res.status === 200) {
              const arr = [];
              const employmentTypeList = res.data.data;
              for(let i = 0 ; i < employmentTypeList.length ; i++) {
                arr.push({
                  value: employmentTypeList[i].name,
                  label: employmentTypeList[i].name,
                });
              };
              setEmploymentTypeOptions(arr);
            };
          };

          const handleGetEmployeeGrade= async () => {
            const res = await getEmployeeGrade();
            if(res.status === 200) {
              const arr = [];
              const employmentTypeList = res.data.data;
              for(let i = 0 ; i < employmentTypeList.length ; i++) {
                arr.push({
                  value: employmentTypeList[i].name,
                  label: employmentTypeList[i].name,
                });
              };
              setEmployeeGradeOptions(arr);
            };
          };

          const handleGetLeavePeriod= async () => {
            const payload = {
                doctype:"Leave Period"
            }
            const res = await getLeavePeriod( payload );
            if(res.status === 200) {
              const arr = [];
              const employmentTypeList = res.data.data;
              for(let i = 0 ; i < employmentTypeList.length ; i++) {
                arr.push({
                  value: employmentTypeList[i].name,
                  label: employmentTypeList[i].name,
                });
              };
              setLeavePeriodOptions(arr);
            };
          };

          const handleGetLeavePolicy= async () => {
            const payload = {
                doctype:"Leave Period"
            }
            const res = await getLeavePolicy( payload );
            if(res.status === 200) {
              const arr = [];
              const employmentTypeList = res.data.data;
              for(let i = 0 ; i < employmentTypeList.length ; i++) {
                arr.push({
                  value: employmentTypeList[i].name,
                  label: employmentTypeList[i].name,
                });
              };
              setLeavePolicyOptions(arr);
            };
          };

    useEffect(() =>{
        handleGetLeaveType();
        handleGetEmployee();
        handleGetInstitution();
        handleGetDepartement();
        handleGetDesignation();
        handleGetEmploymentType();
        handleGetInstitution();
        handleGetLocation();
        handleGetEmployeeGrade();
        handleGetLeavePeriod();
        handleGetLeavePolicy();
        handleGetLeaveControl();    
    },[])

    const handleFieldChange = (name, value) => {
        setHolidayList((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };

      const updateHolidayListWithSelectedRows = () => {
        const selectedRows = rows.filter(row => row.checked);
        console.log()
        setHolidayList(prevState => ({
          ...prevState,
          selectedRows: selectedRows,
        }));
      };
      
      const selectedEmployeeIds = holidayList.selectedRows.map(row => row.name);

      

      const handleSave = async () => {
        try {
          const doctype = "Leave Control Panel";
          const validationErrors = validateFields();
      
          if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
          }
          
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        const getEmail = (user) => {
          return user.name !== "Administrator" ? user.email : "Administrator";
        };

        const payload = {
          employees: selectedEmployeeIds,
          docs: {
            name: "Leave Control Panel",
            owner: getEmail(currentUser),
            modified: modifiedDate,
            modified_by: getEmail(currentUser),
            docstatus: 0,
            idx: "0",
            dates_based_on: holidayList.dates_based_on,
            from_date: holidayList.from_date,
            to_date: holidayList.to_date,
            carry_forward: holidayList.carry_forward,
            allocate_based_on_leave_policy: holidayList.allocate_based_on_leave_policy ? 1 : 0,
            no_of_days: holidayList.no_of_days,
            company: holidayList.company,
            doctype: "Leave Control Panel",
            leave_period: holidayList.leave_period,
            leave_policy: holidayList.leave_policy,
            designation: holidayList.designation,
            __unsaved: 1
          },
          method: "allocate_leave",
          args: {
            employees: selectedEmployeeIds
          }
        };
      
          const response = await createLeaveControlPanel( {payload} )
          if (response.status === 200) {
            message.success("Leave Control is Allocated");
            navigate(-1);
          } else {
            throw new Error("Failed to save leave control. Please try again.");
          }
        } catch (error) {
          console.error("Error saving holiday list:", error);
          message.error(
            error.message || "Failed to save leave control. Please try again."
          );
        }
      };
      
      

  // Function to validate required fields
  const validateFields = () => {
    const errors = {};

    if (!holidayList.dates_based_on.trim()) {
      errors.dates_based_on = "Date based is required";
    }

    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHolidayList((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddHoliday = () => {
    // Append a new holiday to the holidays array
    const newHoliday = {
      id: "",
      holiday_date: "",
      description: "",
      weekly_off: "",
    };
    setHolidayList((prevState) => ({
      ...prevState,
      holidays: [...prevState.holidays, newHoliday],
    }));
  };

  const handleHolidayChange = (index, e) => {
    const { name, value } = e.target;
    const updatedHolidays = [...holidayList.holidays];
    updatedHolidays[index][name] = value;
    setHolidayList((prevState) => ({
      ...prevState,
      holidays: updatedHolidays,
    }));
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const columns = [
    {
      title: () => (
        <Checkbox checked={selectAll} onChange={handleSelectAllChange} />
      ),
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => (
        <Checkbox
          checked={record.checked}
          onChange={(e) => {
            handleCheckboxChange(index, e.target.checked)
            updateHolidayListWithSelectedRows();
          }}
        />
      ),
    },
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "EMPLOYEE NAME",
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: "EMPLOYEE ID",
      dataIndex: "name", 
      key: "name",
    },
    {
      title: "INSTITUTION",
      dataIndex: "company", 
      key: "company",
    },
    {
      title: "DEPARTMENT",
      dataIndex: "department", 
      key: "department",
    },
  ];
  
  return (
    <MainLayout>
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <>
          <Breadcrumbs />
          <div className="master-heading">
            <h2>{isViewMode ? "View Holiday List" : "Leave Control Panel"}</h2>
            <div className="btnsGrp">
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                back
              </Button>
              <Button
              onClick={handleSave}
              variant="contained"
              color="primary"
              className="redBtn"
            >
              Allocate Leave
            </Button>
            </div>
          </div>
          <div className="formApplication">
            <section className="form-section">
              <div className="fields-block">
                <div className="form-field">
                  <label>Dates Based On *</label>
                  <Select
                    name="dates_based_on"
                    placeholder="Dates Based On"
                    value={holidayList.dates_based_on}
                    onChange={(value) => handleFieldChange("dates_based_on", value)}
                  >
                    <Select.Option value="Leave Period">Leave Period</Select.Option>
                    <Select.Option value="Joining Date">Joining Date</Select.Option>
                    <Select.Option value="Custom Range">Custom Range</Select.Option>
                  </Select>
                  {errors.dates_based_on && (
                    <span className="error-message">
                      {errors.holiday_list_name}
                    </span>
                  )}
                </div>
            </div>
        </section>
                <div className="form-field col-break"></div>
                <section className="form-section">

                    {holidayList.dates_based_on && (
                        <div>
                        {holidayList.dates_based_on === "Leave Period" && (
                            <>
                            <div className="fields-block">
                            <div className="form-field">
                            <label>Leave Period *</label>
                            <Select
                                options={leavePeriodOptions}
                                name="leave_period"
                                placeholder="Leave Period"
                                value={holidayList.leave_period}
                                onChange={(value) => handleFieldChange("leave_period", value)}
                            />
                            {errors.total_holidays && (
                                <span className="error-message">
                                {errors.total_holidays}
                                </span>
                            )}
                            </div>
                            <div className="form-field">
                            <label>From Date *</label>
                            <Input
                                type="date"
                                name="from_date"
                                placeholder="YYYY-MM-DD"
                                value={holidayList.from_date}
                                onChange={handleChange}
                            />
                            {errors.from_date && (
                                <span className="error-message">{errors.from_date}</span>
                            )}
                            </div>
                        </div>
                        </>
                        )}

                        {holidayList.dates_based_on === "Joining Date" && (
                            <div className="fields-block">
                                <div className="form-field">
                                <label>To Date *</label>
                                <Input
                                    type="date"
                                    name="to_date"
                                    placeholder="YYYY-MM-DD"
                                    value={holidayList.to_date}
                                    onChange={handleChange}
                                />
                                {errors.to_date && (
                                    <span className="error-message">{errors.to_date}</span>
                                )}
                                </div>
                            </div>
                        )}

                        {holidayList.dates_based_on === "Custom Range" && (
                            <>
                            <div className="fields-block">
                                <div className="form-field">
                                    <label>From Date *</label>
                                    <Input
                                    type="date"
                                    name="from_date"
                                    placeholder="YYYY-MM-DD"
                                    value={holidayList.from_date}
                                    onChange={handleChange}
                                    />
                                    {errors.from_date && (
                                    <span className="error-message">{errors.from_date}</span>
                                    )}
                                </div>
                                <div className="form-field">
                                    <label>To Date *</label>
                                    <Input
                                    type="date"
                                    name="to_date"
                                    placeholder="YYYY-MM-DD"
                                    value={holidayList.to_date}
                                    onChange={handleChange}
                                    />
                                    {errors.to_date && (
                                    <span className="error-message">{errors.to_date}</span>
                                    )}
                                </div>
                            </div>
                            </>
                        )}
                        </div>
                    )}
            </section>
            <div className={styles.formContainer}>
                <div className={styles.field}>
                    <div className={styles.checkboxContainer}>
                    <input
                    type="checkbox"
                    name="allocate_based_on_leave_policy"
                    checked={holidayList.allocate_based_on_leave_policy}
                    onChange={(e) => {
                        const checked = e.target.checked;  
                        setHolidayList((prevState) => ({
                        ...prevState,
                        allocate_based_on_leave_policy: checked,  
                        }));
                        setShowEarnedLeave(checked);
                    }}
                    />
                    <p className={styles.fieldLabel}>Allocate Based on Leave Policy</p>
                    </div>
                </div>
                </div>
                <section className="form-section">
            <div className="fields-block">
                {showEarnedLeave  ? (
               <div className="form-field">
               <label>Leave Policy *</label>
               <Select
                   options={leavePolicyOptions}
                   name="leave_policy"
                   placeholder="Leave Policy"
                   value={holidayList.leave_policy}
                   onChange={(value) => handleFieldChange("leave_policy", value)}
               />
               {errors.leave_policy && (
                   <span className="error-message">{errors.leave_policy}</span>
               )}
               </div>
                ) : (
                <React.Fragment>
                    <div className="form-field">
                    <label>Leave Type *</label>
                    <Select
                        options={leaveType}
                        name="leave_type"
                        placeholder="Leave Type"
                        value={holidayList.leave_type}
                        onChange={(value) => handleFieldChange("leave_type", value)}
                    />
                    {errors.leave_type && (
                        <span className="error-message">{errors.leave_type}</span>
                    )}
                    </div>
                    <div className="form-field">
                    <label>New Leaves Allocated (In Days) *</label>
                    <Input
                        type="text"
                        name="no_of_days"
                        value={holidayList.no_of_days}
                        placeholder="New Leaves Allocated"
                        onChange={handleChange}
                    />
                    {errors.no_of_days && (
                        <span className="error-message">{errors.no_of_days}</span>
                    )}
                    </div>
                </React.Fragment>
                )}
            </div>
            </section>
            <section className="form-section">
              <h2>Quick Filters</h2>
              <hr className={styles.line}></hr>
              <div className="fields-block">
                <div className="form-field">
                  <label>Institution *</label>
                  <Select
                    options={institutionOptions}
                    name="company"
                    placeholder="Institution"
                    value={holidayList.company}
                    onChange={(value) => handleFieldChange("company", value)}
                  />
                  {errors.holiday_list_name && (
                    <span className="error-message">
                      {errors.holiday_list_name}
                    </span>
                  )}
                </div>
                <div className="form-field">
                  <label>Branch</label>
                  <Select
                    options={locationOptions}
                    name="branch"
                    placeholder="Branch"
                    value={holidayList.branch}
                    onChange={(value) => handleFieldChange("branch", value)}
                  />
                  {errors.total_holidays && (
                    <span className="error-message">
                      {errors.total_holidays}
                    </span>
                  )}
                </div>
                <div className="form-field">
                  <label>Department</label>
                  <Select
                    options={departmentOptions}
                    name="department"
                    placeholder="Department"
                    value={holidayList.holiday_list_name}
                    onChange={(value) => handleFieldChange("department", value)}
                  />
                  {errors.holiday_list_name && (
                    <span className="error-message">
                      {errors.holiday_list_name}
                    </span>
                  )}
                </div>
                <div className="form-field">
                  <label>Employment Type </label>
                  <Select
                    options={employmentTypeOptions}
                    name="employment_type"
                    placeholder="Employment Type"
                    value={holidayList.employment_type}
                    onChange={(value) => handleFieldChange("employment_type", value)}
                  />
                  {errors.holiday_list_name && (
                    <span className="error-message">
                      {errors.holiday_list_name}
                    </span>
                  )}
                </div>
                <div className="form-field">
                  <label>Designation</label>
                  <Select
                    options={designationOptions}
                    name="designation"
                    placeholder="Designation"
                    value={holidayList.designation}
                    onChange={(value) => handleFieldChange("designation", value)}
                  />
                  {errors.holiday_list_name && (
                    <span className="error-message">
                      {errors.holiday_list_name}
                    </span>
                  )}
                </div>
                <div className="form-field">
                  <label>Employee Grade </label>
                  <Select
                    options={employeeGradeOptions}
                    name="employee_grade"
                    placeholder="Employee Grade"
                    value={holidayList.holiday_list_name}
                    onChange={(value) => handleFieldChange("employee_grade", value)}
                  />
                  {errors.holiday_list_name && (
                    <span className="error-message">
                      {errors.holiday_list_name}
                    </span>
                  )}
                </div>
            </div>
              <h4> {"Select Employee"}</h4>
              <Table columns={columns} dataSource={rows} />
            </section>
          </div>
        </>
      )} 
    </MainLayout>
  );
};

export default HolidayListDetails;

import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GridMenuIcon } from "@mui/x-data-grid";
import { Table } from "antd";
import { Button, Grid, Stack, Typography } from "iiht-b2a-ui-components/build/components";
import {
  CustomBreadcrumb,
  FormDatepicker,
  FormSelectField,
  FormTextField,
  MainLayout,
} from "components/common";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { validationRules } from "constants/validationRules";
import {
  createGenericData,
  getGenericDataApi,
  getGenericListApi,
  getInstitution,
  updateGenericData,
} from "modules/hr";
import { addressMapper } from "utils";
import { setLoading } from "slices/commonSlice";

const CreateAppointmentLetter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const applicantName = location.state?.applicantName || "Appointment Letter";
  const { id: jobApplicantId, jobOpeningId, designationId, appointmentId } = useParams();
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [appointmentLetterData, setAppointmentLetterData] = useState({});
  const [appointmentLetterTemplateList, setAppointmentLetterTemplateList] = useState([]);
  const [appointmentTermsList, setAppointmentTermsList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [appointmentTermsRows, setAppointmentTermsRows] = useState([
    {
      id: 0,
      key: 0,
      number: 0,
      term: "",
    },
  ]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const isEditMode = !!(location.pathname.includes("edit-view") && jobApplicantId && appointmentId);
  const [isSaveBtnDisable, setIsSaveBtnDisable] = useState(isEditMode);
  const { loading } = useSelector((state) => state.common);

  const breadcrumbs = [
    {
      label: "IIHT",
      link: "/dashboard",
    },
    {
      label: "HR",
      link: "/modules/hr",
    },
    {
      label: "Recruitment",
      link: "/modules/hr",
    },
    {
      label: "Job Applications",
      link: "/modules/hr/job-application",
    },
    {
      label: applicantName === "Appointment Letter" ? jobApplicantId : applicantName,
      link: `/modules/hr/job-application/view/${jobApplicantId}/${jobOpeningId}/${designationId}?tab=5`,
    },
    {
      label: isEditMode ? "New Appointment Letter" : "View Appointment Letter",
      link: "",
    },
  ];

  const columns = [
    {
      dataIndex: "number",
      title: "SNo.",
      width: "10%",
      render: (_val, _row, rowIndex) => rowIndex + 1,
    },
    {
      dataIndex: "title",
      title: "Title",
      width: "40%",
      render: (val, _row, rowIndex) => {
        return val;
        // <div style={{ width: "200px" }}>
        //   <FormSelectField
        //     name={`appointmentLetterTerms.${rowIndex}.interviewer`}
        //     variant="outlined"
        //     label=""
        //     size="small"
        //     fullWidth
        //     onChange={(e) => {
        //       setValue(`appointmentLetterTerms.${rowIndex}.interviewer`, e.target.value);
        //       setIsSaveBtnDisable(false);
        //     }}
        //     options={appointmentTermsList}
        //   />
        // </div>
      },
    },
    {
      dataIndex: "description",
      title: "Description",
      width: "50%",
    },
  ];

  const defaultValues = {
    job_applicant: jobApplicantId || "",
    applicant_name: applicantName || "",
    applicant_email: location.state?.emailId || "",
    appointment_letter_template: "",
    company: "",
    appointment_date: "",
    introduction: "",
    // appointmentLetterTerms: [],
  };

  const methods = useForm({
    defaultValues: defaultValues,
  });

  const { handleSubmit, reset, watch, getValues, setValue, control } = methods;

  // const { append: skillsAppend, remove: skillsRemove } = useFieldArray({
  //   control: control,
  //   name: "appointmentLetterTerms",
  // });

  const appointmentLetterTemplateWatch = watch("appointment_letter_template");

  const onSubmit = async (data) => {
    dispatch(setLoading({ loading: true }));
    const terms = appointmentTermsList.map((term) => {
      return {
        title: term.title,
        description: term.description,
      };
    });
    const payload = {
      ...data,
      doctype: "Appointment Letter",
      id: appointmentId,
      terms,
    };
    const res = isEditMode ? await updateGenericData(payload) : await createGenericData(payload);
    if (res?.status === 200) {
      isEditMode && fetchAppointmentLetter();
      toast.success(`Appointment Letter ${isEditMode ? "Updated" : "Created"}!`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsSaveBtnDisable(true);
      setIsFormSubmited(true);
    } else {
      toast.error(res?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    dispatch(setLoading({ loading: false }));
  };

  const fetchAppointmentLetter = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Appointment Letter",
      id: appointmentId,
    };
    const res = await getGenericDataApi(params);
    if (res?.status === 200) {
      const data = res?.data?.message;
      setAppointmentLetterData(data);
      reset({
        job_applicant: data?.job_applicant || "",
        appointment_letter_template: data?.appointment_letter_template || "",
        applicant_name: data?.applicant_name || "",
        applicant_email: data?.applicant_email || location.state?.emailId || "",
        company: data?.company || "",
        appointment_date: data?.appointment_date || "",
        introduction: data?.introduction || "",
      });
    }
    dispatch(setLoading({ loading: false }));
  };

  const fetcAppointmentTemplatesList = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Appointment Letter Template",
      fields: JSON.stringify(["*"]),
      filters: [],
      limit: "*",
    };
    const res = await getGenericListApi(params);
    if (res?.status === 200) {
      if (res?.data?.message?.length) {
        const list = addressMapper(res.data.message, "template_name", "id", true);
        setAppointmentLetterTemplateList(list);
      } else {
        setAppointmentLetterTemplateList([]);
      }
    }
    await fetchCompanyList();
  };

  const fetchCompanyList = async () => {
    const res = await getInstitution();
    if (res?.status === 200) {
      if (res?.data?.data?.length) {
        const list = addressMapper(res.data.data, "name", "name", true);
        setCompanyList(list);
      } else {
        setCompanyList([]);
      }
    }
    dispatch(setLoading({ loading: false }));
  };

  const fetcAppointmentTermsList = async (id) => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Appointment Letter Template",
      id,
    };
    const res = await getGenericDataApi(params);
    if (res?.status === 200) {
      const data = res.data?.message;
      if (data.terms?.length) {
        const list = data.terms.map((term, i) => {
          return {
            ...term,
            title: term.title,
            description: term.description,
            key: i,
          };
        });
        setAppointmentTermsList(list);
      } else {
        setAppointmentTermsList([]);
      }
      setValue("introduction", data?.introduction || "");
    }
    dispatch(setLoading({ loading: false }));
  };

  const onSelectChange = (selectedKeys, selectedRowsData) => {
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      id: record.id,
    }),
  };

  // const handleAddRow = () => {
  //   setAppointmentTermsRows((prev) => {
  //     return [
  //       ...prev,
  //       {
  //         id: appointmentTermsRows.length,
  //         key: appointmentTermsRows.length,
  //         number: appointmentTermsRows.length,
  //         interviewer: "",
  //       },
  //     ];
  //   });
  //   skillsAppend({
  //     interviewer: "",
  //   });
  // };

  // const handleDeleteRow = () => {
  //   const skillData = [...appointmentTermsRows];
  //   const data = [];
  //   let i = 0;
  //   skillData.forEach((skill, index) => {
  //     if (!selectedRowKeys.includes(skill.key)) {
  //       data.push({ ...skill, id: i, key: i, number: i });
  //       i++;
  //     }
  //   });
  //   setAppointmentTermsRows(data);
  //   skillsRemove(selectedRowKeys);
  //   setSelectedRowKeys([]);
  //   const { interviewers } = getValues();
  //   reset({
  //     ...getValues(),
  //     interviewers: interviewers,
  //   });
  //   setIsSaveBtnDisable(false);
  // };

  useEffect(() => {
    if (isEditMode) {
      fetchAppointmentLetter();
    }
  }, [isEditMode]);

  useEffect(() => {
    if (appointmentLetterTemplateWatch) {
      fetcAppointmentTermsList(appointmentLetterTemplateWatch);
    }
  }, [appointmentLetterTemplateWatch]);

  useEffect(() => {
    fetcAppointmentTemplatesList();
  }, []);

  return (
    <MainLayout>
      {loading && <LoadingBackdrop open={loading} />}
      <div className="mb20">
        <CustomBreadcrumb breadcrumbs={breadcrumbs} />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={1} alignItems="center">
                <GridMenuIcon className="gridIcon" />
                <Typography color="#0D1021" fontSize={22}>
                  {isEditMode ? "View Appointment Letter" : "New Appointment Letter"}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={3}>
                <Button
                  variant="contained"
                  color="grey"
                  className="qa-back-btn"
                  size="large"
                  onClick={() =>
                    navigate(
                      `/modules/hr/job-application/view/${jobApplicantId}/${jobOpeningId}/${designationId}?tab=5`
                    )
                  }
                >
                  Back
                </Button>
                {!isEditMode && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="qa-save-btn"
                    disabled={isSaveBtnDisable}
                  >
                    {isEditMode ? "Update" : "Save"}
                  </Button>
                )}
              </Stack>
            </Stack>

            <Grid
              container
              rowSpacing={2}
              columnSpacing={5}
              display="flex"
              flexDirection="row"
              alignItems="start"
              sx={{ mt: 1 }}
            >
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Job Applicant *
                </Typography>
                <FormTextField
                  name="job_applicant"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                  className="mt1"
                />
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Applicant Name *
                </Typography>
                <FormTextField
                  name="applicant_name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                  className="mt1"
                />
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Applicant Email Address *
                </Typography>
                <FormTextField
                  name="applicant_email"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                  className="mt1"
                />
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Institution *
                </Typography>
                <FormSelectField
                  name="company"
                  variant="outlined"
                  label=""
                  size="small"
                  fullWidth
                  className="mt1"
                  disabled={isEditMode}
                  options={companyList}
                  rules={{
                    required: validationRules.REQUIRED,
                  }}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12} sx={{ mb: 1 }}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Appointment Date *
                </Typography>
                <FormDatepicker
                  name="appointment_date"
                  fullWidth
                  className="mt1"
                  disabled={isEditMode}
                  rules={{
                    required: validationRules.REQUIRED,
                  }}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Appointment Letter Template *
                </Typography>
                <FormSelectField
                  name="appointment_letter_template"
                  variant="outlined"
                  label=""
                  size="small"
                  fullWidth
                  className="mt1"
                  disabled={isEditMode}
                  options={appointmentLetterTemplateList}
                  rules={{
                    required: validationRules.REQUIRED,
                  }}
                />
              </Grid>
            </Grid>

            {appointmentLetterTemplateWatch && (
              <div className="my6">
                <Typography color="#012148" fontSize={14} fontWeight="500">
                  Appointment Letter Template *
                </Typography>
                <FormTextField
                  name="introduction"
                  variant="outlined"
                  size="small"
                  className="mt1"
                  placeholder="Enter Text here..."
                  fullWidth
                  multiline
                  disabled
                  rows={5}
                  rules={{
                    required: validationRules.REQUIRED,
                  }}
                />
                <Typography color="#012148" fontSize={14} fontWeight="500" className="mt2">
                  Appointment Letter Terms *
                </Typography>
                <Table
                  rowSelection={{ type: "checkbox", ...rowSelection }}
                  columns={columns}
                  dataSource={appointmentTermsList}
                  size="small"
                  pagination={false}
                />
              </div>
            )}
          </form>
        </FormProvider>
      </div>
    </MainLayout>
  );
};

export default CreateAppointmentLetter;

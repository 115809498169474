import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "iiht-b2a-ui-components/build/components";
import { CustomBreadcrumb, MainLayout } from "components/common";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { createHrEmployee, getGenericDataApi } from "modules/hr";
import JobApplicationDetails from "./JobApplicationDetails";
import { InterviewList } from "./Interview";
import { JobOfferList } from "./JobOffer";
import { AppointmentLetterList } from "./AppointmentLetter";
import { AddNote, Notes } from "./Notes";
import { ComposeEmail, EmailList } from "./ComposeEmail";
import SureSubmitModal from "components/common/SureSubmitModal";
import eventBus from "core/eventBus";
import { toast } from "react-toastify";

const JobApplicationView = () => {
  const emailRef = useRef();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab") ?? 0;
  const { id: jobApplicantId, jobOpeningId, designationId } = useParams();
  const [applicantName, setApplicantName] = useState("");
  const [jobApplicationData, setJobApplicationData] = useState({});
  const [tabValue, setTabValue] = useState(parseInt(tab));
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [showComposeEmail, setShowComposeEmail] = useState(false);
  const [emailView, setEmailView] = useState({
    data: "",
    value: false,
  });
  const [loading, setLoading] = useState(false);
  const [hasClearedInterview, setHasClearedInterview] = useState(false);

  const breadcrumbs = [
    {
      label: "IIHT",
      link: "/dashboard",
    },
    {
      label: "HR",
      link: "/modules/hr",
    },
    {
      label: "Recruitment",
      link: "/modules/hr",
    },
    {
      label: "Job Applications",
      link: "/modules/hr/job-application",
    },
    {
      label: applicantName,
      link: "",
    },
  ];

  const showEmailView = (record) => {
    setEmailView({ data: record, value: true });
    setShowComposeEmail(true);
  };

  const fetchJobApplication = async () => {
    setLoading(true);
    const params = {
      doctype: "Job Applicant",
      id: jobApplicantId,
    };
    const res = await getGenericDataApi(params);
    if (res?.status === 200) {
      const data = res?.data?.message;
      console.log(data);
      setJobApplicationData(data);
      const name = data?.applicant_name || "";
      setApplicantName(name);
    }
    setLoading(false);
  };

  const handleAddEmployee = async () => {
    try {
      const payload = {
        doctype: "Employee",
        naming_series: "HR-EMP-",
        ...jobApplicationData,
        gender: jobApplicationData.gender || "Male",
        first_name: jobApplicationData.first_name,
        company: jobApplicationData.company || "Techademy",
        date_of_birth: jobApplicationData.date_of_birth || "1998-08-06",
        date_of_joining: jobApplicationData.date_of_joining || "2024-04-08",
        status: "Active",
      };
      const res = await createHrEmployee({ payload });
      console.log(res);
      if (res.status === 200) {
        toast.success("Employee created!");
        navigate(`/modules/hr/employee/${res.data.message.name}`);
      } else {
        toast.error("There was an issue while saving employee.");
      }
    } catch (error) {
      toast.error(error.response.data.exception);
    }
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleOpenDialog = () => {
    eventBus.emit(
      "open-dialog",
      <SureSubmitModal
        handleUpdate={handleAddEmployee}
        confirmationMessage="Are you sure you want to add as an employee?"
      />
    );
  };

  useEffect(() => {
    tabValue === 1 && setShowComposeEmail(false);
  }, [tabValue]);

  useEffect(() => {
    fetchJobApplication();
  }, []);

  return (
    <MainLayout>
      {loading && <LoadingBackdrop open={loading} />}
      <div>
        <CustomBreadcrumb breadcrumbs={breadcrumbs} />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <GridMenuIcon className="gridIcon" />
            <Typography color="#0D1021" fontSize={22}>
              {applicantName}
            </Typography>
            {jobApplicationData?.status && (
              <Typography
                fontSize={12}
                fontWeight={500}
                color={`${
                  jobApplicationData?.status === "Rejected"
                    ? "#ac0521"
                    : jobApplicationData?.status === "Accepted"
                    ? "#4baf4f"
                    : "#2C83EC"
                }`}
                sx={{
                  border: `1px solid ${
                    jobApplicationData?.status === "Rejected"
                      ? "#ac052180"
                      : jobApplicationData?.status === "Accepted"
                      ? "#4baf4f80"
                      : "#2c83ec80"
                  }`,
                  borderRadius: "6px",
                  backgroundColor: `${
                    jobApplicationData?.status === "Rejected"
                      ? "#ac05210d"
                      : jobApplicationData?.status === "Accepted"
                      ? "#4baf4f0d"
                      : "#2c83ec0d"
                  }`,
                  textAlign: "center",
                  padding: "2px 6px",
                  width: "80px",
                }}
              >
                {jobApplicationData?.status ?? ""}
              </Typography>
            )}
          </Stack>
          <Stack direction="row" alignItems="center" spacing={3}>
            <Button
              variant="contained"
              color="grey"
              className="qa-save-btn"
              size="large"
              onClick={() => navigate("/modules/hr/job-application")}
            >
              Back
            </Button>
          </Stack>
        </Stack>

        <div>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Tabs
              value={tabValue}
              onChange={(event, newValue) => {
                setTabValue(newValue);
                setSearchParams({ tab: newValue });
              }}
              aria-label="basic tabs example"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Details" {...a11yProps(0)} />
              <Tab label="Email" {...a11yProps(1)} />
              <Tab label="Notes" {...a11yProps(2)} />
              <Tab label="Interview" {...a11yProps(3)} />
              <Tab label="Job Offer" {...a11yProps(4)} />
              <Tab label="Appointment" {...a11yProps(5)} />
            </Tabs>
            {tabValue === 1 && (
              <>
                {showComposeEmail ? (
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      sx={{ height: "32px" }}
                      className="qa-cancel-btn"
                      onClick={() => {
                        setShowComposeEmail(false);
                        setEmailView({ data: "", value: false });
                      }}
                      style={{
                        marginRight: "8px",
                        backgroundColor: "white",
                        color: "gray",
                      }}
                    >
                      Cancel
                    </Button>
                    {!emailView.value && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{ height: "32px" }}
                        className="qa-send-email-btn"
                        onClick={() => emailRef.current?.onSubmit()}
                      >
                        Send
                      </Button>
                    )}
                  </Stack>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ height: "32px" }}
                    className="qa-compose-email-btn"
                    onClick={() => setShowComposeEmail(true)}
                  >
                    Compose Email
                  </Button>
                )}
              </>
            )}
            {tabValue === 2 && (
              <div className="rightBtn" style={{ marginBottom: 0 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ height: "32px" }}
                  className="qa-add-note-btn"
                  onClick={() => setNoteModalOpen(true)}
                >
                  Add Note
                </Button>
              </div>
            )}
            {tabValue === 3 && (
              <div className="rightBtn" style={{ marginBottom: 0 }}>
                {hasClearedInterview && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ height: "32px" }}
                    className="qa-add-employee-btn"
                    onClick={handleOpenDialog}
                  >
                    Add Employee
                  </Button>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ height: "32px" }}
                  className="qa-add-interview-btn"
                  onClick={() =>
                    navigate(
                      `/modules/hr/job-application/view/interview/create/${jobApplicantId}/${jobOpeningId}/${designationId}`,
                      {
                        state: {
                          applicantName,
                        },
                      }
                    )
                  }
                >
                  Add Interview
                </Button>
              </div>
            )}
            {tabValue === 4 && (
              <div className="rightBtn" style={{ marginBottom: 0 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ height: "32px" }}
                  className="qa-add-interview-btn"
                  onClick={() =>
                    navigate(
                      `/modules/hr/job-application/view/job-offer/create/${jobApplicantId}/${jobOpeningId}/${designationId}`,
                      {
                        state: {
                          applicantName,
                          emailId: jobApplicationData?.email_id,
                        },
                      }
                    )
                  }
                >
                  Create Job Offer Letter
                </Button>
              </div>
            )}
            {tabValue === 5 && (
              <div className="rightBtn" style={{ marginBottom: 0 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ height: "32px" }}
                  className="qa-add-interview-btn"
                  onClick={() =>
                    navigate(
                      `/modules/hr/job-application/view/appointment-letter/create/${jobApplicantId}/${jobOpeningId}/${designationId}`,
                      {
                        state: {
                          applicantName,
                          emailId: jobApplicationData?.email_id,
                        },
                      }
                    )
                  }
                >
                  Create New Appointment Letter
                </Button>
              </div>
            )}
          </Box>
          <TabPanel value={tabValue} index={0}>
            <JobApplicationDetails jobApplicationData={jobApplicationData} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {showComposeEmail ? (
              <ComposeEmail
                ref={emailRef}
                uniqueId={jobApplicantId}
                jobApplicationData={jobApplicationData}
                close={setShowComposeEmail}
                emailView={emailView}
              />
            ) : (
              <EmailList
                uniqueId={jobApplicantId}
                showEmailView={showEmailView}
              />
            )}
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <Notes
              open={noteModalOpen}
              onClose={setNoteModalOpen}
              uniqueId={jobApplicantId}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <InterviewList
              applicantName={applicantName}
              onStatusCheck={(status) => setHasClearedInterview(status)}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <JobOfferList
              applicantName={applicantName}
              jobApplicationData={jobApplicationData}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={5}>
            <AppointmentLetterList
              applicantName={applicantName}
              jobApplicationData={jobApplicationData}
            />
          </TabPanel>
        </div>
        {noteModalOpen && (
          <AddNote
            open={noteModalOpen}
            onClose={() => setNoteModalOpen(false)}
            uniqueId={jobApplicantId}
          />
        )}
      </div>
    </MainLayout>
  );
};

export default JobApplicationView;

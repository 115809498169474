import React, { useState } from "react";
import TechademyLogo from "../../assets/images/TechademyLogo.svg";
import { useTranslation } from "react-i18next";
import {
  FormControlLabel,
  Grid,
  Link,
  Paper,
  Switch,
  TextField,
} from "iiht-b2a-ui-components/build/components";
import Button from "iiht-b2a-ui-components/build/components/Button";
import Divider from "iiht-b2a-ui-components/build/components/Divider";
import Typography from "iiht-b2a-ui-components/build/components/Typography";
import { useTheme } from "iiht-b2a-ui-components/build/components/Styles";
import IconButton from "iiht-b2a-ui-components/build/components/IconButton";
import InputAdornment from "iiht-b2a-ui-components/build/components/InputAdornment";
import FormControl from "iiht-b2a-ui-components/build/components/Form/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { login } from "../../modules/auth";
import "./index.scss";
import { useDispatch } from "react-redux";
import { addUserDetails } from "../../slices/userSlice";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { roleConstants } from "../../config/roles";
import { toast } from "react-toastify";

const Loginform = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies(null, { path: "/" });

  const [values, setValues] = useState({
    userNameOrEmailAddress: "",
    password: "",
    showPassword: false,
  });
  const [rememberMeChecked, setRememberMeChecked] = useState(false);

  const handleChange = (event) => {
    setValues({ ...values, [event.target.id]: event.target.value });
  };

  const handleRememberMe = (e) => {
    setRememberMeChecked(e.target.checked);
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLoginClick = async () => {
    // TODO: error handling

    const payload = {
      usr: values.userNameOrEmailAddress,
      pwd: values.password,
    };

    const res = await login({ payload });
    if (res.status === 200) {
      cookies.set("techademy_name", res.data.full_name);

      const isAdminRoleExists = res.data?.message?.roles.filter(
        (role) => role === roleConstants.admissionCommitee
      );
      const isStudentRoleExists = res.data?.message?.roles.filter(
        (role) => role === roleConstants.student
      );

      dispatch(addUserDetails(res.data));
      const val = {
        name: res.data.full_name,
        email: res.data.message.email,
        isAdmin: isAdminRoleExists.length > 0 ? true : false,
        isStudent: isStudentRoleExists.length > 0 ? true : false,
        roles: res.data.message?.roles,
      };

      window.localStorage.setItem("userDetails", JSON.stringify(val));

      isAdminRoleExists && isAdminRoleExists.length > 0
        ? navigate("/dashboard")
        : navigate("/student/dashboard");
    } else {
      toast.error(res.data?.message?.message);
    }
  };

  const handleOpenForgotPassword = () => {
    navigate("/forgotpassword");
  };

  return (
    <div className="login-form-logo-wrapper">
      <div className="sign-in-section">
        <img
          className="defaultTenantLogo"
          alt="Default Logo"
          src={TechademyLogo}
        />

        <form autoComplete="off">
          <Paper elevation={0} className="sign-in-input-wrapper">
            <div style={{ padding: "30px 0" }}>
              <Typography variant="h4" className="loginHead">{t("loginAccount")}</Typography>
            </div>

            <input type="search" className="disable-autofill-chromium" />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                alignItems: "center",
              }}
            >
              <FormControl
                tabIndex={0}
                variant="outlined"
                sx={{ width: "100%" }}
              >
                <TextField
                  label={`${t("username")} *`}
                  id="userNameOrEmailAddress"
                  className="qa-sign-in-username"
                  type="text"
                  value={values?.userNameOrEmailAddress || ""}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </FormControl>
              <FormControl
                tabIndex={1}
                variant="outlined"
                sx={{ width: "100%", mb: 2 }}
              >
                <TextField
                  label={`${t("password")} *`}
                  id="password"
                  className="qa-sign-in-password"
                  type={values?.showPassword ? "text" : "password"}
                  value={values?.password || ""}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          sx={{ color: theme.palette.grey[500] }}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values?.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="new-password"
                />
              </FormControl>
            </div>
            <br />
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <FormControlLabel
                control={<Switch size="medium" onChange={handleRememberMe} />}
                label={t("rememberMe")}
              />
              <Button
                className="qa-sign-in-button"
                variant="contained"
                color="secondary"
                tabIndex={2}
                sx={{
                  width: "fit-content",
                  alignSelf: "center",
                  color: theme.palette.common.white,
                }}
                onClick={handleLoginClick}
              >
                {t("login")}
              </Button>
            </Grid>

            <br />
            <Typography variant="body2">
              <Link
                color="secondary"
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/forgotpassword")}
              >
                {t("forgotPassword")}
              </Link>{" "}
              &nbsp;
              {t("unableLogin")}&nbsp;
              <Link color="secondary" sx={{ cursor: "pointer" }}>
                {t("support")}
              </Link>
            </Typography>
            <Divider sx={{ width: "100%", mt: 1 }} />
            <div style={{ paddingBottom: "20px" }}>
              <Button
                className="qa-sign-in-button"
                variant="contained"
                color="secondary"
                tabIndex={2}
                sx={{
                  width: "fit-content",
                  alignSelf: "center",
                  color: theme.palette.common.white,
                  mt: 2,
                }}
              >
                {t("googlelogin")}
              </Button>
            </div>
          </Paper>
        </form>
      </div>
    </div>
  );
};

export default Loginform;

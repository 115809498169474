import { createSlice } from "@reduxjs/toolkit";

const commonSlice = createSlice({
  name: "common",
  initialState: {
    loading: false
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading =  action.payload.loading;
    }
  },
});

export const { setLoading } = commonSlice.actions;

export default commonSlice.reducer;

import React, { useState, useEffect } from "react";
import MainFooter from "../../components/common/MainFooter";
import HiringImg from "../../../src/assets/images/Career Hiring.png";
import { ReactSVG } from "react-svg";
import useStyles from "./style";
import Logo from "../../assets/images/TechademyLogo.svg"
import Typography from "@mui/material/Typography";
import ApplyJob from "../../components/ApplyJob/ApplyJob/applyjob"
import CloseOn from "../../../src/assets/images/ClosesOn.svg";
import Department from "../../../src/assets/images/Department.svg";
import EmployemntType from "../../../src/assets/images/EmploymentType.svg";
import Location from "../../../src/assets/images/Location.svg";
import ApplicationReceived from "../../../src/assets/images/ApplicationReceived.svg";
import { JobOpeningDetail } from "../../modules/hr";
import { useParams } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import HtmlParser from "react-html-parser";

const JobOpening = () => {
  const styles = useStyles();
  const [ jobDetails,setJobDetails ] = useState([])
  const { id,count } = useParams();
  const uniqueId = id.split("=")[1];
  
  const handleGetJobDetails = async () => {
    const payload={
      doctype:"Job Opening",
      id:uniqueId,
    }
    try {
      const res = await JobOpeningDetail(payload);
      if (res.status === 200) {
        setJobDetails(res.data.message);
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };
  console.log(jobDetails,"------------")

  useEffect(() => {
    handleGetJobDetails();
  }, []);

  const htmlContent = jobDetails.description || ""

  
  const fieldsData = [
    { name: "Location", img: Location, info: jobDetails.location },
    { name: "Applications Received", img: ApplicationReceived, info: jobDetails.no_of_applications_received},
    { name: "Department", img: Department, info: jobDetails.department },
    { name: "Closes On", img: CloseOn, info: jobDetails.closes_on },
    { name: "Employment Type", img: EmployemntType, info: jobDetails.employment_type },
  ];

  return (
    <>
      <div className={styles.logoContainer}>
        <ReactSVG src={Logo} />
        <div className={styles.labelsContainer}>
          {/* Clickable labels */}
          <span className={styles.label}>Career</span>
          <span className={styles.label}>Contact Us</span>
        </div>
      </div>
      <div className={styles.mainContainer}>
        <img src={HiringImg} className={styles.mainImage} alt="Main" />
      </div>
      <div style={{padding: '40px'}}>
      <div>
        <div className={styles.jobOpeningTitle}>
          <Typography className={styles.Title}>Hiring for {jobDetails.designation}</Typography>
          <ApplyJob details={jobDetails}></ApplyJob>
          </div>
          <p>{jobDetails.company} 5 min ago</p>
        </div>
        <div className={styles.cardBlock}>
            <div className={styles.jobOpeningCards} >
            {fieldsData.map((field, index) => (
                <div key={index} className={styles.openingCards}>
                <img src={field.img} alt={field.name} />
                <div className={styles.labels}>
                  <span className={styles.cardHead}>{field.name}</span>
                  <h3>{field.info}</h3></div>
                </div>
            ))}
            </div>
        </div>
        <div>
            <Typography className={styles.jobDescription}>Job Description</Typography>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </div>
      </div>
    </>
  );
};

export default JobOpening;

import React, { useEffect, useState } from "react";
import "./table.css";
import useStyles from "./style";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import MainHeader from "../../../common/MainHeader";
import MainFooter from "../../../common/MainFooter";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSidebar } from "../../../../slices/financeSlice";
import { setLoading } from "../../../../slices/commonSlice";
import LoadingBackdrop from "../../../common/LoadingBackdrop.js";
import { toast } from "react-toastify";
import { structureBasedOnOptions, AdmissionTypeOptions } from "../config.js";
import { Select, Button, Table, Input } from "antd";
import {
  createFeeStructure,
  getAcademicTermListing,
  getAcademicYearListing,
  getAllFeeCategories,
  getCollegeListing,
  getCostCenterListing,
  getIncomeAccountListing,
  getProgramListing,
  getReceivableAccountListing,
  getStudentCategoryListing,
} from "../../../../modules/finance.js";

const AddNewFeeStructure = () => {
  const styles = useStyles();
  const selectSize = "middle";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [programOptions, setProgramOptions] = useState([]);
  const [academicYearOptions, setAcademicYearOptions] = useState([]);
  const [academicTermOptions, setAcademicTermOptions] = useState([]);
  const [studentCategoryOptions, setStudentCategoryOptions] = useState([]);
  const [feeCategoryOptions, seFeeCategoryOptions] = useState([]);
  const [collegeOptions, setCollegeOptions] = useState([]);
  const [receivableAccountOptions, setReceivableAccountOptions] = useState([]);
  const [incomeAccountOptions, setIncomeAccountOptions] = useState([]);
  const [costCenterOptions, setCostCenterOptions] = useState([]);
  const [feeCategories, setFeeCategories] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [values, setValues] = useState({
    name: "",
    custom_structure_based_on: null,
    program: null,
    student_category: null,
    custom_admission_type: null,
    academic_year: null,
    components: [{}],
    academic_term: null,
    total_amount: "",
    receivable_account: null,
    income_account: null,
    cost_center: null,
    company: null,
  });

  const [errors, setErrors] = useState({
    name: "",
    custom_structure_based_on: "",
    program: "",
    student_category: "",
    custom_admission_type: "",
    academic_year: "",
    components: [],
    academic_term: "",
    total_amount: "",
    receivable_account: "",
    income_account: "",
    cost_center: "",
    company: "",
  });

  const handleFeeStructureNavigation = () => {
    dispatch(setActiveSidebar({ activeSidebar: 2 }));
    navigate("/modules/finance");
  };

  const handleGetAcademicYearListing = async () => {
    const res = await getAcademicYearListing();
    if (res.status === 200) {
      const arr = [];
      const acdemicYearList = res.data.data;
      for (let i = 0; i < acdemicYearList.length; i++) {
        arr.push({
          value: acdemicYearList[i].name,
          label: acdemicYearList[i].name,
        });
      }
      setAcademicYearOptions(arr);
    }
  };

  const handleGetAcademicTermListing = async () => {
    const res = await getAcademicTermListing();
    if (res.status === 200) {
      const arr = [];
      const acdemicTermList = res.data.data;
      for (let i = 0; i < acdemicTermList.length; i++) {
        arr.push({
          value: acdemicTermList[i].name,
          label: acdemicTermList[i].name,
        });
      }
      setAcademicTermOptions(arr);
    }
  };

  const handleGetProgramListing = async () => {
    const res = await getProgramListing();
    if (res.status === 200) {
      const arr = [];
      const programList = res.data.data;
      for (let i = 0; i < programList.length; i++) {
        arr.push({
          value: programList[i].name,
          label: programList[i].name,
        });
      }
      setProgramOptions(arr);
    }
  };

  const handleGetStudentCategoryListing = async () => {
    const res = await getStudentCategoryListing();
    if (res.status === 200) {
      const arr = [];
      const studentCategoryList = res.data.data;
      for (let i = 0; i < studentCategoryList.length; i++) {
        arr.push({
          value: studentCategoryList[i].name,
          label: studentCategoryList[i].name,
        });
      }
      setStudentCategoryOptions(arr);
    }
  };

  const handleGetFeeCategoylisting = async () => {
    const res = await getAllFeeCategories();
    if (res.status === 200) {
      const arr = [];
      const feeCategoryList = res.data.data;
      for (let i = 0; i < feeCategoryList.length; i++) {
        arr.push({
          value: feeCategoryList[i].category_name,
          label: feeCategoryList[i].category_name,
          description: feeCategoryList[i].description,
          amount: feeCategoryList[i].custom_amount,
          hasCustomAmount: feeCategoryList[i].custom_amount ? true : false,
        });
      }
      setFeeCategories(feeCategoryList);
      seFeeCategoryOptions(arr);
    }
  };

  const handleGetCollegeListing = async () => {
    const res = await getCollegeListing();
    if (res.status === 200) {
      const arr = [];
      const collegeList = res.data.data;
      for (let i = 0; i < collegeList.length; i++) {
        arr.push({
          value: collegeList[i].name,
          label: collegeList[i].name,
        });
      }
      setCollegeOptions(arr);
    }
  };

  const handleGetReceivableAccountListing = async (companyId) => {
    const res = await getReceivableAccountListing(companyId);
    if (res.status === 200) {
      const arr = [];
      const receivableAccountList = res.data.message;
      for (let i = 0; i < receivableAccountList.length; i++) {
        arr.push({
          value: receivableAccountList[i].value,
          label: receivableAccountList[i].value,
        });
      }
      setReceivableAccountOptions(arr);
    }
  };

  const handleGetIncomeAccountListing = async (companyId) => {
    const res = await getIncomeAccountListing(companyId);
    if (res.status === 200) {
      const arr = [];
      const incomeAccountList = res.data.message;
      for (let i = 0; i < incomeAccountList.length; i++) {
        arr.push({
          value: incomeAccountList[i].value,
          label: incomeAccountList[i].value,
        });
      }
      setIncomeAccountOptions(arr);
    }
  };

  const handleGetCostCenterListing = async (companyId) => {
    const res = await getCostCenterListing(companyId);
    if (res.status === 200) {
      const arr = [];
      const costCenterList = res.data.message;
      for (let i = 0; i < costCenterList.length; i++) {
        arr.push({
          value: costCenterList[i].value,
          label: costCenterList[i].value,
        });
      }
      setCostCenterOptions(arr);
    }
  };

  const handleAddFeeCategory = () => {
    setValues({
      ...values,
      components: [...values.components, {}],
    });
    setErrors({
      ...errors,
      components: [],
    });
  };

  const handleDeleteFeeCategory = () => {
    const components = values.components;
    const resultArray = components.filter(
      (obj) => !selectedRowKeys.includes(obj.key)
    );
    setSelectedRowKeys([]);
    setValues({
      ...values,
      components: resultArray.length > 0 ? resultArray : [{}],
    });
    setErrors({
      ...errors,
      components: [],
    });
  };

  const onSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = {
    type: "checkbox",
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      feeCategory: record.feeCategory,
    }),
    selectedRowKeys,
  };

  const handleValidateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!values.name) {
      newErrors.name = "Required";
      isValid = false;
    } else {
      newErrors.name = "";
    }

    if (values.custom_structure_based_on === null) {
      newErrors.custom_structure_based_on = "Required";
      isValid = false;
    } else {
      newErrors.custom_structure_based_on = "";
    }

    if (values.academic_year === null) {
      newErrors.academic_year = "Required";
      isValid = false;
    } else {
      newErrors.academic_year = "";
    }

    if (values.program === null) {
      newErrors.program = "Required";
      isValid = false;
    } else {
      newErrors.program = "";
    }

    if (values.custom_admission_type === null) {
      newErrors.custom_admission_type = "Required";
      isValid = false;
    } else {
      newErrors.custom_admission_type = "";
    }

    if (values.receivable_account === null) {
      newErrors.receivable_account = "Required";
      isValid = false;
    } else {
      newErrors.receivable_account = "";
    }

    if (values.company === null) {
      newErrors.company = "Required";
      isValid = false;
    } else {
      newErrors.company = "";
    }

    if (values.income_account === null) {
      newErrors.income_account = "Required";
      isValid = false;
    } else {
      newErrors.income_account = "";
    }

    if (values.cost_center === null) {
      newErrors.cost_center = "Required";
      isValid = false;
    } else {
      newErrors.cost_center = "";
    }

    if (Object.keys(values.components[0]).length > 0) {
      const components = values.components;
      for (let i = 0; i < components.length; i++) {
        if (components[i].amount <= 0) {
          newErrors.components = `Enter valid amount for Fee Category "${components[i].feeCategory}"`;
          isValid = false;
          break;
        }
      }
    } else {
      newErrors.components = "Required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSave = async () => {
    const isValid = handleValidateForm();
    if (isValid) {
      try {
        const payload = {
          custom_structure_based_on: values.custom_structure_based_on,
          program: values.program,
          student_category: values.student_category,
          custom_admission_type: values.custom_admission_type,
          academic_year: values.academic_year,
          academic_term: values.academic_term,
          components: values.components.map((component) => ({
            fees_category: component.feeCategory,
            amount: parseInt(component.amount),
          })),
          total_amount: values.total_amount,
          custom_fee_structure_name: values.name,
          receivable_account: values.receivable_account,
          company: values.company,
          income_account: values.income_account,
          cost_center: values.cost_center,
          docstatus: 0,
        };
        const res = await createFeeStructure({ payload });
        if (res.status === 200) {
          navigate(`/modules/finance/fee-structure/${res.data.data.name}`);
          toast.success("Fee Structure Saved!");
        } else {
          toast.error("There is some issue while creating");
        }
      } catch (error) {
        toast.error(error.response.data.exception);
      }
    }
  };

  const calculateTotalAmount = () => {
    const totalAmount =
      Object.keys(values.components[0]).length > 0
        ? values.components.reduce(
            (acc, currentValue) => acc + parseInt(currentValue.amount),
            0
          )
        : 0;

    if (totalAmount >= 0) {
      setValues({
        ...values,
        total_amount: totalAmount,
      });
    }
  };

  const columns = [
    {
      title: "Fee Category",
      dataIndex: "feeCategory",
      width: "25%",
      render: (val, record, index) => {
        return (
          <Select
            className={styles.tableContentFields}
            size={selectSize}
            placeholder="Select"
            style={{ width: "100%", border: "none !important" }}
            defaultValue={record.feeCategory}
            options={feeCategoryOptions}
            onChange={(value) => {
              const feeCategory = feeCategories.find(
                (category) => category.name === value
              );
              const componentsArr = values.components;
              componentsArr[index] = {
                key: index,
                feeCategory: feeCategory.category_name,
                description: feeCategory.description,
                amount: feeCategory.custom_amount,
                action: feeCategory.category_name,
                hasCustomAmount: feeCategory.custom_amount ? true : false,
              };
              setValues({
                ...values,
                components: [...componentsArr],
              });
              setErrors({
                ...errors,
                components: "",
              });
            }}
          />
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "45%",
      render: (val, record) => {
        return (
          <p className={styles.tableContentFields}>{record.description}</p>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      width: "15%",
      render: (val, record, index) => {
        return (
          <>
            {record.hasCustomAmount ? (
              <p className={styles.tableContentFields}>{record.amount}</p>
            ) : (
              <Input
                placeholder="Enter Amount"
                className={styles.tableContentFields}
                style={{ width: "100%", border: "none" }}
                value={record.amount}
                onChange={(e) => {
                  const feeCategory = feeCategories.find(
                    (category) => category.name === record.feeCategory
                  );
                  const componentsArr = values.components;
                  componentsArr[index] = {
                    key: index,
                    feeCategory: feeCategory.category_name,
                    description: feeCategory.description,
                    amount: e.target.value,
                    action: feeCategory.category_name,
                    hasCustomAmount: false,
                  };

                  setValues({
                    ...values,
                    components: [...componentsArr],
                  });
                  setErrors({
                    ...errors,
                    components: "",
                  });
                }}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "15%",
      render: (val, record, index) => {
        return (
          <>
            {record.feeCategory && (
              <p
                className={styles.tableContentView}
                onClick={() => {
                  const url = `/modules/finance/fee-category/${record.feeCategory}`;
                  window.open(url, "_blank");
                }}
              >
                View
              </p>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    handleGetAcademicYearListing();
    handleGetAcademicTermListing();
    handleGetProgramListing();
    handleGetStudentCategoryListing();
    handleGetFeeCategoylisting();
    handleGetCollegeListing();
    // handleGetReceivableAccountListing();
    // handleGetIncomeAccountListing();
    // handleGetCostCenterListing();
  }, []);

  useEffect(() => {
    calculateTotalAmount();
  }, [values.components]);

  useEffect(() => {
    if (values.company !== null) {
      console.log("yo");
      handleGetReceivableAccountListing(values.company);
      handleGetIncomeAccountListing(values.company);
      handleGetCostCenterListing(values.company);
    }
  }, [values.company]);

  useEffect(() => {
    console.log(values);
  }, [values]);

  return (
    <>
      <MainHeader />
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/dashboard")}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/modules/finance")}
            >
              Finance {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={handleFeeStructureNavigation}
            >
              Fee Structure
            </p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>New Fee Structure</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div
                className={styles.button}
                onClick={handleFeeStructureNavigation}
              >
                <p className={styles.buttonText}>Back</p>
              </div>
              <div className={styles.activeButton}>
                <p className={styles.activeButtonText} onClick={handleSave}>
                  Save
                </p>
              </div>
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Structure Based On *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  defaultValue={values.custom_structure_based_on}
                  className={styles.selectInput}
                  options={structureBasedOnOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      custom_structure_based_on: value,
                    });
                    setErrors({
                      ...errors,
                      custom_structure_based_on: "",
                    });
                  }}
                />
                {errors.custom_structure_based_on && (
                  <p className={styles.errorMessage}>
                    {errors.custom_structure_based_on}
                  </p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Academic Year *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  defaultValue={values.academic_year}
                  className={styles.selectInput}
                  options={academicYearOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      academic_year: value,
                    });
                    setErrors({
                      ...errors,
                      academic_year: "",
                    });
                  }}
                />
                {errors.academic_year && (
                  <p className={styles.errorMessage}>{errors.academic_year}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Academic Term</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  defaultValue={values.academic_term}
                  className={styles.selectInput}
                  options={academicTermOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      academic_term: value,
                    });
                  }}
                />
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Programme *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  defaultValue={values.program}
                  className={styles.selectInput}
                  options={programOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      program: value,
                    });
                    setErrors({
                      ...errors,
                      program: "",
                    });
                  }}
                />
                {errors.program && (
                  <p className={styles.errorMessage}>{errors.program}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Student Category</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  defaultValue={values.student_category}
                  className={styles.selectInput}
                  options={studentCategoryOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      student_category: value,
                    });
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Admission Type *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  defaultValue={values.custom_admission_type}
                  options={AdmissionTypeOptions}
                  className={styles.selectInput}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      custom_admission_type: value,
                    });
                    setErrors({
                      ...errors,
                      custom_admission_type: "",
                    });
                  }}
                />
                {errors.custom_admission_type && (
                  <p className={styles.errorMessage}>
                    {errors.custom_admission_type}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Structure Name *</p>
                <input
                  type="text"
                  placeholder="ex Annual"
                  defaultValue={values.name}
                  className={styles.fieldInput}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      name: e.target.value,
                    });
                    setErrors({
                      ...errors,
                      name: "",
                    });
                  }}
                />
                {errors.name && (
                  <p className={styles.errorMessage}>{errors.name}</p>
                )}
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.tableField}>
                <p className={styles.fieldLabel}>Fee Components *</p>
                <Table
                  rowSelection={{ type: "checkbox", ...rowSelection }}
                  columns={columns}
                  dataSource={values.components}
                  size="small"
                  pagination={false}
                />
                {errors.components && (
                  <p className={styles.errorMessage}>{errors.components}</p>
                )}
              </div>
            </div>
            <div className={styles.fieldButtons}>
              {selectedRowKeys.length > 0 && (
                <div
                  className={styles.deleteRow}
                  onClick={handleDeleteFeeCategory}
                >
                  Delete
                </div>
              )}
              <div className={styles.addRow} onClick={handleAddFeeCategory}>
                +
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Total Amount</p>
                <input
                  type="text"
                  placeholder="ex 1500"
                  defaultValue={values.total_amount}
                  className={styles.fieldInput}
                  disabled="true"
                  onChange={(e) => {
                    setValues({
                      ...values,
                      total_amount: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <p className={styles.heading}>Account's Details</p>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Receivable Account *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  defaultValue={values.receivable_account}
                  className={styles.selectInput}
                  options={receivableAccountOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      receivable_account: value,
                    });
                    setErrors({
                      ...errors,
                      receivable_account: "",
                    });
                  }}
                />
                {errors.receivable_account && (
                  <p className={styles.errorMessage}>
                    {errors.receivable_account}
                  </p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>College/University *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  defaultValue={values.company}
                  className={styles.selectInput}
                  options={collegeOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      company: value,
                    });
                    setErrors({
                      ...errors,
                      company: "",
                    });
                  }}
                />
                {errors.company && (
                  <p className={styles.errorMessage}>{errors.company}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Income Account</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  defaultValue={values.income_account}
                  className={styles.selectInput}
                  options={incomeAccountOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      income_account: value,
                    });
                    setErrors({
                      ...errors,
                      income_account: "",
                    });
                  }}
                />
                {errors.income_account && (
                  <p className={styles.errorMessage}>{errors.income_account}</p>
                )}
              </div>
            </div>
            <p className={styles.heading}>Account's Dimensions</p>
            <div className={styles.formRow} style={{ marginBottom: "20px" }}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Cost Center *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  defaultValue={values.cost_center}
                  className={styles.selectInput}
                  options={costCenterOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      cost_center: value,
                    });
                    setErrors({
                      ...errors,
                      cost_center: "",
                    });
                  }}
                />
                {errors.cost_center && (
                  <p className={styles.errorMessage}>{errors.cost_center}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default AddNewFeeStructure;

import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { useDispatch, useSelector } from "react-redux";
import { Select, Table, Input, DatePicker, Tabs, Tab, Checkbox } from "antd";
import { toast } from "react-toastify";
import MainHeader from "components/common/MainHeader";
import MainFooter from "components/common/MainFooter";
import { getStudentsListing } from "modules/finance";
import {
  createStudentLeaveApplication,
  getCourseScheduleListing,
  getStudentGroupListing,
} from "modules/hr";

const AddNewStudentLeaveApplication = () => {
  const styles = useStyles();
  const selectSize = "middle";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [studentOptions, setStudentOptions] = useState([]);
  const [studentGroupOptions, setStudentGroupOptions] = useState([]);
  const [courseScheduleOptions, setCourseScheduleOptions] = useState([]);
  const [values, setValues] = useState({
    student: "",
    student_name: "",
    from_date: "",
    to_date: "",
    student_based_on: "Student Group",
    student_group: "",
    course_schedule: "",
    mark_as_present: "",
    reason: "",
  });
  const [errors, setErrors] = useState({
    student: "",
    student_name: "",
    from_date: "",
    to_date: "",
    student_group: "",
    course_schedule: "",
  });

  const handleGetStudentListing = async () => {
    const res = await getStudentsListing();
    if (res.status === 200) {
      const arr = [];
      const studentList = res.data.data;
      for (let i = 0; i < studentList.length; i++) {
        arr.push({
          value: studentList[i].name,
          label: `${studentList[i].name} - ${studentList[i].first_name}`,
        });
      }
      setStudentOptions(arr);
    }
  };

  const studentBasedOnOptions = [
    {
      value: "Student Group",
      label: "Student Group",
    },
    {
      value: "Course Schedule",
      label: "Course Schedule",
    },
  ];

  const handleGetStudentGroupListing = async () => {
    const res = await getStudentGroupListing();
    if (res.status === 200) {
      const arr = [];
      const studentGroupList = res.data.data;
      for (let i = 0; i < studentGroupList.length; i++) {
        arr.push({
          value: studentGroupList[i].name,
          label: studentGroupList[i].name,
        });
      }
      setStudentGroupOptions(arr);
    }
  };

  const handleGetCourseScheduleListing = async () => {
    const res = await getCourseScheduleListing();
    if (res.status === 200) {
      const arr = [];
      const courseScheduleList = res.data.data;
      for (let i = 0; i < courseScheduleList.length; i++) {
        arr.push({
          value: courseScheduleList[i].name,
          label: `${courseScheduleList[i].name} - ${courseScheduleList[i].instructor}`,
        });
      }
      setCourseScheduleOptions(arr);
    }
  };

  const handleValidateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!values.student) {
      newErrors.student = "Student ID is required";
      isValid = false;
    } else {
      newErrors.student = "";
    }

    if (!values.student_name) {
      newErrors.student_name = "Student Name is required";
      isValid = false;
    } else {
      newErrors.student_name = "";
    }

    if (!values.from_date) {
      newErrors.from_date = "From Date is required";
      isValid = false;
    } else {
      newErrors.from_date = "";
    }

    if (!values.to_date) {
      newErrors.to_date = "To Date is required";
      isValid = false;
    } else {
      newErrors.to_date = "";
    }

    if (values.student_based_on === "Student Group" && !values.student_group) {
      newErrors.student_group = "Student Group is required";
      isValid = false;
    } else {
      newErrors.student_group = "";
    }

    if (
      values.student_based_on === "Course Schedule" &&
      !values.course_schedule
    ) {
      newErrors.course_schedule = "Course Schedule is required";
      isValid = false;
    } else {
      newErrors.course_schedule = "";
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSave = async () => {
    const isValid = handleValidateForm();
    if (isValid) {
      try {
        const payload = {
          ...values,
          doctype: "Student Leave Application",
          student: values.student,
          student_name: values.student_name,
          from_date: values.from_date,
          to_date: values.to_date,
          attendance_based_on: values.student_based_on,
          student_group: values.student_group,
          course_schedule: values.course_schedule,
          mark_as_present: values.mark_as_present,
          reason: values.reason,
        };
        const res = await createStudentLeaveApplication({ payload });
        console.log(res);
        if (res.status === 200) {
          toast.success("Student Leave Application created!");
          navigate(
            `/modules/academics/student-leave-application/${res.data.message.name}`
          );
        } else {
          toast.error(
            "There was an issue while saving student leave application."
          );
        }
      } catch (error) {
        toast.error(error.response.data.exception);
      }
    }
  };

  const handleStudentLeaveNavigation = () => {
    navigate("/modules/academics/student-leave-application");
  };

  useEffect(() => {
    handleGetStudentListing();
    handleGetStudentGroupListing();
    handleGetCourseScheduleListing();
  }, []);

  return (
    <>
      <MainHeader />
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/dashboard")}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/modules/academics")}
            >
              Academics {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={handleStudentLeaveNavigation}
            >
              Student Leave Application {" > "}
            </p>
            <p className={styles.breadCrumbsContent}>Create</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>New Student Leave Application</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div
                className={styles.button}
                onClick={handleStudentLeaveNavigation}
              >
                <p className={styles.buttonText}>Back</p>
              </div>
              <div className={styles.activeButton}>
                <p className={styles.activeButtonText} onClick={handleSave}>
                  Save
                </p>
              </div>
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Student ID*</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.student}
                  className={styles.selectInput}
                  options={studentOptions}
                  onChange={(value, options) => {
                    const selectedParty = studentOptions.find(
                      (opt) => opt.value === value
                    );
                    if (selectedParty) {
                      setValues({
                        ...values,
                        student: value,
                        student_name: selectedParty.label,
                      });
                    } else {
                      setValues({
                        ...values,
                        student: null,
                        student_name: null,
                      });
                    }
                    setErrors({
                      ...errors,
                      student: "",
                    });
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
                {errors.student && (
                  <p className={styles.errorMessage}>{errors.student}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Student Name *</p>
                <Input
                  placeholder="ex Text"
                  disabled={true}
                  value={values.student_name}
                  className={styles.fieldInput}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      student_name: e.target.value,
                    });
                    setErrors({
                      ...errors,
                      student_name: "",
                    });
                  }}
                />
                {errors.student_name && (
                  <p className={styles.errorMessage}>{errors.student_name}</p>
                )}
              </div>
              <div className={styles.field}></div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>From Date *</p>
                <input
                  type="date"
                  value={values.from_date}
                  placeholder="YYYY-MM-DD"
                  className={styles.dateInput}
                  onChange={(e) => {
                    const dateString = e.target.value;
                    setValues({ ...values, from_date: dateString });
                    setErrors({
                      ...errors,
                      from_date: "",
                    });
                  }}
                />
                {errors.from_date && (
                  <p className={styles.errorMessage}>{errors.from_date}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>To Date *</p>
                <input
                  type="date"
                  value={values.to_date}
                  placeholder="YYYY-MM-DD"
                  className={styles.dateInput}
                  onChange={(e) => {
                    const dateString = e.target.value;
                    setValues({ ...values, to_date: dateString });
                    setErrors({
                      ...errors,
                      to_date: "",
                    });
                  }}
                />
                {errors.to_date && (
                  <p className={styles.errorMessage}>{errors.to_date}</p>
                )}
              </div>
              <div className={styles.field}></div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Student Based On</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.student_based_on}
                  className={styles.selectInput}
                  options={studentBasedOnOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      student_based_on: value,
                    });
                  }}
                />
              </div>
              {values.student_based_on === "Student Group" ? (
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>Student Group*</p>
                  <Select
                    size={selectSize}
                    placeholder="Select"
                    value={values.student_group}
                    className={styles.selectInput}
                    options={studentGroupOptions}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        student_group: value,
                      });
                      setErrors({
                        ...errors,
                        student_group: "",
                      });
                    }}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                  {errors.student_group && (
                    <p className={styles.errorMessage}>
                      {errors.student_group}
                    </p>
                  )}
                </div>
              ) : (
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>Course Schedule *</p>
                  <Select
                    size={selectSize}
                    placeholder="Select"
                    value={values.course_schedule}
                    className={styles.selectInput}
                    options={courseScheduleOptions}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        course_schedule: value,
                      });
                      setErrors({
                        ...errors,
                        course_schedule: "",
                      });
                    }}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                  {errors.course_schedule && (
                    <p className={styles.errorMessage}>
                      {errors.course_schedule}
                    </p>
                  )}
                </div>
              )}
              <div className={styles.field}></div>
            </div>
            <div className={styles.formRow}>
              <Checkbox
                checked={values.mark_as_present === 1}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  const newValue = isChecked ? 1 : 0;
                  setValues({
                    ...values,
                    mark_as_present: newValue,
                  });
                }}
              >
                Mark as Present
              </Checkbox>
            </div>
            <div className={styles.formRow}>
              <div className={styles.textarea}>
                <p className={styles.fieldLabel}>Reason </p>
                <textarea
                  type="text"
                  placeholder="Enter Text here..."
                  className={styles.textAreaInput}
                  value={values.reason}
                  rows={5}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      reason: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default AddNewStudentLeaveApplication;

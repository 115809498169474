import React, { useEffect, useState } from "react";
import { Popover, Badge } from "@mui/material";
import "./filters.css";
import { Checkbox, Select, Input } from "antd";
import { getSavedFilter } from "../../modules/lead";
import { useDispatch, useSelector } from "react-redux";
import { addFilter, clearAllFilters, selectedSaveFilter } from "../../slices/filterSlice";
import useStyles from "./style";

function SavedFilters(type) {
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const { doctype } = type;
  const dispatch = useDispatch();
  const [savedFilters, setSavedFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const { selectedSavedFilters } = useSelector((state) => state.filters);
  const [checkedValues, setCheckedValues] = useState([...selectedSavedFilters]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCheckboxChange = (index) => (event) => {
    setSelectedFilters((prevState) => ({
      ...prevState,
      [index]: event.target.checked,
    }));
    let arr = {...selectedFilters, [index]: event.target.checked };
    const trueIndices = Object.keys(arr).filter(key => arr[key]);
    setCheckedValues(trueIndices);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const getSavedFilters = async () => {
    try {
      const res = await getSavedFilter(doctype);
      if (res.status === 200) {
        setSavedFilters(res?.data.data);
      }
    } catch (error) {
      console.error("Error listing the saved filters:", error);
    }
  };

  useEffect(() => {
    getSavedFilters(doctype);
    const initialSelectedFilters = {};
    selectedSavedFilters.forEach(item => {
      initialSelectedFilters[item] = true;
    });
    setSelectedFilters(initialSelectedFilters);
  }, []);


  const handleClear = () => {
    dispatch(clearAllFilters());
  }

  const handleApplyFilter = () => {
    if(selectedFilters.length === 0) return;
    const selectedFilterObjects = Object.keys(selectedFilters)
      .filter((index) => selectedFilters[index])
      .flatMap((index) => {
        const conditions = JSON.parse(savedFilters[index].filters);
        if (conditions.length === 1) {
          return conditions[0];
        } else {
          return conditions;
        }
      });
    
    const trueIndices = Object.keys(selectedFilters).filter(key => selectedFilters[key]);
    dispatch(addFilter({ doctype: doctype, filters: selectedFilterObjects }));
    dispatch(selectedSaveFilter({ selectedSavedFilters: trueIndices }));
  };

  return (
    <div>
      <div className={styles.button} onClick={handleClick}>
        <p className={styles.buttonText}>Saved Filters</p>
        {selectedSavedFilters.length > 0 && (
          <p style={{ margin: "0px 12px" }}>
            <Badge badgeContent={selectedSavedFilters.length} color="error" />
          </p>
        )}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{ mt: 1 }}
      >
        <div className={styles.savedFiltersContainer}>
          <Input placeholder="Search Filters" value={searchTerm} onChange={handleSearchChange} />
          <div className={styles.checkboxContainer}>
            {savedFilters
              .filter((options) => options.filter_name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
              .map((options, index) => {
                return(
                  <Checkbox 
                    checked={checkedValues.includes(index.toString())} 
                    className={styles.checkboxText} onChange={handleCheckboxChange(index)}>{options.filter_name}</Checkbox>
                )})}
          </div>
          <div className={styles.savedFilters}>
            <p className={styles.nonActiveButton} onClick={handleClear}>Clear</p>
            <p className={styles.applyButton} onClick={handleApplyFilter}>Apply</p>
          </div>
        </div>
      </Popover>
    </div>
  );
}

export default SavedFilters;

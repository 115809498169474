import React, { useCallback, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  FormControl,
  Autocomplete,
  TextField,
  Box,
  Chip,
} from "iiht-b2a-ui-components/build/components";
import CancelIcon from "@mui/icons-material/Cancel";

const FormAutoComplete = React.memo(
  ({
    name,
    rules,
    fullWidth,
    className,
    label,
    size,
    options,
    totalCount,
    handleFetchNext,
    resetSearch,
    multiple,
    isAsterisk,
    onSelectionChange,
    ...rest
  }) => {
    const {
      control,
      formState: { errors },
    } = useFormContext();
    const { getFieldState } = control;
    const [searchUsers, setSearchUsers] = useState("");

    const fetchNext = useCallback(() => {
      if (options?.length < totalCount) {
        const offset = options?.length;
        handleFetchNext?.(searchUsers, offset);
      }
    }, [options, totalCount, handleFetchNext, searchUsers]);

    useEffect(() => {
      if ((searchUsers && searchUsers?.length > 2) || searchUsers == "") {
        resetSearch?.(searchUsers);
      }
    }, [searchUsers]);
    return (
      <FormControl
        margin="normal"
        fullWidth={fullWidth}
        className={className}
        size={size}
        variant="standard"
      >
        <Controller
          name={name}
          control={control}
          rules={rules}
          noRef={true}
          render={({ field }) => {
            const { name, value, onChange, onBlur } = field;
            const { invalid, error } = getFieldState(name);
            return (
              <>
                <Autocomplete
                  freeSolo
                  name={name}
                  id="free-solo-2-demo"
                  multiple={multiple}
                  label={`${label ?? ""}${isAsterisk ? "*" : ""}`}
                  value={Array.isArray(value) ? value : []}
                  onChange={(e, val) => {
                    setSearchUsers("");
                    onChange(val);
                    onSelectionChange(val);
                  }}
                  onBlur={onBlur}
                  error={error ? error.message : undefined}
                  aria-invalid={invalid ? "true" : "false"}
                  size={size}
                  options={options}
                  autoComplete
                  ListboxProps={{
                    style: {
                      maxHeight: "150px",
                      border: "secondary",
                      fontSize: "14px",
                    },
                    onScroll: (event) => {
                      const listboxNode = event.currentTarget;
                      if (
                        listboxNode.scrollTop + listboxNode.clientHeight + 10 >=
                        listboxNode.scrollHeight
                      ) {
                        fetchNext();
                      }
                    },
                  }}
                  renderValue={(selected) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                      }}
                    >
                      {selected?.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          deleteIcon={
                            <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
                          }
                        />
                      ))}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      color="secondary"
                      {...params}
                      label={`${label ?? ""}${isAsterisk ? "*" : ""}`}
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        color: "secondary",
                      }}
                      error={error ? true : false}
                      aria-invalid={invalid ? "true" : "false"}
                      helperText={error?.message}
                      onChange={(e) => {
                        setSearchUsers(e.target.value);
                      }}
                    />
                  )}
                  {...rest}
                />
                {/* <FormHelperText>{error?.message}</FormHelperText> */}
              </>
            );
          }}
        ></Controller>
      </FormControl>
    );
  }
);
export default FormAutoComplete;

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  contentContainer: {
    display: "flex",
    gap: "32px",
    justifyContent: "space-between",
  },
  itemContainer: {
    width: "30%",
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "16px",
    padding: "5px 16px",
    borderRadius: "4px",
    border: "1px solid rgba(236, 236, 236, 0.23)",
    borderLeft: "5px solid black",
    background: "#FFF",
    boxShadow: "1px 4px 7.3px 0px rgba(195, 195, 195, 0.25)",
  },
  itemTitle: {
    color: "#0D1021",
    fontSize: "25px",
    fontWeight: "500",
    fontFamily: "Poppins",
  },
  icon: {
    color: "red" /* Set the color of the icon to red */,
    cursor: "pointer",
    fontSize: "35px",
    fontWeight: "700",
    display: "flex",
    justifyContent: "center",
    width: "50px",
  },
  popupContainer: {
    position: "fixed",
    zIndex: "2",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "10px",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
    width: "500px",
  },
  heading: {
    fontSize: "1.2rem",
    marginBottom: "10px",
  },
  closeIcon: {
    position: "absolute",
    top: "5px",
    right: "5px",
    cursor: "pointer",
  },
  inputContainer: {
    marginTop: "10px",
    textAlign: "left",
  },
  inputField: {
    width: "100%",
    marginBottom: "10px",
  },
  addButton: {
    marginTop: "20px",
    textAlign: "center",
    marginBottom: "30px",
  },
  subelements: {
    display: "flex",
    border: "1px solid #CDD6E2",
    padding: "10px",
    borderRadius: "4px",
    margin: "20px 0px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    width: "100%",
  },
  selectedsubelements: {
    display: "flex",
    border: "1px solid black",
    padding: "10px",
    borderRadius: "4px",
    margin: "20px 0px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    width: "100%",
  },
  link: {
    cursor: "pointer",
    color: "#0D1021",
    fontWeight: "500",
    textDecoration: "underline",
    fontSize: "14px",
  },
  dateInput: {
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: 4,
    width: "100%",
    boxSizing: "border-box",
  },
  fieldInput: {
    color: "#9DA9B0",
    fontSize: "12px",
    fontWeight: "400",
    padding: "0px 12px",
    borderRadius: "8px",
    background: "#fff",
    outline: "none",
    height: "40px",
    border: "1px solid #CDD6E2",
  },
  dateInput: {
    padding: "0 10px",
    height: "40px",
    borderRadius: "8px",
    border: "1px solid #D7D7D7",
    background: "#FFF",
  },
  bigfield: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    borderRadius: "8px",
    paddingBottom: "20px",
  },
  quill: {
    height: "150px",
    marginBottom: "50px",
    borderRadius: "8px",
  },
  article: {
    padding: "0 20px",
    marginBottom: "50px",
  },
  unitname: {
    padding: "0px 10px",
    fontSize: "18px",
  },
}));

export default useStyles;

import MainFooter from "components/common/MainFooter";
import MainHeader from "components/common/MainHeader";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "../style";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Select, Input, Table, Checkbox } from "antd";
import { setActiveSidebar } from "../../../../slices/academicsSlice";
import { GridMenuIcon } from "@mui/x-data-grid";
import {
  getAcademicTermListing,
  getAcademicYearListing,
} from "modules/finance";
import {
  getBatch,
  getCourseListing,
  getInstructor,
  getProgram,
  getStudentCategory,
  getStudentGroupByName,
  getStudents,
  updateStudent,
} from "modules/studentGroup";
import { toast } from "react-toastify";

const GetUpdateStudentGroup = () => {
  const styles = useStyles();
  const { group_name } = useParams();
  const selectSize = "middle";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    academic_year: "",
    academic_term: "",
    group_based_on: "",
    program: "",
    student_group_name: "",
    batch: "",
    max_strength: "",
    student_category: "",
    course: "",
    disabled: "",
    instructors: [{}],
    students: [],
  });
  const { loading } = useSelector((state) => state.common);
  const [academicYearOptions, setAcademicYearOptions] = useState([]);
  const [academicTermOptions, setAcademicTermOptions] = useState([]);
  const [programOptions, setProgramOptions] = useState([]);
  const [batchOptions, setBatchOptions] = useState([]);
  const [studentCategoryOptions, setStudentCategoryOptions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [instructorOptions, setInstructorOptions] = useState([]);
  const [instructorIdList, setInstructorIdList] = useState([]);
  const [studentTableData, setStudentTableData] = useState([]);
  const [showStudentTable, setShowStudentTable] = useState(false);
  const [errors, setErrors] = useState({
    academic_year: "",
    group_based_on: "",
    program: "",
    student_group_name: "",
    course: "",
  });

  const handleGetAcademicYearListing = async () => {
    const res = await getAcademicYearListing();
    if (res.status === 200) {
      const arr = [];
      const acdemicYearList = res.data.data;
      for (let i = 0; i < acdemicYearList.length; i++) {
        arr.push({
          value: acdemicYearList[i].name,
          label: acdemicYearList[i].name,
        });
      }
      setAcademicYearOptions(arr);
    }
  };

  const handleGetAcademicTermListing = async () => {
    const res = await getAcademicTermListing();
    if (res.status === 200) {
      const arr = [];
      const acdemicTermList = res.data.data;
      for (let i = 0; i < acdemicTermList.length; i++) {
        arr.push({
          value: acdemicTermList[i].name,
          label: acdemicTermList[i].name,
        });
      }
      setAcademicTermOptions(arr);
    }
  };

  const groupBasedOnOptions = [
    { value: "Batch", label: "Batch" },
    { value: "Course", label: "Course" },
    { value: "Activity", label: "Activity" },
  ];

  const handleGetProgramListing = async () => {
    const res = await getProgram();
    if (res.status === 200) {
      const arr = [];
      const programList = res.data.data;
      for (let i = 0; i < programList.length; i++) {
        arr.push({
          value: programList[i].name,
          label: programList[i].name,
        });
      }
      setProgramOptions(arr);
    }
  };

  const handleGetBatchListing = async () => {
    const res = await getBatch();
    if (res.status === 200) {
      const arr = [];
      const batchList = res.data.data;
      for (let i = 0; i < batchList.length; i++) {
        arr.push({
          value: batchList[i].name,
          label: batchList[i].name,
        });
      }
      setBatchOptions(arr);
    }
  };

  const handleStudentCategoryListing = async () => {
    const res = await getStudentCategory();
    if (res.status === 200) {
      const arr = [];
      const studentCategoryList = res.data.data;
      for (let i = 0; i < studentCategoryList.length; i++) {
        arr.push({
          value: studentCategoryList[i].name,
          label: studentCategoryList[i].name,
        });
      }
      setStudentCategoryOptions(arr);
    }
  };

  const handleGetInstructorListing = async () => {
    const res = await getInstructor();
    if (res.status === 200) {
      const arr = [];
      const instructorList = res.data.data;
      for (let i = 0; i < instructorList.length; i++) {
        arr.push({
          value: instructorList[i].name,
          label: instructorList[i].name,
        });
      }
      setInstructorIdList(instructorList);
      setInstructorOptions(arr);
    }
  };

  const handleGetCourseListing = async () => {
    const res = await getCourseListing();
    if (res.status === 200) {
      const arr = [];
      const courseList = res.data.data;
      for (let i = 0; i < courseList.length; i++) {
        arr.push({
          value: courseList[i].name,
          label: courseList[i].name,
        });
      }
      setCourseOptions(arr);
    }
  };

  const handleAddInstructor = () => {
    setValues({
      ...values,
      instructors: [...values.instructors, {}],
    });
    setErrors({
      ...errors,
      instructors: [],
    });
  };

  const handleDeleteInstructor = () => {
    const instructors = values.instructors;
    const resultArray = instructors.filter(
      (obj) => !selectedRowKeys.includes(obj.key)
    );
    setSelectedRowKeys([]);
    setValues({
      ...values,
      instructors: resultArray.length > 0 ? resultArray : [{}],
    });
    setErrors({
      ...errors,
      instructors: [],
    });
  };

  const onSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = {
    type: "checkbox",
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      instructor: record.instructor,
    }),
    selectedRowKeys,
  };

  const columns = [
    {
      title: "Instructor",
      dataIndex: "instructor",
      width: "45%",
      render: (val, record, index) => {
        return (
          <Select
            className={styles.tableContentFields}
            size={selectSize}
            placeholder="Select"
            style={{ width: "100%", border: "none !important" }}
            defaultValue={record.instructor}
            options={instructorOptions}
            onChange={(value) => {
              const instructorList = instructorIdList.find(
                (category) => category.name === value
              );
              const componentsArr = values.instructors;
              componentsArr[index] = {
                key: index,
                instructor: instructorList.name,
                instructor_name: instructorList.name,
              };
              setValues({
                ...values,
                instructors: [...componentsArr],
              });
              setErrors({
                ...errors,
                instructors: "",
              });
            }}
          />
        );
      },
    },
    {
      title: "Instructor Name",
      dataIndex: "instructor_name",
      width: "40%",
      render: (val, record) => {
        return (
          <p className={styles.tableContentFields}>{record.instructor_name}</p>
        );
      },
    },
  ];

  const handleGetStudentsListing = async () => {
    try {
      const { academic_year, group_based_on, program } = values;
      const res = await getStudents(academic_year, group_based_on, program);
      const students = res.data.message;
      console.log(students);
      const mappedStudentTableData = students.map((student, i) => ({
        key: i + 1,
        student: student.student,
        student_name: student.student_name,
        active: student.active,
        group_roll_number: i + 1,
      }));

      setStudentTableData(mappedStudentTableData);
      setShowStudentTable(true);
    } catch (error) {
      console.log(error);
    }
  };

  const studentsColumn = [
    {
      title: "Student ID",
      dataIndex: "student",
      key: "student",
    },
    {
      title: "Student Name",
      dataIndex: "student_name",
      key: "student_name",
    },
    {
      title: "Group Roll Number",
      dataIndex: "group_roll_number",
      key: "group_roll_number",
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (active, student) => (
        <Checkbox
          checked={active === 1}
          onChange={() => {
            const newValue = active === 1 ? 0 : 1;
            const updatedStudentTableData = studentTableData.map((s) => {
              if (s.key === student.key) {
                return { ...s, active: newValue };
              }
              return s;
            });
            setStudentTableData(updatedStudentTableData);
          }}
        />
      ),
    },
  ];

  const handleStudentNavigation = () => {
    navigate("/modules/academics");
  };

  const handleGetStudentGroup = async () => {
    try {
      const res = await getStudentGroupByName(group_name);
      const studentGroupData = res.data.data;
      const instructorData = studentGroupData.instructors.map((instructor) => ({
        key: instructor.idx,
        instructor: instructor.instructor || "",
        instructor_name: instructor.instructor_name || "",
      }));
      console.log(studentGroupData.students, "students of student group");
      const studentListData = studentGroupData.students.map((student) => ({
        key: student.idx,
        student: student.student,
        student_name: student.student_name,
        active: student.active,
        group_roll_number: student.group_roll_number,
      }));
      setStudentTableData(studentListData);
      setShowStudentTable(true);
      setValues({
        academic_year: studentGroupData.academic_year,
        academic_term: studentGroupData.academic_term,
        group_based_on: studentGroupData.group_based_on,
        program: studentGroupData.program,
        student_group_name: studentGroupData.student_group_name,
        batch: studentGroupData.batch,
        max_strength: studentGroupData.max_strength,
        student_category: studentGroupData.student_category,
        course: studentGroupData.course,
        disabled: studentGroupData.disabled,
        instructors: instructorData,
        students: studentListData,
      });
    } catch (error) {
      console.error("Error fetching student group:", error);
    }
  };

  const handleValidateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };
    if (!values.academic_year) {
      newErrors.academic_year = "Academic Year is required";
      isValid = false;
    } else {
      newErrors.academic_year = "";
    }
    if (!values.group_based_on) {
      newErrors.group_based_on = "Group Based On is required";
      isValid = false;
    } else {
      newErrors.group_based_on = "";
    }
    if (!values.program) {
      newErrors.program = "Programme is required";
      isValid = false;
    } else {
      newErrors.program = "";
    }
    if (!values.student_group_name) {
      newErrors.student_group_name = "Student Group Name is required";
      isValid = false;
    } else {
      newErrors.student_group_name = "";
    }
    if (values.group_based_on === "Course" && !values.course) {
      newErrors.course = "Course is required";
      isValid = false;
    } else {
      newErrors.course = "";
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleUpdate = async () => {
    const isValid = handleValidateForm();
    if (isValid) {
      try {
        const payload = {
          ...values,
          doctype: "Student Group",
        };
        const res = await updateStudent({ id: group_name, payload });
        if (res.status === 200) {
          toast.success("Student Group updated");
          console.log(res);
          navigate(`/modules/academics/student-group/${res.data.data.name}`);
        } else {
          toast.error("Error while updating Student Group!");
        }
      } catch (error) {
        toast.error(error.response.data.exception);
      }
    }
  };

  useEffect(() => {
    handleGetStudentGroup();
  }, [group_name]);

  useEffect(() => {
    handleGetAcademicYearListing();
    handleGetAcademicTermListing();
    handleGetProgramListing();
    handleGetBatchListing();
    handleStudentCategoryListing();
    handleGetInstructorListing();
    handleGetCourseListing();
  }, []);

  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      students: studentTableData.map((student) => ({
        student: student.student,
        student_name: student.student_name,
        active: student.active,
        group_roll_number: student.group_roll_number,
      })),
    }));
  }, [studentTableData]);

  return (
    <>
      <MainHeader />
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/dashboard");
                dispatch(setActiveSidebar({ activeSidebar: 0 }));
              }}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/modules/academics");
                dispatch(setActiveSidebar({ activeSidebar: 0 }));
              }}
            >
              Academics {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/modules/academics");
                dispatch(setActiveSidebar({ activeSidebar: 4 }));
              }}
            >
              Student Group {" > "}
            </p>
            <p className={styles.breadCrumbsContent}>{group_name}</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>{group_name}</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div className={styles.button} onClick={handleStudentNavigation}>
                <p className={styles.buttonText}>Back</p>
              </div>
              <div className={styles.activeButton}>
                <p className={styles.activeButtonText} onClick={handleUpdate}>
                  Update
                </p>
              </div>
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Academic Year *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.academic_year}
                  className={styles.selectInput}
                  options={academicYearOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      academic_year: value,
                    });
                    setErrors({
                      ...errors,
                      academic_year: "",
                    });
                  }}
                />
                {errors.academic_year && (
                  <p className={styles.errorMessage}>{errors.academic_year}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Academic Term</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.academic_term}
                  className={styles.selectInput}
                  options={academicTermOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      academic_term: value,
                    });
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Group Based On *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.group_based_on}
                  className={styles.selectInput}
                  options={groupBasedOnOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      group_based_on: value,
                    });
                    setErrors({
                      ...errors,
                      group_based_on: "",
                    });
                  }}
                />
                {errors.group_based_on && (
                  <p className={styles.errorMessage}>{errors.group_based_on}</p>
                )}
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Programme *</p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.program}
                  className={styles.selectInput}
                  options={programOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      program: value,
                    });
                    setErrors({
                      ...errors,
                      program: "",
                    });
                  }}
                />
                {errors.program && (
                  <p className={styles.errorMessage}>{errors.program}</p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Student Group Name *</p>
                <Input
                  placeholder="ex Text"
                  disabled={true}
                  value={values.student_group_name}
                  className={styles.fieldInput}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      student_group_name: e.target.value,
                    });
                    setErrors({
                      ...errors,
                      student_group_name: "",
                    });
                  }}
                />
                {errors.student_group_name && (
                  <p className={styles.errorMessage}>
                    {errors.student_group_name}
                  </p>
                )}
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Batch </p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.programme}
                  className={styles.selectInput}
                  options={batchOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      batch: value,
                    });
                    setErrors({
                      ...errors,
                      batch: "",
                    });
                  }}
                />
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Max Strength </p>
                <Input
                  placeholder="ex Text"
                  value={values.max_strength}
                  className={styles.fieldInput}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      max_strength: e.target.value,
                    });
                  }}
                />
              </div>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Student Category </p>
                <Select
                  size={selectSize}
                  placeholder="Select"
                  value={values.student_category}
                  className={styles.selectInput}
                  options={studentCategoryOptions}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      student_category: value,
                    });
                    setErrors({
                      ...errors,
                      student_category: "",
                    });
                  }}
                />
              </div>
              {values.group_based_on === "Course" ? (
                <div className={styles.field}>
                  <p className={styles.fieldLabel}>Course *</p>
                  <Select
                    size={selectSize}
                    placeholder="Select"
                    value={values.course}
                    className={styles.selectInput}
                    options={courseOptions}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        course: value,
                      });
                      setErrors({
                        ...errors,
                        course: "",
                      });
                    }}
                  />
                  {errors.course && (
                    <p className={styles.errorMessage}>{errors.course}</p>
                  )}
                </div>
              ) : (
                <div className={styles.field}></div>
              )}
            </div>
            <div className={styles.formRow}>
              <Checkbox
                checked={values.disabled === 1}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  const newValue = isChecked ? 1 : 0;
                  setValues({
                    ...values,
                    disabled: newValue,
                  });
                }}
              >
                Disabled
              </Checkbox>
            </div>
            <div className={styles.formRow}>
              <div className={styles.activeButton}>
                <p
                  className={styles.activeButtonText}
                  onClick={handleGetStudentsListing}
                >
                  Get Students
                </p>
              </div>
            </div>
            {showStudentTable && (
              <div className={styles.formRow}>
                <div className={styles.tableField}>
                  <p className={styles.fieldLabel}>Students</p>
                  <Table
                    columns={studentsColumn}
                    dataSource={studentTableData}
                    size="small"
                    pagination={false}
                  />
                </div>
              </div>
            )}
            <div className={styles.formRow}>
              <div className={styles.tableField}>
                <p className={styles.fieldLabel}>Instructor </p>
                <Table
                  rowSelection={{ type: "checkbox", ...rowSelection }}
                  columns={columns}
                  dataSource={values.instructors}
                  size="small"
                  pagination={false}
                />
                {errors.instructors && (
                  <p className={styles.errorMessage}>{errors.instructors}</p>
                )}
              </div>
            </div>
            <div className={styles.fieldButtons}>
              {selectedRowKeys.length > 0 && (
                <div
                  className={styles.deleteRow}
                  onClick={handleDeleteInstructor}
                >
                  Delete
                </div>
              )}
              <div className={styles.addRow} onClick={handleAddInstructor}>
                +
              </div>
            </div>
          </div>
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default GetUpdateStudentGroup;

import * as React from "react";
import FormContainer from "./FormContainer";
import { MainLayout } from "components/common";

export default function DashboardLanding() {
  return (
    <MainLayout>
      <FormContainer />
    </MainLayout>
  );
}

import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
} from 'iiht-b2a-ui-components/build/components';
import IconButton from 'iiht-b2a-ui-components/build/components/IconButton';
import DialogActions from 'iiht-b2a-ui-components/build/components/DialogActions';
import Button from 'iiht-b2a-ui-components/build/components/Button';
import { CloseIcon } from '../../../icons';
import { theme } from '../../../themes';

export const CommonModal = (props) => {
  const {
    open,
    onClose,
    modalTitle,
    children,
    showDividers,
    onSubmit,
    cancelBtnName,
    submitBtnName,
    maxWidth,
    fullWidth,
    hideActions = false,
    pastBrodcast,
    redirectHandler,
    btnTitle,
    displayClose = true,
    commonModalStyle = '',
    isSampleCsvFile = false,
    csvFile,
    csvFileName,
    hideSubmitButton = false,
  } = props;
  return (
    <Dialog
      open={open}
      onBackdropClick={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      classes={{ paper: commonModalStyle?.paper }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} onClose={onClose}>
        {modalTitle}
        {displayClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 9,
            }}
            className="qa-close-icon"
          >
            <CloseIcon fill={theme.palette.secondary.light} />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers={showDividers}>{children}</DialogContent>
      {!hideActions && (
        <DialogActions>
          {pastBrodcast && (
            <Button
              variant="text"
              sx={{ marginRight: 'auto', ml: 1 }}
              onClick={redirectHandler}
              className="qa-redirect-btn"
            >
              {btnTitle}
            </Button>
          )}
          {isSampleCsvFile && (
            <Box sx={{ marginRight: 'auto', ml: 1 }}>
              <a href={csvFile} download={csvFileName}>
                <Button variant="text" className="qa-redirect-btn">
                  {btnTitle}
                </Button>
              </a>
            </Box>
          )}
          <Button variant="grayed" onClick={onClose} className="qa-cancel-btn">
            {cancelBtnName}
          </Button>
          {hideSubmitButton == false && (
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              onClick={onSubmit}
              className="qa-submit-btn"
            >
              {submitBtnName}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

import React from "react";
import DOMPurify from "dompurify";
import useStyles from "./topic-view-styles";

const GetTopicDetails = ({ details, setSelectedTopic, setTopicDetails }) => {
  const styles = useStyles();
  const { title, content, author, publish_date } = details;

  const handleBack = () => {
    setSelectedTopic("");
    setTopicDetails({});
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerContainer}>
        <p className={styles.title}>{title}</p>
        <div className={styles.activeButton} onClick={handleBack}>
          <p className={styles.activeButtonText}>Back</p>
        </div>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.field}>
          <p className={styles.fieldLabel}>Title</p>
          <p className={styles.labelValue}>{title || "-"}</p>
        </div>
        <div className={styles.field}>
          <p className={styles.fieldLabel}>Author Name</p>
          <p className={styles.labelValue}>{author || "-"}</p>
        </div>
        <div className={styles.field}>
          <p className={styles.fieldLabel}>Content</p>
          <div
            className={styles.labelValue}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(content || "-"),
            }}
          />
        </div>
        <div className={styles.field}>
          <p className={styles.fieldLabel}>Publish Date</p>
          <p className={styles.labelValue}>{publish_date || "-"}</p>
        </div>
      </div>
    </div>
  );
};

export default GetTopicDetails;

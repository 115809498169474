// AcademicsDashboard.js
import { Box, Button } from "@mui/material";
import { GridMenuIcon } from "@mui/x-data-grid";
import React from "react";
import { Navigate } from "react-router-dom";
export default function AcademicsDashboard() {
  const handleBackButtonClick = () => {
    // Use navigate to go back to the previous page
    Navigate(-1);
  };
  const CustomToolbar = () => {
    return (
      <>
        <div className="master-heading">
          <h2>
            <GridMenuIcon />
            {`Dashboard`}
          </h2>
          <div className="btnsGrp" onClick={handleBackButtonClick}>
            <Button variant="outlined">Back</Button>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <CustomToolbar />
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3 }}
        className="mainPanel noPadding"
      >
        <>
           <h1>Dashboard Content  will be here soon</h1>
        </>
      </Box>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GridMenuIcon } from "@mui/x-data-grid";
import { DeleteOutlined } from "@material-ui/icons";
import { Table } from "antd";
import {
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "iiht-b2a-ui-components/build/components";
import {
  CustomBreadcrumb,
  FormDatepicker,
  FormSelectField,
  FormTextField,
  MainLayout,
} from "components/common";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { validationRules } from "constants/validationRules";
import {
  createGenericData,
  getGenericDataApi,
  getGenericListApi,
  getInstitution,
  updateGenericData,
} from "modules/hr";
import { addressMapper } from "utils";
import { AddBoxIcon } from "icons";
import { setLoading } from "slices/commonSlice";

const CreateJobOffer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const applicantName = location.state?.applicantName || "Job Offer";
  const { id: jobApplicantId, jobOpeningId, designationId, jobOfferId } = useParams();
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [jobOfferData, setJobOfferData] = useState({});
  const [jobOfferTermTemplatesList, setJobOfferTermTemplatesList] = useState([]);
  const [jobOfferTermsList, setJobOfferTermsList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [jobOfferTermsRows, setJobOfferTermsRows] = useState([
    {
      id: 0,
      key: 0,
      number: 0,
      interviewer: "",
    },
  ]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const isEditMode = !!(location.pathname.includes("edit-view") && jobApplicantId && jobOfferId);
  const [isSaveBtnDisable, setIsSaveBtnDisable] = useState(isEditMode);
  const { loading } = useSelector((state) => state.common);

  const breadcrumbs = [
    {
      label: "IIHT",
      link: "/dashboard",
    },
    {
      label: "HR",
      link: "/modules/hr",
    },
    {
      label: "Recruitment",
      link: "/modules/hr",
    },
    {
      label: "Job Applications",
      link: "/modules/hr/job-application",
    },
    {
      label: applicantName === "Job Offer" ? jobApplicantId : applicantName,
      link: `/modules/hr/job-application/view/${jobApplicantId}/${jobOpeningId}/${designationId}?tab=4`,
    },
    {
      label: isEditMode ? "New Job Offer" : "View Job Offer",
      link: "",
    },
  ];

  const columns = [
    {
      dataIndex: "number",
      title: "SNo.",
      width: "10%",
      render: (_val, _row, rowIndex) => rowIndex + 1,
    },
    {
      dataIndex: "offer_term",
      title: "Offer Term",
      width: "40%",
      render: (val, _row, rowIndex) => {
        return val;
        // <div style={{ width: "200px" }}>
        //   <FormSelectField
        //     name={`jobOfferTerms.${rowIndex}.interviewer`}
        //     variant="outlined"
        //     label=""
        //     size="small"
        //     fullWidth
        //     onChange={(e) => {
        //       setValue(`jobOfferTerms.${rowIndex}.interviewer`, e.target.value);
        //       setIsSaveBtnDisable(false);
        //     }}
        //     options={jobOfferTermsList}
        //   />
        // </div>
      },
    },
    {
      dataIndex: "value",
      title: "Value / Description",
      width: "50%",
    },
  ];

  const statusOptions = [
    {
      id: 1,
      key: 1,
      label: "Awaiting Response",
      value: "Awaiting Response",
    },
    {
      id: 2,
      key: 2,
      label: "Accepted",
      value: "Accepted",
    },
    {
      id: 3,
      key: 3,
      label: "Rejected",
      value: "Rejected",
    },
  ];

  const defaultValues = {
    job_applicant: jobApplicantId || "",
    applicant_name: applicantName || "",
    applicant_email: location.state?.emailId || "",
    designation: designationId || "",
    status: "",
    job_offer_term_template: "",
    company: "",
    offer_date: "",
    // jobOfferTerms: [],
  };

  const methods = useForm({
    defaultValues: defaultValues,
  });

  const { handleSubmit, reset, watch, getValues, setValue, control } = methods;

  // const { append: skillsAppend, remove: skillsRemove } = useFieldArray({
  //   control: control,
  //   name: "jobOfferTerms",
  // });

  const jobOfferTermTemplateWatch = watch("job_offer_term_template");

  const onSubmit = async (data) => {
    dispatch(setLoading({ loading: true }));
    const offer_terms = jobOfferTermsList.map((offer) => {
      return {
        offer_term: offer.offer_term,
        value: offer.value,
      };
    });
    const payload = {
      ...data,
      doctype: "Job Offer",
      id: jobOfferId,
      offer_terms,
    };
    const res = isEditMode ? await updateGenericData(payload) : await createGenericData(payload);
    if (res?.status === 200) {
      isEditMode && fetchJobOffer();
      toast.success(`Job offer ${isEditMode ? "Updated" : "Created"}!`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsSaveBtnDisable(true);
      setIsFormSubmited(true);
    } else {
      toast.error(res?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    dispatch(setLoading({ loading: false }));
  };

  const fetchJobOffer = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Job Offer",
      id: jobOfferId,
    };
    const res = await getGenericDataApi(params);
    if (res?.status === 200) {
      const data = res?.data?.message;
      setJobOfferData(data);
      reset({
        job_offer_term_template: data?.job_offer_term_template,
        status: data?.status,
        applicant_name: data?.applicant_name ?? "",
        applicant_email: data?.applicant_email,
        designation: data?.designation,
        company: data?.company,
        offer_date: data?.offer_date ?? "",
      });
    }
    dispatch(setLoading({ loading: false }));
  };

  const fetcJobOfferTermTemplatesList = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Job Offer Term Template",
      fields: JSON.stringify(["*"]),
      filters: [],
      limit: "*",
    };
    const res = await getGenericListApi(params);
    if (res?.status === 200) {
      if (res.data?.message?.length) {
        const list = addressMapper(res.data.message, "title", "id", true);
        setJobOfferTermTemplatesList(list);
      } else {
        setJobOfferTermTemplatesList([]);
      }
    }
    await fetchCompanyList();
  };

  const fetchCompanyList = async () => {
    const res = await getInstitution();
    if (res?.status === 200) {
      if (res?.data?.data?.length) {
        const list = addressMapper(res.data.data, "name", "name", true);
        setCompanyList(list);
      } else {
        setCompanyList([]);
      }
    }
    dispatch(setLoading({ loading: false }));
  };

  const fetchJobOfferTermsList = async (id) => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Job Offer Term Template",
      id,
    };
    const res = await getGenericDataApi(params);
    if (res?.status === 200) {
      const data = res.data?.message;
      if (data.offer_terms?.length) {
        const list = data.offer_terms.map((offer, i) => {
          return {
            ...offer,
            id: offer.offer_term,
            key: i,
          };
        });
        setJobOfferTermsList(list);
      } else {
        setJobOfferTermsList([]);
      }
    }
    dispatch(setLoading({ loading: false }));
  };

  const onSelectChange = (selectedKeys, selectedRowsData) => {
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      id: record.id,
    }),
  };

  // const handleAddRow = () => {
  //   setJobOfferTermsRows((prev) => {
  //     return [
  //       ...prev,
  //       {
  //         id: jobOfferTermsRows.length,
  //         key: jobOfferTermsRows.length,
  //         number: jobOfferTermsRows.length,
  //         interviewer: "",
  //       },
  //     ];
  //   });
  //   skillsAppend({
  //     interviewer: "",
  //   });
  // };

  // const handleDeleteRow = () => {
  //   const skillData = [...jobOfferTermsRows];
  //   const data = [];
  //   let i = 0;
  //   skillData.forEach((skill, index) => {
  //     if (!selectedRowKeys.includes(skill.key)) {
  //       data.push({ ...skill, id: i, key: i, number: i });
  //       i++;
  //     }
  //   });
  //   setJobOfferTermsRows(data);
  //   skillsRemove(selectedRowKeys);
  //   setSelectedRowKeys([]);
  //   const { interviewers } = getValues();
  //   reset({
  //     ...getValues(),
  //     interviewers: interviewers,
  //   });
  //   setIsSaveBtnDisable(false);
  // };

  useEffect(() => {
    if (isEditMode) {
      fetchJobOffer();
    }
  }, [isEditMode]);

  useEffect(() => {
    if (jobOfferTermTemplateWatch) {
      fetchJobOfferTermsList(jobOfferTermTemplateWatch);
    }
  }, [jobOfferTermTemplateWatch]);

  useEffect(() => {
    fetcJobOfferTermTemplatesList();
  }, []);

  return (
    <MainLayout>
      {loading && <LoadingBackdrop open={loading} />}
      <div className="mb20">
        <CustomBreadcrumb breadcrumbs={breadcrumbs} />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={1} alignItems="center">
                <GridMenuIcon className="gridIcon" />
                <Typography color="#0D1021" fontSize={22}>
                  {isEditMode ? "View Job Offer" : "New Job Offer"}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={3}>
                <Button
                  variant="contained"
                  color="grey"
                  className="qa-back-btn"
                  size="large"
                  onClick={() =>
                    navigate(
                      `/modules/hr/job-application/view/${jobApplicantId}/${jobOpeningId}/${designationId}?tab=4`
                    )
                  }
                >
                  Back
                </Button>
                {!isEditMode && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="qa-save-btn"
                    disabled={isSaveBtnDisable}
                  >
                    {isEditMode ? "Update" : "Save"}
                  </Button>
                )}
              </Stack>
            </Stack>

            <Grid
              container
              rowSpacing={2}
              columnSpacing={5}
              display="flex"
              flexDirection="row"
              alignItems="start"
              sx={{ mt: 1 }}
            >
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Job Applicant *
                </Typography>
                <FormTextField
                  name="job_applicant"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                  className="mt1"
                />
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Applicant Name *
                </Typography>
                <FormTextField
                  name="applicant_name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                  className="mt1"
                />
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Job Applicant Email *
                </Typography>
                <FormTextField
                  name="applicant_email"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                  className="mt1"
                />
              </Grid>

              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Status *
                </Typography>
                <FormSelectField
                  name="status"
                  variant="outlined"
                  size="small"
                  fullWidth
                  className="mt1"
                  options={statusOptions}
                  disabled={isEditMode}
                  rules={{
                    required: validationRules.REQUIRED,
                  }}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12} sx={{ mb: 1 }}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Offer Date *
                </Typography>
                <FormDatepicker
                  name="offer_date"
                  fullWidth
                  className="mt1"
                  disabled={isEditMode}
                  rules={{
                    required: validationRules.REQUIRED,
                  }}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Designation *
                </Typography>
                <FormTextField
                  name="designation"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                  className="mt1"
                />
              </Grid>

              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Institution *
                </Typography>
                <FormSelectField
                  name="company"
                  variant="outlined"
                  label=""
                  size="small"
                  fullWidth
                  className="mt1"
                  disabled={isEditMode}
                  options={companyList}
                  rules={{
                    required: validationRules.REQUIRED,
                  }}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Typography variant="bodyText" color="#012148" fontWeight={500}>
                  Job Offer Term Template *
                </Typography>
                <FormSelectField
                  name="job_offer_term_template"
                  variant="outlined"
                  label=""
                  size="small"
                  fullWidth
                  className="mt1"
                  disabled={isEditMode}
                  options={jobOfferTermTemplatesList}
                  rules={{
                    required: validationRules.REQUIRED,
                  }}
                />
              </Grid>
            </Grid>

            {jobOfferTermTemplateWatch && (
              <div className="my6">
                <Typography color="#012148" fontSize={14} fontWeight="500">
                  Job Offer Terms *
                </Typography>
                <Table
                  rowSelection={{ type: "checkbox", ...rowSelection }}
                  columns={columns}
                  dataSource={jobOfferTermsList}
                  size="small"
                  pagination={false}
                />
                {/* <div className="mt1">
                  <IconButton color="primary" onClick={handleAddRow}>
                    <AddBoxIcon fill="#A32930" />
                  </IconButton>
                  {selectedRowKeys.length > 0 && (
                    <IconButton aria-label="delete" color="primary" onClick={handleDeleteRow}>
                      <DeleteOutlined />
                    </IconButton>
                  )}
                </div> */}
              </div>
            )}
          </form>
        </FormProvider>
      </div>
    </MainLayout>
  );
};

export default CreateJobOffer;

import ArrowDownSVG from "../../../../assets/images/arrow-down.svg";
import { setActiveSidebar } from "../../../../slices/academicsSlice";
import FiltersSVG from "../../../../assets/images/filters-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { Table } from "antd";
import useStyles from "./style";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { GridMenuIcon } from "@mui/x-data-grid";
import MainHeader from "components/common/MainHeader";
import MainFooter from "components/common/MainFooter";
import { useEffect, useState } from "react";
import { getAllEmployeeAttendanceListing } from "modules/academics";

const EmployeeAttendance = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const styles = useStyles();
  const { loading } = useSelector((state) => state.common);
  const [attendanceList, setAttendanceList] = useState([]);

  const columns = [
    {
      dataIndex: "employee_name",
      title: "EMPLOYEE NAME",
      width: 1,
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.employee_name}</p>;
      },
    },
    {
      dataIndex: "id",
      title: "ATTENDANCE ID",
      width: 0.8,
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.id}</p>;
      },
    },
    {
      dataIndex: "attendance_date",
      title: "DATE",
      width: 0.7,
      render: (val, row) => {
        return (
          <p className={styles.tableContentFields}>{row.attendance_date}</p>
        );
      },
    },
    {
      dataIndex: "status",
      title: "STATUS",
      width: 0.8,
      render: (val, row) => {
        return (
          <p
            className={
              row.status === "Absent"
                ? styles.buttonPublish
                : styles.buttonUnPublish
            }
          >
            {row.status}
          </p>
        );
      },
    },
    {
      dataIndex: "action",
      title: "ACTION",
      width: 0.5,
      render: (val, row) => {
        return (
          <p
            className={styles.tableContentView}
            onClick={() =>
              navigate(`/modules/hr/employee-attendance/${row.id}`)
            }
          >
            View
          </p>
        );
      },
    },
  ];

  const sortedDataSource = [...attendanceList].sort((a, b) => {
    return b.id.localeCompare(a.id);
  });

  const handleGetAllEmployeeAttendance = async () => {
    const res = await getAllEmployeeAttendanceListing();
    if (res.status === 200) {
      const employeeList = [];
      for (let i = 0; i < res.data.message.length; i++) {
        employeeList.push({
          key: res.data.message[i].id,
          employee_name: res.data.message[i].employee_name,
          id: res.data.message[i].id,
          attendance_date: res.data.message[i].attendance_date,
          status: res.data.message[i].status,
        });
      }
      setAttendanceList(employeeList);
    } else {
      console.log("Something went wrong while getting employee data");
    }
  };

  const handleAttendanceNavigation = () => {
    dispatch(setActiveSidebar({ activeSidebar: 3 }));
    navigate("/modules/hr");
  };

  useEffect(() => {
    handleGetAllEmployeeAttendance();
  }, []);

  return (
    <>
      <MainHeader />{" "}
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/dashboard");
                dispatch(setActiveSidebar({ activeSidebar: 0 }));
              }}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/modules/hr");
                dispatch(setActiveSidebar({ activeSidebar: 0 }));
              }}
            >
              Hr {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => handleAttendanceNavigation()}
            >
              Attendance {" > "}
            </p>
            <p className={styles.breadCrumbsContent}>Employee Attendance</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>Employee Attendance</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div className={`${styles.button} ${styles.filterButton}`}>
                <ReactSVG src={FiltersSVG} />
                <p className={styles.buttonText}>Filters</p>
                <ReactSVG src={ArrowDownSVG} />
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>Saved Filters</p>
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>...</p>
              </div>
              <div
                className={styles.activeButton}
                onClick={() =>
                  navigate("/modules/hr/add-new-employee-attendance")
                }
              >
                <p className={styles.activeButtonText}>
                  Add Employee Attendance
                </p>
              </div>
            </div>
          </div>
          <Table
            rowSelection={{ type: "checkbox" }}
            columns={columns}
            dataSource={sortedDataSource}
            size="small"
            pagination={true}
          />
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default EmployeeAttendance;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, CardContent, Container, Typography } from "@material-ui/core";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { getPaymentReceipt } from "../../modules/studentApplication";
import LoadingBackdrop from "../../components/common/LoadingBackdrop.js";
import { useParams } from "react-router-dom";

const Receipt = () => {
  const { name, type } = useParams();
  const [message, setMessage] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
      formatPayload();
  }, []);

  const formatPayload = async () => {
    const doc = type == 0 ? "Fees" : "Sales Invoice"
    if(name && doc) {
      const res = await getPaymentReceipt({ doc, name });
      if(res.status === 200) {
        setMessage(res.data.message);
        setIsLoading(false);
      } else {
        setIsError(true);
        setIsLoading(false);
      }
    }
  }
  
  const handleDownloadButtonClick = () => {
    setIsLoading(true);
    const element = document.getElementById('pdf-container');

    html2canvas(element)
      .then((canvas) => {
        const pdf = new jsPDF('p', 'mm', 'a4');
        pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 210, 297);
        pdf.save('invoice.pdf');
      });
      setIsLoading(false);  
  };

  return (
    <>
    {
      isError ? 
      <p>Something went wrong</p> :
      isLoading ? 
      <LoadingBackdrop open={isLoading} />:
      <Container maxWidth="sm" style={{ marginTop: "50px" }}>
        <Card style={{ border: "2px solid #000", borderRadius: "10px" }}>
          <CardContent>
            <div id="pdf-container" dangerouslySetInnerHTML={{ __html: `${message.html}<style>${message.style}</style>` }}></div>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <a href="#" style={{ textDecoration: "none", cursor: "pointer" }} onClick={handleDownloadButtonClick}>
                <Typography variant="body2" color="primary">
                  Download Receipt
                </Typography>
              </a>
            </div>
          </CardContent>
        </Card>
      </Container>
    }
    </>
  );
};

export default Receipt;

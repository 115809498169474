import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  heading: {
    color: "#1C385C",
    fontSize: "22px",
    fontWeight: "600",
    textAlign: "center",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  inputText: {
    color: "#536882",
    fontSize: "12px",
    fontWeight: "400",
    padding: "12px",
    borderRadius: "5px",
    border: "0px",
    background: "#fff",
    outline: "none",
  },
  buttonfieldsContainer: {
    display: "inline-flex",
    gap: "6px",
  },
  buttonFieldInputFirst: {
    color: "#536882",
    fontSize: "12px",
    fontWeight: "400",
    padding: "12px",
    borderRadius: "5px",
    border: "0px",
    background: "#fff",
    width: "70%",
    outline: "none",
  },
  buttonFieldInputSecond: {
    backgroundColor: "#868175",
    fontSize: "12px",
    fontWeight: "500",
    padding: "12px 14px",
    color: "#FFF",
    borderRadius: "5px",
    width: "40%",
    textAlign: "center",
    border: "0px",
    cursor: "pointer",
  },
  dropDownContainer: {
    display: "inline-flex",
    gap: "16px",
  },
  dropDownInputText: {
    color: "#536882",
    fontSize: "12px",
    fontWeight: "400",
    padding: "12px",
    borderRadius: "5px",
    border: "0px",
    background: "#fff",
    outline: "none",
    width: "100%",
  },
  termsAndConditionsContainer: {
    display: "inline-flex",
    gap: "8px",
    alignItems: "flex-start",
    "& input": {
      margin: "10px 0 0 0",
    },
  },
  termsAndConditionsText: {
    color: "#1C385C",
    fontSize: "12px",
    fontWeight: "400",
  },
  submitButton: {
    backgroundColor: "#868175",
    padding: "12px 0px",
    textAlign: "center",
    borderRadius: "5px",
    color: "#FFF",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "#5a5a5a", // Change the color for hover
    },
  },
  loginText: {
    color: "#1C385C",
    fontSize: "12px",
    fontWeight: "400",
    textAlign: "center",
    cursor: "pointer",
  },
  activeButton: {
    backgroundColor: "#1C385C",
    border: "0px",
  },
  successMessage: {
    color: "green",
    fontSize: "10px",
  },
  errorMessage: {
    color: "red",
    fontSize: "10px",
  },
}));

export default useStyles;

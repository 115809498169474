import React, { useEffect, useState } from 'react';
import useStyles from './style';
import { useNavigate } from 'react-router-dom';
import { GridMenuIcon } from '@mui/x-data-grid';
import { Select } from 'antd';
import { getFeedbackDashboard, getFeedbackTemplateId } from 'modules/survey';
import Star from '../../../assets/images/star.svg';
import highlightedStar from '../../../assets/images/kid_star (1).svg';

const SurveyDashboard = ({ setActiveModule }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const [feedbackTemplate, setFeedbackTemplate] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [questions, setQuestions] = useState([]);

  const handleGetFeedbackTemplate = async () => {
    const res = await getFeedbackTemplateId();
    if (res.status === 200) {
      const arr = res.data.data.map(item => ({
        value: item.name,
        label: item.name,
      }));
      setFeedbackTemplate(arr);
    }
  };

  const selectTemplate = async () => {
    const res = await getFeedbackDashboard(selectedTemplate);
    if (res.status === 200) {
      const template = res.data;
      setFeedbackTemplate(template);
      const inputQuestions = [];
      const selectQuestions = [];
      const ratingQuestions = [];
      template.message.forEach((item) => {
        switch (item.type) {
          case 'Input':
            inputQuestions.push(item);
            break;
          case 'Select':
            selectQuestions.push(item);
            break;
          case 'Rating':
            ratingQuestions.push(item);
            break;
          default:
            break;
        }
      });
      const allQuestions = inputQuestions.concat(selectQuestions, ratingQuestions);
      setQuestions(allQuestions);
    }
  };

  console.log(questions)

  const handleTemplateChange = (value) => {
    setSelectedTemplate(value);
  };

  useEffect(() => {
    handleGetFeedbackTemplate();
  }, []);

  useEffect(() => {
    selectTemplate();
  }, [selectedTemplate]);

  const StarRating = ({ rating }) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      const highlighted = i < rating;
      stars.push(
        <img
          key={i}
          src={highlighted ? highlightedStar : Star}
          alt="star"
        />
      );
    }
    return <>{stars}</>;
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.breadCrumbsContainer}>
        <p className={styles.breadCrumbsContent} onClick={() => navigate('/dashboard')}>
          IIHT {' > '}
        </p>
        <p className={styles.breadCrumbsContent} onClick={() => setActiveModule(0)}>
          Survey {' > '}
        </p>
        <p className={styles.breadCrumbsContent}>Dashboard</p>
      </div>
      <div className={styles.headerContainer}>
        <div className={styles.breadCrumbsContainer}>
          <GridMenuIcon className={styles.gridIcon} />
          <p className={styles.title}>Feedback Dashboard</p>
        </div>
        <Select
          className={styles.fieldSelect}
          options={feedbackTemplate}
          placeholder="Select Feedback Template"
          value={selectedTemplate}
          onChange={handleTemplateChange}
        />
      </div>
      {feedbackTemplate.length === 0 ? (
        <div className={styles.emptyFeedbackTemplate}>
          <h2>No feedback template selected.</h2>
        </div>
      ) : (
        <>
          {questions && questions.length > 0 && (
            <div className={styles.inputContainer}>
              {questions.map((question, index) => (
                <div key={index} className={styles.questionContainer} style={{ width: '100%' }}>
                  <h4>Question {index + 1}</h4>
                  <p>{question.question}</p>
                  <div className={styles.commentsBox}>
                    {/* Render responses based on the question type */}
                    {question.type === 'Input' && (
                      question.responses.map((response, i) => (
                        <React.Fragment key={i}>
                          <div className={styles.answer}>Response {i + 1}: {response.comment}</div>
                          {i !== question.responses.length - 1 && <hr className={styles.responseLine} />}
                        </React.Fragment>
                      ))
                    )}
                    {question.type === 'Select' && (
                      Object.entries(question.responses[0]).map(([option, count], i) => (
                        <React.Fragment key={option}>
                          <div className={styles.answers}>
                            {option}: {count}
                          </div>
                          {i !== 3 && <hr className={styles.responseLine} />}
                        </React.Fragment>
                      ))
                    )}
                    {question.type === 'Rating' && (
                      Object.entries(question.responses[0]).map(([rating, count], i) => (
                        <React.Fragment key={rating}>
                          {parseInt(rating) > 0 && (
                            <div className={styles.stars}>
                              <StarRating rating={parseInt(rating)}  />: {count}
                            </div>
                          )}
                          {i !== 5 && <hr className={styles.responseLine} />}
                        </React.Fragment>
                      ))
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SurveyDashboard;

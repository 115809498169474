import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import LoadingBackdrop from "../../common/LoadingBackdrop.js";
import { Table, Modal, Upload, Menu, Dropdown, Input, Popover } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { createNewFolder, cutPasteFile, getFilesListing, uploadFile } from "modules/dms";
import { setLoading } from "slices/commonSlice";
import CommonFilters from "components/Filters/CommonFilters";
import SavedFilters from "components/Filters/SavedFilters";
import FileIcon from "assets/images/file-name.svg";
import FolderIcon from "assets/images/folder-name.svg"
import { toast } from "react-toastify";
import { formatDateFile } from "utils/date";
import { genericBulkDelete } from "modules/common";
import DeleteConfirmationDialog from "components/common/DeleteConfirmation";
import eventBus from "core/eventBus";

const DocumentManagement = ({ setActiveModule }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const { doctype, filters } = useSelector((state) => state.filters);
  const [importModalVisible, setImportModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [folderModalVisible, setFolderModalVisible] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [pasteEnabled, setPasteEnabled] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [folderName, setFolderName] = useState("Home");
  const [newFolder, setNewFolder] = useState("");
  const [listData, setListData] = useState([]);
  const [fileList, setFileList] = useState(null);
  const [openActions, setOpenActions] = useState(false);
  const [recordType, setRecordType] = useState("");
  const [error, setError] = useState("");

  const columns = [
    {
      dataIndex: "fileName",
      title: () => {
        const path = folderName.split("/");
        return <div className={styles.headerField}>
          {
            path.map((val, i) => {
              return (
                <p className={styles.headerContent} 
                  onClick={() => {
                    let folder = "";
                    for(let j = 0 ; j <= i ; j++) {
                      folder += path[j];
                      if(i != j) folder += "/";
                    }
                    setFolderName(folder);
                  }}
                >{val} {i != path.length - 1 && "/"}</p>
              )
            })
          }
        </div>
      },
      width: 1,
      render: (val, row) => {
        return <p className={styles.nameContentField} onClick={() => {
          if(row.name.includes(row.fileName)) {
            setFolderName(row.name);
          } else {
            navigate(`/modules/iqac/get-file-details/${row.name}`, {
              state: {
                fileDetails: row
              }
            })
          }
        }}>
          <ReactSVG src={row.name.includes(row.fileName) ? FolderIcon : FileIcon} />{row.fileName}
        </p>;
      },
    },
    {
      dataIndex: "recordType",
      title: "Type",
      width: 0.7,
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.recordType}</p>;
      },
    },
    {
      dataIndex: "fileSize",
      title: "Size",
      width: 0.8,
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{!row.name.includes(row.fileName) && `${row.fileSize} KB`}</p>;
      },
    },
    {
      dataIndex: "fileType",
      title: "Type",
      width: 0.7,
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.fileType}</p>;
      },
    },
    {
      dataIndex: "date",
      title: "Created On",
      width: 0.7,
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{formatDateFile(row.date)}</p>;
      },
    },
  ];

  const showModal = () => {
    setModalVisible(true);
  };

  const handleOk = async () => {
    if(!recordType) {
      setError("Required");
      return;
    }
    if (fileList) {
      dispatch(setLoading({ loading: true }));
      const formData = new FormData();
      formData.append('file', fileList.originFileObj);
      formData.append('is_private', 0);
      formData.append('folder', folderName);
      formData.append('record_type', recordType);

      var xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      xhr.onreadystatechange = () => {
        if (xhr.readyState == XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            toast.success("File uploaded!");
            setRecordType("");
            setError("");
            setFileList(null);
            setModalVisible(false);
            handleGetFileListing();
            dispatch(setLoading({ loading: false }));
          } else {
            toast.error("some issue while uploading!");
          }
        }
      }

      xhr.open("POST", "/api/method/upload_file");
      xhr.send(formData);
    };
  };

  const handleCancel = () => {
    setModalVisible(false);
    setFileList(null);
  };

  const handleImportSuccess = async () => {
    if (fileList) {
      dispatch(setLoading({ loading: true }));
      const formData = new FormData();
      formData.append('file', fileList.originFileObj);
      formData.append('is_private', 0);
      formData.append('folder', folderName);

      var xhr = new XMLHttpRequest();
      const unzipToast = toast.info("Unzipping files!");
      xhr.withCredentials = true;
      xhr.onreadystatechange = () => {
        if (xhr.readyState == XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            toast.dismiss(unzipToast);
            toast.success("Files uploaded!");
            setFileList(null);
            setImportModalVisible(false);
            handleGetFileListing();
            dispatch(setLoading({ loading: false }));
          } else {
            toast.error("some issue while uploading!");
          }
        }
      }
      xhr.open("POST", "/api/method/upload_file");
      xhr.send(formData);
    };
  };

  const handleImportCancel = () => {
    setImportModalVisible(false);
    setFileList(null);
  };

  const uploadProps = {
    beforeUpload: () => false,
    onChange: (info) => {
      const newFile = info.fileList.slice(-1)[0];
      setFileList(newFile);
    },
    fileList: fileList ? [fileList] : null,
    multiple: false,
  };

  const importUploadProps = {
    beforeUpload: () => false,
    onChange: (info) => {
      const newFile = info.fileList.slice(-1)[0];
      setFileList(newFile);
    },
    fileList: fileList ? [fileList] : null,
    multiple: false,
    accept: ".zip"
  };

  const handleCreateFolderSuccess = async () => {
    if (newFolder.trim() !== "") {
      dispatch(setLoading({ loading: true }));
      const res = await createNewFolder({ fileName: newFolder, folderName: folderName });
      if(res.status === 200) {
        toast.success("Folder Created!");
      } else {
        toast.error("Some issue while creating!");
      }
      setNewFolder("");
      setFolderModalVisible(false);
      dispatch(setLoading({ loading: false }));
      handleGetFileListing();
    }
  };

  const handleCreateFolderCancel = () => {
    setNewFolder("");
    setFolderModalVisible(false);
  };

  const handleMenuClick = (e) => {
    if (e.key === "createFolder") {
      setFolderModalVisible(true);
    } else if (e.key === "importZip") {
      setImportModalVisible(true);
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="createFolder">Create Folder</Menu.Item>
      <Menu.Item key="importZip">Import Zip</Menu.Item>
    </Menu>
  );

  const handleRowSelectionChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    let selectedDataIds = [];
    for(let i = 0 ; i < selectedRows.length ; i++) {
      selectedDataIds.push({
        name: selectedRows[i].name
      });
    }
    setSelectedIds([...selectedDataIds]);
  };

  const rowSelection = {
    onChange: handleRowSelectionChange,
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  const handleGetFileListing = async () => {
    let filtersToPass = [];
    if(doctype === "File") {
      filtersToPass = JSON.stringify(filters).slice(1, -1);
    }
    dispatch(setLoading({ loading: true }));
    const res = await getFilesListing({ folder: folderName, filtersToPass });
    if (res.status === 200) {
      let data = res?.data?.message;
      data.sort((a, b) => new Date(b.creation) - new Date(a.creation));
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        let val = {
          name: data[i].name,
          fileName: data[i].file_name,
          fileType: data[i].file_type,
          fileSize: data[i].file_size,
          fileUrl: data[i].file_url,
          recordType: data[i].custom_record_type,
          id: data[i].name,
          date: data[i].creation,
          key: i
        };
        arr.push(val);
      }
      setListData(arr);
      dispatch(setLoading({ loading: false }));
    } else {
      dispatch(setLoading({ loading: false }));
    }
  };

  const handleCut = () => {
    setPasteEnabled(true);
  };

  const handlePaste = async () => {
    dispatch(setLoading({ loading: true }));
    const payload = {
      data: {
        files: selectedIds,
        folder: folderName
      }
    };

    const res = await cutPasteFile({ payload });

    if(res.status === 200) {
      toast.success(res.data.message[0]);
      handleGetFileListing();
    } else {
      toast.error("Some issue while pasting");
      dispatch(setLoading({ loading: false }));
    }
    setSelectedIds([]);
    setSelectedRowKeys([]);
    setPasteEnabled(false);
  };

  const handleConfirmBulkDelete = () => {
    setOpenActions(false);
    eventBus.emit("open-dialog", <DeleteConfirmationDialog items={selectedRowKeys.length} handleBulkDelete={handleBulkDelete} />)
  };

  const handleBulkDelete = async () => {
    dispatch(setLoading({ loading: true }));
    let deleteIds = [];
    for(let i = 0 ; i < selectedIds.length ; i++) {
      deleteIds.push({
        doctype: "File",
        id: selectedIds[i].name
      })
    };

    const payload = { delete: [...deleteIds] };
    const res = await genericBulkDelete({ payload });
    if(res.status === 200) {
      handleGetFileListing();
      setSelectedIds([]);
      setSelectedRowKeys([]);
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message);
      dispatch(setLoading({ loading: false }));
    }
  };

  useEffect(() => {
    handleGetFileListing();
  }, [filters, folderName]);

  return (
    <div className={styles.mainContainer}>
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/dashboard")}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => setActiveModule(0)}
            >
              IQAC {" > "}
            </p>
            <p className={styles.breadCrumbsContent}>Document Management</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>Document Management</p>
            </div>
            <div className={styles.buttonsGroup}>
              {selectedRowKeys.length > 0 && !pasteEnabled && (
                <div className={styles.button} onClick={handleCut}>
                  <p className={styles.buttonText}>Cut</p>
                </div>
              )}
              {
                pasteEnabled && (
                <div className={styles.button} onClick={handlePaste}>
                  <p className={styles.buttonText}>Paste</p>
                </div>
              )}
              <CommonFilters doctype={"File"}></CommonFilters>
              <SavedFilters doctype={"File"}></SavedFilters>
              <Dropdown overlay={menu}>
                <div className={styles.button}>
                  <p className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                    ...
                  </p>
                </div>
              </Dropdown>
              {
                selectedRowKeys.length > 0 ? (
                  <Popover
                    content={<div className={styles.popoverContainer}>
                      <p className={styles.popoverContent} onClick={handleConfirmBulkDelete}>Delete</p>
                    </div>}
                    trigger="click"
                    open={openActions}
                    placement="bottomRight"
                    arrow={false}
                    onOpenChange={() => setOpenActions(true)}
                  >
                    <div className={styles.activeButton}>
                      <p className={styles.activeButtonText}>Actions</p>
                    </div>
                  </Popover>
                ) : (
                <div className={styles.activeButton} onClick={() => showModal()}>
                  <p className={styles.activeButtonText}>+ Add File</p>
                </div>
              )}
            </div>
          </div>
          <Table
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            columns={columns}
            dataSource={listData}
            size="small"
            pagination={true}
          />
          <Modal
            title="Upload File"
            open={modalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
          >
            <div className={styles.uploadContainer}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Record Type *</p>
                <Input className={styles.fieldInput} value={recordType} onChange={(e) => {
                  setRecordType(e.target.value);
                  setError("");
                }}/>
                {
                  error && <p className={styles.errorMessage}>{error}</p>
                }
              </div>
              <Upload.Dragger {...uploadProps} className={styles.containerUpload}>
                <UploadOutlined />
                <p className={styles.uploadText}>
                  Click here or Drag & drop file here to upload
                </p>
              </Upload.Dragger>
              {fileList && (
                <div className={styles.uploadButtons}>
                  <div className={styles.button} onClick={handleCancel}>
                    <p className={styles.buttonText}>Cancel</p>
                  </div>
                  <div className={styles.activeButton} onClick={handleOk}>
                    <p className={styles.activeButtonText}>Upload</p>
                  </div>
                </div>
              )}
            </div>
          </Modal>
          <Modal
            title="Upload File"
            open={importModalVisible}
            onOk={handleImportSuccess}
            onCancel={handleImportCancel}
            footer={null}
          >
            <div className={styles.uploadContainer}>
              <Upload.Dragger {...importUploadProps} className={styles.containerUpload}>
                <UploadOutlined />
                <p className={styles.uploadText}>
                  Click here or Drag & drop file here to upload
                </p>
              </Upload.Dragger>
              {fileList && (
                <div className={styles.uploadButtons}>
                  <div className={styles.button} onClick={handleImportCancel}>
                    <p className={styles.buttonText}>Cancel</p>
                  </div>
                  <div className={styles.activeButton} onClick={handleImportSuccess}>
                    <p className={styles.activeButtonText}>Upload</p>
                  </div>
                </div>
              )}
            </div>
          </Modal>
          <Modal
            title="Enter Folder Name"
            open={folderModalVisible}
            onOk={handleCreateFolderSuccess}
            onCancel={handleCreateFolderCancel}
            footer={null}
          >
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Folder Name *</p>
              <Input
                value={newFolder}
                className={styles.fieldInput}
                onChange={(e) => setNewFolder(e.target.value)}
                placeholder="Enter folder name"
              />
            </div>

            <div className={styles.uploadButtons}>
              <div className={styles.button} onClick={handleCreateFolderCancel}>
                <p className={styles.buttonText}>Cancel</p>
              </div>
              <div
                className={styles.activeButton}
                onClick={handleCreateFolderSuccess}
              >
                <p className={styles.activeButtonText}>Create</p>
              </div>
            </div>
          </Modal>
          ;
        </>
      )}
    </div>
  );
};

export default DocumentManagement;

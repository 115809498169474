import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import ArrowDownSVG from "../../../assets/images/arrow-down.svg";
import FiltersSVG from "../../../assets/images/filters-icon.svg";
import {
  getAllPaymentRequests,
  getStudentsListing,
} from "../../../modules/finance";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../slices/commonSlice";
import LoadingBackdrop from "../../common/LoadingBackdrop.js";
import { Table } from "antd";

const PaymentRequest = ({ setActiveModule }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [paymentRequests, setPaymentRequests] = useState([]);
  const [studentList, setStudentList] = useState([]);

  const columns = [
    {
      dataIndex: "paymentId",
      title: "PAYMENT ID",
      width: "20%",
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.paymentId}</p>;
      },
    },
    {
      dataIndex: "studentName",
      title: "STUDENT NAME",
      width: "20%",
      render: (val, row) => {
        const student = studentList.find(
          (student) => student.id === row.studentName
        );
        return (
          <>
            <p className={styles.tableContentFields}>{student?.name}</p>
          </>
        );
      },
    },
    {
      dataIndex: "series",
      title: "SERIES",
      width: "30%",
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.series}</p>;
      },
    },
    {
      dataIndex: "status",
      title: "STATUS",
      width: "20%",
      render: (val, row) => {
        return (
          <p
            className={
              row.status === "Active"
                ? styles.buttonPublish
                : styles.buttonUnPublish
            }
          >
            {row.status}
          </p>
        );
      },
    },
    {
      dataIndex: "id",
      title: "ACTION",
      width: "10%",
      render: (val, row) => {
        return (
          <p
            className={styles.tableContentView}
            onClick={() =>
              navigate(`/modules/finance/payment-request/${row.paymentId}`)
            }
          >
            View
          </p>
        );
      },
    },
  ];

  const handleGetAllFeeCategories = async () => {
    dispatch(setLoading({ loading: true }));
    const res = await getAllPaymentRequests();
    if (res.status === 200) {
      dispatch(setLoading({ loading: false }));
      const requests = [];
      for (let i = 0; i < res.data.data.length; i++) {
        requests.push({
          id: i,
          paymentId: res.data.data[i].name,
          studentName: res.data.data[i].party,
          series: res.data.data[i].name,
          status: res.data.data[i].status,
          idx: res.data.data[i].idx,
        });
      }
      setPaymentRequests(requests);
    } else {
      dispatch(setLoading({ loading: false }));
    }
  };

  const handleGetStudentListing = async () => {
    const res = await getStudentsListing();
    if (res.status === 200) {
      const arr = [];
      const studentList = res.data.data;
      for (let i = 0; i < studentList.length; i++) {
        arr.push({
          id: studentList[i].name,
          name: studentList[i].first_name,
        });
      }
      setStudentList(arr);
    }
  };

  const sortedDataSource = [...paymentRequests].sort((a, b) => {
    return b.paymentId.localeCompare(a.paymentId);
  });

  useEffect(() => {
    handleGetAllFeeCategories();
    handleGetStudentListing();
  }, []);

  return (
    <div className={styles.mainContainer}>
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/dashboard")}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => setActiveModule(0)}
            >
              Finance {" > "}
            </p>
            <p className={styles.breadCrumbsContent}>Payment Request</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>Payment Request</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div className={`${styles.button} ${styles.filterButton}`}>
                <ReactSVG src={FiltersSVG} />
                <p className={styles.buttonText}>Filters</p>
                <ReactSVG src={ArrowDownSVG} />
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>Saved Filters</p>
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>...</p>
              </div>
              <div
                className={styles.activeButton}
                onClick={() =>
                  navigate("/modules/finance/add-new-payment-request")
                }
              >
                <p className={styles.activeButtonText}>+ Add New</p>
              </div>
            </div>
          </div>
          <Table
            rowSelection={{ type: "checkbox" }}
            columns={columns}
            dataSource={sortedDataSource}
            size="small"
            pagination={true}
          />
        </>
      )}
    </div>
  );
};

export default PaymentRequest;

import React, { useEffect, useState } from "react";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import {
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "iiht-b2a-ui-components/build/components";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GridMenuIcon } from "@mui/x-data-grid";
import moment from "moment";
import { toast } from "react-toastify";
import { CustomBreadcrumb, FormCheckbox, FormTextField, MainLayout } from "components/common";
import { validationRules } from "constants/validationRules";
import { createGenericData, getGenericDataApi, updateGenericData } from "modules/hr";
import { setLoading } from "slices/commonSlice";
import { checkIfObjectNotEmpty } from "utils/helper";
import "./style.scss";

const AddInterviewType = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id: interviewTypeId = undefined } = useParams();
  const [showMore, setShowMore] = useState(false);
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [interviewTypeData, setInterviewTypeData] = useState({});
  const { loading } = useSelector((state) => state.common);
  const isEditMode = !!(location.pathname.includes("edit-view") && interviewTypeId);
  const [isSaveBtnDisable, setIsSaveBtnDisable] = useState(isEditMode);
  const breadcrumbs = [
    {
      label: "IIHT",
      link: "/dashboard",
    },
    {
      label: "HR",
      link: "/modules/hr",
    },
    {
      label: "Recruitment",
      link: "/modules/hr",
    },
    {
      label: "Masters",
      link: "/modules/hr/masters",
    },
    {
      label: "Interview Type",
      link: "/modules/hr/masters/interview-type",
    },
    {
      label: "Add",
      link: "",
    },
  ];

  const defaultValues = {
    name: "",
    confirmCheckBox: false,
    description: "",
  };

  const methods = useForm({
    defaultValues: defaultValues,
  });

  const { handleSubmit, reset, watch, getValues } = methods;
  const descriptionWatch = watch("description");
  const confirmCheckBoxWatch = watch("confirmCheckBox");

  const onSubmit = async (data) => {
    dispatch(setLoading({ loading: true }));
    const payload = {
      doctype: "Interview Type",
      name: data.name,
      description: data.description,
      id: interviewTypeId || data.id,
      disabled: data.confirmCheckBox,
    };
    const res = isEditMode ? await updateGenericData(payload) : await createGenericData(payload);
    isEditMode && getInterviewType();
    if (res?.status === 200) {
      toast.success(`Interview Type ${isEditMode ? "Updated" : "Created"}!`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsSaveBtnDisable(true);
      setIsFormSubmited(true);
    } else {
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    dispatch(setLoading({ loading: false }));
  };

  const getInterviewType = async () => {
    dispatch(setLoading({ loading: true }));
    const params = {
      doctype: "Interview Type",
      id: interviewTypeId,
    };
    const res = await getGenericDataApi(params);
    if (res?.status === 200) {
      const data = res?.data?.message;
      setInterviewTypeData(data);
      reset({
        ...data,
        confirmCheckBox: !!data?.disabled,
      });
    }
    dispatch(setLoading({ loading: false }));
  };

  useEffect(() => {
    if (isEditMode) {
      getInterviewType();
    }
  }, [isEditMode]);

  useEffect(() => {
    if (checkIfObjectNotEmpty(interviewTypeData)) {
      const { description, confirmCheckBox } = getValues();
      if (
        description !== interviewTypeData?.description ||
        confirmCheckBox !== !!interviewTypeData?.disabled
      ) {
        setIsSaveBtnDisable(false);
      } else {
        setIsSaveBtnDisable(true);
      }
    }
  }, [descriptionWatch, confirmCheckBoxWatch, interviewTypeData]);

  const dropdownSx = {
    color: "#1C1B1F",
    backgroundColor: "#F3F3F3",
    borderRadius: "4px",
    height: "24px",
    width: "24px",
  };

  return (
    <MainLayout>
      {loading && <LoadingBackdrop open={loading} />}
      <div className="interviewTypeContainer">
        <CustomBreadcrumb breadcrumbs={breadcrumbs} />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={1} alignItems="center">
                <GridMenuIcon className="gridIcon" />
                <Typography color="#0D1021" fontSize={22}>
                  {isEditMode ? "View Interview Type" : "New Interview Type"}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={3}>
                <Button
                  variant="contained"
                  color="grey"
                  className="qa-save-btn"
                  size="large"
                  onClick={() => navigate("/modules/hr/masters/interview-type")}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="qa-save-btn"
                  disabled={isSaveBtnDisable}
                >
                  {isEditMode ? "Update" : "Save"}
                </Button>
              </Stack>
            </Stack>

            <Stack sx={{ mt: 4 }}>
              <Typography variant="bodyText" color="#012148" fontWeight={500} sx={{ mb: 1 }}>
                Name*
              </Typography>
              <Stack direction="row" alignItems="center" spacing={10} sx={{ maxWidth: "40rem" }}>
                <FormTextField
                  name="name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={isEditMode || isFormSubmited}
                  rules={{
                    required: validationRules.REQUIRED,
                  }}
                />
                <FormCheckbox
                  name="confirmCheckBox"
                  label="Disabled"
                  size="small"
                  fullWidth
                  className="qa-confirm-check"
                />
              </Stack>
              <Stack sx={{ mt: 4 }}>
                <Typography variant="bodyText" color="#012148" fontWeight={500} sx={{ mb: 1 }}>
                  Description
                </Typography>
                <FormTextField
                  name="description"
                  variant="outlined"
                  size="small"
                  placeholder="Enter Text here..."
                  fullWidth
                  multiline
                  rows={5}
                />
              </Stack>
            </Stack>
          </form>
        </FormProvider>

        {interviewTypeId && (
          <div className="mt20">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography color="#000" variant="fieldHeadings">
                Activity
              </Typography>
              {showMore ? (
                <IconButton variant="contained" sx={dropdownSx} onClick={() => setShowMore(false)}>
                  <ExpandLessIcon />
                </IconButton>
              ) : (
                <IconButton variant="contained" sx={dropdownSx} onClick={() => setShowMore(true)}>
                  <ExpandMoreIcon />
                </IconButton>
              )}
            </Stack>
            {showMore && (
              <Stack direction="row" spacing={3} sx={{ height: "40px", mt: 2 }}>
                <Stack direction="column" justifyContent="start" alignItems="center" spacing={1}>
                  <CircleIcon sx={{ fontSize: "6px" }} />
                  <Divider orientation="vertical" fullWidth sx={{ borderColor: "#646464" }} />
                  <CircleIcon sx={{ fontSize: "6px" }} />
                </Stack>
                <Stack direction="column" justifyContent="space-between" sx={{ height: "70px" }}>
                  <Typography color="#696969" fontSize={12}>
                    Created <CircleIcon sx={{ fontSize: "8px", color: "#696969" }} />{" "}
                    {moment(interviewTypeData?.creation).format("LLL")}
                  </Typography>
                  <Typography color="#696969" fontSize={12}>
                    Edited <CircleIcon sx={{ fontSize: "8px", color: "#696969" }} />{" "}
                    {moment(interviewTypeData?.modified).format("LLL")}
                  </Typography>
                </Stack>
              </Stack>
            )}
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default AddInterviewType;

import { Button } from 'iiht-b2a-ui-components/build/components';
import React from 'react';
import eventBus from '../../../core/eventBus';

const ConfirmationDialog = ({ handleUpdateDataToApplicationForm, nextState  }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "18px", alignItems: "center", padding: "18px 22px" }}>
        <p style={{ color: "#405071", fontSize: "22px", fontWeight: "600" }}>CONFIRMATION!</p>
        <div style={{ display: "flex", flexDirection: "column"}}>
            <span style={{ color: "#405071", fontSize: "14px", fontWeight: "500" }}>Are you sure you want to take this action?</span>
            <span style={{ color: "#405071", fontSize: "14px", fontWeight: "500" }}>This action cannot be undone.</span>
        </div>
        <div style={{ display: "inline-flex"}}>
        <Button
            variant="contained"
            style={{
              marginRight: "8px",
              backgroundColor: "white",
              color: "gray",
              cursor: "pointer"
            }}
            onClick={() => {
                eventBus.emit("close-dialog");
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" style={{ cursor: "pointer" }} 
            onClick={() => {
                handleUpdateDataToApplicationForm({ nextState })
                eventBus.emit("close-dialog");
            }}>
            Confirm
          </Button>
        </div>
    </div>
  )
}

export default ConfirmationDialog;
import React, { useState, useEffect } from "react";
import useStyles from "./style";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridMenuIcon } from "@mui/x-data-grid";
import ArrowDownSVG from "../../../assets/images/arrow-down.svg";
import FiltersSVG from "../../../assets/images/filters-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../slices/commonSlice";
import LoadingBackdrop from "../../common/LoadingBackdrop.js";
import { getFeeSchedule } from "../../../modules/finance.js";
import { Table } from "antd";
import APIs from "api";
import ActionExport from "components/Filters/ActionExport";
import { toast } from "react-toastify";

const FeeSchedule = ({ setActiveModule }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [feeSchedule, setFeeSchedule] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  const columns = [
    {
      dataIndex: "schedule_name",
      title: "Schedule Name",
      flex: 0.75,
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.schedule_name}</p>;
      },
    },
    {
      dataIndex: "schedule_id",
      title: "Schedule Id",
      flex: 0.75,
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.schedule_id}</p>;
      },
    },
    {
      dataIndex: "fee_structure",
      title: "Fee Structure",
      flex: 0.75,
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.fee_structure}</p>;
      },
    },
    {
      dataIndex: "status",
      title: "Status",
      flex: 0.7,
      render: (val, row) => {
        return (
          <p
            className={
              row.status === "Fee Creation Pending"
                ? styles.buttonPublish
                : row.status === "Fee Created"
                ? styles.buttonGreen
                : styles.buttonUnPublish
            }
          >
            {row.status}
          </p>
        );
      },
    },
    {
      dataIndex: "id",
      title: "Action",
      flex: 0.5,
      render: (val, row) => {
        return (
          <p
            className={styles.tableContentView}
            onClick={() =>
              navigate(`/modules/finance/fee-schedule/${row.schedule_id}`)
            }
          >
            View
          </p>
        );
      },
    },
  ];

  const actionOptions = [
    { label: "Export", id: "export", key: "export" },
    { label: "Delete", id: "delete", key: "delete" },
  ];

  const handleBulkExport = async () => {
    let exportIds = [];
    for (let i = 0; i < selectedIds.length; i++) {
      exportIds.push({
        doctype: "Fee Schedule",
        id: selectedIds[i],
      });
    }

    const payload = { export: [...exportIds] };
    const response = await fetch(APIs.bulkActionExport, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      dispatch(setLoading({ loading: false }));
      setSelectedIds([]);
      setSelectedRowKeys([]);
      toast.success("Data exported!");
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Fee Schedule.xlsx");
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error("Something went wrong");
      dispatch(setLoading({ loading: false }));
    }
  };

  const handleAction = (option) => {
    if (option.id === "export") {
      handleBulkExport();
    } else if (option.id === "delete") {
      handleBulkDelete();
    }
  };

  const handleBulkDelete = async () => {
    let exportIds = [];
    let hasSubmittedDoc = false;
    for (let i = 0; i < selectedIds.length; i++) {
      const item = feeSchedule.find(
        (item) => item.schedule_id === selectedIds[i]
      );
      console.log(item);
      if (item.docstatus === 1) {
        hasSubmittedDoc = true;
      } else {
        exportIds.push({
          doctype: "Fee Schedule",
          id: selectedIds[i],
        });
      }
    }
    console.log(exportIds);

    const payload = { delete: [...exportIds] };
    if (exportIds.length > 0) {
      const response = await fetch(APIs.bulkActionDelete, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setSelectedIds([]);
        setSelectedRowKeys([]);
        toast.success("Data Deleted!");
        handleGetFeeScheduleListing();
      } else {
        toast.error("Submitted Documents cannot be deleted");
        dispatch(setLoading({ loading: false }));
      }
    } else {
      toast.error("Submitted Documents cannot be deleted");
    }
  };

  const onSelectChange = (selectedKeys, selectedRowData) => {
    setSelectedRowKeys(selectedKeys);
    let selectedDataIds = [];
    selectedRowData.map((rowData) => selectedDataIds.push(rowData.key));
    setSelectedIds([...selectedDataIds]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      id: record.key,
    }),
  };

  const handleGetFeeScheduleListing = async () => {
    dispatch(setLoading({ loading: true }));
    const res = await getFeeSchedule();
    if (res.status === 200) {
      dispatch(setLoading({ loading: false }));
      const schedules = [];
      console.log(res.data.data);
      for (let i = 0; i < res.data.data.length; i++) {
        schedules.push({
          id: i,
          key: res.data.data[i].name,
          schedule_name: res.data.data[i].custom_fee_schedule_name,
          schedule_id: res.data.data[i].name,
          fee_structure: res.data.data[i].fee_structure,
          program: res.data.data[i].program,
          academic_term: res.data.data[i].academic_term,
          status:
            res.data.data[i].docstatus === 0
              ? "Draft"
              : res.data.data[i].docstatus === 1 &&
                res.data.data[i].fee_creation_status !== "Successful"
              ? "Fee Creation Pending"
              : "Fee Created",
          idx: res.data.data[i].idx,
        });
      }
      console.log(schedules);
      setFeeSchedule(schedules);
    } else {
      dispatch(setLoading({ loading: false }));
    }
  };

  useEffect(() => {
    handleGetFeeScheduleListing();
  }, []);

  useEffect(() => {
    console.log(selectedIds);
  }, [selectedIds]);

  return (
    <div className={styles.mainContainer}>
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/dashboard")}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => setActiveModule(3)}
            >
              Finance {" > "}
            </p>
            <p className={styles.breadCrumbsContent}>Fee Schedule</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>Fee Schedule</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div className={`${styles.button} ${styles.filterButton}`}>
                <ReactSVG src={FiltersSVG} />
                <p className={styles.buttonText}>Filters</p>
                <ReactSVG src={ArrowDownSVG} />
              </div>
              <div className={styles.button}>
                <p className={styles.buttonText}>Saved Filters</p>
              </div>
              {selectedRowKeys.length > 0 && (
                <ActionExport
                  title="Actions"
                  options={actionOptions}
                  disabled={!selectedRowKeys.length}
                  onChange={handleAction}
                />
              )}
              {!selectedRowKeys.length && (
                <div
                  className={styles.activeButton}
                  onClick={() =>
                    navigate("/modules/finance/add-new-fee-schedule")
                  }
                >
                  <p className={styles.activeButtonText}>+ Add Fee Schedule</p>
                </div>
              )}
            </div>
          </div>
          <Table
            rowSelection={{ type: "checkbox", ...rowSelection }}
            columns={columns}
            dataSource={feeSchedule}
            size="small"
            pagination={true}
          />
        </>
      )}
    </div>
  );
};

export default FeeSchedule;

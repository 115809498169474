import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "48px",
    transition: "margin-left 0.3s ease",
  },
  sidebarContainer: {
    width: "150px",
    boxShadow: "5px 0px 10px 0px #00000026",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px 0px",
    transition: "width 0.3s ease",
    borderRadius: "0 8px 8px 0",
  },
  collapsedSidebar: {
    width: "3%",
    boxShadow: "5px 0px 10px 0px #00000026",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px 0px",
    transition: "width 0.3s ease",
  },
  sidebarContent: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #FBEDED",
    width: "70%",
    height:"100px",
    padding: "16px 0px",
    borderRadius: "8px",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    color: "##0D1021",
    transition: "width 0.3s ease",
  },
  sidebarContentActive: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    backgroundColor: "#AC0521",
    border: "1px solid #FBEDED",
    color: "#FFFFFF",
    width: "70%",
    height:"100px",
    padding: "16px 0px",
    borderRadius: "8px",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    transition: "width 0.3s ease",
  },
  sidebarContentIcons: {
    display: "flex",
    padding: "4px",
    borderRadius: "2px",
    background:"#fff",
    border: "1.32px solid rgba(255, 255, 255, 0.50)",
  },
  sidebarContentLabel: {
    fontSize: "12px",
    fontWeight: "500",
    textAlign: "center",
  },
  moduleContainer: {
    width: "85%",
    marginTop: "28px",
    transition: "width 0.3s ease",
  },
  leftContainer: {
    width: "2%",
  },
}));

export default useStyles;

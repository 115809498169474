import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  IconButton,
  TextField,
  Box,
  Typography,
  Backdrop,
  Select as SelectMUI,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { GridMenuIcon } from "@mui/x-data-grid";
import MuiAlert from "@mui/material/Alert";
import Button from "iiht-b2a-ui-components/build/components/Button";
import {
  createGenericEntity,
  getBulkEditFieldNames,
  getCountryList,
  getCurrencyList,
  getEntityList,
  getGenericList,
  updateFieldsInBulk,
} from "../../modules/masters";
import { MainLayout } from "components/common";
import { Link, useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import APIs from "../../api";
import { formConfig } from "./MasterFormJson";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import Breadcrumbs from "../common/breadcrumb/breadcrumb";
import FiltersSVG from "../../assets/images/filters-icon.svg";

import {
  CheckboxField,
  DateInputField,
  EmailInputField,
  PhoneInputField,
  Select1InputField,
  Select2InputField,
  SelectInputField,
  TableField,
  TextAreaField,
  TextInputField,
} from "./FormFields";
import { useDispatch, useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import Outcomes from "./Outcomes";
import CourseContentManagement from "./Content Management/ContentManagement";
import { Popover, Table } from "antd";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { genericBulkDelete } from "modules/common";
import DeleteConfirmationDialog from "components/common/DeleteConfirmation";
import eventBus from "core/eventBus";
import BulkEdit from "components/common/BulkEdit";
import useStyles from "./style";
// import { setLoading } from "slices/commonSlice";
// import LoadingBackdrop from "components/common/LoadingBackdrop.js";

const EntityList = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  // const dispatch = useDispatch();
  // const { loading } = useSelector((state) => state.common);
  const loading = useSelector((state) => state.common.loading);

  const { entityId, entityType } = useParams();
  const [filtering, setFiltering] = useState(false);
  const [isAddApplication, setIsAddApplication] = useState(false);
  const [formData, setFormData] = useState({});
  const [showAddCampusForm, setShowAddCampusForm] = useState(false);
  const [currentEntityType, setCurrentEntityType] = useState(entityType);
  const initialCourse = "initialCourse";

  const [
    showBulkActionEditSuccessMessage,
    setshowBulkActionEditSuccessMessage,
  ] = useState(false);
  const [error, setError] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  // const [tabData, setTabData] = useState({});

  const [
    showBulkActionDeleteSuccessMessage,
    setshowBulkActionDeleteSuccessMessage,
  ] = useState(false);
  const [
    showBulkActionExportSuccessMessage,
    setshowBulkActionExportSuccessMessage,
  ] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  // const [paginationModel, setPaginationModel] = useState({
  //   pageSize: 25,
  //   page: 0,
  // });
  const [bulkEditSelectValue, setbulkEditSelectValue] = useState("Select");
  const [bulkEditValue, setBulkEditValue] = useState("");
  const [bulkEditSelectValueOptions, setbulkEditSelectValueOptions] = useState(
    []
  );

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [dataOptions, setDataOptions] = useState({});
  const [dataCampusOptions, setDataCampusOptions] = useState({});
  const [dataCollegeOptions, setDataCollegeOptions] = useState({});
  const [dataAcademicYrOptions, setDataAcademicYrOptions] = useState({});
  const [dataDepartmentOptions, setDataDepartmentOptions] = useState({});
  const [dataAcademicTermOptions, setDataAcademicTermOptions] = useState({});
  const [dataCourseOptions, setDataCourseOptions] = useState({});
  const [dataProgramOptions, setDataProgramOptions] = useState({});
  const [academicYearStartDate, setAcademicYearStartDate] = useState("");
  const [dataCurriculumOptions, setDataCurriculumOptions] = useState({});
  const [dataInstructorOptions, setDataInstructorOptions] = useState({});
  const [dataEmployeeOptions, setDataEmployeeOptions] = useState({});
  const [dataOutcomesOptions, setDataOutcomesOptions] = useState({});
  const [dataCountryOptions, setDataCountryOptions] = useState({});
  const [dataCurrencyOptions, setDataCurrencyOptions] = useState({});
  const [curriculumData, setCurriculumData] = useState({});
  const [instructorData, setInstructorData] = useState({});
  const [employeeData, setEmployeeData] = useState({});
  const [courseData, setCourseData] = useState({});
  const [outcomesData, setOutcomesData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [rowData, setRowData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openActions, setOpenActions] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const [isVisibleCourseDropdown, setIsVisibleCourseDropdown] = useState(false);
  const [isVisibleProgramDropdown, setIsVisibleProgramDropdown] =
    useState(false);

  const handleSelectChange1 = (fieldName, selectedValue) => {
    // Show/hide course and program dropdowns based on selected outcome type
    if (fieldName === "outcome_type") {
      if (selectedValue === "Course Outcome(CO)") {
        setIsVisibleCourseDropdown(true);
        setIsVisibleProgramDropdown(false);
      } else {
        setIsVisibleCourseDropdown(false);
        setIsVisibleProgramDropdown(true);
      }
    }
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSetFields = (fields, id) => {
    setCurrentEntityType(id);
  };

  // Call handleSetFields when the component mounts
  React.useEffect(() => {
    handleSetFields(currentEntityType);
  }, [location.pathname]); // Update when the pathname changes

  const onSelectChange = (selectedKeys, selectedRowData) => {
    setSelectedRowKeys(selectedKeys);
    let selectedDataIds = [];
    for (let i = 0; i < selectedRowData.length; i++) {
      selectedDataIds.push(selectedRowData[i].id);
    }
    setSelectedIds([...selectedDataIds]);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      id: record.id,
      doctype: record.doctype,
    }),
  };
  const handleConfirmBulkDelete = () => {
    setOpenActions(false);
    eventBus.emit(
      "open-dialog",
      <DeleteConfirmationDialog
        items={selectedRowKeys.length}
        handleBulkDelete={handleBulkDelete}
      />
    );
  };

  const handleBulkDelete = async () => {
    setOpenActions(false);
    let deleteIds = [];
    for (let i = 0; i < selectedIds.length; i++) {
      // Use getCheckboxProps to get the doctype for each selected record
      let doctype = entityType;

      if (entityType === "University" || entityType === "College") {
        doctype = "Company";
      } else if (entityType === "Program") {
        doctype = "Program";
      } else if (entityType === "Campus") {
        doctype = "Cost Center";
      } else if (entityType === "Batch") {
        doctype = "Program Batch";
      }

      deleteIds.push({
        doctype: doctype,
        id: selectedIds[i],
      });
    }
    const payload = { delete: [...deleteIds] };
    try {
      const res = await genericBulkDelete({ payload });
      if (res.status === 200) {
        handleEntityList();
        setSelectedIds([]);
        setSelectedRowKeys([]);
        toast.success(res.data.message);
      } else {
        // Display both exception and message in toast error
        const errorMessage = res.data.exception || res.data.message;
        toast.error(errorMessage.replace(/<[^>]+>/g, ""));
      }
    } catch (error) {
      console.error("Error during bulk delete:", error);
      toast.error("An error occurred during bulk delete. Please try again.");
    }
  };

  const handleBulkExport = async () => {
    setOpenActions(false);
    try {
        let exportData = [];

        // Define doctype mappings
        const doctypeMap = {
            University: "Company",
            College: "Company",
            Program: "Program",
            Campus: "Cost Center",
            Batch: "Program Batch"
        };

        for (let i = 0; i < selectedIds.length; i++) {
            let doctype = doctypeMap[entityType] || entityType; // Use mapped doctype or entityType if not found
            exportData.push({
                doctype: doctype,
                id: selectedIds[i],
            });
        }

        const payload = { export: exportData };
        const response = await fetch(APIs.bulkActionExport, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });

        if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob); // Simplified URL creation
            const link = document.createElement("a");
            link.href = url;
            toast.success("Data exported!");
            // Use entityType for filename if available, otherwise default to "ExportedData"
            const filename = entityType ? `${entityType} List-Data.xlsx` : "ExportedData.xlsx";
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
        } else {
            toast.error("Something went wrong");
        }
    } catch (error) {
        console.error("Error during bulk export:", error);
        toast.error("An error occurred during export. Please try again.");
    }
};


const handleBulkEdit = async ({ customfieldname, value }) => {
  setOpenActions(false);
  if (selectedRowKeys.length > 0) { // Check if there are selected rows
      const { id } = selectedRowKeys[0].props; // Assuming you want to use the doctype from the first selected item
      let doctype = entityType;
      // Check if the entity type is "University" and set the doctype to "Company"
      if (entityType === "University") {
          doctype = "Company";
      }
      const payload = {
          doctype: doctype,
          docnames: selectedIds,
          customfieldname,
          value,
      };

      setIsEditOpen(false);
      try {
          const res = await updateFieldsInBulk({ payload });
          if (res.status === 200) {
              handleEntityList();
              setSelectedIds([]);
              setSelectedRowKeys([]);
              toast.success(res.data.message);
          } else {
              toast.error(res.data.exc_type);
          }
      } catch (error) {
          console.error("Error during bulk edit:", error);
          toast.error("An error occurred during bulk edit. Please try again.");
      }
  } else {
      toast.error("Please select at least one row to perform bulk edit.");
  }
};


  const CustomToolbar = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const toggleDropdown = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };

    const handleAddAppClick = () => {
      setIsAddApplication(true);
      setShowAddCampusForm(true);
      handleSetFields();
    };
    // useEffect(() => {
    //   const selectedAcademicYear = dataAcademicYrOptions.academicYrList.find(
    //     (item) => item.value === formData.academic_year
    //   );

    //   if (selectedAcademicYear) {
    //     setAcademicYearStartDate(selectedAcademicYear.yearStartDate);
    //   }
    // }, [formData.academic_year, dataAcademicYrOptions.academicYrList]);
    return (
      <>
        <Breadcrumbs />
        <div className="master-heading">
          <h2>
            <GridMenuIcon />
            {`${entityType} Masters`}
          </h2>

          <span className="rightBtn">
            <Button
              variant="outlined"
              // startIcon={<FilterListIcon />}
              onClick={handleFilterButtonClick}
            >
              <ReactSVG src={FiltersSVG} />
              &nbsp;
              {filtering ? " Filters" : " Filters"}
            </Button>
            <Button variant="outlined">Saved Filters</Button>

            {/* <div className="action-button">
              <Button className="dots-button" onClick={toggleDropdown}>
                &#x22EF;
              </Button>
              <div>
                {isDropdownOpen && (
                  <div className="dropdown-content">
                    <span onClick={handleBulkEdit}>Edit</span>
                    <span onClick={handleBulkExport}>Export</span>
                    <span onClick={handleBulkDelete}>Delete</span>
                  </div>
                )}
              </div>
            </div>  */}
            {selectedRowKeys.length > 0 ? (
              <Popover
                content={
                  <div className={styles.popoverContainer}>
                    <p
                      className={styles.popoverContent}
                      onClick={() => {
                        setOpenActions(false);
                        setIsEditOpen(true);
                      }}
                    >
                      Edit
                    </p>
                    <p
                      className={styles.popoverContent}
                      onClick={handleBulkExport}
                    >
                      Export
                    </p>
                    <p
                      className={styles.popoverContent}
                      onClick={handleConfirmBulkDelete}
                    >
                      Delete
                    </p>
                  </div>
                }
                trigger="click"
                open={openActions}
                placement="bottomRight"
                arrow={false}
                onOpenChange={() => setOpenActions(true)}
              >
                <div className={styles.activeButton}>
                  <p className={styles.activeButtonText}>Actions</p>
                </div>
              </Popover>
            ) : (
              <Button
                variant="outlined"
                className="redBtn"
                onClick={handleAddAppClick}
              >
                {`Add ${entityType}`}
              </Button>
            )}
            {/* <Button
              variant="outlined"
              className="redBtn"
              onClick={handleAddAppClick}
            >
              {`+ Add ${entityType}`}
            </Button> */}
          </span>
        </div>
      </>
    );
  };

  const handleFilterButtonClick = () => {
    setFiltering(!filtering);
  };

  // Add a utility function to compare dates
  const isDateBefore = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return d1 < d2;
  };

  const validateField = (value, field, formData) => {
    switch (field.type) {
      case "text":
      case "textarea":
        return typeof value === "string" && value.trim() !== ""; // Check if non-empty string

      case "select":
        return value !== ""; // Check if a value is selected

      case "date":
        // Check if the date is not empty and, if it's not the "founded year" field, ensure it's not a past date
        if (value === "") {
          return false; // Date is empty, consider it invalid
        }
        if (field.name === "founded_year") {
          return true; // Allow any date for the "founded year" field
        }
        const selectedDate = new Date(value);
        const currentDate = new Date();

        const academicYearStartDate = formData?.year_start_date; // Access academic year start date from form data with optional chaining

        return (
          selectedDate >= new Date() &&
          !isDateBefore(selectedDate, academicYearStartDate)
        );

        // Compare the selected date with the current date
        return selectedDate >= currentDate;

      case "email":
        // Email validation using a simple regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return typeof value === "string" && emailRegex.test(value);

      case "phone":
        // Phone number validation using a simple regex
        const phoneRegex = /^[0-9]{10}$/;
        return (
          typeof value === "string" &&
          value.trim() !== "" &&
          phoneRegex.test(value)
        );

      case "checkbox":
        // Check if checkbox is checked
        return typeof value === "boolean" || value === 1 || value === 0;

      case "gst_number":
        // GST number validation
        const gstRegex = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}[A-Z\d]{1}$/;
        return typeof value === "string" && gstRegex.test(value);

      case "tel":
        // Telephone number validation using a simple regex
        // This regex allows for digits, hyphens, spaces, parentheses, and the plus symbol
        const telRegex = /^[\d\-\s()+]+$/;
        return (
          typeof value === "string" &&
          value.trim() !== "" &&
          telRegex.test(value)
        );

      default:
        return true; // Default to true for other types
    }
  };

  const handleFieldBlur = (fieldName, fieldValue, field, formData) => {
    // Add your onBlur logic here if needed
    const isValid = validateField(fieldValue, field, formData);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: isValid
        ? undefined
        : `'${field.label}' is required or the date is invalid`,
    }));
  };

  const handleTableDataChange = (tableName, tableData, fieldOptions) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [tableName]: tableData,
    }));

    // Extract course codes from the selected courses and update the course_code field
    const selectedCourseCodes = tableData
      .flatMap((row) => {
        // Check if 'prerequisites' array exists in the row
        if (row.prerequisites && Array.isArray(row.prerequisites)) {
          return row.prerequisites.map((prereq) => prereq.id || "");
        }
        return [];
      })
      .join(", ");

    setFormData((prevFormData) => ({
      ...prevFormData,
      id: formData.id,
    }));
  };

  const renderFormField = (field, tableData) => {
    const { name, type } = field;
    const fieldError = fieldErrors[name];
    // const value = tabData[activeTab] && tabData[activeTab][name] ? tabData[activeTab][name] : '';

    const value = formData[name] !== undefined ? formData[name] : "";

    const handleSelectChange = (fieldName, selectedValue) => {
      setFormData((prevData) => ({ ...prevData, [fieldName]: selectedValue }));
    };
    const handleSelect1Change = (fieldName, selectedValue) => {
      setFormData((prevData) => ({ ...prevData, [fieldName]: selectedValue }));
    };
    const handleInputChange = (fieldName, value) => {
      setFormData((prevData) => ({ ...prevData, [fieldName]: value }));
    };
    const handleCheckboxChange = (fieldName, checked) => {
      // Ensure the value is a boolean
      const updatedValue = checked === true;

      // Add your logic to handle input changes
      setFormData((prevData) => ({ ...prevData, [fieldName]: updatedValue }));
    };
    // Check if the field is applicable to the current entityType
    if (!field.entityType || field.entityType === entityType) {
      const isRequired = field.required ? "required" : "";

      // Determine visibility based on the type of outcome selected
      let isVisible = false;
      if (
        field.name === "course" &&
        formData.outcome_type === "Course Outcome(CO)"
      ) {
        isVisible = true;
      }
      if (
        field.name === "program" &&
        formData.outcome_type !== "Course Outcome(CO)"
      ) {
        isVisible = true;
      }

      // Add condition to check if the field is visible before rendering
      if (isVisible || (field.name !== "course" && field.name !== "program")) {
        switch (type) {
          case "text":
            return (
              <div className="form-field">
                <TextInputField
                  field={field}
                  value={formData[field.name]}
                  onChange={(e) => handleInputChange(name, e.target.value)}
                  onBlur={() => handleFieldBlur(name, value, field)}
                  {...isRequired}
                />
                {fieldError && (
                  <div className="error-message">{fieldError}</div>
                )}
              </div>
            );
          case "gst_number":
            return (
              <div className="form-field">
                <TextInputField
                  field={field}
                  value={formData[field.name]}
                  onChange={(e) => handleInputChange(name, e.target.value)}
                  onBlur={() => handleFieldBlur(name, value, field)}
                  {...isRequired}
                />
                {fieldError && (
                  <div className="error-message">{fieldError}</div>
                )}
              </div>
            );
          case "tel":
            return (
              <div className="form-field">
                <PhoneInputField
                  field={field}
                  value={formData[field.name]}
                  onChange={(e) => handleInputChange(name, e.target.value)}
                  onBlur={() => handleFieldBlur(name, value, field)}
                />
                {fieldError && (
                  <div className="error-message">{fieldError}</div>
                )}
              </div>
            );
          case "email":
            return (
              <div className="form-field">
                <EmailInputField
                  field={field}
                  value={formData[field.name]}
                  onChange={(e) =>
                    handleInputChange(field.name, e.target.value)
                  }
                  fieldError={fieldErrors[field.name]}
                />
                {fieldError && (
                  <div className="error-message">{fieldError}</div>
                )}
              </div>
            );
          case "checkbox":
            return (
              <div className="form-field">
                <CheckboxField
                  field={field}
                  checked={formData[field.name]}
                  onChange={(e) =>
                    handleCheckboxChange(field.name, e.target.checked)
                  }
                />
                {fieldError && (
                  <div className="error-message">{fieldError}</div>
                )}
              </div>
            );
          case "textarea":
            return (
              <div className="form-field fullWidthFields">
                <TextAreaField
                  field={field}
                  value={formData[field.name]}
                  onChange={(e) => handleInputChange(name, e.target.value)}
                  onBlur={() => handleFieldBlur(name, value, field)}
                />
                {fieldError && (
                  <div className="error-message">{fieldError}</div>
                )}
              </div>
            );
          case "table":
            return (
              <>
                <label htmlFor={field.label}>
                  <h4>{field.label}</h4>
                </label>
                <TableField
                  key={field.name}
                  headers={field.headers.map((header) => ({
                    key: header.key,
                    value: header.value,
                    options: header.options,
                  }))}
                  data={tableData || []} // Pass the table data
                  onTableDataChange={(data) =>
                    handleTableDataChange(field.name, data)
                  }
                  dropdownOptions={{
                    courseList: dataCourseOptions,
                    academicTermList: dataAcademicTermOptions,
                    academicYrList: dataAcademicYrOptions,
                    programList: dataProgramOptions,
                    curriculumList: dataCurriculumOptions,
                    outcomesList: dataOutcomesOptions,
                    assessmentCriteria: {
                      assessmentCriteria: [
                        {
                          label: "Assessment xyz",
                          value: "Assessment xyz",
                        },
                      ],
                      isFetched: true,
                    },
                  }}
                />
              </>
            );

          case "select":
            return (
              <div className="form-field">
                <SelectInputField
                  field={field}
                  value={field.value}
                  academicYrList={dataAcademicYrOptions.academicYrList}
                  onChange={(selectedValue) =>
                    handleSelectChange(
                      field.name,
                      selectedValue,
                      dataAcademicYrOptions.academicYrList
                    )
                  }
                  {...isRequired}
                />
                {fieldError && (
                  <div className="error-message">{fieldError}</div>
                )}

                {/* Display Academic Year start date */}
                {formData.academic_year && academicYearStartDate && (
                  <div className="academic-year-start-date">
                    Academic Year Start Date: {academicYearStartDate}
                  </div>
                )}
              </div>
            );
          case "select1":
            return (
              <div className="form-field">
                <Select1InputField
                  field={field}
                  value={field.value}
                  onChange={(selectedValue) =>
                    handleSelect1Change(field.name, selectedValue)
                  }
                  {...isRequired}
                />
                {fieldError && (
                  <div className="error-message">{fieldError}</div>
                )}
              </div>
            );

          case "select2":
            return (
              <div className="form-field2">
                <Select2InputField
                  field={field}
                  value={field.value}
                  academicYrList={dataAcademicYrOptions.academicYrList}
                  onChange={(selectedValue) =>
                    handleSelectChange(
                      field.name,
                      selectedValue,
                      dataAcademicYrOptions.academicYrList
                    )
                  }
                />
                {fieldError && (
                  <div className="error-message">{fieldError}</div>
                )}

                {/* Display Academic Year start date */}
                {formData.academic_year && academicYearStartDate && (
                  <div className="academic-year-start-date">
                    Academic Year Start Date: {academicYearStartDate}
                  </div>
                )}
              </div>
            );

          case "date":
            const selectedDate = formData[field.name];
            const isValid = validateField(selectedDate, field);

            return (
              <div className="form-field">
                <DateInputField
                  field={field}
                  value={selectedDate}
                  onChange={handleInputChange}
                  onBlur={() => {
                    // Add your onBlur logic here if needed
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      [field.name]: isValid
                        ? undefined
                        : `${field.label} is empty or the selected date is in the past`,
                    }));
                  }}
                />
                {fieldError && (
                  <div className="error-message">{fieldError}</div>
                )}
              </div>
            );
          case "col-break":
            return <div className="col-break" key={field.name}></div>;
          case "createOutcome":
            return (
              <Outcomes
                initialCourse={initialCourse}
                currentCourse={currentCourse}
                closeModal={closeModal}
              />
            );
          case "courseOutcome":
            const outcomesForCurrentCourse = outcomesData.filter(
              (outcome) => outcome.course === currentCourse && !outcome.created
            );
            return (
              <div className="button-popover">
                {outcomesForCurrentCourse.length > 0 ? (
                  <>
                    <TableField
                      key={field.name}
                      onTableDataChange={(data) =>
                        handleTableDataChange(field.name, data)
                      }
                      headers={[
                        { key: "ID", value: "id" },
                        { key: "Title", value: "title" },
                        { key: "Outcome Type", value: "outcome_type" },
                      ]}
                      data={outcomesForCurrentCourse}
                    />
                  </>
                ) : (
                  <p>No outcomes available for the current course.</p>
                )}
              </div>
            );

          default:
            return null;
        }
      }
    } else {
      return null;
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    formConfig
      .find((config) => config.entityType === entityType)
      ?.fields.forEach((field) => {
        const value = formData[field.name];
        let fieldError = null;

        // Check for required fields
        if (field.required) {
          if (field.type === "checkbox") {
            // Checkbox validation
            if (typeof value !== "boolean") {
              fieldError = `'${field.label}' must be checked`;
              isValid = false;
            }
          } else if (
            !value ||
            (typeof value === "string" && value.trim() === "")
          ) {
            // Other field types validation
            fieldError = `'${field.label}' is required`;
            isValid = false;
          } else if (field.type === "email" && value) {
            // Email validation using a simple regex
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
              fieldError = `Invalid email format for '${field.label}'`;
              isValid = false;
            } else if (field.type === "select" && !value) {
              // Validation for "select" field
              fieldError = `Please select a value for '${field.label}'`;
              isValid = false;
            } else if (
              field.name === "academic_year" &&
              value &&
              academicYearStartDate
            ) {
              const selectedAcademicYear =
                dataAcademicYrOptions.academicYrList.find(
                  (item) => item.value === value
                );
              if (
                new Date(formData.term_start_date) <
                new Date(selectedAcademicYear.yearStartDate)
              ) {
                fieldError = "The Term cannot start before the Academic Year.";
                isValid = false;
              }
              console.log("selectedAcademicYear", selectedAcademicYear);
            }
          } else if (field.type === "gst_number" && value) {
            // GST number validation using a regex
            const gstRegex = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}[A-Z\d]{1}$/;
            if (!gstRegex.test(value)) {
              fieldError = `Invalid format '${field.label}'. Correct format is 22AAAAA0000A1Z5, 22-state code, AAAAA0000A-PAN, and 1-entity number of the same PAN holder in a state, Z-default alphabet and 5-checksum digit.`;
              isValid = false;
            }
          } else if (field.type === "tel" && value) {
            // Telephone number validation using a simple regex
            const telRegex = /^[\d\-\s()+]+$/;
            if (!telRegex.test(value)) {
              fieldError = `Invalid format for '${field.label}'. Please enter a valid telephone number.`;
              isValid = false;
            }
          }
        }

        errors[field.name] = fieldError;
      });

    setFieldErrors(errors);
    return isValid;
  };

  useEffect(() => {
    const fetchData = async () => {
      // dispatch(setLoading({ loading: true }));
      setIsLoading(true);
      try {
        await handleEntityList();
        await getAcademicTermList();
        await getDepartmentList();
        await getCollegeList();
        await getCampusList();
        await getCountryDataList();
        await getCurrencyDataList();
        await getUniversities();
        await getAcademicYear();
        await getCourseList();
        await getProgramList();
        await handleEmployeeList();
        await handleInstructorList();
        await handleCurriculumList();
        await handleOutcomesList();
      } catch (error) {
      } finally {
        //  dispatch(setLoading({ loading: false }));
        setIsLoading(false);
      }
    };
    fetchData();
  }, [entityType]);

  // useEffect(() => {
  //   getBulkEditOptions();
  // }, [rowData]);

  const handleOutcomesList = async () => {
    try {
      const doctype = "Outcomes";
      const outcomesList = await getGenericList(doctype);

      // Add a unique identifier to each outcomes
      const outcomesWithId = outcomesList.map((outcome) => ({
        ...outcome,
        id: outcome.id,
        name: outcome.name,
        outcome_type: outcome.outcome_type,
        entityType: "Outcomes",
      }));

      // Update the OutcomesData state with Outcomes
      setOutcomesData(outcomesWithId);

      // Assuming formConfig is a constant or comes from a static source
      const entityConfig = formConfig.find(
        (config) => config.entityType === "Outcomes"
      );
      handleSetFields(entityConfig?.fields, "Outcomes");

      // Update the dropdown options for Outcomes

      const outcomesOptions = outcomesList.map((outcome) => ({
        outcome: outcome.id,
        value: outcome.name,
        name: outcome.name,
        label: outcome.id, // Assuming the outcomes object has a property named 'name'
        type: outcome.outcome_type,
      }));
      const finalOutcomesData = {
        ...dataOutcomesOptions,
        outcomesList: outcomesOptions,
        isFetched: true,
      };
      console.log(finalOutcomesData, "RESPONSE Outcomes");
      setDataOutcomesOptions(finalOutcomesData);
    } catch (error) {
      // Handle errors if needed
      console.error("Error:", error);
    }
  };

  const handleCurriculumList = async () => {
    try {
      const doctype = "Curriculum";
      const curriculumList = await getGenericList(doctype);

      // Add a unique identifier to each curriculum
      const curriculumWithId = curriculumList.map((curriculum) => ({
        ...curriculum,
        id: curriculum.id,
        entityType: "Curriculum",
      }));

      // Update the curriculumData state with curriculum
      setCurriculumData(curriculumWithId);

      // Assuming formConfig is a constant or comes from a static source
      const entityConfig = formConfig.find(
        (config) => config.entityType === "Curriculum"
      );
      handleSetFields(entityConfig?.fields, "Curriculum");

      // Update the dropdown options for curriculum
      const curriculumOptions = curriculumList.map((curriculum) => ({
        value: curriculum.id,
        label: curriculum.name, // Assuming the curriculum object has a property named 'name'
      }));
      const finalCurriculumData = {
        ...dataCurriculumOptions,
        curriculumList: curriculumOptions,
        isFetched: true,
      };
      console.log(finalCurriculumData, "RESPONSE curriculum");
      setDataCurriculumOptions(finalCurriculumData);
    } catch (error) {
      // Handle errors if needed
      console.error("Error:", error);
    }
  };
  const handleEmployeeList = async () => {
    try {
      const doctype = "Employee";
      const employeeList = await getGenericList(doctype);

      // Add a unique identifier to each Employee
      const employeesWithId = employeeList.map((employee) => ({
        ...employee,
        id: employee.id,
        entityType: "Employee",
      }));

      // Update the EmployeeData state with Employees
      setEmployeeData(employeesWithId);

      // Assuming formConfig is a constant or comes from a static source
      const entityConfig = formConfig.find(
        (config) => config.entityType === "Employee"
      );
      handleSetFields(entityConfig?.fields, "Employee");

      // Update the dropdown options for Employees
      const employeeOptions = employeeList.map((employee) => ({
        value: employee.id,
        label: employee.name, // Assuming the Employee object has a property named 'name'
      }));
      const finalEmployeeData = {
        ...dataEmployeeOptions,
        employeeList: employeeOptions,
        isFetched: true,
      };
      console.log(finalEmployeeData, "RESPONSE Employee");
      setDataEmployeeOptions(finalEmployeeData);
    } catch (error) {
      // Handle errors if needed
      console.error("Error:", error.message);
      toast.error("Error:", error.message);
    }
  };
  const handleInstructorList = async () => {
    try {
      const doctype = "Instructor";
      const instructorList = await getGenericList(doctype);

      // Add a unique identifier to each instructor
      const instructorsWithId = instructorList.map((instructor) => ({
        ...instructor,
        id: instructor.id,
        entityType: "Instructor",
      }));

      // Update the instructorData state with instructors
      setInstructorData(instructorsWithId);

      // Assuming formConfig is a constant or comes from a static source
      const entityConfig = formConfig.find(
        (config) => config.entityType === "Instructor"
      );
      handleSetFields(entityConfig?.fields, "Instructor");

      // Update the dropdown options for instructors
      const instructorOptions = instructorList.map((instructor) => ({
        value: instructor.id,
        label: instructor.name, // Assuming the instructor object has a property named 'name'
      }));
      const finalInstructorData = {
        ...dataInstructorOptions,
        instructorList: instructorOptions,
        isFetched: true,
      };
      console.log(finalInstructorData, "RESPONSE Instructor");
      setDataInstructorOptions(finalInstructorData);
    } catch (error) {
      // Handle errors properly
      console.error("Error fetching instructor list:", error);
      // Assuming setError is a function to set an error state
      setError("Error fetching instructor list:", error);
      // Display toast message for the error
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error fetching instructor list. Please try again later.");
      }
    }
  };

  const getCourseList = async () => {
    try {
      const doctype = "Course";
      const courseList = await getGenericList(doctype);

      // Add a unique identifier to each course
      const coursesWithId = courseList.map((course) => ({
        ...course,
        id: course.id,
        entityType: "Course",
      }));

      // Update the courseData state with courses
      setCourseData(coursesWithId);

      // Assuming formConfig is a constant or comes from a static source
      const entityConfig = formConfig.find(
        (config) => config.entityType === "Course"
      );
      handleSetFields(entityConfig?.fields, "Course");

      // Update the dropdown options for courses
      const courseOptions = courseList?.map((course, id) => ({
        value: course.id,
        label: course.name, // Assuming the course object has a property named 'name'
        code: course.code,
      }));
      const finalCourseData = {
        ...dataCourseOptions,
        courseList: courseOptions,
        isFetched: true,
      };
      console.log(finalCourseData, "RESPONSE Course");
      setDataCourseOptions(finalCourseData);
    } catch (error) {
      // Handle errors if needed
      console.error("Error:", error);
    }
  };

  const handleEntityList = async () => {
    try {
      const response = await getEntityList(entityType);

      if (response.success_key === 1) {
        // Assuming there's a property named 'isGeneric' that identifies generic entries
        const filteredData = response.message.filter((item) => !item.isGeneric);

        // Add entityType to each item in the filteredData
        const finalData = filteredData.map((item) => ({
          ...item,
          entityType: entityType,
          doctype: entityType,
        }));

        setRowData(finalData);

        // Assuming formConfig is a constant or comes from a static source
        const entityConfig = formConfig.find(
          (config) => config.entityType === entityType
        );
        handleSetFields(entityConfig?.fields, entityType);
      } else {
        console.error(
          `${entityType} data is not in the expected format:`,
          response
        );
      }
    } catch (error) {
      console.error(`Error fetching ${entityType} data:`, error);
    }
  };

  const getAcademicTermList = async () => {
    try {
      const response = await getEntityList("Academic Term");
      if (response.success_key === 1) {
        let options = [{ label: "", value: "" }];
        response.message.map((item) => {
          options.push({
            label: item.id,
            value: item.id,
          });
        });
        const finalAcademicTermData = {
          ...dataAcademicTermOptions,
          academicTermList: options,
          isFetched: true,
        };
        console.log(finalAcademicTermData, "RESPONSE Academic Term");
        setDataAcademicTermOptions(finalAcademicTermData);
      }
    } catch (error) {
      console.error(error, "Something went wrong");
    }
  };
  const getDepartmentList = async () => {
    try {
      const response = await getEntityList("Department");
      if (response.success_key === 1) {
        let options = [{ label: "", value: "" }];
        response.message.map((item) => {
          options.push({
            label: item.id,
            value: item.id,
          });
        });
        const finalDepartmentData = {
          ...dataDepartmentOptions,
          departmentList: options,
          isFetched: true,
        };
        console.log(finalDepartmentData, "RESPONSE Department");
        setDataDepartmentOptions(finalDepartmentData);
      }
    } catch (error) {
      console.error(error, "Something went wrong");
    }
  };
  const getAcademicYear = async () => {
    try {
      const response = await getEntityList("Academic Year");
      if (response.success_key === 1) {
        let options = [{ label: "", value: "" }];
        response.message.map((item) => {
          options.push({
            label: item.id,
            value: item.id,
            yearStartDate: item.year_start_date, // Include year_start_date in options
          });
        });
        const finalAcademicYrData = {
          ...dataAcademicYrOptions,
          academicYrList: options,
          isFetched: true,
        };
        console.log(finalAcademicYrData, "RESPONSE Academic Year");
        setDataAcademicYrOptions(finalAcademicYrData);
      }
    } catch (error) {
      console.error(error, "Something went wrong");
    }
  };
  // const getBulkEditOptions = async () => {
  //   try {
  //     const response = await getBulkEditFieldNames(rowData[0].entityType);
  //     if (response.status === 200) {
  //       let options = [];
  //       response.data.message.map((item) => {
  //         if (
  //           item.customfieldname !== "founded_year" &&
  //           item.customfieldname !== "default_currency" &&
  //           item.customfieldname !== "code" &&
  //           item.customfieldname !== "gstn" &&
  //           item.customfieldname !== "term_code"
  //         )
  //           options.push({
  //             field: item.customfieldname.replace(/_/g, " "),
  //             headerName: item.customfieldname.replace(/_/g, " "),
  //           });
  //       });

  //       console.log(options);
  //       //  setDataAcademicYrOptions(finalAcademicYrData);
  //       setbulkEditSelectValueOptions(options);
  //     }
  //   } catch (error) {
  //     console.error(error, "Something went wrong");
  //   }
  // };
  const getCollegeList = async () => {
    try {
      const response = await getEntityList("College");
      if (response.success_key === 1) {
        let options = [{ label: "", value: "" }];
        response.message.map((item) => {
          options.push({ label: item.id, value: item.id });
        });
        const finalCollegeData = {
          ...dataCollegeOptions,
          collegeList: options,
          isFetched: true,
        };
        console.log(finalCollegeData, "RESPONSE College");
        setDataCollegeOptions(finalCollegeData);
      }
    } catch (error) {
      console.error(error, "Something went wrong");
    }
  };
  const getCampusList = async () => {
    try {
      const response = await getEntityList("Campus");
      if (response.success_key === 1) {
        let options = [{ label: "", value: "" }];
        response.message.map((item) => {
          options.push({ label: item.id, value: item.id });
        });
        const finalCampusData = {
          ...dataCampusOptions,
          campusList: options,
          isFetched: true,
        };
        console.log(finalCampusData, "RESPONSE Campus");
        setDataCampusOptions(finalCampusData);
      }
    } catch (error) {
      console.error(error, "Something went wrong");
    }
  };
  const getCountryDataList = async () => {
    try {
      const response = await getCountryList();
      if (response.status === 200) {
        let options = [{ label: "", value: "" }];
        response.data.data.map((item) => {
          options.push({ label: item.name, value: item.name });
        });
        const finalCampusData = {
          ...dataCampusOptions,
          countryList: options,
          isFetched: true,
        };
        console.log(finalCampusData, "RESPONSE Campus");
        setDataCountryOptions(finalCampusData);
      }
    } catch (error) {
      console.error(error, "Something went wrong");
    }
  };
  const getCurrencyDataList = async () => {
    try {
      const response = await getCurrencyList();
      if (response.status === 200) {
        let options = [{ label: "", value: "" }];
        response.data.data.map((item) => {
          options.push({ label: item.name, value: item.name });
        });
        const finalCampusData = {
          ...dataCampusOptions,
          currencyList: options,
          isFetched: true,
        };
        console.log(finalCampusData, "RESPONSE Campus");
        setDataCurrencyOptions(finalCampusData);
      }
    } catch (error) {
      console.error(error, "Something went wrong");
    }
  };

  const getProgramList = async () => {
    try {
      const response = await getEntityList("Program");
      if (response.success_key === 1) {
        let options = [{ label: "", value: "" }];
        response.message.map((item) => {
          options.push({ label: item.id, value: item.id });
        });
        const finalProgramData = {
          ...dataProgramOptions,
          programList: options,
          isFetched: true,
        };
        console.log(finalProgramData, "RESPONSE Program");
        setDataProgramOptions(finalProgramData);
      }
    } catch (error) {
      console.error(error, "Something went wrong");
    }
  };
  const getUniversities = async () => {
    try {
      const response = await getEntityList("University");
      if (response.success_key === 1) {
        let options = [{ label: "", value: "" }];
        response.message.map((item) => {
          options.push({ label: item.id, value: item.id });
        });
        const finalData = {
          ...dataOptions,
          universityList: options,
          isFetched: true,
        };
        console.log(finalData, "RESPONSE UNIVERSITY");
        setDataOptions(finalData);
      }
    } catch (error) {
      console.error(error, "Something went wrong");
    }
  };

  const updateFormDataWithContentManagement = (newContentManagementData) => {
    console.log("saves clicked ");
    const payload = newContentManagementData.flatMap((unit) => {
      // Extract the unit ID
      const unitId = unit.id;

      // Return an array with one object containing the unit ID
      return [{ topic: unitId }];
    });

    setFormData((prevState) => ({
      ...prevState,
      contentManagementData: newContentManagementData,
      topics: payload,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      // Validate the entire form
      const isFormValid = validateForm();
      if (!isFormValid) {
        return;
      }

      setIsLoading(true);

      let assessment_criteria = formData?.assessment_criteria;
      assessment_criteria?.map((temp) => {
        temp.weightage = parseInt(temp.weightage);
      });
      setFormData({ ...formData, ...{ assessment_criteria } });

      const payloadData = {
        ...formData,
        entityType: entityType,
        doctype: selectedIds[0].doctype,
      };

      let response;
      if (
        entityType !== "Instructor" &&
        entityType !== "Curriculum" &&
        entityType !== "Course" &&
        entityType !== "Outcomes"
      ) {
        response = await fetch(APIs.createEntity, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payloadData),
        });
      } else {
        const coursesData = formData?.courses || [];
        payloadData.courses = coursesData; // Include courses data in the payload

        response = await createGenericEntity({ payload: payloadData });
      }

      if (response.status === 200) {
        toast.success(id ? `${entityType} Updated!` : `${entityType} Created!`);
      } else {
        let errorMessage = `Failed to save ${entityType}. Please try again later.`;
        if (
          response.data &&
          response.data.message &&
          response.data.message.message
        ) {
          // Extract and format the error message
          errorMessage = response.data.message.message;
        } else {
          errorMessage = `An error occurred while saving ${entityType}.`;
        }
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error(`Error saving  ${entityType}:`, error);
      toast.error(`An error occurred while saving  ${entityType}.`);
    } finally {
      setIsLoading(false); // Hide loading indicator regardless of success or error
    }
  };

  const handleFormCancel = () => {
    setShowAddCampusForm(false);
  };

  const renderColumnsByEntityType = (entityType, selectedColumns) => {
    const dataKeys = Object.keys(rowData[0] || {});

    // Include the commonAction column for all entity types
    const commonActionColumn = [
      {
        title: "Action",
        dataIndex: "action",
        render: (text, record) => (
          <a
            href={`/modules/academics/${entityType}/${record.id}`}
            rel="noopener noreferrer"
          >
            View
          </a>
        ),
      },
    ];

    // Add the serial number column
    const serialNumberColumn = {
      title: "No.",
      dataIndex: "serialNumber",
      render: (text, record, index) => index + 1,
      flex: 0.5,
    };

    // Filter columns based on the selectedColumns
    const filteredColumns = dataKeys
      .filter((key) => selectedColumns.includes(key))
      .map((key) => ({
        dataIndex: key,
        title: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " "), // Convert snake_case to Title Case
        flex: 0.5,
      }));

    // Add columns specific to the "Instructor" entity type
    if (entityType === "Instructor") {
      const instructorColumns = [
        { dataIndex: "instructor_name", title: "Instructor Name", flex: 0.5 },
        { dataIndex: "department", title: "Department", flex: 1 },
        { dataIndex: "status", title: "Status", flex: 0.5 },
      ];

      return [
        serialNumberColumn,
        ...filteredColumns,
        ...instructorColumns,
        ...commonActionColumn,
      ];
    }

    // Add columns specific to the "Curriculum" entity type
    if (entityType === "Curriculum") {
      const curriculumColumns = [
        { dataIndex: "id", title: "Id", flex: 1 },
        { dataIndex: "curriculum_name", title: "Curriculum Name", flex: 0.5 },
      ];

      return [
        serialNumberColumn,
        ...filteredColumns,
        ...curriculumColumns,
        ...commonActionColumn,
      ];
    }

    // Add columns specific to the "Curriculum" entity type
    if (entityType === "Outcomes") {
      const curriculumColumns = [
        { dataIndex: "id", title: "Id", flex: 1 },
        { dataIndex: "name", title: "Name", flex: 0.5 },
        { dataIndex: "outcome_type", title: "Outcome type", flex: 0.5 },
      ];

      return [
        serialNumberColumn,
        ...filteredColumns,
        ...curriculumColumns,
        ...commonActionColumn,
      ];
    }

    return [serialNumberColumn, ...filteredColumns, ...commonActionColumn];
  };

  const selectedColumns = [
    "id",
    "name",
    "code",
    "founded_year",
    "instructor_name",
    "curriculum_name",
    "department",
    "status",
  ];
  // Pass selectedColumns as the second argument
  const columnsToDisplay = renderColumnsByEntityType(
    entityType,
    selectedColumns
  );
  console.log(columnsToDisplay, "columnsToDisplay is selected");

  // const handleBulkEditPopupClose = () => {
  //   setAnchorEl(null);
  //   setBulkEditValue("");
  //   setbulkEditSelectValue("Select");
  // };

  // const handleBulkEditSubmit = async () => {
  //   try {
  //     console.log(rowSelectionModel);

  //     const payloadData = {
  //       doctype: rowData[0].doctype,
  //       docnames: rowSelectionModel,
  //       customfieldname: bulkEditSelectValue,
  //       value: bulkEditValue,
  //     };
  //     console.log(payloadData);
  //     const response = await fetch(APIs.bulkActionEdit, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(payloadData),
  //     });

  //     if (response.ok) {
  //       const responseData = await response.json();
  //       console.log(`${rowSelectionModel} deleted successfully:`, responseData);

  //       setshowBulkActionEditSuccessMessage(true);

  //       setTimeout(() => {
  //         setshowBulkActionEditSuccessMessage(false);
  //       }, 3000);
  //     } else {
  //       console.error(`Failed to delete ${entityType}:`, response.statusText);
  //     }
  //   } catch (error) {
  //     console.error(`Error adding ${entityType}:`, error.message);
  //   }

  //   handleEntityList();

  //   handleBulkEditPopupClose();
  // };

  // const handleBulkEditValueChange = (event) => {
  //   setBulkEditValue(event.target.value);
  // };

  // const handleBilkEditSelectChange = (event) => {
  //   setbulkEditSelectValue(event.target.value);
  // };

  const [activeTab, setActiveTab] = useState(null);
  const [showTabsContent, setShowTabsContent] = useState(true);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const [formDataByTab, setFormDataByTab] = useState({});

  // Save the form data whenever there is a change in the active tab
  useEffect(() => {
    const firstTab = formConfig.find(
      (config) => config.entityType === entityType
    )?.fields[0]?.tab;

    setActiveTab(firstTab);
    setShowTabsContent(true);

    if (entityType === "Program" || entityType === "Course") {
      setShowTabsContent(true);
    } else {
      setShowTabsContent(false);
      setActiveTab(null);
    }
  }, [entityType]);

  // Function to get options based on field name
  const getSelectOptions = (fieldName) => {
    switch (fieldName) {
      case "academic_year":
        return dataAcademicYrOptions.academicYrList;
      case "academic_term":
        return dataAcademicTermOptions.academicTermList;
      case "campus":
        return dataCampusOptions.campusList;
      case "college":
        return dataCollegeOptions.collegeList;
      case "department":
      case "primary_department":
      case "secondary_department":
        return dataDepartmentOptions.departmentList;
      case "course":
      case "courses":
      case "course_name":
        return dataCourseOptions.courseList;
      case "program":
        return dataProgramOptions.programList;
      case "curriculum":
        return dataCurriculumOptions.curriculumList;
      case "instructor":
        return dataInstructorOptions.instructorList;
      case "employee":
        return dataEmployeeOptions.employeeList;
      case "outcome_type":
        return dataOutcomesOptions.outcomesList;
      case "country":
        return dataCountryOptions.countryList;
      case "default_currency":
        return dataCurrencyOptions.currencyList;
      default:
        return dataOptions.universityList;
    }
  };

  useEffect(() => {
    // Update formDataByTab whenever formData changes
    setFormDataByTab((prevData) => ({
      ...prevData,
      [activeTab]: formData,
    }));
  }, [activeTab, formData]);

  useEffect(() => {
    const firstTab = formConfig.find(
      (config) => config.entityType === entityType
    )?.fields[0]?.tab;

    setActiveTab(firstTab);
    setShowTabsContent(true);

    if (
      entityType === "Course" ||
      entityType === "Program" ||
      entityType === "Instructor"
    ) {
      setShowTabsContent(true);
    } else {
      setShowTabsContent(false);
      setActiveTab(null);
    }
  }, [entityType]);

  const renderTabContent = (tab) => {
    const fieldsForTab = formConfig
      .find((config) => config.entityType === entityType)
      ?.fields.filter((field) => field.tab === tab);

    if (!fieldsForTab || !fieldsForTab.length) {
      return null;
    }

    const renderedFields = fieldsForTab.map((field, index) => {
      const value = formDataByTab[tab]?.[field.name];
      if (field.type === "page") {
        return (
          <div style={{ width: "100%" }}>
            <ComponentPageToRender field={field} />
          </div>
        );
      } else if (field.type === "table") {
        return renderFormField(field, value); // Pass the table data to the form field
      } else if (field.type === "select" || field.type === "select2") {
        field.options = getSelectOptions(field.name);
      }

      return renderFormField({
        ...field,
        value, // Pass the value to the form field
      });
    });

    return renderedFields;
  };

  const ComponentPageToRender = ({ field }) => {
    switch (field.pagename) {
      case "ContentManagement":
        return (
          <CourseContentManagement
            updateFormDataWithContentManagement={
              updateFormDataWithContentManagement
            }
            formData={formData.contentManagementData}
          />
        );
      default:
        return null;
    }
  };

  const renderTabs = () => {
    const tabs = Array.from(
      new Set(
        formConfig
          .find((config) => config.entityType === entityType)
          ?.fields.map((field) => field.tab)
      )
    ).flat();

    if (!tabs || !tabs.length) {
      return null;
    }

    return (
      <div className="tabs-bar">
        {tabs.map((tab) => (
          <div
            key={tab}
            onClick={() => handleTabClick(tab)}
            className={`tabsItem ${activeTab === tab ? "active-tab" : ""}`}
          >
            {tab}
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    const firstTab = formConfig.find(
      (config) => config.entityType === entityType
    )?.fields[0]?.tab;

    setActiveTab(firstTab);
    setShowTabsContent(true);

    if (
      entityType === "Course" ||
      entityType === "Program" ||
      entityType === "Instructor"
    ) {
      setShowTabsContent(true);
    } else {
      setShowTabsContent(false);
      setActiveTab(null);
    }
  }, [entityType]);

  // const handleRowSelectionChange = (selectedRowKeys) => {
  //   setRowSelectionModel(selectedRowKeys);
  // };

  const displayData =
    entityType !== "Employee"
      ? entityType !== "Instructor"
        ? entityType !== "Curriculum"
          ? entityType !== "Course"
            ? entityType !== "Outcomes"
              ? rowData
              : outcomesData
            : courseData
          : curriculumData
        : instructorData
      : employeeData;

  return (
    <MainLayout>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Box sx={{ height: "100%" }}>
            {showAddCampusForm &&
            dataOptions.isFetched &&
            dataCampusOptions.isFetched &&
            dataDepartmentOptions.isFetched &&
            dataCourseOptions.isFetched &&
            dataProgramOptions.isFetched &&
            dataCurriculumOptions.isFetched &&
            dataEmployeeOptions.isFetched &&
            dataOutcomesOptions.isFetched &&
            dataCollegeOptions.isFetched &&
            dataAcademicYrOptions.isFetched &&
            dataAcademicTermOptions.isFetched ? (
              <div className="formApplication">
                <section className="form-section">
                  <Breadcrumbs />
                  <div className="master-heading">
                    <h2>{`Add New ${entityType}`}</h2>
                    <div className="btnsGrp">
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleFormCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFormSubmit}
                        className="redBtn"
                      >
                        {isLoading ? "Submitting..." : "Submit"}
                      </Button>
                    </div>
                  </div>

                  <div className="fields-block fullWidthFields">
                    {showTabsContent ? (
                      <div className="tabsContent fullWidthFields">
                        {renderTabs()}
                        <div className="tab-content">
                          <div className="fields-block ">
                            {activeTab && renderTabContent(activeTab)}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {formConfig
                          .find((config) => config.entityType === entityType)
                          ?.fields.map((field) => {
                            if (field.type === "table") {
                              return renderFormField(field);
                            } else {
                              // Render other form fields
                              if (
                                field.type === "select" ||
                                field.type === "select2"
                              ) {
                                field.options = getSelectOptions(field.name);
                              }

                              // Determine visibility based on the type of outcome selected
                              const isVisible =
                                field.name === "course"
                                  ? isVisibleCourseDropdown
                                  : field.name === "program"
                                  ? isVisibleProgramDropdown
                                  : true;

                              if (field.type === "select1") {
                                // Handle select1 type separately
                                return renderFormField({
                                  ...field,
                                  isVisible,
                                  handleSelectChange1, // Pass the appropriate function
                                });
                              } else {
                                // For other field types
                                return renderFormField({
                                  ...field,
                                  isVisible,
                                  handleSelectChange: handleSelectChange1, // Pass the appropriate function
                                });
                              }
                            }
                          })}
                      </>
                    )}
                  </div>
                </section>
              </div>
            ) : (
              <>
                <CustomToolbar />
                {loading ? (
                  <LoadingBackdrop open={loading} />
                ) : (
                  <Table
                    className="mainTable"
                    rowKey="id"
                    columns={columnsToDisplay}
                    dataSource={displayData}
                    rowSelection={{
                      type: "checkbox",
                      ...rowSelection,
                    }}
                  />
                )}
                {isEditOpen && (
                  <BulkEdit
                    handleBulkEdit={handleBulkEdit}
                    doctype={rowData[0].doctype}
                    isEditOpen={isEditOpen}
                    setIsEditOpen={setIsEditOpen}
                    items={selectedIds.length}
                  />
                )}
              </>
            )}
          </Box>
        </>
      )}
    </MainLayout>
  );
};

export default EntityList;

import axios from "axios";
import { localStorageHelper } from "./storageHelper";
import moment from "moment";
import { D_MMMM_YYYY_h_mm_ss, h_mm_A } from "constants/DateConstants";
import { drawerEnum } from "constants/commonConstants";
import { statusCode } from "constants/apis/apiStatus";
import { GENDER, MARTIAL_STATUS } from "common/enum/LeadEnum";

export function placeParams(pathRegex, params) {
  var segments = pathRegex.split("/");
  return segments
    .map((segment) => {
      var offset = segment.indexOf(":") + 1;
      if (!offset) return segment;

      var key = segment.slice(offset);
      return params[key];
    })
    .join("/");
}

export const PhoneNumber = (phoneNo) => {
  return phoneNo;
};

export const PhoneNumberInversion = (phoneNo) => {
  return phoneNo;
};

export const CheckIfImageExists = async (path) => {
  return axios
    .get(path)
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const generateRandomFileNameWithExt = (format = ".png") => {
  return `${Math.random().toString(36).slice(2, 7)}${format}`;
};

export const hasAccess = (requiredRole, roles) => {
  return requiredRole?.some((item) => {
    return roles?.includes(item);
  });
};

export const createFormData = (data) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }
  return formData;
};

export const getRoleIdFromRoles = (role_search, allRoles) => {
  if (allRoles && allRoles.length) {
    const role = allRoles.find((item) => item.name === role_search);
    return role?.id;
  }
};

export const dynamicListMapper = (list, property, id, customId = false) => {
  return list?.map((data, index) => ({
    id: customId ? data?.id : index + 1,
    label: data[property] ?? "",
    key: index + 1,
    value: data[id],
  }));
};

export const dynamicAutoListMapper = (list, property, id) => {
  return list?.map((data, index) => ({
    id: data?.[id] || index,
    label: data?.[property] || "-",
    value: data?.[id] || "-",
  }));
};

export const getCourseInfoIds = () => {
  const courseInfoIds = {
    deptId: localStorageHelper.get("departmentId"),
    clusterId: localStorageHelper.get("clusterId"),
    courseId: localStorageHelper.get("courseId"),
  };
  return courseInfoIds;
};

export const dynamicDateMapper = (list) => {
  return list?.map((data) => {
    const startDate =
      data?.session_start_datetime?.split("T")[0] +
      " " +
      data?.session_start_datetime?.split("T")[1]?.split("Z")[0];
    const endDate =
      data?.session_start_datetime?.split("T")[0] +
      " " +
      data?.session_start_datetime?.split("T")[1]?.split("Z")[0];

    let calenderObj = {
      id: data?.session_id,
      title:
        data?.action === true
          ? `Present- ${data?.session_title}`
          : `Absent- ${data?.session_title}`,
      start: moment(new Date(startDate)).toDate(),
      end: moment(new Date(endDate)).toDate(),
    };
    return calenderObj;
  });
};

export const dynamicDateMapperForStudent = (list) => {
  return list?.map((data) => {
    const startDate = moment.utc(data?.session_start_datetime);
    const endDate = moment.utc(data?.session_end_datetime);
    const timeRange = `${startDate.format(h_mm_A)} - ${endDate.format(h_mm_A)}`;

    let calenderObj = {
      id: data?.session_id,
      title: `${timeRange} ${""}, ${""}${data?.action === true ? "Present" : "Absent"} - ${
        data?.session_title
      }`,
      start: startDate.toDate(),
      end: endDate.toDate(),
    };

    return calenderObj;
  });
};

export const getCurrentWeekFromToDates = () => {
  let curr = new Date();
  let first = curr.getDate() - curr.getDay();
  let last = first + 6;
  const currentWeekDates = {
    fromDate: new Date(curr.setDate(first)).toISOString(),
    toDate: new Date(curr.setDate(last)).toISOString(),
  };
  return currentWeekDates;
};
export const getTimeZoneFromNewDate = () => {
  return /\((.*)\)/.exec(new Date().toString())[1];
};

export const addressMapper = (list, name, code, isId) => {
  return list?.map((data) => ({
    id: isId ? data[code] : Math.random() * 10,
    label: data[name],
    value: data[code],
    key: data[code],
  }));
};

export const addressFinder = (list, value) => {
  return list?.find((data) => {
    return data?.name?.toLowerCase() === value;
  });
};

export const makeId = () => {
  let r = (Math.random() + 1).toString(36).substring(7);
  return r;
};

// export const setFormErrorHelper = (
//   mainErrorItem,
//   errorObj,
//   setError,
//   non_form_field_keys = [],
//   dispatch
// ) => {
//   errorObj?.forEach((fieldItem, index) => {
//     Object.keys(fieldItem)?.forEach?.((fieldKey) => {
//       if (non_form_field_keys?.includes(fieldKey)) {
//         dispatch(
//           setToastMessage({
//             data: `${fieldKey}: ${fieldItem[fieldKey]?.[0]}`,
//             severity: "error",
//           })
//         );

//         if (fieldKey == "doc_path") {
//           setError(`${mainErrorItem}[${index}].doc_file`, {
//             type: "custom",
//             message: fieldItem[fieldKey]?.[0],
//           });
//         }
//       } else {
//         setError(`${mainErrorItem}[${index}].${fieldKey}`, {
//           type: "custom",
//           message: fieldItem[fieldKey]?.[0],
//         });
//       }
//     });
//   });
// };

export const isRubricEnabled = () => {
  return true;
  const rubricEnabledTenants = ["AnnaUniversity", "IIHTUniversity", "geetauniversity"];
  const localTenant = localStorageHelper.get("tenantName");
  return rubricEnabledTenants?.includes(localTenant);
};

export const setUTCToLocalTime = (UTC_time, format) => {
  return moment.utc(UTC_time).local().format(format);
};

export const getTenantLogo = () => {
  let tenantLogo = "";
  let tenantName = "";
  const access_token = localStorageHelper.get("access_token") ?? "";
  const currentEnv = window.location.hostname.split(".")[0];
  const localEnv = ["localhost", "dev", "stage"];
  const UAT_ENV = "uat";
  if (access_token) {
    tenantName = localStorageHelper.get("tenantName") ?? "";
  } else {
    tenantName = window.location.pathname.trim().split("/")[1];
  }
  if (localEnv.includes(currentEnv)) {
    tenantLogo = `https://irisb2astagdatacontainer.blob.core.windows.net/b2alogos/${tenantName}/tenantlogo.png`;
  }
  if (currentEnv === UAT_ENV) {
    tenantLogo = `https://irisb2auatdatacontainer.blob.core.windows.net/b2alogos/${tenantName}/tenantlogo.png`;
  }
  return tenantLogo;
};

export const getDocNumber = (docKey = "", candidateDetails) => {
  const kycDocument = candidateDetails?.kyc_document ?? [];
  const foundDocumentNumber = kycDocument?.find((kycDoc) => kycDoc.doc === docKey)?.doc_number ?? 0;
  return foundDocumentNumber;
};

export const getPreferredLanguage = (arr = []) => {
  let convertedArr = arr?.map((word) => {
    const firstLetter = word?.charAt(0)?.toUpperCase();
    const rest = word?.slice(1)?.toLowerCase();
    return firstLetter + rest || "NA";
  });
  if (convertedArr?.length === 0) {
    return "NA";
  }
  return convertedArr.join(", ");
};

export const getCourse = (course) => {
  return course?.map((item) => <div key={item?.id}>{item?.course_name}</div>);
};

export const getMatchingRoles = (allRoles, roles_list) => {
  // returns comma separated rolesids
  return allRoles
    ?.filter(function (role) {
      return roles_list?.indexOf(role?.role_name) !== -1;
    })
    ?.map((d) => d?.id)
    ?.toString();
};

export const getMatchingRolesfunction = (allRoles, roles_list) => {
  // returns comma separated rolesids
  if (!Array.isArray(allRoles)) {
    console.error("allRoles is not an array:", allRoles);
    return "";
  }
  const filteredRoles = allRoles
    .filter(function (role) {
      return roles_list?.indexOf(role?.name) !== -1;
    })
    .map((d) => d?.id);
  if (filteredRoles.length === 0) {
    return "";
  }
  return filteredRoles.join(",");
};

export const generateRandom = () => {
  var length = 8,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export const isHttp = (str) => {
  const regex = new RegExp("^(http|https)://", "i");
  return str?.match?.(regex) ? str : `//${str}`;
};

export const getLeadCurrentStage = (leadCurrentStage) => {
  let options = Object.entries(leadCurrentStage).map(([key, value], index) => {
    let stage = {};
    if (key !== "lead_name") {
      stage = {
        label: drawerEnum[key],
        date: value?.date ? moment(value?.date).format(D_MMMM_YYYY_h_mm_ss) : "-",
        owner: value?.name,
        completed: true,
        status: value?.stage,
        id: index - 1,
      };
    }
    return stage;
  });
  return options;
};

export const percentageToNumber = (total, percentage) => {
  return !isNaN(total) && !isNaN(percentage) ? parseInt((percentage * total) / 100) : "";
};

export const getHoursFromMins = (minutes) => {
  const hrs = Math.floor(parseInt(minutes) / 60);
  return hrs > 9 ? hrs : "0" + hrs;
};

export const getMins = (minutes) => {
  return Math.floor(parseInt(minutes) % 60);
};

export const findCandidateCurrentStage = (activeStepId, stepperOptions) => {
  return activeStepId !== stepperOptions?.length
    ? stepperOptions[activeStepId].label
    : stepperOptions[activeStepId - 1].label;
};

export const getActiveStepId = (stages) => {
  let activeStepId;
  const inProgressStage = stages?.filter((stage) => {
    return stage.status === drawerEnum.inProgress;
  });

  if (!inProgressStage.length) {
    const lastDoneStage = stages?.filter((stage) => {
      return stage.status === drawerEnum.done;
    });
    if (lastDoneStage?.length) {
      activeStepId = lastDoneStage?.[lastDoneStage?.length - 1]?.id + 1;
    }
  } else {
    activeStepId = inProgressStage?.[inProgressStage?.length - 1]?.id;
  }

  return activeStepId;
};

export const isCounsellingOrVerificationStarted = (leadCurrentStage, stage) => {
  let stageInfo = leadCurrentStage[stage];
  return stageInfo.stage === "" ? false : true;
};

export const truncateString = (string, limit) => {
  if (string?.length <= limit) {
    return string;
  }
  return string?.slice(0, limit) + "...";
};
export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const processBlobPathURL = async (dataArr, fetchBlobURlFunc, key) => {
  let fileData = dataArr || [];
  let result;
  let promises = [];
  for (const urlObj of fileData) {
    promises.push(fetchBlobURlFunc(urlObj[key]));
  }
  result = await Promise.all(promises);

  if (fileData) {
    for (let i = 0; i < fileData?.length; i++) {
      fileData[i]["fileURL"] = result[i];
      fileData[i]["fileName"] = fileData[i][key]
        ?.split("/")
        ?.slice(-1)?.[0]
        ?.split("_")
        ?.slice(-1)?.[0];
      fileData[i]["fileType"] = fileData[i][key]
        ?.split("/")
        ?.slice(-1)?.[0]
        ?.split("_")
        ?.slice(-1)?.[0]
        ?.split(".")?.[1];
    }
  }
  return fileData;
};

export const getCourseAndYear = (course) => {
  return course?.map((item) => (
    <div key={item?.id}>{`${item?.course_name} (AY ${item?.academic_year})`}</div>
  ));
};

export const createValidUrl = (str) => {
  if (str != "NA") {
    if (!/^https?:\/\//i.test(str)) {
      return "https://" + str;
    }
  }
  return str;
};

export const removeUnderscore = (str) => str.replace(/_/gi, " ");

export const convertMinToTime = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${hours > 0 ? hours : ""}${hours > 0 ? "hr" : ""} ${minutes} ${
    minutes >= 0 ? "min" : ""
  }`;
};

export const convertSecToTime = (totalSeconds) => {
  const totalMinutes = Math.floor(totalSeconds / 60);
  const seconds = Math.floor(totalSeconds % 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${hours > 0 ? hours : ""}${hours > 0 ? "hr" : ""} ${minutes} ${
    minutes >= 0 ? "min" : ""
  } ${seconds > 0 ? seconds : ""} ${seconds > 0 ? "sec" : ""}`;
};

export const checkIfObjectNotEmpty = (obj) => {
  if (obj && Object.keys(obj).length > 0) {
    return true;
  }
  return false;
};

export const removeSpace = (data) => {
  return data.replace(/\s/g, "");
};

import NonActiveIcon from "../../../assets/images/non-active-sidebar-icon.svg";

export const stautsTypeOptions = [
  {
    value: "Present",
    label: "Present",
  },
  {
    value: "Absent",
    label: "Absent",
  },
];

export const employeeStautsTypeOptions = [
  {
    value: "Present",
    label: "Present",
  },
  {
    value: "Absent",
    label: "Absent",
  },
  {
    value: "Half Day",
    label: "Half Day",
  },
  {
    value: "On Leave",
    label: "On Leave",
  },
  {
    value: "Work From Home",
    label: "Work From Home",
  },
];

export const attendanceBasedOnOptions = [
  {
    value: "Student Group",
    label: "Student Group",
  },
  {
    value: "Course Schedule",
    label: "Course Schedule",
  },
];

export const groupBasedOnOptions = [
  {
    value: "Batch",
    label: "Batch",
  },
  {
    value: "Course",
    label: "Course",
  },
  {
    value: "Activity",
    label: "Activity",
  },
];
export const attendanceDashboardList = [
  {
    name: "Employee Attendance",
    icon: NonActiveIcon,
    url: "/employee-attendance",
    forStudents: false,
  },
];

import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { useNavigate, useParams } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import MainHeader from "../../../common/MainHeader";
import MainFooter from "../../../common/MainFooter";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSidebar } from "../../../../slices/financeSlice";
import { setLoading } from "../../../../slices/commonSlice";
import LoadingBackdrop from "../../../common/LoadingBackdrop.js/index.js";
import { toast } from "react-toastify";
import {
  getRoomDetailsById,
} from "modules/seatManagement";

const GetUpdateRoom = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { name } = useParams();
  const { loading } = useSelector((state) => state.common);
  const [updateRoomDetails, setUpdateRoomDetails] = useState(false);
  const [formData, setFormData] = useState({
    roomName: "",
    roomNumber: "",
    seatingCapacity: "",
    row: "",
    column: "",
    NoOfStudentPerSeat: 1,
  });

  const handleGetRoomDetailsById = async () => {
    const payload = {
      doctype: "Room",
      id: name,
    };

    const res = await getRoomDetailsById({ payload });
    const roomDetails = res.data.message;
    console.log("roomDetails: ", roomDetails);
    setFormData({
      roomName: roomDetails.room_name,
      roomNumber: roomDetails.room_number,
      seatingCapacity: roomDetails.seating_capacity,
      row: roomDetails.custom_row,
      column: roomDetails.custom_column,
      NoOfStudentPerSeat: roomDetails.custom_number_of_student_per_seat,
    })
  };

  const handleUpdate = async () => {
    dispatch(setLoading({ loading: true }));
    const payload = {
      doctype: "Room",
    };

    console.log("paylod: ", payload)
    dispatch(setLoading({ loading: false }));
  };

  useEffect(() => {
    handleGetRoomDetailsById();
  }, []);

  return (
    <>
      <MainHeader />
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/dashboard");
                dispatch(setActiveSidebar({ activeSidebar: 0 }));
              }}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/modules/examination");
                dispatch(setActiveSidebar({ activeSidebar: 1 }));
              }}
            >
              Examination {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/modules/examination");
                dispatch(setActiveSidebar({ activeSidebar: 1 }));
              }}
            >
              Seat Management {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/modules/examination/seat-management/rooms");
              }}
            >
              Rooms
            </p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>{name}</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div
                className={styles.button}
                onClick={() => {
                  navigate("/modules/examination/seat-management/rooms");
                }}
              >
                <p className={styles.buttonText}>Back</p>
              </div>
              {updateRoomDetails ? (
                <div className={styles.activeButton} onClick={handleUpdate}>
                  <p className={styles.activeButtonText}>Update</p>
                </div>
              ) : (
                <div
                  className={styles.activeButton}
                  onClick={() => setUpdateRoomDetails(true)}
                >
                  <p className={styles.activeButtonText}>Edit</p>
                </div>
              )}
            </div>
          </div>
          <div className={styles.formContainer}>
          <div className={styles.formRow}>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Room Name *</p>
              <input
                type="text"
                placeholder="ex text"
                className={styles.fieldInput}
                value={formData.roomName}
                disabled={!updateRoomDetails}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    roomName: e.target.value,
                  });
                }}
              />
            </div>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Room Number *</p>
              <input
                placeholder="ex 302"
                className={styles.fieldInput}
                type="text"
                value={formData.roomNumber}
                disabled={!updateRoomDetails}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    roomNumber: e.target.value,
                  });
                }}
              />
            </div>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Seating Capacity *</p>
              <input
                className={styles.fieldInput}
                value={formData.seatingCapacity}
                disabled={true}
              />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Row *</p>
              <input
                placeholder="ex 10"
                className={styles.fieldInput}
                type="text"
                value={formData.row}
                disabled={!updateRoomDetails}
                onChange={(e) => {
                  if (formData.column && e.target.value) {
                    setFormData({
                      ...formData,
                      seatingCapacity:
                        Number(formData.column) * Number(e.target.value),
                      row: Number(e.target.value),
                    });
                  } else {
                    setFormData({
                      ...formData,
                      row: Number(e.target.value),
                    });
                  }
                }}
              ></input>
            </div>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Column *</p>
              <input
                placeholder="ex 3"
                className={styles.fieldInput}
                type="text"
                value={formData.column}
                disabled={!updateRoomDetails}
                onChange={(e) => {
                  if (formData.row && e.target.value) {
                    setFormData({
                      ...formData,
                      seatingCapacity:
                        Number(e.target.value) * Number(formData.row),
                      column: Number(e.target.value),
                    });
                  } else {
                    setFormData({
                      ...formData,
                      column: Number(e.target.value),
                    });
                  }
                }}
              />
            </div>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>No. of Student Per Seat *</p>
              <input
                className={styles.fieldInput}
                value={formData.NoOfStudentPerSeat}
                disabled={true}
              />
            </div>
          </div>
          </div>
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default GetUpdateRoom;

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: "flex", 
    flexDirection: "column",
    gap: "28px"
  },
  breadCrumbsContainer: {
    display: "inline-flex", 
    gap: "4px", 
    alignItems: "center"
  },
  breadCrumbsContent: {
    color: "#6F6F6F",
    fontSize: "12px",
    fontWeight: "400",
    cursor: "pointer"
  },
  headerContainer: {
    display: "inline-flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "-20px"
  },
  title: {
    fontSize: "22px", 
    color: "#0D1021", 
    fontWeight: "500"
  },
  gridIcon: {
    color: "#647B8F"
  },
}));

export default useStyles;

import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { useNavigate, useParams } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { useDispatch, useSelector } from "react-redux";
import { Select, Table, Input } from "antd";
import { toast } from "react-toastify";
import MainHeader from "components/common/MainHeader";
import MainFooter from "components/common/MainFooter";
import { getGradingScaleByID } from "modules/hr";

const GetUpdateGradingScale = () => {
  const styles = useStyles();
  const selectSize = "middle";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading } = useSelector((state) => state.common);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [values, setValues] = useState({
    grading_scale_name: "",
    components: [{}],
    description: "",
  });
  const [errors, setErrors] = useState({
    grading_scale_name: "",
    components: [],
  });

  const handleGetGradingScaleByID = async () => {
    const params = {
      doctype: "Grading Scale",
      id: id,
    };
    const res = await getGradingScaleByID(params);
    const data = res.data.message;
    const mappedComponents = data.intervals.map((interval) => ({
      grade_code: interval.grade_code,
      threshold: interval.threshold,
      grade_description: interval.grade_description,
    }));
    setValues({
      grading_scale_name: data.grading_scale_name,
      components: mappedComponents,
      description: data.description,
    });
  };
  const handleGradingScaleNavigation = () => {
    navigate("/modules/hr");
  };
  const onSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = {
    type: "checkbox",
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      grade_code: record.grade_code,
    }),
    selectedRowKeys,
  };

  const columns = [
    {
      title: "Grade Code",
      dataIndex: "grade_code",
      width: "25%",
      render: (val, record, index) => {
        return (
          <Input
            placeholder="Enter Grade Code"
            disabled={true}
            className={styles.tableContentFields}
            style={{ width: "100%", border: "none" }}
            value={record.grade_code}
            onChange={(e) => {
              record.grade_code = e.target.value;
            }}
          />
        );
      },
    },
    {
      title: "Threshold",
      dataIndex: "threshold",
      width: "25%",
      render: (val, record) => {
        return (
          <Input
            placeholder="Enter Threshold"
            disabled={true}
            className={styles.tableContentFields}
            style={{ width: "100%", border: "none" }}
            value={record.threshold}
            onChange={(e) => {
              record.threshold = e.target.value;
            }}
            addonAfter="%"
          />
        );
      },
    },
    {
      title: "Grade Description",
      dataIndex: "grade_description",
      width: "40%",
      render: (val, record) => {
        return (
          <Input.TextArea
            placeholder="Enter Grade Description"
            disabled={true}
            className={styles.tableContentFields}
            style={{ width: "100%", border: "none" }}
            value={record.grade_description}
            onChange={(e) => {
              record.grade_description = e.target.value;
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    handleGetGradingScaleByID();
  }, []);

  return (
    <>
      <MainHeader />
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/dashboard")}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => navigate("/modules/hr")}
            >
              HR {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={handleGradingScaleNavigation}
            >
              Grading Scale {" > "}
            </p>
            <p className={styles.breadCrumbsContent}>{id}</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>{id}</p>
            </div>
            <div className={styles.buttonsGroup}>
              <div
                className={styles.button}
                onClick={handleGradingScaleNavigation}
              >
                <p className={styles.buttonText}>Back</p>
              </div>
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formRow}>
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Grading Scale Name *</p>
                <Input
                  placeholder="ex Text"
                  value={values.grading_scale_name}
                  className={styles.fieldInput}
                  disabled={true}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      grading_scale_name: e.target.value,
                    });
                    setErrors({
                      ...errors,
                      grading_scale_name: "",
                    });
                  }}
                />
                {errors.grading_scale_name && (
                  <p className={styles.errorMessage}>
                    {errors.grading_scale_name}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.tableField}>
                <p className={styles.fieldLabel}>Grading Scale Interval *</p>
                <Table
                  rowSelection={{ type: "checkbox", ...rowSelection }}
                  columns={columns}
                  dataSource={values.components}
                  size="small"
                  pagination={false}
                  rowKey={(record) => record.grade_code}
                />
                {errors.components && (
                  <p className={styles.errorMessage}>{errors.components}</p>
                )}
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.textarea}>
                <p className={styles.fieldLabel}>Description </p>
                <textarea
                  type="text"
                  placeholder="Enter Text here..."
                  disabled={true}
                  className={styles.textAreaInput}
                  value={values.description}
                  rows={5}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      description: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <MainFooter />
    </>
  );
};

export default GetUpdateGradingScale;

import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { resetpassword } from '../../modules/auth';
// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import { toast } from "react-toastify";
import TechademyLogo from '../../assets/images/TechademyLogo.svg';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from 'iiht-b2a-ui-components/build/components';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { selectResetPasswordEmail } from '../../slices/authSlice';

// import {
//   isEmailValid,
//   isPasswordConfirmed,
//   isPasswordValid,
// } from 'utils/validation';
function ResetPassword() {
  const { t } = useTranslation();
//   let history = useHistory();
//   let dispatch = useDispatch();
  const theme = useTheme();
  // const { email } = useParams();
  // const email_id = email.split("=")[1];
  const [tenantSponsorLogos, setTenantSponsorLogos] = useState({});
//   const [error, setError] = useState(signInFormError);
  const [values, setValues] = useState({
    password: '',
    confirmPassword: '',
    showPassword: false,
  });
  const [emailParam,setEmailParam] = useState("")

  useEffect(() => {
    // Extracting email from URL using URLSearchParams
    const queryParams = new URLSearchParams(window.location.search);
    const EmailParam = queryParams.get('email');
    setEmailParam(EmailParam)
  
    if (emailParam) {
      // Log the decoded email value to the console
      console.log('Email:', decodeURIComponent(emailParam));
    }
  }, [emailParam]);

  const handleResetPassword = async () => {
    try {
      if (values.password !== values.confirmPassword) {
        toast.success('New Password and Confirm Paswword should be same', { position: toast.POSITION.TOP_RIGHT });
        return;
      }

      const payload = {
        user: emailParam,
        pwd: values.password,
      };
      const res = await resetpassword({ payload });

      if (res.status === 200) {
        toast.success('Password reset successfully!', { position: toast.POSITION.TOP_RIGHT });
        setValues({
          password: '',
          confirmPassword: '',
          showPassword: false,
        })
      } else {
        console.error('Reset Failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  const handleChange = (event) => {
    setValues({ ...values, [event.target.id]: event.target.value });
    // handleError(event.target.id, event.target.value);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

//   const handleKeyPress = (e) => {
//     if (e.charCode === 13 && isFormValid(error)) {
//       onLoginClick();
//     }
//   };



  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values?.showPassword,
    });
  };

//   const handleError = (fieldName, fieldValue) => {
//     switch (fieldName) {
//       case 'password': {
//         setError((prev) => {
//           return { ...prev, [fieldName]: !isPasswordValid(fieldValue) };
//         });
//         break;
//       }
//       case 'confirmPassword': {
//         setError((prev) => {
//           return { ...prev, [fieldName]: !isPasswordConfirmed(fieldValue) };
//         });
//         break;
//       }
//     }
//   };

  return (
    <>
      <>
        <div className="login-form-logo-wrapper">
          <div className="sign-in-section">
            {tenantSponsorLogos?.logo_image_url ? (
              <img
                className="tenantLogo"
                alt="Tenant Logo"
                src={tenantSponsorLogos?.logo_image_url}
              />
            ) : (
              <img
                className="defaultTenantLogo"
                alt="Default Logo"
                src={TechademyLogo}
              />
            )}
            <form autoComplete="off" className="sign-form">
              <Paper elevation={0} className="sign-in-input-wrapper p-2 pt-4">
                <div className="reset-password">
                  <Typography variant="h4" sx={{ mb: 3, mt: 2 }}>
                    {t('createNewPassword')}
                  </Typography>
                </div>
                <div className="reset_details">
                  <div>{t('differentPassword')}</div>
                </div>

                <FormControl
                  tabIndex={1}
                  variant="outlined"
                  sx={{ width: '80%', mb: 2 }}
                //   error={error?.password}
                  // fullWidth
                >
                  <TextField
                    label={`${t('password')} *`}
                    id="password"
                    className="qa-sign-in-password"
                    type={values?.showPassword ? 'text' : 'password'}
                    value={values?.password || ''}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            sx={{ color: theme.palette.grey[500] }}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values?.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    // onKeyPress={handleKeyPress}
                    autoComplete="new-password"
                  />
                  <FormHelperText>
                    {/* {error?.password ? t('signIn.password.error') : ''} */}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  tabIndex={1}
                  variant="outlined"
                  sx={{ width: '80%', mb: 2 }}
                //   error={error?.password}
                  // fullWidth
                >
                  <TextField
                    label={`${t('confirmPassword')} *`}
                    id="confirmPassword"
                    className="qa-sign-in-password"
                    type={values?.showPassword ? 'text' : 'confirmPassword'}
                    value={values?.confirmPassword || ''}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            sx={{ color: theme.palette.grey[500] }}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values?.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    // onKeyPress={handleKeyPress}
                    autoComplete="new-password"
                  />
                  <FormHelperText>
                    {/* {error?.password ? t('signIn.password.error') : ''} */}
                  </FormHelperText>
                </FormControl>
                <br />
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  className="mx10"
                >
                  <Button
                    className="qa-sign-in-button"
                    variant="contained"
                    color="secondary"
                    tabIndex={5}
                    sx={{
                      width: '100%',
                      alignSelf: 'center',
                      color: theme.palette.common.white,
                      textTranform: 'uppercase',
                    }}
                    onClick={handleResetPassword}
                  >
                    {t('resetPassword')}
                  </Button>
                </Grid>

                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className="mx101"
                  sx={{ mb: 2 }}
                >
                  {t('unableLogin')}
                  <Typography color="#004FAF" sx={{ cursor: 'pointer', ml: 1 }}>
                    {t('support')}
                  </Typography>
                </Grid>
                <br />
              </Paper>
            </form>
          </div>
        </div>
      </>
    </>
  );
}

export default ResetPassword;

import React, { useEffect, useState } from 'react';
import { Table, Popover } from 'antd';
import useStyles from './style';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { GridMenuIcon } from '@mui/x-data-grid';
import { setLoading } from '../../../slices/commonSlice';
import CommonFilters from '../../Filters/CommonFilters';
import SavedFilters from '../../Filters/SavedFilters';
import AddLead from '../../Lead/AddLead';
import { useDispatch, useSelector } from "react-redux";
import { createLead, getLeadListingByFilters } from '../../../modules/enquiry';
import LoadingBackdrop from "../../common/LoadingBackdrop.js";
import eventBus from '../../../core/eventBus.js';
import DeleteConfirmationDialog from '../../common/DeleteConfirmation/index.js';
import { genericBulkDelete } from '../../../modules/common.js';
import APIs from '../../../api/index.js';
import BulkEdit from '../../common/BulkEdit';
import { updateFieldsInBulk } from '../../../modules/admission.js';

const AdmissionLeadManagement = ({ setActiveModule }) => {
  const styles = useStyles();
  const navigate= useNavigate();
  const dispatch = useDispatch();
  const [leadList, setLeadList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isAddLeadOpen, setIsAddLeadOpen] = useState(false);
  const [openActions, setOpenActions] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const { doctype, filters } = useSelector((state) => state.filters);
  const { loading } = useSelector((state) => state.common);
  const [values, setValues] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    country: null,
    state: null,
    city: null,
    college: null,
    programGroups: null,
    program: null,
  });
  const [errorMessages, setErrorMessages] = useState({
    email: "",
    mobileNumber: "",
    name: "",
    country: "",
    state: "",
    city: "",
    college: "",
    programGroups: "",
    program: "",
  });

  const columns = [
    {
      dataIndex: "id",
      title: "Lead ID",
      width: "30%",
    },
    {
      dataIndex: "status",
      title: "Status",
      width: "15%",
      render: (val, row) => {
        const dynamicClassName = `status ${row.status}`;
        return (
          <>
            <span className={dynamicClassName}>{row.status}</span>
          </>
        );
      },
    },
    {
      dataIndex: "first_name",
      title: "Full Name",
      width: "15%",
    },
    {
      dataIndex: "custom_program_group",
      title: "Program Type",
      width: "15%",
    },
    {
      dataIndex: "custom_program",
      title: "Program",
      width: "15%",
    },
    {
      dataIndex: "name",
      title: "Action",
      width: "10%",
      render: (val, row) => {
        return (
          <p onClick={() => navigate(`/lead/${row?.id}/details`)} className={styles.link}>
            View
          </p>
        );
      },
    },
  ];

  const handleApplicationListing = async () => {
    let filtersToPass = [];
    if(doctype === "Lead") {
      filtersToPass = JSON.stringify(filters).slice(1, -1);
    }
    dispatch(setLoading({ loading: true }));
    const res = await getLeadListingByFilters(filtersToPass);
    if (res.status === 200) {
      let data = res?.data?.data;
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        let val = {
          ...data[i],
          id: data[i].name,
          key: i
        };
        arr.push(val);
      }
      setLeadList(arr);
      dispatch(setLoading({ loading: false }));
    } else {
      dispatch(setLoading({ loading: false }));
    }
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isPhoneNumberValid = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };

  const handleClearErrormessages = () => {
    setErrorMessages({
      email: "",
      mobileNumber: "",
      name: "",
      country: "",
      state: "",
      city: "",
      college: "",
      programGroups: "",
      program: "",
    });
  }

  const handleValidateForm = () => {
    const { name, email, mobileNumber, country, state, city, college, programGroups, program } = values;
    const errors = { name: "", email: "", mobileNumber: "", country: "", state: "", city: "", college: "", programGroups: "", program: "" };

    if (!name) errors.name = "Name is Required";
    if (!email)  errors.email = "Email is Required";
    if (email && !isEmailValid(email))  errors.email = "Please Enter Valid Email";
    if (!mobileNumber) errors.mobileNumber = "Mobile Number is Required";
    if (mobileNumber && !isPhoneNumberValid(mobileNumber)) errors.email = "Please Enter Valid Number";
    if (!country) errors.country = "Country is Required";
    if (!state) errors.state = "State is Required";
    if (!city) errors.city = "City is Required";
    if (!college) errors.college = "College is Required";
    if (!programGroups) errors.programGroups = "Program Type is Required";
    if (!program) errors.program = "Program is Required";
    setErrorMessages(errors);

   if(!name || !email || !isEmailValid(email) || !mobileNumber || !isPhoneNumberValid(mobileNumber) || !country || !state || !city || !college || !programGroups || !program ) return false;
   return true;
  }

  const handleAddLead = async () => {
    dispatch(setLoading({ loading: true }));
    const isValid = handleValidateForm();
    if(!isValid) {
      dispatch(setLoading({ loading: false }));
      return;
    }
    const { name, email, mobileNumber, country, state, city, campus, college, programGroups, program } = values;
    const payload = {
      first_name: name,
      email_id: email,
      mobile_no: mobileNumber,
      country,
      state,
      city,
      campus:campus,
      company: college,
      custom_program_group: programGroups,
      custom_program: program,
      status: "Open",
    };
    const res = await createLead({ payload });
    dispatch(setLoading({ loading: false }));
    if (res.status == 200) {
      toast.success("Lead Created!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValues({
        name: "",
        email: "",
        mobileNumber: "",
        country: null,
        state: null,
        city: null,
        campus: null,
        college: null,
        programGroups: null,
        program: null,
      });
      setIsAddLeadOpen(false);
      handleApplicationListing();
    } else {
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const onSelectChange = (selectedKeys, selectedRowData) => {
    setSelectedRowKeys(selectedKeys);
    let selectedDataIds = [];
    for(let i = 0 ; i < selectedRowData.length ; i++) {
      selectedDataIds.push(selectedRowData[i].id);
    }
    setSelectedIds([...selectedDataIds]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      id: record.id,
    }),
  };

  const handleConfirmBulkDelete = () => {
    setOpenActions(false);
    eventBus.emit("open-dialog", <DeleteConfirmationDialog items={selectedRowKeys.length} handleBulkDelete={handleBulkDelete} />)
  }

  const handleBulkDelete = async () => {
    dispatch(setLoading({ loading: true }));
    let deleteIds = [];
    for(let i = 0 ; i < selectedIds.length ; i++) {
      deleteIds.push({
        doctype: "Lead",
        id: selectedIds[i]
      })
    };

    const payload = { delete: [...deleteIds] };
    const res = await genericBulkDelete({ payload });
    if(res.status === 200) {
      handleApplicationListing();
      setSelectedIds([]);
      setSelectedRowKeys([]);
      toast.success(res.data.message);
    } else {
      toast.error(res.data.exc_type);
      dispatch(setLoading({ loading: false }));
    }
  } 

  const handleBulkExport = async () => {
    setOpenActions(false);
    dispatch(setLoading({ loading: true }));
    let exportIds = [];
    for(let i = 0 ; i < selectedIds.length ; i++) {
      exportIds.push({
        doctype: "Lead",
        id: selectedIds[i]
      })
    };

    const payload = { export : [...exportIds] };
    const response = await fetch(APIs.bulkActionExport, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      dispatch(setLoading({ loading: false }));
      setSelectedIds([]);
      setSelectedRowKeys([]);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      toast.success("Data exported!");
      link.setAttribute("download", "Lead-Data.xlsx");
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error("Something went wrong");
      dispatch(setLoading({ loading: false }));
    }
  };

  const handleBulkEdit = async ({ customfieldname, value }) => {
    const payload = {
      doctype: "Lead",
      docnames:	selectedIds,
      customfieldname,
      value
    };
    dispatch(setLoading({ loading: true }));
    setIsEditOpen(false)
    const res = await updateFieldsInBulk({ payload });
    if(res.status === 200) {
      handleApplicationListing();
      setSelectedIds([]);
      setSelectedRowKeys([]);
      toast.success(res.data.message);
    } else {
      toast.error(res.data.exc_type);
      dispatch(setLoading({ loading: false }));
    }
  }

  useEffect(() => {
    handleApplicationListing();
  }, [filters]);

  return (
   <>
    {
      loading ?
       <LoadingBackdrop open={loading} /> :
       <div className={styles.mainContainer}>
        <div className={styles.breadCrumbsContainer}>
          <p className={styles.breadCrumbsContent} onClick={() => navigate("/dashboard")}>IIHT {" > "}</p>
          <p className={styles.breadCrumbsContent} onClick={() => setActiveModule(0)}>Admission {" > "}</p>
          <p className={styles.breadCrumbsContent}>Lead Management</p>
        </div>
        <div className={styles.headerContainer}>
          <div className={styles.breadCrumbsContainer}>
            <GridMenuIcon className={styles.gridIcon}/>
            <p className={styles.title}>{isAddLeadOpen ? "Add Lead" : "Lead Management"}</p>
          </div>
          {
            !isAddLeadOpen ? (
              <div className={styles.buttonsGroup}>
                <CommonFilters doctype={"Lead"}></CommonFilters>
                <SavedFilters doctype={"Lead"}></SavedFilters>
                {
                  selectedRowKeys.length > 0 ? (
                  <Popover
                    content={<div className={styles.popoverContainer}>
                      <p className={styles.popoverContent} onClick={() => {
                        setOpenActions(false);
                        setIsEditOpen(true);
                      }}>Edit</p>
                      <p className={styles.popoverContent} onClick={handleBulkExport}>Export</p>
                      <p className={styles.popoverContent} onClick={handleConfirmBulkDelete}>Delete</p>
                    </div>}
                    trigger="click"
                    open={openActions}
                    placement="bottomRight"
                    arrow={false}
                    onOpenChange={() => setOpenActions(true)}
                  >
                    <div className={styles.activeButton}>
                      <p className={styles.activeButtonText}>Actions</p>
                    </div>
                  </Popover>
                  ) : (
                    <div className={styles.activeButton} onClick={() => setIsAddLeadOpen(true)}>
                      <p className={styles.activeButtonText}>+ Add Lead</p>
                    </div>
                  )
                }
              </div>
            ) : (
              <div className={styles.buttonsGroup}>
                <div className={styles.button} onClick={() => {
                  setIsAddLeadOpen(false);
                  handleClearErrormessages()
                }}>
                  <p className={styles.buttonText}>Back</p>
                </div>
                <div className={styles.activeButton} onClick={() => handleAddLead()}>
                  <p className={styles.activeButtonText}>Save</p>
                </div>
              </div>
            )
          }
        </div>
        {isAddLeadOpen ? (
          <AddLead values={values} setValues={setValues} errorMessages={errorMessages} />
        ) : (
          <Table 
            rowSelection={{ type: "checkbox", ...rowSelection }} 
            columns={columns} 
            dataSource={leadList} 
            size='small'
            pagination={true}
          />
        )}
      </div>
    }
    {
      isEditOpen && 
        <BulkEdit 
          handleBulkEdit={handleBulkEdit} 
          doctype="Lead" 
          isEditOpen={isEditOpen} 
          setIsEditOpen={setIsEditOpen} 
          items={selectedIds.length}
        />
    }
   </>
  )
}

export default AdmissionLeadManagement;
import React, { useEffect, useState } from 'react';
import useStyles from './style';
import { Select, Modal, Input } from "antd";
import { getBulkEditFieldList, getListingFromDoctype, updateFieldsInBulk } from '../../../modules/admission';

const SelectField = ({ selectedOption, value, setValue }) => {
  const styles = useStyles();
  const [options, setOptions] = useState([]);
  const handleGetOptions = async () => {
    const arr = selectedOption.options ? selectedOption.options.split("\n") : [];
    let dataOptions = [];
    for(let i = 0 ; i < arr.length ; i++){
      dataOptions.push({
        value: arr[i],
        label: arr[i]
      })
    }
    setOptions(dataOptions);
  };

  useEffect(() => {
    handleGetOptions();
  }, [selectedOption]);

  return (
    <Select 
      showSearch
      className={styles.selectInput} 
      placeholder="Select" 
      options={options}
      value={value}
      onChange={(value) => setValue(value)}
    />
  )
};

const LinkField = ({ selectedOption, value, setValue }) => {
  const styles = useStyles();
  const [options, setOptions] = useState([]);
  const handleGetOptions = async () => {
    const res = await getListingFromDoctype({ doctype: selectedOption.options });
    let arr = [];
    if(res.status === 200) {
      const optionsData = res.data.data;
      for(let i = 0 ; i < optionsData.length ; i++){
        arr.push({
          value: optionsData[i].name,
          label: optionsData[i].name
        })
      }
      setOptions(arr);
    }
  };

  useEffect(() => {
    handleGetOptions();
  }, [selectedOption]);

  return (
    <Select 
      showSearch
      className={styles.selectInput} 
      placeholder="Select" 
      options={options}
      value={value}
      onChange={(value) => setValue(value)}
    />
  )
};

const InputField = ({ value, setValue }) => {
  const styles = useStyles();

  return (
    <Input 
      value={value}
      className={styles.selectInput} 
      placeholder="Select" 
      type="text"
      onChange={(e) => setValue(e.target.value)}
    />
  )
};

const BulkEdit = ({ doctype, isEditOpen, setIsEditOpen, handleBulkEdit, items }) => {
  const styles = useStyles();
  const [value, setValue] = useState("");
  const [selectedOption, setSelectedOption] = useState({});
  const [fieldsData, setFieldsData] = useState([]);
  const [fieldOptions, setFieldOptions] = useState([]);

  const handleGetFieldsListing = async () => {
    try {
      const response = await getBulkEditFieldList({ doctype });
      if(response.status === 200) {
        setFieldsData(response.data.message);
        let arr = [];

        for(let i = 0 ; i < response.data.message.length ; i++) {
          arr.push({
            value: response.data.message[i].customfieldname,
            label: response.data.message[i].customfieldname
          });
        }

        setFieldOptions(arr);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectField = (value) => {
    const filteredValue = fieldsData.filter((data) => data.customfieldname === value);
    setValue("");
    setSelectedOption(filteredValue[0]);
  };

  const renderValue = () => {
    const fieldType = selectedOption.fieldtype;
    switch(fieldType) {
      case "Link": 
        return <LinkField selectedOption={selectedOption} value={value} setValue={setValue} />;
      case "Select":
        return <SelectField selectedOption={selectedOption} value={value} setValue={setValue} />;
      default:
        return  <InputField value={value} setValue={setValue} />
    }
  };

  useEffect(() => {
    handleGetFieldsListing();
  }, []);

  return (
    <Modal title="Bulk Edit" open={isEditOpen} okText={`Update ${items} ${items > 1 ? "records" : "record"}`}
      onOk={() => handleBulkEdit({customfieldname: selectedOption.customfieldname, value })} 
      onCancel={() => setIsEditOpen(false)}
      okButtonProps={{
        style: { 
          cursor: "pointer",
          borderRadius:" 4px",
          background: "#A32930",
          padding: "4px 14px",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0px 0px 2px 0px rgba(21, 40, 80, 0.12), 0px 2px 4px 0px rgba(21, 40, 80, 0.10)",
          color: "#FFF",
          fontSize: "14px",
          fontWeight: "400",
          marginTop: "12px"
        } 
      }}
      cancelButtonProps={{ 
        style: { 
          cursor: "pointer",
          borderRadius:" 4px",
          padding: "4px 14px",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0px 0px 2px 0px rgba(21, 40, 80, 0.12), 0px 2px 4px 0px rgba(21, 40, 80, 0.10)",
          color: "#405071",
          fontSize: "14px",
          fontWeight: "400",
          marginTop: "12px"
        } 
      }}
    >
      <div className={styles.contentContainer}>
        <div className={styles.field}>
          <p className={styles.fieldLabel}>Field *</p>
          <Select 
            showSearch
            className={styles.selectInput} 
            placeholder="Select" 
            options={fieldOptions}
            onChange={(value) => handleSelectField(value)}
    
          />
        </div>
        <div className={styles.field}>
          <p className={styles.fieldLabel}>Value</p>
          {renderValue()}
        </div>
      </div>
    </Modal>
  )
};

export default BulkEdit;
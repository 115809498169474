import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { Popover, Table } from "antd";
import { setLoading } from "slices/commonSlice";
import MainFooter from "components/common/MainFooter";
import MainHeader from "components/common/MainHeader";
import { setActiveSidebar } from "slices/financeSlice";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import CommonFilters from "components/Filters/CommonFilters";
import SavedFilters from "components/Filters/SavedFilters";
import eventBus from "core/eventBus.js";
import DeleteConfirmationDialog from "components/common/DeleteConfirmation/index.js";
import { genericBulkDelete } from "modules/common.js";
import { toast } from "react-toastify";
import { getAllQuestionsListing } from "modules/Examination";

const QuestionBankDashboard = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [questionBankList, setQuestionBankList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [openActions, setOpenActions] = useState(false);

  const columns = [
    {
      dataIndex: "sNo",
      title: "No.",
      width: "2.5%",
      render: (val, row, index) => {
        return <p className={styles.tableContentFields}>{index + 1}</p>;
      },
    },
    {
      dataIndex: "courseName",
      title: "Course Name",
      width: "12.5%",
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.courseName}</p>;
      },
    },
    {
      dataIndex: "questionBankName",
      title: "Question Bank Name",
      width: "20%",
      render: (val, row) => {
        return (
          <p className={styles.tableContentFields}>{row.questionBankName}</p>
        );
      },
    },
    {
      dataIndex: "version",
      title: "Version",
      width: "2.5%",
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.version}</p>;
      },
    },
    // {
    //   dataIndex: "createdBy",
    //   title: "Created By",
    //   width: "10%",
    //   render: (val, row) => {
    //     return <p className={styles.tableContentFields}>{row.createdBy}</p>;
    //   },
    // },
    {
      dataIndex: "totalQuestions",
      title: "Total Question",
      width: "15%",
      render: (val, row) => {
        return (
          <p className={styles.tableContentFields}>{row.totalQuestions}</p>
        );
      },
    },
    {
      dataIndex: "createdOn",
      title: "Created On",
      width: "15%",
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.createdOn}</p>;
      },
    },
    {
      dataIndex: "modifiedOn",
      title: "Modified On",
      width: "15%",
      render: (val, row) => {
        return <p className={styles.tableContentFields}>{row.modifiedOn}</p>;
      },
    },
    {
      dataIndex: "status",
      title: "Status",
      width: "10%",
      render: (val, row) => {
        return (
          <p
            className={
              row.status === "Pending"
                ? styles.buttonPublish
                : styles.buttonUnPublish
            }
          >
            {row.status}
          </p>
        );
      },
    },
    {
      dataIndex: "id",
      title: "ACTION",
      width: "5%",
      render: (val, row) => {
        return (
          <p
            className={`${styles.tableContentView} ${styles.link}`}
            onClick={() =>
              navigate(
                `/modules/examination/question-bank/${row.questionBankName}`
              )
            }
          >
            View
          </p>
        );
      },
    },
  ];

  const onSelectChange = (selectedKeys, selectedRowData) => {
    setSelectedRowKeys(selectedKeys);
    let selectedDataIds = [];
    for (let i = 0; i < selectedRowData.length; i++) {
      selectedDataIds.push(selectedRowData[i].name);
    }
    setSelectedIds([...selectedDataIds]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      id: record.id,
    }),
  };

  const handleConfirmBulkDelete = () => {
    setOpenActions(false);
    eventBus.emit(
      "open-dialog",
      <DeleteConfirmationDialog
        items={selectedRowKeys.length}
        handleBulkDelete={handleBulkDelete}
      />
    );
  };

  const handleBulkDelete = async () => {
    dispatch(setLoading({ loading: true }));
    let deleteIds = [];
    for (let i = 0; i < selectedIds.length; i++) {
      deleteIds.push({
        doctype: "Question Bank",
        id: selectedIds[i],
      });
    }

    const payload = { delete: [...deleteIds] };
    const res = await genericBulkDelete({ payload });
    if (res.status === 200) {
      setSelectedIds([]);
      setSelectedRowKeys([]);
      toast.success(res.data.message);
    } else {
      toast.error(res.data.exc_type);
      dispatch(setLoading({ loading: false }));
    }
  };

  const handleGetAllQuestionBankList = async () => {
    dispatch(setLoading({ loading: true }));
    const res = await getAllQuestionsListing();

    if (res.status === 200) {
      dispatch(setLoading({ loading: false }));

      const questionList = [];
      for (let i = 0; i < res.data.message.length; i++) {
        questionList.push({
          key: res.data.message[i].id,
          courseName: res.data.message[i].course_name,
          questionBankName: res.data.message[i].name,
          version: res.data.message[i].version,
          totalQuestions: res.data.message[i].total_no_of_questions,
          createdOn: res.data.message[i].creation.split(" ")[0],
          modifiedOn: res.data.message[i].modified.split(" ")[0],
          status: res.data.message[i].workflow_state,
        });
      }
      setQuestionBankList(questionList);
    } else {
      dispatch(setLoading({ loading: false }));
      console.log("Something went wrong while getting questionList");
    }
  };

  useEffect(() => {
    handleGetAllQuestionBankList();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.breadCrumbsContainer}>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/dashboard");
                dispatch(setActiveSidebar({ activeSidebar: 0 }));
              }}
            >
              IIHT {" > "}
            </p>
            <p
              className={styles.breadCrumbsContent}
              onClick={() => {
                navigate("/modules/examination");
                dispatch(setActiveSidebar({ activeSidebar: 0 }));
              }}
            >
              Examination {" > "}
            </p>
            <p className={styles.breadCrumbsContent}>Question Bank</p>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.breadCrumbsContainer}>
              <GridMenuIcon className={styles.gridIcon} />
              <p className={styles.title}>Question Bank</p>
            </div>
            <div className={styles.buttonsGroup}>
              <CommonFilters doctype={"Lead"}></CommonFilters>
              <SavedFilters doctype={"Lead"}></SavedFilters>
              {selectedRowKeys.length > 0 ? (
                <Popover
                  content={
                    <div className={styles.popoverContainer}>
                      <p
                        className={styles.popoverContent}
                        onClick={handleConfirmBulkDelete}
                      >
                        Delete
                      </p>
                    </div>
                  }
                  trigger="click"
                  open={openActions}
                  placement="bottomRight"
                  arrow={false}
                  onOpenChange={() => setOpenActions(true)}
                >
                  <div className={styles.activeButton}>
                    <p className={styles.activeButtonText}>Actions</p>
                  </div>
                </Popover>
              ) : (
                <div
                  className={styles.activeButton}
                  onClick={() =>
                    navigate("/modules/examination/add-question-bank")
                  }
                >
                  <p className={styles.activeButtonText}>+ Add Question Bank</p>
                </div>
              )}
            </div>
          </div>
          <Table
            rowSelection={{ type: "checkbox", ...rowSelection }}
            columns={columns}
            dataSource={questionBankList}
            size="small"
            pagination={true}
          />
        </div>
      )}
    </>
  );
};

export default QuestionBankDashboard;

import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import Radio from "@mui/material/Radio";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { styled } from "@mui/material/styles";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import UploadButton from "../../common/UploadButton";
import { PhotoProvider, PhotoView } from "react-photo-view";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Button } from "iiht-b2a-ui-components/build/components";
import { Country, State, City } from "country-state-city";
import {
  getGenderList,
  getGenderPronounList,
  getStudentCategoryListing,
  getDataOfApplicationFormByApplicationId,
} from "../../../modules/student";
import "./index.scss";
import debounce from "lodash/debounce"; // Import debounce function from lodash

const DEBOUNCE_DELAY = 300; // Adjust the debounce delay as needed
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const FormPersonalDetails = ({
  handleNext,
  academicYear,
  programGroup,
  program,
  handleBack,
}) => {
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 16);
  maxDate.setMonth(maxDate.getMonth() + 6);
  const user = useSelector((state) => state.user);
  const [flag, setFlag] = useState(false);
  const [genderList, setGenderList] = useState([]);
  const [genderPronounList, setGenderPronounList] = useState([]);
  const [studentCategoryList, setStudentCategoryList] = useState([]);
  const [countries, setCountries] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    pGender: "",
    dateOfBirth: "",
    nationality: "",
    category: "",
    studentFileUpload: "",
    attachGoverentIdUpload: "",
    bloodGroup: "",
    userEmail: "",
    email: "",
    alternateEmail: "",
    phoneNumber: "",
    alternatePhoneNumber: "",
    telNumber: "",
    emergenyContactName: "",
    relationWithEmergencyName: "",
    emergenyContactNumber: "",
    country: "",
    state: "",
    city: "",
    address1: "",
    address2: "",
    pincode: "",
    isPermanentSameAsCorrespondence: "",
    permanentCountry: "",
    permanentState: "",
    permanentCity: "",
    permanentAddressLine1: "",
    permanentAddressLine2: "",
    permanentPincode: "",
    isPermanentSameAsCorrespondenceChecked: false,
    aadharcardNumber: "",
    abcId: "",
  });

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    pGender: "",
    dateOfBirth: "",
    nationality: "",
    category: "",
    studentFileUpload: "",
    attachGoverentIdUpload: "",
    bloodGroup: "",
    email: "",
    alternateEmail: "",
    phoneNumber: "",
    alternatePhoneNumber: "",
    telNumber: "",
    emergenyContactName: "",
    relationWithEmergencyName: "",
    emergenyContactNumber: "",
    country: "",
    state: "",
    city: "",
    address1: "",
    address2: "",
    pincode: "",
    isPermanentSameAsCorrespondence: "",
    permanentCountry: "",
    permanentState: "",
    permanentCity: "",
    permanentAddressLine1: "",
    permanentAddressLine2: "",
    permanentPincode: "",
    isPermanentSameAsCorrespondenceChecked: "",
    aadharcardNumber: "",
    abcId: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...formErrors };

    Object.keys(formData).forEach((key) => {
      if (
        // formData["isPermanentSameAsCorrespondenceChecked"] == false ||
        formData[key] === "" &&
        key !== "alternatePhoneNumber" &&
        key !== "telNumber" &&
        key !== "middleName" &&
        key !== "lastName" &&
        key !== "pGender" &&
        key !== "alternateEmail" &&
        key !== "address2" &&
        key !== "permanentAddressLine2" &&
        key !== "userEmail" &&
        key !== "abcId"
      ) {
        newErrors[key] = "Required";
        valid = false;
      } else {
        newErrors[key] = "";
      }
    });

    if (formData.isPermanentSameAsCorrespondenceChecked == false) {
      newErrors.isPermanentSameAsCorrespondenceChecked = "Required";
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.email && !emailRegex.test(formData.email)) {
      newErrors.email = "Invalid email address";
      valid = false;
    } else if (formData.email) {
      newErrors.email = "";
    }

    if (formData.alternateEmail && !emailRegex.test(formData.alternateEmail)) {
      newErrors.alternateEmail = "Invalid email address";
      valid = false;
    } else {
      newErrors.alternateEmail = "";
    }

    // Phone number validation
    const phoneRegex = /^([0-9]{1,5})?([7-9][0-9]{9})$/;
    if (formData.phoneNumber && !phoneRegex.test(formData.phoneNumber)) {
      newErrors.phoneNumber = "Invalid phone number";
      valid = false;
    } else if (formData.phoneNumber) {
      newErrors.phoneNumber = "";
    }

    // Alternate phone number validation
    if (
      formData.alternatePhoneNumber &&
      !phoneRegex.test(formData.alternatePhoneNumber)
    ) {
      newErrors.alternatePhoneNumber = "Invalid phone number";
      valid = false;
    } else if (formData.alternatePhoneNumber) {
      newErrors.alternatePhoneNumber = "";
    }
    const nameRegex = /^[a-zA-Z]+$/;
    if (formData.firstName && !nameRegex.test(formData.firstName)) {
      newErrors.firstName = "Invalid First name";
      valid = false;
    } else if (formData.firstName) {
      newErrors.firstName = "";
    }

    if (formData.middleName && !nameRegex.test(formData.middleName)) {
      newErrors.middleName = "Invalid Middle name";
      valid = false;
    } else {
      newErrors.middleName = "";
    }

    if (formData.lastName && !nameRegex.test(formData.lastName)) {
      newErrors.lastName = "Invalid Last name";
      valid = false;
    } else {
      newErrors.lastName = "";
    }

    // Aadhaar card number validation
    const aadharRegex = /^\d{12}$/;
    if (
      formData.aadharcardNumber &&
      !aadharRegex.test(formData.aadharcardNumber)
    ) {
      newErrors.aadharcardNumber = "Invalid Aadhaar card number";
      valid = false;
    } else if (formData.aadharcardNumber) {
      newErrors.aadharcardNumber = "";
    }
   
   
    // Pincode validation for India
    const validatePincode = (pincode) => {
      // Regular expression for Indian pincode (exactly 6 digits)
      const pincodeRegex = /^\d{6}$/;
      // Check if the provided pincode matches the Indian pincode format
      return pincodeRegex.test(pincode);
    };


    // Validate pincode if provided and applicable
    if (formData.country === "India" && formData.pincode) {
      if (!validatePincode(formData.pincode)) {
        newErrors.pincode = "Invalid! Please enter 6 digit pincode for India";
        valid = false;
      } else {
        newErrors.pincode = "";
      }
    }

    setFormErrors(newErrors);
    return valid;
  };

  const handleSave = () => {
    const isValid = validateForm();
    if (!user || !user.email) {
      toast.error("Error while submitting application");
      return;
    }
    if (isValid) {
      const countryObject = JSON.parse(formData.country);
      const stateObject = JSON.parse(formData.state);

      const countryName = countryObject.name;
      const stateName = stateObject.name;
      console.log(countryName, stateName, "country state");
      console.log(
        formData.stateName,
        "formData.state formData.state formData.state ___________________________"
      );

      const payload = {
        application_date: "2024-01-24",
        application_status: "Applied",
        first_name: formData.firstName,
        middle_name: formData.middleName,
        last_name: formData.lastName,
        gender: formData.gender,
        custom_preferred_gender_pronouns: formData.pGender,
        date_of_birth: formData.dateOfBirth,
        nationality: formData.nationality,
        student_category: formData.category,
        blood_group: formData.bloodGroup,
        student_email_id: user.email,
        custom_primary_email_address: formData.email
          ? formData.email.trim()
          : "",
        custom_alternate_email_address: formData.alternateEmail
          ? formData.alternateEmail.trim()
          : "",
        student_mobile_number: `+${formData.phoneNumber}`,
        custom_alternate_mobile: formData.alternatePhoneNumber
          ? `+${formData.alternatePhoneNumber}`
          : "",
        custom_telephone_number_residence: formData.telNumber,
        program: program,
        custom_program_group: programGroup,
        academic_year: academicYear,
        country: formData.country,
        state: formData.state,
        city: formData.city,
        custom_is_correspondence_address_same_as_permanent_address:
          formData.isPermanentSameAsCorrespondence == "yes" ? 1 : 0,
        address_line_1: formData.address1,
        address_line_2: formData.address2,
        pincode: formData.pincode,
        custom_country_correspondence: countryName,
        custom_state_correspondence: stateName,
        custom_city_correspondence: formData.city,
        custom_address_line_1_correspondence:
          formData.custom_address_line_1_correspondence,
        custom_address_line_2_correspondence:
          formData.custom_address_line_2_correspondence,
        custom_pincode_correspondence: formData.custom_pincode_correspondence,
        custom_emergency_contact_name_: formData.emergenyContactName,
        custom_emergency_contact_number_: `+${formData.emergenyContactNumber}`,
        custom_relation_with_emergency_contact_:
          formData.relationWithEmergencyName,
        custom_upload_student_image: formData.studentFileUpload,
        custom_attach_aadhar_card: formData.attachGoverentIdUpload,
        custom_aadhar_card_number: formData.aadharcardNumber,
        custom_abc_id: formData.abcId,
      };
      if (window.localStorage.getItem("application-name")) {
        handleNext({ payload, addData: false });
      } else {
        handleNext({ payload, addData: true });
      }
    }
  };

  const handleGetGenderListing = async () => {
    const res = await getGenderList();
    if (res.status === 200) {
      setGenderList(res.data?.data);
    }
  };

  const handleGetGenderPronounsListing = async () => {
    const res = await getGenderPronounList();
    if (res.status === 200) {
      setGenderPronounList(res.data?.data);
    }
  };

  const handleGetStudentCategoryListing = async () => {
    const res = await getStudentCategoryListing();
    if (res.status === 200) {
      setStudentCategoryList(res.data?.data);
    }
  };

  const handleGetDataOfApplicationFormByApplicationId = async () => {
    if (window.localStorage.getItem("application-name")) {
      const applicationId = JSON.parse(
        window.localStorage.getItem("application-name")
      );
      const res = await getDataOfApplicationFormByApplicationId({
        applicationId,
      });
      if (res.status === 200) {
        const data = res.data.data;
        console.log(
          data,
          data.custom_country_correspondence,
          "Data returned____"
        );
        setFormData({
          firstName: data.first_name,
          middleName: data.middle_name,
          lastName: data.last_name,
          gender: data.gender,
          pGender: data.custom_preferred_gender_pronouns,
          dateOfBirth: data.date_of_birth,
          nationality: data.nationality,
          category: data.student_category,
          studentFileUpload: data.custom_upload_student_image,
          attachGoverentIdUpload: data.custom_attach_aadhar_card,
          bloodGroup: data.blood_group,
          email: data.custom_primary_email_address,
          alternateEmail: data.custom_alternate_email_address,
          phoneNumber: data.student_mobile_number
            ? data.student_mobile_number.slice(1)
            : "",
          alternatePhoneNumber: data.custom_alternate_mobile
            ? data.custom_alternate_mobile.slice(1)
            : "",
          telNumber: data.custom_telephone_number_residence,
          emergenyContactName: data.custom_emergency_contact_name_,
          relationWithEmergencyName:
            data.custom_relation_with_emergency_contact_,
          emergenyContactNumber: data.custom_emergency_contact_number_
            ? data.custom_emergency_contact_number_.slice(1)
            : "",
          country: data.country,
          state: data.state,
          city: data.custom_city_correspondence,
          address1: data.custom_address_line_1_correspondence,
          address2: data.custom_address_line_2_correspondence,
          pincode: data.custom_pincode_correspondence,
          isPermanentSameAsCorrespondence:
            data.custom_is_correspondence_address_same_as_permanent_address == 1
              ? "yes"
              : "no",
          permanentCountry: data.country,
          permanentState: data.state,
          permanentCity: data.city,
          permanentAddressLine1: data.address_line_1,
          permanentAddressLine2: data.address_line_2,
          permanentPincode: data.pincode,
          isPermanentSameAsCorrespondenceChecked: true,
          aadharcardNumber: data.custom_aadhar_card_number
            ? `********${data.custom_aadhar_card_number.substr(-4)}`
            : "",
          abcId: data.custom_abc_id,
        });
      }
    }
  };

  useEffect(() => {
    handleGetGenderListing();
    handleGetGenderPronounsListing();
    handleGetStudentCategoryListing();
    handleGetDataOfApplicationFormByApplicationId();
  }, []);

  // Fetch all countries on component mount
  useEffect(() => {
    const fetchCountries = async () => {
      const allCountries = Country.getAllCountries();
      setCountries(allCountries);
      console.log(allCountries, "Countries");
    };
    fetchCountries();
  }, []);

  // Fetch states of the selected country
  useEffect(() => {
    if (selectedCountry) {
      console.log(selectedCountry.isoCode, "isoCode");
      const statesOfCountry = State.getStatesOfCountry(selectedCountry.isoCode);
      console.log(statesOfCountry, "States of country");
      setStates(statesOfCountry);
    }
  }, [selectedCountry]); // Update states when selectedCountry changes

  // Fetch cities of the selected state
  useEffect(() => {
    if (selectedState) {
      const fetchCities = async () => {
        const citiesOfState = await City.getCitiesOfState(
          selectedState.countryCode,
          selectedState.isoCode
        );
        console.log(citiesOfState, "Cities of state");
        setCities(citiesOfState);
      };

      fetchCities();
    }
  }, [selectedState]); // Update cities when selectedState changes

  // Handle country select
  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setSelectedState(null); // Reset selected state when country changes
  };

  // Handle state select
  const handleStateSelect = (state) => {
    setSelectedState(state);
    console.log(state, "Selected State");
  };

  return (
    <>
      <Box sx={{ boxShadow: "none" }} className="formPersonalDetails">
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Item className="mainFormBody">
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={12}>
                    <Item>
                      <h3>Personal Details & Verification</h3>
                    </Item>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">First Name*</Typography>
                    <input
                      type="text"
                      required
                      name="firstName"
                      placeholder="Enter your first name"
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                    <div className="error">{formErrors.firstName}</div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Middle Name</Typography>
                    <input
                      type="text"
                      required
                      name="middleName"
                      placeholder="Enter your middle name"
                      value={formData.middleName}
                      onChange={handleChange}
                    />
                    <div className="error">{formErrors.middleName}</div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Last Name</Typography>
                    <input
                      type="text"
                      required
                      name="lastName"
                      placeholder="Enter your last name"
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                    <div className="error">{formErrors.lastName}</div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">select Gender*</Typography>
                    <select
                      label="Gender"
                      id="gender"
                      placeholder="select gender"
                      value={formData.gender}
                      onChange={handleChange}
                      name="gender"
                    >
                      <option value="" disabled selected>
                        Select Gender
                      </option>
                      {genderList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    <div className="error">{formErrors.gender}</div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Preferred Gender Pronoun(S)
                    </Typography>
                    <select
                      label="PGender"
                      id="pGender"
                      placeholder="ex he/him"
                      value={formData.pGender}
                      onChange={handleChange}
                      name="pGender"
                    >
                      <option value="" disabled selected>
                        Select Pronoun
                      </option>
                      {genderPronounList.map((item) => {
                        return (
                          <option key={item.name} value={item.name}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    <div className="error">{formErrors.pGender}</div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Enter DOB *</Typography>
                    <input
                      type="date"
                      required
                      name="dateOfBirth"
                      placeholder="Date of Birth (DD-MM-YYYY)"
                      value={formData.dateOfBirth}
                      onChange={handleChange}
                      max={maxDate.toISOString().split("T")[0]}
                    />
                    <div className="error">{formErrors.dateOfBirth}</div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Nationality *</Typography>
                    <input
                      type="text"
                      required
                      name="nationality"
                      placeholder="Nationality"
                      value={formData.nationality}
                      onChange={handleChange}
                    />
                    <div className="error">{formErrors.nationality}</div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Category *</Typography>
                    <select
                      label="Category"
                      id="category"
                      placeholder="select category"
                      value={formData.category}
                      onChange={handleChange}
                      name="category"
                    >
                      <option value="" disabled selected>
                        Select Category
                      </option>
                      {studentCategoryList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    <div className="error">{formErrors.category}</div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Blood Group *</Typography>
                    <select
                      label="Blood Group"
                      id="bloodGroup"
                      placeholder="select your blood group"
                      value={formData.bloodGroup}
                      onChange={handleChange}
                      name="bloodGroup"
                    >
                      <option value="" disabled selected>
                        Select Blood Group
                      </option>
                      <option value="A+">A+</option>
                      <option value="A-">A-</option>
                      <option value="B+">B+</option>
                      <option value="B-">B-</option>
                      <option value="O+">O+</option>
                      <option value="O-">O-</option>
                      <option value="AB+">AB+</option>
                      <option value="AB-">AB-</option>
                    </select>
                    <div className="error">{formErrors.bloodGroup}</div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Aadhaar Card Number *
                    </Typography>
                    <input
                      type="text"
                      required
                      name="aadharcardNumber"
                      placeholder="Enter Aadhaar card number"
                      value={formData.aadharcardNumber}
                      onChange={handleChange}
                    />
                    <div className="error">{formErrors.aadharcardNumber}</div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Attach Aadhaar Card *
                    </Typography>
                    <UploadButton
                      formData={formData}
                      setFormData={setFormData}
                      fieldName="attachGoverentIdUpload"
                      flag={flag}
                      setFlag={setFlag}
                      fileTypes={["JPG", "PNG", "JPEG", "PDF"]}
                    />
                    <div className="error">
                      {formErrors.attachGoverentIdUpload}
                    </div>
                    {formData.attachGoverentIdUpload ? (
                      formData.attachGoverentIdUpload.split(".")[1] ===
                      "pdf" ? (
                        <p
                          style={{
                            color: "black",
                            textDecoration: "none",
                            cursor: "pointer",
                            fontSize: "12px",
                          }}
                          onClick={() =>
                            window.open(
                              `https://erp-dev.techademycampus.com${formData.attachGoverentIdUpload}`,
                              "_blank"
                            )
                          }
                        >
                          {formData.attachGoverentIdUpload.split("/")[2]}
                        </p>
                      ) : (
                        <PhotoProvider>
                          <div className="foo">
                            <PhotoView
                              src={`https://erp-dev.techademycampus.com${formData.attachGoverentIdUpload}`}
                            >
                              <img
                                src={`https://erp-dev.techademycampus.com${formData.attachGoverentIdUpload}`}
                                alt=""
                                style={{
                                  width: "150px",
                                  height: "100px",
                                  marginTop: "12px",
                                  cursor: "pointer",
                                }}
                              />
                            </PhotoView>
                          </div>
                        </PhotoProvider>
                      )
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Upload Student Photo *
                    </Typography>
                    <UploadButton
                      formData={formData}
                      setFormData={setFormData}
                      fieldName="studentFileUpload"
                      flag={flag}
                      setFlag={setFlag}
                      fileTypes={["JPG", "PNG", "JPEG"]}
                    />
                    {formErrors.studentFileUpload && (
                      <div className="error">
                        {formErrors.studentFileUpload}
                      </div>
                    )}
                    {formData.studentFileUpload ? (
                      formData.studentFileUpload.split(".")[1] === "pdf" ? (
                        <p
                          style={{
                            color: "black",
                            textDecoration: "none",
                            cursor: "pointer",
                            fontSize: "12px",
                          }}
                          onClick={() =>
                            window.open(
                              `https://erp-dev.techademycampus.com${formData.studentFileUpload}`,
                              "_blank"
                            )
                          }
                        >
                          {formData.studentFileUpload.split("/")[2]}
                        </p>
                      ) : (
                        <PhotoProvider>
                          <div className="foo">
                            <PhotoView
                              src={`https://erp-dev.techademycampus.com${formData.studentFileUpload}`}
                            >
                              <img
                                src={`https://erp-dev.techademycampus.com${formData.studentFileUpload}`}
                                alt=""
                                style={{
                                  width: "150px",
                                  height: "100px",
                                  marginTop: "12px",
                                  cursor: "pointer",
                                }}
                              />
                            </PhotoView>
                          </div>
                        </PhotoProvider>
                      )
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">ABC ID</Typography>
                    <input
                      type="text"
                      required
                      name="abcId"
                      placeholder="Enter ABC Id"
                      value={formData.abcId}
                      onChange={handleChange}
                    />
                    <div className="error">{formErrors.abcId}</div>
                  </Grid>
                </Grid>
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Item className="mainFormBody">
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={12}>
                    <Item>
                      <h3>Contact Information</h3>
                    </Item>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Email Address *</Typography>
                    <input
                      type="text"
                      required
                      name="email"
                      placeholder="Enter your email ID here"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <div className="error">{formErrors.email}</div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Alternate Email Address
                    </Typography>
                    <input
                      type="text"
                      required
                      name="alternateEmail"
                      placeholder="Enter your alternative email ID here"
                      value={formData.alternateEmail}
                      onChange={handleChange}
                    />
                    <div className="error">{formErrors.alternateEmail}</div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Phone Number *</Typography>
                    <PhoneInput
                      country={"in"}
                      onChange={(phone) =>
                        setFormData({ ...formData, phoneNumber: phone })
                      }
                      value={formData.phoneNumber}
                      inputStyle={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        width: "100%",
                      }}
                    />
                    <div className="error">{formErrors.phoneNumber}</div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Alternate Phone Number
                    </Typography>
                    <PhoneInput
                      country={"in"}
                      onChange={(phone) =>
                        setFormData({
                          ...formData,
                          alternatePhoneNumber: phone,
                        })
                      }
                      value={formData.alternatePhoneNumber}
                      inputStyle={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        width: "100%",
                      }}
                    />
                    <div className="error">
                      {formErrors.alternatePhoneNumber}
                    </div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Telephone Number</Typography>
                    <input
                      type="text"
                      required
                      name="telNumber"
                      placeholder="Enter your landline number"
                      value={formData.telNumber}
                      onChange={handleChange}
                    />
                    <div className="error">{formErrors.telNumber}</div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}></Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Emergency Contact Name *
                    </Typography>
                    <input
                      type="text"
                      required
                      name="emergenyContactName"
                      placeholder="Enter your emergency Contact person name"
                      value={formData.emergenyContactName}
                      onChange={handleChange}
                    />
                    <div className="error">
                      {formErrors.emergenyContactName}
                    </div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Relation with Emergency Contact *
                    </Typography>
                    <select
                      label="Select Relation"
                      id="relationWithEmergencyName"
                      placeholder="select your blood group"
                      value={formData.relationWithEmergencyName}
                      onChange={handleChange}
                      name="relationWithEmergencyName"
                    >
                      <option value="" disabled selected>
                        Select Relation
                      </option>
                      <option value="Father">Father</option>
                      <option value="Mother">Mother</option>
                      <option value="Brother">Brother</option>
                      <option value="Sister">Sister</option>
                      <option value="Others">Others</option>
                    </select>
                    <div className="error">
                      {formErrors.relationWithEmergencyName}
                    </div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">
                      Emergency Contact Number *
                    </Typography>
                    <PhoneInput
                      country={"in"}
                      onChange={(phone) =>
                        setFormData({
                          ...formData,
                          emergenyContactNumber: phone,
                        })
                      }
                      value={formData.emergenyContactNumber}
                      inputStyle={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        width: "100%",
                      }}
                    />
                    <div className="error">
                      {formErrors.emergenyContactNumber}
                    </div>
                  </Grid>
                </Grid>
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Item className="mainFormBody">
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={12}>
                    <Item>
                      <h3>
                        Correspondence Address (communication may be sent to
                        this address for the duration of the admission cycle)
                      </h3>
                    </Item>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Country *</Typography>
                    <select
                      label="Select Country"
                      id="country"
                      placeholder="select your country"
                      name="country"
                      required
                      value={formData.country}
                      onChange={(e) => {
                        const selectedCountry = countries.find(
                          (country) => country.name === e.target.value
                        );
                        setFormData({ ...formData, country: e.target.value });
                        handleCountrySelect(selectedCountry);
                        if (e.target.value) {
                          setFormErrors({ ...formErrors, country: "" });
                        }
                      }}
                    >
                      <option value="" disabled>
                        Select Country
                      </option>
                      {countries.map((country) => (
                        <option key={country.isoCode} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </select>

                    <div className="error">{formErrors.country}</div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">State *</Typography>
                    <select
                      label="Select State"
                      id="state"
                      placeholder="select your state"
                      name="state"
                      required
                      value={formData.state}
                      onChange={(e) => {
                        setFormData({ ...formData, state: e.target.value });
                        handleStateSelect(JSON.parse(e.target.value));
                        if (e.target.value) {
                          setFormErrors({ ...formErrors, state: "" });
                        }
                      }}
                    >
                      <option value="">Select State</option>
                      {states.map((state) => (
                        <option
                          key={state.id}
                          value={JSON.stringify(state)}
                          selected={state.name == formData.state}
                        >
                          {state.name}
                        </option>
                      ))}
                    </select>
                    <div className="error">{formErrors.state}</div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">City *</Typography>

                    <select
                      label="Select City"
                      id="city"
                      placeholder="select your city"
                      value={formData.city}
                      onChange={(e) => {
                        setFormData({ ...formData, city: e.target.value });

                        if (e.target.value) {
                          setFormErrors({ ...formErrors, city: "" });
                        }
                      }}
                      name="city"
                      required
                    >
                      <option value="" disabled selected={formData.city == ""}>
                        Select City
                      </option>
                      {cities.map((city) => (
                        <option
                          key={city.id}
                          value={city.name}
                          selected={city.name == formData.city}
                        >
                          {city.name}
                        </option>
                      ))}
                    </select>
                    <div className="error">{formErrors.city}</div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Address line 1 *</Typography>
                    <input
                      type="text"
                      required
                      name="address1"
                      placeholder="Enter address"
                      value={formData.address1}
                      onChange={handleChange}
                    />
                    <div className="error">{formErrors.address1}</div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Address line 2</Typography>
                    <input
                      type="text"
                      required
                      name="address2"
                      placeholder="Enter address"
                      value={formData.address2}
                      onChange={handleChange}
                    />
                    <div className="error">{formErrors.address2}</div>
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Pincode *</Typography>
                    <input
                      type="text"
                      required
                      name="pincode"
                      placeholder="Enter pincode"
                      value={formData.pincode}
                      onChange={handleChange}
                    />
                    <div className="error">{formErrors.pincode}</div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="body1">
                      Is your Permanent Address the same as your Correspondence
                      Address? *
                    </Typography>
                    <RadioGroup
                      row
                      aria-labelledby="radio-buttons-group"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                        checked={
                          formData.isPermanentSameAsCorrespondence == "yes"
                        }
                        onClick={() => {
                          setFormData({
                            ...formData,
                            isPermanentSameAsCorrespondence: "yes",
                            permanentCountry: formData.country,
                            permanentState: formData.state,
                            permanentCity: formData.city,
                            permanentAddressLine1: formData.address1,
                            permanentAddressLine2: formData.address2,
                            permanentPincode: formData.pincode,
                            isPermanentSameAsCorrespondenceChecked: true,
                          });
                          setFormErrors({
                            ...formErrors,
                            permanentCountry: formErrors.country,
                            permanentState: formErrors.state,
                            permanentCity: formErrors.city,
                            permanentAddressLine1: formErrors.address1,
                            permanentAddressLine2: formErrors.address2,
                            permanentPincode: formErrors.pincode,
                            isPermanentSameAsCorrespondenceChecked: "",
                          });
                        }}
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                        checked={
                          formData.isPermanentSameAsCorrespondence == "no"
                        }
                        onClick={() => {
                          setFormData({
                            ...formData,
                            isPermanentSameAsCorrespondence: "no",
                            permanentCountry: "",
                            permanentState: "",
                            permanentCity: "",
                            permanentAddressLine1: "",
                            permanentAddressLine2: "",
                            permanentPincode: "",
                            isPermanentSameAsCorrespondenceChecked: true,
                          });
                          setFormErrors({
                            isPermanentSameAsCorrespondenceChecked: "",
                          });
                        }}
                      />
                    </RadioGroup>
                    <div className="error">
                      {formErrors.isPermanentSameAsCorrespondenceChecked}
                    </div>
                  </Grid>
                </Grid>
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Item className="mainFormBody">
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={12}>
                    <Item>
                      <h4>Permanent Address</h4>
                    </Item>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Country *</Typography>
                    <select
                      label="Select Country"
                      id="permanentCountry"
                      placeholder="Select your country"
                      value={formData.permanentCountry}
                      onChange={handleChange}
                      name="permanentCountry"
                      required
                    >
                      <option
                        value=""
                        disabled
                        selected={formData.country == ""}
                      >
                        Select Country
                      </option>
                      {countries.map((country) => (
                        <option key={country.isoCode} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </select>

                    <div className="error">{formErrors.permanentCountry}</div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">State *</Typography>
                    <select
                      label="Select State"
                      id="permanentState"
                      placeholder="Select your state"
                      value={formData.permanentState}
                      onChange={handleChange}
                      name="permanentState"
                      required
                    >
                      <option value="" disabled selected={formData.state == ""}>
                        Select State
                      </option>
                      {states.map((state) => (
                        <option
                          key={state.name}
                          value={JSON.stringify(state)}
                          selected={state.name == formData.state}
                        >
                          {state.name}
                        </option>
                      ))}
                    </select>
                    <div className="error">{formErrors.state}</div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">City *</Typography>
                    <select
                      label="Select City"
                      id="permanentCity"
                      placeholder="select your city"
                      value={formData.permanentCity}
                      onChange={handleChange}
                      name="permanentCity"
                      required
                    >
                      <option value="" disabled selected={formData.city == ""}>
                        Select City
                      </option>
                      {cities.map((city) => (
                        <option
                          key={city.name}
                          value={city.name}
                          selected={city.name == formData.city}
                        >
                          {city.name}
                        </option>
                      ))}
                    </select>
                    <div className="error">{formErrors.city}</div>
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Address line 1 *</Typography>
                    <input
                      type="text"
                      required
                      name="permanentAddressLine1"
                      placeholder="Enter address"
                      value={formData.permanentAddressLine1}
                      onChange={handleChange}
                    />
                    <div className="error">
                      {formErrors.permanentAddressLine1}
                    </div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Address line 2</Typography>
                    <input
                      type="text"
                      required
                      name="permanentAddressLine2"
                      placeholder="Enter address"
                      value={formData.permanentAddressLine2}
                      onChange={handleChange}
                    />
                    <div className="error">
                      {formErrors.permanentAddressLine2}
                    </div>
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1">Pincode *</Typography>
                    <input
                      type="text"
                      required
                      name="permanentPincode"
                      placeholder="Enter pincode"
                      value={formData.permanentPincode}
                      onChange={handleChange}
                    />
                    <div className="error">{formErrors.permanentPincode}</div>
                  </Grid>
                </Grid>
              </Item>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          pt: 2,
          justifyContent: "space-between",
        }}
        className="navBottomBtns"
      >
        <div style={{ display: "flex", gap: "12px" }}>
          <Button
            color="inherit"
            sx={{ mr: 1 }}
            className="backBtn"
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            onClick={() => {
              handleSave();
            }}
            sx={{ mr: 1 }}
          >
            Save
          </Button>
        </div>
        <Button sx={{ mr: 1 }} onClick={() => handleSave()}>
          Next
        </Button>
      </Box>
    </>
  );
};

export default FormPersonalDetails;

import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import "./index.scss";
import UploadButton from "../common/UploadButton";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { RemoveIconCircle } from "../../icons";
import { Button } from "iiht-b2a-ui-components/build/components";
import { getDataOfApplicationFormByApplicationId } from "../../modules/student";
import { PhotoProvider, PhotoView } from "react-photo-view";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function FormSurvey({ handleNext, handleBack }) {
  const [flag, setFlag] = useState(false);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [values, setValues] = useState({
    custom_how_did_you_hear_about_our_university: "",
    custom_have_you_applied_our_university_before: null,
    custom_not_applicable: false,
    custom_inquiry: false,
    custom_disciplinary_action_due_to_substance_abuse: false,
    custom_arrestedcharged: false,
    custom_attachment_info: "",
    custom_provide_info: "",
    custom_known_language: [],
  });

  const [languages, setLanguages] = React.useState([]);
  const [errorMessages, setErrorMessages] = useState({});

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  const handleLanguageChange = (index, field, value) => {
    const updatedLanguages = [...languages];
    updatedLanguages[index][field] = value;

    setLanguages(updatedLanguages);

    setValues((prevValues) => ({
      ...prevValues,
      custom_known_language: updatedLanguages.map((language) => ({
        language: language.language,
        read: language.read || false,
        write: language.write || false,
        speak: language.speak || false,
        language_proficiency: language.language_proficiency || "",
      })),
    }));
  };

  const handleAddLanguage = () => {
    const newLanguage = {
      doctype: "Language proficiency",
      language: "", // Initialize with default value or an empty string
      language_proficiency: "", // Initialize with default value or an empty string
      parentfield: "custom_known_language",
      parenttype: "Student Applicant",
      idx: languages.length,
    };

    setValues((prevValues) => ({
      ...prevValues,
      custom_known_language: [...prevValues.custom_known_language, newLanguage],
    }));

    setLanguages([
      ...languages,
      {
        id: languages.length + 1,
        language: "",
        read: false,
        write: false,
        speak: false,
        language_proficiency: "",
      },
    ]);
  };

  const handleRemoveLanguage = () => {
    const updatedLanguages = [...languages];
    updatedLanguages.pop(); // Remove the last entered language from the languages array

    setValues((prevValues) => ({
      ...prevValues,
      custom_known_language: prevValues.custom_known_language.slice(0, -1), // Remove the last entered language from custom_known_language
    }));

    setLanguages(updatedLanguages);
  };

  const handleDisciplinaryChange = (name) => {
    setValues((prevValues) => {
      if (name === "not_applicable") {
        // If "Not Applicable" is selected, disable other checkboxes
        return {
          ...prevValues,
          custom_not_applicable: 1,
          custom_inquiry: 0,
          custom_disciplinary_action_due_to_substance_abuse: 0,
          custom_arrestedcharged: 0,
        };
      } else if (name === "inquiry") {
        // For other checkboxes, toggle their values
        return {
          ...prevValues,
          custom_not_applicable: 0,
          custom_inquiry: 1,
          custom_disciplinary_action_due_to_substance_abuse: 0,
          custom_arrestedcharged: 0,
        };
      } else if (name === "disciplinary_action_due_to_substance_abuse") {
        // For other checkboxes, toggle their values
        return {
          ...prevValues,
          custom_not_applicable: 0,
          custom_inquiry: 0,
          custom_disciplinary_action_due_to_substance_abuse: 1,
          custom_arrestedcharged: 0,
        };
      } else {
        // For other checkboxes, toggle their values
        return {
          ...prevValues,
          custom_not_applicable: 0,
          custom_inquiry: 0,
          custom_disciplinary_action_due_to_substance_abuse: 0,
          custom_arrestedcharged: 1,
        };
      }
    });
  };

  const handleRadioChange = (field, value) => {
    setValues((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSave = () => {
    // Create a formData object with specific fields
    const formData = {
      custom_how_did_you_hear_about_our_university:
        values.custom_how_did_you_hear_about_our_university,
      custom_have_you_applied_our_university_before:
        values.custom_have_you_applied_our_university_before,
      custom_not_applicable: values.custom_not_applicable,
      custom_inquiry: values.custom_inquiry,
      custom_attachment_info: values.custom_attachment_info,
      custom_disciplinary_action_due_to_substance_abuse:
        values.custom_disciplinary_action_due_to_substance_abuse,
      custom_arrestedcharged: values.custom_arrestedcharged,
      custom_provide_info: values.custom_provide_info,
      custom_known_language: values.custom_known_language.map((language) => ({
        language: language.language,
        read: language.read,
        write: language.write,
        speak: language.speak,
        language_proficiency: language.language_proficiency,
      })),
    };
    const newErrorMessages = {};

    for (const key in formData) {
      if (!formData[key]) {
        newErrorMessages[key] = "Required";
      }
    }
    if (languages.length === 0) {
      newErrorMessages.languages = "Add at least one language";
    } else {
      values.custom_known_language.forEach((language, index) => {
        if (!language.language || !language.language_proficiency) {
          newErrorMessages[`languageName${index}`] = "Required";
          newErrorMessages[`language_proficiency${index}`] = "Required";
        }
      });
    }

    setErrorMessages(newErrorMessages);

    handleNext({ payload: formData, addData: false });
  };

  const handleGetDataOfApplicationFormByApplicationId = async () => {
    if (window.localStorage.getItem("application-name")) {
      const applicationId = JSON.parse(
        window.localStorage.getItem("application-name")
      );
      const res = await getDataOfApplicationFormByApplicationId({
        applicationId,
      });
      if (res.status == 200) {
        const data = res.data.data;
        setValues({
          ...values,
          custom_how_did_you_hear_about_our_university:
            data.custom_how_did_you_hear_about_our_university
              ? data.custom_how_did_you_hear_about_our_university
              : "",
          custom_have_you_applied_our_university_before:
            data.custom_have_you_applied_our_university_before != 0 ||
            data.custom_have_you_applied_our_university_before != 1
              ? data.custom_have_you_applied_our_university_before == 0
                ? false
                : true
              : null,
          custom_not_applicable: data.custom_not_applicable
            ? data.custom_not_applicable == 0
              ? false
              : true
            : "",
          custom_inquiry: data.custom_inquiry
            ? data.custom_inquiry == 0
              ? false
              : true
            : "",
          custom_disciplinary_action_due_to_substance_abuse:
            data.custom_disciplinary_action_due_to_substance_abuse
              ? data.custom_disciplinary_action_due_to_substance_abuse == 0
                ? false
                : true
              : "",
          custom_arrestedcharged: data.custom_arrestedcharged
            ? data.custom_arrestedcharged == 0
              ? false
              : true
            : "",
          custom_attachment_info: data.custom_attachment_info
            ? data.custom_attachment_info
            : "",
          custom_provide_info: data.custom_provide_info
            ? data.custom_provide_info
            : "",
        });

        if (data.custom_known_language) {
          const languageArr = data.custom_known_language;
          const arr = [];
          const saveDataArr = [];
          for (let i = 0; i < languageArr.length; i++) {
            const { language, language_proficiency, read, write, speak } =
              languageArr[i];
            arr.push({
              id: i + 1,
              language,
              language_proficiency,
            });
            saveDataArr.push({
              language: language,
              language_proficiency: language_proficiency,
              read,
              write,
              speak,
            });
          }

          values.custom_known_language = saveDataArr;
          setLanguages(arr);
        }
      }
    }
  };

  useEffect(() => {
    handleGetDataOfApplicationFormByApplicationId();
  }, []);

  return (
    <>
      <Box sx={{ boxShadow: "none" }} className="formPersonalDetails">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item className="mainFormBody">
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={12}>
                  <Item>
                    <h4>Other Relevant Information</h4>
                  </Item>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">
                    How did you hear about IIHT University? *
                  </Typography>
                  <select
                    label="Gender"
                    id="gender"
                    placeholder="Select"
                    style={{
                      color: "#536882",
                      fontSize: "12px",
                      fontWeight: "400",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #CDD6E2",
                      background: "#fff",
                      outline: "none",
                      width: "100%",
                    }}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        custom_how_did_you_hear_about_our_university:
                          e.target.value,
                      });
                      setErrorMessages({
                        ...errorMessages,
                        custom_how_did_you_hear_about_our_university: "",
                      });
                    }}
                  >
                    <option
                      disabled={true}
                      selected={
                        values.custom_how_did_you_hear_about_our_university ===
                        ""
                      }
                    >
                      Source
                    </option>
                    <option
                      value="Google"
                      selected={
                        values.custom_how_did_you_hear_about_our_university ==
                        "Google"
                      }
                    >
                      Google
                    </option>
                    <option
                      value="Friends"
                      selected={
                        values.custom_how_did_you_hear_about_our_university ==
                        "Friends"
                      }
                    >
                      Friends
                    </option>
                    <option
                      value="Others"
                      selected={
                        values.custom_how_did_you_hear_about_our_university ==
                        "Others"
                      }
                    >
                      Others
                    </option>
                  </select>
                  {errorMessages.custom_how_did_you_hear_about_our_university && (
                    <span className="error">
                      {
                        errorMessages.custom_how_did_you_hear_about_our_university
                      }
                    </span>
                  )}
                </Grid>
                <Grid item xs={2} sm={8} md={8}>
                  &nbsp;
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">
                    Have you applied to IIHT University before? *
                  </Typography>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={
                      values.custom_have_you_applied_our_university_before ===
                      null
                        ? ""
                        : values.custom_have_you_applied_our_university_before ===
                          1
                        ? "yes"
                        : "no"
                    }
                    onChange={(e) => {
                      handleRadioChange(
                        "custom_have_you_applied_our_university_before",
                        e.target.value === "yes" ? 1 : 0
                      );
                      setErrorMessages({
                        ...errorMessages,
                        custom_have_you_applied_our_university_before: null,
                      });
                    }}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                      checked={
                        values.custom_have_you_applied_our_university_before ==
                          1 ||
                        values.custom_have_you_applied_our_university_before ==
                          "true"
                      }
                      onClick={() => {
                        handleRadioChange(
                          "custom_have_you_applied_our_university_before",
                          1
                        );
                        setErrorMessages({
                          ...errorMessages,
                          custom_have_you_applied_our_university_before: null,
                        });
                      }}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                      checked={
                        values.custom_have_you_applied_our_university_before ==
                          0 ||
                        values.custom_have_you_applied_our_university_before ==
                          "false"
                      }
                      onClick={() => {
                        handleRadioChange(
                          "custom_have_you_applied_our_university_before",
                          0
                        );
                        setErrorMessages({
                          ...errorMessages,
                          custom_have_you_applied_our_university_before: null,
                        });
                      }}
                    />
                  </RadioGroup>
                  {errorMessages.custom_have_you_applied_our_university_before && (
                    <span className="error">
                      {
                        errorMessages.custom_have_you_applied_our_university_before
                      }
                    </span>
                  )}
                </Grid>
                <Grid item xs={2} sm={8} md={8}>
                  &nbsp;
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="body1">
                    Have you ever been implicated in any one or more of the
                    following? Please note that the information you provide will
                    be kept confidential and not shared publicly. Selective
                    information shared by students who are enrolled at IIHT
                    University might be shared with specific Departments and
                    Offices within IIHT University on a case-by-case basis for
                    internal official purposes.
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.custom_not_applicable}
                          onChange={() =>
                            handleDisciplinaryChange("not_applicable")
                          }
                        />
                      }
                      label="Not Applicable"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.custom_inquiry}
                          onChange={() => handleDisciplinaryChange("inquiry")}
                        />
                      }
                      label="Disciplinary inquiry leading to suspension or expulsion from any academic programme"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            values.custom_disciplinary_action_due_to_substance_abuse
                          }
                          onChange={() =>
                            handleDisciplinaryChange(
                              "disciplinary_action_due_to_substance_abuse"
                            )
                          }
                        />
                      }
                      label="Disciplinary Status due to substance abuse"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.custom_arrestedcharged}
                          onChange={() =>
                            handleDisciplinaryChange("arrestedcharged")
                          }
                        />
                      }
                      label="Arrested/Charged or Convicted by a court of law for offenses in India/abroad"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="body1">
                    Please provide the relevant details in no more than 100
                    words. Please attach relevant documents (if any)
                  </Typography>
                  <TextareaAutosize
                    aria-label="empty textarea"
                    placeholder="Type here ..."
                    style={{ width: "100%", minHeight: "100px" }}
                    value={values.custom_provide_info}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        custom_provide_info: e.target.value,
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={2} sm={4} md={4}>
                  <Typography variant="body1">Attachment</Typography>
                  <UploadButton
                    formData={values}
                    setFormData={setValues}
                    fieldName="custom_attachment_info"
                    flag={flag}
                    setFlag={setFlag}
                    fileTypes={["JPG", "PNG", "JPEG", "PDF"]} 
                  />
                  {values.custom_attachment_info ? (
                    values.custom_attachment_info.split(".")[1] === "pdf" ? (
                      <p
                        style={{
                          color: "black",
                          textDecoration: "none",
                          cursor: "pointer",
                          fontSize: "12px",
                        }}
                        onClick={() =>
                          window.open(
                            `https://erp-dev.techademycampus.com${values.custom_attachment_info}`,
                            "_blank"
                          )
                        }
                      >
                        {values.custom_attachment_info.split("/")[2]}
                      </p>
                    ) : (
                      <PhotoProvider>
                        <div className="foo">
                          <PhotoView
                            src={`https://erp-dev.techademycampus.com${values.custom_attachment_info}`}
                          >
                            <img
                              src={`https://erp-dev.techademycampus.com${values.custom_attachment_info}`}
                              alt=""
                              style={{
                                width: "150px",
                                height: "100px",
                                marginTop: "12px",
                                cursor: "pointer",
                              }}
                            />
                          </PhotoView>
                        </div>
                      </PhotoProvider>
                    )
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={2} sm={8} md={8}>
                  &nbsp;
                </Grid>
              </Grid>
            </Item>
          </Grid>

          <Grid item xs={12}>
            <Item className="mainFormBody">
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={12}>
                  <Item>
                    <h4>Known Languages</h4>
                  </Item>
                </Grid>
                <Grid item xs={12} className="siblingContainer">
                  <Item className="mainFormBody">
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                      className="siblingItemWrap"
                    >
                      <Grid item xs={1} sm={3} md={3}>
                        <Typography variant="body1">Language *</Typography>
                      </Grid>
                      {/* <Grid item xs={2} sm={2} md={2}>
                      <Typography variant="body1">Read</Typography>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                      <Typography variant="body1">Write</Typography>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                      <Typography variant="body1">Speak</Typography>
                    </Grid> */}
                      <Grid item xs={2} sm={3} md={3}>
                        <Typography variant="body1">
                          Proficiency Level *
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      {errorMessages.languages && (
                        <span className="error">
                          {errorMessages.languages}
                        </span>
                      )}
                      {languages.map((language, index) => (
                        <Item key={language.id} className="mainFormBody">
                          <Grid
                            container
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                            className="siblingItemWrap"
                          >
                            <Grid item xs={1} sm={3} md={3}>
                              <input
                                style={{
                                  height: "40px",
                                  width: "100%",
                                  padding: "12px",
                                  borderRadius: "4px",
                                  border: "1px solid #CDD6E2",
                                  backgroundColor: "#FFF",
                                }}
                                required
                                name={`languageName${language.id}`}
                                placeholder="Add Language Name"
                                value={language.language}
                                onChange={(e) =>
                                  handleLanguageChange(
                                    index,
                                    "language",
                                    e.target.value
                                  )
                                }
                              />
                              {errorMessages[`languageName${index}`] && (
                                <span
                                  className="error"
                                >
                                  {errorMessages[`languageName${index}`]}
                                </span>
                              )}
                            </Grid>
                            {/* <Grid item xs={2} sm={2} md={2}>
                  <Checkbox
                    checked={language.read}
                    onChange={(e) => handleLanguageChange(index, 'proficiency', e.target.checked)}
                  />
                </Grid>
                <Grid item xs={2} sm={2} md={2}>
                  <Checkbox
                    checked={language.write}
                    onChange={(e) => handleLanguageChange(index, 'write', e.target.checked)}
                  />
                </Grid>
                <Grid item xs={2} sm={2} md={2}>
                  <Checkbox
                    checked={language.speak}
                    onChange={(e) => handleLanguageChange(index, 'speak', e.target.checked)}
                  />
                </Grid> */}
                            <Grid item xs={2} sm={3} md={3}>
                              <select
                                style={{
                                  color: "#536882",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  height:"40px",
                                  padding: "0 10px",
                                  borderRadius: "5px",
                                  border: "1px solid #CDD6E2",
                                  background: "#fff",
                                  outline: "none",
                                  width: "100%",
                                }}
                                label="Proficiency"
                                id={`language_proficiency${language.id}`}
                                placeholder="Select"
                                onChange={(e) =>
                                  handleLanguageChange(
                                    index,
                                    "language_proficiency",
                                    e.target.value
                                  )
                                }
                              >
                                <option
                                  disabled={true}
                                  selected={language.language_proficiency == ""}
                                >
                                  Proficiency
                                </option>
                                <option
                                  value="Medium"
                                  selected={
                                    language.language_proficiency == "Medium"
                                  }
                                >
                                  Medium
                                </option>
                                <option
                                  value="Conversational"
                                  selected={
                                    language.language_proficiency ==
                                    "Conversational"
                                  }
                                >
                                  Conversational
                                </option>
                                <option
                                  value="Expert"
                                  selected={
                                    language.language_proficiency == "Expert"
                                  }
                                >
                                  Expert
                                </option>
                              </select>
                              {errorMessages[
                                `language_proficiency${index}`
                              ] && (
                                <span
                                  className="error"
                                >
                                  {
                                    errorMessages[
                                      `language_proficiency${index}`
                                    ]
                                  }
                                </span>
                              )}
                            </Grid>
                          </Grid>
                        </Item>
                      ))}
                      <div className="dynamicFieldbutton">
                        <IconButton
                          onClick={handleRemoveLanguage}
                          style={{ color: "white", borderRadius: "4px" }}
                        >
                          <RemoveIconCircle />
                        </IconButton>
                        <IconButton onClick={handleAddLanguage}>
                          <AddIcon
                            style={{
                              color: "white",
                              background: "darkred",
                              borderRadius: "4px",
                            }}
                          />
                        </IconButton>
                      </div>
                    </Grid>
                  </Item>
                </Grid>
              </Grid>
            </Item>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          pt: 2,
          justifyContent: "space-between",
        }}
        className="navBottomBtns"
      >
        <div style={{ display: "flex", gap: "12px" }}>
          <Button
            color="inherit"
            sx={{ mr: 1 }}
            className="backBtn"
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            onClick={() => {
              handleSave();
            }}
            sx={{ mr: 1 }}
          >
            Save
          </Button>
        </div>
        <Button sx={{ mr: 1 }} onClick={() => handleSave()}>
          Next
        </Button>
      </Box>
    </>
  );
}

import { Button } from 'iiht-b2a-ui-components/build/components';
import React from 'react';
import eventBus from '../../../core/eventBus';
import { toast } from 'react-toastify';
import { updateDataToApplicationForm } from '../../../modules/student';
import { useDispatch, useSelector } from 'react-redux';
import { setToggle } from '../../../slices/userSlice';

const AcceptOfferLetterDialog = ({ uniqueId  }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const handleUpdateDataToApplicationForm = async ({ state }) => {
    const payload = {
        workflow_state: state
    }
    const res = await updateDataToApplicationForm({ payload, name: uniqueId });
    if(res.status == 200) {
      if(state === "Rejected") {
        toast.warn("Offer Letter Rejected!");
      } else if(state === "Offer Accepted") {
        toast.success("Offer Letter Accepted!");
      }
      dispatch(setToggle({ toggle: !user.toggle }))
    } else {
      let error = res.data?.exception;
      error = error.split(":")[1];
      toast.error(error);
    } 
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "18px", alignItems: "center", padding: "18px 22px", width: "420px" }}>
        <p style={{ color: "#405071", fontSize: "22px", fontWeight: "600" }}>CONFIRMATION!</p>
        <div style={{ display: "flex", flexDirection: "column"}}>
            <span style={{ color: "#405071", fontSize: "14px", fontWeight: "500" }}>
                I have thoroughly reviewed the terms outlined in the offer letter, including the responsibilities of the mentioned teams, and I am prepared to accept the offer.
            </span>
        </div>
        <div style={{ display: "inline-flex"}}>
        <Button
            variant="contained"
            style={{
              marginRight: "8px",
              backgroundColor: "white",
              color: "gray",
              cursor: "pointer"
            }}
            onClick={() => {
                handleUpdateDataToApplicationForm({ state: "Rejected" })
                eventBus.emit("close-dialog");
            }}
          >
            Reject
          </Button>
          <Button variant="contained" style={{ cursor: "pointer" }} 
            onClick={() => {
                handleUpdateDataToApplicationForm({ state: "Offer Accepted" })
                eventBus.emit("close-dialog");
            }}>
            Accept
          </Button>
        </div>
    </div>
  )
}

export default AcceptOfferLetterDialog;
import React, { useState, useEffect } from "react";
import MainFooter from "../../components/common/MainFooter";
import HiringImg from "../../../src/assets/images/Career Hiring.png";
import { ReactSVG } from "react-svg";
import useStyles from "./style";
import Logo from "../../assets/images/TechademyLogo.svg";
import { JobOpeningCards } from "../../modules/hr";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import JobCard from "./JobCard";

const Career = () => {
  const [jobsList, setJobsList] = useState([]);
  const styles = useStyles();

  const handleGetJobs = async () => {
    const payload={
      doctype:"Job Opening",
      status:"Open"
    }
    try {
      const res = await JobOpeningCards(payload);
      if (res.status === 200) {
        setJobsList(res.data.message);
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };
  console.log(jobsList,"000000000000000")

  useEffect(() => {
    handleGetJobs();
  }, []);

  return (
    <>
      <div className={styles.logoContainer}>
        <ReactSVG src={Logo} />
        <div className={styles.labelsContainer}>
          {/* Clickable labels */}
          <span className={styles.label}>Career</span>
          <span className={styles.label}>Contact Us</span>
        </div>
      </div>
      <div className={styles.mainContainer}>
        <img src={HiringImg} className={styles.mainImage} alt="Main" />
        <Typography
          variant="h1"
          align="center"
          className={styles.boldHeading}
          gutterBottom
        >
          Current Openings
        </Typography>
        <Grid container spacing={3} className={styles.cardContainer}>
          {jobsList.map((job, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Link to={`/jobOpening/id=${job.id}`} style={{ textDecoration: 'none' }}>
                <JobCard
                  jobTitle={job.job_title}
                  designation={job.designation}
                  postedOn={job.posted_on}
                  company={job.company}
                  jobId={job.id}
                  applicationCount={job.no_of_applications_received}
                  closesOn={job.closes_on}
                  location={job.location}
                  postedon={job.posted_on}
                />
              </Link>
            </Grid>
          ))}
        </Grid>
      </div>
      <MainFooter />
    </>
  );
};

export default Career;

import { createSlice } from "@reduxjs/toolkit";

const financeSlice = createSlice({
  name: "finance",
  initialState: {
    activeSidebar: 0
  },
  reducers: {
    setActiveSidebar: (state, action) => {
      state.activeSidebar =  action.payload.activeSidebar;
    }
  },
});

export const { setActiveSidebar } = financeSlice.actions;

export default financeSlice.reducer;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FormDashboard from "./FormDashboard";
import FormInstructions from "./FormInstructions";
import FormPersonalDetails from "./personal-details/FormPersonalDetails";
import FormParentDetails from "./FormParentDetails";
import FormAcademics from "./FormAcademics";
import FormSurvey from "./FormSurvey";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { razorpayCheckout } from "../../modules/razorpayCheckOut";
import { Step, Stepper } from "react-form-stepper";

import StepIcon1 from "../../assets/images/icon1.svg";
import StepIcon2 from "../../assets/images/icon2.svg";
import StepIcon3 from "../../assets/images/icon3.svg";
import StepIcon4 from "../../assets/images/icon4.svg";
import StepIcon5 from "../../assets/images/icon5.svg";
import StepIcon11 from "../../assets/images/icon11.svg";
import StepIcon12 from "../../assets/images/icon12.svg";
import StepIcon13 from "../../assets/images/icon13.svg";
import StepIcon14 from "../../assets/images/icon14.svg";
import FinishTick from "../../assets/images/finishTick.svg";
import BackArrowSvg from "../../assets/images/backArrow.svg";
import NextArrowSvg from "../../assets/images/nextArrow.svg";
import FormCoursePref from "./FormCoursePref";
import FormSupport from "./FormSupport";
import FormDeclaration from "./FormDeclaration";
import Loader from "../../assets/images/loader-img.gif";
import {
  addDataToApplicationForm,
  updateDataToApplicationForm,
} from "../../modules/student";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";

const steps = [
  {
    label: "Admission Policies",
    icon: (
      <img src={StepIcon1} alt="StepIcon1" style={{ height: 26, width: 22 }} />
    ),
  },
  {
    label: "General Instructions",
    icon: (
      <img src={StepIcon2} alt="StepIcon1" style={{ height: 26, width: 22 }} />
    ),
  },
  {
    label: "Personal and Contact Details",
    icon: (
      <img src={StepIcon3} alt="StepIcon1" style={{ height: 26, width: 23 }} />
    ),
  },
  {
    label: "Parent's / Guardian's & Sibling's Details",
    icon: (
      <img src={StepIcon4} alt="StepIcon1" style={{ height: 26, width: 24 }} />
    ),
  },
  {
    label: "Academic Details",
    icon: (
      <img src={StepIcon5} alt="StepIcon1" style={{ height: 29, width: 24 }} />
    ),
  },
  {
    label: "Survey",
    icon: (
      <img src={StepIcon11} alt="StepIcon1" style={{ height: 26, width: 26 }} />
    ),
  },
  {
    label: "Preferences",
    icon: (
      <img src={StepIcon12} alt="StepIcon1" style={{ height: 27, width: 23 }} />
    ),
  },
  {
    label: "Support at IIHT",
    icon: (
      <img src={StepIcon13} alt="StepIcon1" style={{ height: 27, width: 23 }} />
    ),
  },
  {
    label: "Declaration",
    icon: (
      <img src={StepIcon14} alt="StepIcon1" style={{ height: 27, width: 23 }} />
    ),
  },
];

export default function Navigation() {
  const location = useLocation();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [openDialog, setOpenDialog] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [validSteps, setValidSteps] = React.useState([0]);

  const [finishClicked, setFinishClicked] = React.useState(false);
  const navigate = useNavigate();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = async ({ payload, addData }) => {
    if (addData && payload) {
      const res = await addDataToApplicationForm({ payload });
      if (res.status === 200) {
        window.localStorage.setItem(
          "application-name",
          JSON.stringify(res.data?.data?.name)
        );
        if (isLastStep()) {
          handleOpenPayment();
        } else {
          const newActiveStep =
            activeStep === totalSteps() - 1
              ? steps.findIndex((step, i) => !(i in completed))
              : activeStep + 1;
          setActiveStep(newActiveStep);
          setValidSteps([...validSteps, newActiveStep]);
        }
        window.scrollTo(0, 0);
      } else {
        let error = res.data?.exception;
        error = error.split(":")[1];
        toast.error(error);
      }
    } else if (payload && Object.keys(payload).length > 0) {
      const name = JSON.parse(window.localStorage.getItem("application-name"));
      const res = await updateDataToApplicationForm({ payload, name });
      if (res.status === 200) {
        if (isLastStep()) {
          handleOpenPayment();
        } else {
          const newActiveStep =
            activeStep === totalSteps() - 1
              ? steps.findIndex((step, i) => !(i in completed))
              : activeStep + 1;
          setActiveStep(newActiveStep);
          setValidSteps([...validSteps, newActiveStep]);
        }
        window.scrollTo(0, 0);
      } else {
        let error = res.data?.exception;
        error = error.split(":")[1];
        toast.error(error);
      }
    } else {
      if (isLastStep()) {
        handleOpenPayment();
      } else {
        const newActiveStep =
          activeStep === totalSteps() - 1
            ? steps.findIndex((step, i) => !(i in completed))
            : activeStep + 1;
        setValidSteps([...validSteps, newActiveStep]);
        setActiveStep(newActiveStep);
      }
      window.scrollTo(0, 0);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate("/student/dashboard");
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
    window.scrollTo(0, 0);
  };

  const handleStep = (step) => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setFinishClicked(false);
  };

  const handleOpenPayment = () => {
    setOpenDialog(false);
    const studentApplicantId = JSON.parse(
      window.localStorage.getItem("application-name")
    );
    razorpayCheckout({ studentApplicantId, applicationFee: 1 }).then((resp) => {
      if (resp.message === "Success") {
        window.localStorage.removeItem("application-name");
        navigate("/student/dashboard");
      }
    });
  };

  const StepContent = [
    () => (
      <div>
        <FormDashboard handleNext={handleNext} handleBack={handleBack} />
      </div>
    ),
    () => (
      <div>
        <FormInstructions handleNext={handleNext} handleBack={handleBack} />
      </div>
    ),
    () => (
      <div>
        <FormPersonalDetails
          handleNext={handleNext}
          academicYear={location.state?.academicYear}
          programGroup={location.state?.programGroup}
          program={location.state?.program}
          handleBack={handleBack}
        />
      </div>
    ),
    () => (
      <div>
        <FormParentDetails handleNext={handleNext} handleBack={handleBack} />
      </div>
    ),
    () => (
      <div>
        <FormAcademics handleNext={handleNext} handleBack={handleBack} />
      </div>
    ),
    () => (
      <div>
        <FormSurvey handleNext={handleNext} handleBack={handleBack} />
      </div>
    ),
    () => (
      <div>
        <FormCoursePref handleNext={handleNext} handleBack={handleBack} />
      </div>
    ),
    () => (
      <div>
        <FormSupport handleNext={handleNext} handleBack={handleBack} />
      </div>
    ),
    () => (
      <div>
        <FormDeclaration
          handleNext={handleNext}
          academicYear={location.state?.academicYear}
          handleBack={handleBack}
          isFeePaid={location.state.isFeePaid}
        />
      </div>
    ),
  ];

  const handleNextStep = () => {
    if (activeStep < totalSteps()) {
      if (validSteps.includes(activeStep + 1)) {
        setActiveStep(activeStep + 1);
      }
    }
  };

  const handlePrevStep = () => {
    if (activeStep > 0) {
      if (validSteps.includes(activeStep)) {
        setActiveStep(activeStep - 1);
      }
    }
  };

  const renderSteps = () => {
    const last4Steps = activeStep + 4 >= steps.length;
    console.log("activeStep: ", activeStep, validSteps);
    return last4Steps
      ? steps.slice(steps.length - 4, steps.length).map((step, index) => {
          console.log("index: ", index);
          return (
            <div
              className="step-item-container"
              onClick={() => {
                if (validSteps.includes(activeStep)) {
                  handleStep(activeStep);
                }
              }}
            >
              <div
                key={step}
                className={`step-icon ${
                  validSteps.includes(activeStep) ? "success" : "current"
                }`}
              >
                {step.icon}
              </div>
              {step.label}
            </div>
          );
        })
      : steps.slice(activeStep, activeStep + 4).map((step, index) => (
          <div
            className="step-item-container"
            onClick={() => {
              if (validSteps.includes(activeStep + index)) {
                handleStep(activeStep + index);
              }
            }}
          >
            <div
              key={step}
              className={`step-icon ${
                validSteps.includes(activeStep + index) ? "success" : "current"
              }`}
            >
              {step.icon}
            </div>
            {step.label}
          </div>
        ));
  };

  useEffect(() => {
    if (location.state?.applicationNumber) {
      window.localStorage.setItem(
        "application-name",
        JSON.stringify(location.state.applicationNumber)
      );
      handleActiveNavigationSteps();
    }
  }, []);

  const handleActiveNavigationSteps = () => {
    const percentFilled = location.state.percentFilled;
    if (percentFilled == "15.0") {
      setValidSteps([0, 1, 2, 3]);
    } else if (percentFilled == "30.0") {
      setValidSteps([0, 1, 2, 3, 4]);
    } else if (percentFilled == "45.0") {
      setValidSteps([0, 1, 2, 3, 4, 5]);
    } else if (percentFilled == "60.0") {
      setValidSteps([0, 1, 2, 3, 4, 5, 6]);
    } else if (percentFilled == "75.0") {
      setValidSteps([0, 1, 2, 3, 4, 5, 6, 7]);
    } else if (percentFilled == "90.0") {
      setValidSteps([0, 1, 2, 3, 4, 5, 6, 7, 8]);
    } else if (percentFilled == "100.0") {
      setValidSteps([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%" }}>
        <div className="arrow-container">
          <ReactSVG
            src={BackArrowSvg}
            className="arrows-icon"
            onClick={() => {
              if (activeStep > 0) {
                handlePrevStep();
              }
            }}
          />
          <div className="steps-container">{renderSteps()}</div>
          <ReactSVG
            src={NextArrowSvg}
            className="arrows-icon"
            onClick={() => {
              if (activeStep !== totalSteps()) {
                handleNextStep();
              }
            }}
          />
        </div>
      </div>
      <div style={{ marginTop: "32px" }}>
        {allStepsCompleted() ? (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </>
        ) : (
          <>{StepContent[activeStep]()}</>
        )}
      </div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        className="completedPopup"
      >
        <DialogTitle className="popupHeader">
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseDialog}
            sx={{ position: "absolute", top: 0, right: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="popupContent">
          <Typography>
            <img
              src={FinishTick}
              alt="Complete"
              style={{ height: 96, width: 96 }}
            />

            <h2>Submission Confirmed!</h2>
          </Typography>
        </DialogContent>
        <DialogActions className="popupAction">
          {isLoading ? (
            <Loader /> // Show the loader when isLoading is true
          ) : (
            <Button onClick={handleOpenPayment}>Make Payment</Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

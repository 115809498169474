import GeneralIcon from "../../assets/images/icon2.svg";
import JobIcon from "../../assets/images/icon5.svg";
import PersonalIcon from "../../assets/images/icon4.svg";
import EducationIcon from "../../assets/images/icon3.svg";
import ExperienceIcon from "../../assets/images/icon15.svg";
import ResearchIcon from "../../assets/images/icon7.svg";
import PaperIcon from "../../assets/images/icon17.svg";
import DeclarationIcon from "../../assets/images/icon14.svg"

export const formData = [
    {
        name: "General Instructions",
        icon: GeneralIcon,
        fields: [
            {
              fields: [
                {
                  type: "component",
                  componentToRender: "general",
                },
              ],
            },
          ],
    },
    {
        name: "Job Details",
        icon: JobIcon,
        fields: [
            {
              label: "Job Details",
              name: "generalDetails",
              type: "section",
              fields: [
                {
                  label: "Type *",
                  name: "name",
                  type: "text",
                  placeholder: "",
                },
                {
                  label: "Position *",
                  name: "assignedto",
                  type: "text",
                  placeholder: "",
                },
                {
                    label: "Program",
                    name: "custom_program_group",
                    type: "text",
                    placeholder: "",
                },
                {
                  type: "col-break",
                },
                {
                  label: "Specialization",
                  name: "program",
                  type: "text",
                  placeholder: "",
                },
                {
                  label: "Interest Area's *",
                  name: "college",
                  type: "text",
                  placeholder: "",
                },
                {
                    label: "Campus Preference *",
                    name: "custom_preference_1_",
                    type: "text",
                    placeholder: "",
                },
                {
                    type: "col-break",
                },
                {
                    type: "col-break",
                },
                {
                    label:"Next",
                    name:"Next",
                    type:"button",
                }
              ],
            },
            {
                name: "custom_xii_subject",
                fields: [
                  {
                    label: "Specialization",
                    type: "label1",
                    name: "custom_xii_subject",
                  },
                  {
                    type:"col-break",
                  }, 
                  {
                    headers: [
                      {
                        key: "No.",
                        value: "",
                      },
                      {
                        key: "Type",
                        value: "subject",
                        valueText: "subject_text"
                      },
                      {
                        key: "Position",
                        value: "marks",
                      },
                      {
                        key: "Program",
                        value: "program",
                      },
                      {
                        key: "Specialization",
                        value: "",
                      },
                      {
                        key: "Interest's Area",
                        value: "",
                      },
                      {
                        key: "Campus Preference",
                        value: "",
                      },
                    ],
                    type: "table",
                    name: "subjectMarks",
                    minRows: 5, // Minimum number of rows
                    name: "custom_xii_subject",
                  },
                ]
            },
          ],
    },
    {
        name: "Personal & Contact Details",
        icon: PersonalIcon,
        fields: [
            {
              label: "Personal Details",
              name: "generalDetails",
              type: "section",
              fields: [
                {
                  label: "First Name *",
                  name: "name",
                  type: "text",
                  placeholder: "",
                },
                {
                  label: "Last Name *",
                  name: "assignedto",
                  type: "text",
                  placeholder: "",
                },
                {
                    label: "Date Of Birth",
                    name: "custom_program_group",
                    type: "text",
                    placeholder: "",
                },
                {
                  type: "col-break",
                },
                {
                  label: "Gender *",
                  name: "program",
                  type: "select",
                  placeholder: "",
                },
                {
                  label: "Category *",
                  name: "college",
                  type: "select",
                  placeholder: "",
                },
                {
                    label: "Region *",
                    name: "custom_preference_1_",
                    type: "select",
                    placeholder: "",
                },
                {
                  type: "col-break",
                },
                {
                    label: "Marital Status*",
                    name: "program",
                    type: "select",
                    placeholder: "",
                  },
                  {
                    label: "Any Criminal Case *",
                    name: "college",
                    type: "select",
                    placeholder: "",
                  },
                  {
                      label: "Differently Abled *",
                      name: "custom_preference_1_",
                      type: "select",
                      placeholder: "",
                  },
                //   {
                //     label: "Have you taken a gap year after Class XII? *",
                //     type: "radio",
                //     name: "custom_have_you_taken_a_gap_year_after_class_xii",
                //     options: ["yes", "no"],
                //   },

                //   {
                //     type: "radio2",
                //     name: "custom_have_you_taken_a_gap_year_after_class_xii",
                //     options: ["yes", "no"],
                //     fields:[
                //         {
                //             label: "Total Number Of Years in Teaching",
                //             name: "name",
                //             type: "text",
                //             placeholder: "",
                //         },
                //         {
                //             headers: [
                //               {
                //                 key: "S.no",
                //                 value: "idx",
                //               },
                //               {
                //                 key: "Designation",
                //                 value: "",
                //               },
                //               {
                //                 key: "Organisation Name",
                //                 value: "",
                //               },
                //               {
                //                 key: "Start Date",
                //                 value: "",
                //               },
                //               {
                //                 key: "End Date",
                //                 value: "",
                //               },
                //             ],
                //             type: "table1",
                //             name: "siblings",
                //             minRows: 3, 
                //           },
                //     ]
                //   },
              ],
            },
            {
                label: "Contact Information",
                name: "generalDetails",
                type: "section",
                fields: [
                  {
                    label: "Email Address *",
                    name: "name",
                    type: "text",
                    placeholder: "",
                  },
                  {
                    label: "Alternate Email Address",
                    name: "assignedto",
                    type: "text",
                    placeholder: "",
                  },
                  {
                      label: "Phone Number *",
                      name: "custom_program_group",
                      type: "text",
                      placeholder: "",
                  },
                  {
                    type: "col-break",
                  },
                  {
                    label: "Alternate Phone Number",
                    name: "program",
                    type: "select",
                    placeholder: "",
                  },
                  {
                    label: "Telephone Number",
                    name: "college",
                    type: "select",
                    placeholder: "",
                  },
                  {
                    type: "col-break",
                  },
                  {
                      label: "Emergency Contact Name *",
                      name: "custom_preference_1_",
                      type: "select",
                      placeholder: "",
                  },
                  {
                      label: "Relation with Emergency Contact *",
                      name: "program",
                      type: "select",
                      placeholder: "",
                    },
                    {
                      label: "Emergency Contact Number *",
                      name: "college",
                      type: "select",
                      placeholder: "",
                    },
                ],
            },
            {
                label: "Present Address (communication may be sent to this address for the duration of the admission cycle)",
                name: "generalDetails",
                type: "section",
                fields: [
                  {
                    label: "Country *",
                    name: "name",
                    type: "text",
                    placeholder: "",
                  },
                  {
                    label: "State *",
                    name: "assignedto",
                    type: "text",
                    placeholder: "",
                  },
                  {
                      label: "District *",
                      name: "custom_program_group",
                      type: "text",
                      placeholder: "",
                  },
                  {
                    type: "col-break",
                  },
                  {
                    label: "City *",
                    name: "program",
                    type: "select",
                    placeholder: "",
                  },
                  {
                    type: "col-break",
                  },
                  {
                      label: "Address Line 1 *",
                      name: "custom_preference_1_",
                      type: "select",
                      placeholder: "",
                  },
                  {
                      label: "Address Line 2",
                      name: "program",
                      type: "select",
                      placeholder: "",
                    },
                    {
                      label: "Pincode *",
                      name: "college",
                      type: "text",
                      placeholder: "",
                    },
                    {
                        label: "Is your Permanent Address the same as your Correspondence Address?  *",
                        type: "radio",
                        name: "custom_have_you_taken_a_gap_year_after_class_xii",
                        options: ["yes", "no"],
                    },
        
                ],
            },
            {
                label: "Permanent Address",
                name: "generalDetails",
                type: "section",
                fields: [
                    {
                        label: "Country *",
                        name: "name",
                        type: "text",
                        placeholder: "",
                      },
                      {
                        label: "State *",
                        name: "assignedto",
                        type: "text",
                        placeholder: "",
                      },
                      {
                          label: "District *",
                          name: "custom_program_group",
                          type: "text",
                          placeholder: "",
                      },
                      {
                        type: "col-break",
                      },
                      {
                        label: "City *",
                        name: "program",
                        type: "select",
                        placeholder: "",
                      },
                      {
                        type: "col-break",
                      },
                      {
                          label: "Address Line 1 *",
                          name: "custom_preference_1_",
                          type: "select",
                          placeholder: "",
                      },
                      {
                          label: "Address Line 2",
                          name: "program",
                          type: "select",
                          placeholder: "",
                        },
                        {
                          label: "Pincode *",
                          name: "college",
                          type: "text",
                          placeholder: "",
                        },
                ],
            },
        ]
    },
    {
        name: "Education & Qualification",
        icon: EducationIcon,
        fields: [
            {
              label: "Undergraduate educational details",
              name: "generalDetails",
              type: "section",
              fields: [
                {
                    type: "radio2",
                    name: "custom_have_you_taken_a_gap_year_after_class_xii",
                    options: ["yes", "no"],
                    fields:[
                        {
                            label: "Degree *",
                            name: "name",
                            type: "text",
                            placeholder: "",
                        },
                        {
                            label: "Branch/Specialization *",
                            name: "assignedto",
                            type: "text",
                            placeholder: "",
                        },
                        {
                            label: "Year Of Passing/Graduation *",
                            name: "custom_program_group",
                            type: "text",
                            placeholder: "",
                        },
                        {
                            type: "col-break",
                        },
                        {
                            label: "Class/Grade *",
                            name: "program",
                            type: "select",
                            placeholder: "",
                        },
                        {
                            label: "Marks/GPA(Out Of 100) *",
                            name: "college",
                            type: "select",
                            placeholder: "",
                        },
                        {
                            label: "Institute Studied *",
                            name: "custom_preference_1_",
                            type: "select",
                            placeholder: "",
                        },
                        {
                            type: "col-break",
                        },
                        {
                            label: "University Studied *",
                            name: "program",
                            type: "text",
                            placeholder: "",
                        },
                        {
                            label: "University Type *",
                            name: "college",
                            type: "select",
                            placeholder: "",
                        },
                    ]
                  },
                {
                    type: "col-break",
                },

              ],
            },
            {
                label: "Postgraduate educational details",
                name: "generalDetails",
                type: "section",
                fields: [
                    {
                        type: "radio2",
                        name: "custom_have_you_taken_a_gap_year_after_class_xii",
                        options: ["yes", "no"],
                        fields:[
                            {
                                label: "Degree *",
                                name: "name",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                label: "Branch/Specialization *",
                                name: "assignedto",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                label: "Year Of Passing/Graduation *",
                                name: "custom_program_group",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                type: "col-break",
                            },
                            {
                                label: "Class/Grade *",
                                name: "program",
                                type: "select",
                                placeholder: "",
                            },
                            {
                                label: "Marks/GPA(Out Of 100) *",
                                name: "college",
                                type: "select",
                                placeholder: "",
                            },
                            {
                                label: "Institute Studied *",
                                name: "custom_preference_1_",
                                type: "select",
                                placeholder: "",
                            },
                            {
                                type: "col-break",
                            },
                            {
                                label: "University Studied *",
                                name: "program",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                label: "University Type *",
                                name: "college",
                                type: "select",
                                placeholder: "",
                            },
                        ]
                    },
  
                ],
            },
            {
                label: "M.Phil details",
                name: "generalDetails",
                type: "section",
                fields: [
                    {
                        type: "radio2",
                        name: "custom_have_you_taken_a_gap_year_after_class_xii",
                        options: ["yes", "no"],
                        fields:[
                            {
                                label: "Branch/Specialization *",
                                name: "name",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                label: "Title Of Thesis *",
                                name: "assignedto",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                label: "Year Of Award *",
                                name: "custom_program_group",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                type: "col-break",
                            },
                            {
                                label: "Marks *",
                                name: "program",
                                type: "select",
                                placeholder: "",
                            },
                            {
                                label: "Awarded By *",
                                name: "college",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                label: "University Name *",
                                name: "custom_preference_1_",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                type: "col-break",
                            },
                            {
                                label: "Registered Date *",
                                name: "program",
                                type: "select",
                                placeholder: "",
                            },
                        ]
                    },  
                ],
            },
            {
                label: "Ph.D details",
                name: "generalDetails",
                type: "section",
                fields: [
                    {
                        type: "radio2",
                        name: "custom_have_you_taken_a_gap_year_after_class_xii",
                        options: ["yes", "no"],
                        fields:[
                            {
                                label: "Branch/Specialization *",
                                name: "name",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                label: "Title Of Thesis *",
                                name: "assignedto",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                label: "Year Of Award *",
                                name: "custom_program_group",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                type: "col-break",
                            },
                            {
                                label: "Awarded By *",
                                name: "program",
                                type: "select",
                                placeholder: "",
                            },
                            {
                                label: "Type of Ph.D *",
                                name: "college",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                label: "University Name *",
                                name: "custom_preference_1_",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                type: "col-break",
                            },
                            {
                                label: "Registered Date *",
                                name: "program",
                                type: "select",
                                placeholder: "",
                            },
                        ]
                    },
                ],
            },
            {
                label: "Other Qualifications",
                name: "generalDetails",
                type: "section",
                fields: [
                  {
                      label: "Branch/Specialization ",
                      name: "name",
                      type: "text",
                      placeholder: "",
                  },
                  {
                      label: "Year Of Passing ",
                      name: "assignedto",
                      type: "text",
                      placeholder: "",
                  },
                  {
                      label: "Class/Grade",
                      name: "custom_program_group",
                      type: "text",
                      placeholder: "",
                  },
                  {
                      type: "col-break",
                  },
                  {
                      label: "% Marks *",
                      name: "program",
                      type: "select",
                      placeholder: "",
                  },
                  {
                      label: "Institute Studied",
                      name: "college",
                      type: "text",
                      placeholder: "",
                  },
                  {
                      label: "University Name",
                      name: "custom_preference_1_",
                      type: "text",
                      placeholder: "",
                  },
                  {
                      type: "col-break",
                  },
                  {
                      label: "Teaching Eligibility Test",
                      name: "program",
                      type: "select",
                      placeholder: "",
                  },
                ],
            },
            {
                label: "Latest employment details",
                name: "generalDetails",
                type: "section",
                fields: [
                    {
                        type: "radio2",
                        name: "custom_have_you_taken_a_gap_year_after_class_xii",
                        options: ["yes", "no"],
                        fields:[
                            {
                                label: "Position *",
                                name: "name",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                label: "Location *",
                                name: "assignedto",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                label: "Total Annual Compensation (CTC) *",
                                name: "custom_program_group",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                type: "col-break",
                            },
                            {
                                label: "Organization Where Employed *",
                                name: "program",
                                type: "select",
                                placeholder: "",
                            },
                            {
                                label: "Job Type *",
                                name: "college",
                                type: "select",
                                placeholder: "",
                            },
                            {
                                label: "Notice Period In Days *",
                                name: "custom_preference_1_",
                                type: "select",
                                placeholder: "",
                            },
                        ]
                    }, 
                ],
            },
            {
                label: "Upload (Upload recent passport size)",
                name: "generalDetails",
                type: "section",
                fields: [
                {
                    label: "Upload Photo * ",
                    name: "name",
                    type: "upload",
                    placeholder: "",
                },
                {
                    label: "Upload Resume *",
                    name: "assignedto",
                    type: "upload",
                    placeholder: "",
                },
                {
                    type: "col-break",
                },
                {
                    label: "Aadhaar Number",
                    name: "custom_program_group",
                    type: "text",
                    placeholder: "",
                },
                {
                    label: "Upload Aadhaar Card *",
                    name: "program",
                    type: "upload",
                    placeholder: "",
                  },
                ],
            },
          ],
    },
    {
        name: "Experience & Awards/Recognitions",
        icon: ExperienceIcon,
        fields: [
            {
                label: "Teaching Experience",
                name: "generalDetails",
                type: "section",
                fields: [
                    {
                        type: "radio2",
                        name: "custom_have_you_taken_a_gap_year_after_class_xii",
                        options: ["yes", "no"],
                        fields:[
                            {
                                label: "Total Number Of Years in Teaching",
                                name: "name",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                headers: [
                                  {
                                    key: "S.no",
                                    value: "idx",
                                  },
                                  {
                                    key: "Designation",
                                    value: "",
                                  },
                                  {
                                    key: "Organisation Name",
                                    value: "",
                                  },
                                  {
                                    key: "Start Date",
                                    value: "",
                                  },
                                  {
                                    key: "End Date",
                                    value: "",
                                  },
                                ],
                                type: "table1",
                                name: "siblings",
                                minRows: 3, 
                              },
                        ]
                      },
                ]
            },
            {
                label: "Research experience",
                name: "generalDetails",
                type: "section",
                fields: [
                    {
                        type: "radio2",
                        name: "custom_have_you_taken_a_gap_year_after_class_xii",
                        options: ["yes", "no"],
                        fields:[
                            {
                                label: "Total Number Of Years in Research",
                                name: "name",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                headers: [
                                  {
                                    key: "S.no",
                                    value: "idx",
                                  },
                                  {
                                    key: "Designation",
                                    value: "",
                                  },
                                  {
                                    key: "Organisation Name",
                                    value: "",
                                  },
                                  {
                                    key: "Start Date",
                                    value: "",
                                  },
                                  {
                                    key: "End Date",
                                    value: "",
                                  },
                                ],
                                type: "table1",
                                name: "siblings",
                                minRows: 3, 
                              },
                        ]
                      },
                ]
            },
            {
                label: "Industry experience",
                name: "generalDetails",
                type: "section",
                fields: [
                    {
                        type: "radio2",
                        name: "custom_have_you_taken_a_gap_year_after_class_xii",
                        options: ["yes", "no"],
                        fields:[
                            {
                                label: "Total Number Of Years in Industry *",
                                name: "name",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                headers: [
                                  {
                                    key: "S.no",
                                    value: "idx",
                                  },
                                  {
                                    key: "Designation",
                                    value: "",
                                  },
                                  {
                                    key: "Industry Name",
                                    value: "",
                                  },
                                  {
                                    key: "Start Date",
                                    value: "",
                                  },
                                  {
                                    key: "End Date",
                                    value: "",
                                  },
                                ],
                                type: "table1",
                                name: "siblings",
                                minRows: 3, 
                              },
                        ]
                      },
                ]
            },
            {
                label: "National & International Awards / Recognitions",
                name: "generalDetails",
                type: "section",
                fields: [
                    {
                        type: "radio2",
                        name: "custom_have_you_taken_a_gap_year_after_class_xii",
                        options: ["yes", "no"],
                        fields:[
                            {
                                headers: [
                                  {
                                    key: "S.no",
                                    value: "idx",
                                  },
                                  {
                                    key: "Award/Recognitions",
                                    value: "",
                                  },
                                  {
                                    key: "Year",
                                    value: "",
                                  },
                                ],
                                type: "table1",
                                name: "siblings",
                                minRows: 3, 
                              },
                        ]
                      },
                ]
            },
            {
                label: "State Awards / Recognitions",
                name: "generalDetails",
                type: "section",
                fields: [
                    {
                        type: "radio2",
                        name: "custom_have_you_taken_a_gap_year_after_class_xii",
                        options: ["yes", "no"],
                        fields:[
                            {
                                headers: [
                                    {
                                        key: "S.no",
                                        value: "idx",
                                      },
                                      {
                                        key: "Award/Recognitions",
                                        value: "",
                                      },
                                      {
                                        key: "Year",
                                        value: "",
                                      },
                                ],
                                type: "table1",
                                name: "siblings",
                                minRows: 3, 
                              },
                        ]
                      },
                ]
            },
        ]
    },
    {
        name: "Projects & Research",
        icon: ResearchIcon,
        fields: [
            {
                label: "Research projects",
                name: "generalDetails",
                type: "section",
                fields: [
                    {
                        type: "radio2",
                        name: "custom_have_you_taken_a_gap_year_after_class_xii",
                        options: ["yes", "no"],
                        fields:[
                            {
                                label: "Total Number Of Research Projects UnderTaken",
                                name: "name",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                label: "Major Research Projects UnderTaken",
                                name: "assignedto",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                label: "Minor Research Projects UnderTaken",
                                name: "custom_program_group",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                type: "col-break",
                            },
                            {
                                label: "Project Budget Less Than Rs. 10 Lakhs",
                                name: "program",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                label: "Project Budget Between Rs.10-25 Lakhs",
                                name: "college",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                label: "Project Budget Rs.25 Lakhs and above",
                                name: "custom_preference_1_",
                                type: "text",
                                placeholder: "",
                            },
                        ]
                    },
                  {
                      type: "col-break",
                  },
                    
                ],
            },
            {
                label: "Consultancy projects",
                name: "generalDetails",
                type: "section",
                fields: [
                    {
                        type: "radio2",
                        name: "custom_have_you_taken_a_gap_year_after_class_xii",
                        options: ["yes", "no"],
                        fields:[
                            {
                                label: "Total Number Of Consultancy Projects UnderTaken",
                                name: "name",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                label: "Major Consultancy Projects UnderTaken",
                                name: "assignedto",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                label: "Minor Consultancy Projects UnderTaken",
                                name: "custom_program_group",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                type: "col-break",
                            },
                            {
                                label: "Project Budget Less Than Rs. 10 Lakhs",
                                name: "program",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                label: "Project Budget Between Rs.10-25 Lakhs",
                                name: "college",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                label: "Project Budget Rs.25 Lakhs and above",
                                name: "custom_preference_1_",
                                type: "text",
                                placeholder: "",
                            },
                        ]
                    },
                  {
                      type: "col-break",
                  },
                    
                ],
            },
            {
                label: "Patents",
                name: "generalDetails",
                type: "section",
                fields: [
                    {
                        type: "radio2",
                        name: "custom_have_you_taken_a_gap_year_after_class_xii",
                        options: ["yes", "no"],
                        fields:[
                            {
                                label: "Number Of Patents-Indian",
                                name: "name",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                label: "Number Of Patents-International",
                                name: "assignedto",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                label: "Number Of Patents Filed",
                                name: "custom_program_group",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                type: "col-break",
                            },
                            {
                                label: "Number Of Patents Awarded",
                                name: "program",
                                type: "text",
                                placeholder: "",
                            },
                        ]
                    },
                ],
            },
            {
                label: "Research guidance",
                name: "generalDetails",
                type: "section",
                fields: [
                    {
                        type: "radio2",
                        name: "custom_have_you_taken_a_gap_year_after_class_xii",
                        options: ["yes", "no"],
                        fields:[
                            {
                                label: "Number Of Ph.D's Awarded",
                                name: "name",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                label: "Number Of Ph.D's Under Supervision",
                                name: "assignedto",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                label: "Number Of M.Phil's Awarded",
                                name: "custom_program_group",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                type: "col-break",
                            },
                            {
                                label: "Number Of M.Phil's Under Supervision",
                                name: "program",
                                type: "text",
                                placeholder: "",
                            },
                        ]
                    },  
                ],
            },  
        ]
    },
    {
        name: "Paper & Miscellaneous",
        icon: PaperIcon,
        fields: [
            {
                label: "Papers published (Total)",
                name: "generalDetails",
                type: "section",
                fields: [
                    {
                        type: "radio2",
                        name: "custom_have_you_taken_a_gap_year_after_class_xii",
                        options: ["yes", "no"],
                        fields:[
                            {
                                label: "Total Papers",
                                name: "name",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                label: "Web of Science",
                                name: "assignedto",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                label: "Google Scholar",
                                name: "custom_program_group",
                                type: "text",
                                placeholder: "",
                            },
                            {
                                type: "col-break",
                            },
                            {
                                label: "Scopus",
                                name: "program",
                                type: "text",
                                placeholder: "",
                            },
                        ]
                    },
                  {
                      type: "col-break",
                  },
                  
                ],
            },
            {
                label: "Miscellaneous",
                name: "generalDetails",
                type: "section",
                fields: [
                  {
                      label: "Your Orchid ID",
                      name: "name",
                      type: "text",
                      placeholder: "",
                  },
                  {
                      label: "LinkedIn URL",
                      name: "assignedto",
                      type: "text",
                      placeholder: "",
                  },
                  {
                      label: "Books/Books Chapter",
                      name: "custom_program_group",
                      type: "text",
                      placeholder: "",
                  },
                  {
                      type: "col-break",
                  },
                  {
                      label: "Summary Of All Your Publications",
                      name: "program",
                      type: "upload",
                      placeholder: "",
                  },
                ],
            },
            {
                label: "Web of Science",
                name: "generalDetails",
                type: "section",
                fields: [
                  {
                      label: "Your Researcher ID",
                      name: "name",
                      type: "text",
                      placeholder: "",
                  },
                  {
                      label: "H -Index",
                      name: "assignedto",
                      type: "text",
                      placeholder: "",
                  },
                  {
                      label: "Number Of Citations",
                      name: "custom_program_group",
                      type: "text",
                      placeholder: "",
                  },
                ],
            },
            {
                label: "Scopus",
                name: "generalDetails",
                type: "section",
                fields: [
                  {
                      label: "Your Author ID",
                      name: "name",
                      type: "text",
                      placeholder: "",
                  },
                  {
                      label: "H -Index",
                      name: "assignedto",
                      type: "text",
                      placeholder: "",
                  },
                  {
                      label: "Number Of Citations",
                      name: "custom_program_group",
                      type: "text",
                      placeholder: "",
                  },
                ],
            },
            {
                label: "Best 5 Papers (in your opinion)",
                name: "generalDetails",
                type: "section",
                fields: [
                    {
                        type: "col-break",
                    },
                    {
                        label: "Paper 1",
                        name: "name",
                        type: "label",
                        placeholder: "",
                    },
                    {
                        type: "col-break",
                    },
                    {
                        label: "Author",
                        name: "name",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        label: "Title",
                        name: "assignedto",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        label: "Journal/Conference",
                        name: "custom_program_group",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        type: "col-break",
                    },
                    {
                        label: "Year",
                        name: "name",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        label: "Number Of Pages",
                        name: "assignedto",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        label: "Number Of Citations",
                        name: "custom_program_group",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        type: "col-break",
                    },
                    {
                        label: "Upload Publication",
                        name: "custom_program_group",
                        type: "upload",
                        placeholder: "",
                    },
                    {
                        type: "col-break",
                    },
                    {
                        type: "col-break",
                    },
                    {
                        label: "Paper 2",
                        name: "name",
                        type: "label",
                        placeholder: "",
                    },
                    {
                        type: "col-break",
                    },
                    {
                        label: "Author",
                        name: "name",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        label: "Title",
                        name: "assignedto",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        label: "Journal/Conference",
                        name: "custom_program_group",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        type: "col-break",
                    },
                    {
                        label: "Year",
                        name: "name",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        label: "Number Of Pages",
                        name: "assignedto",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        label: "Number Of Citations",
                        name: "custom_program_group",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        type: "col-break",
                    },
                    {
                        label: "Upload Publication",
                        name: "custom_program_group",
                        type: "upload",
                        placeholder: "",
                    },
                    {
                        type: "col-break",
                    },
                    {
                        type: "col-break",
                    },
                    {
                        label: "Paper 3",
                        name: "name",
                        type: "label",
                        placeholder: "",
                    },
                    {
                        type: "col-break",
                    },
                    {
                        label: "Author",
                        name: "name",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        label: "Title",
                        name: "assignedto",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        label: "Journal/Conference",
                        name: "custom_program_group",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        type: "col-break",
                    },
                    {
                        label: "Year",
                        name: "name",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        label: "Number Of Pages",
                        name: "assignedto",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        label: "Number Of Citations",
                        name: "custom_program_group",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        type: "col-break",
                    },
                    {
                        label: "Upload Publication",
                        name: "custom_program_group",
                        type: "upload",
                        placeholder: "",
                    },
                    {
                        type: "col-break",
                    },
                    {
                        type: "col-break",
                    },
                    {
                        label: "Paper 4",
                        name: "name",
                        type: "label",
                        placeholder: "",
                    },
                    {
                        type: "col-break",
                    },
                    {
                        label: "Author",
                        name: "name",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        label: "Title",
                        name: "assignedto",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        label: "Journal/Conference",
                        name: "custom_program_group",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        type: "col-break",
                    },
                    {
                        label: "Year",
                        name: "name",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        label: "Number Of Pages",
                        name: "assignedto",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        label: "Number Of Citations",
                        name: "custom_program_group",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        type: "col-break",
                    },
                    {
                        label: "Upload Publication",
                        name: "custom_program_group",
                        type: "upload",
                        placeholder: "",
                    },
                    {
                        type: "col-break",
                    },
                    {
                        type: "col-break",
                    },
                    {
                        label: "Paper 5",
                        name: "name",
                        type: "label",
                        placeholder: "",
                    },
                    {
                        type: "col-break",
                    },
                    {
                        label: "Author",
                        name: "name",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        label: "Title",
                        name: "assignedto",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        label: "Journal/Conference",
                        name: "custom_program_group",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        type: "col-break",
                    },
                    {
                        label: "Year",
                        name: "name",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        label: "Number Of Pages",
                        name: "assignedto",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        label: "Number Of Citations",
                        name: "custom_program_group",
                        type: "text",
                        placeholder: "",
                    },
                    {
                        type: "col-break",
                    },
                    {
                        label: "Upload Publication",
                        name: "custom_program_group",
                        type: "upload",
                        placeholder: "",
                    },
                ],
            },
          ],
    },
    {   name: "Declaration",
        icon: DeclarationIcon,
        fields: [
            {
            name: "languages",
            // type: "section",
            fields: [
                {
                    type: "col-break",
                },
                {
                    type: "col-break",
                },
                {
                    type: "col-break",
                },
                {
                    type: "col-break",
                },
                {
                    label: "Declaration",
                    name: "custom_program_group",
                    type: "label1",
                    placeholder: "",
                },    
                {
                    type: "checkbox",
                    label:
                    "I certify that the information submitted by me in support of this application, is true to the best of knowledge and belief. I understand that in the event of any information being found false or incorrect, my admission is liable to be rejected / cancelled at any stage of the program. I undertake to abide by the disciplinary rules and regulations of the institute.",
                    name: "custom_i_agree",
                    options: [
                    {
                        key: "I Agree",
                        value: "custom_i_agree",
                    },
                    ],
                },]
        }
        ]
    },
]
import React, { useEffect, useState } from "react";
import { getEvents } from "modules/application.js";
import LoadingBackdrop from "components/common/LoadingBackdrop.js";
import { Table } from "antd";
import useStyles from "../style";
import GetEvent from "./GetEvent";
import { useSelector } from "react-redux";
import { getStudentDetails } from "modules/studentApplication";

export default function DataGridDemo({ uniqueId }) {
  const styles = useStyles();
  const user = useSelector((state) => state.user);
  const [events, setEvents] = useState([]);
  const [showGetEvent, setShowGetEvent] = useState(false);
  const [eventDetails, setEventDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [guests, setGuests] = useState("");

  useEffect(() => {
    handleGetEvents();
    handleGetStudentDetails();
  }, []);

  const handleGetStudentDetails = async () => {
    const res = await getStudentDetails( uniqueId );
    if (res.status === 200) {
      setGuests(`${res.data.data.student_email_id}, ${user.email}`);
    }
  };

  const handleGetEvents = async () => {
    setLoading(true);
    const res = await getEvents({ id: uniqueId });
    if (res.status === 200) {
      setLoading(false);
      let data = res?.data?.data;
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        let val = {
          ...data[i],
          id: data[i].name,
          idx: i
        };
        arr.push(val);
      }
      setEvents(arr);
    } else {
      setLoading(false);
    }
  };

  const handleShowEvent = (details) => {
    setShowGetEvent(true);
    setEventDetails(details);
  };

  const columns = [
    {
      dataIndex: "subject",
      title: "Subject",
      width: 600,
    },
    {
      dataIndex: "event_type",
      title: "Event Type",
      width: 200,
      render: (val, row) => {
        const dynamicClassName = `statusEvent ${row.event_type}`;
        return (
          <span className={dynamicClassName}>{row.event_type}</span>
        );
      },
    },
    {
      dataIndex: "status",
      title: "Status",
      width: 200,
      render: (val, row) => {
        const dynamicClassName = `status ${row.status}`;
        return (
          <span className={dynamicClassName}>{row.status}</span>
        ); 
      },
    },
    {
      dataIndex: "name",
      title: "ID",
      width: 200,
    }, 
    {
      dataIndex: "idx",
      title: "Action",
      width: 200,
      render: (val, row) => {
        return (
          <span className={styles.viewLink} onClick={() => handleShowEvent(row)}>View</span>
        ); 
      },
    }, 
  ];

  return (
    <div style={{ width: "100%" }}>
      {
        loading ? (
        <LoadingBackdrop open={loading}/>
        ) : <>
          {
            showGetEvent && <GetEvent eventDetails={eventDetails} values={guests}/>
          }
          {
            !loading && events.length > 0 && !showGetEvent ? (
              <Table
                columns={columns} 
                dataSource={events} 
                size='small'
                pagination={true}
              />
            ) : !showGetEvent && <p>No Events Created yet</p>
          }
        </>
      }
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Breadcrumbs, MainLayout } from "components/common";
import {
  getGenericData,
  createGenericEntity,
  updateGenericEntity,
  createLeaveType,
  updateLeaveType,
} from "modules/hr";
import { Button, Table, message, Input } from "antd";
import useStyles from "./style.js";
import { CustomBreadcrumb } from "components/common";
import { toast } from "react-toastify";

const LeaveTypeDetails = () => {
  const styles = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const [leaveType, setLeaveType] = useState({
    leave_type_name: "",
    max_leaves_allowed: "",
    applicable_after: "",
    max_continuous_days_allowed: "",
    is_carry_forward: false,
    doctype: "Leave Type",
    is_lwp: false,
    is_ppl: false,
    is_optional_leave: false,
    allow_negative: false,
    allow_over_allocation: false,
    include_holiday: false,
    is_compensatory: false,
    is_earned_leave: false,
    allow_encashment: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isViewMode, setIsViewMode] = useState(false);
  const [errors, setErrors] = useState({});
  const [showEarnedLeave, setShowEarnedLeave] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          const leaveTypeData = await getGenericData("Leave Type", id);
          setLeaveType(leaveTypeData.message);
          setIsViewMode(true);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching Leave Type Details:", error);
        setIsLoading(false);
      }
    };

    fetchData();
    setShowEarnedLeave(true);

    return () => {
      // Cleanup if needed
    };
  }, [id]);

  const handleSave = async () => {
    try {
      const doctype = "Leave Type";
      const validationErrors = validateFields();

      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }

      if (id && id !== "new") {
        await updateLeaveType({ payload: { ...leaveType, doctype } });
        toast.success("Leave Type Updated!");
        navigate(-1);
      } else {
        await createLeaveType({ payload: { ...leaveType, doctype } });
        toast.success("Leave Type Created!");
        navigate(-1);
      }
    } catch (error) {
      console.error("Error saving leaveType:", error);
    } 
  };

  const validateFields = () => {
    const errors = {};

    if (!leaveType.leave_type_name.trim()) {
      errors.leave_type_name = "Leave Type Name is required";
    }

    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLeaveType((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (
      name === "is_carry_forward" ||
      name === "is_lwp" ||
      name === "is_ppl" ||
      name === "is_optional_leave" ||
      name === "allow_negative" ||
      name === "allow_over_allocation" ||
      name === "include_holiday" ||
      name === "is_compensatory"
    ) {
      setShowEarnedLeave(true);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const LeavePolicyOption = ({ title, description }) => {
    return (
      <div>
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
    );
  };

  const leavePolicyOptions = [
    {
      title: "Maximum Leave Allocation Allowed:",
      description:
        "Determine the maximum annual allocation for this Leave Type when creating the Leave Policy.",
    },
    {
      title: "Applicable After (Working Days):",
      description:
        "Specify the minimum number of working days an employee must have completed to be eligible for this leave type. Days taken for other leave types (such as Casual Leave or Sick Leave) after the joining date are included in the calculation of working days.",
    },
    {
      title: "Maximum Consecutive Leaves Allowed:",
      description:
        "Set the maximum number of consecutive days this Leave Type can be taken. If exceeded, additional leave will be treated as 'Leave Without Pay'.",
    },
    {
      title: "Is Carry Forward:",
      description:
        "If enabled, remaining leave balance for this Leave Type will be carried forward to the next allocation period.",
    },
    {
      title: "Is Leave Without Pay:",
      description:
        "Designate this Leave Type as unpaid, resulting in salary deduction when taken.",
    },
    {
      title: "Is Optional Leave:",
      description:
        "Allow employees to choose from a list of company-designated holidays. The maximum number of optional leaves can be restricted.",
    },
    {
      title: "Allow Negative Balance:",
      description:
        "Permit applying for and approving leave applications even when there is no leave balance.",
    },
    {
      title: "Allow Over Allocation:",
      description:
        "Allow allocation of more leave days than the duration of the allocation period.",
    },
    {
      title: "Include holidays within leaves as leaves:",
      description:
        "If checked, holidays within leave periods are counted as part of the leave duration.",
    },
    {
      title: "Is Compensatory:",
      description:
        "Mark this Leave Type as compensatory, typically granted for overtime or work on holidays. Employees can request compensatory leaves.",
    },
  ];

  const breadcrumbs = [
    {
      label: "IIHT",
      link: "/dashboard",
    },
    {
      label: "HR",
      link: "/modules/hr",
    },
    {
      label: "Leave Type",
      link: "/modules/hr/leave-management/masters/leave-type",
    },
    {
      label: "Add-update Leave Type",
      link: "/modules/hr/leave-management/masters/get-update-leaveType-list",
    },
  ];
  return (
    <MainLayout>
      <CustomBreadcrumb breadcrumbs={breadcrumbs} />
      <div className="master-heading">
        <h2>{isViewMode ? "View Leave Type" : "Add Leave Type"}</h2>
        <div className="btnsGrp">
          <Button variant="outlined" color="secondary" onClick={handleCancel}>
            back
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            className="redBtn"
            // disabled={isViewMode}
          >
            {isViewMode ? "Update" : "Save"} Leave Type
          </Button>
        </div>
      </div>
      <div className="formApplication leaveMgmtForm">
        <section className="form-section">
          <div className="fields-block">
            <div className="form-field">
              <label>Leave Type Name *</label>
              <input
                type="text"
                name="leave_type_name"
                placeholder="ex Text"
                value={leaveType.leave_type_name}
                onChange={handleChange}
              />
              {errors.leave_type_name && (
                <span className="error">{errors.leave_type_name}</span>
              )}
            </div>
            <div className="form-field">
              <label>Maximum Leave Allocation </label>
              <input
                type="text"
                name="max_leaves_allowed"
                placeholder="ex 4"
                value={leaveType.max_leaves_allowed}
                onChange={handleChange}
              />
            </div>
            <div className="form-field">
              <label>Applicable After (Working Days)</label>
              <input
                type="text"
                name="applicable_after"
                placeholder="ex 4"
                value={leaveType.applicable_after}
                onChange={handleChange}
              />
            </div>
            <div className="form-field">
              <label>Maximum Consecutive Leaves Allowed </label>
              <input
                type="text"
                name="max_continuous_days_allowed"
                placeholder="ex Text"
                value={leaveType.max_continuous_days_allowed}
                onChange={handleChange}
              />
            </div>
          </div>
        </section>
        <div className={styles.formContainer}>
          <div className={styles.field}>
            <div className={styles.checkboxContainer}>
              <input
                type="checkbox"
                name="is_carry_forward"
                checked={leaveType.is_carry_forward}
                onChange={(e) => {
                  setLeaveType({
                    ...leaveType,
                    is_carry_forward: e.target.checked,
                  });
                  setShowEarnedLeave(e.target.checked);
                }}
              />
              <p className={styles.fieldLabel}>Is Carry Forward</p>
            </div>
          </div>
          <div className={styles.field}>
            <div className={styles.checkboxContainer}>
              <input
                type="checkbox"
                checked={leaveType.is_lwp}
                name="is_lwp"
                onChange={(e) => {
                  setLeaveType({ ...leaveType, is_lwp: e.target.checked });
                  setShowEarnedLeave(e.target.checked);
                }}
              />
              <p className={styles.fieldLabel}>Is Leave Without Pay</p>
            </div>
          </div>
          <div className={styles.field}>
            <div className={styles.checkboxContainer}>
              <input
                type="checkbox"
                name="is_ppl"
                checked={leaveType.is_ppl}
                onChange={(e) => {
                  setLeaveType({ ...leaveType, is_ppl: e.target.checked });
                  setShowEarnedLeave(e.target.checked);
                }}
              />
              <p className={styles.fieldLabel}>Is Partially Paid Leave</p>
            </div>
          </div>
          <div className={styles.field}>
            <div className={styles.checkboxContainer}>
              <input
                type="checkbox"
                checked={leaveType.is_optional_leave}
                name="is_optional_leave"
                onChange={(e) => {
                  setLeaveType({
                    ...leaveType,
                    is_optional_leave: e.target.checked,
                  });
                  setShowEarnedLeave(e.target.checked);
                }}
              />
              <p className={styles.fieldLabel}>Is Optional Leave</p>
            </div>
          </div>
          <div className={styles.field}>
            <div className={styles.checkboxContainer}>
              <input
                type="checkbox"
                checked={leaveType.allow_negative}
                onChange={(e) => {
                  setLeaveType({
                    ...leaveType,
                    allow_negative: e.target.checked,
                  });
                  setShowEarnedLeave(e.target.checked);
                }}
              />
              <p className={styles.fieldLabel}>Allow Negative Balance</p>
            </div>
          </div>
          <div className={styles.field}>
            <div className={styles.checkboxContainer}>
              <input
                type="checkbox"
                checked={leaveType.allow_over_allocation}
                name="allow_over_allocation"
                onChange={(e) => {
                  setLeaveType({
                    ...leaveType,
                    allow_over_allocation: e.target.checked,
                  });
                  setShowEarnedLeave(e.target.checked);
                }}
              />
              <p className={styles.fieldLabel}>Allow Over Allocation</p>
            </div>
          </div>
          <div className={styles.field}>
            <div className={styles.checkboxContainer}>
              <input
                type="checkbox"
                checked={leaveType.include_holiday}
                name="include_holiday"
                onChange={(e) => {
                  setLeaveType({
                    ...leaveType,
                    include_holiday: e.target.checked,
                  });
                  setShowEarnedLeave(e.target.checked);
                }}
              />
              <p className={styles.fieldLabel}>
                Include holidays within leaves as leaves
              </p>
            </div>
          </div>
          <div className={styles.field}>
            <div className={styles.checkboxContainer}>
              <input
                type="checkbox"
                checked={leaveType.is_compensatory}
                name="is_compensatory"
                onChange={(e) => {
                  setLeaveType({
                    ...leaveType,
                    is_compensatory: e.target.checked,
                  });
                  setShowEarnedLeave(e.target.checked);
                }}
              />
              <p className={styles.fieldLabel}>Is Compensatory</p>
            </div>
          </div>
        </div>
        {showEarnedLeave && (
          <>
            <div className={styles.formContainer}>
              <h2>Encashment</h2>
              <hr className={styles.line}></hr>
              <div className={styles.field}>
                <div className={styles.checkboxContainer}>
                  <input
                    type="checkbox"
                    name="allow_encashment"
                    checked={leaveType.allow_encashment}
                    onChange={(e) =>
                      setLeaveType({
                        ...leaveType,
                        allow_encashment: e.target.checked,
                      })
                    }
                  />
                  <p className={styles.fieldLabel}>Is Partially Paid Leave</p>
                </div>
              </div>
            </div>
            <div className={styles.formContainer}>
              <h2>Earned Leave</h2>
              <hr className={styles.line}></hr>
              <div className={styles.field}>
                <div className={styles.checkboxContainer}>
                  <input
                    type="checkbox"
                    name="is_earned_leave"
                    checked={leaveType.is_earned_leave}
                    onChange={(e) =>
                      setLeaveType({
                        ...leaveType,
                        is_earned_leave: e.target.checked,
                      })
                    }
                  />
                  <p className={styles.fieldLabel}>Is Partially Paid Leave</p>
                </div>
              </div>
            </div>
          </>
        )}
        <section className="form-section">
          <h2 className="">Reference</h2>
          <hr className={styles.line}></hr>
          {leavePolicyOptions.map((option) => (
            <LeavePolicyOption
              title={option.title}
              description={option.description}
            />
          ))}
        </section>
      </div>
    </MainLayout>
  );
};

export default LeaveTypeDetails;
